<template id="academymodifica">
	<section class="academymodifica">

		<headapp></headapp>

		<menusub></menusub>

		<v-form></v-form>

		<footerapp></footerapp>

	</section>
</template>

<script>
export default {

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
    	menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		vForm:              require('./form.vue').default,
	}

}
</script>
