<template id="flag">
    <span>
        <img v-bind:src="image" v-bind:alt="country" @error="imageLoadError">
    </span>
</template>

<script>

export default {
    data: function() {
        return {
            hasError: false
        }
    },

    props: {
        country: {
            type: String,
            required: true
        },

        size: {
            type: Number,
            default: 24
        },

        stile: {
            default: function(){
                return 'shiny'
            }
        }
    },

    computed: {
        image: function() {
            return this.hasError ? '/assets/flags/flags-iso/' + this.stile + '/' + this.size + '/_unknown.png' : '/assets/flags/flags-iso/' + this.stile + '/' + this.size + '/' + this.country.toUpperCase() + '.png';
        }
    },

    watch: {
         country: function() {
            this.hasError = false;
        }
    },

    methods: {
        imageLoadError () {
            this.hasError = true;
        }
    },
}
</script>
