<template>
	<div :style="contentHeight && isFullscreen ? 'max-height:' + (contentHeight - 140) + 'px;overflow-y:auto;overflow-x:hidden;' : ''">

		<canvas style="display: none" ref="outputImage" class="img-fluid"></canvas>

		<b-card>

			<template #header>Panoramica di Squadra nel tempo.</template>

			<b-row>
				<b-col :cols="!isWidget || isFullscreen ? 10 : 12">
					<canvas ref="graph_1" width="400" :height="contentHeight && !isFullscreen ? contentHeight - 150 : 200"></canvas>
				</b-col>
				<b-col cols="2" v-if="!isWidget || isFullscreen">

					<b-form-group :class="{'m-0': ux.g1.aggregate == false }">
						<template #label>
							<b-checkbox size="sm" :disabled="ux.g1.type.length > 0" v-model="ux.g1.aggregate">Raggruppa Occorrenze</b-checkbox>
						</template>
						<div v-if="ux.g1.aggregate == true" class="d-flex">
							<b-select :disabled="ux.g1.type.length > 0" size="sm" v-model="ux.g1.aggregateMode">
								<b-select-option value="mean">Inferiori alla media generale</b-select-option>
								<b-select-option value="step">Inferiori a</b-select-option>
								<b-select-option value="perc">Inferiori alla %</b-select-option>
							</b-select>
							<div v-if="ux.g1.aggregateMode == 'step' || ux.g1.aggregateMode == 'perc'" class="ml-1">
								<b-input :disabled="ux.g1.type.length > 0" style="max-width: 100px" size="sm" type="number" v-model.number="ux.g1.aggreateValue"></b-input>
							</div>
						</div>
						
					</b-form-group>

					<b-form-group>
						<template #label>Aggrega Risultati</template>
						<b-select size="sm" v-model="ux.g1.segmentation">
							<b-select-option value="day">Giornaliera</b-select-option>
							<b-select-option value="week">Settimanale</b-select-option>
							<b-select-option value="month">Mensile</b-select-option>
							<b-select-option value="year">Annuale</b-select-option>
						</b-select>
					</b-form-group>

					<b-form-group>
						<template #label><span @click="ux.g1.showFilterItem = !ux.g1.showFilterItem">Filtra per <u>{{ config.itemSingular }}</u></span></template>

						<b-select size="sm" v-if="ux.g1.showFilterItem == true" v-model="ux.g1.type" :select-size="types.length/2" multiple>
							<b-select-option :value="null">Nessuna</b-select-option>
							<b-select-option v-for="t in types" :value="t">{{t}}</b-select-option>
						</b-select>
						<div style="line-height: 1" v-else>

							<span v-for="t, k in ux.g1.type">
								<a href="#" class="small" @click.prevent="ux.g1.type.splice(k, 1)">{{ t }}</a>
								<span v-if="k < ux.g1.type.length - 1">, </span>
							</span>

							<div class="small mt-2" v-if="ux.g1.type.length > 0">
								<small><a href="#" @click.prevent="ux.g1.type = []" class="text-muted">Svuota Filtro</a></small>
							</div>
							<div class="small" v-else>
								<small>Nessun <a href="#" @click.prevent="ux.g1.showFilterItem = true">Filtro</a></small>
								
							</div>
						</div>
					</b-form-group>

					<b-form-group>
						<template #label><span @click="ux.g1.showFilterPlayer = !ux.g1.showFilterPlayer">Filtra per <u>Giocatore</u></span></template>
						
						<b-select size="sm" v-if="ux.g1.showFilterPlayer == true" v-model="ux.g1.player" :select-size="players.length/2" multiple>
							<b-select-option :value="null">Nessuno</b-select-option>
							<b-select-option v-for="t in players" :value="t">{{t}}</b-select-option>
						</b-select>
						<div style="line-height: 1" v-else>

							<span v-for="t, k in ux.g1.player">
								<a href="#" class="small" @click.prevent="ux.g1.player.splice(k, 1)">{{ t }}</a>
								<span v-if="k < ux.g1.player.length - 1">, </span>
							</span>

							<div class="small mt-2" v-if="ux.g1.player.length > 0">
								<small><a href="#" @click.prevent="ux.g1.player = []" class="text-muted">Svuota Filtro</a></small>
							</div>
							<div class="small" v-else>
								<small>Nessun <a href="#" @click.prevent="ux.g1.showFilterPlayer = true">Filtro</a></small>
							</div>

						</div>
					</b-form-group>

					<hr />

					<b-form-group>
						<template #label>{{ config.itemPlural }} <strong>Svolte</strong></template>
						<b-form-select size="sm" v-model="ux.g1.configFatti">
							<b-form-select-option :value="null">Non Mostrare</b-form-select-option>
							<b-form-select-option value="bar">Istogramma Totale</b-form-select-option>
							<b-form-select-option value="bar-stacked">Istogramma Singole {{ config.itemPlural }}</b-form-select-option>
							<b-form-select-option value="line">Linea Totale</b-form-select-option>
							<!-- <b-form-select-option value="line-stacked">Linea Singole Fisioterapie</b-form-select-option> -->
						</b-form-select>
					</b-form-group>

					<b-form-group>
						<template #label>{{ config.itemPlural }} <strong>Programmate</strong></template>
						<b-form-select size="sm" v-model="ux.g1.configProgrammati">
							<b-form-select-option :value="null">Non Mostrare</b-form-select-option>
							<b-form-select-option value="bar">Istogramma Totale</b-form-select-option>
							<b-form-select-option value="bar-stacked">Istogramma Singole {{ config.itemPlural }}</b-form-select-option>
							<b-form-select-option value="line">Linea</b-form-select-option>
							<!-- <b-form-select-option value="line-stacked">Linea Singole Fisioterapie</b-form-select-option> -->
						</b-form-select>
					</b-form-group>

					<b-form-checkbox size="sm" v-model="ux.g1.hideLegend">Nascondi Legenda</b-form-checkbox>
					<b-form-checkbox size="sm" v-model="ux.g1.showTitle">Intestazione in Stampa</b-form-checkbox>

					<b-button-group>
						<b-button size="xs" variant="outline-secondary" @click.prevent="downloadGraph($refs.graph_1, ux.g1.title, ux.g1.showTitle ? ux.g1.title : null)" class="mt-2"><em class="fa fa-file-image-o"></em></b-button>
						<b-button size="xs" variant="outline-secondary" @click.prevent="downloadGraphDati(grafico_1.rawDati, config.itemSingular + ux.g1.title)" class="mt-2"><em class="fa fa-file-excel-o"></em></b-button>
					</b-button-group>
				</b-col>

			</b-row>

		</b-card>

		<hr v-if="!isWidget || isFullscreen" />

		<b-card v-show="!isWidget || isFullscreen">
			<template #header>Confronto Giocatori</template>

			<b-row>
				<b-col cols="10" v-show="ux.g2.show == true">
					<canvas ref="graph_2" width="400" height="200"></canvas>
				</b-col>
				<b-col :cols="ux.g2.show == true ? 2:12">
					
					<div v-if="ux.g2.show == true">

						<b-form-group :class="{'m-0': ux.g2.aggregate == false }">
							<template #label>
								<b-checkbox size="sm" :disabled="ux.g2.type.length > 0" v-model="ux.g2.aggregate">Raggruppa Occorrenze</b-checkbox>
							</template>
							<div v-if="ux.g2.aggregate == true" class="d-flex">
								<b-select :disabled="ux.g2.type.length > 0" size="sm" v-model="ux.g2.aggregateMode">
									<b-select-option value="mean">Inferiori alla media generale</b-select-option>
									<b-select-option value="step">Inferiori a</b-select-option>
									<b-select-option value="perc">Inferiori alla %</b-select-option>
								</b-select>
								<div v-if="ux.g2.aggregateMode == 'step' || ux.g2.aggregateMode == 'perc'" class="ml-1">
									<b-input :disabled="ux.g2.type.length > 0" style="max-width: 100px" size="sm" type="number" v-model.number="ux.g2.aggreateValue"></b-input>
								</div>
							</div>
							
						</b-form-group>

						<b-form-group>
							<template #label>{{ config.itemPlural }} <strong>Svolte</strong></template>
							<b-form-select size="sm" v-model="ux.g2.configFatti">
								<b-form-select-option :value="null">Non Mostrare</b-form-select-option>
								<b-form-select-option value="bar">Istogramma Totale</b-form-select-option>
								<b-form-select-option value="bar-stacked">Istogramma Singole {{ config.itemPlural }}</b-form-select-option>
								<b-form-select-option value="line">Punto Totale</b-form-select-option>
							</b-form-select>
						</b-form-group>

						<b-form-group>
							<template #label>{{ config.itemPlural }} <strong>Programmate</strong></template>
							<b-form-select size="sm" v-model="ux.g2.configProgrammati">
								<b-form-select-option :value="null">Non Mostrare</b-form-select-option>
								<b-form-select-option value="bar">Istogramma Totale</b-form-select-option>
								<b-form-select-option value="bar-stacked">Istogramma Singole {{ config.itemPlural }}</b-form-select-option>
								<b-form-select-option value="line">Punto Totale</b-form-select-option>
							</b-form-select>
						</b-form-group>

					</div>
					<div v-else>
						<p>Per poter generare il grafico è necessario selezionare almeno un giocatore!</p>
						<b-button class="mb-3" variant="primary" size="sm" @click.prevent="ux.g2.show = true; ux.g2.showFilterPlayer = false" :disabled="ux.g2.players.length == 0">Genera Grafico</b-button>
					</div>


					<b-form-group>
						<template #label><span @click.prevent="ux.g2.showFilterPlayer = !ux.g2.showFilterPlayer">Filtra per <u>Giocatore</u></span></template>

						<div>
							<b-checkbox size="sm" v-model="ux.g2.players" v-for="k,a in players" :value="k" v-if="ux.g2.showFilterPlayer == true || ux.g2.players.indexOf(k) !== -1">{{k}}</b-checkbox>
						</div>

						<small v-if="ux.g2.showFilterPlayer == false && players.length != ux.g2.players.length"><a href="" @click.prevent="ux.g2.showFilterPlayer = true">Mostra Tutti</a> </small>
						<small v-if="ux.g2.showFilterPlayer == true"><a href="" @click.prevent="toggleAllPlayersG2">Seleziona Tutti</a></small>
					</b-form-group>



					<b-form-group>
						<template #label><span @click.prevent="ux.g2.showFilterItem = !ux.g2.showFilterItem">Filtra per <u>{{ config.itemSingular }}</u></span></template>
						<b-select size="sm" v-model="ux.g2.type" multiple v-if="ux.g2.showFilterItem == true">
							<b-select-option :value="null">Nessuna</b-select-option>
							<b-select-option v-for="t in types" :value="t">{{t}}</b-select-option>
						</b-select>
						<div style="line-height: 1" v-else>

							<span v-for="t, k in ux.g2.type">
								<a href="#" class="small" @click.prevent="ux.g2.type.splice(k, 1)">{{ t }}</a>
								<span v-if="k < ux.g2.type.length - 1">, </span>
							</span>

							<div class="small mt-2" v-if="ux.g2.type.length > 0">
								<small><a href="#" @click.prevent="ux.g2.type = []" class="text-muted">Svuota Filtro</a></small>
							</div>
							<div class="small" v-else>
								<small>Nessun <a href="#" @click.prevent="ux.g2.showFilterItem = true">Filtro</a></small>
							</div>

						</div>
					</b-form-group>

					<div v-if="ux.g2.show">
						<hr />
						<b-form-checkbox size="sm" v-model="ux.g2.hideLegend">Nascondi Legenda</b-form-checkbox>
						<b-form-checkbox size="sm" v-model="ux.g2.showTitle">Intestazione in Stampa</b-form-checkbox>
						<!-- <b-textarea class="mt-1" type="text" v-model="ux.g2.title" v-if="ux.g2.showTitle"></b-textarea> -->
						
						<b-button-group>
							<b-button size="xs" variant="outline-secondary" @click.prevent="downloadGraph($refs.graph_2, ux.g2.title, ux.g2.showTitle ? ux.g2.title : null)" class="mt-2"><em class="fa fa-file-image-o"></em></b-button>
							<b-button size="xs" variant="outline-secondary" @click.prevent="downloadGraphDati(grafico_2.rawDati, config.itemSingular + ux.g2.title)" class="mt-2"><em class="fa fa-file-excel-o"></em></b-button>
						</b-button-group>

					</div>

				</b-col>
			</b-row>
		</b-card>
		
		<a ref="button_helper"></a>
		<span style="font-family: 'ASRoma';"> </span>

	</div>
</template>

<script>

function calcLabels(date, segmentation) {

	if(!date.from) return false;

	var from = date.from.data;
	var to = date.to.data;
	var out = [];

	var chiaveConsumata = [];

	var start = from;

	while( moment(start).format('X') <= moment(to).format('X') ) {

		var chiave = { day: 'YYYY-MM-DD', week: 'YYYY-WW', month: 'YYYY-MM', year: 'YYYY' }[segmentation];
		var chiaveDaCercare = moment(start).format(chiave);
		//console.log(chiaveDaCercare, start, to);

		if(chiaveConsumata.indexOf(chiaveDaCercare) === -1) {
			chiaveConsumata.push(chiaveDaCercare);
			out.push(chiaveDaCercare);
		}

		start = moment(start).add(1, 'day').format('YYYY-MM-DD');

	}
	return out;
}

function calcFill(collezione, date, segmentation) {

	if(!date.from) return false;

	var from = date.from.data;
	var to = date.to.data;
	var outputDati = [];

	var chiaveConsumata = [];

	var start = from;
	while( moment(start).format('X') <= moment(to).format('X') ) {

		var chiave = { day: 'YYYY-MM-DD', week: 'YYYY-WW', month: 'YYYY-MM', year: 'YYYY' }[segmentation];
		var chiaveDaCercare = moment(start).format(chiave);

		if(chiaveConsumata.indexOf(chiaveDaCercare) === -1) {

			chiaveConsumata.push(chiaveDaCercare);

			var risultati = _.filter(collezione, function(a) {
				return a[segmentation] == chiaveDaCercare;
			});

			if(risultati.length > 0)
				outputDati.push(risultati.length);
			else
				outputDati.push(0);

		}
		start = moment(start).add(1, 'day').format('YYYY-MM-DD');
	}	
	return outputDati;
}

import csvDownload from 'json-to-csv-export'

var Auth = require('../../../auth.js');
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
export default {
	props: [ 'area', 'dataset', 'config', 'isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget' ],
	data: function(){
		return {
			//selected: null,
			//i: 0,
			ux: {
				generic: {
					aggregate: false,
					aggregateMode: 'mean',
					aggreateValue: 2
				},
				g1: this.configWidget && this.configWidget[this.area + '_grafico_1'] ? this.configWidget[this.area + '_grafico_1'] : { 
					aggregate: false,
					aggregateMode: 'mean',
					aggreateValue: 2,
					viewDone: true,
					segmentation: 'day',
					configFatti: 'bar-stacked',
					configProgrammati: 'line',
					hideLegend: this.isWidget && !this.isFullscreen ? true : false,
					showTitle: true,
					title: '',
					type: [],
					player: [],
					showFilterItem: false,
					showFilterPlayer: false
				},
				g2: {
					aggregate: false,
					aggregateMode: 'mean',
					aggreateValue: 2,
					show: false,
					onlyDone: true,
					players: [],
					type: [],
					types: [],
					segmentation: 'day',
					configFatti: 'bar-stacked',
					configProgrammati: 'line',
					hideLegend: false,
					showTitle: true,
					title: '',
					showFilterItem: false,
					showFilterPlayer: true
				},
				hideDisabled: false,
				sort: null,
				order: 1,
			//	to: moment().format('YYYY-MM-DD'),
			//	from: moment().add(this.getDays(), 'days').format('YYYY-MM-DD'),
			},
			graphs: {
				g1: null,
				g2: null,
				g3: null
			},
			canFilter: false,
			hidePreset: null,
			dati: this.dataset
		}
	},
	computed: {
		_: function() { return _; },
		players: function() {
			return _.keys(_.groupBy(this.dati, 'persona')).sort();
		},
		types: function() {
			if(!this.dati) return [];
			return _.keys(_.groupBy(this.dati, this.config.itemIdField));
		},
		grafico_1: function() {

			var dati = _.cloneDeep(this.dati);

			if(this.ux.g1.type.length > 0) {
				//console.log(this.ux.g1.type, this.config.itemIdField);
				dati = _.filter(dati, function(a) {
					return this.ux.g1.type.indexOf(a[this.config.itemIdField]) !== -1 ? true : false;
				}.bind(this));
			}

			if(this.ux.g1.player.length > 0) {
				dati = _.filter(dati, function(a) {
					return this.ux.g1.player.indexOf(a.persona) !== -1 ? true : false;
				}.bind(this));
			}

			var terapie = {};
			var datasets = [];

			var date = {
				from: _.minBy(dati, function(a) { return moment(a.day) }.bind(this)),
				to: _.maxBy(dati, function(a) { return moment(a.day) }.bind(this)),
			}

			if(this.ux.g1.aggregate != false && this.ux.g1.type.length == 0) {

				if(this.ux.g1.configFatti && this.ux.g1.configFatti.indexOf('stacked') !== -1)
					var tipologie_tmp = _.groupBy(_.filter(dati, { done: 1 }), this.config.itemIdField);
				else
					var tipologie_tmp = _.groupBy(dati, this.config.itemIdField);

				var tipologieNumerosita = _.mapValues(tipologie_tmp, function(a) { return a.length });
				if(this.ux.g1.aggregateMode == 'mean') {
					var total = _.sum(_.values(tipologieNumerosita))/_.values(tipologieNumerosita).length;
				} else if(this.ux.g1.aggregateMode == 'step') {
					var total = this.ux.g1.aggreateValue;
				} else if(this.ux.g1.aggregateMode == 'perc') {
					var total = this.ux.g1.aggreateValue * (_.sum(_.values(tipologieNumerosita))/100)
				}

				_.each(tipologieNumerosita, function(numerosita, nome) {

					if(numerosita < total) {
						var pl = {};
						pl[this.config.itemIdField] = nome;
						_.each(_.filter(dati, pl), function(a) {
							a[this.config.itemIdField] = 'Altro';
						}.bind(this));
					}

				}.bind(this));

			}
			
			var grouped = dati;
			var grouped_fatti = _.filter(dati, {done:1});

			var tipologie = _.groupBy(dati, this.config.itemIdField);

			_.each(tipologie, function(tipologia, k) {

				var tipologia_fatti = _.filter(tipologia, { done: 1});

				terapie[k] = { fatti: null, programmati: null };
				terapie[k].fatti = tipologia_fatti;
				terapie[k].programmati = tipologia;

			}.bind(this));

			if(terapie.Altro) {

				var altroBk = _.cloneDeep(terapie.Altro);
				delete terapie.Altro;

				var newTerapia = {};
				newTerapia.Altro = altroBk;
				_.extend(newTerapia, terapie);

				terapie = newTerapia;

			}


			if(this.ux.g1.configFatti == 'bar' || this.ux.g1.configFatti == 'line') {

				datasets.push({
					label: 'Fatte',
					data: calcFill(grouped_fatti, date, this.ux.g1.segmentation),
					type: this.ux.g1.configFatti,
					backgroundColor: 'green',
					borderColor: 'green',
					pointRadius: 8,
					pointHoverRadius: 10,
					order: this.ux.g1.configFatti == 'bar' ? 1 : -1
				})

			} else if(this.ux.g1.configFatti == 'bar-stacked' || this.ux.g1.configFatti == 'line-stacked') {

				_.each(_.mapValues(terapie, 'fatti'), function(terapia, k) {

					datasets.push({
						label: k,
						data: calcFill(terapia, date, this.ux.g1.segmentation),
						type: this.ux.g1.configFatti == 'bar-stacked' ? 'bar' : 'line',
						borderColor: this.stringToColour(k),
						backgroundColor: this.stringToColour(k),
						pointBorderColor: this.stringToColour(k),
						pointBackgroundColor: this.stringToColour(k),
						pointRadius: 8,
						pointHoverRadius: 10,
						order: this.ux.g1.configFatti == 'bar-stacked' ? 1 : -1
						
					})

				}.bind(this));

			}


			if(this.ux.g1.configProgrammati == 'bar' || this.ux.g1.configProgrammati == 'line') {

				datasets.push({
					label: 'Programmate',
					data: calcFill(grouped, date, this.ux.g1.segmentation),
					type: this.ux.g1.configProgrammati,
					backgroundColor: '#CCC',
					borderColor: '#CCC',
					pointRadius: 8,
					pointHoverRadius: 10,
					order: this.ux.g1.configProgrammati == 'bar' ? 1 : -1
				})

			} else if(this.ux.g1.configProgrammati == 'bar-stacked' || this.ux.g1.configProgrammati == 'line-stacked') {

				_.each(_.mapValues(terapie, 'programmati'), function(terapia, k) {

					datasets.push({
						label: k,
						data: calcFill(terapia, date, this.ux.g1.segmentation),
						type: this.ux.g1.configProgrammati == 'bar-stacked' ? 'bar' : 'line',
						borderColor: this.stringToColour(k),
						backgroundColor: this.stringToColour(k),
						pointBorderColor: this.stringToColour(k),
						pointBackgroundColor: this.stringToColour(k),
						pointRadius: 8,
						pointHoverRadius: 10,
						order: this.ux.g1.configProgrammati == 'bar-stacked' ? 1 : -1
					})

				}.bind(this));

			}

			var tipLabel = [];
			var doneTrack = [];
			var progTrack = [];

			var tip = {};
			_.each(_.keys(_.groupBy(dati, this.config.itemIdField)), function(b) {
				tipLabel.push(b);
				var bl = {};
				var pl = {};
				pl[this.config.itemIdField] = b;
				bl[this.config.itemIdField] = b;
				bl.done = true;

				doneTrack.push(_.filter(dati, bl).length);
				progTrack.push(_.filter(dati, pl).length);
			}.bind(this));

			return {
				labels: calcLabels(date, this.ux.g1.segmentation),
				datasets,
				legend: this.ux.g1.hideLegend,
				//title: this.ux.g1.showTitle,
				tipologie: {
					labels: tipLabel,
					datasets: [doneTrack, progTrack]
				},
				rawDati: dati
			}

		},
		grafico_2: function() {

			var dati = _.cloneDeep(this.dati);

			if(this.ux.g2.type.length > 0) {
				dati = _.filter(dati, function(a) {
					return this.ux.g2.type.indexOf(a[this.config.itemIdField]) !== -1 ? true : false;
				}.bind(this));
			}

			if(this.ux.g2.players.length > 0) {
				dati = _.filter(dati, function(a) {
					return this.ux.g2.players.indexOf(a.persona) !== -1 ? true : false;
				}.bind(this));
			}

			if(this.ux.g2.aggregate != false && this.ux.g2.type.length == 0) {

				if(this.ux.g2.configFatti.indexOf('stacked') !== -1)
					var tipologie_tmp = _.groupBy(_.filter(dati, { done: 1 }), this.config.itemIdField);
				else
					var tipologie_tmp = _.groupBy(dati, this.config.itemIdField);

				var tipologieNumerosita = _.mapValues(tipologie_tmp, function(a) { return a.length });
				if(this.ux.g2.aggregateMode == 'mean') {
					var total = _.sum(_.values(tipologieNumerosita))/_.values(tipologieNumerosita).length;
				} else if(this.ux.g2.aggregateMode == 'step') {
					var total = this.ux.g2.aggreateValue;
				} else if(this.ux.g2.aggregateMode == 'perc') {
					var total = this.ux.g2.aggreateValue * (_.sum(_.values(tipologieNumerosita))/100)
				}

				_.each(tipologieNumerosita, function(numerosita, nome) {

					if(numerosita < total) {
						var pl = {};
						pl[this.config.itemIdField] = nome;
						_.each(_.filter(dati, pl), function(a) {
							a[this.config.itemIdField] = 'Altro';
						}.bind(this));
					}

				}.bind(this));

			}

			var players = this.ux.g2.players;
			var types = _.uniq(_.map(dati, this.config.itemIdField));

			var isAltro = types.indexOf('Altro');
			if(isAltro !== -1) {
				types.splice(isAltro, 1);
				types.unshift('Altro');
			}

			//console.log(types, '!!!');

			var datasets = [];

			var programmati = [];
			var fatti = [];

			var datasetByTypeProgrammate = [];
			var datasetByTypeFatte = [];

			_.each(players, function(persona, i) {

				datasetByTypeProgrammate.push([]);
				datasetByTypeFatte.push([]);
				
				var programmate = _.filter(dati, { persona });
				var fatte = _.filter(programmate, { done: 1 });
				var programmati_type = _.groupBy(programmate, this.config.itemIdField);
				var fatte_type = _.groupBy(fatte, this.config.itemIdField);

				programmati.push(programmate.length);
				fatti.push(fatte.length);

				_.each(types, function(a) {
					datasetByTypeProgrammate[i].push(programmati_type[a] ? programmati_type[a].length : 0);
					datasetByTypeFatte[i].push(fatte_type[a] ? fatte_type[a].length : 0);
				});

			}.bind(this));

			var datasets = [];


			if(this.ux.g2.configFatti == 'bar' || this.ux.g2.configFatti == 'line') {
				datasets.push({
					label: 'Fatti',
					type: this.ux.g2.configFatti,
					backgroundColor: 'green',
					borderColor: 'green',
					data: fatti,
					showLine: false,
					pointRadius: 10,
					pointHoverRadius: 15,
				});
			}

			if(this.ux.g2.configProgrammati == 'bar' || this.ux.g2.configProgrammati == 'line') {
				datasets.push({
					label: 'Programmati',
					backgroundColor: '#CCC',
					borderColor: '#CCC',
					type: this.ux.g2.configProgrammati,
					data: programmati,
					showLine: false,
					pointRadius: 10,
					pointHoverRadius: 15,
				});
			}


			if(this.ux.g2.configFatti == 'bar-stacked' || this.ux.g2.configFatti == 'line-stacked') {
				
				_.each(types, function(b, k) {

					datasets.push({
						label: b,
						type: this.ux.g2.configFatti.replace('-stacked', ''),
						data: _.map(datasetByTypeFatte, k),
						borderColor: this.stringToColour(b),
						backgroundColor: this.stringToColour(b),
						pointBorderColor: this.stringToColour(b),
						pointBackgroundColor: this.stringToColour(b),
						showLine: false,
						pointRadius: 10,
						pointHoverRadius: 15,
					});

				}.bind(this));

			}

			if(this.ux.g2.configProgrammati == 'bar-stacked' || this.ux.g2.configProgrammati == 'line-stacked') {

				_.each(types, function(b, k) {

					datasets.push({
						label: b,
						type: this.ux.g2.configProgrammati.replace('-stacked', ''),
						data: _.map(datasetByTypeProgrammate, k),
						borderColor: this.stringToColour(b),
						backgroundColor: this.stringToColour(b),
						pointBorderColor: this.stringToColour(b),
						pointBackgroundColor: this.stringToColour(b),
						showLine: false,
						pointRadius: 10,
						pointHoverRadius: 15,
					});

				}.bind(this));

			}


			return { 
				labels: players,
				datasets,
				legend: this.ux.g2.hideLegend,
				//title: this.ux.g2.showTitle,
				rawDati: dati
			};

		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		'ux.generic.aggregateMode': function() {
			if(this.ux.generic.aggregateMode == null) {
				this.ux.generic.aggregate = false;
			} else 
			this.ux.generic.aggregate = true;
		},
		'ux.g1': {
			deep: true,
			handler: function() {
				if(this.isWidget) {
					this.$emit('updateConfigWidget', this.isWidget, this.area + '_grafico_1', this.ux.g1);
				}
			}
		},
		'ux.g1.type': function() {
			if(this.ux.g1.type.length == 0) return false;

			if(_.filter(this.ux.g1.type).length == 0) {
				this.ux.g1.type = [];
				this.ux.g1.showFilterItem = false;
			}
		},
		'ux.g1.player': function() {
			if(this.ux.g1.player.length == 0) return false;

			if(_.filter(this.ux.g1.player).length == 0) {
				this.ux.g1.player = [];
				this.ux.g1.showFilterPlayer = false;
			}
		},
		'ux.g2.type': function() {
			if(this.ux.g2.type.length == 0) return false;

			if(_.filter(this.ux.g2.type).length == 0) {
				this.ux.g2.type = [];
				this.ux.g2.showFilterItem = false;
			}
		},
		'ux.g2.players': function() {
			if(this.ux.g2.players.length == 0)
				this.ux.g2.show = false;
		},
		'ux.g1.configFatti': function() {

			if(this.ux.g1.configProgrammati && this.ux.g1.configFatti.indexOf('bar') != -1 && this.ux.g1.configProgrammati.indexOf('bar') != -1) {
				this.ux.g1.configProgrammati = 'line';
			}

			if(this.ux.g1.configProgrammati && this.ux.g1.configFatti.indexOf('line') != -1 && this.ux.g1.configProgrammati.indexOf('line') != -1) {
				this.ux.g1.configProgrammati = 'bar';
			}

		},
		'ux.g1.configProgrammati': function() {
			if(this.ux.g1.configFatti && this.ux.g1.configProgrammati && this.ux.g1.configFatti.indexOf('bar') != -1 && this.ux.g1.configProgrammati.indexOf('bar') != -1) {
				this.ux.g1.configFatti = 'line';
			}

			if(this.ux.g1.configFatti && this.ux.g1.configProgrammati && this.ux.g1.configFatti.indexOf('line') != -1 && this.ux.g1.configProgrammati.indexOf('line') != -1) {
				this.ux.g1.configFatti = 'bar';
			}
		},
		'ux.g2.configFatti': function() {

			if(this.ux.g2.configFatti.indexOf('bar') != -1 && this.ux.g2.configProgrammati.indexOf('bar') != -1) {
				this.ux.g2.configProgrammati = 'line';
			}

			if(this.ux.g2.configFatti.indexOf('line') != -1 && this.ux.g2.configProgrammati.indexOf('line') != -1) {
				this.ux.g2.configProgrammati = 'bar';
			}

		},
		'ux.g2.configProgrammati': function() {
			if(this.ux.g2.configFatti.indexOf('bar') != -1 && this.ux.g2.configProgrammati.indexOf('bar') != -1) {
				this.ux.g2.configFatti = 'line';
			}

			if(this.ux.g2.configFatti.indexOf('line') != -1 && this.ux.g2.configProgrammati.indexOf('line') != -1) {
				this.ux.g2.configFatti = 'bar';
			}
		},
		'grafico_1': function() {
			this.createGraph_One();
		},
		'grafico_2': function() {
			this.createGraph_Two();
		},
		contentHeight() {
			_.delay(function() {
				this.createGraph_One();
			}.bind(this), 200);
		},  
		dati: {
			immediate: true,
			handler: function() {
				if(this.dati.length > 0) {
					_.delay(function() {
						this.createGraph_One();
					}.bind(this), 200);
				}
			}
		},
		
	},
	filters: {
		data: function(i, format) {
			return moment(i).format(format || 'D/M/YY HH:mm')
		}
	},
	methods: {
		map: function(n, start1, stop1, start2, stop2) {
			return ((n-start1)/(stop1-start1))*(stop2-start2)+start2;
		},
		downloadGraphDati: function(coll, title) {

			var dataToConvert = {
				data: coll,
				filename: _.camelCase(title),
				delimiter: ',',
				headers: _.keys(_.first(coll))
			}

			return csvDownload(dataToConvert);

		},
		downloadGraph: function(canvas, nomeFile, title = null) {

			if(title != null && title != false) {

				var destCtx = this.$refs.outputImage.getContext('2d');

				var img = new Image();
				img.onload = function() {
					destCtx.drawImage(img, 0, 0);

					destCtx.font = "28px 'ASRoma'";
					destCtx.textAlign = "right";

					//var lines = _.split(title, ' ', 100);
					var lines = title.match(/.{1,120}/g);

					// 55 -> 1 riga

					var startingPoint = 15 + ((100 - ( 30 * lines.length) )/2);

					_.each(lines, function(line, k) {
						destCtx.fillText(line, canvas.width - 20, startingPoint + (30*k));
					});


					_.delay(function() {

						this.$refs.button_helper.href = this.$refs.outputImage.toDataURL("image/png");
						this.$refs.button_helper.download = _.camelCase(nomeFile) + ".png";
						this.$refs.button_helper.click();

					}.bind(this), 200);

				}.bind(this);

				img.src = "assets/images/logo.svg";


				this.$refs.outputImage.width = canvas.width;
				this.$refs.outputImage.height = canvas.height + 100;
				//console.log(canvas.width, canvas.height)
				//call its drawImage() function passing it the source canvas directly
				destCtx.drawImage(canvas, 0, 100);

			} else {

				this.$refs.button_helper.href = canvas.toDataURL("image/png");
				this.$refs.button_helper.download = _.camelCase(nomeFile) + ".png";
				this.$refs.button_helper.click();

			}


		},
		toggleAllPlayersG2: function() {
			if(this.ux.g2.players.length == this.players.length) {
				this.ux.g2.players = [];
			} else {
				this.ux.g2.players = _.cloneDeep(this.players);
			}
		},
		hex2hls: function(hex) {

			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			var r = parseInt(result[1], 16);
			var g = parseInt(result[2], 16);
			var b = parseInt(result[3], 16);

			r /= 255, g /= 255, b /= 255;
			var max = Math.max(r, g, b), min = Math.min(r, g, b);
			var h, s, l = (max + min) / 2;
			if(max == min){
				h = s = 0; // achromatic
			}else{
				var d = max - min;
				s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
				switch(max){
				case r: h = (g - b) / d + (g < b ? 6 : 0); break;
				case g: h = (b - r) / d + 2; break;
				case b: h = (r - g) / d + 4; break;
				}
				h /= 6;
			}

			//h = this.map(h, 0, 1, 0.2, 0.8);
			l = this.map(l, 0, 1, 0, 1);
			s = this.map(s, 0, 1, 0, 0.8);


			if (s === 0) {
		  		r = g = b = l; // achromatic
		  	} else {
		  		var hue2rgb = function(p, q, t) {
		  			if (t < 0) t += 1;
		  			if (t > 1) t -= 1;
		  			if (t < 1 / 6) return p + (q - p) * 6 * t;
		  			if (t < 1 / 2) return q;
		  			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
		  			return p;
		  		};
		  		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		  		const p = 2 * l - q;
		  		r = hue2rgb(p, q, h + 1 / 3);
		  		g = hue2rgb(p, q, h);
		  		b = hue2rgb(p, q, h - 1 / 3);
		  	}

		  	var toHex = function(x) {
		  		const hex = Math.round(x * 255).toString(16);
		  		return hex.length === 1 ? '0' + hex : hex;
		  	};

		  	return `#${toHex(r)}${toHex(g)}${toHex(b)}`;

		  },
		  stringToColour: function(str) {
		  	var hash = 0;
		  	for (var i = 0; i < str.length; i++) {
		  		hash = str.charCodeAt(i) + ((hash << 5) - hash);
		  	}
		  	var colour = '#';
		  	for (var i = 0; i < 3; i++) {
		  		var value = (hash >> (i * 8)) & 0xFF;
		  		colour += ('00' + value.toString(16)).substr(-2);
		  	}

		  	return this.hex2hls(colour);
		  },
		  createGraph_One: function() {

		  	if(this.graphs.g1 != null) {
		  		this.graphs.g1.destroy();	
		  	}

		  	if(this.graphs.g3 != null) {
		  		this.graphs.g3.destroy();	
		  	}

		  	var ctx = this.$refs.graph_1.getContext('2d');


		  	var title = [];

		  	if(this.ux.g1.player.length > 0) {
		  		if(this.ux.g1.player.length > 8)
		  			title.push(this.ux.g1.player.length + ' Giocatori');
		  		else
		  			title.push(this.ux.g1.player.join(', '));
		  	} else
		  	title.push('Squadra');

		  	if(this.ux.g1.type.length > 0) {
		  		if(this.ux.g1.type.length > 8)
		  			title.push(this.ux.g1.type.length + ' Terapie');
		  		else
		  			title.push(this.ux.g1.type.join(', '));
		  	}

		  	this.ux.g1.title = title.join(' - ');

		  	this.graphs.g1 = new Chart(ctx, {
		  		type: 'bar',
		  		data: this.grafico_1,
		  		options: {
		  			plugins: {
		  				legend: {
		  					display: this.ux.g1.hideLegend ? false : true,
		  					position: 'bottom'
		  				},
		  				title: {
		  					display: false,
		  					text: this.ux.g1.title,
		  					font: {
		  						size: 16,
		  						family: 'ASRoma'
		  					}
		  				}
		  			},
		  			responsive: true,
		  			scales: {
		  				x: {
		  					position: 'top',
		  					stacked: true,
		  				},
		  				y: {
		  					stacked: true
		  				}
		  			}
		  		}
		  	});
		  },
		  createGraph_Two: function() {

		  	if(this.graphs.g2 != null) {
		  		this.graphs.g2.destroy();	
		  	}

		  	var title = [];

		//  title.push('('+ moment(this.ux.from).format('DD/MM/YYYY') +' - '+ moment(this.ux.to).format('DD/MM/YYYY') +')')

		  	if(this.ux.g2.type.length > 0)
		  		title.push(this.ux.g2.type.join(', '));

		  	this.ux.g2.title = title.join(' - ');

		  	var ctx = this.$refs.graph_2.getContext('2d');
		  	this.graphs.g2 = new Chart(ctx, {
		  		type: 'bar',
		  		data: this.grafico_2,
		  		options: {
		  			plugins: {
		  				title: {
							//display: this.ux.g2.showTitle,
		  					text: this.ux.g2.title,
		  					font: {
		  						size: 16,
		  						family: 'ASRoma'
		  					}
		  				},
		  				legend: {
		  					display: this.ux.g2.hideLegend ? false : true,
		  					position: 'bottom'
		  				},
		  			},
		  			responsive: true,
		  			scales: {
		  				x: {
		  					position: 'top',
		  					stacked: true,
		  				},
		  				y: {
		  					stacked: true
		  				}
		  			}
		  		}
		  	});

			//this.graphs.g1.options.plugins.legend.position = 'bottom';

		  },
		},
	}

	</script>