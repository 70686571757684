<template>
	<div>
		<SingolaValutazione v-for="(player, index) in theFormazione" :player="player" :index="index" :form="form" :gradimenti="gradimenti" :ruoli="ruoli" :lingua="lingua"></SingolaValutazione>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	components: {
		SingolaValutazione: require('@/components/layout/nextscouting/edit-valutazione-singola.vue').default,
	},
	data: function() {
		return {
			form: {},
			ruoli_map: [
				{ id: 3, ws_roles: ['gk']}, /* po - portiere */
				{ id: 4, ws_roles: ['cb']}, /* dc - difensore centrale */
				{ id: 5, ws_roles: ['rcb', 'rcb3']}, /* dcd - difensore centrale destro */
				{ id: 6, ws_roles: ['lcb', 'lcb3']}, /* dcs - difensore centrale sinistro */
				{ id: 9, ws_roles: ['rb', 'rb5']}, /* td - terzino destro */
				{ id: 10, ws_roles: ['lb', 'lb5']}, /* ts - terzino sinistro */
				{ id: 16, ws_roles: ['dmf']}, /* cc - centocampista centrale */
				{ id: 17, ws_roles: ['rcmf', 'rcmf3', 'rdmf']}, /* ccd - centocampista centrale destro */
				{ id: 18, ws_roles: ['lcmf', 'lcmf3', 'ldmf']}, /* ccs - centrocampista centrale sinistro */
				{ id: 19, ws_roles: ['rw', 'ramf', 'rwb']}, /* ed - esterno destro */
				{ id: 20, ws_roles: ['lw', 'lamf', 'lwb']}, /* es - esterno sinistro */	
				{ id: 24, ws_roles: ['rwf']}, /* ad - attaccante destro */
				{ id: 25, ws_roles: ['lwf']}, /* as - attaccante sinistro */
				{ id: 26, ws_roles: ['cf', 'amf']}, /* ac - attaccante centrale */
				{ id: 27, ws_roles: ['ss']}, /* pc - punta centrale */
				],
		}
	},
	props: [ 'formazione', 'valutazioni', 'home', 'away', 'ruoli', 'gradimenti', 'lingua' ],
	watch: {
		form: {
			deep: true,
			handler: function() {
				this.$emit('change');
			}
		},
		'valutazioni': {
			immediate: true,
			handler: function() {

			//	console.log('Ricostruisco il form');
				var form = {};

				_.each(this.formazione, function(player, i) {

					var valutazione = _.find(this.valutazioni, { persona_id: player.persona_id, utente_id: this.auth.user.id });

					var val = {
						id_valutazione: null,
						id_gradimento: null,
						note: null,
						current: null,
						potential: null,
						id_posizione: (player.id_ruolo && i < 11) ? player.id_ruolo : this.posizione(player.wyId, true),
						id_posizione2: null,
						id_formazione: player.id_formazione,
						altezza: player.height,
						struttura: null,
						muscolatura: null,
						velocita: null,
						resistenza: null,
						piede: this.piede(player.foot),
						tecnica: null,
						home: this.home == true ? 1 : 0,
						tattica: null,
						capacita_offensive: null,
						capacita_difensive: null,
						conferma: null,
						confermato: null,
						lingua: { note_1: {} }
					};

					if(this.$route.meta.currentAndPotential == false) {
						delete val.current;
						delete val.potential;
					}


					if(valutazione) {

						val.id_valutazione = valutazione.id;
						val.id_gradimento = valutazione.id_gradimento;
						val.note = valutazione.note;

						if(this.$route.meta.currentAndPotential) {
							val.current = valutazione.current;
							val.potential = valutazione.potential;
						}


						if(valutazione.breve) {
							val.id_breve = valutazione.breve.id;
							val.id_posizione = valutazione.breve.id_posizione ? valutazione.breve.id_posizione : ((player.id_ruolo && i < 11) ?  player.id_ruolo : this.posizione(player.wyId, true));
							val.id_posizione2 = valutazione.breve.id_posizione2;
							val.altezza = valutazione.breve.altezza ? valutazione.breve.altezza : player.height;
							val.struttura = valutazione.breve.struttura;
							val.muscolatura = valutazione.breve.muscolatura;
							val.velocita = valutazione.breve.velocita;
							val.resistenza = valutazione.breve.resistenza;
							val.piede = valutazione.breve.piede ? valutazione.breve.piede : this.piede(player.foot);
							val.tecnica = valutazione.breve.tecnica;
							val.tattica = valutazione.breve.tattica;
							val.capacita_offensive = valutazione.breve.capacita_offensive;
							val.capacita_difensive = valutazione.breve.capacita_difensive; 
						}
						if(valutazione.lingua) {
							val.lingua = valutazione.lingua;
						}

					}

					this.$set(form, player.wyId, val);

				}.bind(this));

				this.form = form;
				
			}
		}
	},
	methods: {
		importVal: function() {

			_.each(this.formazione, function(player) {

				if(player.last_val_breve) {
					if(this.form[player.wyId] && !this.form[player.wyId].id_valutazione) {
						this.form[player.wyId].conferma = true;
						this.form[player.wyId].confermato = false;
						this.form[player.wyId].id_gradimento = player.last_val_breve.valutazione.id_gradimento;
						this.form[player.wyId].note = player.last_val_breve.valutazione.note;

						if(this.$route.meta.currentAndPotential) {
							this.form[player.wyId].current = player.last_val_breve.valutazione.current;
							this.form[player.wyId].potential = player.last_val_breve.valutazione.potential;
						}

						this.form[player.wyId].id_posizione = player.last_val_breve.id_posizione;
						this.form[player.wyId].id_posizione2 = player.last_val_breve.id_posizione2;
						this.form[player.wyId].altezza = player.last_val_breve.altezza;
						this.form[player.wyId].struttura = player.last_val_breve.struttura;
						this.form[player.wyId].muscolatura = player.last_val_breve.muscolatura;
						this.form[player.wyId].velocita = player.last_val_breve.velocita;
						this.form[player.wyId].resistenza = player.last_val_breve.resistenza;
						this.form[player.wyId].piede = player.last_val_breve.piede;
						this.form[player.wyId].tecnica = player.last_val_breve.tecnica;
						this.form[player.wyId].tattica = player.last_val_breve.tattica;
						this.form[player.wyId].capacita_offensive = player.last_val_breve.capacita_offensive;
						this.form[player.wyId].capacita_difensive = player.last_val_breve.capacita_difensive;

						var lingua_obj = {
							note_1: {}
						};
						for(var i in player.last_val_breve.valutazione.lingue) {
							var lingua = player.last_val_breve.valutazione.lingue[i];
							lingua_obj.note_1[lingua.lingua_glo_id] = lingua.note_1;						
						}
						this.form[player.wyId].lingua = lingua_obj; 
					}
				}

			});

		},
		piede: function (foot) {
			if(foot == "right") {        
				return "0";
			}  else if(foot == "left") {
				return "1";
			}  else if(foot == "both") {
				return "2";
			} 
			return null;          
		},
		posizione: function(playerId, isHome) {
			//console.log(playerId);
			var position_id = null;
			var formation_players = null;
			formation_players = this.formazione;
			/*if(isHome) {
			} else {
				formation_players = this.formation_away_players;
			}*/
			for(var i in formation_players) {
				var item = formation_players[i];
				var wyId = Object.keys(item)[0];
				if(wyId == playerId) {
					var position = item[Object.keys(item)[0]]['position'];
					for(var x in this.ruoli_map) {
						if(this.ruoli_map[x].ws_roles.includes(position)) {
							return this.ruoli_map[x].id;
						}
					}
				}
			}
			return position_id;
		},
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		theFormazione: function() {
			return this.formazione.sort(function(a, b) { 
				var roles_ws = ['GKP', 'DEF', 'MID', 'FWD', 'N/D'];
				var role_a = a.role ? a.role.code3 : 'N/D';
				var role_b = b.role ? b.role.code3 : 'N/D';
				return roles_ws.indexOf(role_a) - roles_ws.indexOf(role_b);
			});
		}
	}

}

</script>