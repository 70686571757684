<template id="figurinagiocatore">
  <div class="figurinagiocatore h-100 pb-2" :id="'giocatore_'+giocatore.id">
    <div class="card mb-2 h-100" :style="giocatore.carriera.tipologia == 'prova' ? 'background:#FFEB3B' : ''" :title="giocatore.carriera.tipologia == 'prova' ? 'In prova' : ''">
      <div class="row">
        <div class="col-12 text-center">
          <router-link :to="{ name: 'football_giocatore_scheda', params: { id: giocatore.id }}"><img v-bind:src="avatar" height="200"></router-link>
          <i class="fa fa-ambulance" v-if="giocatore.player.injured" title="Infortunato" style="position:absolute;top:10px;right:20px;"></i>
        </div>
      </div>
      
      <div class="card-body p-2">
        <router-link :to="{ name: 'football_giocatore_scheda', params: { id: giocatore.id }}" style="text-decoration:none;color:#000000;">
          <div class="font-bold text-center mb-1">{{ giocatore.name }}</div>
        </router-link>

        <div class="mb-2 text-center font-size-xs color-rosso">{{ giocatore.ruolo ? giocatore.ruolo : '&nbsp;' }}</div>
        
        <div class="row no-gutters text-center font-size-sm">
          <div class="col" title="Presenze partite"><i class="fa fa-fw fa-flag"></i><br> {{ giocatore.player.matches_played || 0 }}</div>
          <div class="col" title="Minuti giocati"><i class="fa fa-fw fa-arrow-up"></i><br>{{ giocatore.player.minutes_played || 0 }}</div>
          <div class="col" title="Goal"><i class="fa fa-fw fa-futbol-o"></i><br> {{ giocatore.player.goal || 0 }}</div>
          <div class="col" title="Presenze sessioni"><i class="fa fa-fw fa-plus"></i><br> {{ giocatore.player.attendances || 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {

    data: function(){
      return {
        render_player: true,
      }
    },

    props:{
        giocatore: {
            type: Object,
            required: true
        }
    },

    computed: {

    avatar: function(){
      return this.giocatore.player.avatar_url;
    }

  },

  methods: {


  }

}
</script>
