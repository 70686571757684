<template id="esercizifiltri">
  <form v-on:submit.prevent="submit()" id="form" action="#" method="POST">
    <div class="esercizifiltri">
      <div class="form-row mb-3">
        <b-input-group class="mb-2">
          <b-form-input type="text" v-model="title" placeholder="Cerca per titolo/autore..."></b-form-input>
          <b-input-group-append>
            <b-button @click="reset"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
            <b-button variant="primary" @click="submit"><i class="fa fa-search" aria-hidden="true"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-row mb-3">
        <label class="color-rosso font-bold text-uppercase">Facility</label>
        <b-form-select v-model="facility">
          <option :value="0">-- seleziona --</option>
          <option :value="item.id" v-for="item in facility_opt" :key="item.id">{{ item.name }}</option> 
        </b-form-select>
      </div>
      <div class="form-row mb-3">
        <label class="color-rosso font-bold text-uppercase">Goal</label>
        <b-form-select v-model="goal">
          <option :value="0">-- seleziona --</option>
          <option :value="item.id" v-for="item in goal_opt" :key="item.id">{{ item.name }}</option> 
        </b-form-select>
      </div>
      <div class="form-row mb-3">
        <label class="color-rosso font-bold text-uppercase">Tt</label>
        <b-form-select v-model="tt">
          <option :value="0">-- seleziona --</option>
          <option :value="item.id" v-for="item in tt_opt" :key="item.id">{{ item.name }}</option> 
        </b-form-select>
      </div>
      <div class="form-row mb-3">
        <label class="color-rosso font-bold text-uppercase">Intensity</label>
        <b-form-select v-model="intensity">
          <option :value="0">-- seleziona --</option>
          <option :value="item.id" v-for="item in intensity_opt" :key="item.id">{{ item.name }}</option> 
        </b-form-select>
      </div>
 </div>
</form>
</template>

<script>

import axios from 'axios';
import { mapState } from 'vuex';

export default {
  data: function(){
    return {
      facility_opt: [],
      goal_opt: [],
      tt_opt: [],
      intensity_opt: [],
    }
  },
  created() {
  },
  mounted() {

   axios.get(`/palestra/opzioni/esercizi`)
   .then((res) => {
    this.facility_opt = res.data.facility;
    this.goal_opt = res.data.goal;
    this.tt_opt = res.data.tt;
    this.intensity_opt = res.data.intensity;
  }).catch((err) => {
  });
},
computed:{
 title: {
   get() {
     return this.$store.state.palestra.title;
   },

   set(value) {
     this.$store.commit('palestra/setTitle', value);
   },
 },
 facility: {
   get() {
     return this.$store.state.palestra.filters.facility;
   },

   set(value) {
     this.$store.commit('palestra/setFacility', value);
   },
 },
 goal: {
   get() {
     return this.$store.state.palestra.filters.goal;
   },

   set(value) {
     this.$store.commit('palestra/setGoal', value);
   },
 },
 tt: {
   get() {
     return this.$store.state.palestra.filters.tt;
   },

   set(value) {
     this.$store.commit('palestra/setTt', value);
   },
 },
 intensity: {
   get() {
     return this.$store.state.palestra.filters.intensity;
   },

   set(value) {
     this.$store.commit('palestra/setIntensity', value);
   },
 },
 ...mapState('palestra', ['exercises_tab']),
 ...mapState(['rosaTeamId']),
},
watch: {
  facility() {
    this.submit();
  },
  goal() {
    this.submit();
  },
  tt() {
    this.submit();
  },
  intensity() {
    this.submit();
  },  
},
methods: {
  submit() {
    this.$store.commit('palestra/setPage', 1);
    if(this.exercises_tab == 0) {
      this.$store.dispatch('palestra/filter');
    } else if(this.exercises_tab == 1) {
      this.$store.dispatch('palestra/filter_archivio');
    } else if(this.exercises_tab == 2) {
      this.$store.dispatch('palestra/filter_condivisi');
    } 
    $('html,body').animate({ scrollTop: 0 }, 'slow');
  },
  reset() {
    this.title = '';
    this.submit();
  },
}

}
</script>
