<template>
	<b-card>
		<b-table-simple small striped bordered style="font-size:0.9rem">
			<thead>
				<tr>
					<th title="Numero">Numero</th>
					<th title="Player">Player</th>
					<th title="Altezza">Altezza</th>
					<th title="Piede">Piede</th>
					<th title="Data di nascita">Data di nascita</th>
					<th title="Minutes">Minutes</th>
					<th title="Goals">Goals</th>
					<th title="Ruolo di reparto">Ruolo di reparto</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="player in players">
					<td>{{ player.shirt }}</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.height }}</td>
					<td>{{ player.foot | formatPiede }}</td>
					<td>{{ player.birthDate | formatDate }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.goals }}</td>
					<td>{{ player.genericPosition }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['players'],
	data: function() {
		return {

		}
	},
	components: {
	},
	methods: {
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY') + ' ('+ moment().diff(date, 'years') +')'; 
			}
			return "";
		},
		formatPiede: function (foot) {
			var piede = '';
			if(foot == 'right') {
				piede = 'DESTRO';
			} else if(foot == 'left') {
				piede = 'SINISTRO';
			} else if(foot == 'both') {
				piede = 'AMBIDESTRO';
			} 
			return piede;
		}
	},
}
</script>
