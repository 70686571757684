<template>
	<div v-if="stage">

		<h5 v-if="stage.nextMatch">{{stage.nextMatch.name}}</h5>
		<b-row class="mt-2" v-if="stage.nextMatch">
			<b-col v-for="col in nextMatches">
				<b-list-group>
					<b-list-group-item v-for="event in col">
						{{ event.home.name }} 
						<span class="small text-muted" v-if="event.status !== '0'">({{event.home_result}} - {{event.away_result}})</span>
						<span class="small text-muted" v-else> - </span>
						{{ event.away.name }}

						<span class="float-right small">{{event.date | date('DD/MM HH:mm')}}</span>
					</b-list-group-item>
				</b-list-group>
				<!-- <pre>{{col}}</pre> -->
			</b-col>
		</b-row>

		<b-row class="mt-3">
			<b-col>
				<h5>Teams</h5>
				<hr />
				<b-list-group v-if="stage.teams">
					<b-list-group-item  :to="{ name: 'CompetitionStageTeam', params: { team_id: team.id } }" v-for="team in stage.teams">
						{{ team.name }}
					</b-list-group-item>
				</b-list-group>
				<div class="mt-2" v-if="auth.isAdmin()">
					<b-button block variant="outline-primary" size="sm" :to="{ name: 'CompetitionStageNewTeam', params: { stage_id: stage.id } }">Nuova Squadra</b-button>
				</div>
			</b-col>
			<b-col>
				<h5>Matches</h5>
				<hr />
				<b-list-group v-if="stage.matches">
					<b-list-group-item  :to="{ name: 'CompetitionStageMatch', params: { stage_id: stage.id, match_id: match.id }}" v-for="match in stage.matches">
						{{ match.name }}
						<b-badge class="float-right" :variant="match.status == 3 ? 'success' : 'secondary'">{{ {0: 'Pianificata', 1: 'Live', 2: 'Conclusa', 3: 'Giocata' }[match.status] }}</b-badge>
					</b-list-group-item>
				</b-list-group>
				<div class="mt-2">
					<b-button block variant="outline-primary" size="sm" :to="{ name: 'CompetitionStageNewMatch', params: { stage_id: stage.id } }">Nuova Partita</b-button>
				</div>
			</b-col>
			<b-col>

				<h5>Standing</h5>
				<hr />
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th>Team</b-th>
							<b-th></b-th>
							<b-th>V</b-th>
							<b-th>P</b-th>
							<b-th>S</b-th>
							<b-th>GF</b-th>
							<b-th>GS</b-th>
							<b-th>DR</b-th>
							<b-th>PT</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="team in stage.rank">
							<b-td>{{ team.team.name }}</b-td>
							<b-td>{{ team.played }}</b-td>
							<b-td>{{ team.vittoria_home + team.vittoria_away || '' }}</b-td>
							<b-td>{{ team.pareggio_home + team.pareggio_away || '' }}</b-td>
							<b-td>{{ team.sconfitta_home + team.sconfitta_away || '' }}</b-td>
							<b-td>{{ team.gol_home + team.gol_away || '' }}</b-td>
							<b-td>{{ team.golsubiti_home + team.golsubiti_away || '' }}</b-td>
							<b-td>{{ (team.gol_home + team.gol_away) - (team.golsubiti_home + team.golsubiti_away) || '' }}</b-td>
							<b-td>{{ team.pts }}</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</b-col>
		</b-row>


		<div v-if="stage.feeder_api && auth.isAdmin()">
			<a :href="$store.state.endpointRedazione + 'import/' + competition.id + '/stage/' + stage.id" target="_blank">Reimporta Stage</a>
		</div>
	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: [ 'competition', 'stage' ],
	computed: {
		auth: function() {
			return Auth;
		},
		nextMatches: function() {
			return _.chunk(this.stage.nextMatch.events, Math.ceil(this.stage.nextMatch.events.length/3))
		}
	}
}

</script>