<template id="menusub">
	<div class="menusub" v-if="items.length">
		<div class="card rounded-0 border-top-0 border-left-0 border-right-0">
      <div class="card-body">
  			<ul class="list-inline m-0">
  				<li v-for="item in items" class="list-inline-item">

  					<router-link :to="item.to">
  						<span>
                
  							{{ $i18n.t(item.content) }}

  						</span>
  					</router-link>

  				</li>
  			</ul>
      </div>
		</div>
	</div>
</template>

<script>
var Auth = require('../../auth.js');
export default {

    data: function() {
        return {
            items: [],
        }
    },

    methods: {

        update()
        {
            this.items = [];
            if(this.$route.meta.menusub) {
                for (var i in this.$router.options.routes)
                {
                    var subroute = this.$router.options.routes[i];

                    if (this.$route.meta.menusub.indexOf(subroute.name) > -1)
                    {
                        if(subroute.meta.permesso && !Auth.isUserEnable(subroute.meta.permesso)) {
                            continue;
                        }
                        this.items.push({ to: subroute.path.replace(':id', this.$route.params.id).replace(':val_id', this.$route.params.val_id).replace(':team_id', this.$route.params.team_id).replace(':category_id', this.$route.params.category_id).replace(':tipo', this.$route.params.tipo), content: subroute.meta.label, active: false });
                    }
                }
            } else {
                var parent_name = this.$route.name;

                for (var i in this.$router.options.routes)
                {
                    var subroute = this.$router.options.routes[i];

                    if (subroute.meta && (subroute.meta.parent == parent_name) && subroute.meta.showInMenu)
                    {
                      if(subroute.meta.permesso && !Auth.isUserEnable(subroute.meta.permesso)) {
                            continue;
                      }
                      this.items.push({ to: subroute.path.replace(':id', this.$route.params.id).replace(':val_id', this.$route.params.val_id).replace(':team_id', this.$route.params.team_id).replace(':category_id', this.$route.params.category_id).replace(':tipo', this.$route.params.tipo), content: subroute.meta.label, active: false });
                    }
                }
            }
        },
    },

    created: function () {
        this.update();
    },

    watch: {
        '$route': function (newValues) {
            this.update();
        },
    },
}
</script>
