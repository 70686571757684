<template id="squadre">
	<section class="squadre">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Squadre ({{ total }})</span>
					</div>
					<div class="content">
						<div class="mb-1">
							<b-btn v-b-toggle.collapse variant="primary">Filtra <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
							<b-collapse id="collapse" class="mt-2">
								<b-row class="mb-1">
									<b-col sm="2">
										<b-form-select v-model="filters.categoria">
											<option value="0">-- Categoria --</option>
											<option v-for="item in categorie" :value="item.id">{{item.name}}</option>
										</b-form-select>
									</b-col>
									<b-col sm="3">
										<b-input-group>
											<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
											<b-input-group-append>
												<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>  	
									</b-col>	
								</b-row>
							</b-collapse>
						</div>
						<div v-if="vista == 'griglia'">	
							<v-infinite-scroll :loading="loading" @bottom="nextPage" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
								<div v-for="squadra in squadre" class="col-2">
									<cardsquadra :squadra="squadra" :modulo="'usascout'"></cardsquadra>
								</div>
								<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
							</v-infinite-scroll>

						</div>
					</div>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

import InfiniteScroll from 'v-infinite-scroll'

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		cardsquadra: 	require('../../layout/cardsquadra.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},
	data: function(){
		return {
			vista: 'griglia',
			squadre: [],
			categorie: [],
			page: 1,
			total: 0,
			loading: false,
			filters: {
				search_name: '',
				categoria: 0,
			},
		}
	},
	created: function () {
		this.loading = true;
		this.$http.get(this.$store.state.apiEndPoint + '/usascout/squadre').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.squadre = response.data.data; 
            	this.total = response.data.total;
            	this.loading = false;
            }
        }, (response) => {
            // error callback
        }); 
        this.$http.get(this.$store.state.apiEndPoint + '/exercise/categories').then((response) => {
			if (response.ok)
			{
				this.categorie = response.data; 
			}
		}, (response) => {
            // error callback
        });    
	},
	methods: {
		nextPage() {
			if(this.squadre.length < this.total) {
				this.page++;
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/usascout/squadre?page=' + this.page, {params: this.filters}).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.squadre = this.squadre.concat(response.data.data);
	            	this.loading = false;
	            }
	        }, (response) => {
	            	// error callback
	            });
			}
		},
		filtraArchivio() {
			this.loading = true;
			this.squadre = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/usascout/squadre', {params: this.filters}).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.squadre = response.data.data;
            	this.total = response.data.total;
            	this.loading = false; 
            }
        }, (response) => {
            // error callback
        });
		},
	},
	watch: {
		'filters.categoria': function () {
			this.filtraArchivio();
		},
	},

}
</script>
