<template id="langswitch">
    <div class="langswitch">

        <ul class="list-inline text-center">
            <li class="list-inline-item" v-for="c in countries">
                
                <div class="flag d-inline-block" v-on:click="setLang(c)">
                    <flag :country="c" :size="size"></flag>
                </div>

            </li>
        </ul>

    </div>
</template>

<script>
export default {
    data: function(){
        return {
            countries: [ 'it', 'en' ]
        }
    },

    components: {
        flag: require('./flag.vue').default,
    },

    methods: {

/*
        setLang: function(lang){
            console.log(lang);
            Vue.config.lang = lang;
        }*/
        setLang: function(lang){
            this.$store.dispatch('setLang', lang)
        },

    },

    props: {

        size: {
            type: Number,
            default: 24
        }

    }

}
</script>
