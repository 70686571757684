<template>
	<div v-if="sessione_tipi">
		<form @submit.stop.prevent="handleSubmit">
			<b-row class="mb-1">
				<b-col>
					<b-row class="mb-2">
						<b-col>
							<label>	Nome </label>
							<b-form-input v-model="model.nome" type="text"></b-form-input>
						</b-col>
						<b-col>
							<label>	Giocatori </label>
							<multiselect v-model="persone" :options="player_options" :multiple="true" placeholder="Seleziona" label="name" track-by="id" group-values="items" group-label="label" :group-select="true"></multiselect>
						</b-col>
					</b-row>
					<b-row class="mb-2">
						<b-col>
							<label>Dalle ore</label>
							<b-form-input v-model="from_hour" type="time"></b-form-input>
						</b-col>
						<b-col>

							<label>Alle ore</label>
							<b-form-input v-model="to_hour" type="time"></b-form-input>
						</b-col>
					</b-row>
					<b-row class="mb-1">
						<b-col>
							<b-form-radio-group id="level" v-model="model.sessione_tipo_glo_id" name="radioLevel" :disabled="entityPayload.id != null">
								<b-form-radio v-for="tipo in sessione_tipi" v-if="tipo.gruppo == 'Medical'" :value="tipo.id">{{ tipo.nome }}</b-form-radio>
							</b-form-radio-group>
						</b-col>
					</b-row>
					<b-row class="mt-5">
						<b-col>
							<b-button-group>
								<b-button class="mr-0" variant="outline-primary" @click="handleOk($event, 'close')">Salva e chiudi</b-button>
								<b-button class="mr-0" variant="outline-primary" @click="handleOk($event, 'edit')">Salva e Personalizza</b-button>
								<b-button v-if="entityPayload.id && auth.isLastSeason(entityPayload.stagione_id)" @click="eliminaSessione" variant="outline-primary">Elimina</b-button>
							</b-button-group>
						</b-col>
						<b-col v-if="entityPayload.id" class="text-right">
							<b-button-group>
							<!--b-button v-show="entityPayload.id" :to="{ path: `/football/allenamento/${this.entityPayload.id}/presenze` }" variant="outline-primary">Presenze</b-button-->
								<b-button class="mr-0" variant="outline-primary" @click="$emit('activityCheck', entityPayload.id)">{{hasSetup(entity, 'matrixLabel', 'Activity Check')}}</b-button>
								<b-button class="mr-0" v-show="entityPayload.id" :to="{ path: `/medical/sessione/${this.entityPayload.id}/${this.entityPayload.sessione_tipo_glo_id}` }" variant="outline-primary">Personalizza</b-button>
							</b-button-group>
							<!--b-button variant="outline-primary" v-if="entityPayload.id" @click="downloadPdf">Stampa pdf</b-button-->
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</form>
		<!--
		<pre>{{entityPayload}}</pre>
		<pre>{{sessioneTipoCorrente}}</pre>
		-->

	</div>
</template>

<script>

import sessionMixin from '@/mixins/sessione.js';
import _ from 'lodash'
import moment from 'moment'
var Auth = require('../../../auth.js');
import * as types from '@/store/types'
import { mapState } from 'vuex';

export default {
	props: [ 'entity', 'player_options', 'team', 'date' ],
	mixins: [ sessionMixin ],
	created: function() {
		this.getTipiSessione();
	},
	computed: {
		sessioneTipoCorrente: function() {
			return _.find(this.sessione_tipi, { id: this.model.sessione_tipo_glo_id });
		},
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
		entityPayload: function() {

			var data = moment(this.model.inizio).format('YYYY-MM-DD');
			
			var cp = _.cloneDeep(this.model);
			delete cp.CalendarObject;

			cp.persone = this.persone;
			cp.inizio = data + ' ' + this.from_hour;
			cp.fine = data + ' ' + this.to_hour;

			_.each(cp.fasi, function(fase) {
				fase.persone = this.persone;
			}.bind(this));

			return cp;

		},
		user: function() {
			return Auth.user;
		}
	},
	watch: {
		'model.sessione_tipo_glo_id': {
			deep: true,
			handler: function() {

				if(!this.sessioneTipoCorrente) return false;
				
				this.model.nome = this.sessioneTipoCorrente.nome;
				this.model.fasi = [];

				if(this.sessioneTipoCorrente.setup.tuttaLaRosa != undefined && this.sessioneTipoCorrente.setup.tuttaLaRosa == true) {
					this.persone = [];
					this.persone = _.cloneDeep(this.player_options[0].items);
				}

				if(this.sessioneTipoCorrente.setup.fasiDefault != undefined && this.sessioneTipoCorrente.setup.fasiDefault.length > 0) {

					this.model.fasi = _.map(this.sessioneTipoCorrente.setup.fasiDefault, function(item) {
						if(item.nome != undefined)
							item.nome = item.nome;
						else
							item.nome = item.entita.replace('Fase_', '');

						item.persone = _.cloneDeep(this.persone);

						return item;
					}.bind(this));

				}

			},
		},
		'entity': {
			deep: true,
			handler: function() {

				if(this.entity == null) {
					this.initModel();
				} else {
					this.model = _.cloneDeep(this.entity);
				}

				this.createPersone();
				this.createTimes();
			},
			immediate: true
		}
	},
	methods: {
		createTimes: function() {

			this.from_hour = moment(this.model.inizio).format('HH:mm')
			this.to_hour = moment(this.model.fine).format('HH:mm')

		},
		createPersone: function() {
			this.persone = _.map(this.model.persone, function(persona) {
				return {
					name: persona.cognome + ' ' + persona.nome,
					id: persona.id
				}
			});
		},
		getTipiSessione() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione/tipi').then((response) => {
				if (response.ok)
				{
					this.sessione_tipi = response.data;
					for(var i in this.sessione_tipi) {
						this.levels.push(this.sessione_tipi[i].id);
					}
				}
			}, (response) => {
			});
		},
		closeModal: function() {
			this.$emit('close');
		},
		handleOk (evt, action) {
			evt.preventDefault();
			this.handleSubmit(action);
		},
		update(data, id, action){
			var l = data.sessione_tipo_glo_id;
			this.$http.post(this.$store.state.apiEndPoint + '/sessione/update/' + id, data).then((response) => {
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				this.refreshEvents();
				this.closeModal();
				if(action == 'edit') {
					this.$router.push({ name: 'medical_sessione', params: { id: id, tipo: l }});
				}
			}, (response) => {
				this.errors = response.data;
				for (var index in this.errors)
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
			});
		},
		create(action){
			var l = this.entityPayload.sessione_tipo_glo_id;
			this.$http.post(this.$store.state.apiEndPoint + '/sessione', this.entityPayload).then((response) => {
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				this.refreshEvents();
				this.closeModal();
				if(action == 'edit')
					this.$router.push({ name: 'medical_sessione', params: { id: response.data.sessione_id, tipo: l }});
			}, (response) => {
				this.errors = response.data;
				for (var index in this.errors)
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
			});
		},
		eliminaSessione() {
			if(confirm("Confermi l'eliminazione della sessione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/sessione/delete/' + this.entityPayload.id).then(function(response) {
					this.refreshEvents();
					this.closeModal();
				});
			}
		},
		refreshEvents: function() {
			this.$emit('refresh');
		},
		handleSubmit (action = 'close') {

			if(!this.entityPayload.sessione_tipo_glo_id) {
				alert("Devi selezionare una tipologia di sessione!");
				return;
			}
			if(this.entityPayload.persone.length == 0) {
				alert("Devi selezionare almeno un giocatore della rosa!");
				return;
			}
			if(!this.entityPayload.id){
				this.create(action);
			} else {
				if(Auth.isLastSeason(this.entityPayload.stagione_id)) {
					this.update(this.entityPayload, this.entityPayload.id, action);
				} else {
					this.closeModal();
				}
			}
			
		},
		initModel: function() {
			this.model = {
				team_id: this.team.id,
				categoria_id: this.team.id_categoria,
				inizio: moment(this.date).add({'hour': 9}).format('YYYY-MM-DD HH:mm:ss'),
				fine: moment(this.date).add({'hour': 10}).format('YYYY-MM-DD HH:mm:ss'),
				nome: null,
				fasi: [],
				persone: [],
				sessione_tipo_glo_id: null,
				stagione_id: this.seasonId,
			};
		}
	},
	filters: {
		
	},
	data: function() {
		return {
			model: false,
			from_hour: null,
			to_hour: null,
			persone: null,
			levels: [],
			fasi: [],
			sessione_tipi: null,
			ux: {
				creating: false
			}
		}
	}
};

</script>