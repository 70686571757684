<template>
	<div>
		<input v-if="!readonly" type="checkbox" v-model="test[field]" :true-value="1" :false-value="0" />
		<span v-else>{{ test[field] }}</span>
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	computed: {
	}
}
	
</script>