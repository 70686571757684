<template>
	<div>
		<b-overlay style="min-height: 200px" :show="!list || waiting">
			<div v-if="list">
				<b-form @submit.prevent="changeTitle">
					<b-form-group label="Nome Lista">
						<b-input type="text" v-model="list.nome"></b-input>
					</b-form-group>
					<b-button type="submit" variant="outline-success" size="sm">Salva Modifiche</b-button>
				</b-form>
				<hr />
				<b-row class="mt-5">
					<b-col cols="6">
						<strong>Giocatori</strong>
						<b-table-simple class="mt-1" small striped>
							<b-tbody>
								<b-tr v-for="player in list.giocatori">
									<b-td class="small">
										<span v-if="player.last_carriera_sportiva && player.last_carriera_sportiva.ruoolo_ideale">{{ player.last_carriera_sportiva.ruolo_ideale[0].sigla }}</span>
									</b-td>
									<b-td class="small">
										{{ player.nascita_data | year }}
									</b-td>
									<b-td class="small">
										{{ player.last_carriera_sportiva.squadra.name }}
									</b-td>
									<b-td>{{ player.cognome }} {{ player.nome }}</b-td>
									<b-td>
										<b-button @click.prevent="removePlayer(player.id)" size="xs" variant="outline-danger">&times;</b-button>
									</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>
					<b-col cols="6" v-if="auth.isAdmin() && sistemi">
						<strong>Formazioni</strong>
						<b-table-simple class="mt-1" small striped>
							<b-tbody>
								<b-tr v-for="formazione in list.formazioni">
									<b-td :colspan="formazione.titolo ? 1 : 2">
										<router-link :to="{ name: 'newscout_lista_in_campo_new', params: { id: listId, formazioneId: formazione.id_sistema_gioco } }">{{ getSistemaName(formazione.id_sistema_gioco) }}</router-link>
									</b-td>
									<b-td v-if="formazione.titolo">{{ formazione.titolo }}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-col>
				</b-row>
			</div>
		</b-overlay>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
import moment from 'moment';
var Auth = require('@/auth.js');

export default {

	mixins: [ pageBase ],
	props: [ 'listId' ],
	filters: {
		year: function(p) {
			return moment(p).format('YYYY')
		}
	},
	data: function() {
		return {
			sistemi: null,
			waiting: false,
			list: null
		}
	},
	watch: {
		listId: {
			immediate: true,
			handler: function() {
				this.fetchList();
				this.getSistemi();
			}
		}
	},
	computed: {
		auth: function() {
			return Auth;
		}
	},
	methods: {
		getSistemaName: function(id) {
			return _.find(this.sistemi, {id}).sistema_gioco;
		},
		getSistemi: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then(function(data) {
				this.sistemi = data.data;
			});
		},
		removePlayer: function(playerId) {

			this.$bvModal.msgBoxConfirm('Confermi la rimozione del Giocatore dalla Lista?', { centered: true }).then(function(value) {
				
				if(value == true) {
					this.waiting = true;
					this.$http.get(this.apiPath + '/scouting/delete/giocatore/lista/' + this.listId +'/' + playerId).then(function() {
						this.fetchList();
					});
				}

			}.bind(this));
		},
		changeTitle: function() {
			this.waiting = true;
			this.$http.post(this.apiPath + '/scouting/lista/in/campo/' + this.listId + '/edit', this.list).then(function(data) {
				this.fetchList();
				this.$emit('refresh');
			});
		},
		fetchList: function() {
			this.$http.get(this.apiPath + '/scouting/lista/in/campo/' + this.listId).then(function(data) {
				this.waiting = false;
				this.list = data.data;
			});
		}
	}

}

</script>