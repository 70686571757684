<template>
	<div>
		<b-modal size="lg" v-model="ux.show" @close.prevent="$emit('close')">

			<template #modal-header>
				<strong>Duplica Bonus</strong>
			</template>

			<template #modal-footer>

				<b-row style="width: 100%">
					<b-col>
						<b-button-group>
							<b-button class="float-left" size="sm" variant="outline-secondary" @click.prevent="ux.show = false">Cancella</b-button>
						</b-button-group>
					</b-col>
					<b-col class="text-right">
						<b-button variant="success" @click.prevent="add">Duplica</b-button>
					</b-col>
				</b-row>

			</template>

			<b-row>
				<b-form-group label="Stagione di Riferimento">
					<b-form-radio v-for="p in periodi" v-model="bonus.persona_contratto_periodo_id" :value="p.value">
						{{p.label}} <small v-if="p.value == start">(Originale)</small>
					</b-form-radio>
				</b-form-group>
			</b-row>

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'

export default {
	components: {
	},
	watch: {
		'ux.show': {
			deep: true,
			handler: function() {
			}
		}
	},
	props: [ 'bonus', 'periodi' ],
	data: function() {
		return {
			start: false,
			ux: {
				show: true
			}
		}
	},
	created: function() {
		this.start = this.bonus.persona_contratto_periodo_id;
	},
	methods: {
		add: function() {
			this.$emit('add');
			this.ux.show = false;
		},
		show: function() {
			this.ux.show = true;
		}
	}
}

</script>