<template>
	<div class="my-3" v-if="!isInCalendarView">
		
		<b-card v-if="showImport">
			<h5 class="mb-3">Importa File</h5>
			<div class="d-flex">
				<div class="px-2 pr-3">
					<em class="fa fa-cog text-muted" style="font-size: 300%"></em>
				</div>
				<div class="flex-grow-1">

					<p v-if="!estensione.file_path">
						Carica il file .txt elaborato dalla DEXA e importa i dati rilevati nella sessione del <strong>{{ sessione.inizio | date }}</strong>
					</p>

					<b-form-group>
						<b-form-file
							v-model="file"
							placeholder="Scegli un file da caricare..."
							drop-placeholder="Cancella file..."
						></b-form-file>
					</b-form-group>
					<b-button-group>
						<b-button class="mr-0" variant="outline-success" :disabled="!file" @click.prevent="askUpload">Carica File</b-button>
					</b-button-group>
					<div v-if="estensione.file_path" class="mt-2">
						<em class="text-muted fa fa-undo"></em> <a class="text-muted" href="" @click.prevent="showImport = false">Torna al file attuale</a>
					</div>
				</div>
			</div>
		</b-card>
		<b-card v-else border-variant="warning">
			<h5 class="mb-3">File Attuale</h5>
			<div class="d-flex">
				<div class="px-2 pr-3">
					<em class="fa fa-file text-muted" style="font-size: 200%"></em>
				</div>
				<div class="flex-grow-1">
					<p class="m-0">Caricato il: <em>{{ estensione.updated_at | datetime }}</em></p>
					<p class="m-0">
						<a class="small text-muted" href="" @click.prevent="downloadFile">Scarica File</a>,
						<a class="small text-muted" href="" @click.prevent="showImport = true">Sostituisci File</a>,
						<a class="small text-muted" href="" @click.prevent="askDelete">Elimina File</a>
					</p>
					<b-button size="sm" class="mt-2" :variant="estensione.is_valid ? 'outline-success':'outline-warning'" v-if="estensione.file_path" @click.prevent="askProcess" :disabled="ux.loading == true || estensione.is_valid === 0">Processa File</b-button>
					<b-button size="sm" class="mt-2" variant="outline-success" v-if="estensione.is_valid == 0" @click.prevent="showImport = true">Sostituisci</b-button>
				</div>
			</div>

			<b-alert variant="danger" show class="mt-3" v-if="estensione.is_valid === 0">
				<p>Il file non risulta valido.</p>
				<pre style="max-height: 400px; overflow-y: auto">{{ JSON.parse(estensione.errors) }}</pre>
			</b-alert>

		</b-card>

	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
		sessionMixin
	],
	data: function() {
		return {
			ux: {
				loading: false
			},
			showImport: true,
			response: null,
			file: null
		}
	},
	watch: {
		estensione: {
			immediate: true,
			handler: function() {
				if(this.estensione.file_path) {
					this.showImport = false;
				} else {
					this.showImport = true;
				}
			}
		}
	},
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { file_path: null, last_process: null, is_valid: null, errors: null });
		}
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY');
		},
		datetime: function(a) {
			return moment(a).format('DD/MM/YYYY HH:mm');
		}
	},
	props: [ 'sessione', 'estensione', 'model', 'field' ],
	methods: {
		askDelete: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione-dexa/' + this.estensione.id + '/delete').then(function(data) {
				this.$emit('refresh');
			});
		},
		askEmpty: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione-dexa/' + this.estensione.id + '/empty').then(function(data) {
				this.$emit('refresh');
			});
		},
		askUpload: function() {

			var formData = new FormData();
			formData.append('file', this.file);

			this.$http.post(this.$store.state.apiEndPoint + '/sessione-dexa/' + this.estensione.id + '/upload', formData).then(function(data) {
				this.$emit('refresh');
			});

		},
		askProcess: function() {
			this.ux.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/sessione-dexa/' + this.estensione.id + '/process').then(function(data) {
				this.ux.loading = false;
				this.$emit('refresh');
				this.response = data.data;
			});
		},

		downloadFile(id) {
			window.open(this.$store.state.apiEndPoint + '/sessione-dexa/' + this.estensione.id + '/download/' + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
	}
};
</script>