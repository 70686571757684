export default {
  canvas: {
    background: null,
    color: {
      hex: '#000000',
    },
    drawingMode: false,
    lineWidth: 1,
    export: '',
    image: '',
    wavy: false,
    dashed: false,
    opacity: 0.3,
  },
  activeObject: null,
  canvasObjects: null,
  exercises: [],
  exerciseLoading: false,
  pagination: {
    currentPage: 1,
    length: 1,
  },
};
