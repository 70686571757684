<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Video ({{ total }})</span>
					</div>
					<div class="content">
						<b-row class="mb-3" align-v="center">
							<b-col cols="3">
								<b-button :to="{ name: 'academy_ita_video_new' }" variant="outline-primary" v-if="auth.isUserEnable('academy_ita_video_mng')">Nuovo Video</b-button>
							</b-col>
							<b-col md="1">
								Filtra per:
							</b-col>
							<b-col md="2">
								<b-form-input v-model="filters.titolo" placeholder="Titolo" />
							</b-col>
							<b-col md="2">
							<b-form-select v-model="filters.stato" class="inline mr-3"> 
									<option value="">-- Stato --</option>
									<option value="0" v-if="auth.isUserEnable('academy_ita_video_mng')">Bozza</option>
									<option value="1" v-if="auth.isUserEnable('academy_ita_video_mng')">Pubblicato</option>
									<option value="2">Archiviato</option>
								</b-form-select>
							</b-col>
							<b-col md="2">
								<b-form-input type="date" v-model="filters.data" class="inline mr-3"></b-form-input>
							</b-col>
							<b-col md="2">
								<multiselect
								v-model="filters.skills"
								:multiple="true"
								:options="skills_options"
								group-values="skills"
								group-label="macro"
								placeholder="Macroprincipi"
								label="name"
								track-by="id"/>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="3">
								<v-infinite-scroll :loading="loading" @bottom="nextPage" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
									<b-col cols="12" v-for="item in video" class="mb-1">
										<b-card :bg-variant="bgCard(item.stato)">
											<h5 class="m-0">{{ item.titolo != '' ? item.titolo : 'Video' }} </h5>
											<small class="color-rosso"> {{ item.created_at | formatDate }} </small>
											<b-img :src="item.thumb != '' ? item.thumb : '/assets/varie/missing.jpg'" fluid alt="Responsive image" @click="playVideo(item.video)" style="cursor:pointer;"/>
											<b-row class="mt-1">
												<b-col>
													<div v-for="skill in item.skills">
														<span style="font-size: 0.9rem;">{{ skill.nome_skill }}</span>
													</div>	
												</b-col>
												<b-col cols="2">
													<b-button variant="primary" :to="{ name: 'academy_ita_video_edit', params: {id: item.id} }" title="Modifica Video" v-if="auth.isUserEnable('academy_ita_video_mng')" class="mb-1"><i class="fa fa-pencil" aria-hidden="true"></i></b-button>
													<b-button @click="eliminaVideo(item.id)" title="Elimina Video" v-if="auth.isUserEnable('academy_ita_video_del')"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
												</b-col>
											</b-row>
										</b-card>
									</b-col>
								</v-infinite-scroll>
							</b-col>
							<b-col cols="9">
								<video
								id="video-wrapper"
								class="video-js vjs-default-skin"
								controls
								:data-setup="setup">
							</video>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>	
	</div>

	<footerapp></footerapp>

</section>
</template>


<script>
var Auth = require('../../../auth.js');
import videojs from 'video.js';
import 'videojs-errors';
import { mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import InfiniteScroll from 'v-infinite-scroll';
export default {

	data: function() {
		return {
			video: [],
			total: 0,
			page: 1,
			skills_options: [],
			filters: {
				titolo: '',
				data: '',
				stato: '',
				skills: [],
			},
			errors_player: {
			    4: {
			      headline: 'Video non disponibile',
			      type: '',
			      message: 'Il video non è stato ancora transcodificato.<br/>Riprova più tardi.',
			    }
			}
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		'v-infinite-scroll': InfiniteScroll,
	},
	computed: {
		auth: function() {
			return Auth;
		},
		setup() {
			return JSON.stringify({
				techOrder: ['html5'],
				width: '100%',
				height: '100%',
				aspectRatio: '16:9',
				youtube: {
					iv_load_policy: 3,
					modestbranding: 1,
					rel: 0,
					showinfo: 0,
				},
			});
		},
		...mapState(['academyCatId']),
	},
	created: function () {
		if(this.academyCatId) {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/academyita/video/list/' + this.academyCatId + '/0').then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.video = response.data.data; 
	            	this.total = response.data.total;
	            	this.loading = false;
	            }
	        }, (response) => {
	            // error callback
	        });
		}
		axios.get(`/skills`)
		.then((res) => {
			var skills = JSON.parse(res.data.standard);
			var skills_portiere = JSON.parse(res.data.special);

			var skills1 = this.getOptionsSkills(skills[0]);
			var skills2 = this.getOptionsSkills(skills[1]);
			var skills3 = this.getOptionsSkills(skills[2]);

			var skills4 = this.getOptionsSkills(skills_portiere[0]);
			var skills5 = this.getOptionsSkills(skills_portiere[1]);
			var skills6 = this.getOptionsSkills(skills_portiere[2]);
			var skills7 = this.getOptionsSkills(skills_portiere[3]);

			this.skills_options = [
			{
				macro: skills[0].it,
				skills: skills1,
			},
			{
				macro: skills[1].it,
				skills: skills2,
			},
			{
				macro: skills[2].it,
				skills: skills3,
			},
			{
				macro: skills_portiere[0].it,
				skills: skills4,
			},
			{
				macro: skills_portiere[1].it,
				skills: skills5,
			},
			{
				macro: skills_portiere[2].it,
				skills: skills6,
			},
			];
		}).catch((err) => {
		});
	},
	mounted() {
		this.$video = videojs('video-wrapper');
		this.$video.errors({
			"errors": this.errors_player,
		});
	},
	beforeDestroy() {
		this.$video.dispose();
		this.$video = null;
	},
	methods: {
		nextPage() {
			if(this.academyCatId) {
				if(this.video.length < this.total) {
					this.page++;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/academyita/video/list/' + this.academyCatId + '/0?page=' + this.page, {params: this.filters}).then((response) => {
		            // success callback
		            if (response.ok)
		            {
		            	this.video = this.video.concat(response.data.data);
		            	this.loading = false;
		            }
		        }, (response) => {
		            	// error callback
		            });
				}
			}
		},
		filtraVideo() {
			if(this.academyCatId) {
				this.loading = true;
				this.video = [];
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/video/list/' + this.academyCatId + '/0', {params: this.filters}).then((response) => {
					if (response.ok)
					{
						this.video = response.data.data;
						this.total = response.data.total;
						this.loading = false; 
					}
				}, (response) => {
				});
			}
		},
		eliminaVideo(id) {
			if(confirm("Confermi l'eliminazione del video ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/video/elimina/' + id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.filtraVideo();			
				}
			}, (response) => {
			});
			}
		},
		playVideo(url) {
			this.$video.src({type: 'video/mp4', src: url });
			this.$video.play();
		},
		bgCard(stato) {
			var bg = "";
			if(stato == 0) {
				bg = 'warning';
			} else if(stato == 2) {
				bg = 'info';
			}
			return bg;
		},
		getOptionsSkills(skills) {
			var options = [];
			var skill_items = skills.fields;
			for(var key in skill_items) {
				var skill = skill_items[key];
				var option = {
					id: skill.id,
					name: skill.it,
				}
				options.push(option);

			}
			return options;
		},
	},
	watch: {
		academyCatId() {
			this.filtraVideo();
		}, 
		filters: {
			handler() {
				this.filtraVideo();
			},      
			deep: true,
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
}
</script>
<style lang="scss">

.video-js {
	width: 100%;
	height: 100%;
	flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
	$big-play-width: 3em;
	$big-play-height: 1.5em;
	left: 50%;
	top: 50%;
	margin-left: -($big-play-width / 2);
	margin-top: -($big-play-height / 2);
}

</style>
