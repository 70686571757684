<template>
	<div style="max-height: 500px; overflow-y: auto">
		<div class="d-flex mb-2">
			<div @click.prevent="type = 'players'" class="ms col-6 text-center" :style="type == 'players' ? 'font-weight: bold; background-color: rgba(0,0,0,.05)': ''">
				Per Giocatore
			</div>
			<div @click.prevent="type = 'matches'" class="ms col-6 text-center" :style="type == 'matches' ? 'font-weight: bold; background-color: rgba(0,0,0,.05)': ''">
				Per Partita
			</div>
		</div>
		<div v-if="type == 'players'">
			<div v-for="giocatore in giocatori">
				<b-table-simple style="min-width: 350px" small striped hover responsive>
					<b-thead>
						<b-tr>
							<b-th>{{ giocatore[0].osservato }}</b-th>
							<b-th style="width:50px !important;" colspan="2"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="item in giocatore" :key="item.id">
							<b-td> {{ item.partita }} </b-td>
							<b-td class="text-muted small" style="vertical-align: middle">{{ item.parole }}</b-td>
							<b-td> {{ item.gradimento }} </b-td>	
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>
		<div v-else-if="type == 'matches'">
			<div v-for="giocatore in partite">
				<b-table-simple style="min-width: 350px" small striped hover responsive>
					<b-thead>
						<b-tr>
							<b-th>{{ giocatore[0].partita }}</b-th>
							<b-th style="width:50px !important;" colspan="2"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="item in giocatore" :key="item.id">
							<b-td> {{ item.osservato }} </b-td>
							<b-td class="text-muted small" style="vertical-align: middle">{{ item.parole }}</b-td>
							<b-td> {{ item.gradimento }} </b-td>	
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>
	</div>
</template>

<script>

import _ from 'lodash';

export default {
	props: [ 'id', 'ds' ],
	data: function() {
		return {
			type: 'matches'
		}
	},
	computed: {
		giocatori: function() {
			return this.id.osservati;
		},
		partite: function() {
			return this.id.partite;
		}
	},
	methods: {
		findPartita: function(a) {
			return _.find(this.ds, { id_partita: parseInt(a) });
		}
	}
}

</script>