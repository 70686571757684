<template>
  <b-tabs v-if="teams && teams.home && teams.visiting" class="team-tabs">

    <b-tab
      v-if="teams.home"
      active>
      <template slot="title">
      <b-img :src="teams.home.logo" height="28" alt="teams.home.name" :title="teams.home.name"/>
      </template>
      <team :team="teams.home" />
    </b-tab>

    <b-tab
      v-if="teams.visiting && teams.visiting.id">
      <template slot="title">
      <b-img :src="teams.visiting.logo" height="28" alt="teams.visiting.name" :title="teams.visiting.name"/>
      </template>
      <team :team="teams.visiting" />
    </b-tab>

  </b-tabs>
</template>

<script>
import Team from './Team.vue';

export default {
  name: 'TeamTabs',
  props: ['teams'],
  components: { Team },
};
</script>
