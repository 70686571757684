<template>
	<div class="text-center font-weight-bold" style="min-width:100px">
		{{ Groin_MVC_Force_Left }}
	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	created: function() {


	},
	methods: {
		
	},
	watch: {
		dataLayer: {
			deep: true,
			handler: function() {

			}
		},
		Groin_MVC_Force_Left: {
			immediate: true,
			handler: function() {
				this.test[this.field] = this.Groin_MVC_Force_Left;
			}
		},
	},
	computed: {
		prefix: function() {
			var array = this.field.split('_');
			if(isNaN(array[0])) {
				return array[0] + "_" + array[1];
			}
			return array[0];
		},
		suffix: function() {
			var array = this.field.split('_');
			return array[array.length - 1];
		},
		Groin_MVC_Force_Left: function() {
			var value = null;
			if(!isNaN(this.test[this.prefix + '_Groin_MVC_Force_Left_' + this.suffix]) && this.test[this.prefix + '_Groin_MVC_Force_Left_' + this.suffix] != null && this.test[this.prefix + '_Groin_MVC_Force_Left_' + this.suffix] != '' && !isNaN(this.test[this.prefix + '_Groin_MVC_Force_Right_' + this.suffix]) && this.test[this.prefix + '_Groin_MVC_Force_Right_' + this.suffix] != null && this.test[this.prefix + '_Groin_MVC_Force_Right_' + this.suffix] != '') {
				value = (parseFloat(this.test[this.prefix + '_Groin_MVC_Force_Left_' + this.suffix]) + parseFloat(this.test[this.prefix + '_Groin_MVC_Force_Right_' + this.suffix])) / 2;
				value =  Math.round(value * 100) / 100;
			}
			return value;
		},		
	}
}

</script>