import Vue from 'vue';

export default {
    setExercises(state, exercises) {
        if(state.page == 1) {
            state.exercises = exercises;
            state.n_exercises = exercises.data.length;
        } else {
            state.exercises.data = state.exercises.data.concat(exercises.data);
            state.n_exercises = (state.n_exercises + exercises.data.length);
        }
    },
    setObjIds(state, objective_ids) {
      state.objective_ids = objective_ids;
    },
    setTitle(state, title) {
        state.title = title;
    },
    setPage(state, page) {
        state.page = page;
    },
    setExercisesWaiting(state, exercises) {
        if(state.page == 1) {
            state.exercises_waiting = exercises;
            state.n_exercises_waiting = exercises.data.length;
        } else {
            state.exercises_waiting.data = state.exercises_waiting.data.concat(exercises.data);
            state.n_exercises_waiting = (state.n_exercises_waiting + exercises.data.length);
        }
    },
    setExercisesASRoma(state, exercises) {
        if(state.page == 1) {
            state.exercises_asroma = exercises;
        } else {
            state.exercises_asroma.data = state.exercises_asroma.data.concat(exercises.data);
        }
    },

    setExercisesCertificati(state, exercises) {
        if(state.page == 1) {
            state.exercises_certificati = exercises;
        } else {
            state.exercises_certificati.data = state.exercises_certificati.data.concat(exercises.data);
        }
    },

    setExercisesDaValidare(state, exercises) {
        if(state.page == 1) {
            state.exercises_da_validare = exercises;
        } else {
            state.exercises_da_validare.data = state.exercises_da_validare.data.concat(exercises.data);
        }
    },

    setExcerciseTab(state, tab) {
        state.exercises_tab = tab;
    },

    resetAllExercises(state) {
        state.exercises = [];
        state.n_exercises = 0;
        state.exercises_waiting = [];
        state.n_exercises_waiting = 0;
        state.exercises_asroma = [];
    },
    setDrillId(state, drill_id) {
        state.drill_id = drill_id;
    },
    
};
