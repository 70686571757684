import axios from 'axios';

export default {
    filter({ state, commit }, params) {       
        var page = (state.page === undefined) ? 1 : state.page;
        var url = null;
        if(state.academy == -1 && state.footballTeamId) {
            url = `/exercises/filters?page=${page}&team_id=${state.footballTeamId}&tab=certificati`;
        } else if(state.academy != -1 && state.academyCategoryId) {
            url = `/academyita/esercizi?page=${page}&cat_id=${state.academyCategoryId}&tab=esercizi&academy=${state.academy}`;
        }
        if(url) {
            state.loadingEsercizi = true; 
            return axios.post(url, {
                objective_ids: state.objective_ids,
                title: state.title,
                drill_id: state.drill_id
            })
            .then(function (response) {
                commit('setEsercizi', response.data);
                state.loadingEsercizi = false;     
            })
            .catch(function (error) {
                console.log(error);
                state.loadingEsercizi = true;     
            });
        } 
    },
};
