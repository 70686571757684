import { render, staticRenderFns } from "./Json.vue?vue&type=template&id=0f547beb&"
import script from "./Json.vue?vue&type=script&lang=js&"
export * from "./Json.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports