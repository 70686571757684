<template>
	<div v-if="contratto_data && !contratto_data.entity.locked">

		<headgiocatore :player_id="contratto_data.entity.persona_id"></headgiocatore>

		<div class="card border-0 rounded-0 mt-1">
			<div class="card-body">
				<div class="content">

					<b-row>
						<b-col>
							<b-form-group>
								<template #label>
									Inizio Contratto
									<span class="float-right"><a href="" @click.prevent="askDurata">Imp. Durata</a></span>
								</template>
								<b-form-input type="date" size="sm" v-model="contratto_data.entity.inizio"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="Fine Contratto">
								<b-form-input type="date" size="sm" v-model="contratto_data.entity.fine"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b-form-group>
								<template #label>
									Inizio Tesseramento
								</template>
								<b-form-input type="date" size="sm" v-model="contratto_data.entity.inizio_tesseramento"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="Fine Tesseramento">
								<b-form-input type="date" size="sm" v-model="contratto_data.entity.fine_tesseramento"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>


					<b-row>
						<b-col>
							<b-form-group label="Importo Lordo">
								<b-form-input type="number" size="sm" v-model.number="contratto_data.entity.stipendio_lordo"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="Importo Netto">
								<b-form-input type="number" size="sm" v-model.number="contratto_data.entity.stipendio_netto"></b-form-input>
							</b-form-group>
						</b-col>
						<!--<b-col>
							
							<div><label>Netto Garantito</label></div>
							<div><label>Decreto Crescita</label></div>

						</b-col>-->
					</b-row>

					<hr />


					<div v-if="duration">

						<b-card>

							<template #header>
								<b-row>
									<b-col>Stagioni di Pertinenza</b-col>
									<b-col class="text-center">
										<b-button v-if="canGeneratePayments" variant="outline-secondary" size="xs" @click.prevent="prepareImporti()">Predisponi i Pagamenti</b-button>
									</b-col>
									<b-col class="text-right small">
										<a v-if="!expandPeriodi" href="" @click.prevent="expandPeriodi = true">Espandi</a>
										<a v-if="expandPeriodi" href="" @click.prevent="expandPeriodi = false">Collassa</a>
									</b-col>
								</b-row>
							</template>

							<Contratto-Periodi v-show="expandPeriodi" v-for="periodo, k in contratto_data.entity.periodi" :stagioni="stagioni" :stagioniused="seasonsUsed" :periodo="periodo" @rim="contratto_data.entity.periodi.splice(k,1)" @lordo="applyImporti('lordo', $event)" @netto="applyImporti('netto', $event)" @refresh="softRefresh"></Contratto-Periodi>
						
							<div v-if="contratto_data.entity.periodi.length == 0" class="small">Non ci sono periodi impostati</div>

							<b-row v-if="expandPeriodi">
								<b-col cols="6"></b-col>
								<b-col class="text-right">
									<div class="small">
										<a href="" @click.prevent="addPeriodo">Aggiungi Stagione</a>
									</div>
								</b-col>
							</b-row>

							<div v-if="!expandPeriodi">
								<b-row>
									<b-col v-for="a,k in contratto_data.entity.periodi" class="small text-center py-1 mx-1 rounded" style="border: 1px solid rgba(0,0,0,.1)">

										<div>
											<span v-if="season[a.contratti_squadra_variabili_id]">{{ season[a.contratti_squadra_variabili_id].name }}</span>
											<span v-else>⚠️ Seleziona stagione</span>
										</div>

										<div class="text-center">
											<div v-if="a.stipendio_annuo_netto > 0">
												<p class="lead m-0">{{ a.importo_netto | money }}</p>
												<strong>Netto</strong>
											</div>
											<div v-else>
												<p class="lead m-0">{{ a.importo_lordo | money }}</p>
												<strong>Lordo</strong>
											</div>
										</div>
										
									</b-col>
								</b-row>
							</div>

						</b-card>

					</div>

					<hr />
					

					<div v-if="periodi_table.length == 0">
						Devi inserire almeno un periodo.
					</div>

					<b-tabs v-model="tabIndex" align="center" @activate-tab="changeTab" v-else>
						<b-tab disabled title="Bonus per Stagione"></b-tab>
						<b-tab v-for="pt,k in periodi_table" :title="pt.label + ' ('+(bonusOf[pt.value]?bonusOf[pt.value].length:0)+')'" class="py-0">
							
							<div class="text-right py-1 pb-2">
								<a href="javascript:;" v-b-toggle="'k_' + pt.variables.id">Variabili di Stagione, per il giocatore</a>
							</div>
							<b-collapse :id="'k_' + pt.variables.id">
								<periodo-variabili v-if="pt.variables.id" :periodo="pt.variables.id" :periodi="contratto_data.entity.periodi"></periodo-variabili>
							</b-collapse>

							<div v-for="bonus, i in bonusOf[pt.value]" class="mb-2" :key="i">
								<bonus :contratto="contratto_data.entity" :periodi="periodi_table" :rules-archive="rules" class="" :bonus="bonus" @duplicate="duplicateBonus(bonus)" @remove="removeBonus(bonus)"></bonus>
							</div>

							<p class="lead text-center my-2" v-if="!bonusOf[pt.value]">Non ci sono bonus per questa stagione ({{ pt.label }})</p>

							<div class="text-right mb-1">
								<b-button variant="outline-success" size="sm" @click.prevent="addBonus(pt.value)">Aggiungi nuovo Bonus</b-button>
							</div>

						</b-tab>
					</b-tabs>

					<div class="mt-5">
						<b-button :disabled="hasModified == false" @click.prevent="do_update">Salva Modifiche</b-button>
					</div>

					<hr />
					<h5 class="font-bold">Note</h5>
					<NoteManager entity="Persona_Contratto" :entityId="contratto_data.entity.id" :hideForm="true"></NoteManager>
					

					<hr class="mt-5" />

					<b-card v-if="ux.preset" class="mb-4">

						<template #header><a href="javascript:;" @click.prevent="ux.preset = false">Presets</a></template>
						<template #footer>
							<div v-if="contratto_data.entity.bonuses.length > 0" class="w-full text-right">
								<b-btn href="" @click.prevent="savePreset()">Salva l'attuale contratto come preset</b-btn>
							</div>
						</template>
						<p>Puoi utilizzare un preset per scegliere un modello di contratto:</p>
						<ul>
							<li v-for="preset in presets" class="mb-4">
								<div>{{ preset.name }} ({{ preset.bonuses.length }} bonus)</div>
								<div v-for="bonus in preset.bonuses">
									{{ bonus.name }} <b-badge v-for="rule in bonus.rules">{{rule.name}}</b-badge>
								</div>
								<a href="#" @click.prevent="applyPreset(preset)" class="mt-1 small" variant="outline-secondary" size="sm">Applica Preset</a>
							</li>
						</ul>


					</b-card>
					<b-btn  v-else size="sm" variant="outline-secondary" @click.prevent="ux.preset=true">Apri i preset</b-btn>

					<b-btn size="sm" variant="outline-danger" @click.prevent="deleteContratto()">Elimina Contratto</b-btn>

				</div>

			</div>
		</div>

		<ModalDuplicateBonus @close="ux.duplicate = false" v-if="ux.duplicate" :bonus="ux.duplicate" :periodi="periodi_table" @add="contratto_data.entity.bonuses.push(ux.duplicate); ux.duplicate = false"></ModalDuplicateBonus>
		
	</div>
	<div v-else>
		<b-alert variant="danger" :show="true">Questo contratto è bloccato</b-alert>
	</div>
</template>



<script>

import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
	props: [ 'contrattoId' ],
	components: {
		NoteManager: require('@/components/layout/notes/manager.vue').default,
		ModalDuplicateBonus: require('./ModalDuplicateBonus.vue').default,
		ContrattoAnagrafica: require('./ContrattoAnagrafica.vue').default,
		ContrattoImporti: require('./ContrattoImporti.vue').default,
		ContrattoPeriodi: require('./ContrattoPeriodi.vue').default,
		PeriodoVariabili: require('./PeriodoVariabili.vue').default,
		Bonus: require('./Bonus.vue').default,
		headgiocatore: require('../../layout/headgiocatore.vue').default,
		FontAwesomeIcon
	},
	data: function() {
		return {
			tabIndex: 1,
			contratto_data: false,
			rules: false,
			hasModified: false,
			modifierCheck: false,
			presets: false,
			expandPeriodi: false,
			stagioni: false,
			ux: {
				duplicate: false,
				preset: false
			}
		}
	},
	filters: {
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return formatter.format(v).replace(' €', '');
		},
		date: function(v) {
			return moment(v).format('DD/MM/YYYY');
		} 
	},
	computed: {
		season: function() {
			return _.keyBy(this.stagioni, 'id');
		},
		seasonsUsed: function() {
			return _.map(this.contratto_data.entity.periodi, 'contratti_squadra_variabili_id');
		},
		bonusOf: function() {
			return _.groupBy(this.contratto_data.entity.bonuses, 'persona_contratto_periodo_id');
		},
		periodi_table: function() {

			return _.filter(_.map(this.contratto_data.entity.periodi, function(a, pos) {

				if(a.contratti_squadra_variabili_id == 0) return false;

				var s = _.find(this.stagioni, { id: a.contratti_squadra_variabili_id });
				if(a.id) {
					return {
						value: a.id,
						label: s.name,
						inizio: s.inizio,
						fine: s.fine,
						variables: a
					}
				} else {
					return {
						value: 'pos-' + pos,
						label: s.name,
						inizio: s.inizio,
						fine: s.fine,
						variables: a
					}
				}
			}.bind(this)));

			return this.contratto_data.entity.periodi;
		},
		canGeneratePayments: function() {
			return _.filter(this.contratto_data.entity.importi, { pagato: 1 }).length == 0;
		},
		summed: function() {

			var i = {
				netto: _.round(_.sum(_.map(this.contratto_data.entity.importi, function(i) { return parseFloat(i.stipendio_annuo_netto) }),2)),
				lordo: _.round(_.sum(_.map(this.contratto_data.entity.importi, function(i) { return parseFloat(i.stipendio_annuo_lordo) }),2)),
				nettoDiff: false,
				lordoDiff: false,
				nettoDiffValue: false,
				lordoDiffValue: false
			}

			if(i.netto != this.contratto_data.entity.stipendio_netto && i.netto > 0) {
				i.nettoDiff = true;
				i.nettoDiffValue = this.contratto_data.entity.stipendio_netto - i.netto;
			}

			if(i.lordo != this.contratto_data.entity.stipendio_lordo && i.lordo > 0) {
				i.lordoDiff = true;
				i.lordoDiffValue = this.contratto_data.entity.stipendio_lordo - i.lordo;
			}

			return i;
		},
		duration: function() {
			return parseInt(_.ceil(moment(this.contratto_data.entity.fine).diff(this.contratto_data.entity.inizio, 'days')/365));
		}
	},
	watch: {
		'$route.query.season': {
			immediate: true,
			deep: true,
			handler: function() {
				this.tabIndex = parseInt(this.$route.query.season)
			}
		},
		'contratto_data.entity': {
			deep: true,
			handler: function(a, b) {
				if(a != undefined) {
					//console.log(a,b);
					if(JSON.stringify(this.contratto_data.entity) != this.modifierCheck)
						this.hasModified = true;
					else
						this.hasModified = false;
				}
			}
		}
	},
	methods: {
		changeTab: function(a,b) {

			//console.log(this.seasons[a-1]);
			this.$router.push({ query: { season: a } });

		},
		duplicateBonus: function(bonus) {
			var cl = _.cloneDeep(bonus);

			delete cl.id;
			
				_.each(cl.rules, function(b) {
					delete b.id;
					delete b.progresso_current;
					delete b.created_at;
					delete b.updated_at;
					delete b.persona_contratto_bonus_id;
				});

			this.ux.duplicate = cl;

		},
		removeBonus: function(bonus) {

			var mishable = JSON.stringify(bonus);
			var found = _.findKey(this.contratto_data.entity.bonuses, function(i) {
				return JSON.stringify(i) === mishable;
			});

			if(found !== false)
				this.contratto_data.entity.bonuses.splice(found, 1);

		},
		savePreset: function() {

			var name = window.prompt('Inserisci il nome del preset.');

			var c = window.confirm('Confermi il salvataggio del preset con il nome ' + name + '?');

			if(!c) return false;

			var preset = {
				name: name,
				bonuses: _.map(this.contratto_data.entity.bonuses, function(i) {

					return {
						con_ultima_mensilita: i.con_ultima_mensilita,
						importo: i.importo,
						multiplier: i.multiplier,
						multiplier_type: i.multiplier_type,
						name: i.name,
						description: i.description,
						netto: i.netto,
						netto_garantito: i.netto_garantito,
						validation: i.validation,
						watchdog: i.watchdog,
						rules: _.map(i.rules, function(r) {
							return {
								input_payload: r.input_payload,
								name: r.name,
								posizione: r.posizione,
								regola: r.regola
							}
						})
					}

				})
			};

			this.$http.post(this.$store.state.apiEndPoint + '/contratti/save-preset', { preset }).then(function(data) {
				alert('Preset Salvato');
			});

		},
		applyPreset: function(preset) {

			if(this.contratto_data.entity.bonuses.length > 0) {
				var confirmNew = window.confirm("Il contratto corrente ha dei bonus. Vuoi sostituirli con il preset?");
			} else {
				var confirmNew = true;
			}

			if(confirmNew) {

				var bonusCopy = _.cloneDeep(preset.bonuses);
				this.contratto_data.entity.bonuses = bonusCopy;

			}

		},
		fetchStagioni: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/stagioni').then(function(data) {
				this.stagioni = data.data;
			})
		},
		fetchPresets: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/presets').then(function(data) {
				this.presets = data.data;
			});
		},
		applyImporti: function(type, v) {

			_.each(this.contratto_data.entity.importi, function(a) {

				if(a.pagato == 0) {
					if(type == 'lordo')
						a.stipendio_annuo_lordo = v;
					else if(type == 'netto')
						a.stipendio_annuo_netto = v;
				}


			});

		},
		prepareImporti: function() {

			this.expandPeriodi = true;

			var nettoPerMese = _.round(this.contratto_data.entity.stipendio_netto / this.duration, 2);
			var lordoPerMese = _.round(this.contratto_data.entity.stipendio_lordo / this.duration, 2);

			var nettoLost = this.contratto_data.entity.stipendio_netto - (nettoPerMese * this.duration);
			var lordoLost = this.contratto_data.entity.stipendio_lordo - (lordoPerMese * this.duration);

			if(this.contratto_data.entity.periodi.length == this.duration) {

				var periodi = [];
				for(var i = 0; i < this.duration; i++) {
					this.contratto_data.entity.periodi[i].importo_lordo = _.round(lordoPerMese + (i == this.duration -1 ? lordoLost : 0), 2);
					this.contratto_data.entity.periodi[i].importo_netto = _.round(nettoPerMese + (i == this.duration -1 ? nettoLost : 0), 2);
				}

				var goOn = false;

			} else if(this.contratto_data.entity.periodi.length == 0) {
				var goOn = true;
			} else {
				var goOn = window.confirm('Hai già degli periodi frazionati, vuoi sostituirli?');
			}

			if(!goOn) return false;

			var importi = [];
			for(var i = 0; i < this.duration; i++) {
				var importo = {
					dal: moment(this.contratto_data.entity.inizio).add(i, 'years').format('YYYY-MM-DD'),
					al: moment(this.contratto_data.entity.inizio).add(i + 1, 'years').subtract(1, 'second').format('YYYY-MM-DD'),
					importo_lordo: _.round(lordoPerMese + (i == this.duration -1 ? lordoLost : 0), 2),
					importo_netto: _.round(nettoPerMese + (i == this.duration -1 ? nettoLost : 0), 2)
				};
				importi.push(importo);
			}

			this.contratto_data.entity.importi = importi;

		},
		setDurata: function(durata) {
			this.contratto_data.entity.fine = moment(this.contratto_data.entity.inizio).add(durata, 'years').subtract(1, 'second').format('YYYY-MM-DD');
		},
		addBonus: function(v = null) {
			this.contratto_data.entity.bonuses.push({
				name: 'Senza Nome',
				description: '',
				persona_contratto_periodo_id: v,
				importo: 0,
				validation: '',
				paymentsRulesJson: null,
				payment_rule: null,
				multiplier_type: 'once',
				multiplier: 1,
				watchdog: '',
				watchdogs: [],
				rules: []
			});
		},
		addPeriodo: function() {
			this.contratto_data.entity.periodi.push({
				contratti_squadra_variabili_id: 0,
				importo_lordo: 0,
				importo_netto: 0,
				migliormarcatore_seriea: null,
				migliormarcatore_coppa_italia: null,
				migliormarcatore_uel: null,
				migliormarcatore_ucl: null,
				migliormarcatore_uecl: null,
				presenze_nazionale: null
			});
		},
		fetchContratto: function(id) {
			return this.$http.get(this.$store.state.apiEndPoint + '/contratto/'+id+'/analyze');
		},
		fetchRules: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/rules').then(function(data) {
				this.rules = data.data;
			})
		},
		do_update: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/contratto/'+this.contratto_data.entity.id+'/edit', { contract: this.contratto_data.entity }).then(function(data) {
				//console.log(data.data);
			})	
		},
		askDurata: function() {
			var amount = window.prompt('Inserisci la durata');
			if(amount)
				this.setDurata(amount);
		},
		deleteContratto: function() {
			var c = window.confirm('Confermi la rimozione del contratto? L\'operazione non è annullabile.');
			if(c) {

				this.$http.post(this.$store.state.apiEndPoint + '/contratto/' + this.contratto_data.entity.id + '/delete').then(function() {
					this.$router.push({ name: 'contratti_dashboard' });
				})

			}
		},
		softRefresh: function() {
			this.fetchContratto(this.contrattoId).then(function(data) {
				this.contratto_data = data.data;
				this.modifierCheck = JSON.stringify(data.data.entity);
			});
		}
	},
	created: function() {

		this.fetchRules();
		this.fetchPresets();
		this.fetchStagioni();
		this.fetchContratto(this.contrattoId).then(function(data) {
			this.contratto_data = data.data;
			this.modifierCheck = JSON.stringify(data.data.entity);
		});

	}
};

</script>