<template>
	<div class="mt-2">
		
		<b-button v-if="ux.collapsed" block size="sm" variant="success" @click.prevent="openTrainingReport">Single Player Training Report</b-button>
		
		<b-card v-if="!ux.collapsed">
			<template slot="header">
				<b-row>
					<b-col>
						Single Player Training Report
					</b-col>
					<b-col v-if="collapsable" class="text-right">
						<a href="javascript:;" @click.prevent="ux.collapsed = true">Chiudi</a>
					</b-col>
				</b-row>
			</template>
			<div class="text-right">
				<b-button-group>
					<b-button variant="outline-secondary" size="sm" @click="selectAll">Seleziona tutti</b-button>
					<b-button variant="outline-secondary" size="sm" @click="unselectAll">Deseleziona tutti</b-button>
					<b-button variant="outline-secondary" size="sm" @click="createReport">Genera report per i giocatori selezionati</b-button>
					<b-button variant="outline-secondary" size="sm" @click="refreshAll">Ricarica tutti i report</b-button>
					<b-button variant="outline-secondary" size="sm" @click="sendReport">Invia report ai giocatori selezionati</b-button>
				</b-button-group>
			</div>
			<b-row v-for="persona in sessione.persone">
				<b-col>
					<training-report-player  :ref="'trp_' + persona.id" :sessione="sessione" :persona="persona" :persone_sel="ux.persone_sel" @updateSelect="updateSelect"></training-report-player>
				</b-col>	
			</b-row>
		</b-card>

	</div>
</template>


<script>

import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'collapsable', 'sessione' ],
	components: {
		TrainingReportPlayer: require('@/components/layout/Sessioni/Generica/Training_Report_Player.vue').default,
	},
	data: function() {
		return {
			ux: {
				collapsed: this.collapsable ? true : false,
				persone_sel: [],
			},
		}
	},
	computed: {
	},
	methods: {
		refreshAll: function() {
			this.sessione.persone.forEach(persona => {
				this.$refs['trp_' + persona.id][0].getReport();
			});
		},
		openTrainingReport: function() {
			this.ux.collapsed = false;
		},
		updateSelect(persona_id, value) {
			if(value == true && !this.ux.persone_sel.includes(persona_id)) {
				this.ux.persone_sel.push(persona_id);
			} else if(value == false && this.ux.persone_sel.includes(persona_id)) {
				var index = this.ux.persone_sel.indexOf(persona_id);
				this.ux.persone_sel.splice(index, 1);
			}
		},
		selectAll() {
			this.ux.persone_sel = this.sessione.persone.map(item => item.id);
		},
		unselectAll() {
			this.ux.persone_sel = [];
		},
		createReport() {
			this.ux.persone_sel.forEach(persona_id => {
				this.$http.post(this.$store.state.apiEndPoint + '/training/report/create', { persona_id: persona_id, sessione_id: this.sessione.id}).then(function(data) {
					this.$refs['trp_' + persona_id][0].getReport();
				});
			});
		},
		sendReport() {
			if(confirm("Confermi di voler inviare via email i report ai giocatori selezionati ?")) {
				this.ux.persone_sel.forEach(persona_id => {
					this.$http.get(this.$store.state.apiEndPoint + '/training/report/send', {params: { persona_id: persona_id, sessione_id: this.sessione.id}}).then(function(data) {
						this.$refs['trp_' + persona_id][0].getReport();
					});
				});
			}
		}
	}
}

</script>