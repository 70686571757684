<template id="giocatore">
	<section class="giocatore">
		<headapp></headapp>
		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<schedagiocatore :player_id="$route.params.id"></schedagiocatore>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>
export default {
	data: function() {
		return {
		}
	},
	components: {
		headapp: require('../../layout/headapp.vue').default,
		menusub: require('../../layout/menusub.vue').default,
		footerapp: require('../../layout/footerapp.vue').default,
		schedagiocatore: require('../../layout/schedagiocatore.vue').default,
	}
}
</script>
