<template>
	<div>
		<b-modal size="lg" v-model="ux.show">

			<template #modal-header>
				Modifica Regola {{ rule.name }}
			</template>

			<template #modal-footer>

				<b-row style="width: 100%">
					<b-col>
						<!-- <b-button class="float-left" size="sm" variant="outline-secondary" @click.prevent="ux.show = false">Rimuovi</b-button> -->
					</b-col>
					<b-col class="text-right">
						<b-button v-if="rule" variant="success" @click.prevent="ux.show = false">Chiudi</b-button>
					</b-col>
				</b-row>

			</template>

			<Regola class="mb-1" :compact="true" :key="rule.posizione" :rule="rule"></Regola>

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'

export default {
	components: {
		RuleConfig: require('./RegolaConfiguratore.vue').default,
		Regola: require('./Regola.vue').default,
	},
	watch: {
		'ux.show': {
			deep: true,
			handler: function() {}
		}
	},
	props: [ 'position', 'rule' ],
	data: function() {
		return {
			ux: {
				show: false
			},
			ruleInfo: false,
			rules: []
		}
	},
	methods: {
		show: function() {
			this.ux.show = true;
		}
	}
}

</script>