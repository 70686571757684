<template id="tagscategorie">
	<div class="card elencocategorie">
		<div class="card-body">
			<div v-if="title" class="card-title">
				<span>{{ title }}</span>
			</div>

			<div class="content">

				<div class="row flex-items-xs-middle">

					<div class="col-6" v-for="skill in skills_tags">
						<div class="categoria-item">
							<span>
								<div class="row"> 	
									<div class="col-1" @click="skill.count_tags ? selectSkillTags(skill, skill.it) : ''">	
										<i class="fa fa-youtube-play"></i>
									</div>
									<div class="col-8 pl-2" @click="skill.count_tags ? selectSkillTags(skill, skill.it) : ''">
										{{skill.it}} 
									</div>
									<div class="col-2 color-rosso text-right" v-if="skill.count_tags" v-b-toggle="skill.id">
										<strong>{{skill.count_tags}}</strong>
									</div>
									<div class="col-2 text-right" v-if="!skill.count_tags">	
										0
									</div>
									<div class="col-1" v-if="skill.count_tags && skill.fields && !only_root_skill" v-b-toggle="skill.id">	
										<i class="skilltags-closed fa fa-arrow-down" aria-hidden="true"></i>
										<i class="skilltags-opened fa fa-arrow-up" aria-hidden="true"></i>
									</div>
								</div>
								<b-collapse :id="skill.id" style="width:100%" v-if="skill.count_tags && skill.fields && !only_root_skill">
									<div class="col-12" v-for="subskill in skill.fields">
										<div class="categoria-item">
											<span>
												<div class="row"> 	
													<div class="col-1" @click="subskill.count_tags ? selectSkillTags(subskill, skill.it + ' -> ' + subskill.it) : ''">	
														<i class="fa fa-youtube-play"></i>
													</div>
													<div class="col-8 pl-2" @click="subskill.count_tags ? selectSkillTags(subskill, skill.it + ' -> ' + subskill.it) : ''">
														{{subskill.it}} 
													</div>
													<div class="col-2 color-rosso text-right" v-if="subskill.count_tags">	
														<strong>{{subskill.count_tags}}</strong>
													</div>
													<div class="col-2 text-right" v-if="!subskill.count_tags">	
														0
													</div>
													<div class="col-1" v-if="subskill.count_tags && subskill.fields" v-b-toggle="subskill.id">	
														<i class="subskilltags-closed fa fa-arrow-down" aria-hidden="true"></i>
														<i class="subskilltags-opened fa fa-arrow-up" aria-hidden="true"></i>
													</div>
												</div>
												<b-collapse :id="subskill.id" style="width:100%" v-if="subskill.count_tags && subskill.fields">
													<div class="col-12" v-for="subskill2 in subskill.fields">
														<div class="categoria-item">
															<span @click="subskill2.count_tags ? selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it) : ''">
																<div class="row"> 	
																	<div class="col-1">	
																		<i class="fa fa-youtube-play"></i>
																	</div>
																	<div class="col-9 pl-2">
																		{{subskill2.it}} 
																	</div>
																	<div class="col-2 color-rosso text-right" v-if="subskill2.count_tags">	
																		<strong>{{subskill2.count_tags}}</strong>
																	</div>
																	<div class="col-2 text-right" v-if="!subskill2.count_tags">	
																		0
																	</div>
																</div>
															</span>	
														</div>
													</div>
												</b-collapse>
											</span>	
										</div>
									</div>
								</b-collapse>
							</span>	
						</div>				
					</div>
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
export default {
	props:{
		title: {
			type: String,
			required: true
		},
		skills_tags: {
			type: Array,
			required: true
		},
		only_root_skill: {
			type: Number,
			default: 0
		},
	},
	data: function(){
		return {
			tags: [],

		}
	},
	methods: {
		selectSkillTags(skill, title) {
			this.$emit('selectSkillTags', skill, title);
		},
	},
}
</script>
