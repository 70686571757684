<template>
	<div>
		<div ref="competitionFilter" v-if="season">
			<b-form-select size="sm" v-model="comp_filter" class="mb-1">
				<b-form-select-option disabled :value="null">Seleziona Competizione</b-form-select-option>
				<b-form-select-option v-for="c in competizioni" :value="c">{{ c }}</b-form-select-option>
			</b-form-select>
		</div>

		<div :style="'height: '+ux.altezzaPartite+'px; overflow-y: scroll'">

			<b-card no-body class="p-2 mb-1" v-for="p in thePartite" :bg-variant="isSelected(p) ? 'warning' : 'default'"> 
				<b-row>
					<b-col @click="selezionaPartita(p)" style="cursor: pointer;">
						<div class="text-muted small" v-if="season && p.competizione">{{ p.competizione.nome }}</div>
						<h6 style="display: inline;">{{ p.label }}</h6>
						<br/>
						<small class="text-muted">{{ p.data | dataFilter(moment) }}</small>
					</b-col>
					<b-col cols="3" align-self="center" class="text-right">
						<i class="fa fa-pencil-square-o mr-1" aria-hidden="true" v-if="p.valutazioni > 0" :title="'Valutazioni: ' + p.valutazioni"></i>
						<!--b-button size="xs" v-if="p.partitaWyScoutId" @click.prevent="getVideoWyscout(p)" class="px-1"><i class="fa fa-video-camera" aria-hidden="true" title="Video Wyscout"></i></b-button-->
						<b-button size="xs" v-if="hasVideo(p)" @click.prevent="selezionaVideo(p)" class="px-1"><i class="fa fa-video-camera" aria-hidden="true" title="Video"></i></b-button>
						<b-button size="xs" v-if="p.allegati" @click.prevent="selezionaAllegati(p)" class="px-1"><i class="fa fa-file" aria-hidden="true" title="Allegati"></i></b-button>
					</b-col>
				</b-row>
			</b-card>

		</div>

		<b-modal ref="modalVideo" size="xl" :title="ux.partita ? ux.partita.label : ''">
			<video_partita_football :readonly="true" :partita="ux.partita && ux.partita.footballId ? ux.partita.footballId : null"></video_partita_football>
		</b-modal>

		<b-modal ref="modalScoutingVideo" size="xl" :title="ux.partita ? ux.partita.label : ''" @hide="ux.partita = null">
			<video_partita_scouting :readonly="true" v-if="ux.partita && ux.partita.partiteId" :partita="{ entita: 'Partite', entita_id: ux.partita.partiteId }"></video_partita_scouting>
		</b-modal>
		<b-modal ref="modalDoc" size="xl" :title="ux.partita ? ux.partita.label : ''">
			<DocumentiManager v-if="ux.partita && ux.partita.partiteId" entity="Partite" :entityId="ux.partita.partiteId" :tipoId="1" path="" :readonly="true"></DocumentiManager>
		</b-modal>
	</div>
</template>

<script>

import $ from 'jquery'
import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	props: ['partite', 'season', 'height'],
	components: {
		video_partita_football: require('@/components/layout/video/video_partita_football.vue').default,
		video_partita_scouting: require('@/components/layout/video/video_partita_scouting.vue').default,
		DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
	},
	filters: {
		dataFilter: function(valore, m) {
			return m(valore).format('DD/MM/YYYY HH:mm');
		}
	},
	data: function() {
		return {
			ux: {
				altezzaPartite: null,
				partita: null
			},
			comp_filter: null
		}
	},
	mounted: function() {
		this.design();
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		thePartite: function() {

			if(this.comp_filter) {
				return _.filter(this.partite, function(a) {

					if(!a.competizione && this.comp_filter == 'Sconosciuta') return true;
					return a.competizione.nome === this.comp_filter;

				}.bind(this));
			} else
			return this.partite;
		},
		competizioni: function() {
			return _.keys(_.groupBy(this.partite, function(a) {
				if(a.competizione)
					return a.competizione.nome;
				return 'Sconosciuta';
			}));
		}
	},
	watch: {
		season: {
			deep: true,
			handler: function() {
				_.delay(function() { this.design() }.bind(this), 200);
			},
		},
		height: {
			immediate: true,
			handler: function() {
				this.design();
			}
		}
	},
	methods: {
		getVideoWyscout: function (p) {
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/videos/match/' + p.partitaWyScoutId).then(function(data) {
				console.log(data.data);
			});
		},
		hasVideo: function (p) {
			if(!p.video) {
				return false;
			}
			if(p.partitaFootballId && p.video.filter(item => item.preferiti != null).length == 0) {
				return false;
			}
			return true;	
		},
		isSelected: function(p) {

			var bk = _.cloneDeep(this.match(p).params);
			var rt = _.cloneDeep(this.$route.params);

			if(bk.paese_id)
				bk.paese_id = parseInt(bk.paese_id);

			if(bk.competizione_id)
				bk.competizione_id = parseInt(bk.competizione_id);

			if(bk.squadra_id)
				bk.squadra_id = parseInt(bk.squadra_id);

			if(bk.pabkite_id)
				bk.pabkite_id = parseInt(bk.pabkite_id);

			if(bk.w_id)
				bk.w_id = parseInt(bk.w_id);


			if(rt.paese_id)
				rt.paese_id = parseInt(rt.paese_id);

			if(rt.competizione_id)
				rt.competizione_id = parseInt(rt.competizione_id);

			if(rt.squadra_id)
				rt.squadra_id = parseInt(rt.squadra_id);

			if(rt.partite_id)
				rt.partite_id = parseInt(rt.partite_id);

			if(rt.w_id)
				rt.w_id = parseInt(rt.w_id);

			return this.$route.name == this.match(p).name && JSON.stringify(bk) == JSON.stringify(rt)

		},
		design: function() {

			var altezza = parseFloat(this.height);

			if(this.$refs.competitionFilter)
				altezza -= parseFloat($(this.$refs.competitionFilter).height());

			var intestazione = $('.prePartite', $(this.$el).parent().parent()).eq(0).height();
			altezza -= parseFloat(intestazione);

			this.ux.altezzaPartite = altezza;

		//	console.log(altezza);

	},
	selezionaPartita: function(match) {

		if(this.isSelected(match)) {
			if(this.$route.params.competizione_id == 0)
				this.$router.push({ name: this.area + '_next_nazione_squadra'  });
			else
				this.$router.push({ name: this.area + '_next_club_paese_competizione_squadra'  });
		}
		else
			this.$router.push(this.match(match));
	},
	selezionaVideo: function(p) {

		if(_.isArray(p.video)) {
			//	console.log('Video Football');
			this.ux.partita = {
				label: p.label,
				footballId: p.partitaFootballId
			};
			this.$refs.modalVideo.show();
		} else {
			this.ux.partita = {
				label: p.label,
				partiteId: p.partiteId
			};
			this.$refs.modalScoutingVideo.show();
			//	console.log('Video Scouting');
		}


		//	console.log('video: ', p.video)
		//	console.log('p: ', p)
	},
	selezionaAllegati: function(p) {
		this.ux.partita = {
			label: p.label,
			partiteId: p.partiteId
		};
		this.$refs.modalDoc.show();
	},
	match: function(i) {

		var name = null;

		if(i.partitaWyScoutId && i.partiteId)
			name = this.area + '_next_club_paese_competizione_squadra_partita_partitewy';
		else if(!i.partitaWyScoutId && i.partiteId)
			name = this.area + '_next_club_paese_competizione_squadra_partita_manuale';
		else if(i.partitaWyScoutId && !i.partiteId)
			name = this.area + '_next_club_paese_competizione_squadra_partita_wy';

		var base = _.cloneDeep(this.$route.params);
		if(base.partite_id != undefined) delete base.partite_id;
		if(base.wy_id != undefined) delete base.wy_id;

		if(i.partiteId)			
			this.$set(base, 'partite_id', i.partiteId);

		if(i.partitaWyScoutId)			
			this.$set(base, 'wy_id', i.partitaWyScoutId);


		if(base.competizione_id == undefined)
			base.competizione_id = 0;

		return {
			name: name,
			params: base
		}

		return base;
	}
}
}

</script>