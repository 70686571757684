import Vue from 'vue'
import { isPlainObject, has, findIndex } from 'lodash'

export default {
  set(state, payload) {
    if (!payload.key) return
    state[payload.key] = payload.value
  },

  setDayEvents( state, datas ) {
    const { entry, category } = datas
    const [ day, events ]  = entry
    state.day_events[day] = events
    
    /** Store/Update in indexedDB */
    const indexedDBObj = {
      'day': day,
      'events': events,
      'category': category
    }

    const idbCalendar = window.indexedDB.open(`session_calendar_${category}`, 1);

    /* Error handler */
    idbCalendar.onerror = ( event ) => {  
    }
    
    /* Create object store */
    idbCalendar.onupgradeneeded = ( event ) => {
        const db          = event.target.result
        db.createObjectStore( "day_events", { keyPath: "day" })
    }
    
    /* Add/Update datas on store */
    idbCalendar.onsuccess = ( event ) => {
        const db          = event.target.result
        const transaction = db.transaction("day_events", "readwrite").objectStore("day_events")
        const allEvents   = transaction.count()
        allEvents.onsuccess = ( event ) => {
          /* if there are more than X elements remove first X day elements */
          if( allEvents.result >= 500 ){
            // const cursorRequestReverse = transaction.openCursor(null, 'prev');
            const cursorRequest = transaction.openCursor();
            let count = 0;
            
            cursorRequest.onsuccess = function(event) {
              const cursor = event.target.result;
              if (!!cursor && count < 50) {
                cursor.delete(); /* Delete (last - count) */
                count++;
                cursor.continue();
              } else {
                transaction.put( indexedDBObj )
              }
            };
          }else{
            transaction.put( indexedDBObj )
          }
        }  

    }
  },
  
  setDayWithoutIDB( state, datas ) {
    const { entry } = datas
    const [ day, events ]  = entry
    state.day_events[day] = events  
  },

  deleteDayEvent(state, id) {
    const index = findIndex( state.day_events, ['id', id])

    if (index !== -1)
        state.day_events.splice(index, 1)
  },

  resetDayEvents(state) {
    state.day_events = []
  }
};
