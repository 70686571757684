<template id="dashboard">
	<section class="allenamenti">

		<headapp></headapp>

		<div class="">
			<div class="card border-0 rounded-0">
				<div class="card-body">
					<div class="card-title">
						<span>Partite</span>
						<div class="pull-right">
							<button class="btn btn-link" v-on:click="vista = 'elenco'" v-b-tooltip.hover title="Vista Elenco" placement="bottom"><i class="fa fa-bars"></i></button>
							<button class="btn btn-link" v-on:click="vista = 'calendario'" v-b-tooltip.hover title="Vista Calendario" placement="bottom"><i class="fa fa-calendar"></i></button>
							<span class="mr-3">|</span>
							<!--b-button variant="outline-primary" :to="{ path: `/football/partite/convocati/aggiungi`}" v-if="auth.isUserEnable('football_partite_mng')"><i class="fa fa-plus fa-fw"></i> Nuova Convocazione</b-button-->
							<b-button variant="outline-primary" :to="{ path: `/football/partite/aggiungi`}" v-if="auth.isUserEnable('football_partite_mng')"><i class="fa fa-plus fa-fw"></i> Nuova partita</b-button>
						</div>
					</div>
					<div class="content">
						<div v-if="vista == 'elenco'">
							<div class="mb-3">
								<b-button variant="outline-primary" @click="get(1, 'all')">Tutte le partite</b-button>
								<b-button variant="outline-primary" @click="get(1, 'Campionato')">Partite di Campionato</b-button>
								<b-button variant="outline-primary" @click="get(1, 'Coppa')">Partite di Coppa</b-button>
								<b-button variant="outline-primary" @click="get(1, 'Amichevole')">Partite Amichevoli</b-button>
								<b-button variant="outline-primary" @click="get(1, 'Torneo')">Partite di Torneo</b-button>
								<b-button variant="outline-primary" @click="get(1, 'Nazionale')">Partite di Nazionale</b-button>
							</div>
							<table class="table table-hover text-center">
								<thead>
									<tr class="row no-gutters align-items-center">
										<th scope="col" class="col-3">Casa</th>
										<th scope="col" class="col-1">Goal</th>
										<th scope="col" class="col-1">Data/Ora</th>
										<th scope="col" class="col-1">Goal</th>
										<th scope="col" class="col-3">Trasferta</th>
										<th scope="col" class="col-3">Video</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="partita in partite"  :key="partita.id" class="row no-gutters border-bottom align-items-center">
										<td @click="show(partita.id)" class="col-3 border-0"><b-button style="float:left;" class="ml-1" size="sm" @click.stop="showCache(partita.id)" title="partita con cache" v-if="auth.isAdmin()"><i class="fa fa-database" aria-hidden="true"></i></b-button> {{partita.home_team}}</td>
										<td @click="show(partita.id)" class="col-1 border-0 h1">{{partita.goals[0]}}</td>
										<td @click="show(partita.id)" class="col-1 border-0" style="line-height: 1;">
											<img :src="partita.src_ico" :alt="partita.type_name" :title="partita.type_name" height="24" /><br /><small>{{partita.start}}</small><br /><small>{{partita.time}}</small>
										</td>
										<td @click="show(partita.id)" class="col-1 border-0 h1">{{partita.goals[1]}}</td>
										<td @click="show(partita.id)" class="col-3 border-0">{{partita.opponent_team}}</td>
										<td class="col-3 border-0">
											<div class="row px-1">
												<div class="col-3">
													<button :class="'btn btn-sm btn-block ' + (partita.video_partita.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(partita, 1)" :disabled="partita.video_partita.length == 0">Partita</button>
												</div>
												<div class="col-3">
													<button :class="'btn btn-sm btn-block ' + (partita.video_goals.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(partita, 4)" :disabled="partita.video_goals.length == 0">Goals</button>
												</div>
												<div class="col-3">
													<button :class="'btn btn-sm btn-block ' + (partita.video_highlights.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(partita, 2)" :disabled="partita.video_highlights.length == 0">Highlights</button>
												</div>
												<div class="col-3">
													<button :class="'btn btn-sm btn-block ' + (partita.video_sintesi.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(partita, 3)" :disabled="partita.video_sintesi.length == 0">Sintesi</button>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="row" v-if="partite_source.last_page != 1">
								<nav aria-label="Page navigation example">
									<ul class="pagination no-margin pull-right">
										<li class="page-item"><a class="page-link" @click="paginate(parseInt(partite_source.current_page) - 1)" >Indietro</a></li>
										<li class="page-item"><a class="page-link" @click="paginate(parseInt(partite_source.current_page) + 1)">Avanti</a></li>
									</ul>
								</nav>
							</div>
						</div>

						<full-calendar v-if="vista == 'calendario'" ref="calendar" :event-sources="eventSources" @event-render="eventRender" @event-selected="eventSelected" @day-click="dayClick" :config="config" @event-drop="eventDrop" ></full-calendar>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="clipsModal" size="xl" ref="clipsModal" :title="titoloClips" class="fs-modal" @hidden="onHiddenClips" @shown="onShowClips" ok-only ok-title="Chiudi">
			<div>
				<video
				id="video-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
			<b-pagination v-show="playlist.length > 1" size="sm" align="center" :total-rows="playlist.length" v-model="count" :per-page="1" @change="selectPageClips" class="mt-1" :limit="40"></b-pagination>
		</div>
	</b-modal>

	<footerapp></footerapp>

</section>
</template>

<script>
import moment from 'moment'
import { FullCalendar } from 'vue-full-calendar'
import * as types from '../../../../store/types'
import axios from 'axios';
import { mapState } from 'vuex';
var Auth = require('../../../../auth.js');
import videojs from 'video.js';
import 'videojs-playlist';
export default {

	data: function(){
		return{
			vista: 'elenco',
			partite:  [],
			partite_source:  [],
			events: 	[],
			config:
			{
				disableResizing: false,
				editable: true,
			},
			selected: null,
			event_id: null,
			title: '',
			level: 0,
			start_at: '',

			eventSources: [{
				events(start, end, timezone, callback) {

					var rosaTeamId = $("#team_football option:selected").val();	
					var seasonId = $("#season option:selected").val();	

					var start = moment(start).format("YYYY-MM-DD HH:mm:ss")
					var end = moment(end).format("YYYY-MM-DD HH:mm:ss")

					const formData = new FormData();
					formData.append("start_at", start);
					formData.append("end_at", end);
					formData.append("team_id", rosaTeamId);
					formData.append("season_id", seasonId);
					formData.set('_method', 'POST');

					const opts = {
						url: `/football/partite`,
						method: 'POST',
						data: formData,
					};

					axios(opts)
					.then((res) => {
						var data = res.data.map(item => ({
							title: item.tipo_categoria.tipo.nome == 'Nazionale' ? '['+ item.tipo_categoria.tipo.nome + '] ' + item.home_team + ' VS ' + item.away_team : '['+ item.tipo_categoria.tipo.nome + '] ' + item.home.name + ' VS ' + item.away.name,
							start: item.data,
							id: item.id,
						}));

						callback(data);
					}).catch((err) => {
						console.log(err);
					});
				},
				color: '#a80532',
				textColor: 'white',
			}],

			titoloClips: '',
			playlist: [],
			count: 0,
		}
	},

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		FullCalendar,
	},

	created: function () {
		this.get();
	},

	methods: {

		handleCancel (evt) {

		},
		handleOk (evt) {
			evt.preventDefault()
			this.handleSubmit();
		},

		handleSubmit () {

		},

		removeEvent() {

		},
		eventSelected(event) {
			this.show(event.id)
		},
		eventRender(event) {
		},
		dayClick(date, jsEvent, view) {
		},
		eventDrop(event, delta, revertFunc) {

		},
		eventResize(event, delta, revertFunc) {
		},
		refreshEvents() {
			this.$refs.calendar.$emit('refetch-events')
		},
		show(ID){
			this.$router.push({ path: `/football/partita/${ID}` })
		},
		showCache(ID){
			this.$router.push({ path: `/football/partita/cache/${ID}` })
		},
		paginate(page){
			this.get(page);
		},
		get(page, type){
			if(this.rosaTeamId && this.seasonId) {

				var page = (page === undefined) ? 1 : page;
				var type = (type === undefined) ? 'all' : type;
				const opts = {
					url: `/football/partite?page=${page}&type=${type}&team_id=${this.rosaTeamId}&season_id=${this.seasonId}`,
					method: 'GET',
				};

				axios(opts)
				.then((res) => {
					
					var data = res.data.data.map(item => ({
						home_team: item.tipo_categoria.tipo.nome == 'Nazionale' ? item.home_team : (item.home ? item.home.name : 'N/D'),
						opponent_team: item.tipo_categoria.tipo.nome == 'Nazionale' ? item.away_team : (item.away ? item.away.name : 'N/D'),

						goals: item.tipo_categoria.tipo.nome == 'Nazionale' ? [(item.home_score ? item.home_score : 0), (item.away_score ? item.away_score : 0)] : (item.eventi.reduce((goals, i) => {
							if(item.home_id == i.team_id) {
								if(i.partita_evento_tipo_glo_id == 4) {
									goals[1] += 1;
								} else {
									goals[0] += 1;
								}
							} else {
								if(i.partita_evento_tipo_glo_id == 4) {
									goals[0] += 1;
								}else{
									goals[1] += 1;
								}
							}
							return goals;
						},[0,0])),
						start: moment(item.data).format("DD-MM-YYYY"),
						time: moment(item.data).format("HH:mm"),
						id: item.id,
						type_name: item.tipo_categoria.tipo.nome,
						src_ico: `assets/partite/icona-${item.tipo_categoria.tipo.nome}.png`,
						video_sintesi: item.sintesi,
						video_goals: item.goals,
						video_highlights: item.highlights,
						video_partita: item.video,
					}));

					this.partite_source = res.data;
					this.partite = data;

				}).catch((err) => {
					console.log(err);
				});
			}
		},
		selectClips(partita, tipo) {
			var titolo = '';
			var clips = [];
			var video = null;
			if(tipo == 1) {
				video = partita.video_partita;
				titolo = 'Video';
			} else if(tipo == 2) {
				clips = partita.video_highlights;
				titolo = 'Highlights';
			} else if(tipo == 3) {
				clips = partita.video_sintesi;
				titolo = 'Sintesi';
			} else if(tipo == 4) {
				clips = partita.video_goals;
				titolo = 'Goals';
			}
			this.count = 1;
			this.playlist = [];
			this.titoloClips = titolo + ' di ' + partita.home_team + ' - ' + partita.opponent_team + ' del ' + partita.start + ' ' + partita.time;
			if(video) {
				for(var i in video) {
					var clip = video[i];
					var source = {
						sources: [{
							src: clip.video,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
			} else {
				for(var i in clips) {
					var clip = clips[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + clip.videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
			}
			this.$refs.clipsModal.show();
		},
		onShowClips() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
				this.$video.on('playlistitem', this.autoSelectPage);
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			this.$video.play();
		},
		onHiddenClips() {
			this.$video.playlist([]);
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;	
		},
		selectPageClips(page) {
			this.$video.playlist.currentItem(page - 1);    
		},
		autoSelectPage() {
			this.count = (this.$video.playlist.currentIndex() + 1);
		},
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},

	watch: {
		rosaTeamId() {
			if( this.vista == 'calendario' ) {
				this.refreshEvents();
			}else{
				this.get(1, 'all')
			}
		},

		seasonId() {
			if( this.vista == 'calendario' ) {
				this.refreshEvents();
			}else{
				this.get(1, 'all')
			}
		},  
	}

}
</script>

<style>
.fc-resizer.fc-end-resizer {
	display: none;
}
</style>
