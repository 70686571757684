<template>
	<div>
		<b-modal :title="getTitle" size="lg" class="fs-modal" ok-only ok-title="Chiudi" v-model="showModal">
			<div v-if="entita">
				<b-form-group label="Nome">
					<b-form-input v-model="entita.nome"></b-form-input>
				</b-form-group>
				<b-form-group label="Gruppo">
					<multiselect v-model="entita.gruppo" :options="entita_gruppi" :multiple="false" placeholder="Seleziona gruppo" :taggable="true" @tag="addGruppoEntita" tag-placeholder="Aggiungi nuovo gruppo"></multiselect>
				</b-form-group>
				<b-form-group label="Descrizione">
					<textarea class="form-control" v-model="entita.descrizione" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Etichetta valore 1" v-if="entita && typeof entita.label_value_1 !== 'undefined'">
					<b-form-input v-model="entita.label_value_1"></b-form-input>
				</b-form-group>
				<b-form-group label="Opzioni valore 1" description='es: { "type": "boolean"} | { "type": "number"} | { "type": "string", "range": ["-", "+", "++"]}' v-if="entita && typeof entita.option_value_1 !== 'undefined'">
					<textarea class="form-control" v-model="entita.option_value_1" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Etichetta valore 2" v-if="entita && typeof entita.label_value_2 !== 'undefined'">
					<b-form-input v-model="entita.label_value_2"></b-form-input>
				</b-form-group>
				<b-form-group label="Opzioni valore 2" description='es: { "type": "boolean"} | { "type": "number"} | { "type": "string", "range": ["-", "+", "++"]}' v-if="entita && typeof entita.option_value_2 !== 'undefined'">
					<textarea class="form-control" v-model="entita.option_value_2" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Etichetta valore 3" v-if="entita && typeof entita.label_value_3 !== 'undefined'">
					<b-form-input v-model="entita.label_value_3"></b-form-input>
				</b-form-group>
				<b-form-group label="Opzioni valore 3" description='es: { "type": "boolean"} | { "type": "number"} | { "type": "string", "range": ["-", "+", "++"]}' v-if="entita && typeof entita.option_value_3 !== 'undefined'">
					<textarea class="form-control" v-model="entita.option_value_3" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Etichetta valore 4" v-if="entita && typeof entita.label_value_4 !== 'undefined'">
					<b-form-input v-model="entita.label_value_4"></b-form-input>
				</b-form-group>
				<b-form-group label="Opzioni valore 4" description='es: { "type": "boolean"} | { "type": "number"} | { "type": "string", "range": ["-", "+", "++"]}' v-if="entita && typeof entita.option_value_4 !== 'undefined'">
					<textarea class="form-control" v-model="entita.option_value_4" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Etichetta valore 5" v-if="entita && typeof entita.label_value_5 !== 'undefined'">
					<b-form-input v-model="entita.label_value_5"></b-form-input>
				</b-form-group>
				<b-form-group label="Opzioni valore 5" description='es: { "type": "boolean"} | { "type": "number"} | { "type": "string", "range": ["-", "+", "++"]}' v-if="entita && typeof entita.option_value_5 !== 'undefined'">
					<textarea class="form-control" v-model="entita.option_value_5" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Etichetta valore 6" v-if="entita && typeof entita.label_value_6 !== 'undefined'">
					<b-form-input v-model="entita.label_value_6"></b-form-input>
				</b-form-group>
				<b-form-group label="Opzioni valore 6" description='es: { "type": "boolean"} | { "type": "number"} | { "type": "string", "range": ["-", "+", "++"]}' v-if="entita && typeof entita.option_value_6 !== 'undefined'">
					<textarea class="form-control" v-model="entita.option_value_6" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Sigla" v-if="entita && typeof entita.sigla !== 'undefined'">
					<b-form-input v-model="entita.sigla"></b-form-input>
				</b-form-group>
				<b-form-group label="Forma farmaceutica" v-if="entita && typeof entita.forma_farmaceutica !== 'undefined'">
					<b-form-select v-model="entita.forma_farmaceutica">
						<option :value="null">seleziona</option>
						<option :value="forma" v-for="forma in option_items.forme_farmaceutiche">{{ forma }}</option>
					</b-form-select>
				</b-form-group>
				<b-form-group label="Via di somministrazione" v-if="entita && typeof entita.via_di_somministrazione !== 'undefined'">
					<b-form-select v-model="entita.via_di_somministrazione">
						<option :value="null">seleziona</option>
						<option :value="via" v-for="via in option_items.vie_di_somministrazione">{{ via }}</option>
					</b-form-select>
				</b-form-group>
				<b-form-group label="Dosaggio" v-if="entita && typeof entita.dosaggio !== 'undefined'">
					<b-form-input v-model="entita.dosaggio"></b-form-input>
				</b-form-group>
				<b-form-group label="Unità di misura" v-if="entita && typeof entita.unita_di_misura !== 'undefined'">
					<b-form-select v-model="entita.unita_di_misura">
						<option :value="null">seleziona</option>
						<option :value="unita" v-for="unita in option_items.unita_di_misura">{{ unita }}</option>
					</b-form-select>
				</b-form-group>
				<b-form-group label="Categoria" v-if="entita && typeof entita.categoria !== 'undefined'">
					<b-form-input v-model="entita.categoria"></b-form-input>
				</b-form-group>
				<b-form-group label="Principi attivi" v-if="entita && typeof entita.principi_attivi !== 'undefined'">
					<textarea class="form-control" v-model="entita.principi_attivi" rows="2"></textarea>
				</b-form-group>
				<b-form-group label="Icona">
					<b-img v-if="entita.icona_id" :src="entita.icona_url" fluid width="50" title="Cambia icona" @click="showIcone" style="cursor:pointer;"></b-img>
					<b-button v-else size="sm" @click="showIcone">Scegli icona <i class="fa fa-search"></i></b-button>
				</b-form-group>
			</div>
			<SelectIcona @settaIcona="settaIcona" ref="selectIcona"></SelectIcona>
			<b-row class="mt-2">
				<b-col>
					<b-button variant="outline-primary" @click="salvaEntita" class="mr-2">Salva</b-button>
					<b-button v-if="entita && entita.id" variant="outline-secondary" @click="eliminaEntita">Elimina</b-button>
				</b-col>
			</b-row>
		</b-modal>

	</div>
</template>

<script>

export default {
	components: {
		SelectIcona: require('../../layout/Medical/SelectIcona.vue').default,
	},
	watch: {
	},
	props: ['tipo', 'entita', 'entita_gruppi', 'option_items'],
	data: function() {
		return {
			showModal: false,
		}
	},

	created: function() {
	},

	computed: {
		getTitle() {
			if(this.entita && this.entita.id) {
				return this.tipo;
			} else return 'Aggiungi ' + this.tipo;
		},
	},
	methods: {
		show: function() {
			this.showModal = true;
		},
		hide: function() {
			this.showModal = false;
		},
		addGruppoEntita(gruppo) {
			this.entita_gruppi.push(gruppo);
			this.entita.gruppo = gruppo;
		},
		salvaEntita() {
			this.$emit('salvaEntita', this.tipo);
		},
		eliminaEntita() {
			this.$emit('eliminaEntita', this.tipo);
		},
		settaIcona(icona) {
			this.entita.icona_id = icona.id;
			this.entita.icona_url = icona.url;
		},

		showIcone() {
			this.$refs.selectIcona.showIcone();
		},
	}
}

</script>