<template>
	<div>
		<b-table striped hover small responsive :items="items" :fields="fields" style="font-size:0.85rem" v-model="table_confronto" foot-clone ref="tbl_confronto" id="tbl_confronto">
			<template #cell(FOOT_giocatore)="data">
				<span title="Media">Media</span>
			</template>
			<template #cell(FOOT_ruolo)="data">
				<span></span>
			</template>	
			<template #cell(FOOT_classe)="data">
				<span>{{ medie_confronto['classe'] }}</span>
			</template>
			<template #cell(FOOT_altezza)="data">
				<span>{{ medie_confronto['altezza'] }}</span>
			</template>
			<template #cell(FOOT_piede)="data">
				<span></span>
			</template>
			<template v-slot:[`cell(FOOT_${field})`]="data" v-for="field in opta_fields">
				<span>{{ medie_confronto[field] }}</span>
			</template>	
			<template #cell(FOOT_voto)="data">
				<span>{{ medie_confronto['voto'] }}</span>
			</template>
		</b-table>
		<b-row class="mt-2">
			<b-col>
				<b-form-group label="Titolo" label-cols="2" content-cols="10">
					<b-form-input v-model="title_confronto"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col>
				<b-button variant="primary" title="Esporta PDF" @click="pdfConfronto"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
				</b-button>
			</b-col>
		</b-row>	
	</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex';
import { jsPDF } from 'jspdf';

export default {

	components: {
	},

	props: ['opta_fields', 'lista_confronto'],

	data: function() {
		return {
			header_pdf: null,
			dati_confronto: {},
			range_confronto: {},
			table_confronto: [],
			title_confronto: '',
			medie_confronto: {},
		}
	},
	created: function () {
		this.headerPdf();
		this.creaDatiConfronto();
	},

	methods: {
		headerPdf() {
			this.$http.get(this.$store.state.apiEndPoint + '/headerpdf/scouting').then((response) => {
				if (response.ok)
				{
					this.header_pdf = response.data;    
				}
			}, (response) => {
			});
		},

		classe: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "-";
		},
		votoMedio: function(valutazioni) {
			var count = 0;
			var voto = 0;
			for (var index in valutazioni) {
				if(valutazioni[index].voto[0] > 0) {
					voto += valutazioni[index].voto[0];
					count++;
				}
			}
			if(voto == 0) {
				return "-";
			}
			return Math.round((voto/count) * 100) / 100;
		},
		creaDatiConfronto() {
			this.dati_confronto = {};
			for(var i in this.lista_confronto) {
				var osservato = this.lista_confronto[i];
				var giocatore = osservato.cognome + " " + osservato.nome.substr(0, 1) + ".";
				var giocatori = [];
				if(i > 0) {
					var giocatori = this.dati_confronto['giocatore'];
				} else {
					this.dati_confronto['giocatore'] = giocatori;
				}
				giocatori.push(giocatore);
				var ruolo = (osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].sigla : "-");
				var ruoli = [];
				if(i > 0) {
					var ruoli = this.dati_confronto['ruolo'];
				} else {
					this.dati_confronto['ruolo'] = ruoli;
				}
				ruoli.push(ruolo);
				var classe =  this.classe(osservato.nascita_data);
				var classi = [];
				if(i > 0) {
					var classi = this.dati_confronto['classe'];
				} else {
					this.dati_confronto['classe'] = classi;
				}
				classi.push(classe);
				var altezza = osservato.info_fisica ? osservato.info_fisica.altezza : '';
				var altezze = [];
				if(i > 0) {
					var altezze = this.dati_confronto['altezza'];
				} else {
					this.dati_confronto['altezza'] = altezze;
				}
				altezze.push(altezza);
				var	piede =  osservato.giocatore ? osservato.giocatore.label_piede : '';
				var piedi = [];
				if(i > 0) {
					var piedi = this.dati_confronto['piede'];
				} else {
					this.dati_confronto['piede'] = piedi;
				}
				piedi.push(piede);

				for(var y in this.opta_fields) {
					var isValue = false;
					var isValue2 = false;
					var value = (osservato.opta && osservato.opta.length && osservato.opta[0].stats.length ? osservato.opta[0].stats[0][this.opta_fields[y]] : "-");
					if(!isNaN(value) && value != 0) {
						value = Math.round(value * 100) / 100;
						isValue = true;
					}
					var value2 = (osservato.opta && osservato.opta.length && osservato.opta[0].stats.length > 1 ? osservato.opta[0].stats[1][this.opta_fields[y]] : "-");
					if(!isNaN(value2) && value2 != 0) {
						value2 = Math.round(value2 * 100) / 100;
						isValue2 = true;
					}
					if(isValue && isValue2) {
						if(this.opta_fields[y].indexOf("_90min") != -1) {
							value = Math.round(((value + value2)/2)  * 100) / 100;
						} else {
							value = value + value2;
						}
					} else if(!isValue && isValue2) {
						value = value2;
					}
					var array_opta = [];
					if(i > 0) {
						var array_opta = this.dati_confronto[this.opta_fields[y]];
					} else {
						this.dati_confronto[this.opta_fields[y]] = array_opta;
					}
					array_opta.push(value);
				}
				var voto =  this.votoMedio(osservato.valutazioni);
				var voti = [];
				if(i > 0) {
					var voti = this.dati_confronto['voto'];
				} else {
					this.dati_confronto['voto'] = voti;
				}
				voti.push(voto);
			}
			this.settaRangeConfronto();	
		},
		settaRangeConfronto() {
			this.range_confronto = {};
			this.medie_confronto = {};
			for(var key in this.dati_confronto) {
				var interval = 0;
				var array = this.dati_confronto[key].slice(0);
				array.sort(function(a, b) {
					if(!isNaN(a) && !isNaN(b)) {
						return b - a;
					} else if(isNaN(a) && !isNaN(b)) {
						return 1;
					} else if(!isNaN(a) && isNaN(b)) {
						return -1;
					} else {
						return 0;
					} 
				});
				var count = array.length;
				if(count > 10) {
					interval = parseInt(count / 10);
				}
				var array_range = [];
				var from = 0;
				var to = interval;
				for(var i = 0 ; i < 10 ; i++) {
					if(to < count) {
						var start = array[from];
						var end = array[to];
						var range = {};
						range['from'] = start;
						range['to'] = end;
						array_range.push(range);
						from = to;
						to = to + interval;
						if(interval == 0) {
							to++;
						}
					}
				}
				this.range_confronto[key] = array_range;
				var media = '';
				var tot = 0;
				var num = 0;
				for(var i in array) {
					if(!isNaN(array[i]) && array[i] > 0) {
						tot += parseFloat(array[i]);	
						num ++;
					}
				}
				if(num > 0) {
					media = tot/num;
					media = Math.round(media * 100) / 100;
					if(key == 'classe') {
						media = Math.round(media);
					}
				}
				this.medie_confronto[key] = media;
			}
		},
		getVariantConfronto(value, key) {
			var variant = '';
			var array_range = this.range_confronto[key];
			if(array_range && array_range.length > 0 && value != "-") {
				for(var i = 0; i < array_range.length ; i++) {
					if(i == 0) {
						if(value <= array_range[i].from && value >= array_range[i].to) {
							variant = 'voto-' + (10 - i);
							break;
						}
					} else if(i == 9) {
						if(value < array_range[i].from) {
							variant = 'voto-' + (10 - (i+1));
							break;
						}

					} else {
						if(value < array_range[i].from && value >= array_range[i].to) {
							variant = 'voto-' + (10 - i);
							break;
						}
					}
				}
			} 
			return variant;
		},
		pdfConfronto() {
			var page_hratio = $("#tbl_confronto").get(0).clientHeight/$("#tbl_confronto").get(0).clientWidth;
			const options = {
				type: 'dataURL',
			}
			this.$html2canvas($("#tbl_confronto").get(0), options).then(canvas => {
				var doc = new jsPDF("l", "mm", "a4");
				var width = doc.internal.pageSize.getWidth();
				var height = doc.internal.pageSize.getHeight();	
				doc.addImage(this.header_pdf, 'JPG', 0, 0, width, 30);
				var y = 40;
				if(this.title_confronto != "") {
					doc.text(150, y, this.title_confronto, null, null, 'center');
					y = 45;
				}
				doc.addImage(canvas, 'PNG', 2, y, width - 4, (width * page_hratio));
				doc.save('confronto.pdf');	
			}); 
		},		
	},

	computed: {
		auth: function() {
			return Auth;
		},
		anni: function() {
			var options = [];
			options.push({ value: '', text: '-- Anno --' });
			var anno = moment().subtract(45, 'years').format('YYYY'); 
			for(var i = 0; i <= 40; i++) {
				options.push({ value: anno, text: anno });
				anno++;
			}
			return options;
		},
		fields: function() {
			var fields = [
				{ key: 'giocatore', label: 'Giocatore', sortable: true, tdClass: 'td_comp_gio', thClass: 'th_comp_gio'},
				{ key: 'ruolo', label: 'Ruolo', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'classe', label: 'Classe', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'altezza', label: 'Altezza', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill'},
				{ key: 'piede', label: 'Piede', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'gm', label: 'Partite', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill'},
				{ key: 'min', label: 'Minuti', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill'},
				{ key: 'goal', label: 'Goal', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'assist', label: 'Assist', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'recovery_90min', label: 'Recovery', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'rcvryD3_90min', label: 'RcvryD3', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'rcvryM3_90min', label: 'RcvryM3', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'rcvryA3_90min', label: 'RcvryA3', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'percTeamRcv_90min', label: 'TeamRcv', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'duels_90min', label: 'Duels', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'duel_90min', label: 'Duel%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'tackles_90min', label: 'Tackle', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'tackle_90min', label: 'Tackle%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'touches_90min', label: 'Touches', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'pass_90min', label: 'Pass%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'psInA3rd_90min', label: 'Ps%InA3', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'keyPass', label: 'kPassTot', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'aerial_90min', label: 'Ariel%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'int_90min', label: 'Int', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'keyPass_90min', label: 'KeyPass', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'tsr_90min', label: 'TSR', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'crossCmp_90min', label: 'Cross', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'oneVsOne_90min', label: '%1v1', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'bgChncCrtd_90min', label: 'BgChCrd', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'expG_90min', label: 'ExpG', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'shotConv_90min', label: 'ShotCv', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
				{ key: 'sog_90min', label: 'SOG', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },	
				];
			return fields;
		},

		items: function() {
			var items = [];
			for(var i = 0; i < this.lista_confronto.length ; i++) {
				var item = { 
					giocatore: this.dati_confronto['giocatore'][i],
					ruolo: this.dati_confronto['ruolo'][i],
					classe:  this.dati_confronto['classe'][i],
					altezza:  this.dati_confronto['altezza'][i],
					piede: this.dati_confronto['piede'][i],
					gm: this.dati_confronto['gm'][i],
					min: this.dati_confronto['min'][i],
					goal: this.dati_confronto['goal'][i],
					assist: this.dati_confronto['assist'][i],
					keyPass: this.dati_confronto['keyPass'][i],
					duel_90min: this.dati_confronto['duel_90min'][i],
					tackle_90min: this.dati_confronto['tackle_90min'][i],
					aerial_90min: this.dati_confronto['aerial_90min'][i],
					int_90min: this.dati_confronto['int_90min'][i],
					pass_90min: this.dati_confronto['pass_90min'][i],
					keyPass_90min: this.dati_confronto['keyPass_90min'][i],
					recovery_90min: this.dati_confronto['recovery_90min'][i],
					psInA3rd_90min: this.dati_confronto['psInA3rd_90min'][i],
					tsr_90min: this.dati_confronto['tsr_90min'][i],
					crossCmp_90min: this.dati_confronto['crossCmp_90min'][i],
					oneVsOne_90min: this.dati_confronto['oneVsOne_90min'][i],
					rcvryA3_90min: this.dati_confronto['rcvryA3_90min'][i],
					bgChncCrtd_90min: this.dati_confronto['bgChncCrtd_90min'][i],
					expG_90min: this.dati_confronto['expG_90min'][i],
					shotConv_90min: this.dati_confronto['shotConv_90min'][i],
					sog_90min: this.dati_confronto['sog_90min'][i],
					tackles_90min: this.dati_confronto['tackles_90min'][i],
					rcvryD3_90min: this.dati_confronto['rcvryD3_90min'][i],
					rcvryM3_90min: this.dati_confronto['rcvryM3_90min'][i],
					percTeamRcv_90min: this.dati_confronto['percTeamRcv_90min'][i],
					duels_90min: this.dati_confronto['duels_90min'][i],
					touches_90min: this.dati_confronto['touches_90min'][i],
				};
				var variant_obj = {};
				variant_obj['classe'] = this.getVariantConfronto(this.dati_confronto['classe'][i], 'classe');
				variant_obj['altezza'] = this.getVariantConfronto(this.dati_confronto['altezza'][i], 'altezza');
				for(var y in this.opta_fields) {
					variant_obj[this.opta_fields[y]] = this.getVariantConfronto(this.dati_confronto[this.opta_fields[y]][i], this.opta_fields[y]);	
				}
				variant_obj['voto'] = this.getVariantConfronto(this.dati_confronto['voto'][i], 'voto');
				item['_cellVariants'] = variant_obj;
				items.push(item);
			}
			return items;
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YY'); 
			}
			return "";
		}
	},

}
</script>
