<template id="gstats">
	<div class="gstats">
		<b-tabs card>
			<b-tab v-for="(season, index) in params.seasons" :title="season.stagione">
				<table class="table table-striped">
					<thead>
						<tr>
							<th title="Rosa">Type</th>
							<th v-for="department in season.departments" class="text-center" title="Department">{{ getDepartment(department) }}</th>
							<th class="text-center" title="Tot">Tot</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="content in season.contents">
							<td>{{  getContent(content) }}</td>
							<td class="text-center" v-for="department in season.departments"><div class="color-rosso" style="cursor:pointer" @click="getAttivitaGiocatore(season.stagione, content, department)">{{ season.count[content + '_' + department] }}</div></td>	
							<td class="text-center"><div class="color-rosso" style="cursor:pointer" @click="getAttivitaGiocatore(season.stagione, content, 0)">{{ getTotByContent(season, content) }}</div></td>
						</tr>
					</tbody>
					<thead>
						<tr>
							<th>Total activities</th>
							<th v-for="department in season.departments" class="text-center"><div class="color-rosso" style="cursor:pointer" @click="getAttivitaGiocatore(season.stagione, 0, department)">{{ getTotByDepartment(season, department) }}</div></th>
							<th class="text-center" title="Tot"><div class="color-rosso" style="cursor:pointer" @click="getAttivitaGiocatore(season.stagione, 0, 0)">{{ getTot(season) }}</div></th>
						</tr>
					</thead>
				</table>
			</b-tab>
		</b-tabs>
		<b-modal id="attivitaModal" ref="attivitaModal" title="Attività" centered ok-title="Chiudi" ok-only size="lg">
			<b-container fluid>
				<b-row class="mb-1" v-if="attivita">
					<b-col>
						<div class="border p-1 mb-1" style="font-size: 0.9rem;">
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Department: 
								</b-col>
								<b-col cols="2">
									{{ getDepartment(attivita.richiesta.department) }}	
								</b-col>
								<b-col>
									{{ attivita.richiesta.department_text }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Content: 
								</b-col>
								<b-col cols="2">	
									{{ getContent(attivita.richiesta.content) }}	
								</b-col>

								<b-col>
									{{ attivita.richiesta.content_text }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Required by: 
								</b-col>
								<b-col>
									{{ attivita.richiesta.required_by }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Period: 
								</b-col>
								<b-col>
									{{ attivita.richiesta.period }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Location: 
								</b-col>
								<b-col cols="2">
									{{ getLocation(attivita.richiesta.location) }}		
								</b-col>
								<b-col>
									{{ attivita.richiesta.location_text }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Player/s: 
								</b-col>
								<b-col cols="2">	
									<strong><em>Desiderata</em></strong>
								</b-col>
								<b-col>
									<span v-for="(item, key) in JSON.parse(attivita.richiesta.players_desiderata)">
										{{ item.name }} 
										<span v-if="key < (JSON.parse(attivita.richiesta.players_desiderata)).length - 1">, </span>
									</span>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
								</b-col>
								<b-col cols="2">	
									<strong><em>Alternative</em></strong>
								</b-col>
								<b-col>
									<span v-for="(item, key) in JSON.parse(attivita.richiesta.players_alternative)">
										{{ item.name }} 
										<span v-if="key < (JSON.parse(attivita.richiesta.players_alternative)).length - 1">, </span>
									</span>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Timing: 
								</b-col>
								<b-col>
									{{ attivita.richiesta.timing }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Dress code: 
								</b-col>
								<b-col>
									{{ attivita.richiesta.dress_code }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Description: 
								</b-col>
								<b-col>
									{{ attivita.richiesta.description }}
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Note: 
								</b-col>
								<b-col>
									{{ attivita.richiesta.note }}

								</b-col>
							</b-row>
						</div>
						<b-pagination size="sm" align="center" :total-rows="attivitas.length" v-model="page_att" :per-page="1" @change="selectPageAttivita"></b-pagination>
					</b-col>
				</b-row>
			</b-container>
		</b-modal>
	</div>
</template>

<script>
export default {

	data: function() {
		return {
			options_content: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Interviews'},
			{ value: 2, text: 'Shootings (foto&video)'},
			{ value: 3, text: 'Events'},
			{ value: 4, text: 'Appereances'},
			{ value: 5, text: 'Other'}
			],

			options_department: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Communication'},
			{ value: 2, text: 'Partnerships'},
			{ value: 3, text: 'Brand'},
			{ value: 4, text: 'Roma Department'},
			{ value: 5, text: 'Digital'},
			{ value: 7, text: 'Sustainability Department'},
			{ value: 6, text: 'Other'}
			],
			options_location: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Internal'},
			{ value: 2, text: 'External'},
			],
			attivitas: [],
			attivita: null,
			page_att: 1,
		}
	},

	props: {
		params: {
			type: Object,
			required: false
		}
	},

	methods: {
		getContent(content) {
			var index = this.options_content.findIndex((item) => item.value == content);
			if(index > -1) {
				return this.options_content[index].text;
			}
			return "";
		},

		getDepartment(department) {
			var index = this.options_department.findIndex((item) => item.value == department);
			if(index > -1) {
				return this.options_department[index].text;
			}
			return "";
		},

		getLocation(location) {
			var index = this.options_location.findIndex((item) => item.value == location);
			if(index > -1) {
				return this.options_location[index].text;
			}
			return "";
		},

		getTotByContent(season, content) {
			var count = 0;
			var keys = Object.keys(season.count);
			for(var i in keys) {
				var array = keys[i].split('_');
				var key_content = array[0];
				if(key_content == content) {
					count += season.count[keys[i]];
				}
			}
			return count;
		},

		getTotByDepartment(season, department) {
			var count = 0;
			var keys = Object.keys(season.count);
			for(var i in keys) {
				var array = keys[i].split('_');
				var key_department = array[1];
				if(key_department == department) {
					count += season.count[keys[i]];
				}
			}
			return count;
		},

		getTot(season) {
			var count = 0;
			var keys = Object.keys(season.count);
			for(var i in keys) {
				count += season.count[keys[i]];
			}
			return count;
		},

		getAttivitaGiocatore(stagione, content, department) {
			this.attivita = null;
			this.attivitas = [];
			this.page_att = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/playeraccess/view/attivita/giocatore/' + this.params.rosa_id, {params: { stagione: stagione, content: content, department: department }}).then((response) => {
				if (response.ok)
				{
					this.attivitas = response.data;
					if(this.attivitas.length > 0) {
						this.attivita = this.attivitas[0];
					}
					this.$refs.attivitaModal.show();
				}
			}, (response) => {
			});
		},

		selectPageAttivita(page) {
			this.attivita = this.attivitas[page - 1];   
		},
	}
}
</script>
