<template>
	<div class="my-3">
		<div class="mb-2">Tipologia di Prevenzione</div>
		<b-row class="mb-1">
			<b-col>
				<label></label>
				<b-form-select v-model="estensione.primo_livello">
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Primaria', 'Secondaria']" @click.prevent="estensione.primo_livello = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
			<b-col>
				<label></label>
				<b-form-select v-model="estensione.secondo_livello" :disabled="!estensione.primo_livello">
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Propriocezione', 'Propriocezione caviglia', 'Propriocezione ginocchio', 'Scapolo-omerale', 'Back', 'Potenziamento', 'Allungamento/mobilità', 'Allungamento dinamico cat. anteriore', 'Allungamento dinamico cat. posteriore', 'Mobilità tibio-tarsica', 'Mobilità coxo-femorale', 'Core - stability']" @click.prevent="estensione.secondo_livello = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
			<b-col>
				<label></label>
				<b-form-select v-model="estensione.terzo_livello_1" :disabled="!estensione.secondo_livello">
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Generale', 'Specifico']" @click.prevent="estensione.terzo_livello_1 = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
			<b-col>
				<label></label>
				<b-form-select v-model="estensione.terzo_livello_2" :disabled="!estensione.secondo_livello">
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Statico', 'Dinamico']" @click.prevent="estensione.terzo_livello_2 = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
		sessionMixin
	],
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { primo_livello: null, secondo_livello: null, terzo_livello_1: null, terzo_livello_2: null  });
		}
	},
	props: [ 'estensione', 'model', 'field' ],

	watch: {
		'estensione': {
			deep: true,
			handler: function() {
				if(!this.model.id) {
					var label =  [this.estensione.primo_livello, this.estensione.secondo_livello, this.estensione.terzo_livello_1, this.estensione.terzo_livello_2].join('').length ? ['Prevenzione', this.estensione.primo_livello, this.estensione.secondo_livello, this.estensione.terzo_livello_1, this.estensione.terzo_livello_2].join(' ') : null;
					if(label) {
						this.$emit('updateNome', label);
					}
				}
			}
		}

	}
};
</script>