<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>{{ osservatore.nomeCompleto }}</span>
					</div>
					<div class="content">
						<b-row class="mb-1">
							<b-col cols="3">
								<b-form-group label="Lista" label-cols="2" content-cols="10">
									<b-form-select v-model="lista_selected" :options="osservatore.liste_utente" value-field="id" text-field="nome"/>
								</b-form-group>
							</b-col>
							<b-col cols="3">
								<b-form-select v-model="sistema_gioco">
									<option value="0">-- Sistemi di gioco --</option>
									<option v-for="item in sistemi_gioco" :value="item.id">{{item.sistema_gioco}}</option>
								</b-form-select>
							</b-col>
							<b-col cols="5">
							</b-col>
							<b-col cols="1">
								<b-button variant="primary" title="Archivio PDF" @click="archivioPdfLista" class="ml-1"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
								</b-button>
							</b-col>
						</b-row>
						<b-row class="mb-1" v-if="auth.isAdmin()">
							<b-col cols="3">
								<b-form-group
								label="Live dal" label-cols="3" content-cols="9">
								<b-form-input type="date" v-model="form.live_dal"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<b-form-group
							label="al" label-cols="3" content-cols="9" label-text-align="right">
							<b-form-input type="date" v-model="form.live_al"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<div style="position:absolute;z-index:1">
					<b-container>
						<b-row v-for="giocatore in form_non_in_campo" v-if="giocatore && giocatore instanceof Object">
							<b-col class="notInCampo"><b-link :href="'#/youthscout/view/giocatore/' + giocatore.id" target="_blank" style="color:black;"><span style="color:#0000FF">{{ giocatore.cognome.toUpperCase() }}</span> <span style="color:#FF6600">{{ giocatore.nascita_data | formatYear }}</span> <img :src="'/assets/smiles/grad_' + report.id_gradimento + '.png'" width="10" :title="getTitleGrad(report)" v-if="showGrad(report)" v-for="report in giocatore.partite_report_giocatori"/>{{ giocatore.last_carriera_sportiva.squadra ? giocatore.last_carriera_sportiva.squadra.name : '' }} </b-link></b-col></b-row>	
						</b-container> 
					</div>
					<div :class="'sistema sistema-' + sistema_gioco">
						<div class="campo">
							<img src="/assets/images/campo.svg">
						</div>
						<div v-if="sistema_gioco > 0" v-for="(item, key) in form_in_campo" :class="'player player-'+ moduli_ruoli[sistema_gioco][key - 1]">
							<span class="label-value">{{ getNomeRuolo(moduli_ruoli[sistema_gioco][key - 1]) }}</span>
							<b-container>
								<b-row v-for="giocatore in item" v-if="giocatore && giocatore instanceof Object"><b-col class="truncate"><b-link :href="'#/youthscout/view/giocatore/' + giocatore.id" target="_blank" style="color:black;"><span style="color:#0000FF">{{ giocatore.cognome.toUpperCase() }}</span> <span style="color:#FF6600">{{ giocatore.nascita_data | formatYear }}</span> <img :src="'/assets/smiles/grad_' + report.id_gradimento + '.png'" width="10" :title="getTitleGrad(report)" v-if="showGrad(report)" v-for="report in giocatore.partite_report_giocatori"/> {{ giocatore.last_carriera_sportiva.squadra ? giocatore.last_carriera_sportiva.squadra.name : '' }}</b-link></b-col></b-row>
							</b-container> 
						</div>
					</div>
					<b-row class="mb-1">
						<b-col cols="12">
							<b-btn variant="primary" v-b-toggle="'collapse'" v-if="auth.isAdmin()">Salva su archivio PDF <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
						</b-col>
						<b-col cols="12">
							<b-collapse id="collapse" ref="collapse" class="mb-1">
								<b-row>
									<b-col cols="3">
										<b-form-group
										label="Titolo" :label-cols="2">
										<b-form-input type="text" v-model="form.titolo"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group
									label="Data">
									<b-form-input type="date" v-model="form.data"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="7">
							</b-col>
							<b-col cols="7">
								<b-form-group
								label="Note" :label-cols="1">
								<b-form-textarea v-model="form.note" :rows="3" :max-rows="6"></b-form-textarea>
							</b-form-group>
						</b-col>
						<b-col cols="5">
						</b-col>
						<b-col cols="12">
							<b-btn variant="primary" @click="salvaPdf">Salva</b-btn>
						</b-col>
					</b-row>					
				</b-collapse>
			</b-col>	
		</b-row>
	</div>
	<b-modal ref="pdfModal" title="Archivio PDF" ok-only ok-title="Chiudi">	
		<b-table :items="elenco_pdf" :fields="['titolo', {key: 'data', formatter: this.formatDate}, 'azioni']">
			<template #cell(azioni)="row">
				<b-button size="sm" title="download PDF" @click="downloadPdf(row.item.id)">
					<i class="fa fa-download" aria-hidden="true"></i>
				</b-button>
				<b-button size="sm" title="elimina PDF" @click="eliminaPdf(row.item.id)" v-if="auth.isAdmin()">
					<i class="fa fa-trash" aria-hidden="true"></i>
				</b-button>
			</template>
		</b-table>
	</b-modal>
</div>
</div>
</div>

<footerapp></footerapp>

</section>
</template>

<script>
import moment from 'moment'
var Auth = require('../../../auth.js');

export default {

	data: function() {
		return {
			osservatore: {},
			sistemi_gioco: [],
			sistema_gioco: 4,
			lista_selected: 0,
			lista_giocatori: [],
			lista_in_campo: '',
			ruoli: [],
			moduli_ruoli: {
				1: [3, 10, 6, 5, 9, 20, 18, 17, 19, 26, 27], /* 4-4-2 */
				2: [3, 10, 6, 5, 9, 13, 18, 17, 16, 26, 27], /* 4-4-2 rombo*/
				3: [3, 10, 6, 5, 9, 20, 18, 17, 19, 21, 26], /* 4-4-1-1 */
				4: [3, 10, 6, 5, 9, 15, 13, 14, 25, 27, 24], /* 4-3-3 */
				5: [3, 8, 4, 7, 20, 18, 17, 19, 25, 27, 24], /* 3-4-3 */
				6: [3, 8, 4, 7, 12, 18, 13, 17, 11, 26, 27], /* 3-5-2 */
				7: [3, 10, 6, 5, 9, 18, 17, 23, 21, 22, 26], /* 4-2-3-1 */
				8: [3, 10, 6, 5, 9, 15, 13, 14, 21, 28, 27], /* 4-3-2-1 */
				9: [3, 10, 6, 5, 9, 18, 13, 17, 21, 26, 27], /* 4-3-1-2 */
				10: [3, 10, 6, 5, 9, 13, 15, 16, 14, 26, 27], /* 4-1-3-2 */
				11: [3, 10, 6, 5, 9, 13, 20, 18, 17, 19, 26], /* 4-1-4-1 */
				12: [3, 8, 4, 7, 20, 18, 17, 19, 21, 25, 24], /* 3-4-1-2 */
				13: [3, 8, 4, 7, 20, 18, 17, 19, 23, 22, 27], /* 3-4-2-1 */
				14: [3, 12, 8, 4, 7, 11, 18, 17, 25, 26, 24], /* 5-2-3 */
				15: [3, 12, 8, 4, 7, 11, 15, 13, 14, 26, 27], /* 5-3-2 */
				16: [3, 12, 8, 4, 7, 11, 20, 18, 17, 19, 26], /* 5-4-1 */
			},
			elenco_pdf: [],	
			form: {
				titolo: '',
				data: '',
				note: '',
				live_dal: null,
				live_al: null,
			},
			form_in_campo: {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			},
			form_non_in_campo: [],
			formazione_id: null,
			formazione_ids: [],				
		}
	},
	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {

			if (response.ok)
			{
				this.ruoli = response.data;    
			}
		}, (response) => {
		});
		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
			if (response.ok)
			{
				this.sistemi_gioco = response.data; 
			}
		}, (response) => {
		});
		this.$http.get(this.$store.state.apiEndPoint + '/scouting/osservatore/liste/' + this.$route.params.id).then((response) => {
			if (response.ok)
			{
				this.osservatore = response.data; 
				this.lista_selected = this.osservatore.liste_utente[0].id;
			}
		}, (response) => {
		});
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},
	computed: {
		auth: function() {
			return Auth;
		},
	},
	methods: {
		listaInCampo() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/lista/in/campo/' + this.lista_selected).then((response) => {
				if (response.ok)
				{
					this.lista_in_campo = response.data;
					this.setFormazioneLista(); 
				}
			}, (response) => {
            // error callback
        });
		},
		setFormazioneLista() {
			this.form_in_campo = {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			};
			this.form_non_in_campo = [];
			this.formazione_ids = [];
			this.formazione_id = null;
			for(var i in this.lista_in_campo.formazioni) {
				if(this.lista_in_campo.formazioni[i].id_sistema_gioco == this.sistema_gioco) {
					this.formazione_id = this.lista_in_campo.formazioni[i].id;
					this.form_in_campo = JSON.parse(this.lista_in_campo.formazioni[i].in_campo);
					this.form_non_in_campo = JSON.parse(this.lista_in_campo.formazioni[i].non_in_campo);
					break;
				}
			}
			if(this.formazione_id) {
				for(var i in this.form_in_campo) {
					var array_gio = this.form_in_campo[i];
					for(var j in array_gio) {
						var id_gio = array_gio[j];
						for(var x in this.lista_in_campo.giocatori) {
							var giocatore = this.lista_in_campo.giocatori[x]; 
							if(giocatore.id == id_gio) {
								array_gio[j] = giocatore;
								this.formazione_ids.push(id_gio);
								break;
							}
						}
					}
					this.form_in_campo[i] = this.form_in_campo[i].filter(item => item.cognome);
				}
				for(var i in this.form_non_in_campo) {
					var id_gio = this.form_non_in_campo[i];
					for(var x in this.lista_in_campo.giocatori) {
						var giocatore = this.lista_in_campo.giocatori[x]; 
						if(giocatore.id == id_gio) {
							this.form_non_in_campo[i] = giocatore;
							this.formazione_ids.push(id_gio);
							break;
						}
					}
				}
				this.form_non_in_campo = this.form_non_in_campo.filter(item => item.cognome);
			}
			for(var i in this.lista_in_campo.giocatori) {
				var giocatore = this.lista_in_campo.giocatori[i];
				if(this.formazione_ids.indexOf(giocatore.id) === -1) {
					var index = this.getPosizioneGiocatore(giocatore);
					if(index > -1) {
						this.form_in_campo[index + 1].push(giocatore);
					} else {
						this.form_non_in_campo.push(giocatore);
					}
				}
			}

		},
		getPosizioneGiocatore(giocatore) {	
			var index = -1;
			var array_ruoli = this.moduli_ruoli[this.sistema_gioco];

			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				var ruolo_ideale = giocatore.last_carriera_sportiva.ruolo_ideale[0];
				if(array_ruoli.indexOf(ruolo_ideale.id) > -1) {
					return array_ruoli.indexOf(ruolo_ideale.id);
				} else if(giocatore.last_carriera_sportiva.altri_ruoli && giocatore.last_carriera_sportiva.altri_ruoli.length > 0) {
					for(var i in giocatore.last_carriera_sportiva.altri_ruoli) {
						var altro_ruolo_id = giocatore.last_carriera_sportiva.altri_ruoli[i].id;
						if(array_ruoli.indexOf(altro_ruolo_id) > -1) {
							return array_ruoli.indexOf(altro_ruolo_id);
						}
					}
				} 
			}  
			return index;
		},
		getNomeRuolo(id) {
			for(var i in this.ruoli) {
				var ruolo = this.ruoli[i];
				if(ruolo.id == id) {
					return ruolo.nome.toUpperCase();
				}
			}
			return "";
		},
		hasRuolo(giocatore, ruolo_id) {	
			var array_ruoli = this.moduli_ruoli[this.sistema_gioco];
			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				var ruolo_ideale = giocatore.last_carriera_sportiva.ruolo_ideale[0];
				if(ruolo_ideale.id == ruolo_id) {
					return true;
				} else if(array_ruoli.indexOf(ruolo_ideale.id) > -1) {
					return false;
				} else if(giocatore.last_carriera_sportiva.altri_ruoli && giocatore.last_carriera_sportiva.altri_ruoli.length > 0) {
					var count = 0;
					for(var i in giocatore.last_carriera_sportiva.altri_ruoli) {
						var altro_ruolo_id = giocatore.last_carriera_sportiva.altri_ruoli[i].id;
						if(array_ruoli.indexOf(altro_ruolo_id) > -1) {
							count++;		
						}
						if(altro_ruolo_id == ruolo_id) {
							if(count == 1) {
								return true;
							} else {
								return false;
							}
						}
					}
				} 
			}  
			return false;
		},
		notHasRuolo(giocatore) {	
			var array_ruoli = this.moduli_ruoli[this.sistema_gioco];
			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				var ruolo_ideale = giocatore.last_carriera_sportiva.ruolo_ideale[0];
				if(array_ruoli.indexOf(ruolo_ideale.id) > -1) {
					return false;
				}
			}
			if(giocatore.last_carriera_sportiva.altri_ruoli && giocatore.last_carriera_sportiva.altri_ruoli.length > 0) {
				for(var i in giocatore.last_carriera_sportiva.altri_ruoli) {
					var altro_ruolo_id = giocatore.last_carriera_sportiva.altri_ruoli[i].id;
					if(array_ruoli.indexOf(altro_ruolo_id) > -1) {
						return false;	
					}
				}
			}
			return true;
		},
		archivioPdfLista() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/pdf/lista/' + this.lista_selected).then((response) => {
				if (response.ok)
				{
					this.elenco_pdf = response.data; 
					this.$refs.pdfModal.show();
				}
			}, (response) => {
            // error callback
        });
		},
		salvaPdf() {
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/pdf/lista/' + this.lista_selected + '/' + this.sistema_gioco, this.form).then(response => {
				if (response.ok) {
					this.$refs.collapse.toggle();
					window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + response.data + "/" + Auth.user.id + "/" + Auth.user.api_token;		
				}
			}, (response) => {
            // error callback
        });
		},
		eliminaPdf(pdf) {
			if(confirm("Confermi l'eliminazione del pdf?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/pdf/lista/' + pdf).then((response) => {
					if (response.ok)
					{
						this.elenco_pdf = response.data; 
					}
				}, (response) => {
	            // error callback
	        });
			}
		},
		formatDate (data) {
			var m = moment(data);
			var format = m.format('DD/MM/YYYY'); 
			return format;
		},
		downloadPdf(pdf) {
			window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + pdf + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		showGrad(report) {
			return false;
			
			if(!Auth.isAdmin()) {
				return false;
			}
			if(!report.partita_report.partita.newscout) {
				return false;
			} 
			if(!report.gradimento) {
				return false;
			} 
			if(!this.form.live_dal && !this.form.live_al) {
				return true;
			} else if(this.form.live_dal && this.form.live_al) {
				if(report.partita_report.created_at >= this.form.live_dal + ' 00:00:00' && report.partita_report.created_at <= this.form.live_al + ' 23:59:59') {
					return true;

				}
			} else if(this.form.live_dal && !this.form.live_al) {
				if(report.partita_report.created_at >= this.form.live_dal + ' 00:00:00') {
					return true;
				}

			} else if(!this.form.live_dal && this.form.live_al) {
				if(report.partita_report.created_at <= this.form.live_al + ' 23:59:59') {
					return true;
				}
			}
			return false;
		},
		getTitleGrad(report) {
			var title = '';
			if(report.gradimento) {
				title = report.gradimento.nome + ' del ' + moment(report.partita_report.created_at).format("DD/MM/YYYY") + ' di ' + report.partita_report.utente.nomeCompleto;
			}
			return title;
		}

	},
	watch: {
		lista_selected: function () {
			this.listaInCampo();
		},
		sistema_gioco: function () {
			this.setFormazioneLista();
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YY'); 
			}
			return "";
		}
	},
}
</script>