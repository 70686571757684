export default {
    fetchEvents( { commit }, filters) {
        commit('setEvents', [] );
    },

    fetchIndexedDBDaysEvents( {commit}, searchCategory ) {
        return new Promise( ( resolve ) => {
          
          const idbCalendar = window.indexedDB.open(`session_calendar_${searchCategory}`, 1);
          
          /* Error handler */
          idbCalendar.onerror = function( event ){
            console.log('Errore on IDB open');
            resolve(false)
          }
          
          /* Create object store */
          idbCalendar.onupgradeneeded = function( event ){
              const db = event.target.result
              db.createObjectStore( "day_events", { keyPath: "day" })
          }
    
          /* Add/Update datas on store */
          idbCalendar.onsuccess = async function( event ){
            const db      = event.target.result
            const request = db.transaction("day_events", "readwrite").objectStore("day_events").getAll()

            request.onerror = function(event){
              console.log('Errore on IDB transaction open');
              resolve(false)
            }
            
            request.onsuccess = function( event ){
              event.target.result.forEach( function(entry){
                const { day, events, category } = entry
                if( searchCategory == category ){
                    
                  const datas = {
                      entry: [day,events],
                      category: category
                  }

                  commit('setDayWithoutIDB', datas );
                }
              })

              resolve(true)
            }

          }
        })
    },
    
    flushIndexedDBDaysEvents( {commit}, searchCategory ) {
        return new Promise( ( resolve ) => {
          window.indexedDB.databases().then((r) => {
            for (var i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
          })
          .then( ( res ) => resolve(true) )
          .catch( ( err ) => resolve(false) );
        })
    },
};
