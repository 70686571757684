<template>
	<div>
		<b-row v-if="original_medical_regions && medical_regions_map && medical_regions_supergroups && medical_regions_groups">
			<b-col cols="6">
				<SelettoreCorpo @unselect="multiUnselect($event)" @select="multiSelect($event)" :parts="medical_regions" :supergruppi="medical_regions_supergroups" :gruppi="medical_regions_groups" :selected="model[field].body_parts"></SelettoreCorpo>
			</b-col>
			<b-col cols="6">
				<treedbody :disabled="disabled" :regioniSelezionate="model[field].body_parts" :mapping="medical_regions" :zones="medical_regions_map" :hash="model[field].percorso" @path="!disabled ? model[field].percorso = $event : false" @upd="updateRegionsSelected($event)"></treedbody>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	name: 'Fase_Ext_BodyParts',
	components: {
		SelettoreCorpo: require('@/components/layout/Medical/SelettoreZoneCorpo.vue').default,
		treedbody: require('@/components/layout/3dbody.vue').default
	},
	mixins: [
		sessionMixin
	],
	data: function() {
		return {
			original_medical_regions: null,
			medical_regions: null,
			medical_regions_map: null,
			medical_regions_supergroups: null,
			medical_regions_groups: null
		}
	},
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { 
				percorso: null,
				body_parts: []
			});
		}

		this.getMedicalRegions();
		this.getMedicalRegionsMap();
		this.getMedicalRegionsSuperGroups();
		this.getMedicalRegionsGroups();

	},
	methods: {
		updateRegionsSelected: function(content) {
			if(!this.disabled) {
				this.model[this.field].body_parts = content;
			}
		},
		multiUnselect: function(ids) {
			//console.log('multiUnselect', ids);
			var toUnselect = [];
			_.each(ids, function(id) {
				var k = _.findKey(this.model[this.field].body_parts, { id });
				this.model[this.field].body_parts.splice(k, 1);
			}.bind(this));
		},
		multiSelect: function(ids) {
			//console.log('multiSelect', ids);
			_.each(ids, function(id) {
				var obj = _.find(this.medical_regions, { id });
				if(!_.find(this.model[this.field].body_parts, { id }))
					this.model[this.field].body_parts.push(_.cloneDeep(obj));
			}.bind(this));
		},
		getMedicalRegions() {
			this.$http.get(this.$store.state.apiEndPoint + '/body-parts/regions').then((response) => {
				if (response.ok) {
					this.original_medical_regions = response.data;
					this.medical_regions = response.data.map(item => ({								
						name: item.name,
						id: item.id,
					}));
				}
			}, (response) => {});
		},
		getMedicalRegionsMap() {
			this.$http.get(this.$store.state.apiEndPoint + '/body-parts/map').then(function(data) {
				this.medical_regions_map = data.data;
			})
		},
		getMedicalRegionsSuperGroups() {
			this.$http.get(this.$store.state.apiEndPoint + '/body-parts/super-groups').then(function(data) {
				this.medical_regions_supergroups = data.data;
			})
		},
		getMedicalRegionsGroups() {
			this.$http.get(this.$store.state.apiEndPoint + '/body-parts/groups').then(function(data) {
				this.medical_regions_groups = _.map(data.data, function(a) {
					a.group_ids = JSON.parse(a.group_ids);
					return a;
				});
			})
		},
	},
	computed: {
		canBeDeleted: function() {
			if(this.model[this.field].body_parts.length > 0) return false;
			return true;
		}
	},
	props: [ 'estensione', 'model', 'field', 'sessione', 'disabled' ],
};
</script>

