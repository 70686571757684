export const ROUTES_YOUNGSCOUT = [
    {
        path: '/youngscout/dashboard',
        name: 'youngscout_dashboard',
        component: require('../components/pages/youngscout/dashboard.vue').default,
        meta: {
            module: 'youngscout',

            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'youngscout',
        }
    },
    {
        path: '/youngscout/lista/in/campo/:id',
        name: 'youngscout_lista_in_campo',
        component: require('../components/pages/youngscout/lista_in_campo.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Lista',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'youngscout',
            logModel: 'Liste',
            logModelId: 'id',
        }
    },
    {
        path: '/youngscout/lista/in/campo/new/:id/:formazioneId?',
        name: 'youngscout_lista_in_campo_new',
        component: require('../components/pages/youngscout/lista_in_campo_dev.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Lista',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'youngscout',
            logModel: 'Liste',
            logModelId: 'id',
        }
    },
    {
        path: '/next/young/new/giocatore',
        name: 'young_next_new_giocatore',
        component: require('../components/pages/next-scouting/giocatori/new.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_add_player',
        }
    },  
    {
        path: '/youngscout/view/giocatore/:id',
        name: 'youngscout_view_giocatore',
        component: require('../components/pages/next-scouting/giocatori/view.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youngscout_nuova_valutazione',
            menusub: [ 'youngscout_edit_giocatore', 'youngscout_nuova_valutazione'],
            permesso: 'youngscout',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youngscout/edit/giocatore/:id',
        name: 'youngscout_edit_giocatore',
        component: require('../components/pages/next-scouting/giocatori/edit.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Modifica Anagrafica',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youngscout_view_giocatore',
            menusub: ['youngscout_view_giocatore'],
            permesso: 'scout_edit_player',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youngscout/edit/valutazione/:id/:val_id',
        name: 'youngscout_edit_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/edit.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youngscout_view_giocatore',
            menusub: ['youngscout_view_giocatore', 'youngscout_nuova_valutazione'],
            permesso: 'scout_edit_val',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/youngscout/nuova/valutazione/:id',
        name: 'youngscout_nuova_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/new.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youngscout_view_giocatore',
            menusub: ['youngscout_view_giocatore'],
            permesso: 'scout_add_val',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youngscout/view/valutazione/:id/:val_id',
        name: 'youngscout_view_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/view.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Valutazione Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youngscout_view_giocatore',
            menusub: ['youngscout_view_giocatore', 'youngscout_edit_valutazione', 'youngscout_nuova_valutazione'],
            permesso: 'youngscout',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/youngscout/view/valutazione/football/:id/:val_id',
        name: 'youngscout_view_valutazione_football',
        component: require('../components/pages/next-scouting/valutazioni/view_football.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Valutazione Football',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youngscout_view_giocatore',
            menusub: ['youngscout_view_giocatore'],
            permesso: 'youngscout',
            logModel: 'RosaValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/youngscout/videotag/giocatore/:id',
        name: 'youngscout_videotag_giocatore',
        component: require('../components/pages/next-scouting/giocatori/videotag.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Videotag Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youngscout_view_giocatore',
            permesso: 'scout_videotag',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youngscout/agenda',
        name: 'youngscout_agenda',
        component: require('../components/pages/youngscout/agenda.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Agenda',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_agenda',
        }
    },
    {
        path: '/next/young/partite',
        name: 'young_next_partite',
        component: require('../components/pages/next-scouting/partite.vue').default,
        meta: {
            area: 'young',
            module: 'youngscout',
            label: 'Partite',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_partite',
        }
    },
    {
        path: '/youngscout/osservatori',
        name: 'youngscout_osservatori',
        component: require('../components/pages/youngscout/osservatori.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Osservatori',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_utenti',        
        }
    },
    {
        path: '/youngscout/osservatore/paesi/:id',
        name: 'youngscout_osservatore_paesi',
        component: require('../components/pages/youngscout/osservatore_paesi.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Paesi',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youngscout_osservatori',
            permesso: 'scout_utenti',
            menusub: ['youngscout_osservatore_paesi', 'youngscout_osservatore_liste'], 
            logModel: 'Persona_Utente',
            logModelId: 'id',        
        }
    },
    {
        path: '/youngscout/osservatore/liste/:id',
        name: 'youngscout_osservatore_liste',
        component: require('../components/pages/youngscout/osservatore_liste.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Liste',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youngscout_osservatori',
            permesso: 'scout_utenti',  
            menusub: ['youngscout_osservatore_paesi', 'youngscout_osservatore_liste'], 
            logModel: 'Persona_Utente',
            logModelId: 'id',  
        }
    },
    {
        path: '/youngscout/squadre',
        name: 'youngscout_squadre',
        component: require('../components/pages/youngscout/squadre.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Squadre',
            requiresAuth: true,
            showInMenu: true,
            parent: '',  
            permesso: 'scout_squadre',    
        }
    },
    {
        path: '/youngscout/squadre/nuova',
        name: 'youngscout_squadre_new',
        component: require('../components/pages/youngscout/new_squadra.vue').default,
        meta: {
            module: 'youngscout',

            label: 'Nuova Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youngscout_squadre',
            permesso: 'scout_squadre',
        }
    },
    {
        path: '/youngscout/squadre/edit/:id',
        name: 'youngscout_squadre_edit',
        component: require('../components/pages/youngscout/edit_squadra.vue').default,
        meta: {
            module: 'youngscout',
            label: 'Modifica Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youngscout_view_squadra',
            permesso: 'scout_squadre',
            logModel: 'Squadre',
            logModelId: 'id',
        }
    },
];
