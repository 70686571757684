<template id="elencostaff">
	<div class="elencogiocatori">

		<div class="card-title d-flex">
			<span class="mr-auto">Staff ({{ members.length }})</span>
		</div>
		<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
		<div v-if="vista == 'griglia'">
			<div class="row">
				<div v-for="staff in members" :key="staff.id" class="col-2">
					<figurinastaff :staff="staff" v-on:change-player="getStaff()"></figurinastaff>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {

	components: {
		figurinastaff: 	require('./figurinastaff.vue').default,
	},

	data: function(){
		return {
			vista: 'griglia',
			members: [],
			loading: false,
		}
	},
	computed: {
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.getStaff();
		},
		seasonId() {
			this.getStaff();
		},  
	},
	methods: {
		
		getStaff: function(){				
				this.members = [];
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: this.rosaTeamId,season_id: this.seasonId  }}).then((response) => {
					// success callback
					if (response.ok)
					{
						this.members = response.data;
						this.loading = false;
					}
				}, (response) => {
					// error callback
				});
			},
		},
		created: function () {
			this.getStaff();
		},

	}
	</script>
