<template id="rosa">

	<section class="rosa">

		<headapp></headapp>
		<menusub></menusub>
		
		<div class="card">
			<div class="card-body">
				<div :is="$route.meta.report.dashboardType" :config="$route.meta.report"></div>
			</div>
		</div>

		<footerapp></footerapp>

	</section>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	components: {
		'DashboardTypeDebug': require('@/components/layout/reports/DashboardTypeDebug.vue').default,
		'DashboardTypeA': require('@/components/layout/reports/DashboardTypeA.vue').default
	}
}

</script>