<template>
	<b-card title="Dettagli tiri open play">
		<b-table-simple small striped bordered style="font-size:0.9rem">
			<thead>
				<tr>
					<th title="Player">Player</th>
					<th title="Position">Position</th>
					<th title="Games played">Games played</th>
					<th title="Minutes">Minutes</th>
					<th title="Age">Age</th>
					<th title="Preferred Foot">Pref Foot</th>
					<th title="ShotRL">ShotRL</th>
					<th title="ShotR">ShotR</th>
					<th title="ShotL">ShotL</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="player in sorted_players">
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.position }}</td>
					<td>{{ player.gamePlayed }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.birthDate | age }}</td>
					<td>{{ player.foot }}</td>
					<td>{{ player.shotRL }}</td>
					<td>{{ player.shotR }}</td>
					<td>{{ player.shotL }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['players'],
	data: function() {
		return {

		}
	},
	computed: {
		sorted_players: function() {
			return _.orderBy(this.players, ['shotRL'],['desc']);
		},
	},
	filters: {
		age: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return moment().diff(date, 'years'); 
			}
			return "";
		}
	},
}
</script>
