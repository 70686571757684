<template>
	<section>

		<div class="container-fluid m-y-1">

			<div class="card-deck">
				<div class="card card-block">
					<div class="card-title"><span>Pagina non trovata</span></div>

					<div class="content">
						<p>404. Pagina non trovata!</p>
						<p><a href="/">Torna alla Home Page</a></p>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
export default {

	components: {
		footerapp:          require('../layout/footerapp.vue').default,
	}

}
</script>
