<template id="esercizifiltri">
  <form v-on:submit.prevent="submit()" id="form" action="#" method="POST">
    <div class="esercizifiltri">
      <div class="form-row mb-3">
        <b-input-group class="mb-2">
          <b-form-input type="text" v-model="title" placeholder="Cerca per titolo/autore..."></b-form-input>
          <b-input-group-append>
            <b-button @click="reset"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
            <b-button variant="primary" @click="submit"><i class="fa fa-search" aria-hidden="true"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
  </form>
</template>

<script>

import axios from 'axios';
import { mapState } from 'vuex';

export default {
  data: function(){
    return {
      facility_opt: [],
      goal_opt: [],
      tt_opt: [],
      intensity_opt: [],
    }
  },
  created() {
  },
  mounted() {
  },
  computed:{
    title: {
     get() {
      return this.$store.state.piscina.title;
     },

     set(value) {
      this.$store.commit('piscina/setTitle', value);
     },
   },
   ...mapState('piscina', ['exercises_tab']),
   ...mapState(['rosaTeamId']),
 },
 watch: {
  facility() {
    this.submit();
  },
  goal() {
    this.submit();
  },
  tt() {
    this.submit();
  },
  intensity() {
    this.submit();
  },  
},
methods: {
  submit() {
    this.$store.commit('piscina/setPage', 1);
    if(this.exercises_tab == 0) {
      this.$store.dispatch('piscina/filter');
    } else if(this.exercises_tab == 1) {
      this.$store.dispatch('piscina/filter_archivio');
    } else if(this.exercises_tab == 2) {
      this.$store.dispatch('piscina/filter_condivisi');
    } 
    $('html,body').animate({ scrollTop: 0 }, 'slow');
  },
  reset() {
    this.title = '';
    this.submit();
  },
}

}
</script>
