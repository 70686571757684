<template id="archivio_giocatori">
	<section class="archivio_giocatori">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card" v-if="view_campo">
				<div class="card-body">
					<div class="card-title">
						<span>Lista {{ lista_selected_label }}</span>
					</div>
					<div class="content">
						<b-row class="mb-1">
							<b-col cols="3">
								<b-form-select v-model="sistema_gioco">
									<option value="0">-- Sistemi di gioco --</option>
									<option v-for="item in sistemi_gioco" :value="item.id">{{item.sistema_gioco}}</option>
								</b-form-select>
							</b-col>
							<b-col cols="2">
							</b-col>
							<b-col cols="3">
								<b-form-group
								label="Titolo" label-cols="2" content-cols="10">
								<b-form-input type="text" v-model="form.titolo"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="2">
							<b-form-group
							label="Data" label-cols="3" content-cols="9">
							<b-form-input type="date" v-model="form.data"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="1">
					</b-col>
					<b-col cols="1">
						<b-img @click="backArchivio" src="/assets/varie/shirt.png" width="40" height="40" style="cursor:pointer;" title="Torna all'archivio"/>
						<b-button variant="primary" title="Archivio PDF" @click="archivioPdfLista" class="ml-1"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
						</b-button>
					</b-col>
				</b-row>
				<div style="position:absolute;z-index:1">
					<b-container>
						<b-row v-for="giocatore in lista_in_campo.giocatori" v-if="notHasRuolo(giocatore)">
							<b-col class="notInCampo"><b-link :href="'#/usascout/view/giocatore/' + giocatore.id" target="_blank" style="color:black;"><span style="color:#0000FF">{{ giocatore.cognome.toUpperCase() }}</span> <span style="color:#FF6600">{{ giocatore.nascita_data | formatYear }}</span> {{ giocatore.last_carriera_sportiva.squadra ? giocatore.last_carriera_sportiva.squadra.name : '' }} </b-link></b-col></b-row>
						</b-container> 
					</div>
					<div :class="'sistema sistema-' + sistema_gioco">
						<div class="campo">
							<img src="/assets/images/campo.svg">
						</div>
						<div v-if="sistema_gioco > 0" v-for="ruolo_id in moduli_ruoli[sistema_gioco]" :class="'player player-'+ ruolo_id">
							<span class="label-value">{{ getNomeRuolo(ruolo_id) }}</span>
							<b-container>
								<b-row v-for="giocatore in lista_in_campo.giocatori" v-if="hasRuolo(giocatore, ruolo_id)"><b-col class="truncate"><b-link :href="'#/usascout/view/giocatore/' + giocatore.id" target="_blank" style="color:black;"><span style="color:#0000FF">{{ giocatore.cognome.toUpperCase() }}</span> <span style="color:#FF6600">{{ giocatore.nascita_data | formatYear }}</span> {{ giocatore.last_carriera_sportiva.squadra ? giocatore.last_carriera_sportiva.squadra.name : '' }}</b-link></b-col></b-row>
							</b-container> 
						</div>
					</div>
					<b-row align-h="center" class="mt-5">
						<b-col cols="10">
							<b-form-group
							label="Note" :label-cols="1">
							<b-form-textarea v-model="form.note" :rows="3" :max-rows="6"></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
				<b-button variant="primary" @click="salvaPdf">Salva PDF</b-button>
			</div>
			<b-modal ref="pdfModal" title="Archivio PDF" ok-only ok-title="Chiudi">	
				<b-table :items="elenco_pdf" :fields="['titolo', {key: 'data', formatter: this.formatDate}, 'azioni']">
					<template #cell(azioni)="row">
						<b-button size="sm" title="download PDF" @click="downloadPdf(row.item.id)">
							<i class="fa fa-download" aria-hidden="true"></i>
						</b-button>
						<b-button size="sm" title="elimina PDF" @click="eliminaPdf(row.item.id)">
							<i class="fa fa-trash" aria-hidden="true"></i>
						</b-button>
					</template>
				</b-table>
			</b-modal>
		</div>
	</div>
	<div class="card" v-if="!view_campo">
		<div class="card-body">
			<div class="card-title">
				<span>Usa Scout - giocatori ({{ total }})</span>
			</div>
			<div class="content">
				<div class="mb-1">
					<b-btn v-b-toggle.collapse variant="primary">Filtra <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
					<b-collapse id="collapse" class="mt-2">
						<b-row class="mb-1">
							<b-col sm="2">
								<b-form-select v-model="filters.ruolo">
									<option value="0">-- Ruolo --</option>
									<option v-for="item in ruoli" :value="item.id">{{item.nome}}</option>
								</b-form-select>
							</b-col>
							<b-col sm="2">
								<b-form-select v-model="filters.gradimento">
									<option value="0">-- Gradimento --</option>
									<option v-for="item in gradimenti" :value="item.id">{{item.nome}}</option>
								</b-form-select>
							</b-col>
							<b-col sm="2" v-if="auth.isUserEnable('scout_utenti')">
								<b-form-select v-model="filters.osservatore">
									<option value="0">-- Osservatore --</option>
									<option v-for="item in osservatori" :value="item.id">{{item.nomeCompleto}}</option>
								</b-form-select>
							</b-col>
							<b-col sm="2">
								<b-input-group>
									<b-form-input type="text" v-model="filters.search_name" placeholder="Cognome / Nome"></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>  	
							</b-col>
							<b-col sm="2">
								<b-form-select v-model="filters.anno" :options="anni">
								</b-form-select>
							</b-col>
							<b-col sm="2">
								<b-form-select v-model="filters.non_valutati">
									<option value="0">-- Solo valutati --</option>
									<option value="1">Non valutati</option>
									<option value="2">Tutti</option>
								</b-form-select>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="2">
								<b-input-group>
									<b-form-input v-model="filters.voto_medio"
									type="text"
									placeholder="Voto medio"></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-col>
							<b-col sm="2">
								<b-form-group label-cols="3" content-cols="9" label="Val. dal">
									<b-form-input type="date" v-model="filters.valutati_dal"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="2">
								<b-form-group label-cols="3" content-cols="9" label="al">
									<b-form-input type="date" v-model="filters.valutati_al"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="2">
								<b-form-select v-model="filters.lista">
									<option value="0">-- Liste --</option>
									<option v-for="item in liste" :value="item.id">{{item.nome}}</option>
								</b-form-select>
							</b-col>
							<b-col sm="2">
								<b-btn variant="primary" @click="searchTeam" title="Filtra per squadra">Squadra</b-btn>
								<span v-if="squadra"><img :src="squadra.logo_url" height="35" /><strong>{{ squadra.name }}</strong><b-btn variant="secondary" @click="rimuoviSquadra" class="ml-2" title="Rimuovi filtro squadra"><i class="fa fa-ban" aria-hidden="true"></i></b-btn></span>
							</b-col>
						</b-row>
					</b-collapse>
				</div>
					<!--div class="btn-group mb-1" role="group">
						<button class="btn btn-primary" v-on:click="vista = 'griglia'"><i class="fa fa-th"></i> Vista griglia</button>
						<button class="btn btn-primary" v-on:click="vista = 'lista'"><i class="fa fa-bars"></i> Vista elenco</button>
						<b-pagination :total-rows="osservati.total" v-model="osservati.current_page" @change="changePage" :per-page="osservati.per_page" align="center" class="ml-1 mb-0"/>
					</div-->
					<b-row>
						<b-col cols="3">
							<b-row>
								<b-col cols="8">
									<b-form-group label="Lista" label-cols="3" content-cols="9">
										<b-form-select v-model="lista_selected" :options="liste" value-field="id" text-field="nome"/>
									</b-form-group>
									<b-collapse id="collapse2" ref="collapse2" class="mb-1">
										<b-form-input v-model="new_lista"
										type="text"
										placeholder="Inserisci nuova lista"></b-form-input>
										<b-btn variant="primary" size="sm" @click="aggiungiLista">Salva</b-btn>
									</b-collapse>
								</b-col>
								<b-col>
									<b-btn variant="primary" title="Elimina lista" @click="eliminaLista" v-if="!is_lista_master && !is_lista_condivisa"><i class="fa fa-minus" aria-hidden="true"></i></b-btn>
									<b-btn variant="primary" title="Aggiungi lista" v-b-toggle="'collapse2'"><i class="fa fa-plus" aria-hidden="true"></i></b-btn>
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="3">
							<!--div v-if="auth.isUserEnable('scout_compare_players')">
								<b-row align-v="center">
									<b-col cols="6">
										<b-form-checkbox id="checkConfronto" v-model="confronta">
											Confronta
										</b-form-checkbox>
									</b-col>
									<b-col cols="2">
										<b-btn variant="primary" title="Mostra confronto" @click="showConfronto"><i class="fa fa-search" aria-hidden="true"></i></b-btn>
									</b-col>
									<b-col cols="2">
										<b-btn variant="secondary" title="Reset confronto" @click="resetConfronto"><i class="fa fa-ban" aria-hidden="true"></i></b-btn>
									</b-col>
									<b-col cols="2">
										<b-btn variant="success" title="Liste confronto" @click="listeConfronto"><i class="fa fa-list" aria-hidden="true"></i></b-btn>
									</b-col>
								</b-row>
							</div-->
						</b-col>
						<b-col cols="3">
							<!--b-form-select v-if="confronta" v-model="ruolo_sel" :options="ruoli_grp"/-->
						</b-col>
						<b-col cols="2">
						</b-col>
						<b-col cols="1">
							<b-img @click="listaInCampo" src="/assets/varie/campo_red.png" width="40" height="40" style="cursor:pointer;" title="Lista in campo"/>
						</b-col>
					</b-row>
					<div v-if="vista == 'griglia'">	
						<v-infinite-scroll :loading="loading" @bottom="nextPage" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
							<div v-for="osservato in osservati" class="col-2">
								<cardosservato :osservato="osservato" :in_lista="lista_giocatori.indexOf(osservato.id) > -1" @addGiocatoreLista="addGiocatoreLista" @delGiocatoreLista="delGiocatoreLista" :editable="!is_lista_condivisa" :academy="2"></cardosservato>
								</div>
								<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
							</v-infinite-scroll>

						</div>
						<div v-if="vista == 'lista'">
							<tabellaosservati :osservati="osservati"></tabellaosservati>	
						</div>
					</div>
				</div>
			</div>
			<b-modal id="searchTeamModal" ref="searchTeamModal" title="Filtro squadra" ok-only ok-title="Chiudi">
				<div v-if="show_aree">
					<span>Seleziona un'area: </span>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
						<b-row>
							<b-col cols="3" v-for="area in aree" style="padding: 0.2rem">
								<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.id)">
									<flag :country="area.sigla" :size="24"></flag><br/>
									{{area.paese}}
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
				<div v-if="show_squadre">
					<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
					<div v-if="squadre.length > 0">
						<span>Seleziona una squadra: </span>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<b-row>
								<b-col cols="4" v-for="team in squadre" style="padding: 0.2rem">
									<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selezionaSquadra(team)">
										<img :src="team.logo_url" width="50" /><br/>
										<strong>{{team.name}}</strong><br/>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
			</b-modal>
			<b-modal id="confrontoModal" ref="confrontoModal" title="Confronta giocatori" class="fs-modal" ok-only ok-title="Chiudi">
				<b-table striped hover small responsive :items="items" :fields="fields" style="font-size:0.85rem" v-model="table_confronto" foot-clone>
					<template #cell(FOOT_giocatore)="data">
						<span title="Media">Media</span>
					</template>
					<template #cell(FOOT_ruolo)="data">
						<span></span>
					</template>	
					<template #cell(FOOT_classe)="data">
						<span>{{ medie_confronto['classe'] }}</span>
					</template>
					<template #cell(FOOT_altezza)="data">
						<span>{{ medie_confronto['altezza'] }}</span>
					</template>
					<template #cell(FOOT_piede)="data">
						<span></span>
					</template>
					<template v-slot:[`cell(FOOT_${field})`]="data" v-for="field in opta_fields">
						<span>{{ medie_confronto[field] }}</span>
					</template>	
					<template #cell(FOOT_voto)="data">
						<span>{{ medie_confronto['voto'] }}</span>
					</template>
				</b-table>
				<b-row class="mt-2">
					<b-col>
						<b-form-group label="Titolo" horizontal :label-cols="2">
							<b-form-input v-model="title_confronto"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-button variant="primary" title="Esporta PDF" @click="pdfConfronto"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
						</b-button>
					</b-col>
				</b-row>
			</b-modal>
			<b-modal ref="listeConfrontoModal" title="Liste confronto" ok-only ok-title="Chiudi">	
				<b-row class="m-2">
					<b-col cols="9">
						<b-form-group label="Nuova lista" horizontal>
							<b-form-input v-model="nuova_lista_confronto"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-button variant="primary" title="Salva" @click="addListaConfronto">
							Aggiungi
						</b-button>
					</b-col>
				</b-row>
				<b-table :items="liste_confronto" :fields="['nome', {key: 'created_at', label: 'Data', formatter: this.formatDate}, 'azioni']">
					<template #cell(azioni)="row">
						<b-button size="sm" variant="success" title="carica lista" @click="loadListaConfronto(row.item.id)">
							<i class="fa fa-list" aria-hidden="true"></i>
						</b-button>
						<b-button size="sm" variant="primary" title="aggiorna lista" @click="updateListaConfronto(row.item.id)">
							<i class="fa fa-pencil" aria-hidden="true"></i>
						</b-button>
						<b-button size="sm" title="elimina lista" @click="deleteListaConfronto(row.item.id)">
							<i class="fa fa-trash" aria-hidden="true"></i>
						</b-button>
					</template>
				</b-table>
			</b-modal>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>
import moment from 'moment'
import * as types from '../../../store/types'
import InfiniteScroll from 'v-infinite-scroll'
import { mapState } from 'vuex';

var Auth = require('../../../auth.js');

export default {

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
		cardosservato: 	require('../../layout/cardosservato.vue').default,
		tabellaosservati: 	require('../../layout/tabellaosservati.vue').default,
		flag:               require('../../layout/flag.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},

	data: function(){
		return {
			vista: 'griglia',
			osservati: [],
			ruoli: [],
			gradimenti: [],
			osservatori: [],
			filters: {
				ruolo: 0,
				gradimento: 0,
				osservatore: 0,
				search_name: '',
				anno: '',
				voto_medio: '',
				voto_medio_ruolo: '',
				segnalatore: 0,
				stato: '',
				non_valutati: 0,
				note: 0,
				valutati_dal: '',
				valutati_al: '',
				lista: 0,
				live: 0,
				squadra: 0,
			},
			liste: [],
			lista_selected: 0,
			lista_selected_label: '',
			lista_giocatori: [],
			new_lista: '',
			view_campo: false,
			sistemi_gioco: [],
			sistema_gioco: 4,
			lista_in_campo: '',
			ruoli: [],
			moduli_ruoli: {
				1: [3, 10, 6, 5, 9, 20, 18, 17, 19, 26, 27], /* 4-4-2 */
				2: [3, 10, 6, 5, 9, 13, 18, 17, 16, 26, 27], /* 4-4-2 rombo*/
				3: [3, 10, 6, 5, 9, 20, 18, 17, 19, 21, 26], /* 4-4-1-1 */
				4: [3, 10, 6, 5, 9, 15, 13, 14, 25, 27, 24], /* 4-3-3 */
				5: [3, 8, 4, 7, 20, 18, 17, 19, 25, 27, 24], /* 3-4-3 */
				6: [3, 8, 4, 7, 12, 18, 13, 17, 11, 26, 27], /* 3-5-2 */
				7: [3, 10, 6, 5, 9, 18, 17, 23, 21, 22, 26], /* 4-2-3-1 */
				8: [3, 10, 6, 5, 9, 15, 13, 14, 21, 28, 27], /* 4-3-2-1 */
				9: [3, 10, 6, 5, 9, 18, 13, 17, 21, 26, 27], /* 4-3-1-2 */
				10: [3, 10, 6, 5, 9, 13, 15, 16, 14, 26, 27], /* 4-1-3-2 */
				11: [3, 10, 6, 5, 9, 13, 20, 18, 17, 19, 26], /* 4-1-4-1 */
				12: [3, 8, 4, 7, 20, 18, 17, 19, 21, 25, 24], /* 3-4-1-2 */
				13: [3, 8, 4, 7, 20, 18, 17, 19, 23, 22, 27], /* 3-4-2-1 */
				14: [3, 12, 8, 4, 7, 11, 18, 17, 25, 26, 24], /* 5-2-3 */
				15: [3, 12, 8, 4, 7, 11, 15, 13, 14, 26, 27], /* 5-3-2 */
				16: [3, 12, 8, 4, 7, 11, 20, 18, 17, 19, 26], /* 5-4-1 */
			},
			form: {
				titolo: '',
				data: '',
				note: '',
			},
			elenco_pdf: [],
			total: 0,
			loading: false,
			page: 1,
			is_search: false,
			aree: [],
			squadre: [],
			show_aree: false,
			show_squadre: false,
			squadra: null,	
			opta_range: {},
			opta_fields: [],
			confronta: false,
			lista_confronto: [],
			difensori: [2, 3, 4, 5, 6],
			cc_centrali: [11, 14, 15, 16],
			cc_interni: [12, 13],
			terzini: [7, 8, 9, 10, 17, 18],
			att_esterni: [19, 20, 21, 22, 23],
			att_centrali: [24, 25, 26],
			skills: {
				difensori: {
					sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
					sogg_2: { label: 'MEDIA: TAKLE + CONTRASTO', ids: ['18a', '18b'] },
					sogg_3: { label: 'MEDIA: INTERC ORIZZ + VERTICALE', ids: ['6a', '6b'] },
					sogg_4: { label: 'DUELLI AEREI', ids: ['18c'] },
					sogg_5: { label: 'MEDIA: PASSAGGI CORTO/LANCIO/FILTRANTE', ids: ['12a', '12c', '12f'] },
				},
				cc_centrali: {
					sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
					sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
					sogg_3: { label: 'MEDIA: INTERC ORIZZ + VERTICALE', ids: ['6a', '6b'] },
					sogg_4: { label: 'MEDIA: SKILL DI PRESSING E PRESSIONE', ids: ['8a', '8b', '8c', '8d'] },
					sogg_5: { label: 'MEDIA: PASSAGGI CORTO/LANCIO/FILTRANTE', ids: ['12a', '12c', '12f'] },
				},
				cc_interni: {
					sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
					sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
					sogg_3: { label: 'MEDIA: SKILL DI TIRO IN PORTA', ids: ['11a', '11b', '11c', '11d', '11e'] },
					sogg_4: { label: 'SMARCAMENTO TRA LE LINEE', ids: ['2b'] },
					sogg_5: { label: 'MEDIA: PASSAGGI CORTO/LANCIO/FILTRANTE', ids: ['12a', '12c', '12f'] },
				},
				terzini: {
					sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
					sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
					sogg_3: { label: 'MEDIA: INTERC ORIZZ + VERTICALE', ids: ['6a', '6b'] },
					sogg_4: { label: 'CROSS', ids: ['13b'] },
					sogg_5: { label: 'MEDIA: SKILL DI DRIBBLING', ids: ['14a', '14b', '14c'] },
				},
				att_esterni: {
					sogg_1: { label: 'MEDIA: TIRO IN PORTA', ids: ['11a', '11b', '11c', '11d', '11e'] },
					sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
					sogg_3: { label: 'MOVIMENTO DOPO PALLA PERSA', ids: ['3c'] },
					sogg_4: { label: 'MEDIA: SKILL DI ASSIST', ids: ['13a', '13b', '13c', '13d'] },
					sogg_5: { label: 'MEDIA: SKILL DI DRIBBLING', ids: ['14a', '14b', '14c'] },
				},
				att_centrali: {
					sogg_1: { label: 'MEDIA: TIRO IN PORTA', ids: ['11a', '11b', '11c', '11d', '11e'] },
					sogg_2: { label: 'ATTACCO DELLA PORTA', ids: ['9b'] },
					sogg_3: { label: 'SENSO DEL GOAL', ids: ['30b'] },
					sogg_4: { label: 'MEDIA: SKILL DI ASSIST', ids: ['13a', '13b', '13c', '13d'] },
					sogg_5: { label: 'MEDIA: SKILL DI DRIBBLING', ids: ['14a', '14b', '14c'] },
				},
			},
			ruoli_grp: [
			{ value: null, text: 'Seleziona un ruolo' },
			{ value: 'difensori', text: 'Difensore centrale'},
			{ value: 'cc_centrali', text: 'Centrocampista centrale/metodista'},
			{ value: 'cc_interni', text: 'Centrocampista interno'},
			{ value: 'terzini', text: 'Terzino'},
			{ value: 'att_esterni', text: 'Attaccante esterno'},
			{ value: 'att_centrali', text: 'Attaccante centrale'},
			],
			ruolo_sel: null,
			dati_confronto: {},
			range_confronto: {},
			table_confronto: [],
			title_confronto: '',
			liste_confronto: [],
			nuova_lista_confronto: '',
			medie_confronto: {},
		}
	},
	created: function () {
		this.loading = true;
		this.$http.get(this.$store.state.apiEndPoint + '/usascout/archivio/giocatori/' + this.scoutingCategory).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.osservati = response.data.data; 
            	this.total = response.data.total;
            	this.loading = false;
            }
        }, (response) => {
            // error callback
        }); 

		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.ruoli = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.gradimenti = response.data;    
            }
        }, (response) => {
            // error callback
        });

		/*
		this.$http.get(this.$store.state.apiEndPoint + '/opta/range/stats').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.opta_range = response.data.opta_range;  
            	this.opta_fields = response.data.opta_fields;  
            }
        }, (response) => {
            // error callback
        });  */

		this.$http.get(this.$store.state.apiEndPoint + '/osservatori/usascout').then((response) => {
			if (response.ok)
			{
				this.osservatori = response.data; 
			}
		}, (response) => {
            // error callback
        }); 

		this.$http.get(this.$store.state.apiEndPoint + '/liste').then((response) => {
			if (response.ok)
			{
				this.liste = response.data; 
				this.lista_selected = this.liste[0].id;
				this.lista_selected_label = this.liste[0].nome;
			}
		}, (response) => {
            // error callback
        });

		/*
		this.$http.get(this.$store.state.apiEndPoint + '/liste/confronto').then((response) => {
			if (response.ok)
			{
				this.liste_confronto = response.data; 
			}
		}, (response) => {
            // error callback
        });  */

		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
			if (response.ok)
			{
				this.sistemi_gioco = response.data; 
			}
		}, (response) => {
            // error callback
        }); 

		this.$http.get(this.$store.state.apiEndPoint + '/paesi/squadre/giocatori').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.aree = response.data;    
            }
        }, (response) => {
            // error callback
        });
	},

	methods: {
		nextPage() {
			if(this.osservati.length < this.total) {
				this.page++;
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/usascout/archivio/giocatori/' + this.scoutingCategory + '?page=' + this.page, {params: this.filters}).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.osservati = this.osservati.concat(response.data.data);
	            	this.loading = false;
	            }
	        }, (response) => {
	            	// error callback
	            });
			}
		},
		filtraArchivio() {
			this.loading = true;
			this.osservati = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/usascout/archivio/giocatori/' + this.scoutingCategory, {params: this.filters}).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.osservati = response.data.data;
            	this.total = response.data.total;
            	this.loading = false; 
            }
        }, (response) => {
            // error callback
        });
		},
		addGiocatoreLista(id) {
			if(!this.is_lista_condivisa) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/add/giocatore/lista/' + this.lista_selected + '/' + id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Giocatore aggiunto alla lista!', status: 1 });
						this.liste = response.data;
						this.lista_giocatori.push(id);  
					}
				}, (response) => {
	            // error callback
	        }); 
			}
		},
		delGiocatoreLista(id) {
			if(!this.is_lista_condivisa) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/giocatore/lista/' + this.lista_selected + '/' + id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Giocatore eliminato dalla lista!', status: 1 });
						this.liste = response.data; 
						var index = this.lista_giocatori.indexOf(id);	
						this.lista_giocatori.splice(index, 1);
						if(this.filters.lista > 0) {
							this.filtraArchivio();
						}  
					}
				}, (response) => {
	            // error callback
	        }); 
			}  
		},
		aggiungiLista() {
			if(this.new_lista == '') {
				alert("Inserisci un nome per la lista!");
				return;
			}
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/add/lista/' + this.new_lista).then((response) => {
				if (response.ok)
				{
					this.$refs.collapse2.toggle();
					this.new_lista = '';
					this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista aggiunta ok', status: 1 });
					this.liste = response.data; 
				}
			}, (response) => {
            // error callback
        }); 
		},
		eliminaLista() {
			if(confirm("Confermi l'eliminazione della lista selezionata?")) {

				this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/lista/' + this.lista_selected).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista eliminata ok', status: 1 });
						this.liste = response.data; 
						this.lista_selected = this.liste[0].id;
						this.lista_selected_label = this.liste[0].nome;
					}
				}, (response) => {
            // error callback
        }); 
			}
		},
		listaInCampo() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/lista/in/campo/' + this.lista_selected).then((response) => {
				if (response.ok)
				{
					this.lista_in_campo = response.data; 
					this.view_campo = true;
				}
			}, (response) => {
            // error callback
        });
		},
		backArchivio() {
			this.view_campo = false;
		},
		getNomeRuolo(id) {
			for(var i in this.ruoli) {
				var ruolo = this.ruoli[i];
				if(ruolo.id == id) {
					return ruolo.nome.toUpperCase();
				}
			}
			return "";
		},
		hasRuolo(giocatore, ruolo_id) {	
			var array_ruoli = this.moduli_ruoli[this.sistema_gioco];
			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				var ruolo_ideale = giocatore.last_carriera_sportiva.ruolo_ideale[0];
				if(ruolo_ideale.id == ruolo_id) {
					return true;
				} else if(array_ruoli.indexOf(ruolo_ideale.id) > -1) {
					return false;
				} else if(giocatore.last_carriera_sportiva.altri_ruoli && giocatore.last_carriera_sportiva.altri_ruoli.length > 0) {
					var count = 0;
					for(var i in giocatore.last_carriera_sportiva.altri_ruoli) {
						var altro_ruolo_id = giocatore.last_carriera_sportiva.altri_ruoli[i].id;
						if(array_ruoli.indexOf(altro_ruolo_id) > -1) {
							count++;		
						}
						if(altro_ruolo_id == ruolo_id) {
							if(count == 1) {
								return true;
							} else {
								return false;
							}
						}
					}
				} 
			}  
			return false;
		},
		notHasRuolo(giocatore) {	
			var array_ruoli = this.moduli_ruoli[this.sistema_gioco];
			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				var ruolo_ideale = giocatore.last_carriera_sportiva.ruolo_ideale[0];
				if(array_ruoli.indexOf(ruolo_ideale.id) > -1) {
					return false;
				}
			}
			if(giocatore.last_carriera_sportiva.altri_ruoli && giocatore.last_carriera_sportiva.altri_ruoli.length > 0) {
				for(var i in giocatore.last_carriera_sportiva.altri_ruoli) {
					var altro_ruolo_id = giocatore.last_carriera_sportiva.altri_ruoli[i].id;
					if(array_ruoli.indexOf(altro_ruolo_id) > -1) {
						return false;	
					}
				}
			}
			return true;
		},
		salvaPdf() {
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/pdf/lista/' + this.lista_selected + '/' + this.sistema_gioco, this.form).then(response => {
				if (response.ok) {
					window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + response.data + "/" + Auth.user.id + "/" + Auth.user.api_token;		
				}
			}, (response) => {
            // error callback
        	});
		},

		archivioPdfLista() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/pdf/lista/' + this.lista_selected).then((response) => {
				if (response.ok)
				{
					this.elenco_pdf = response.data; 
					this.$refs.pdfModal.show();
				}
			}, (response) => {
            // error callback
        });
		},
		formatDate (data) {
			var m = moment(data);
			var format = m.format('DD/MM/YYYY'); 
			return format;
		},
		downloadPdf(pdf) {
			window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + pdf + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		eliminaPdf(pdf) {
			if(confirm("Confermi l'eliminazione del pdf?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/pdf/lista/' + pdf).then((response) => {
					if (response.ok)
					{
						this.elenco_pdf = response.data; 
					}
				}, (response) => {
	            // error callback
	        });
			}
		},
		searchTeam: function() {
			this.show_aree = true;
			this.show_squadre = false;
			this.$refs.searchTeamModal.show();	
		},
		selezionaArea: function(id) {
			this.squadre = [];
			this.show_aree = false;
			this.is_search = true;
			this.show_squadre = true;
			this.$http.get(this.$store.state.apiEndPoint + '/squadre/' + id).then((response) => {

				if (response.ok)
				{
					this.squadre = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});
		},
		selezionaSquadra: function(squadra) {
			this.squadra = squadra;
			this.filters.squadra = squadra.id;
			this.$refs.searchTeamModal.hide();	
		},
		rimuoviSquadra: function() {
			this.squadra = null;
			this.filters.squadra = 0;	
		},
		inListaConfronto(osservato) {
			for(var index in this.lista_confronto) {
				var item = this.lista_confronto[index];
				if(item.id == osservato.id) {
					return index;
				}
			}
			return -1;
		},
		editConfronto(osservato) {
			var index = this.inListaConfronto(osservato);
			if(index > -1) {
				this.lista_confronto.splice(index, 1);
			} else {
				this.lista_confronto.push(osservato); 
			}
		},
		showConfronto() {
			this.$refs.confrontoModal.show();
		},
		resetConfronto() {
			this.lista_confronto = [];
		},
		classe: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "-";
		},
		votoMedio: function(valutazioni) {
			var count = 0;
			var voto = 0;
			for (var index in valutazioni) {
				if(valutazioni[index].voto[0] > 0) {
					voto += valutazioni[index].voto[0];
					count++;
				}
			}
			if(voto == 0) {
				return "-";
			}
			return Math.round((voto/count) * 100) / 100;
		},
		getMedia(skills_filter, skills_val) {
			var count_val = 0;
			var media_val = 0;
			for(var key in skills_val) {
				var obj_cat = skills_val[key];
				for(var key2 in obj_cat) {
					if(obj_cat[key2] !== null && typeof obj_cat[key2] === "object") {
						var obj_skills = obj_cat[key2];
						for(var key3 in obj_skills) {
							if(obj_skills[key3] !== null && typeof obj_skills[key3] === "object") {
								var obj_subskills = obj_skills[key3];
								for(var key4 in obj_subskills) {
									if(skills_filter.indexOf(key4) > -1 && obj_subskills[key4] > 0) {
										media_val += obj_subskills[key4];
										count_val ++;
									}
								}  
							} else {
								if(skills_filter.indexOf(key3) > -1 && obj_skills[key3] > 0) {
									media_val += obj_skills[key3];
									count_val ++;
								}
							}
						}
					} else {
						if(skills_filter.indexOf(key2) > -1 && obj_cat[key2] > 0) {
							media_val += obj_cat[key2];
							count_val ++;
						}
					}  
				}       
			}
			if(count_val > 0) {
				media_val = media_val/count_val;
			}
			return media_val;
		},
		getSkills(pos) {
			var skills = [];
			if(this.ruolo_sel) {
				skills = this.skills[this.ruolo_sel]['sogg_' + pos]['ids'];
			}
			return skills;
		},
		getDatoSogg(pos, valutazioni) {
			var skills = this.getSkills(pos);
			if(skills.length > 0) {
				var value = null;
				var count_vals = 0;
				var media_vals = 0;
				for(var i in valutazioni) {
					var valutazione = valutazioni[i];
					if(valutazione.fields && valutazione.fields.attivo) {
						var skills_val = JSON.parse(valutazione.skills);
						var media_val = this.getMedia(skills, skills_val); 
						if(media_val > 0) {
							media_vals += media_val;
							count_vals ++;
						}
					}
				}
				if(count_vals > 0) {
					value = media_vals/count_vals;
				}
				return (value != null ? ( Number.isInteger(value) ? value : value.toFixed(2)) : '-');
			}    
			return " - ";
		},
		creaDatiConfronto() {
			this.dati_confronto = {};
			for(var i in this.lista_confronto) {
				var osservato = this.lista_confronto[i];
				var giocatore = osservato.cognome + " " + osservato.nome.substr(0, 1) + ".";
				var giocatori = [];
				if(i > 0) {
					var giocatori = this.dati_confronto['giocatore'];
				} else {
					this.dati_confronto['giocatore'] = giocatori;
				}
				giocatori.push(giocatore);
				var ruolo = (osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].sigla : "-");
				var ruoli = [];
				if(i > 0) {
					var ruoli = this.dati_confronto['ruolo'];
				} else {
					this.dati_confronto['ruolo'] = ruoli;
				}
				ruoli.push(ruolo);
				var classe =  this.classe(osservato.nascita_data);
				var classi = [];
				if(i > 0) {
					var classi = this.dati_confronto['classe'];
				} else {
					this.dati_confronto['classe'] = classi;
				}
				classi.push(classe);
				var altezza = osservato.info_fisica ? osservato.info_fisica.altezza : '';
				var altezze = [];
				if(i > 0) {
					var altezze = this.dati_confronto['altezza'];
				} else {
					this.dati_confronto['altezza'] = altezze;
				}
				altezze.push(altezza);
				var	piede =  osservato.giocatore ? osservato.giocatore.label_piede : '';
				var piedi = [];
				if(i > 0) {
					var piedi = this.dati_confronto['piede'];
				} else {
					this.dati_confronto['piede'] = piedi;
				}
				piedi.push(piede);

				for(var y in this.opta_fields) {
					var value = (osservato.opta && osservato.opta.length && osservato.opta[0].stats.length ? osservato.opta[0].stats[0][this.opta_fields[y]] : "-");
					if(!isNaN(value) && value != 0) {
						value = Math.round(value * 100) / 100;
					}
					var array_opta = [];
					if(i > 0) {
						var array_opta = this.dati_confronto[this.opta_fields[y]];
					} else {
						this.dati_confronto[this.opta_fields[y]] = array_opta;
					}
					array_opta.push(value);
				}
				var voto =  this.votoMedio(osservato.valutazioni);
				var voti = [];
				if(i > 0) {
					var voti = this.dati_confronto['voto'];
				} else {
					this.dati_confronto['voto'] = voti;
				}
				voti.push(voto);
				for(var x = 1 ; x <= 5 ; x ++) {
					var sogg = this.getDatoSogg(x, osservato.valutazioni);
					var array_sogg = [];
					if(i > 0) {
						var array_sogg = this.dati_confronto['sogg' + x];
					} else {
						this.dati_confronto['sogg' + x] = array_sogg;
					}
					array_sogg.push(sogg);	
				} 
			}
			this.settaRangeConfronto();	
		},
		settaRangeConfronto() {
			this.range_confronto = {};
			for(var key in this.dati_confronto) {
				var interval = 0;
				var array = this.dati_confronto[key].slice(0);
				array.sort(function(a, b) {
					if(!isNaN(a) && !isNaN(b)) {
						return b - a;
					} else if(isNaN(a) && !isNaN(b)) {
						return 1;
					} else if(!isNaN(a) && isNaN(b)) {
						return -1;
					} else {
						return 0;
					} 
				});
				var count = array.length;
				if(count > 10) {
					interval = parseInt(count / 10);
				}
				var array_range = [];
				var from = 0;
				var to = interval;
				for(var i = 0 ; i < 10 ; i++) {
					if(to < count) {
						var start = array[from];
						var end = array[to];
						var range = {};
						range['from'] = start;
						range['to'] = end;
						array_range.push(range);
						from = to;
						to = to + interval;
						if(interval == 0) {
							to++;
						}
					}
				}
				this.range_confronto[key] = array_range;
				var media = '';
				var tot = 0;
				var num = 0;
				for(var i in array) {
					if(!isNaN(array[i]) && array[i] > 0) {
						tot += parseFloat(array[i]);	
						num ++;
					}
				}
				if(num > 0) {
					media = tot/num;
					media = Math.round(media * 100) / 100;
					if(key == 'classe') {
						media = Math.round(media);
					}
				}
				this.medie_confronto[key] = media;
			}
		},
		getVariantConfronto(value, key) {
			var variant = '';
			var array_range = this.range_confronto[key];
			if(array_range && array_range.length > 0 && value != "-") {
				for(var i = 0; i < array_range.length ; i++) {
					if(i == 0) {
						if(value <= array_range[i].from && value >= array_range[i].to) {
							variant = 'voto-' + (10 - i);
							break;
						}
					} else if(i == 9) {
						if(value < array_range[i].from) {
							variant = 'voto-' + (10 - (i+1));
							break;
						}

					} else {
						if(value < array_range[i].from && value >= array_range[i].to) {
							variant = 'voto-' + (10 - i);
							break;
						}
					}
				}
			} 
			return variant;
		},
		pdfConfronto() {
			var tbl_confronto = {
				header: this.fields,
				data: this.table_confronto,
				title: this.title_confronto,
				footer: this.medie_confronto,
			}
			this.$http.post(this.$store.state.apiEndPoint + '/pdf/table/confronto', tbl_confronto, {responseType: 'blob'}).then(response => {
				return response.blob();
			}).then(blob => {
				var url = window.URL.createObjectURL(blob);
				window.open(url);
			});
		},
		listeConfronto() {
			this.$refs.listeConfrontoModal.show();
		},
		addListaConfronto: function() {
			if(this.nuova_lista_confronto != "") {
				this.$http.get(this.$store.state.apiEndPoint + '/add/lista/confronto/' + this.nuova_lista_confronto).then((response) => {
					if (response.ok)
					{
						this.liste_confronto = response.data;
						this.nuova_lista_confronto = "";
					}
				}, (response) => {

				});
			}
		},
		loadListaConfronto: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/load/lista/confronto/giocatori/' + id).then((response) => {
				if (response.ok)
				{
					this.lista_confronto = response.data;
					this.$store.commit(types.ALERTS_PUSH, { msg: 'Lista di confronto caricata con successo!', status: 1 });
					this.$refs.listeConfrontoModal.hide();
				}
			}, (response) => {

			});
		},
		updateListaConfronto: function(id) {
			var ids_gio = [];
			for(var i in this.lista_confronto) {
				var id_gio = this.lista_confronto[i].persona_id;
				ids_gio.push(id_gio);
			}
			this.$http.get(this.$store.state.apiEndPoint + '/edit/lista/confronto/giocatori/' + id + "/" + ids_gio).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, (response) => {

			});
		},
		deleteListaConfronto: function(id) {
			if(confirm("Confermi l'eliminazione della lista confronto selezionata?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/delete/lista/confronto/' + id).then((response) => {
					if (response.ok)
					{
						this.liste_confronto = response.data;
					}
				}, (response) => {

				});
			}
		},
	},

	watch: {
		'filters.ruolo': function () {
			this.filtraArchivio();
		},
		'filters.gradimento': function () {
			this.filtraArchivio();
		},
		'filters.osservatore': function () {
			this.filtraArchivio();
		},
		'filters.anno': function () {
			this.filtraArchivio();
		},
		'filters.segnalatore': function () {
			this.filtraArchivio();
		},
		'filters.stato': function () {
			this.filtraArchivio();
		},
		'filters.non_valutati': function () {
			this.filtraArchivio();
		},
		'filters.note': function () {
			this.filtraArchivio();
		},
		'filters.valutati_dal': function () {
			this.filtraArchivio();
		},
		'filters.valutati_al': function () {
			this.filtraArchivio();
		},
		'filters.lista': function () {
			this.filtraArchivio();
		},
		'filters.live': function () {
			this.filtraArchivio();
		},
		'filters.squadra': function () {
			this.filtraArchivio();
		},
		lista_selected: function () {
			this.lista_giocatori = [];
			for(var index in this.liste) {
				var lista = this.liste[index];
				if(lista.id == this.lista_selected) {
					this.lista_selected_label = lista.nome;
					for(var i in lista.lista_giocatori) {
						var item = lista.lista_giocatori[i]; 
						this.lista_giocatori.push(item.persona_id);
					}
				}
			}	
		},
		scoutingCategory() {
			this.filtraArchivio();
		}, 
		lista_confronto: function () {
			this.creaDatiConfronto();	
		},
		ruolo_sel: function () {
			this.creaDatiConfronto();
		},
	},

	computed: {
		auth: function() {
			return Auth;
		},
		anni: function() {
			var options = [];
			options.push({ value: '', text: '-- Anno --' });
			var anno = moment().subtract(45, 'years').format('YYYY'); 
			for(var i = 0; i <= 40; i++) {
				options.push({ value: anno, text: anno });
				anno++;
			}
			return options;
		},
		is_lista_master: function() {
			for(var index in this.liste) {
				var lista = this.liste[index];
				if(lista.id == this.lista_selected) {
					if(lista.master) {
						return true;

					}
				}
			}
			return false;
		},
		is_lista_condivisa: function() {
			for(var index in this.liste) {
				var lista = this.liste[index];
				if(lista.id == this.lista_selected) {
					if(lista.condivisa && !this.auth.isAdmin()) {
						return true;
					}
				}
			}
			return false;
		},
		...mapState(['scoutingCategory']),

		fields: function() {
			var fields = [
			{ key: 'giocatore', label: 'Giocatore', sortable: true, tdClass: 'td_comp_gio', thClass: 'th_comp_gio'},
			{ key: 'ruolo', label: 'Ruolo', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'classe', label: 'Classe', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'altezza', label: 'Altezza', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill'},
			{ key: 'piede', label: 'Piede', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'gm', label: 'Partite', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill'},
			{ key: 'min', label: 'Minuti', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill'},
			{ key: 'goal', label: 'Goal', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'assist', label: 'Assist', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'keyPass', label: 'kPassTot', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'duel_90min', label: 'Duel%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'tackle_90min', label: 'Tackle%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'aerial_90min', label: 'Ariel%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'int_90min', label: 'Int', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'pass_90min', label: 'Pass%', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'keyPass_90min', label: 'KeyPass', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'recovery_90min', label: 'Recovery', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'psInA3rd_90min', label: 'Ps%InA3', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'tsr_90min', label: 'TSR', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'crossCmp_90min', label: 'Cross', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'oneVsOne_90min', label: '%1v1', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'rcvryA3_90min', label: 'RcvryA3', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'bgChncCrtd_90min', label: 'BgChCrd', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'expG_90min', label: 'ExpG', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'shotConv_90min', label: 'ShotCv', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'sog_90min', label: 'SOG', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' },
			{ key: 'voto', label: 'Voto', sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' }
			];
			if(this.ruolo_sel) {
				var fields_ruolo = this.skills[this.ruolo_sel];
				for(var i = 1 ; i <= 5 ; i ++) {
					var field = {key: 'sogg' + i, label: fields_ruolo['sogg_' + i]['label'], sortable: true, tdClass: 'td_comp_skill', thClass: 'th_comp_skill' };
					fields.push(field);
				}
			}
			return fields;
		},
		
		items: function() {
			var items = [];
			for(var i = 0; i < this.lista_confronto.length ; i++) {
				var item = { 
					giocatore: this.dati_confronto['giocatore'][i],
					ruolo: this.dati_confronto['ruolo'][i],
					classe:  this.dati_confronto['classe'][i],
					altezza:  this.dati_confronto['altezza'][i],
					piede: this.dati_confronto['piede'][i],
					gm: this.dati_confronto['gm'][i],
					min: this.dati_confronto['min'][i],
					goal: this.dati_confronto['goal'][i],
					assist: this.dati_confronto['assist'][i],
					keyPass: this.dati_confronto['keyPass'][i],
					duel_90min: this.dati_confronto['duel_90min'][i],
					tackle_90min: this.dati_confronto['tackle_90min'][i],
					aerial_90min: this.dati_confronto['aerial_90min'][i],
					int_90min: this.dati_confronto['int_90min'][i],
					pass_90min: this.dati_confronto['pass_90min'][i],
					keyPass_90min: this.dati_confronto['keyPass_90min'][i],
					recovery_90min: this.dati_confronto['recovery_90min'][i],
					psInA3rd_90min: this.dati_confronto['psInA3rd_90min'][i],
					tsr_90min: this.dati_confronto['tsr_90min'][i],
					crossCmp_90min: this.dati_confronto['crossCmp_90min'][i],
					oneVsOne_90min: this.dati_confronto['oneVsOne_90min'][i],
					rcvryA3_90min: this.dati_confronto['rcvryA3_90min'][i],
					bgChncCrtd_90min: this.dati_confronto['bgChncCrtd_90min'][i],
					expG_90min: this.dati_confronto['expG_90min'][i],
					shotConv_90min: this.dati_confronto['shotConv_90min'][i],
					sog_90min: this.dati_confronto['sog_90min'][i],
					voto:  this.dati_confronto['voto'][i],
				};
				for(var x = 1 ; x <= 5 ; x ++) {
					item['sogg' + x] = this.dati_confronto['sogg' + x][i];
				}
				var variant_obj = {};
				variant_obj['classe'] = this.getVariantConfronto(this.dati_confronto['classe'][i], 'classe');
				variant_obj['altezza'] = this.getVariantConfronto(this.dati_confronto['altezza'][i], 'altezza');
				for(var y in this.opta_fields) {
					variant_obj[this.opta_fields[y]] = this.getVariantConfronto(this.dati_confronto[this.opta_fields[y]][i], this.opta_fields[y]);	
				}
				variant_obj['voto'] = this.getVariantConfronto(this.dati_confronto['voto'][i], 'voto');
				for(var x = 1 ; x <= 5 ; x ++) {
					variant_obj['sogg' + x] = this.getVariantConfronto(this.dati_confronto['sogg' + x][i], 'sogg' + x);
				}
				item['_cellVariants'] = variant_obj;
				items.push(item);
			}
			return items;
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YY'); 
			}
			return "";
		}
	},

}
</script>
