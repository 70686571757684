<template>
	<div>
		<b-form-group v-for="type, k in rule" :label="type.name">
			
			<div v-if="type.type == 'int'">
				<b-input type="number" v-model.number="configuration[k]"></b-input>
			</div>
			<div v-if="type.type == 'from'">
				<Type-From :bonus="bonus" :contratto="contratto" :from="type.from" :from-params="type.from_params" :selected="configuration[k]" @update="configuration[k] = $event"></Type-From>
			</div>
			<div v-if="type.type == 'bool'">
				<label><input type="checkbox" v-model="configuration[k]" :value-true="1" :value-false="0"> Sì</label>
			</div>
			<div v-if="type.type == 'date'">
				<b-input type="date" v-model="configuration[k]"></b-input>
			</div>

		</b-form-group>

		<div v-show="!nomultitarget" v-if="behaviour && behaviour.multitarget" :style="multitarget.length ? 'background-color: rgba(255,255,0, 0.2); padding: 1em; border: 1px solid rgba(255,255,0, 0.9); border-radius: 5px':''">

			<p><strong>La regola può avere dei target intermedi, che rappresenteranno degli alert.</strong></p>
			<b-button v-if="!ux.showMultiTarget" @click.prevent="ux.showMultiTarget = true" variant="outline-secondary" size="sm">Configura Alert sul Preset <span v-if="multitarget.length" class="text-muted">({{ multitarget.length }})</span></b-button>

			<div v-if="multitarget.length" v-show="ux.showMultiTarget">
				<b-form-group :label="multitargetField.name">

					<b-row v-for="v,kk in multitarget" class="mb-1 align-items-center">

						<b-col :cols="11" v-if="multitargetField.type == 'int'">
							<b-input type="number" v-model.number="multitarget[kk]"></b-input>
						</b-col>
						<b-col :cols="11" v-if="multitargetField.type == 'from'">
							<Type-From :bonus="bonus" :contratto="contratto" :from="type.from" :from-params="multitargetField.from_params" :selected="multitarget[kk]" @update="v = $event"></Type-From>
						</b-col>
						<b-col :cols="11" v-if="multitargetField.type == 'bool'">
							<label><input type="checkbox" v-model="multitarget[kk]" :value-true="1" :value-false="0"> Sì</label>
						</b-col>
						<b-col :cols="11" v-if="multitargetField.type == 'date'">
							<b-input type="date" v-model="multitarget[kk]"></b-input>
						</b-col>
						<b-col cols="1" class="text-center"><a href="javascript:;" @click.prevent="multitarget.splice(kk, 1)">Rimuovi</a></b-col>
					</b-row>

				</b-form-group>
			</div>
				<a href="#" @click.prevent="multitarget.push(null)">Aggiungi Target</a>

		</div>


		<!--
		<pre>{{multitarget}}</pre>
		<pre>{{config}}</pre>
		<pre>{{behaviour}}</pre> 
		<pre>{{rule}}</pre>
		<pre>{{configuration}}</pre>
		<hr />
		-->
		
	</div>
</template>

<script>

export default {
	props: [ 'config', 'bonus', 'rule', 'contratto', 'behaviour', 'basemultitarget', 'nomultitarget' ],
	data: function() {
		return {
			ux: {
				showMultiTarget: false
			},
			configuration: false,
			multitarget: []
		}
	},
	created: function() {
		if(this.basemultitarget) {
			this.multitarget = JSON.parse(this.basemultitarget);
		}
	},
	computed: {
		multitargetField: function() {
			return this.rule[this.behaviour.multitarget];
		}
	},
	components: {
		TypeFrom: require('./RegolaTypeFrom.vue').default
	},
	watch: {
		configuration: {
			deep: true,
			handler: function() {
				console.log('??');
				this.$emit('update', JSON.stringify(this.configuration));
			}
		},
		multitarget: {
			immediate: true,
			deep: true,
			handler: function() {
				this.$emit('multitarget', JSON.stringify(this.multitarget));
			}
		},
		config: {
			immediate: true,
			deep: true,
			handler: function() {
				console.log('!!');
				this.configuration = JSON.parse(this.config);
			}
		}
	}
};

</script>