<template id="cardsquadra">
    <div class="cardsquadra">

        <div class="card mb-2">
            <div class="row no-gutters text-center" style="height:2em">      
            </div>
            <div class="text-center">
                <div class="resize-image-card">
                    <router-link :to="'/scouting/view/squadra/' + squadra.id" target="_blank" v-if="modulo == 'scouting'">
                        <b-img :src="squadra.logo_url" fluid :alt="squadra.name" :width="120"/>
                    </router-link>
                    <router-link :to="'/football/squadre/view/' + squadra.id" v-if="modulo == 'football'">
                        <b-img :src="squadra.logo_url" fluid :alt="squadra.name" :width="120"/>
                    </router-link>
                    <router-link :to="'/usascout/squadre/edit/' + squadra.id" target="_blank" v-if="modulo == 'usascout'">
                        <b-img :src="squadra.logo_url" fluid :alt="squadra.name" :width="120"/>
                    </router-link>
                    <router-link :to="'/newscout/view/squadra/' + squadra.id" target="_blank" v-if="modulo == 'newscout'">
                        <b-img :src="squadra.logo_url" fluid :alt="squadra.name" :width="120"/>
                    </router-link>
                    <b-img v-if="modulo == 'youthscout' || modulo == 'youngscout'" :src="squadra.logo_url" fluid :alt="squadra.name" :width="120"/>
                </div>
            </div>
            <div class="card-body">
                <b-row>
                    <b-col class="small text-center" title="Nome ufficiale">
                        <strong>{{ squadra.officialName }}</strong>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="font-size-xs color-rosso text-center" title="Città">
                       {{ squadra.city ? squadra.city : '&nbsp;'}}
                   </b-col>
               </b-row>
               <b-row>
                <b-col class="font-size-xs text-center" title="Allenatore">
                   {{ squadra.societa.length == 0 && squadra.allenatore && squadra.allenatore.length > 0 ? squadra.allenatore[0].cognome + " " +  squadra.allenatore[0].nome : '&nbsp;' }}
               </b-col>
           </b-row>
           <b-row>
            <b-col class="font-size-xs color-rosso text-center" title="Categoria">
               {{ squadra.categoria ? squadra.categoria.name : '&nbsp;' }}
           </b-col>
       </b-row>
       <b-row>
        <b-col class="font-size-xs text-center" title="Paese">
            <flag :country="squadra.paese ? squadra.paese.sigla : ' '" :size="16"></flag> {{ squadra.paese.paese }}
        </b-col>
    </b-row>

    <hr>
    <b-row v-if="modulo == 'football'">
        <b-col class="text-center" title="Gestisci Rosa" v-if="squadra.categoria && squadra.id && squadra.societa.length == 0">
            <b-button size="sm" :to="'/football/opponents/' + squadra.id + '/' + squadra.categoria.id" variant="primary">ROSA <i class="fa fa-users" aria-hidden="true"></i>
            </b-button>
        </b-col>
        <b-col v-else-if="squadra.categoria && squadra.id && squadra.societa.length > 0" class="text-center" title="Gestisci Rosa">
            <b-button size="sm" @click="goToRosa(squadra)" variant="primary">ROSA <i class="fa fa-users" aria-hidden="true"></i>
            </b-button>
        </b-col>
        <b-col class="text-center" v-else title="Imposta una categoria per gestire la rosa!">
            <b-button :disabled="true" size="sm" variant="secondary">ROSA <i class="fa fa-users" aria-hidden="true"></i>
            </b-button>
        </b-col>
    </b-row>
    <div class="row no-gutters text-center font-size-sm" v-if="modulo == 'scouting' || modulo == 'newscout'">
        <div class="col-3" title="Giocatori osservati"><i class="fa fa-futbol-o" aria-hidden="true"></i><br><span style="font-size:90%">{{ squadra.giocatori_count }}</span></div>
        <div class="col-3" title="Videotags"><i class="fa fa-youtube-play" aria-hidden="true"></i><br><span style="font-size:90%">{{ squadra.videotags_count }}</span></div>
        <div class="col-3" title="Report partite"><i class="fa fa-file-pdf-o" aria-hidden="true"></i><br>{{ squadra.report_partite_scouting_home_count + squadra.report_partite_scouting_away_count }}</div>
        <div class="col-3" title="Missioni"><i class="fa fa-calendar" aria-hidden="true"></i><br>{{ missioni }}</div>
    </div>
</div>
</div>
</div>
</template>

<script>

export default {
    props:{
        squadra: {
            type: Object,
            required: true
        },
        modulo: {
            type: String,
        default: 'scouting'
        },
    },
    data: function() {
        return {
        }
    },
    components: {
        flag:  require('./flag.vue').default,
    },
    computed: {
     missioni: function() {
        var count = 0;
        for(var i in this.squadra.partite) {
            count = count + this.squadra.partite[i].missioni_count;
        }
        return count;
    },
},
methods: {
    goToRosa(team) {
        this.$store.commit('changeRosaTeamId', team.id);
        this.$store.commit('changeCategoriaId', team.id_categoria);
        this.$router.push({ name: "football_rosa"});
    },
}
}
</script>
