<template>
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 529.4 241.5" style="enable-background:new 0 0 529.4 241.5;" xml:space="preserve">
    <g id="Livello_3">
        <polygon id="XMLID_11_" style="fill:#6AB75F;" points="512.3,194.5 17,195.1 84.8,0.4 444.1,0   "/>
        <rect id="XMLID_9_" x="17" y="194.5" style="fill:#479F46;" width="495.3" height="6.2"/>
        <rect id="XMLID_28_" x="17" y="200.8" style="fill:#D1D1D1;" width="495.3" height="12.1"/>
    </g>
    <g id="Livello_1_1_">
        <g id="XMLID_36_" style="opacity:0.5;">
            <g id="XMLID_38_">
                <polygon id="XMLID_48_" style="fill:#479F46;" points="263.6,178.2 242.7,178.2 247.6,10.2 263.5,10.2           "/>
                <polygon id="XMLID_47_" style="fill:#479F46;" points="95.9,178.4 75,178.4 120.5,10.4 136.4,10.4           "/>
                <polygon id="XMLID_46_" style="fill:#479F46;" points="137.9,178.3 116.9,178.4 152.3,10.4 168.2,10.3           "/>
                <polygon id="XMLID_45_" style="fill:#479F46;" points="179.8,178.3 158.8,178.3 184.1,10.3 200,10.3             "/>
                <polygon id="XMLID_44_" style="fill:#479F46;" points="221.7,178.3 200.8,178.3 215.8,10.3 231.7,10.2           "/>
                <polygon id="XMLID_43_" style="fill:#479F46;" points="305.5,178.2 284.6,178.2 279.4,10.2 295.2,10.2           "/>
                <polygon id="XMLID_42_" style="fill:#479F46;" points="347.4,178.1 326.5,178.2 311.1,10.1 327,10.1             "/>
                <polygon id="XMLID_41_" style="fill:#479F46;" points="389.3,178.1 368.4,178.1 342.8,10.1 358.7,10.1           "/>
                <polygon id="XMLID_40_" style="fill:#479F46;" points="431.2,178 410.3,178.1 374.6,10.1 390.5,10           "/>
                <polygon id="XMLID_39_" style="fill:#479F46;" points="473.1,178 452.2,178 406.3,10 422.2,10           "/>
            </g>
        </g>
        <path id="XMLID_35_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M449.4,54.4c0,0,0.3,3.4,2.5,10.5c2.2,7.1,4.8,12.4,4.8,12.4h-9.1l-6.8-23L449.4,54.4
        L449.4,54.4z"/>
        <path id="XMLID_34_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M447.6,93.7h10.1c0,0-1.5-3.9-1.5-8.3s0.5-8.1,0.5-8.1h-9.1V93.7z"/>
        <path id="XMLID_33_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M449.3,54.4c0,0-0.4,3.8-0.4,8.3s1.4,8.6,1.4,8.6s-0.3,2.5,1.7,8.8s5.7,13.7,5.7,13.7
        s-1.5-3.9-1.5-8.4s0.5-8.1,0.5-8.1l0,0c0,0-2.6-5.3-4.8-12.3C449.7,57.8,449.4,54.4,449.3,54.4L449.3,54.4z"/>
        <path id="XMLID_32_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M440.8,71.2h9.5c0,0-1.4-4-1.4-8.5s0.4-8.3,0.4-8.3h-8.6v16.8H440.8z"/>
        <polygon id="XMLID_31_" style="fill:none;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;" points="473.2,178.2 53.9,178.2 104.8,10.2 422.3,10.2    "/>
        <polyline id="XMLID_27_" style="fill:none;stroke:#FFFFFF;stroke-width:0.4551;stroke-miterlimit:10;" points="437.4,60 420.1,60 432.8,106.6 451.5,106.6  "/>
        <line id="XMLID_26_" style="fill:none;stroke:#FFFFFF;stroke-width:0.5157;stroke-miterlimit:10;" x1="263.5" y1="10.2" x2="263.5" y2="178.2"/>
        <ellipse id="XMLID_24_" style="fill:none;stroke:#FFFFFF;stroke-width:0.5396;stroke-miterlimit:10;" cx="263.5" cy="83.3" rx="33.7" ry="23.3"/>
        <ellipse id="XMLID_23_" style="fill:#FFFFFF;" cx="263.5" cy="83.3" rx="1" ry="0.7"/>
        <path id="XMLID_22_" style="fill:none;stroke:#FFFFFF;stroke-width:0.5552;stroke-miterlimit:10;" d="M394.6,101.8c-10.1-4.1-17.6-10.9-19.2-18.6c-1.6-7.5,2.8-14.1,10.8-18.2"/>
        <path id="XMLID_21_" style="fill:#FFFFFF;" d="M408.9,83.3c0.1,0.3-0.2,0.5-0.7,0.5s-0.8-0.2-0.9-0.5s0.2-0.5,0.7-0.5
        C408.4,82.8,408.8,83,408.9,83.3z"/>
        <polyline id="XMLID_20_" style="fill:none;stroke:#FFFFFF;stroke-width:0.4268;stroke-miterlimit:10;" points="431.2,40.5 381.1,40.5 401.8,138.2 461,138.2   "/>
        <polyline id="XMLID_19_" style="fill:none;stroke:#FFFFFF;stroke-width:0.4551;stroke-miterlimit:10;" points="89.7,60 107,60 94.3,106.6 75.6,106.6   "/>
        <path id="XMLID_18_" style="fill:none;stroke:#FFFFFF;stroke-width:0.5493;stroke-miterlimit:10;" d="M132.5,101.8c9.9-4.1,17.2-10.9,18.8-18.6c1.5-7.5-2.8-14.1-10.6-18.2"/>
        <path id="XMLID_17_" style="fill:#FFFFFF;" d="M118.2,83.3c-0.1,0.3,0.2,0.5,0.7,0.5s0.8-0.2,0.9-0.5s-0.2-0.5-0.7-0.5S118.3,83,118.2,83.3z"
        />
        <polyline id="XMLID_16_" style="fill:none;stroke:#FFFFFF;stroke-width:0.4268;stroke-miterlimit:10;" points="66,138.2 125.3,138.2 146,40.5 95.6,40.5   "/>
        <polyline id="XMLID_15_" style="fill:none;stroke:#FFFFFF;stroke-width:0.4268;stroke-miterlimit:10;" points="447.6,93.7 447.6,77.4 440.8,54.9 440.8,71.2   "/>
        <path id="XMLID_14_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M77.7,54.4c0,0-0.3,3.4-2.5,10.5C73,72,70.4,77.3,70.4,77.3h9.1l6.8-23L77.7,54.4L77.7,54.4z"
        />
        <path id="XMLID_13_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M79.4,93.7H69.3c0,0,1.5-3.9,1.5-8.3s-0.5-8.1-0.5-8.1h9.1V93.7z"/>
        <path id="XMLID_12_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M77.7,54.4c0,0,0.4,3.8,0.4,8.3s-1.4,8.6-1.4,8.6s0.3,2.5-1.7,8.8s-5.7,13.7-5.7,13.7
        s1.5-3.9,1.5-8.4s-0.5-8.1-0.5-8.1l0,0c0,0,2.6-5.3,4.8-12.3C77.3,57.8,77.7,54.4,77.7,54.4L77.7,54.4z"/>
        <path id="XMLID_10_" style="opacity:0.3;fill:#FFFFFF;enable-background:new;" d="M86.3,71.2h-9.5c0,0,1.4-4,1.4-8.5s-0.4-8.3-0.4-8.3h8.6v16.8H86.3z"/>
        <polyline id="XMLID_8_" style="fill:none;stroke:#FFFFFF;stroke-linejoin:round;stroke-miterlimit:10;" points="79.4,93.7 79.4,77.4 86.3,54.9 86.3,71.2    "/>
    </g>
</svg>
</template>

<script>
export default {
    data()
    {
        return {
        }
    },

    methods:
    {
    }
}
</script>
