<template>

	<div>

		<headapp></headapp>

		<div style="background-color: white" class="p-1 px-2 breadcrumbWrap">
			
			<div style="background-color: rgba(0,0,0,.06); border-radius: 3px; width: auto" class="px-2 py-2">
				<b-row align-v="center">
					<b-col cols="6">
						<b-breadcrumb style="background-color: transparent" class="m-0 p-0" :items="breadcrumb"></b-breadcrumb>
					</b-col>
					<b-col class="text-right">
						<b-row class="mb-1" align-v="center">
							<b-col cols="6">
								<b-form-input v-model="ux.search" placeholder="Quick search"></b-form-input>
							</b-col>
							<b-col cols="6" class="text-center">
								<b-form-radio-group v-model="ux.genere">
									<b-form-radio value="male">Maschile</b-form-radio>
									<b-form-radio value="female">Femminile</b-form-radio>
								</b-form-radio-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>

	    </div>

		<b-card title-tag="div">
			<b-overlay :show="ux.loading">
				
				<b-row>
					<b-col sm="1" v-for="squadra in filtered_squadre" style="padding: 0.2rem">
						<router-link :to="{ name: area + '_next_nazione_squadra', params: { paese_id: $route.params.paese_id, squadra_id: squadra.id } }">
							<b-card class="small text-center" style="height:100%">
								<b-img :src="squadra.logo_url" fluid :alt="squadra.officialName" :width="120"/>
								<br/>
								<span>{{squadra.officialName}}</span>
							</b-card>
						</router-link>
					</b-col>
				</b-row>
			</b-overlay>
			
		</b-card>

		<footerapp></footerapp>

	</div>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			competizione: null,
			squadre: null,
			categorie_opt: [],
			teams: [],
			page: 1,
			paese: null,
			ux: {
				search: '',
				loading: true,
				genere: 'male'
			},
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
		}
	},
	components: {
		flag: require('@/components/layout/flag.vue').default,
	},
	watch: {
		'$route.params.paese_id': {
			immediate: true,
			handler: function() {
				this.fetchNazionali();
				this.fetchPaese();
				//this.fetchCategorie();
				//this.fetch();
			}
		}
	},
	methods: {
		fetchPaese: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/paese/' + this.$route.params.paese_id).then(function(data) {
				this.paese = data.data;
			});
		},
		fetchNazionali: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionali/' + this.$route.params.paese_id).then(function(data) {
				this.ux.loading = false;
				this.squadre = data.data;
			});
		},
		// fetch: function() {
		// 	this.$http.get(this.$store.state.apiEndPoint + '/club/squadre/' + this.$route.params.competizione_id).then(function(data) {
		// 		this.squadre = data.data;
		// 		this.ux.loading = false;
		// 	});
		// },
		// fetchCategorie: function() {
		// 	this.$http.get(this.$store.state.apiEndPoint + '/exercise/categories').then(function(data) {
		// 		this.categorie_opt = data.data.map(item => ({
		// 			name: item.name,
		// 			id: item.id,
		// 		}));
		// 	});
		// },
		// showSearchTeam: function() {
		// 	this.$refs.searchTeamModal.show();	
		// },
		// filtraArchivio: function() {
		// 	this.teams = [];
		// 	this.page = 1;
		// 	this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then(function(data) {
		// 		this.teams = data.data;
		// 	});
		// },
		// getTeams: function() {
		// 	this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then(function(data) {
		// 		this.teams = data.data;
		// 	});
		// },
		// paginate: function(page) {
		// 	this.page = page;
		// 	this.getTeams();
		// },
	},
	computed: {
		breadcrumb: function() {

			var b = [];

			b.push({
				text: 'Paesi',
				to: {
					name: this.area + '_next_nazionali'
				}
			});

			b.push({
				text: this.paese ? this.paese.paese : ''
			});

			return b;

		},
		area: function() {
			return this.$route.meta.area;
		},
		filtered_squadre: function () {

			return _.filter(this.squadre, function(squadra) {

				if(this.ux.search != "")
					if(!squadra.officialName.toLowerCase.includes(this.ux.search.toLowerCase()))
						return false;

				return this.ux.genere == squadra.gender;

			}.bind(this));

		},
	}
}

</script>