<template>
  <b-collapse :id="`skill-collapse-${skill.id}`" class="col-12" :visible="isChildrenActive">
    <b-card>
      <template v-for="subskill in skill.children">
        <b-row :key="`subskill-${subskill.id}`">
          <skill :skill="subskill" child />
        </b-row>

        <b-row :key="`subskill-container-${subskill.id}`" v-if="subskill.children" class="subskill-container" style="padding-bottom: 0">
          <skill-children :skill="subskill" />
        </b-row>
      </template>
    </b-card>
  </b-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Skill from './Skill.vue';

export default {
  name: 'SkillChildren',
  props: ['skill'],
  components: { Skill },
  computed: {
    ...mapGetters({
      selectedSkillId: 'newTagSkillId',
    }),

    isChildrenActive() {
      return _.findIndex(this.skill.children, ['id', this.selectedSkillId]) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.row:not(:last-child):not(.last) {
  padding-bottom: 10px;
}

.collapse {
  padding-bottom: 10px;
}

div[class^="col-"]:not(:last-child):not(:nth-last-child(2)) {
  padding-bottom: 10px;
}
</style>
