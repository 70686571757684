<template id="form">
	<div class="card border-0 rounded-0">
		<div class="card-body">
			<b-container>

				<b-row>
					<b-col cols="12">
						<div v-if="title" class="card-title">
							<span>{{ title }}</span>
						</div>
						<form v-on:submit.prevent="onSubmit()" action="#" method="POST">
							<div class="content">
								<picture-input ref="pictureTeam" @change="onLogoChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="logo"></picture-input>
								<b-form-group id="nomeGroup" horizontal label="Nome" :label-cols="3" label-for="nome">
									<b-form-input id="nome" type="text" v-model="form.nome" :required="true"></b-form-input>
								</b-form-group>
								<b-form-group id="matricolaGroup" horizontal label="Matricola" :label-cols="3" label-for="matricola">
									<b-form-input id="matricola" type="text" v-model="form.matricola"></b-form-input>
								</b-form-group>
								<b-form-group id="indirizzoGroup" horizontal label="Indirizzo" :label-cols="3" label-for="indirizzo">
									<b-form-input id="indirizzo" type="text" v-model="form.indirizzo"></b-form-input>
								</b-form-group>
								<b-form-group id="telefonoGroup" horizontal label="Telefono" :label-cols="3" label-for="telefono">
									<b-form-input id="telefono" type="tel" v-model="form.telefono"></b-form-input>
								</b-form-group>
								<b-form-group id="emailGroup" horizontal label="Email" :label-cols="3" label-for="email">
									<b-form-input id="email" type="email" v-model="form.email"></b-form-input>
								</b-form-group>
								<b-form-group id="cfPivaGroup" horizontal label="CF/PIVA" :label-cols="3" label-for="cfPiva">
									<b-form-input id="cfPiva" type="text" v-model="form.cf_piva"></b-form-input>
								</b-form-group>
								<div class="d-flex align-items-center">
									<b-form-group class="text-right">
										<b-button type="submit" variant="primary" class="text-uppercase mr-2"><strong>Salva</strong></b-button>
										<b-button class="text-uppercase" @click="eliminaAcademy" v-if="$route.params.id"><strong>Elimina</strong></b-button>
									</b-form-group>
								</div>
							</div>
						</form>
					</b-col>

				</b-row>
			</b-container>
		</div>
	</div>

</template>


<script>

import moment from 'moment'
import axios from 'axios';
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types'
export default {

	data: function(){
		return {
			image_logo: '',
			file_logo: '',
			form: {
				nome: '',
				matricola: '',
				indirizzo: '',
				telefono: '',
				email: '',
				cf_piva: '',
				academy: 0,	
			},
			errors: [],
		}
	},

	methods: {

		onSubmit: function(){
			var formData = new FormData();
			var path = '/academyita/societa/add';
			formData.append("form", JSON.stringify(this.form));
			formData.append("file_logo", this.file_logo);
			if(this.$route.params.id) {
				formData.set('id', this.$route.params.id);
				path  = '/academyita/societa/edit';
			}

			this.$http.post(this.$store.state.apiEndPoint + path, formData).then((response) => {
            	// success callback
            	if (response.ok)
            	{
            		this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            		this.$router.push({ name: 'academy_ita_societa'});
            	}
            }, (response) => {
            	if (response.status == 422) {
            		this.errors = response.data;
            		for (var index in this.errors) {
            			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
            		}
            	}
				// error callback
			});
		},
		onLogoChange(e) {
			if (this.$refs.pictureTeam.file) {
				this.file_logo = this.$refs.pictureTeam.file;
			} else {
				console.log('FileReader Player API not supported: use the <form>')
			}
		},
		eliminaAcademy: function() {
			if(confirm("Confermi l'eliminazione dell'academy?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/societa/delete/' + this.$route.params.id).then((response) => {

					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$router.push({ name: "academy_ita_societa"});
					}
				}, (response) => {
					if (response.status == 422) {
	            		this.errors = response.data;
	            		for (var index in this.errors) {
	            			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
	            		}
            		}
				});
			}
		},
	},

	props: {
		title: {
			type: String,
			required: false
		},
	},

	components: {
		pictureInput:		require('../../../layout/picture_input.vue').default,
	},

	computed: {
		auth: function() {
			return Auth;
		},
		logo: function() {
			if(this.image_logo != "") {
				return this.image_logo;
			} 
			return '';
		},
	},

	mounted: function(){
	},

	created: function () {

		if(this.$route.params.id){
			axios.get(`/academyita/societa/get/` + this.$route.params.id)
			.then((res) => {

				var societa = res.data;
				this.form.nome = societa.nome;
				this.form.matricola = societa.matricola;
				this.form.indirizzo = societa.indirizzo;
				this.form.telefono = societa.telefono;
				this.form.email = societa.email;
				this.form.cf_piva = societa.cf_piva;
				this.form.academy = societa.academy;

				if(societa.logo != null && societa.logo != "") {
					this.image_logo = societa.logo_url;
				}

			}).catch((err) => {
				this.loading = false;
			});
		}		
	},

	watch: {

		form: {
			handler: function () {this.errors = []; },
			deep: true
		},
	},

}
</script>
