<template>
	<b-card title="Valori difensivi per match">
		<b-table-simple small striped bordered responsive style="font-size:0.9rem">
			<thead>
				<tr>
					<th style="min-width:200px;"></th>
					<th class="text-center" v-for="match in matches">{{ match.game }} <br/> {{ match.date | formatDate }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td style="min-width:200px;">AvgSeqTimeAfterRecovery</td>
					<td class="text-center" v-for="match in matches">{{ match.avgSeqTimeAfterRecovery }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;">PPDA</td>
					<td class="text-center" v-for="match in matches">{{ match.ppda }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team'],
	data: function() {
		return {

		}
	},
	computed: {
		matches: function () {
			return this.stats && this.stats.games ? this.stats.games : [];
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
}
</script>
