<template>
	<div>

		<b-row>
			<b-col>
				<formazione :modulo="fase.entity.modulo1" @updatePartita="updateModulo1" dove="casa" :giocatori="_.ceil(fase.entity.players/2)"></formazione>
				<div class="form-group" v-for="n in _.ceil(fase.entity.players/2)" :key="n">
					<div class="row">
						<label class="col-1 text-sm-center col-form-label">
							{{ n }}
						</label>
						<div class="col-11">
							<multiselect v-model="fase.entity.match_players1[n - 1]" :options="fasePlayerClean" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="name" :show-labels="false" :allow-empty="false" :id="n - 1"></multiselect>
						</div>
					</div>
				</div>
			</b-col>
			<b-col>
				<formazione :modulo="fase.entity.modulo2" @updatePartita="updateModulo2" dove="fuori" :giocatori="_.ceil(fase.entity.players/2)"></formazione>
				<div class="form-group" v-for="n in _.ceil(fase.entity.players/2)" :key="n">
					<div class="row">
						<label class="col-1 text-sm-center col-form-label">
							{{ n }}
						</label>
						<div class="col-11">
							<multiselect v-model="fase.entity.match_players2[n - 1]" :options="fasePlayerClean" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="name" :show-labels="false" :allow-empty="false" :id="n - 1"></multiselect>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="6">
				<b-row>
					<b-col sm="3" class="mr-4">
						<b-form-group :label="'Tot. giocatori ' + (!hasNotRequiredField('players') ? '*' : '')">
							<b-form-input type="number" :required="!hasNotRequiredField('players')" min="1" v-model="fase.entity.players"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G1">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="fase.entity.g1"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G2">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="fase.entity.g2"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G3">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="fase.entity.g3"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G4">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="fase.entity.g4"></b-form-input></b-col><b-col class="p-0 text-center"></b-col></b-row>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="3">
						<b-form-group label="Jolly">
							<b-form-input type="number" min="0" v-model="fase.entity.jolly"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group label="Sponde">
							<b-form-input type="number" min="0" v-model="fase.entity.sponde"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="3">
						<b-form-group label="Portieri">
							<b-form-input type="number" min="0" v-model="fase.entity.portieri"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="2">
					</b-col>
					<b-col sm="3">
						<b-form-group label="Porte grandi">
							<b-form-input type="number" min="0" v-model="fase.entity.porte_grandi"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group label="Porte piccole">
							<b-form-input type="number" min="0" v-model="fase.entity.porte_piccole"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="4">
						<b-form-group :label="'Lunghezza campo ' + (!hasNotRequiredField('campo_lunghezza') ? '*' : '')">
							<b-form-input type="number"  min="1" v-model="fase.entity.campo_lunghezza" :required="!hasNotRequiredField('campo_lunghezza')"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group :label="'Larghezza campo ' + (!hasNotRequiredField('campo_larghezza') ? '*' : '')">
							<b-form-input type="number"  min="1" v-model="fase.entity.campo_larghezza" :required="!hasNotRequiredField('campo_larghezza')"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group label="Densità campo">
							<b-form-input plaintext :value="getDensitaCampo(fase.entity)"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>

			<b-col cols="6">
				<b-form-row>
					<b-col cols="12" md="6">
						<b-form-group :label="'Minuti esercitazione ' + (!hasNotRequiredField('training_minutes') ? '*' : '')">
							<b-form-input type="number" :required="!hasNotRequiredField('training_minutes')" min="1" v-model="fase.entity.training_minutes"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group :label="'N. serie esercitazione ' + (!hasNotRequiredField('sets') ? '*' : '')">
							<b-form-input type="number" :required="!hasNotRequiredField('sets')" min="1" v-model="fase.entity.sets"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group label="Min. recupero esercitazione">
							<b-form-input type="number" min="0" v-model="fase.entity.recovery_minutes"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group label="Sec. recupero esercitazione">
							<b-form-input type="number" min="0" v-model="fase.entity.recovery_seconds"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group label="N. recupero esercitazione">
							<b-form-input type="number" min="0" v-model="fase.entity.recoveries"></b-form-input>
						</b-form-group>
					</b-col>
				</b-form-row>
			</b-col>
		</b-row>

		<!-- <v-for...> -->
		<div v-if="hasSkills">
			<SkillPickerGroup :model="fase.entity.skills_rel" :config="{ radici: hasSkills }"></SkillPickerGroup>
		</div>
		
	</div>
</template>

<script>

import sessioneMixin from '@/mixins/sessione.js';
import InfiniteScroll from 'v-infinite-scroll';
import axios from 'axios';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
		sessioneMixin
	],
	components: {
		FaseTestString: require('@/components/layout/Sessioni/Test/FaseTestString.vue').default,
		FaseTestNumber: require('@/components/layout/Sessioni/Test/FaseTestNumber.vue').default,
		FaseTestBoolean: require('@/components/layout/Sessioni/Test/FaseTestBoolean.vue').default,
		FaseTestSelect: require('@/components/layout/Sessioni/Test/FaseTestSelect.vue').default,
		SkillPickerGroup: require('@/components/layout/Sessioni/Generica/SkillPickerGroup.vue').default,
		formazione:         require('@/components/layout/formazione.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},
	props: [ 'sessione', 'fase', 'disabled' ],
	created: function() {
		this.getDrills();
		this.getSkills();
	},
	data: function(){
		return {
			drills: [],
			skills: [],
			ux: {
				loading: false,
				search: '',
				search_condivisi: ''
			}
		}
	},
	computed: {
		fasePlayerClean: function() {
			return _.map(this.fase.players, function(a) {
				return {
					id: a.id,
					name: a.name
				}
			});
		},
		_: function() {
			return _;
		},
		hasSkills: function() {

			if(this.hasSetupTipo(this.fase, 'hasSkills', false))
				return this.hasSetupTipo(this.fase, 'hasSkills', false);
			else if(this.hasSetup(this.sessione, 'hasSkills', false))
				return this.hasSetup(this.sessione, 'hasSkills', false);

			return false;

		},
		...mapState('editor', ['exercises_certificati', 'page']),
		...mapState(['rosaTeamId']),
		user: function() {
			return Auth.user;
		},
		auth: function() {
			return Auth;
		}
	},
	methods: {
		updateModulo1(modulo) {
			this.fase.entity.modulo1 = _.replace(modulo.name, /\-/g, '');
		},
		updateModulo2(modulo) {
			this.fase.entity.modulo2 = _.replace(modulo.name, /\-/g, '');
		},
		skillsFrom: function(id) {
			return _.find(this.skills, { id });
		},
		getDrills: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/drills').then(function(data) {
				this.drills = data.data;
			});
		},
		getSkills: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skill/object').then(function(data) {
				this.skills = data.data;
			});
		},
		getDensitaCampo(phase) {
			var densita = "";
			if(phase.players > 0 && phase.campo_lunghezza > 0 && phase.campo_larghezza > 0) {
				densita = (phase.campo_lunghezza * phase.campo_larghezza) / phase.players;
				densita = Math.round(densita * 100) / 100;
			}
			return densita;
		},
		nextPage: function() {
			if(this.page < this.exercises_certificati.last_page) {
				this.$store.commit('editor/setPage', this.page + 1);
				this.$store.dispatch('editor/filter_certificati', this.page + 1);
			}
		},
		hasNotRequiredField(field) {
			if(this.hasSetup(this.sessione, 'notRequiredFields', false)) {
				if(this.hasSetup(this.sessione, 'notRequiredFields', false)['Fase_Partita']) {
					if(this.hasSetup(this.sessione, 'notRequiredFields', false)['Fase_Partita'].indexOf(field) > -1) {
						return true;
					}
				}
			}
			return false;
		}
	},
	watch: {
		
	}
}
</script>