<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>{{ osservatore.nomeCompleto }}</span>
					</div>
					<div class="content">
						<div class="row">
							<div class="col-2">
								<h4>Paesi</h4>
								<div v-for="paese in paesi_utente">
									<div class="row">
										<div class="col-1 mr-3">
											<flag :country="paese.sigla" :size="32"></flag>
										</div>
										<div class="col"> 
											<span class="m-1" style="font-size: 1.25rem;"> {{ paese.paese }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-10">
								<div id="chartdiv" style="width: 100%; height: 500px;"></div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>

		<footerapp></footerapp>

	</section>
</template>

	<script>

	import AmCharts from 'ammap3';
	import 'ammap3/ammap/ammap.js';
	import 'ammap3/ammap/maps/js/worldLow.js';
	import 'ammap3/ammap/maps/js/continentsLow.js';
	import 'ammap3/ammap/lang/it.js';

	export default {

		data: function() {
			return {
				osservatore: {},
				paesi: [],
				paesi_utente: [],
				
				
			}
		},
		created: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/osservatore/paesi/' + this.$route.params.id).then((response) => {
				if (response.ok)
				{
					this.osservatore = response.data; 
					this.paesi_utente = this.osservatore.paesi;
					this.preSelectCountries();
				}
			}, (response) => {
            // error callback
        	});
			this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
				if (response.ok)
				{
					this.paesi = response.data; 
				}
			}, (response) => {
            // error callback
        	});
		},

		components: {
			headapp:            require('../../layout/headapp.vue').default,
			menusub:            require('../../layout/menusub.vue').default,
			footerapp:          require('../../layout/footerapp.vue').default,
			flag: 				require('../../layout/flag.vue').default,
		},

		mounted () {
			this.$map = new window.AmCharts.makeChart( "chartdiv", {
				"type": "map",
				"theme": "light",
				"projection": "miller",
				"language": "it",
				"panEventsEnabled": true,
				"areasSettings": {
					"autoZoom": false,
					"rollOverOutlineColor": "#A80532",
					"selectedColor": "#A80532",
					"color": "#FFC64F",
					"rollOverColor": "#A80532",
					"selectable": true
				},

				"dataProvider": {
    				"map": "worldLow",
    				"getAreasFromMap": true
  				},
				"listeners": [ {
					"event": "homeButtonClicked",
					"method": this.handleGoHome
				}, {
					"event": "clickMapObject",
					"method": this.handleMapObjectClick
				}]
			});
		},
		methods: {
			handleGoHome() {
				this.paese = '';
				this.$map.validateNow();
			},

			handleMapObjectClick(event) {
				this.$map.selectedObject = this.$map.dataProvider;
			    event.mapObject.showAsSelected = !event.mapObject.showAsSelected;
			    this.$map.returnInitialColor(event.mapObject);
			    var id = this.getId(event.mapObject.id);
			    if(event.mapObject.showAsSelected) {
			    	this.aggiungiPaese(id);
			    } else {
			    	this.rimuoviPaese(id);
			    }
			},
			preSelectCountries() {
				for(var i = 0; i < this.paesi_utente.length; i++) {
			    var area = this.$map.getObjectById(this.paesi_utente[i].sigla);
			    area.showAsSelected = true;
			    this.$map.returnInitialColor(area);
			  }
			},
			getId(paese) {
				if(paese.length == 2) {
					for(var i in this.paesi) {
						var item = this.paesi[i];
						if(item.sigla == paese) {
							return item.id;
						}
					}
				} 
			},
			aggiungiPaese(id) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/osservatore/add/paese/'+ this.osservatore.id + "/" + id).then((response) => {
				if (response.ok)
				{
					this.paesi_utente = response.data; 
				}
				}, (response) => {
            	// error callback
        		});
			},
			rimuoviPaese(id) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/osservatore/del/paese/'+ this.osservatore.id + "/" + id).then((response) => {
				if (response.ok)
				{
					this.paesi_utente = response.data; 
				}
				}, (response) => {
            	// error callback
        		});	
			}
		},
	}
	</script>