<template>
	<div style="width: 100%">

		<div class="border p-2 mb-2 bg-light">
			<b-row>
				<b-col cols="6">
					<b-input-group>
						<b-form-input v-on:keydown.enter.prevent="search_ex" type="text" v-model="ux.search" placeholder="Cerca esercizio da Rosa per titolo/autore..."></b-form-input>
						<b-input-group-append>
							<b-button variant="primary" @click="search_ex()"><i class="fa fa-search" aria-hidden="true"></i></b-button>
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col cols="6">
					<b-input-group>
						<b-form-input v-on:keydown.enter.prevent="search_ex_condivisi" type="text" v-model="ux.search_condivisi" placeholder="Cerca esercizio da Archivio per titolo/autore..."></b-form-input>
						<b-input-group-append>
							<b-button variant="primary" @click="search_ex_condivisi()"><i class="fa fa-search" aria-hidden="true"></i></b-button>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>			
		</div>


		<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
			
			<b-media class="py-3 border-bottom w-100"  :style="selectedEx.indexOf(null) != -1 ? 'background-color: yellow' : ''"  @click="setExercise(emptyExercise)">
				<b-img slot="aside" src="http://via.placeholder.com/320x150?text=Fase Palestra" height="150" />
				<div class="d-flex flex-column" style="height: 80px;">
					<h5 class="flex-shrink-0 pb-2 m-0">Fase Palestra</h5>
					<!-- <div v-if="!ex.portiere">
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="getLabel(ex.principiDiGioco, skills[0])">{{ getLabel(ex.principiDiGioco, skills[0]) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="getLabel(ex.componentiTecniche, skills[1])">{{ getLabel(ex.componentiTecniche, skills[1]) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills[2])">{{ getLabel(ex.capacitaMotorie, skills[2]) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
					</div>
					<div v-if="ex.portiere">
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaPodalica, skills_portiere[0])">{{ getLabel(ex.tecnicaPodalica, skills_portiere[0]) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaDiBase, skills_portiere[1])">{{ getLabel(ex.tecnicaDiBase, skills_portiere[1]) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="getLabel(ex.qualitaTattiche, skills_portiere[2])">{{ getLabel(ex.qualitaTattiche, skills_portiere[2]) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills_portiere[3])">{{ getLabel(ex.capacitaMotorie, skills_portiere[3]) }}</span></p>
					</div> -->
					<div class="flex-grow-1" style="overflow-y: auto;">
						<p class="text-muted">Esercizio Vuoto. Parti da questo per creare la tua fase personalizzata.</p>
					</div>
				</div>
			</b-media>

			<b-media class="py-3 border-bottom w-100" :style="selectedEx.indexOf(ex.id) != -1 ? 'background-color: yellow' : ''" v-for="ex in exercises" :key="ex.id" @click="setExercise(ex)">
				<b-img slot="aside" :src="ex.media.length > 0 ? (ex.media[0].tipo == 'foto' ? ex.media[0].foto_url : ex.media[0].image) : '/assets/varie/missing.jpg'" height="170" style="cursor:pointer;"/>
				<div class="d-flex flex-column" style="height: 170px;">
					<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
					<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
					<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Facility">Facility:</span> <span class="color-rosso" :title="ex.facility ? ex.facility.name : ''">{{ ex.facility ? ex.facility.name : '' }}</span></p>
					<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Facility">Goal:</span> <span class="color-rosso" :title="ex.goal ? ex.goal.name : ''">{{ ex.goal ? ex.goal.name : '' }}</span></p>
					<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tt">Tt:</span> <span class="color-rosso" :title="ex.tt ? ex.tt.name : ''">{{ ex.tt ? ex.tt.name : '' }}</span></p>
					<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Intensity">Intensity:</span> <span class="color-rosso" :title="ex.intensity ? ex.intensity.name : ''">{{ ex.intensity ? ex.intensity.name : '' }}</span></p>
					<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>	
					<div class="flex-grow-1">
						<p class="text-muted"><small>{{ex.description | trim(420)}}</small></p>
					</div>
				</div>
			</b-media>


		</v-infinite-scroll>

	</div>
</template>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';
import InfiniteScroll from 'v-infinite-scroll';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');


export default {
	components: {
		'v-infinite-scroll': InfiniteScroll
	},
	data: function() {
		return {
			drills: [],
			skills: [],
			selected: [],
			facility_opt: [],
			goal_opt: [],
			tt_opt: [],
			intensity_opt: [],
			ux: {
				searchType: null,
				loading: false,
				drill_id: null,
				search: '',
				search_condivisi: ''
			}
		}
	},
	filters: {
		trim: function(val, length) {
			return _.truncate(val, { length: length, omission: '…' });
		}
	},
	computed: {
		exercises: function() {
			return this[this.ux.searchType] ? this[this.ux.searchType].data : [];
		},
		...mapState('palestra', ['exercises_archivio', 'exercises_condivisi', 'page']),
		...mapState(['rosaTeamId']),
		user: function() {
			return Auth.user;
		},
		auth: function() {
			return Auth;
		},
		emptyExercise: function() {
			return {
				id: null,
				name: 'Esercizio',
				esercizio: null,
				id_facility: null,
				id_goal: null,
				id_tt: null,
				id_intensity: null
			}
		},
		selectedEx: function() {
			return _.map(this.selected, 'id');
		}
	},
	created: function() {
		//this.getDrills();
		//this.getSkills();
	},
	watch: {
		selected: {
			deep: true,
			handler: function() {

				var esercizi = _.map(this.selected, function(exercise) {
					var ex = _.cloneDeep(exercise);
					return {
						tipologia: 'Football',
						item: {
							id: 'gen-ex-' + _.uniqueId(),
							nome: ex.name,
							tipologia: 'Palestra',
							baseObject: {
								id_esercizio: exercise.id,
								esercizio: exercise,
								id_facility: exercise.id_facility,
								id_goal: exercise.id_goal,
								id_tt: exercise.id_tt,
								id_intensity: exercise.id_intensity
							}
						}
					};
				});

				this.$emit('addFasi', { 
					fasi: esercizi, 
					filter: { 
						tipologia: 'Football', 
						item: { tipologia: 'Palestra' }
					}
				});

			}
		}
	},
	methods: {
		nextPage() {
			if(this.ux.searchType == 'exercises_archivio') {
				if(this.page < this.exercises_archivio.last_page) {
					this.$store.commit('palestra/setPage', this.page + 1);
					this.$store.dispatch('palestra/filter_archivio', this.page + 1);
				}
			} else {
				if(this.page < this.exercises_condivisi.last_page) {
					this.$store.commit('palestra/setPage', this.page + 1);
					this.$store.dispatch('palestra/filter_condivisi', this.page + 1);
				}	
			}
		},
		nextPageCondivisi() {
			if(this.page < this.exercises_condivisi.last_page) {
				this.$store.commit('palestra/setPage', this.page + 1);
				this.$store.dispatch('palestra/filter_condivisi', this.page + 1);
			}
		},
		search_ex: function () {
			this.ux.searchType = 'exercises_archivio'
			var array = [];
			this.$store.commit('palestra/setTitle', this.ux.search);
			this.$store.commit('palestra/setPage', 1);
			this.$store.dispatch('palestra/filter_archivio');
		},
		search_ex_condivisi: function () {
			this.ux.searchType = 'exercises_condivisi';
			var array = [];
			this.$store.commit('palestra/setTitle', this.ux.search_condivisi);
			this.$store.commit('palestra/setPage', 1);
			this.$store.dispatch('palestra/filter_condivisi');
		},
		getOptionsEsercizi: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/opzioni/esercizi').then(function(res) {
				this.facility_opt = res.data.facility;
				this.goal_opt = res.data.goal;
				this.tt_opt = res.data.tt;
				this.intensity_opt = res.data.intensity;
			});
		},
		setExercise: function(exercise) {

			if(!_.find(this.selected, { id: exercise ? exercise.id : null })) {
				if(exercise == null)
					this.selected.push({ id: null });
				else
					this.selected.push(exercise);
			} else {
				var k = _.findKey(this.selected, { id: exercise.id });
				this.selected.splice(k, 1);
			}

		}
	}
}

</script>