<template id=" acad_val_new">
	<section class="acad_val_new">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="academy">
			<div class="card-body p-3">
				<div class="row flex-items-xs-middle">
					<div class="col-5">
						<div class="media">
							<img v-bind:src="academy.logo_url" class="" height="80">
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{academy.nome}}</strong></h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="card-title">
					<span>{{ $i18n.t('Valutazione') }}</span>
				</div>
				<div class="content">
					<b-row align-v="center" ref="page_1">
						<b-col>
							<b-row class="mb-2 py-2">
								<b-col cols="4">
									{{ $i18n.t('Data') }}
								</b-col>
								<b-col>
									<strong>{{ form.data | formatDate}}</strong>
								</b-col>
								<b-col>
								</b-col>
							</b-row>
							<b-row class="mb-2 py-2">
								<b-col cols="4">
								</b-col>
								<b-col>
									<span class="color-rosso"><strong>{{ $i18n.t('Voto') }}</strong></span>
								</b-col>
								<b-col cols="7">
									<span class="color-rosso"><strong>{{ $i18n.t('Note') }}</strong></span>
								</b-col>
							</b-row>
							<b-row v-for="item, key in voti" class="mb-2 py-2">
								<b-col cols="4">
									{{ $i18n.t(item) }}
								</b-col>
								<b-col class="border">
									<strong>{{ form.voti[key] }}</strong>
								</b-col>
								<b-col cols="7" class="border">
									{{ form.note[key] }}
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="5">
							<radarchart radarid="radar_val" :datachart="radardatachart" :wchart="500" :hchart="278"></radarchart>
						</b-col>
					</b-row>
					<b-button variant="primary" @click="download" class="mt-3 text-uppercase">{{ $i18n.t('Stampa pdf') }}</b-button>
				</div>	
			</div>
		</div>


		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../../store/types';
import { jsPDF } from 'jspdf';
export default {

	data: function() {
		return {
			academy: null,
			form: {
				data: null,
				voti: {
					1: 0,
					2: 0,
					3: 0,
					4: 0,
					5: 0,
					6: 0,
				},
				note: {
					1: '',
					2: '',
					3: '',
					4: '',
					5: '',
					6: '',
				},
			},
			voti: {
				1: this.$i18n.t('Utilizzo software'),
				2: this.$i18n.t('Interesse apprendimento'),
				3: this.$i18n.t('Qualità allenatori'),
				4: this.$i18n.t('Qualità giocatori'),
				5: this.$i18n.t('Struttura centro sportivo'),
				6: this.$i18n.t('Struttura organizzativa societaria'),
			},
			options: [{ value: 0, text: '1-10' }, 1,2,3,4,5,6,7,8,9,10],
			header_pdf: null,
			page_count: 0,
			page_hratio: [1],
			page_pdf: [null],
			logo_academy: null,
		}
	},

	components: {
		headapp: require('../../../layout/headapp.vue').default,
		menusub: require('../../../layout/menusub.vue').default,
		footerapp: require('../../../layout/footerapp.vue').default,
		radarchart: require('../../../layout/charts/radar.vue').default,
	},

	created: function () {
		if(this.$route.params.id) {
			axios.get(`/academyita/societa/get/` + this.$route.params.id)
			.then((response) => {
				this.academy = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		}
		if(this.$route.params.val_id) {
			axios.get(`/academyint/valutazione/` + this.$route.params.val_id)
			.then((response) => {
				this.form.data = response.data.data;
				this.form.voti = JSON.parse(response.data.voti);
				this.form.note = JSON.parse(response.data.note);
			}).catch((err) => {
				this.loading = false;
			});
		}

		this.$http.get(this.$store.state.apiEndPoint + '/headerpdf/academy').then((response) => {
			if (response.ok)
			{
				this.header_pdf = response.data;    
			}
		}, (response) => {
		});

		this.$http.get(this.$store.state.apiEndPoint + '/logo/academy/base64/' + this.$route.params.id).then((response) => {
			if (response.ok)
			{
				this.logo_academy = response.data;    
			}
		}, (response) => {
		});

	},

	computed: {
		radardatachart: function() {
			var data = {
				labels: [],
				datasets: [{
					label: '',
					data: [],
					backgroundColor: 'rgba(255, 102, 0, 0.5)',
					borderColor: 'rgb(255, 102, 0)',
					pointBackgroundColor: 'rgb(255, 102, 0)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgb(255, 102, 0)',
				}]
			};
			for(var i in this.voti) {
				data.labels.push(this.voti[i]);
			}
			for(var i in this.form.voti) {
				data.datasets[0].data.push(this.form.voti[i]);
			}
			return data;	
		},
	},

	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.addValutazione();
		},

		addValutazione() {
			if(!this.form.data) {
				alert(this.$i18n.t('Devi inserire una data per la valutazione!'));
				return;
			}
			var array_keys = Object.keys(this.voti);
			for(var i in array_keys) {
				var key = array_keys[i];
				if(!this.form.voti[key]) {
					alert(this.voti[key] + ': ' + this.$i18n.t('voto obbligatorio') + '!');
					return;
				}
			}
			this.$http.post(this.$store.state.apiEndPoint + '/academyint/valutazione/edit/' + this.$route.params.val_id, this.form).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: "academy_int_societa_valutazioni", params: { id: this.$route.params.id }});
					
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		download() { 

			this.page_count = 0;
			this.page_hratio = [1];
			this.page_pdf = [ null];

			this.printing = true;

			for(var page = 1; page <=1; page++) {

				const el = this.$refs['page_' + page];

				this.page_hratio[page - 1] = el.clientHeight/el.clientWidth;

				const options = {
					type: 'dataURL',
					index: page,
				}
				this.$html2canvas(el, options).then(canvas => {
					this.page_pdf[options.index - 1] = canvas;
					this.page_count++;

				});
			}
		},
	},

	watch: {
		page_count() {
			if(this.page_count ==  1) {
				var doc = new jsPDF("l", "mm", "a4");
				var width = doc.internal.pageSize.getWidth();
				var height = doc.internal.pageSize.getHeight();	
				doc.addImage(this.header_pdf, 'PNG', 0, 0, width, 40);
				if(this.logo_academy && this.logo_academy != '') {
					doc.addImage(this.logo_academy, 'PNG', 220, 10, 43, 17);
				}
				doc.addImage(this.page_pdf[0], 'JPEG', 15, 40, width, (width * this.page_hratio[0]));
				doc.save('valutazione.pdf');
				this.printing = false;
			}
		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
