<template id="card">
	<div class="card">
		<div class="card-body">

		<div v-if="title" class="card-title">
			<span>{{ title }}</span>
		</div>

		<div class="content">

			<div :is="comp" :params="params"></div>

		</div>
		</div>
	</div>
</template>

<script>
export default {

	data: function(){
		return {

			dummy: null

		}
	},

	props: {

		title: {
			type: String,
			required: false
		},

		comp: {
			type: String,
			required: true
		},

		params: {
			type: Object,
			required: false
		}

	},

	components: {

		elencopartite:      require('../layout/elencopartite.vue').default,
		elencogiocatori:    require('../layout/elencogiocatori.vue').default,
		elencostaff:    require('../layout/elencostaff.vue').default,

		gcarriera:          require('../layout/giocatore/carriera.vue').default,
		griassunto:         require('../layout/giocatore/riassunto.vue').default,
		gvalutazioni:       require('../layout/giocatore/valutazioni.vue').default,
		gprove:       		require('../layout/giocatore/prove.vue').default,
		gstats:       		require('../layout/giocatore/stats.vue').default,
		gpalestra:       	require('../layout/giocatore/palestra.vue').default,
		gattivita:       	require('../layout/giocatore/attivita.vue').default,
		ginjuries:       	require('../layout/giocatore/injuries.vue').default,
		gillnesses:       	require('../layout/giocatore/illnesses.vue').default,
		ginjuriesrtp:       require('../layout/giocatore/injuries_rtp.vue').default,
		gsessions:       require('../layout/giocatore/sessions.vue').default,

		scoutvalutazioni:   require('../layout/giocatore/scoutvalutazioni.vue').default,

		esercizifiltri:     require('../layout/esercizi/esercizifiltri.vue').default,
		esercizibozze:      require('../layout/esercizi/esercizibozze.vue').default,
		esercizielenco:     require('../layout/esercizi/esercizielenco.vue').default,
		eserciziasroma:     require('../layout/esercizi/eserciziasroma.vue').default,
		esercizicertificati:     require('../layout/esercizi/esercizicertificati.vue').default,
		esercizidavalidare:     require('../layout/esercizi/esercizidavalidare.vue').default,
		esercizieditor:     require('../layout/esercizi/esercizieditor.vue').default,

		acadesercizifiltri:     require('../layout/esercizi_academy/esercizifiltri.vue').default,
		acadesercizi:     		require('../layout/esercizi_academy/esercizi.vue').default,
		acadesercizibozze:      require('../layout/esercizi_academy/esercizibozze.vue').default,
		acadeserciziarchivio:   require('../layout/esercizi_academy/eserciziarchivio.vue').default,
		acadesercizidavalidare:      require('../layout/esercizi_academy/esercizidavalidare.vue').default,
		acadesercizieditor:     require('../layout/esercizi_academy/esercizieditor.vue').default,

		palestraesercizifiltri: require('../layout/palestra/esercizifiltri.vue').default,
		palestraesercizi:       require('../layout/palestra/esercizi.vue').default,
		palestraeserciziarchivio: require('../layout/palestra/eserciziarchivio.vue').default,
		palestraesercizicondivisi: require('../layout/palestra/esercizicondivisi.vue').default,
		palestraesercizieditor:     require('../layout/palestra/esercizieditor.vue').default,

		piscinaesercizifiltri: require('../layout/piscina/esercizifiltri.vue').default,
		piscinaesercizi:       require('../layout/piscina/esercizi.vue').default,
		piscinaeserciziarchivio: require('../layout/piscina/eserciziarchivio.vue').default,
		piscinaesercizicondivisi: require('../layout/piscina/esercizicondivisi.vue').default,
		piscinaesercizieditor:     require('../layout/piscina/esercizieditor.vue').default,

        simplemessage:       require('../layout/simplemessage.vue').default,
	}

}
</script>
