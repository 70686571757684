import _ from 'lodash';
import moment from 'moment';


export default {
	deviazioneStandard: function(ds) {
		var avg = _.sum(ds) / ds.length;
		var ii = _.round(Math.sqrt(_.sum(_.map(ds, (i) => Math.pow((i - avg), 2))) / ds.length), 0);

		if(ii !== 0)
			return ii;
		return '';
	},
	buildDurationByFase: function(dati, ds = null) {
		var duration = (_.sumBy(dati, 'total_duration')/60)/_.size(dati);
		return _.round(duration);
	},
	buildNeuromByFase: function(fase, dati, ds = null) {

		var duration = this.buildDurationByFase(dati, ds);

		var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
		var edi = _.sum(_.filter(_.map(dati, function(a) { 
			return  (a.catapult_equivalent_distance / a.total_distance) - 1;}), function(b) { 
			return  isValidNumber(b) }))/_.size(dati) * 100;
		var pow_ev = _.sum([
			_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
			])/_.size(dati);
		var num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
			])/_.size(dati);
		var acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count')/_.size(dati);
		var acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count')/_.size(dati);
		var acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')/_.size(dati);
		var num_dec = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
			])/_.size(dati);
		var dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count')/_.size(dati);
		var dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count')/_.size(dati);
		var dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')/_.size(dati);

		return {
			fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, 0),
			gps_minutes: this.isValidNumber(duration) ? _.round(duration, 0) : 0,
			mp: this.isValidNumber(mp) ? _.round(mp, 1) : 0,
			edi: this.isValidNumber(edi) ? _.round(edi, 1) : 0,
			pow_ev: this.isValidNumber(pow_ev) ? _.round(pow_ev, 0) : 0,
			num_acc: this.isValidNumber(num_acc) ? _.round(num_acc, 0) : 0,
			acc1: this.isValidNumber(acc1) ? _.round(acc1, 2) : 0,
			acc2: this.isValidNumber(acc2) ? _.round(acc2, 2) : 0,
			acc3: this.isValidNumber(acc3) ? _.round(acc3, 2) : 0,
			num_dec: this.isValidNumber(num_dec) ? _.round(num_dec, 2) : 0,
			dec3: this.isValidNumber(dec3) ? _.round(dec3, 2) : 0,
			dec2: this.isValidNumber(dec2) ? _.round(dec2, 2) : 0,
			dec1: this.isValidNumber(dec1) ? _.round(dec1, 2) : 0,
		}
	},
	buildDrillByFase: function(fase, dati, ds = null) {

		var duration = this.buildDurationByFase(dati, ds);

		var total_distance = _.sumBy(dati, 'total_distance')/_.size(dati);
		var relative_distance =  total_distance/duration;
		var cc_dist_15 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			])/_.size(dati);
		var max_vel = _.sumBy(dati, 'catapult_max_vel')/_.size(dati);
		var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
		var edi = _.sum(_.filter(_.map(dati, function(a) { 
			return  (a.catapult_equivalent_distance / a.total_distance) - 1;}), function(b) { 
			return  isValidNumber(b) }))/_.size(dati) * 100;
		var pow_ev = _.sum([
			_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
			])/_.size(dati);
		var num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
			])/_.size(dati);
		var num_dec =_.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
			])/_.size(dati);
		var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count')/_.size(dati);

		var player_load = _.sumBy(dati, 'catapult_total_player_load')/_.size(dati);
		var player_load_distance = _.sum(_.map(dati, function(a) { return a.total_distance ? a.catapult_total_player_load/a.total_distance : null; }))/_.size(dati);

		return {
			fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, 0),
			gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : 0,
			total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : 0,
			relative_distance: isValidNumber(relative_distance) ? _.round(relative_distance) : 0,
			cc_dist_15: isValidNumber(cc_dist_15) ? _.round(cc_dist_15) : 0,
			max_vel: isValidNumber(max_vel) ? _.round(max_vel, 1) : 0,
			mp: isValidNumber(mp) ? _.round(mp, 1) : 0,
			edi: isValidNumber(edi) ? _.round(edi, 1) : 0,
			pow_ev: isValidNumber(pow_ev) ? _.round(pow_ev, 0) : 0,
			num_acc: isValidNumber(num_acc) ? _.round(num_acc, 2) : 0,
			num_dec: isValidNumber(num_dec) ? _.round(num_dec, 2) : 0,
			sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : 0,
			player_load: isValidNumber(player_load) ? _.round(player_load, 0) : 0,
			player_load_distance: isValidNumber(player_load_distance) ? _.round(player_load_distance, 2) : 0,
		}
	},
	buildVelocityByFase: function(fase, dati, ds = null) {

		var duration = this.buildDurationByFase(dati, ds);

		var total_distance = _.sumBy(dati, 'total_distance')/_.size(dati);
		var velocity_0_8 =_.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
			])/_.size(dati);
		var velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_distance')/_.size(dati);
		var velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_distance')/_.size(dati);
		var velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_distance')/_.size(dati);
		var velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_distance')/_.size(dati);
		var velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_distance')/_.size(dati);
		var velocity__15 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			])/_.size(dati);
		var velocity__20 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			])/_.size(dati);
		var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count')/_.size(dati);
		var velocity__15_p = 100 * (_.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]) / _.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band4_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band5_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance')
			]));

		var velocity__15_p = 100 * _.sum(_.map(dati, function(a) { return _.sum([
			a.catapult_velocity_band6_total_distance,
			a.catapult_velocity_band7_total_distance,
			a.catapult_velocity_band8_total_distance,
			]) / _.sum([
			a.catapult_velocity_band1_total_distance,
			a.catapult_velocity_band2_total_distance,
			a.catapult_velocity_band3_total_distance,
			a.catapult_velocity_band4_total_distance,
			a.catapult_velocity_band5_total_distance,
			a.catapult_velocity_band6_total_distance,
			a.catapult_velocity_band7_total_distance,
			a.catapult_velocity_band8_total_distance
			]); }))/_.size(dati);

		return {
			fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, 0),
			gps_minutes: this.isValidNumber(duration) ? _.round(duration, 0) : 0,
			total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : 0,
			velocity_0_8: isValidNumber(velocity_0_8) ? _.round(velocity_0_8, 0) : 0,
			velocity_8_13: isValidNumber(velocity_8_13) ? _.round(velocity_8_13, 0) : 0,
			velocity_13_15: isValidNumber(velocity_13_15) ? _.round(velocity_13_15, 0) : 0,
			velocity_15_20: isValidNumber(velocity_15_20) ? _.round(velocity_15_20, 0) : 0,
			velocity_20_25: isValidNumber(velocity_20_25) ? _.round(velocity_20_25, 0) : 0,
			velocity__25: isValidNumber(velocity__25) ? _.round(velocity__25, 0) : 0,
			velocity__15: isValidNumber(velocity__15) ? _.round(velocity__15, 0) : 0,
			velocity__20: isValidNumber(velocity__20) ? _.round(velocity__20, 0) : 0,
			sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : 0,
			velocity__15_p: isValidNumber(velocity__15_p) ? _.round(velocity__15_p, 1) : 0,
		}
	},
	buildHeartRateByFase: function(fase, dati, ds = null) {

		var duration = this.buildDurationByFase(dati, ds);

		var avg_hr = _.sum(_.map(dati, function(a) { return a.firstbeat_avg_hb; }))/_.size(dati);
		var avg_hr_p = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_max_hb_absolute ? a.firstbeat_avg_hb/a.firstbeat_max_hb_absolute : 0; }))/_.size(dati);
		var max_hr = _.sum(_.map(dati, function(a) { return a.firstbeat_max_hb ? a.firstbeat_max_hb : null; }))/_.size(dati);
		var max_hr_p = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_max_hb && a.firstbeat_max_hb_absolute ? a.firstbeat_max_hb/a.firstbeat_max_hb_absolute : null; }))/_.size(dati);
		var hr_0_55 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_55/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
		var hr_55_65 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_55_65/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
		var hr_65_75 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_65_75/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
		var hr_75_85 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_75_85/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
		var hr_85_95 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_85_95/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
		var hr_95 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_95/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
		var hr_85 = 100 * _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_check ? a.firstbeat_heart_rate_85/a.firstbeat_heart_rate_check : null; }))/_.size(dati);
		var hr_85_raw = _.sum(_.map(dati, function(a) { return a.firstbeat_heart_rate_85/12; }))/_.size(dati);
		
		var edward = _.sum(_.map(dati, function(a) { 
			return _.sum([
				a.firstbeat_heart_rate_50_60, 
				a.firstbeat_heart_rate_60_70 * 2,
				a.firstbeat_heart_rate_70_80 * 3,
				a.firstbeat_heart_rate_80_90 * 4,
				a.firstbeat_heart_rate_90 * 5,
				])/12;}))/_.size(dati);
		var session_minutes = _.sum(_.map(dati, function(a) { return a.fase_durata; }))/_.size(dati);
		var rpe = _.sum(_.map(dati, function(a) { return a.fase_rpe; }))/_.size(dati);
		var sessione_tl = session_minutes * rpe;

		return {
			fase: fase.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, 0),
			gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : 0,
			avg_hr: isValidNumber(avg_hr) ? _.round(avg_hr, 2) : 0,
			avg_hr_p: isValidNumber(avg_hr_p) ? _.round(avg_hr_p, 1) : 0,
			max_hr: isValidNumber(max_hr) ? _.round(max_hr, 0) : 0,
			max_hr_p: isValidNumber(max_hr_p) ? _.round(max_hr_p, 0) : 0,
			hr_0_55: isValidNumber(hr_0_55) ? _.round(hr_0_55, 2) : 0,
			hr_55_65: isValidNumber(hr_55_65) ? _.round(hr_55_65, 2) : 0,
			hr_65_75: isValidNumber(hr_65_75) ? _.round(hr_65_75, 2) : 0,
			hr_75_85: isValidNumber(hr_75_85) ? _.round(hr_75_85, 2) : 0,
			hr_85_95: isValidNumber(hr_85_95) ? _.round(hr_85_95, 2) : 0,
			hr_95: isValidNumber(hr_95) ? _.round(hr_95, 2) : 0,
			hr_85: isValidNumber(hr_85) ? _.round(hr_85, 2) : 0,
			hr_85_raw: isValidNumber(hr_85_raw) ? _.round(hr_85_raw, 0) : 0,
			edward: isValidNumber(edward) ? _.round(edward, 0) : 0,
			sessione_tl: isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : 0,
		}
	},









	buildDuration: function(dati, ds = null) {

		var duration = _.sumBy(dati, 'total_duration')/60;
		if(ds !== null) {

			duration = 0;

			var valid_sessions = _.keys(_.groupBy(dati, 'sessione_id'));

			var fasiInCuiHoPartecipato = _.filter(ds.timeline.timeline, function(a) {
				return a.persone.indexOf(dati[0].persona_id) !== -1 && !a.is_sessione;
			});

			var fasiInCuiHoPartecipatoG = _.groupBy(fasiInCuiHoPartecipato, 'sessione_id');
			var durateSessione = {};

			_.each(fasiInCuiHoPartecipatoG, function(a, kk) {
				if(valid_sessions.indexOf(kk) !== -1) {
					var c = _.first(a);
					var b = _.last(a);
					duration += ((b.end - c.start)/60);
				}
			});

		}

		return _.round(duration);

	},
	buildRpe: function(dati) {
		var rpeByDay = [];
		var datiWithRpe = _.filter(dati, function(a) { return a.fase_rpe != null; });
		var datiByDay = _.groupBy(datiWithRpe, 'date');
		_.forEach(datiByDay, function(a) {
			var datiBySession = _.groupBy(a, 'sessione_id');
			var rpe = 0;
			_.forEach(datiBySession, function(b) {
				rpe += b[0].fase_rpe;
			});
			rpeByDay.push(rpe/_.keys(datiBySession).length);
		});

		if(_.keys(datiByDay).length > 0) {
			return _.sum(rpeByDay) / _.keys(datiByDay).length;
		}
		return null;
	},
	buildTqr: function(dati) {
		var tqrByDay = [];
		var datiWithTqr = _.filter(dati, function(a) { return a.fase_tqr != null; });
		var datiByDay = _.groupBy(datiWithTqr, 'date');
		_.forEach(datiByDay, function(a) {
			var datiBySession = _.groupBy(a, 'sessione_id');
			var tqr = 0;
			_.forEach(datiBySession, function(b) {
				tqr += b[0].fase_tqr;
			});
			tqrByDay.push(tqr/_.keys(datiBySession).length);
		});

		if(_.keys(datiByDay).length > 0) {
			return _.sum(tqrByDay) / _.keys(datiByDay).length;
		}
		return null;
	},
	buildSessionTl: function(dati) {

		var datiWithRpeAndDurata = _.filter(dati, function(a) { return a.fase_rpe != null && a.fase_durata != null; });
		var datiBySession = _.groupBy(datiWithRpeAndDurata, 'sessione_id');
		var sessione_tl = 0;
		_.forEach(datiBySession, function(a) {
			sessione_tl += a[0].fase_rpe * a[0].fase_durata;
		});
		if(_.keys(datiBySession).length > 0) {
			return sessione_tl;
		}
		return null;
	},
	fromDatasetToMean: function(a, dati) {
		
	},
	buildNeurom: function(dati, ds = null, mean = false, remap = false) {

		var duration = this.buildDuration(dati, ds);

		var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
		var edi = ((_.sumBy(dati, 'catapult_equivalent_distance') / _.sumBy(dati, 'total_distance'))-1) * 100;
		var pow_ev = _.sum([
			_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
			]);
		var num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
			]);
		var acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count');
		var acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count');
		var acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count');
		var num_dec = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
			]);
		var dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count');
		var dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count');
		var dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count');

		var tmp = {
			gps_minutes: this.isValidNumber(duration) ? _.round(duration, 0) : 0,
			mp: this.isValidNumber(mp) ? _.round(mp, 1) : 0,
			edi: this.isValidNumber(edi) ? _.round(edi, 1) : 0,
			pow_ev: this.isValidNumber(pow_ev) ? _.round(pow_ev, 0) : 0,
			num_acc: this.isValidNumber(num_acc) ? _.round(num_acc, 0) : 0,
			acc1: this.isValidNumber(acc1) ? _.round(acc1, 2) : 0,
			acc2: this.isValidNumber(acc2) ? _.round(acc2, 2) : 0,
			acc3: this.isValidNumber(acc3) ? _.round(acc3, 2) : 0,
			num_dec: this.isValidNumber(num_dec) ? _.round(num_dec, 2) : 0,
			dec3: this.isValidNumber(dec3) ? _.round(dec3, 2) : 0,
			dec2: this.isValidNumber(dec2) ? _.round(dec2, 2) : 0,
			dec1: this.isValidNumber(dec1) ? _.round(dec1, 2) : 0
		}

		if(mean == true || remap != false) {

			if(mean == true) {

				var out = {};
				_.each(_.keys(tmp), function(p) {
					out[p] = tmp[p]/dati.length;
				});

			}

			if(remap != false) {

				_.each(_.keys(tmp), function(p) {
					out[p] /= (dati[0].total_duration/60);
					out[p] *= remap;
				});

			}

			return out;

		}


		return tmp;

	},
	buildDrill: function(dati, ds = null, mean = false, remap = false) {

		var duration = this.buildDuration(dati, ds);

		var total_distance = _.sumBy(dati, 'total_distance');
		var relative_distance =  _.sumBy(dati, 'total_distance')/duration;
		var cc_dist_15 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]);
		var max_vel = _.maxBy(dati, 'catapult_max_vel') ? _.maxBy(dati, 'catapult_max_vel').catapult_max_vel : 0;
		var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
		var edi = 100 * ((_.sumBy(dati, 'catapult_equivalent_distance') / _.sumBy(dati, 'total_distance'))-1);
		var pow_ev = _.sum([
			_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
			]);
		var num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
			]);
		var num_dec =_.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
			]);
		var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count');
		var edward = _.sum([
			_.sumBy(dati, 'firstbeat_heart_rate_50_60'),
			_.sumBy(dati, 'firstbeat_heart_rate_60_70') * 2,
			_.sumBy(dati, 'firstbeat_heart_rate_70_80') * 3,
			_.sumBy(dati, 'firstbeat_heart_rate_80_90') * 4,
			_.sumBy(dati, 'firstbeat_heart_rate_90') * 5,
			])/12;
		var session_minutes = _.sum(_.map(_.groupBy(dati, 'sessione_id'), function(a) { return a[0].fase_durata; }));
		var rpe = this.buildRpe(dati);
		var sessione_tl = this.buildSessionTl(dati);
		var tqr = this.buildTqr(dati);

		var tmp = {
			gps_minutes: this.isValidNumber(duration) ? _.round(duration, 0) : 0,
			total_distance: this.isValidNumber(total_distance) ? _.round(total_distance, 0) : 0,
			relative_distance: this.isValidNumber(relative_distance) ? _.round(relative_distance) : 0,
			cc_dist_15: this.isValidNumber(cc_dist_15) ? _.round(cc_dist_15) : 0,
			max_vel: this.isValidNumber(max_vel) ? _.round(max_vel, 1) : 0,
			mp: this.isValidNumber(mp) ? _.round(mp, 1) : 0,
			edi: this.isValidNumber(edi) ? _.round(edi, 1) : 0,
			pow_ev: this.isValidNumber(pow_ev) ? _.round(pow_ev, 0) : 0,
			num_acc: this.isValidNumber(num_acc) ? _.round(num_acc, 2) : 0,
			num_dec: this.isValidNumber(num_dec) ? _.round(num_dec, 2) : 0,
			sp_ev: this.isValidNumber(sp_ev) ? _.round(sp_ev, 0) : 0,
			edward: this.isValidNumber(edward) ? _.round(edward, 0) : 0,
			session_minutes: this.isValidNumber(session_minutes) ? _.round(session_minutes, 0) : 0,
			rpe: this.isValidNumber(rpe) ? _.round(rpe, 1) : 0,
			sessione_tl: this.isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : 0,
			tqr: this.isValidNumber(tqr) ? _.round(tqr, 1) : 0,
		}

		if(mean == true || remap != false) {

			if(mean == true) {

				var out = {};
				_.each(_.keys(tmp), function(p) {
					out[p] = tmp[p]/dati.length;
				});

			}

			if(remap != false) {

				_.each(_.keys(tmp), function(p) {
					out[p] /= (dati[0].total_duration/60);
					out[p] *= remap;
				});

			}

			return out;

		}

		return tmp;

	},
	buildVelocity: function(dati, ds = null, mean = false, remap = false) {

		var duration = this.buildDuration(dati, ds);

		var total_distance = _.sumBy(dati, 'total_distance');
		var velocity_0_8 =_.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
			]);
		var velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_distance');
		var velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_distance');
		var velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_distance');
		var velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_distance');
		var velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_distance');
		var velocity__15 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]);
		var velocity__20 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]);
		var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count');
		var velocity__15_p = 100 * (_.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]) / _.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band4_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band5_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance')
			]));

		var vel_max_perc_0_60 =_.sum([
			_.sumBy(dati, 'catapult_velocity2_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band3_total_distance'),
			]);
		var vel_max_perc_60_70 = _.sumBy(dati, 'catapult_velocity2_band4_total_distance');
		var vel_max_perc_70_80 = _.sumBy(dati, 'catapult_velocity2_band5_total_distance');
		var vel_max_perc_80_90 = _.sumBy(dati, 'catapult_velocity2_band6_total_distance');
		var vel_max_perc_90_100 = _.sumBy(dati, 'catapult_velocity2_band7_total_distance');
		var vel_max_perc__100 = _.sumBy(dati, 'catapult_velocity2_band8_total_distance');
		var vel_max_perc__80 = _.sum([
			_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
			]);
		var vel_max_perc__90 = _.sum([
			_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
			]);
		var vel_max_perc__80_p = 100 * (_.sum([
			_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band8_total_distance'),
			]) / _.sum([
			_.sumBy(dati, 'catapult_velocity2_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band3_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band4_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band5_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity2_band8_total_distance')
			]));

		var tmp = {
			gps_minutes: this.isValidNumber(duration) ? _.round(duration, 0) : 0,
			total_distance: this.isValidNumber(total_distance) ? _.round(total_distance, 0) : 0,
			velocity_0_8: this.isValidNumber(velocity_0_8) ? _.round(velocity_0_8, 0) : 0,
			velocity_8_13: this.isValidNumber(velocity_8_13) ? _.round(velocity_8_13, 0) : 0,
			velocity_13_15: this.isValidNumber(velocity_13_15) ? _.round(velocity_13_15, 0) : 0,
			velocity_15_20: this.isValidNumber(velocity_15_20) ? _.round(velocity_15_20, 0) : 0,
			velocity_20_25: this.isValidNumber(velocity_20_25) ? _.round(velocity_20_25, 0) : 0,
			velocity__25: this.isValidNumber(velocity__25) ? _.round(velocity__25, 0) : 0,
			velocity__15: this.isValidNumber(velocity__15) ? _.round(velocity__15, 0) : 0,
			velocity__20: this.isValidNumber(velocity__20) ? _.round(velocity__20, 0) : 0,
			sp_ev: this.isValidNumber(sp_ev) ? _.round(sp_ev, 0) : 0,
			velocity__15_p: this.isValidNumber(velocity__15_p) ? _.round(velocity__15_p, 1) : 0,
			vel_max_perc_0_60: this.isValidNumber(vel_max_perc_0_60) ? _.round(vel_max_perc_0_60, 0) : 0,
			vel_max_perc_60_70: this.isValidNumber(vel_max_perc_60_70) ? _.round(vel_max_perc_60_70, 0) : 0,
			vel_max_perc_70_80: this.isValidNumber(vel_max_perc_70_80) ? _.round(vel_max_perc_70_80, 0) : 0,
			vel_max_perc_80_90: this.isValidNumber(vel_max_perc_80_90) ? _.round(vel_max_perc_80_90, 0) : 0,
			vel_max_perc_90_100: this.isValidNumber(vel_max_perc_90_100) ? _.round(vel_max_perc_90_100, 0) : 0,
			vel_max_perc__100: this.isValidNumber(vel_max_perc__100) ? _.round(vel_max_perc__100, 0) : 0,
			vel_max_perc__80: this.isValidNumber(vel_max_perc__80) ? _.round(vel_max_perc__80, 0) : 0,
			vel_max_perc__90: this.isValidNumber(vel_max_perc__90) ? _.round(vel_max_perc__90, 0) : 0,
			vel_max_perc__80_p: this.isValidNumber(vel_max_perc__80_p) ? _.round(vel_max_perc__80_p, 1) : 0,
			alert_d: _.filter(_.map(dati, 'alert_d')).length > 0,
			alert_v: _.filter(_.map(dati, 'alert_v')).length > 0,
			alert_h: _.filter(_.map(dati, 'alert_h')).length > 0
		}

		if(mean == true || remap != false) {

			if(mean == true) {

				var out = {};
				_.each(_.keys(tmp), function(p) {
					out[p] = tmp[p]/dati.length;
				});

			}

			if(remap != false) {

				_.each(_.keys(tmp), function(p) {
					out[p] /= (dati[0].total_duration/60);
					out[p] *= remap;
				});

			}

			return out;

		}

		return tmp;

	},
	buildHeartRate: function(dati, ds = null) {

		var duration = this.buildDuration(dati, ds);

		var find_max_hr = dati.find(item => item.firstbeat_max_hb_absolute);
		var max_hb_absolute = find_max_hr ? find_max_hr.firstbeat_max_hb_absolute :  null;

		var avg_hr = _.sum(_.filter(_.map(dati, 'firstbeat_avg_hb')))/_.filter(_.map(dati, 'firstbeat_avg_hb')).length;
		var avg_hr_p = 100 * (_.sum(_.filter(_.map(dati, 'firstbeat_avg_hb')))/_.filter(_.map(dati, 'firstbeat_avg_hb')).length/max_hb_absolute);
		var max_hr = _.maxBy(dati, 'firstbeat_max_hb') ? _.maxBy(dati, 'firstbeat_max_hb').firstbeat_max_hb : null;
		var max_hr_p = 100 * (_.maxBy(dati, 'firstbeat_max_hb') ? _.maxBy(dati, 'firstbeat_max_hb').firstbeat_max_hb/max_hb_absolute : null);
		var hr_0_55 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_55')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
		var hr_55_65 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_55_65')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
		var hr_65_75 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_65_75')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
		var hr_75_85 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_75_85')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
		var hr_85_95 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_85_95')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
		var hr_95 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_95')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
		var hr_85 = 100 * (_.sumBy(dati, 'firstbeat_heart_rate_85')/_.sumBy(dati, 'firstbeat_heart_rate_check'));
		var hr_85_raw = _.sumBy(dati, 'firstbeat_heart_rate_85')/12;
		var edward = _.sum([
			_.sumBy(dati, 'firstbeat_heart_rate_50_60'),
			_.sumBy(dati, 'firstbeat_heart_rate_60_70') * 2,
			_.sumBy(dati, 'firstbeat_heart_rate_70_80') * 3,
			_.sumBy(dati, 'firstbeat_heart_rate_80_90') * 4,
			_.sumBy(dati, 'firstbeat_heart_rate_90') * 5,
			])/12;
		var sessione_tl = this.buildSessionTl(dati);

		return {
			gps_minutes: this.isValidNumber(duration) ? _.round(duration, 0) : 0,
			avg_hr: this.isValidNumber(avg_hr) ? _.round(avg_hr, 2) : 0,
			avg_hr_p: this.isValidNumber(avg_hr_p) ? _.round(avg_hr_p, 1) : 0,
			max_hr: this.isValidNumber(max_hr) ? _.round(max_hr, 0) : 0,
			max_hr_p: this.isValidNumber(max_hr_p) ? _.round(max_hr_p, 0) : 0,
			hr_0_55: this.isValidNumber(hr_0_55) ? _.round(hr_0_55, 2) : 0,
			hr_55_65: this.isValidNumber(hr_55_65) ? _.round(hr_55_65, 2) : 0,
			hr_65_75: this.isValidNumber(hr_65_75) ? _.round(hr_65_75, 2) : 0,
			hr_75_85: this.isValidNumber(hr_75_85) ? _.round(hr_75_85, 2) : 0,
			hr_85_95: this.isValidNumber(hr_85_95) ? _.round(hr_85_95, 2) : 0,
			hr_95: this.isValidNumber(hr_95) ? _.round(hr_95, 2) : 0,
			hr_85: this.isValidNumber(hr_85) ? _.round(hr_85, 2) : 0,
			hr_85_raw: this.isValidNumber(hr_85_raw) ? _.round(hr_85_raw, 0) : 0,
			edward: this.isValidNumber(edward) ? _.round(edward, 0) : 0,
			sessione_tl: this.isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : 0,
		}
	},
	isValidNumber: function(a) {
		if(!_.isNumber(a) || _.isNaN(a) || a == 0 || !_.isFinite(a)) return false;
		return true;
	},

	ChartDatasetter: function(dataset, days, results, show, builder) {

		var weekCount = 1;
		var weekSessioneId = null;
		dataset = _.orderBy(dataset, [ 'sessione_id', function(a) {
			return moment(a).format('X');
		}], [ 'asc', 'asc' ]);

		_.each(dataset, function(a) {

			var sessione = _.find(results.sessioni, { id: a.sessione_id });

			var week = 1;
			_.each(days, function(b) {
				var data = b.split(' ');
				if(data[1] && data[1].length > 0) {
					if(moment(a.date).format('x') > moment(data[0]).format('x')) {
						week++;
					}
				}
			});
			a.week = 'Week ' + week;

			if(sessione.sessione_tipo_glo_id === 15 && weekSessioneId !== a.sessione_id) {
				weekCount++;
				weekSessioneId = a.sessione_id;
			}

		});

		dataset = _.groupBy(dataset, 'date');
		
		if(show == 'media') {

			var data = _.mapValues(dataset, function(a) {

				var gp = _.groupBy(a, 'persona_id');

				var kpiBuilt = _.mapValues(gp, function(b) {
					var c = builder(b);
					c.sessione_id = b.sessione_id;
					c.persona_id = b.persona_id;
					return c;
				});


				var objToReturn = {};
				var keys = _.keys(_.first(_.values(kpiBuilt)));
				// console.log(kpiBuilt);
				_.each(keys, function(key) {
					if(key !== 'week' && key !== 'date')
						objToReturn[key] = _.round(_.sumBy(_.values(kpiBuilt), key) / _.size(kpiBuilt), 1);
					else {
						objToReturn[key] = _.values(kpiBuilt)[0][key];
					}
				});

				return objToReturn;

			});

			return data;

		} else {
			return _.mapValues(dataset, function(a) {
				return builder(a);
			});
		}

	}
}