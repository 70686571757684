<template>
	<div class="small">
		<div v-if="loading" class="text-center"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
		<videotag v-if="!loading" :goalVisible="false"></videotag>
	</div>
</template>

<script>

import moment from 'moment';
import { mapState } from 'vuex';

export default {

	data: function() {
		return {
			loading: true,
			data: [],
		}
	},
	props: [ 'videoId', 'personeIds'],

	components: {
		videotag: require('@/components/layout/videotag/Home.vue').default,	
	},

	created: function () {
	},
	computed: {
		...mapState(['rosaTeamId']),	
	},
	watch: {
		personeIds() {
			this.loadData();	 
		},
		videoId() {
			this.loadData();	 
		}
	},
	methods: {
		loadData() {
			this.loading = true;	
			this.$http.post(this.$store.state.apiEndPoint + '/videotags', {
				video_id: this.videoId,
				person_ids: this.personeIds,
				team_id: this.rosaTeamId,
			}).then((response) => {
				if (response.ok) {
					this.data = response.data;
					this.$store.commit('cancelNewTag');
					this.$store.commit('setPrivateTag', false);
					this.$store.commit('updateData', this.data);
					this.loading = false;	
				}
			}, (response) => {
			});
		}
	}
}
</script>