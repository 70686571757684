<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<b-card title="Romanext" title-tag="span">
				<b-card-body class="pt-3 px-0" v-if="!confronto">	
					<b-row>
						<b-col cols="2">
							<b-input-group class="mb-2">
								<b-form-input type="text" v-model="query" placeholder="Giocatore"></b-form-input>
								<b-input-group-append>
									<b-button variant="primary" @click="cercaGiocatore">
										<i class="fa fa-search" aria-hidden="true"></i>
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-col>
						<b-col cols="2">
							<b-button variant="outline-primary" @click="giocatoriSocieta" title="carica giocatori società">
								<img src="assets/images/logo.png" height="20">
							</b-button>
						</b-col>
						<b-col cols="1">
							<b-form-select v-model="id_modulo">
								<option v-for="item in modules" :value="item.id" v-if="item.id == 4 || item.id == 7">{{item.sistema_gioco}}</option>
							</b-form-select>
						</b-col>
						<b-col cols="4">
							<b-form-input v-model="nome" type="text" placeholder="Nome"></b-form-input>
						</b-col>
						<b-col cols="1">
						</b-col>
						<b-col cols="1">
							<i class="fa fa-trash" aria-hidden="true" style="font-size: 3em;float:left;position:relative;left:35px;" title="Trascina qui per eliminare">
							</i>
							<draggable :options="{group:'giocatori'}" style="height:45px;">
							</draggable>
						</b-col>
						<b-col cols="1" class="text-right">
							<b-button variant="primary" title="Formazioni" @click.prevent="showFormazioni">
								<i class="fa fa-users" aria-hidden="true"></i>
							</b-button>
							<b-button variant="primary" title="Archivio PDF" @click.prevent="archivioPdf">
								<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
							</b-button>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="2">
							<div v-if="search" class="text-center">
								<img src="/assets/varie/loading.gif" style="width: 50px;" />
							</div>
							<div style="height:65vh;overflow-y:auto;overflow-x: hidden;" 
							v-if="giocatori.length > 0">
							<draggable :list="giocatori" :options="{group:{ name:'giocatori', put:false}, sort:false}">
								<div v-for="giocatore in giocatori" :key="'gio_' + giocatore.id">
									<b-card no-body class="mb-1 p-1" :id="'gio_' + giocatore.id" title="clicca per maggiori info">
										<b-row style="cursor:pointer">
											<b-col>
												<b-img :src="giocatore.avatar_url" :alt="giocatore.cognome + ' ' + giocatore.nome" :height="50">
												</b-img>
												<small>{{ giocatore.cognome }}</small>
											</b-col>
										</b-row>
									</b-card>
									<b-popover :target="'gio_' + giocatore.id" placement="top" triggers="click" :title="giocatore.cognome + ' ' + giocatore.nome">
										<div class="font-size-xs" v-if="giocatore.nascita_data"> {{ giocatore.nascita_data | anni }} anni</div>
										<div class="font-size-xs color-rosso">{{giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 ? giocatore.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}</div>
										<div class="font-size-xs" v-if="giocatore.last_carriera_sportiva.squadra">{{ giocatore.last_carriera_sportiva.squadra.officialName }}</div>
										<div class="font-size-xs" v-if="giocatore.info_economica && giocatore.info_economica.valore">Valore: <strong>{{ giocatore.info_economica.valore }}</strong></div>
										<div class="font-size-xs" v-if="giocatore.contratto && giocatore.contratto.stipendio_lordo">Stipendio lordo: <strong>{{ giocatore.contratto.stipendio_lordo }}</strong></div>
										<div class="font-size-xs text-success" v-if="giocatore.giocatore && giocatore.giocatore.cresciuto_giovanili">Cresciuto nelle giovanili</div>
										<div class="font-size-xs" v-if="giocatore. giocatore && giocatore.giocatore.cresciuto_vivaio_italiano">Cresciuto in un vivaio italiano</div>
									</b-popover>
								</div>
							</draggable>
						</div>
					</b-col>
					<b-col>
						<div :class="'romanext modulo-' + modulo">
							<div class="campo">
								<img src="/assets/images/campo.svg">
							</div>
							<div v-for="(item, key) in formazione" :class="'player player-'+ key" :key="'players_' + key">
								<span class="numero"> {{ key }}</span>
								<divisa
								:type="divisa.type"
								:primario="divisa.primario"
								:secondario="divisa.secondario"
								:terziario="divisa.terziario"
								></divisa>
								<div style="position:absolute;z-index:1;width:140px;top:0px;left:36px;" :key="'div_' + key">
									<draggable :list="item" :options="{group:{ name:'giocatori'}}" style="min-height:50px;">
										<div v-for="(giocatore, key) in item" :key="'form_' + key + '_' + giocatore.id" style="line-height: 35px;">
											<b-card no-body class="mb-1" :text-variant="stato(giocatore)" :border-variant="stato(giocatore)" :id="'form_' + key + '_' + giocatore.id" title="clicca per maggiori info">
												<b-row>
													<b-col>
														<b-img :src="giocatore.avatar_url" :alt="giocatore.cognome + ' ' + giocatore.nome" :height="35" style="float:left;">
														</b-img>
														<div style="width:100px;white-space: nowrap;overflow:hidden;"><small>{{ giocatore.cognome }}</small></div>
													</b-col>
												</b-row>
											</b-card>
											<b-popover :target="'form_' + key + '_' + giocatore.id" placement="top" triggers="click" :title="giocatore.cognome + ' ' + giocatore.nome">
												<div class="font-size-xs" v-if="giocatore.nascita_data"> {{ giocatore.nascita_data | anni }} anni</div>
												<div class="font-size-xs color-rosso">{{giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 ? giocatore.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}</div>
												<div class="font-size-xs" v-if="giocatore.last_carriera_sportiva.squadra">{{ giocatore.last_carriera_sportiva.squadra.officialName }}</div>
												<div  class="font-size-xs"v-if="giocatore.info_economica && giocatore.info_economica.valore">Valore: <strong>{{ giocatore.info_economica.valore }}</strong></div>
												<div class="font-size-xs" v-if="giocatore.contratto && giocatore.contratto.stipendio_lordo">Stipendio lordo: <strong>{{ giocatore.contratto.stipendio_lordo }}</strong></div>
												<div class="font-size-xs text-success" v-if="giocatore. giocatore && giocatore.giocatore.cresciuto_giovanili">Cresciuto nelle giovanili</div>
												<div class="font-size-xs" v-if="giocatore.giocatore && giocatore.giocatore.cresciuto_vivaio_italiano">Cresciuto in un vivaio italiano</div>
											</b-popover>
										</div>
									</draggable>
								</div>
							</div>
						</div>
						<div style="position:absolute;top:0;right:0;text-align:right;">
							<div><strong>ROSA</strong></div>
							<div>- <span class="color-rosso font-weight-bold">{{ n_giocatori }}</span> giocatori</div>
							<div>- <span class="color-rosso font-weight-bold">{{ n_confermati }}</span> confermati</div>
							<div>- <span class="color-rosso font-weight-bold">{{ n_acquisti }}</span> acquisti</div>
							<div>- <span class="color-rosso font-weight-bold">{{ n_cresciuti_giovanili }}</span> giovanili</div>
							<div>- <span class="color-rosso font-weight-bold">{{ n_cresciuti_vivaio_italiano }}</span> vivaio italiano</div>
							<div>- <span class="color-rosso font-weight-bold">{{ eta_media }}</span> età media</div>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="2">
					</b-col>
					<b-col class="text-center">
						<b-row>
							<b-col>
								<h5>Valore rosa <span id="no_valore_rosa_tt" v-if="no_valore_rosa != ''" style="cursor:pointer;font-weight:bold;"> * </span> = <span class="color-rosso font-weight-bold">{{ valore_rosa }}</span></h5>
								<b-tooltip target="no_valore_rosa_tt" v-if="no_valore_rosa != ''">
									Valori mancanti su:<br/>
									<span v-html="no_valore_rosa"></span>
								</b-tooltip>
							</b-col>
							<b-col>
								<h5>Valore titolari <span id="no_valore_titolari_tt" v-if="no_valore_titolari != ''" style="cursor:pointer;font-weight:bold;"> * </span> = <span class="color-rosso font-weight-bold">{{ valore_titolari }}</span></h5>
								<b-tooltip target="no_valore_titolari_tt" v-if="no_valore_titolari != ''">
									Valori mancanti su:<br/>
									<span v-html="no_valore_titolari"></span>
								</b-tooltip>
							</b-col>
							<b-col>
								<h5>Valore riserve <span id="no_valore_riserve_tt" v-if="no_valore_riserve != ''" style="cursor:pointer;font-weight:bold;"> * </span> = <span class="color-rosso font-weight-bold">{{ valore_riserve }}</span></h5>
								<b-tooltip target="no_valore_riserve_tt" v-if="no_valore_riserve != ''">
									Valori mancanti su:<br/>
									<span v-html="no_valore_riserve"></span>
								</b-tooltip>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<h5>Stipendio rosa <span id="no_stipendio_rosa_tt" v-if="no_stipendio_rosa != ''" style="cursor:pointer;font-weight:bold;"> * </span> = <span class="color-rosso font-weight-bold">{{ stipendio_rosa }}</span></h5>
								<b-tooltip target="no_stipendio_rosa_tt" v-if="no_stipendio_rosa != ''">
									Valori mancanti su:<br/>
									<span v-html="no_stipendio_rosa"></span>
								</b-tooltip>
							</b-col>
							<b-col>
								<h5>Stipendio titolari <span id="no_stipendio_titolari_tt" v-if="no_stipendio_titolari != ''" style="cursor:pointer;font-weight:bold;"> * </span> = <span class="color-rosso font-weight-bold">{{ stipendio_titolari }}</span></h5>
								<b-tooltip target="no_stipendio_titolari_tt" v-if="no_stipendio_titolari != ''">
									Valori mancanti su:<br/>
									<span v-html="no_stipendio_titolari"></span>
								</b-tooltip>
							</b-col>
							<b-col>
								<h5>Stipendio riserve <span id="no_stipendio_riserve_tt" v-if="no_stipendio_riserve != ''" style="cursor:pointer;font-weight:bold;"> * </span> = <span class="color-rosso font-weight-bold">{{ stipendio_riserve }}</span></h5>
								<b-tooltip target="no_stipendio_riserve_tt" v-if="no_stipendio_riserve != ''">
									Valori mancanti su:<br/>
									<span v-html="no_stipendio_riserve"></span>
								</b-tooltip>
							</b-col>
						</b-row>
						<b-row class="mt-4 border-top pt-4">
							<b-col class="text-left">
								<b-button variant="primary" @click="nuovaFormazione">Nuova</b-button>
								<b-button variant="success" v-if="!id_formazione" @click.prevent="salvaFormazione">Salva</b-button>
								<b-button variant="success" v-if="id_formazione" @click.prevent="updateFormazione">Modifica</b-button>
								<b-button v-if="id_formazione" @click.prevent="eliminaFormazione">Elimina</b-button>
							</b-col>
							<b-col>
								<b-form-group label="Titolo pdf" horizontal :label-cols="2" v-if="id_formazione">
									<b-form-input v-model="title_pdf" type="text" class="mb-1"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col class="text-left" cols="1">
								<b-button variant="primary" v-if="id_formazione" @click.prevent="salvaPdf">Salva PDF</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-card-body>
			<b-card-body class="pt-3 px-0" v-if="confronto">
				<div v-if="confronti.length == 0" class="text-center">
					<img src="/assets/varie/loading.gif" style="width: 150px;" />
				</div>
				<b-row>
					<b-col cols="6" v-for="(obj, index) in confronti">
						<div class="text-center"><h5>{{ obj.nome }}</h5></div>
						<div :class="'romanext modulo-' + getNameModulo(obj.id_modulo)">
							<div class="campo">
								<img src="/assets/images/campo.svg">
							</div>
							<div v-for="(item, key) in obj.formazione" :class="'player player-'+ key" :key="'players_' + key">
								<span class="numero"> {{ key }}</span>
								<divisa
								:type="divisa.type"
								:primario="divisa.primario"
								:secondario="divisa.secondario"
								:terziario="divisa.terziario"
								></divisa>
								<div style="position:absolute;z-index:1;width:100px;top:0px;left:36px;" :key="'div_' + key">
									<div v-for="(giocatore, key) in item" style="line-height: 25px;">
										<b-card no-body class="mb-1" :text-variant="stato(giocatore)" :border-variant="stato(giocatore)" :id="'form_' + index + '_' + key + '_' + giocatore.id" title="clicca per maggiori info">
											<b-row>
												<b-col>
													<b-img :src="giocatore.avatar_url" :alt="giocatore.cognome + ' ' + giocatore.nome" :height="25" style="float:left;">
													</b-img>
													<div style="width:60px;white-space: nowrap;overflow:hidden;"><small>{{ giocatore.cognome }}</small></div>
												</b-col>
											</b-row>
										</b-card>
										<b-popover :target="'form_' + index + '_' + key + '_' + giocatore.id" placement="top" triggers="click" :title="giocatore.cognome + ' ' + giocatore.nome">
											<div class="font-size-xs" v-if="giocatore.nascita_data"> {{ giocatore.nascita_data | anni }} anni</div>
											<div class="font-size-xs color-rosso">{{giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 ? giocatore.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}</div>
											<div class="font-size-xs" v-if="giocatore.last_carriera_sportiva.squadra">{{ giocatore.last_carriera_sportiva.squadra.officialName }}</div>
											<div  class="font-size-xs"v-if="giocatore.info_economica && giocatore.info_economica.valore">Valore: <strong>{{ giocatore.info_economica.valore }}</strong></div>
											<div class="font-size-xs" v-if="giocatore.contratto && giocatore.contratto.stipendio_lordo">Stipendio lordo: <strong>{{ giocatore.contratto.stipendio_lordo }}</strong></div>
											<div class="font-size-xs text-success" v-if="giocatore.giocatore && giocatore.giocatore.cresciuto_giovanili">Cresciuto nelle giovanili</div>
											<div class="font-size-xs" v-if="giocatore.giocatore && giocatore.giocatore.cresciuto_vivaio_italiano">Cresciuto in un vivaio italiano</div>
										</b-popover>
									</div>
								</div>
							</div>
						</div>
						<div style="position:absolute;top:0;right:0;text-align:right;">
							<div><strong>ROSA</strong></div>
							<div>- <span class="color-rosso font-weight-bold">{{ obj.n_giocatori }}</span> giocatori</div>
							<div>- <span class="color-rosso font-weight-bold">{{ obj.n_confermati }}</span> confermati</div>
							<div>- <span class="color-rosso font-weight-bold">{{ obj.n_acquisti }}</span> acquisti</div>
							<div>- <span class="color-rosso font-weight-bold">{{ obj.n_cresciuti_giovanili }}</span> giovanili</div>
							<div>- <span class="color-rosso font-weight-bold">{{ obj.n_cresciuti_vivaio_italiano }}</span> vivaio italiano</div>
							<div>- <span class="color-rosso font-weight-bold">{{ obj.eta_media }}</span> età media</div>
						</div>
						<b-row class="text-center">
							<b-col>
								<div style="font-size:1.1rem;">Valore rosa = <span class="color-rosso font-weight-bold">{{ obj.valore_rosa }}</span></div>
							</b-col>
							<b-col>
								<div style="font-size:1.1rem;">Valore titolari = <span class="color-rosso font-weight-bold">{{ obj.valore_titolari }}</span></div>
							</b-col>
							<b-col>
								<div style="font-size:1.1rem;">Valore riserve = <span class="color-rosso font-weight-bold">{{ obj.valore_riserve }}</span></div>
							</b-col>
						</b-row>
						<b-row class="text-center mb-3">
							<b-col>
								<div style="font-size:1.1rem;">Stipendio rosa = <span class="color-rosso font-weight-bold">{{ obj.stipendio_rosa }}</span></div>
							</b-col>
							<b-col>
								<div style="font-size:1.1rem;">Stipendio titolari = <span class="color-rosso font-weight-bold">{{ obj.stipendio_titolari }}</span></div>
							</b-col>
							<b-col>
								<div style="font-size:1.1rem;">Stipendio riserve = <span class="color-rosso font-weight-bold">{{ obj.stipendio_riserve }}</span></div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-button variant="primary" @click="backFormazione">Esci</b-button>	
			</b-card-body>
		</b-card>
	</div>
	<footerapp></footerapp>
	<b-modal ref="formazioniModal" title="Formazioni" ok-only ok-title="Chiudi" size="lg">	
		<b-table :items="formazioni" :fields="[{key: 'seleziona', label: ''}, 'nome', 'utente', {key: 'updated_at', label: 'Data', formatter: this.formatDate}, 'azioni']">
			<template #cell(seleziona)="row">
				<b-form-checkbox v-model="formazioni_ids" :value="row.item.id"></b-form-checkbox>
			</template>
			<template #cell(utente)="row">
				{{ row.item.utente.nomeCompleto }}
			</template>
			<template #cell(azioni)="row">
				<b-button size="sm" title="carica formazione" @click.prevent="loadFormazione(row.item.id)" variant="primary">
					<i class="fa fa-folder-open" aria-hidden="true"></i>
				</b-button>
				<b-button size="sm" title="elimina formazione" @click.prevent="eliminaRigaFormazione(row.item.id)">
					<i class="fa fa-trash" aria-hidden="true"></i>
				</b-button>
			</template>
		</b-table>
		<b-button title="carica formazione" @click.prevent="confronta" variant="success">Confronta</b-button>
	</b-modal>
	<b-modal ref="pdfModal" title="Archivio PDF" ok-only ok-title="Chiudi" size="lg">	
		<b-table :items="elenco_pdf" :fields="['titolo', 'utente', {key: 'created_at', label: 'Data', formatter: this.formatDate}, 'azioni']">
			<template #cell(utente)="row">
				{{ row.item.utente.nomeCompleto }}
			</template>
			<template #cell(azioni)="row">
				<b-button size="sm" title="download PDF" @click="downloadPdf(row.item.id)" variant="primary">
					<i class="fa fa-download" aria-hidden="true"></i>
				</b-button>
				<b-button size="sm" title="elimina PDF" @click="eliminaPdf(row.item.id)">
					<i class="fa fa-trash" aria-hidden="true"></i>
				</b-button>
			</template>
		</b-table>
	</b-modal>
</section>
</template>

<script>
import draggable from 'vuedraggable';
import * as types from '../../../store/types';
import moment from 'moment';
var Auth = require('../../../auth.js');
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
		divisa:           	require('../../layout/divise/divisa.vue').default,
		draggable,
	},

	data () {
		return {
			nome: '',
			modules: [],
			modulo: '433',
			id_modulo: 4,
			id_formazione: null,
			divisa: {
				type:       'tinta',
				primario:   '#a80532',
				secondario: '#FFEB3B',
				terziario:  '#f07f01'
			},
			formazione: {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			},
			query: '',
			giocatori: [],
			search: false,
			moduli_ruoli: {
				4: [3, 10, 6, 5, 9, 15, 13, 14, 25, 27, 24], /* 4-3-3 */
				7: [3, 10, 6, 5, 9, 18, 17, 23, 21, 22, 26], /* 4-2-3-1 */
			},
			errors: [],
			formazioni: [],
			elenco_pdf: [],

			confronto: false,
			formazioni_ids: [],
			confronti: [],
		}
	},

	created() {    
		this.getModuli();
		this.loadFormazioni();
	},

	methods: {
		getModuli: function(){
			this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.modules = response.data;
            }
        }, (response) => {
            // error callback
        });
		},
		loadFormazioni: function(){
			this.$http.get(this.$store.state.apiEndPoint + '/romanext/formazioni').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.formazioni = response.data;
            }
        }, (response) => {
            // error callback
        });
		},
		cercaGiocatore: function() {
			if(this.query != "") {
				this.giocatori = [];
				this.search = true;
				this.$http.get(this.$store.state.apiEndPoint + '/romanext/cerca/giocatore/' + this.query).then((response) => {

					if (response.ok)
					{
						this.giocatori = response.data;
						this.search = false;
					}
				}, (response) => {

				});
			}
		},
		giocatoriSocieta: function() {
			this.giocatori = [];
			this.search = true;
			this.$http.get(this.$store.state.apiEndPoint + '/romanext/giocatori/societa').then((response) => {

				if (response.ok)
				{
					for(var i in response.data) {
						var gio = response.data[i];
						var ruolo_ideale = this.ruoloIdeale(gio);
						if(ruolo_ideale && this.moduli_ruoli[this.id_modulo].indexOf(ruolo_ideale.id) > -1) {
							var index_ruolo  = this.moduli_ruoli[this.id_modulo].indexOf(ruolo_ideale.id);
							this.formazione[index_ruolo + 1].push(gio);
						} else {
							this.giocatori.push(gio);
						}
					}
					this.search = false;
				}
			}, (response) => {

			});
		},
		stato: function(giocatore) {
			var stato = '';
			if(giocatore.last_carriera_sportiva.squadra && giocatore.last_carriera_sportiva.squadra.societa.length > 0) {
				stato = 'danger';
			}
			else if(giocatore.giocatore && giocatore.giocatore.cresciuto_giovanili) {
				stato = 'success';
			} else if(!giocatore.last_carriera_sportiva.squadra || (giocatore.last_carriera_sportiva.squadra && giocatore.last_carriera_sportiva.squadra.societa.length == 0)) {
				stato = 'info';
			}
			return stato;
		},
		ruoloIdeale: function(giocatore) {
			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				return giocatore.last_carriera_sportiva.ruolo_ideale[0];
			}
			return null;
		},
		nuovaFormazione: function() {
			this.nome = '';
			this.id_formazione = null;
			this.giocatori = [];
			this.formazione = {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			};
		},
		salvaFormazione: function() {
			if(this.nome == "") {
				alert("Devi inserire un nome per la formazione, ad esempio '2018/2019'.");
				return;
			}
			var form_data = {
				nome: this.nome,
				modulo: this.id_modulo,
				formazione: {
					1: [],
					2: [],
					3: [],
					4: [],
					5: [],
					6: [],
					7: [],
					8: [],
					9: [],
					10: [],
					11: [],
				},
			};
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					form_data.formazione[key].push(giocatore.id);
				}
			}
			this.$http.post(this.$store.state.apiEndPoint + '/romanext/aggiungi', form_data).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.formazioni = response.data.formazioni;
					this.id_formazione = response.data.id_formazione;
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		}, 
		updateFormazione: function() {
			if(this.nome == "") {
				alert("Devi inserire un nome per la formazione, ad esempio '2018/2019'.");
				return;
			}
			var form_data = {
				nome: this.nome,
				modulo: this.id_modulo,
				formazione: {
					1: [],
					2: [],
					3: [],
					4: [],
					5: [],
					6: [],
					7: [],
					8: [],
					9: [],
					10: [],
					11: [],
				},
			};
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					form_data.formazione[key].push(giocatore.id);
				}
			}
			this.$http.post(this.$store.state.apiEndPoint + '/romanext/modifica/' + this.id_formazione, form_data).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.formazioni = response.data.formazioni;
					this.id_formazione = response.data.id_formazione;
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		eliminaFormazione: function() {
			if(confirm("Confermi l'eliminazione della formazione selezionata?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/romanext/elimina/' + this.id_formazione).then((response)=> {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.formazioni = response.data.formazioni;
						this.nuovaFormazione();
					}
				}, (response) => {  
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},
		eliminaRigaFormazione: function($id) {
			if(confirm("Confermi l'eliminazione della formazione selezionata?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/romanext/elimina/' + $id).then((response)=> {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.formazioni = response.data.formazioni;
						if(this.id_formazione == $id) {
							this.nuovaFormazione();
						}
					}
				}, (response) => {  
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},
		showFormazioni: function() {
			this.$refs.formazioniModal.show();
		},
		loadFormazione: function($id) {
			this.$http.get(this.$store.state.apiEndPoint + '/romanext/carica/' + $id).then((response)=> {
				if (response.ok)
				{
					this.nome = response.data.nome;
					this.id_modulo = response.data.id_sistema_gioco;
					this.id_formazione = response.data.id;
					this.formazione = response.data.formazione;
					this.$refs.formazioniModal.hide();
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		salvaPdf: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/romanext/salva/pdf/' + this.id_formazione, {titolo: this.title_pdf}).then(response => {
				if (response.ok) {
					window.location = this.$store.state.apiEndPoint + '/romanext/download/pdf/' + response.data + "/" + Auth.user.id + "/" + Auth.user.api_token;		
				}
			}, (response) => {
            // error callback
        });
		},
		downloadPdf: function(pdf) {
			window.location = this.$store.state.apiEndPoint + '/romanext/download/pdf/' + pdf + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		eliminaPdf: function(pdf) {
			if(confirm("Confermi l'eliminazione del pdf?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/romanext/elimina/pdf/' + pdf).then((response) => {
					if (response.ok)
					{
						this.elenco_pdf = response.data; 
					}
				}, (response) => {
	            // error callback
	        });
			}
		},
		archivioPdf: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/romanext/archivio/pdf').then((response) => {
				if (response.ok)
				{
					this.elenco_pdf = response.data; 
					this.$refs.pdfModal.show();
				}
			}, (response) => {
            // error callback
        });
		},
		formatDate (data) {
			var m = moment(data);
			var format = m.format('DD/MM/YYYY'); 
			return format;
		},
		age: function (date) {
			var birthday = moment(date);
			if(birthday.isValid()) {
				return moment().diff(birthday, 'years');
			}
			return 0;
		},
		confronta: function () {
			if(this.formazioni_ids.length < 2) {
				alert("Devi selezionare almeno 2 formazioni!");
				return;
			}
			if(this.formazioni_ids.length > 4) {
				alert("Puoi confrontare al massimo 4 formazioni!");
				return;
			}
			this.confronto = true;
			this.$refs.formazioniModal.hide();
			this.confronti = [];
			for(var i in this.formazioni_ids) {
				this.$http.get(this.$store.state.apiEndPoint + '/romanext/carica/' + this.formazioni_ids[i]).then((response)=> {
					if (response.ok)
					{
						var nome = response.data.nome;
						var id_modulo = response.data.id_sistema_gioco;				
						var formazione = response.data.formazione;
						var n_giocatori = 0;
						var n_confermati = 0;
						var n_acquisti = 0;
						var n_cresciuti_giovanili = 0;
						var n_cresciuti_vivaio_italiano = 0;
						var eta_media = 0;
						var count_eta_media = 0;
						var valore_rosa = 0;
						var valore_titolari = 0;
						var valore_riserve = 0;
						var stipendio_rosa = 0;
						var stipendio_titolari = 0;
						var stipendio_riserve = 0;
						for (var key in formazione) {
							var array_gio = formazione[key];
							for(var i in array_gio) {
								var giocatore = array_gio[i];
								if(giocatore.info_economica && giocatore.info_economica.valore) {
									valore_rosa += parseFloat(giocatore.info_economica.valore);
								}
								if(giocatore.contratto && giocatore.contratto.stipendio_lordo) {
									stipendio_rosa += parseFloat(giocatore.contratto.stipendio_lordo);
								}
								if(i == 0) {
									if(giocatore.info_economica && giocatore.info_economica.valore) {
										valore_titolari += parseFloat(giocatore.info_economica.valore);
									}
									if(giocatore.contratto && giocatore.contratto.stipendio_lordo) {
										stipendio_titolari += parseFloat(giocatore.contratto.stipendio_lordo);
									}
								} else {
									if(giocatore.info_economica && giocatore.info_economica.valore) {
										valore_riserve += parseFloat(giocatore.info_economica.valore);
									}
									if(giocatore.contratto && giocatore.contratto.stipendio_lordo) {
										stipendio_titolari += parseFloat(giocatore.contratto.stipendio_lordo);
									}
								}
								n_giocatori++;
								if(giocatore.last_carriera_sportiva.squadra && giocatore.last_carriera_sportiva.squadra.societa.length > 0) {
									n_confermati++;
								}
								if(!!giocatore.giocatore.cresciuto_giovanili && (!giocatore.last_carriera_sportiva.squadra || (giocatore.last_carriera_sportiva.squadra && giocatore.last_carriera_sportiva.squadra.societa.length == 0))) {
									n_acquisti++;
								}
								if(giocatore.giocatore && giocatore.giocatore.cresciuto_giovanili) {
									n_cresciuti_giovanili++;
								}
								if(giocatore.giocatore && giocatore.giocatore.cresciuto_vivaio_italiano) {
									n_cresciuti_vivaio_italiano++;
								}
								var eta = this.age(giocatore.nascita_data);
								if(eta > 0) {
									count_eta_media++;
									eta_media += eta;
								}
							}
						}
						if(count_eta_media > 0) {
							eta_media = Math.round(eta_media/count_eta_media);
						}
						var obj = {
							nome: nome,
							id_modulo: id_modulo,
							formazione: formazione,
							valore_rosa: valore_rosa,
							valore_titolari: valore_titolari,
							valore_riserve: valore_riserve,
							stipendio_rosa: stipendio_rosa,
							stipendio_titolari: stipendio_titolari,
							stipendio_riserve: stipendio_riserve,
							n_giocatori: n_giocatori,
							n_confermati: n_confermati,
							n_acquisti: n_acquisti,
							n_cresciuti_giovanili: n_cresciuti_giovanili,
							n_cresciuti_vivaio_italiano: n_cresciuti_vivaio_italiano,
							eta_media: eta_media,
						}
						this.confronti.push(obj);
					}
				}, (response) => {  
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},
		backFormazione: function () {
			this.confronto = false;
		},
		getNameModulo: function (id_modulo) {
			for(var i in this.modules) {
				var modulo = this.modules[i];
				if(modulo.id == id_modulo) {
					var name = modulo.sistema_gioco.replace(/ /g, "").replace(/-/g, "");
					return name;
				}
			}
		},
	},
	computed: {
		valore_rosa: function() {
			var value = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore.info_economica && giocatore.info_economica.valore) {
						value += giocatore.info_economica.valore;
					}
				}
			}
			return value;
		},
		valore_titolari: function() {
			var value = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				if(array_gio.length > 0) {
					var giocatore = array_gio[0];
					if(giocatore.info_economica && giocatore.info_economica.valore) {
						value += giocatore.info_economica.valore;
					}
				}
			}
			return value;
		},
		valore_riserve: function() {
			var value = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					if(i > 0) {
						var giocatore = array_gio[i];
						if(giocatore.info_economica && giocatore.info_economica.valore) {
							value += giocatore.valore;
						}
					}
				}
			}
			return value;
		},
		stipendio_rosa: function() {
			var value = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore.contratto && giocatore.contratto.stipendio_lordo) {
						value += parseFloat(giocatore.contratto.stipendio_lordo);
					}
				}
			}
			return value;
		},
		stipendio_titolari: function() {
			var value = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				if(array_gio.length > 0) {
					var giocatore = array_gio[0];
					if(giocatore.contratto && giocatore.contratto.stipendio_lordo) {
						value += parseFloat(giocatore.contratto.stipendio_lordo);
					}
				}
			}
			return value;
		},
		stipendio_riserve: function() {
			var value = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					if(i > 0) {
						var giocatore = array_gio[i];
						if(giocatore.contratto && giocatore.contratto.stipendio_lordo) {
							value += parseFloat(giocatore.contratto.stipendio_lordo);
						}
					}
				}
			}
			return value;
		},
		no_valore_rosa: function() {
			var label = '';
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore.valore == 0) {
						label += giocatore.cognome + "<br>";
					}
				}
			}
			return label;
		},
		no_valore_titolari: function() {
			var label = '';
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				if(array_gio.length > 0) {
					var giocatore = array_gio[0];
					if(giocatore.valore == 0) {
						label += giocatore.cognome + "<br>";
					}
				}
			}
			return label;
		},
		no_valore_riserve: function() {
			var label = '';
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					if(i > 0) {
						var giocatore = array_gio[i];
						if(giocatore.valore == 0) {
							label += giocatore.cognome + "<br>";
						}
					}
				}
			}
			return label;
		},
		no_stipendio_rosa: function() {
			var label = '';
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(!giocatore.contratto || (giocatore.contratto && !giocatore.contratto.stipendio_lordo)) {
						label += giocatore.cognome + "<br>";
					}
				}
			}
			return label;
		},
		no_stipendio_titolari: function() {
			var label = '';
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				if(array_gio.length > 0) {
					var giocatore = array_gio[0];
					if(!giocatore.contratto || (giocatore.contratto && !giocatore.contratto.stipendio_lordo)) {
						label += giocatore.cognome + "<br>";
					}
				}
			}
			return label;
		},
		no_stipendio_riserve: function() {
			var label = '';
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					if(i > 0) {
						var giocatore = array_gio[i];
						if(!giocatore.contratto || (giocatore.contratto && !giocatore.contratto.stipendio_lordo)) {
							label += giocatore.cognome + "<br>";
						}
					}
				}
			}
			return label;
		},
		n_giocatori: function() {
			var count = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				count += array_gio.length; 
			}
			return count;
		},
		n_confermati: function() {
			var count = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore.last_carriera_sportiva.squadra && giocatore.last_carriera_sportiva.squadra.societa.length > 0) {
						count++;
					}
				}
			}
			return count;
		},
		n_acquisti: function() {
			var count = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(!giocatore.giocatore.cresciuto_giovanili && (!giocatore.last_carriera_sportiva.squadra || (giocatore.last_carriera_sportiva.squadra && giocatore.last_carriera_sportiva.squadra.societa.length == 0))) {
						count++;
					}
				}
			}
			return count;
		},
		n_cresciuti_giovanili: function() {
			var count = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore.giocatore && giocatore.giocatore.cresciuto_giovanili) {
						count++;
					}
				}
			}
			return count;
		},
		n_cresciuti_vivaio_italiano: function() {
			var count = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore.giocatore && giocatore.giocatore.cresciuto_vivaio_italiano) {
						count++;
					}
				}
			}
			return count;
		},
		eta_media: function() {
			var value = 0;
			var count = 0;
			for (var key in this.formazione) {
				var array_gio = this.formazione[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					var eta = this.age(giocatore.nascita_data);
					if(eta > 0) {
						count++;
						value += eta;
					}
				}
			}
			if(count > 0) {
				value = Math.round(value/count);
			}
			return value;
		},
	},
	watch: {
		id_modulo: function () {
			for(var i in this.modules) {
				var modulo = this.modules[i];
				if(modulo.id == this.id_modulo) {
					var name = modulo.sistema_gioco.replace(/ /g, "").replace(/-/g, "");
					this.modulo = name;
					break;
				}
			}
		},
	},
	filters: {
		anni: function (date) {
			var birthday = moment(date);
			if(birthday.isValid()) {
				return moment().diff(birthday, 'years');
			}
			return "";
		},
	}

}
</script>
