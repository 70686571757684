<template id="logs">
  <section class="logs">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>Logs Utenti</span>
        </div>

        <div class="content">
          <admin-table
          api-url="/admin/logs"
          :columns="columns"
          :canDelete="true"
          :viewDetails="true"
          :defaultFilter="false"
          @showModal="onShowModal">
          <template slot="filters">
            <b-row>
              <b-col cols="4">
                <multiselect 
                v-model="filters.utenti"
                :multiple="true"
                :options="utenti"
                placeholder="Filtra per utenti"
                label="nomeCompleto"
                track-by="id" 
                group-values="items"
                group-label="label" 
                :group-select="true"/>
              </b-col>
              <b-col>
                <b-form-select v-model="filters.log"> 
                  <option value="">-- Logs --</option>
                  <option v-for="item in logs" :value="item.log_name">{{item.log_name}}</option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-input type="date" v-model="filters.data"></b-form-input>    
              </b-col>
              <b-col>
                <b-form-select v-model="filters.type"> 
                  <option value="">-- Tipo Soggetto --</option>
                  <option v-for="item in types" :value="item.subject_type">{{item.subject_type}}</option>
                </b-form-select>   
              </b-col>
              <b-col>
                <b-form-input type="text" v-model="filters.subject_id" placeholder="ID Soggetto"></b-form-input>
              </b-col>
              <b-col>
                <b-form-input type="text" v-model="filters.description" placeholder="Descrizione"></b-form-input>   
              </b-col>
            </b-row>
          </template>
          <template slot="view-modal-body" slot-scope="row">
            <div class="color-rosso">Campi modificati</div>
            <ul v-if="infoData.properties">
              <li v-for="(item, key, index) in infoData.properties.attributes">{{ key }} = {{ item }}</li>
            </ul>
            <div class="color-rosso">Valori precedenti</div>
            <ul v-if="infoData.properties">
              <li v-for="(item, key, index) in infoData.properties.old">{{ key }} = {{ item }}</li>
            </ul>
          </template>
        </admin-table>
      </div>
    </div>
  </div>
  <footerapp></footerapp>
</section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default
  },

  data() {
    return {
      columns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'log_name', label: 'Nome', sortable: true },
        { key: 'causer_id', label: 'ID Utente' },
        { key: 'causer_type', label: 'Tipo Utente' },
        { key: 'causer', label: 'Utente', formatter: value => value ? value.nomeCompleto : '', sortable: true },
        { key: 'description', label: 'Descrizione' },
        { key: 'subject_id', label: 'ID Soggetto' },
        { key: 'subject_type', label: 'Tipo Soggetto' },
        { key: 'created_at', label: 'Data', formatter: this.dateFormat },
        { key: 'actions', label: 'Azioni' },
        ],
      infoData: {
        properties: null,
      },
      utenti: [],
      logs: [],
      types: [],
      filters: {
        utenti: [],
        log: '',
        data: '',
        type: '',
        subject_id: '',
        description: '',
      },
    };
  },
  methods: {
    onShowModal(row) {
      this.infoData.properties = row.item.properties;
    },
    dateFormat (value) {
      var m = moment.utc(value).local();
      if(m.isValid()) {
        return m.format('DD/MM/YYYY HH:mm'); 
      }
      return "";
    },
  },
  created: function() {
    axios.get('/admin/filtri/logs').then((res) => {
      this.utenti = [{
        label: 'Tutti gli utenti',
        items: res.data.utenti.map(item => ({
          nomeCompleto: item.nomeCompleto,
          id: item.id,
        }))
      }];
      this.logs = res.data.logs;
      this.types = res.data.types;
    });
  },
  watch: {
    filters: {
      handler() {
        this.$root.$emit('admin-table::filter', this.filters);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051 !important;
}
</style>
