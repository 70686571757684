<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 758 753.3" style="enable-background:new 0 0 758 753.3;" xml:space="preserve">
    <g id="sfondo">
      <polygon id="XMLID_588_" class="st0" points="74,623 684,623 583.5,95 174.5,95 	"/>
      <rect id="XMLID_14_" x="74" y="623" class="st1" width="610" height="20.8"/>
      <rect id="XMLID_12_" x="74" y="643.8" class="st2" width="610" height="32.2"/>
    </g>
    <g id="elementi">
      <g id="XMLID_36_">
        <path id="XMLID_63_" class="st3" d="M268.7,517.5c11.4,13,30.8,12.3,45.6,18.6c16.6,7.1,35.3,32.9,45.2,35.9
          c11,2,20.3-5.3,25.4-15.5c2.8-6.8,6.7-25.4,3.9-32.9c-6-19-18.6-23.7-36.8-30.1c-23.2-6.7-46.3-2-66.6,4
          C274.6,501.7,260,505.1,268.7,517.5z"/>
        <path id="XMLID_62_" class="st4" d="M415,158.4c0.5-1.2,4.9-10.7,3.5-19.5c-2.3-15-22.5-19.9-34.7-13.5c-7.1,3.7-9.7,8.6-9.8,18.2
          c-0.3,7.7,1,13.2,1,13.2l5.9,8.7l11.6,0.3l9.4-7.7L415,158.4z"/>
        <path id="XMLID_61_" class="st5" d="M377.3,162.7c0,0,0.8,4.9,0.3,7.9c-0.6,2.9-2.3,4.5-3.4,9c-1.1,4.5-3.6,10,2.2,14.4
          c5.8,4.5,23.9,8.5,25.2,8.2c1.3-0.3,11.9-12.4,11.9-13.2c0-0.8-3.6-8.3-3.6-8.3l-2.1-10.3c0,0-3.4-11.9-4.7-12.2
          c-1.3-0.3-18.4,1.6-18.4,1.6L377.3,162.7z"/>
        <radialGradient id="XMLID_2_" cx="405.9593" cy="155.6204" r="52.6291" gradientUnits="userSpaceOnUse">
          <stop  offset="0.3801" style="stop-color:#000000;stop-opacity:0"/>
          <stop  offset="0.7745" style="stop-color:#000000;stop-opacity:0.4"/>
        </radialGradient>
        <path id="XMLID_60_" class="st6" d="M377.3,162.7c0,0,0.8,4.9,0.3,7.9c-0.6,2.9-2.3,4.5-3.4,9c-1.1,4.5-3.6,10,2.2,14.4
          c5.8,4.5,23.9,8.5,25.2,8.2c1.3-0.3,11.9-12.4,11.9-13.2c0-0.8-3.6-8.3-3.6-8.3l-2.1-10.3c0,0-3.4-11.9-4.7-12.2
          c-1.3-0.3-18.4,1.6-18.4,1.6L377.3,162.7z"/>
        <path id="XMLID_59_" class="st5" d="M456.2,195.3c0,0,10.9,4.7,16.5,13.4c5.8,8.7,8.2,16.3,11.5,20.9c3,4.3,9.7,3.5,12.5,7.3
          c2.7,3.7,0.7,12.4-1.4,15.8c-5,4.4-9.9,5.2-9.9,5.2s-5.6-1.5-7.2-5.2c-1.7-3.7-1.7-9.1-3.3-12.6c-3.1-6.6-11.4-10.1-17.8-16
          c-2.3-2.6-8.3-13.6-6.6-15.8L456.2,195.3z"/>
        <linearGradient id="XMLID_3_" gradientUnits="userSpaceOnUse" x1="453.89" y1="244.7038" x2="473.2094" y2="226.4785">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_58_" class="st7" d="M456.2,195.3c0,0,10.9,4.7,16.5,13.4c5.8,8.7,8.2,16.3,11.5,20.9c3,4.3,9.7,3.5,12.5,7.3
          c2.7,3.7,0.7,12.4-1.4,15.8c-5,4.4-9.9,5.2-9.9,5.2s-5.6-1.5-7.2-5.2c-1.7-3.7-1.7-9.1-3.3-12.6c-3.1-6.6-11.4-10.1-17.8-16
          c-2.3-2.6-8.3-13.6-6.6-15.8L456.2,195.3z"/>
        <path id="XMLID_57_" class="st8" d="M452.4,188.7c-10.4-1.9-15.8-1.1-24.4-2.7c-8.5-1.5-17.9-5.2-17.9-5.2s2.2,3.2-0.7,8.1
          c-3,5-5.8,10.1-18.2,7.8c-8.3-2.5-13.4-6.4-15.1-11.4c-1.2-5.3-0.8-9.1-0.8-9.1s-8.7-1.7-14.9,2.1c-6.2,3.9-16.3,4.7-17.8,17.8
          c-1.6,13.2-4.6,21-3,28.8c1.6,7.8-2.5,29.5-2.5,29.5s0.1,0.4,0.3,1.1l-0.5,2.4c-0.6,2.5-1.1,4.5-1.2,5.1c-0.1,1.4-2.5,3.6-2.8,7.3
          c-0.3,3.5-1.5,8.1-2,9c-0.5,1-2.3,13.5-2.3,13.5s-0.1,10.9,1.6,16.1c0.3,7.1,6.4,21.2,27.3,18.9c20.4,3.4,34.5-3.2,34.5-3.2
          l12.5-2.1c0,0,1.5-3.9,4-15.1c5.1-10.8,8.3-27.2,8.8-30.2c1.2-10.9,3.9-21.6,7.3-31c2-5.3,2.5-11.5,2.9-18.1c0,0,5.5-7.5,15.9-8.4
          c12.9-1.7,17.7-2.8,18.6-14.7C463.1,194.9,461.4,189,452.4,188.7z"/>
        <linearGradient id="XMLID_4_" gradientUnits="userSpaceOnUse" x1="304.2066" y1="261.4114" x2="390.3528" y2="249.636">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_56_" class="st9" d="M452.4,188.7c-10.4-1.9-15.8-1.1-24.4-2.7c-8.5-1.5-17.9-5.2-17.9-5.2s2.2,3.2-0.7,8.1
          c-3,5-5.8,10.1-18.2,7.8c-8.3-2.5-13.4-6.4-15.1-11.4c-1.2-5.3-0.8-9.1-0.8-9.1s-8.7-1.7-14.9,2.1c-6.2,3.9-16.3,4.7-17.8,17.8
          c-1.6,13.2-4.6,21-3,28.8c1.6,7.8-2.5,29.5-2.5,29.5s0.1,0.4,0.3,1.1l-0.5,2.4c-0.6,2.5-1.1,4.5-1.2,5.1c-0.1,1.4-2.5,3.6-2.8,7.3
          c-0.3,3.5-1.5,8.1-2,9c-0.5,1-2.3,13.5-2.3,13.5s-0.1,10.9,1.6,16.1c0.3,7.1,6.4,21.2,27.3,18.9c20.4,3.4,34.5-3.2,34.5-3.2
          l12.5-2.1c0,0,1.5-3.9,4-15.1c5.1-10.8,8.3-27.2,8.8-30.2c1.2-10.9,3.9-21.6,7.3-31c2-5.3,2.5-11.5,2.9-18.1c0,0,5.5-7.5,15.9-8.4
          c12.9-1.7,17.7-2.8,18.6-14.7C463.1,194.9,461.4,189,452.4,188.7z"/>
        <path id="XMLID_55_" class="st4" d="M301.2,469.3c0.5,1,5.7,7.9,2.3,15.9c-3.7,6.5-7.4,9.6-8.4,13.4c-2.5,9.3-12.7,15.3-22.1,14.5
          c-9-0.7-3.4-10.5-1.2-15.5c6.1-13.8,10.8-25.5,10.8-25.5l9.4-3.3L301.2,469.3z"/>
        <path id="XMLID_54_" class="st10" d="M321.7,401.2c0,0,3.5,0.8,2.3,5.7c-0.3,4.8,1,10.9-3.9,23.8c-5.4,8.6-15.3,19.5-17.4,26.7
          c-2.3,6.2-2.3,16.3-2.3,16.3s-2.9,6.2-10.1,5.1c-7.8-0.8-7.8-3.5-5.4-12.8c2.3-9.3,2.3-16.7,2.7-25.2c0.4-8.5,2.7-21.3,5.4-28.7
          c2.7-7.4,7.4-15.9,8.5-15.9C302.7,396.2,321.7,401.2,321.7,401.2z"/>
        <linearGradient id="XMLID_5_" gradientUnits="userSpaceOnUse" x1="320.4225" y1="449.7214" x2="298.6176" y2="436.2586">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_53_" class="st11" d="M321.7,401.2c0,0,3.5,0.8,2.3,5.7c-0.3,4.8,1,10.9-3.9,23.8c-5.4,8.6-15.3,19.5-17.4,26.7
          c-2.3,6.2-2.3,16.3-2.3,16.3s-2.9,6.2-10.1,5.1c-7.8-0.8-7.8-3.5-5.4-12.8c2.3-9.3,2.3-16.7,2.7-25.2c0.4-8.5,2.7-21.3,5.4-28.7
          c2.7-7.4,7.4-15.9,8.5-15.9C302.7,396.2,321.7,401.2,321.7,401.2z"/>
        <path id="XMLID_52_" class="st5" d="M344.6,365.9c0,0-5.9,10.9-8.1,16.5c-2.8,5.4-8.6,15.4-11.4,18.9c-2.4,3.7-1.5,10.9-11.2,14.3
          c-11.3,1.8-15.2,0.1-15.2-8.1c-0.8-8.5,2.6-12.9,3.3-17c0.8-4.1-0.4-17.4,1.7-27.5c1.7-10.2,19.9-13,19.9-13L344.6,365.9z"/>
        <linearGradient id="XMLID_6_" gradientUnits="userSpaceOnUse" x1="351.0047" y1="404.0319" x2="314.4393" y2="383.58">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_51_" class="st12" d="M344.6,365.9c0,0-5.9,10.9-8.1,16.5c-2.8,5.4-8.6,15.4-11.4,18.9
          c-2.4,3.7-1.5,10.9-11.2,14.3c-11.3,1.8-15.2,0.1-15.2-8.1c-0.8-8.5,2.6-12.9,3.3-17c0.8-4.1-0.4-17.4,1.7-27.5
          c1.7-10.2,19.9-13,19.9-13L344.6,365.9z"/>
        <path id="XMLID_50_" class="st4" d="M351.7,472.9c6,2.3,11.7-4.5,15.5-16.4c1.6-12.4,1.1-31.5,1.1-31.5c-3.8-6.7-5.5-14-15-10.1
          c-3.3,1.4-4.6,5.2-4.6,8.5c0,7.8,1.6,10.3-0.5,16c-2.1,5.7-4.1,9.8-3.8,18.1C345.7,465.6,347.9,471.3,351.7,472.9z"/>
        <path id="XMLID_49_" class="st10" d="M367.7,426.4c1.7-3.1,3.6-6,6.1-9.3c4.7-4.8,13.6-11.4,17.7-22.8c4.1-11.4,3.1-16.5,3.1-16.5
          l-12.9-4.1c0,0-14-6.2-17.6,6.2c-3.6,12.4-1.7,23.4-3.3,28.1c-1.5,5-8,7.2-7.3,12.9C354.3,428.7,363.5,431.1,367.7,426.4z"/>
        <linearGradient id="XMLID_7_" gradientUnits="userSpaceOnUse" x1="353.4501" y1="400.4819" x2="394.7671" y2="400.4819">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_48_" class="st13" d="M367.7,426.4c1.7-3.1,3.6-6,6.1-9.3c4.7-4.8,13.6-11.4,17.7-22.8c4.1-11.4,3.1-16.5,3.1-16.5
          l-12.9-4.1c0,0-14-6.2-17.6,6.2c-3.6,12.4-1.7,23.4-3.3,28.1c-1.5,5-8,7.2-7.3,12.9C354.3,428.7,363.5,431.1,367.7,426.4z"/>
        <path id="XMLID_47_" class="st5" d="M385.8,390.4c3.6-3.4,8.5-9.3,10.5-15.1c2.1-5.7,7.2-13.4,8.3-16.5c1-3.1-2.1-14-2.1-14
          l-19.1-5.7l-13.4,2.1l-6.2,8.3c0,0,3.6,11.9,3.6,17.6c0,5.7-1.6,7.8-0.5,12.9c1,5.2,3.1,9.3,7.8,11.4
          C379.8,393.5,385.8,390.4,385.8,390.4z"/>
        <linearGradient id="XMLID_8_" gradientUnits="userSpaceOnUse" x1="357.8358" y1="369.8337" x2="377.4686" y2="366.1194">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_46_" class="st14" d="M385.8,390.4c3.6-3.4,8.5-9.3,10.5-15.1c2.1-5.7,7.2-13.4,8.3-16.5c1-3.1-2.1-14-2.1-14
          l-19.1-5.7l-13.4,2.1l-6.2,8.3c0,0,3.6,11.9,3.6,17.6c0,5.7-1.6,7.8-0.5,12.9c1,5.2,3.1,9.3,7.8,11.4
          C379.8,393.5,385.8,390.4,385.8,390.4z"/>
        <path id="XMLID_45_" class="st8" d="M409.2,328.4c0.4,7.3,0,14.7-1.5,22l-1.1,11c0,0-9.6-6.8-20-7c-10.1,0.3-17.1,2.7-19,5.7
          c-2.2,3.6-2.2,3.6-2.2,3.6l-4.1-8.7l-4.1-4.4l-3,4.8c-0.8,6.1-1.7,12.1-4.9,17.4c-5.5,2.6-8,1.3-13.2,5c-5.7,2-11.2,3.1-20,2.6
          c-11-1.4-16.9-8-16.9-8s2.5-14.8,5.9-24.8c3.1-9.2,6.7-18.9,11.8-28.1c3.9-7.1,8.2-14.7,13-20c7.6,10,13.2,16.4,25.2,18.1
          c21.5-1.2,34.3-7.2,42-7.5c6.4,0.9,10.7,0.3,10.7,0.3C407.9,316.5,408.5,322.2,409.2,328.4z"/>
        <radialGradient id="XMLID_9_" cx="384.7527" cy="335.3498" r="49.6634" gradientUnits="userSpaceOnUse">
          <stop  offset="0.1537" style="stop-color:#000000;stop-opacity:0"/>
          <stop  offset="0.7179" style="stop-color:#000000;stop-opacity:0.3636"/>
          <stop  offset="0.7745" style="stop-color:#000000;stop-opacity:0.4"/>
        </radialGradient>
        <path id="XMLID_44_" class="st15" d="M409.2,328.4c-0.7-6.2-1.3-12-1.5-17.9c0,0-4.3,0.6-10.7-0.3c-6.4,0.2-16.9,4.4-38,7.6
          c-0.3,0.8-0.5,1.7-0.5,2.6c-3.4,8.2-2.3,17.5-1.8,26.4c0.1,1.4-0.1,2.2,0.6,4.1l3.7,3.9l4.4,9c0,0,0,0,2.2-3.6
          c1.9-3,8.9-5.4,19-5.7c10.4,0.2,20,7,20,7l1.1-11C409.2,343.1,409.6,335.8,409.2,328.4z"/>
        <g id="XMLID_42_">
          <radialGradient id="XMLID_10_" cx="318.5736" cy="335.6596" r="67.4369" gradientUnits="userSpaceOnUse">
            <stop  offset="0.1537" style="stop-color:#000000;stop-opacity:0"/>
            <stop  offset="0.7179" style="stop-color:#000000;stop-opacity:0.3636"/>
            <stop  offset="0.7745" style="stop-color:#000000;stop-opacity:0.4"/>
          </radialGradient>
          <path id="XMLID_43_" class="st16" d="M357.1,350.6c-0.6-1.8-0.4-2.5-0.5-3.9c-0.4-8.8-1.6-18.2,1.9-26.4c0-0.9,0.2-1.8,0.5-2.6
            c1.8-0.3,3.5-0.5,5.2-0.8c0-0.1,0-0.1,0-0.2c-2.9,0.4-5.9,0.7-9.1,0.9c-12-1.7-17.6-8.1-25.2-18.1c-4.8,5.3-9.1,12.9-13,20
            c-5.1,9.2-8.7,18.9-11.8,28.1c-3.4,10-5.9,24.8-5.9,24.8s5.9,6.6,16.9,8c8.9,0.5,14.3-0.6,20-2.6c5.2-3.7,7.7-2.3,13.2-5
            c3.2-5.3,4.7-10.9,4.9-17.4l1.4-2L357.1,350.6C357.1,350.7,357.1,350.6,357.1,350.6z"/>
        </g>
        <path id="XMLID_41_" class="st5" d="M340.8,245.8c0,0-1.9,13.6,0.5,20.4c2,6.7-0.9,8.7,1.7,10.4c2.7,1.7,15.2,3.6,24.3,1.7
          c9.2-2.2,36.9-5.7,45-5.8c8.3,0,13.1,2.5,13.1,2.5l9.6-0.6l7-1l5.6-8l1.4-12.1l-12.3-2.9l-18.3,6.4l-26.8-0.4c0,0-17.8-3.4-24-2.9
          c-4.5,0.7-0.7-8.7-0.7-8.7L340.8,245.8z"/>

          <linearGradient id="XMLID_11_" gradientUnits="userSpaceOnUse" x1="408.9776" y1="377.6524" x2="404.5035" y2="354.6691" gradientTransform="matrix(0.9807 0.1957 -0.1957 0.9807 67.1841 -161.6945)">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_40_" class="st17" d="M340.8,245.8c0,0-1.9,13.6,0.5,20.4c2,6.7-0.9,8.7,1.7,10.4c2.7,1.7,15.2,3.6,24.3,1.7
          c9.2-2.2,36.9-5.7,45-5.8c8.3,0,13.1,2.5,13.1,2.5l9.6-0.6l7-1l5.6-8l1.4-12.1l-12.3-2.9l-18.3,6.4l-26.8-0.4c0,0-17.8-3.4-24-2.9
          c-4.5,0.7-0.7-8.7-0.7-8.7L340.8,245.8z"/>
        <path id="XMLID_39_" class="st8" d="M369.1,212.7c1,6.1,0.3,9.5,0.3,14.4c0,8.9-0.1,19.5-0.1,19.5c0,1.7-5.7,2.9-6.7,3.3
          c-7.6,2.9-19,3.7-29.6,3.2c0,0,0.8-5.2,1.4-11.4c0.1-1.2,0.2-2.5,0.4-3.8c-0.7-9.8,2.3-18.8,2.4-29.2c0.2-1.2,0.4-2.3,0.6-3.5
          c1.1-5.8,3-11.6,6.6-17.1c0.7-1,1.5-1.8,2.4-2.6c4.2-3.7,9.9-4.8,13.9-7.2c0,0,1.1,5.9,2.9,14.2c0.8,4.3,3,6.5,4.4,13.9
          C368.2,208.1,368.7,210.6,369.1,212.7z"/>
        <linearGradient id="XMLID_13_" gradientUnits="userSpaceOnUse" x1="308.7764" y1="223.8347" x2="355.2583" y2="215.4679">
          <stop  offset="0" style="stop-color:#000000;stop-opacity:0.4"/>
          <stop  offset="1" style="stop-color:#000000;stop-opacity:0"/>
        </linearGradient>
        <path id="XMLID_38_" class="st18" d="M369.1,212.7c1,6.1,0.3,9.5,0.3,14.4c0,8.9-0.1,19.5-0.1,19.5c0,1.7-5.7,2.9-6.7,3.3
          c-7.6,2.9-19,3.7-29.6,3.2c0,0,0.8-5.2,1.4-11.4c0.1-1.2,0.2-2.5,0.4-3.8c-0.7-9.8,2.3-18.8,2.4-29.2c0.2-1.2,0.4-2.3,0.6-3.5
          c1.1-5.8,3-11.6,6.6-17.1c0.7-1,1.5-1.8,2.4-2.6c4.2-3.7,9.9-4.8,13.9-7.2c0,0,1.1,5.9,2.9,14.2c0.8,4.3,3,6.5,4.4,13.9
          C368.2,208.1,368.7,210.6,369.1,212.7z"/>
        <path id="XMLID_37_" class="st5" d="M416.2,149.8C416.2,149.8,416.3,149.8,416.2,149.8c1.3,1.1-0.8,10.5-0.7,12.7
          c1,5.2-2.2,12.3-5.4,17c-5.8,7.6-8.3,10.1-13.3,9.7c-7-2.1-13.5-6.4-14.6-9.9c-1.7-3.3-3.4-12.5-3.4-12.5s-0.9,2-2.8-1.2
          c-1.7-3.3-2.9-8.9-2.2-11.8c0.7-2.9,4.4-2.1,4.9,1c-0.2,2.5,1.4,12.8,4-0.7c1.1-9.6,3-5,7.3-3.4c3.4,1.5,8.9,2.1,16.6,1.8
          C408.7,152.4,414.7,149,416.2,149.8z"/>
      </g>
    </g>
    <g id="testa" @mouseover="hoverSection = 'testa'" @mouseout="hoverSection = null" @click="clicked(1)">
      <path class="st19" d="M377.8,167.3c0,1.1,0,2.3-0.1,3.3c-0.4,2-1.4,3.4-2.3,5.6c0,0.5-0.3,4.1,0.8,9.1c1.7,5,6.8,8.9,15.1,11.4
        c12.4,2.2,15.1-2.8,18.2-7.8c1.5-2.5,1.7-4.6,1.4-6c-0.5-1.1-1-2-1-2l-0.2-0.9c0.1-0.1,0.2-0.3,0.3-0.4c3.2-4.6,6.4-11.8,5.4-17
        c0-1.2,0.6-4.6,1-7.6c1.3-3.8,3-10,2.1-15.9c-2.3-15-22.5-19.9-34.7-13.5c-7.1,3.7-9.7,8.6-9.8,18.2c-0.2,3.5,0,6.5,0.3,8.7
        c-0.3,0.3-0.6,0.7-0.7,1.3c-0.7,2.9,0.5,8.5,2.2,11.8C376.7,166.9,377.3,167.3,377.8,167.3z"/>
    </g>
    <g id="check_testa" v-if="hoverSection === 'testa' || selectedSection === 1">
      <path d="M411,133.2c-0.5-0.5-1.3-0.5-1.9,0l-16.3,16.3l-7.2-7.2c-0.5-0.5-1.3-0.5-1.9,0c-0.5,0.5-0.5,1.3,0,1.9l8.2,8.2
        c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4c0,0,0,0,0-0.1c0,0,0,0,0.1,0l17.1-17.1C411.6,134.6,411.6,133.7,411,133.2z"/>
    </g>
    <g id="piede_dx" @mouseover="hoverSection = 'piede_dx'" @mouseout="hoverSection = null" @click="clicked(2)">
      <g class="st20">
        <path class="st21" d="M358.5,320.4c0-0.9,0.2-1.8,0.5-2.6c0.3,0,0.5-0.1,0.8-0.1c0-0.1,0-0.2,0-0.3c-1.6,0.1-3.2,0.3-4.8,0.4
          c-12-1.7-17.6-8.1-25.2-18.1c-4.8,5.3-9.1,12.9-13,20c-5.1,9.2-8.7,18.9-11.8,28.1c-3.4,10-5.9,24.8-5.9,24.8s1.1,1.3,3.4,2.8
          c-0.2,6.7,0.1,12.7-0.4,15.3c-0.4,2.4-1.7,4.8-2.6,8.1c-1.9,3.1-4.6,8.5-6.4,13.5c-2.7,7.4-5,20.2-5.4,28.7
          c-0.4,8.5-0.4,15.9-2.7,25.2c-0.6,2.3-1,4.2-1.2,5.8l-1,0.4c0,0-4.6,11.7-10.8,25.5c-2.2,5-7.8,14.7,1.2,15.5
          c9.3,0.8,19.6-5.3,22.1-14.5c1-3.9,4.7-6.9,8.4-13.4c3.4-8-1.8-14.9-2.3-15.9l-0.6,0c0.2-3.5,0.8-8.2,2.1-11.8
          c2.1-7.2,12-18,17.4-26.7c4.9-12.9,3.7-19,3.9-23.8c0.4-1.5,0.3-2.6,0-3.4c0.3-0.8,0.6-1.6,1-2.2c2.8-3.5,8.6-13.5,11.4-18.9
          c0.7-1.8,1.8-4.2,3-6.6c3.1-1.3,5.6-1.1,9.7-3c3.2-5.3,4.1-11.4,4.9-17.4l3-4.7c-0.6-1.8-0.5-2.6-0.5-4
          C356.2,337.9,355,328.6,358.5,320.4z"/>
      </g>
    </g>
    <g id="check_piede_dx" v-if="hoverSection === 'piede_dx' || selectedSection === 2">
      <path d="M336.6,349.1c-0.5-0.5-1.3-0.5-1.9,0l-16.3,16.3l-7.2-7.2c-0.5-0.5-1.3-0.5-1.9,0c-0.5,0.5-0.5,1.3,0,1.9l8.2,8.2
        c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4c0,0,0,0,0-0.1c0,0,0,0,0.1,0l17.1-17.1C337.1,350.4,337.1,349.6,336.6,349.1z"/>
    </g>
    <g id="piede_sx" @mouseover="hoverSection = 'piede_sx'" @mouseout="hoverSection = null" @click="clicked(3)">
      <path class="st19" d="M409.2,328.4c-0.7-6.2-1.3-12-1.5-17.9c0,0-4.3,0.6-10.7-0.3c-6.4,0.2-16.9,4.4-38,7.6
        c-0.3,0.8-0.5,1.7-0.5,2.6c-3.4,8.2-2.3,17.5-1.8,26.4c0.1,1.4-0.1,2.2,0.6,4.1l3.7,3.9l4.4,9c0,0,0,0,1.3-2.1
        c0.4,2,0.6,3.8,0.6,5.3c0,3.8-0.7,6-0.8,8.5c-0.9,1.1-1.7,2.6-2.3,4.4c-3.6,12.4-1.7,23.4-3.3,28.1c-0.7,2.3-2.4,4-4,5.8
        c-1,0.1-2.2,0.5-3.5,1c-3.3,1.4-4.6,5.2-4.6,8.5c0,7.8,1.6,10.3-0.5,16c-2.1,5.7-4.1,9.8-3.8,18.1c1.3,8.2,3.6,13.8,7.3,15.4
        c6,2.3,11.7-4.5,15.5-16.4c1.5-11.4,1.2-28.4,1.1-31.2c1.5-2.7,3.3-5.2,5.5-8.1c4.7-4.8,13.6-11.4,17.7-22.8
        c3.2-8.8,3.3-13.9,3.2-15.7c0.6-1.1,1.1-2.3,1.5-3.4c1.9-5.1,6.2-11.8,7.8-15.4c1.6,0.9,2.5,1.6,2.5,1.6l1.1-11
        C409.2,343.1,409.6,335.8,409.2,328.4z"/>
    </g>
    <g id="check_piede_sx" v-if="hoverSection === 'piede_sx' || selectedSection === 3">
      <path d="M399.6,330.1c-0.5-0.5-1.3-0.5-1.9,0l-16.3,16.3l-7.2-7.2c-0.5-0.5-1.3-0.5-1.9,0c-0.5,0.5-0.5,1.3,0,1.9l8.2,8.2
        c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4c0,0,0,0,0-0.1c0,0,0,0,0.1,0l17.1-17.1C400.1,331.4,400.1,330.6,399.6,330.1z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BodySection',

  data() {
    return {
      selectedSection: null,
      hoverSection: null,
    };
  },

  methods: {
    clicked(section) {
      this.selectedSection = section;
      this.$emit('clicked', section);
    },

    reset() {
      this.selectedSection = null;
      this.hoverSection = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.st0{fill:#6AB75F;}
.st1{fill:#41903F;}
.st2{fill:#D1D1D1;}
.st3{opacity:0.15;}
.st4{fill:#3C3C3B;}
.st5{fill:#ECB895;}
.st6{fill:url(#XMLID_2_);}
.st7{fill:url(#XMLID_3_);}
.st8{fill:#9B1E33;}
.st9{fill:url(#XMLID_4_);}
.st10{fill:#EE9624;}
.st11{fill:url(#XMLID_5_);}
.st12{fill:url(#XMLID_6_);}
.st13{fill:url(#XMLID_7_);}
.st14{fill:url(#XMLID_8_);}
.st15{fill:url(#XMLID_9_);}
.st16{fill:url(#XMLID_10_);}
.st17{fill:url(#XMLID_11_);}
.st18{fill:url(#XMLID_13_);}
.st19{opacity:0.6;fill:#FFFFFF;}
.st20{opacity:0.6;}
.st21{fill:#FFFFFF;}

#testa, #check_testa, #piede_dx, #check_piede_dx, #piede_sx, #check_piede_sx {
  cursor: pointer;
}
</style>
