<template>
	<div>
		<b-card no-body :class="'p-2 mb-1' + (hasValBreve(player.persona_id) ? ' border-dark' : '')" v-for="(player, index) in theFormazione" :bg-variant="index % 2 == 0 ? 'light' : 'default'">
			<b-row>
				<b-col :cols="4">
					<b-row>
						<b-col cols="1">
							<strong>{{ player.shirtNumber }}</strong>
						</b-col>
						<b-col cols="1">
							<em>{{ player.role.code3 }}</em>
						</b-col>
						<b-col>
							<router-link :to="'/next/'+area+'/view/giocatore/' + player.persona_id" target="_blank" v-if="player.persona_id">
								{{ player.shortName }}
							</router-link>
							<div v-else> {{ player.shortName }}</div>
						</b-col>
						<b-col cols="6">
							<div class="row no-gutters text-center">
								<div class="col mr-1" title="A"><b-badge variant="bg-1" v-if="player.persona_id && isValutato(player.persona_id, 1)" style="border:1px solid #006600;">&nbsp;</b-badge><b-badge v-else variant="bg-1" style="background-color:rgba(0,179,134,0.1)!important;border:1px solid #006600;">&nbsp;</b-badge></div>
								<div class="col mr-1" title="B+"><b-badge variant="bg-2" v-if="player.persona_id && isValutato(player.persona_id, 2)" style="border:1px solid #006600;">&nbsp;</b-badge><b-badge v-else variant="bg-2" style="background-color:rgba(123,222,60,0.1)!important;border:1px solid #00BB00;">&nbsp;</b-badge></div>
								<div class="col mr-1" title="B"><b-badge variant="bg-3" v-if="player.persona_id && isValutato(player.persona_id, 3)" style="border:1px solid #FF9900;">&nbsp;</b-badge><b-badge v-else variant="bg-3" style="background-color:rgba(255,243,0,0.1)!important;border:1px solid #FF9900;">&nbsp;</b-badge></div>
								<div class="col mr-1" title="C"><b-badge variant="bg-5" v-if="player.persona_id && isValutato(player.persona_id, 5)" style="border:1px solid #990000;">&nbsp;</b-badge><b-badge v-else variant="bg-5" style="background-color:rgba(255,0,89,0.1)!important;border:1px solid #990000;">&nbsp;</b-badge></div>
							</div>
						</b-col>
					</b-row>
				</b-col>
				<b-col :cols="2" v-if="$route.meta.currentAndPotential">

					<div v-if="hasVal(player.persona_id) && player.persona_id">
						
						<div class="text-center font-size-sm" v-if="hasVal(player.persona_id)" style="font-size:0.75rem;">
							<b-row align-v="center">
								<b-col>CURRENT</b-col>
								<b-col><h5><b-badge :style="{background: value_bg(hasVal(player.persona_id).current), color: '#000000'}">{{hasVal(player.persona_id).current}}</b-badge></h5></b-col>
							</b-row>
						</div>
						<div class="text-center font-size-sm" v-if="hasVal(player.persona_id)" style="font-size:0.75rem;">
							<b-row align-v="center">
								<b-col>POTENTIAL</b-col>
								<b-col><h5><b-badge :style="{background: value_bg(hasVal(player.persona_id).potential), color: '#000000'}">{{hasVal(player.persona_id).potential}}</b-badge></h5></b-col>
							</b-row>
						</div>

					</div>

				</b-col>
				<b-col :cols="$route.meta.currentAndPotential ? 6 : 8" style="font-size:0.75rem;text-align: justify;text-transform: uppercase;">
					<div v-if="player.persona_id && hasValBreve(player.persona_id) && Object.keys(hasValBreve(player.persona_id).lingua.note_1).length > 0 && !hasValBreve(player.persona_id).breve.tecnica">
						<textarealingua campo="note_1" entita="OsservatiValutazioni" :entitaId="hasValBreve(player.persona_id).id" :model="hasValBreve(player.persona_id).lingua.note_1" :linguaModel="lingua" :lingua_selected="lingua.selected" :lingua_master="lingua.master" :readonly="true"></textarealingua>
					</div>
					<div v-else-if="hasVal(player.persona_id) && Object.keys(hasVal(player.persona_id).lingua.note_1).length > 0"> 
						<textarealingua campo="note_1" entita="OsservatiValutazioni" :entitaId="hasValBreve(player.persona_id).id" :model="hasVal(player.persona_id).lingua.note_1" :linguaModel="lingua" :lingua_selected="lingua.selected" :lingua_master="lingua.master" :readonly="true"></textarealingua>
					</div>
					<div v-else-if="hasValBreve(player.persona_id)" v-html="hasValBreve(player.persona_id).breve.testo[lingua.selected]"></div>
					<div v-else>-- NON VALUTATO --</div>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

import linguaBase from '@/mixins/linguaBase.js'

export default {
	mixins: [ linguaBase ],
	props: [ 'formazione', 'valutazioni', 'gradimenti', 'lingua' ],
	methods: {
		hasValBreve: function(persona_id) {
			return this.valutazioni.find(item => item.persona_id == persona_id && item.breve);
		},
		hasVal: function(persona_id) {
			return this.valutazioni.find(item => item.persona_id == persona_id);
		},
		hasValNote: function(persona_id) {
			return this.valutazioni.find(item => item.persona_id == persona_id && item.note.length > 0);
		},
		isValutato: function(persona_id, grad) {
			return this.valutazioni.find(item => item.persona_id == persona_id && item.id_gradimento == grad);
		},
		value_bg: function(value) {
			if(this.gradimenti.length > 0) {
				if(value >= 8.5 && value <= 10) {
					return this.gradimenti[0].colore;
				} else if(value >= 6.5 && value <= 8.49) {
					return this.gradimenti[1].colore;
				} else if(value >= 4.5 && value <= 6.49) {
					return this.gradimenti[2].colore;
				} else if(value >= 2.5 && value <= 4.49) {
					return this.gradimenti[3].colore;
				} else if(value >= 0 && value <= 2.49) {
					return this.gradimenti[4].colore;
				} else {
					return '#FFFFFF';
				}
			}
			return '#FFFFFF';
		}
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		theFormazione: function() {
			return this.formazione.sort(function(a, b) { 
				var roles_ws = ['GKP', 'DEF', 'MID', 'FWD', 'N/D'];
				var role_a = a.role ? a.role.code3 : 'N/D';
				var role_b = b.role ? b.role.code3 : 'N/D';
				return roles_ws.indexOf(role_a) - roles_ws.indexOf(role_b);
			});
		}
	}

}

</script>