<template id="esercizi">
	<section class="esercizi">

		<headapp></headapp>

		<div>
			<menusub></menusub>

			<div class="row no-gutters">
				<div class="d-none d-md-flex col-md-3 border-right">
					<div style="width:100%;height:100vh;overflow:auto;">
						<card title="Filtri" class="w-100 border-0 rounded-0" comp="palestraesercizifiltri"></card>
					</div>
				</div>
				<div class="col-md-9">
					<b-card no-body class="rounded-0 border-0">
						<b-tabs card class="p-0" v-model="exercises_tab">
							<b-tab title="I miei esercizi" class="p-0">
								<card :title="'Esercizi (' + (exercises.total ? exercises.total : 0) + ')'" class="border-0 rounded-0" comp="palestraesercizi"></card>
							</b-tab>
							<b-tab title="Rosa" class="p-0">
								<card :title="'Rosa (' + (exercises_archivio.total ? exercises_archivio.total : 0) + ')'" class="border-0 rounded-0" comp="palestraeserciziarchivio"></card>
							</b-tab>
							<b-tab title="Archivio" class="p-0">
								<card :title="'Archivio (' + (exercises_condivisi.total ? exercises_condivisi.total : 0) + ')'" class="border-0 rounded-0" comp="palestraesercizicondivisi"></card>
							</b-tab>
						</b-tabs>
					</b-card>

				</div>
			</div>

		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
import { mapState } from 'vuex';
export default {

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		card:               require('../../../layout/card.vue').default,
	},

	computed:{
		...mapState(['rosaTeamId']),
		...mapState('palestra', ['exercises', 'exercises_archivio', 'exercises_condivisi']),
		exercises_tab: {
			get() {
				return this.$store.state.palestra.exercises_tab;
			},

			set(value) {
				this.$store.commit('palestra/setExcerciseTab', value);
			},
		},
	},

	created() {
		this.$store.commit('palestra/setTitle', '');
		this.$store.commit('palestra/setFilters', { facility: 0, goal: 0, tt: 0, intensity: 0});
	},

	watch: {
		rosaTeamId() {
			this.$store.commit('palestra/setPage', 1);
			if(this.exercises_tab == 0) {
				this.$store.dispatch('palestra/filter');
			} else if(this.exercises_tab == 1) {
				this.$store.dispatch('palestra/filter_archivio');
			} else if(this.exercises_tab == 2) {
				this.$store.dispatch('palestra/filter_condivisi');
			} 
		},  
		exercises_tab() {
			this.$store.commit('palestra/setPage', 1);
			if(this.exercises_tab == 0) {
				this.$store.dispatch('palestra/filter');
			} else if(this.exercises_tab == 1) {
				this.$store.dispatch('palestra/filter_archivio');
			} else if(this.exercises_tab == 2) {
				this.$store.dispatch('palestra/filter_condivisi');
			} 
		}
	}, 
}
</script>
<style scoped>
.card-header {
	padding-bottom: 0.5rem;
}
.tab-content {
	padding: 0 !important;
	border: 0;
}
</style>
