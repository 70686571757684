<template>
	<div>

		<b-button size="lg" @click.prevent="goTo">ClickMe</b-button>

	</div>
</template>

<script type="text/javascript">
	
/*

 */

export default {

	components: {
	},
	mounted: function() {

		

	},
	methods: {
		goTo: function() {
			window.top.postMessage({
				goTo: 'football_dashboard'
			}, '*')
		}
	}
}

</script>