<template>
	<div>
		<b-card no-body class="mb-1" :bg-variant="index % 2 == 0 ? 'light' : 'default'" :border-variant="errors.length ? 'danger' : ''">
			<b-card-body>
				<b-row align-v="center" :key="'row_' + player.wyId">
					<b-col cols="2" class="text-center">
						<b-row>
							<b-col class="text-center">
								<router-link :to="'/next/'+area+'/view/giocatore/' + player.persona_id" target="_blank" v-if="player.persona_id">
									<b-img :src="player.imageDataURL ? player.imageDataURL : '/assets/varie/profile.jpg'" :alt="player.shortName" :height="80"/>
									<div>{{ player.shortName }}</div>
									<strong>{{ player.shirtNumber }}</strong> <em>{{ player.role.code3 }}</em>
								</router-link>
								<div v-else>
									<b-img :src="player.imageDataURL ? player.imageDataURL : '/assets/varie/profile.jpg'" :alt="player.shortName" :height="80"/>
									<div>{{ player.shortName }}</div>
									<strong>{{ player.shirtNumber }}</strong> <em>{{ player.role.code3 }}</em>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="text-center">
								<b-form-radio-group buttons v-model="form[player.wyId].id_gradimento" stacked size="sm">
									<b-form-radio v-if="grad.newscout" v-for="grad in gradimenti" :value="grad.id" :button-variant="'outline-' + grad.id" :title="grad.title">{{grad.new_name ? grad.new_name : grad.nome}}</b-form-radio>
								</b-form-radio-group>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="4" class="text-center">
						<div class="posizione">
							<div class="campo">
								<img src="/assets/images/campo.svg">
							</div>
							<div v-for="r in filtered_ruoli" :class="'player ' + r.sigla.toLowerCase()" v-on:click="selezionaRuolo(player.wyId, r.id)" :title="r.nome">
								<span class="ruolo" v-if="form[player.wyId].id_posizione && form[player.wyId].id_posizione == r.id"><div class="tondo_ide"></div></span>
								<span class="ruolo" v-else-if="form[player.wyId].id_posizione2 && form[player.wyId].id_posizione2 == r.id"><div class="tondo_on"></div></span>
								<span v-else class="ruolo"><div class="tondo"></div></span>
							</div>
						</div>
						<b-row v-if="this.$route.meta.currentAndPotential">
							<b-col>
								<b-form-group label="Current" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
									<b-form-input type="number" min="0" max="10" v-model="form[player.wyId].current" step="0.01" size="sm"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="Potential" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
									<b-form-input type="number" min="0" max="10" v-model="form[player.wyId].potential" step="0.01" size="sm"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-form-checkbox v-if="form[player.wyId].conferma" v-model="form[player.wyId].confermato">
							Conferma valutazione
						</b-form-checkbox>
					</b-col>
					<b-col cols="3">
						<b-form-group label="Altezza" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-input type="number" v-model="form[player.wyId].altezza" size="sm"></b-form-input>
						</b-form-group>
						<b-form-group label="Struttura" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].struttura" :options="opt_struttura" size="sm"></b-form-select>
						</b-form-group>
						<b-form-group label="Muscoltura" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].muscolatura" :options="opt_muscolatura" size="sm"></b-form-select>
						</b-form-group>
						<b-form-group label="Velocità" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].velocita" :options="opt_sing" size="sm"></b-form-select>
						</b-form-group>
						<b-form-group label="Resistenza" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].resistenza" :options="opt_sing" size="sm"></b-form-select>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group label="Piede" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].piede" :options="opt_piede" size="sm"></b-form-select>
						</b-form-group>
						<b-form-group label="Tecnica" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].tecnica" :options="opt_sing" size="sm"></b-form-select>
						</b-form-group>
						<b-form-group label="Tattica" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].tattica" :options="opt_sing" size="sm"></b-form-select>
						</b-form-group>
						<b-form-group label="Offensive" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].capacita_offensive" :options="opt_plur" size="sm"></b-form-select>
						</b-form-group>
						<b-form-group label="Difensive" label-text-align="right" label-size="sm" :label-cols="5" horizontal>
							<b-form-select v-model="form[player.wyId].capacita_difensive" :options="opt_plur" size="sm"></b-form-select>
						</b-form-group>
					</b-col>
					<b-col cols="12" class="mt-2">
						<textarealingua label="" :model="form[player.wyId].lingua.note_1" :linguaModel="lingua" :lingua_selected="lingua.selected" :lingua_master="lingua.master"></textarealingua>
					</b-col>
				</b-row>
			</b-card-body>
			<template #footer v-if="errors">
				<p v-for="a in errors" class="text-danger m-0">{{ a }}</p>
			</template>
		</b-card>

	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import linguaBase from '@/mixins/linguaBase.js'

export default {
	mixins: [ pageBase, linguaBase ],
	props: [ 'player', 'index', 'form', 'gradimenti', 'ruoli', 'lingua' ],
	data: function() {
		return {
			opt_struttura: [
				{ value: null, text: '-- Select --' },
				{ value: '0', text: 'Brevilinea' },
				{ value: '1', text: 'Normolinea' },
				{ value: '2', text: 'Longilinea' }
			],
			opt_muscolatura: [
				{ value: null, text: '-- Select --' },
				{ value: '0', text: 'Esile' },
				{ value: '1', text: 'Normale' },
				{ value: '2', text: 'Possente' }
			],
			opt_piede: [
				{ value: null, text: '-- Select --' },
				{ value: '0', text: 'Destro' },
				{ value: '1', text: 'Sinistro' },
				{ value: '2', text: 'Ambidestro' }
			],
			opt_sing: [
				{ value: null, text: '-- Select --' },
				{ value: '4', text: 'Insufficiente' },
				{ value: '0', text: 'Sufficiente' },
				{ value: '1', text: 'Discreta' },
				{ value: '2', text: 'Buona' },
				{ value: '3', text: 'Ottima' }
			],
			opt_plur: [
				{ value: null, text: '-- Select --' },
				{ value: '4', text: 'Insufficienti' },
				{ value: '0', text: 'Sufficienti' },
				{ value: '1', text: 'Discrete' },
				{ value: '2', text: 'Buone' },
				{ value: '3', text: 'Ottime' }
			],
			ruoli_sigle: ['PO', 'DC', 'DCS', 'DCD', 'TS', 'TD', 'CC', 'CID', 'CIS', 'ES', 'ED', 'TQC', 'AC', 'AS', 'AD', 'PC'],
		}
	},
	watch: {
	},
	methods: {
		selezionaRuolo: function(wyId, id) {
			if(this.form[wyId].id_posizione == null) {
				this.form[wyId].id_posizione = id;
			} else if(this.form[wyId].id_posizione == id) {
				this.form[wyId].id_posizione = null;
				if(this.form[wyId].id_posizione2) {
					this.form[wyId].id_posizione = this.form[wyId].id_posizione2;
					this.form[wyId].id_posizione2 = null;
				}
			} else if(this.form[wyId].id_posizione2 == id) {
				this.form[wyId].id_posizione2 = null;
			} else {
				this.form[wyId].id_posizione2 = id;
			}
		},
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		errors: function() {

			var item = _.cloneDeep(this.form[this.player.wyId]);
				var errors = [];
				if(item.id_gradimento == null && item.struttura == null && item.muscolatura == null && item.velocita == null && item.resistenza == null && item.tecnica == null && item.tattica == null && item.capacita_offensive == null && item.capacita_difensive == null && (item.lingua.note_1[this.lingua.selected] == null || item.lingua.note_1[this.lingua.selected].trim() == ''))
					return false;
				else if((item.id_gradimento != null && item.struttura != null && item.muscolatura != null && item.velocita != null && item.resistenza != null && item.tecnica != null && item.tattica != null && item.capacita_offensive != null && item.capacita_difensive != null && item.altezza != null && item.id_posizione != null && item.piede != null) || (item.lingua.note_1[this.lingua.selected] != null && item.lingua.note_1[this.lingua.selected].trim() != '' && item.id_gradimento != null)) {

					if(item.conferma && !item.confermato)
						errors.push("Non hai confermato la valutazione importata.");

						if(this.$route.meta.currentAndPotential) {

							if(item.current < 0 || item.current > 10)
								errors.push("Il campo 'current' deve essere un valore compreso tra 0 e 10");

							if(item.potential < 0 || item.potential > 10)
								errors.push("Il campo 'potential' deve essere un valore compreso tra 0 e 10");
						}

				} else {

					if(item.lingua.note_1[this.lingua.selected] != null && item.lingua.note_1[this.lingua.selected].trim() != '' && item.id_gradimento == null) {
						errors.push('Inserisci il Gradimento');
					} else {

						var single = [];
						var toCheck = {
							id_gradimento: 'gradimento',
							id_posizione: 'posizione',
							altezza: 'altezza',
							struttura: 'struttura',
							muscolatura: 'muscolatura',
							velocita: 'velocità',
							resistenza: 'resistenza',
							piede: 'piede',
							tecnica: 'tecnica',
							tattica: 'tattica',
							capacita_offensive: 'capacità offensive',
							capacita_difensive: 'capacità difensive',
						};

						for(var b in toCheck) {
							if(item[b] == null)
								single.push(toCheck[b]);
						}

						errors.push("Per proseguire, inserisci le note e il gradimento o compila i seguenti campi: " + single.join(', '));

					}
				}

				if(errors.length > 0)
					return errors;

				return false;

			//});

			return errors;

		},
		filtered_ruoli() {
			return this.ruoli.filter(ruolo => this.ruoli_sigle.includes(ruolo.sigla));
		},
	}

}

</script>