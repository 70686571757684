<template id="acad_val">
	<section class="acad_val">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="academy">
			<div class="card-body p-3">
				<div class="row flex-items-xs-middle">
					<div class="col-5">
						<div class="media">
							<img v-bind:src="academy.logo_url" class="" height="80">
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{academy.nome}}</strong></h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<b-button variant="primary" class="text-uppercase mb-2" v-if="auth.isUserEnable('academy_attivita_mng')" @click="newAttivita"><strong>{{ $i18n.t('Nuova') }}</strong></b-button>
				
				<b-table striped show-empty
				:items="attivita"
				:fields="fields"
				>
				<template #cell(icon)="row"><i :class="!row.item.remota ? 'fa fa-car' : 'fa fa-video-camera'" aria-hidden="true" :title="!row.item.remota ? 'On Site' : 'Video Call'"></i></template>
				<template #cell(remota)="row">{{ row.value ? 'Video Call' : 'On Site'}}</template>
				<template #cell(dal)="row">{{ row.value | formatDate }}</template>
				<template #cell(al)="row">{{ row.value | formatDate }}</template>
				<template #cell(ora)="row">{{  row.value ? row.value.substr(0, 5) : '' }}</template>
				<template #cell(azioni)="row">
					<div>
					<b-button size="sm" v-if="auth.isUserEnable('academy_attivita_report') && !hasReport(row.item.id)" :to="{ path: `/academyint/societa/attivita/report/new/${$route.params.id}/${getAttivitaAcademyId(row.item.id)}` }" variant="success" class="mr-1">{{ $i18n.t('Compila report') }} <i class="fa fa-pencil" aria-hidden="true"></i></b-button>

					<b-button size="sm" v-if="auth.isUserEnable('academy_attivita_report') && hasReport(row.item.id)" :to="{ path: `/academyint/societa/attivita/report/edit/${$route.params.id}/${getAttivitaReportId(row.item.id)}` }" variant="info" class="mr-1">{{ $i18n.t('Modifica report') }} <i class="fa fa-pencil" aria-hidden="true"></i></b-button>
					<b-button size="sm" v-if="auth.isUserEnable('academy_attivita_report') && hasReport(row.item.id)" class="mr-1" variant="success" @click="downloadReport(getAttivitaReportId(row.item.id))">{{ $i18n.t('Stampa pdf') }} <i class="fa fa-eye fa-fw" aria-hidden="true"></i></b-button>
					<b-button v-if="auth.isUserEnable('academy_attivita_mng') && (auth.user.id == row.item.utente_id || auth.isAdmin())" size="sm" class="mr-1" variant="primary" @click="openAttivita(row.item.id)">
						Edit <i class="fa fa-pencil" aria-hidden="true"></i>
					</b-button>
					<b-button v-if="auth.isUserEnable('academy_attivita_mng') && (auth.user.id == row.item.utente_id || auth.isAdmin())" size="sm" @click.stop="eliminaAttivita(row.item.id)" class="mr-1">
						Canc <i class="fa fa-trash" aria-hidden="true"></i>
					</b-button>	
					</div>	
				</template>
			</b-table>

			<b-modal id="eventModal" ref="eventModal" :title="form.id ? $i18n.t('Attività') : $i18n.t('Nuova attività')" ok-only :ok-title="$i18n.t('Chiudi')" ok-variant="secondary" size="lg">
				<b-container fluid>
					<b-row class="mb-1">
						<b-col>
							<b-row class="mb-2">
								<b-col>
									<b-form-radio-group v-model="form.remota">
										<b-form-radio :value="0" :disabled="attivita_sel && attivita_sel.academies.length > 1">On Site</b-form-radio>
										<b-form-radio :value="1" :disabled="attivita_sel && attivita_sel.academies.length > 1">Video Call</b-form-radio>
									</b-form-radio-group>
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="form.remota == 0">
								<b-col>
									<b-form-group :label="$i18n.t('Dal')">
										<b-form-input v-model="form.dal" type="date"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group :label="$i18n.t('Al')">
										<b-form-input v-model="form.al" type="date"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="form.remota == 1">
								<b-col>
									<b-form-group :label="$i18n.t('Data')">
										<b-form-input v-model="form.dal" type="date"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group :label="$i18n.t('Ora')">
										<b-form-input v-model="form.ora" type="time"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col>
									<b-button variant="primary" @click="salvaAttivita">{{ $i18n.t('Salva') }}</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-container>
			</b-modal>
		</div>
	</div>


	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types';
import axios from 'axios';
import moment from 'moment';
export default {

	data: function() {
		return {
			academy: null,
			attivita: [],
			attivita_sel: null,
			form: {
				id: null,
				remota: 0,
				dal: null,
				al: null,
				ora: null,
				academy_id: this.$route.params.id,
			},
			fields: [
			{ key: 'icon', label: '', class: 'text-center' },
			{ key: 'remota', label: this.$i18n.t('Tipo'), class: 'text-center' },
			{ key: 'dal', label: this.$i18n.t('Dal'), class: 'text-center' },
			{ key: 'al', label: this.$i18n.t('Al'), class: 'text-center' },
			{ key: 'ora', label: this.$i18n.t('Ora'), class: 'text-center' },
			{ key: 'azioni', label: this.$i18n.t('Azioni'), class: 'text-center' },
			],

		}
	},

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
	},

	computed: {
		auth: function() {
			return Auth;
		},
	},

	created: function () {
		if(this.$route.params.id) {
			axios.get(`/academyita/societa/get/` + this.$route.params.id)
			.then((response) => {
				this.academy = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		}

		this.getAttivita();

	},

	methods: {
		getAttivita() {
			axios.post(`/academyint/attivita`, { academy_id: this.$route.params.id })
			.then((response) => {
				this.attivita = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		},

		newAttivita() {
			this.attivita_sel = null;
			this.form.id = null;
			this.form.remota = 0;
			this.form.dal = null;
			this.form.al = null;
			this.form.ora = null;
			this.$refs.eventModal.show();
		},

		openAttivita(id) {
			var attivita = this.attivita.find((item) => item.id == id);
			if(attivita) {
				this.attivita_sel = attivita;
				this.form.id = attivita.id;
				this.form.remota = attivita.remota;
				this.form.dal = attivita.dal;
				if(this.form.remota) {
					this.form.ora = attivita.ora;
					this.form.al = null;
				} else {
					this.form.al = attivita.al;
					this.form.ora = null;
				}
				this.$refs.eventModal.show();
			}
		},

		salvaAttivita() {
			if(!this.form.dal || this.form.dal == '') {
				alert(this.$i18n.t('Devi inserire una data per l\'attivita!'));
				return;
			}
			if(this.form.remota) {
				if(!this.form.ora || this.form.ora == '') {
					alert(this.$i18n.t('Devi inserire un orario per l\'attivita!'));
					return;
				}
			} else {
				if(!this.form.al || this.form.al == '') {
					alert(this.$i18n.t('Devi inserire un intervallo di date!'));
					return;
				}
			}
			if(this.form.id) {
				this.$http.post(this.$store.state.apiEndPoint + '/academyint/attivita/edit/' + this.form.id, this.form).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.getAttivita();	
						this.$refs.eventModal.hide();	
					}
				}, (response) => {
				});
			} else {
				this.$http.post(this.$store.state.apiEndPoint + '/academyint/attivita/add', this.form).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.getAttivita();
						this.$refs.eventModal.hide();		
					}
				}, (response) => {
				});
			}
		},

		eliminaAttivita(id) {
			if(confirm(this.$i18n.t('Confermi l\'eliminazione dell\'attività ?'))) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyint/delete/attivita/academy/' + id + '/' + this.$route.params.id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.getAttivita();		
					}
				}, (response) => {
				});
			}
		},

		hasReport(id) {
			var attivita = this.attivita.find((item) => item.id == id);
			if(attivita) {
				var attivitaAcademy = attivita.attivita_academies.find((item) => item.id_academy == this.$route.params.id);
				if(attivitaAcademy) {
					if(attivitaAcademy.report) {
						return true;

					}
				}
			}
			return false; 
		},

		getAttivitaAcademyId(id) {
			var attivita = this.attivita.find((item) => item.id == id);
			if(attivita) {
				var attivitaAcademy = attivita.attivita_academies.find((item) => item.id_academy == this.$route.params.id);
				if(attivitaAcademy) {
					return attivitaAcademy.id;
				}
			}
			return null; 
		},

		getAttivitaReportId(id) {
			var attivita = this.attivita.find((item) => item.id == id);
			if(attivita) {
				var attivitaAcademy = attivita.attivita_academies.find((item) => item.id_academy == this.$route.params.id);
				if(attivitaAcademy) {
					if(attivitaAcademy.report) {
						return attivitaAcademy.report.id;

					}
				}
			}
			return null; 
		},

		downloadReport(id) {
			window.open(this.$store.state.apiEndPoint + '/academyint/attivita/report/' + id + "/pdf/" + this.$i18n.t('lang'), "_blank");
		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
