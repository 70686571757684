<template id="rosa">
	<section class="rosa">

		<headapp></headapp>

		<div class="card">
			<div class="card-body">
				<div class="content">
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					<div v-if="!loading">
						<div class="card-title d-flex">
							<span class="mr-auto">Visite mediche ({{ giocatori.length }})</span>
							<div>
								<button class="btn btn-link" v-on:click="vista = 'griglia'" v-b-tooltip.hover title="Vista Griglia" placement="bottom"><i class="fa fa-th"></i></button>
								<button class="btn btn-link" v-on:click="vista = 'lista'" v-b-tooltip.hover title="Vista Elenco" placement="bottom"><i class="fa fa-bars"></i></button>
							</div>
						</div>
						<p>Giocatori o membri dello staff che si dovranno sottoporre a visita medica nei prossimi 30 giorni.</p>
						<div v-if="vista == 'griglia'">
							<div class="row">
								<div v-for="item in giocatori" :key="item.id" class="col-2">
									<div class="figurinagiocatore" :id="'giocatore_'+item.id">
										<div class="card mb-2">
											<div class="row">
												<div class="col-12 text-center">
													<img v-bind:src="item.avatar_url" height="200" @click="viewScheda(item)" style="cursor:pointer;">
												</div>
											</div>

											<div class="card-body">
												<div class="text-center">{{item.last_carriera_sportiva &&item.last_carriera_sportiva.persona_ruolo_glo_id == 1 ? 'Giocatore' : 'Staff' }}</div>
												<div class="font-bold text-center">{{ item.cognome }} {{ item.nome }}</div>

												<div class="text-center font-size-xs color-rosso">{{
													item.last_carriera_sportiva && item.last_carriera_sportiva.ruoli.length > 0 ? item.last_carriera_sportiva.ruoli[0].nome : '&nbsp;'}}</div>
													<div v-if="item.visita_intermedia" class="text-center font-size-sm color-rosso"> Visita medica intermedia: <strong>{{ getVisitaMedicaIntermedia(item.documenti[0].documento.scadenza) }}</strong></div>
													<div v-else class="text-center font-size-sm color-rosso"> Visita medica: <strong>{{ item.documenti[0].documento.scadenza | formatDate }}</strong></div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="vista == 'lista'">
								<table class="table table-striped">
									<thead>
										<th>Nominativo</th>
										<th>Ruolo</th>
										<th>Qualifica</th>
										<th>Telefoni</th>
										<th>Parenti</th>
										<th>Visita medica</th>
									</thead>
									<tbody>
										<tr v-for="item in giocatori">
											<td style="cursor:pointer;" @click="viewScheda(item)">{{ item.cognome }} {{ item.nome }}</td>
											<td>{{ item.last_carriera_sportiva && item.last_carriera_sportiva.ruoli.length > 0 ? item.last_carriera_sportiva.ruoli[0].nome : ''}}</td>
											<td>{{ item.last_carriera_sportiva && item.last_carriera_sportiva.persona_ruolo_glo_id == 1 ? 'Giocatore' : 'Staff' }}</td>
											<td><div v-for="contatto in item.contatti"><div v-if="contatto.contatto.tipo == 'telefono'">{{ contatto.contatto.valore }}</div></div></td>
											<td><div v-for="relazione in item.relazioni"> <div v-if="relazione.persona_relazioni_glo_id <= 3">{{ relazione.relazionante.cognome }} {{ relazione.relazionante.nome }} ({{ relazione.tipo.nome }}) - tel: <span v-for="cont in relazione.relazionante.contatti"><span v-if="cont.contatto.tipo == 'telefono'">{{ cont.contatto.valore }}</span></span></div></div></td>
												<td v-if="item.visita_intermedia" class="color-rosso"><strong>{{ getVisitaMedicaIntermedia(item.documenti[0].documento.scadenza) }}</strong> ({{ fra(getVisitaMedicaIntermedia(item.documenti[0].documento.scadenza), 'DD/MM/YYYY') }}) </td>
												<td v-else class="color-rosso"><strong>{{ item.documenti[0].documento.scadenza | formatDate}}</strong> ({{ fra(item.documenti[0].documento.scadenza) }}) </td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<footerapp></footerapp>

			</section>
		</template>

		<script>
		import axios from 'axios';
		import moment from 'moment';
		import { mapState } from 'vuex';
		export default {
			data: function(){
				return {
					vista: 'griglia',
					giocatori: [],
					loading: false,
					errors: [],
				}
			},
			computed: {
				...mapState(['rosaTeamId']),
			},
			watch: { 
				rosaTeamId() {
					this.getVisitaMedica();		
				},
			},
			components: {
				headapp:            require('../../layout/headapp.vue').default,
				footerapp:          require('../../layout/footerapp.vue').default,
			},
			created: function () {
				this.getVisitaMedica();
			},
			methods: {
				getVisitaMedica() {
					if(this.rosaTeamId) {
						this.loading = true;
						axios.get(`/rosa/visita/medica`, { params: { team_id: this.rosaTeamId }})
						.then((res) => {
							this.giocatori = res.data;
							this.giocatori.sort(function(a, b) {
								return new Date(a.documenti[0].documento.scadenza) - new Date(b.documenti[0].documento.scadenza);
							})
							this.loading = false;
						}).catch((err) => {
						});
					}
				},
				viewScheda(item) {
					if(item.last_carriera_sportiva && item.last_carriera_sportiva.persona_ruolo_glo_id == 1) {
						this.$router.push({ name: "football_giocatore_scheda", params: { id: item.id} });	
					} else {
						this.$router.push({ name: "football_staff_scheda", params: { id: item.id} });
					}
				},
				fra: function (date, format = null) {
					var diff = NaN;
					var m = null;
					if(format) {
						m = moment(date, format);
					} else {
						m = moment(date);
					}
					var today = moment().startOf('day');
					if(m.isValid()) {
						diff = m.diff(today, 'days');
					}
					if(diff == 0) {
						return "oggi";
					}
					if(diff == 1) {
						return "fra 1 giorno";
					} else {
						return "fra " + diff + " giorni";
					}
				},
				getVisitaMedicaIntermedia: function (date) {
					var m = moment(date);
					if(m.isValid()) {
						return m.subtract(6, 'M').format('DD/MM/YYYY'); 
					}
					return "N/D";
				},
			},
			filters: {
				formatDate: function (date) {
					var m = moment(date);
					if(m.isValid()) {
						return m.format('DD/MM/YYYY'); 
					}
					return "";
				},
			},
		}
		</script>
