<template>
	<div>
		<div v-if="compact">
			<b-form-select size="sm" value-field="id" text-field="name" v-model="estensione.work_id" :options="glo_works">
				<template #first>
					<b-form-select-option disabled :value="null">-- Work --</b-form-select-option>
				</template>
			</b-form-select>
		</div>
		<div v-else>
			<b-row class="mb-1">
				<b-col>
					<label>Work</label>
					<b-form-select value-field="id" text-field="name" v-model="estensione.work_id" :options="glo_works"></b-form-select>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
	sessionMixin
	],
	props: [ 'estensione', 'model', 'field', 'compact' ],
	data: function() {
		return {
			glo_works: []
		}
	},
	created: function() {

		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { work_id: null });
		}

		this.getWorks();

	},
	methods: {
		getWorks: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/work').then(function(data) {
				this.glo_works = data.data;
			});
		}
	}
};
</script>