<template id="elencogiocatori">
	<div class="elencogiocatori">

		<div class="card-title d-flex">
			<span class="mr-auto">Rosa ({{ players.length }})</span>
			<div>
				<button class="btn btn-link" v-on:click="vista = 'griglia'" v-b-tooltip.hover title="Vista Griglia" placement="bottom"><i class="fa fa-th"></i></button>
				<!--button class="btn btn-link" v-on:click="vista = 'lista'" v-b-tooltip.hover title="Vista Elenco" placement="bottom"><i class="fa fa-bars"></i></button-->
				<button class="btn btn-link" v-on:click="$router.push({name: 'football_rosa_elenco'})" v-b-tooltip.hover title="Vista Elenco" placement="bottom"><i class="fa fa-bars"></i></button>
			</div>
		</div>
		<b-form-group label="Filtra per:">
			<b-form-radio-group v-model="tipo_partita" :options="opt_partita">
			</b-form-radio-group>
		</b-form-group>
		<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
		<div v-if="vista == 'griglia'">
			<div class="row">
				<div v-for="giocatore in players" :key="giocatore.id" class="col-2">
					<figurinagiocatore :giocatore="giocatore" v-on:change-player="getPlayers()"></figurinagiocatore>
				</div>
			</div>
		</div>
		<div v-if="vista == 'lista'">
			<tabellagiocatori :rosa="players"></tabellagiocatori>
		</div>

	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {

	components: {
		figurinagiocatore: 	require('./figurinagiocatore.vue').default,
		tabellagiocatori: 	require('./tabellagiocatori.vue').default
	},

	data: function(){
		return {
			vista: 'griglia',
			players: [],
			loading: false,
			tipo_partita: 0,
			opt_partita: [
			{ text: 'Totale', value: 0 },
			{ text: 'Campionato', value: 'Campionato' },
			{ text: 'Coppa', value: 'Coppa' },
			{ text: 'Amichevole', value: 'Amichevole' },
			{ text: 'Torneo', value: 'Torneo' },
			{ text: 'Nazionale', value: 'Nazionale' }
			]
		}
	},
	computed: {
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.getPlayers();
		},
		tipo_partita() {
			this.getPlayers();
		},
		seasonId() {
			this.getPlayers();
		},  
	},
	methods: {
		
		getPlayers: function(){				
				/**
				*  RETRY players
				**/
				this.players = [];
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/rosa/get/players', {params: { team_id: this.rosaTeamId, tipo_partita: this.tipo_partita, season_id: this.seasonId  }}).then((response) => {
					// success callback
					if (response.ok)
					{
						if(response.data.players) {
							this.players = response.data.players.map(item => ({								
								name: item.nome + ' ' + item.cognome,
								id: item.id,
								ruolo: item.last_carriera_sportiva.ruolo_ideale.length > 0 ? item.last_carriera_sportiva.ruolo_ideale[0].nome : '',
								player: item,
								carriera: item.last_carriera_sportiva,
							}));
						}
						this.loading = false;
					}
				}, (response) => {
					// error callback
				});
			},
		},
		created: function () {
			this.getPlayers();
		},

	}
	</script>
