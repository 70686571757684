<template>
	<div>

		<label>Nome</label>
		<b-form-input v-model="fase.nome" type="text"></b-form-input>
		<b-row v-if="hasSetupTipo(this.fase, 'hideFormDuration', false) == false">
			<b-col>
				<label>Durata</label>
				<b-form-input :disabled="disabled" v-model="fase.durata" type="time"></b-form-input>
			</b-col>
			<b-col>
				<label>Durata effettiva</label>
				<b-form-input :disabled="disabled" v-model="fase.durata_reale" type="time"></b-form-input>
			</b-col>
		</b-row>
		<!--
		<label>Note della Fase</label>
		<b-textarea :disabled="disabled" v-model="fase.entity.nota" rows="4"></b-textarea>
		-->

	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';
import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [faseMixin, sessionMixin],
	props: [ 'sessione', 'glossario', 'fase', 'fisioterapia_glo_options', 'medical_regions', 'medical_regions_map', 'disabled' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	components: {
		treedbody: require('@/components/layout/3dbody.vue').default,
	},
}
</script>