<template id="gillnesses">
	<div class="gillnesses">
		<b-table striped show-empty
		ref="illnessesTable"
		:items="illnesses"
		:fields="fields"
		>
		<template #cell(data)="row">{{ row.value | formatDate }}</template>
		<template #cell(return)="row">{{ row.value | formatDate }}</template>
		<template #cell(reillness)="row">{{ row.value ? illness_label[row.value] : 'No' }}</template>
		<template #cell(types)="row">
			<div v-for="item in row.item.illness_types" v-if="item.id != 7"> {{item.name}} </div>
			<div v-for="item in row.item.illness_other_types"> {{item.name}} </div>
		</template>
		<template #cell(actions)="row"><span class="color-rosso" style="cursor:pointer"><i class="fa fa-info-circle" aria-hidden="true" @click="selectIllness(row.item.id)"></i></span></template>
	</b-table>
	<b-button v-if="auth.isUserEnable('medical')" size="sm" :to="{ name: 'medical_illnesses', params: { id: params.player_id }}" variant="outline-primary">Go to Illnesses</b-button>
	<b-modal id="injuryModal" ref="illnessModal" title="Illness" size="xl" class="fs-modal" ok-only ok-title="Chiudi">
		<div v-if="illness.id">
			<b-card>
				<b-row>
					<b-col>
						<b-form-group label="Date of Illness *">
							<b-form-input type="date" v-model="illness.data"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Date of return of full participation">
							<b-form-input type="date" v-model="illness.return"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group label="Type of illness">
							<b-form-checkbox-group
							v-model="illness.illness_types">
							<b-form-checkbox v-if="!item.other" v-for="item in illness_types" :value="item.id"> {{ item.name}} </b-form-checkbox>	
						</b-form-checkbox-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row v-if="illness.illness_types.indexOf(7) > -1">
				<b-col>
					<b-form-group>
						<b-form-checkbox-group
						v-model="illness.illness_other_types">
						<b-form-checkbox v-if="item.other" v-for="item in illness_types" :value="item.id"> {{ item.name}} </b-form-checkbox>	
					</b-form-checkbox-group>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="2">
				<b-form-group label="Second Illness" description="Is this a second illness?">
					<b-form-select v-model="illness.reillness">
						<option :value="0">No</option>
						<option :value="2">Subsequent new illness</option>
						<option :value="3">Subsequent local illness</option>
						<option :value="4">Exacerbation</option>
						<option :value="1">Reillness</option>
					</b-form-select>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group v-if="illness.reillness" label="Previous illnesses">
					<b-form-select v-model="illness.id_illness">
						<option :value="null">Select</option>
						<option v-if="!illness.id || (illness.id && illness.id != item.id)" v-for="item in illnesses" :value="item.id">Illness of {{item.data | formatDate }} - {{ getLabelIllness(item) }} - Data of return {{ item.return | formatDate }}</option>
					</b-form-select>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group v-if="illness.reillness" label="Date of return from previous illness">
					<b-form-input type="date" v-model="illness.return_reillness"></b-form-input>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-group label="Diagnosis">
					<b-form-textarea
					v-model="illness.diagnosis"
					rows="3"
					max-rows="6"
					></b-form-textarea>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-group label="Other comments">
					<b-form-textarea
					v-model="illness.comments"
					rows="3"
					max-rows="6"
					></b-form-textarea>
				</b-form-group>
			</b-col>
		</b-row>
	</b-card>
</div>
</b-modal>
</div>

</template>

<script>
var Auth = require('@/auth.js');
import moment from 'moment'
export default {
	props: {
		params: {
			type: Object,
			required: false
		}
	},
	data: function() {
		return {
			illnesses: [],
			illness_types: [],
			fields: [
			{ key: 'data', label: 'Date of Illness' },
			{ key: 'return', label: 'Date of return' },
			{ key: 'reillness', label: 'Second Illness' },
			{ key: 'types', label: 'Type of Illness' },
			{ key: 'actions', label: '' },

			],
			illness: {
				id: null,
				persona_id: this.$route.params.id,
				data: null,
				return: null,
				reillness: 0,
				id_illness: null,
				return_reillness: null,
				illness_types: [],
				illness_other_types: [],
				diagnosis: null,
				comments: null,
			},
			illness_label: [ 'No', 'Reillness', 'Subsequent new illness', 'Subsequent local illness', 'Exacerbation'],
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
	},
	methods: {
		getIllnesses() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/illnesses/player/' + this.$route.params.id).then((response) => {
				if (response.ok) {
					this.illnesses = response.data.illnesses;
				}
			}, (response) => {
			});
		},

		getIllnessTypes() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/illness/types').then((response) => {
				if (response.ok) {
					this.illness_types = response.data; 
				}
			}, (response) => {
			});
		},

		selectIllness(id) {
			var item = this.illnesses.find(item => item.id === id);
			this.illness.id = item.id;
			this.illness.data = item.data;
			this.illness.return = item.return;
			this.illness.reillness = item.reillness;
			this.illness.id_illness = item.id_illness;
			this.illness.return_reillness = item.return_reillness;
			this.illness.diagnosis = item.diagnosis;
			this.illness.comments = item.comments;

			for(var i in item.illness_types) {
				this.illness.illness_types.push(item.illness_types[i].id);
			}
			for(var i in item.illness_other_types) {
				this.illness.illness_other_types.push(item.illness_other_types[i].id);
			}
			
			this.$refs.illnessModal.show();
		},

		getLabelIllness(illness) {
			var label = '';
			if(illness.illness_types.length > 0) {
				if(illness.illness_types[0].id != 7) {
					label = illness.illness_types[0].name;
					if(illness.illness_types.length > 1) {
						label = label + '...';
					}
				} else if(illness.illness_other_types.length > 0) {
					label = illness.illness_other_types[0].name;
					if(illness.illness_other_types.length > 1) {
						label = label + '...';
					}
				}
			}
			return label;
		}
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
	created: function () {
		this.getIllnessTypes();
		this.getIllnesses();
	},
}
</script>
