<template id="allenatore">
	<section class="allenatore">

		<headapp></headapp>

		<menusub></menusub>

		<div class="card rounded-0 border-top-0 border-right-0 border-left-0">
			<div class="card-body">
				<div class="row flex-items-xs-middle">
					<div class="col-5">
						<div class="media">
							<img v-bind:src="allenatore.avatar_url" class="" height="100">
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{allenatore.nome}} {{allenatore.cognome}}</strong></h3>
								<div class="text-muted"><small><flag :country="allenatore.nascita_paese ? allenatore.nascita_paese.sigla : ''" :size="16"></flag> {{allenatore.nascita_paese ? allenatore.nascita_paese.paese : ''}} </small></div>
								<div class="text-muted"><small>{{ allenatore.nascita_data | formatDate }} </small></div>
							</div>
						</div>
					</div>
					<div class="col-7 text-right">
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col" v-for="cat in skills_tags">
				<tagscategorie class="h-100" :skills_tags="cat.fields" :title="cat.it" @selectSkillTags="selectSkillTags"></tagscategorie>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<b-button variant="primary" style="position:absolute;top:25px;right:25px;z-index:1" @click="selectGenericTags"><i class="fa fa-youtube-play"></i> Azione generica / Goal </b-button>
				<card class="h-100" title="Riassunto" comp="griassunto" :params="{'riassunti': riassunti}"></card>
			</div>
		</div>
	</div>

	<b-modal id="tagsModal" size="xl" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
		<b-row v-if="skill_tags_sel">
			<b-col cols="3" style="overflow-y:auto;height:550px;">
				<div v-for="(tag, index) in videotags">
					<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag" @deleteTag="deleteTag"></thumbtag>
				</div>
			</b-col>
			<b-col cols="9">
				<video
				id="videotags-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
			<b-pagination size="sm" align="center" :total-rows="videotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
			<div v-if="tag_sel">
				<p class="m-1">{{ count }} - {{ skillName }}</p>
				<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
				<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
				<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
				<p class="m-1">{{ tag_sel.nota }}</p>
			</div>
		</b-col>
	</b-row>
</b-modal>
<b-modal id="riassuntoModal" size="xl" ref="riassuntoModal" :title="titleRiassunto" class="fs-modal" @hidden="onHiddenRiassunto" @shown="onShowRiassunto" ok-only ok-title="Chiudi">
	<div>
		<video
		id="video-wrapper"
		class="video-js vjs-default-skin vjs-16-9"
		controls
		data-setup="{}">
	</video>
</div>
</b-modal>

<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import axios from 'axios';
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-errors';
import 'videojs-playlist';
export default {
	data: function() {
		return {
			allenatore: [],
			skills: [],
			loading: true,
			skills_tags: [],
			generic_tags: [],
			skill_tags_sel: null,
			videotags: [],
			tag_sel: null,
			count: 0,
			title: '',
			reload: false,
			riassunti: [],
			playlist: [],
			titleRiassunto: '',

			errors_player: {
				4: {
					headline: 'Video non disponibile',
					type: '',
					message: 'La clip non è stata ancora generata.<br/>Riprova più tardi.',
				}
			}
		}
	},

	methods: {
		selectSkillTags(skill_tags, title) {
			this.title = title;	
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.tagsModal.show();
		},
		selectGenericTags() {
			if(this.generic_tags.length > 0) {
				this.title = 'Azione generica';	
				this.videotags = [];
				this.skill_tags_sel = 'Azione generica';
				this.videotags = this.generic_tags;
				this.tag_sel = this.videotags[0];
				this.count = 1;
				this.playlist = [];
				for(var i in this.videotags) {
					var videotag = this.videotags[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
				this.$refs.tagsModal.show();
			}
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1); 
		},
		selectPageTag(page) {
			this.tag_sel = this.videotags[page - 1]; 
			this.$video_tags.playlist.currentItem(page - 1);   
		},
		autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		deleteTag(tag) {
			if(confirm('Sei sicuro di voler eliminare il tag?')) {
				this.$http.delete(this.$store.state.apiEndPoint + '/tag/' + tag.id).then((response) => {
					if (response.ok)
					{
						if(this.tag_sel.id == tag.id) {
							this.tag_sel = null;
						}
						var index = this.videotags.indexOf(tag);	
						this.videotags.splice(index, 1);
						this.reload = true; 
						if(this.videotags.length == 0) {
							this.$refs.tagsModal.hide();
						}
					}
				}, (response) => {
		            // error callback
		        });
			}		
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);

				this.$video_tags.errors({
				    "errors": this.errors_player,
				});
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.$video_tags.dispose();
			this.$video_tags = null;
			this.tag_sel = null;
			if(this.reload) {
				this.$http.get(this.$store.state.apiEndPoint + '/videotags/allenatore/' + this.allenatore.id).then((response) => {
					if (response.ok)
					{
						this.skills_tags = response.data.skills_tags;
						this.generic_tags = response.data.generic_tags;  
						this.reload = false; 
					}
				}, (response) => {
		            // error callback
		        });
			}
		},
		selectRiassunto(index) {
			var videotags = this.riassunti[index].videotags;
			this.playlist = [];
			this.titleRiassunto = 'Video riassunto di ' + this.riassunti[index].utente.nomeCompleto;
			for(var i in videotags) {
				var videotag = videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.riassuntoModal.show();
		},
		onShowRiassunto() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			this.$video.play();
		},
		onHiddenRiassunto() {
			this.$video.playlist([]);
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;	
		},
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},
	computed: {
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},
	},

	created: function () {
		if(this.$route.params.id){

			axios.get(`/allenatore/`+this.$route.params.id)
			.then((res) => {
				this.allenatore = res.data;
				
				this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
					if (response.ok) {
						this.skills = JSON.parse(response.data.standard);
					}
				}, (response) => {

				});

				this.$http.get(this.$store.state.apiEndPoint + '/videotags/allenatore/' + this.allenatore.id).then((response) => {
					if (response.ok)
					{
						this.skills_tags = response.data.skills_tags;
						this.generic_tags = response.data.generic_tags;
					}
				}, (response) => {
				});

				this.$http.get(this.$store.state.apiEndPoint + '/videotag/riassunto/allenatore/' + this.allenatore.id).then((response) => {
					if (response.ok)
					{
						this.riassunti = response.data.riassunti;
					}
				}, (response) => {
				});  

			}).catch((err) => {
				this.loading = false;
			});
		}

		this.$bus.$on('selectRiassunto', ($event) => {
			this.selectRiassunto($event);
		});
	},

	components: {
		headapp: 		require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
		flag: 			require('../../layout/flag.vue').default,
		card: 			require('../../layout/card.vue').default,
		tagscategorie: 		require('../../layout/tagscategorie.vue').default,
		thumbtag:   require('../../layout/thumbtag.vue').default,
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
}
</script>
