export const ROUTES_WHITEBOARD = [
    {
        path: '/whiteboard/dashboard',
        name: 'witheboard_dashboard',
        component: require('../components/pages/whiteboard/dashboard.vue').default,
        meta: {
            module: 'whiteboard',

            label: 'Whiteboard',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'whiteboard',
        }
    },
    {
        path: '/whiteboard/config',
        name: 'witheboard_config',
        component: require('../components/pages/whiteboard/config.vue').default,
        meta: {
            module: 'whiteboard',

            label: 'Config',
            requiresAuth: true,
            showInMenu: true,
            enableRosaTeam: true,
            parent: '',
            permesso: 'whiteboard_config',
            logCategory: true,
        }
    },
];
