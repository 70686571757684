<template>
	<div>

		<Player-Card :player="player"></Player-Card>

		<b-alert v-if="dates && isActive" variant="primary" class="text-center" show>
			Al <strong>{{ moment.unix(parseInt(dates.to)).format('DD/MM/YYYY') }}</strong> risulta un infortunio attivo
		</b-alert>
		<b-alert v-else-if="!isActive" variant="success" class="text-center" show>
			Al termine dell'intervallo non sono presenti infortuni attivi.	
		</b-alert>

		<div v-if="dates" class="text-center">
			Nell'intervallo visualizzato sono presenti <strong>{{ injuries.length }}</strong> infortuni. Per un totale di <strong>{{ totalOutDays.out }} giorni</strong> su <strong><em>{{ totalOutDays.interval }}</em></strong> 
			<span class="text-muted"> (pari al {{ totalOutDays.percent }} %)</span>.
		</div>

		<b-row class="mt-2">
			<b-col :cols="4">
				<div class="text-center"><strong>Aree del Corpo</strong></div>
				<E-Chart style="width: 100%; height: 300px" :option="datasetRegions"></E-Chart>
			</b-col>
			<!--
			<b-col :cols="4">
				<div class="text-center"><strong>Cause</strong></div>
				<E-Chart style="width: 100%; height: 300px" :option="datasetCauses"></E-Chart>
			</b-col>-->
			<b-col :cols="4">
				<div class="text-center"><strong>Classification</strong></div>
				<E-Chart style="width: 100%; height: 300px" :option="datasetClassifications"></E-Chart>
			</b-col>
			<b-col :cols="4">
				<div class="text-center"><strong>Severity</strong></div>
				<E-Chart style="width: 100%; height: 300px" :option="datasetSeverity"></E-Chart>
			</b-col>
		</b-row>

		<div v-for="injury in theInjuries" class="my-2">
			<Single-Smart-Injury :injury="injury"></Single-Smart-Injury>
		</div>

		<!--
		<pre>{{isActive}}</pre>
		<pre>{{totalOutDays}}</pre>
		<pre>{{injuries}}</pre>
		<pre>{{player}}</pre>-->
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import eChart from '@/components/layout/E-Chart/Base.vue'
import singleSmartInjury from '@/components/layout/Medical/SingleInjurySmart.vue'
import PlayerCard from '@/components/layout/giocatore/PlayerCard.vue'
import moment from 'moment'

export default {
	mixins: [ pageBase ],
	components: {
		'E-Chart': eChart,
		'Player-Card': PlayerCard,
		'Single-Smart-Injury': singleSmartInjury
	},
	props: [ 'dates', 'player', 'injuries' ],
	methods: {
		simpleDataset: function(key, title, values = null) {

			var regions = _.groupBy(this.injuries, key);
			var dataset = [];

			_.each(regions, function(b, k) {

				var label = k;
				if(values != null && values[k])
					label = values[k];

				dataset.push({ value: b.length, name: label });
			});

			return {
				name: title,
				type: 'pie',
				radius: '50%',
				data: dataset
			};

		}
	},
	computed: {
		theInjuries: function() {
			return _.orderBy(this.injuries, function(a) {
				return -1* moment(a.data).format('X');
			});
		},
		datasetRegions: function() {

			return { 
				series: [ 
					this.simpleDataset('injury_region', 'Body Regions') 
				]
			};

		},
		datasetCauses: function() {

			return { 
				series: [ 
					this.simpleDataset('cause', 'Body Regions') 
				]
			};

		},
		datasetClassifications: function() {

			return { 
				series: [ 
					this.simpleDataset('injury_classification', 'Body Regions') 
				]
			};

		},
		datasetSeverity: function() {

			return { 
				series: [ 
					this.simpleDataset('severity', 'Severity', ['', 'Minimal (1-3)', 'Mild (4-7)', 'Moderate (8-28)', 'Severe (>28)'])
				]
			};

		},
		totalOutDays: function() {

			var mom_from = moment.unix(this.dates.from);
			var mom_to = moment.unix(this.dates.to);
			var duration = mom_to.diff(mom_from, 'days')

			var out = 0;
			_.each(this.injuries, function(injury) {

				var loc_from = moment(injury.data);
				var loc_to = mom_to;

				if(injury.return)
					loc_to = moment(injury.return);

				var loc_duration = loc_to.diff(loc_from, 'days');
				out += parseFloat(loc_duration);


			});

			return {
				out: out,
				interval: duration,
				percent: _.round(100*(out/duration),2)
			}

		},
		isActive: function() {

			var notReturnYet = _.find(this.injuries, { return: null });
			if(notReturnYet) return notReturnYet;

			var maxReturn = _.maxBy(_.map(_.cloneDeep(this.injuries), function(b) { b.return = moment(b.return).format('X'); return b; }), 'return');
			if(maxReturn.return && parseInt(maxReturn.return) >= this.dates.to) return maxReturn;

			return false;

		}
	}
}

</script>