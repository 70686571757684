<template id="dashboard">
	<section class="allenamenti">

		<headapp></headapp>

		<div class="">
			<menusub></menusub>
			<div class="card border-0 rounded-0">
				<div class="card-body">
					<div class="card-title">
						<span>{{ $i18n.t('Attività') }}</span>
					</div>
					<div class="content">
						<full-calendar v-if="!user.academy_id" ref="calendar" :event-sources="eventSources" @event-render="eventRender" @event-selected="eventSelected" @day-click="dayClick" :config="config" @event-drop="eventDrop" @event-resize="eventResize"></full-calendar>
						<full-calendar v-else ref="calendar" :event-sources="eventSources" :config="config"></full-calendar>
						<b-row class="my-2">
							<b-col>
								<b-badge style="background:red;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">On Site</b-badge>
								<b-badge style="background:orange;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Video Call</b-badge>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>

		<b-modal id="eventModal" ref="eventModal" :title="event_id ? $i18n.t('Attività') : $i18n.t('Nuova attività')" centered @ok="handleOk" @cancel="handleCancel" :ok-title="$i18n.t('Salva')" size="lg">
			<b-container fluid>
				<form @submit.stop.prevent="handleSubmit">
					<b-row class="mb-1">
						<b-col>
							<b-row class="mb-2">
								<b-col>
									<b-form-radio-group v-model="remota">
										<b-form-radio :value="0">On Site</b-form-radio>
										<b-form-radio :value="1">Video Call</b-form-radio>
									</b-form-radio-group>
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="remota == 0">
								<b-col>
									<b-form-group :label="$i18n.t('Dal')">
										<b-form-input v-model="dal" type="date"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group :label="$i18n.t('Al')">
										<b-form-input v-model="al" type="date"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="remota == 0">
								<b-col>
									<b-form-group label="Academy">
										<multiselect v-model="academy" :options="academy_options" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" :placeholder="$i18n.t('seleziona')" label="nome" track-by="id"></multiselect>
									</b-form-group> 
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="remota == 1">
								<b-col>
									<b-form-group :label="$i18n.t('Data')">
										<b-form-input v-model="dal" type="date"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group :label="$i18n.t('Ora')">
										<b-form-input v-model="ora" type="time"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="remota == 1">
								<b-col>
									<b-form-group label="Academy">
										<multiselect v-model="academies" :options="academy_options" :multiple="true" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" :placeholder="$i18n.t('seleziona')" label="nome" track-by="id"></multiselect>
									</b-form-group> 
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="auth.isUserEnable('academy_attivita_report')">
								<b-col>
									<b-button size="sm" v-if="attivita_academy && !attivita_academy.report" :to="{ path: `/academyint/attivita/report/new/${attivita_academy.id}` }" variant="primary">{{ $i18n.t('Compila report') }} <i class="fa fa-pencil" aria-hidden="true"></i></b-button>
									<b-button size="sm" v-if="attivita_academy && attivita_academy.report" :to="{ path: `/academyint/attivita/report/edit/${attivita_academy.report.id}` }" variant="primary">{{ $i18n.t('Modifica report') }} <i class="fa fa-pencil" aria-hidden="true"></i></b-button> <b-button size="sm" class="ml-2" variant="success" v-if="attivita_academy && attivita_academy.report" @click="downloadReport(attivita_academy.report.id)">{{ $i18n.t('Stampa pdf') }} <i class="fa fa-eye fa-fw" aria-hidden="true"></i></b-button>
									<div v-for="item in attivita_academies" class="mb-2">
										<b-button size="sm" v-if="!item.report" :to="{ path: `/academyint/attivita/report/new/${item.id}` }" variant="primary">{{ $i18n.t('Compila report') }} {{ attivita_academies.length > 1 ? getNomeAcademy(item.id_academy) : ''}} <i class="fa fa-pencil" aria-hidden="true"></i></b-button>
										<b-button size="sm" v-if="item.report" :to="{ path: `/academyint/attivita/report/edit/${item.report.id}` }" variant="primary">{{ $i18n.t('Modifica report') }} {{ attivita_academies.length > 1 ? getNomeAcademy(item.id_academy) : ''}} <i class="fa fa-pencil" aria-hidden="true"></i></b-button>
										<b-button size="sm" class="ml-2" variant="success" v-if="item.report" @click="downloadReport(item.report.id)">{{ $i18n.t('Stampa pdf') }} <i class="fa fa-eye fa-fw" aria-hidden="true"></i></b-button>
									</div>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col>
									<b-button v-if="auth.isUserEnable('academy_attivita_mng') && (auth.isAdmin() || (selected && selected.utente_id == user.id))" v-show="event_id" @click="eliminaAttivita" variant="outline-primary">{{ $i18n.t('Elimina') }}</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</form>
			</b-container>
		</b-modal>

	</section>
</template>

<script>
import moment from 'moment'
import { FullCalendar } from 'vue-full-calendar'
// import '../../../lang/agenda.js'
import * as types from '../../../store/types'
import axios from 'axios';
var Auth = require('../../../auth.js');
export default {

	data: function(){
		return{	
			config:
			{
				disableResizing: false,
				editable: true,
				defaultView: 'month',
				locale: this.$i18n.t('lang'),
			},
			selected: null,
			event_id: null,
			remota: 0,
			dal: null,
			al: null,
			ora: null,
			academy: null,
			academies: [],
			attivita_academy: null,
			attivita_academies: [],
			academy_options: [],
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		eventSources: function() {
			const self = this;
			var events = [];
			events.push({
				events(start, end, timezone, callback) {
					var start = moment(start).format("YYYY-MM-DD HH:mm:ss")
					var end = moment(end).format("YYYY-MM-DD HH:mm:ss")					
					const formData = new FormData();
					if(self.user.academy_id) {
						formData.append("academy_id", self.user.academy_id);
					}
					formData.append("dal", start);
					formData.append("al", end);
					formData.set('_method', 'POST');

					const opts = {
						url: `/academyint/attivita`,
						method: 'POST',
						data: formData,
					};

					axios(opts)
					.then((res) => {
						var data = res.data.map(item => ({
							title: (item.remota ? 'Video Call' : 'On Site') + '\n' + (!self.user.academy_id ? item.title : ''),
							start: item.dal + (item.ora ? ' ' + item.ora : ''),
							end: item.al ? moment(item.al).add(1, 'days').format("YYYY-MM-DD") : null,
							dal: item.dal,
							al: item.al,
							ora: item.ora,
							id: item.id,
							remota: item.remota,
							academies: item.academies,
							attivita_academies: item.attivita_academies,
							utente_id: item.utente_id,
							color: item.remota == 0 ? 'red' : 'orange',
							textColor: '#FFF',
						}));
						callback(data);
					}).catch((err) => {
						console.log(err);
					});
				},
				color: '#F7CA18',
				textColor: '#A80532',
			});
			return events;
		}
	},

	created() {
		axios.get('/academyita/get/list/societa/1').then((res) => {
			this.academy_options = res.data;
		});
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		FullCalendar,
	},

	methods: {

		handleCancel (evt) {
			this.event_id = null;
			this.remota = 0;
		},
		handleOk (evt) {
			evt.preventDefault();
			if(this.auth.isUserEnable('academy_attivita_mng')) {
				this.handleSubmit();
			}
		},

		handleSubmit () {
			if(!this.event_id) {
				this.create();
			} else {
				if(this.auth.isAdmin() || (this.selected && this.selected.utente_id == this.user.id)) {
					var academy_ids = [];
					const formData = new FormData();
					formData.append("remota", this.remota);
					if(!this.dal || this.dal == '') {
						alert(this.$i18n.t('Devi inserire una data per l\'attivita!'));
						return;
					}
					formData.append("dal", this.dal);
					if(this.remota) {
						if(!this.ora || this.ora == '') {
							alert(this.$i18n.t('Devi inserire un orario per l\'attivita!'));
							return;
						}
						formData.append("ora", this.ora);
						if(this.academies.length == 0) {
							alert(this.$i18n.t('Devi selezionare una o più academy!'));
							return;
						}
						for(var i in this.academies) {
							academy_ids.push(this.academies[i].id);
						}
					} else {
						if(!this.al || this.al == '') {
							alert(this.$i18n.t('Devi inserire un intervallo di date!'));
							return;
						}
						formData.append("al", this.al);
						if(!this.academy) {
							alert(this.$i18n.t('Devi selezionare un\'academy!'));
							return;
						}
						academy_ids.push(this.academy.id);
					}
					formData.append("academy_ids", academy_ids);
					this.update(formData,  this.event_id);
				}
			}
			this.event_id = null;
			this.remota = 0;
		},

		removeEvent() {
			this.$refs.calendar.$emit('remove-event', this.selected);
			this.selected = {};
		},
		eventSelected(event) {
			console.log('eventSelected');
			this.academy = null;
			this.academies = [];
			this.attivita_academy = null;
			this.attivita_academies = [];
			this.event_id = event.id;
			this.remota = event.remota;
			if(this.remota) {
				this.dal = event.dal;
				this.al = null;
				this.ora = event.ora;
				for(var i in event.academies) {
					this.academies.push(event.academies[i]);
					this.attivita_academies.push(event.attivita_academies[i]);
				}
			} else {
				this.dal = event.dal;
				this.al = event.al;
				this.ora = null;
				this.academy = event.academies[0];
				this.attivita_academy = event.attivita_academies[0];
			}
			this.selected = event;
			this.$refs.eventModal.show();
		},
		eventRender(event) {
		},
		dayClick(date, jsEvent, view) {
			this.remota = 0;
			this.selected = null;
			this.dal = moment(date['_d']).format("YYYY-MM-DD");
			this.al = null;
			this.ora = null;
			this.academy = null;
			this.academies = [];
			this.$refs.eventModal.show();
		},

		eventDrop(event, delta, revertFunc) {
			if(this.auth.isAdmin() || (event && event['utente_id'] == this.user.id)) {
				const formData = new FormData();
				formData.append("drop",  true);
				if(event['remota']) {
					formData.append("dal",  moment(event['start']['_d']).format("YYYY-MM-DD"));
					formData.append("ora",  moment(event['start']['_d']).subtract({ hours: 1 }).format("HH:mm:ss"));
				} else {
					formData.append("dal",  moment(event['start']['_d']).format("YYYY-MM-DD"));
					formData.append("al",  moment(event['end']['_d']).subtract({ days: 1 }).format("YYYY-MM-DD"));
				}
				this.update(formData,  event['id']);
			}
		},

		eventResize(event, delta, revertFunc) {
			if(this.auth.isAdmin() || (event && event['utente_id'] == this.user.id)) {
				const formData = new FormData();
				formData.append("drop",  true);
				if(event['remota']) {
					formData.append("dal",  moment(event['start']['_d']).format("YYYY-MM-DD"));
					formData.append("ora",  moment(event['start']['_d']).subtract({ hours: 1 }).format("HH:mm:ss"));
				} else {
					formData.append("dal",  moment(event['start']['_d']).format("YYYY-MM-DD"));
					formData.append("al",  moment(event['end']['_d']).subtract({ days: 1 }).format("YYYY-MM-DD"));
				}
				this.update(formData,  event['id']);
			}
		},

		refreshEvents() {
			this.$refs.calendar.$emit('refetch-events');
			this.$refs.calendar.fireMethod('changeView', 'basicDay');
			this.$refs.calendar.fireMethod('changeView', 'month');
		},

		update(data, id) {
			if(this.auth.isUserEnable('academy_attivita_mng')) {
				this.$http.post(this.$store.state.apiEndPoint + '/academyint/attivita/edit/' + id, data).then((response) => {
					if (response.ok){
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		create() {
			var academy_ids = [];
			const formData = new FormData();
			formData.append("remota", this.remota);
			if(!this.dal || this.dal == '') {
				alert(this.$i18n.t('Devi inserire una data per l\'attivita!'));
				return;
			}
			formData.append("dal", this.dal);
			if(this.remota) {
				if(!this.ora || this.ora == '') {
					alert(this.$i18n.t('Devi inserire un orario per l\'attivita!'));
					return;
				}
				formData.append("ora", this.ora);
				if(this.academies.length == 0) {
					alert(this.$i18n.t('Devi selezionare una o più academy!'));
					return;
				}
				for(var i in this.academies) {
					academy_ids.push(this.academies[i].id);
				}
			} else {
				if(!this.al || this.al == '') {
					alert(this.$i18n.t('Devi inserire un intervallo di date!'));
					return;
				}
				formData.append("al", this.al);
				if(!this.academy) {
					alert(this.$i18n.t('Devi selezionare un\'academy!'));
					return;
				}
				academy_ids.push(this.academy.id);
			}
			formData.append("academy_ids", academy_ids);

			this.$http.post(this.$store.state.apiEndPoint + '/academyint/attivita/add', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		eliminaAttivita() {
			if(confirm(this.$i18n.t("Confermi l'eliminazione dell'attività ?"))) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyint/delete/attivita/' + this.event_id).then((response) => {

					if (response.ok)
					{
						this.event_id = null;
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
				});
			}
		},

		getNomeAcademy(id_academy) {
			var academy = this.academy_options.find((item) => item.id == id_academy);
			if(academy) {
				return academy.nome;
			}
			return "";
		},

		downloadReport(id) {
			window.open(this.$store.state.apiEndPoint + '/academyint/attivita/report/' + id + "/pdf/" + this.$i18n.t('lang'), "_blank");
		},
	}

}
</script>

<style>
.fc-resizer.fc-end-resizer {
	display: none;
}
</style>
