<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 937.9 536.2" style="enable-background:new 0 0 937.9 536.2;" xml:space="preserve">
    <g id="sfondo">
      <rect id="XMLID_550_" x="84" y="412.6" class="st0" width="769.9" height="8.9"/>
      <rect id="XMLID_549_" x="84" y="421.5" class="st1" width="769.9" height="20.7"/>
      <polygon id="XMLID_548_" class="st2" points="853.9,412.6 736.6,101.1 201.4,101.1 84,412.6 	"/>
      <polygon id="XMLID_547_" class="st3" points="853.9,412.6 736.6,101.1 201.4,101.1 84,412.6 	"/>
      <polygon id="XMLID_546_" class="st2" points="824.4,395.7 112.8,395.7 210.3,118 727,118 	"/>
      <g id="XMLID_513_" class="st4">
        <g>
          <polygon id="XMLID_226_" class="st5" points="468.9,371.7 439.1,371.8 446.1,132.6 468.7,132.6 			"/>
          <polygon id="XMLID_223_" class="st5" points="230.3,372 200.4,372 265.3,132.9 287.9,132.8 			"/>
          <polygon id="XMLID_221_" class="st5" points="290,371.9 260.1,372 310.5,132.8 333.1,132.8 			"/>
          <polygon id="XMLID_219_" class="st5" points="349.6,371.9 319.8,371.9 355.7,132.8 378.3,132.7 			"/>
          <polygon id="XMLID_217_" class="st5" points="409.3,371.8 379.5,371.8 400.9,132.7 423.5,132.7 			"/>
          <polygon id="XMLID_215_" class="st5" points="528.6,371.7 498.8,371.7 491.3,132.6 513.9,132.5 			"/>
          <polygon id="XMLID_213_" class="st5" points="588.2,371.6 558.4,371.7 536.5,132.5 559.1,132.5 			"/>
          <polygon id="XMLID_211_" class="st5" points="647.9,371.6 618,371.6 581.7,132.4 604.3,132.4 			"/>
          <polygon id="XMLID_209_" class="st5" points="707.5,371.5 677.7,371.5 626.9,132.4 649.5,132.4 			"/>
          <polygon id="XMLID_207_" class="st5" points="767.1,371.4 737.3,371.5 672,132.3 694.6,132.3 			"/>
        </g>
      </g>
      <path id="XMLID_512_" class="st6" d="M733.3,195.5c0,0,0.5,4.8,3.6,15c3.1,10.1,6.8,17.7,6.8,17.7h-13l-9.7-32.7H733.3z"/>
      <path id="XMLID_511_" class="st6" d="M730.8,251.5h14.4c0,0-2.2-5.5-2.2-11.8s0.8-11.5,0.8-11.5h-13V251.5z"/>
      <path id="XMLID_510_" class="st6" d="M733.3,195.5c0,0-0.6,5.4-0.6,11.8c0,6.4,2,12.2,2,12.2s-0.5,3.5,2.4,12.5
        c2.8,9,8.2,19.5,8.2,19.5s-2.2-5.6-2.2-12c0-6.4,0.8-11.6,0.8-11.6h0c0,0-3.7-7.5-6.8-17.5C733.8,200.3,733.3,195.5,733.3,195.5
        L733.3,195.5z"/>
      <path id="XMLID_509_" class="st6" d="M721.1,219.4h13.5c0,0-2-5.7-2-12.1c0-6.5,0.6-11.8,0.6-11.8h-12.2V219.4z"/>
      <polygon id="XMLID_508_" class="st7" points="767.2,371.7 170.5,371.7 242.8,132.6 694.8,132.6 	"/>
      <polyline id="XMLID_507_" class="st8" points="716.3,203.5 691.7,203.5 709.7,269.8 736.3,269.8 	"/>
      <line id="XMLID_506_" class="st9" x1="468.8" y1="132.6" x2="468.8" y2="371.7"/>
      <ellipse id="XMLID_505_" class="st10" cx="468.8" cy="235.3" rx="47.9" ry="33.2"/>
      <path id="XMLID_504_" class="st11" d="M470.2,233.6c0,0.5-0.6,1-1.4,1c-0.8,0-1.4-0.4-1.4-1c0-0.5,0.6-1,1.4-1
        C469.6,232.7,470.2,233.1,470.2,233.6z"/>
      <path id="XMLID_503_" class="st12" d="M654.6,262.7c-14.4-5.8-24.3-15.2-26.6-26.1c-2.2-10.7,4-20.1,15.4-25.9"/>
      <path id="XMLID_502_" class="st11" d="M675.1,233.7c0.1,0.4-0.3,0.7-0.9,0.7c-0.6,0-1.2-0.3-1.2-0.7c-0.1-0.4,0.3-0.7,0.9-0.7
        C674.4,232.9,675,233.3,675.1,233.7z"/>
      <polyline id="XMLID_501_" class="st13" points="707.5,175.7 636.2,175.7 665.6,314.7 749.9,314.7 	"/>
      <polyline id="XMLID_500_" class="st8" points="221.4,203.5 246,203.5 227.9,269.8 201.3,269.8 	"/>
      <path id="XMLID_499_" class="st14" d="M283.1,262.7c14.1-5.8,23.7-15.1,26-26.1c2.2-10.7-3.9-20.1-15.1-25.9"/>
      <path id="XMLID_498_" class="st11" d="M262.5,233.9c-0.1,0.4,0.3,0.7,0.9,0.7c0.6,0,1.2-0.3,1.2-0.7c0.1-0.4-0.3-0.7-0.9-0.7
        C263.1,233.2,262.6,233.5,262.5,233.9z"/>
      <polyline id="XMLID_497_" class="st13" points="187.7,314.7 272.1,314.7 301.5,175.7 229.8,175.7 	"/>
      <polyline id="XMLID_496_" class="st15" points="730.8,251.5 730.8,228.2 721.1,196.2 721.1,219.4 	"/>
      <path id="XMLID_495_" class="st6" d="M204.2,195.5c0,0-0.5,4.8-3.6,15c-3.1,10.1-6.8,17.7-6.8,17.7h13l9.7-32.7H204.2z"/>
      <path id="XMLID_494_" class="st6" d="M206.8,251.5h-14.4c0,0,2.2-5.5,2.2-11.8s-0.8-11.5-0.8-11.5h13V251.5z"/>
      <path id="XMLID_493_" class="st6" d="M204.3,195.5c0,0,0.6,5.4,0.6,11.8c0,6.4-2,12.2-2,12.2s0.5,3.5-2.4,12.5
        c-2.8,9-8.2,19.5-8.2,19.5s2.2-5.6,2.2-12c0-6.4-0.8-11.6-0.8-11.6h0c0,0,3.7-7.5,6.8-17.5C203.8,200.3,204.2,195.5,204.3,195.5
        L204.3,195.5z"/>
      <path id="XMLID_492_" class="st6" d="M216.5,219.4h-13.5c0,0,2-5.7,2-12.1c0-6.5-0.6-11.8-0.6-11.8h12.2V219.4z"/>
      <polyline id="XMLID_491_" class="st15" points="206.8,251.5 206.8,228.2 216.5,196.2 216.5,219.4 	"/>
      <path id="XMLID_234_" class="st13" d="M691.1,132.6c1.2,2.5,4.4,2.5,4.4,2.5"/>
      <path id="XMLID_233_" class="st13" d="M246.5,132.6c-1.2,2.5-4.4,2.5-4.4,2.5"/>
      <path id="XMLID_232_" class="st13" d="M762.3,371.7c-1.1-3.4,3.8-3.4,3.8-3.4"/>
      <path id="XMLID_231_" class="st13" d="M175.3,371.7c1.1-3.4-3.8-3.4-3.8-3.4"/>
      <polygon id="XMLID_11_" class="st16" points="242.8,132.6 224.9,191.9 712.9,191.9 694.8,132.6 	"/>
      <polygon id="XMLID_10_" class="st17" points="196.7,285 170.3,371.7 767.3,371.7 740.9,285 	"/>
      <polygon id="XMLID_9_" class="st18" points="712.9,191.9 740.9,285 196.7,285 224.9,191.9 	"/>
      <polygon id="XMLID_8_" class="st19" points="242.8,132.6 379.2,132.7 349.6,371.9 170.3,371.7 	"/>
      <polygon id="XMLID_7_" class="st19" points="694.8,132.6 559.2,132.7 588.7,371.9 767.3,371.7 	"/>
      <polygon id="XMLID_586_" class="st20" points="242.8,132.6 224.9,191.9 712.9,191.9 694.8,132.6 	"/>
      <polygon id="XMLID_585_" class="st20" points="196.7,285 170.3,371.7 767.3,371.7 740.9,285 	"/>
      <polygon id="XMLID_584_" class="st20" points="712.9,191.9 740.9,285 196.7,285 224.9,191.9 	"/>
      <polygon id="XMLID_581_" class="st20" points="379.2,132.7 349.6,371.9 170.3,371.7 242.8,132.6 	"/>
      <polygon id="XMLID_582_" class="st20" points="379.2,132.7 559.2,132.7 588.7,371.9 349.6,371.9 	"/>
      <polygon id="XMLID_583_" class="st20" points="559.2,132.7 694.8,132.6 767.3,371.7 588.7,371.9 	"/>
    </g>
    <g id="sotto_sx" @mouseover="hoverSection = 'sotto_sx'" @mouseout="hoverSection = null" @click="clicked(7)">
      <polygon class="st21" points="360.1,285 197.2,285 170.8,371.7 349.4,371.9 	"/>
    </g>
    <g id="c_sotto_sx" v-if="hoverSection === 'sotto_sx' || selectedSection === 7">
      <path class="st2" d="M286.5,314.1c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6C287.1,315.8,287.1,314.8,286.5,314.1
        z"/>
    </g>
    <g id="sotto_centro" @mouseover="hoverSection = 'sotto_centro'" @mouseout="hoverSection = null" @click="clicked(8)">
      <polygon class="st21" points="360.1,285 578,285 588.7,371.9 349.4,371.9 	"/>
    </g>
    <g id="c_sotto_centro" v-if="hoverSection === 'sotto_centro' || selectedSection === 8">
      <path class="st2" d="M486.7,314.1c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6C487.3,315.8,487.3,314.8,486.7,314.1
        z"/>
    </g>
    <g id="sotto_dx" @mouseover="hoverSection = 'sotto_dx'" @mouseout="hoverSection = null" @click="clicked(9)">
      <polygon class="st21" points="578,285 740.9,285 767.3,371.7 588.7,371.9 	"/>
    </g>
    <g id="c_sotto_dx" v-if="hoverSection === 'sotto_dx' || selectedSection === 9">
      <path class="st2" d="M687.6,314.1c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6L657,325c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6
        C688.2,315.8,688.2,314.8,687.6,314.1z"/>
    </g>
    <g id="centro_sx" @mouseover="hoverSection = 'centro_sx'" @mouseout="hoverSection = null" @click="clicked(4)">
      <polygon class="st21" points="225.3,191.9 371.6,191.9 360.1,285 197.2,285 	"/>
    </g>
    <g id="c_centro_sx" v-if="hoverSection === 'centro_sx' || selectedSection === 4">
      <path class="st2" d="M304.4,220.8c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6C305,222.4,305,221.4,304.4,220.8z"/>
    </g>
    <g id="centro_centro" @mouseover="hoverSection = 'centro_centro'" @mouseout="hoverSection = null" @click="clicked(5)">
      <polygon class="st21" points="371.6,191.9 566.5,191.9 578,285 360.1,285 	"/>
    </g>
    <g id="c_centro_centro" v-if="hoverSection === 'centro_centro' || selectedSection === 5">
      <path class="st2" d="M486.7,220.8c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6C487.3,222.4,487.3,221.4,486.7,220.8
        z"/>
    </g>
    <g id="centro_dx" @mouseover="hoverSection = 'centro_dx'" @mouseout="hoverSection = null" @click="clicked(6)">
      <polygon class="st21" points="712.9,191.9 566.5,191.9 578,285 740.9,285 	"/>
    </g>
    <g id="c_centro_dx" v-if="hoverSection === 'centro_dx' || selectedSection === 6">
      <path class="st2" d="M665.2,220.8c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6C665.8,222.4,665.8,221.4,665.2,220.8
        z"/>
    </g>
    <g id="alto_dx" @mouseover="hoverSection = 'alto_dx'" @mouseout="hoverSection = null" @click="clicked(3)">
      <polygon class="st21" points="559.2,132.7 694.8,132.6 712.9,191.9 566.5,191.9 	"/>
    </g>
    <g id="c_alto_dx" v-if="hoverSection === 'alto_dx' || selectedSection === 3">
      <path class="st2" d="M649.4,147.1c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6
        C650,148.7,650,147.7,649.4,147.1z"/>
    </g>
    <g id="alto_centro" @mouseover="hoverSection = 'alto_centro'" @mouseout="hoverSection = null" @click="clicked(2)">
      <polygon class="st21" points="379,132.7 371.6,191.9 566.5,191.9 559.2,132.7 	"/>
    </g>
    <g id="c_alto_centro" v-if="hoverSection === 'alto_centro' || selectedSection === 2">
      <path class="st2" d="M486.7,147.1c-0.6-0.6-1.6-0.6-2.2,0l-19.6,19.6l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6C487.3,148.7,487.3,147.7,486.7,147.1
        z"/>
    </g>
    <g id="alto_sx" @mouseover="hoverSection = 'alto_sx'" @mouseout="hoverSection = null" @click="clicked(1)">
      <polygon class="st21" points="379,132.7 243.3,132.6 225.3,191.9 371.6,191.9 	"/>
    </g>
    <g id="c_alto_sx" v-if="hoverSection === 'alto_sx' || selectedSection === 1">
      <path class="st2" d="M319.9,147.1c-0.6-0.6-1.6-0.6-2.2,0L298,166.7l-8.7-8.7c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
        l9.9,9.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l20.6-20.6C320.5,148.7,320.5,147.7,319.9,147.1
        z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'FieldSection',

  data() {
    return {
      selectedSection: null,
      hoverSection: null,
    };
  },

  methods: {
    clicked(section) {
      this.selectedSection = section;
      this.$emit('clicked', section);
    },

    reset() {
      this.selectedSection = null;
      this.hoverSection = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.st0{fill:#41903F;}
.st1{fill:#D1D1D1;}
.st2{fill:#6AB75F;}
.st3{opacity:0.5;fill:#479F46;}
.st4{opacity:0.5;}
.st5{fill:#479F46;}
.st6{opacity:0.3;fill:#FFFFFF;}
.st7{fill:none;stroke:#FFFFFF;stroke-width:0.7117;stroke-miterlimit:10;}
.st8{fill:none;stroke:#FFFFFF;stroke-width:0.6478;stroke-miterlimit:10;}
.st9{fill:none;stroke:#FFFFFF;stroke-width:0.734;stroke-miterlimit:10;}
.st10{fill:none;stroke:#FFFFFF;stroke-width:0.768;stroke-miterlimit:10;}
.st11{fill:#FFFFFF;}
.st12{fill:none;stroke:#FFFFFF;stroke-width:0.7902;stroke-miterlimit:10;}
.st13{fill:none;stroke:#FFFFFF;stroke-width:0.6075;stroke-miterlimit:10;}
.st14{fill:none;stroke:#FFFFFF;stroke-width:0.7818;stroke-miterlimit:10;}
.st15{fill:none;stroke:#FFFFFF;stroke-width:1.4233;stroke-linejoin:round;stroke-miterlimit:10;}
.st16{opacity:0.3;fill:#36A9E1;}
.st17{opacity:0.3;fill:#F39200;}
.st18{opacity:0.3;fill:#E6007E;}
.st19{opacity:0.5;fill:#9D9D9C;}
.st20{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
.st21{opacity:0.6;fill:#FFFFFF;}

#sotto_sx, #c_sotto_sx,
#sotto_centro, #c_sotto_centro,
#sotto_dx, #c_sotto_dx,
#centro_sx, #c_centro_sx,
#centro_centro, #c_centro_centro,
#centro_dx, #c_centro_dx,
#alto_dx, #c_alto_dx,
#alto_centro, #c_alto_centro,
#alto_sx, #c_alto_sx {
  cursor: pointer;
}
</style>
