<template>
	<div>
		<b-card class="shrink">
			<template v-if="!$slots.default" #header>Injury</template>
			<template #header v-else>
				<slot></slot>
			</template>
			<b-row>
				<b-col>
					
					<!-- <b-row> -->
						<!-- <b-col> -->
							<div><strong>Regions</strong> <small v-if="filter.region_id != null">- <a href="javascript:;" @click.prevent="filter.region_id = null">Tutte</a></small></div>
							<b-button @click.prevent="filter.region_id = []" v-if="filter.region_id == null" size="xs" variant="outline-secondary">Tutte</b-button>
							
							<div v-if="filter.region_id != null">
								<b-form-select v-model="filter.region_id" multiple>
									<b-form-select-option v-for="region in regions" :value="region.id">{{region.name}} <span v-if="amount('injury_region_id', region.id)">({{ amount('injury_region_id', region.id) }})</span></b-form-select-option>
								</b-form-select>
							</div>

						</b-col>
						<b-col>
							<div><strong>Classifications</strong> <small v-if="filter.classification_id != null">- <a href="javascript:;" @click.prevent="filter.classification_id = null">Tutte</a></small></div>
							<b-button @click.prevent="filter.classification_id = []" v-if="filter.classification_id == null" size="xs" variant="outline-secondary">Tutte</b-button>
							
							<div v-if="filter.classification_id != null">
								<b-form-select v-model="filter.classification_id" multiple>
									<b-form-select-option v-for="classification in classifications" :value="classification.id">{{classification.name}}</b-form-select-option>
								</b-form-select>
							</div>

							<div v-if="hasMuscular" class="mt-2">
								<div><strong>Muscle Detail</strong></div>
								{{hasMuscular}}
							</div>

						</b-col>
					<!-- </b-row> -->

					
				<!-- </b-col> -->
				<b-col coels="4">
					<div v-if="specificPathology.length == 0">
						<div><strong>Pathology</strong> <small v-if="filter.pathology_id != null">- <a href="javascript:;" @click.prevent="filter.pathology_id = null">Tutte</a></small></div>
						
						<b-button @click.prevent="filter.pathology_id = []" v-if="filter.pathology_id == null" size="xs" variant="outline-secondary">Tutte</b-button>

						<b-input v-if="filter.pathology_id != null" type="search" placeholder="Search Pathology" v-model="ux.search"></b-input>

						<b-form-select v-if="filter.pathology_id != null" v-model="filter.pathology_id" multiple>
							<b-form-select-option v-for="pathology in pathologiesFound" :value="pathology.id">{{pathology.name}} <span v-if="amount('pathology_id', pathology.id)">({{ amount('pathology_id', pathology.id) }})</span></b-form-select-option>
						</b-form-select>
					</div>
					<div v-else>
						<div><strong>Pathology</strong> <small v-if="filter.pathology_id != null">- <a href="javascript:;" @click.prevent="filter.pathology_id = null">Tutte</a></small></div>
						<b-button @click.prevent="filter.pathology_id = []" v-if="filter.pathology_id == null" size="xs" variant="outline-secondary">Tutte ({{specificPathology.length}})</b-button>
						
						<div v-if="filter.pathology_id != null">
							<b-form-select v-model="filter.pathology_id" multiple>
								<b-form-select-option v-for="pathology in specificPathology" :value="pathology.id">{{pathology.name}} <span v-if="amount('pathology_id', pathology.id)">({{ amount('pathology_id', pathology.id) }})</span></b-form-select-option>
							</b-form-select>
						</div>
					</div>

					<!-- <pre>{{ filter.pathology_id }}</pre> -->
					
				</b-col>
				<b-col></b-col>
				<b-col></b-col>
			</b-row>
			<hr />
			<b-row>
				<b-col>
					<div><strong>Second Injury</strong> <small v-if="filter.reinjury != null">- <a href="javascript:;" @click.prevent="filter.reinjury = null">Tutte</a></small></div>
					<b-button @click.prevent="filter.reinjury = []" v-if="filter.reinjury == null" size="xs" variant="outline-secondary">Tutte (5)</b-button>

					<div v-if="filter.reinjury != null">
						<b-form-select v-model="filter.reinjury" multiple>
							<b-form-select-option :value="0">No</b-form-select-option>
							<b-form-select-option :value="1">Reinjury</b-form-select-option>
							<b-form-select-option :value="2">Subsequent new injury</b-form-select-option>
							<b-form-select-option :value="3">Subsequent local injury</b-form-select-option>
							<b-form-select-option :value="4">Exacerbation</b-form-select-option>
						</b-form-select>
					</div>
				</b-col>
				<b-col>
					<div><strong>Cause</strong> <small v-if="filter.cause != null">- <a href="javascript:;" @click.prevent="filter.cause = null">Tutte</a></small></div>
					<b-button @click.prevent="filter.cause = []" v-if="filter.cause == null" size="xs" variant="outline-secondary">Tutte (3)</b-button>

					<div v-if="filter.cause != null">
						<b-form-select v-model="filter.cause" multiple>
							<b-select-option value="overuse">Overuse</b-select-option>
							<b-select-option value="trauma">Trauma</b-select-option>
							<b-select-option value="N/A">N/A</b-select-option>
						</b-form-select>
					</div>
				</b-col>
				<b-col>
					<div><strong>Mode</strong> <small v-if="filter.contact != null">- <a href="javascript:;" @click.prevent="filter.contact = null">Tutte</a></small></div>
					<b-button @click.prevent="filter.contact = []" v-if="filter.contact == null" size="xs" variant="outline-secondary">Tutte (2)</b-button>

					<div v-if="filter.contact != null">
						<b-form-select v-model="filter.contact" size="sm">
							<b-select-option :value="null">No Filter</b-select-option>
							<b-select-option :value="1">Contact</b-select-option>
							<b-select-option :value="0">Not Contact</b-select-option>
						</b-form-select>
					</div>
				</b-col>
				<b-col>
					<div><strong>Mechanism</strong> <small v-if="filter.mechanism != null">- <a href="javascript:;" @click.prevent="filter.mechanism = null">Tutte</a></small></div>
					<b-button @click.prevent="filter.mechanism = []" v-if="filter.mechanism == null" size="xs" variant="outline-secondary">Tutti ({{ guideline.mechanisms.length }})</b-button>
					<div v-if="filter.mechanism != null">
						<b-form-select v-model="filter.mechanism" multiple>
							<b-form-select-option v-for="mechanism in guideline.mechanisms" :value="mechanism.id">{{mechanism.name}}</b-form-select-option>
						</b-form-select>
					</div>
				</b-col>
				<b-col>
					<div><strong>Event</strong> <small v-if="filter.event != null">- <a href="javascript:;" @click.prevent="filter.event = null">Tutte</a></small></div>

					<b-button @click.prevent="filter.event = []" v-if="filter.event == null" size="xs" variant="outline-secondary">Tutte (2)</b-button>

					<div v-if="filter.event != null">
						<b-form-select v-model="filter.event" multiple>
	  						<b-select-option value="training">Training</b-select-option>
	  						<b-select-option value="match">Match</b-select-option>
	  						<b-select-option value="N/A">N/A</b-select-option>
						</b-form-select>
					</div>

				</b-col>
			</b-row>
		</b-card>

	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import moment from 'moment'
import PlayerPicker from '@/components/layout/Football/PlayerPicker.vue';

export default {
	mixins: [ pageBase ],
	props: [ 'guideline', 'start', 'results' ],
	data: function() {
		return {
			filter: null,
			ux: {
				search: ''
			}
		}
	},
	watch: {
		filter: {
			handler: function(a,b) {
				this.$emit('change', this.filter);
			}
		}
	},
	created: function() {

		if(this.start) {
			this.filter = _.cloneDeep(this.start);
		} else {
			this.emptyFilter();
		}

	},
	methods: {
		amount: function(k, id) {
			var b = _.groupBy(this.results, k)[id];
			if(b) return b.length;
			return false;
		},
		emptyFilter: function() {

			if(this.filter == null) {

				this.filter = {
					person_ids: [],
					from: null,
					to: null,
					region_id: null,
					classification_id: null,
					pathology_id: null,
					reinjury: null,
					cause: null,
					contact: null,
					mechanism: null,
					event: null
				};

			} else {

				if(!this.filter.region_id)
					this.$set(this.filter, 'region_id', null)
				else
					this.filter.region_id = null;
				
				if(!this.filter.classification_id)
					this.$set(this.filter, 'classification_id', null)
				else
					this.filter.classification_id = null;
				
				if(!this.filter.pathology_id)
					this.$set(this.filter, 'pathology_id', null)
				else
					this.filter.pathology_id = null;
				
				if(!this.filter.reinjury)
					this.$set(this.filter, 'reinjury', null)
				else
					this.filter.reinjury = null;
				
				if(!this.filter.cause)
					this.$set(this.filter, 'cause', null)
				else
					this.filter.cause = null;
				
				if(!this.filter.contact)
					this.$set(this.filter, 'contact', null)
				else
					this.filter.contact = null;
				
				if(!this.filter.mechanism)
					this.$set(this.filter, 'mechanism', null)
				else
					this.filter.mechanism = null;
				
				if(!this.filter.event)
					this.$set(this.filter, 'event', null)
				else
					this.filter.event = null;

			}

			return b;
		}
	},
	computed: {
		hasMuscular: function() {

			if(this.filter.classification_id == null) return false;

			return this.filter.classification_id.indexOf(11) !== -1;

		},
		pathologiesFound: function() {

			var toSearch = _.map(_.filter(this.ux.search.split(' ')), function(i) { return i.toLowerCase(); });

			

			var res = _.filter(this.pathologies, function(pat) {
				var valid = true;
				_.each(toSearch, function(i) {
					if(pat.name.toLowerCase().indexOf(i) === -1)
						valid = false;
				});

				return valid;

			});

			if(res.length > 0) {

				if(this.filter.pathology_id == null)
					this.filter.pathology_id = [];

				return res;

			} else if(!res || res.length == 0 && this.filter.pathology_id && this.filter.pathology_id.length == 0)
				return this.pathologies;//this.filter.pathology_id = null;

			return toSearch;
		},
		specificPathology: function() {

			if(
				this.filter.region_id && this.filter.region_id.length == 1 &&
				this.filter.classification_id && this.filter.classification_id.length == 1
			) {
				return _.filter(this.guideline.pathologies, function(a) {

					return a.region_classification.id_region == _.first(this.filter.region_id) && a.region_classification.id_class == _.first(this.filter.classification_id);

				}.bind(this));
			}
			return [];

		},
		regions: function() {
			return _.map(_.cloneDeep(this.guideline.regions), function(b) {
				delete b.classifications;
				return b;
			});
		},
		pathologies: function() {
			return this.guideline.pathologies;
		},
		classifications: function() {
			return _.map(_.uniq(_.map(_.map(_.flatten(_.map(this.guideline.regions, 'classifications')), function(a) { delete a.pivot; return a; }), function(a) {
				return JSON.stringify(a);
			})), function(b) {
				return JSON.parse(b);
			});
		}
	}
}

</script>