import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	components: {
		SessioneIntestazione: require('@/components/layout/Sessioni/Intestazione.vue').default,
		Fase: require('@/components/layout/Sessioni/Fase_Contenitore.vue').default,
		VideoManager: require('@/components/layout/video/manager.vue').default,

		Fase_Fisioterapia: require('@/components/layout/Sessioni/Medical/Fase_Fisioterapia.vue').default,
		Fase_Form: require('@/components/layout/Sessioni/Generica/Fase_Form.vue').default,
		Fase_Vuota: require('@/components/layout/Sessioni/Generica/Fase_Vuota.vue').default,
		Fase_Dexa: require('@/components/layout/Sessioni/Medical/Fase_Dexa.vue').default,
		Fase_Fisioterapia_Test: require('@/components/layout/Sessioni/Medical/Fase_Fisioterapia_Test.vue').default,
		Fase_Nutrition: require('@/components/layout/Sessioni/Medical/Fase_Nutrition.vue').default,
		Fase_Farmaco: require('@/components/layout/Sessioni/Medical/Fase_Farmaco.vue').default,
		Fase_Psicologia_Test: require('@/components/layout/Sessioni/Medical/Fase_Psicologia_Test.vue').default,
		Fase_Palestra: require('@/components/layout/Sessioni/Football/Fase_Palestra.vue').default,
		Fase_Esercizio: require('@/components/layout/Sessioni/Football/Fase_Esercizio.vue').default,
		Fase_Partita: require('@/components/layout/Sessioni/Football/Fase_Partita.vue').default,
		Fase_Piscina: require('@/components/layout/Sessioni/Football/Fase_Piscina.vue').default,
		Fase_Video: require('@/components/layout/Sessioni/Football/Fase_Video.vue').default,
		Fase_Documento: require('@/components/layout/Sessioni/Football/Fase_Documento.vue').default,
	},
	methods: {
		isPhaseDisabled: function(fase) {
			return _.filter(fase.players, function(a) {
				return a.inPhase == true && (a.activity_check.checked == 1 || a.activity_check.checked == null);
			}).length == 0;//) return true;
		},
		showAddFasi: function () {
			this.$emit('newFase');
		},
		removeFase: function (k) {
			this.$emit('removeFase', k);
		},
		duplicaFase: function (k) {
			this.$emit('duplicateFase', k);
		},
		sposta(index, dove) {
			this.$emit('moveFase', { index, dove });
		}
	},
	computed: {
		auth: function() { return Auth; },
		...mapState(['rosaTeamId']),
		canAddPhase: function() {
			if(!this.sessione.sessione_tipo.setup) return true;
			return this.sessione.sessione_tipo.setup.aggiuntaFasi == undefined || this.sessione.sessione_tipo.setup.aggiuntaFasi == true;
		},
		glossario: function() {
			return this.fasiGeneriche;
		},
		sessioneCorrente: function() {
			return _.find(this.sessioneTipi, { id: this.sessione.sessione_tipo_glo_id });
		},
		personeCorrenti: function() {
			return _.map(this.sessione.persone, function(a) {
				return {
					name: a.cognome + ' ' + a.nome,
					id: a.id
				}
			});
		},
		personeIds: function() {
			return _.map(this.personeCorrenti, 'id');
		},
		getTipoSessione: function() {
			if(this.sessioneTipi.length > 0) {
				return this.sessioneTipi.find((item) => item.id == this.$route.params.tipo).nome;	
			} return 'Sessione';
		},
		isManagerSessione: function() {
			if(this.sessione && this.sessione.sessione_tipo) {
				if(this.team && this.sessione.categoria_id != this.team.id_categoria) {
					return false;
				}
				return this.auth.isUserEnable('sessione_' + this.sessione.sessione_tipo.nome.toLowerCase().replaceAll(' ', '') + '_mng');
			}
			return false;
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}
};