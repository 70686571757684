<template>
  <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1285.9 410.2" style="enable-background:new 0 0 1285.9 410.2;" xml:space="preserve">
    <g id="sfondo_1_">
      <polygon id="XMLID_58_" class="st0" points="255.7,349.6 1030,349.6 1029.4,358.5 256.4,358.5 	"/>
      <polygon id="XMLID_57_" class="st1" points="256.4,358.5 1029.4,358.5 1027.9,379.2 258,379.2 	"/>
      <polygon id="XMLID_56_" class="st2" points="1030,349.6 926.7,38.1 356.3,38.1 255.7,349.6 	"/>
      <polygon id="XMLID_55_" class="st3" points="1030,349.6 926.7,38.1 356.3,38.1 255.7,349.6 	"/>
      <polygon id="XMLID_54_" class="st2" points="1001.4,332.7 283.4,332.7 366.7,55 915.7,55 	"/>
      <g id="XMLID_43_" class="st4">
        <polygon id="XMLID_53_" class="st5" points="642.6,308.7 612.4,308.8 617.4,69.6 641.3,69.6 		"/>
        <polygon id="XMLID_52_" class="st5" points="400.8,309 370.5,309 425.8,69.9 449.7,69.8 		"/>
        <polygon id="XMLID_51_" class="st5" points="461.3,308.9 431,309 473.7,69.8 497.6,69.8 		"/>
        <polygon id="XMLID_50_" class="st5" points="521.7,308.9 491.5,308.9 521.6,69.8 545.5,69.7 		"/>
        <polygon id="XMLID_49_" class="st5" points="582.2,308.8 552,308.8 569.5,69.7 593.4,69.7 		"/>
        <polygon id="XMLID_48_" class="st5" points="703.1,308.7 672.9,308.7 665.3,69.6 689.2,69.5 		"/>
        <polygon id="XMLID_47_" class="st5" points="763.5,308.6 733.3,308.7 713.2,69.5 737.1,69.5 		"/>
        <polygon id="XMLID_46_" class="st5" points="824,308.6 793.7,308.6 761.1,69.4 785,69.4 		"/>
        <polygon id="XMLID_45_" class="st5" points="884.5,308.5 854.3,308.5 809,69.4 833,69.4 		"/>
        <polygon id="XMLID_44_" class="st5" points="944.9,308.4 914.7,308.5 856.8,69.3 880.8,69.3 		"/>
      </g>
      <path id="XMLID_42_" class="st6" d="M918.8,132.5c0,0,0.3,4.8,3.1,15c2.8,10.1,6.2,17.7,6.2,17.7h-13.5l-8.7-32.7H918.8z"/>
      <path id="XMLID_41_" class="st6" d="M913.6,188.5h14.9c0,0-2-5.5-1.7-11.8c0.3-6.3,1.4-11.5,1.4-11.5h-13.5L913.6,188.5z"/>
      <path id="XMLID_40_" class="st6" d="M918.8,132.5c0,0-0.9,5.4-1.2,11.8c-0.3,6.4,1.5,12.2,1.5,12.2s-0.7,3.5,1.9,12.5
        c2.5,9,7.6,19.5,7.6,19.5s-2-5.6-1.7-12s1.4-11.6,1.4-11.6l0,0c0,0-3.5-7.5-6.3-17.5C919.1,137.3,918.8,132.5,918.8,132.5
        L918.8,132.5z"/>
      <path id="XMLID_39_" class="st6" d="M905,156.4h14.1c0,0-1.8-5.7-1.5-12.1c0.3-6.5,1.2-11.8,1.2-11.8h-12.8l-1.1,23.9H905z"/>
      <polygon id="XMLID_38_" class="st7" points="945,308.7 340.1,308.7 401.9,69.6 881,69.6 	"/>
      <polyline id="XMLID_37_" class="st8" points="900.7,140.5 874.9,140.5 891,206.8 918.5,206.8 	"/>
      <line id="XMLID_36_" class="st9" x1="641.4" y1="69.6" x2="642.5" y2="308.7"/>
      <path id="XMLID_35_" class="st10" d="M641.7,139.1c27.7,0,50.1,14.9,50,33.2s-22.3,33.2-49.7,33.2s-49.7-14.9-50-33.2
        S614.1,139.1,641.7,139.1z"/>
      <path id="XMLID_34_" class="st11" d="M643.3,170.6c0,0.5-0.6,1-1.5,1c-0.8,0-1.5-0.4-1.5-1c0-0.5,0.6-1,1.5-1
        C642.7,169.7,643.3,170.1,643.3,170.6z"/>
      <path id="XMLID_33_" class="st12" d="M834.3,199.7c-14.7-5.8-24.7-15.2-26.8-26.1c-2-10.7,4.7-20.1,16.8-25.9"/>
      <path id="XMLID_32_" class="st11" d="M856.5,170.7c0.1,0.4-0.3,0.7-1,0.7c-0.6,0-1.2-0.3-1.2-0.7c-0.1-0.4,0.3-0.7,1-0.7
        C855.8,169.9,856.4,170.3,856.5,170.7z"/>
      <polyline id="XMLID_31_" class="st13" points="892.6,112.7 817.6,112.7 843.9,251.7 930.3,251.7 	"/>
      <polyline id="XMLID_30_" class="st8" points="382.9,140.5 408.7,140.5 393.1,206.8 365.6,206.8 	"/>
      <path id="XMLID_29_" class="st14" d="M449.9,199.7c14.4-5.8,24-15.1,26-26.1c1.9-10.7-4.8-20.1-16.7-25.9"/>
      <path id="XMLID_28_" class="st11" d="M427.3,170.9c-0.1,0.4,0.3,0.7,1,0.7s1.2-0.3,1.2-0.7c0.1-0.4-0.3-0.7-1-0.7
        S427.4,170.5,427.3,170.9z"/>
      <polyline id="XMLID_27_" class="st13" points="354.2,251.7 440.7,251.7 465.7,112.7 390.3,112.7 	"/>
      <polyline id="XMLID_26_" class="st15" points="913.6,188.5 914.7,165.2 906,133.2 905,156.4 	"/>
      <path id="XMLID_25_" class="st6" d="M364.5,132.5c0,0-0.3,4.8-2.9,15c-2.7,10.1-6.1,17.7-6.1,17.7H369l8.4-32.7H364.5z"/>
      <path id="XMLID_24_" class="st6" d="M370.3,188.5h-14.9c0,0,2-5.5,1.6-11.8s-1.5-11.5-1.5-11.5H369L370.3,188.5z"/>
      <path id="XMLID_23_" class="st6" d="M364.6,132.5c0,0,0.9,5.4,1.3,11.8c0.4,6.4-1.4,12.2-1.4,12.2s0.7,3.5-1.8,12.5
        c-2.4,9-7.4,19.5-7.4,19.5s2-5.6,1.6-12s-1.5-11.6-1.5-11.6l0,0c0,0,3.4-7.5,6.1-17.5C364.3,137.3,364.5,132.5,364.6,132.5
        L364.6,132.5z"/>
      <path id="XMLID_22_" class="st6" d="M378.7,156.4h-14.1c0,0,1.8-5.7,1.4-12.1c-0.4-6.5-1.3-11.8-1.3-11.8h12.8l1.3,23.9H378.7z"/>
      <polyline id="XMLID_21_" class="st15" points="370.3,188.5 369,165.2 377.4,133.2 378.7,156.4 	"/>
      <path id="XMLID_20_" class="st13" d="M877,69.6c1.2,2.5,4.6,2.5,4.6,2.5"/>
      <path id="XMLID_19_" class="st13" d="M405.8,69.6c-1.2,2.5-4.5,2.5-4.5,2.5"/>
      <path id="XMLID_18_" class="st13" d="M940,308.7c-0.9-3.4,4-3.4,4-3.4"/>
      <path id="XMLID_17_" class="st13" d="M345,308.7c0.9-3.4-4.1-3.4-4.1-3.4"/>
      <polygon id="XMLID_16_" class="st16" points="401.9,69.6 386,128.9 897.6,128.9 881,69.6 	"/>
      <polygon id="XMLID_15_" class="st17" points="361.7,222 339.9,308.7 945.1,308.7 922.5,222 	"/>
      <polygon id="XMLID_14_" class="st18" points="897.6,128.9 922.5,222 361.7,222 386,128.9 	"/>
      <polygon id="XMLID_13_" class="st19" points="401.9,69.6 546.5,69.7 521.7,308.9 339.9,308.7 	"/>
      <polygon id="XMLID_12_" class="st19" points="881,69.6 737.2,69.7 764,308.9 945.1,308.7 	"/>
      <polygon id="XMLID_6_" class="st20" points="401.9,69.6 386,128.9 897.6,128.9 881,69.6 	"/>
      <polygon id="XMLID_5_" class="st20" points="361.7,222 339.9,308.7 945.1,308.7 922.5,222 	"/>
      <polygon id="XMLID_4_" class="st20" points="897.6,128.9 922.5,222 361.7,222 386,128.9 	"/>
      <polygon id="XMLID_3_" class="st20" points="546.5,69.7 521.7,308.9 339.9,308.7 401.9,69.6 	"/>
      <polygon id="XMLID_2_" class="st20" points="546.5,69.7 737.2,69.7 764,308.9 521.7,308.9 	"/>
      <polygon id="XMLID_1_" class="st20" points="737.2,69.7 881,69.6 945.1,308.7 764,308.9 	"/>
    </g>
    <g id="sotto_sx_1_" :class="newTag && Number(newTag.field_section) === 7 ? 'active' : ''" @click="setTagField('field_section', 7)">
      <polygon class="st21" points="530.1,222 362.3,222 340.4,308.7 521.5,308.9 	"/>
    </g>
    <g id="sotto_centro_1_" :class="newTag && Number(newTag.field_section) === 8 ? 'active' : ''" @click="setTagField('field_section', 8)">
      <polygon class="st21" points="530.1,222 754.6,222 764,308.9 521.5,308.9 	"/>
    </g>
    <g id="sotto_dx_1_" :class="newTag && Number(newTag.field_section) === 9 ? 'active' : ''" @click="setTagField('field_section', 9)">
      <polygon class="st21" points="754.6,222 922.5,222 945.1,308.7 764,308.9 	"/>
    </g>
    <g id="centro_sx_1_" :class="newTag && Number(newTag.field_section) === 4 ? 'active' : ''" @click="setTagField('field_section', 4)">
      <polygon class="st21" points="386.4,128.9 539.8,128.9 530.1,222 362.3,222 	"/>
    </g>
    <g id="centro_centro_1_" :class="newTag && Number(newTag.field_section) === 5 ? 'active' : ''" @click="setTagField('field_section', 5)">
      <polygon class="st21" points="539.8,128.9 744.1,128.9 754.6,222 530.1,222 	"/>
    </g>
    <g id="centro_dx_1_" :class="newTag && Number(newTag.field_section) === 6 ? 'active' : ''" @click="setTagField('field_section', 6)">
      <polygon class="st21" points="897.6,128.9 744.1,128.9 754.6,222 922.5,222 	"/>
    </g>
    <g id="alto_dx_1_" :class="newTag && Number(newTag.field_section) === 3 ? 'active' : ''" @click="setTagField('field_section', 3)">
      <polygon class="st21" points="737.2,69.7 881,69.6 897.6,128.9 744.1,128.9 	"/>
    </g>
    <g id="alto_centro_1_" :class="newTag && Number(newTag.field_section) === 2 ? 'active' : ''" @click="setTagField('field_section', 2)">
      <polygon class="st21" points="546.3,69.7 539.8,128.9 744.1,128.9 737.2,69.7 	"/>
    </g>
    <g id="alto_sx_1_" :class="newTag && Number(newTag.field_section) === 1 ? 'active' : ''" @click="setTagField('field_section', 1)">
      <polygon class="st21" points="546.3,69.7 402.4,69.6 386.4,128.9 539.8,128.9 	"/>
    </g>
    <g id="result-pos" :class="['cursor', newTag && newTag.result === 'positive' ? 'active' : '']" @click="setTagField('result', 'positive')">
      <polygon class="st22" points="105.8,159.9 274.2,159.9 319.3,37.6 167.4,37.6 	"/>
      <rect x="105.8" y="159.9" class="st23" width="168.1" height="24.4"/>
      <polygon class="st24" points="274.2,184.3 319.3,60.9 319.3,37.6 274.2,159.9 	"/>
      <g>
        <path class="st11" d="M207.6,102h-28.5l5.4-14H213l9.5-24.8h16.2L229.2,88h28.5l-5.4,14h-28.5l-9.5,24.6h-16.2L207.6,102z"/>
      </g>
    </g>
    <g id="result-neg" :class="['cursor', newTag && newTag.result === 'negative' ? 'active' : '']" @click="setTagField('result', 'negative')">
      <polygon class="st25" points="20,331.3 210.8,331.3 261.6,194.1 88.8,194.1 	"/>
      <g>
        <path class="st11" d="M103,271.8l9.1-19.9h73.7l-8,19.9H103z"/>
      </g>
      <rect x="20" y="331.3" class="st26" width="191" height="27.2"/>
      <polygon class="st27" points="210.8,358.5 261.6,218 261.6,194.1 210.8,331.3 	"/>
      <path class="st28" d="M210.8,331.3"/>
    </g>
    <g id="action-def" :class="['cursor', newTag && newTag.type === 'defensive' ? 'active' : '']" @click="setTagField('type', 'defensive')">
      <polygon class="st29" points="1178.9,159.9 1006.2,159.9 961.1,37.6 1117.5,37.6 	"/>
      <g>
        <path class="st11" d="M1050.8,94.9c2.6,6.1,2.6,10.8-0.2,14c-2.6,3.2-7.8,4.8-15.3,4.8h-12.1l-15.6-37h13.4
          c6.9,0,13.2,1.5,18.4,4.8C1044.5,84.7,1048.4,89,1050.8,94.9z M1041.5,95.1c-3.2-8-9.1-11.9-17.3-11.9h-4.8l10.2,24h3.9
          C1042.1,107.2,1044.9,103.1,1041.5,95.1z"/>
        <path class="st11" d="M1090.3,113.7h-24.6l-15.6-37h24.6l2.8,6.5H1062l3.5,8.2h14.5l2.8,6.5h-14.5l4.1,9.5h15.6L1090.3,113.7z"/>
        <path class="st11" d="M1106.5,113.7h-9.1l-15.6-37h24.6l2.8,6.5h-15.6l4.1,9.5h14.5l2.6,6.5h-14.5L1106.5,113.7z"/>
      </g>
      <rect x="1006.3" y="160" class="st30" width="172.6" height="24.4"/>
      <polygon class="st31" points="1006.2,184.3 961.1,60.9 961.1,37.6 1006.2,159.9 	"/>
    </g>
    <g id="action-off" :class="['cursor', newTag && newTag.type === 'offensive' ? 'active' : '']" @click="setTagField('type', 'offensive')">
      <polygon class="st32" points="1264.9,331.3 1069.6,331.3 1018.8,194.1 1196,194.1 	"/>
      <g>
        <path class="st11" d="M1125.1,260.2c2.8,6.9,3,12.1,0.6,15.8c-2.4,3.7-7.1,5.4-14.5,5.4c-7.3,0-13.6-1.7-19.2-5.4
          c-5.4-3.7-9.7-8.9-12.5-15.8c-2.8-6.9-3.2-12.1-0.6-15.8s7.3-5.4,14.7-5.4s13.6,1.7,19.2,5.4
          C1118.2,248.1,1122.3,253.3,1125.1,260.2z M1090.3,260.2c1.9,4.5,4.3,8,7.3,10.4c3,2.4,6.5,3.5,10.6,3.5c8,0,10.2-4.5,6.3-13.8
          c-3.9-9.3-9.9-13.8-17.9-13.8c-4.1,0-6.5,1.1-7.6,3.5C1087.9,252,1088.4,255.6,1090.3,260.2z"/>
        <path class="st11" d="M1151.7,280.7h-9.9l-17.3-41.3h27.4l3,7.1h-17.5l4.5,10.6h16.2l3,7.1H1145L1151.7,280.7z"/>
        <path class="st11" d="M1186.5,280.7h-9.9l-17.3-41.3h27.4l3,7.1h-17.5l4.5,10.6h16.2l3,7.1h-16.4L1186.5,280.7z"/>
      </g>
      <rect x="1069.4" y="331.1" class="st33" width="195.3" height="27.2"/>
      <polygon class="st34" points="1069.6,358.5 1018.8,218 1018.8,194.1 1069.6,331.3 	"/>
      <path class="st28" d="M1069.6,331.3"/>
    </g>
  </svg>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Field',

  computed: mapState(['newTag']),

  methods: {
    setTagField(key, value) {
      this.$store.commit('updateTagField', { key, value });
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  height: 100%;
}
</style>

<style>
.st0{fill:#41903F;}
.st1{fill:#D1D1D1;}
.st2{fill:#6AB75F;}
/* .st3{opacity:0.5;fill:#479F46;enable-background:new    ;} */
.st3{fill:#479F46;enable-background:new    ;}
/* .st4{opacity:0.5;} */
.st5{fill:#479F46;}
.st6{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
.st7{fill:none;stroke:#FFFFFF;stroke-width:0.7117;stroke-miterlimit:10;}
.st8{fill:none;stroke:#FFFFFF;stroke-width:0.6478;stroke-miterlimit:10;}
.st9{fill:none;stroke:#FFFFFF;stroke-width:0.734;stroke-miterlimit:10;}
.st10{fill:none;stroke:#FFFFFF;stroke-width:0.768;stroke-miterlimit:10;}
.st11{fill:#FFFFFF;}
.st12{fill:none;stroke:#FFFFFF;stroke-width:0.7902;stroke-miterlimit:10;}
.st13{fill:none;stroke:#FFFFFF;stroke-width:0.6075;stroke-miterlimit:10;}
.st14{fill:none;stroke:#FFFFFF;stroke-width:0.7818;stroke-miterlimit:10;}
.st15{fill:none;stroke:#FFFFFF;stroke-width:1.4233;stroke-linejoin:round;stroke-miterlimit:10;}
.st16{opacity:0.3;fill:#36A9E1;enable-background:new    ;}
.st17{opacity:0.3;fill:#F39200;enable-background:new    ;}
.st18{opacity:0.3;fill:#E6007E;enable-background:new    ;}
.st19{opacity:0.5;fill:#9D9D9C;enable-background:new    ;}
.st20{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
.st21{opacity:0;fill:#FFFFFF;enable-background:new    ;}
.st22{fill:#58AB86;}
.st23{fill:#76BC9C;}
.st24{fill:#209D72;}
.st25{fill:#A51B37;}
.st26{fill:#B44450;}
.st27{fill:#88192E;}
.st28{fill:none;stroke:#A51B37;stroke-width:0.5804;stroke-miterlimit:10;}
.st29{fill:#F8B431;}
.st30{fill:#FCCC7B;}
.st31{fill:#D69D27;}
.st32{fill:#EF8629;}
.st33{fill:#F4A259;}
.st34{fill:#CB6E24;}

.cursor {
  cursor: pointer;
}

#result-pos:hover .st22, #result-pos.active .st22 {
  fill: rgba(88, 171, 134, 0.5);
}

#result-neg:hover .st25, #result-neg.active .st25 {
  fill: rgba(165, 27, 55, 0.5);
}

#action-def:hover .st29, #action-def.active .st29 {
  fill: rgba(248, 180, 49, 0.5);
}

#action-off:hover .st32, #action-off.active .st32 {
  fill: rgba(239, 134, 41, 0.5);
}

#alto_sx_1_:hover polygon, #alto_sx_1_.active polygon,
#alto_centro_1_:hover polygon, #alto_centro_1_.active polygon,
#alto_dx_1_:hover polygon, #alto_dx_1_.active polygon,
#centro_dx_1_:hover polygon, #centro_dx_1_.active polygon,
#centro_centro_1_:hover polygon, #centro_centro_1_.active polygon,
#centro_sx_1_:hover polygon, #centro_sx_1_.active polygon,
#sotto_dx_1_:hover polygon, #sotto_dx_1_.active polygon,
#sotto_centro_1_:hover polygon, #sotto_centro_1_.active polygon,
#sotto_sx_1_:hover polygon, #sotto_sx_1_.active polygon {
  cursor: pointer;
  opacity: 0.5;
}
</style>
