<template>
	<span>
		<span v-if="interact" @click="doClick">
			<span v-if="notes" class="mr-1">(💬 {{ notes.notes.length ? notes.notes.length : 'Note' }})</span>
			<NoteExplorer :entity="entity" :entityId="entityId" v-if="ux.show" @close="ux.show = false"></NoteExplorer>
		</span>
		<span v-else>
			<span v-if="notes && notes.notes.length" class="mr-1">(💬 {{ notes.notes.length }})</span>
		</span>
	</span>
</template>

<script>

import _ from 'lodash'
var Auth = require('../../../auth.js');

export default {
	props: [ 'entity', 'entityId', 'interact' ],
	components: {
		NoteExplorer: require('./modal-explorer.vue').default,
		NoteForm: require('./form.vue').default,
		Nota: require('./nota.vue').default
	},
	created: function() {

	},
	computed: {
		user: function() {
			return Auth.user;
		}
	},
	watch: {
		entity: {
			deep: true,
			handler: function() {
				this.fetch();
			},
			immediate: true
		}
	},
	methods: {
		doClick: function(event) {
			if(this.interact == true) {
				this.ux.show = !this.ux.show;
			}
		},
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/note/of/' + this.entity + '/' + this.entityId).then(function(data) {
				this.notes = data.data;
			});
		}
	},
	filters: {
		
	},
	data: function() {
		return {
			notes: false,
			ux: {
				show: false,
			}
		}
	}
};

</script>