<template id="rosa">
	<section class="rosa">
		<div class="card" v-if="!isWidget || isFullscreen">
			<div class="card-body">
				<div>
					<b-overlay :show="!dati">
						<div class="mb-4">
							<b-row>
								<b-col cols="4">
									<h3 class="font-bold font-italic m-0" style="text-transform: uppercase; color: #990a2c">Prevenzione <span v-if="datiOk.length">({{ datiOk.length }})</span></h3>
									<label ><input type="checkbox" v-model="ux.hideDisabled" :disabled="activeIndex > 0"/> Gestisci Giocatori Nascosti</label>
									<b-button-group class="float-right" v-if="activeIndex == 0">
										<b-button v-if="ux.hideDisabled" variant="outline-success" size="xs" class="mr-0 " @click.prevent="savePersone(hidePreset)">Aggiorna</b-button>
										<b-button v-if="ux.hideDisabled" variant="outline-warning" size="xs" @click.prevent="svuotaPersone()">Svuota</b-button>
									</b-button-group>
								</b-col>
								<b-col cols="8">
									
									<div class="d-flex justify-content-end align-content-center">
										<div class="mr-1">
											<div class="d-flex" style="min-width: 440px">
												<b-datepicker v-model="ux.from" placeholder="Dal" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
												<b-datepicker v-model="ux.to" placeholder="Al" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
												<b-button @click.prevent="getDashboard" variant="primary" :disabled="!canFilter" size="sm">
													<em class="fa fa-search"></em>
												</b-button>
												<!--<b-button @click.prevent="downloadDati" variant="outline-primary" :disabled="!dati || dati.length == 0" size="sm">
													<em class="fa fa-download"></em>
												</b-button>-->
											</div>
											<div class="text-center mt-1 small font-italic">
												<a href="" @click.prevent="applyPreset('lastWeek')">Ultima Settimana</a>, 
												<a href="" @click.prevent="applyPreset('lastMonth')">Ultimo Mese</a>, 
												<a href="" @click.prevent="applyPreset('lastTrimester')">Ultimo Trimestre</a>
											</div>
										</div>
										<div style="position: relative" v-if="activeIndex == 0">
											<div style="position: absolute; top: -20px; text-transform: uppercase; letter-spacing: 1px; font-weight: bold" class="small text-muted">Ordina per</div>
											<b-button-group>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'overall' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('overall')">
													Overall

													<span v-if="ux.sort == 'overall'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button v-for="area in aree" size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != area ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder(area)">
													{{ area }}

													<span v-if="ux.sort == area">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button variant="outline-danger" class="mr-0" @click.prevent="ux.sort = null" v-if="ux.sort != null" size="sm">&times;</b-button>
											</b-button-group>
										</div>
									</div>

								</b-col>
							</b-row>
						</div>

						<hr />

						<b-tabs v-if="datiOk && datiOk.length" v-model="activeIndex">
							<b-tab title="Overview" @click="selectTab(0)">

								<b-row :style="contentHeight ? 'max-height: ' + (contentHeight - 140) + 'px;overflow:auto;' : ''">

									<b-col cols="4" v-for="persona in datiOk" :key="persona.persona_id" class="mb-4 px-3">	

										<b-card class="h-100" :border-variant="ux.hideDisabled == true ? (( hidePreset.indexOf(persona.persona_id) !== -1 ) ? 'danger':'success') : ''" no-body>

											<div class="p-1 d-flex align-items-center" style="border-bottom: 1px solid rgb(100,100,100); background-color: rgb(240,240,240); border-top-left-radius: 3px; border-top-right-radius: 3px">

												<div class="ml-1 small text-muted">
													<span v-if="persona.ruolo">{{ persona.ruolo }}</span>
												</div>

												<div class="flex-grow-1 text-center">
													<label>
														<input v-if="ux.hideDisabled" type="checkbox" v-model="hidePreset" :value="persona.persona_id" />

														<strong style="color: ; text-transform: uppercase;">
															{{ persona.cognome }}
														</strong>
														<span>
															{{ persona.nome }}
														</span>
													</label>
												</div>

												<div class="mr-1 small text-muted">
													<span v-if="persona.maglia">{{ persona.maglia }}</span>
												</div>
											</div>

											<div class="d-flex">

												<div :style="'min-width: 150px; max-height: 255px; background-image: url('+persona.avatar+'); background-size: cover; background-position: center center'"></div>
												<div class="flex-grow-1 px-1" style="min-height: 255px">
													<div v-for="area in aree" class="mb-1">
														<div class="small">
															<span><strong>{{ area }}</strong></span>
														</div>
														<div v-if="persona.aree.find(item => item.name == area)">

															<b-row no-gutters>
																<b-col :cols="6" :xl="8">
																	<b-progress :max="persona.aree.find(item => item.name == area).programmato" height="1.2rem">
																		<b-progress-bar :variant="persona.aree.find(item => item.name == area).amount == 0 ? 'lightGray' : ''" :value="persona.aree.find(item => item.name == area).amount || persona.aree.find(item => item.name == area).programmato">
																		</b-progress-bar>
																	</b-progress>
																</b-col>
																<b-col :cols="4" class="small font-weight-bold text-center">
																	{{ persona.aree.find(item => item.name == area).amount }}/{{ persona.aree.find(item => item.name == area).programmato }}
																</b-col>
															</b-row>

														</div>
														<div v-else>
															<b-row>
																<b-col cols="8" xl="8">
																	<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
																</b-col>
																<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
															</b-row>
														</div>
													</div>
												</div>

											</div>
											<div class="text-center" style="border-top: 1px solid rgb(100,100,100); position: relative;">
												<b-progress class="overall_progress" :max="100" height="2rem">
													<b-progress-bar style="border-radius: none"  :variant="persona.overall.variant" :value="persona.overall.value"></b-progress-bar>
												</b-progress>

												<div :style="persona.overall.value > 60 ? 'color: white' : ''" style="width: 100%; position: absolute; text-align: center; top: 0; left: 0; height: 2rem; padding-top: 0.2rem; font-size: 110%" class="font-weight-bold">
													<div>{{ persona.overall.value }}<small>%</small></div>
												</div>
											</div>

										</b-card>
									</b-col>
								</b-row>
							</b-tab>
							<b-tab v-for="(area, index) in aree" :title="area" :key="area" @click="selectTab(1 + index)">
								<GraphsTypeA :config = "{ title: area, itemSingular: area, itemPlural: area, itemIdField: 'attivita'}" :dataset="dati.dataset_aree[area]" :contentHeight="contentHeight" :isWidget="isWidget" :isFullscreen="isFullscreen" :area="area" @updateConfigWidget="updateConfigWidget" :configWidget="configWidget"></GraphsTypeA>	
							</b-tab>
						</b-tabs>
						<b-alert v-else-if="dati && dati.players && dati.players.length == 0" variant="warning" show>
							Nessun dato trovato per la <strong>Squadra</strong> e il <strong>Periodo</strong> selezionato.
						</b-alert>
					</b-overlay>
				</div>
			</div>
		</div>

		<b-card no-body v-else>
			<b-table-simple v-if="datiOk && datiOk.length" :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 20) + 'px' : '90vh'" striped hover bordered responsive fixed small>
				<b-thead>
					<b-tr>
						<b-th style="vertical-align: middle;padding: 0; width:190px !important;" sticky-column>Player</b-th>
						<b-th class="text-center" v-for="area in aree" style="vertical-align:middle; padding:0; width:40px !important;" :title="area">{{ area }}</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:120px !important;">Overall</b-th>		
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="persona in datiOk" :key="persona.persona_id">	
						<b-td>	
							<strong style="color: ; text-transform: uppercase;">
								{{ persona.cognome }}
							</strong>
							<span>
								{{ persona.nome }}
							</span>
						</b-td>
						<b-td class="text-center" v-for="area in aree">
							<div v-if="persona.aree.find(item => item.name == area)">
								{{ persona.aree.find(item => item.name == area).amount }}/{{ persona.aree.find(item => item.name == area).programmato }}
							</div>
							<div v-else>
							</div>
						</b-td>
						<b-td>
							<div class="text-center" style="position: relative;">
								<b-progress class="overall_progress" :max="100" height="1.3rem">
									<b-progress-bar style="border-radius: none"  :variant="persona.overall.variant" :value="persona.overall.value"></b-progress-bar>
								</b-progress>

								<div :style="persona.overall.value > 60 ? 'color: white' : ''" style="width: 100%; position: absolute; text-align: center; top: 0; left: 0; height: 1.3rem; padding-top: 0rem; font-size: 100%" class="font-weight-bold">
									<div>{{ persona.overall.value }}<small>%</small></div>
								</div>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-card>


	</section>
</template>

<style type="text/css">

.overall_progress {
	border-radius: 0px !important;
}

.bg-slightGray {
	background-color: #fbfbfb;
}

.bg-lightGray {
	background-color: #e9ecef;
	color: black;
}

</style>

<script>
// fbba00
// 990a2c
var Auth = require('../../../auth.js');
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
export default {
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function(){
		return {
			selected: null,
			i: 0,
			ux: {
				hideDisabled: false,
				sort: this.configWidget && this.configWidget.sortBy ? this.configWidget.sortBy : null,
				order: this.configWidget && this.configWidget.order ? this.configWidget.order : 1,
				to: moment().format('YYYY-MM-DD'),
				from: moment().add(this.getDays(), 'days').format('YYYY-MM-DD'),
			},
			scoped: [],
			expanded: [],
			canFilter: false,
			dati: null,
			hidePreset: null,
			activeIndex: 0,
		}
	},
	computed: {
		aree: function() {

			if(!this.dati) return false;

			return _.values(this.dati.aree);

		},
		glossario: function() {

			if(!this.dati) return false;

			return _.values(this.dati.fasi_glossario);

		},
		datiOk: function() {

			this.i++;
			if(!this.dati) return false;

			if(this.ux.hideDisabled == false) {
				var dati = _.filter(this.dati.players, function(a) {
					return this.hidePreset.indexOf(a.persona_id) == -1;
				}.bind(this));
			} else {
				var dati = this.dati.players;
			}

			if(this.ux.sort == null)
				return dati;

			return dati.sort(function(a,b) {

				if(this.ux.sort == 'overall') {
					return this.ux.order * ((b.overall.value) - (a.overall.value))
				} else {
					var find_a = _.find(a.aree, { name: this.ux.sort });
					var find_b = _.find(b.aree, { name: this.ux.sort });
					return this.ux.order * ((find_b && find_b.amount ? find_b.amount : 0) - (find_a && find_a.amount ? find_a.amount : 0));
				}
			}.bind(this));
		},

		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		'ux.from': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		'ux.to': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		rosaTeamId: {
			immediate: true,
			handler: function() {
				this.getDashboard();
			}
		},
		'ux.sort': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'sortBy', this.ux.sort);
			}
		},
		'ux.order': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'order', this.ux.order);
			}	
		},
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},
	},
	components: {
		'GraphsTypeA': require('@/components/layout/reports/GraphsTypeA.vue').default,
	},
	created: function () {
		if(this.isWidget) {
			if(this.configWidget && this.configWidget.hidePlayers) {
				this.hidePreset = this.configWidget.hidePlayers;
			} else {
				this.hidePreset = [];
			}
		} else if(this.user.presets().prevenzioneDashboardHide != undefined) {
			this.hidePreset = _.cloneDeep(this.user.presets().prevenzioneDashboardHide)
		} else {
			this.hidePreset = [];
		}
	},
	filters: {
		duration: function(seconds) {
			return new Date(parseFloat(seconds*1000)).toISOString().slice(11, 19);
		},
		data: function(i, format) {
			return moment(i).format(format || 'D/M/YY HH:mm')
		}
	},
	methods: {
		selectTab(index) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'activeTab', index);
			}
		},
		listKpi: function(persona) {

			if(this.scoped.length > 0) {

				return _.filter(persona.kpi, function(a) {

					var is = _.find(this.scoped, function(b) {
						return b.tipologia_fase == 'Fase_' + a.area && b.nome == a.kpi;
					}.bind(this));

					if(is)
						return true;
					return false;

				}.bind(this));

			}

			if(this.expanded.indexOf(persona.persona_id) !== -1)
				return persona.kpi;
			else return _.chunk(persona.kpi, 5)[0];
		},
		refreshData: function() {
			this.getDashboard();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		svuotaPersone: function() {
			this.hidePreset = [];
			this.savePersone(this.hidePreset);
		},
		savePersone: function(persone) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'hidePlayers', persone);
				this.ux.hideDisabled = false;
			} else {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'prevenzioneDashboardHide', persone: persone }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.ux.hideDisabled = false;
				});
			}
		},
		downloadDati: function() {

			var url = [this.$store.state.apiEndPoint + '/atlas/dashboard/recovery/csv/' + this.user.user.id + '/' + this.user.user.api_token];

			url.push('?from=' + this.ux.from);
			url.push('&to=' + this.ux.to);
			url.push('&team_id=' + this.rosaTeamId);

			window.open(url.join(''));

		},
		test: function(e) {
			$(e.target).fadeIn();
		},
		applyPreset: function(period) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'period', period);
			}
			var days = -7;
			if(period == 'lastWeek') {
				days = -7;
			} else if(period == 'lastMonth') {
				days = -7*4;
			} else if(period == 'lastTrimester') {
				days = -7*4*3;
			}

			this.ux.from = moment().add(days, 'days').format('YYYY-MM-DD');
			this.ux.to = moment().format('YYYY-MM-DD');

			this.getDashboard();

		},
		setOrder: function(order) {

			if(this.ux.sort == order) {
				this.ux.order *= -1;
			} else
			this.ux.order = 1;

			this.ux.sort = order;
			$('.face').fadeOut();
		},
		getDashboard: function() {

			this.dati = null;
			this.canFilter = null;
			this.expanded = [];
			this.activeIndex = 0;

			this.$http.get(this.$store.state.apiEndPoint + '/atlas/reports/prevenzione', { params: {
				team_id: this.rosaTeamId,
				from: this.ux.from,
				to: this.ux.to 
			}}).then(function(data) {
				this.dati = data.data;
				_.delay(function() { this.setTabActive(); }.bind(this), 100);
			});

		},
		setTabActive() {
			this.activeIndex = this.configWidget && this.configWidget.activeTab != undefined ? this.configWidget.activeTab : 0;
		},
		getDays: function() {

		  	var days = -7;
			if(this.configWidget) {
				if(this.configWidget.period) {
					var days = -7;
					if(this.configWidget.period == 'lastWeek') {
						days = -7;
					} else if(this.configWidget.period == 'lastMonth') {
						days = -7*4;
					} else if(this.configWidget.period == 'lastTrimester') {
						days = -7*4*3;
					}
				}
			}
			return days;
		},
		updateConfigWidget: function(id, key, value) {
			this.$emit('updateConfigWidget', id, key, value);
		}
	}
}

</script>