<template>
	<div></div>
</template>

<script>

export default {
	props: [ 'option', 'contentHeight' ],
	data: function() {
		return {
			chart: null
		}
	},
	watch: {
		'option': {
			deep: true,
			handler: function() {
				this.generateGraph();
			}
		},
		contentHeight() {
			this.chart.resize();
		},
	},
	mounted: function() {
		this.generateGraph();
	},
	methods: {
		generateGraph: function() {

			if(this.chart == null) {
				var chartDom = this.$el;
				var eChart = echarts.init(chartDom);
				this.chart = eChart;
			} else {
				eChart = this.chart;
			}
			
			eChart.setOption(this.option);

			eChart.on('click', function (params) {
				this.$emit('onClick', params);	
			}.bind(this));
		}
	}
}

</script>