<template id="editor">
	<section class="editor">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

            <menusub></menusub>

			<div class="card-deck">
				<card comp="palestraesercizieditor" :params="{ titolo: 'Nuovo esercizio' }"></card>
			</div>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
export default {

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
        menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		card:               require('../../../layout/card.vue').default,
	}

}
</script>
