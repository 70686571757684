<template>
	<section>
		<div class="container-fluid m-y-1">
			<div class="card">
				<div class="card-body py-1">
					<div class="content">
						<div>
							<b-sidebar id="sidebar-right" title="Fasi Sessione" right shadow v-model="visible">
								<div class="px-3 py-2" v-if="sessione">
									<b-row v-for="fase, index in sessione.fasi" class="mb-1">
										<b-col>
											<b-card>
												<template #header>
													<b-row align-v="center">
														<b-col>
															<h5 class="mb-0 color-rosso">{{ (index + 1) + ' - ' + fase.nome }}</h5>
														</b-col>
														<b-col class="text-right" cols="1">
															<b-button size="sm" variant="primary" @click="playFase(fase)" title="Play"><i class="fa fa-play" aria-hidden="true"></i></b-button>
														</b-col>
													</b-row>
												</template>
												<div v-if="fase.entity.sorgente == 'videocartella' && fase.entity.videocartella">
													<b-card no-body :class="['mb-1 p-2', fase.entity.videocartella.riassunto ? 'riassunto-' + fase.entity.videocartella.riassunto : '' ]">
														<b-row>
															<b-col cols="11" v-b-toggle="'cartella_' + fase.entity.videocartella_id" style="cursor:move">
																<div>{{ fase.entity.videocartella.nome }}</div>
																<div><small>{{ durataCartella(fase.entity.videocartella) }}</small></div>
																<div><small>{{ fase.entity.videocartella.utente.nomeCompleto }}</small></div>
															</b-col>
															<b-col v-b-toggle="'cartella_' + fase.entity.videocartella_id">
																<i class="when-closed fa fa-caret-right"></i>
																<i class="when-opened fa fa-caret-down"></i>
															</b-col>
														</b-row>
														<b-collapse :id="'cartella_' + fase.entity.videocartella_id">
															<div v-for="(videotag, idx) in fase.entity.videocartella.videotags" :key="'cvt_' + videotag.id">
																<b-card no-body :class="['mb-1 p-2 default', videotag.peso ]">
																	<b-row>
																		<b-col cols="1" @click="selectClips(idx, fase)">
																			<i class="fa fa-play" aria-hidden="true"></i>
																		</b-col>
																		<b-col cols="7" @click="selectClips(idx, fase)">
																			<small>{{ videotag.inizio }} - {{ videotag.fine }}</small>
																		</b-col>
																		<b-col>
																			<i title="preferiti" class="fa fa-heart" aria-hidden="true" v-if="videotag.preferiti"></i>
																		</b-col>
																		<b-col>
																			<i v-b-tooltip.hover :title="videotag.nota" class="fa fa-info-circle" aria-hidden="true" v-if="videotag.nota"></i>
																		</b-col>
																	</b-row>
																</b-card>
															</div>
														</b-collapse>
													</b-card>
												</div>
												<div v-if="fase.entity.sorgente == 'upload' && fase.entity.video">
													<b-img :src="fase.entity.video.thumb ? fase.entity.video.thumb : '/assets/varie/missing.jpg'" fluid @click="playFase(fase)" style="cursor:pointer"></b-img>
													<div class="small">
														<div class="font-bold">{{ getTitle(fase.entity.video) }}</div>
														<div>Caricato da <span class="color-rosso font-bold"> {{fase.entity.video.utente.nomeCompleto}} </span></div>
														<div>il <span class="font-italic"> {{ fase.entity.video.data | formatDateHour}} </span></div>
														<div>Sorgente: <span class="font-bold"> {{ fase.entity.video.tipo }} </span></div>
													</div>
												</div>
												<div v-if="fase.entity.sorgente == 'filebrowser' && fase.entity.filebrowser_url">
													<b-img src="/assets/varie/missing.jpg" fluid @click="playFase(fase)" style="cursor:pointer"></b-img>
													<div class="small">
														<div class="font-bold">{{ fase.entity.filebrowser_path }}</div>
													</div>
												</div>
												<div v-if="fase.entity.sorgente == 'upload' && fase.entity.documento_rel">
													<b-img src="/assets/varie/missing.jpg" fluid @click="playFase(fase)" style="cursor:pointer"></b-img>
													<div class="small">
														<div class="font-bold">{{ fase.entity.documento_rel.documento.nome ? fase.entity.documento_rel.documento.nome : 'Documento' }}</div>
														<div><span class="color-rosso font-bold"> {{ fase.entity.documento_rel.documento.path }} </span></div>
													</div>
												</div>
											</b-card>
										</b-col>
									</b-row>
								</div>
							</b-sidebar>
						</div>

						
						<div class="pres-video-container">
							
							<div class="pres-video-col1">
								<b-row class="mb-2">
									<b-col cols="12" class="mb-1">
										<b-button size="sm" variant="outline-primary" v-if="auth.isUserEnable('video_sessioni')" :to="{ name: $route.meta.module + '_sessione', params: { id: $route.params.id, tipo: $route.params.tipo }}" style="width:80%"><i class="fa fa-sign-out" aria-hidden="true"></i></b-button>
									</b-col>

									<b-col cols="12" class="mb-1">
										<b-button size="sm" aria-controls="sidebar-right" :aria-expanded="visible ? 'true' : 'false'" @click="switchVisible" :title="visible ? 'Nascondi fasi' : 'Mostra fasi'" style="width:80%"><i :class="visible ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i></b-button>
									</b-col>
									<b-col cols="12" class="mb-1">
										<b-button size="sm" :variant="editor ? 'success' : 'outline-secondary'" @click="editor = !editor" :title="editor ? 'Esci dalla modalità disegno' : 'Entra in modalità disegno'" style="width:80%"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></b-button>
									</b-col>
								</b-row>
								<b-row v-if="editor" class="my-5">
									<b-col cols="12" class="mb-1">
										<b-form-input v-if="editor" type="color" v-model="color" :title="'Colore: ' + color" style="height:30px;width:80%"></b-form-input>
									</b-col>
									<b-col cols="12" class="mb-1">
										<b-form-input :title="'Spessore linea: ' + line_width" type="range" v-model="line_width" min="1" max="10" class="p-0" style="width:80%"></b-form-input>
									</b-col>
									<b-col cols="12" class="mb-1">
										<b-button size="sm" variant="danger" @click="clearCanvas" style="width:80%">
											<i class="fa fa-trash" aria-hidden="true"></i>
										</b-button>	
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-pagination v-show="showVideo && playlist.length > 1" size="sm" align="center" :total-rows="playlist.length" v-model="count" :per-page="1" @change="selectPageClips" :limit="40" class="pagination-vertical">
											<template #page="{ page }">	
												clip {{ page }}
											</template>
										</b-pagination>
										<b-pagination v-show="showPdf && totalPagesPdf > 1" size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPagePdf" :per-page="1" @change="showPage" :limit="20" class="pagination-vertical">
											<template #page="{ page }">	
												pag {{ page }}
											</template>
										</b-pagination>
									</b-col>
								</b-row>

							</div>
							
							<div class="pres-video-col2">

								<div id="container">
									<div class="overlayDiv" v-show="editor"> 
										<canvas
										id="canvas"
										style="width:100%; height: 100%;"
										/>
									</div>
									<div v-show="showVideo">
										<video
										id="video-wrapper"
										class="video-js vjs-default-skin vjs-16-9"
										controls
										data-setup="{}">
									</video>
								</div>
								<div id="img-container" v-if="showImage" class="media-container">
									<b-img :src="image_url" fluid></b-img>
								</div>
								<div id="pdf-container" v-if="showPdf && pdfDoc" class="media-container">
									<canvas id="pdf-canvas" width="1000"></canvas>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</section>
</template>

<script>
var Auth = require('@/auth.js');
import axios from 'axios';
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-playlist';
import fabric from '@/lib/fabric';
export default {
	data: function() {
		return {
			sessione: null,
			fase_id: null,
			visible: true,
			titoloClips: '',
			count: 0,
			playlist: [],
			editor: false,
			color: '#000000',
			line_width: 2,
			
			showVideo: true,
			showImage: false,
			showPdf: false,

			pdfDoc: null,
			totalPagesPdf: 0,
			currentPagePdf: 0,

			image_url: '',
			file: null,
		}
	},
	created: function () {
		this.getSessione();
	},
	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		VideoManager: require('@/components/layout/video/manager.vue').default,
	},
	methods: {
		getSessione() {
			if(this.$route.params.id) {
				axios.get(`/sessione/` + this.$route.params.id)
				.then((res) => {
					this.sessione = res.data;
					if(this.sessione.fasi.length > 0) {
						this.playFase(this.sessione.fasi[0]);
					}
				}).catch((err) => {
				});
			}
		},

		switchVisible() {
			this.visible = !this.visible;
		},

		durataCartella(cartella) {
			var time = 0;
			for(var x in cartella.videotags) {
				var videotag = cartella.videotags[x];
				time = time + (moment(videotag.fine, "HH:mm:ss") - moment(videotag.inizio, "HH:mm:ss"));
			}
			return moment.utc(time).format("HH:mm:ss");
		},
		selectClips(index, fase) {
			if(fase.id != this.fase_id) {
				this.fase_id = fase.id;
				if(this.$video) {
					this.$video.playlist([]);
				}
			}
			var titolo = '';
			var clips = [];
			var video = null;
			var video_url = null;
			this.count = 1;
			this.playlist = [];
			if(fase.entity.sorgente == 'videocartella') {
				titolo = fase.entity.videocartella.nome + ' di ' + fase.entity.videocartella.utente.nomeCompleto;
				clips = fase.entity.videocartella.videotags;
			} else if (fase.entity.sorgente == 'upload') {
				titolo = this.getTitle(fase.entity.video) + ' di ' + fase.entity.video.utente.nomeCompleto;
				video = fase.entity.video;
			} else if (fase.entity.sorgente == 'filebrowser') {
				titolo =fase.entity.filebrowser_path;
				video_url = fase.entity.filebrowser_url;
			}
			this.titoloClips = titolo;
			if(video_url) {
				var source = {
					sources: [{
						src: video_url,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			} else if(video) {
				var source = {
					sources: [{
						src: video.video,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			} else {
				for(var i in clips) {
					var clip = clips[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + clip.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
				this.count = index + 1;
			}

			this.onShowClips();
		},
		onShowClips() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
				this.$video.on('playlistitem', this.autoSelectPage);
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			if(this.count > 1) {
				this.selectPageClips(this.count);
			}
			this.$video.play();
		},
		selectPageClips(page) {
			this.$video.playlist.currentItem(page - 1);    
		},
		autoSelectPage() {
			this.count = (this.$video.playlist.currentIndex() + 1);
		},
		clearCanvas() {
			this.$canvas.clear();
		},
		playFase(fase) {
			if((fase.entity.sorgente == 'videocartella' && fase.entity.videocartella && fase.entity.videocartella.videotags.length > 0) || (fase.entity.sorgente == 'upload' && fase.entity.video) || (fase.entity.sorgente == 'filebrowser' && fase.entity.filebrowser_url && !fase.entity.filebrowser_mimetype)) {
				this.showImage = false;
				this.showPdf = false;
				this.showVideo = true;
				this.selectClips(0, fase);
			} else if(fase.entity.sorgente == 'filebrowser' && fase.entity.filebrowser_url && fase.entity.filebrowser_mimetype) {
				if(fase.entity.filebrowser_mimetype.includes('image/')) {
					this.image_url = fase.entity.filebrowser_url;
					this.showImage = true;
					this.showPdf = false;
					if(this.showVideo) {
						if(this.$video) {
							this.$video.pause();
							this.$video = null;
						}
						this.showVideo = false;
					}
				} else if(fase.entity.filebrowser_mimetype.includes('application/pdf')) {
					PDFJS.getDocument(fase.entity.filebrowser_url).then((pdf_doc) => {
						this.totalPagesPdf = pdf_doc.numPages;
						this.pdfDoc = pdf_doc;
						this.currentPagePdf = 0;
						this.showPage(1);
					});
					this.showImage = false;
					this.showPdf = true;
					if(this.showVideo) {
						if(this.$video) {
							this.$video.pause();
							this.$video = null;
						}
						this.showVideo = false;
					}
				}
			} else if(fase.entity.sorgente == 'upload' && fase.entity.documento_rel) {
				this.getFile(fase);
			}
			this.visible = false;
		},
		getTitle(video) {
			var title = "";
			if(video.titolo != "") {
				title = video.titolo;
			} else {
				title = this.auth.isAdmin() ? video.video : video.file;
			}
			return title;
		},
		showPage(page_no) {
			this.currentPagePdf = page_no;
			this.pdfDoc.getPage(page_no).then((page) => {
				var desiredWidth = document.getElementById('pdf-canvas').width;
				var viewport = page.getViewport(1);
				var scale = desiredWidth / viewport.width;
				var scaledViewport = page.getViewport(scale);
				document.getElementById('pdf-canvas').height = scaledViewport.height;
				var renderContext = {
					canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
					viewport: scaledViewport
				};
				page.render(renderContext).then(() => {
				});
			});
		},

		prevPage() {
			if(this.currentPagePdf != 1) {
				this.showPage(this.currentPagePdf - 1);
			}    
		},

		nextPage() {
			if(this.currentPagePdf != this.totalPagesPdf) {
				this.showPage(this.currentPagePdf + 1);
			}    
		},
		getFile(fase) {
			if(fase.entity.documento_rel) {
				this.$http.get(this.$store.state.apiEndPoint + '/documenti/download/' + fase.entity.documento_rel.id + '/' + this.user.id + '/' + this.user.api_token).then((response) => {
					if (response.ok)
					{
						this.file = response;
						if(this.isFilePdf) {
							PDFJS.getDocument(this.file.url).then((pdf_doc) => {
								this.totalPagesPdf = pdf_doc.numPages;
								this.pdfDoc = pdf_doc;
								this.currentPagePdf = 0;
								this.showPage(1);
							});
							this.showImage = false;
							this.showPdf = true;
							if(this.showVideo) {
								if(this.$video) {
									this.$video.pause();
									this.$video = null;
								}
								this.showVideo = false;
							}
						} else if(this.isFileImage) {
							this.image_url = this.file.url;
							this.showImage = true;
							this.showPdf = false;
							if(this.showVideo) {
								if(this.$video) {
									this.$video.pause();
									this.$video = null;
								}
								this.showVideo = false;
							}

						}					
					}
				}, (response) => {

				});
			}
		},
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		isFileImage: function() {
			if(this.contentType.includes('image/')) {
				return true;
			}
			return false;	
		},
		isFilePdf: function() {
			if(this.contentType.includes('application/pdf')) {
				return true;
			}
			return false;	
		},
		contentType: function() {
			if(this.file) {
				var mimetype = this.file.headers.map['content-type'][0];
				return mimetype;
			}
			return '';	
		},
	},

	watch: {
		color: function () {
			this.$canvas.freeDrawingBrush.color = this.color;	
		},

		line_width: function () {
			this.$canvas.freeDrawingBrush.width = parseInt(this.line_width, 10);	
		},

		editor: function() {
			if(this.editor) {
				var w = 0;
				var h = 0;
				if(this.$video) {
					this.$video.pause();
				}
				if(this.showVideo) {
					w = this.$video.currentWidth();
					h = this.$video.currentHeight();
				} else if(this.showPdf) {
					w = document.getElementById('pdf-container').offsetWidth;
					h = document.getElementById('pdf-container').offsetHeight;
					console.log(w);
					console.log(h);
				} else if(this.showImage) {
					w = document.getElementById('img-container').offsetWidth;
					h = document.getElementById('img-container').offsetHeight;
				}
				this.$canvas = new fabric.Canvas('canvas', {containerClass: 'my-canvas-container'});
				this.$canvas.setHeight(h);
				this.$canvas.setWidth(w);
				this.$canvas.isDrawingMode = true;
				this.$canvas.freeDrawingBrush.color = this.color;
				this.$canvas.freeDrawingBrush.width = parseInt(this.line_width, 10);
			} else {
				this.$canvas.dispose();
				if(this.$video) {
					this.$video.play();
				}
			}
		},

	},

	mounted() {
		if(!this.$video) {
			this.$video = videojs('video-wrapper');
		}
	},
	beforeDestroy() {
		this.$video.dispose();
		this.$video = null;
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},		
	},
}
</script>
<style scoped>
.overlayDiv {
	text-align: center;
	margin: 0px auto;
	background: rgba(255, 255, 255, 0);
	position: absolute;
	width:100%;
	height: 100%;
	z-index:10001;
}
</style>
