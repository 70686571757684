<template id="costi">
	<section class="osservatori">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>{{ osservatore.nomeCompleto }}</span>
					</div>
					<div class="content">
						<b-row>
							<b-col cols="3">
								<b-form-group horizontal label="Periodo dal" label-cols="4">
									<b-form-input type="date" v-model="filters.dal"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="3">
								<b-form-group horizontal label="al" label-cols="3" label-text-align="right">
									<b-form-input type="date" v-model="filters.al"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="mb-3">
							<b-col cols="1">
								Partenza da:
							</b-col>
							<b-col>
								<b-form-radio-group  v-model="partenza">
									<b-form-radio value="trigoria">Trigoria</b-form-radio>
									<b-form-radio value="casa" v-if="osservatore.indirizzo && osservatore.indirizzo != ''"> {{ osservatore.indirizzo }} </b-form-radio>
								</b-form-radio-group>
							</b-col>
						</b-row>
						<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
						<b-row v-if="!loading">
							<b-col>
								<h5>Partite: {{ items_partite.length }}</h5>
								<b-table striped hover :items="items_partite" :fields="fields_partite" ref="partiteTable">
									<template #cell(sigla)="data">
										<flag :country="data.value" :size="32"></flag>
									</template>
									<template #cell(from_prec)="row">
										<b-form-checkbox v-if="row.index > 0"
											v-model="from_prec_partite"
											:value="row.item.id"
											>
											Destinazione precedente
										</b-form-checkbox>
									</template>
									<!--template slot="azioni" slot-scope="row">
										<b-button size="sm" variant="primary" title="Visualizza percorso su mappa" @click="displayRoute(row.item.id)">
											<i class="fa fa-map-marker" aria-hidden="true"></i>
										</b-button>
									</template-->
									<template #cell(marker)="data">
										<span style="height: 25px;
										width: 25px;
										background-color: #ff0000;
										border-radius: 50%;
										display: inline-block;
										text-align:center;
										cursor:pointer;" v-if="data.value != ''" @click="centerMap(data.value)"> {{ data.value }}</span>
								</template>
								<template #cell(HEAD_marker)="data">
									<i class="fa fa-map-marker" aria-hidden="true"></i>
								</template>
							</b-table>
							<h4 class="text-right mt-2"> Totale: {{ km_totali }} km </h4>
							<b-button variant="success" @click="calcolaDistanze">Calcola km</b-button>
							<b-button variant="primary" @click="salvaKilometri">Salva</b-button>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col cols="12">
							<div id="map" ref="map" style="height: 600px;"></div>	
						</b-col>
							<!--b-col cols="5">
								<div ref="panel" style="max-height: 500px; overflow-y: scroll;"></div>	
							</b-col-->
						</b-row>
					</div>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

import moment from 'moment';
import * as types from '../../../store/types';
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		flag: 				require('../../layout/flag.vue').default,
	},
	data: function(){
		return {
			osservatore: {},
			loading: false,
			filters: {
				dal: moment().startOf('month').format('YYYY-MM-DD'), 
				al: moment().endOf('month').format('YYYY-MM-DD'),
			},
			fields_partite: [
			{ key: 'sigla', label: ''},
			{ key: 'paese', label: 'Paese'},
			{ key: 'competizione', label: 'Competizione'},
			{ key: 'partita', label: 'Partita'},
			{ key: 'data', label: 'Data'},
			{ key: 'from_prec', label: 'Parti da'},
			{ key: 'origine', label: 'Origine'},
			{ key: 'destinazione', label: 'Destinazione'},
			{ key: 'km', label: 'Km'},
			{ key: 'marker', label: ''},
			],
			items_partite: [],
			km_totali: 0,
			partenza: 'trigoria',
			address_trigoria: 'Piazzale Dino Viola, Roma, RM',
			from_prec_partite: [],
			markers: [],
			labels: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
			labelIndex: 0,

		}
	},
	created: function () {
		this.getPartiteLive();      
	},
	methods: {

		getPartiteLive() {
			if(!this.filters.dal || !this.filters.al) {
				return;
			}
			this.loading = true;
			this.items_partite = [];
			this.from_prec_partite = [];
			this.km_totali = 0;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/osservatore/partite/live/' + this.$route.params.id, {params: this.filters}).then((response) => {
				if (response.ok)
				{
					this.osservatore = response.data; 
					var partite = this.osservatore.partite; 
					for(var i in partite) {
						var partita = partite[i];
						var km = 0;
						var origine = this.address_trigoria;
						var destinazione = partita.stadio ? partita.stadio.nome : '';
						if(partita.kilometri.length > 0) {
							km = partita.kilometri[0].km;
							origine = partita.kilometri[0].origine;
							destinazione = partita.kilometri[0].destinazione;
							if(partita.kilometri[0].from_prec_dest) {
								this.from_prec_partite.push(partita.id);
							}
						}
						var item = {
							id: partita.id,
							sigla: partita.competizione.paese.sigla,
							paese: partita.competizione.paese.paese,
							competizione: partita.competizione.nome,
							partita: this.getLabelPartita(partita),
							data: moment(partita.data).format("DD/MM/YYYY HH:mm"),
							origine: origine,
							destinazione: destinazione,
							stadio: partita.stadio,
							origine_index: 0,
							km: km,
							marker: '',			
						};
						this.items_partite.push(item);
						this.km_totali += km; 
					}
					this.loading = false;
				}
			}, (response) => {
            // error callback
        });
		},	
		getLabelPartita(partita) {
			var label = '';
			var squadre = partita.squadre;
			if(squadre[0].pivot.side == 'home') {
				label = squadre[0].officialName + " - " + squadre[1].officialName;
			} else {
				label = squadre[1].officialName + " - " + squadre[0].officialName;
			}
			return label;
		},

		calcolaDistanze() {
			this.clearMarkers();
			if(this.items_partite.length == 0) {
				return;
			}
			this.km_totali = 0;
			var origini = [];
			var destinazioni = [];
			for(var i in this.items_partite) {
				var partita = this.items_partite[i];
				if(i == 0) {
					origini.push(partita.origine);
					partita.origine_index = 0;
				} else {	
					if(this.from_prec_partite.indexOf(partita.id) > -1) {
						var partita_prec = this.items_partite[i - 1];
						if(partita_prec.stadio && partita_prec.stadio.lat && partita_prec.stadio.lng) {
							var origine = new window.google.maps.LatLng(partita_prec.stadio.lat, partita_prec.stadio.lng);
							origini.push(origine);
							partita.origine_index = origini.length - 1;
						}
					} else {
						partita.origine_index = 0;
					}
				}
				var destinazione = '';
				if(partita.stadio && partita.stadio.lat && partita.stadio.lng) {
					destinazione = new window.google.maps.LatLng(partita.stadio.lat, partita.stadio.lng);

					var label = this.labels[this.labelIndex++ % this.labels.length];

					partita.marker = label;

					this.$marker = new window.google.maps.Marker({
						position: destinazione,
						map: this.$map, 
						title: partita.stadio.nome, 
						label: label,
					});
					this.markers.push(this.$marker);
				}
				destinazioni.push(destinazione);
			}
			var self = this;
			this.$service = new window.google.maps.DistanceMatrixService();
			this.$service.getDistanceMatrix({
				origins: origini,
				destinations: destinazioni,
				travelMode: google.maps.TravelMode.DRIVING,
				unitSystem: google.maps.UnitSystem.METRIC
			}, function(response, status) {
				if (status !== 'OK') {
					alert("ERRORE: " + status);
				} else {
					console.log(response);
					for(var i in self.items_partite) {
						var elem = response.rows[self.items_partite[i].origine_index].elements[i];
						if(elem.status == 'OK') {
							var km = Math.round(elem.distance.value / 1000);
							var index = i;
							index++;
							if(self.items_partite.length > index) {
								if(self.items_partite[index].origine_index == 0) {
									var elem_ritorno = response.rows[0].elements[i];
									if(elem_ritorno.status == 'OK') {
										var km_ritorno = Math.round(elem_ritorno.distance.value / 1000);
										km = km + km_ritorno;
									}
								}
							} else {
								var elem_ritorno = response.rows[0].elements[i];
								if(elem_ritorno.status == 'OK') {
									var km_ritorno = Math.round(elem_ritorno.distance.value / 1000);
									km = km + km_ritorno;
								}
							}
							self.items_partite[i].km = km;
							self.km_totali = self.km_totali + km;
						} else {
							self.items_partite[i].km = 0;
						}
					}
				}
			});
		},
		salvaKilometri() {
			if(this.items_partite.length == 0) {
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/osservatore/salva/kilometri/' + this.$route.params.id, this.items_partite).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getPartiteLive();
				}
			}, (response) => {
            // error callback
        });
		}, 

		clearMarkers() {
			for(var i in this.markers) {
				this.markers[i].setMap(null);
			}
			this.markers = [];
			this.labelIndex = 0;
			this.$map.panTo({lat: 41.871940, lng: 12.567380});
			this.$map.setZoom(4);
		},

		centerMap(label) {
			for(var i in this.markers) {
				if(this.markers[i].label == label) {
					this.$map.panTo(this.markers[i].getPosition());
					this.$map.setZoom(10);
					return;
				}
			}
		},
		/*
		displayRoute(id) {
			var partita = null;
			for(var i in this.items_partite) {
				if(this.items_partite[i].id == id) {
					partita = this.items_partite[i];
					break;
				}
			}
			var origine = '';
			var destinazione = '';
			if(i > 0 && partita.origine_index > 0) {
				var partita_prec = this.items_partite[i - 1];
				if(partita_prec.stadio && partita_prec.stadio.lat && partita_prec.stadio.lng) {
					origine = new window.google.maps.LatLng(partita_prec.stadio.lat, partita_prec.stadio.lng);
				}
			} else {
				origine = partita.origine;
			}
			if(partita.stadio && partita.stadio.lat && partita.stadio.lng) {
				destinazione = new window.google.maps.LatLng(partita.stadio.lat, partita.stadio.lng);
			}

			if(origine != '' && destinazione != '') {
				var self = this;
				this.$directionsService.route({
					origin: origine,
					destination: destinazione,
					waypoints: [],
					travelMode: 'DRIVING',
				}, function(response, status) {
					if (status === 'OK') {
						self.$directionsDisplay.setDirections(response);
					} else {
						alert('Could not display directions due to: ' + status);
					}
				});
			}
		}*/
	}, 
	watch: {
		'filters.dal': function () {
			this.getPartiteLive();
		},
		'filters.al': function () {
			this.getPartiteLive();
		},
		partenza: function () {
			if(this.items_partite.length > 0) {
				for(var i in this.items_partite) {
					var partita = this.items_partite[i];
					if(this.from_prec_partite.indexOf(partita.id) == -1) {
						if(this.partenza == 'trigoria') {
							partita.origine = this.address_trigoria;
						} else {
							partita.origine = this.osservatore.indirizzo;
						}
					}
				}
			}
		},
		from_prec_partite: function () {
			if(this.items_partite.length > 0) {
				for(var i in this.items_partite) {
					var partita = this.items_partite[i];
					if(this.from_prec_partite.indexOf(partita.id) == -1) {
						if(this.partenza == 'trigoria') {
							partita.origine = this.address_trigoria;
						} else {
							partita.origine = this.osservatore.indirizzo;
						}
					} else {
						var partita_prec = this.items_partite[i - 1];
						partita.origine = partita_prec.stadio ? partita_prec.stadio.nome : '';
					}
				}
			}
		},
	},
	mounted() {

		this.$map = new window.google.maps.Map(this.$refs.map, {
			zoom: 4,
			center: { lat: 41.871940, lng: 12.567380 },
			streetViewControl: false,
			mapTypeControl: false,
		});

	/*	this.$directionsService = new window.google.maps.DirectionsService;
		this.$directionsDisplay = new window.google.maps.DirectionsRenderer({
			draggable: false,
			map: this.$map,
			panel: this.$refs.panel,
		});  */

	}

}
</script>
