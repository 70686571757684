<template>
	<div class="my-2">

		<b-row>
			<fase-glossario-dati-single v-for="item in items" :item="item"></fase-glossario-dati-single>		
		</b-row>

	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

import FaseGlossarioDatiSingle from '@/components/layout/Sessioni/Generica/FaseGlossarioDatiSingle.vue';

export default {
	name: 'Fase_Glossario_Dati',
	props: [ 'items' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	components: {
		FaseGlossarioDatiSingle
	},
}
</script>