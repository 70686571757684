<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="user.academy">
				<div class="card-body p-3">
					<div class="row flex-items-xs-middle">
						<div class="col-5">
							<div class="media">
								<img v-bind:src="user.academy.logo_url" class="" height="80">
								<div class="media-body align-self-center ml-3">
									<h3 class="text-primary mb-0"><strong>{{user.academy.nome}}</strong></h3>
								</div>
							</div>
						</div>
						<div class="col-7 text-right">
							<ul class="list-inline mb-0">
								<li class="list-inline-item"><b-button :to="{ name: 'academy_ita_pdf_archivio' }" variant="outline-primary">Archivio Documenti</b-button></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="card-deck">
				
				<div class="card">
					<div class="card-body">
						<div class="card-title">
							<span>{{ titolo }}</span>
						</div>
						<div class="content">
							<b-row>
								<b-col>
									<div v-if="loading" align="center" style="width:100%">
										<img src="/assets/varie/loading.gif" style="width: 150px;" />
									</div>
									<div class="text-center" v-if="!loading && pdfDoc">
										<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPagePdf" :per-page="1" @change="showPage" :limit="20">
										</b-pagination>
										<canvas id="pdf-canvas" width="650"></canvas>
										<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPagePdf" :per-page="1" @change="showPage" :limit="20">
										</b-pagination>
									</div>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="card-title">
							<span>Esercizi Recenti</span>
						</div>
						<div class="content">
							
							<div class="row">
								<div class="col-md-4 col-lg-4 col-xl-4 mb-4" v-for="ex in exercises.data.length > 12 ? exercises.data.slice(0, 12) : exercises.data">
									<esercizicard :ex="ex" :skills="skills(ex)" :skills_portiere="skills_portiere(ex)"></esercizicard>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>	

		</div>

		<footerapp></footerapp>

	</section>
</template>


<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import axios from 'axios';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		esercizicard: 		require('../../layout/esercizi_academy/esercizicard.vue').default,
	},

	data: function(){
		return {
			pdf: null,
			pdfDoc: null,
			totalPagesPdf: 0,
			currentPagePdf: 0,
			loading: false,
		}
	},

	computed: {
		user: function() {
			return Auth.user;
		},
		...mapState(['academyCatId']),
		...mapState('acad_esercizi', ['exercises']),
		titolo: function() {
			if(this.pdf && this.pdf.hasOwnProperty('titolo') && this.pdf.titolo != '') {
				return this.pdf.titolo;
			}
			return "Programma";
		}
	},
	watch: {
		academyCatId() {
			this.$store.commit('acad_esercizi/setPage', 1);
			this.$store.dispatch('acad_esercizi/filter');
			this.getPdfAttivoByCategoria();	
		},  
	},

	created() {
		this.$store.commit('acad_esercizi/setAcademy', 0);
		this.$store.commit('acad_esercizi/setPage', 1);
		this.$store.dispatch('acad_esercizi/filter');
		this.getPdfAttivoByCategoria();
	},
	methods: {
		getPdfAttivoByCategoria() {
			this.loading = true;
			if(this.academyCatId){
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/pdf/attivo/by/categoria/' + this.academyCatId + '/0').then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.pdf = response.data;
	            	if(this.pdf.hasOwnProperty('document') && this.pdf.document != '') {
	            		this.viewPdf(this.pdf.document);
	            	} else {
	            		this.pdfDoc = null;
	            		this.totalPagesPdf = 0;
	            		this.currentPagePdf = 0;
	            		this.loading = false;
	            	}
	            }
	        }, (response) => {
		            // error callback
		        });
			}
		},
		viewPdf: function(pdf) {
			this.$http.get(this.$store.state.apiEndPoint + '/pdfacademy/' + pdf).then((response) => {
				if (response.ok)
				{
					var pdf_data = atob(response.data);
					PDFJS.getDocument({ data: pdf_data }).then((pdf_doc) => {
						this.totalPagesPdf = pdf_doc.numPages;
						this.pdfDoc = pdf_doc;
						this.currentPagePdf = 0;
						this.showPage(1);
						this.loading = false;
					});
				}
			}, (response) => {
			});
		},
		showPage(page_no) {
			this.currentPagePdf = page_no;
			this.pdfDoc.getPage(page_no).then((page) => {
				var desiredWidth = document.getElementById('pdf-canvas').width;
				var viewport = page.getViewport(1);
				var scale = desiredWidth / viewport.width;
				var scaledViewport = page.getViewport(scale);
				document.getElementById('pdf-canvas').height = scaledViewport.height;
				var renderContext = {
					canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
					viewport: scaledViewport
				};
				page.render(renderContext).then(() => {
				});
			});
		},
		prevPage() {
			if(this.currentPagePdf != 1) {
				this.showPage(this.currentPagePdf - 1);
			}    
		},
		nextPage() {
			if(this.currentPagePdf != this.totalPagesPdf) {
				this.showPage(this.currentPagePdf + 1);
			}    
		},
		skills(ex) {
			return JSON.parse(ex.skill.standard);
		},

		skills_portiere(ex) {
			return JSON.parse(ex.skill.special);
		}
	}

}
</script>
