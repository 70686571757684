<template id="archivio_giocatori">
	<section class="archivio_giocatori">

		<headapp></headapp>

		<b-overlay :show="loading" no-wrap></b-overlay>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			
			<div class="card">
				<div class="card-body">
					<b-row>
						<b-col>
							<div class="card-title">
								<span>Lista {{ lista_selected_label }}</span>
							</div>
						</b-col>
						<b-col class="text-right" v-if="lista_in_campo && lista_in_campo.formazioni">
							<div v-if="lista_in_campo_formazione.buckets_v2 !== null" class="small">
								Questa lista è stata realizzata con una versione più recente delle formazioni in campo.<br />
								<router-link :to="{ name: 'youthscout_lista_in_campo_new', params: { id: $route.params.id } }">Clicca qui per spostarti nella nuova versione.</router-link>
							</div>
						</b-col>
					</b-row>
					<div class="content">
						<b-row class="mb-1">
							<b-col cols="2">
								<b-form-select v-model="sistema_gioco">
									<option value="0">-- Sistemi di gioco --</option>
									<option v-for="item in sistemi_gioco" :value="item.id">{{item.sistema_gioco}} {{ hasFormation(item.id) ? ' *' : ''}} {{ auth.isUserEnable('shadow_team') && isCurrentShadowTeamFormation(item.id) ? ' (Current Shadow Team)' : ''}}</option>
								</b-form-select>
							</b-col>
							<b-col cols="1">
								<b-form-select v-model="campo">
									<option value="0"> Campo 1 </option>
									<option value="1"> Campo 2 </option>
									<option value="2"> Campo 3 </option>
									<option value="3"> Campo 4 </option>
									<option value="4"> Campo 5 </option>
									<option value="5"> Campo 6 </option>	
								</b-form-select>
							</b-col>
							<b-col cols="3">
								<b-button variant="primary" :title="formazione_id ? 'Aggiorna formazione' : 'Salva nuova formazione'" @click="salvaFormazioneLista" class="ml-1"><span class="mr-1"></span> <i :class="formazione_id ? 'fa fa-pencil' : 'fa fa-plus'" aria-hidden="true"></i>
								</b-button>
								<!--b-button v-if="auth.isUserEnable('shadow_team') && !formazione_id" variant="warning" title="Unisci formazioni (Shadow Team)" @click="selectFormazioniListe" class="ml-1"> <i class="fa fa-clone" aria-hidden="true"></i>
								</b-button>
								<b-button v-if="auth.isUserEnable('shadow_team_current') && formazione_id" :variant="current_shadow_team ? 'info' : 'secondary'" :title="current_shadow_team ? 'Rimuovi da Current Shadow Team' : 'Imposta come Current Shadow Team'" @click="current_shadow_team ? unsetCurrentShadowTeam() : setCurrentShadowTeam()" class="ml-1"> <i :class="current_shadow_team ? 'fa fa-star' : 'fa fa-star-o'" aria-hidden="true"></i>
								</b-button-->
								<b-button :variant="show_levels ? 'success' : 'secondary'" :title="show_levels ? 'Nascondi livelli' : 'Mostra livelli'" @click="show_levels = !show_levels" class="ml-1"> <i class="fa fa-list-ol" aria-hidden="true"></i>
								</b-button>
							</b-col>
							<b-col cols="5">
								<b-form-group
								label="Titolo" label-cols="2" content-cols="10">
								<b-form-input type="text" v-model="form.titolo"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="1" class="text-right">
							<b-img @click="backArchivio" src="/assets/varie/shirt.png" width="40" height="40" style="cursor:pointer;" title="Torna all'archivio"/>
						</b-col>
					</b-row>
					<div style="position:absolute;z-index:1">
						<b-container>
							<small>Non in campo</small>
							<draggable :list="form_non_in_campo" :options="{group:{ name:'giocatori'}}" style="min-height:50px;background: #DDDDDD;">
								<b-row v-for="giocatore in form_non_in_campo" v-if="giocatore && giocatore instanceof Object" :class="formazione_ids.indexOf(giocatore.id) === -1 ? 'bg-secondary' : getBgByLevel(giocatore.level)">
									<b-col class="notInCampo"><b-link :href="'#/youthscout/view/giocatore/' + giocatore.id" target="_blank" style="color:black;"><span style="color:#0000FF">{{ giocatore.cognome.toUpperCase() }}</span> <span style="color:#FF6600">{{ giocatore.nascita_data | formatYear }}</span> <img :src="'/assets/smiles/grad_' + report.id_gradimento + '.png'" width="10" :title="getTitleGrad(report)" v-if="showGrad(report)" v-for="report in giocatore.partite_report_giocatori"/>{{ giocatore.last_carriera_sportiva.squadra ? giocatore.last_carriera_sportiva.squadra.name : '' }} </b-link></b-col></b-row>
								</draggable>
							</b-container> 
						</div>
						<div :class="'sistema sistema-' + sistema_gioco" 
						ref="field_lista"
						id="field_lista">
						<div class="campo text-center">
							<campodefault v-if="campo == 0"/>
							<campoorizzontale v-if="campo == 1" style="width:80%"/>
							<campoverticale v-if="campo == 2" style="width:75%"/>
							<campodefaultwhite v-if="campo == 3"/>
							<campoorizzontalewhite v-if="campo == 4" style="width:80%"/>
							<campoverticalewhite v-if="campo == 5" style="width:75%"/>
						</div>
						<div v-if="sistema_gioco > 0">
							<draggablediv
							:ref="'draggablediv_' + key"
							v-for="(item, key) in form_in_campo"
							:class="'player player-' + moduli_ruoli[sistema_gioco][key - 1]"
							:key="'players_' + key"
							>
							<template slot="header">
								<span class="label-value">{{
									getNomeRuolo(moduli_ruoli[sistema_gioco][key - 1])
								}}</span>
							</template>
							<template slot="main">
								<b-container>
									<draggable :list="item" :options="{group:{ name:'giocatori'}}" style="min-height:50px;">
										<div v-for="giocatore in item" v-if="giocatore && giocatore instanceof Object">
											<b-row align-v="center" :class="formazione_ids.indexOf(giocatore.id) === -1 ? 'bg-secondary' : getBgByLevel(giocatore.level)">
												<b-col cols="3" v-if="show_levels">
													<b-form-select v-model="giocatore.level" size="sm" 
													style="font-size:100%; line-height: 1;">
													<option :value="0">0</option>
													<option :value="1">1</option>
													<option :value="2">2</option>	
													<option :value="3">3</option>	
													<option :value="4">4</option>	
												</b-form-select>
											</b-col><b-col class="truncate"><b-link :href="'#/youthscout/view/giocatore/' + giocatore.id" target="_blank" style="color:black;"><span style="color:#0000FF">{{ giocatore.cognome.toUpperCase() }}</span> <span style="color:#FF6600">{{ giocatore.nascita_data | formatYear }}</span> <img :src="'/assets/smiles/grad_' + report.id_gradimento + '.png'" width="10" :title="getTitleGrad(report)" v-if="showGrad(report)" v-for="report in giocatore.partite_report_giocatori"/> {{ giocatore.last_carriera_sportiva.squadra ? giocatore.last_carriera_sportiva.squadra.name : '' }}</b-link></b-col><!--b-col cols="1"><b-link :id="'gio_' + giocatore.id" href="#" tabindex="0"><i class="fa fa-info-circle" aria-hidden="true"></i></b-link></b-col--></b-row>
											<b-popover :target="'gio_' + giocatore.id" placement="top" triggers="focus" @show="onShowVal(giocatore.id)">
												<div class="color-rosso bg-light p-1 mb-1"><strong>{{ giocatore.cognome.toUpperCase() + ' ' + giocatore.nome }}</strong></div>
												<div style="width:250px">
													<div v-if="loading_val" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 50px;" /></div>
													<b-row v-if="!loading_val">
														<b-col cols="3">
														</b-col>
														<b-col cols="2">
														</b-col>
														<b-col class="border text-center bg-light" title="Current">
															<strong>C</strong>
														</b-col>
														<b-col class="border text-center bg-light" title="Potential">
															<strong>P</strong>
														</b-col>
														<b-col class="border text-center color-rosso bg-light" title="Grade">
															<strong>G</strong>
														</b-col>
													</b-row>
													<b-row v-for="val in valutazioni">
														<b-col :cols="val.is_media ? '5' : '3'" :class="'border-bottom ' + (val.is_media ? 'color-rosso text-right' : '')">
															<span :style="(val.is_media ? 'font-weight:bold;' : '')">{{ val.label }}</span>
														</b-col>
														<b-col cols="2" class="border-bottom text-right" v-if="!val.is_media" title="Valutazioni">
															<span style="font-size:0.7rem;">({{ val.value }} val.)</span>
														</b-col>
														<b-col :class="'border text-center ' + (val.is_media ? val.current_bg : '')" title="Current">
															<span :style="(val.is_media ? 'font-weight:bold;' : '')">{{ val.current }}</span>
														</b-col>
														<b-col :class="'border text-center ' + (val.is_media ? val.potential_bg : '')" title="Potential">
															<span :style="(val.is_media ? 'font-weight:bold;' : '')">{{ val.potential }}</span>
														</b-col>
														<b-col :class="'border text-center ' + (val.is_media ? val.grade_bg : '')" title="Grade">
															<strong>{{ val.grade }}</strong>
														</b-col>
													</b-row>

												</div>											
											</b-popover>
										</div>
									</draggable>
								</b-container>
							</template>
						</draggablediv>
					</div>
				</div>
				<b-button
				v-if="formazione_id"
				variant="warning"
				class="ml-2"
				title="Esporta campo"
				@click="pdfFieldLista"
				>
				Esporta campo <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
			</b-button>
		</div>
		<b-modal ref="pdfModal" title="Archivio PDF" ok-only ok-title="Chiudi">	
			<b-table :items="elenco_pdf" :fields="['titolo', {key: 'data', formatter: this.formatDate}, 'azioni']">
				<template #cell(azioni)="row">
					<b-button size="sm" title="download PDF" @click="downloadPdf(row.item.id)">
						<i class="fa fa-download" aria-hidden="true"></i>
					</b-button>
					<b-button size="sm" title="elimina PDF" @click="eliminaPdf(row.item.id)">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</b-button>
				</template>
			</b-table>
		</b-modal>
		<b-modal ref="listeModal" title="Unisci formazioni" ok-only ok-title="Chiudi">
			<div>Shadow Team List: <strong>{{ lista_selected_label }}</strong></div>
			<div class="mb-2" v-if="sistemi_gioco.length > 0">Sistema di gioco: <strong>{{ sistemi_gioco.find((item) => item.id == sistema_gioco).sistema_gioco }}</strong></div>
			<b-row v-for="list in list_to_merge" class="mb-1" align-v="center">
				<b-col cols="6">
					<b-form-select v-model="list.lista_id">
						<option value="0">-- Liste --</option>
						<option v-for="item in liste" :value="item.id" v-if="item.id != lista_selected">{{item.nome}}</option>
					</b-form-select>
				</b-col>
				<b-col>
					<b-form-select v-if="list.lista_id" v-model="list.formazione_id">
						<option value="0">-- Sistema gioco --</option>
						<option v-for="item in liste.find((obj) => obj.id == list.lista_id).formazioni" :value="item.id">{{ item.sistema_gioco.sistema_gioco }}</option>
					</b-form-select>
				</b-col>
				<b-col cols="1">
					<b-button size="sm" @click="removeListaToMerge(list)" title="Rimuovi"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
				</b-col>
			</b-row>
			<div class="mt-2">
				<b-button size="sm" title="add lista" @click="addListaToMerge" class="mr-1">
					Aggiungi lista <i class="fa fa-plus" aria-hidden="true"></i>
				</b-button>
				<b-button size="sm" variant="warning" title="Unisci formazioni" @click="mergeList">
					Unisci formazioni <i class="fa fa-clone" aria-hidden="true"></i>
				</b-button>
			</div>
		</b-modal>
	</div>
</div>
</div>
<footerapp></footerapp>

</section>
</template>

<script>
import moment from 'moment'
import draggable from 'vuedraggable';
import * as types from '../../../store/types'
import { mapState } from 'vuex';
import { jsPDF } from 'jspdf';
var Auth = require('../../../auth.js');

export default {

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
		draggable,
		draggablediv: require('../../layout/draggable_div.vue').default,
		campodefault: 	require('../../layout/campi/default.vue').default,
		campoorizzontale: 	require('../../layout/campi/orizzontale.vue').default,
		campoverticale: 	require('../../layout/campi/verticale.vue').default,
		campodefaultwhite: 	require('../../layout/campi/default_white.vue').default,
		campoorizzontalewhite: 	require('../../layout/campi/orizzontale_white.vue').default,
		campoverticalewhite: 	require('../../layout/campi/verticale_white.vue').default,
	},

	data: function(){
		return {
			ruoli: [],
			liste: [],
			lista_selected: this.$route.params.id,
			lista_selected_label: '',
			sistemi_gioco: [],
			sistema_gioco: 4,
			lista_in_campo: null,
			ruoli: [],
			moduli_ruoli: {
				1: [3, 10, 6, 5, 9, 20, 18, 17, 19, 26, 27], /* 4-4-2 */
				2: [3, 10, 6, 5, 9, 13, 18, 17, 16, 26, 27], /* 4-4-2 rombo*/
				3: [3, 10, 6, 5, 9, 20, 18, 17, 19, 21, 26], /* 4-4-1-1 */
				4: [3, 10, 6, 5, 9, 15, 13, 14, 25, 27, 24], /* 4-3-3 */
				5: [3, 8, 4, 7, 20, 18, 17, 19, 25, 27, 24], /* 3-4-3 */
				6: [3, 8, 4, 7, 12, 18, 13, 17, 11, 26, 27], /* 3-5-2 */
				7: [3, 10, 6, 5, 9, 18, 17, 23, 21, 22, 26], /* 4-2-3-1 */
				8: [3, 10, 6, 5, 9, 15, 13, 14, 21, 28, 27], /* 4-3-2-1 */
				9: [3, 10, 6, 5, 9, 18, 13, 17, 21, 26, 27], /* 4-3-1-2 */
				10: [3, 10, 6, 5, 9, 13, 15, 16, 14, 26, 27], /* 4-1-3-2 */
				11: [3, 10, 6, 5, 9, 13, 20, 18, 17, 19, 26], /* 4-1-4-1 */
				12: [3, 8, 4, 7, 20, 18, 17, 19, 21, 25, 24], /* 3-4-1-2 */
				13: [3, 8, 4, 7, 20, 18, 17, 19, 23, 22, 27], /* 3-4-2-1 */
				14: [3, 12, 8, 4, 7, 11, 18, 17, 25, 26, 24], /* 5-2-3 */
				15: [3, 12, 8, 4, 7, 11, 15, 13, 14, 26, 27], /* 5-3-2 */
				16: [3, 12, 8, 4, 7, 11, 20, 18, 17, 19, 26], /* 5-4-1 */
			},
			form: {
				titolo: '',
				data: '',
				note: '',
				live_dal: null,
				live_al: null,
			},
			elenco_pdf: [],
			loading: false,
			header_pdf: null,
			live_dal: null,
			live_al: null,
			form_in_campo: {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			},
			form_non_in_campo: [],
			formazione_id: null,
			formazione_ids: [],
			positions: {
				1: {top: '', left: ''},
				2: {top: '', left: ''},
				3: {top: '', left: ''},
				4: {top: '', left: ''},
				5: {top: '', left: ''},
				6: {top: '', left: ''},
				7: {top: '', left: ''},
				8: {top: '', left: ''},
				9: {top: '', left: ''},
				10: {top: '', left: ''},
				11: {top: '', left: ''},
			},
			campo: 0,
			show_levels: false,
			list_to_merge: [],
			current_shadow_team: 0,
			valutazioni: [],
			val_max: 5,
			loading_val: false,
		}
	},
	created: function () {
		
		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
			if (response.ok)
			{
				this.sistemi_gioco = response.data; 
			}
		}, (response) => {
		}); 
		
		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
			if (response.ok)
			{
				this.ruoli = response.data;    
			}
		}, (response) => {
		});
		
		this.$http.get(this.$store.state.apiEndPoint + '/liste', {params: {no_players: true }}).then((response) => {
			if (response.ok)
			{
				this.liste = response.data; 
			}
		}, (response) => {
		});

		this.listaInCampo();

		this.headerPdf();
	},

	methods: {
		listaInCampo() {
			this.loading = true;
			this.form.titolo = null;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/lista/in/campo/' + this.$route.params.id).then((response) => {
				if (response.ok)
				{
					this.lista_in_campo = response.data; 
					this.lista_selected_label = this.lista_in_campo.nome;
					if(this.lista_in_campo.formazioni.length > 0) {
						this.sistema_gioco = this.lista_in_campo.formazioni[0].id_sistema_gioco;
					}
					this.setFormazioneLista();
					this.loading = false;
				}
			}, (response) => {
			});
		},
		setFormazioneLista() {
			this.form_in_campo = {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			};
			this.form_non_in_campo = [];
			this.formazione_ids = [];
			this.formazione_id = null;
			this.current_shadow_team = 0;
			if(this.sistema_gioco == 0) {
				this.sistema_gioco = 4;
			}
			this.positions = {
				1: {top: '', left: ''},
				2: {top: '', left: ''},
				3: {top: '', left: ''},
				4: {top: '', left: ''},
				5: {top: '', left: ''},
				6: {top: '', left: ''},
				7: {top: '', left: ''},
				8: {top: '', left: ''},
				9: {top: '', left: ''},
				10: {top: '', left: ''},
				11: {top: '', left: ''},
			};
			for(var i in this.lista_in_campo.formazioni) {
				if(this.lista_in_campo.formazioni[i].id_sistema_gioco == this.sistema_gioco) {
					this.formazione_id = this.lista_in_campo.formazioni[i].id;
					this.form_in_campo = JSON.parse(this.lista_in_campo.formazioni[i].in_campo);
					this.form_non_in_campo = JSON.parse(this.lista_in_campo.formazioni[i].non_in_campo);
					if(this.lista_in_campo.formazioni[i].positions) {
						this.positions = JSON.parse(this.lista_in_campo.formazioni[i].positions);
					}
					this.campo = this.lista_in_campo.formazioni[i].campo;
					this.current_shadow_team = this.lista_in_campo.formazioni[i].shadow_team;
					this.form.titolo = this.lista_in_campo.formazioni[i].titolo;
					break;
				}
			}
			if(this.formazione_id) {
				for(var i in this.form_in_campo) {
					var array_gio = this.form_in_campo[i];
					for(var j in array_gio) {
						var item_gio = array_gio[j];
						var id_gio = null;
						var level = 0;
						if(item_gio.id) {
							id_gio = item_gio.id;
							level = item_gio.level;
						} else {
							id_gio = item_gio;
						}
						for(var x in this.lista_in_campo.giocatori) {
							var giocatore = this.lista_in_campo.giocatori[x]; 
							if(giocatore.id == id_gio) {
								giocatore.level = level;
								array_gio[j] = giocatore;
								this.formazione_ids.push(id_gio);
								break;
							}
						}
					}
					this.form_in_campo[i] = this.form_in_campo[i].filter(item => item.cognome);
				}
				for(var i in this.form_non_in_campo) {

					var item_gio = this.form_non_in_campo[i];
					var id_gio = null;
					var level = 0;
					if(item_gio.id) {
						id_gio = item_gio.id;
						level = item_gio.level;
					} else {
						id_gio = item_gio;
					}
					for(var x in this.lista_in_campo.giocatori) {
						var giocatore = this.lista_in_campo.giocatori[x]; 
						if(giocatore.id == id_gio) {
							giocatore.level = level;
							this.form_non_in_campo[i] = giocatore;
							this.formazione_ids.push(id_gio);
							break;
						}
					}
				}
				this.form_non_in_campo = this.form_non_in_campo.filter(item => item.cognome);
			} 
			for(var i in this.positions) {
				this.$refs['draggablediv_' + i][0].$el.style.top = this.positions[i].top;
				this.$refs['draggablediv_' + i][0].$el.style.left = this.positions[i].left;
			}
			for(var i in this.lista_in_campo.giocatori) {
				var giocatore = this.lista_in_campo.giocatori[i];
				if(this.formazione_ids.indexOf(giocatore.id) === -1) {
					var index = this.getPosizioneGiocatore(giocatore);
					giocatore.level = 0;
					if(index > -1) {
						this.form_in_campo[index + 1].push(giocatore);
					} else {
						this.form_non_in_campo.push(giocatore);
					}
				}
			}

		},
		getPosizioneGiocatore(giocatore) {	
			var index = -1;
			var array_ruoli = this.moduli_ruoli[this.sistema_gioco];

			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				var ruolo_ideale = giocatore.last_carriera_sportiva.ruolo_ideale[0];
				if(array_ruoli.indexOf(ruolo_ideale.id) > -1) {
					return array_ruoli.indexOf(ruolo_ideale.id);
				} else if(giocatore.last_carriera_sportiva.altri_ruoli && giocatore.last_carriera_sportiva.altri_ruoli.length > 0) {
					for(var i in giocatore.last_carriera_sportiva.altri_ruoli) {
						var altro_ruolo_id = giocatore.last_carriera_sportiva.altri_ruoli[i].id;
						if(array_ruoli.indexOf(altro_ruolo_id) > -1) {
							return array_ruoli.indexOf(altro_ruolo_id);
						}
					}
				} 
			}  
			return index;
		},
		salvaFormazioneLista() {
			var svg = $("#field_lista").find("svg");
			var form_data = {
				form_in_campo: {
					1: [],
					2: [],
					3: [],
					4: [],
					5: [],
					6: [],
					7: [],
					8: [],
					9: [],
					10: [],
					11: [],
				},
				form_non_in_campo: [],
				positions: {
					1: {top: '', left: ''},
					2: {top: '', left: ''},
					3: {top: '', left: ''},
					4: {top: '', left: ''},
					5: {top: '', left: ''},
					6: {top: '', left: ''},
					7: {top: '', left: ''},
					8: {top: '', left: ''},
					9: {top: '', left: ''},
					10: {top: '', left: ''},
					11: {top: '', left: ''},
				},
				campo: this.campo,
				width: svg.width(),
				height: svg.height(),
				titolo: this.form.titolo,
			};
			for (var key in this.form_in_campo) {
				var array_gio = this.form_in_campo[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore && giocatore instanceof Object) {
						form_data.form_in_campo[key].push({id: giocatore.id, level: giocatore.level });
					}
				}
				form_data.positions[key].top = this.$refs['draggablediv_' + key][0].$el.style.top;
				form_data.positions[key].left = this.$refs['draggablediv_' + key][0].$el.style.left;
			}
			for (var i in this.form_non_in_campo) {
				var giocatore = this.form_non_in_campo[i];
				if(giocatore && giocatore instanceof Object) {
					form_data.form_non_in_campo.push({id: giocatore.id, level: giocatore.level });
				}
			}
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/formazione/lista/' + this.lista_selected + '/' + this.sistema_gioco, form_data).then(response => {
				if (response.ok) {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.listaInCampo();		
				}
			}, (response) => {
			});
		},
		backArchivio() {
			this.$router.push({ name: "youthscout_dashboard"});
		},
		getNomeRuolo(id) {
			for(var i in this.ruoli) {
				var ruolo = this.ruoli[i];
				if(ruolo.id == id) {
					return ruolo.nome.toUpperCase();
				}
			}
			return "";
		},
		salvaPdf() {
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/pdf/lista/' + this.lista_selected + '/' + this.sistema_gioco, this.form).then(response => {
				if (response.ok) {
					window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + response.data + "/" + Auth.user.id + "/" + Auth.user.api_token;		
				}
			}, (response) => {
			});
		},

		archivioPdfLista() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/pdf/lista/' + this.lista_selected).then((response) => {
				if (response.ok)
				{
					this.elenco_pdf = response.data; 
					this.$refs.pdfModal.show();
				}
			}, (response) => {
			});
		},
		formatDate (data) {
			var m = moment(data);
			var format = m.format('DD/MM/YYYY'); 
			return format;
		},
		downloadPdf(pdf) {
			window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + pdf + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		eliminaPdf(pdf) {
			if(confirm("Confermi l'eliminazione del pdf?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/pdf/lista/' + pdf).then((response) => {
					if (response.ok)
					{
						this.elenco_pdf = response.data; 
					}
				}, (response) => {
				});
			}
		},
		pdfFieldLista() {
			window.open(this.$store.state.apiEndPoint + '/scouting/download/formazione/' + this.formazione_id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},

		headerPdf() {
			this.$http.get(this.$store.state.apiEndPoint + '/headerpdf/scouting').then((response) => {
				if (response.ok)
				{
					this.header_pdf = response.data;    
				}
			}, (response) => {
			});
		},
		showGrad(report) {
			if(!Auth.isAdmin()) {
				return false;
			}
			if(!report.partita_report.partita.newscout) {
				return false;
			}  
			if(!report.gradimento) {
				return false;
			} 
			if(!this.form.live_dal && !this.form.live_al) {
				return true;
			} else if(this.form.live_dal && this.form.live_al) {
				if(report.partita_report.created_at >= this.form.live_dal + ' 00:00:00' && report.partita_report.created_at <= this.form.live_al + ' 23:59:59') {
					return true;

				}
			} else if(this.form.live_dal && !this.form.live_al) {
				if(report.partita_report.created_at >= this.form.live_dal + ' 00:00:00') {
					return true;
				}

			} else if(!this.form.live_dal && this.form.live_al) {
				if(report.partita_report.created_at <= this.form.live_al + ' 23:59:59') {
					return true;
				}
			}
			return false;
		},
		getTitleGrad(report) {
			var title = '';
			if(report.gradimento) {
				title = report.gradimento.nome + ' del ' + moment(report.partita_report.created_at).format("DD/MM/YYYY") + ' di ' + report.partita_report.utente.nomeCompleto;
			}
			return title;
		},
		getBgByLevel(level) {
			if(level == 1) {
				return 'bg-info';
			} else if(level == 2) {
				return 'bg-success';
			} else if(level == 3) {
				return 'bg-warning';
			} else if(level == 4) {
				return 'bg-danger';	
			} 
			return "";
		},

		selectFormazioniListe() {
			this.list_to_merge = [];
			this.$refs.listeModal.show();
		},

		addListaToMerge() {
			this.list_to_merge.push({lista_id: 0, formazione_id: 0});
		},

		removeListaToMerge(item) {
			var index = this.list_to_merge.indexOf(item);
			this.list_to_merge.splice(index, 1);
		},

		mergeList() {
			if(this.list_to_merge.length == 0) {
				alert("ATTENZIONE! Non hai selezionato alcuna lista da unire");
				return;
			}
			for(var i in this.list_to_merge) {
				var list = this.list_to_merge[i];
				if(!list.lista_id) {
					alert("ATTENZIONE! Non hai selezionato una lista");
					return;
				}
				if(list.lista_id && !list.formazione_id) {
					alert("ATTENZIONE! Non hai selezionato una formazione per la lista");
					return;
				}
			}
			if(confirm("Confermi di unire nella lista '" + this.lista_selected_label + "' le formazioni selezionate?")) {
				var svg = $("#field_lista").find("svg");
				var form = {
					list_to_merge: this.list_to_merge,
					id_lista: this.lista_selected,
					id_sistema_gioco: this.sistema_gioco,
					campo: this.campo,
					width: svg.width(),
					height: svg.height(),
					titolo: this.form.titolo,
				}
				this.$http.post(this.$store.state.apiEndPoint + '/scouting/unisci/formazioni/lista', form).then(response => {
					if (response.ok) {
						this.$refs.listeModal.hide();
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.listaInCampo();		
					}
				}, (response) => {
				});
			}
		},

		setCurrentShadowTeam() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/current/shadow/team/' + this.formazione_id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.listaInCampo();
				}
			}, (response) => {
			}); 
		},

		unsetCurrentShadowTeam() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/current/shadow/team/unset/' + this.formazione_id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.listaInCampo();
				}
			}, (response) => {
			}); 
		},

		isCurrentShadowTeamFormation(id) {
			if(this.lista_in_campo) {
				for(var i in this.lista_in_campo.formazioni) {
					var form = this.lista_in_campo.formazioni[i];
					if(form.id_sistema_gioco == id) {
						if(form.shadow_team) {
							return true;
						} else {
							return false;
						}
					}
				}
			}
			return false;
		},

		isCurrentShadowTeamList(id) {	
			var lista = this.liste.find((item) => item.id == id);
			for(var i in lista.formazioni) {
				var form = lista.formazioni[i];
				if(form.shadow_team) {
					return true;
				} 
			}
			return false;
		},

		hasFormation(id) {
			if(this.lista_in_campo) {
				for(var i in this.lista_in_campo.formazioni) {
					var form = this.lista_in_campo.formazioni[i];
					if(form.id_sistema_gioco == id) {
						return true;
					}
				}
			}
			return false;
		},

		onShowVal(giocatore_id) {
			this.loading_val = true;
			this.valutazioni = [];
			this.val_max = 0;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/valutazioni/giocatore/' + giocatore_id).then((response) => {
				if (response.ok)
				{
					this.valutazioni = response.data;
					for(var i in this.valutazioni) {
						var value = this.valutazioni[i].value;
						if(value > this.val_max) {
							this.val_max = value;
						}
					}
					this.loading_val = false;
				}
			}, (response) => {
			}); 
		}
	},

	watch: {
		sistema_gioco: function () {
			this.setFormazioneLista();
		}, 
	},

	computed: {
		lista_in_campo_formazione: function() {

			var found = _.find(this.lista_in_campo.formazioni, { id_sistema_gioco: this.sistema_gioco });
			if(found) return found;

			return false;

		},
		auth: function() {
			return Auth;
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YY'); 
			}
			return "";
		}
	},

}
</script>
