<template id="archivio_giocatori">
	<section class="archivio_giocatori">

		<headapp></headapp>
		<b-overlay :show="loading" no-wrap>
		</b-overlay>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">

					<b-row>
						<b-col>
							<div class="card-title">
								<span>Lista {{ lista_selected_label }}</span>
							</div>
						</b-col>
						<b-col class="text-right" v-if="lista_in_campo && lista_in_campo.formazioni">
							<div v-if="lista_in_campo_formazione.positions !== null" class="small">
								Questa lista è stata realizzata con una versione più vecchia delle formazioni in campo.<br />
								<router-link :to="{ name: 'youthscout_lista_in_campo', params: { id: $route.params.id } }">Clicca qui per spostarti nella precedente versione.</router-link>
							</div>
						</b-col>
					</b-row>
					<div class="content">

						<b-row class="mb-1">
							<b-col cols="2">
								<b-form-select v-model="sistema_gioco">
									<option value="0">-- Sistemi di gioco --</option>
									<option v-for="item in sistemi_gioco" :value="item.id">
										{{item.sistema_gioco}}
										<span v-if="formazioneOf[item.id] && formazioneOf[item.id].titolo">- {{formazioneOf[item.id].titolo}} </span>
										<span v-if="formazioneOf[item.id] && formazioneOf[item.id].id">*</span>
										<span v-else-if="formazioneOf[item.id]" class="small">(NEW)</span>
										{{ auth.isUserEnable('shadow_team') && isCurrentShadowTeamFormation(item.id) ? ' (Current Shadow Team)' : ''}}
									</option>
								</b-form-select>
							</b-col>
							<b-col cols="1">
								<b-form-select v-model="campo">
									<option value="0">Campo 1</option>
									<option value="1">Campo 2</option>
									<option value="2">Campo 3</option>
									<option value="3">Campo 4</option>
									<option value="4">Campo 5</option>
									<option value="5">Campo 6</option>	
								</b-form-select>
							</b-col>
							<b-col cols="3">

								<b-button-group>
									<b-button style="margin-left: 0 !important" variant="primary" :title="formazione_id ? 'Aggiorna formazione' : 'Salva nuova formazione'" @click="salvaFormazioneLista" class="ml-1"><span class="mr-1"></span> <i :class="formazione_id ? 'fa fa-pencil' : 'fa fa-plus'" aria-hidden="true"></i></b-button>
									<b-button style="margin-left: 0 !important" v-if="auth.isUserEnable('shadow_team') && !formazione_id" variant="warning" title="Unisci formazioni (Shadow Team)" @click="selectFormazioniListe" class="ml-1"> <i class="fa fa-clone" aria-hidden="true"></i></b-button>
									<b-button style="margin-left: 0 !important" v-if="auth.isUserEnable('shadow_team_current') && formazione_id" :variant="current_shadow_team ? 'info' : 'secondary'" :title="current_shadow_team ? 'Rimuovi da Current Shadow Team' : 'Imposta come Current Shadow Team'" @click="current_shadow_team ? unsetCurrentShadowTeam() : setCurrentShadowTeam()" class="ml-1"> <i :class="current_shadow_team ? 'fa fa-star' : 'fa fa-star-o'" aria-hidden="true"></i></b-button>
									<b-button style="margin-left: 0 !important" size="sm" v-if="formazione_id" variant="danger" title="Elimina" @click="eliminaLista" class="ml-1"> <i class="fa fa-trash" aria-hidden="true"></i></b-button>
								</b-button-group>

							</b-col>
							<b-col cols="5">
								<b-form-group
								label="Titolo" label-cols="2" content-cols="10">
								<b-form-input type="text" v-model="form.titolo"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="1" class="text-right">
							<b-img @click="backArchivio" src="/assets/varie/shirt.png" width="40" height="40" style="cursor:pointer;" title="Torna all'archivio"/>
						</b-col>
					</b-row>

					<div v-if="lista_in_campo_formazione && presets && sistema_gioco">
						<lista-in-campo :ruoli="ruoli" @formazioneChanged="formazioneIsChanged" :campo="campo" :ratio="campoRatio" :presets="presets" :starting-players="lista_in_campo.giocatori" :base="lista_in_campo_formazione" ref="listaInCampo"></lista-in-campo>
						<!--
						<pre>{{lista_in_campo_formazione}}</pre>
						<pre>{{lista_in_campo.formazioni}}</pre>
						-->
					</div>


					
						<div :class="'sistema sistema-' + sistema_gioco" 
						ref="field_lista"
						id="field_lista">
						
						<div v-if="sistema_gioco > 0">
							<draggablediv
							:ref="'draggablediv_' + key"
							v-for="(item, key) in form_in_campo"
							:class="'player player-' + moduli_ruoli[sistema_gioco][key - 1]"
							:key="'players_' + key"
							>
							
						</draggablediv>


					</div>
				</div>
				<b-button
				v-if="formazione_id"
				variant="warning"
				class="ml-2"
				title="Esporta campo"
				@click="pdfFieldLista"
				>
				Esporta campo <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
			</b-button>
		</div>
		<b-modal ref="pdfModal" title="Archivio PDF" ok-only ok-title="Chiudi">	
			<b-table :items="elenco_pdf" :fields="['titolo', {key: 'data', formatter: this.formatDate}, 'azioni']">
				<template #cell(azioni)="row">
					<b-button size="sm" title="download PDF" @click="downloadPdf(row.item.id)">
						<i class="fa fa-download" aria-hidden="true"></i>
					</b-button>
					<b-button size="sm" title="elimina PDF" @click="eliminaPdf(row.item.id)">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</b-button>
				</template>
			</b-table>
		</b-modal>
		<b-modal ref="listeModal" title="Unisci formazioni" ok-only ok-title="Chiudi">
			<div>Shadow Team List: <strong>{{ lista_selected_label }}</strong></div>
			<div class="mb-2" v-if="sistemi_gioco.length > 0">Sistema di gioco: <strong>{{ sistemi_gioco.find((item) => item.id == sistema_gioco).sistema_gioco }}</strong></div>
			<b-row v-for="list in list_to_merge" class="mb-1" align-v="center">
				<b-col cols="6">
					<b-form-select v-model="list.lista_id">
						<option value="0">-- Liste --</option>
						<option v-for="item in liste" :value="item.id" v-if="item.id != lista_selected">{{item.nome}}</option>
					</b-form-select>
				</b-col>
				<b-col>
					<b-form-select v-if="list.lista_id" v-model="list.formazione_id">
						<option value="0">-- Sistema gioco --</option>
						<option v-for="item in liste.find((obj) => obj.id == list.lista_id).formazioni" :value="item.id">{{ item.sistema_gioco.sistema_gioco }}</option>
					</b-form-select>
				</b-col>
				<b-col cols="1">
					<b-button size="sm" @click="removeListaToMerge(list)" title="Rimuovi"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
				</b-col>
			</b-row>
			<div class="mt-2">
				<b-button size="sm" title="add lista" @click="addListaToMerge" class="mr-1">
					Aggiungi lista <i class="fa fa-plus" aria-hidden="true"></i>
				</b-button>
				<b-button size="sm" variant="warning" title="Unisci formazioni" @click="mergeList">
					Unisci formazioni <i class="fa fa-clone" aria-hidden="true"></i>
				</b-button>
			</div>
		</b-modal>
	</div>
</div>
</div>
<footerapp></footerapp>

</section>
</template>

<script>
import moment from 'moment'
import draggable from 'vuedraggable';
import * as types from '../../../store/types'
import { mapState } from 'vuex';
import { jsPDF } from 'jspdf';
var Auth = require('../../../auth.js');

export default {

	components: {
		'lista-in-campo': require('@/components/layout/scouting/lista-in-campo.vue').default,
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
		draggable,
		draggablediv: require('../../layout/draggable_div.vue').default,
		campodefault: 	require('../../layout/campi/default.vue').default,
		campoorizzontale: 	require('../../layout/campi/orizzontale.vue').default,
		campoverticale: 	require('../../layout/campi/verticale.vue').default,
		campodefaultwhite: 	require('../../layout/campi/default_white.vue').default,
		campoorizzontalewhite: 	require('../../layout/campi/orizzontale_white.vue').default,
		campoverticalewhite: 	require('../../layout/campi/verticale_white.vue').default,
	},

	data: function(){

		return {
			ratio: 16/10,
			presets: [
				{
					id: 1,
					name: '442',
					bucketsName: [3, 10, 6, 5, 9, 20, 18, 17, 19, 26, 27], /* 4-4-2 */
					ratios: [
						{
							ratio: 2.19,
							positions: [{"id":1,"x":0.15260770707128826,"y":0.3023859587295847},{"id":2,"x":0.2531367899326818,"y":0.05085236846647652},{"id":3,"x":0.23197142058671227,"y":0.19062606597430565},{"id":4,"x":0.20884748399240513,"y":0.45535089562390574},{"id":5,"x":0.19455957537748336,"y":0.62838395469789},{"id":6,"x":0.4919407205267095,"y":0.09582633814451631},{"id":7,"x":0.4724128418309894,"y":0.24161906599074476},{"id":8,"x":0.46713434255842057,"y":0.41583343008441487},{"id":9,"x":0.4805681874224279,"y":0.591130657112797},{"id":10,"x":0.7269431041993737,"y":0.19075263436927198},{"id":11,"x":0.742329094779757,"y":0.39231983493066797}]
						},
						{
							ratio: 0.68,
							positions: [{"id":1,"x":0.4310030309833857,"y":0.8138358778625954},{"id":2,"x":0.062294777166591826,"y":0.654663645038168},{"id":3,"x":0.311666830377189,"y":0.6539837786259542},{"id":4,"x":0.5759306943197126,"y":0.6461891698473282},{"id":5,"x":0.8063678715761113,"y":0.6489921278625954},{"id":6,"x":0.08668472159856308,"y":0.37063454198473283},{"id":7,"x":0.3186128339694656,"y":0.3721493320610687},{"id":8,"x":0.5636699455545576,"y":0.37460639312977095},{"id":9,"x":0.800500603390211,"y":0.3720419847328244},{"id":10,"x":0.2644304978670857,"y":0.15487237595419848},{"id":11,"x":0.5413848506960035,"y":0.15873091603053435}]
						},
						{
							ratio: 1.46,
							positions: [{"id":1,"x":0.03352681837236255,"y":0.4703469065682512},{"id":2,"x":0.15983984483096092,"y":0.1245471564716761},{"id":3,"x":0.1585901485546129,"y":0.30647931135723977},{"id":4,"x":0.16173284467572438,"y":0.5611165809491027},{"id":5,"x":0.16054642416020412,"y":0.7322245732682691},{"id":6,"x":0.45582803504296004,"y":0.12265331295010158},{"id":7,"x":0.4501648544488766,"y":0.30249916054157305},{"id":8,"x":0.44367381598396344,"y":0.5617478621229609},{"id":9,"x":0.4472699883910071,"y":0.7351962139159428},{"id":10,"x":0.7028829702148339,"y":0.3613237879738933},{"id":11,"x":0.7029515189557306,"y":0.5360270035636909}]
						}
					]
				},
				{
					id: 3,
					name: '4411',
					bucketsName: [3, 10, 6, 5, 9, 20, 18, 17, 19, 21, 26], /* 4-4-1-1 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.10750286657186775,"y":0.2813334157001833},{"id":2,"x":0.24943305876147276,"y":0.038143132174654044},{"id":3,"x":0.2490013233493067,"y":0.20027339030174007},{"id":4,"x":0.2365307082795637,"y":0.4062986109969505},{"id":5,"x":0.22301338350005345,"y":0.6008061077502239},{"id":6,"x":0.4373705419156509,"y":0.03705641341514536},{"id":7,"x":0.43490467364233404,"y":0.1668452708756298},{"id":8,"x":0.4287335814270802,"y":0.3925307822556119},{"id":9,"x":0.42132313354320616,"y":0.6191725881753396},{"id":10,"x":0.6018756504124372,"y":0.2995796645234361},{"id":11,"x":0.799024800470159,"y":0.30182343252973426}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.4310030309833857,"y":0.8138358778625954},{"id":2,"x":0.062294777166591826,"y":0.654663645038168},{"id":3,"x":0.311666830377189,"y":0.6539837786259542},{"id":4,"x":0.5759306943197126,"y":0.6461891698473282},{"id":5,"x":0.8063678715761113,"y":0.6489921278625954},{"id":6,"x":0.08668472159856308,"y":0.37063454198473283},{"id":7,"x":0.3186128339694656,"y":0.3721493320610687},{"id":8,"x":0.5636699455545576,"y":0.37460639312977095},{"id":9,"x":0.800500603390211,"y":0.3720419847328244},{"id":10,"x":0.43057329086214635,"y":0.10372733778625955},{"id":11,"x":0.42912620678042207,"y":0.22056297709923664}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.03352681837236255,"y":0.4703469065682512},{"id":2,"x":0.15983984483096092,"y":0.1245471564716761},{"id":3,"x":0.1585901485546129,"y":0.30647931135723977},{"id":4,"x":0.16173284467572438,"y":0.5611165809491027},{"id":5,"x":0.16054642416020412,"y":0.7322245732682691},{"id":6,"x":0.43490485012925134,"y":0.12166789843481078},{"id":7,"x":0.4258669622910213,"y":0.3054554040874454},{"id":8,"x":0.42545039686557196,"y":0.5617478621229609},{"id":9,"x":0.4229720962331518,"y":0.7361816284312337},{"id":10,"x":0.604341518685754,"y":0.4381861201665756},{"id":11,"x":0.8014929704848105,"y":0.4404417955804834}]
						}
					]
				},
				{
					id: 4,
					name: '433',
					bucketsName: [3, 10, 6, 5, 9, 15, 13, 14, 25, 27, 24], /* 4-3-3 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.07575481255291343,"y":0.2609277778006099},{"id":2,"x":0.25199375724348805,"y":0.033174982636177575},{"id":3,"x":0.23759668258521632,"y":0.19170892890901767},{"id":4,"x":0.22183824315105088,"y":0.4571369163084308},{"id":5,"x":0.20263786258538072,"y":0.6526991496864237},{"id":6,"x":0.48084431329678373,"y":0.09859677967877956},{"id":7,"x":0.43339561363131984,"y":0.31028945183748285},{"id":8,"x":0.4765483084143645,"y":0.5248228813054306},{"id":9,"x":0.7011682366759335,"y":0.19707727593576718},{"id":10,"x":0.7151210638577687,"y":0.338739214394917},{"id":11,"x":0.7279898139091411,"y":0.4940808244836061}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.40976159070498425,"y":0.8266459923664122},{"id":2,"x":0.057769350583744945,"y":0.6604842557251909},{"id":3,"x":0.29674870902559497,"y":0.6569179389312977},{"id":4,"x":0.5752290777952402,"y":0.6705927958015268},{"id":5,"x":0.7940018803322855,"y":0.666787929389313},{"id":6,"x":0.0662852211495285,"y":0.3569895038167939},{"id":7,"x":0.40456085821733273,"y":0.36384780534351147},{"id":8,"x":0.7698399612707678,"y":0.3580808683206107},{"id":9,"x":0.14287543500224517,"y":0.19139432251908398},{"id":10,"x":0.4166111220251459,"y":0.15623807251908398},{"id":11,"x":0.698239994948361,"y":0.1976204675572519}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.03352681837236255,"y":0.4703469065682512},{"id":2,"x":0.15983984483096092,"y":0.1245471564716761},{"id":3,"x":0.1585901485546129,"y":0.30647931135723977},{"id":4,"x":0.16173284467572438,"y":0.5611165809491027},{"id":5,"x":0.16054642416020412,"y":0.7322245732682691},{"id":6,"x":0.4517783863499842,"y":0.20542813223452866},{"id":7,"x":0.41101825041677637,"y":0.45031133783519284},{"id":8,"x":0.44974828902342723,"y":0.6681726297743671},{"id":9,"x":0.6929486757648774,"y":0.31048255782560863},{"id":10,"x":0.6995082629706874,"y":0.45395275241122834},{"id":11,"x":0.7049763433022186,"y":0.6128893357563732}]
						}
					]
				},
				{
					id: 5,
					name: '343',
					bucketsName: [3, 8, 4, 7, 20, 18, 17, 19, 25, 27, 24], /* 3-4-3 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.0332956432217391,"y":0.275750343166668},{"id":2,"x":0.21419019858459162,"y":0.10095938971815126},{"id":3,"x":0.22236480877191542,"y":0.35789323149982327},{"id":4,"x":0.17431248510204586,"y":0.5532023280878835},{"id":5,"x":0.4862897724003584,"y":0.017705512140291466},{"id":6,"x":0.4528978061991929,"y":0.16788594434535306},{"id":7,"x":0.4527565324960341,"y":0.42981220615069743},{"id":8,"x":0.49014269157741597,"y":0.6396344786948981},{"id":9,"x":0.6945914261760138,"y":0.17006573336977338},{"id":10,"x":0.7287796623404378,"y":0.32035867081480507},{"id":11,"x":0.7184795250737707,"y":0.4880899204551993}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.38055680287382126,"y":0.7946564885496183},{"id":2,"x":0.038167938931297704,"y":0.5580152671755725},{"id":3,"x":0.406376290974405,"y":0.5618320610687023},{"id":4,"x":0.7689717108217332,"y":0.5603053435114503},{"id":5,"x":0.028064660978895373,"y":0.3244274809160305},{"id":6,"x":0.3008531656937584,"y":0.32213740458015266},{"id":7,"x":0.5433318365514145,"y":0.3221374045801527},{"id":8,"x":0.7678491243825774,"y":0.3236641221374046},{"id":9,"x":0.09654243376740008,"y":0.11145038167938931},{"id":10,"x":0.40188594521778176,"y":0.14580152671755725},{"id":11,"x":0.6960035922766052,"y":0.10229007633587786}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.011927480916030535,"y":0.468372138248257},{"id":2,"x":0.18953726857945075,"y":0.16396373708330803},{"id":3,"x":0.19031239664959065,"y":0.4533060741355688},{"id":4,"x":0.1907553269753849,"y":0.7296224630638293},{"id":5,"x":0.5216400992838871,"y":0.1311217189408819},{"id":6,"x":0.4315301428851048,"y":0.2813050499119202},{"id":7,"x":0.423167196495704,"y":0.5774298103077058},{"id":8,"x":0.5246667898434811,"y":0.7440495474517586},{"id":9,"x":0.6929486757648774,"y":0.31048255782560863},{"id":10,"x":0.7575532275700083,"y":0.43917153468186637},{"id":11,"x":0.699576811711584,"y":0.5852977293282308}]
						}
					]
				},
				{
					id: 6,
					name: '352',
					bucketsName: [3, 8, 4, 7, 12, 18, 13, 17, 11, 26, 27], /* 3-5-2 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.043159116315006456,"y":0.27035009164810414},{"id":2,"x":0.23556105695333757,"y":0.07935838364389573},{"id":3,"x":0.20592568694980315,"y":0.33809230926508904},{"id":4,"x":0.1891076947419469,"y":0.5910040887178307},{"id":5,"x":0.48501830907192944,"y":0.027662225877643616},{"id":6,"x":0.4584460098141558,"y":0.18568989857061835},{"id":7,"x":0.4540986326760425,"y":0.33308582608642046},{"id":8,"x":0.46046237085014924,"y":0.4844897527761567},{"id":9,"x":0.49111234290364214,"y":0.6927088256507837},{"id":10,"x":0.7389155156419599,"y":0.19514024456938756},{"id":11,"x":0.752661339706233,"y":0.4361406258990145}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.3891165244723843,"y":0.8500477099236641},{"id":2,"x":0.07278394420745397,"y":0.7006023377862596},{"id":3,"x":0.38900251178715756,"y":0.710555820610687},{"id":4,"x":0.7531677986079928,"y":0.6975608301526718},{"id":5,"x":0.010322533116299954,"y":0.42432013358778625},{"id":6,"x":0.1904011843286933,"y":0.3478828721374046},{"id":7,"x":0.40075458857207,"y":0.3909351145038168},{"id":8,"x":0.6167647760440054,"y":0.35625},{"id":9,"x":0.7778296194431971,"y":0.4310174141221374},{"id":10,"x":0.2727972749214189,"y":0.12287690839694657},{"id":11,"x":0.5369032751459362,"y":0.12099236641221374}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.011927480916030535,"y":0.468372138248257},{"id":2,"x":0.18953726857945075,"y":0.16396373708330803},{"id":3,"x":0.19031239664959065,"y":0.4533060741355688},{"id":4,"x":0.1907553269753849,"y":0.7296224630638293},{"id":5,"x":0.47844384655881106,"y":0.1212675737879739},{"id":6,"x":0.4362547330269099,"y":0.26849466121313975},{"id":7,"x":0.44341543996058347,"y":0.43750094913641246},{"id":8,"x":0.4382742843933289,"y":0.605106100795756},{"id":9,"x":0.4837168266277901,"y":0.753919089706468},{"id":10,"x":0.7487789887352273,"y":0.29234477190353736},{"id":11,"x":0.7501974203737827,"y":0.5675602680529964}]
						}
					]
				},
				{
					id: 7,
					name: '4231',
					bucketsName: [3, 10, 6, 5, 9, 18, 17, 23, 21, 22, 26], /* 4-2-3-1 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.01603456530852122,"y":0.29555126540140225},{"id":2,"x":0.2617801719532143,"y":0.01840866989010447},{"id":3,"x":0.22773963102391073,"y":0.16176847192197993},{"id":4,"x":0.2044936853223301,"y":0.4343686683694857},{"id":5,"x":0.2125783940621892,"y":0.6466238667280394},{"id":6,"x":0.413077886504303,"y":0.19867019063216643},{"id":7,"x":0.41565934235293156,"y":0.43166854261020376},{"id":8,"x":0.5692495438143694,"y":0.08450549837252694},{"id":9,"x":0.5969777188252604,"y":0.3256604802483951},{"id":10,"x":0.5603300359194813,"y":0.6038437492294162},{"id":11,"x":0.7362029532882354,"y":0.42684488044648655}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.3868713515940727,"y":0.7973759541984733},{"id":2,"x":0.06379448248765154,"y":0.6668356393129771},{"id":3,"x":0.30952689997754823,"y":0.6079198473282442},{"id":4,"x":0.568607571845532,"y":0.6135496183206107},{"id":5,"x":0.7800484817018409,"y":0.6762762404580153},{"id":6,"x":0.16606386113605745,"y":0.42220300572519087},{"id":7,"x":0.637067804220925,"y":0.41634661259541983},{"id":8,"x":0.040351720363718,"y":0.24791269083969467},{"id":9,"x":0.39508903513695554,"y":0.24341603053435115},{"id":10,"x":0.75323796026044,"y":0.2515207538167939},{"id":11,"x":0.39040574483610235,"y":0.05090648854961832}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.011927480916030535,"y":0.468372138248257},{"id":2,"x":0.18616256133530418,"y":0.14622627580807365},{"id":3,"x":0.18828757230310272,"y":0.3399834048771269},{"id":4,"x":0.18805556118006764,"y":0.5837811148007911},{"id":5,"x":0.18956890645986463,"y":0.7312391587529782},{"id":6,"x":0.39170859740417524,"y":0.2832758789425018},{"id":7,"x":0.39346977274721423,"y":0.5468819603336911},{"id":8,"x":0.6218583584749023,"y":0.18531951728187582},{"id":9,"x":0.6241046479842874,"y":0.39227196259474495},{"id":10,"x":0.6252647035994627,"y":0.6362544377400261},{"id":11,"x":0.806892502075445,"y":0.43945638106519264}]
						}
					]
				},
				{
					id: 8,
					name: '4321',
					bucketsName: [3, 10, 6, 5, 9, 15, 13, 14, 21, 28, 27], /* 4-3-2-1 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.024254126219577354,"y":0.3099519361175726},{"id":2,"x":0.23218975267341221,"y":0.05621043052005162},{"id":3,"x":0.20472486047295355,"y":0.22117123862618257},{"id":4,"x":0.1864106513180066,"y":0.4613699259623051},{"id":5,"x":0.19696122833118257,"y":0.6520241182466032},{"id":6,"x":0.4278409884843952,"y":0.12925445757062656},{"id":7,"x":0.41770781104873383,"y":0.32446511207371304},{"id":8,"x":0.4130650434403795,"y":0.550853781203508},{"id":9,"x":0.6098336258127092,"y":0.2041407579257116},{"id":10,"x":0.6003104939134152,"y":0.4510335070400539},{"id":11,"x":0.7427786020170803,"y":0.32784026927281545}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.3951504265828468,"y":0.8053435114503816},{"id":2,"x":0.019083969465648852,"y":0.582442748091603},{"id":3,"x":0.2638078132016165,"y":0.5816793893129771},{"id":4,"x":0.5208801077682982,"y":0.5816793893129771},{"id":5,"x":0.7633587786259541,"y":0.583206106870229},{"id":6,"x":0.15267175572519082,"y":0.3694656488549618},{"id":7,"x":0.3929052537045352,"y":0.37328244274809164},{"id":8,"x":0.6342613381230354,"y":0.3702290076335878},{"id":9,"x":0.3973955994611585,"y":0.09312977099236641},{"id":10,"x":0.16951055231252804,"y":0.19770992366412213},{"id":11,"x":0.6320161652447238,"y":0.19923664122137405}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.011927480916030535,"y":0.468372138248257},{"id":2,"x":0.18616256133530418,"y":0.14622627580807365},{"id":3,"x":0.18828757230310272,"y":0.3399834048771269},{"id":4,"x":0.18805556118006764,"y":0.5837811148007911},{"id":5,"x":0.18956890645986463,"y":0.7312391587529782},{"id":6,"x":0.40318260203427353,"y":0.23006349511679863},{"id":7,"x":0.4029189530308246,"y":0.45228216686577444},{"id":8,"x":0.41060168499132704,"y":0.6750705313814027},{"id":9,"x":0.625454530881946,"y":0.31935128846322586},{"id":10,"x":0.6266145864971214,"y":0.5554504474861807},{"id":11,"x":0.806892502075445,"y":0.43945638106519264}]
						}
					]
				},
				{
					id: 9,
					name: '4312',
					bucketsName: [3, 10, 6, 5, 9, 18, 13, 17, 21, 26, 27], /* 4-3-1-2 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.03165173103952787,"y":0.31895235531517907},{"id":2,"x":0.22807997221788415,"y":0.031009256766753517},{"id":3,"x":0.19075160692415813,"y":0.18336947799623543},{"id":4,"x":0.1864106513180066,"y":0.4829709320365606},{"id":5,"x":0.20189296487781624,"y":0.6466238667280394},{"id":6,"x":0.440992285942085,"y":0.13825487676823303},{"id":7,"x":0.38976130395114295,"y":0.32626519591323433},{"id":8,"x":0.4344359018091254,"y":0.5418533620059016},{"id":9,"x":0.6156194261102572,"y":0.36395445130321136},{"id":10,"x":0.7509576902565385,"y":0.17273908783767777},{"id":11,"x":0.7523723707679536,"y":0.5474786240044056}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.38055680287382126,"y":0.7984732824427481},{"id":2,"x":0.012348450830713965,"y":0.6106870229007634},{"id":3,"x":0.2694207453973956,"y":0.6274809160305344},{"id":4,"x":0.5287382128423888,"y":0.6290076335877863},{"id":5,"x":0.7689717108217332,"y":0.5969465648854961},{"id":6,"x":0.06623259991019308,"y":0.3519083969465649},{"id":7,"x":0.3940278401436911,"y":0.36412213740458016},{"id":8,"x":0.7061068702290076,"y":0.3427480916030534},{"id":9,"x":0.15491692860350245,"y":0.09389312977099237},{"id":10,"x":0.6297709923664122,"y":0.09694656488549619},{"id":11,"x":0.3951504265828469,"y":0.21755725190839695}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.011927480916030535,"y":0.468372138248257},{"id":2,"x":0.18616256133530418,"y":0.14622627580807365},{"id":3,"x":0.18828757230310272,"y":0.3399834048771269},{"id":4,"x":0.18805556118006764,"y":0.5837811148007911},{"id":5,"x":0.18956890645986463,"y":0.7312391587529782},{"id":6,"x":0.40318260203427353,"y":0.23006349511679863},{"id":7,"x":0.4029189530308246,"y":0.45228216686577444},{"id":8,"x":0.41060168499132704,"y":0.6750705313814027},{"id":9,"x":0.6139805262518477,"y":0.43957185932870324},{"id":10,"x":0.8142483092716707,"y":0.25194277677661464},{"id":11,"x":0.8156667409102261,"y":0.6266851389704443}]
						}
					]
				},
				{
					id: 10,
					name: '4132',
					bucketsName: [3, 10, 6, 5, 9, 13, 15, 16, 14, 26, 27], /* 4-1-3-2 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.030010514920354024,"y":0.2991642573332555},{"id":2,"x":0.25311752533679654,"y":0.05396032572065},{"id":3,"x":0.20502025094319462,"y":0.2170788605222709},{"id":4,"x":0.1950668764024626,"y":0.45923232640287354},{"id":5,"x":0.19561270661921243,"y":0.6477489191277401},{"id":6,"x":0.3389156138779067,"y":0.34174872956411667},{"id":7,"x":0.5115577751751723,"y":0.07674210524180439},{"id":8,"x":0.5252013461782424,"y":0.30763810636866656},{"id":9,"x":0.5213706015074675,"y":0.5154427569229252},{"id":10,"x":0.7594039482660837,"y":0.17535347964836717},{"id":11,"x":0.7719130925275972,"y":0.484560068551138}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.3917826672653794,"y":0.8229007633587786},{"id":2,"x":0.008980691513246519,"y":0.6183206106870229},{"id":3,"x":0.2795240233497979,"y":0.633587786259542},{"id":4,"x":0.5253704535249214,"y":0.6343511450381679},{"id":5,"x":0.7745846430175123,"y":0.6160305343511451},{"id":6,"x":0.3929052537045352,"y":0.4687022900763359},{"id":7,"x":0.019083969465648852,"y":0.33587786259541985},{"id":8,"x":0.40188594521778176,"y":0.3061068702290076},{"id":9,"x":0.7566232599910192,"y":0.33587786259541985},{"id":10,"x":0.5455770094297261,"y":0.09083969465648854},{"id":11,"x":0.20543331836551412,"y":0.09312977099236641}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.011927480916030535,"y":0.468372138248257},{"id":2,"x":0.1881873856817921,"y":0.20338031769493997},{"id":3,"x":0.18693768940544409,"y":0.3862978870957945},{"id":4,"x":0.1860307368335797,"y":0.5798394567396279},{"id":5,"x":0.188219023562206,"y":0.7233558426306519},{"id":6,"x":0.339738105844318,"y":0.48035878200066146},{"id":7,"x":0.5041601703552218,"y":0.23154931544063553},{"id":8,"x":0.5071183121739189,"y":0.43364397513515707},{"id":9,"x":0.5032901286438402,"y":0.6504505656009342},{"id":10,"x":0.7528286374282032,"y":0.25095736226132387},{"id":11,"x":0.763696249350369,"y":0.6069768486646284}]
						}
					]
				},
				{
					id: 11,
					name: '4141',
					bucketsName: [3, 10, 6, 5, 9, 13, 20, 18, 17, 19, 26], /* 4-1-4-1 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.049143984103369204,"y":0.2993342540953962},{"id":2,"x":0.24010108005030373,"y":0.011011450362071658},{"id":3,"x":0.20279840088442477,"y":0.15968712498253343},{"id":4,"x":0.176553599756701,"y":0.5040516013759545},{"id":5,"x":0.18458051470890427,"y":0.6939885727554433},{"id":6,"x":0.29565375305151204,"y":0.31920549210511173},{"id":7,"x":0.5139152028998611,"y":0.010069218977322231},{"id":8,"x":0.5067744593583812,"y":0.16147314566705845},{"id":9,"x":0.496281676132861,"y":0.4715094607146086},{"id":10,"x":0.5001345953099186,"y":0.7065469701671037},{"id":11,"x":0.7406594964696986,"y":0.3119067146620527}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.38616973506960034,"y":0.8122137404580153},{"id":2,"x":0.012348450830713963,"y":0.5633587786259542},{"id":3,"x":0.2705433318365514,"y":0.5671755725190839},{"id":4,"x":0.5163897620116749,"y":0.567175572519084},{"id":5,"x":0.7678491243825774,"y":0.5603053435114503},{"id":6,"x":0.38729232150875614,"y":0.3900763358778626},{"id":7,"x":0.0044903457566232595,"y":0.20763358778625954},{"id":8,"x":0.2604400538841491,"y":0.21526717557251906},{"id":9,"x":0.5163897620116749,"y":0.21450381679389313},{"id":10,"x":0.7678491243825774,"y":0.21221374045801528},{"id":11,"x":0.3906600808262236,"y":0.06793893129770992}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.03352681837236255,"y":0.4703469065682512},{"id":2,"x":0.16119329530416487,"y":0.13342183916785166},{"id":3,"x":0.17074211333130584,"y":0.30549860370209025},{"id":4,"x":0.16915720061284684,"y":0.5778686277090462},{"id":5,"x":0.17472019458561971,"y":0.7479912055129218},{"id":6,"x":0.30059150181221783,"y":0.4488255175113559},{"id":7,"x":0.4744627466067319,"y":0.16848278646202444},{"id":8,"x":0.47472112263011185,"y":0.336087938121368},{"id":9,"x":0.46751823185588653,"y":0.5381133108577831},{"id":10,"x":0.4902764138336,"y":0.710160526386836},{"id":11,"x":0.7373735328460257,"y":0.42171891978995824}]
						}
					]
				},
				{
					id: 12,
					name: '3412',
					bucketsName: [3, 8, 4, 7, 20, 18, 17, 19, 21, 25, 24], /* 3-4-1-2 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.010512047821405381,"y":0.28493358337922586},{"id":2,"x":0.1884814149663447,"y":0.03049415583875381},{"id":3,"x":0.204198294852089,"y":0.25367118982253967},{"id":4,"x":0.16160427334971766,"y":0.5538070437527227},{"id":5,"x":0.43246094515869354,"y":0.01489188206515732},{"id":6,"x":0.4327931429030392,"y":0.2439083742594595},{"id":7,"x":0.41682163963801055,"y":0.47135476600964976},{"id":8,"x":0.42210013891057946,"y":0.6829630592383754},{"id":9,"x":0.5911405462720182,"y":0.33182014213675703},{"id":10,"x":0.7485194515908961,"y":0.15604476783850207},{"id":11,"x":0.7541960858450942,"y":0.4868382996605321}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.3906600808262236,"y":0.816030534351145},{"id":2,"x":0.008980691513246519,"y":0.5809160305343511},{"id":3,"x":0.2671755725190839,"y":0.6305343511450382},{"id":4,"x":0.5354737314773237,"y":0.6320610687022901},{"id":5,"x":0.7712168837000448,"y":0.5809160305343511},{"id":6,"x":0.3973955994611585,"y":0.46946564885496184},{"id":7,"x":0.08531656937584194,"y":0.3526717557251908},{"id":8,"x":0.3985181859003143,"y":0.3137404580152672},{"id":9,"x":0.7027391109115402,"y":0.35419847328244275},{"id":10,"x":0.2570722945666816,"y":0.083206106870229},{"id":11,"x":0.5253704535249214,"y":0.08244274809160305}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.03352681837236255,"y":0.4703469065682512},{"id":2,"x":0.1868375027841335,"y":0.1817011983585424},{"id":3,"x":0.18693768940544409,"y":0.41388949352393684},{"id":4,"x":0.18873050262889696,"y":0.7158266598497581},{"id":5,"x":0.4291731207942711,"y":0.1192967447573923},{"id":6,"x":0.4295053185386168,"y":0.34831323695169447},{"id":7,"x":0.4258669622910213,"y":0.5685610796700886},{"id":8,"x":0.4295000455585478,"y":0.7765682264563549},{"id":9,"x":0.60183158017292,"y":0.4218343980534689},{"id":10,"x":0.7197565064355668,"y":0.29825725899528216},{"id":11,"x":0.7204999966252928,"y":0.6020497760881743}]
						}
					]
				},
				{
					id: 13,
					name: '3421',
					bucketsName: [3, 8, 4, 7, 20, 18, 17, 19, 23, 22, 27], /* 3-4-2-1 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.049143984103369204,"y":0.310134757132524},{"id":2,"x":0.21889075998060187,"y":0.05028984226662611},{"id":3,"x":0.214883724036462,"y":0.3184742080453062},{"id":4,"x":0.17639948298961872,"y":0.5628074629503291},{"id":5,"x":0.4447874113314867,"y":0.005878398788436721},{"id":6,"x":0.4574506723600826,"y":0.18629461423545754},{"id":7,"x":0.44641205891781266,"y":0.4029515801078406},{"id":8,"x":0.4508686020992759,"y":0.6829630592383754},{"id":9,"x":0.7017706989092644,"y":0.14646775928604894},{"id":10,"x":0.7266798213889415,"y":0.5717235032179581},{"id":11,"x":0.7568674431411875,"y":0.39153229825087743}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.38055680287382126,"y":0.8274809160305343},{"id":2,"x":0.056129321957790745,"y":0.5503816793893129},{"id":3,"x":0.3996407723394701,"y":0.5648854961832062},{"id":4,"x":0.7633587786259541,"y":0.5458015267175572},{"id":5,"x":0.0044903457566232595,"y":0.3374045801526718},{"id":6,"x":0.2694207453973956,"y":0.3381679389312977},{"id":7,"x":0.5231252806466098,"y":0.34122137404580155},{"id":8,"x":0.7712168837000448,"y":0.3366412213740458},{"id":9,"x":0.11113605747642567,"y":0.18167938931297709},{"id":10,"x":0.673551863493489,"y":0.18396946564885497},{"id":11,"x":0.38953749438706775,"y":0.08931297709923663}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.03352681837236255,"y":0.4703469065682512},{"id":2,"x":0.1868375027841335,"y":0.1817011983585424},{"id":3,"x":0.18693768940544409,"y":0.41388949352393684},{"id":4,"x":0.18873050262889696,"y":0.7158266598497581},{"id":5,"x":0.4291731207942711,"y":0.1192967447573923},{"id":6,"x":0.4295053185386168,"y":0.34831323695169447},{"id":7,"x":0.4258669622910213,"y":0.5685610796700886},{"id":8,"x":0.4295000455585478,"y":0.7765682264563549},{"id":9,"x":0.6335538282678979,"y":0.2562847594846147},{"id":10,"x":0.6313391766389266,"y":0.5771295668225782},{"id":11,"x":0.7987932046894932,"y":0.44733969718751904}]
						}
					]
				},
				{
					id: 14,
					name: '523',
					bucketsName: [3, 12, 8, 4, 7, 11, 18, 17, 25, 26, 24], /* 5-2-3 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.055989337174608134,"y":0.29131825574752795},{"id":2,"x":0.2624351682133141,"y":0.01697422808048594},{"id":3,"x":0.22367169439423262,"y":0.14719366955976967},{"id":4,"x":0.22991653035894824,"y":0.3413690243792176},{"id":5,"x":0.2137663774751153,"y":0.4963168661280114},{"id":6,"x":0.23590781967927274,"y":0.6886305107018683},{"id":7,"x":0.434266918489734,"y":0.16661460865787975},{"id":8,"x":0.43991989010447063,"y":0.49088848829945503},{"id":9,"x":0.6598097834814806,"y":0.11246331618074781},{"id":10,"x":0.6913485525353236,"y":0.5157240200228503},{"id":11,"x":0.7292074741693134,"y":0.32618278773534043}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.42345188313875165,"y":0.8270515267175572},{"id":2,"x":0.01120832397844634,"y":0.6965648854961831},{"id":3,"x":0.22077240963179165,"y":0.7039360687022901},{"id":4,"x":0.4291525174000898,"y":0.7166626908396946},{"id":5,"x":0.6479779411764705,"y":0.70471731870229},{"id":6,"x":0.833757226650202,"y":0.697286498091603},{"id":7,"x":0.17011569656488548,"y":0.42743916984732827},{"id":8,"x":0.6629749943870678,"y":0.42729604007633587},{"id":9,"x":0.15520634541984732,"y":0.1428196564885496},{"id":10,"x":0.42715291030534347,"y":0.1281607824427481},{"id":11,"x":0.7017042265379434,"y":0.14432251908396945}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.0058530078765667085,"y":0.4713283817941294},{"id":2,"x":0.16051478627979024,"y":0.1087805242270233},{"id":3,"x":0.15791520710578358,"y":0.24439819689391948},{"id":4,"x":0.17320684930582272,"y":0.4349835229918805},{"id":5,"x":0.1652710143020093,"y":0.6169310749792456},{"id":6,"x":0.16357838769986704,"y":0.785837281740809},{"id":7,"x":0.41371801621209364,"y":0.24041804607825273},{"id":8,"x":0.4193759238261081,"y":0.5647041056688332},{"id":9,"x":0.6828245540324377,"y":0.2168681788729828},{"id":10,"x":0.6954586142777115,"y":0.6579335570764236},{"id":11,"x":0.7522222447202705,"y":0.4305876504275754}]
						}
					]
				},
				{
					id: 15,
					name: '532',
					bucketsName: [3, 12, 8, 4, 7, 11, 15, 13, 14, 26, 27], /* 5-3-2 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.013247620437116251,"y":0.2823178365499215},{"id":2,"x":0.2287349684779839,"y":0.018774311920007232},{"id":3,"x":0.1973657848447736,"y":0.14538489635133692},{"id":4,"x":0.1962163306236181,"y":0.31976801830496215},{"id":5,"x":0.17431248510204586,"y":0.5269182913998735},{"id":6,"x":0.1693293762997181,"y":0.6670295046276128},{"id":7,"x":0.4344615879369724,"y":0.07671451050459883},{"id":8,"x":0.42738505971511004,"y":0.3075893260782009},{"id":9,"x":0.4226780767871381,"y":0.5483505396141738},{"id":10,"x":0.6899615016315829,"y":0.17763171075776132},{"id":11,"x":0.6951950501804194,"y":0.45560403241383846}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.4089722721149528,"y":0.8323890744274809},{"id":2,"x":0.013006216322406824,"y":0.642581106870229},{"id":3,"x":0.18420941850022451,"y":0.6993916984732824},{"id":4,"x":0.4080426302200269,"y":0.7132454675572519},{"id":5,"x":0.6231670268298158,"y":0.7016042461832062},{"id":6,"x":0.8118580208801077,"y":0.6434100667938931},{"id":7,"x":0.1436472131791648,"y":0.42278148854961833},{"id":8,"x":0.41377834530758867,"y":0.4639968988549618},{"id":9,"x":0.7236122025145936,"y":0.4316913167938931},{"id":10,"x":0.5749396609788954,"y":0.14354723282442747},{"id":11,"x":0.23957573248765154,"y":0.14420324427480916}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.0058530078765667085,"y":0.4713283817941294},{"id":2,"x":0.16051478627979024,"y":0.1087805242270233},{"id":3,"x":0.15791520710578358,"y":0.24439819689391948},{"id":4,"x":0.17320684930582272,"y":0.4349835229918805},{"id":5,"x":0.1652710143020093,"y":0.6169310749792456},{"id":6,"x":0.16357838769986704,"y":0.785837281740809},{"id":7,"x":0.4278917866375092,"y":0.17932234613022321},{"id":8,"x":0.4241005139679133,"y":0.4228044154669583},{"id":9,"x":0.42432197913081043,"y":0.6563630526926789},{"id":10,"x":0.7055827360101512,"y":0.3396446686374957},{"id":11,"x":0.7083510505463652,"y":0.5636186099918332}]
						}
					]
				},
				{
					id: 16,
					name: '541',
					bucketsName: [3, 12, 8, 4, 7, 11, 20, 18, 17, 19, 26], /* 5-4-1 */
					ratios: [
						{ 
							ratio: 2.19,
							positions: [{"id":1,"x":0.004206103434954505,"y":0.3111191779822622},{"id":2,"x":0.18845912001380888,"y":0.01697422808048594},{"id":3,"x":0.17928275084045012,"y":0.15258523170942206},{"id":4,"x":0.17567271757913958,"y":0.34497933101581585},{"id":5,"x":0.14225619754892696,"y":0.5125176206837031},{"id":6,"x":0.13234135219996548,"y":0.6760299238252192},{"id":7,"x":0.4411567180920951,"y":0.035120355813164666},{"id":8,"x":0.443439918461963,"y":0.22243379777832417},{"id":9,"x":0.43826185203422563,"y":0.45894867854991733},{"id":10,"x":0.44684626358652346,"y":0.6536604279306586},{"id":11,"x":0.7202660991032924,"y":0.3602917487573268}]
						},
						{ 
							ratio: 0.68,
							positions: [{"id":1,"x":0.3869064324202963,"y":0.8179270038167938},{"id":2,"x":0.004420184104176021,"y":0.6337845896946565},{"id":3,"x":0.208853698922317,"y":0.6791746183206107},{"id":4,"x":0.39083548495734166,"y":0.6061247614503816},{"id":5,"x":0.5824820386169735,"y":0.6764134064885496},{"id":6,"x":0.7746284940502918,"y":0.6365696564885496},{"id":7,"x":0.016637081836551413,"y":0.3485865935114504},{"id":8,"x":0.26563201616524473,"y":0.2941913167938931},{"id":9,"x":0.5345616299955096,"y":0.29463263358778624},{"id":10,"x":0.7690944937135159,"y":0.34861641221374046},{"id":11,"x":0.39078286371800625,"y":0.11942986641221374}]
						},
						{ 
							ratio: 1.46,
							positions: [{"id":1,"x":0.0058530078765667085,"y":0.4713283817941294},{"id":2,"x":0.16051478627979024,"y":0.1087805242270233},{"id":3,"x":0.15791520710578358,"y":0.24439819689391948},{"id":4,"x":0.17320684930582272,"y":0.4349835229918805},{"id":5,"x":0.1652710143020093,"y":0.6169310749792456},{"id":6,"x":0.16357838769986704,"y":0.785837281740809},{"id":7,"x":0.43869084981877826,"y":0.1251245477892293},{"id":8,"x":0.44097405018864616,"y":0.3124379897543888},{"id":9,"x":0.4357959837609088,"y":0.548952870525982},{"id":10,"x":0.4443803953132066,"y":0.7436646199067232},{"id":11,"x":0.7178002308299756,"y":0.4502959407333914}]
						}
					]
				}
			],
			example1: [
				{ id: 1, x: 0, y: 0.45 },
				{ id: 2, x: 0.17, y: 0.14 },
				{ id: 3, x: 0.17, y: 0.28 },
				{ id: 4, x: 0.17, y: 0.62 },
				{ id: 5, x: 0.17, y: 0.76 },
				{ id: 6, x: 0.45, y: 0.14 },
				{ id: 7, x: 0.45, y: 0.28 },
				{ id: 8, x: 0.45, y: 0.62 },
				{ id: 9, x: 0.45, y: 0.76 },
				{ id: 10, x: 0.73, y: 0.34 },
				{ id: 11, x: 0.73, y: 0.57 },
			],
			example2: [
				{ id: 1, x: 0.4, y: 0 },
				{ id: 2, x: 0.15, y: 0.2 },
				{ id: 3, x: 0.33, y: 0.2 },
				{ id: 4, x: 0.51, y: 0.2 },
				{ id: 5, x: 0.71, y: 0.2 },
				{ id: 6, x: 0.15, y: 0.4 },
				{ id: 7, x: 0.33, y: 0.4 },
				{ id: 8, x: 0.51, y: 0.4 },
				{ id: 9, x: 0.71, y: 0.4 },
				{ id: 10, x: 0.3, y: 0.6 },
				{ id: 11, x: 0.5, y: 0.6 },
			],
			ruoli: [],
			liste: [],
			lista_selected: this.$route.params.id,
			lista_selected_label: '',
			sistemi_gioco: [],
			sistema_gioco: 4,
			lista_in_campo: null,
			ruoli: [],
			moduli_ruoli: {
				1: [3, 10, 6, 5, 9, 20, 18, 17, 19, 26, 27], /* 4-4-2 */
				2: [3, 10, 6, 5, 9, 13, 18, 17, 16, 26, 27], /* 4-4-2 rombo*/
				3: [3, 10, 6, 5, 9, 20, 18, 17, 19, 21, 26], /* 4-4-1-1 */
				4: [3, 10, 6, 5, 9, 15, 13, 14, 25, 27, 24], /* 4-3-3 */
				5: [3, 8, 4, 7, 20, 18, 17, 19, 25, 27, 24], /* 3-4-3 */
				6: [3, 8, 4, 7, 12, 18, 13, 17, 11, 26, 27], /* 3-5-2 */
				7: [3, 10, 6, 5, 9, 18, 17, 23, 21, 22, 26], /* 4-2-3-1 */
				8: [3, 10, 6, 5, 9, 15, 13, 14, 21, 28, 27], /* 4-3-2-1 */
				9: [3, 10, 6, 5, 9, 18, 13, 17, 21, 26, 27], /* 4-3-1-2 */
				10: [3, 10, 6, 5, 9, 13, 15, 16, 14, 26, 27], /* 4-1-3-2 */
				11: [3, 10, 6, 5, 9, 13, 20, 18, 17, 19, 26], /* 4-1-4-1 */
				12: [3, 8, 4, 7, 20, 18, 17, 19, 21, 25, 24], /* 3-4-1-2 */
				13: [3, 8, 4, 7, 20, 18, 17, 19, 23, 22, 27], /* 3-4-2-1 */
				14: [3, 12, 8, 4, 7, 11, 18, 17, 25, 26, 24], /* 5-2-3 */
				15: [3, 12, 8, 4, 7, 11, 15, 13, 14, 26, 27], /* 5-3-2 */
				16: [3, 12, 8, 4, 7, 11, 20, 18, 17, 19, 26], /* 5-4-1 */
			},
			form: {
				titolo: '',
				data: '',
				note: '',
				live_dal: null,
				live_al: null,
			},
			elenco_pdf: [],
			loading: false,
			header_pdf: null,
			live_dal: null,
			live_al: null,
			form_in_campo: {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			},
			form_non_in_campo: [],
			formazione_id: null,
			formazione_ids: [],
			positions: {
				1: {top: '', left: ''},
				2: {top: '', left: ''},
				3: {top: '', left: ''},
				4: {top: '', left: ''},
				5: {top: '', left: ''},
				6: {top: '', left: ''},
				7: {top: '', left: ''},
				8: {top: '', left: ''},
				9: {top: '', left: ''},
				10: {top: '', left: ''},
				11: {top: '', left: ''},
			},
			campo: 0,
			show_levels: false,
			list_to_merge: [],
			current_shadow_team: 0,
			valutazioni: [],
			val_max: 5,
			loading_val: false,
		}
	},
	created: function () {
		
		//if(this.$route.params.formazioneId)
		//	this.sistema_gioco = _.clone(this.$route.params.formazioneId);

		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
			if (response.ok)
			{
				this.sistemi_gioco = response.data; 
			}
		}, (response) => {
		}); 
		
		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
			if (response.ok)
			{
				this.ruoli = response.data;    
			}
		}, (response) => {
		});
		
		this.$http.get(this.$store.state.apiEndPoint + '/liste', {params: {no_players: true }}).then((response) => {
			if (response.ok)
			{
				this.liste = response.data; 
			}
		}, (response) => {
		});

		this.listaInCampo();
		this.headerPdf();

		console.log(this.$route.params);
	},

	methods: {
		eliminaLista: function() {

			if(window.confirm('Confermi la rimozione della formazione?')) {

				this.$http.get(this.$store.state.apiEndPoint + '/scouting/lista/in/campo/' + this.$route.params.id + '/' + this.sistema_gioco +'/delete').then(function(p) {
					console.log(p);
					this.listaInCampo();
				});

			}


		},
		formazioneIsChanged: function(toStore) {

			var found = _.find(this.lista_in_campo.formazioni, { id_sistema_gioco: this.sistema_gioco });
			found.buckets_v2 = JSON.stringify(toStore);
			console.log('I will store this', toStore);

		},
		applyForm: function(w) {

			this.$refs.listaInCampo.applyFormation(w);

		},
		listaInCampo() {
			this.loading = true;
			this.form.titolo = null;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/lista/in/campo/' + this.$route.params.id).then((response) => {
				if (response.ok)
				{
					this.lista_in_campo = response.data; 
					this.lista_selected_label = this.lista_in_campo.nome;
					if(this.lista_in_campo.formazioni.length > 0) {
						if(this.$route.params.formazioneId)
							this.sistema_gioco = _.clone(this.$route.params.formazioneId);
						else
							this.sistema_gioco = this.lista_in_campo.formazioni[0].id_sistema_gioco;
					} else
						this.sistema_gioco = 1
					this.setFormazioneLista();
					this.loading = false;
				}
			}, (response) => {
			});
		},
		setFormazioneLista() {

			this.form_in_campo = {
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: [],
				7: [],
				8: [],
				9: [],
				10: [],
				11: [],
			};
			this.form_non_in_campo = [];
			this.formazione_ids = [];
			this.formazione_id = null;
			this.current_shadow_team = 0;
			if(this.sistema_gioco == 0) {
				this.sistema_gioco = 4;
			}
			this.positions = {
				1: {top: '', left: ''},
				2: {top: '', left: ''},
				3: {top: '', left: ''},
				4: {top: '', left: ''},
				5: {top: '', left: ''},
				6: {top: '', left: ''},
				7: {top: '', left: ''},
				8: {top: '', left: ''},
				9: {top: '', left: ''},
				10: {top: '', left: ''},
				11: {top: '', left: ''},
			};

			for(var i in this.lista_in_campo.formazioni) {
				if(this.lista_in_campo.formazioni[i].id_sistema_gioco == this.sistema_gioco) {
					this.formazione_id = this.lista_in_campo.formazioni[i].id;
					this.form_in_campo = JSON.parse(this.lista_in_campo.formazioni[i].in_campo);
					this.form_non_in_campo = JSON.parse(this.lista_in_campo.formazioni[i].non_in_campo);
					if(this.lista_in_campo.formazioni[i].positions) {
						this.positions = JSON.parse(this.lista_in_campo.formazioni[i].positions);
					}
					this.campo = this.lista_in_campo.formazioni[i].campo;
					this.current_shadow_team = this.lista_in_campo.formazioni[i].shadow_team;
					this.form.titolo = this.lista_in_campo.formazioni[i].titolo;
					break;
				}
			}
			if(this.formazione_id) {
				for(var i in this.form_in_campo) {
					var array_gio = this.form_in_campo[i];
					for(var j in array_gio) {
						var item_gio = array_gio[j];
						var id_gio = null;
						var level = 0;
						if(item_gio.id) {
							id_gio = item_gio.id;
							level = item_gio.level;
						} else {
							id_gio = item_gio;
						}
						for(var x in this.lista_in_campo.giocatori) {
							var giocatore = this.lista_in_campo.giocatori[x]; 
							if(giocatore.id == id_gio) {
								giocatore.level = level;
								array_gio[j] = giocatore;
								this.formazione_ids.push(id_gio);
								break;
							}
						}
					}
					this.form_in_campo[i] = this.form_in_campo[i].filter(item => item.cognome);
				}
				for(var i in this.form_non_in_campo) {

					var item_gio = this.form_non_in_campo[i];
					var id_gio = null;
					var level = 0;
					if(item_gio.id) {
						id_gio = item_gio.id;
						level = item_gio.level;
					} else {
						id_gio = item_gio;
					}
					for(var x in this.lista_in_campo.giocatori) {
						var giocatore = this.lista_in_campo.giocatori[x]; 
						if(giocatore.id == id_gio) {
							giocatore.level = level;
							this.form_non_in_campo[i] = giocatore;
							this.formazione_ids.push(id_gio);
							break;
						}
					}
				}
				//this.form_non_in_campo = this.form_non_in_campo.filter(item => item.cognome);
			} 

			// for(var i in this.positions) {
			// 	this.$refs['draggablediv_' + i][0].$el.style.top = this.positions[i].top;
			// 	this.$refs['draggablediv_' + i][0].$el.style.left = this.positions[i].left;
			// }
			// for(var i in this.lista_in_campo.giocatori) {
			// 	var giocatore = this.lista_in_campo.giocatori[i];
			// 	if(this.formazione_ids.indexOf(giocatore.id) === -1) {
			// 		var index = this.getPosizioneGiocatore(giocatore);
			// 		giocatore.level = 0;
			// 		if(index > -1) {
			// 			this.form_in_campo[index + 1].push(giocatore);
			// 		} else {
			// 			this.form_non_in_campo.push(giocatore);
			// 		}
			// 	}
			// }

		},
		getPosizioneGiocatore(giocatore) {	
			var index = -1;
			var array_ruoli = this.moduli_ruoli[this.sistema_gioco];

			if(giocatore.last_carriera_sportiva.ruolo_ideale && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0) {
				var ruolo_ideale = giocatore.last_carriera_sportiva.ruolo_ideale[0];
				if(array_ruoli.indexOf(ruolo_ideale.id) > -1) {
					return array_ruoli.indexOf(ruolo_ideale.id);
				} else if(giocatore.last_carriera_sportiva.altri_ruoli && giocatore.last_carriera_sportiva.altri_ruoli.length > 0) {
					for(var i in giocatore.last_carriera_sportiva.altri_ruoli) {
						var altro_ruolo_id = giocatore.last_carriera_sportiva.altri_ruoli[i].id;
						if(array_ruoli.indexOf(altro_ruolo_id) > -1) {
							return array_ruoli.indexOf(altro_ruolo_id);
						}
					}
				} 
			}  
			return index;
		},
		salvaFormazioneLista() {

			this.lista_in_campo_formazione.campo = _.cloneDeep(this.campo);
			this.lista_in_campo_formazione.titolo = _.cloneDeep(this.form.titolo);

			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/formazione/lista-2/' + this.lista_selected + '/' + this.sistema_gioco, this.lista_in_campo_formazione).then(function(data) {
				this.$store.commit(types.ALERTS_PUSH, { msg: data.data.message, status: 1 });
				//this.listaInCampo();
			});

			return false;

			var svg = $("#field_lista").find("svg");
			var form_data = {
				form_in_campo: {
					1: [],
					2: [],
					3: [],
					4: [],
					5: [],
					6: [],
					7: [],
					8: [],
					9: [],
					10: [],
					11: [],
				},
				form_non_in_campo: [],
				positions: {
					1: {top: '', left: ''},
					2: {top: '', left: ''},
					3: {top: '', left: ''},
					4: {top: '', left: ''},
					5: {top: '', left: ''},
					6: {top: '', left: ''},
					7: {top: '', left: ''},
					8: {top: '', left: ''},
					9: {top: '', left: ''},
					10: {top: '', left: ''},
					11: {top: '', left: ''},
				},
				campo: this.campo,
				width: svg.width(),
				height: svg.height(),
				titolo: this.form.titolo,
			};
			for (var key in this.form_in_campo) {
				var array_gio = this.form_in_campo[key];
				for(var i in array_gio) {
					var giocatore = array_gio[i];
					if(giocatore && giocatore instanceof Object) {
						form_data.form_in_campo[key].push({id: giocatore.id, level: giocatore.level });
					}
				}
				form_data.positions[key].top = this.$refs['draggablediv_' + key][0].$el.style.top;
				form_data.positions[key].left = this.$refs['draggablediv_' + key][0].$el.style.left;
			}
			for (var i in this.form_non_in_campo) {
				var giocatore = this.form_non_in_campo[i];
				if(giocatore && giocatore instanceof Object) {
					form_data.form_non_in_campo.push({id: giocatore.id, level: giocatore.level });
				}
			}
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/formazione/lista/' + this.lista_selected + '/' + this.sistema_gioco, form_data).then(response => {
				if (response.ok) {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.listaInCampo();		
				}
			}, (response) => {
			});
		},
		backArchivio() {
			this.$router.push({ name: "youthscout_dashboard"});
		},
		getNomeRuolo(id) {
			for(var i in this.ruoli) {
				var ruolo = this.ruoli[i];
				if(ruolo.id == id) {
					return ruolo.nome.toUpperCase();
				}
			}
			return "";
		},
		salvaPdf() {
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/pdf/lista/' + this.lista_selected + '/' + this.sistema_gioco, this.form).then(response => {
				if (response.ok) {
					window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + response.data + "/" + Auth.user.id + "/" + Auth.user.api_token;		
				}
			}, (response) => {
			});
		},

		archivioPdfLista() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/pdf/lista/' + this.lista_selected).then((response) => {
				if (response.ok)
				{
					this.elenco_pdf = response.data; 
					this.$refs.pdfModal.show();
				}
			}, (response) => {
			});
		},
		formatDate (data) {
			var m = moment(data);
			var format = m.format('DD/MM/YYYY'); 
			return format;
		},
		downloadPdf(pdf) {
			window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/lista/' + pdf + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		eliminaPdf(pdf) {
			if(confirm("Confermi l'eliminazione del pdf?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/pdf/lista/' + pdf).then((response) => {
					if (response.ok)
					{
						this.elenco_pdf = response.data; 
					}
				}, (response) => {
				});
			}
		},
		pdfFieldLista() {
			window.open(this.$store.state.apiEndPoint + '/scouting/download/formazione-2/' + this.formazione_id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},

		headerPdf() {
			this.$http.get(this.$store.state.apiEndPoint + '/headerpdf/scouting').then((response) => {
				if (response.ok)
				{
					this.header_pdf = response.data;    
				}
			}, (response) => {
			});
		},
		showGrad(report) {
			if(!Auth.isAdmin()) {
				return false;
			}
			if(!report.partita_report.partita.newscout) {
				return false;
			}  
			if(!report.gradimento) {
				return false;
			} 
			if(!this.form.live_dal && !this.form.live_al) {
				return true;
			} else if(this.form.live_dal && this.form.live_al) {
				if(report.partita_report.created_at >= this.form.live_dal + ' 00:00:00' && report.partita_report.created_at <= this.form.live_al + ' 23:59:59') {
					return true;

				}
			} else if(this.form.live_dal && !this.form.live_al) {
				if(report.partita_report.created_at >= this.form.live_dal + ' 00:00:00') {
					return true;
				}

			} else if(!this.form.live_dal && this.form.live_al) {
				if(report.partita_report.created_at <= this.form.live_al + ' 23:59:59') {
					return true;
				}
			}
			return false;
		},
		getTitleGrad(report) {
			var title = '';
			if(report.gradimento) {
				title = report.gradimento.nome + ' del ' + moment(report.partita_report.created_at).format("DD/MM/YYYY") + ' di ' + report.partita_report.utente.nomeCompleto;
			}
			return title;
		},
		getBgByLevel(level) {
			if(level == 1) {
				return 'bg-info';
			} else if(level == 2) {
				return 'bg-success';
			} else if(level == 3) {
				return 'bg-warning';
			} else if(level == 4) {
				return 'bg-danger';	
			} 
			return "";
		},

		selectFormazioniListe() {
			this.list_to_merge = [];
			this.$refs.listeModal.show();
		},

		addListaToMerge() {
			this.list_to_merge.push({lista_id: 0, formazione_id: 0});
		},

		removeListaToMerge(item) {
			var index = this.list_to_merge.indexOf(item);
			this.list_to_merge.splice(index, 1);
		},

		mergeList() {
			if(this.list_to_merge.length == 0) {
				alert("ATTENZIONE! Non hai selezionato alcuna lista da unire");
				return;
			}
			for(var i in this.list_to_merge) {
				var list = this.list_to_merge[i];
				if(!list.lista_id) {
					alert("ATTENZIONE! Non hai selezionato una lista");
					return;
				}
				if(list.lista_id && !list.formazione_id) {
					alert("ATTENZIONE! Non hai selezionato una formazione per la lista");
					return;
				}
			}
			if(confirm("Confermi di unire nella lista '" + this.lista_selected_label + "' le formazioni selezionate?")) {
				var svg = $("#field_lista").find("svg");
				var form = {
					list_to_merge: this.list_to_merge,
					id_lista: this.lista_selected,
					id_sistema_gioco: this.sistema_gioco,
					campo: this.campo,
					width: svg.width(),
					height: svg.height(),
					titolo: this.form.titolo,
				}
				this.$http.post(this.$store.state.apiEndPoint + '/scouting/unisci/formazioni/lista-2', form).then(response => {
					if (response.ok) {
						this.$refs.listeModal.hide();
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.listaInCampo();		
					}
				}, (response) => {
				});
			}
		},

		setCurrentShadowTeam() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/current/shadow/team/' + this.formazione_id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.listaInCampo();
				}
			}, (response) => {
			}); 
		},

		unsetCurrentShadowTeam() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/current/shadow/team/unset/' + this.formazione_id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.listaInCampo();
				}
			}, (response) => {
			}); 
		},

		isCurrentShadowTeamFormation(id) {
			if(this.lista_in_campo) {
				for(var i in this.lista_in_campo.formazioni) {
					var form = this.lista_in_campo.formazioni[i];
					if(form.id_sistema_gioco == id) {
						if(form.shadow_team) {
							return true;
						} else {
							return false;
						}
					}
				}
			}
			return false;
		},

		isCurrentShadowTeamList(id) {	
			var lista = this.liste.find((item) => item.id == id);
			for(var i in lista.formazioni) {
				var form = lista.formazioni[i];
				if(form.shadow_team) {
					return true;
				} 
			}
			return false;
		},

		hasFormation(id) {
			if(this.lista_in_campo) {
				for(var i in this.lista_in_campo.formazioni) {
					var form = this.lista_in_campo.formazioni[i];
					if(form.id_sistema_gioco == id) {
						return true;
					}
				}
			}
			return false;
		},

		onShowVal(giocatore_id) {
			this.loading_val = true;
			this.valutazioni = [];
			this.val_max = 0;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/valutazioni/giocatore/' + giocatore_id).then((response) => {
				if (response.ok)
				{
					this.valutazioni = response.data;
					for(var i in this.valutazioni) {
						var value = this.valutazioni[i].value;
						if(value > this.val_max) {
							this.val_max = value;
						}
					}
					this.loading_val = false;
				}
			}, (response) => {
			}); 
		}
	},

	watch: {
		sistema_gioco: function () {
			console.log('ok');

			if(!this.lista_in_campo_formazione) {
				console.log('FORMAZIONE HAS ???????? No.');
				this.lista_in_campo.formazioni.push({
					id: null,
					in_campo: null,
					non_in_campo_old: null,
					id_sistema_gioco: this.sistema_gioco,
					id_lista: this.lista_in_campo.id,
					positions: null,
					buckets_v2: null,
					campo: 0,
					parent_ids: null,
					width: null,
					height: null,
					titolo: null
				});
			}
			//console.log(this.lista_in_campo_formazione)

			this.setFormazioneLista();
		}, 
	},

	computed: {
		formazioneOf: function() {

			if(!this.lista_in_campo || !this.sistemi_gioco) return {};

			var keyed = _.keyBy(_.map(this.sistemi_gioco, function(p) {
				return _.find(this.lista_in_campo.formazioni, { id_sistema_gioco: p.id });
			}.bind(this)), 'id_sistema_gioco');

			var o = {};
			_.each(this.sistemi_gioco, function(p) {
				o[p.id] = keyed[p.id] || null
			});

			return o;

		},
		lista_in_campo_formazione: function() {

			var found = _.find(this.lista_in_campo.formazioni, { id_sistema_gioco: this.sistema_gioco });
			if(found) return found;

			return false;

		},
		campoRatio: function() {

			if(this.campo == 0 || this.campo == 3) return 2.19;
			if(this.campo == 1 || this.campo == 4) return 1.46;
			if(this.campo == 2 || this.campo == 5) return 0.68;

		},
		auth: function() {
			return Auth;
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YY'); 
			}
			return "";
		}
	},

}
</script>
