import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueResource from 'vue-resource'
import App from './components/App.vue'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { sync } from 'vuex-router-sync'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import store from './store'
import babelPolyfill from 'babel-polyfill'
import VueHtml2Canvas from 'vue-html2canvas'
import VueSmartWidget from 'vue-smart-widget'
import VueVirtualScroller from 'vue-virtual-scroller'
import VueKonva from 'vue-konva'

Vue.use(BootstrapVue)
Vue.use(VueResource)
Vue.use(VueRouter)
Vue.use(VueI18n)
Vue.use(VueHtml2Canvas)
Vue.use(VueSmartWidget)
Vue.use(VueVirtualScroller)
Vue.use(VueKonva)

Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false

import languages from './lang/lang';

const messages = languages;

const i18n = new VueI18n({
  locale: 'it', 
  messages,
  silentTranslationWarn: true,
})

var RoutesModule = require('./routes');

const routes = RoutesModule.getRoutes();

export const router = new VueRouter({ routes })

sync(store, router)

// authentication service
var Auth = require('./auth.js');

const EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus
    }
  }
});

var moduli_categoria = ['football', 'performance', 'analysis', 'medical', 'reports', 'contratti'];

router.beforeEach((to, from, next) => {
    
    if(Auth.user && Auth.user.id && to.name) {
        var params = {user_id: Auth.user.id, name: to.name, path: to.path};
        if(to.meta && to.meta.logModel) {
            params.model = to.meta.logModel;
            if(to.meta.logModelId) {
                params.model_id = to.params[to.meta.logModelId];
            }
        }
        if(to.meta && to.meta.logCategory) {
            params.category_id = store.state.categoriaId;
        }
        axios.get('/log/add/frontend/route', {params: params});
    }

  if (to.matched.some(record => record.meta.requiresAuth)) {
      var default_path = {
        path: '/login',
        query: { redirect: to.fullPath }
      };
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Auth.checkLocalStorage()) {
      next(default_path)
    } else {
      /*** Controllo permessi route ***/
      if (to.matched.some(record => record.meta.permesso)) {

        var cat = moduli_categoria.includes(to.matched[0].meta.module) ? store.state.categoriaId : null;
        if(!Auth.isUserEnable(to.matched[0].meta.permesso, cat)) {
            if(to.matched[0].meta.permesso === null)
                next();
            else {
                var foundPermesso = Auth.user.table_rights.find(item => item.permesso == to.matched[0].meta.permesso);
                if(foundPermesso) {
                    var foundTeam = Auth.user.rosaTeams.find(item => item.id_categoria == foundPermesso.categoria_id);
                    if(foundTeam) {
                        store.commit('changeRosaTeamId', foundTeam.id);
                        store.commit('changeCategoriaId', foundTeam.id_categoria);
                        next();
                    } else next(default_path);
                } else next(default_path);
            }
        } else next();
      } else next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});


Vue.http.interceptors.push((request, next) => {

    if (Auth.checkLocalStorage())
        Vue.http.headers.common['tokenauth'] = 'Bearer ' + Auth.user.api_token;
    else
       Vue.http.headers.common['tokenauth'] = '';

    Vue.http.headers.common['request-path'] = router.app.$route.name;
    Vue.http.headers.common['request-right'] = router.app.$route.meta.permesso;
    Vue.http.headers.common['request-right-cat'] = router.app.$route.meta.permessoCategory;

    next((response) => {
        if (response.status == 401) {
            window.localStorage.clear();
            Auth.user = {};
            Auth.authenticated = false;
            router.replace('/login');
        } else if(response.status == 403) {
            router.replace('/');
        }
    });

});


axios.interceptors.request.use(function (config) {
    config.url = store.state.apiEndPoint + config.url;
    if (Auth.checkLocalStorage()) {
        config.headers['tokenauth'] = 'Bearer ' + Auth.user.api_token;
    }
    else
    {
        config.headers['tokenauth'] = '';
    }

    config.headers['request-path'] = router.app.$route.name;
    config.headers['request-right'] = router.app.$route.meta.permesso;
    config.headers['request-right-cat'] = router.app.$route.meta.permessoCategory;

    return config;
  }, function (error) {
    console.log(error);
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    if (response.status == 401)
        {
            window.localStorage.clear();
      //  localStorage.removeItem('user');

            Auth.user = {};
            Auth.authenticated = false;

            router.replace('/login');
        } else if(response.status == 403) {
            router.replace('/');
        }
    return response;
  }, function (error) {
    console.log(error);
    return Promise.reject(error);
  });

export const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
