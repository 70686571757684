<template>
	<div>
		{{fase.nome}} {{fase.entita}}
	</div>
</template>

<script>

export default {
	props: [ 'fase' ],
}	

</script>