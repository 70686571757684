<template id="rosa">
	<section class="rosa">

		<headapp></headapp>

		<div class="card">
			<div class="card-body">
				<div class="content">
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					<div v-if="!loading">
						<div class="card-title d-flex">
							<span class="mr-auto">Documenti ({{ documenti.length }})</span>
						</div>
						<p>Documenti scaduti o che scadranno nei prossimi 30 giorni.</p>
						<table class="table table-striped">
							<thead>
								<th>Tipo</th>
								<th>Numero</th>
								<th>Scadenza</th>
								<th>Nominativo</th>
								<th>Qualifica</th>
								<th>Telefoni</th>
								<th>Parenti</th>
							</thead>
							<tbody>
								<tr v-for="item in documenti">
									<td><div v-if="auth.isUserEnable('egnyte') && item.documento.path" @click="downloadDoc(item.documento.id)" style="cursor:pointer;" class="color-rosso"> {{ item.documento.tipo.label || item.documento.tipo.nome  }} </div><div v-else> {{ item.documento.tipo.label || item.documento.tipo.nome }} </div><div v-if="!auth.isUserEnable('egnyte')">{{ item.documento.tipo.label || item.documento.tipo.nome }}</div></td>
									<td>{{ item.documento.numero }}</td>
									<td>{{ item.documento.scadenza | formatDate }}</td>
									<td><div @click="viewScheda(item)" style="cursor:pointer;" class="color-rosso">{{ item.persona.cognome + ' ' + item.persona.nome }}</div></td>
									<td>{{ item.persona.last_carriera_sportiva.persona_ruolo_glo_id == 1 ? 'Giocatore' : 'Staff' }}</td>
									<td><div v-for="contatto in item.persona.contatti"><div v-if="contatto.contatto.tipo == 'telefono'">{{ contatto.contatto.valore }}</div></div></td>
									<td><div v-for="relazione in item.persona.relazioni"> <div v-if="relazione.persona_relazioni_glo_id <= 3">{{ relazione.relazionante.cognome }} {{ relazione.relazionante.nome }} ({{ relazione.tipo.nome }}) - tel: <span v-for="cont in relazione.relazionante.contatti"><span v-if="cont.contatto.tipo == 'telefono'">{{ cont.contatto.valore }}</span></span></div></div></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapState } from 'vuex';
var Auth = require('../../../auth.js');
export default {
	data: function(){
		return {
			documenti: [],
			loading: false,
			errors: [],
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId']),
	},
	watch: { 
		rosaTeamId() {
			this.getDocumentiInScadenza();		
		},
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},
	created: function () {
		this.getDocumentiInScadenza();
	},
	methods: {
		getDocumentiInScadenza() {
			if(this.rosaTeamId) {
				this.loading = true;
				axios.get(`/rosa/documenti/scadenza`, { params: { team_id: this.rosaTeamId }}).then((res) => {
					this.documenti = res.data;
					if(!this.auth.isUserEnable('contratti')) {
						this.documenti = this.documenti.filter(item => item.documento.documento_tipo_glo_id != 12);
					}
					this.documenti.sort(function(a, b) {
						return new Date(a.documento.scadenza) - new Date(b.documento.scadenza);
					});
					this.loading = false;
				}).catch((err) => {
				});
			}
		},
		viewScheda(item) {
			if(item.persona.last_carriera_sportiva.persona_ruolo_glo_id == 1) {
				this.$router.push({ name: "football_giocatore_scheda", params: { id: item.persona.id} });	
			} else if(item.staff) {
				this.$router.push({ name: "football_staff_scheda", params: { id: item.persona.id} });
			}
		},
		downloadDoc(id) {
			window.open(this.$store.state.apiEndPoint + '/football/download/egnyte/doc/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
}
</script>
