<template id="partite">
	<section class="partite">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">

					<div class="row flex-items-xs-middle">
						<div class="col-4">

							<div class="text-uppercase color-rosso font-bold font-size-lg">
								{{osservato.cognome}} {{osservato.nome}}
							</div>
							<div class="text-muted">
								{{osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}
							</div>

						</div>
						<div class="col-8 text-right">
							<ul class="list-inline mb-0">
								<li class="list-inline-item"><a :href="'#/scouting/view/giocatore/' + osservato.id">Scheda giocatore</a></li>
								<li class="list-inline-item" v-if="osservato.valutazioni.length > 0"><a :href="'#/scouting/view/valutazione/' + osservato.id + '/' + osservato.valutazioni[0].id">Valutazioni</a></li>
								<!--li class="list-inline-item" v-if="osservato.valutazioni.length == 0"><a :href="'#/scouting/nuova/valutazione/' + osservato.giocatore.id">Valutazioni</a></li-->
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Partite ({{ total }})</span>
					</div>
					<div class="mb-1">
						<b-btn v-b-toggle.collapse variant="primary">Filtra <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
						<b-collapse id="collapse" class="mt-2">
							<b-row class="mb-1">
								<b-col sm="2">
									<b-form-group label-cols="3" content-cols="9" label="Data">
										<b-form-input type="date" v-model="filters.data"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="2">
									<b-input-group>
										<b-form-input type="text" v-model="filters.squadra" placeholder="Squadra"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="filtraPartite"><i class="fa fa-search" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-col>
								<b-col sm="2" v-if="auth.isUserEnable('scout_utenti')">
									<b-form-select v-model="filters.osservatore">
										<option value="0">-- Osservatore --</option>
										<option v-for="item in osservatori" :value="item.id">{{item.nomeCompleto}}</option>
									</b-form-select>
								</b-col>	
							</b-row>
						</b-collapse>
					</div>
				</div></div>
				<v-infinite-scroll :loading="loading" @bottom="nextPage" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
					<div v-for="partita in partite" class="col-6">
						<div class="card h-100">
							<div class="card-body">
								<div class="card-title mb-0">
									<span>{{ partita.competizione.paese.paese }} - {{ partita.competizione.nome }}</span>
								</div>
								<cardpartita :partita="partita" :editable="false" @zoomReport="zoomReport"></cardpartita>
							</div>
						</div>
					</div>
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				</v-infinite-scroll>	
				<b-modal id="reportModal" ref="reportModal" title="Report partita" ok-only ok-title="Chiudi">
					<div class="text-center">
						<div class="row align-items-center"><div class="col-8 text-center"><button type="button" class="btn btn-link" @click="prevPage">PREV</button><button type="button" class="btn btn-link" @click="nextPage">NEXT</button></div><div class="col-4 text-left" style="font-size:1rem;">{{currentPage}} di {{totalPages}}</div></div>
						<canvas id="pdf-canvas" width="450"></canvas>
					</div>
				</b-modal>
			</div>

			<footerapp></footerapp>

		</section>
	</template>

	<script>

	import moment from 'moment'

	import * as types from '../../../store/types'

	import InfiniteScroll from 'v-infinite-scroll'

	var Auth = require('../../../auth.js');

	export default {

		data: function() {
			return {
				osservato: [],
				partite: [],
				filters: {
					data: '',
					squadra: '',
					osservatore: 0,
				},
				osservatori: [],
				loading: false,
				total: 0,
				page: 1,
				errors: [],
				pdfDoc: null,
				totalPages: 0,
				currentPage: 0,
			}
		},

		components: {
			headapp:             require('../../layout/headapp.vue').default,
			menusub:             require('../../layout/menusub.vue').default,
			footerapp:           require('../../layout/footerapp.vue').default,
			cardpartita:         require('../../layout/cardpartita.vue').default,
			'v-infinite-scroll': InfiniteScroll,
		},

		created: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/giocatore/' + this.$route.params.id + '/old').then((response) => {
				if (response.ok) {
					this.osservato = response.data;		
				}
			}, (response) => {
            // error callback
        });
			this.$http.get(this.$store.state.apiEndPoint + '/osservatori').then((response) => {
				if (response.ok)
				{
					this.osservatori = response.data; 
				}
			}, (response) => {
            // error callback
        });
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/giocatore/' +this.$route.params.id + '/old').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.partite = response.data.data; 
            	this.total = response.data.total;
            	this.loading = false;
            }
        }, (response) => {
            // error callback
        }); 
		},
		methods: {
			nextPage() {
				if(this.partite.length < this.total) {
					this.page++;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/giocatore/' + this.osservato.id + '/old?page=' + this.page, {params: this.filters}).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.partite = this.partite.concat(response.data.data);
	            	this.loading = false;
	            }
	        }, (response) => {
	            // error callback
	        });
				}
			},
			filtraPartite() {
				this.loading = true;
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/giocatore/' + this.osservato.id + '/old', {params: this.filters}).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.partite = response.data.data;
            	this.total = response.data.total;
            	this.loading = false; 
            }
        }, (response) => {
            // error callback
        });
			},

			zoomReport: function(pdf, pages) {
				this.pdfDoc = pdf;
				this.totalPages = pages;
				this.currentPage = 0;
				this.showPage(1);
				this.$refs.reportModal.show();

			},
			showPage(page_no) {
				this.currentPage = page_no;
				this.pdfDoc.getPage(page_no).then((page) => {
					var desiredWidth = document.getElementById('pdf-canvas').width;
					var viewport = page.getViewport(1);
					var scale = desiredWidth / viewport.width;
					var scaledViewport = page.getViewport(scale);
					document.getElementById('pdf-canvas').height = scaledViewport.height;
					var renderContext = {
						canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
						viewport: scaledViewport
					};
					page.render(renderContext).then(() => {
					});
				});
			},
			prevPage() {
				if(this.currentPage != 1) {
					this.showPage(this.currentPage - 1);
				}    
			},
			nextPage() {
				if(this.currentPage != this.totalPages) {
					this.showPage(this.currentPage + 1);
				}    
			},
		},
		watch: {
			'filters.data': function () {
				this.filtraPartite();
			},
			'filters.osservatore': function () {
				this.filtraPartite();
			},
		},
		filters: {
			formatDateHour: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('DD/MM/YYYY HH:mm'); 
				}
				return "";
			},
		},
		computed: {
			auth: function() {
				return Auth;
			},
		},
	}
	</script>