<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Prestiti giocatore ({{ prestiti.length }})</span>
					</div>

					<b-row align-v="center" v-if="osservato" class="mb-2">
						<b-col cols="2" class="text-center">
							<b-img :src="osservato.avatar_url" fluid :alt="osservato.cognome + ' ' + osservato.nome" :width="100"/>
						</b-col>

						<b-col>
							<div class="text-uppercase color-rosso font-bold font-size-lg">
								{{osservato.cognome}} {{osservato.nome}}
							</div>
							<div class="text-muted">
								{{osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}
							</div>

						</b-col>
						<b-col cols="7">
							<b-row class="mb-1">
								<b-col>
									Filtra prestiti
								</b-col>
							</b-row>

							<b-row>
								<b-col>	
									<b-form-group label="Dal" horizontal :label-cols="2" label-class="text-sm-right">
										<b-form-input type="date"  v-model="filters.dal" required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>	
									<b-form-group label="al" horizontal :label-cols="2" label-class="text-sm-right">
										<b-form-input type="date" v-model="filters.al" required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col v-if="auth.isUserEnable('prestiti_utenti')">
									<b-form-select :options="utenti" value-field="id" text-field="nomeCompleto" v-model="filters.utente">
										<template slot="first">
											<option :value="auth.user.id">I miei voti</option>
											<option :value="0">Tutti</option>
										</template>
									</b-form-select>
								</b-col>
								<b-col cols="1">
									<b-btn variant="primary" title="Cerca" @click="loadPrestiti">
										<i class="fa fa-search" aria-hidden="true"></i>
									</b-btn>
								</b-col>
								<b-col cols="1">
									<b-btn variant="success" title="Download" @click="downloadPrestiti">
										<i class="fa fa-download" aria-hidden="true"></i>		
									</b-btn>
								</b-col>
							</b-row>
						</b-col>
					</b-row>

					<div v-if="loading" align="center" style="width:100%">
						<img src="/assets/varie/loading.gif" style="width: 150px;" />
					</div>
					<b-card v-for="prestito in prestiti" class="mb-2">
						<b-row class="mb-1">
							<b-col cols="2" class="text-center" align-self="center">
								<div><strong>{{ prestito.at_away ?  prestito.away : prestito.home }}</strong>
								</div>
								<div><b-form-checkbox v-model="prestito.nazionale" :value="1">Nazionale</b-form-checkbox></div>
							</b-col>
							<b-col>
								<b-row>
									<b-col>
										<b-row>
											<b-col>
												<b-form-group label="Partita">
													<b-form-input type="text" placeholder="Categoria" v-model="prestito.categoria" class="mb-2"></b-form-input>
													<b-row>
														<b-col>
															<b-form-input type="text" placeholder="Home" v-model="prestito.home" class="mb-2"></b-form-input>
														</b-col>
														<b-col cols="3">
															<b-form-input type="number" min="0" v-model="prestito.home_score"></b-form-input>
														</b-col>
													</b-row>
													<b-row>
														<b-col>
															<b-form-input type="text" placeholder="Away" v-model="prestito.away"></b-form-input>
														</b-col>
														<b-col cols="3">
															<b-form-input type="number" min="0" v-model="prestito.away_score"></b-form-input>
														</b-col>
													</b-row>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
									<b-col cols="2">
										<b-form-group label="Data/Ora">
											<b-form-input v-model="prestito.date" type="date"></b-form-input>

											<b-form-input v-model="prestito.hour" type="time" class="mt-2"></b-form-input>
										</b-form-group>	
										<b-form-radio-group v-model="prestito.at_away">
											<b-form-radio :value="0">Casa</b-form-radio>
											<b-form-radio :value="1">Trasferta</b-form-radio>
										</b-form-radio-group>
									</b-col>
									<b-col cols="3">
										<b-form-group label="Stato">
											<b-form-radio-group v-model="prestito.stato">
												<b-form-radio value="titolare">Titolare</b-form-radio>
												<b-form-radio value="panchina">Panchina</b-form-radio>
												<b-form-radio value="tribuna">Tribuna</b-form-radio>
												<b-form-radio value="squalificato">Squalificato</b-form-radio>
											</b-form-radio-group>
											<b-form-select :options="infortuni" value-field="id" text-field="nome" v-model="prestito.infortunio_id">
												<template slot="first">
													<option :value="null">-- Infortunio --</option>
												</template>
											</b-form-select>
											<b-form-input type="text" class="mt-2" v-model="prestito.infortunio_note" placeholder="Note infortunio"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col cols="1">
										<b-form-group label="Minuti" class="mb-4">
											<b-form-input type="number" v-model="prestito.minuti"></b-form-input>
										</b-form-group>
										<b-form-group label="Assist" class="mb-4">
											<b-form-input type="number" v-model="prestito.assist"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col cols="1">
										<b-form-group label="Goal" class="mb-4">
											<b-form-input type="number" v-model="prestito.goal"></b-form-input>
										</b-form-group>
										<b-form-group label="Yellow">
											<b-form-input type="number" v-model="prestito.gialli"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col cols="1">
										<b-form-group label="Goal subiti" class="mb-4">
											<b-form-input type="number" v-model="prestito.goal_subiti"></b-form-input>
										</b-form-group>
										<b-form-group label="Red">
											<b-form-input type="number" v-model="prestito.rossi"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col cols="1">
										<b-form-group label="Autogoal" class="mb-4">
											<b-form-input type="number" v-model="prestito.autogoal"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<b-row class="mb-2 border p-2" v-for="voto in prestito.voti">
							<b-col cols="12" class="text-right" v-if="voto.utente">
								<strong>{{ voto.utente.nomeCompleto}}</strong>
							</b-col>
							<b-col cols="1">
								<b-form-group label="Voto" class="mb-1">
									<b-form-input v-if="voto.voto > 0" type="number" min="0" max="10" step="0.1" v-model="voto.voto" :disabled="voto.utente && voto.utente.id != auth.user.id" :style="'background-color:' + voto_bg(voto.voto)"></b-form-input>
									<b-form-input v-else value="s.v." style="background-color:#CCCCCC7F" :disabled="voto.utente && voto.utente.id != auth.user.id"></b-form-input>
								</b-form-group>
								<b-form-radio-group v-model="voto.modalita" :disabled="voto.utente && voto.utente.id != auth.user.id">
									<b-form-radio value="stadio">Stadio</b-form-radio>
									<b-form-radio value="video">Video</b-form-radio>
								</b-form-radio-group>
							</b-col>
							<b-col>
								<b-form-group label="Note">
									<textarea class="form-control" :rows="3" v-model="voto.note" :disabled="voto.utente && voto.utente.id != auth.user.id"></textarea>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card>

				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>

var Auth = require('../../../auth.js');
import moment from 'moment';
import * as types from '../../../store/types';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
	},

	data: function(){
		return {
			osservato: null,
			prestiti: [],
			utenti: [],
			filters: {
				dal: null,
				al: null,
				utente: 0,
				utenti: Auth.isUserEnable('prestiti_utenti'),	
			},
			infortuni: [],
			loading: false,
			waiting: false,
			errors: [],
			gradimenti: [],
		}
	},

	created: function () {
		
		this.getGiocatore();

		this.$http.get(this.$store.state.apiEndPoint + '/infortuni').then((response) => {
			if (response.ok)
			{
				this.infortuni = response.data;    
			}
		}, (response) => {
		});

		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
			if (response.ok)
			{
				this.gradimenti = response.data;    
			}
		}, (response) => {
		});
	},
	methods: {
		getGiocatore() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/anagrafica/giocatore/' + this.$route.params.id).then((response) => {
				if (response.ok)
				{
					this.osservato = response.data;  
					this.getUtenti();
				}
			}, (response) => {
			});
		},

		getUtenti() {
			this.$http.get(this.$store.state.apiEndPoint + '/prestiti/utenti/osservato/' + this.osservato.id).then((response) => {
				if (response.ok)
				{
					this.utenti = response.data;  
					this.filters.utente = this.auth.user.id;  
					this.loadPrestiti();
				}
			}, (response) => {
			});
		},

		loadPrestiti() {
			this.loading = true;
			this.prestiti.dati = {};
			this.$http.get(this.$store.state.apiEndPoint + '/prestiti/osservato/' + this.osservato.id,  {params: this.filters}).then((response) => {
				if (response.ok)
				{

					this.prestiti = response.data.prestiti;
					for(var i in this.prestiti) {
						this.prestiti[i].date = this.prestiti[i].data.split(' ')[0];
						this.prestiti[i].hour = this.prestiti[i].data.split(' ')[1];
					}
					this.loading = false;    
				}
			}, (response) => {
			});
		},
		downloadPrestiti() {
			window.open(this.$store.state.apiEndPoint + '/prestiti/osservato/download/' + this.osservato.id + '/' + this.filters.dal + "/" + this.filters.al + "/" + this.filters.utenti + "/" + this.filters.utente + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},

		voto_bg: function(voto) {
			if(voto >= 6 && voto <= 10) {
				return this.gradimenti[0].colore + '7F';
			} else if(voto >= 5 && voto <= 5.9) {
				return this.gradimenti[3].colore + '7F';
			} else if(voto > 0 && voto  <= 4.9) {
				return this.gradimenti[4].colore + '7F';
			}
			return '#CCCCCC7F';
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
	watch: {
	},
	computed: {
		auth: function() {
			return Auth;
		},
	}
}
</script>
