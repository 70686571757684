<template>
	<div>
		
		<b-form @submit.prevent="create">

			<b-form-select v-model="model.fornitore">
				<option :value="null">Seleziona provider</option>
				<option :value="provider" v-for="provider in providers" v-if="auth.isUserEnable('providers_' + provider)">{{ provider }}</option>
			</b-form-select>
			
			<b-form-group label="Chiave">
				<b-input v-model="model.chiave"></b-input>
			</b-form-group>

			<div class="mt-3">
				<b-button variant="outline-success" size="sm" type="submit">Salva</b-button>
			</div>

		</b-form>

	</div>
</template>

<script>

var Auth = require('../../../auth.js');
import _ from 'lodash';

export default {
	props: [ 'model', 'providers' ],
	created: function() {

	},
	computed: {
		auth: function() {
			return Auth;
		},
	},
	methods: {
		create: function() {
			this.$emit('save');
		},
	},
	data: function() {
		return {
		}
	}
};

</script>