<template>
	<b-modal v-model="ux.show" size="lg" title="Modifica Utente" @show="setData" @hide="close" @ok="updateUtente" ok-title="Salva" cancel-title="Chiudi">
		<b-form-group label="Cognome" horizontal>
			<b-form-input type="text" placeholder="Cognome" v-model="utentiData.cognome" />
		</b-form-group>
		<b-form-group label="Nome" horizontal>
			<b-form-input type="text" placeholder="Nome" v-model="utentiData.nome" />
		</b-form-group>
		<b-form-group label="Username" horizontal>
			<b-form-input type="text" v-model="utentiData.username" plaintext/>
		</b-form-group>
		<b-form-group label="Nuova password" horizontal>
			<b-form-input type="password" v-model="utentiData.password"/>
		</b-form-group>
		<b-form-group label="Ripeti password" horizontal>
			<b-form-input type="password" v-model="utentiData.ripeti_password"/>
		</b-form-group>
		<b-form-group label="Invia nuova password" horizontal>
			<b-form-checkbox v-model="utentiData.notifica" value="1"/>
		</b-form-group>
		<b-form-group label="Email" horizontal>
			<b-form-input type="email" placeholder="Email" v-model="utentiData.email" />
		</b-form-group>
		<b-form-group label="Slack ID" horizontal>
			<b-form-input type="text" v-model="utentiData.slack_id"/>
		</b-form-group>
		<b-form-group label="Attivo" horizontal>
			<b-form-checkbox v-model="utentiData.attivo" value="1"/>
		</b-form-group>
		<b-form-group label="Active Directory AS Roma" horizontal>
			<b-form-checkbox v-model="utentiData.auth_ldap" value="1"/>
		</b-form-group>
		<b-form-group label="Academy Italia" horizontal v-show="is_academy_soc">
			<multiselect v-model="utentiData.academy" :options="academy_options" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id"></multiselect>
		</b-form-group> 
		<b-form-group label="Academy International" horizontal v-show="is_academy_int_soc">
			<multiselect v-model="utentiData.academy" :options="academy_int_options" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id"></multiselect>
		</b-form-group> 
		<b-form-group label="Categorie Academy" horizontal>
			<multiselect v-model="utentiData.academy_categorie" :options="academy_cats_options" :multiple="true" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id" group-values="items"
			group-label="label" :group-select="true"></multiselect>
		</b-form-group>      
		<b-form-group label="Squadre AS Roma" horizontal description="La selezione non ha effetto per amministratori, owner, segretari e coordinatori RomaLab">
			<multiselect
			v-model="utentiData.squadre"
			:multiple="true"
			:options="squadre_options"
			placeholder="Seleziona squadra"
			label="squadra"
			:custom-label="customLabelTeam"
			:show-labels="false"
			track-by="id"/>
		</b-form-group>
		<b-form-group label="Preset Filtri Osservatori" horizontal>
			<b-row v-for="item in utentiData.preset_osservatori" class="mb-1">
				<b-col cols="4">
					<b-input placeholder="Nome" v-model="item.name"/>
				</b-col>
				<b-col>
					<multiselect
					v-model="item.value"
					:multiple="true"
					:options="utenti_options"
					placeholder="Seleziona osservatori"
					label="nomeCompleto"
					track-by="id"/>
				</b-col>
				<b-col cols="1">
					<b-button size="sm" @click="removePreset(item)" title="Rimuovi"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
				</b-col>
			</b-row>
			<b-button size="sm" title="Aggiungi" @click="addPreset"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
		</b-form-group>
	</b-modal>
</template>

<script>

import axios from 'axios';
var Auth = require('@/auth.js');
export default {
	props: ['persona', 'ux'],
	components: {
	},
	data: function() {
		return {
			utentiData: {
				nome: '',
				cognome: '',
				username: '',
				password: '',
				ripeti_password: '',
				notifica: false,
				attivo: false,
				auth_ldap: false,
				email: '',
				slack_id: null,
				squadre: [],
				academy: null,
				academy_categorie: [],
				preset_osservatori: [],
			},
			utenti_options: [],
			squadre_options: [],
			is_academy_soc: false,
			is_academy_int_soc: false,
			academy_options: [],
			academy_cats_options: [],
			academy_int_options: [],
		}
	},
	created: function() {
		axios.get('/societa/squadre').then((res) => {
			this.squadre_options = res.data;
		});
		axios.get('/admin/utenti/football').then((res) => {
			this.utenti_options = res.data;
		});
		axios.get('/academyita/get/list/societa/0').then((res) => {
			this.academy_options = res.data;
		});
		axios.get('/academyita/get/list/societa/1').then((res) => {
			this.academy_int_options = res.data;
		});
		this.academy_cats_options = [
		{
			label: 'Categorie',
			items: Auth.user.academyCats
		}
		];
	},
	methods: {
		close: function() {
			this.$emit('close');
		},
		customLabelTeam ({ squadra }) {
			return squadra.categoria.name;
		},
		setData() {
			if(this.persona) {
				this.utentiData.nome = this.persona.nome;
				this.utentiData.cognome = this.persona.cognome;
				this.utentiData.username = this.persona.utente.username;
				this.utentiData.attivo = this.persona.utente.attivo;
				this.utentiData.auth_ldap = this.persona.utente.auth_ldap;
				this.utentiData.email = this.persona.contatti.find((item) => item.contatto.tipo == 'email').contatto.valore;
				this.utentiData.slack_id = this.persona.utente.slack_id;
				this.utentiData.password = '';
				this.utentiData.ripeti_password = '';
				this.utentiData.notifica = false;
				this.utentiData.squadre = this.persona.utente.squadre_societa;
				if(this.persona.utente.academy) {
					this.utentiData.academy = {
						nome: this.persona.utente.academy.nome,
						id: this.persona.utente.academy.id,
					};
				} else {
					this.utentiData.academy = null;
				}
				this.utentiData.academy_categorie = this.persona.utente.academy_categorie;

				if(this.persona.utente.preset) {
					var preset = JSON.parse(this.persona.utente.preset);
					if(preset.osservatori) {
						for(var i in preset.osservatori) {
							var array_osserv_ids = preset.osservatori[i].value;
							var array_osserv = [];
							for(var x in array_osserv_ids) {
								var id = array_osserv_ids[x];
								var utente = this.utenti_options.find((item) => item.id == id);
								array_osserv.push(utente);
							}
							preset.osservatori[i].value = array_osserv;
						}
						this.utentiData.preset_osservatori = preset.osservatori;
					}
				}
			}
		},
		addPreset: function () {
			this.utentiData.preset_osservatori.push({name: '', value: []});
		},
		removePreset: function (item) {
			var index = this.utentiData.preset_osservatori.indexOf(item);
			this.utentiData.preset_osservatori.splice(index, 1);	
		},
		updateUtente() {
			axios.put('/admin/utenti/' + this.persona.utente.id, {
				data: this.utentiData,
			}).then(() => {
				this.$root.$emit('fetchPersona', this.persona.id);
			});
		},
	},
	watch: {
		is_academy_soc() {
			if(!this.is_academy_soc && !this.is_academy_int_soc) {
				this.utentiData.academy = null;
				this.utentiData.academy_categorie = [];
			}
		},

		is_academy_int_soc() {
			if(!this.is_academy_soc && !this.is_academy_int_soc) {
				this.utentiData.academy = null;
				this.utentiData.academy_categorie = [];
			}
		},
	},
}

</script>