<template id="edit_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card title_page">
				<div class="card-body">
					<div class="card-title">
						<span>Modifica Valutazione</span>
					</div>
				</div>
			</div>	
			<div class="content_page">
				<b-form @submit="onSubmit">
					<b-row>
						<b-col cols="3">
							<div class="card" style="height:100%">
								<div style="text-align:center;">
									<b-img :src="valutazione.persona.avatar_url" fluid :alt="valutazione.persona.cognome + ' ' + valutazione.persona.nome" :width="130"></b-img>
								</div>
								<div class="card-body">
									<div class="mb-1 font-bold text-center">{{ valutazione.persona.nome }} {{ valutazione.persona.cognome }}</div>
									<div class="text-center font-size-sm color-rosso">{{ valutazione.persona.last_carriera_sportiva.ruolo_ideale && valutazione.persona.last_carriera_sportiva.ruolo_ideale.length > 0 ? valutazione.persona.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</div>
									<div class="text-center font-size-sm">Classe {{ valutazione.persona.last_carriera_sportiva.nascita_data | formatYear}}</div>
									<div class="text-center font-size-sm color-rosso">{{ valutazione.persona.last_carriera_sportiva.squadra ? valutazione.persona.last_carriera_sportiva.squadra.name : '' }}</div>
									<div class="text-center font-size-sm mt-3">
										<b-row align-h="center" class="mt-3">
											<b-col cols="6">
												<b-form-group id="currentGroup" label="Current" label-for="current">
													<b-form-input id="current" type="number" min="0" max="10" v-model="form.current" step="0.01" :style="'background-color:' + current_bg"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row align-h="center">
											<b-col cols="6">
												<b-form-group id="potentialGroup" label="Potential" label-for="potential">
													<b-form-input id="potential" type="number" min="0" max="10" v-model="form.potential" step="0.01" :style="'background-color:' + potential_bg"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
									</div>
								</div>
							</div>
						</b-col>
						<b-col>
							<b-row>
								<b-col cols="6">
									<b-form-group id="dataGroup" label="Dal" :label-cols="3" label-for="data">
										<b-form-input id="data" type="date" v-model="form.dal" required="true"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col cols="6">
									<b-form-group id="dataGroup" label="Al" :label-cols="3" label-for="data">
										<b-form-input id="data" type="date" v-model="form.al" required="true"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Caratteristiche fisiche">
										<b-form-textarea id="note2" v-model="form.note2" :rows="3"></b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Caratteristiche tecniche">
										<b-form-textarea id="note3" v-model="form.note3" :rows="3"></b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Caratteristiche tattiche">
										<b-form-textarea id="note4" v-model="form.note4" :rows="3"></b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Caratteristiche comportamentali">
										<b-form-textarea id="note5" v-model="form.note5" :rows="3"></b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Giudizio personale">
										<b-form-textarea id="note" v-model="form.note" :rows="3"></b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row class="mt-1">
						<b-col v-for="item in skills">
							<b-card :title="item[$i18n.t('lang')]" v-if="!valutazione.categoria || (valutazione.categoria && !valutazione.categoria.only_root_skill)">
								<b-list-group>
									<b-list-group-item v-for="skill in item.fields">
										<b-row :style="'width:100%;' + (skill.fields ? 'cursor:pointer;' : '')" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
											<b-col :cols="skill.fields ? '11' : '9'">
												{{skill[$i18n.t('lang')]}}
											</b-col>
											<b-col :cols="skill.fields ? '1' : '3'">
												<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
												<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
												<b-form-select v-if="!skill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id]">
													<template slot="first">
														<option value="0"></option>
													</template>
												</b-form-select>
											</b-col>
										</b-row>						
										<b-collapse visible :id="skill.id" style="width:100%">
											<b-list-group v-if="skill.fields">
												<b-list-group-item v-for="subskill in skill.fields">

													<b-row :style="'width:100%;' + (subskill.fields ? 'cursor:pointer;' : '')" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
														<b-col :cols="subskill.fields ? '11' : '9'">
															{{subskill[$i18n.t('lang')]}}
														</b-col>
														<b-col :cols="subskill.fields ? '1' : '3'">
															<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
															<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
															<b-form-select v-if="!subskill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id]">
																<template slot="first">
																	<option value="0"></option>
																</template>
															</b-form-select>
														</b-col>
													</b-row>						
													<b-collapse visible :id="subskill.id" style="width:100%">
														<b-list-group v-if="subskill.fields">
															<b-list-group-item v-for="subskill2 in subskill.fields">
																<b-row style="width:100%">
																	<b-col cols="9">
																		{{subskill2[$i18n.t('lang')]}}
																	</b-col>
																	<b-col cols="3">
																		<b-form-select :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id][subskill2.id]">
																			<template slot="first">
																				<option value="0"></option>
																			</template>
																		</b-form-select>
																	</b-col>
																</b-row>
															</b-list-group-item>
														</b-list-group>
													</b-collapse>
												</b-list-group-item>
											</b-list-group>
										</b-collapse>
									</b-list-group-item>
								</b-list-group>
							</b-card>
							<b-card :title="item[$i18n.t('lang')]" v-if="valutazione.categoria && valutazione.categoria.only_root_skill">
								<b-list-group>
									<b-list-group-item v-for="skill in item.fields">
										<b-row style="'width:100%">
											<b-col cols="7">
												{{skill[$i18n.t('lang')]}}
											</b-col>
											<b-col :cols="5">
												<b-form-select :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id]">
													<template slot="first">
														<option :value="0"></option>
													</template>
												</b-form-select>
											</b-col>
										</b-row>						
									</b-list-group-item>
								</b-list-group>
							</b-card>
						</b-col>
					</b-row>
					<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
					<b-button @click="eliminaValutazione" variant="secondary" class="mt-1">Elimina</b-button>
				</b-form>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

import * as types from '../../../../store/types'
import moment from 'moment'

export default {
	data: function() {
		return {
			valutazione: [],
			voti: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			form: {
				gradimento: 0,
				fields: {},
				note: '',
				dal: '',
				al: '',
				note2: null,
				note3: null,
				note4: null,
				note5: null,
				current: null,
				potential: null,
			},
			errors: [],
			gradimenti: [],
			skills: [],
			skills_value: [],
		}
	},

	components: {
		headapp: 	require('../../../layout/headapp.vue').default,
		menusub:    require('../../../layout/menusub.vue').default,
		footerapp:  require('../../../layout/footerapp.vue').default,
	},
	created: function () { 

		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.gradimenti = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/rosa/view/valutazione/' + this.$route.params.val_id).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.valutazione = response.data;
            	if(this.valutazione.persona.last_carriera_sportiva.ruolo_ideale && this.valutazione.persona.last_carriera_sportiva.ruolo_ideale.length > 0 && this.valutazione.persona.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
            		this.skills = JSON.parse(this.valutazione.fields.special);
            	} else {
            		this.skills = JSON.parse(this.valutazione.fields.standard);
            	}
            	this.skills_value = JSON.parse(this.valutazione.skills); 

            	this.form.gradimento = this.valutazione.id_gradimento;	
            	this.form.note = this.valutazione.note;	
            	this.form.dal = this.valutazione.dal;
            	this.form.al = this.valutazione.al;	
            	this.form.note2 = this.valutazione.note2;	
            	this.form.note3 = this.valutazione.note3;	
            	this.form.note4 = this.valutazione.note4;	
            	this.form.note5 = this.valutazione.note5;

            	this.form.current = this.valutazione.current;
            	this.form.potential = this.valutazione.potential;	
            	var jsonObject = {};
            	if(!this.valutazione.categoria || (this.valutazione.categoria && !this.valutazione.categoria.only_root_skill)) {
            		for (var k in this.skills) {
            			var item = this.skills[k];          
            			if(item.fields) {
            				for (var j in item.fields) {
            					var item2 = item.fields[j];

            					if(item2.fields) {

            						for (var y in item2.fields) {
            							var item3 = item2.fields[y];
            							if(item3.fields) {

            								for (var x in item3.fields) {
            									var item4 = item3.fields[x];
            									jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id, item4.id], (this.skills_value[item.id][item2.id][item3.id][item4.id] ? this.skills_value[item.id][item2.id][item3.id][item4.id] : 0));
            								}

            							} else {
            								jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id], (this.skills_value[item.id][item2.id][item3.id] ? this.skills_value[item.id][item2.id][item3.id] : 0));
            							}	 
            						}

            					} else {
            						jsonObject = this.nest(jsonObject, [item.id, item2.id], (this.skills_value[item.id][item2.id] ? this.skills_value[item.id][item2.id] : 0));		
            					}	 
            				}
            			}
            		}
            	} else {
            		for (var k in this.skills) {
            			var item = this.skills[k];          
            			if(item.fields) {
            				for (var j in item.fields) {
            					var item2 = item.fields[j];
            					jsonObject = this.nest(jsonObject, [item.id, item2.id], (this.skills_value[item.id][item2.id] ? this.skills_value[item.id][item2.id] : 0));		 
            				}
            			}
            		}
            	}
            	this.form.fields = jsonObject;
            }
        }, (response) => {
        });
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		}
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.$http.post(this.$store.state.apiEndPoint + '/rosa/edit/valutazione/' + this.valutazione.id, this.form).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            	this.$router.push({ name: "football_rosa_view_valutazione", params: { id: this.valutazione.persona.id, val_id: this.valutazione.id} }); 
            }
        }, (response) => {  
        	if (response.status == 422) {
        		this.errors = response.data;
        		for (var index in this.errors) {
        			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        		}
        	}
            // error callback
        });
		},
		nest: function(obj, keys, v) {
			if (keys.length === 1) {
				obj[keys[0]] = v;
			} else {
				var key = keys.shift();
				obj[key] = this.nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v);
			}

			return obj;
		},

		selectGradimento(id) {
			this.form.gradimento = id;
		},

		eliminaValutazione: function() {
			if(confirm("Confermi l'eliminazione della valutazione?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/rosa/delete/valutazione/' + this.valutazione.id).then((response) => {

					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$router.push({ name: "football_giocatore_scheda", params: { id: this.valutazione.persona.id } });
					}
				}, (response) => {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				});
			}
		},
	},

	computed: {
		current_bg: function() {
			if(this.gradimenti.length > 0) {
				if(this.form.current >= 8.5 && this.form.current <= 10) {
					return this.gradimenti[0].colore + '7F';
				} else if(this.form.current >= 6.5 && this.form.current <= 8.49) {
					return this.gradimenti[1].colore + '7F';
				} else if(this.form.current >= 4.5 && this.form.current <= 6.49) {
					return this.gradimenti[2].colore + '7F';
				} else if(this.form.current >= 2.5 && this.form.current <= 4.49) {
					return this.gradimenti[3].colore + '7F';
				} else if(this.form.current >= 0 && this.form.current <= 2.49) {
					return this.gradimenti[4].colore + '7F';
				} else {
					return '#FFFFFF';
				}
			}
			return '#FFFFFF';
		},

		potential_bg: function() {
			if(this.gradimenti.length > 0) {
				if(this.form.potential >= 8.5 && this.form.potential <= 10) {
					return this.gradimenti[0].colore + '7F';
				} else if(this.form.potential >= 6.5 && this.form.potential <= 8.49) {
					return this.gradimenti[1].colore + '7F';
				} else if(this.form.potential >= 4.5 && this.form.potential <= 6.49) {
					return this.gradimenti[2].colore + '7F';
				} else if(this.form.potential >= 2.5 && this.form.potential <= 4.49) {
					return this.gradimenti[3].colore + '7F';
				} else if(this.form.potential >= 0 && this.form.potential <= 2.49) {
					return this.gradimenti[4].colore + '7F';
				} else {
					return '#FFFFFF';
				}
			}
			return '#FFFFFF';
		},
	}

}

</script>
