<template id="form">
	<div class="card border-0 rounded-0">
		<div class="card-body">
			<b-container>
				<form v-on:submit.prevent="onSubmit()" action="#" method="POST">
					<b-row>
						<b-col cols="12">
							<div v-if="title" class="card-title">
								<span>{{ title }}</span>
							</div>
							<!--form v-on:submit.prevent="onSubmit()" action="#" method="POST"-->
							<div class="content">
								<picture-input ref="pictureTeam" @change="onLogoChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="logo"></picture-input>
								<b-form-group id="nomeGroup" horizontal :label="$i18n.t('Nome')" :label-cols="3" label-for="nome">
									<b-form-input id="nome" type="text" v-model="form.nome" :required="true"></b-form-input>
								</b-form-group>
								<b-form-group id="matricolaGroup" horizontal :label="$i18n.t('Matricola')" :label-cols="3" label-for="matricola">
									<b-form-input id="matricola" type="text" v-model="form.matricola"></b-form-input>
								</b-form-group>
								<b-form-group id="indirizzoGroup" horizontal :label="$i18n.t('Indirizzo')" :label-cols="3" label-for="indirizzo">
									<b-form-input id="indirizzo" type="text" v-model="form.indirizzo"></b-form-input>
								</b-form-group>
								<b-form-group id="telefonoGroup" horizontal :label="$i18n.t('Telefono')" :label-cols="3" label-for="telefono">
									<b-form-input id="telefono" type="tel" v-model="form.telefono"></b-form-input>
								</b-form-group>
								<b-form-group id="emailGroup" horizontal label="Email" :label-cols="3" label-for="email">
									<b-form-input id="email" type="email" v-model="form.email"></b-form-input>
								</b-form-group>
								<b-form-group id="cfPivaGroup" horizontal label="CF/PIVA" :label-cols="3" label-for="cfPiva">
									<b-form-input id="cfPiva" type="text" v-model="form.cf_piva"></b-form-input>
								</b-form-group>
								<div class="d-flex align-items-center" v-if="auth.isUserEnable('academy_societa_mng')">
									<b-form-group class="text-right">
										<b-button type="submit" variant="primary" class="text-uppercase mr-2"><strong>{{ $i18n.t('Salva') }}</strong></b-button>
										<b-button class="text-uppercase" @click="eliminaAcademy" v-if="$route.params.id"><strong>{{ $i18n.t('Elimina') }}</strong></b-button>
									</b-form-group>
								</div>
							</div>
							<!--/form-->
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="text-center color-rosso">
							<h4>TEAM AND STAFF</h4>
						</b-col>
					</b-row>
					<b-row v-for="item in staff">
						<b-col>
							<b-row class="mt-2">
								<b-col cols="12" class="text-left color-rosso">
									<h5><strong>{{ item.nome.toUpperCase() }}</strong></h5>
								</b-col>
							</b-row>
							<b-row align-v="center" v-for="user in item.staff">
								<b-col cols="3" class="text-left">
									{{ $i18n.t('Allenatore') }}: 
								</b-col>
								<b-col cols="9" class="text-left">
									<b-form-input v-model="user.nomeCompleto"></b-form-input>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="text-center color-rosso text-uppercase">
							<h4>{{ $i18n.t('Collaboratori') }}</h4>
						</b-col>
					</b-row>
					<b-row align-v="center" v-if="form.staff.length > 0">
						<b-col>
							{{$i18n.t('Cognome')}}
						</b-col>
						<b-col>
							{{$i18n.t('Nome')}}
						</b-col>
						<b-col cols="1">
						</b-col>
					</b-row>
					<b-row align-v="center" v-for="(item, index) in form.staff" class="mb-1">
						<b-col>
							<b-form-input v-model="item.cognome" type="text" :placeholder="$i18n.t('Cognome')"></b-form-input>
						</b-col>
						<b-col>			
							<b-form-input v-model="item.nome" type="text" :placeholder="$i18n.t('Nome')"></b-form-input>
						</b-col>
						<b-col cols="1">
							<b-button size="sm" @click="delCollaboratore(index)" class="ml-3"><i class="fa fa-minus" aria-hidden="true"></i></b-button>	
						</b-col>
					</b-row>
					<b-button size="sm" @click="addCollaboratore" class="mt-1 mb-2"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
					<div class="d-flex align-items-center mt-3" v-if="auth.isUserEnable('academy_societa_mng')">
						<b-form-group class="text-right">
							<b-button type="submit" variant="primary" class="text-uppercase mr-2"><strong>{{ $i18n.t('Salva') }}</strong></b-button>
							<b-button class="text-uppercase" @click="eliminaAcademy" v-if="$route.params.id"><strong>{{ $i18n.t('Elimina') }}</strong></b-button>
						</b-form-group>
					</div>
				</form>

			</b-container>
		</div>
	</div>

</template>


<script>

import moment from 'moment'
import axios from 'axios';
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types'
export default {

	data: function(){
		return {
			image_logo: '',
			file_logo: '',
			form: {
				nome: '',
				matricola: '',
				indirizzo: '',
				telefono: '',
				email: '',
				cf_piva: '',
				academy: 1,	
				staff: [],
			},
			errors: [],
			staff: [],
		}
	},

	methods: {

		onSubmit: function() {
			if(this.auth.isUserEnable('academy_societa_mng')) {
				var formData = new FormData();
				var path = '/academyita/societa/add';
				formData.append("form", JSON.stringify(this.form));
				formData.append("file_logo", this.file_logo);
				if(this.$route.params.id) {
					formData.set('id', this.$route.params.id);
					path  = '/academyita/societa/edit';
				}

				this.$http.post(this.$store.state.apiEndPoint + path, formData).then((response) => {
	            	// success callback
	            	if (response.ok)
	            	{
	            		this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
	            		this.$router.push({ name: 'academy_int_societa'});
	            	}
	            }, (response) => {
	            	if (response.status == 422) {
	            		this.errors = response.data;
	            		for (var index in this.errors) {
	            			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
	            		}
	            	}
					// error callback
				});
			}
		},
		onLogoChange(e) {
			if (this.$refs.pictureTeam.file) {
				this.file_logo = this.$refs.pictureTeam.file;
			} else {
				console.log('FileReader Player API not supported: use the <form>')
			}
		},
		eliminaAcademy: function() {
			if(confirm("Confermi l'eliminazione dell'academy?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/societa/delete/' + this.$route.params.id).then((response) => {

					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$router.push({ name: "academy_int_societa"});
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		addCollaboratore() {
			this.form.staff.push({
				cognome: null,
				nome: null,
			});
		},

		delCollaboratore(index) {
			this.form.staff.splice(index, 1);
		},
	},

	props: {
		title: {
			type: String,
			required: false
		},
	},

	components: {
		pictureInput:		require('../../../layout/picture_input.vue').default,
	},

	computed: {
		auth: function() {
			return Auth;
		},
		logo: function() {
			if(this.image_logo != "") {
				return this.image_logo;
			} 
			return '';
		},
	},

	mounted: function(){
	},

	created: function () {

		if(this.$route.params.id){
			axios.get(`/academyita/societa/get/` + this.$route.params.id)
			.then((res) => {

				var societa = res.data;
				this.form.nome = societa.nome;
				this.form.matricola = societa.matricola;
				this.form.indirizzo = societa.indirizzo;
				this.form.telefono = societa.telefono;
				this.form.email = societa.email;
				this.form.cf_piva = societa.cf_piva;
				this.form.academy = societa.academy;
				this.form.staff = societa.staff ? JSON.parse(societa.staff) : [];

				if(societa.logo != null && societa.logo != "") {
					this.image_logo = societa.logo_url;
				}

			}).catch((err) => {
				this.loading = false;
			});

			axios.get(`/academyita/societa/staff/` + this.$route.params.id)
			.then((res) => {
				this.staff = res.data;		
			}).catch((err) => {
				this.loading = false;
			});
		}		
	},

	watch: {

		form: {
			handler: function () {this.errors = []; },
			deep: true
		},
	},

}
</script>
