<template>
	<div>
		<b-btn variant="primary" size="sm" v-for="video, index in videos" @click="playVideo(video)">{{ video.video && video.video.titolo && video.video.titolo != '' ? video.video.titolo : 'Video ' + (index + 1)}}</b-btn>
		<b-modal id="videoModal" size="xl" ref="videoModal" :title="video_selected.titolo" class="fs-modal" @hidden="onHiddenVideo" @shown="onShowVideo" ok-only ok-title="Chiudi">
			<div>
				<video
				id="video-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
		</div>
	</b-modal>
</div>
</template>

<script>

import videojs from 'video.js';

export default {
	props: ['entity', 'entityId' ],
	computed: {
	},
	watch: {
		entity: {
			deep: true,
			handler: function() {
				this.fetch();
			},
			immediate: true
		},
	},
	methods: {
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/of/' + this.entity + '/' + this.entityId).then(function(data) {
				this.videos = data.data;
			});
		},
		playVideo(video) {
			this.video_selected.url = video.video.video;
			this.video_selected.titolo = video.video.titolo ? video.video.titolo : 'Video';
			this.$refs.videoModal.show();
		},
		onShowVideo() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
			}
			this.$video.src({type: 'video/mp4', src: this.video_selected.url });
			this.$video.play();
		},
		onHiddenVideo() {
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;		
		}, 
	},

	data: function() {
		return {
			videos: false,
			video_selected: {
				url: null,
				titolo: null,
			},
		}
	}
};

</script>