<template>
	<b-card title="Report Skills Offensive">
		<b-table-simple small striped bordered responsive v-if="myStats" style="font-size:0.9rem">
			<thead>
				<tr>
					<th style="min-width:200px;"></th>
					<th class="text-center">AVG</th>
					<th class="text-center">{{ myStats.name }}</th>
					<th class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.name }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>	
					<td>Falli subiti</td>
					<td class="text-center">{{ stats.average.falliSubiti }}</td>
					<td class="text-center">{{ myStats.falliSubiti }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.falliSubiti }}</td>				
				</tr>
				<tr>	
					<td>Possessi individuali metà campo difensiva</td>
					<td class="text-center">{{ stats.average.possessiMetaDifensiva }}</td>
					<td class="text-center">{{ myStats.possessiMetaDifensiva }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.possessiMetaDifensiva }}</td>				
				</tr>
				<tr>	
					<td>Possessi individuali metà campo offensiva</td>
					<td class="text-center">{{ stats.average.possessiMetaOffensiva }}</td>
					<td class="text-center">{{ myStats.possessiMetaOffensiva }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.possessiMetaOffensiva }}</td>				
				</tr>
				<tr>	
					<td>Possessi individuali in area avversaria</td>
					<td class="text-center">{{ stats.average.possessiAreaAvversaria }}</td>
					<td class="text-center">{{ myStats.possessiAreaAvversaria }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.possessiAreaAvversaria }}</td>				
				</tr>
				<tr>	
					<td>Progressive carries</td>
					<td class="text-center">{{ stats.average.progressiveCarries }}</td>
					<td class="text-center">{{ myStats.progressiveCarries }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.progressiveCarries }}</td>				
				</tr>
				<tr>	
					<td>Attacchi Diretti da 3Dif Tentati</td>
					<td class="text-center">{{ stats.average.attacchiDiretti3D }}</td>
					<td class="text-center">{{ myStats.attacchiDiretti3D }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.attacchiDiretti3D }}</td>				
				</tr>
				<tr>	
					<td>Passaggi Lunghi</td>
					<td class="text-center">{{ stats.average.passaggiLunghi }}</td>
					<td class="text-center">{{ myStats.passaggiLunghi }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.passaggiLunghi }}</td>				
				</tr>
				<tr>	
					<td>Cross dal Fondo Completati (OP)</td>
					<td class="text-center">{{ stats.average.crossFondoCmp }}</td>
					<td class="text-center">{{ myStats.crossFondoCmp }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.crossFondoCmp }}</td>				
				</tr>
				<tr>	
					<td>Cross dal Fondo Tentati (OP)</td>
					<td class="text-center">{{ stats.average.crossFondoAtt }}</td>
					<td class="text-center">{{ myStats.crossFondoAtt }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.crossFondoAtt }}</td>				
				</tr>
				<tr>	
					<td>Cross Completati (Open Play)</td>
					<td class="text-center">{{ stats.average.crossCmp }}</td>
					<td class="text-center">{{ myStats.crossCmp }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.crossCmp }}</td>				
				</tr>
				<tr>	
					<td>Cross Tentati (Open Play)</td>
					<td class="text-center">{{ stats.average.crossAtt }}</td>
					<td class="text-center">{{ myStats.crossAtt }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.crossAtt }}</td>				
				</tr>
				<tr>	
					<td>1v1</td>
					<td class="text-center">{{ stats.average.dribbling }}</td>
					<td class="text-center">{{ myStats.dribbling }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.dribbling }}</td>				
				</tr>
				<tr>	
					<td>1v1 riusciti</td>
					<td class="text-center">{{ stats.average.dribblingSucc }}</td>
					<td class="text-center">{{ myStats.dribblingSucc }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.dribblingSucc }}</td>				
				</tr>
				<tr>	
					<td>Shots from Inside Box</td>
					<td class="text-center">{{ stats.average.shtInBoxOP }}</td>
					<td class="text-center">{{ myStats.shtInBoxOP }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.shtInBoxOP }}</td>				
				</tr>
				<tr>	
					<td>Occasioni (Open Play)</td>
					<td class="text-center">{{ stats.average.occasioniOP }}</td>
					<td class="text-center">{{ myStats.occasioniOP }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.occasioniOP }}</td>				
				</tr>
				<tr>	
					<td>Occasioni (Set Pieces)</td>
					<td class="text-center">{{ stats.average.occasioniSP }}</td>
					<td class="text-center">{{ myStats.occasioniSP }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.occasioniSP }}</td>				
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team'],
	data: function() {
		return {

		}
	},
	computed: {
		myStats: function () {
			if(this.stats && this.stats.teams) 
				return this.stats.teams.find(item => item.optaId == this.team.opta.chiave);
			return null;
		},
	},
}
</script>
