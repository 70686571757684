<template>
	<section>
		<b-row class="mb-3">
			<b-col cols="2">
				<b-datepicker v-model="filters.from" placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
			</b-col>
			<b-col cols="2">
				<b-datepicker v-model="filters.to" placeholder="To" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>		
			</b-col>
			<b-col cols="2">
				<multiselect v-model="aree_selected" :options="moduli_options" :multiple="true" placeholder="Filtra per aree" label="label" track-by="name" group-values="items" group-label="label" :group-select="true"></multiselect>
			</b-col>
			<b-col cols="2">
				<multiselect v-model="gruppi_selected" :options="gruppi_options" :multiple="true" placeholder="Filtra per gruppi" label="nome" track-by="id" size="sm" group-values="items" group-label="label" :group-select="true"></multiselect>
			</b-col>
			<b-col cols="2">
				<multiselect v-model="utenti_selected" :options="utenti_filtered" :multiple="true" placeholder="Filtra per utenti" label="nomeCompleto" track-by="id" group-values="items" group-label="label" :group-select="true"></multiselect>
			</b-col>
			<b-col cols="1">
				<b-form-checkbox :value="1" :unchecked-value="0" v-model="only_attivi" size="sm">Solo attivi</b-form-checkbox>
			</b-col>
			<b-col cols="1">
				<b-button size="sm" variant="primary" @click="fetchDati"><i class="fa fa-search" aria-hidden="true"></i></b-button> 
			</b-col>
		</b-row>
		<hr/>
		<b-card>
			<b-overlay :show="loading" no-wrap></b-overlay>
			<b-row v-if="dataset">
				<b-col cols="10">
					<E-Chart style="width: 100%; height: 500px" :option="graphOption" @onClick="setDataInfo"></E-Chart>
					<b-row v-if="xData_selected">
						<b-col cols="6">
							<p class="text-center"><strong>{{ table_table }}</strong></p>
							<b-table small striped hover :fields="table_fields" :items="table_items" selectable select-mode="single" @row-selected="onRowSelected"></b-table>
						</b-col>
						<b-col cols="6">
							<p class="text-center"><strong>{{ detail_title }}</strong></p>
							<b-overlay :show="loading_utenti">
								<b-table v-if="dataset_utenti" small striped hover :fields="table_detail_fields" :items="table_detail_items"></b-table>
							</b-overlay>
						</b-col>	
					</b-row>
				</b-col>
				<b-col cols="2">
					<b-form-group label="Visualizza per:">
						<b-form-radio v-model="filters.group_by" :value="0" size="sm">Aree</b-form-radio>
						<b-form-radio v-model="filters.group_by" :value="1" size="sm">Gruppi</b-form-radio>
						<b-form-radio v-model="filters.group_by" :value="2" size="sm">Utenti</b-form-radio>
					</b-form-group>
					<b-form-group label="Tipo di grafico">
						<b-form-select v-model="graph_type" size="sm">
							<b-form-select-option value="line">Linea</b-form-select-option>
							<b-form-select-option value="bar">Barra</b-form-select-option>
							<b-form-select-option value="pie" :disabled="xData.length > 1">Torta</b-form-select-option>
						</b-form-select>
					</b-form-group>
					<b-form-group label="Aggrega risultati per">
						<b-form-select v-model="aggregate" size="sm">
							<b-form-select-option value="day">Giorno</b-form-select-option>
							<b-form-select-option value="week" :disabled="days.length < 7">Settimana</b-form-select-option>
							<b-form-select-option value="month" :disabled="days.length < 30">Mese</b-form-select-option>
						</b-form-select>
					</b-form-group>
					<div>Totale Utilizzo: <strong>{{ total }}</strong></div></div>
				</b-col>
			</b-row>
		</b-card>
	</section>

</template>

<script>

import eChart from '@/components/layout/E-Chart/Base.vue';
import pageBase from '@/mixins/pageBase.js';

export default {
	mixins: [ pageBase ],
	components: {
		'E-Chart': eChart,
	},
	props: ['gruppi', 'utenti'],
	data: function() {
		return {
			loading: false,
			loading_utenti: false,
			gruppi_selected: [],
			utenti_selected: [],
			aree_selected: [],
			filters: {
				from: null,
				to: null,
				group_by: 0,
				gruppi_ids: [],
				utenti_ids: [],
				aree: []
			},
			dataset: null,
			graph_type: 'line',
			aggregate: 'day',
			only_attivi: 1,
			xData_selected: null,
			dataset_utenti: null,
			detail_title: null,
			moduli: [
				{ name: 'academy_ita_dashboard', label: 'AcademyItalia'},
				{ name: 'academy_int_dashboard', label: 'AcademyInt'},
				{ name: 'analysis_dashboard', label: 'Analysis'},
				{ name: 'scouting_dashboard', label: 'ArchivioScout'},
				{ name: 'camera_dashboard', label: 'Camera'},
				{ name: 'daily_check', label: 'DailyCheck'}, 
				{ name: 'filebrowser_dashboard', label: 'FileBrowser'},
				{ name: 'football_dashboard', label: 'Football'},
				{ name: 'geomatch', label: 'Geomatch'},
				{ name: 'medical_calendar', label: 'Medical'},
				{ name: 'performance_dashboard', label: 'Performance'},
				{ name: 'prestiti_dashboard', label: 'Prestiti'},
				{ name: 'player_access_attivita', label: 'PlayerAccess'},
				{ name: 'reports_dashboard', label: 'Reports'},
				{ name: 'romalab_dashboard', label: 'RomaLab'},
				{ name: 'romanext_dashboard', label: 'RomaNext'},
				{ name: 'newscout_dashboard', label: 'Scouting'},
				{ name: 'stats_dashboard', label: 'Stats'},
				{ name: 'usascout_dashboard', label: 'UsaScout'},
				{ name: 'videotag_archivio', label: 'Videotag'},
				{ name: 'witheboard_dashboard', label: 'Witheboard'},
				{ name: 'academy_dashboard', label: 'WorldScout'},
				{ name: 'youthscout_dashboard', label: 'YouthScout'},
				{ name: 'youngscout_dashboard', label: 'YoungScout'},
				{ name: 'contratti_dashboard', label: 'Contratti'}
				],
		}
	},
	watch: {
		'filters.group_by': function() {
			this.fetchDati();
		},
		aree_selected: function() {
			this.filters.aree = this.aree_selected.map(function(item) { return item.name });
		},
		gruppi_selected: function() {
			this.filters.gruppi_ids = this.gruppi_selected.map(function(item) { return item.id });
		},
		utenti_selected: function() {
			this.filters.utenti_ids = this.utenti_selected.map(function(item) { return item.id });
		},
		xData: function() {
			if(this.xData.length == 1) {
				this.graph_type = 'pie';
			} else {
				this.graph_type = 'line';
			}
		},
		aggregate: function() {
			this.xData_selected = null;
		},
	},
	created: function() {
		this.filters.from = this.moment().subtract(6, 'd').format('YYYY-MM-DD');
		this.filters.to = this.moment().format('YYYY-MM-DD');
		this.filters.aree = this.moduli.map(function(item) { return item.name });
		this.fetchDati();
	},
	mounted: function() {
	},
	computed: {

		table_fields: function() {
			return [ {key: 'name', label: this.filters.group_by == 0 ? 'Aree' : (this.filters.group_by == 1 ? 'Gruppi' : 'Utenti'), sortable: true, }, {key: 'value', label: 'Utilizzo', sortable: true} ];
		},

		table_items: function() {
			var rows = [];
			if(this.xData_selected) {
				if(this.graph_type == 'pie') {
					var findItem = this.graphOption.series[0].data.find(item => item.name == this.xData_selected);
					var row = {name: findItem.name, value: findItem.value, id: findItem.id};
						rows.push(row);
				} else {
					_.forEach(this.graphOption.series, function (item) {
						var row = {name: item.name, value: item.data[this.getIndexData(this.xData_selected)], id: item.id ? item.id : null};
						rows.push(row);
					}.bind(this));
				}
			}
			return rows;
		},

		table_detail_fields: function() {
			if(this.filters.group_by == 0) {
				return [ {key: 'utente', label: 'Utenti', sortable: true, }, {key: 'value', label: 'Utilizzo', sortable: true} ];
			} else if(this.filters.group_by == 1) {
				return [ {key: 'utente', label: 'Utenti', sortable: true, }, {key: 'area', label: 'Area', sortable: true, }, {key: 'value', label: 'Utilizzo', sortable: true} ];
			} else {
				return [ {key: 'area', label: 'Area', sortable: true, }, {key: 'value', label: 'Utilizzo', sortable: true} ];
			}
		},

		table_detail_items: function() {
			var rows = [];
			if(this.dataset_utenti) {
				_.forEach(this.dataset_utenti, function (item) {
					var row = {};
					if(this.filters.group_by == 0) {
						row = {utente: this.utenti.find(obj => obj.id == item.user_id).nomeCompleto, value: item.utilizzi };
					} else if(this.filters.group_by == 1) {
						row = {utente: this.utenti.find(obj => obj.id == item.user_id).nomeCompleto, area: this.moduli.find(obj => obj.name == item.area).label , value: item.utilizzi };
					} else {
						row = {area: this.moduli.find(obj => obj.name == item.area).label , value: item.utilizzi };
					}
					rows.push(row);
				}.bind(this));
			}
			return rows;
		},

		table_table: function () {
			var title = '';
			if(this.xData_selected) {
				if(this.aggregate == 'day') {
					title = this.graph_type == 'pie' ? this.moment(this.firstDay).format('ddd DD/MM/YYYY') : this.moment(this.xData_selected).format('ddd DD/MM/YYYY');
				} else if(this.aggregate == 'week') {
					if(this.graph_type == 'pie') {
						title = this.moment(this.firstDay).format('ddd DD/MM/YYYY') + ' - ' + this.moment(this.lastDay).format('ddd DD/MM/YYYY');
					} else {
						var week = this.moment(this.xData_selected, 'YYYY-WW').toDate();
						var startDate = this.moment(week).startOf('isoWeek');
						if (startDate < this.moment(this.firstDay)) {
							startDate = this.moment(this.firstDay);
						}
						var endDate = this.moment(week).endOf('isoWeek');
						if (endDate > this.moment(this.lastDay)) {
							endDate = this.moment(this.lastDay);
						}
						title = startDate.format('ddd DD/MM/YYYY') + ' - ' + endDate.format('ddd DD/MM/YYYY');
					} 
				} else if(this.aggregate == 'month') {
					if(this.graph_type == 'pie') {
						title = this.moment(this.firstDay).format('ddd DD/MM/YYYY') + ' - ' + this.moment(this.lastDay).format('ddd DD/MM/YYYY');
					} else {
						var month = this.moment(this.xData_selected, 'YYYY-MM').toDate();
						var startDate = this.moment(month).startOf('month');
						if (startDate < this.moment(this.firstDay)) {
							startDate = this.moment(this.firstDay);
						}
						var endDate = this.moment(month).endOf('month');
						if (endDate > this.moment(this.lastDay)) {
							endDate = this.moment(this.lastDay);
						}
						title = startDate.format('ddd DD/MM/YYYY') + ' - ' + endDate.format('ddd DD/MM/YYYY');
					} 
				}
			}
			return title;
		},

		moduli_options: function() {
			return [{
				label: 'Seleziona tutti',
				items: this.moduli
			}];
		},

		gruppi_options: function() {
			return [{
				label: 'Seleziona tutti',
				items: this.gruppi
			}];
		},

		utenti_filtered: function() {
			var utenti = this.utenti;
			if(this.only_attivi) {
				utenti = utenti.filter(item => item.attivo);
			}
			if(this.filters.gruppi_ids.length > 0) {
				utenti = utenti.filter(item => item.gruppi.findIndex(g => this.filters.gruppi_ids.includes(g.id)) > -1);
			} 
			return [{
				label: 'Seleziona tutti',
				items: utenti
			}];
		},
		
		total: function () {
			var totale = 0;
			_.forEach(this.dataset, function (items) {
				totale += _.sumBy(items, 'utilizzi');
			}.bind(this));
			return totale;
		},

		days: function () {
			var days = [];
			if(this.dataset) {
				days = _.keys(this.dataset);
			}
			return days;
		},

		firstDay: function () {
			return _.first(this.days);
		},

		lastDay: function() {
			return _.last(this.days);
		},

		weeks: function () {
			var weeks = [];
			_.forEach(this.days, function (day) {
				var week = this.moment(day).format('YYYY-WW');
				if(!weeks.includes(week)) {
					weeks.push(week);
				}
			}.bind(this));
			return weeks;
		},

		months: function () {
			var months = [];
			_.forEach(this.days, function (day) {
				var month = this.moment(day).format('YYYY-MM');
				if(!months.includes(month)) {
					months.push(month);
				}
			}.bind(this));
			return months;
		},

		xData: function() {
			if(this.aggregate == 'day') {
				return this.days;
			}
			if(this.aggregate == 'week') {
				return this.weeks;
			}
			if(this.aggregate == 'month') {
				return this.months;
			}
		},

		dataset_gruppi: function () {
			return _.uniq(_.flatten(_.values(this.dataset)).map(function(item) { return item.gruppo }));		
		},

		dataset_aggregate: function () {
			var dataset_aggregate = {};
			if(this.aggregate == 'day') {
				dataset_aggregate = this.dataset;
			} else {
				var key_format = this.aggregate == 'week' ? 'YYYY-WW' : 'YYYY-MM';
				_.forEach(this.dataset, function (items, day) {
					var key = this.moment(day).format(key_format);
					if(!dataset_aggregate[key]) {
						dataset_aggregate[key] = [];
					}
					_.forEach(items, function (item) {
							var gruppo = item.gruppo;
							var dato = _.find(dataset_aggregate[key], { gruppo: gruppo });
							if(dato) {
								dato.utilizzi += item.utilizzi;
							} else {
								dato = {day: key, utilizzi: item.utilizzi, gruppo: gruppo};
								dataset_aggregate[key].push(dato);
							}
						});
				}.bind(this));
			}
			return dataset_aggregate;
		},

		graphOption: function() {
			var series = [];
			var legend = [];
			var xData = this.xData;
			if(this.dataset_aggregate) {
				var gruppi = this.dataset_gruppi;
				if(this.graph_type == 'pie') {
					var serie = {
						name: 'Utilizzo',
						data: [],
						type: this.graph_type,
					}
					_.forEach(xData, function (day) {
						var dati = this.dataset_aggregate[day];
						_.each(gruppi, function( gruppo, index ) {
							var label = this.filters.group_by == 1 ? (gruppo ? this.gruppi.find(item => item.id == gruppo).nome : 'Senza gruppo') : (this.filters.group_by == 2 ? this.utenti.find(item => item.id == gruppo).nomeCompleto : this.moduli.find(item => item.name == gruppo).label );
							var dato = _.find(dati, { gruppo: gruppo });
							if(dato) {
								serie.data.push({value: dato.utilizzi, name: label, id: gruppo });
							} else {
								serie.data.push({value: 0, name: label, id: gruppo  });
							}
							legend.push(label);
						}.bind(this));
					}.bind(this));
					series.push(serie);
				} else {
					_.forEach(gruppi, function (gruppo) {
						var label = this.filters.group_by == 1 ? (gruppo ? this.gruppi.find(item => item.id == gruppo).nome : 'Senza gruppo') : (this.filters.group_by == 2 ? this.utenti.find(item => item.id == gruppo).nomeCompleto : this.moduli.find(item => item.name == gruppo).label );
						var serie = {
							id: gruppo,
							name: label,
							data: [],
							type: this.graph_type,
						}
						if(this.graph_type == 'bar') {
							serie.stack = 'Total';
						}
						series.push(serie);
						legend.push(label);
					}.bind(this));
					_.forEach(xData, function (day) {
						var dati = this.dataset_aggregate[day];
						_.each( gruppi, function( gruppo, index ) {
							var dato = _.find(dati, { gruppo: gruppo });
							if(dato) {
								series[index].data.push(dato.utilizzi);
							} else {
								series[index].data.push(0);
							}
						});
					}.bind(this));
				}
			}
			var options = {};
			if(this.graph_type != 'pie') {
				options.xAxis = {
					type: 'category',
					data: xData,
					show: true,
				};
				options.yAxis = {
					type: 'value',
					show: true,
				};
				options.tooltip = {
					trigger: 'axis',
					formatter: function (params) {
						var label = params[0].name;
						if(this.aggregate == 'day') {
							label = this.moment(label).format('ddd DD/MM/YYYY');
						} else if(this.aggregate == 'week') {
							var week = this.moment(label, 'YYYY-WW').toDate();
							var startDate = this.moment(week).startOf('isoWeek');
							if (startDate < this.moment(this.firstDay)) {
								startDate = this.moment(this.firstDay);
							}
							var endDate = this.moment(week).endOf('isoWeek');
							if (endDate > this.moment(this.lastDay)) {
								endDate = this.moment(this.lastDay);
							}
							label = startDate.format('ddd DD/MM/YYYY') + ' - ' + endDate.format('ddd DD/MM/YYYY');
						} else if(this.aggregate == 'month') {
							var month = this.moment(label, 'YYYY-MM').toDate();
							var startDate = this.moment(month).startOf('month');
							if (startDate < this.moment(this.firstDay)) {
								startDate = this.moment(this.firstDay);
							}
							var endDate = this.moment(month).endOf('month');
							if (endDate > this.moment(this.lastDay)) {
								endDate = this.moment(this.lastDay);
							}
							label = startDate.format('ddd DD/MM/YYYY') + ' - ' + endDate.format('ddd DD/MM/YYYY');
						}
						var totale = 0;
						_.each( params, function(param) {
							totale += param.value;
						});
						return label + '<br/>Totale utilizzo: <strong>' + totale + '</strong>';
					}.bind(this)
				};
			} else {
				options.xAxis = {
					show: false,
				};
				options.yAxis = {
					show: false,
				};
				options.tooltip = {
					trigger: 'item'
				};
			}
			options.legend = {
				padding: [0, 0, 0, 0],
				data: legend,
			};
			options.series = series;

			return options;
		}	
	},
	methods: {
		getIndexData(x) {
			return this.xData.findIndex(item => item == x);
		},
		setDataInfo: function (params) {
			this.xData_selected = params.name;
			this.dataset_utenti = null;
			this.detail_title = null;
		},
		fetchDati: function() {
			if(this.filters.aree.length == 0) {
				this.filters.aree = this.moduli.map(function(item) { return item.name });	
			}
			this.dataset = null;
			this.xData_selected = null;
			this.dataset_utenti = null;
			this.detail_title = null;
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/log/utilizzo/aree/by/day', {params: this.filters }).then(function(data) {
				this.dataset = data.data;
				this.loading = false;
			});
		},
		onRowSelected(items) {
			this.dataset_utenti = null;
			this.detail_title = null;
			if(items.length > 0 && items[0].value > 0) {
				var filters = {
					from: null,
					to: null,
					aree: this.filters.aree,
				};
				if(this.aggregate == 'day') {
					filters.from = this.graph_type == 'pie' ? this.moment(this.firstDay).format('YYYY-MM-DD') : this.moment(this.xData_selected).format('YYYY-MM-DD');
					filters.to = filters.from;
				} else if(this.aggregate == 'week') {
					if(this.graph_type == 'pie') {
						filters.from = this.moment(this.firstDay).format('YYYY-MM-DD');
						filters.to = this.moment(this.lastDay).format('YYYY-MM-DD');
					} else {
						var week = this.moment(this.xData_selected, 'YYYY-WW').toDate();
						var startDate = this.moment(week).startOf('isoWeek');
						if (startDate < this.moment(this.firstDay)) {
							startDate = this.moment(this.firstDay);
						}
						var endDate = this.moment(week).endOf('isoWeek');
						if (endDate > this.moment(this.lastDay)) {
							endDate = this.moment(this.lastDay);
						}
						filters.from = startDate.format('YYYY-MM-DD');
						filters.to = endDate.format('YYYY-MM-DD');
					} 
				} else if(this.aggregate == 'month') {
					if(this.graph_type == 'pie') {
						filters.from = this.moment(this.firstDay).format('YYYY-MM-DD');
						filters.to = this.moment(this.lastDay).format('YYYY-MM-DD');
					} else {
						var month = this.moment(this.xData_selected, 'YYYY-MM').toDate();
						var startDate = this.moment(month).startOf('month');
						if (startDate < this.moment(this.firstDay)) {
							startDate = this.moment(this.firstDay);
						}
						var endDate = this.moment(month).endOf('month');
						if (endDate > this.moment(this.lastDay)) {
							endDate = this.moment(this.lastDay);
						}
						filters.from = startDate.format('YYYY-MM-DD');
						filters.to = endDate.format('YYYY-MM-DD');
					} 
				}
				if(this.filters.group_by == 0) {
					filters.area = items[0].id;
					this.detail_title = this.moduli.find(item => item.name == items[0].id).label;
				} else if(this.filters.group_by == 1) {
					filters.gruppo_id = items[0].id ? items[0].id : -1;
					this.detail_title = items[0].id ? this.gruppi.find(item => item.id == items[0].id).nome : 'Senza gruppo';
				} else {
					filters.user_id = items[0].id;
					this.detail_title = this.utenti.find(item => item.id == items[0].id).nomeCompleto;
				}
				this.loading_utenti = true;
				this.$http.get(this.$store.state.apiEndPoint + '/log/utilizzo/aree/by/utente', {params: filters }).then(function(data) {
					this.dataset_utenti = data.data;
					this.loading_utenti = false;
				});
			}
      	},
	},
}

</script>