<template>
	<div>
		<b-card no-body class="p-1 px-2">
			<b-row>
				<b-col cols="1" class="align-items-center d-flex text-center justify-content-between">
					<div class="text-muted" style="font-size: 70%; text-transform: uppercase">TEST</div>
					<div style="font-size: 120%" class="font-weight-bold mr-2">{{ fase.order + 1 }}</div>
				</b-col>
				<b-col cols="3" class="align-items-center d-flex" style="line-height: 1.25">
					<div>
						<strong>{{ fase.nome }}</strong>
						<!--
						<div class="d-flex">
							
							<div class="mr-3"  v-for="a,k in [ 'option_value_1', 'option_value_2', 'option_value_3', 'option_value_4', 'option_value_5', 'option_value_6' ]">
								{{ fase.entity.test[a.replace('option_', 'label_')] }}: <strong>{{ fase.entity[a.replace('option_', '')] }}</strong>
							</div>

						</div>
						-->
					</div>
				</b-col>
				<b-col class="align-items-center d-flex">
					<div>
						<span style="text-transform: uppercase" class="small text-muted mr-1">Durata</span>

						<span v-if="(fase.durata != '00:00:00' && fase.durata != null) || (fase.durata_reale != '00:00:00' && fase.durata_reale != null)">
							<span class="ml-1" v-if="fase.durata_reale == '00:00:00' || fase.durata_reale == null">{{ fase.durata }}</span>
							<span class="ml-1" v-else>{{ fase.durata_reale }} <em class="text-muted small">eff.</em></span>
						</span>
						<span v-else class="small text-muted">N.D.</span>

					</div>
				</b-col>
				<b-col class="align-items-center d-flex" cols="5">
					<div style="line-height: 1">
						<span v-if="zoneDelCorpo.length < 10">
							<b-badge v-for="z in zoneDelCorpo" class="mr-1">{{z}}</b-badge>
						</span>
						<span v-else-if="zoneDelCorpo.length > 0">
							<b-badge variant="primary">{{zoneDelCorpo.length}} zone</b-badge>
						</span>
						<div v-if="fase.injuries.length > 0">
							<small class="text-muted" v-for="inj in fase.injuries" variant="warning">{{inj.pathology.name}} ({{inj.pathology.code}})</small>
						</div>
					</div>
				</b-col>
				<b-col cols="1" class="d-flex align-items-center justify-content-center">
					<span v-if="checked == 1">
						<em class="fa fa-check"></em>
					</span>
					<span v-else-if="checked == -1">
						<em class="fa fa-times"></em>
					</span>
					<span v-else class="muted small">
						<small><em>A.C. Assente</em></small>
					</span>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

export default {
	components: {
		FisioItemsSpan: require('@/components/layout/Sessioni/Medical/FisioItemsSpan.vue').default,
	},
	mixins: [
	],
	props: [ 'fase' ],
	computed: {
		zoneDelCorpo: function() {
			if(this.fase.Fase_Ext_BodyParts && this.fase.Fase_Ext_BodyParts.body_parts) {
				return _.uniq(_.map(this.fase.Fase_Ext_BodyParts.body_parts, 'group_name'));
			}
			return false;
		},
		checked: function() {
			var p = _.uniq(_.map(this.fase.persone, 'pivot.checked'));

			if(p.length == 1) {
				if(p[0] == true) return 1;
				else if(p[0] == false) return -1;
			}

			return null;

		}
	},
	filters: {
	}
}	

</script>