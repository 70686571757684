<template id="gruppi">
  <section class="gruppi">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>Referenti</span>
        </div>
        <div class="content">
          <admin-table
          api-url="/admin/squadre/referenti"
          :columns="columns"
          :editFormData="squadreData"
          :canDelete="false"
          :defaultFilter="false"
          @showModal="onShowModal">
          <template slot="edit-modal-body" slot-scope="row">
            <b-form-group label="Referenti">
              <multiselect
              v-model="squadreData.referenti"
              :multiple="true"
              :options="referenti_options"
              placeholder="Seleziona referente"
              label="nomeCompleto"
              track-by="id"/>
            </b-form-group>
          </template>
        </admin-table>
      </div>
    </div>
  </div>
  <footerapp></footerapp>
</section>
</template>

<script>
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default
  },

  data() {
    return {
      columns: [
      { key: 'id', label: 'ID' },
      { key: 'squadra', label: 'Squadra' },
      { key: 'referenti', label: 'Referenti / Notifiche'},
      { key: 'actions', label: 'Azioni' },
      ],
      squadreData: {
        referenti: [],
      },
      referenti_options: [],
    };
  },
  methods: {
    onShowModal(row) {
      this.squadreData.referenti = row.item.referenti;
    },
  },
  created: function() {
    axios.get('/admin/utenti/football').then((res) => {
      this.referenti_options = res.data;
    });
  },
};
</script>

<style lang="scss">

.pac-container {
  z-index: 1051 !important;
}
</style>
