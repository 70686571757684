<template>
  <b-dropdown dropup split no-caret variant="primary"
    :text="title || (currentPlayer ? currentPlayer.name : null) || team.name"
    :class="['w-100', currentPlayer ? 'current-player' : '']"
    @click="currentPlayer ? setPlayer(currentPlayer) : setTeam()">
    <b-dropdown-item @click="setTeam()">
      {{ team.name }}
    </b-dropdown-item>
    <b-dropdown-item @click="setCoach()" v-if="team.coach">
      {{ team.coach.name }} (coach)
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item
      v-for="player in players"
      :key="player.id"
      @click="setPlayer(player)">
      {{ player.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { filter, pick, isArray } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  props: {
    team: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters(['currentPlayerId', 'players']),

    players() {
      return filter(this.team.players, 'visible');
    },

    currentPlayer() {
      const result = filter(this.team.players, player => player.id === this.currentPlayerId);
      return isArray(result) && result.length === 1 ? result[0] : null;
    },
  },

  methods: {
    setPlayerOrTeam(player) {
      this.$store.commit('updateTagField', {
        key: player.isTeam ? 'team' : (player.isCoach ? 'coach' : 'player'),
        value: pick(player, ['id', 'name']),
      });

      this.$store.dispatch('saveTag', true);
    },

    setTeam() {
      this.setPlayerOrTeam({
        ...this.team,
        isTeam: true,
      });
    },

    setCoach() {
      this.setPlayerOrTeam({
        ...this.team.coach,
        isCoach: true,
      });
    },

    setPlayer(player) {
      this.setPlayerOrTeam(player);
    },
  },
};
</script>

<style lang="scss">
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  flex-shrink: 5;
}
</style>
