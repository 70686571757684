<template>
	<div>
		<b-form-select :disabled="disabled" v-model="test[field]">
			<option :value="null">seleziona</option>
			<option :value="opt" v-for="opt in options.range">{{opt}}</option>
		</b-form-select>
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'disabled' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	computed: {
	}
}
	
</script>