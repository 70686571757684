<template>
	<section class="players-matches">
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card title="Players Disciplinary" :no-body="isWidget ? true : false">
			<b-tabs v-model="activeIndex">
				<b-tab v-for="(tab, index) in tabs" :key="tab.id" :title="tab.label" @click="selectTab(index)">
					<b-table-simple :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 70) + 'px' : '70vh'" striped hover bordered responsive fixed>
						<b-thead>
							<b-tr>
								<b-th v-if="ux.hideDisabled" style="background-color:#FAFAFA;vertical-align: middle;padding: 0; width:40px !important;" class="text-center" sticky-column></b-th>
								<b-th style="background-color:#FAFAFA;width:220px !important;" sticky-column>Player</b-th>
								<b-th style="background-color:#FAFAFA;width:220px !important;" v-for="col,i in columns[index]" :key="col.id" class="text-center"> 
									{{i + 1}}	
								</b-th>
								<b-th class="text-center" style="border:none;">	
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="player in items_filtered[index]" :key="player.id" :variant="ux.hideDisabled == true ? (( hidePreset.indexOf(player.persona_id) !== -1 ) ? 'danger':'') : ''" style="font-size:0.9rem">
								<b-td v-if="ux.hideDisabled" style="padding: 0; vertical-align: middle;" class="text-center" sticky-column><input type="checkbox" v-model="hidePreset" :value="player.persona_id" /></b-td>
								<b-td style="padding: 0; vertical-align: middle;" sticky-column>{{ player.player }}</b-td>
								<b-td style="padding: 0" class="text-center" v-for="col in columns[index]">
									<div v-if="player[col]" :style="'width:100%;padding:5px;' + getStyleByState(player[col].state)" v-b-tooltip.top.html="{ customClass: 'tooltipClassCell' }" :title="player.player + '<br/>' + (getMatch(index, player[col].match_id).real_competition ? getMatch(index, player[col].match_id).real_competition + '<br/>' : '') + (getMatch(index, player[col].match_id).home ? getMatch(index, player[col].match_id).opponent + ' - AS Roma' : 'AS Roma - ' + getMatch(index, player[col].match_id).opponent) + '<br/>' + getMatch(index, player[col].match_id).score + '<br/>' + getMatch(index, player[col].match_id).date">
									 <strong>{{ player[col].real_competition ? player[col].real_competition : 'gara ' + player[col].day }}</strong> <em>{{ getMatch(index, player[col].match_id).home ? getMatch(index, player[col].match_id).opponent + ' - AS Roma' : 'AS Roma - ' + getMatch(index, player[col].match_id).opponent }}</em>
								</div>
								<div v-else style="width:100%;padding:5px;">
									&nbsp;
								</div>
							</b-td>
							<b-td style="background-color:#FFFFFF;border:none;">	
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-tab>
		</b-tabs>

		<b-row class="mt-1" align-v="center" v-if="!isWidget || isFullscreen">
			<b-col cols="6">
				<b-badge :style="getStyleByState(1)" class="mr-1">
					Ammonito
				</b-badge>
				<b-badge :style="getStyleByState(6)" class="mr-1">
					Pre Diffida
				</b-badge>
				<b-badge :style="getStyleByState(2)" class="mr-1">
					Diffidato
				</b-badge>
				<b-badge :style="getStyleByState(5)">
					Squalificato
				</b-badge>
			</b-col>
			<b-col class="text-right">
				<b-form-checkbox size="sm" v-model="ux.hideDisabled">
					Gestisci Giocatori Nascosti {{ ux.hideDisabled ? '(' + hidePreset.length + ')' : ''}}
				</b-form-checkbox>
			</b-col>
			<b-col>
				<b-button-group>
					<b-button v-if="ux.hideDisabled" variant="outline-success" size="sm" @click.prevent="savePersone(hidePreset)">Aggiorna</b-button>
					<b-button v-if="ux.hideDisabled" variant="outline-warning" size="sm" @click.prevent="svuotaPersone()">Svuota</b-button>
				</b-button-group>
			</b-col>
			<b-col cols="3" class="text-right" v-if="tabs.length > 0 && activeIndex > -1">
				<b-button size="sm" variant="primary" :title="'Download report ' + tabs[activeIndex].label" class="mr-2" @click="download(tabs[activeIndex].id)">{{ tabs[activeIndex].label }} <i class="fa fa-download" aria-hidden="true"></i></b-button>
				<b-button size="sm" variant="primary" title="Download report completo" class="mr-2" @click="download('')"><i class="fa fa-download" aria-hidden="true"></i></b-button>
			</b-col>
		</b-row>
	</b-card>
</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
import { mapState } from 'vuex';
var Auth = require('@/auth.js');
export default {
	mixins: [ pageBase ],
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function() {
		return {
			loading: false,
			tabs: [],
			items: [],
			columns: [],
			matches: [],
			activeIndex: 0,
			ux: {
				hideDisabled: false,
			},
			hidePreset: [],
		}
	},
	mounted() {
		this.fetchPlayersMatches();
	},
	computed: {
		user: function() {
			return Auth.user;
		},
		items_filtered: function() {
			return _.map(this.items, function(array) {
				return _.filter(array, function(a) {
					if(this.ux.hideDisabled == false) {
						return this.hidePreset.indexOf(a.persona_id) == -1;
					} else
					return true;
				}.bind(this));
			}.bind(this));
		},
		...mapState(['rosaTeamId']),
	},
	watch: {
		rosaTeamId() {
			this.fetchPlayersMatches();
		},	
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		}
	},
	created: function() {
		if(this.isWidget) {
			if(this.configWidget && this.configWidget.hidePlayers) {
				this.hidePreset = this.configWidget.hidePlayers;
			} else {
				this.hidePreset = [];
			}
		} else if(this.auth.presets().playersYellowsHide != undefined) {
			this.hidePreset = _.cloneDeep(this.auth.presets().playersYellowsHide)
		} else {
			this.hidePreset = [];
		}
	},
	methods: {
		selectTab(index) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'activeTab', index);
			}
		},
		refreshData: function() {
			this.fetchPlayersMatches();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		fetchPlayersMatches: function() {
			this.tabs = [];
			this.items = [];
			this.columns = [];
			this.matches = [];
			this.activeIndex = 0;
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/report/players/matches', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then(function(data) {
				Object.entries(data.data).forEach(([key, item]) => {
					this.tabs.push({id: key, label: item.name});
					this.matches.push(item.matches);
					this.columns.push(Array.from({length: (item.countEvents)}, (_, i) => 'event_id_' + (i + 1)));
					const sortedPlayers = _.sortBy(Object.values(item.players), ['player']);
					const playersYellows = sortedPlayers.map(obj => {
						return Object.assign({}, {player: obj.player, persona_id: obj.persona_id}, obj.events);	
					});
					this.items.push(playersYellows);
				});
				this.loading = false;
				_.delay(function() { this.setTabActive(); }.bind(this), 100);
			});
		},
		setTabActive() {
			this.activeIndex = this.configWidget && this.configWidget.activeTab != undefined ? this.configWidget.activeTab : 0;
		},
		getMatch: function(index, match_id) {
			return this.matches[index].find((item) => item.id == match_id);
		},
		download: function(league) {
			var type = '0';
			if(league != '') {
				type = league;
			}
			var url = this.$store.state.apiEndPoint + '/report/download/players/yellows/' + this.rosaTeamId + '/' + type + '/' + this.user.id + "/" + this.user.api_token;
			if(this.hidePreset.length > 0) {
				url = url + '?hide_players=' + this.hidePreset;
			}
			window.open(url, "_blank");
		},
		getStyleByState: function(state)  {
			var bg = 'background:transparent';
			if(state == 1) {
				bg = 'background:#FFEB3B;color:black';
			} else if(state == 2) {
				bg = 'background:orange;color:black';
			} else if(state == 5) {
				bg = 'background:black;color:white';
			} else if(state == 6) {
				bg = 'background:#FFCA28;color:black';
			}
			return bg;
		},
		svuotaPersone: function() {
			this.hidePreset = [];
			this.savePersone(this.hidePreset);
		},
		savePersone: function(persone) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'hidePlayers', persone);
				this.ux.hideDisabled = false;
			} else {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'playersYellowsHide', persone: persone }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.ux.hideDisabled = false;
				});
			}
		},
	}
};
</script> 
<style>
.tooltipClassHeader {
	text-align: center;
	min-width:250px;
}

.tooltipClassCell {
	text-align: center;
	font-size: 0.9rem !important;
	min-width:250px;
}
</style>   
