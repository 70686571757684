<template>

	<div>

		<div class="d-flex justify-content-between">
			<span>Permessi ({{ permessiNelSistemaRelazioneUtente.length }})</span> 
			<div>
				<label class="mr-1"><input type="radio" :value="0" v-model="ux.onlyOwned"> Tutti</label>
				<label class="mr-1"><input type="radio" :value="2" v-model="ux.onlyOwned"> Non Associati</label>
				<label class="mr-1"><input type="radio" :value="1" v-model="ux.onlyOwned"> Associati</label>
			</div>
		</div>

		<div style="background-color: rgba(0,0,0,.05); border-radius: 10px;" class="p-2 px-2 mb-2">
			<b-row>
				<b-col>
					<b-form-group label="Cerca Permesso">
						<b-input type="search" v-model.debounce.500="ux.search"></b-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Filtra per Gruppo">
						<b-form-select v-model="ux.group">
							<b-form-select-option :value="null">Tutti</b-form-select-option>
							<b-form-select-option :value="-1">Nessuno</b-form-select-option>
							<b-form-select-option v-for="gruppo in catalogo.gruppi" :value="gruppo.id">{{ gruppo.nome }} ({{_.filter(permessi, { byGroup: gruppo.id }).length}})</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Filtra per Categoria">
						<b-form-select v-model="ux.category">
							<b-form-select-option :value="null">Tutte</b-form-select-option>
							<b-form-select-option :value="-1">Nessuna</b-form-select-option>
							<b-form-select-option v-for="categoria in catalogo.categorie" :value="categoria.id">{{ categoria.name }} ({{_.filter(permessi, { categoria_id: categoria.id }).length}})</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</b-col>
			</b-row>
		</div>


		<div class="text-right my-1 mb-2">
			<b-button-group size="sm">
				<b-button disabled>Operazioni</b-button>
				<b-button v-if="ux.mode == 'edit'" variant="outline-secondary" @click.prevent="ux.mode = null">Aggiungi Permessi</b-button>
				<b-button v-if="ux.mode == null" variant="outline-secondary" @click.prevent="ux.mode = 'edit'">Seleziona Permessi</b-button>
			</b-button-group>
		</div>
		<b-table-simple striped small>
			<b-thead>
				<b-tr>
					<b-th width="18%">Permesso</b-th>
					<b-th width="30%">Info</b-th>
					<b-th width="35%">Gruppi</b-th>
					<b-th width="15%">Categoria</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="permesso in permessiNelSistemaRelazioneUtente">
					<b-td>
						<div class="d-flex justify-content-between">
							<div class="small">{{ permesso.nome }}</div>
							<div class="small" v-if="!ux.mode">
								<a href="" v-if="toCreate.permessi.indexOf(permesso.id) === -1" @click.prevent="pushToCreate(permesso)">
									+ ({{ toCreate.permessi.length }})
								</a>
								<span v-else>•</span>
							</div>
						</div>
					</b-td>
					<b-td class="small"><span v-if="permesso.info">{{ permesso.info.info }}</span></b-td>
					<b-td class="small">
						<span class="mr-1" v-for="a, u in permesso.inGroup"><span :class="{ 'text-success': a.isIn, 'text-muted': !a.isIn }">{{ a.nome }}</span>{{ u == permesso.inGroup.length - 1 ? '' : ',' }} </span>
					</b-td>
					<b-td class="small">
						<div class="mr-1" v-for="a, u in permesso.hasRight">
							<label>
								<input type="checkbox" v-model="toEdit.permessi" :value="a[0]" v-if="ux.mode == 'edit' && a[0].byGroup == null" class="mr-1">
								{{ u != 'null' ? '✔︎ ' + getCategoria(u).name : '✔︎' }}
							</label>
						</div>
					</b-td>
				</b-tr>
			</b-tbody>
			<b-tfoot>
				<b-tr>
					<b-td class="text-center" colspan="4"><a v-if="!ux.mode" href="" @click.prevent="addAllView">Aggiungi tutti i permessi alla selezione</a></b-td>
				</b-tr>
			</b-tfoot>
		</b-table-simple>

		<div v-if="toEdit.permessi.length > 0 && ux.mode == 'edit'" class="mt-2">
			<hr />
			<b-card class="mt-2">

				<template #header>Modifica Permessi</template>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th>Permesso</b-th>
							<b-th>hasCategory</b-th>
							<b-th>Info</b-th>
							<b-th>Categoria</b-th>
							<b-th>act</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="permesso, k in toEdit.permessi">
							<b-td>{{ getPermesso(permesso.permesso).nome }}</b-td>
							<b-td>{{ getPermesso(permesso.permesso).hasCategory ? 'Si' : 'No'}}</b-td>
							<b-td>{{ getPermesso(permesso.permesso).info ? getPermesso(permesso.permesso).info.info : '' }}</b-td>
							<b-td>{{ getCategoria(permesso.categoria_id) ? getCategoria(permesso.categoria_id).name : '' }}</b-td>
							<b-td><a href="" @click.prevent="toEdit.permessi.splice(k, 1)">Rimuovi</a></b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-row class="align-items-end">
					<b-col>
						<b-form-group label="Seleziona Categoria">
							<b-form-select v-model="toEdit.category_id">
								<b-form-select-option :value="null">Non Modificare</b-form-select-option>
								<b-form-select-option v-for="cat in catalogo.categorie" :value="cat.id">{{cat.name}}</b-form-select-option>
							</b-form-select>
						</b-form-group>
						<b-button variant="outline-info" @click.prevent="do_update" :disabled="toEdit.category_id == null" block>Cambia Categoria</b-button>
					</b-col>
					<b-col>
						<b-button variant="danger" @click.prevent="do_delete" block>Elimina <strong>{{ toEdit.permessi.length }}</strong> permessi</b-button>
					</b-col>
				</b-row>

			</b-card>
		</div>

		<div v-if="toCreate.permessi.length > 0 && ux.mode == null" class="mt-2">
			<hr />
			<b-card class="mt-2">

				<template #header>Crea nuovi Permessi</template>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th>Permesso</b-th>
							<b-th>hasCategory</b-th>
							<b-th>Info</b-th>
							<b-th>act</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="permesso, k in toCreate.permessi">
							<b-td>{{ getPermessoById(permesso).nome }}</b-td>
							<b-td>{{ getPermessoById(permesso).hasCategory ? 'Si' : 'No'}}</b-td>
							<b-td>{{ getPermessoById(permesso).info ? getPermessoById(permesso).info.info : '' }}</b-td>
							<b-td><a href="" @click.prevent="toCreate.permessi.splice(k, 1)">Rimuovi</a></b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-form-group label="Associa Categoria">
					<b-form-select v-model="toCreate.category_id">
						<b-form-select-option :value="null">Nessuna</b-form-select-option>
						<b-form-select-option v-for="cat in catalogo.categorie" :value="cat.id">{{cat.name}}</b-form-select-option>
					</b-form-select>
				</b-form-group>

				<div class="mt-2">
					<b-button-group size="sm">
						<b-button @click.prevent="do_create(false)" variant="outline-secondary">Crea</b-button>
						<b-button @click.prevent="do_create(true)" variant="outline-secondary">Crea e Rimani</b-button>
						<b-button @click.prevent="toCreate = { category_id: null, permessi: [] }" variant="outline-secondary">&times; Annulla</b-button>
					</b-button-group>
				</div>

			</b-card>
		</div>

	</div>

</template>

<script>

import pageBase from '@/mixins/pageBase.js';

export default {
	mixins: [ pageBase ],
	props: [ 'utente', 'catalogo', 'permessi' ],
	components: {
	},
	data: function() {
		return {
			toCreate: {
				category_id: null,
				permessi: []
			},
			toEdit: {
				category_id: null,
				permessi: []
			},
			ux: {
				mode: null,
				group: null,
				onlyOwned: 0,
				allRights: false,
				showAll: false,
				search: '',
				category: null,
				copyCategory: null,
				action: null,
				show: false
			}
		}
	},
	computed: {
		notFiltering: function() {
			return this.ux.search.length == 0 && this.ux.category == null;
		},
		hasGroup: function() {
			return _.size(_.groupBy(this.permessi, 'byGroup')) > 1;
		},
		permessiNelSistemaRelazioneUtente: function() {

			var b = _.clone(this.catalogo.permessi);

			if(this.ux.search.length > 2) {

				var toSearch = _.map(_.filter(this.ux.search.split(' ')), function(i) { return i.toLowerCase(); });

				var b = _.filter(b, function(perm) {
					var valid = true;
					_.each(toSearch, function(i) {
						var info = perm.info && perm.info.info ? perm.info.info.toLowerCase() : ''
						if(perm.nome.toLowerCase().indexOf(i) === -1 && info.indexOf(i) === -1)
							valid = false;
					});

					return valid;

				});

			}

			b = _.map(b, function(e) {

				e.inGroup = this.inGroup(e);
				e.hasRight = this.hasRight(e);
				return e;

			}.bind(this));

			if(this.ux.group) {

				if(this.ux.group != -1) {
					b = _.filter(b, function(i) {

						var g = _.find(this.catalogo.gruppi, { id: this.ux.group });
						if(!g) return false;
						return _.filter(g.permessi, { nome: i.nome }).length

						var permessiSpecifici = _.flatten(_.values(i.hasRight));
						return _.filter(permessiSpecifici, { byGroup: this.ux.group }).length > 0;

					}.bind(this));
				} else if(this.ux.group == -1) {
					b = _.filter(b, function(a) {

						if(this.ux.onlyOwned == 1) {
							return _.filter(_.flatten(_.values(a.hasRight)), { byGroup: null }).length > 0;
						} else {
							return _.size(a.inGroup) == 0;
						}


					}.bind(this));
				}
			}

			if(this.ux.onlyOwned == 1) {

				b = _.filter(b, function(i) {

					if(this.ux.category) {
						return _.size(_.filter(_.flatten(_.values(i.hasRight)), { categoria_id: this.ux.category != -1 ? this.ux.category : null })) > 0;
					} else 
						return _.size(i.hasRight) > 0;
				}.bind(this));

			} else if(this.ux.onlyOwned == 2) {
				b = _.filter(b, function(i) {

					if(this.ux.category) {
						return _.size(_.filter(_.flatten(_.values(i.hasRight)), { categoria_id: this.ux.category != -1 ? this.ux.category : null })) == 0;
					} else 
						return _.size(i.hasRight) == 0;

				}.bind(this));
			}


			b = _.map(b, function(e) {

				e.selectable = false;

				// if(_.flatten())
				// console.log(e);


				return e;

			}.bind(this));

			return _.orderBy(b, ['nome'],['asc']);

		}
	},
	mounted: function() {

	},
	methods: {
		do_delete: function() {
			this.$emit('deletePermessi', _.clone(this.toEdit.permessi));
			this.toEdit = { category_id: null, permessi: [] };
		},
		do_update: function() {
			this.$emit('updatePermessi', _.clone(this.toEdit));
			this.toEdit = { category_id: null, permessi: [] };
		},
		addAllView: function() {
			_.each(this.permessiNelSistemaRelazioneUtente, function(i) {
				this.pushToCreate(i);
			}.bind(this));
		},
		do_create: function(stay = false) {

			this.$emit('newPermessi', _.clone(this.toCreate));

			if(stay == false)
				this.toCreate = { category_id: null, permessi: [] };
			else
				this.toCreate.category_id = null;

		},
		pushToCreate: function(permesso) {

			if(this.toCreate.permessi.indexOf(permesso.id) === -1)
				this.toCreate.permessi.push(permesso.id);

		},
		hasRight: function(permesso, categoria) {
			if(categoria)
				return _.groupBy(_.groupBy(_.filter(this.permessi, { categoria_id: (categoria != -1 ? categoria : null) }), 'permesso')[permesso.nome], 'categoria_id');
			else
				return _.groupBy(_.groupBy(this.permessi, 'permesso')[permesso.nome], 'categoria_id');
		},
		inGroup: function(permesso, categoria) {

			var gruppiUtente = _.map(this.utente.utenti_gruppi, 'id_gruppo');

			return _.map(_.filter(this.catalogo.gruppi, function(b) {
				if(categoria)
					return _.filter(b.permessi, { id: permesso.id, categoria_id: (categoria != -1 ? categoria : null) }).length > 0;
				else
					return _.filter(b.permessi, { id: permesso.id }).length > 0;
			}), function(c) { 
				return { 
					nome: c.nome, 
					id: c.id,
					isIn: gruppiUtente.indexOf(c.id) !== -1
				}
			});

			return _.filter(this.catalogo.gruppi, function(a) {
				return _.filter(a.permessi, function(b) {
					return b.nome == permesso.nome;
				}) > 0;
			});

		},
		getPermesso: function(nome) {
			return _.find(this.catalogo.permessi, {nome});
		},
		getPermessoById: function(id) {
			return _.find(this.catalogo.permessi, {id});
		},
		getCategoria: function(id) {
			return _.find(this.catalogo.categorie, {id:parseInt(id)});
		}
	}
}

</script>