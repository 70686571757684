<template>
	<b-card>
		<template v-if="ux.show && !compact" #footer>
			<b-row>
				<b-col>
					<b-button variant="outline-danger" @click.prevent="removeRule">Elimina Regola</b-button>
				</b-col>
			</b-row>
		</template>

		<b-row>
			<b-col>
				<a @click.prevent="ux.show = !ux.show" href=""><strong>{{ rule.name }}</strong></a>
			</b-col>
			<b-col class="text-right">
				<b-badge>rule{{ rule.posizione }}</b-badge>
			</b-col>
		</b-row>
		<div v-if="ux.show">

			<b-row>
				<b-col cols="7">
					<em><u>Configurazione</u></em>

					<b-form-group label="Nome">
						<b-input v-model="rule.name"></b-input>
						<template #description>Il nome della regola ha il solo scopo di descrivere la configurazione.</template>
					</b-form-group>

					<Rule-Config :behaviour="ruleInfo.behaviour" :contratto="contratto" :bonus="bonus" :rule="ruleInfo.payload" :config="rule.input_payload" :basemultitarget="rule.multitarget" @multitarget="rule.multitarget = $event" @update="rule.input_payload = $event" :nomultitarget="nomultitarget"></Rule-Config>

				</b-col>
				<b-col cols="5">
					<em><u>Informazioni Regola</u></em>
					<dl>
						<dt>Nome</dt>
						<dd>{{ ruleInfo.name }}</dd>
						<dt>Descrizione</dt>
						<dd>{{ ruleInfo.description }}</dd>
					</dl>
				</b-col>
			</b-row>

			<!--
			<hr />
			<pre>{{ ruleInfo }}</pre>
			<pre>{{rule.input_payload}}</pre>
			<pre>{{rule}}</pre>
			-->

		</div>

	</b-card>
</template>


<script>


export default {
	props: [ 'rule', 'bonus', 'compact', 'contratto', 'nomultitarget' ],
	components: {
		RuleConfig: require('./RegolaConfiguratore.vue').default
	},
	data: function() {
		return {
			ux: {
				show: false
			},
			ruleInfo: false
		}
	},
	created: function() {
		console.log(this.rule, this.bonus, this.compact, this.contratto);
		if(this.compact == true)
			this.ux.show = true;
	},
	watch: {
		'rule.regola': {
			deep: true,
			immediate: true,
			handler: function() {
				this.getRuleInfo();
			}
		}
	},
	methods: {
		removeRule: function() {
			var confirm = window.confirm("Confermi la rimozione della regola?");
			if(confirm) {
				this.$emit('removerule');
			}
		},
		getRuleInfo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/rule/' + this.rule.regola).then(function(data) {
				console.log(data);
				this.ruleInfo = data.data;
			});
		}
	}
};

</script>