<template>

	<div>

		<headapp></headapp>

		<div style="background-color: white" class="p-1 px-2 breadcrumbWrap">
			<div style="background-color: rgba(0,0,0,.06); border-radius: 3px; width: auto" class="px-4 py-2">
				<b-row align-v="center">
					<b-col cols="9">
						<b-breadcrumb style="background-color: transparent" class="m-0 p-0" :items="breadcrumb"></b-breadcrumb>
					</b-col>
					<b-col class="text-right">
						<b-form-input size="sm" v-model="ux.search" placeholder="Quick search"></b-form-input>
					</b-col>
				</b-row>
			</div>

		</div>

		<b-card title-tag="div">
			<b-overlay :show="ux.loading">
				<b-row>
					<b-col sm="1" v-for="squadra in filtered_squadre" style="padding: 0.2rem">
						<router-link :to="{ name: area + '_next_club_paese_competizione_squadra', params: { paese_id: $route.params.paese_id, squadra_id: squadra.id, competizione_id: $route.params.competizione_id } }">
							<b-card class="small text-center" style="height:100%">
								<b-img :src="squadra.logo_url" fluid :alt="squadra.officialName" :width="120"/>
								<br/>
								<span>{{squadra.officialName}}</span>
							</b-card>
						</router-link>
					</b-col>
					<b-col sm="1" style="padding: 0.2rem">
						<b-card class="small text-center" style="cursor: pointer;height:100%;border: 5px dashed #e5e5e5;" @click="showSearchTeam">
							<div class="text-center">
								<p class="mb-0"><i class="fa fa-search text-muted fa-5x"></i></p>
								<strong class="text-uppercase text-muted">Cerca squadra</strong>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</b-overlay>
			<b-modal id="searchTeamModal" ref="searchTeamModal" title="Cerca squadra" ok-only ok-title="Chiudi" size="lg">
				<div>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
						<b-row class="mb-1">
							<b-col sm="6">
								<b-form-select v-model="filters.categoria">
									<option value="0">-- Categoria --</option>
									<option v-for="item in categorie_opt" :value="item.id" :key="item.id">{{item.name}}</option>
								</b-form-select>
							</b-col>
							<b-col sm="6">
								<b-input-group>
									<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>  	
							</b-col>
						</b-row>
						<div v-if="teams.data && teams.data.length == 0" class="my-2">
							<h5>Nessuna squadra trovata!</h5>
							<b-button :to="{ name: area +'scout_squadre_new'}" variant="outline-primary" target="_blank" class="mt-2">Aggiungi squadra</b-button>
						</div>	
						<b-row>
							<b-col cols="4" v-for="team in teams.data" v-bind:key="team.id" style="padding: 0.2rem">
								<router-link :to="{ name: area + '_next_club_paese_competizione_squadra', params: { paese_id: $route.params.paese_id, squadra_id: team.id, competizione_id: $route.params.competizione_id } }">
									<b-card class="small text-center py-1" style="cursor: pointer;height:100%;">
										<img :src="team.logo_url" width="50" /><br/>
										<strong>{{team.officialName}}</strong><br/>
										<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
									</b-card>
								</router-link>
							</b-col>
						</b-row>	
						<b-row>
							<b-col cols="6" style="padding: 0.2rem">
								<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
							</b-col>	

							<b-col cols="6" style="padding: 0.2rem">
								<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
							</b-col>	
						</b-row>				
					</div>
				</div>
			</b-modal>
		</b-card>

		<footerapp></footerapp>

	</div>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			competizione: null,
			squadre: null,
			categorie_opt: [],
			teams: [],
			page: 1,
			ux: {
				search: '',
				loading: true,
			},
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
		}
	},
	components: {
		flag: require('@/components/layout/flag.vue').default,
	},
	watch: {
		'$route.params.competizione_id': {
			immediate: true,
			handler: function() {
				this.fetchCompetizione();
				this.fetchCategorie();
				this.fetch();
			}
		}
	},
	methods: {
		fetchCompetizione: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/competizione/' + this.$route.params.competizione_id).then(function(data) {
				this.competizione = data.data;
			});
		},
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/squadre/' + this.$route.params.competizione_id).then(function(data) {
				this.squadre = data.data;
				this.ux.loading = false;
			});
		},
		fetchCategorie: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/categories').then(function(data) {
				this.categorie_opt = data.data.map(item => ({
					name: item.name,
					id: item.id,
				}));
			});
		},
		showSearchTeam: function() {
			this.$refs.searchTeamModal.show();	
		},
		filtraArchivio: function() {
			this.teams = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then(function(data) {
				this.teams = data.data;
			});
		},
		getTeams: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then(function(data) {
				this.teams = data.data;
			});
		},
		paginate: function(page) {
			this.page = page;
			this.getTeams();
		},
	},
	computed: {
		breadcrumb: function() {

			var b = [];

			b.push({
				text: 'Paesi',
				to: {
					name: this.area + '_next_club'
				}
			});

			b.push({
				text: this.competizione ? this.competizione.paese.paese : '',
				to: {
					name: this.area + '_next_club_paese',
					params: {
						paese_id: this.$route.params.paese_id
					}
				}
			});
			b.push({
				text: this.competizione ? this.competizione.nome : '',
				to: {
					name: this.area + '_next_club_paese_competizione',
					params: {
						paese_id: this.$route.params.paese_id,
						competizione_id: this.$route.params.competizione_id
					}
				}
			});

			return b;

		},
		area: function() {
			return this.$route.meta.area;
		},
		filtered_squadre: function () {
			if (this.ux.search != "") {
				return this.squadre.filter((squadra) =>
					squadra.officialName
					.toLowerCase()
					.includes(this.ux.search.toLowerCase())
					);
			}
			return this.squadre;
		},
	}
}

</script>