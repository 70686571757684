<template id="view_giocatore">
	<section class="giocatore">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card" v-if="rosa">
				<div class="card-body">

					<div class="row">
						<div class="col">
							<div class="media">
								<img v-bind:src="rosa.avatar_url" class="" height="100">
								<div class="media-body align-self-center ml-3">
									<h3 class="text-primary mb-0"><strong><a :href="'#/medical/player/' + $route.params.id">{{ rosa.cognome }} {{ rosa.nome }}</a></strong></h3>
									<small class="text-muted">{{ rosa.last_carriera_sportiva.ruolo_ideale && rosa.last_carriera_sportiva.ruolo_ideale.length > 0 ? rosa.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</small>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="card-body">

					<div class="row" v-if="showTable">
						<div class="col-10">	
							<b-table striped show-empty
							ref="illnessesTable"
							:items="illnesses"
							:fields="fields"
							>
							<template #cell(data)="row"><a href="#" @click="selectIllness(row.item.id)">{{ row.value | formatDate }}</a></template>
							<template #cell(return)="row">{{ row.value | formatDate }}</template>
							<template #cell(reillness)="row">{{ row.value ? illness_label[row.value] : 'No' }}</template>
							<template #cell(types)="row">
								<div v-for="item in row.item.illness_types" v-if="item.id != 7"> {{item.name}} </div>
								<div v-for="item in row.item.illness_other_types"> {{item.name}} </div>
							</template>
						</b-table>
					</div>
					<div class="col-2">
						<b-button variant="link" class="w-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;height: 200px;" @click="newIllness">
							<div class="text-center">
								<p class="mb-0"><i class="fa fa-plus-circle text-muted fa-5x"></i></p>
								<strong class="text-uppercase text-muted">NEW ILLNESS</strong>
							</div>
						</b-button>
					</div>
				</div>

				<div class="row" v-if="!showTable">
					<div class="col">
						<b-form @submit="onSubmit">
							<b-card :title="illness.id ? 'Edit Illness' : 'New Illness'" class="mb-1">
								<b-row>
									<b-col>
										<b-form-group label="Date of Illness *">
											<b-form-input type="date" v-model="illness.data"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group label="Date of return of full participation">
											<b-form-input type="date" v-model="illness.return"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Type of illness">
											<b-form-checkbox-group
											v-model="illness.illness_types">
											<b-form-checkbox v-if="!item.other" v-for="item in illness_types" :value="item.id"> {{ item.name}} </b-form-checkbox>	
										</b-form-checkbox-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="illness.illness_types.indexOf(7) > -1">
								<b-col>
									<b-form-group>
										<b-form-checkbox-group
										v-model="illness.illness_other_types">
										<b-form-checkbox v-if="item.other" v-for="item in illness_types" :value="item.id"> {{ item.name}} </b-form-checkbox>	
									</b-form-checkbox-group>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="2">
								<b-form-group description="Is this a second illness?">
									<label>Second Illness</label> <span class="color-rosso" style="cursor:pointer;"><i class="fa fa-info-circle" aria-hidden="true" @click="$refs.infoModal.show()"></i></span>
									<b-form-select v-model="illness.reillness">
										<option :value="0">No</option>
										<option :value="2">Subsequent new illness</option>
										<option :value="3">Subsequent local illness</option>
										<option :value="4">Exacerbation</option>
										<option :value="1">Reillness</option>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group v-if="illness.reillness" label="Previous illnesses">
									<b-form-select v-model="illness.id_illness">
										<option :value="null">Select</option>
										<option v-if="!illness.id || (illness.id && illness.id != item.id)" v-for="item in illnesses" :value="item.id">Illness of {{item.data | formatDate }} - {{ getLabelIllness(item) }} - Data of return {{ item.return | formatDate }}</option>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="3">
								<b-form-group v-if="illness.reillness" label="Date of return from previous illness">
									<b-form-input type="date" v-model="illness.return_reillness"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-form-group label="Diagnosis">
									<b-form-textarea
									v-model="illness.diagnosis"
									rows="3"
									max-rows="6"
									></b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-form-group label="Other comments">
									<b-form-textarea
									v-model="illness.comments"
									rows="3"
									max-rows="6"
									></b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
					</b-card>
					<b-row>
						<b-col>
							<b-button type="submit" variant="success">Save</b-button>
							<b-button type="button" variant="primary" v-if="illness.id" @click="deleteIllness">Delete</b-button>
							<b-button @click="backToIllnesses">Back</b-button>
						</b-col>
						<b-col>
							<div style="text-align:right;"><small> * Campi obbligatori </small></div>
						</b-col>
					</b-row>
				</b-form>
			</div>
		</div>
	</div>
</div>
<b-modal ref="infoModal" title="Second Injury/Illness Work Flow" size="lg" ok-only ok-title="Chiudi">
	<div class="text-center">
		<b-img src="/assets/varie/injury_flow.jpeg" fluid/>
	</div>
</b-modal>
</div>
<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';

export default {
	data: function() {
		return {
			showTable: true,
			rosa: null,
			illnesses: [],
			illness_types: [],
			fields: [
			{ key: 'data', label: 'Date of Illness' },
			{ key: 'return', label: 'Date of return' },
			{ key: 'reillness', label: 'Second Illness' },
			{ key: 'types', label: 'Type of Illness' },
			],
			illness: {
				id: null,
				persona_id: this.$route.params.id,
				data: null,
				return: null,
				reillness: 0,
				id_illness: null,
				return_reillness: null,
				illness_types: [],
				illness_other_types: [],
				diagnosis: null,
				comments: null,
			},
			illness_label: [ 'No', 'Reillness', 'Subsequent new illness', 'Subsequent local illness', 'Exacerbation'],	
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
	},

	created: function () {
		this.getIllnessTypes();
		this.getIllnesses();
	},
	watch: {
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 
	methods: { 
		getIllnesses() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/illnesses/player/' + this.$route.params.id).then((response) => {
				if (response.ok) {
					this.rosa = response.data.rosa;
					this.illnesses = response.data.illnesses;
					this.reset();  
					this.showTable = true; 
				}
			}, (response) => {
			});
		},

		getIllnessTypes() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/illness/types').then((response) => {
				if (response.ok) {
					this.illness_types = response.data; 
				}
			}, (response) => {
			});
		},

		onSubmit(evt) {
			evt.preventDefault()
			if(!this.illness.data) {
				alert("Campo 'Date o Illness' obbligatorio!");
				return;
			} else {
				var m = moment(this.illness.data);
				if(!m.isValid()) {
					alert("Campo 'Date o Illness' invalido!");
					return;
				}
			}
			var apiPath = '/medical/add/illness';
			if(this.illness.id) {
				apiPath = '/medical/edit/illness/' + this.illness.id;
			}
			this.$http.post(this.$store.state.apiEndPoint + apiPath, this.illness).then((response) => {
				if (response.ok) {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getIllnesses();
				}
			}, (response) => {  
				if (response.status == 422) {
				}
			});
		},

		deleteIllness() {
			if(confirm('Confermi la cancellazione di questa malattia?')) {
				this.$http.get(this.$store.state.apiEndPoint + '/medical/delete/illness/' + this.illness.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getIllnesses();
					}
				}, (response) => {
				});
			}
		},

		reset() {
			this.illness.id = null;
			this.illness.data = null;
			this.illness.return = null;
			this.illness.reillness = 0;
			this.illness.id_illness = null;
			this.illness.return_reillness = null;
			this.illness.illness_types = [];
			this.illness.illness_other_types = [];
			this.illness.diagnosis = null;
			this.illness.comments = null;
		},

		newIllness() {
			this.reset();
			this.showTable = false;
		},

		backToIllnesses() {
			this.showTable = true;
			this.reset();
		},

		selectIllness(id) {
			var item = this.illnesses.find(item => item.id === id);
			this.illness.id = item.id;
			this.illness.data = item.data;
			this.illness.return = item.return;
			this.illness.reillness = item.reillness;
			this.illness.id_illness = item.id_illness;
			this.illness.return_reillness = item.return_reillness;
			this.illness.diagnosis = item.diagnosis;
			this.illness.comments = item.comments;

			for(var i in item.illness_types) {
				this.illness.illness_types.push(item.illness_types[i].id);
			}
			for(var i in item.illness_other_types) {
				this.illness.illness_other_types.push(item.illness_other_types[i].id);
			}
			
			this.showTable = false;	
		},

		getLabelIllness(illness) {
			var label = '';
			if(illness.illness_types.length > 0) {
				if(illness.illness_types[0].id != 7) {
					label = illness.illness_types[0].name;
					if(illness.illness_types.length > 1) {
						label = label + '...';
					}
				} else if(illness.illness_other_types.length > 0) {
					label = illness.illness_other_types[0].name;
					if(illness.illness_other_types.length > 1) {
						label = label + '...';
					}
				}
			}
			return label;
		}
	},
}
</script>