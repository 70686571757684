<template id="prepostpartita">
	<section class="prepostpartita">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Pre Partita</span>
					</div>
					<filemanager :url="'/prepost/match/' + $route.params.id + '/pre'" theme="asrs"></filemanager>
				</div>
			</div>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>

import moment from 'moment';
import * as types from '../../../../store/types';
import { mapState } from 'vuex';
var Auth = require('../../../../auth.js');

export default {

	data: function() {
		return {
			loading: true,
			data: [],
		}
	},

	components: {
		headapp:             require('../../../layout/headapp.vue').default,
		menusub:             require('../../../layout/menusub.vue').default,
		footerapp:           require('../../../layout/footerapp.vue').default,
		filemanager: 		 require('../../../layout/filemanager/Finder.vue').default,
		
	},

	created: function () {
	},
	computed: {
		auth: function() {
      		return Auth;
    	},
		...mapState(['rosaTeamId']),
	},
	watch: {
	    rosaTeamId() {
	    },  
  	},
	methods: {
	}
}
</script>