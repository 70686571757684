<template>
	<div>
		<b-modal size="xl" v-model="ux.show">

			<template #modal-header>
				Esplora Preset
			</template>

			<template #modal-footer>

				<b-button @click.prevent="addPreset">Aggiungi Preset</b-button>

			</template>

			<div>
				
				<b-nav tabs class="mb-1">
					<b-nav-item :active="ux.tab == 'all'" :disabled="picked" @click.prevent="ux.tab = 'all'">Tutte</b-nav-item>
					<b-nav-item :active="ux.tab == 'primitive'" :disabled="picked" @click.prevent="ux.tab = 'primitive'">Primitive</b-nav-item>
				</b-nav>

				<b-input class="mb-1" :disabled="picked" placeholder="Cerca tra i preset" size="sm" type="search" v-model="ux.search"></b-input>

				<b-card-group :columns="!picked">

					<b-card v-for="preset in thePresets" :title="preset.nome" v-if="!picked || picked.id === preset.id" @click="!picked ? pickPreset(preset) : null">
						<b-card-text>
							{{ preset.descrizione }}
						</b-card-text>
						<b-card-text v-if="picked">
							<hr />
							
							<Regola :contratto="contratto" :compact="true" ref="rule" class="mb-1" :key="rule.posizione" v-for="rule, k in picked.regole_json" :rule="rule" @removerule="do_removeRule(k)"></Regola>

						</b-card-text>
						<!--
						<template #footer>
							<small class="text-muted">ee</small>
						</template>
						-->
					</b-card>


				</b-card-group>

				<a v-if="picked" href="javascript:;" @click.prevent="resetPick">Annulla</a>

			</div>

			<!-- <pre>{{presets}}</pre> -->

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'

export default {
	created: function() {
		//this.ux.show = true;
	},
	components: {
		Regola: require('@/components/layout/contratti/Regola.vue').default,
		RuleConfig: require('./RegolaConfiguratore.vue').default
	},
	watch: {
		'ux.tab': function () {
			this.fetchPresets();
		},
		'ux.search': _.debounce(function () {
			this.fetchPresets();
		}, 300),
		'ux.show': {
			deep: true,
			handler: function() {
				this.fetchPresets();
				if(this.ux.show == false)
					this.$emit('close');
			}
		}
	},
	props: [ 'contratto', 'bonus', 'watchdog' ],
	data: function() {
		return {
			presets: false,
			picked: null,
			ux: {
				show: false,
				tab: 'all',
				search: null
			},
			ruleInfo: false,
			rules: [],
			watchdogRef: null
		}
	},
	methods: {
		resetPick: function() {
			this.picked = null;
			this.ux.tab = 'all';
			this.ux.search = null;
		},
		addPreset: function() {

			if(!this.watchdog) {

				var rulesToDuplicate = _.cloneDeep(this.picked.regole_json);
				var validation = this.picked.validation;

				var currentMax = 1;
				if(this.bonus.rules.length > 0)
					currentMax = _.maxBy(this.bonus.rules, 'posizione').posizione + 1;

				var toCreate = [];
				var toReplace = [];
				var a = 0;

				_.each(rulesToDuplicate, function(i) {

					var o = {
						name: i.name,
						description: i.description,
						regola: i.regola,
						input_payload: i.input_payload,
						posizione: currentMax + (a++)
					};

					toCreate.push(o);
					toReplace.push([i.posizione, o.posizione])

				}.bind(this));

				_.each(toCreate, function(i) {
					this.bonus.rules.push(i);
				}.bind(this));

				_.each(toReplace, function(pair) {
					validation = validation.replace('rule'+pair[0], 'rule'+pair[1]);
				});

				this.bonus.preset_id = this.picked.id;
				this.bonus.validation = validation;

			} else if(this.watchdogRef) {

				var rulesToDuplicate = _.cloneDeep(this.picked.regole_json);
				var validation = this.picked.validation;

				var currentMax = 1;
				if(this.bonus.rules.length > 0)
					currentMax = _.maxBy(this.bonus.rules, 'posizione').posizione + 1;

				var toCreate = [];
				var toReplace = [];
				var a = 0;

				_.each(rulesToDuplicate, function(i) {

					var o = {
						fromWatchdog: true,
						name: 'WatchDog ' + i.name,
						description: 'WatchDog ' + i.description,
						regola: i.regola,
						input_payload: i.input_payload,
						posizione: currentMax + (a++)
					};

					toCreate.push(o);
					toReplace.push([i.posizione, o.posizione])

				}.bind(this));

				_.each(toCreate, function(i) {
					this.bonus.rules.push(i);
				}.bind(this));

				_.each(toReplace, function(pair) {
					validation = validation.replace('rule'+pair[0], 'rule'+pair[1]);
				});

				this.watchdogRef.name = this.picked.nome;
				this.watchdogRef.description = this.picked.descrizione;
				this.watchdogRef.validation = validation;
				this.watchdogRef.preset_id = this.picked.id;
				this.watchdogRef.ownedRulesJson = _.map(toCreate, 'posizione');

			}

			this.ux.show = false;
			this.picked = null;

		},
		pickPreset: function(preset) {
			this.picked = _.cloneDeep(preset);
		},
		fetchPresets: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/presets-rules-public', { params: { tab: this.ux.tab, search: this.ux.search } }).then(function(data) {
				this.presets = data.data;
			});
		},
		show: function() {
			this.ux.show = true;
		}
	},
	computed: {
		thePresets: function() {
			return this.presets;
		}
	}
}

</script>