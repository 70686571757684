<style>
.over{ cursor: pointer; }
.over:hover{
  background-color: #dedede;
  cursor: pointer;
}
.selactive {
  background-color: #cecece;
}
</style>

<template id="exerciseform">
  <div class="container-fluid">
    <div class="card-title">
      <span>{{ params ? $i18n.t(params.titolo) : 'Editor Esercizi'}}</span>
      <div class="pull-right">
        <b-button variant="outline-primary" @click="showGuida" v-if="auth.isUserEnable('academy_ita_ese_mng')"><i class="fa fa-question-circle"></i> {{ $i18n.t('guida') }} </b-button>
        <b-button variant="outline-primary" @click="downloadPdf" v-if="$route.params.id"><i class="fa fa-eye fa-fw"></i> {{ $i18n.t('stampa pdf') }}</b-button>
      </div>
    </div>
    <b-card class="mb-2" v-if="rifiutato">
      <h4 class="color-rosso">{{ $i18n.t('RIFIUTATO') }}</h4>
      <b-form-textarea id="textarea3" plaintext :value="motivo">   
      </b-form-textarea>
    </b-card> 
    <form v-on:submit.prevent="submit()" action="#" method="POST">
      <input id="resolution_w" type="hidden" name="resolution_w" :value="resolution_w"/>
      <input id="resolution_h" type="hidden" name="resolution_h" :value="resolution_h"/>
      <div>
        <div class="card mb-2">
          <div class="card-body">
            <div class="form-group row">
              <label for="titolo" class="col-2 col-form-label">{{ $i18n.t('Titolo esercizio') }}</label>
              <div class="col-10">
                <input class="form-control" type="text" id="title" v-model="title" name="title" value="" :placeholder="$i18n.t('Titolo esercizio')"/>
              </div>
            </div>

            <div class="form-group row flex-items-xs-bottom">
              <label class="col-2 col-form-label">{{ $i18n.t('Difficoltà') }}</label>

              <div class="col-10">

                <b-form-select v-model="difficulty">
                  <template slot="first">
                    <option :value="null" disabled>{{ $i18n.t('seleziona') }}</option>
                  </template>
                  <option v-for="diff in difficulties" value="diff.value"> {{ $i18n.t(diff.text) }}</option>
                </b-form-select>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-deck d-block d-xl-flex">
        <div class="card col-xl-3 px-0" v-if="auth.isUserEnable('academy_ita_ese_mng')">
          <div class="card-header pb-2" id="tabs">
            <ul class="nav nav-tabs card-header-tabs" role="tablist">
              <li class="nav-item m-0 p-0" v-for="(objects, category, id) in canvas_objs" :key="category">
                <a :href="'#' + category" data-toggle="tab" class="nav-link text-capitalize" role="tab" v-bind:class="{'active':(id === 0)}" style="padding: 0.5rem 0.47rem;">{{ category == 'backgrounds' ? 'fields' : category }}</a>
              </li>
              <li class="nav-item m-0 p-0">
                <a href="#editor" data-toggle="tab" class="nav-link text-capitalize" role="tab" style="padding: 0.5rem 0.47rem;">editor</a>
              </li>
            </ul>
          </div>
          <div class="card-body tab-content border-0">
            <div class="tab-pane clearfix" :id="category" role="tabpanel" v-for="(objects, category, id) in canvas_objs" :key="category" v-bind:class="{'active':(id === 0)}">

              <b-form-row v-if="category == 'players'" class="p-3">
                <b-col>
                  <select v-model="shirt" class="form-control">
                    <option value="all">{{ $i18n.t('Maglie') }}</option>
                    <option value="first">{{ $i18n.t('Prima') }}</option>
                    <option value="second">{{ $i18n.t('Seconda') }}</option>
                    <option value="third">{{ $i18n.t('Terza') }}</option>
                    <option value="fourth">{{ $i18n.t('Quarta') }}</option>
                  </select>
                </b-col>
                <b-col>
                  <select v-model="move" class="form-control">
                    <option value="all">{{ $i18n.t('Movimenti') }}</option>
                    <option value="static">{{ $i18n.t('Statico') }}</option>
                    <option value="dynamic">{{ $i18n.t('Dinamico') }}</option>
                  </select>
                </b-col>
              </b-form-row>
              
              <div class="d-inline-block text-center" :style="'min-width: 50px; height: 50px;cursor:pointer;border-radius: 5px;' + (activeObject && obj.id === activeObject.id ? 'background: #a80532;' : '')" @click="changeCanvasBkg(obj, category)" v-for="(obj, index) in objects" :key="index" >
                <img :src="obj.image_url" :class="obj.shirt +' '+ obj.move" style="max-width: 40px;" />
              </div>
            </div>

            <div class="tab-pane clearfix" id="editor" role="tabpanel">
              <b-row align-v="center">
                <b-col cols="3" class="mb-2">
                  <b-form-input style="height:38px" type="color" v-model="text_color" :title="'Colore: ' + text_color"></b-form-input>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'Text' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Text')" title="Testo"><i class="fa fa-font" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button variant="outline-primary" style="width:100%" @click="cloneCanvasObject" title="Clona selezionati"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button variant="outline-primary" style="width:100%" @click="deleteCanvasObject" title="Cancella selezionati"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'Line' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Line')" title="Freccia"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'DashedLine' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('DashedLine')" title="Freccia tratteggiata"><i class="fa fa-ellipsis-h" aria-hidden="true"></i> <i class="fa fa-caret-right" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'FlatLine' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('FlatLine')" title="Linea"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'FlatDashedLine' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('FlatDashedLine')" title="Linea tratteggiata"><i class="fa fa-ellipsis-h" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'CurvedArrow' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('CurvedArrow')" title="Curva"><i class="fa fa-share" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'Triangle' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Triangle')" title="Triangolo"><i class="fa fa-play" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="activeObject === 'Rect' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Rect')" title="Rettangolo"><i class="fa fa-stop" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-form-input type="range" v-model="opacity" min="0" max="1" step="0.1" class="p-0" :title="'Trasparenza triangolo / rettangolo: ' + opacity"></b-form-input>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="canvasObj.drawingMode ? 'primary' : 'outline-primary'" @click="toggleDrawingMode" :title="canvasObj.drawingMode ? 'Esci dalla modalità disegno' : 'Entra in modalità disegno'"><i class="fa fa-pencil" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2" v-show="canvasObj.drawingMode">
                  <b-form-input :title="'Spessore linea: ' + line_width" type="range" v-model="line_width" min="1" max="150" class="p-0"></b-form-input>
                </b-col>
                <b-col cols="3" class="mb-2">
                  <b-button style="width:100%" :variant="canvasObj.wavy ? 'primary' : 'outline-primary'" @click="toggleWavy" :title="canvasObj.wavy ? 'Esci dalla modalità disegno freccia ondulata' : 'Entra in modalità disegno freccia ondulata'"><i class="fa fa-line-chart" aria-hidden="true"></i></b-button>
                </b-col>
                <b-col cols="3" class="mb-2" v-show="canvasObj.wavy">
                  <b-form-checkbox v-model="canvasObj.dashed">
                    Tratteggiata
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

          </div>
        </div>

        <div :class="auth.isUserEnable('academy_ita_ese_mng') ? 'card col-xl-9' : 'card col-xl-12'">
          <div class="">
            <div id="field" class="card-body" ref="my_editor">
              <img v-if="this.$route.params.id && immagine != ''" :src="immagine" class="img-fluid mb-2" />
              <div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
              <canvas-obj v-if="exercise" ref="my_canvas"></canvas-obj>
              
            </div>
          </div>

        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-sm">
          <div class="form-group">
            <label>{{ $i18n.t('Misure campo') }}</label>
            <input
            name="misureCampo"
            type="text"
            v-model="soccer_field_size"
            class="form-control"
            :placeholder="$i18n.t('Seleziona una misura fra quelle disponibili e/o digitala manualmente')"
            ></input>
          </div>
          <b-btn v-b-toggle.collapse1 variant="primary" v-if="auth.isUserEnable('academy_ita_ese_mng')">
            {{ $i18n.t('Misure') }} 
            <i class="when-closed fa fa-arrow-down"></i>
            <i class="when-opened fa fa-arrow-up"></i>
          </b-btn>
          <b-collapse id="collapse1" :visible="show_fields_size" class="mt-2">
            <table style="padding: 10px;" id="mytable" width="100%">
              <thead>
                <tr class="top">
                  <th rowspan="2">{{ $i18n.t('Categoria') }}</th>
                  <th rowspan="2">{{ $i18n.t('Numero giocatori') }}</th>
                  <th rowspan="2">{{ $i18n.t('Modalità di gioco') }}</th>
                  <th colspan="3" class="text-center">{{ $i18n.t('Dimensioni') }} (m)</th>
                  <th rowspan="2">{{ $i18n.t('Durata') }} (min)</th>
                </tr>
                <tr class="top">
                  <th>Small &lt;20%</th>
                  <th>Standard</th>
                  <th>Large &gt;20%</th>
                </tr>
              </thead>
              <tbody style="font-weight: normal;">
                <tr @click="selectSoccerFieldSize(sfs.id)" class="over" align="center" v-for="(sfs, index) in soccer_field_sizes" :key="index">
                  <td class="option__category text-left">{{sfs.category}}</td>
                  <td class="option__num_player text-left">{{sfs.num_player}}</td>
                  <td class="option__modality text-left">{{sfs.modality}}</td>
                  <td class="text-left">{{sfs.small}}</td>
                  <td class="text-left">{{sfs.standard}}</td>
                  <td class="text-left">{{sfs.large}}</td>
                  <td class="text-left">{{sfs.time}}</td>
                </tr>
              </tbody>
            </table>
          </b-collapse>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-sm">

          <div class="form-group">
            <label>{{ $i18n.t('Descrizione') }}</label>
            <textarea
            name="description"
            rows="6"
            class="form-control"
            v-model="description"
            :placeholder="$i18n.t('Inserisci una descrizione per far capire lo svolgimento dell\'esercizio')"
            ></textarea>
          </div>
        </div>
        <div class="col-sm">

          <div class="form-group">
            <label>{{ $i18n.t('Materiale occorrente') }}</label>
            <textarea
            name="material_required"
            rows="6"
            class="form-control"
            v-model="material_required"
            :placeholder="$i18n.t('Inserisci il materiale che occorre per lo svolgimento dell\'esercizio')"
            ></textarea>
          </div>
        </div>
      </div>

      <hr />
      <div class="row" v-if="!portiere">
        <div class="col-sm">
          <label>{{ $i18n.t('Principi di gioco') }}</label>
          <b-form-select v-model="game_principles">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option :value="item.id" v-for="item in skills1" :key="item.id">{{item.name}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">

          <label>{{ $i18n.t('Componenti tecniche') }}</label>

          <b-form-select v-model="technical_component">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option :value="item.id" v-for="item in skills2" :key="item.id">{{item.name}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">

          <label>{{ $i18n.t('Capacità motorie') }}</label>
          <b-form-select v-model="motor_skill">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option :value="item.id" v-for="item in skills3" :key="item.id">{{item.name}}</option>
          </b-form-select>

        </div>
      </div>

      <div class="row" v-if="portiere">
        <div class="col-sm">
          <label>{{ $i18n.t('Tecnica podalica') }}</label>
          <b-form-select v-model="tecnica_podalica">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option :value="item.id" v-for="item in skills4" :key="item.id">{{item.name}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">

          <label>{{ $i18n.t('Tecnica di base') }}</label>

          <b-form-select v-model="tecnica_di_base">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option :value="item.id" v-for="item in skills5" :key="item.id">{{item.name}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">

          <label>{{ $i18n.t('Qualità tattiche') }}</label>

          <b-form-select v-model="qualita_tattiche">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option :value="item.id" v-for="item in skills6" :key="item.id">{{item.name}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">

          <label>{{ $i18n.t('Capacità motorie') }}</label>
          <b-form-select v-model="motor_skill">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option :value="item.id" v-for="item in skills7" :key="item.id">{{item.name}}</option>
          </b-form-select>

        </div>
      </div>
      <b-button @click="switchFilter" size="sm" class="mt-2" v-if="auth.isUserEnable('academy_ita_ese_mng')">{{ portiere ? $i18n.t('passa agli obiettivi giocatore') : $i18n.t('passa agli obiettivi portiere') }}</b-button>

      <hr />

      <div class="row">
        <div class="col-sm">
          <label>{{ $i18n.t('Numero giocatori') }}</label>
          <b-form-select v-model="num_player">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option v-for="(value, index) in 40" :value="value" :key="index">{{value}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">
          <label>{{ $i18n.t('Minuti esercitazione') }}</label>
          <b-form-select v-model="min_exs">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option v-for="(value, index) in 120" :value="value" :key="index">{{value}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">
          <label>{{ $i18n.t('Numero di serie') }}</label>
          <b-form-select v-model="num_serie">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option v-for="(value, index) in 20" :value="value" :key="index">{{value}}</option>
          </b-form-select>
        </div>
        <div class="col-sm">
          <label>{{ $i18n.t('Minuti di recupero') }}</label>
          <b-form-select v-model="min_rec">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option v-for="(value, index) in 120" :value="value" :key="index">{{value}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">
          <label>{{ $i18n.t('Secondi di recupero') }}</label>
          <b-form-select v-model="sec_rec">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option v-for="(value, index) in 11" :value="value * 5" :key="index">{{value * 5}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">

          <label>{{ $i18n.t('Numero di recuperi') }}</label>
          <b-form-select v-model="num_rec">
            <option :value="null">{{ $i18n.t('seleziona') }}</option>
            <option v-for="(value, index) in 20" :value="value" :key="index">{{value}}</option>
          </b-form-select>

        </div>
        <div class="col-sm">
          <label style="display:block;">{{ $i18n.t('Minuti totali') }} </label>
          <span>{{this.calculate_tot_min}}</span>
        </div>
      </div>
      <hr />
      <label style="display:block;">  Video </label>
      <b-row v-if="show_video">
        <b-col cols="12">
          <b-embed type="video" controls aspect="16by9" :poster="thumb_video" preload="none" controlsList="nodownload" oncontextmenu="return false;">
            <source :src="src_video"/>    
          </b-embed>
        </b-col>
      </b-row>
      <b-button class="mt-3" @click="showUploaded" v-if="auth.isUserEnable('academy_ita_ese_mng') && !auth.isUserEnable('academy_ita_bozze')">{{ $i18n.t('Seleziona video') }} <i class="fa fa-arrow-up" aria-hidden="true" v-if="show_uploaded"></i><i class="fa fa-arrow-down" aria-hidden="true" v-if="!show_uploaded"></i></b-button>
      <b-row class="mt-3" v-if="show_uploaded">
        <b-col cols="8">
          <b-form-group :label="$i18n.t('Mostra i video di:')">
            <b-form-radio-group v-model="filters_date">
              <b-form-radio value="day">{{ $i18n.t('Oggi') }}</b-form-radio>
              <b-form-radio value="week">{{ $i18n.t('Ultimi 7 giorni') }}</b-form-radio>
              <b-form-radio value="month">{{ $i18n.t('Ultimi 30 giorni') }}</b-form-radio>
              <b-form-radio value="all">{{ $i18n.t('Tutti') }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group :label="$i18n.t('Video caricati sul server ASRoma')" v-if="typeVideo == 'asroma'">
            <div v-if="!upload_loading" style="width:100%;" class="mt-1">
              <span class="font-bold">Loading...</span>
              <img src="/assets/varie/loading.gif" style="width: 30px;" />
            </div>
            <span class="font-bold" v-if="upload_loading && upload_video.length == 0">{{ $i18n.t('Nessun video trovato sul tuo archivio!') }}</span>
            <b-form-select v-model="fileNameVideo" v-if="upload_loading && upload_video.length > 0">
              <option :value="null">{{ $i18n.t('Seleziona video') }}</option>
              <optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
                <option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} {{ $i18n.t('caricato il') }} {{ video.data | formatDateHour }}</option>
              </optgroup>
            </b-form-select>
          </b-form-group>
          <b-row class="mt-3">
            <b-col cols="12">
              <b-button class="mt-3" @click="showUpload">{{ $i18n.t('Carica nuovo video') }}</b-button>
              <b-row v-if="show_upload" class="mt-3">
                <b-col>
                  <b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div class="action-actions" v-if="auth.isUserEnable('academy_ita_ese_mng')">
        <hr />
        <label style="display:block;">  {{ $i18n.t('Categorie esercizio') }}</label>
        <b-form-checkbox v-model="allSelected" @change="toggleAll" :indeterminate="indeterminate" style="float:left">
          {{ allSelected ? $i18n.t('Nessuna') : $i18n.t('Tutte') }}
        </b-form-checkbox>
        <b-form-checkbox-group v-model="categorie_ids">
          <b-form-checkbox v-for="cat in user.academyCats" :value="cat.id"> {{ cat.nome }} </b-form-checkbox>
        </b-form-checkbox-group>
        <hr />
        <div v-if="params && params.academy">
          <div class="action-actions" v-if="!auth.isUserEnable('academy_ita_ese_stato') && current_stato == 0">
            <label style="display:block;"> {{ $i18n.t('Metti in validazione') }}</label>
            <b-form-select v-model="stato">
              <option :value="null">{{ $i18n.t('seleziona') }}</option>
              <option v-for="(value, index) in checklist" :value="value.value" :key="index">{{ $i18n.t(value.name) }}</option>
            </b-form-select>
            <small id="passwordHelpBlock" class="form-text text-muted"> 
              {{ $i18n.t('flaggare nel caso in qui si desidera far validare l\'esercizio, l\'esercizio non potrà più essere modificato') }}
            </small>
            <hr />
          </div>

          <div class="action-actions" v-if="auth.isUserEnable('academy_ita_ese_stato') && current_stato == 3 && exe_id > 0">
            <label style="display:block;"> {{ $i18n.t('Valida esercizio') }} </label>
            <b-form-textarea id="textarea1"
            v-model="motivo"
            :placeholder="$i18n.t('Inserisci la motivazione del rifiuto')"
            :rows="2"
            :max-rows="4" class="mb-2">
          </b-form-textarea>
          <b-button variant="primary" class="mr-2" @click="accetta">{{ $i18n.t('Accetta') }}</b-button>
          <b-button @click="rifiuta">{{ $i18n.t('Rifiuta') }}</b-button>
          <small id="passwordHelpBlock" class="form-text text-muted">
            {{ $i18n.t('premi su accetta per certificare l\'esercizio ed essere utilizzato nelle fasi di allenamento, premi su rifiuta per rimandare l\'esercizio in bozza') }}
          </small>
          <hr />
        </div>
        <div v-if="auth.isUserEnable('academy_ita_ese_stato')">
          <label style="display:block;">{{ $i18n.t('Stato esercizio') }} </label>
          <b-form-radio-group v-model="stato">
            <b-form-radio value="0">{{ $i18n.t('Bozza') }}</b-form-radio>
            <b-form-radio value="1">{{ $i18n.t('Pubblicato') }}</b-form-radio>
            <b-form-radio value="2">{{ $i18n.t('Archiviato') }}</b-form-radio>
          </b-form-radio-group>
          <hr />
        </div>
      </div>
      <div v-else>
        <div v-if="auth.isUserEnable('academy_ita_ese_stato')">
          <label style="display:block;">{{ $i18n.t('Stato esercizio') }} </label>
          <b-form-radio-group v-model="stato">
            <b-form-radio value="0">{{ $i18n.t('Bozza') }}</b-form-radio>
            <b-form-radio value="1">{{ $i18n.t('Pubblicato') }}</b-form-radio>
            <b-form-radio value="2">{{ $i18n.t('Archiviato') }}</b-form-radio>
          </b-form-radio-group>
          <hr />
        </div>
      </div>
      <div v-if="!auth.isUserEnable('academy_ita_bozze')">
        <label style="display:block;">{{ $i18n.t('Visibilità esercizio') }}</label>
        <b-form-radio-group v-model="privato">
          <b-form-radio value="0">{{ $i18n.t('Pubblico') }}</b-form-radio>
          <b-form-radio value="1">{{ $i18n.t('Privato') }}</b-form-radio>
        </b-form-radio-group>
        <div class="mt-3" v-if="privato == 1">
          <label style="display:block;">{{ $i18n.t('Seleziona una o più academy') }}:</label>
          <b-form-checkbox-group v-model="academy_ids">
            <b-form-checkbox v-for="item in academy" :value="item.id"> <img :src="item.logo_url" style="width: 30px;" /> {{ item.nome }} </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
        <hr />
      </div>
    </div>
    <div class="row" v-if="auth.isUserEnable('academy_ita_ese_mng')">
      <div class="col text-right">
        <button class="btn btn-primary btn-lg text-uppercase" v-if="auth.isUserEnable('academy_ita_ese_stato') || current_stato == 0">{{ $i18n.t('Salva') }}</button>
        <b-button class="btn btn-success btn-lg text-uppercase ml-2" @click="clonaEsercizio" v-if="exe_id > 0">{{ $i18n.t('Clona') }}</b-button>
        <b-button class="btn btn-lg text-uppercase ml-2" @click="eliminaEsercizio" v-if="exe_id > 0 && auth.isUserEnable('academy_ita_ese_del')">{{ $i18n.t('Elimina') }}</b-button>
      </div>
    </div>

  </div>
</form>
<b-modal ref="infoModal" :title="$i18n.t('Guida esercizi')" size="lg" ok-only :ok-title="$i18n.t('Chiudi')">
  <div>
    <b-img :src="'/assets/varie/' + $i18n.t('guida_esercizi.jpg')" fluid/>
  </div>
</b-modal>
</div>
</template>


<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import { base64ToBlob } from '../../../lib/image';
import { mapState } from 'vuex';
import { jsPDF } from 'jspdf';
import moment from 'moment';

export default {
  components: {
    Multiselect,
    exerciseform:      require('./form.vue').default,
    canvasObj:         require('./canvas.vue').default,
  },

  props: {
    params: {
      type: Object,
      required: false
    }
  },

  data: function() {
    return {
      academy: [],
      resolution_w: null,
      resolution_h: null,
      show_font_size: 0,
      exe_id: this.$route.params.id,
      shirt: 'first',
      move: 'all',  
      checklist: [{value:3, name: 'si'}, {value:0, name:'no'}],
      bkg_default: '',
      canvas: null,
      sfondo: null,
      is_drawing_mode: false,
      line_color: '#000000',
      testo: '',
      editorActive:           true,
      exercise:               false,
      title: 					'',
      difficulty: 			null,
      description: 			this.$i18n.t('Obiettivi secondari') + ': \n\n' + this.$i18n.t('Dislocazione giocatori') + ': \n\n' + this.$i18n.t('Descrizione') + ': \n\n' + this.$i18n.t('Varianti') + ': ',
      material_required: 		'',
      game_principles:     	null,
      technical_component: 	null,
      motor_skill:        	null,
      tecnica_podalica:     null,
      tecnica_di_base:      null,
      qualita_tattiche:     null,

      num_player:          	  null,
      min_exs:              	null,
      num_serie:              null,
      min_rec:                null,
      sec_rec:                null,
      num_rec:                null,
      current_stato:          0,
      stato:                  0,
      privato:                0,
      min_tot_ex:             null,
      soccer_field_size:      null,

      difficulties: 			[{value: 0, text: "Facile"}, {value: 1, text: "Medio"}, {value: 2, text: "Difficile"}],
      skills: 	              [],
      skills_portiere:        [],
      skills1: 	              [],
      skills2: 	              [],
      skills3: 	              [],

      skills4:                [],
      skills5:                [],
      skills6:                [],
      skills7:                [],
      soccer_field_sizes:     [],

      canvas_objs:            null,
      canvas_players_objs:    null,
      canvasImage:            '',
      immagine:               '',
      loading: false,
      user_id: 0,
      show_fields_size: false,
      portiere: 0,
      header_pdf: null,
      autore: '',
      academy_ids: [],
      categorie_ids: [],

      thumb_video: '',
      src_video: '',
      show_video: false,
      upload_loading: false,
      upload_video: [],
      show_upload: false,
      show_uploaded: false,
      filters_date: 'day',
      upload_iframe: '',
      typeVideo: 'asroma',
      fileNameVideo: null,
      allSelected: false,
      indeterminate: false,

      motivo: null,
      rifiutato: 0,
    }
  },

  methods: {

    clearAndSetActiveObject(obj) {
      this.$root.$emit('canvas::clearSelection');
      this.$store.commit('exercises/setActiveObject', obj);
    },

    toggleDrawingMode() {
      this.$store.commit('exercises/toggleDrawingMode');
    },

    toggleWavy() {
      this.$store.commit('exercises/toggleWavy');
    },

    cloneCanvasObject() {
      this.$root.$emit('canvas::cloneObjects');
    },

    deleteCanvasObject() {
      this.$root.$emit('canvas::deleteObjects');
    },

    submit: function(){

      if(this.categorie_ids.length == 0) {
        alert("Devi selezionare almeno una categoria per l'esercizio!");
        return;
      }

      this.resolution_w  = document.getElementsByClassName('canvas-container')[0].offsetWidth;
      this.resolution_h  = document.getElementsByClassName('canvas-container')[0].offsetHeight;

      const formData = new FormData();
      if(this.exe_id > 0) formData.set('id', this.exe_id);

      if(this.params && this.params.academy) {
        formData.set('academy', this.params.academy);
      }

      formData.set('resolution_w',          this.resolution_w);
      formData.set('resolution_h',          this.resolution_h);
      formData.set('name',                this.title);
      formData.set('difficulty',          this.difficulty);
      formData.set('description',         this.description);
      formData.set('materials',           this.material_required);
      formData.set('principiDiGioco',     this.game_principles);
      formData.set('componentiTecniche',  this.technical_component);
      formData.set('capacitaMotorie',     this.motor_skill);
      formData.set('tecnicaPodalica',     this.tecnica_podalica);
      formData.set('tecnicaDiBase',       this.tecnica_di_base);
      formData.set('qualitaTattiche',     this.qualita_tattiche);
      formData.set('portiere',            this.portiere);
      formData.set('sizeCampo',           this.soccer_field_size);
      formData.set('numeroGiocatori',     this.num_player);
      formData.set('minutiEsercizio',     this.min_exs);
      formData.set('numeroSerie',         this.num_serie);
      formData.set('recupero',            this.min_rec);
      formData.set('secondiRecupero',     this.sec_rec);
      formData.set('numeroRecuperi',      this.num_rec);
      formData.set('stato',               this.stato);
      formData.set('privato',             this.privato);
      formData.set('categorie_ids',       this.categorie_ids);
      formData.set('academy_ids',         this.academy_ids);
      if(this.fileNameVideo) {
        formData.set('typeVideo',           this.typeVideo);
        formData.set('fileNameVideo',       this.fileNameVideo);
      }
      formData.set('_method', 'POST');

      if (this.editorActive) {
        this.$root.$emit('canvas::export');
        const png = this.canvasObj.image.split(',')[1];
        const file = base64ToBlob(png, 'image/png');

        formData.set('canvasImage', file);
        formData.set('canvas', this.canvasObj.export);
      }

                /**
                 *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
                 **/

                 const opts = {
                  url: `/academyita/exercises${(this.exe_id > 0) ? `/edit` : ''}`,
                  method: 'POST',
                  data: formData,
                };

                axios(opts)
                .then((res) => {
                  if(this.params && this.params.academy) {
                    this.$router.push({ name: 'academy_int_esercizi'});
                  }
                  else {
                    this.$router.push({ name: 'academy_ita_esercizi'});
                  }
                }).catch((err) => {
                  console.log(err);
                });
              },

    /*    processVideo(event) {
            this.video = event.target.files[0]
          },  */

            /**
             * @canvas
             * Begin
             */

             changeCanvasBkg(obj, category) {               
              if( category == 'backgrounds' ){
                this.$store.commit('exercises/setBackground', obj.image_url);
              } else {
                this.clearAndSetActiveObject(obj);
              }
            },

            getRandomCoords() {
              var max = 50;
              var min = - 50;
              var coords = {
                x: 350 + Math.floor(Math.random() * (max - min + 1) ) + min,
                y: 150 + Math.floor(Math.random() * (max - min + 1) ) + min,
              };
              return coords;
            },

            setCanvasBkg(path){
              this.$store.commit('exercises/setBackground', path);
            },

            onDragStart(object, evt) {
              evt.dataTransfer.setData('text/plain', JSON.stringify(object));
            },

            /**
             * @canvas
             * End
             */

             selectSoccerFieldSize(ID){
              for(var i in this.soccer_field_sizes) {
                var field_size = this.soccer_field_sizes[i];
                if(field_size.id == ID) {
                  var size = field_size.modality + " - Small: " + field_size.small  + " m - Standard: " + field_size.standard  + " m - Large: " + field_size.large + " m";
                  this.soccer_field_size = size;
                }
              }
              this.show_fields_size = false;
            },

            downloadPdf(){
              var canvas = document.getElementById('canvas');
              var imgData = canvas.toDataURL("png");
              var doc = new jsPDF("p", "mm", "a4");
              var width = doc.internal.pageSize.getWidth();
              var height = doc.internal.pageSize.getHeight(); 
              var fontSize = 12;
              var ptsPerInch = 72;
              doc.addImage(this.header_pdf, 'PNG', 0, 0, width, 33);
              doc.addImage(imgData, 'PNG', 12, 35, 190, 95);
              doc.setFontSize(fontSize);
              doc.setFont(undefined, "bold");
              doc.text(5, 137, this.$i18n.t('Nome') + ':');
              doc.text(5, 142, this.$i18n.t('Autore') + ':');
              doc.text(5, 147, this.$i18n.t('Difficoltà') + ':');
              doc.setFont(undefined, "normal");
              doc.text(20, 137, this.title);
              doc.text(22, 142, this.autore);
              doc.text(27, 147, this.getDifficoltaLabel(this.difficulty));

              var y = 0;

              if(this.portiere) {
                doc.setFont(undefined, "bold");
                doc.text(5, 157, this.$i18n.t('Tecnica podalica') + ':');
                doc.text(5, 162, this.$i18n.t('Tecnica di base') + ':');
                doc.text(5, 167, this.$i18n.t('Qualità tattiche') + ':');
                doc.text(5, 172, this.$i18n.t('Capacità motorie') + ':');
                doc.setFont(undefined, "normal");
                doc.text(42, 157, this.getLabelSkills(this.tecnica_podalica, this.skills_portiere[0]));
                doc.text(39, 162, this.getLabelSkills(this.tecnica_di_base, this.skills_portiere[1]));
                doc.text(38, 167, this.getLabelSkills(this.qualita_tattiche, this.skills_portiere[2]));
                doc.text(42, 172, this.getLabelSkills(this.motor_skill, this.skills_portiere[3]));
                y = 182;

              } else {
                doc.setFont(undefined, "bold");
                doc.text(5, 157, this.$i18n.t('Principi di gioco') + ':');
                doc.text(5, 162, this.$i18n.t('Componenti tecniche') + ':');
                doc.text(5, 167, this.$i18n.t('Capacità motorie') + ':');
                doc.setFont(undefined, "normal");
                doc.text(41, 157, this.getLabelSkills( this.game_principles, this.skills[0]));
                doc.text(52, 162, this.getLabelSkills( this.technical_component, this.skills[1]));
                doc.text(42, 167, this.getLabelSkills( this.motor_skill, this.skills[2]));
                y = 177;
              }

              doc.setFont(undefined, "bold");
              doc.text(152, 137, this.$i18n.t('Numero giocatori') + ':');
              doc.text(152, 142, this.$i18n.t('Minuti esercitazione') + ':');
              doc.text(152, 147, this.$i18n.t('Numero di serie') + ':');
              doc.text(152, 152, this.$i18n.t('Minuti di recupero') + ':');
              doc.text(152, 157, this.$i18n.t('Secondi di recupero') + ':');
              doc.text(152, 162, this.$i18n.t('Numero di recuperi') + ':');
              doc.text(152, 167, this.$i18n.t('Tempo totale') + ':');
              doc.setFont(undefined, "normal");
              doc.text(189, 137, '' + this.num_player);
              doc.text(195, 142, '' + this.min_exs);
              doc.text(187, 147, '' + this.num_serie);
              doc.text(191, 152, '' + this.min_rec);
              doc.text(195, 157, '' + this.sec_rec);
              doc.text(193, 162, '' + this.num_rec);
              doc.text(181, 167, '' + this.calculate_tot_min + ' ' + this.$i18n.t('minuti'));

              doc.setFont(undefined, "bold");
              doc.text(5, y, this.$i18n.t('Misure del campo') + ':');
              doc.setFont(undefined, "normal");
              doc.text(45, y, this.soccer_field_size);
              y += 10;

              doc.setFont(undefined, "bold");
              doc.text(5, y, this.$i18n.t('Materiale') + ':');
              doc.setFont(undefined, "normal");

              if(this.material_required != '') {
                y += 5;
                var textMater = doc.splitTextToSize(this.material_required, width - 10);
                doc.text(5, y, textMater);
                var heightMaterial = textMater.length * fontSize * doc.internal.getLineHeight() / ptsPerInch;
                y += heightMaterial;
              }
              y += 10;

              doc.setFont(undefined, "bold");
              doc.text(5, y, this.$i18n.t('Descrizione') + ':');
              doc.setFont(undefined, "normal");
              y += 5;
              var textDescr = doc.splitTextToSize(this.description, width - 10);
              doc.text(5, y, textDescr);

              doc.save('esercizio_' + this.exe_id + '.pdf');

            },

            getDifficoltaLabel(difficolta) {
              for(var i in this.difficulties) {
                var item = this.difficulties[i];
                if(item.value == difficolta) {
                  return this.$i18n.t(item.text);
                }
              }
              return '';
            },

            getLabelSkills(id, skills) {
              var label = "N/D";
              var skill_items = skills.fields;
              for(var key in skill_items) {
                var skill = skill_items[key];
                if(skill.id == id) {
                  label = skill[this.$i18n.t('lang')];
                  return label;
                }
                if(skill.fields) {
                  var items = skill.fields;
                  for(var key2 in items) {
                    var subskill = items[key2];
                    if(subskill.fields) {
                      var items2 = subskill.fields;
                      for(var key3 in items2) {
                        var subskill2 = items2[key3];
                        if(subskill2.id == id) {
                          label = skill[this.$i18n.t('lang')] + " -> " + subskill[this.$i18n.t('lang')] + " -> " + subskill2[this.$i18n.t('lang')];
                          return label;
                        }  
                      }
                    } else {
                      if(subskill.id == id) {
                        label = skill[this.$i18n.t('lang')] + " -> " + subskill[this.$i18n.t('lang')];
                        return label;
                      }       
                    }
                  }
                } else {
                  if(skill.id == id) {
                    label = skill[this.$i18n.t('lang')];
                    return label;
                  }
                }
              }
              return label;
            },

            getOptionsSkills(skills) {
              var options = [];
              var skill_items = skills.fields;
              for(var key in skill_items) {
                var skill = skill_items[key];
                var option = {
                  id: skill.id,
                  name: skill[this.$i18n.t('lang')],
                }
                options.push(option);

              }
              return options;
            },

            filtraPlayersCanvasEditor() {
              this.canvas_objs.players = [];
              if(this.move == 'all' && this.shirt == 'all') {
                this.canvas_objs.players = this.canvas_players_objs;
              } else {
                for(var i in this.canvas_players_objs) {
                  var player = this.canvas_players_objs[i];
                  if(this.move != 'all' && this.shirt != 'all') {
                    if(player.move == this.move && player.shirt == this.shirt) {
                      this.canvas_objs.players.push(player);
                    }
                  } else if(this.move != 'all' && this.shirt == 'all') {
                    if(player.move == this.move) {
                      this.canvas_objs.players.push(player);
                    }
                  } else if(this.move == 'all' && this.shirt != 'all') {
                    if(player.shirt == this.shirt) {
                      this.canvas_objs.players.push(player);
                    }         
                  }
                }
              }
            },

            eliminaEsercizio() {
              if(confirm("Confermi l'eliminazione dell'esercizio?")) {
                this.$http.get(this.$store.state.apiEndPoint + '/academyita/exercise/delete/' + this.$route.params.id).then((response) => {

                  if (response.ok)
                  {
                    this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
                    this.$store.commit('exercises/resetCanvasEditor');
                    if(this.params && this.params.academy) {
                      this.$router.push({ name: 'academy_int_esercizi'});
                    } else {
                      this.$router.push({ name: "academy_ita_esercizi" });
                    }
                  }
                }, (response) => {
                });
              }
            },

            clonaEsercizio() {
              if(confirm("Confermi la clonazione dell'esercizio?")) {
                this.exe_id = 0;
                this.stato = 0;
                this.submit();    
              }
            },

            switchFilter() {
              if(this.portiere == 0) {
                this.portiere = 1;
              } else {
                this.portiere = 0;
              }
              this.game_principles = null;
              this.technical_component = null;
              this.motor_skill = null;
              this.tecnica_podalica = null;
              this.tecnica_di_base = null;
              this.qualita_tattiche = null;
            },

            changeFontSize(fsize){
              this.$refs.my_canvas.insertText(fsize);
              this.show_font_size = 0;
            },

            showGuida() {
              this.$refs.infoModal.show();
            },
            getUploadVideo() {
              this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', {params: { filters_date: this.filters_date }}).then((response) => {
                if (response.ok)
                {
                  this.upload_video = response.data;
                  this.upload_loading = true;
                }
              }, (response) => {

              });
            },
            showUpload() {
              this.show_upload = !this.show_upload;   
            },
            showUploaded() {
              this.show_uploaded = !this.show_uploaded;  
              if(this.upload_iframe == '') {
                this.getUploadVideo();
                this.getUploadIFrame();
              } 
            },
            getUploadIFrame() {
              this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
                if (response.ok) {
                  this.upload_iframe = response.data;
                }
              }, (response) => {
              });
            },
            toggleAll(checked) {
              if(checked) {
                this.categorie_ids = []; 
                for(var i in Auth.user.academyCats) {
                  this.categorie_ids.push(Auth.user.academyCats[i].id);
                }
              } else {
                this.categorie_ids = [];
              }
            },

            accetta() {
              if(confirm(this.$i18n.t('Sei sicuro di voler accettare l\'esercizio?'))) {
                this.$http.get(this.$store.state.apiEndPoint + '/academyita/exercise/accetta/' + this.$route.params.id).then((response) => {

                  if (response.ok)
                  {
                    this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
                    if(this.params && this.params.academy) {
                      this.$router.push({ name: 'academy_int_esercizi'});
                    } else {
                      this.$router.push({ name: "academy_ita_esercizi" });
                    }
                  }
                }, (response) => {
                });
              }
            },

            rifiuta() {
              if(confirm(this.$i18n.t('Sei sicuro di voler rifiutare l\'esercizio?'))) {
                const formData = new FormData();
                formData.append("motivo",  this.motivo);
                this.$http.post(this.$store.state.apiEndPoint + '/academyita/exercise/rifiuta/' + this.$route.params.id, formData).then((response) => {

                  if (response.ok)
                  {
                    this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
                    if(this.params && this.params.academy) {
                      this.$router.push({ name: 'academy_int_esercizi'});
                    } else {
                      this.$router.push({ name: "academy_ita_esercizi" });
                    }
                  }
                }, (response) => {
                });
              }
            },
          },

          created: function () {
            axios.get(`/skills`)
            .then((res) => {
              this.skills = JSON.parse(res.data.standard);
              this.skills_portiere = JSON.parse(res.data.special);

              this.skills1 = this.getOptionsSkills(this.skills[0]);
              this.skills2 = this.getOptionsSkills(this.skills[1]);
              this.skills3 = this.getOptionsSkills(this.skills[2]);

              this.skills4 = this.getOptionsSkills(this.skills_portiere[0]);
              this.skills5 = this.getOptionsSkills(this.skills_portiere[1]);
              this.skills6 = this.getOptionsSkills(this.skills_portiere[2]);
              this.skills7 = this.getOptionsSkills(this.skills_portiere[3]);
            }).catch((err) => {
            });
            this.$http.get(this.$store.state.apiEndPoint + '/headerpdf/academy').then((response) => {
              if (response.ok)
              {
                this.header_pdf = response.data;    
              }
            }, (response) => {
            });
            this.$http.get(this.$store.state.apiEndPoint + '/academyita/get/list/societa/' + (this.params && this.params.academy ? '1' : '0')).then((response) => {
              if (response.ok)
              {
                this.academy = response.data;    
              }
            }, (response) => {
            });
          },

          computed: {
            auth: function() {
              return Auth;
            }, 

            user: function() {
              return Auth.user;
            },   

            ...mapState('exercises', {
              canvasObj: 'canvas',
              activeObject: 'activeObject',
            }),

            calculate_tot_min: function () {
              var tot = ( this.min_exs * this.num_serie ) + ( this.min_rec * this.num_rec );
              if(this.sec_rec > 0) {
                tot += this.sec_rec/60 * this.num_rec;
              }
              tot = Math.round(tot * 100) / 100;
              return tot;
            },
            text_color: {
              get() {
                return this.canvasObj.color.hex;
              },

              set(value) {
                this.$store.commit('exercises/setCanvasColor', value);
              },
            },
            opacity: {
              get() {
                return this.canvasObj.opacity;
              },

              set(value) {
                this.$store.commit('exercises/setOpacity', value);
              },
            },
            line_width: {
              get() {
                return this.canvasObj.lineWidth;
              },

              set(value) {
                this.$store.commit('exercises/setLineWidth', value);
              },
            }
          },

          watch: { 
            move(){   
              this.filtraPlayersCanvasEditor();       
            },

            shirt(){
              this.filtraPlayersCanvasEditor();
            },
            filters_date: function () {
              this.fileNameVideo = null;
              this.upload_loading = false;
              this.getUploadVideo();
            },

            categorie_ids(newVal, oldVal) {

              if (newVal.length === 0) {
                this.indeterminate = false
                this.allSelected = false
              } else if (newVal.length === Auth.user.academyCats.length) {
                this.indeterminate = false
                this.allSelected = true
              } else {
                this.indeterminate = true
                this.allSelected = false
              }
            }

          },

          mounted()
          { 
            /**
             *  RETRY SIZES SOCCER FIELD
             **/

             axios.get(`/exercise/soccer_field_sizes`)
             .then((res) => {

              this.soccer_field_sizes     = res.data.map(item => ({
                id:         item.id,
                category:   item.category,
                modality:   item.modality,
                num_player: item.num_player,
                small:      item.small ,
                standard:   item.standard ,
                large:      item.large ,
                time:       item.time,
                value:      item.id,
              }));
            }).catch((err) => {
            });


            /**
            *   Retry canvas Objs
            */
            this.$store.commit('exercises/resetCanvasEditor');

            axios.get('/exercises/canvas-objects')
            .then((res) => {
              this.canvas_objs = res.data;
              this.canvas_players_objs = res.data.players;
              this.filtraPlayersCanvasEditor();

              this.bkg_default = res.data['backgrounds'][0].image_url;

              if(!this.$route.params.id){
                this.$store.commit('exercises/setBackground', res.data['backgrounds'][0].image_url);
              }

              /**
             *  RETRY EXCERCISE IF EDIT
             **/

             if(this.$route.params.id){
              this.loading = true;
              axios.get(`/academyita/exercise/get/`+this.$route.params.id)
              .then((res) => {
                this.title                  = res.data.name;
                this.difficulty             = res.data.difficulty;
                this.description            = res.data.description;
                this.material_required      = res.data.materials;
                this.game_principles        = res.data.principiDiGioco;
                this.technical_component    = res.data.componentiTecniche;
                this.motor_skill            = res.data.capacitaMotorie;
                this.tecnica_podalica       = res.data.tecnicaPodalica;  
                this.tecnica_di_base        = res.data.tecnicaDiBase;                           
                this.qualita_tattiche       = res.data.qualitaTattiche;
                this.portiere               = res.data.portiere;
                this.soccer_field_size      = res.data.sizeCampo;
                this.num_player             = res.data.numeroGiocatori;
                this.min_exs                = res.data.minutiEsercizio;
                this.num_serie              = res.data.numeroSerie;
                this.min_rec                = res.data.recupero;
                this.sec_rec                = res.data.secondiRecupero;
                this.num_rec                = res.data.numeroRecuperi;
                this.min_tot_ex             = res.data.min_tot_ex;
                this.immagine               = res.data.immagine;
                this.stato                  = res.data.stato;
                this.current_stato          = res.data.stato;
                this.canvasImage            = res.data.canvasImage;
                this.id_utente              = res.data.id_utente;
                this.exe_id                 = res.data.id;
                this.resolution_w           = res.data.resolution_w;
                this.resolution_h           = res.data.resolution_h;
                this.autore                 = res.data.utente.nomeCompleto;
                this.privato                = res.data.privato;
                this.motivo                 = res.data.motivo;
                this.rifiutato              = res.data.rifiutato;
                this.academy_ids = [];
                this.categorie_ids = [];
                for(var i in res.data.academies) {
                  var acad = res.data.academies[i];
                  this.academy_ids.push(acad.id);
                }
                for(var i in res.data.categorie) {
                  var cat = res.data.categorie[i];
                  this.categorie_ids.push(cat.id);
                }
                if(this.soccer_field_size && this.soccer_field_size != "") {
                  this.show_fields_size = false;
                }

                this.src_video = res.data.video;
                this.thumb_video = res.data.thumb;
                if(this.src_video != '') {
                  this.show_video = true;
                }

           //   this.categories             = res.data.categories;

           this.$store.commit('exercises/export', res.data.canvas);

           if( res.data.old_id ){
            this.$store.commit('exercises/setBackground', this.bkg_default);
          }
          this.loading    = false;
          this.exercise   = true;
        }).catch((err) => {
          this.loading = false;
        });
      }else{
        this.exercise   = true;
      }

    }).catch((error) => {
      console.log(error);
    });
  },

  filters: {
    formatDateHour: function (date) {
      var m = moment(date);
      if(m.isValid()) {
        return m.format('DD/MM/YYYY HH:mm'); 
      }
      return "";
    },
  },
}
</script>
