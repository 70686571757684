<template>
	<div>

		<b-button v-if="auth.isAdmin()" :to="{ name: 'CompetitionNewStage' }">Nuovo Stage</b-button>

		<ul class="mt-3">
			<li v-for="stage in competition.stages">
				<router-link :to="{ name: 'CompetitionStage', params: { stage_id: stage.id } }">{{ stage.name }}</router-link>
			</li>
		</ul>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: [ 'competition' ],
	computed: {
		auth: function() {
			return Auth;
		}
	},
	created: function() {
		if(this.$route.query.first) {
			this.$router.push({ name: 'CompetitionStage', params: { stage_id: this.competition.stages[0].id } })
		}
	}
}

</script>