import { render, staticRenderFns } from "./rosa_elenco.vue?vue&type=template&id=640fcc26&"
import script from "./rosa_elenco.vue?vue&type=script&lang=js&"
export * from "./rosa_elenco.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports