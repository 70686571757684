<template id="esercizi">
	<section class="esercizi">

		<headapp></headapp>

		<div>

			<div class="row no-gutters">
				<div class="d-none d-md-flex col-md-3 border-right">
					<div style="height:100vh;overflow:auto;">
						<card title="Filtri" class="w-100 border-0 rounded-0" comp="acadesercizifiltri"></card>
					</div>
				</div>
				<div class="col-md-9">
					<b-card no-body class="rounded-0 border-0">
						<b-tabs card class="p-0" v-model="exercises_tab">
							<b-tab title="Esercizi" class="p-0">
								<card :title="'Esercizi (' + (exercises.total ? exercises.total : 0) + ')'" class="border-0 rounded-0" comp="acadesercizi"></card>
							</b-tab>
							<b-tab title="Archivio" class="p-0">
								<card :title="'Archivio (' + (exercises_archivio.total ? exercises_archivio.total : 0) + ')'" class="border-0 rounded-0" comp="acadeserciziarchivio"></card>
							</b-tab>
							<b-tab title="Bozze" class="p-0" v-if="auth.isUserEnable('academy_ita_ese_mng')">
								<card :title="'Bozze (' + (exercises_bozze.total ? exercises_bozze.total : 0) + ')'" class="border-0 rounded-0" comp="acadesercizibozze"></card>
							</b-tab>
						</b-tabs>
					</b-card>

				</div>
			</div>

			<b-modal id="exModal" ref="exModal" :title="academy == -1 ? $i18n.t('Cerca esercizio football') : $i18n.t('Cerca esercizio Academy Italia')" size="lg" centered ok-only ok-title="Chiudi">
				<b-container fluid>
					<b-overlay :show="loadingEsercizi" rounded="sm">
						<b-row>
							<b-col>
								<b-input-group class="mb-2">
									<b-form-input type="text" v-model="title" :placeholder="$i18n.t('Cerca per titolo/autore...')"></b-form-input>
									<b-input-group-append>
										<b-button @click="reset"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
										<b-button variant="primary" @click="searchExe"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-col>
							<b-col>
								<div class="category-select">
									<select v-if="academy == -1" id="team_football" name="team_football" class="form-control" v-model="footballTeamId">
										<option :value="null"> -- {{ $i18n.t('Categoria') }} -- </option>
										<option v-for="team in user.rosaTeams" :value="team.id" :key="team.id"> 
											{{ team.categoria.name }} 
										</option>
									</select>
									<select v-else id="cat_academy" name="cat_academy" class="form-control" v-model="academyCategoryId">
										<option :value="null"> -- {{ $i18n.t('Categoria') }} -- </option>
										<option v-for="cat in user.academyCats" :value="cat.id" :key="cat.id"> 
											{{ cat.nome }} 
										</option>
									</select>
								</div>
							</b-col>
						</b-row>
						<p>{{ $i18n.t('Esercizi totali') }}: {{ esercizi.total }}</p>
						<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
							<b-media class="py-3 border-bottom" v-for="ex in esercizi.data" :key="ex.id" :style="exercizi_ids.indexOf(ex.id) > -1 ? 'background:yellow' : ''">
								<b-img slot="aside" :src="ex.canvas_url || ex.immagine" @click="selectEsercizio(ex.id)" height="150" />
								<div class="d-flex flex-column" style="height: 150px;">
									<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
									<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>

									<div v-if="academy == -1">
										<div v-if="!ex.portiere">
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="getLabelObject(getSkillId(ex, 1), skills_object.find(item => item.id == 1))">{{ getLabelObject(getSkillId(ex, 1), skills_object.find(item => item.id == 1)) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="getLabelObject(getSkillId(ex, 30), skills_object.find(item => item.id == 30))">{{ getLabelObject(getSkillId(ex, 30), skills_object.find(item => item.id == 30)) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="getLabelObject(getSkillId(ex, 61), skills_object.find(item => item.id == 61))">{{ getLabelObject(getSkillId(ex, 61), skills_object.find(item => item.id == 61)) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
										</div>
										<div v-if="ex.portiere">
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="getLabelObject(getSkillId(ex, 86), skills_object.find(item => item.id == 86))">{{ getLabelObject(getSkillId(ex, 86), skills_object.find(item => item.id == 86)) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="getLabelObject(getSkillId(ex, 99), skills_object.find(item => item.id == 99))">{{ getLabelObject(getSkillId(ex, 99), skills_object.find(item => item.id == 99)) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="getLabelObject(getSkillId(ex, 124), skills_object.find(item => item.id == 124))">{{ getLabelObject(getSkillId(ex, 124), skills_object.find(item => item.id == 124)) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="getLabelObject(getSkillId(ex, 61), skills_object.find(item => item.id == 61))">{{ getLabelObject(getSkillId(ex, 61), skills_object.find(item => item.id == 61)) }}</span></p>
										</div>
									</div>
									<div v-else>
										<div v-if="!ex.portiere">
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="getLabel(ex.principiDiGioco, skills[0])">{{ getLabel(ex.principiDiGioco, skills[0]) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="getLabel(ex.componentiTecniche, skills[1])">{{ getLabel(ex.componentiTecniche, skills[1]) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills[2])">{{ getLabel(ex.capacitaMotorie, skills[2]) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
										</div>
										<div v-if="ex.portiere">
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaPodalica, skills_portiere[0])">{{ getLabel(ex.tecnicaPodalica, skills_portiere[0]) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaDiBase, skills_portiere[1])">{{ getLabel(ex.tecnicaDiBase, skills_portiere[1]) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="getLabel(ex.qualitaTattiche, skills_portiere[2])">{{ getLabel(ex.qualitaTattiche, skills_portiere[2]) }}</span></p>
											<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills_portiere[3])">{{ getLabel(ex.capacitaMotorie, skills_portiere[3]) }}</span></p>
										</div>
									</div>

									<div class="flex-grow-1" style="overflow-y: auto;">
										<p class="text-muted"><small>{{ex.description}}</small></p>
									</div>
								</div>
							</b-media>
						</v-infinite-scroll>
					</b-overlay>
					<div class="my-2">Esercizi selezionati: {{ exercizi_ids.length }}</div>
					<b-button size="sm" :disabled="exercizi_ids.length == 0" @click="importExercises" variant="outline-primary">Importa</b-button>
				</b-container>
			</b-modal>

		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import { mapState } from 'vuex';
import InfiniteScroll from 'v-infinite-scroll';
export default {
	data: function(){
		return{				
			skills: [],
			skills_portiere: [],
			skills_object: [],
			exercizi_ids: []
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},

	computed:{
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['academyCatId']),
		...mapState('acad_esercizi', ['exercises', 'exercises_bozze', 'exercises_archivio']),
		...mapState('search_esercizi', ['esercizi', 'page', 'academy', 'loadingEsercizi']),
		exercises_tab: {
			get() {
				return this.$store.state.acad_esercizi.exercises_tab;
			},

			set(value) {
				this.$store.commit('acad_esercizi/setExcerciseTab', value);
			},
		},
		title: {
			get() {
				return this.$store.state.search_esercizi.title;
			},

			set(value) {
				this.$store.commit('search_esercizi/setTitle', value);
			},
		},
		footballTeamId: {
			get () {
				return this.$store.state.search_esercizi.footballTeamId;
			},
			set (value) {
				this.$store.commit('search_esercizi/setFootballTeamId', value)
			}
		},
		academyCategoryId: {
			get () {
				return this.$store.state.search_esercizi.academyCategoryId;
			},
			set (value) {
				this.$store.commit('search_esercizi/setAcademyCategoryId', value)
			}
		},
	},

	created() {
		this.getSkills();
		this.getSkillsObject();
		this.$store.commit('acad_esercizi/setAcademy', 0);
		this.$store.commit('acad_esercizi/setTitle', '');
		this.$store.commit('acad_esercizi/setObjIds', []);
	},

	watch: {
		academyCatId() {
			this.$store.commit('acad_esercizi/setPage', 1);
			if(this.exercises_tab == 0) {
				this.$store.dispatch('acad_esercizi/filter');
			} else if(this.exercises_tab == 1) {
				this.$store.dispatch('acad_esercizi/filter_archivio');
			} else if(this.exercises_tab == 2) {
				this.$store.dispatch('acad_esercizi/filter_bozze');
			}
		},  
		exercises_tab() {
			this.$store.commit('acad_esercizi/setPage', 1);
			if(this.exercises_tab == 0) {
				this.$store.dispatch('acad_esercizi/filter');
			} else if(this.exercises_tab == 1) {
				this.$store.dispatch('acad_esercizi/filter_archivio');
			} else if(this.exercises_tab == 2) {
				this.$store.dispatch('acad_esercizi/filter_bozze');
			}
		},
		footballTeamId() {
			this.searchExe();
		},
		academyCategoryId() {
			this.searchExe();
		},
	}, 

	methods: {
		nextPage() {
			if(this.page < this.esercizi.last_page) {
				this.$store.commit('search_esercizi/setPage', this.page + 1);
				this.$store.dispatch('search_esercizi/filter', this.page + 1);
			}
		},

		openSearchExe(academy) {
			this.exercizi_ids = [];
			this.$store.commit('search_esercizi/setAcademy', academy);
			this.$store.commit('search_esercizi/setObjIds', []);
			this.$store.commit('search_esercizi/setTitle', '');
			this.$store.commit('search_esercizi/setDrillId', null);
			this.$store.commit('search_esercizi/setPage', 1);
			this.$store.commit('search_esercizi/setEsercizi', []);
			this.$store.dispatch('search_esercizi/filter');
			this.$refs.exModal.show();
		},

		searchExe() {
			this.$store.commit('search_esercizi/setPage', 1);
			this.$store.dispatch('search_esercizi/filter');
		},

		reset() {
			this.title = '';
			this.searchExe();
		},

		getSkillId(ex, root) {
			var skill_rel = ex.skills_rel.find(item => item.skill_glo_root_id == root);
			if(skill_rel) {
				return skill_rel.skill_glo_id;
			}
			return null;
		},

		getLabelObject(id_skill, skills) {
			var label = "N/D";
			if(id_skill && skills) {
				var skill_items = skills.campi;
				for(var key in skill_items) {
					var skill = skill_items[key];
					if(skill.id == id_skill) {
						label = skill.nome;
						return label;
					}
					if(skill.campi) {
						var items = skill.campi;
						for(var key2 in items) {
							var subskill = items[key2];
							if(subskill.campi) {
								var items2 = subskill.campi;
								for(var key3 in items2) {
									var subskill2 = items2[key3];
									if(subskill2.id == id_skill) {
										label = skill.nome + " -> " + subskill.nome + " -> " + subskill2.nome;
										return label;
									}  
								}
							} else {
								if(subskill.id == id_skill) {
									label = skill.nome + " -> " + subskill.nome;
									return label;
								} 		
							}
						}
					} else {
						if(skill.id == id_skill) {
							label = skill.nome;
							return label;
						}
					}
				}
			}
			return label;
		},

		getLabel(id_skill, skills) {
			var label = "N/D";
			if(id_skill && skills) {
				var skill_items = skills.fields;
				for(var key in skill_items) {
					var skill = skill_items[key];
					if(skill.id == id_skill) {
						label = skill.it;
						return label;
					}
					if(skill.fields) {
						var items = skill.fields;
						for(var key2 in items) {
							var subskill = items[key2];
							if(subskill.fields) {
								var items2 = subskill.fields;
								for(var key3 in items2) {
									var subskill2 = items2[key3];
									if(subskill2.id == id_skill) {
										label = skill.it + " -> " + subskill.it + " -> " + subskill2.it;
										return label;
									}  
								}
							} else {
								if(subskill.id == id_skill) {
									label = skill.it + " -> " + subskill.it;
									return label;
								} 		
							}
						}
					} else {
						if(skill.id == id_skill) {
							label = skill.it;
							return label;
						}
					}
				}
			}
			return label;
		},

		getSkills() {
			axios.get(`/skills`)
			.then((res) => {
				this.skills = JSON.parse(res.data.standard);
				this.skills_portiere = JSON.parse(res.data.special);
				this.loading    = false;
			}).catch((err) => {
				this.loading = false;
			});

		},

		getSkillsObject: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skill/object').then(function(data) {
				this.skills_object = data.data;
			});
		},

		importExercises() {
			if(confirm("Confermi l'importazione degli esercizi selezionati?")) {
				this.$refs.exModal.hide();
				axios.get(`/academyita/import/exercise/${this.exercizi_ids}/${this.academyCatId}/${this.exercises_tab}`, {
					params: {
						academy: this.academy,
						to_academy: 0,
					}
				})
				.then((res) => {
					this.$store.commit(types.ALERTS_PUSH, { msg: res.data.message, status: 1 });
					this.$store.commit('acad_esercizi/setPage', 1);
					if(this.exercises_tab == 0) {
						this.$store.dispatch('acad_esercizi/filter');
					} else if(this.exercises_tab == 1) {
						this.$store.dispatch('acad_esercizi/filter_archivio');
					} else if(this.exercises_tab == 2) {
						this.$store.dispatch('acad_esercizi/filter_bozze');
					}
				}).catch((err) => {
					this.loading = false;
				});
			}
		},
		selectEsercizio(id) {
			var index = this.exercizi_ids.indexOf(id);
			if(index > -1) {
				this.exercizi_ids.splice(index, 1);
			} else {
				this.exercizi_ids.push(id);
			}
		}
	},

	mounted() {
		this.$root.$on('condividiEserciziDaFootball', this.openSearchExe);
	},

	beforeDestroy() {
		this.$root.$off('condividiEserciziDaFootball');
	}
}
</script>
<style scoped>
.card-header {
	padding-bottom: 0.5rem;
}
.tab-content {
	padding: 0 !important;
	border: 0;
}
</style>
