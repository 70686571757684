export const ROUTES_ROMALAB = [
    {
        path: '/romalab/dashboard',
        name: 'romalab_dashboard',
        component: require('../components/pages/romalab/dashboard.vue').default,
        meta: {
            module: 'romalab',

            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            showCategory: true,
            parent: '',
            permesso: 'romalab',
        }
    },

    {
        path: '/romalab/pdf/cartelle',
        name: 'romalab_pdf_cartelle',
        component: require('../components/pages/romalab/pdf_cartelle.vue').default,
        meta: {
            module: 'romalab',
            label: 'Documenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'romalab_pdf',
        }
    },

    {
        path: '/romalab/pdf/:id',
        name: 'romalab_pdf',
        component: require('../components/pages/romalab/pdf.vue').default,
        meta: {
            module: 'romalab',
            label: 'Documenti',
            requiresAuth: true,
            showInMenu: false,
            parent: 'romalab_pdf_cartelle',
            permesso: 'romalab_pdf',
            logModel: 'RomalabPdf',
            logModelId: 'id',
        }
    },

    {
        path: '/romalab/archivio/pdf',
        name: 'romalab_pdf_archivio',
        component: require('../components/pages/romalab/pdf_archivio.vue').default,
        meta: {
            module: 'romalab',
            label: 'Documenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'romalab_pdf_archivio',
        }
    },

    {
        path: '/romalab/video',
        name: 'romalab_video',
        component: require('../components/pages/romalab/video.vue').default,
        meta: {
            module: 'romalab',
            label: 'Video',
            requiresAuth: true,
            showInMenu: true,
            showCategory: true,
            parent: '',
            permesso: 'romalab_video',
        }
    },

    {
        path: '/romalab/video/cartelle',
        name: 'romalab_video_cartelle',
        component: require('../components/pages/romalab/video_cartelle.vue').default,
        meta: {
            module: 'romalab',
            label: 'Video',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'romalab_video_mng',
        }
    },

    {
        path: '/romalab/video/:id',
        name: 'romalab_video_cartella',
        component: require('../components/pages/romalab/video_cartella.vue').default,
        meta: {
            module: 'romalab',
            label: 'Video',
            requiresAuth: true,
            showInMenu: false,
            parent: 'romalab_video_cartelle',
            permesso: 'romalab_video_mng',
            logModel: 'RomalabCartelle',
            logModelId: 'id',
        }
    },

    {
        path: '/romalab/video/new/:id_cartella',
        name: 'romalab_video_new',
        component: require('../components/pages/romalab/video_new.vue').default,
        meta: {
            module: 'romalab',
            label: 'Nuovo Video',
            requiresAuth: true,
            showInMenu: false,
            parent: 'romalab_video',
            permesso: 'romalab_video_mng',
            logModel: 'RomalabCartelle',
            logModelId: 'id_cartella',
        }
    },

    {
        path: '/romalab/video/edit/:id/:id_cartella',
        name: 'romalab_video_edit',
        component: require('../components/pages/romalab/video_edit.vue').default,
        meta: {
            module: 'romalab',
            label: 'Modifica Video',
            requiresAuth: true,
            showInMenu: false,
            parent: 'romalab_video',
            permesso: 'romalab_video_mng',
            logModel: 'RomalabVideo',
            logModelId: 'id',
        }
    },
];
