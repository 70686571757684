<template>
  <div class="vuefinder-file-previewer">
    <component 
    :is="preview[type] || preview['default']" 
    :url="url" 
    :path="path"
    />
    <slot />
</div>
</template>

<script>
import axios from 'axios';

var preview = {
    'default': {
        props: ['url', 'path'],
        template: `
        <div>
        <a 
        class="btn btn-success btn" 
        :href="content"
        target="_blank"
        >
        <i class="fa fa-search"></i> View file
        </a>
        </div>
        `,
        data () {
            return {
                content: ''
            };
        },
        mounted () {
            axios(this.url, { params: { path: this.path } })
            .then(response => {
                this.content = response.data;
            });
        }
    },
    'file-image': {
        props: ['url', 'path'],
        template: `
        <img :src="content" />
        `,
        data () {
            return {
                content: ''
            };
        },
        mounted () {
            axios(this.url, { params: { path: this.path } })
            .then(response => {
                this.content = response.data;
            });
        }
    },
    'file-alt': {
        props: ['url', 'path'],
        template: `
        <iframe style="width:100%;height:100%" :src="content" allowfullscreen>
        </iframe>
        `,
        data () {
            return {
                content: ''
            };
        },
        mounted () {
            axios(this.url, { params: { path: this.path } })
            .then(response => {
                this.content = response.data;
            });
        }
    },
    'file-video': {
        props: ['url', 'path'],
        template: `
        <iframe style="width:100%;height:100%" :src="content" allowfullscreen>
        </iframe>
        `,
        data () {
            return {
                content: ''
            };
        },
        mounted () {
            axios(this.url, { params: { path: this.path } })
            .then(response => {
                this.content = response.data;
            });
        }
    },
    'file-audio': {
        props: ['url', 'path'],
        template: `
        <iframe style="width:100%;height:100%" :src="content">
        </iframe>
        `,
        data () {
            return {
                content: ''
            };
        },
        mounted () {
            axios(this.url, { params: { path: this.path } })
            .then(response => {
                this.content = response.data;
            });
        }
    },
    'file-pdf': {
        props: ['url', 'path'],
        template: `
        <iframe style="width:100%;height:100%" :src="content" allowfullscreen>
        </iframe>
        `,
        data () {
            return {
                content: ''
            };
        },
        mounted () {
            axios(this.url, { params: { path: this.path } })
            .then(response => {
                this.content = response.data;
            });
        }
    },
};

export default {
    name: 'FilePreviewer',
    props:{
        url: {
            type: String,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            preview: preview
        };
    }
};
</script>

<style lang="scss" scoped>
.vuefinder-file-previewer {
  display: flex;
  margin-bottom: 1em;
  max-height: 400px;
  height: 400px;

  >>> .vuefinder-text-preview {
    background-color: whitesmoke;
    color: #4a4a4a;
    font-size: 0.875em;
    overflow-x: auto;
    max-width: 800px;
    padding: 1.1em 1.2rem;
    white-space: pre-line;
    word-wrap: break-word;
    border: 1px solid lightgray;
    user-select: text;
    width: 100%;
}

>>> img,
embed,
object,
audio,
video {
    max-width: 100%;
    max-height: 180px;
}

>>> img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    object-fit: contain;
}
}
</style>