<template id="view_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card title_page">
				<div class="card-body">
				<div class="card-title">
					<span>Valutazione Giocatore</span>
				</div>
			</div>
			</div>	
			<div class="content_page">
				<b-row>
					<b-col cols="3">
						<div class="card" style="height:100%">
							<div style="text-align:center;">
								<b-img :src="valutazione.osservato.avatar_url" :alt="valutazione.osservato.cognome + ' ' + valutazione.osservato.nome" :width="130"></b-img>
							</div>
							<div class="card-block">
								<div class="mb-1 font-bold text-center">{{ valutazione.osservato.nome }} {{ valutazione.osservato.cognome }}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.osservato.last_carriera_sportiva.ruolo_ideale && valutazione.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? valutazione.osservato.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</div>
								<div class="text-center font-size-sm">Classe {{ valutazione.osservato.nascita_data | formatYear }}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.osservato.last_carriera_sportiva.squadra ? valutazione.osservato.last_carriera_sportiva.squadra.name : ''}}</div>
								<div class="text-center font-size-sm mt-1" v-if="valutazione.gradimento">
									<h5><b-badge :style="{background: valutazione.gradimento.colore}">{{ valutazione.gradimento.nome}}</b-badge></h5>
								</div>
								<div class="text-center"><b-button variant="primary" size="sm" title="Download scheda" @click="downloadScheda" v-if="auth.isUserEnable('scout_down_val_player')"><i class="fa fa-download" aria-hidden="true"></i>
								</b-button><b-button variant="primary" size="sm" class="ml-1" title="Condividi scheda" @click="condividiScheda" v-if="auth.isUserEnable('scout_share_val_player')"><i class="fa fa-share-alt" aria-hidden="true"></i>
								</b-button></div>
							</div>
						</div>
					</b-col>
					<b-col>
						<b-tabs>
							<b-tab :title="'Partita ' + (index + 1)" v-for="(item, index) in valutazione.partite" :key="(index + 1)">	
								<b-row>
									<b-col>
										<b-row>
											<b-col>Competizione</b-col>
											<b-col class="label-value">{{item.competizione}}</b-col>
										</b-row>
										<b-row>
											<b-col>Partita</b-col>
											<b-col class="label-value">{{item.partita}}</b-col>
										</b-row>
										<b-row>
											<b-col>Data / Ora</b-col>
											<b-col class="label-value">{{item.data | formatDateHour}}</b-col>
										</b-row>
										<b-row>
											<b-col>Sistema di gioco</b-col>
											<b-col class="label-value">{{item.sistema_gioco ? item.sistema_gioco.sistema_gioco : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Ruolo primario</b-col>
											<b-col class="label-value">{{item.ruolo_primario ? item.ruolo_primario.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Ruolo secondario</b-col>
											<b-col class="label-value">{{item.ruolo_secondario ? item.ruolo_secondario.nome : ''}}</b-col>
										</b-row>		
									</b-col>
									<b-col>
										<b-row>
											<b-col>Meteo</b-col>
											<b-col class="label-value">{{item.meteo ? item.meteo.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Vento</b-col>
											<b-col class="label-value">{{item.vento ? item.vento.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Tipologia campo</b-col>
											<b-col class="label-value">{{item.tipo_campo ? item.tipo_campo.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Condizioni campo</b-col>
											<b-col class="label-value">{{item.condizioni_campo ? item.condizioni_campo.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Infortunio</b-col>
											<b-col class="label-value">{{item.infortunio ? item.infortunio.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Modalità</b-col>
											<b-col class="label-value">{{item.modalita}}</b-col>
										</b-row>	
									</b-col>
								</b-row>
							</b-tab>
							<b-tab title="Note" :key="(valutazione.partite.length + 1)">
								{{ valutazione.note }}
							</b-tab>
						</b-tabs>
						<b-row class="mt-1">
							<b-col>
								<b-card class="p-1">
									<b-row>
										<b-col>Data</b-col>
										<b-col class="label-value">{{ valutazione.created_at | formatDate }}</b-col>
									</b-row>
									<b-row>
										<b-col>Osservatore</b-col>
										<b-col class="label-value">{{ valutazione.utente.cognome }} {{ valutazione.utente.nome }}</b-col>
									</b-row>
									<b-row>
										<b-col>Voto</b-col>
										<b-col class="label-value">{{ valutazione.voto[0] }}</b-col>
									</b-row>
								</b-card>
							</b-col>
							<b-col>
								<b-card class="p-1">
									<b-row>
										<b-col>Media/N° valutazioni</b-col>
										<b-col class="label-value">{{voto_medio}} / {{num_val}}</b-col>
									</b-row>
									<b-row>
										<b-col>Elenco valutazioni</b-col>
										<b-col class="label-value"><b-pagination-nav size="sm" :number-of-pages="valutazione.osservato.valutazioni.length" base-url="#" :link-gen="linkGen" v-model="currentPage" use-router/></b-col>
									</b-row>						 
								</b-card>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row class="mt-1" v-if="!auth.isUserEnable('scout_not_skills')">
					<b-col v-for="item in fields" v-if="skills[item.id]">
						<b-card :title="item[$i18n.t('lang')]">
							<b-list-group>
								<b-list-group-item v-for="skill in item.fields" v-if="(!skill.fields ? skills[item.id][skill.id] : voto(item.id, skill.fields, 1)) > 0">
									<b-row :style="'width:100%;' + (skill.fields ? 'cursor:pointer;' : '')">
										<b-col cols="11">
											<b-row align-v="center">
												<b-col cols="5" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													{{skill[$i18n.t('lang')]}}
												</b-col>
												<b-col cols="1" class="text-right">
													<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(skill, skill.it)">{{ skill.count_tags }}</span>
												</b-col>
												<b-col cols="1" class="text-center">
													<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="skill.count_tags" :title="skill.count_tags" @click="selectSkillTags(skill, skill.it)"></i>
												</b-col>
												<b-col cols="5" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													<b-progress :value="!skill.fields ? skills[item.id][skill.id] : voto(item.id, skill.fields, 1)" :max="max" show-value :precision="!skill.fields ? 0 : 2" :variant="'voto-' + Math.round(!skill.fields ? skills[item.id][skill.id] : voto(item.id, skill.fields, 1))"></b-progress>
												</b-col>
											</b-row>
										</b-col>
										<b-col cols="1" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
											<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
											<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
										</b-col>
									</b-row>						
									<b-collapse :id="skill.id" style="width:100%">
										<b-list-group v-if="skill.fields">
											<b-list-group-item v-for="subskill in skill.fields" v-if="(!subskill.fields ? skills[item.id][skill.id][subskill.id] : voto(item.id, subskill.fields, 2)) > 0">

												<b-row :style="'width:100%;' + (subskill.fields ? 'cursor:pointer;' : '')">
													<b-col cols="11">
														<b-row align-v="center">
															<b-col cols="5" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																{{subskill[$i18n.t('lang')]}}
															</b-col>
															<b-col cols="1" class="text-right">
																<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)">{{ subskill.count_tags }}</span>
															</b-col>
															<b-col cols="1" class="text-center">
																<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill.count_tags" :title="subskill.count_tags" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)"></i>
															</b-col>
															<b-col cols="5" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																<b-progress :value="!subskill.fields ? skills[item.id][skill.id][subskill.id] : voto(item.id, subskill.fields, 2)" :max="max" show-value :precision="!subskill.fields ? 0 : 2" :variant="'voto-' + Math.round(!subskill.fields ? skills[item.id][skill.id][subskill.id] : voto(item.id, subskill.fields, 2))"></b-progress>
															</b-col>
														</b-row>
													</b-col>
													<b-col cols="1">
														<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
														<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
													</b-col>
												</b-row>						
												<b-collapse :id="subskill.id" style="width:100%">
													<b-list-group v-if="subskill.fields">
														<b-list-group-item v-for="subskill2 in subskill.fields" v-if="skills[item.id][skill.id][subskill.id][subskill2.id] > 0">
															<b-row style="width:100%">
																<b-col cols="11">
																	<b-row align-v="center">
																		<b-col cols="5">
																			{{subskill2[$i18n.t('lang')]}}		
																		</b-col>
																		<b-col cols="1" class="text-right">
																			<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)">{{ subskill2.count_tags }}</span>
																		</b-col>
																		<b-col cols="1" class="text-center">
																			<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill2.count_tags" :title="subskill2.count_tags" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)"></i>
																		</b-col>
																		<b-col cols="5">
																			<b-progress :value="skills[item.id][skill.id][subskill.id][subskill2.id]" :max="max" show-value :variant="'voto-' + skills[item.id][skill.id][subskill.id][subskill2.id]"></b-progress>
																		</b-col>
																	</b-row>									
																</b-col>
																<b-col cols="1">
																</b-col>
															</b-row>
														</b-list-group-item>
													</b-list-group>
												</b-collapse>
											</b-list-group-item>
										</b-list-group>
									</b-collapse>
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-col>
				</b-row>
				<b-modal id="shareModal" ref="shareModal" title="Condividi scheda giocatore" ok-only ok-title="Chiudi">
				<div v-if="sending" class="text-center"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
    			<b-form @submit="inviaSchedaGiocatore" v-if="!sending">
    				<b-form-group id="toInputGroup"
                    label="Email destinatari (divisi da virgola):"
                    label-for="toInput">
	        			<b-form-input id="toInput"
	                      type="text"
	                      v-model="form.to"
	                      required>
	        			</b-form-input>
        			</b-form-group>
        			<b-form-group id="ccInputGroup"
                    label="Cc (divisi da virgola):"
                    label-for="ccInput">
        			<b-form-input id="ccInput"
                      type="text"
                      v-model="form.cc">
        			</b-form-input>
        			</b-form-group>
        			<b-form-group id="ccnInputGroup"
                    label="Ccn (divisi da virgola):"
                    label-for="ccnInput">
        			<b-form-input id="ccnInput"
                      type="text"
                      v-model="form.ccn">
        			</b-form-input>
        			</b-form-group>
        			<b-form-group id="textInputGroup"
                    label="Testo del messaggio:"
                    label-for="textInput">
        			<b-form-textarea id="textInput"
                     v-model="form.message"
                     :rows="3"
                     :max-rows="3">
    				</b-form-textarea>
					</b-form-group>
					<b-form-group>
					<b-form-checkbox id="checkbox1"
                     v-model="form.onlyThis"
                     value="1">
      					Invia solo la valutazione corrente
    				</b-form-checkbox>
    				</b-form-group>
					<b-button type="submit" variant="primary">Invia</b-button>
					<b-button type="button" variant="success" @click="getLastSchedaInviata">Carica ultimi destinatari</b-button>
      				<b-button type="reset" variant="secondary">Reset</b-button>
    			</b-form>
  			</b-modal>
  			<b-modal id="tagsModal" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
				<b-row v-if="skill_tags_sel">
					<b-col cols="3" style="overflow-y:auto;height:550px;">
						<div v-for="(tag, index) in videotags">
							<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag"></thumbtag>
						</div>
					</b-col>
					<b-col cols="9">
						<video
						id="videotags-wrapper"
						class="video-js vjs-default-skin vjs-16-9"
						controls
						data-setup="{}">
					</video>
						<b-pagination size="sm" align="center" :total-rows="videotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
						<div v-if="tag_sel">
							<p class="m-1">{{ count }} - {{ skillName }}</p>
							<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
							<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
							<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
							<p class="m-1">{{ tag_sel.nota }}</p>
						</div>
					</b-col>
				</b-row>
			</b-modal>
			</div>	
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-playlist';

export default {
	data: function() {
		return {
			valutazione: [],
			voti: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			skills: [],
			fields: [],
			currentPage: 0,
			max: 10,
			form: {
				to: '',
				cc: '',
				ccn: '',
				message: '',
				onlyThis: '',
			},
			sending: false,
			errors: [],
			skill_tags_sel: null,
			videotags: [], 
			tag_sel: null,
			count: 0,
			title: '',
			playlist: [],
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
		thumbtag:   require('../../layout/thumbtag.vue').default,
	},
	created: function () {
		this.loadData();
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
	methods: {
		loadData() {
			this.skills = [];
			this.fields = [];
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/view/valutazione/' + this.$route.params.val_id + '/old').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.valutazione = response.data;

            	this.fields = this.valutazione.skills_tags;
            	this.skills = JSON.parse(this.valutazione.skills); 	
            	var count = 1;
            	for (var index in this.valutazione.osservato.valutazioni) {
            		if(this.valutazione.osservato.valutazioni[index].id == this.valutazione.id) {
            			this.currentPage = (count);
            			break;
            		}
            		count++;
            	}
            }
        }, (response) => {
        });
		},
		linkGen (pageNum) {
			return {
				path: '/scouting/view/valutazione/' + this.valutazione.osservato.id + '/' + this.valutazione.osservato.valutazioni[pageNum - 1].id
			}
		},

		voto(cat_id, skill_fields, level) {
			var voto = 0;
			var count = 0;
			var voto2 = 0;
			var count2 = 0;
			var arrayVoto = [];
			var arrayCount = [];
			var cat_skills = this.skills[cat_id];
			for(var i in skill_fields) {
				var id = skill_fields[i].id;
				for(var item in cat_skills) {
					var item2 = cat_skills[item];
					if(item2 instanceof Object) {
						for(var item3 in item2) {
							var item4 = item2[item3];
							if(level == 1) {
								if(item3 == id) {
									if(item4 instanceof Object) {
										voto2 = 0;
										count2 = 0;
										for(var item5 in item4) {
											if(item4[item5] > 0) {
												voto2+= item4[item5];
												count2++;
											}
										}
										arrayVoto.push(voto2);
										arrayCount.push(count2);
									} else if(item4 > 0) {
										voto += item4;
										count++;
									}
									break;
								}
							} else if(level == 2) {
								if(item4 instanceof Object) {
									for(var item5 in item4) {
										if(item5 == id && item4[item5] > 0) {
											voto+= item4[item5];
											count++;
											break;
										}
									}
								}
							}
						}        		
					} 
				}
			}     
			if(arrayVoto.length > 0) {
				var votoTot = 0; 
				var countTot = 0;
				if(voto > 0) {
					votoTot = voto/count;
					countTot++;
				}
				for(var index in arrayVoto) {
					if(arrayVoto[index] > 0) {
						votoTot += arrayVoto[index]/arrayCount[index];
						countTot++;
					}            
				}
				if(votoTot > 0) {
					voto = votoTot/countTot;
				}
			} else if(voto > 0) {
				voto = voto/count;
			} 
			return voto;
		},
		downloadScheda() {
			window.location = this.$store.state.apiEndPoint + '/scouting/pdf/giocatore/' + this.valutazione.osservato.id + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		condividiScheda() {
			this.$refs.shareModal.show();		
		},
		inviaSchedaGiocatore(evt) {
			evt.preventDefault();
			this.sending = true;
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/invia/scheda/giocatore/' + this.valutazione.id, this.form).then((response) => {
            // success callback
            if (response.ok)
            {   
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            	this.sending = false;
            	this.$refs.shareModal.hide();
            }
        }, (response) => {  
        	if (response.status == 422) {
        		this.errors = response.data;
        		for (var index in this.errors) {
        			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        		}
        		this.sending = false;
        		this.$refs.shareModal.hide();
        	}
            // error callback
        });
		},
		selectSkillTags(skill_tags, title) {
			this.title = title;	
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.tagsModal.show();
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1); 
		},
		selectPageTag(page) {
           this.tag_sel = this.videotags[page - 1]; 
           this.$video_tags.playlist.currentItem(page - 1);    
        },
        autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.$video_tags.dispose();
			this.$video_tags = null;
			this.tag_sel = null;
		},
		getLastSchedaInviata() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/last/scheda/inviata').then((response) => {
            if (response.ok)
            {
            	var last_scheda = response.data;
            	this.form.to = last_scheda.mailto;
            	this.form.cc = last_scheda.mailcc;
            	this.form.ccn = last_scheda.mailccn;       	
            }
        }, (response) => {
        });
		},
	},
	computed: {
		auth: function() {
            return Auth;
        },
		voto_medio: function() {

			var count = 0;
			var voto = 0;
			for (var index in this.valutazione.osservato.valutazioni) {
				if(this.valutazione.osservato.valutazioni[index].voto[0] > 0) {
					voto += this.valutazione.osservato.valutazioni[index].voto[0];
					count++;
				}
			}
			return Math.round((voto/count) * 100) / 100;
		},
		num_val: function() {

			var count = 0;
			for (var index in this.valutazione.osservato.valutazioni) {
				if(this.valutazione.osservato.valutazioni[index].voto[0] > 0) {
					count++;
				}
			}
			return count;
		},
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},
	},
	watch: {
		'$route': function () {
			this.loadData();
		},
	},
	beforeDestroy() {
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},
}

</script>
