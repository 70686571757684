<template>
  <modal @close="$emit('close')">
    <div class="vuefinder-modal-header">
      <p>Are you sure you want to download these items?</p>
    </div>
    <div>{{ data.length }} item{{ data.length > 1 ? 's':'' }} selected.</div>
    <div class="vuefinder-modal-body">
      <ul class="delete-list">
        <li 
          v-for="item in data" 
          :key="item.path"
        >
          <div class="delete-list-item">
            <span class="file_path">{{ item.dirname?'/':'' }}{{ item.dirname }}/</span>
            <span class="file_name">{{ item.basename }}</span>
          </div>
          <div class="delete-list-type">{{ item.type }}</div>
        </li>
      </ul>
    </div>
    <div class="vuefinder-modal-footer mt-3">
      <button 
        :disabled="waiting"
        class="btn btn-primary btn-sm" 
        @click="download"
      >{{ waiting ? 'Download in corso...' : 'Yes, download!' }}</button>
      <button 
        class="btn btn-secondary btn-sm" 
        @click="$emit('close')"
      >Cancel</button>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';
import axios from 'axios';

export default {
    name: 'ModalDownload',
    components: { 'modal': Modal },
    props: {
        data: {
            type: Array,
            required: true
        }, 
        directory:{
            type: String,
            required: true
        }, 
        url: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            waiting: false,
        };
    },
    methods: {
        download () {
            if (!this.url) {
                this.$emit('close');
                this.$emit('error', 'There is no url defined!', 'error');
                return;
            }

            let selected = this.data.map(function (item) {
                return { path: item.path, type: item.type, basename: item.basename };
            });

            this.waiting = true;

            axios.get(this.url, {
                responseType: 'blob',
                params: {
                    q: 'download',
                    items: JSON.stringify(selected)
                }
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'archive.zip');
                document.body.appendChild(link);
                link.click();
                this.$emit('close');
                this.waiting = false;
            });

        },
    }
};
</script>

<style scoped>
.delete-list {
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.delete-list li {
  display: flex;
  padding-top: 5px;
  border-bottom: 1px dashed lightgray;
}

.delete-list-item {
  flex: 1;
  user-select: text;
}

.delete-list-type {
  margin-left: 10px;
  align-self: center;
}

.file_name {
  color: crimson;
}

.file_path {
  color: darkslategrey;
}
</style>
