export default {
    esercizi: [],
    objective_ids: [],
    title: '',
    page: 1,
    drill_id: null,
    footballTeamId: null,
    academyCategoryId: null,
    academy: -1,
    loadingEsercizi: false,
};
