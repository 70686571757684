export const ROUTES_VISION = [
    {
        path: '/vision/dashboard',
        name: 'vision_dashboard',
        component: require('../components/pages/vision/dashboard.vue').default,
        meta: {
            module: 'vision',

            label: 'Vision',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'vision',
        }
    },
];
