<template>
	<div>
		<b-row>
			<b-col :cols="3">
				<b-form-group label="Cut In">
					<b-input size="sm" type="number" v-model.number="trim.transcoding.in" :step="seekStep" :min="0" :max="probedVideo.Duration"></b-input>
					<a class="muted small" @click.prevent="trim.transcoding.in = parseFloat(player.currentTime)" href="">Current</a>
				</b-form-group>
				<b-form-group label="Cut Out">
					<b-input size="sm" type="number" v-model.number="trim.transcoding.out" :step="seekStep" :min="trim.transcoding.in" :max="probedVideo.Duration"></b-input>
					<a class="muted small" @click.prevent="trim.transcoding.out = parseFloat(player.currentTime)" href="">Current</a>
				</b-form-group>
				<hr />
				Durata Taglio: <strong>{{ cutDuration | duration }}</strong>
			</b-col>
			<b-col :cols="9">
				<Smart-Player ref="player" @tcabs="player.currentTime = $event" :video="makeUrl(video)" :step="seekStep" :cutIn="trim.transcoding.in" :cutOut="trim.transcoding.out" :width="probedVideo.Width" :height="probedVideo.Height"></Smart-Player>
			</b-col>
		</b-row>

		<b-button variant="outline-secondary" size="xs" @click.prevent="addLayer('timer')" class="mb-2">Aggiungi Timer</b-button>

		<Video-Cutter-Layer v-for="layer in trim.rendering" :layer="layer" :player="player" :duration="cutDuration"></Video-Cutter-Layer>

	</div>
</template>

<script>

import moment from 'moment'

export default {
	props: ['trim', 'video', 'probedVideo'],
	components: {
		SmartPlayer: require('@/components/layout/video/smart-player.vue').default,
		VideoCutterLayer: require('@/components/layout/video/video-cutter-layer.vue').default,
	},
	data: function() {
		return {
			player: {
				currentTime: 0
			}
		}
	},
	filters: {
		duration: function(a) {
			return moment('2000-01-01 00:00:00').add(a, 'seconds').format('HH:mm:ss');
		}
	},
	computed: {
		cutDuration: function() {
			return this.trim.transcoding.out - this.trim.transcoding.in;
		},
		seekStep: function() {
			return 1/parseFloat(this.probedVideo.FrameRate);
		}
	},
	methods: {
		addLayer: function(layerType) {

			var layerObject = {
				id: 1,
				fps: 25,
				type: layerType,
				scale: 1,
				x: 0,
				y: 0,
				layerIn: 0,
				layerOut: _.clone(this.trim.transcoding.out),
				payload: null,
				keyframes: []
			};

			this.trim.rendering.push(layerObject);

		},
		makeUrl: function(a) {
			return URL.createObjectURL(a);
		}
	}
}
</script>

</script>