<template>
	<div>
		<headapp></headapp>

		<div style="background-color: white">
			<b-container class="pt-2">
				<menusub></menusub>

				<!-- <b-button variant="outline-primary" size="sm" :to="{ name: 'CompetitionNew' }">Nuova Competizione</b-button> -->

				<b-list-group class="mt-2">
					<b-list-group-item v-for="competition in competitions" :to="{ name: 'CompetitionGet', query: { first: true }, params: { competition_id: competition.id } }">
						{{ competition.name }} <small>({{competition.season}})</small>
					</b-list-group-item>
				</b-list-group>

				<div v-if="timeline">
					<hr />
					<b-table-simple bordered small striped>
						<b-thead>
							<b-tr>
								<b-td>Data</b-td>
								<b-td>Competizione</b-td>
								<b-td>Home</b-td>
								<b-td>Away</b-td>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="match in timeline">
								<b-td>{{ match.date | date }}</b-td>
								<b-td><router-link :to="{ name: 'CompetitionStageMatch', params: { competition_id: match.match.competition_id, stage_id: match.match.competition_stage_id, match_id: match.match.id } }">{{ match.competition.name }}</router-link></b-td>
								<b-td>{{ match.home.name }}</b-td>
								<b-td>{{ match.away.name }}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
					<hr />
				</div>

				<b-card-group columns class="mt-2">
					<b-card v-for="competition in competitions">
						<template slot="header">{{ competition.name }}</template>
						<b-table-simple small striped>
							<b-thead>
								<b-tr>
									<b-th>Team</b-th>
									<b-th>•</b-th>
									<b-th>PT</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="team in competition.stages[0].standing">
									<b-td>{{ team.team.name }}</b-td>
									<b-td>{{ team.played }}</b-td>
									<b-td>{{ team.pts }}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-card>
				</b-card-group>

			</b-container>

		</div>
		<footerapp></footerapp>


	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {

	components: {
		headapp: 	require('@/components/layout/headapp.vue').default,
		menusub:    require('@/components/layout/menusub.vue').default,
		footerapp:  require('@/components/layout/footerapp.vue').default
	},
	filters: {
		date: function(a, format = null) {
			return moment(a).format(format || ( a.indexOf(':') !== -1 ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY' ))
		}
	},
	data: function() {
		return {
			competitions: null,
			timeline: null
		}
	},
	created: function() {
		this.get_competitions();
		this.get_timeline();
	},
	methods: {
		get_timeline: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'timeline').then(function(data) {
				this.timeline = data.data;
			})
		},
		get_competitions: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competitions').then(function(data) {
				this.competitions = data.data;
			});
		}
	}
}

</script>