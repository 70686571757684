<template>
	<div>
		
		<b-form @submit.prevent="create">

			<b-form-group label="Nome">
				<b-input v-model="model.nome"></b-input>
			</b-form-group>

			<b-form-group horizontal>
				<b-form-file
					v-model="ux.file"
					placeholder="Scegli un file da caricare..."
					drop-placeholder="Cancella file..."
				></b-form-file>
			</b-form-group>

			<!--<label class="m-0"><input type="checkbox" v-model="model.pubblica" /> Pubblico</label>-->

			<div class="mt-3">
				<b-button :disabled="!ux.file" variant="outline-success" size="sm" type="submit">Carica File</b-button>
			</div>

			<!-- numero, emissione, scadenza -->

		</b-form>

		<!-- <pre>{{model}}</pre> -->
		<!-- <pre>{{ux}}</pre> -->


	</div>
</template>

<script>

import _ from 'lodash'

export default {
	props: [ 'model' ],
	created: function() {

	},
	computed: {
		
	},
	watch: {
		'model': {
			deep: true,
			handler: function() {
				this.ux.file = [];
			}
		}	
	},
	methods: {
		create: function() {
			this.$emit('upload', this.ux.file);
		}
	},
	filters: {
		
	},
	data: function() {
		return {
			ux: {
				file: [],
				creating: true
			}
		}
	}
};

</script>