import Vue from 'vue';
import _ from 'lodash';
import { compactDeep } from '../../../utils';

export default {
  toggleLeftSidebar(state, toggled) {
    const newState = _.isBoolean(toggled) ? toggled : !state.leftSidebarCollapsed;
    Vue.set(state, 'leftSidebarCollapsed', newState);
  },

  toggleRightSidebar(state, toggled) {
    const newState = _.isBoolean(toggled) ? toggled : !state.rightSidebarCollapsed;
    Vue.set(state, 'rightSidebarCollapsed', newState);
  },

  setMatches(state, matches) {
    Vue.set(state, 'matches', matches);
  },

  setPlayers(state, players) {
    Vue.set(state, 'players', players);
  },

  updateFilters(state, filters) {
    const result = { ...filters };

    if (filters.country) {
      result.country = filters.country.map(c => c.value);
    }

    if (filters.competition) {
      result.competition = filters.competition.map(c => c.value);
    }

    Vue.set(state, 'filters', compactDeep(result));
  },

  updateCenter(state, center) {
    Vue.set(state, 'center', center);
  },

  loading(state, isLoading) {
    Vue.set(state, 'loading', isLoading);
  },

  setMission(state, match) {
    Vue.set(state.mission, 'match', match);
  },

  setMatchesByCity(state, matches) {
    Vue.set(state, 'matches_by_city', matches);
  },
};
