<template>
	<div>
		<div v-for="a,k in fase" v-if="manifest[k].hideFilter == undefined || manifest[k].hideFilter == false">
			{{manifest[k].label}}
			<div class="d-flex">
				<div v-if="hasOperators(manifest[k])">{{manifest[k]}}</div>
				<div class="flex-grow-1" style="height: 100%" :is="typeOf(manifest[k].type)" :test="fase[k]" field="value" :options="manifest[k]"></div>
			</div>
		</div>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	props: ['manifest', 'fase'],
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceActivitycheck: require('@/components/layout/Sessioni/Matrix/ActivityCheck.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceJson: require('@/components/layout/Sessioni/Matrix/Json.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
		SessioneMatriceDate: require('@/components/layout/Sessioni/Matrix/Date.vue').default,
	},
	methods: {
		typeOf: function(tipo) {
			return 'SessioneMatrice' + _.capitalize(tipo);
		},
	},
	data: function() {
		return {
		}
	},
	methods: {
		hasOperators: function(a) {

			if(a.operators != undefined && _.size(a.operators) > 1)
				return true;

		}
	}
}

</script>