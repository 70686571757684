<template>
    <div>
        <b-form-group v-if="!readonly" :label="label + (hasMsgTranslate[lingua_selected] && hasMsgTranslate[lingua_selected] != '' ? ' (' + hasMsgTranslate[lingua_selected] + ')' : '')" >
            <b-overlay :show="waiting">
                <b-row>
                    <b-col :cols="linguaModel.master === linguaModel.selected ? 12 : (model[linguaModel.master] && model[linguaModel.master].length ? 6 : 12)">
                        <b-form-textarea @blur="userInput" v-model="model[lingua_selected]" rows="1" max-rows="8"></b-form-textarea>
                    </b-col>
                    <b-col v-if="linguaModel.master !== linguaModel.selected && model[linguaModel.master] && model[linguaModel.master].length">
                        <b-form-textarea :readonly="true" :value="model[linguaModel.master]" rows="1" max-rows="8"></b-form-textarea>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-form-group>
        <div v-if="readonly && ((model[lingua_selected] && model[lingua_selected].length > 0) || waiting)">
            <h6 class="color-rosso"><strong>{{ label }}</strong>{{ hasMsgTranslate[lingua_selected] && hasMsgTranslate[lingua_selected] != '' ? ' (' + hasMsgTranslate[lingua_selected] + ')' : '' }}</h6> 
            <b-overlay :show="waiting">  
                {{ model[lingua_selected] }}
            </b-overlay>
            <br/>
        </div>
    </div>
</template>

<script>

//import _ from 'lodash'

export default {
    props: [ 'label', 'lingua_selected', 'linguaModel', 'lingua_master', 'model', 'readonly', 'entita', 'entitaId', 'campo'],
    data: function() {
        return {
            lingueProtected: [],
            waiting: false,
            hasMsgTranslate: {},
            last_old_value: null,
            last_new_value: null
        }
    },
    methods: {
        userInput: function() {

            if(this.model[this.lingua_selected].length > 0) {
                this.lingueProtected.push(this.lingua_selected);
            } else {
                var isIn = this.lingueProtected.indexOf(this.lingua_selected);
                if(isIn !== -1)
                    this.lingueProtected.splice(isIn, 1);
            }

            this.lingueProtected = _.uniq(this.lingueProtected);

        },
        doTranslate: function(new_value, old_value) {
            if(this.linguaModel.noTranslate === undefined && this.linguaModel.provider != false) {
                var text = '';
                var from = old_value;
                if(this.model[this.lingua_master] != null && this.model[this.lingua_master].trim() != "") {
                    text = this.model[this.lingua_master];
                    from = this.lingua_master;
                } else if(old_value != null && this.model[old_value] != null && this.model[old_value].trim() != "") {
                    return false;
                    text = this.model[old_value];
                }
                if(text != '') {
                    var entity = null;
                    if(this.entita) {
                        var entity = {
                            entita: this.entita,
                            entita_id: this.entitaId,
                            campo: this.campo
                        }
                    }

                    this.waiting = true;
                    this.$http.post(this.$store.state.apiEndPoint + '/lingue/traduzione', { from: from, to: this.lingua_selected, text: text, entity, provider: this.linguaModel.provider }).then(function(data) { 
                    //  this.model[new_value] = data.data.text;
                        this.$set(this.model, new_value, data.data.text);
                        this.hasMsgTranslate[new_value] = data.data.msg;
                        this.waiting = false;
                    });
                } else {
                    this.$set(this.model, new_value, '');
                    this.hasMsgTranslate[new_value] = '';
                }
            }  

        }
    },
    watch: {
        'linguaModel.provider': function() {

            var isIn = this.lingueProtected.indexOf(this.lingua_selected);
            if(isIn !== -1) return false;
            this.doTranslate(this.lingua_selected, this.last_old_value);

        },
        lingua_selected: function(new_value, old_value) {

            this.last_old_value = old_value;
            this.last_new_value = new_value;

            if(this.model[new_value] == null || this.model[new_value].trim() == '')
                this.doTranslate(this.last_new_value, this.last_old_value);

            return true;      
        }
    },
    created: function() {
        this.lingueProtected = _.map(_.keys(this.model), function(e) { return parseInt(e) });
    }
}
</script>
