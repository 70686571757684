<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<b-card title="Skills Ruoli" title-tag="span">
				<b-card-body class="px-0">
					<b-form @submit="skillsRuoliUpdate">
					<b-row>
						<b-col cols="3">
							<b-form-select v-model="ruolo_sel">
								<option :value="ruolo.id" v-for="ruolo in ruoli">{{ ruolo.nome }}</option>
							</b-form-select>
							<b-form-group>
								<b-form-checkbox-group stacked v-model="altri_ruoli">
									<b-form-checkbox :value="ruolo.id" v-for="ruolo in ruoli" :disabled="ruolo_sel == 3" v-if="ruolo.id != 3 && ruolo.id != ruolo_sel">{{ ruolo.nome }}</b-form-checkbox>
								</b-form-checkbox-group>
							</b-form-group>
						</b-col>
						<b-col>
							<b-row class="mt-1" v-if="ruolo_sel != 3">
								<b-col v-for="item in skills">
									<b-card :title="item.it">
										<b-list-group>
											<b-list-group-item>
												<b-form-group v-for="item2 in item.fields" :key="item2.id" v-if="item.fields">
													<b-form-checkbox v-if="!item2.fields" :id="item2.id" :value="item2.id" v-model="skills_ruoli_ids" style="font-size:1rem;">
														{{ item2.it }}
													</b-form-checkbox>
													<label class="text-uppercase ml-3 font-bold" v-if="item2.fields"> {{ item2.it }} </label>
													<b-form-group v-for="item3 in item2.fields" :key="item3.id" v-if="item2.fields">
														<b-form-checkbox  :id="item3.id" :value="item3.id" v-model="skills_ruoli_ids" style="font-size:1rem;">
															{{ item3.it }}
														</b-form-checkbox>
													</b-form-group>
												</b-form-group>
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</b-col>
							</b-row>
							<b-row class="mt-1" v-if="ruolo_sel == 3">
								<b-col v-for="item in skills_portiere">
									<b-card :title="item.it">
										<b-list-group>
											<b-list-group-item>
												<b-form-group v-for="item2 in item.fields" :key="item2.id" v-if="item.fields">
													<b-form-checkbox v-if="!item2.fields" :id="item2.id" :value="item2.id" v-model="skills_ruoli_ids" style="font-size:1rem;">
														{{ item2.it }}
													</b-form-checkbox>
													<label class="text-uppercase ml-3 font-bold" v-if="item2.fields"> {{ item2.it }} </label>
													<b-form-group v-for="item3 in item2.fields" :key="item3.id" v-if="item2.fields">
														<b-form-checkbox  :id="item3.id" :value="item3.id" v-model="skills_ruoli_ids" style="font-size:1rem;">
															{{ item3.it }}
														</b-form-checkbox>
													</b-form-group>
												</b-form-group>
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
				</b-form>
				</b-card-body>
			</b-card>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
import * as types from '../../../store/types';
import moment from 'moment';
export default {

	data: function() {
		return {
			id_skill: null,
			ruoli: [],
			skills_ruoli: [],
			skills: [],
			skills_portiere: [],
			ruolo_sel: 3,
			altri_ruoli: [],
			skills_ruoli_ids: [],
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
			if (response.ok)
			{
				this.ruoli = response.data; 
			}
		}, (response) => {
            // error callback
        }); 
		this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
			if (response.ok) {
				this.id_skill = JSON.parse(response.data.id);
				this.skills_portiere = JSON.parse(response.data.special);
				this.skills = JSON.parse(response.data.standard);	
				this.skillsRuoli();
			}
		}, (response) => {

		});    
	},

	watch: {
		ruolo_sel: function () {
			this.altri_ruoli = [];
			this.skills_ruoli_ids = [];
			for(var i in this.skills_ruoli) {
				var skill_ruolo = this.skills_ruoli[i];
				if(skill_ruolo.ruolo == this.ruolo_sel) {
					this.skills_ruoli_ids = skill_ruolo.skills.split(',');
					break;
				}
			}
		}
	},
	methods: {
		skillsRuoli() {
			this.$http.get(this.$store.state.apiEndPoint + '/skills/ruoli/' + this.id_skill).then((response) => {
				if (response.ok) {
					this.skills_ruoli = JSON.parse(response.data.valori);	
				}
			}, (response) => {

			});
		},
		skillsRuoliUpdate(evt) {
			evt.preventDefault();
			const formData = new FormData();
			formData.append("id_skill",  this.id_skill);
			formData.append("ruolo_sel",  this.ruolo_sel);
			formData.append("altri_ruoli",  this.altri_ruoli);
			formData.append("skills_ruoli_ids",  this.skills_ruoli_ids);
			this.$http.post(this.$store.state.apiEndPoint + '/skills/ruoli/update', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.skillsRuoli();
				}
			}, (response) => {

			});
		}
	}
}
</script>
