<template id="footerapp">
	<footer class="bg-dark py-2">

		<div class="container-fluid">

			<small class="d-block text-center text-white">A.S. Roma System</small>

		</div>

	</footer>
</template>

<script>

export default {

	data: function(){
		return{

			in: null,

		}
	}
}

</script>
