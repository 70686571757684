<template>
	<div>
		<b-row class="mb-3" v-if="hasVisible">
			<b-col v-for="t,k in timelineBy">
				<b-card class="h-100">
					<div class="text-right"><strong>{{ k | date }}</strong></div>
					<ToolsTimelineRender class="mt-2" @selectfase="$emit('selectfase', $event)" :timeline="t" :filters="filters"></ToolsTimelineRender>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	props: [ 'timeline', 'filters' ],
	components: {
		ToolsTimelineRender: require('@/components/layout/Sessioni/Generica/Tools_Timeline_Render.vue').default
	},
	filters: {
		date: function(a, format) {
			return moment(a).format(format || 'DD/MM/YYYY');
		}
	},
	computed: {
		tl: function() {
			console.log(this.filters);
			return _.filter(this.timeline.timeline, function(a) {

				if(this.filters.days.length > 0) {
					if(this.filters.days.indexOf(moment(a.start_h).format('YYYY-MM-DD')) === -1)
						return false;
				}

				if(this.filters.sessioni.length > 0) {
					if(this.filters.sessioni.indexOf(a.sessione_id) === -1)
						return false;
				}

				return true;
			}.bind(this))
		},
		timelineBy: function() {

			return _.groupBy(this.tl, function(i) {

				return moment(i.start_h).format('YYYY-MM-DD');

			}.bind(this));

		},
		hasVisible() {
			return _.keys(this.timelineBy).length < 4;
		}
	},
	watch: { },
	data: function(){
		return {
		}
	},
	created: function() {
	},
	methods: {
		
	}
}

</script>