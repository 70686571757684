<template>
	<div>
		<b-input type="number" :disabled="disabled" :min="min" :max="max" v-model.number="test[field]" />
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'disabled' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	computed: {
		min: function() {
			if(!this.options.min) return false;
			return this.options.min
		},
		max: function() {
			if(!this.options.max) return false;
			return this.options.max
		},
	}
}
	
</script>