<template>
	<section class="calendar">
		<calendarnew v-if="calendarNew" />
		<calendar v-else />
	</section>
</template>

<script>
import { mapState } from 'vuex';
export default {

	data: function() {
		return {
		}
	},

	computed: {
		...mapState(['calendarNew']),
	},

	components: {
		calendar: require('@/components/pages/medical/calendar.vue').default,
		calendarnew: require('@/components/pages/sessioni/calendar.vue').default,
	},
}
</script>
