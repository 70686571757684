import Vue from 'vue';

export default {
    setPiscinaEsercizi(state, exercises) {
        if(state.page == 1) {
            state.exercises = exercises;
        } else {
            state.exercises.data = state.exercises.data.concat(exercises.data);
        }
    },

    setPiscinaEserciziArchivio(state, exercises) {
        if(state.page == 1) {
            state.exercises_archivio = exercises;
        } else {
            state.exercises_archivio.data = state.exercises_archivio.data.concat(exercises.data);
        }
    },
    
    setPiscinaEserciziCondivisi(state, exercises) {
        if(state.page == 1) {
            state.exercises_condivisi = exercises;
        } else {
            state.exercises_condivisi.data = state.exercises_condivisi.data.concat(exercises.data);
        }
    },

    setFilters(state, filters) {
        state.filters = filters;
    },

    setTitle(state, title) {
        state.title = title;
    },
    setPage(state, page) {
        state.page = page;
    },

    setExcerciseTab(state, tab) {
        state.exercises_tab = tab;
    },  
};
