<template id="scoutvalutazioni">
	<div class="scoutvalutazioni">
		<b-button variant="primary" style="position:absolute;top:25px;right:25px;" @click="selectGenericTags" v-if="!params.academy && !auth.isUserEnable('scout_not_skills')"><i class="fa fa-youtube-play"></i> Azione generica </b-button>
		<table class="table table-striped">
			<thead>
				<tr>
					<th title="Data valutazione">Data</th>
					<th title="Osservatore">Utente</th>
					<th title="Gradimento">Grad.</th>
					<th v-if="!params.newscout" title="Voto">Voto</th>
					<th v-if="!params.newscout" title="Voto per ruolo">V.ruolo</th>
					<th v-if="params.newscout" title="Valutazione breve">Valutazione breve</th>
					<th v-if="params.newscout" title="Note"></th>
					<th v-if="params.newscout && auth.isUserEnable('video_giocatori')" title="Video"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(valutazione, index) in params.valutazioni">
					<td v-if="params.area"><a :href="'#/next/' + params.area + '/view/valutazione/' + params.giocatore_id + '/' + valutazione.id">{{valutazione.created_at | formatDate}}</a></td>
					<td v-else><a :href="(!params.academy ? '#/' + (params.newscout ? (params.youth ? params.youth + 'scout' : 'newscout') : 'scouting') + '/view/valutazione/' : (params.academy == 1 ? '#/academy/scouting/view/valutazione/' : '#/usascout/view/valutazione/')) + params.giocatore_id + '/' + valutazione.id">{{valutazione.created_at | formatDate}}</a></td>
					<td :title="valutazione.utente.nomeCompleto">{{valutazione.utente.cognome}}</td>
					<td :title="valutazione.gradimento ? valutazione.gradimento.nome : ''"><b-badge v-if="valutazione.gradimento" :style="{'color':'black', background: valutazione.gradimento.colore}">{{ params.newscout && valutazione.gradimento.new_name ? valutazione.gradimento.new_name : valutazione.gradimento.nome }}</b-badge></td>
					<td v-if="!params.newscout" class="text-center">{{valutazione.voto[0] > 0 ? valutazione.voto[0] : 'N/D'}}</td>
					<td v-if="!params.newscout" class="text-center">{{valutazione.voto[1] > 0 ? valutazione.voto[1] : 'N/D'}}</td>
					<td v-if="params.newscout && valutazione.breve" class="text-center">
						<div v-if="valutazione.breve.tecnica" style="font-size:0.8rem;text-align: justify;text-transform: uppercase;" v-html="linguaMaster(valutazione) ? valutazione.breve.testo[linguaMaster(valutazione).lingua_glo_id] : valutazione.breve.testo[1]"></div>
						<div v-else style="font-size:0.8rem;text-align: justify;text-transform: uppercase;">
							{{ linguaMaster(valutazione) ? (linguaMaster(valutazione).note_1 ? (linguaMaster(valutazione).note_1.length > 200 ? linguaMaster(valutazione).note_1.substr(0, 200) + '...' : linguaMaster(valutazione).note_1) : '') : (valutazione.note ? (valutazione.note.length > 200 ? valutazione.note.substr(0, 200) + '...' : valutazione.note) : '') }}
						</div>
					</td>
					<td v-if="params.newscout && !valutazione.breve" style="font-size:0.8rem;text-align: justify;text-transform: uppercase;">
						{{ linguaMaster(valutazione) ? (linguaMaster(valutazione).note_1 ? (linguaMaster(valutazione).note_1.length > 200 ? linguaMaster(valutazione).note_1.substr(0, 200) + '...' : linguaMaster(valutazione).note_1) : 'N/D') : (valutazione.note ? (valutazione.note.length > 200 ? valutazione.note.substr(0, 200) + '...' : valutazione.note) : 'N/D') }}
					</td>
					<td v-if="params.newscout" class="text-center">
						<span class="color-rosso ml-1"><i v-if="linguaMaster(valutazione) ? (linguaMaster(valutazione).note_1 || linguaMaster(valutazione).note_2 || linguaMaster(valutazione).note_3 || linguaMaster(valutazione).note_4 || linguaMaster(valutazione).note_5) : (valutazione.note || valutazione.note2 || valutazione.note3 || valutazione.note4 || valutazione.note5)" class="fa fa-pencil-square-o" aria-hidden="true" title="Valutazione con note"></i></span>
					</td>
					<td v-if="params.newscout && auth.isUserEnable('video_giocatori')" class="text-center" colspan="2"> <b-button v-if="auth.isUserEnable('video_giocatori') && hasVideo(valutazione)" variant="danger" size="sm" title="Play video" @click="playVideo(valutazione.id)" class="mr-1"><i class="fa fa-play" aria-hidden="true"></i>
					</b-button></td>
				</tr>
				<tr v-if="params.valutazioni_old" v-for="(valutazione, index) in params.valutazioni_old">
					<td><a :href="'#/scouting/view/valutazione/' + params.giocatore_id + '/' + valutazione.id" target="_blank">{{valutazione.created_at | formatDate}}</a> <b-img src="/assets/icons/scouting.svg" :width="20" style="border-radius:50%; margin-left:10px;" title="Archivio Scout"/> </td>
					<td :title="valutazione.utente.nomeCompleto">{{valutazione.utente.cognome}}</td>
					<td :title="valutazione.gradimento ? valutazione.gradimento.nome : ''"><b-badge v-if="valutazione.gradimento" :style="{'color':'black', background: valutazione.gradimento.colore}">{{ valutazione.gradimento.nome }}</b-badge>
					</td>
					<td class="text-center">{{valutazione.voto[0] > 0 ? valutazione.voto[0] : 'N/D'}}</td>
					<td class="text-center">{{valutazione.voto[1] > 0 ? valutazione.voto[1] : 'N/D'}}</td>
					<td v-if="params.newscout && auth.isUserEnable('video_giocatori')" class="text-center"></td>
				</tr>
				<tr v-if="params.newscout && params.valutazioni_football" v-for="(valutazione, index) in params.valutazioni_football">
					<td v-if="params.area"><a :href="'#/next/' + params.area + '/view/valutazione/football/' + params.giocatore_id + '/' + valutazione.id">{{valutazione.dal | formatDate}} - {{valutazione.al | formatDate}}</a> <b-img src="/assets/icons/football.svg" :width="20" style="border-radius:50%; margin-left:10px;" title="Football"/> </td>
					<td v-else><a :href="'#/'+ (params.youth ? params.youth + 'scout' : 'newscout') +'/view/valutazione/football/' + params.giocatore_id + '/' + valutazione.id">{{valutazione.dal | formatDate}} - {{valutazione.al | formatDate}}</a> <b-img src="/assets/icons/football.svg" :width="20" style="border-radius:50%; margin-left:10px;" title="Football"/> </td>
					<td :title="valutazione.utente.nomeCompleto">{{valutazione.utente.cognome}}</td>
					<td></td>
					<td class="text-center">{{valutazione.voto[0] > 0 ? valutazione.voto[0] : 'N/D'}}</td>
					<td class="text-center"></td>
				</tr>
			</tbody>
		</table>
	</div>

</div>
</template>

<script>
var Auth = require('../../../auth.js');
import moment from 'moment'

export default {
	data: function() {
		return {
			label_struttura: ['brevilinea', 'normolinea', 'longilinea'],
			label_piede: ['dx', 'sx', 'ad'],
			label_muscolatura: ['esile', 'normale', 'possente'],
			label_velocita: ['sufficiente', 'discreta', 'buona', 'ottima', 'insufficiente'],
			label_resistenza: ['sufficiente', 'discreta', 'buona', 'ottima', 'insufficiente'],
			label_tecnica: ['sufficiente', 'discreto', 'buono', 'ottimo', 'insufficiente'],
			label_tattica: ['sufficienti', 'discreti', 'buoni', 'ottimi', 'insufficienti'],
			label_difensive: ['sufficienti', 'discrete', 'buone', 'ottime', 'insufficienti'],
			label_offensive: ['sufficienti', 'discrete', 'buone', 'ottime', 'insufficienti'],
		}
	},
	props: {
		params: {
			type: Object,
			required: false
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
	methods: {
		selectGenericTags() {
			this.$bus.$emit('selectGenericTags');
		},

		playVideo(val_id) {
			this.$bus.$emit('playVideoVal', val_id);
		},

		hasVideo(valutazione) {
			for(var i in valutazione.partite) {
				if(valutazione.partite[i].video.length > 0) {
					return true;
				}
			}
			return false;
		},
		linguaMaster(valutazione) {
			for(var i in valutazione.lingue) {
				if(valutazione.lingue[i].master) {
					return valutazione.lingue[i];
				}
			}
			return null;
		}
	}
}
</script>
