<template id="gprove">
	<div class="gprove">
		<table class="table table-striped">
			<thead>
				<tr>
					<th title="Squadra">Squadra</th>
					<th title="Periodo">Periodo</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in params.prove" v-if="item.societa_id == 1 && item.squadra.categoria.name != 'In prova'">
					<td>{{ item.squadra.name }}</td>
					<td>{{ item.inizio | formatDate }} - {{ item.fine | formatDate }}</td>	
				</tr>
			</tbody>
		</table>
	</div>

</template>

<script>
import moment from 'moment'
export default {
props: {
    params: {
    	type: Object,
		required: false
	}
  },
  data: function() {
		return {

		}
	},
  components: {
  },
  methods: {
  },
  filters: {
  	formatDate: function (date) {
  		var m = moment(date);
		if(m.isValid()) {
			return m.format('DD/MM/YYYY'); 
		}
		return "";
		}
	},
}
</script>
