<template>
	<div>

		<label>Nome</label>
		<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>
		<b-row>
			<b-col>
				<label>Durata</label>
				<b-form-input :disabled="disabled" v-model="fase.durata" type="time"></b-form-input>
			</b-col>
			<b-col>
				<label>Durata effettiva</label>
				<b-form-input :disabled="disabled" v-model="fase.durata_reale" type="time"></b-form-input>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<label>Nome</label>
				<b-form-input v-model="fase.entity.test.nome" type="text" readonly></b-form-input>
			</b-col>
			<b-col>
				<label>Gruppo</label>
				<b-form-input v-model="fase.entity.test.gruppo" type="text" readonly></b-form-input>
			</b-col>
		</b-row>

		<b-col cols="12">
			<b-row class="mt-2">
				<b-col v-for="n in testObject">
					<label>{{ n.label }}</label>
					<div :is="typeOf(n)" :test="fase.entity" :field="n.fieldName" :options="n.option"></div>
				</b-col>
			</b-row>
		</b-col>

	</div>
</template>

<script>
import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
	faseMixin
	],
	components: {
		FaseTestString: require('@/components/layout/Sessioni/Test/FaseTestString.vue').default,
		FaseTestBoolean: require('@/components/layout/Sessioni/Test/FaseTestBoolean.vue').default,
		FaseTestSelect: require('@/components/layout/Sessioni/Test/FaseTestSelect.vue').default,
	},
	props: [ 'sessione', 'glossario', 'fase', 'disabled' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		typeOf: function(item) {
			return 'FaseTest' + _.capitalize(item.option.type);
		}
	},
	computed: {
		testObject: function() {

			var t = [];
			for(var i = 0; i < 6; i++) {
				if(this.fase.entity.test['option_value_' + (i+1)] != null) {
					
					t.push({
						label: this.fase.entity.test['label_value_' + (i+1)] ? this.fase.entity.test['label_value_' + (i+1)] : '',
						option: this.fase.entity.test['option_value_' + (i+1)],
						fieldName: 'value_' + (i+1)
					});

				}

			}

			return t;

		}
	}
}
</script>