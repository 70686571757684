<template>
	<div>
		<span><strong>{{ time | secondsInMinutes }}</strong></span>
	</div>
</template>

<script>
import moment from "moment";

export default {
	props: {
		running: {
			type: Boolean,
			default: false
		},
		resetWhenStart: {
			type: Boolean,
			default: false
		},
		resetWhenStop: {
			type: Boolean,
			default: false
		}
	},
	data: function(){
		return {
			time: 0,
			timer: null,
		}
	},
	watch: {
		running: function(newVal, oldVal) {
			if (newVal) this.startT();
			else this.stopT();
		}
	},
	mounted() {
		if (this.running) this.startT();
	},
	methods: {
		stopT: function() {
			clearInterval(this.timer);
			if (this.resetWhenStop) this.resetT();
			this.$emit('durata', this.time);
		},
		startT: function() {
			if (this.resetWhenStart) this.resetT();
			this.timer = setInterval(() => {
				this.time++;
			}, 1000);
		},
		resetT: function() {
			this.time = 0;
		}
	},
	filters: {
		secondsInMinutes: function(seconds) {
			return moment()
			.startOf("day")
			.seconds(seconds)
			.format("HH:mm:ss");
		}
	}
};
</script>
