<template id="view_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<div class="card title_page">
				<div class="card-body">
					<div class="row flex-items-xs-middle">
						<div class="col-4">
							<div class="card-title">
								<span>Valutazione Giocatore Football</span>
							</div>
						</div>
						<div class="col-8 text-right">
							<menuscout :personaId="$route.params.id"></menuscout>			
						</div>
					</div>
				</div>
			</div>	
			<div class="content_page">
				<b-row>
					<b-col cols="3">
						<div class="card" style="height:100%">
							<div style="text-align:center;">
								<b-img :src="valutazione.persona.avatar_url" :alt="valutazione.persona.cognome + ' ' + valutazione.persona.nome" :width="130"></b-img>
							</div>
							<div class="card-block">
								<div class="mb-1 font-bold text-center">{{ valutazione.persona.nome }} {{ valutazione.persona.cognome }}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.persona.last_carriera_sportiva.ruolo_ideale && valutazione.persona.last_carriera_sportiva.ruolo_ideale.length > 0 ? valutazione.persona.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</div>
								<div class="text-center font-size-sm">Classe {{ valutazione.persona.nascita_data | formatYear }}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.persona.last_carriera_sportiva.squadra ? valutazione.persona.last_carriera_sportiva.squadra.name : ''}}</div>
								<div class="text-center font-size-sm mt-1" v-if="false">
									<h5><b-badge :style="{background: valutazione.gradimento.colore}">{{ valutazione.gradimento.nome}}</b-badge></h5>
								</div>
								<div class="text-center mb-1"><b-button variant="primary" size="sm" title="Download scheda" @click="downloadScheda"><i class="fa fa-download" aria-hidden="true"></i>
								</b-button></div>
							</div>
						</div>
					</b-col>
					<b-col>
						<b-row class="mt-1">
							<b-col>
								<b-card class="p-1">
									<b-row>
										<b-col>Dal</b-col>
										<b-col class="label-value">{{ valutazione.dal | formatDate }}</b-col>
									</b-row>
									<b-row>
										<b-col>Al</b-col>
										<b-col class="label-value">{{ valutazione.al | formatDate }}</b-col>
									</b-row>
									<b-row>
										<b-col>Utente</b-col>
										<b-col class="label-value">{{ valutazione.utente.cognome }} {{ valutazione.utente.nome }}</b-col>
									</b-row>
									<b-row>
										<b-col>Voto</b-col>
										<b-col class="label-value">{{ valutazione.voto[0] }}</b-col>
									</b-row>
								</b-card>
							</b-col>
							<b-col>
								<b-card class="p-1">
									<b-row>
										<b-col>Media/N° valutazioni</b-col>
										<b-col class="label-value">{{voto_medio}} / {{num_val}}</b-col>
									</b-row>
									<b-row>
										<b-col>Elenco valutazioni</b-col>
										<b-col class="label-value"><b-pagination-nav size="sm" :number-of-pages="valutazione.persona.rosa_valutazioni.length" base-url="#" :link-gen="linkGen" v-model="currentPage" use-router/></b-col>
									</b-row>						 
								</b-card>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row class="mt-1" v-if="!auth.isUserEnable('scout_not_skills')">
					<b-col v-for="item in fields" v-if="skills[item.id]">
						<b-card :title="item[$i18n.t('lang')]" v-if="!valutazione.categoria || (valutazione.categoria && !valutazione.categoria.only_root_skill)">
							<b-list-group>
								<b-list-group-item v-for="skill in item.fields" v-if="(!skill.fields ? skills[item.id][skill.id] : voto(item.id, skill.fields, 1)) > 0">
									<b-row :style="'width:100%;' + (skill.fields ? 'cursor:pointer;' : '')">
										<b-col cols="11">
											<b-row align-v="center">
												<b-col cols="5" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													{{skill[$i18n.t('lang')]}}
												</b-col>
												<b-col cols="1" class="text-right">
													<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(skill, skill.it)">{{ skill.count_tags }}</span>
												</b-col>
												<b-col cols="1" class="text-center">
													<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="skill.count_tags" :title="skill.count_tags" @click="selectSkillTags(skill, skill.it)"></i>
												</b-col>
												<b-col cols="5" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													<b-progress :value="!skill.fields ? skills[item.id][skill.id] : voto(item.id, skill.fields, 1)" :max="max" show-value :precision="!skill.fields ? 0 : 2" :variant="'voto-' + Math.round(!skill.fields ? skills[item.id][skill.id] : voto(item.id, skill.fields, 1))"></b-progress>
												</b-col>
											</b-row>
										</b-col>
										<b-col cols="1" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
											<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
											<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
										</b-col>
									</b-row>						
									<b-collapse :id="skill.id" style="width:100%">
										<b-list-group v-if="skill.fields">
											<b-list-group-item v-for="subskill in skill.fields" v-if="(!subskill.fields ? skills[item.id][skill.id][subskill.id] : voto(item.id, subskill.fields, 2)) > 0">

												<b-row :style="'width:100%;' + (subskill.fields ? 'cursor:pointer;' : '')">
													<b-col cols="11">
														<b-row align-v="center">
															<b-col cols="5" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																{{subskill[$i18n.t('lang')]}}
															</b-col>
															<b-col cols="1" class="text-right">
																<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)">{{ subskill.count_tags }}</span>
															</b-col>
															<b-col cols="1" class="text-center">
																<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill.count_tags" :title="subskill.count_tags" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)"></i>
															</b-col>
															<b-col cols="5" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																<b-progress :value="!subskill.fields ? skills[item.id][skill.id][subskill.id] : voto(item.id, subskill.fields, 2)" :max="max" show-value :precision="!subskill.fields ? 0 : 2" :variant="'voto-' + Math.round(!subskill.fields ? skills[item.id][skill.id][subskill.id] : voto(item.id, subskill.fields, 2))"></b-progress>
															</b-col>
														</b-row>
													</b-col>
													<b-col cols="1">
														<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
														<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
													</b-col>
												</b-row>						
												<b-collapse :id="subskill.id" style="width:100%">
													<b-list-group v-if="subskill.fields">
														<b-list-group-item v-for="subskill2 in subskill.fields" v-if="skills[item.id][skill.id][subskill.id][subskill2.id] > 0">
															<b-row style="width:100%">
																<b-col cols="11">
																	<b-row align-v="center">
																		<b-col cols="5">
																			{{subskill2[$i18n.t('lang')]}}		
																		</b-col>
																		<b-col cols="1" class="text-right">
																			<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)">{{ subskill2.count_tags }}</span>
																		</b-col>
																		<b-col cols="1" class="text-center">
																			<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill2.count_tags" :title="subskill2.count_tags" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)"></i>
																		</b-col>
																		<b-col cols="5">
																			<b-progress :value="skills[item.id][skill.id][subskill.id][subskill2.id]" :max="max" show-value :variant="'voto-' + skills[item.id][skill.id][subskill.id][subskill2.id]"></b-progress>
																		</b-col>
																	</b-row>									
																</b-col>
																<b-col cols="1">
																</b-col>
															</b-row>
														</b-list-group-item>
													</b-list-group>
												</b-collapse>
											</b-list-group-item>
										</b-list-group>
									</b-collapse>
								</b-list-group-item>
							</b-list-group>
						</b-card>
						<b-card :title="item[$i18n.t('lang')]" v-if="valutazione.categoria && valutazione.categoria.only_root_skill">
							<b-list-group>
								<b-list-group-item v-for="skill in item.fields" v-if="skills[item.id][skill.id] > 0">
									<b-row style="width:100%">
										<b-col cols="12">
											<b-row align-v="center">
												<b-col cols="5">
													{{skill[$i18n.t('lang')]}}		
												</b-col>
												<b-col cols="1" class="text-right">
													<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(skill, skill.it)">{{ skill.count_tags }}</span>
												</b-col>
												<b-col cols="1" class="text-center">
													<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="skill.count_tags" :title="skill.count_tags" @click="selectSkillTags(skill, skill.it)"></i>
												</b-col>
												<b-col cols="5">
													<b-progress :value="skills[item.id][skill.id]" :max="max" show-value :variant="'voto-' + skills[item.id][skill.id]"></b-progress>
												</b-col>
											</b-row>
										</b-col>
									</b-row>						
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-col>
				</b-row>
				<b-modal id="tagsModal" size="xl" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
					<b-row v-if="skill_tags_sel">
						<b-col cols="3" style="overflow-y:auto;height:550px;">
							<div v-for="(tag, index) in videotags">
								<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag"></thumbtag>
							</div>
						</b-col>
						<b-col cols="9">
							<video
							id="videotags-wrapper"
							class="video-js vjs-default-skin vjs-16-9"
							controls
							data-setup="{}">
						</video>
						<b-pagination size="sm" align="center" :total-rows="videotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
						<div v-if="tag_sel">
							<p class="m-1">{{ count }} - {{ skillName }}</p>
							<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
							<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
							<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
							<p class="m-1">{{ tag_sel.nota }}</p>
						</div>
					</b-col>
				</b-row>
			</b-modal>
		</div>	
	</div>
	<footerapp></footerapp>

</section>
</template>

<script>

var Auth = require('@/auth.js');
import * as types from '@/store/types';
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-playlist';

export default {
	data: function() {
		return {
			valutazione: [],
			voti: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			skills: [],
			fields: [],
			currentPage: 0,
			max: 10,
			form: {
				to: '',
				cc: '',
				ccn: '',
				message: '',
				onlyThis: '',
			},
			sending: false,
			errors: [],
			skill_tags_sel: null,
			videotags: [], 
			tag_sel: null,
			count: 0,
			title: '',
			playlist: [],
		}
	},

	components: {
		headapp: 	require('@/components/layout/headapp.vue').default,
		menusub:    require('@/components/layout/menusub.vue').default,
		footerapp:  require('@/components/layout/footerapp.vue').default,
		thumbtag:   require('@/components/layout/thumbtag.vue').default,
		menuscout: require('@/components/layout/giocatore/menuscout.vue').default,
	},
	created: function () {
		this.loadData();
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
	methods: {
		loadData() {
			this.skills = [];
			this.fields = [];
			this.$http.get(this.$store.state.apiEndPoint + '/rosa/view/valutazione/' + this.$route.params.val_id).then((response) => {
				this.valutazione = response.data;

				this.fields = this.valutazione.skills_tags;
				this.skills = JSON.parse(this.valutazione.skills); 	
				var count = 1;
				for (var index in this.valutazione.persona.rosa_valutazioni) {
					if(this.valutazione.persona.rosa_valutazioni[index].id == this.valutazione.id) {
						this.currentPage = (count);
						break;
					}
					count++;
				}
			});
		},
		linkGen (pageNum) {
			return {
				path: '/next/' + this.area + '/view/valutazione/football/' + this.valutazione.persona_id + '/' + this.valutazione.persona.rosa_valutazioni[pageNum - 1].id
			}
		},
		voto(cat_id, skill_fields, level) {
			var voto = 0;
			var count = 0;
			var voto2 = 0;
			var count2 = 0;
			var arrayVoto = [];
			var arrayCount = [];
			var cat_skills = this.skills[cat_id];
			for(var i in skill_fields) {
				var id = skill_fields[i].id;
				for(var item in cat_skills) {
					var item2 = cat_skills[item];
					if(item2 instanceof Object) {
						for(var item3 in item2) {
							var item4 = item2[item3];
							if(level == 1) {
								if(item3 == id) {
									if(item4 instanceof Object) {
										voto2 = 0;
										count2 = 0;
										for(var item5 in item4) {
											if(item4[item5] > 0) {
												voto2+= item4[item5];
												count2++;
											}
										}
										arrayVoto.push(voto2);
										arrayCount.push(count2);
									} else if(item4 > 0) {
										voto += item4;
										count++;
									}
									break;
								}
							} else if(level == 2) {
								if(item4 instanceof Object) {
									for(var item5 in item4) {
										if(item5 == id && item4[item5] > 0) {
											voto+= item4[item5];
											count++;
											break;
										}
									}
								}
							}
						}        		
					} 
				}
			}     
			if(arrayVoto.length > 0) {
				var votoTot = 0; 
				var countTot = 0;
				if(voto > 0) {
					votoTot = voto/count;
					countTot++;
				}
				for(var index in arrayVoto) {
					if(arrayVoto[index] > 0) {
						votoTot += arrayVoto[index]/arrayCount[index];
						countTot++;
					}            
				}
				if(votoTot > 0) {
					voto = votoTot/countTot;
				}
			} else if(voto > 0) {
				voto = voto/count;
			} 
			return voto;
		},
		downloadScheda() {
			window.location = this.$store.state.apiEndPoint + '/football/pdf/valutazione/' + this.valutazione.id + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		selectSkillTags(skill_tags, title) {
			this.title = title;	
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields && !this.valutazione.categoria.only_root_skill) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.tagsModal.show();
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1); 
		},
		selectPageTag(page) {
			this.tag_sel = this.videotags[page - 1]; 
			this.$video_tags.playlist.currentItem(page - 1);    
		},
		autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.$video_tags.dispose();
			this.$video_tags = null;
			this.tag_sel = null;
		},
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		auth: function() {
			return Auth;
		},
		voto_medio: function() {

			var count = 0;
			var voto = 0;
			for (var index in this.valutazione.persona.rosa_valutazioni) {
				if(this.valutazione.persona.rosa_valutazioni[index].voto[0] > 0) {
					voto += this.valutazione.persona.rosa_valutazioni[index].voto[0];
					count++;
				}
			}
			return Math.round((voto/count) * 100) / 100;
		},
		num_val: function() {

			var count = 0;
			for (var index in this.valutazione.persona.rosa_valutazioni) {
				if(this.valutazione.persona.rosa_valutazioni[index].voto[0] > 0) {
					count++;
				}
			}
			return count;
		},
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},
	},
	watch: {
		'$route': function () {
			this.loadData();
		},
	},
	beforeDestroy() {
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},
}

</script>
