<template>
    <div  @click.prevent="rm()" class="alert alert-success" :class="{'alert-success': message.status == 1, 'alert-danger': message.status == 0}" role="alert">
        {{ $t(message.msg) }}
        <small><i class="float-right fa fa-times"></i></small>
    </div>
</template>

<script>
import * as types from '../../store/types'

export default {
    name: 'alert',
    props: ['message'],
    created: function() {
        var vm = this;
        setTimeout(function() {
            vm.rm();
        }, 5000);
    },
    methods: {
        rm: function() {
            this.$store.commit(types.ALERTS_REMOVE, this.message);
        }
    }
}
</script>