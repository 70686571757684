<template id="figurinastaff" >
  <div class="figurinagiocatore h-100 pb-2" :id="'staff_'+staff.id">
    <div class="card mb-2 h-100">
      <div class="row">
        <div class="col-12 text-center">
          <router-link :to="{ name: 'football_staff_scheda', params: { id: staff.id }}"><img v-bind:src="avatar" height="200"></router-link>
        </div>
      </div>
      
      <div class="card-body p-2">
        <router-link :to="{ name: 'football_staff_scheda', params: { id: staff.id }}" style="text-decoration:none;color:#000000;">
          <div class="font-bold text-center">{{ staff.cognome }} {{ staff.nome }}</div>
        </router-link>
        <div class="mb-1 text-center font-size-xs color-rosso">{{ staff.last_carriera_sportiva && staff.last_carriera_sportiva.ruoli && staff.last_carriera_sportiva.ruoli.length > 0 ? staff.last_carriera_sportiva.ruoli[0].nome : '' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {

    data: function(){
      return {
        render_player: true,
      }
    },

    props:{
        staff: {
            type: Object,
            required: true
        }
    },

    computed: {

        avatar: function() {
          return this.staff.avatar_url;
        }

    },
}
</script>
