<template id="rosa">
	<section class="rosa">
		
		<b-sidebar right width="60vw" :visible="selectedInjuryIdUi" @hidden="selectedInjuryIdUi = false" title="Infortunio" shadow>
			<div v-if="selectedInjuryIdUi" class="px-2">
				<Player-Card :player="selectedInjuryIdPersonObj"></Player-Card>
				<Single-Smart-Injury :injury="selectedInjuryIdObj"></Single-Smart-Injury>
			</div>
		</b-sidebar>

		<b-sidebar right width="80vw" :visible="selectedPlayerUi" @hidden="selectedPlayerUi = false" title="Player" shadow>
			<div v-if="selectedPlayerUi" class="px-2">
				<PlayerDetailInjuries :player="selectedPlayerReal.player" :injuries="selectedPlayerReal.injuries" :dates="dataZoom"></PlayerDetailInjuries>
			</div>
		</b-sidebar>

		<b-sidebar :visible="viewHelp.show" @hidden="viewHelp.show = false" left width="50vw">
			<div class="p-2">
				<b-table-simple striped small>
					<b-thead>
						<b-tr>
							<b-th>Giocatore</b-th>
							<b-th><a href="javascript:;" @click.prevent="viewHelp.key != 'infortuni' ? viewHelp.key = 'infortuni' : (viewHelp.order == 'desc' ? viewHelp.order = 'asc' : viewHelp.order = 'desc')">Infortuni</a></b-th>
							<b-th><a href="javascript:;" @click.prevent="viewHelp.key != 'durata' ? viewHelp.key = 'durata' : (viewHelp.order == 'desc' ? viewHelp.order = 'asc' : viewHelp.order = 'desc')">Durata Complessiva</a></b-th>
							<b-th><a href="javascript:;" @click.prevent="viewHelp.key != 'attivo' ? viewHelp.key = 'attivo' : (viewHelp.order == 'desc' ? viewHelp.order = 'asc' : viewHelp.order = 'desc')">Attivo</a></b-th>
							<b-th>Carriera</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="row in dataSideBar">
							<b-td>{{ row.surname }} {{ row.nome[0] }}.</b-td>
							<b-td><span v-if="row.infortuni > 0">{{ row.infortuni }}</span><span class="text-muted small" v-else>0</span></b-td>
							<b-td><span v-if="row.durata">{{ row.durata }}<small class="text-muted" v-if="row.intervallo">/{{ row.intervallo }}</small></span></b-td>
							<b-td><span v-if="row.attivo">✔︎</span></b-td>
							<b-td>
								<div v-for="a in row.carriera" class="small text-muted">{{a}}</div>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-sidebar>

		<b-card>
			<div class="d-flex justify-content-between">
				<h4 class="card-title" v-if="!isWidget || isFullscreen">
					<injury-filter-title :start="search" v-if="guideline" :guideline="guideline"></injury-filter-title>
				</h4>
				<div v-if="!isWidget || isFullscreen">
					<b-button-group>
						<b-button variant="outline-secondary" size="sm" @click.prevent="viewHelp.show = !viewHelp.show">Mostra Tabella</b-button>
						<b-button variant="outline-secondary" size="sm" @click.prevent="ux.showZero = !ux.showZero">{{ ux.showZero == true ? 'Nascondi' : 'Mostra' }} Giocatori a 0</b-button>
					</b-button-group>
				</div>
			</div>
			
			<div class="ggg" ref="graph" :style="'height: '+ (hs+50 + ((Math.ceil(filters.length/2)) * 18)) +'px; background: white;'"></div>
			
			<hr v-if="!isWidget" />

			<div style="position: relative; z-index: 0" v-if="!isWidget">
				<b-nav tabs>
					<b-nav-item @click.prevent="ux.tab = 'filtering'" :active="ux.tab == 'filtering'">Filtering</b-nav-item>
					<b-nav-item @click.prevent="ux.tab = 'visualization'" :active="ux.tab == 'visualization'">Visualization <span v-if="filters.length > 0" class="small text-muted">({{filters.length}})</span></b-nav-item>
					<div class="flex-grow-1 text-right">
						<b-button class="push-right" variant="outline-success" size="xs" @click.prevent="genPng">
							<em class="fa fa-file-image-o"></em>
						</b-button>
					</div>
				</b-nav>


				<div class="bg-white p-2 mb-2" style="border-left: 1px solid #dee2e6; border-bottom: 1px solid #dee2e6; border-right: 1px solid #dee2e6; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px">

					<div v-show="ux.tab == 'filtering'">

						<b-row>
							<b-col cols="3">
								<b-form-group label="Players">
									<player-picker :max="2" :options="players" value="id" @submit="doSearch" @change="search.person_ids = $event" :base="search.person_ids"></player-picker>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="From">
									<b-form-datepicker size="sm" v-model="search.from"></b-form-datepicker>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="To">
									<b-form-datepicker size="sm" v-model="search.to"></b-form-datepicker>
								</b-form-group>
							</b-col>
							<b-col cols="1">
								<b-button block size="sm" :variant="!ux.wait ? 'success' : 'warning'" :disabled="ux.wait" @click.prevent="doSearch">{{ !ux.wait ? 'Search' : 'Wait...' }}</b-button>
								<b-button block size="xs" variant="outline-secondary" @click.prevent="doReset">Reset</b-button>
							</b-col>
						</b-row>

						<injury-filter-v2 v-if="guideline" :autoemit="true" @filters="applyFilterV2($event, search, doSearch)" :guide="fieldMapGuide" :results="results"></injury-filter-v2>

					</div>
					<div v-if="ux.tab == 'visualization'">
						<div v-for="filter, uuu in filters" class="my-2 pb-3 p-2" style="margin-left: -0.5rem; margin-right: -0.5rem; border-bottom: 1px solid #dee2e6">
							<b-row v-if="ux.stylesOpened.indexOf(uuu) === -1">
								<b-col cols="12">
									<b-card class="shrink">
										<div class="d-flex justify-content-between">
											<div class="d-flex justify-content-between align-items-center" style="cursor: pointer" @click.prevent="ux.stylesOpened.push(uuu)">
												<div class="mr-1">
													<div style="width: 20px; height: 20px;" :style="'border: '+filter.style.borderWidth+'px ' + (filter.style.borderType || 'solid') + ' ' + (filter.style.borderColor || 'transparent') +'; background-color: ' + filter.style.color"></div>
												</div>
												<div>{{filter.style.name}}</div>
											</div>
											<div>
												<a href="javascript:;" @click.prevent="moveStyle(uuu, -1)">Su</a>, 
												<a href="javascript:;" @click.prevent="moveStyle(uuu, +1)">Giù</a>, 
												<a href="javascript:;" @click.prevent="moveStyle(uuu, 0)">Inizio</a>, 
												<a href="javascript:;" @click.prevent="moveStyle(uuu, null)">Fine</a>
											</div>
											<div>
												<a href="javascript:;" @click.prevent="filters.splice(uuu, 1)" class="small">Rimuovi Stile</a>
											</div>
										</div>
									</b-card>
								</b-col>
							</b-row>
							<b-row v-else>
								<b-col cols="2">
									<b-card class="shrink">
										<template #header>
											<div class="d-flex justify-content-between">
												<div>Stile</div>
												<div>
													<a href="javascript:;" @click.prevent="moveStyle(uuu, -1)">Su</a>, 
													<a href="javascript:;" @click.prevent="moveStyle(uuu, +1)">Giù</a>, 
													<a href="javascript:;" @click.prevent="moveStyle(uuu, 0)">Inizio</a>, 
													<a href="javascript:;" @click.prevent="moveStyle(uuu, null)">Fine</a>
												</div>
											</div>
										</template>
										
										<b-form-group label="Nome">
											<b-input size="sm" type="text" v-model="filter.style.name"></b-input>
										</b-form-group>

										<div>

											<b-form-group label="Sfondo">
												<input type="color" style="width: 100%" v-model="filter.style.color">
											</b-form-group>
											
											<b-form-group label="Forma">
												<b-select size="sm" v-model="filter.style.round">
													<b-select-option :value="[0,0,0,0]">Squadrato</b-select-option>
													<b-select-option :value="[20,20,20,20]">Tondeggiante</b-select-option>
													<b-select-option :value="[20,0,0,20]">Inizio Curvo</b-select-option>
													<b-select-option :value="[0,20,20,0]">Fine Curvo</b-select-option>
												</b-select>
											</b-form-group>
											

											<b-row>
												<b-col>
													<b-form-group label="Bordo">
														<b-form-select size="sm" v-model="filter.style.borderType">
															<b-form-select-option :value="null">Nessuno</b-form-select-option>
															<b-form-select-option value="solid">Solido</b-form-select-option>
															<b-form-select-option value="dashed">Tratteggiato</b-form-select-option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col v-if="filter.style.borderType">
													<b-form-group label="Spessore Bordo">
														<b-select size="sm" v-model="filter.style.borderWidth">
															<b-select-option :value="1">1</b-select-option>
															<b-select-option :value="2">2</b-select-option>
															<b-select-option :value="3">3</b-select-option>
															<b-select-option :value="4">4</b-select-option>
														</b-select>
													</b-form-group>
												</b-col>
											</b-row>

											<b-form-group label="Colore Bordo" v-if="filter.style.borderType">
												<input type="color" style="width: 100%" v-model="filter.style.borderColor">
											</b-form-group>

										</div>

									</b-card>
									<a @click.prevent="ux.stylesOpened.splice(ux.stylesOpened.indexOf(uuu), 1)" href="">Collassa Filtro</a>
								</b-col>
								<b-col cols="10">

									<injury-filter-v2 :trim="10" v-if="guideline" :filter="filter" :autoemit="true" @filters="applyFilterV3($event, filter.filter, false)" :guide="fieldMapGuide" :results="results"></injury-filter-v2>

									<div class="text-right mt-1">
										<a href="javascript:;" @click.prevent="filters.splice(uuu, 1);" class="small">Rimuovi Stile</a>
									</div>
								</b-col>
							</b-row>
						</div>

						<b-button block variant="outline-success" @click.prevent="addFilter">Aggiungi Stile</b-button>

					</div>

				</div>
			</div>
		</b-card>
		<a ref="button_helper"></a>
	</section>

</template>

<style type="text/css">
.shrink .card-header {
	padding: 0.5rem !important;
}

.shrink .card-body {
	padding: 0.5rem !important;
}
</style>

<script>

import pageBase from '@/mixins/pageBase.js'
import moment from 'moment'
import PlayerPicker from '@/components/layout/Football/PlayerPicker.vue';
import InjuryFilter from '@/components/layout/Medical/InjuryFilter.vue';
import InjuryFilterV2 from '@/components/layout/Medical/InjuryFilterV2.vue';
import InjuryFilterTitle from '@/components/layout/Medical/InjuryFilterTitle.vue';
import PlayerDetailInjuries from '@/components/layout/Medical/SideBarPlayerInjuries.vue';
import singleSmartInjury from '@/components/layout/Medical/SingleInjurySmart.vue'
import PlayerCard from '@/components/layout/giocatore/PlayerCard.vue'


export default {
	mixins: [ pageBase ],
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh'],
	components: {
		PlayerDetailInjuries,
		PlayerPicker: PlayerPicker,
		InjuryFilter,
		InjuryFilterV2,
		InjuryFilterTitle,
		'Player-Card': PlayerCard,
		'Single-Smart-Injury': singleSmartInjury
	},
	created: function() {
		this.fetchGuideline();
	},
	data: function() {
		return {
			viewHelp: {
				show: false,
				key: 'durata',
				order: 'desc'
			},
			chart: null,
			guideline: null,
			selectedPlayer: false,
			selectedPlayerUi: false,
			selectedInjuryId: false,
			selectedInjuryIdUi: false,
			manifest: null,
			dataZoom: {
				from: 0,
				to: 0
			},
			ux: {
				stylesOpened: [],
				showZero: this.isWidget ? false : true,
				wait: false,
				styleHelperView: true,
				tab: 'filtering',
				season_id: 9
			},
			players: [],
			results: [],
			search: {
				team_id: this.teamId,
				person_ids: [],
				from: moment().add(-12, 'months').format('YYYY-MM-DD HH:mm'),
				to: moment().format('YYYY-MM-DD HH:mm'),
				region_id: null,
				classification_id: null,
				pathology_id: null,
				reinjury: null,
				cause: null,
				contact: null,
				mechanism: null,
				event: null,
				severity: null
			},
			filters: [
				]
		}
	},
	watch: {
		'search.from': function() {
			this.dataZoom = {
				from: moment(this.search.from).format('X'),
				to: moment(this.search.to).format('X')
			}
		},
		'search.to': function() {
			this.dataZoom = {
				from: moment(this.search.from).format('X'),
				to: moment(this.search.to).format('X')
			}
		},
		filters: {
			deep: true,
			handler: function(a,b) {
				_.delay(function() { this.generateGraph(); }.bind(this), 150);
			}
		},
		'ux.showZero': function() {
			_.delay(function() { this.generateGraph(); }.bind(this), 100);
		},
		rosaTeamId: {
			immediate: true,
			handler: function() {
				this.search.teamId = this.rosaTeamId;
				this.doSearch();
			}
		},
		contentHeight() {
			_.delay(function() {
				this.generateGraph();
			}.bind(this), 150);
		},
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		}
	},
	computed: {
		legendItems: function() {

			var rightOffset = ($(this.$refs.graph).width() - 125) / 2;

			return _.map(this.filters, function(e, a) {

				var row = Math.ceil(this.filters.length/2) - Math.floor(a/2) - 1;
				var right = a%2 == 1;

				var sBk = _.cloneDeep(e.style);
				sBk.fill = sBk.color;
				sBk.stroke = sBk.borderColor;

				return [
				{
					type: 'rect',
					left: 125 + (right ? rightOffset : 0),
					bottom: row * 18,
					style: sBk,
					shape: {
						width: 15,
						height: 15
					}
				},
				{
					type: 'text',
					left: 125 + (right ? rightOffset : 0) + 18,
					bottom: row * 18,
					style: {
						text: e.style.name,
						fontSize: 13,
						lineDash: [0, 200],
						lineDashOffset: 0,
						fill: '#000',
						stroke: '#000',
						lineWidth: 0
					}
				},
				]
			}.bind(this));

			return this.filters;
			return 'Hello World'
		},
		legendDesigner: function() {

			var itms = this.legendItems;
			if(itms.length == 0) return { elements: [] }

				return {
					elements: _.flatten(itms)
				}

			},
			dataSideBar: function() {

				if(!this.players || !this.results) return false;

				var minInj = _.minBy(this.results, function(a) {
					return moment(a.data);
				});

				if(!minInj) return false;

				var veroInizioIntervallo = minInj.data;

				var results = _.map(this.players, function(e) {

					var start_intervallo = moment(this.search.from).format('X');
					var end_intervallo = moment(this.search.to).format('X');

					var carriere = _.filter(_.sortBy(e.carriere_sportive, function(u) { return moment(u.inizio).format('X'); }), function(b) {

						if(b.squadra_id != this.rosaTeamId) return false;

						var intStart = moment(this.search.from).format('X');
						var intEnd = moment(this.search.to).format('X');

						var s = moment(b.inizio).format('X');
						if(b.fine)
							var e = moment(b.fine).format('X');
						else
							var e = moment().format('X');

						if(s > intStart && s < intEnd) return true;
						if(s < intStart && e > intStart) return true;

						return false;

					}.bind(this));


					var carrieraMinima = _.minBy(carriere, function(a) {
						return moment(a.inizio).format('X');
					});

					var inizio = moment(veroInizioIntervallo).format('YYYY-MM-DD');


					if(this.grouped[e.id]) {
						var min = _.minBy(this.grouped[e.id], function(b) { return moment(b.data).format('X'); });
						var max = _.maxBy(this.grouped[e.id], function(b) { return b.return ? moment(b.return).format('X') : moment().format('X'); });
						if(min)
							start_intervallo = moment(min.data).format('X');
						if(max)
							end_intervallo = moment(max.return).format('X');
					}

					return {
						player: e.id,
						surname: e.cognome,
						nome: e.nome,
						intervallo: _.floor( (moment(this.search.to).format('X') - moment(this.search.from).format('X')) /60/60/24), 
						attivo: _.filter(this.grouped[e.id], { return: null }).length > 0 ? 1 : 0,
						infortuni: this.grouped[e.id] ? this.grouped[e.id].length : 0,
						durata: _.round(_.sum(_.map(this.grouped[e.id], function(u) {

							if(!u.return) {
								var durata = moment().format('X') - moment(u.data).format('X');
							} else {
								var durata = moment(u.return).format('X') - moment(u.data).format('X');
							}

							return durata / 60 / 60 / 24;

						})),2),
						carriera: _.map(carriere, function(e) {
							return moment(e.inizio).format('DD/MM/YY') + (e.fine ? ' - ' + moment(e.fine).format('DD/MM/YY') : ' •');
						})
					};
				}.bind(this));

				return _.orderBy(results, [ this.viewHelp.key ], [ this.viewHelp.order ]);

				return results;

				return this.players;

			},
			hs: function() {
				if(this.ux.showZero)
					var h = this.players.length * 24;
				else
					var h = _.size(this.grouped) * 24;

				if(h < 200)
					return 200;

				if(this.contentHeight) {
					if(this.isFullscreen) {
						return this.contentHeight - 100;
					}
					else {
						return this.contentHeight - 10;
					} 
				}

				return h;
			},
			selectedInjuryIdPersonObj: function() {
				return _.find(this.players, { id: this.selectedInjuryIdObj.person_id });
			},
			selectedInjuryIdObj: function() {
				return _.find(this.results, { id: this.selectedInjuryId });
			},
			selectedPlayerReal: function() {

				var player = _.find(this.players, { id: parseInt(this.selectedPlayer) });
				var injuries = _.filter(this.results, function(a) {

					var s = moment(a.data).format('X');
					var t = a.return ? moment(a.return).format('X') : moment(this.search.to).format('X');

					if( 
						(s <= this.dataZoom.from && t >= this.dataZoom.from) ||
						(s >= this.dataZoom.from && t <= this.dataZoom.to) || 
						(s >= this.dataZoom.from && s <= this.dataZoom.to && t >= this.dataZoom.to) ||
					(s <= this.dataZoom.from && t >= this.dataZoom.to) /*||
					(s <= this.dataZoom.from && t <= this.dataZoom.to) || 
					(s >= this.dataZoom.from && s <= this.dataZoom.to && t >= this.dataZoom.to) ||
					(s <= this.dataZoom.from && t >= this.dataZoom.from)*/
						) {

					} else {
						return false;
					}

					return a.person_id == player.id;

				}.bind(this));

				return {
					injuries: injuries,
					player
				}

				return _.find(this.players, { id: parseInt(this.selectedPlayer) });
			},
			fieldMapGuide: function() {

				var classifications = _.map(_.uniq(_.map(_.map(_.flatten(_.map(this.guideline.regions, 'classifications')), function(a) { delete a.pivot; return a; }), function(a) {
					return JSON.stringify(a);
				})), function(b) {
					return JSON.parse(b);
				});

				return [
					{ arr: true, label: 'Regions', key: 'region_id', name: 'region_id', guide: this.guideline.regions, guideKey: 'name', remap: 'injury_region_id' },
					{ arr: true, label: 'Classifications', key: 'classification_id', name: 'classification_id', guide: classifications, guideKey: 'name', remap: 'injury_classification_id' },
					{ arr: true, label: 'Pathology', key: 'pathology_id', name: 'pathology_id', guide: this.guideline.pathologies, guideKey: 'name', remap: false },
					{ arr: true, label: 'Second Injury', key: 'reinjury', name: 'reinjury', guide: [ { id: 0, name: 'No' }, { id: 1, name: 'Reinjury' }, { id: 2, name: 'Subsequent new injury' }, { id: 3, name: 'Subsequent local injury' }, { id: 4, name: 'Exacerbation' } ], guideKey: 'name', remap: false },
					{ arr: true, label: 'Cause', key: 'cause', name: 'cause', guide: null, remap: false },
					{ arr: false, label: 'Mode', key: 'contact', name: 'contact', guide: [ { id: 1, name: 'Contact' }, { id: 0, name: 'Not Contact' }, { id: 'null', name: 'N/A' } ], guideKey: 'name', remap: false },
					{ arr: true, label: 'Mechanism', key: 'mechanism', name: 'mechanism', guide: this.guideline.mechanisms, guideKey: 'name', remap: 'mechanismInjuries_filtering' },
					{ arr: true, label: 'Event', key: 'event', name: 'event', guide: '', remap: false },
					{ arr: true, label: 'Severity', key: 'severity', name: 'severity', guide: [ { id: 1, name: 'Minimal (1-3)' }, { id: 2, name: 'Mild (4-7)' }, { id: 3, name: 'Moderate (8-28)' }, { id: 4, name: 'Severe (>28)' } ], guideKey: 'name', remap: false }
					];

			},
			styleHelper: function() {

				var keys = this.fieldMapGuide;

				var collected = {};
				_.each(keys, function(a) {

					collected[a.key] = _.map(_.groupBy(this.results, a.remap || a.key), function(b, c) { 
						var name = null;
						if(a.guide && a.guide != null) {
							var f = _.find(a.guide, { id: parseInt(c) });
							if(!f)
								f = _.find(a.guide, { id: c });

							if(f)
								name = f[a.guideKey];
						} else
						name = c;

						return {
							id: c,
							name: name, 
							amount: b.length
						}; 
					});

				}.bind(this));

				collected = _.map(collected, function(values, k) {
					return {
						label: _.find(keys, { key: k }).label,
						field: k,
						mapped: k,
						choices: _.orderBy(values, function(a) { return a.amount }, ['desc'])
					}
				});

				return collected;
			},
			mechanisms: function() {
				return _.map(this.results, 'tttt');
				return _.keys(this.results[0]);
			},
			_: function() {
				return _;
			},
			grouped: function() {
				return _.groupBy(this.results, 'person_id');
			}
		},
		methods: {
			refreshData: function() {
				this.doSearch();
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			},
			applyFilterV2: function(what, where, callback) {
				_.extend(where, what);
				if(callback)
					callback();
			},
			applyFilterV3: function(what, where, callback) {
				var e = {};

				_.each(what, function(b,k) {

					var mast = _.find(this.fieldMapGuide, { name: k });

					if( _.isArray(b) && b.length > 0 ) {

						if(mast.arr) {
							e[k] = _.map(b, function(e) { return parseInt(e); });
						} else
						e[k] = b;

					} else if(b) {
						e[k] = mast.arr ? parseInt(b) : b;
						if(_.isArray(e[k]) && e[k].length == 0)
							e[k] = null;
					}
				}.bind(this));

				_.extend(where, e);
				if(callback)
					callback();
			},
			moveStyle: function(ind, w) {

				if(w == 0 && ind == 0) {
					return false;
				} else if(w == null && ind == this.filters.length-1) {
					return false;
				} else if(w == 1 && ind == this.filters.length-1) {
					return false;
				} else if(w == -1 && ind == 0) {
					return false;
				}

				var iii = [];
				var base = this.filters[ind];
				var ready = _.cloneDeep(this.filters);
				ready.splice(ind, 1);

				if(w == 0)
					var newInd = 0;
				else if(w == null)
					var newInd = this.filters.length - 1;
				else
					var newInd = ind + w;

				for(var i = 0; i < this.filters.length; i++) {
					if(i == newInd) {
						iii.push(base);
					} else {
						iii.push(_.first(ready));
						ready.splice(0, 1);
					}
				}

				this.filters = iii;

				return false;

			},
			genPng: function() {

				var destCtx = $('canvas', this.$refs.graph)[0].getContext('2d');

				this.$refs.button_helper.href = $('canvas', this.$refs.graph)[0].toDataURL("image/png");
				this.$refs.button_helper.download = _.camelCase('aaaa') + ".png";
				this.$refs.button_helper.click();

			},
			doReset: function() {

				this.search.region_id = null;
				this.search.classification_id = null;
				this.search.pathology_id = null;
				this.search.reinjury = null;
				this.search.cause = null;
				this.search.contact = null;
				this.search.mechanism = null;
				this.search.event = null;
				this.search.severity = null;

				this.filters = [];

			},
			copyStyle: function(style) {
				this.filters.push(_.cloneDeep(style));
			},
			addStyleMassive: function(b) {
				_.each(b.choices, function(u) {
					this.addStyle(u, b);
				}.bind(this));
			},
			addStyle: function(k,b) {

				var master = _.find(this.fieldMapGuide, { key: b.field });

				if(master.guide && k.id != 'null') {
					var kkk = parseInt(k.id);
				} else {
					var kkk = k.id;
				}

				if(this.ux.tab == 'filtering') {

					if(master.arr == true) {
						if(this.search[master.key] == null) {
							this.search[master.key] = [ kkk ];
						} else {
							this.search[master.key].push(kkk);
						}
					} else {
						this.search[master.key] = kkk;
					}

					this.doSearch();
					return false;

				}

				var filterBase = { 
					region_id: null,
					classification_id: null,
					pathology_id: null,
					reinjury: null,
					cause: null,
					contact: null,
					mechanism: null,
					event: null,
					severity: null
				};

				if(master.arr == true && kkk)
					filterBase[master.key] = [ kkk ];
				else
					filterBase[master.key] = kkk;


				this.filters.push({
					filter: filterBase,
					style: {
						name: k.name,
						color: '#'+(Math.floor(Math.random()*16777215).toString(16)),
						borderType: null,
						borderColor: '#000000',
						borderWidth: 1,
						round: [0,0,0,0]
					}
				})
			},
			fetchGuideline: function() {

				this.$http.get(this.$store.state.apiEndPoint + '/dashboards/medical/injury-guideline').then(function(data) {
					this.guideline = data.data;
				});

			},
			addFilter: function() {

				this.filters.push({
					filter: { 
						region_id: null,
						classification_id: null,
						pathology_id: null,
						reinjury: null,
						cause: null,
						contact: null,
						mechanism: null,
						event: null,
						severity: null
					},
					style: {
						name: 'Filtro #' + (this.filters.length+1),
						color: '#'+(Math.floor(Math.random()*16777215).toString(16)),
						borderType: null,
						borderColor: '#000000',
						borderWidth: 1,
						round: [0,0,0,0]
					}
				});

				this.ux.stylesOpened.push(this.filters.length-1)

			},
			calculateStyle: function(item) {

				var keyRemap = {
					region_id: 'injury_region_id',
					mechanism: 'mechanismInjuries_filtering',
					classification_id: 'injury_classification_id'
				};

				var validStyles = _.filter(this.filters, function(i) {

					var valid = true;

					_.each(i.filter, function(v, k) {

						if(v == null || isNaN(v)) return true;

						if(v == 'null')
							v = null;

						var rK = k;
						if(keyRemap[k])
							rK = keyRemap[k];

						if(_.isArray(v)) {
							if(v.indexOf(item[rK]) === -1)
								valid = false;
						} else if(item[k] != v)
						valid = false;

					});

					return valid;

				});

				if(validStyles && validStyles.length) {
					var style = _.first(validStyles);

					if(style.style.borderType == null) {
						style.style.borderColor = null;
					} else if(style.style.borderColor == null)
					style.style.borderColor = '#000000';

					return style.style;
				} else {
					return {
						color: 'gray',
						borderWidth: 1
					}
				}


			},
			doSearch: function() {
				this.ux.wait = true;
				this.$http.get(this.$store.state.apiEndPoint + '/atlas/persona/infortuni', {
					params: this.search
				}).then(function(data) {
					this.ux.wait = false;
					this.results = data.data.results;
					this.players = data.data.players;
					console.log('Change Players', data.data.players)
					_.delay(function() { this.generateGraph(); }.bind(this), 100);
				});
			},
			unselectAll: function() {
				this.search.person_ids = [];
			},
			selectAll: function() {
				this.search.person_ids = _.map(this.players, 'id');
			},
			fetchPlayers: function() {
				this.$http.get(this.$store.state.apiEndPoint + '/rosa/get/players?team_id=' + this.rosaTeamId + '&season_id=' + this.ux.season_id + '&tipo_partita=0').then(function(data) {
					this.players = data.data.players;
					this.selectAll();
					this.doSearch();
				});
			},
			generateGraph: function() {

				var s = this;
				var pp = _.cloneDeep(this.grouped);

				if(this.ux.showZero == true) {
					_.each(this.players, function(b) {
						if(pp[b.id] == undefined) {
							pp[b.id] = [];
						}
					}.bind(this));
				}

				var chartDom = this.$refs.graph;

				if(this.chart) {
					var myChart = this.chart;
					this.chart.resize();
				} else {

					this.dataZoom = {
						from: moment(this.search.from).format('X'),
						to: moment(this.search.to).format('X')
					}

					var myChart = echarts.init(chartDom);
					window.onresize = function() {
						myChart.resize();
					};

					this.chart = myChart;

				}

				myChart.on('click', 'series.custom', function(info) {
					this.selectedInjuryId = info.data.value[6];
					this.selectedInjuryIdUi = true;
				}.bind(this));

				myChart.on('click', 'yAxis.category', function (info) {
					this.selectedPlayer = _.keys(pp)[info.dataIndex];
					this.selectedPlayerUi = true;
				}.bind(this));

				myChart.on('dataZoom', function() {
					var option = myChart.getOption();
					this.dataZoom.from = option.dataZoom[0].startValue;
					this.dataZoom.to = option.dataZoom[0].endValue;
				}.bind(this));

				var option;
				var data = [];

				var startTime = parseInt(moment(s.search.from).format('X'));
				var endTime = parseInt(moment(s.search.to).format('X'));
			/*_.min(_.map(this.results, function(a) {
				return parseInt(moment(a.data).format('X'));
			}));*/

				var i = 0;

				_.each(pp, function(injuries, id) {

					_.each(injuries, function(injury) {

						var pers = _.find(this.players, { id: injury.person_id });
						var s = moment(injury.data);
						var e = injury.return ? moment(injury.return) : moment();

					var d = moment.duration(e.diff(s)).asSeconds();// * 1000;

					var style = this.calculateStyle(injury);

					data.push({
						name: injury.pathology_name + ' ' + injury.reinjury,
						value: [ 
							i, 
							parseInt(s.format('X')), 
							parseInt(e.format('X')), 
							d, 
							injury.reinjury, 
							injury.return, 
							injury.id, 
							JSON.stringify(style), 
							pers.cognome + ' ' + pers.nome
							],
						itemStyle: {
							normal: style
						},
						w: style
					});

				}.bind(this));
					i++;

				}.bind(this));

				function renderItem(params, api) {

					var categoryIndex = api.value(0);

					if(api.value(1) < moment(s.search.from).format('X'))
						var start = api.coord([ moment(s.search.from).format('X'), categoryIndex ]);
					else 
					var start = api.coord([ api.value(1), categoryIndex ]);

					var end = api.coord([ api.value(2), categoryIndex ]);

					var height = api.size([ 0, 1 ])[1] * 0.6;

				//console.log('start', api.value(1));
				//console.log(moment(s.search.from).format('X'));


					var fromBox = {
						x: start[0],
						y: start[1] - height / 2,
						width: end[0] - start[0],
						height: height
					};

					var toBox = {
						x: params.coordSys.x,
						y: params.coordSys.y,
						width: params.coordSys.width,
						height: params.coordSys.height
					};

					var rs = echarts.graphic.clipRectByRect(fromBox, toBox);

					var styleThing = JSON.parse(api.value(7));
					if(styleThing.round && rs)
						rs.r = styleThing.round;

					return (
					{
						type: 'rect',
						transition: ['shape'],
						shape: rs,
						style: api.style()
					}
					);

				}

				var yData = _.map(pp, function(a,k) { 
					var p = _.find(this.players, { id: parseInt(k) });
					return p.cognome + ' ' + p.nome[0] + '.';
				}.bind(this));


				option = {
					graphic: this.legendDesigner, 
					clickable: true,
					tooltip: {
						formatter: function (params,b) {

							var isLive = params.value[5] == null;

							return params.marker + params.value[8] + '<br /><br />' + params.name + 
							'<br />Second Injury: <strong>' + ['No', 'Reinjury', 'Subsequent new injury', 'Subsequent local injury', 'Exacerbation'][params.value[4]] + '</strong>' +
							'<br><br>' + moment.unix(params.value[1]).format('DD/MM/YYYY') + ( !isLive ? ' - ' + (moment.unix(params.value[2]).format('DD/MM/YYYY')) : '' );
						},
						axisPointer: {
							type: 'cross',
							label: {
								formatter: function(params,b) {
									if(params.axisDimension == 'x')
										return moment.unix(params.value).format('DD/MM/YYYY');
									else
										return params.value;
								}
							}
						}
					},
					dataZoom: [
					{
						type: 'slider',
						filterMode: 'weakFilter',
						showDataShadow: false,
						top: this.hs,
						height: 30,
						labelFormatter: ''
					},
					{
						type: 'inside',
						filterMode: 'weakFilter'
					}
					],
					grid: {
						clickable: true,
						left: this.isWidget && !this.isFullscreen ? 0 : '10%',
						top: 0,
						right: 30,
						height: this.hs - 30,
						containLabel: this.isWidget && !this.isFullscreen ? true : false
					},
					xAxis: [
					{
						min: startTime,
					//	max: parseInt(moment().format('X')),
						max: endTime,
						scale: true,
						axisLabel: {
							width: 100,
							interval: 100,
							formatter: function (val) {
								return moment.unix(val).format('DD/MM/YYYY')//Math.max(0, val - startTime) + ' ms';
							},
							fontSize: this.isWidget && !this.isFullscreen ? '10' : '12'
						},
					},
					{
						scale: true,
						gridIndex: 0
					}
					],
					yAxis: {
						type: 'category',
						triggerEvent: true,
						data: yData,
						axisLabel: {
							fontSize: this.isWidget && !this.isFullscreen ? '10' : '12',
						}
					},
					series: [
					{
						type: 'custom',
						renderItem: renderItem,
						itemStyle: {
							opacity: 1
						},
						encode: {
							x: [1, 2],
							y: 0
						},
						data: data
					}
					]
				};

				option && myChart.setOption(option);


			/*if($(window).height() > 1000) {
				$(this.$refs.graph).css({
					position: 'sticky',
					top: 0
				});
			}*/

			}
		}
	}

	</script>