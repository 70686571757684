<template id="utenti">
  <section class="utenti">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>Utenti</span>
        </div>
        <div class="content">
          <admin-table
          api-url="/admin/utenti"
          :columns="columns"
          :editFormData="utentiData"
          :createFormData="newUtentiData"
          :canDelete="false"
          :defaultFilter="false"
          :feixed="true"
          @showModal="onShowModal"
          @editError="showError"
          @createError="showError"
          @resetCreateFormData="resetCreateFormData">
          <template slot="filters">
            <b-row>
              <b-col cols="2">
                <b-form-input v-model="filters.cognome" placeholder="Cognome" />
              </b-col>
              <b-col cols="3">
                <multiselect 
                v-model="filters.gruppi"
                :multiple="true"
                :options="gruppi_options"
                placeholder="Filtra per gruppi"
                label="nome"
                track-by="id"/>
              </b-col>
              <b-col cols="3">
                <multiselect
                v-model="filters.squadre"
                :multiple="true"
                :options="squadre_options"
                placeholder="Filtra per squadra"
                label="squadra"
                :custom-label="customLabelTeam"
                track-by="id"/>
              </b-col>
              <b-col cols="3">
                <multiselect
                v-model="filters.permessi"
                :multiple="true"
                :options="permessi_options"
                placeholder="Filtra per permessi"
                label="nome"
                track-by="id"/>
              </b-col>
            </b-row>
          </template>
          <template slot="edit-modal-body" slot-scope="row">
            <b-form-group label="Cognome" horizontal>
              <b-form-input type="text" placeholder="Cognome" v-model="utentiData.cognome" />
            </b-form-group>
            <b-form-group label="Nome" horizontal>
              <b-form-input type="text" placeholder="Nome" v-model="utentiData.nome" />
            </b-form-group>
            <b-form-group label="Username" horizontal>
              <b-form-input type="text" v-model="utentiData.username" plaintext/>
            </b-form-group>
            <b-form-group label="Nuova password" horizontal>
              <b-form-input type="password" v-model="utentiData.password"/>
            </b-form-group>
            <b-form-group label="Ripeti password" horizontal>
             <b-form-input type="password" v-model="utentiData.ripeti_password"/>
           </b-form-group>
           <b-form-group label="Invia nuova password" horizontal>
            <b-form-checkbox v-model="utentiData.notifica" value="1"/>
          </b-form-group>
          <b-form-group label="Email" horizontal>
            <b-form-input type="email" placeholder="Email" v-model="utentiData.email" />
          </b-form-group>
          <b-form-group label="Slack ID" horizontal>
            <b-form-input type="text" v-model="utentiData.slack_id"/>
          </b-form-group>
          <b-form-group label="Attivo" horizontal>
            <b-form-checkbox v-model="utentiData.attivo" value="1"/>
          </b-form-group>
          <b-form-group label="Active Directory AS Roma" horizontal>
            <b-form-checkbox v-model="utentiData.auth_ldap" value="1"/>
          </b-form-group>
          <!--
          <b-form-group label="Gruppi" horizontal>
            <multiselect
            v-model="utentiData.gruppi"
            :multiple="true"
            :options="gruppi_options"
            placeholder="Seleziona gruppo"
            label="nome"
            track-by="id"/>
          </b-form-group>-->
          <b-form-group label="Academy Italia" horizontal v-show="is_academy_soc">
            <multiselect v-model="utentiData.academy" :options="academy_options" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id"></multiselect>
          </b-form-group> 
          <b-form-group label="Academy International" horizontal v-show="is_academy_int_soc">
            <multiselect v-model="utentiData.academy" :options="academy_int_options" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id"></multiselect>
          </b-form-group> 
          <b-form-group label="Categorie Academy" horizontal>
            <multiselect v-model="utentiData.academy_categorie" :options="academy_cats_options" :multiple="true" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id" group-values="items"
            group-label="label" :group-select="true"></multiselect>
          </b-form-group>      
    <!--b-form-group label="Permessi" horizontal>
        <multiselect
        v-model="utentiData.permessi"
        :multiple="true"
        :options="permessi_options"
        placeholder="Seleziona permesso"
        label="nome"
        :custom-label="customLabel"
        :show-labels="false"
        track-by="id"/>
      </b-form-group-->
      <b-form-group label="Squadre AS Roma" horizontal description="La selezione non ha effetto per amministratori, owner, segretari e coordinatori RomaLab">
        <multiselect
        v-model="utentiData.squadre"
        :multiple="true"
        :options="squadre_options"
        placeholder="Seleziona squadra"
        label="squadra"
        :custom-label="customLabelTeam"
        :show-labels="false"
        track-by="id"/>
      </b-form-group>
      <b-form-group label="Preset Filtri Osservatori" horizontal>
        <b-row v-for="item in utentiData.preset_osservatori" class="mb-1">
          <b-col cols="4">
            <b-input placeholder="Nome" v-model="item.name"/>
          </b-col>
          <b-col>
            <multiselect
            v-model="item.value"
            :multiple="true"
            :options="utenti_options"
            placeholder="Seleziona osservatori"
            label="nomeCompleto"
            track-by="id"/>
          </b-col>
          <b-col cols="1">
           <b-button size="sm" @click="removePreset(item, 'edit')" title="Rimuovi"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
         </b-col>
       </b-row>
       <b-button size="sm" title="Aggiungi" @click="addPreset('edit')"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
     </b-form-group>
     <!--<b-form-group label="Permessi">
      <b-form-checkbox-group v-model="utentiData.permessi" name="permessi_upd" stacked>
        <b-form-checkbox v-for="permesso in permessi_options" :value="permesso" :key="permesso.id">{{ permesso.info ? permesso.info.info + ' (' + permesso.nome + ')' : permesso.nome }}</b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>-->
  </template>
  <template slot="create-modal-body" slot-scope="row">
    <b-form-group label="Cognome" horizontal>
      <b-form-input type="text" placeholder="Cognome" v-model="newUtentiData.cognome" />
    </b-form-group>
    <b-form-group label="Nome" horizontal>
      <b-form-input type="text" placeholder="Nome" v-model="newUtentiData.nome" />
    </b-form-group>
    <b-form-group label="Username" horizontal>
      <b-form-input type="text" v-model="newUtentiData.username"/>
    </b-form-group>
    <b-form-group label="Nuova password" horizontal>
      <b-form-input type="password" v-model="newUtentiData.password"/>
    </b-form-group>
    <b-form-group label="Ripeti password" horizontal>
     <b-form-input type="password" v-model="newUtentiData.ripeti_password"/>
   </b-form-group>
   <b-form-group label="Email" horizontal>
    <b-form-input type="email" placeholder="Email" v-model="newUtentiData.email" />
  </b-form-group>
  <b-form-group label="Slack ID" horizontal>
    <b-form-input type="text" v-model="newUtentiData.slack_id"/>
  </b-form-group>
  <b-form-group label="Attivo" horizontal>
    <b-form-checkbox v-model="newUtentiData.attivo" value="1"/>
  </b-form-group>
  <b-form-group label="Active Directory AS Roma" horizontal>
    <b-form-checkbox v-model="newUtentiData.auth_ldap" value="1"/>
  </b-form-group>
  <!--<b-form-group label="Gruppi" horizontal>
    <multiselect
    v-model="newUtentiData.gruppi"
    :multiple="true"
    :options="gruppi_options"
    placeholder="Seleziona gruppo"
    label="nome"
    track-by="id"/>
  </b-form-group>
  <b-form-group label="Academy Italia" horizontal v-show="is_academy_soc">
    <multiselect v-model="newUtentiData.academy" :options="academy_options" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id"></multiselect>
  </b-form-group>
  <b-form-group label="Academy International" horizontal v-show="is_academy_int_soc">
    <multiselect v-model="newUtentiData.academy" :options="academy_int_options" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id"></multiselect>
  </b-form-group>--> 
  <b-form-group label="Categorie Academy" horizontal v-show="is_academy_soc || is_academy_int_soc">
    <multiselect v-model="newUtentiData.academy_categorie" :options="academy_cats_options" :multiple="true" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id" group-values="items"
    group-label="label" :group-select="true"></multiselect>
  </b-form-group> 
  <!--b-form-group label="Permessi" horizontal>
    <multiselect
    v-model="newUtentiData.permessi"
    :multiple="true"
    :options="permessi_options"
    placeholder="Seleziona permesso"
    label="nome"
    :custom-label="customLabel"
    :show-labels="false"
    track-by="id"/>
  </b-form-group-->
  <!--<b-form-group label="Squadre AS Roma" horizontal description="La selezione non ha effetto per amministratori, owner, segretari e coordinatori RomaLab">
    <multiselect
    v-model="newUtentiData.squadre"
    :multiple="true"
    :options="squadre_options"
    placeholder="Seleziona squadra"
    label="squadra"
    :custom-label="customLabelTeam"
    :show-labels="false"
    track-by="id"/>
  </b-form-group>-->
  <b-form-group label="Preset Filtri Osservatori" horizontal>
    <b-row v-for="item in newUtentiData.preset_osservatori" class="mb-1">
      <b-col cols="4">
        <b-input placeholder="Nome" v-model="item.name"/>
      </b-col>
      <b-col>
        <multiselect
        v-model="item.value"
        :multiple="true"
        :options="utenti_options"
        placeholder="Seleziona osservatori"
        label="nomeCompleto"
        track-by="id"/>
      </b-col>
      <b-col cols="1">
       <b-button size="sm" @click="removePreset(item, 'new')" title="Rimuovi"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
     </b-col>
   </b-row>
   <b-button size="sm" title="Aggiungi" @click="addPreset('new')"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
 </b-form-group>
 <!--<b-form-group label="Permessi">
  <b-form-checkbox-group v-model="newUtentiData.permessi" name="permessi_new" stacked>
        <b-form-checkbox v-for="permesso in permessi_options" :value="permesso" :key="permesso.id">{{ permesso.info ? permesso.info.info + ' (' + permesso.nome + ')' : permesso.nome }}</b-form-checkbox>
  </b-form-checkbox-group>
</b-form-group>-->
</template>
</admin-table>
</div>
</div>
</div>
<footerapp></footerapp>
</section>
</template>

<script>
var Auth = require('../../../auth.js');
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default
  },

  data() {
    return {
      columns: [
        { key: 'id', label: 'ID', sortable: false, tdStyle: 'min-width: 1000px' },
        { key: 'avatar_url', label: 'Avatar'},
        { key: 'cognome', label: 'Cognome', sortable: false },
        { key: 'nome', label: 'Nome', sortable: false },
      //{ key: 'username', label: 'Username', sortable: false },
      //{ key: 'attivo', label: 'Attivo', formatter: value => value ? 'SI' : 'NO', sortable: false },
        { key: 'auth_ldap', label: 'A/D', formatter: value => value ? 'SI' : 'NO', sortable: false },
      //{ key: 'email', label: 'Email', sortable: false },
        { key: 'slack_id', label: 'SlackID', sortable: false },
        { key: 'utenti_gruppi', label: 'Gruppi'},
        { key: 'permessi', label: 'Permessi'},
        { key: 'squadre_societa', label: 'Squadre'},
        { key: 'actions', label: 'Azioni' },
        ],
      utentiData: {
        nome: '',
        cognome: '',
        username: '',
        password: '',
        ripeti_password: '',
        notifica: false,
        attivo: false,
        auth_ldap: false,
        email: '',
        slack_id: null,
        gruppi: [],
        category: null,
        permessi: [],
        squadre: [],
        academy: null,
        academy_categorie: [],
        preset_osservatori: [],
      },
      newUtentiData: {
        persona_id: null,
        nome: '',
        cognome: '',
        username: '',
        password: '',
        ripeti_password: '',
        attivo: false,
        auth_ldap: false,
        email: '',
        slack_id: null,
        gruppi: [],
        category: null,
        permessi: [],
        squadre: [],
        academy: null,
        academy_categorie: [],
        preset_osservatori: [],
      },
      filters: {
        cognome: '',
        gruppi: [],
        squadre: [],
        permessi: [],
      },
      gruppi_options: [],
      permessi_options: [],
      categories_opt: [],
      utenti_options: [],
      squadre_options: [],
      is_academy_soc: false,
      is_academy_int_soc: false,
      academy_options: [],
      academy_cats_options: [],
      academy_int_options: [],
    };
  },
  methods: {
    existAcademySocieta(array){
      var trovato = false;
      array.forEach(function(item){
        if(item.id === 17 || item.id === 45) trovato = true;
      });
      return trovato;
    },
    existAcademyIntSocieta(array){
      var trovato = false;
      array.forEach(function(item){
        if(item.id === 31 || item.id === 32) trovato = true;
      });
      return trovato;
    },
    onShowModal(row) {
      this.utentiData.nome = row.item.nome;
      this.utentiData.cognome = row.item.cognome;
      this.utentiData.username = row.item.username;
      this.utentiData.attivo = row.item.attivo;
      this.utentiData.auth_ldap = row.item.auth_ldap;
      this.utentiData.email = row.item.persona.contatti.find((item) => item.contatto.tipo == 'email').contatto.valore;
      this.utentiData.slack_id = row.item.slack_id;
      this.utentiData.gruppi = row.item.gruppi;
      this.utentiData.permessi = row.item.permessi.map(({pivot, ...rest}) => {
        return rest;
      });
      this.utentiData.category = null;
      this.utentiData.password = '';
      this.utentiData.ripeti_password = '';
      this.utentiData.notifica = false;
      this.utentiData.squadre = row.item.squadre_societa;
      if(row.item.academy) {
        this.utentiData.academy = {
          nome: row.item.academy.nome,
          id: row.item.academy.id,
        };
      } else {
        this.utentiData.academy = null;
      }
      this.utentiData.academy_categorie = row.item.academy_categorie;

      if(row.item.preset) {
        var preset = JSON.parse(row.item.preset);
        if(preset.osservatori) {
          for(var i in preset.osservatori) {
            var array_osserv_ids = preset.osservatori[i].value;
            var array_osserv = [];
            for(var x in array_osserv_ids) {
              var id = array_osserv_ids[x];
              //var utente = this.utenti_options.find((item) => item.persona.id_utente == id);
              var utente = this.utenti_options.find((item) => item.id == id);
              array_osserv.push(utente);
            }
            preset.osservatori[i].value = array_osserv;
          }
          this.utentiData.preset_osservatori = preset.osservatori;
        }
      }
    },
    showError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(types.ALERTS_PUSH, { msg: err.response.data.error, status: 0 });
      }
    },
    customLabel ({ nome, info }) {
      if(info) {
        return info.info;
      } else 
      return nome;
    },
    customLabelTeam ({ squadra }) {
      return squadra.categoria.name;
    },
    resetCreateFormData() {
      this.newUtentiData.persona_id = null;
      this.newUtentiData.nome = '';
      this.newUtentiData.cognome = '';
      this.newUtentiData.username = '';
      this.newUtentiData.attivo = false;
      this.newUtentiData.auth_ldap = false;
      this.newUtentiData.email = '';
      this.newUtentiData.slack_id = null;
      this.newUtentiData.gruppi = [];
      this.newUtentiData.permessi = [];
      this.newUtentiData.password = '';
      this.newUtentiData.ripeti_password = '';
      this.newUtentiData.category = null;
      this.newUtentiData.squadre = [];
      this.newUtentiData.academy = null;
      this.newUtentiData.academy_categorie = [];
      this.newUtentiData.preset_osservatori = [];
    },
    addPreset: function (mode) {
      if(mode == 'edit') {
        this.utentiData.preset_osservatori.push({name: '', value: []});
      } else {
        this.newUtentiData.preset_osservatori.push({name: '', value: []});

      }
    },
    removePreset: function (item, mode) {
      var index = null;
      if(mode == 'edit') {
        index = this.utentiData.preset_osservatori.indexOf(item);
        this.utentiData.preset_osservatori.splice(index, 1);
      } else {
        index = this.newUtentiData.preset_osservatori.indexOf(item);
        this.newUtentiData.preset_osservatori.splice(index, 1);
      }
    },
  },
  created: function() {
    axios.get('/admin/gruppi').then((res) => {
      this.gruppi_options = res.data.map(item => ({
        nome: item.nome,
        id: item.id,
      }));
      this.gruppi_options = this.gruppi_options.sort(function(a, b) { 
        return (a.nome).localeCompare(b.nome);
      });
    });
    axios.get('/admin/permessi').then((res) => {
      this.permessi_options = res.data;
      this.permessi_options = this.permessi_options.sort(function(a, b) { 
        return (a.info ? a.info.info : a.nome).localeCompare(b.info ? b.info.info : b.nome);
      });
    });
    axios.get('/societa/squadre').then((res) => {
      this.squadre_options = res.data;
    });
    axios.get('/admin/utenti/football').then((res) => {
      this.utenti_options = res.data;
    });
    axios.get(`/exercise/categories`)
    .then((res) => {  
      this.categories_opt     = res.data.map(item => ({
        name: item.name,
        id: item.id,
      }));
      this.loading    = false;
    }).catch((err) => {
      this.loading = false;
    });
    axios.get('/academyita/get/list/societa/0').then((res) => {
      this.academy_options = res.data;
    });
    axios.get('/academyita/get/list/societa/1').then((res) => {
      this.academy_int_options = res.data;
    });
    this.academy_cats_options = [
    {
      label: 'Categorie',
      items: Auth.user.academyCats
    }
    ];
  },
  watch: {
    filters: {
      handler() {
        this.$root.$emit('admin-table::filter', this.filters);
      },      
      deep: true,
    },
    'utentiData.gruppi': {
      handler(selectedGroups) {
        this.is_academy_soc = (this.existAcademySocieta(selectedGroups) == true) ?  true : false ;
        this.is_academy_int_soc = (this.existAcademyIntSocieta(selectedGroups) == true) ?  true : false ;
      },      
      deep: true,
    },
    'newUtentiData.gruppi': {
      handler(selectedGroups) {
        this.is_academy_soc = (this.existAcademySocieta(selectedGroups) == true) ?  true : false ;
        this.is_academy_int_soc = (this.existAcademyIntSocieta(selectedGroups) == true) ?  true : false ;
      },      
      deep: true,
    },

    is_academy_soc() {
      if(!this.is_academy_soc && !this.is_academy_int_soc) {
        this.utentiData.academy = null;
        this.utentiData.academy_categorie = [];
      }
    },

    is_academy_int_soc() {
      if(!this.is_academy_soc && !this.is_academy_int_soc) {
        this.utentiData.academy = null;
        this.utentiData.academy_categorie = [];
      }
    },
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051 !important;
}
</style>
