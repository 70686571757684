<template>
	<div>
		<b-row class="mt-2">
			<b-col cols="8">
				<b-form @submit.prevent="create">
					<b-form-group label="Titolo (facoltativo)">
						<b-form-input v-model="model.titolo"></b-form-input>
					</b-form-group>
					<b-form-group label="Mostra i video di:">
						<b-form-radio-group v-model="filters_date">
							<b-form-radio value="day">Oggi</b-form-radio>
							<b-form-radio value="week">Ultimi 7 giorni</b-form-radio>
							<b-form-radio value="month">Ultimi 30 giorni</b-form-radio>
							<b-form-radio value="all">Tutti</b-form-radio>
						</b-form-radio-group>
					</b-form-group>
					<b-form-group label="Video caricati sul server ASRoma" v-if="model.type == 'asroma' && filters_date">
						<div v-if="!upload_loading" style="width:100%;" class="mt-1">
							<span class="font-bold">Loading...</span>
							<img src="/assets/varie/loading.gif" style="width: 30px;" />
						</div>
						<span class="font-bold" v-if="upload_loading && upload_video.length == 0">Nessun video trovato sul tuo archivio!</span>
						<b-form-select v-model="model.fileNameVideo" v-if="upload_loading && upload_video.length > 0">
							<option :value="null">Seleziona video</option>
							<optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
								<option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} caricato il {{ video.data | formatDateHour }}</option>
							</optgroup>
						</b-form-select>
					</b-form-group>
					<b-button size="sm" type="submit" variant="primary" class="mt-1" :disabled="!model.fileNameVideo">Salva</b-button>
				</b-form>
			</b-col>
		</b-row>
		<div class="mt-3">
				<b-button size="sm" class="mt-3" @click="showUpload">Carica nuovo video</b-button>
				<b-row v-if="show_upload" class="mt-3">
					<b-col v-if="type == 'iframe'">
						<b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
					</b-col>
					<b-col v-if="type == 'upload'">

						<b-form @submit.prevent="upload">

							<b-form-file accept="video/*" v-model="ux.file"></b-form-file>
							<div class="mt-3">
								<b-button :disabled="!ux.file" variant="outline-success" size="sm" type="submit">Carica File</b-button>
							</div>
						</b-form>

					</b-col>
					<b-col v-else-if="type == 'cutter'">
						<Smart-Uploader :entity="entity" @refresh="$emit('refresh')" :cutterSource="cutterSource"></Smart-Uploader>
					</b-col>
				</b-row>
			</div>
	</div>
</template>

<script>

import _ from 'lodash'
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	props: [ 'entity', 'model', 'skipRosa', 'type', 'cutterSource' ],
	computed: {
		...mapState(['rosaTeamId']),	
	},
	components: {
		SmartUploader: require('@/components/layout/video/smart-uploader.vue').default,
	},
	watch: {
		filters_date: function () {
			this.model.fileNameVideo = null;
			this.upload_loading = false;
			this.getUploadVideo(); 
		},

		show_upload: function() {
			if(this.show_upload && this.upload_iframe == '') {
				this.getUploadIFrame();
			}
		}	
	},
	methods: {
		create: function() {
			this.$emit('save');
		},
		upload: function() {
			this.$emit('upload', this.ux.file);
		},

		getUploadVideo() {
			if(this.rosaTeamId && (this.skipRosa == undefined || this.skipRosa == false)) {
				this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', { params: { team_id: this.rosaTeamId, filters_date: this.filters_date }}).then((response) => {
					if (response.ok)
					{
						this.upload_video = response.data;
						this.upload_loading = true;
					}
				});
			} else {
				this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', { params: { filters_date: this.filters_date }}).then((response) => {
					if (response.ok)
					{
						this.upload_video = response.data;
						this.upload_loading = true;
					}
				});
			}
		},

		showUpload() {
			this.show_upload = !this.show_upload;		
		},

		getUploadIFrame() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
				if (response.ok) {
					this.upload_iframe = response.data;
				}
			}, (response) => {
			});
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},		
	},
	data: function() {
		return {
			upload_loading: true,
			upload_video: [],
			show_upload: false,
			errors: [],
			filters_date: null,
			upload_iframe: '',
			ux: {
				file: null
			}
		}
	}
};

</script>