<template id="dashboard">
	<section class="allenamenti">

		<headapp></headapp>

		<div class="">
			<menusub></menusub>
			<input type="hidden" :value="$route.params.id" id="academyId"/>
			<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="academy">
				<div class="card-body p-3">
					<div class="row flex-items-xs-middle">
						<div class="col-5">
							<div class="media">
								<img v-bind:src="academy.logo_url" class="" height="80">
								<div class="media-body align-self-center ml-3">
									<h3 class="text-primary mb-0"><strong>{{academy.nome}}</strong></h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card border-0 rounded-0">
				<div class="card-body">
					<div class="content">
						<full-calendar ref="calendar" :event-sources="eventSources" @event-render="eventRender" @event-selected="eventSelected" @day-click="dayClick" :config="config" @event-drop="eventDrop" @event-resize="eventResize"></full-calendar>
						<b-row class="my-2" v-if="academy">
							<b-col>
								<b-form-checkbox-group v-model="cat_ids">
									<b-form-checkbox :value="cat.id" v-for="cat in academy.categorie" class="mt-2">
										<div :style="'background:' + cat.colore + ';padding: 0.2em 0.6em 0.2em 0.6em;font-weight: normal;font-size: 85%;border-radius: 0.25rem;color:#FFFFFF'">{{ cat.nome }}</div>
									</b-form-checkbox>
								</b-form-checkbox-group>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>

		<b-modal id="eventModal" ref="eventModal" :title="$i18n.t('Aggiungi allenamento')" centered  onlyCancel @ok="handleOk" @cancel="handleCancel" size="lg">
			<b-container fluid>
				<form @submit.stop.prevent="handleSubmit">
					<b-row class="mb-1">
						<b-col>
							<b-row class="mb-1" v-if="academy">
								<b-col>
									<label>{{ $i18n.t('Categoria') }}</label>
									<b-form-select v-model="academyCatId">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in academy.categorie" :key="item.id">{{item.nome}}</option>
									</b-form-select>	
								</b-col>
							</b-row>
							<b-row class="mb-1" v-if="level != 3">
								<b-col>
									<label>{{ $i18n.t('Principi di gioco') }}</label>
									<b-form-select v-model="principi_gioco">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in skills1" :key="item.id">{{item.name}}</option>
									</b-form-select>

								</b-col>
								<b-col>

									<label>	{{ $i18n.t('Componenti tecniche') }}</label>
									<b-form-select v-model="componenti_tecniche">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in skills2" :key="item.id">{{item.name}}</option>
									</b-form-select>

								</b-col>
								<b-col>

									<label>	{{ $i18n.t('Capacità motorie') }}</label>
									<b-form-select v-model="capacita_motorie">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in skills3" :key="item.id">{{item.name}}</option>
									</b-form-select>

								</b-col>

							</b-row>

							<b-row class="mb-1" v-if="level == 3">

								<b-col>
									<label>{{ $i18n.t('Tecnica podalica') }}</label>
									<b-form-select v-model="tecnica_podalica">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in skills4" :key="item.id">{{item.name}}</option>
									</b-form-select>

								</b-col>
								<b-col>

									<label>	{{ $i18n.t('Tecnica di base') }} </label>
									<b-form-select v-model="tecnica_di_base">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in skills5" :key="item.id">{{item.name}}</option>
									</b-form-select>

								</b-col>
								<b-col>

									<label>	{{ $i18n.t('Qualità tattiche') }}</label>
									<b-form-select v-model="qualita_tattiche">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in skills6" :key="item.id">{{item.name}}</option>
									</b-form-select>

								</b-col>
								<b-col>
									<label>	{{ $i18n.t('Capacità motorie') }}</label>
									<b-form-select v-model="capacita_motorie">
										<option :value="null">{{ $i18n.t('seleziona') }}</option>
										<option :value="item.id" v-for="item in skills7" :key="item.id">{{item.name}}</option>
									</b-form-select>

								</b-col>

							</b-row>

							<b-row class="mb-1">
								<b-col>
									<b-form-radio-group id="level" v-model="level" name="radioLevel">
										<b-form-radio value="0">Standard</b-form-radio>
										<b-form-radio value="3">{{ $i18n.t('Portieri') }}</b-form-radio>
									</b-form-radio-group>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col>
									<b-button v-show="event_id" :to="{ path: `/academyint/societa/allenamento/${$route.params.id}/${this.event_id}` }" variant="outline-primary">{{ $i18n.t('Personalizza') }}</b-button>
									<b-button v-show="event_id" @click="duplica" variant="outline-primary"><i class="fa fa-arrow-up" aria-hidden="true" v-if="clona"></i><i class="fa fa-arrow-down" aria-hidden="true" v-if="!clona"></i> {{ $i18n.t('Duplica') }}</b-button>
									<b-button v-show="event_id" @click="eliminaAllenamento" variant="outline-primary">{{ $i18n.t('Elimina') }}</b-button>
									<b-button variant="outline-primary" v-if="event_id" @click="downloadPdf">{{ $i18n.t('Stampa pdf') }}</b-button>
								</b-col>
							</b-row>
							<b-row class="mt-3" align-v="center" v-if="clona">
								<b-col>
									<b-form-group :label="$i18n.t('Data')">
										<b-form-input v-model="data_clone" type="date"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group :label="$i18n.t('Ora')">
										<b-form-input v-model="ora_clone" type="time"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-button variant="outline-primary" v-if="event_id" class="mt-3" @click="duplicaAllenamento">{{ $i18n.t('Crea duplicato') }}</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</form>
			</b-container>
		</b-modal>

	</section>
</template>

<script>
import moment from 'moment'
import { FullCalendar } from 'vue-full-calendar'
// import '../../../../lang/agenda.js'
import * as types from '../../../../store/types'
import axios from 'axios';
import { mapState } from 'vuex';
var Auth = require('../../../../auth.js');
export default {

	data: function(){
		return {
			principi_gioco: null,
			componenti_tecniche: null,
			capacita_motorie: null,
			tecnica_podalica: null,
			tecnica_di_base: null,
			qualita_tattiche: null,
			skills: [],
			skills_portiere: [],
			skills1: [],
			skills2: [],
			skills3: [],
			skills4: [],
			skills5: [],
			skills6: [],
			skills7: [],
			events: [],
			max: 100,
			config:
			{
				disableResizing: false,
				editable: true,
				defaultView: 'month',
				locale: this.$i18n.t('lang'),
			},
			selected: null,
			event_id: null,
			title: '',
			level: 0,
			start_at: '',
			end_at: '',
			team: null,
			clona: false,
			data_clone: null,
			ora_clone: null,
			academy: null,
			cat_ids: [],
			academyCatId: null,
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		eventSources: function() {
			const self = this;
			console.log('eventSources: ' + self.cat_ids);
			var events = [];
			events.push({
				events(start, end, timezone, callback) {
					var academyId = $("#academyId").val();
					var start = moment(start).format("YYYY-MM-DD HH:mm:ss")
					var end = moment(end).format("YYYY-MM-DD HH:mm:ss")					
					const formData = new FormData();
					formData.append("start_at", start);
					formData.append("end_at", end);
					formData.append("academy_id", academyId);
					formData.set('_method', 'POST');

					const opts = {
						url: `/academyita/allenamenti/1`,
						method: 'POST',
						data: formData,
					};

					axios(opts)
					.then((res) => {
						var data = res.data;

						if(self.cat_ids.length > 0) {
							data = data.filter((item) => self.cat_ids.includes(item.id_categoria));
						}
						data = data.map(item => ({
							title: '\n' + item.categoria.nome + ' - ' + (item.level == 3 ? 'Portieri' : 'Standard') + '\n' + item.title.replace(/ - /g, '\n'),
							start: item.start_at,
							end: item.end_at,
							principi_gioco: item.principi_gioco,
							componenti_tecniche: item.componenti_tecniche,
							capacita_motorie: item.capacita_motorie,
							tecnica_podalica: item.tecnica_podalica,
							tecnica_di_base: item.tecnica_di_base,
							qualita_tattiche: item.qualita_tattiche,
							id: item.id,
							level: item.level,
							color: item.categoria.colore,
							textColor: '#FFF',
							id_categoria: item.id_categoria,
						}));

						callback(data);
					}).catch((err) => {
						console.log(err);
					});
				},
				color: '#F7CA18',
				textColor: '#a80532',
			});
			return events;
		} 
	},

	watch: {
		level: function (val, oldVal) {
			if(oldVal == 3) {
				this.tecnica_podalica = null;
				this.tecnica_di_base = null;
				this.qualita_tattiche = null;
				this.capacita_motorie = null;
			}
			if(val == 3) {
				this.principi_gioco = null;
				this.componenti_tecniche = null;
				this.capacita_motorie = null;
			}
		}, 
	},

	created() {
		if(this.$route.params.id){
			axios.get(`/academyita/societa/get/` + this.$route.params.id)
			.then((res) => {
				this.academy = res.data;
			}).catch((err) => {
				this.loading = false;
			});
		}
	},

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		FullCalendar,
	},

	methods: {

		handleCancel (evt) {
			this.event_id = null;
			this.title = '';
			this.level = 0;
			this.principi_gioco = null;
			this.componenti_tecniche = null;
			this.capacita_motorie = null;
			this.tecnica_podalica = null;
			this.tecnica_di_base = null;
			this.qualita_tattiche = null;
		},
		handleOk (evt) {
			evt.preventDefault();
			this.handleSubmit();
		},

		handleSubmit () {
			if(!this.academyCatId) {
				alert("Devi selezionare una categoria per l'allenamento!");
				return;
			}
			if( !this.event_id ){
				this.create();
			} else {
				const formData = new FormData();
				formData.append("start_at",  this.start_at);
				formData.append("end_at",  this.end_at);
				formData.append("title",  this.getTitle());
				formData.append("id_categoria", this.academyCatId);
				formData.append("level",  this.level);
				formData.append("principi_gioco",  this.principi_gioco);
				formData.append("componenti_tecniche",  this.componenti_tecniche);
				formData.append("capacita_motorie",  this.capacita_motorie);
				formData.append("tecnica_podalica",  this.tecnica_podalica);
				formData.append("tecnica_di_base",  this.tecnica_di_base);
				formData.append("qualita_tattiche",  this.qualita_tattiche);
				formData.append("no_delete_phases",  true);
				this.update(formData,  this.event_id);
			}
			this.event_id = null;
			this.title = '';
			this.level = 0;
			this.principi_gioco = null;
			this.componenti_tecniche = null;
			this.capacita_motorie = null;
			this.tecnica_podalica = null;
			this.tecnica_di_base = null;
			this.qualita_tattiche = null;
			this.academyCatId = null;
		},

		removeEvent() {
			this.$refs.calendar.$emit('remove-event', this.selected);
			this.selected = {};
		},
		eventSelected(event) {

			this.get_skills();

			this.event_id = event.id;
			this.title = event.title;
			this.level = event.level;
			this.start_at = moment(event.start).format("YYYY-MM-DD HH:mm:ss");
			this.end_at = moment(event.end).format("YYYY-MM-DD HH:mm:ss");
			this.selected = event;
			this.principi_gioco = event.principi_gioco;
			this.componenti_tecniche = event.componenti_tecniche;
			this.capacita_motorie = event.capacita_motorie;
			this.tecnica_podalica = event.tecnica_podalica;
			this.tecnica_di_base = event.tecnica_di_base;
			this.qualita_tattiche = event.qualita_tattiche;
			this.academyCatId = event.id_categoria;
			this.$refs.eventModal.show();
		},
		eventRender(event) {
			this.principi_gioco = null;
			this.componenti_tecniche = null;
			this.capacita_motorie = null;
			this.tecnica_podalica = null;
			this.tecnica_di_base = null;
			this.qualita_tattiche = null;
		},
		dayClick(date, jsEvent, view) {
			this.selected = null;
			this.start_at = moment(date['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss");
			this.end_at = moment(date['_d']).add({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss");
			this.$refs.eventModal.show();
			this.get_skills();
		},

		eventDrop(event, delta, revertFunc) {
			const formData = new FormData();
			formData.append("drop",  true);
			formData.append("start_at",  moment(event['start']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			if(event['end']) {
				formData.append("end_at",  moment(event['end']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			} else {
				formData.append("end_at",  moment(event['start']['_d']).add({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			}
			this.update(formData,  event['id']);
		},
		eventResize(event, delta, revertFunc) {
			const formData = new FormData();
			formData.append("drop",  true);
			formData.append("start_at",  moment(event['start']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			formData.append("end_at",  moment(event['end']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			this.update(formData,  event['id']);
		},
		refreshEvents() {
			this.$refs.calendar.$emit('refetch-events');
			this.$refs.calendar.fireMethod('changeView', 'basicDay');
			this.$refs.calendar.fireMethod('changeView', 'month');
		},
		update(data, id){
			this.$http.post(this.$store.state.apiEndPoint + '/academyita/update/allenamento/' + id, data).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		get_skills(){
			axios.get(`/skills`)
			.then((res) => {
				this.skills = JSON.parse(res.data.standard);
				this.skills_portiere = JSON.parse(res.data.special);

				this.skills1 = this.getOptionsSkills(this.skills[0]);
				this.skills2 = this.getOptionsSkills(this.skills[1]);
				this.skills3 = this.getOptionsSkills(this.skills[2]);

				this.skills4 = this.getOptionsSkills(this.skills_portiere[0]);
				this.skills5 = this.getOptionsSkills(this.skills_portiere[1]);
				this.skills6 = this.getOptionsSkills(this.skills_portiere[2]);
				this.skills7 = this.getOptionsSkills(this.skills_portiere[3]);
			}).catch((err) => {
			});
		},

		getOptionsSkills(skills) {
			var options = [];
			var skill_items = skills.fields;
			for(var key in skill_items) {
				var skill = skill_items[key];

				var option = {
					id: skill.id,
					name: skill[this.$i18n.t('lang')],
				}
				options.push(option);
			}
			return options;
		},

		create() {
			const formData = new FormData();
			formData.append("title", this.getTitle());
			formData.append("id_categoria", this.academyCatId);
			formData.append("level", this.level);
			formData.append("start_at", this.start_at);
			formData.append("end_at", this.end_at);
			formData.append("principi_gioco",  this.principi_gioco);
			formData.append("componenti_tecniche",  this.componenti_tecniche);
			formData.append("capacita_motorie",  this.capacita_motorie);
			formData.append("tecnica_podalica",  this.tecnica_podalica);
			formData.append("tecnica_di_base",  this.tecnica_di_base);
			formData.append("qualita_tattiche",  this.qualita_tattiche);
			formData.append("academy_id",  this.$route.params.id);
			formData.append("academy", 1);

			this.$http.post(this.$store.state.apiEndPoint + '/academyita/create/allenamento', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		show(){
			var event_id = this.event_id;
			this.$router.push({ path: `/academyint/societa/allenamento/${this.$route.params.id}/${event_id}` })
		},
		downloadPdf(){
			window.open(this.$store.state.apiEndPoint + '/academyita/allenamento/' + this.event_id + "/pdf/" + this.$i18n.t('lang'), "_blank");
		},
		eliminaAllenamento(){
			if(confirm("Confermi l'eliminazione dell'allenamento ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/delete/allenamento/' + this.event_id).then((response) => {

					if (response.ok)
					{
						this.event_id = null;
						this.title = '';
						this.level = 0;
						this.principi_gioco = null;
						this.componenti_tecniche = null;
						this.capacita_motorie = null;
						this.tecnica_podalica = null;
						this.tecnica_di_base = null;
						this.qualita_tattiche = null;
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
				});

			}
		},
		getTitle() {
			var title = "";
			if(this.level == 3) {
				if(this.tecnica_podalica != null && this.tecnica_podalica != 'null') {
					title = this.getLabel(this.tecnica_podalica, this.skills_portiere[0]);
				}
				if(this.tecnica_di_base != null && this.tecnica_di_base != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.tecnica_di_base, this.skills_portiere[1]);
				}
				if(this.qualita_tattiche != null && this.qualita_tattiche != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.qualita_tattiche, this.skills_portiere[2]);
				}
				if(this.capacita_motorie != null && this.capacita_motorie != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.capacita_motorie, this.skills_portiere[3]);
				}
			} else {
				if(this.principi_gioco != null && this.principi_gioco != 'null') {
					title = this.getLabel(this.principi_gioco, this.skills[0]);
				}
				if(this.componenti_tecniche != null && this.componenti_tecniche != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.componenti_tecniche, this.skills[1]);
				}
				if(this.capacita_motorie != null && this.capacita_motorie != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.capacita_motorie, this.skills[2]);
				}
			}
			return title;
		},
		getLabel(id_skill, skills) {
			var label = "N/D";
			if(id_skill && skills) {
				var skill_items = skills.fields;
				for(var key in skill_items) {
					var skill = skill_items[key];
					if(skill.id == id_skill) {
						label = skill[this.$i18n.t('lang')];
						return label;
					}
				}
			}
			return label;
		},
		duplica() {
			this.clona = !this.clona;
		},
		duplicaAllenamento() {
			if(this.data_clone && this.ora_clone) {
				const formData = new FormData();
				formData.append("start_at", this.data_clone + ' ' + this.ora_clone);
				formData.append("end_at", moment(this.data_clone + ' ' + this.ora_clone).add({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
				this.$http.post(this.$store.state.apiEndPoint + '/academyita/duplica/allenamento/' + this.event_id, formData).then((response) => {
							// success callback
							if (response.ok){
								this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
								this.refreshEvents();
								this.$refs.eventModal.hide();
							}
						}, (response) => {
							if (response.status == 422) {
								this.errors = response.data;
								for (var index in this.errors) {
									this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
								}
							}
						});
			} else {
				alert("Devi selezionare la data e l'ora dell'allenamento da duplicare!");
			}
		},
	}

}
</script>

<style>
.fc-resizer.fc-end-resizer {
	display: none;
}
</style>
