<template>
	<div>

		<b-modal ref="modal" size="lg">
			
			<b-form @submit.prevent="doSearch" class="d-flex">
				<b-input type="search" v-model="ux.search"></b-input>
				<b-button type="submit">Cerca</b-button> 
			</b-form>

			<div v-if="results">
				<b-row>
					<b-col :cols="5">
						<div class="text-center text-muted py-2"><strong>AS Roma System</strong></div>

						<b-row>
							<b-col :cols="3">
								<img class="img-fluid" :src="persona.avatar_url" />
							</b-col>
							<b-col>
								<div><strong>{{ persona.cognome }}</strong> {{persona.nome}}</div>
								<div>{{ persona.nascita_data | date }}</div>
								<div class="text-muted">{{ persona.last_carriera_sportiva.squadra.officialName }}</div>
							</b-col>
						</b-row>

					</b-col>
					<b-col v-if="!ux.selected">
						<div class="text-center text-muted py-2"><strong>NoiseFeed</strong></div>
						<b-list-group>
							<b-list-group-item :active="current === result.tag" button @click.prevent="ux.selected = result" v-for="result in results">
								<b-row>
									<b-col :cols="3">
										<img class="img-fluid" :src="result.image" />
									</b-col>
									<b-col>
										<div><strong>{{ result.lastname }}</strong> {{result.firstname}} <span v-if="result.common_name">({{result.common_name}})</span></div>
										<div class="text-muted">{{ result.team_name }}</div>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>
			</div>

			<div v-if="ux.selected">
				<p class="lead">Hai selezionato:</p>
				<b-row class="d-flex align-items-center">
					<b-col cols="8">
						<b-card>
							<b-row>
								<b-col :cols="3">
									<img class="img-fluid" :src="ux.selected.image" />
								</b-col>
								<b-col>
									<div><strong>{{ ux.selected.lastname }}</strong> {{ux.selected.firstname}} <span v-if="ux.selected.common_name">({{ux.selected.common_name}})</span></div>
									<div class="text-muted">{{ ux.selected.team_name }}</div>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
					<b-col class="text-center">
						<b-button variant="success" @click.prevent="doReplace(ux.selected.tag)">Conferma Sostituzione</b-button>
					</b-col>
				</b-row>
			</div>

		</b-modal>
	</div>
</template>

<script>

var Auth = require('@/auth.js');
import moment from 'moment';
import videojs from 'video.js';
import _ from 'lodash';

export default {
	props: [ 'persona', 'current' ],
	data: function() {
		return {
			results: null,
			ux: {
				selected: null,
				search: ''
			}
		}
	},
	watch: {
		persona: {
			immediate: true,
			deep: true,
			handler: function() {
				this.ux.search = this.persona.cognome;
			}
		}
	},
	methods: {
		doReplace: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/noisefeed/update-id/'+this.persona.id + '/' + id).then(function(data) {
				this.hide();
				this.$emit('refresh');
			});
		},
		doSearch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/noisefeed/search/'+this.ux.search).then(function(data) {
				this.results = data.data;
			});
		},
		show: function() {
			this.$refs.modal.show();
			this.doSearch();
		},
		hide: function() {
			this.$refs.modal.hide();
		}
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY');
		},
		ago: function(a) {
			return moment(a).fromNow();
		}
	}
}

</script>