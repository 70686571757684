<template id="rosa">
	<section class="rosa">
		<div class="card">
			<div class="card-body">
				<div v-if="!isWidget || isFullscreen">
					<div class="mb-2">
						<b-row align-v="end">
							<b-col cols="2">
								<label>Periodo:</label>
								<b-form-input v-model="ux.stats.from" @change="ux.stats.to = ux.stats.from" type="date"></b-form-input>
								<b-form-input v-show="false" v-model="ux.stats.to" type="date"></b-form-input>
							</b-col>
							<b-col>
								<label>Raccolte Dati: <strong v-if="ux.stats.fase_form.length == 0">Tutte le tipologie</strong></label>
								<multiselect v-model="ux.stats.fase_form" :options="glossarioTipiDato" :multiple="true" placeholder="Seleziona"></multiselect>
							</b-col>
							<b-col>
								<label>Tipi di Sessione: <strong v-if="ux.stats.fase_form_sessione.length == 0">Tutte le tipologie</strong></label>
								<multiselect v-model="ux.stats.fase_form_sessione" :options="['Fisioterapia', 'Nutrition', 'Farmaco', 'CheckIn', 'Psicologica', 'Pesa Giornaliera', 'Dexa', 'RTP', 'Standard', 'Palestra']" :multiple="true" placeholder="Seleziona"></multiselect>
							</b-col>
							<b-col cols="2" class="text-right">
								<b-button size="sm" variant="primary" @click.prevent="showSoglie"><i class="fa fa-cog" aria-hidden="true"></i> Configura risultati
								</b-button>
							</b-col>
						</b-row>
					</div>
				</div>
				<div class="content">
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					<persone-sessioni @refresh="getPlayers" v-else-if="viewMode == 'players' && loading == false && filtered_players.length > 0" :config="ux.stats" :persone="filtered_players" :isWidget="isWidget" :contentHeight="contentHeight" :isFullscreen="isFullscreen"></persone-sessioni>
					<div v-else-if="viewMode == 'players' && loading == false && filtered_players.length == 0">Nessun giocatore trovato!</div>
					<dati-sessioni @refresh="getPlayers" v-else-if="viewMode == 'data' && loading == false && filtered_data.length > 0" :config="ux.stats" :dati="filtered_data" :isWidget="isWidget" :contentHeight="contentHeight" :isFullscreen="isFullscreen"></dati-sessioni>
					
					<div v-else-if="viewMode == 'data' && loading == false && filtered_data.length == 0">Nessun dato trovato!</div>
				</div>
			</div>
		</div>
		<b-modal title="Configura i risultati che vuoi visualizzare" size="lg" ok-only ok-title="Chiudi" ref="sogliaModal">
			<b-card>
				<b-row>
					<b-col>
						<b-form-group label="Vista risultati">
							<b-form-radio-group v-model="viewMode">
								<b-form-radio value="players">Tabella giocatori</b-form-radio>
								<b-form-radio value="data">Tabella dati</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>	
				</b-row>
				<b-row v-for="filtro in filter_options">
					<b-col>
						<b-form-group :label="filtro.label">
							<b-form-checkbox-group v-model="filter_selected[filtro.key]" v-if="filtro.type == 'enum'">
								<b-form-checkbox v-for="opt in getOptions(filtro.key)" :key="opt" :value="opt">{{ opt }}</b-form-checkbox>
							</b-form-checkbox-group>
							<div v-else-if="filtro.type == 'number'">
								<b-row v-for="row in filter_selected[filtro.key]">
									<b-col v-for="col, key in row">
										<b-form-group :label="key">
											<b-form-input type="number" v-model="row[key]"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
						</b-form-group>	
					</b-col>
				</b-row>
			</b-card>
		</b-modal>
	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
export default {
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function(){
		return {
			ux: {
				stats: {
					from: moment().format('YYYY-MM-DD'),
					to: moment().format('YYYY-MM-DD'),
					fase_form: this.configWidget && this.configWidget.faseForm ? this.configWidget.faseForm : [ 'Fase_Dato_Presenza', 'Fase_Dato_Tqr', 'Fase_Dato_Vas' ],
					fase_form_sessione: this.configWidget && this.configWidget.faseFormSessione ? this.configWidget.faseFormSessione : [ 'CheckIn', 'Fisioterapia' ]
				}
			},
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			players: [],

			glossarioTipiDato: [
				'Fase_Dato_Vas',
				'Fase_Dato_Rpe',
				'Fase_Dato_Tqr',
				'Fase_Dato_Peso',
				'Fase_Dato_Presenza',
				'Fase_Dato_Durata',
				'Fase_Dato_Pom',
				'Fase_Dato_Sleep_Quality'
				],

			presenze_options: ['Presente', 'Libero', 'Assente', 'Infortunato', 'Malato', 'Differenziato', 'Altra squadra', 'Nazionale', 'Recovery'],
			filter_options: [ 
				{ key: 'presenza', label: 'Presenza', type: 'enum', fase_dato: 'Fase_Dato_Presenza'},
				{ key: 'tqr', label: 'Tqr', type: 'number', fase_dato: 'Fase_Dato_Tqr' },
				{ key: 'vas', label: 'Vas', type: 'number', fase_dato: 'Fase_Dato_Vas' }, 
				],
			filter_selected: this.configWidget && this.configWidget.filterSelected ? this.configWidget.filterSelected : { 
				presenza: [],
				tqr: [{min: null, max: null}],
				vas: [{min: null, max: null}],
			},
			viewMode: this.configWidget && this.configWidget.viewMode ? this.configWidget.viewMode : 'players', 
			filtered_data_prev: [],
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		filtered_players: function() {
			return _.filter(this.players, function(player) {
				var isPresent = true;
				for(var f in this.filter_options) {
					var filter = this.filter_options[f];
					if(filter.type == 'enum') {
						if(this.filter_selected[filter.key].length > 0) {
							if(player.stats.fase_form.hasOwnProperty(filter.fase_dato)) {
								var fase_dato_array = player.stats.fase_form[filter.fase_dato];	
								for (var i in fase_dato_array) {
									var dato = fase_dato_array[i];
									if(!this.filter_selected[filter.key].includes(dato[filter.key])) {
										isPresent = false;
										break;
									}		
								}
							} else {
								isPresent = false;
								break;
							}
						}		
					} else if(filter.type == 'number') {
						for(var i in this.filter_selected[filter.key]) { 
							if((this.filter_selected[filter.key][i].min !== null && this.filter_selected[filter.key][i].min != "") || (this.filter_selected[filter.key][i].max !== null && this.filter_selected[filter.key][i].max != "")) {
								if(player.stats.fase_form.hasOwnProperty(filter.fase_dato)) {
									var fase_dato_array = player.stats.fase_form[filter.fase_dato];	
									for (var j in fase_dato_array) {
										var dato = fase_dato_array[j];
										if(this.filter_selected[filter.key][i].min !== null && this.filter_selected[filter.key][i].min != "" && dato[filter.key] < this.filter_selected[filter.key][i].min) {
											isPresent = false;
											break;
										}
										if(this.filter_selected[filter.key][i].max !== null && this.filter_selected[filter.key][i].max != "" && dato[filter.key] > this.filter_selected[filter.key][i].max) {
											isPresent = false;
											break;
										}	
									}
								} else {
									isPresent = false;
									break;
								}
							}
						}
					}
				}				
				return isPresent;
			}.bind(this));
		},
		filtered_data: function() {
			var dati = [];
			this.players.forEach((player) => {
				Object.keys(player.stats.fase_form).forEach(key => {	
					player.stats.fase_form[key].forEach((dato) => {
						var isPresent = true;
						var filter = this.filter_options.find(f => f.fase_dato == key);
						if(filter) {
							if(filter.type == 'enum') {
								if(this.filter_selected[filter.key].length > 0) {
									if(!this.filter_selected[filter.key].includes(dato[filter.key])) {
										isPresent = false;
									}
								}
							} else if(filter.type == 'number') {
								for(var i in this.filter_selected[filter.key]) { 
									if((this.filter_selected[filter.key][i].min !== null && this.filter_selected[filter.key][i].min != "") || (this.filter_selected[filter.key][i].max !== null && this.filter_selected[filter.key][i].max != "")) {	
										if(this.filter_selected[filter.key][i].min !== null && this.filter_selected[filter.key][i].min != "" && dato[filter.key] < this.filter_selected[filter.key][i].min) {
											isPresent = false;
										}
										if(this.filter_selected[filter.key][i].max !== null && this.filter_selected[filter.key][i].max != "" && dato[filter.key] > this.filter_selected[filter.key][i].max) {
											isPresent = false;
										}					
									}
								}
							}
						}
						if(isPresent) {
							if(dato[key.replace("Fase_Dato_", "").toLowerCase()] !== null) {
								dato.name_table = player.name_table;
								dato.injured = player.injured;
								dato.fase_dato_type = key.replace("Fase_Dato_", "");
								dati.push(dato);
							}
						}
					});
				});		
			});
			dati = _.orderBy(dati, ['updated_at'], ['desc']);

			if(this.filtered_data_prev.length > 0) {
				dati.forEach((dato) => {
					var isNew = false;
					var findItem = this.filtered_data_prev.find(item => item.id == dato.id);
					if(findItem) {
						if(findItem.updated_at != dato.updated_at) {
							isNew = true;
						}
					} else {
						isNew = true;
					}
					dato.isNew = isNew;
				});
			}
			return dati;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.getPlayers();
		},
		seasonId() {
			this.getPlayers();
		},
		'ux.stats': {
			deep: true,
			handler: function() {
				this.getPlayers();
				if(this.isWidget) {
					this.$emit('updateConfigWidget', this.isWidget, 'faseForm', this.ux.stats.fase_form);
					this.$emit('updateConfigWidget', this.isWidget, 'faseFormSessione', this.ux.stats.fase_form_sessione);
				}
			}
		},
		'filter_selected': {
			deep: true,
			handler: function() {
				if(this.isWidget) {
					this.$emit('updateConfigWidget', this.isWidget, 'filterSelected', this.filter_selected);
				}
			}
		},
		viewMode() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'viewMode', this.viewMode);
			}
		},
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},

	},
	components: {
		PersoneSessioni: require('@/components/layout/Sessioni/PersoneSessioni.vue').default,
		DatiSessioni: require('@/components/layout/Sessioni/DatiSessioni.vue').default,
	},
	created: function () {
		this.getPlayers();
	},
	methods: {
		refreshData: function() {
			this.filtered_data_prev = _.cloneDeep(this.filtered_data);
			this.getPlayers();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		getOptions(key) {
			if(key == 'presenza') {
				return this.presenze_options;
			}
			return [];
		},

		showSoglie() {
			this.$refs.sogliaModal.show();
		},

		getPlayers: _.debounce(function() {

			this.players = [];
			this.loading = true;
			this.$http.post(
				this.$store.state.apiEndPoint + '/medical/players', { 
					team_id: this.rosaTeamId, 
					season_id: this.seasonId,
					stats: this.ux.stats
				}).then((response) => {
					if (response.ok)
					{
						if(response.data.players) {
							this.players = response.data.players.map(item => ({								
								name: item.cognome + ' ' + item.nome,
								name_table: item.cognome + ' ' + item.nome[0] + '.',
								id: item.id,
								ruolo: ( item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length > 0 ) ? item.last_carriera_sportiva.ruolo_ideale[0].nome : '',
								avatar: item.avatar_url,
								injuries: item.injuries_count,
								illnesses: item.illnesses_count,
								testrtp: item.test_count,
								stats: item.stats,
								injured: item.injured
							}));
						}
						this.loading = false;
					}
				}, (response) => {
				});
			}, 100)
	},
	filters: {
		date: function(i) {
			return moment(i).format('DD/MM/YYYY');
		}
	}
}
</script>