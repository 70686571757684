export const ROUTES_STATS = [
    {
        path: '/stats/dashboard',
        name: 'stats_dashboard',
        component: require('../components/pages/stats/dashboard.vue').default,
        meta: {
            module: 'stats',

            label: 'Stats',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'stats',
        }
    },
];
