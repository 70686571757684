<template>
	<div>

		<b-form-group label="Nome">
			<b-input v-model="team.name"></b-input>
		</b-form-group>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: [ 'team' ]
}

</script>