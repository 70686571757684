<template>
	<div>
		<div ref="chart" :style="'width: '+(width||400)+'px; height: '+ (height||400) +'px'"></div>
	</div>
</template>

<script>

var Auth = require('@/auth.js');
import moment from 'moment';
import videojs from 'video.js';
import _ from 'lodash';

export default {
	props: [ 'ds', 'label', 'width', 'height' ],
	methods: {
		buildGraph: function() {
			var chartDom = this.$refs.chart;
			var myChart = echarts.init(chartDom);
			myChart.setOption(this.options);
		}
	},
	watch: {
		ds: {
			deep: true,
			handler: function() {
				this.buildGraph();
			}
		}
	},
	mounted: function() {

		this.buildGraph();

	},
	computed: {
		options: function() {

			return {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999'
						}
					}
				},
				toolbox: {
					feature: {
						dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar'] },
						restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				legend: {
					data: ['Numero Infortuni', 'Durate Infortuni']
				},
				xAxis: [
					{
						type: 'category',
						data: _.keys(this.ds),
						axisPointer: {
							type: 'shadow'
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						name: 'Durata Infortuni',
						min: 0,
						max: _.maxBy(this.ds, 'amount'),
						interval: 50,
						axisLabel: {
							formatter: '{value} gg'
						}
					},
					{
						type: 'value',
						name: 'Numero Infortuni',
						min: 0,
						max: _.maxBy(this.ds, 'days'),
						interval: 5,
						axisLabel: {
							formatter: '{value}'
						}
					}
				],
				series: [
					{
						name: 'Durate Infortuni',
						type: 'bar',
						tooltip: {
							valueFormatter: function (value) {
								return value + ' giorni out';
							}
						},
						data: _.map(this.ds, 'days')
					},
					{
						name: 'Numero Infortuni',
						type: 'line',
						yAxisIndex: 1,
						tooltip: {
							valueFormatter: function (value) {
								return value + ' infortuni';
							}
						},
						data: _.map(this.ds, 'amount')
					}
				]
			};

		}
	}
}

</script>