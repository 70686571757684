<template>
	<b-card v-if="presets">

		<b-modal v-if="ux.modalConfig" visible @hide="ux.modalConfig = null" size="lg" title="Configura Alert">
			<Regola :contratto="contratto" :compact="true" :bonus="bonus" class="mb-1" :key="rule.posizione" v-for="rule, k in configContextRules" :rule="rule"></Regola>
		</b-modal>

		<template #header>
			<b-row>
				<b-col>
					<span v-if="ux.show == false && qualityCheck.alert">⚠️</span>
					<small>Bonus</small> {{ bonus.name }}
				</b-col>
				<b-col class="text-right">
					<small class="mr-1"><a href="" @click.prevent="$emit('duplicate')">Duplica</a></small>
					<small v-if="ux.show == false"><a href="" @click.prevent="ux.show = true">Espandi</a></small>
					<small v-if="ux.show == true"><a href="" @click.prevent="ux.show = false">Collassa</a></small>
				</b-col>
			</b-row>
		</template>
		<template #footer v-if="ux.show">
			<small><a href="" @click.prevent="do_removeBonus">Elimina Bonus</a></small>
		</template>

		<div v-show="ux.show">

			<h5 class="font-bold">Descrittivi ed Area Economica</h5>

			<b-form-group label="Nome del Bonus">
				<b-input v-model="bonus.name"></b-input>
			</b-form-group>

			<b-form-group label="Descrizione del Bonus">
				<b-textarea v-model="bonus.description"></b-textarea>
			</b-form-group>

			<!-- {{ rulesArchive }} -->
			<Modal-New-Rule ref="modalNew" :position="maxPosition" @add="bonus.rules.push($event)"></Modal-New-Rule>
			<Modal-Explore-Presets ref="modalExplore" :contratto="contratto" :bonus="bonus"></Modal-Explore-Presets>
			<Modal-Explore-Presets ref="modalExploreWatchdogs" :watchdog="true" :contratto="contratto" :bonus="bonus"></Modal-Explore-Presets>
			
			<b-row class="d-flex align-items-center">
				<b-col>
					<b-form-group class="small" label="Importo Bonus Lordo">
						<b-input type="number" step="0.01" v-model.number="bonus.importo"></b-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group class="small" label="Importo Bonus Netto">
						<b-input type="number" step="0.01" v-model.number="bonus.netto"></b-input>
					</b-form-group>
				</b-col>
				<b-col>

					<div :class="{'text-center': !bonus.netto_garantito}">
						<label><b-checkbox size="sm" v-model="bonus.netto_garantito" :true-value="1" :false-value="0"> Importo Netto Garantito</b-checkbox></label>
					</div>
					<b-form-group class="small" v-if="bonus.netto_garantito" label="Clausola Importo Netto Garantito">
						<b-textarea size="sm" v-model="bonus.clausola_netto_garantito" :max-rows="3"></b-textarea>
					</b-form-group>
				</b-col>
				<b-col>
					<div :class="{'text-center': !bonus.decreto_crescita}">
						<label><b-checkbox size="sm" v-model="bonus.decreto_crescita" :true-value="1" :false-value="0"> Decreto Crescita</b-checkbox></label>
					</div>
					<b-form-group class="small" v-if="bonus.decreto_crescita" label="Clausola Decreto Crescita">
						<b-textarea size="sm" v-model="bonus.clausola_decreto_crescita" :max-rows="3"></b-textarea>
					</b-form-group>

				</b-col>
				
			</b-row>

			<b-form-group label="Periodo di Riferimento">
				<b-select v-model="bonus.persona_contratto_periodo_id">
					<b-select-option :value="0" disabled>Seleziona il Periodo</b-select-option>
					<b-select-option v-for="periodo in periodi" :value="periodo.value">{{ periodo.label }}</b-select-option>
				</b-select>
			</b-form-group>

			<hr />
			
			<bonus-pagamento-planner :periodo="periodoRiferimento" :bonus="bonus"></bonus-pagamento-planner>

					<!--<div>
						<label style="display: block"><input type="radio" v-model="bonus.con_ultima_mensilita" :value="1"> Pagamento con <strong>ultima mensilità</strong></label>
						<label style="display: block"><input type="radio" v-model="bonus.con_ultima_mensilita" :value="0"> Pagamento con <strong>primo pagamento utile</strong></label>
						<label style="display: block"><input type="radio" v-model="bonus.con_ultima_mensilita" :value="-1"> Pagamento <strong>in data</strong></label>
					</div>
					<b-form-group v-if="bonus.con_ultima_mensilita == -1" label="Data Pagamento">
						<b-input type="date"></b-input>
					</b-form-group>-->
					

			<hr />
			<h5 class="font-bold">Regola del Bonus</h5>


			<div v-if="bonus.rules.length > 0" class="mt-2">

				<div v-if="!bonus.preset_id">
					<b-alert variant="secondary" class="mb-2" show>
						Questo bonus è stato progettato prima dell'introduzione dei preset; Il <strong>contratto continuerà a funzionare correttamente.</strong>
					</b-alert>

					<div class="text-right mb-2">
						<a href="javascript:;" @click.prevent="expandAll">Espandi tutte le Regole</a> / <a href="javascript:;" @click.prevent="collapseAll">Collassa tutte le regole</a>
					</div>

				</div>
				<div v-else>

					<b-card :title="preset.nome">
						<b-card-text>{{ preset.descrizione }}</b-card-text>
						<template #footer>
							<a href="javascript:;" @click.prevent="expandPreset">Configura Preset</a>
						</template>
					</b-card>

					<div v-show="expandedPreset" class="mt-2">
						<Regola :contratto="contratto" :nomultitarget="true" :compact="true" :bonus="bonus" ref="rule" class="mb-1" :key="rule.posizione" v-for="rule, k in bonusRealRules" :rule="rule" @removerule="do_removeRule(k)"></Regola>
					</div>

					<b-button class="mt-4" size="xs" variant="info" @click.prevent="replaceWithPreset">Rimuovi Preset</b-button>

				</div>

				<div v-if="!bonus.preset_id">

					<Regola :contratto="contratto" :bonus="bonus" ref="rule" class="mb-1" :key="rule.posizione" v-for="rule, k in bonus.rules" :rule="rule" @removerule="do_removeRule(k)"></Regola>
				
					<b-button class="mt-4" size="xs" variant="info" @click.prevent="replaceWithPreset">Sostituisci queste regole con un preset</b-button>
				</div>

			</div>
			<div v-else>
				<b-button size="lg" variant="outline-primary" @click.prevent="$refs.modalExplore.show()">Aggiungi Regola</b-button>
			</div>

			<hr />
			<h5 class="font-bold">Ripetibilità</h5>
			<b-form-group>

				<b-form-select v-model="bonus.multiplier_type">
					<b-form-select-option value="once">Una Tantum</b-form-select-option>
					<b-form-select-option value="repeat">Ripetibile</b-form-select-option>
					<b-form-select-option value="amount">Moltiplicatore</b-form-select-option>
					<b-form-select-option value="custom">Personalizzato</b-form-select-option>
				</b-form-select>

				<div class="my-1" v-if="bonus.multiplier_type == 'repeat'">

					<b-form-select v-model="bonus.multiplier">
						<b-form-select-option :value="''" disabled>Seleziona la regola per il calcolo del modulo.</b-form-select-option>
						<b-form-select-option v-for="opt in rulesWtarget" :value="'rule' + opt.posizione">{{ opt.name }}</b-form-select-option>
					</b-form-select>

				</div>

				<div class="my-1" v-if="bonus.multiplier_type == 'amount'">
					
					<b-form-select v-model="bonus.multiplier">
						<b-form-select-option :value="''" disabled>Seleziona la regola per il calcolo del moltiplicatore.</b-form-select-option>
						<b-form-select-option v-for="opt in bonus.rules" :value="'rule' + opt.posizione + '.amount'">{{ opt.name }}</b-form-select-option>
					</b-form-select>

					<b-form-group label="Moltiplicatore Massimo" description="Se 0, il limite non ci sarà.">
						<b-input type="number" v-model="bonus.multiplier_max" :step="1"></b-input>
					</b-form-group>

				</div>

				<b-textarea v-if="bonus.multiplier_type == 'custom'" class="mt-1" @focus="ux.editorValidazione = 'multiplier'" v-model="bonus.multiplier"></b-textarea>

			</b-form-group>


			<b-alert variant="danger" v-if="qualityCheck.alert" show>

				<p class="lead">Attenzione, <strong>questo BONUS ha dei problemi.</strong></p>

				<div v-for="area in validationsArea">
					<div v-if="area.bucket && qualityCheck.missingRules[area.bucket].length > 0">
						<u>{{area.name}}</u> utilizza delle REGOLE inesistenti.
						<ul>
							<li v-for="single in qualityCheck.missingRules[area.bucket]">
								<code>{{single}}</code>
							</li>
						</ul>
					</div>
				</div>

				<div v-if="qualityCheck.noRule">
					<p>Questo BONUS <strong>non ha nessuna regola</strong>, e nessuna <strong>Regola di Validazione</strong></p>
				</div>
				

			</b-alert>

			<div v-if="canInheritedWatchdogs" class="my-2">

				<hr />
				<h5 class="font-bold">Alerts del Preset</h5>
				<b-row>
					<b-col cols="4" v-for="rule in watchDogInherith">
						<b-card>

							<b-form-group :label="rule.rule.name">

								<div class="d-flex align-items-center" v-for="t,ee in rule.targets">

									<div class="w-100" v-if="realRule(rule.rule.regola).payload[realRule(rule.rule.regola).behaviour.multitarget].type == 'int'">
										<b-input @change="concrete(rule)" type="number" v-model.number="rule.targets[ee]"></b-input>
									</div>
									
									<div class="w-100" v-if="realRule(rule.rule.regola).payload[realRule(rule.rule.regola).behaviour.multitarget].type == 'from'">
										<Type-From :bonus="bonus" @change="concrete(rule)" :contratto="contratto" :from="type.from" :from-params="multitargetField.from_params" :selected="rule.targets[ee]" @update="v = $event"></Type-From>
									</div>
									
									<div class="w-100" v-if="realRule(rule.rule.regola).payload[realRule(rule.rule.regola).behaviour.multitarget].type == 'bool'">
										<label><input @change="concrete(rule)" type="checkbox" v-model="rule.targets[ee]" :value-true="1" :value-false="0"> Sì</label>
									</div>
									
									<div class="w-100" v-if="realRule(rule.rule.regola).payload[realRule(rule.rule.regola).behaviour.multitarget].type == 'date'">
										<b-input type="date" @change="concrete(rule)" v-model="rule.targets[ee]"></b-input>
									</div>

									<!-- <b-input class="mt-1" v-model.number="rule.targets[ee]" @change="concrete(rule)"></b-input> -->
									<div class="px-1"><a href="javascript:;" @click.prevent="rule.targets.splice(ee, 1)">Rimuovi</a></div>
								</div>

								<div v-if="rule.targets.length == 0" class="text-muted">Nessun alert per la regola</div>

								<a href="javascript:;" @click.prevent="rule.targets.push(null)">Aggiungi</a>
							</b-form-group>

						</b-card>
					</b-col>
				</b-row>

				<!--
				<pre>{{watchDogInherith}}</pre>
				<pre>{{inheritedWatchdogs}}</pre>
				<div>Watchdogs from rules?!</div>-->
			</div>
			<!-- -->

			<hr />
			<h5 class="font-bold">Alerts Personalizzati</h5>

			<b-card size="sm" v-for="watchdog, k in bonus.watchdogs" class="mb-2">
				<template #header>Alert {{ k+1 }}</template>
				<template #footer>
					<b-btn @click.prevent="removeAlert(watchdog, k)">Rimuovi</b-btn>
				</template>
				<b-row>
					<b-col cols="3">
						
						<div v-if="!watchdog.preset_id && watchdog.validation" class="text-center">
							<b-alert show variant="info">Questo alert è stato progettato senza un preset.</b-alert>
						</div>
						<div v-else-if="!watchdog.preset_id">
							<b-button-group>
								<b-button size="sm" @click.prevent="addPresetToWatchdog(watchdog)">Aggiungi Regola</b-button>
								<b-button size="sm" @click.prevent="duplicateMaturationRule(watchdog)">Duplica Regola di Maturazione</b-button>
							</b-button-group>
						</div>
						<div v-else>

							<b-card :title="infoPreset(watchdog.preset_id).nome">
								<b-card-text>{{ infoPreset(watchdog.preset_id).descrizione }}</b-card-text>
								<template #footer>
									<a href="javascript:;" @click.prevent="configureModal(watchdog.ownedRulesJson)">Configura Preset</a>, 
									<a class="small" href="javascript:;" @click.prevent="removePreset(watchdog, watchdog.ownedRulesJson)">Rimuovi Preset</a>
								</template>
							</b-card>

							
						</div>

					</b-col>
					<b-col cols="9">
						<b-form-group label="Nome dell'Alert">
							<b-input v-model="watchdog.name"></b-input>
						</b-form-group>
						<b-form-group label="Descrizione dell'Alert">
							<b-textarea v-model="watchdog.description"></b-textarea>
						</b-form-group>
						<b-form-group label="Regola di Validazione" v-if="!watchdog.preset_id && watchdog.validation.length > 0">
							<b-textarea v-model="watchdog.validation"></b-textarea>
						</b-form-group>
					</b-col>
				</b-row>
				
				<!--<b-row>
					<b-col v-if="ux.editorValidazione == 'watchdog_' + k">
						<Rule-Helper :watchdog="true" :fields="[ 'result', 'progress', 'amount' ]" @addr="onAddRule" :bonus-rules="bonus.rules" :rules="rulesArchive" @add="watchdog.validation += ' ' + $event"></Rule-Helper>
					</b-col>
					<b-col>
						<b-form-group label="Nome dell'Alert">
							<b-input v-model="watchdog.name"></b-input>
						</b-form-group>
						<b-form-group label="Descrizione dell'Alert">
							<b-textarea v-model="watchdog.description"></b-textarea>
						</b-form-group>
						<b-form-group label="Regola">
							<b-textarea @focus="ux.editorValidazione = 'watchdog_' + k" v-model="watchdog.validation"></b-textarea>
						</b-form-group>
					</b-col>
				</b-row>-->
									
			</b-card>

			<a href="" @click.prevent="addWatchdog">Aggiungi Alert</a>
			<div class="small text-muted" v-if="bonus.watchdogs.length">
				<a href="" @click.prevent="emptyAlerts">Svuota tutti gli alerts</a>
			</div>

			<!-- <b-row>
				<b-col v-if="ux.editorValidazione == 'watchdog'">
					<Rule-Helper :fields="[ 'result', 'progress', 'amount' ]" @addr="$refs.modalNew.show()" :bonus-rules="bonus.rules" :rules="rulesArchive" @add="bonus.watchdog += ' ' + $event"></Rule-Helper>
				</b-col>
				<b-col>

				</b-col>
			</b-row> -->

			<hr />			

			<div v-if="bonus_test" class="my-3">
				<b-row>
					<b-col cols="9">
						<table class="table small table-striped">
							<thead>
								<tr>
									<th>Regola</th>
									<th>Verificata</th>
									<th>Quantità</th>
									<th>Progresso</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="b in bonus_test.rules">
									<td>{{ b.entity.name }}</td>
									<td>{{ b.result ? '✅' : '✖️' }}</td>
									<td>{{ b.amount }}</td>
									<td>{{ b.progress }}</td>
								</tr>
							</tbody>
						</table>

						<div class="text-success" v-if="bonus_test.invalid_validation == false">
							La Regola di Validazione non ha errori.
						</div>
						<div class="text-danger" v-else>
							La Regola di Validazione ha degli errori.
							<pre>{{ bonus_test.invalid_validation }}</pre>
						</div>
						
						<div class="text-success" v-if="bonus_test.invalid_watchdog == false">
							La Regola del Watchdog non ha errori.
						</div>
						<div class="text-danger" v-else>
							La Regola del Watchdog ha degli errori.
							<pre>{{ bonus_test.invalid_watchdog }}</pre>
						</div>

					</b-col>
					<b-col cols="3">
						<b-alert show class="text-center" variant="success" v-if="bonus_test.result">
							Il Bonus <strong>è</strong> Maturato
						</b-alert>
						<b-alert show class="text-center" variant="secondary" v-else>
							Il Bonus <strong>non è</strong> Maturato
						</b-alert>
						<hr />
						Attuale Moltiplicatore: <code>{{ bonus_test.multiplier_value }}</code>
					</b-col>
				</b-row>
			</div>

			<b-button size="sm" class="mt-3" variant="outline-info" @click.prevent="do_test(bonus)">Prova il Bonus e le Regole</b-button>

			<hr />
			<h5 class="font-bold">Note</h5>
			
			<NoteManager entity="Persona_Contratto_Bonus" :entityId="bonus.id"></NoteManager>

		</div>

		<div v-show="!ux.show">
			<b-row align-v="center">
				<b-col :cols="5">
					<b-row align-v="center">
						<b-col cols="2" class="text-center" style="line-height: 1">
							<strong class="small">Num. Regol{{ validationRules.length == 1 ? 'a' : 'e' }}</strong>
							<div>{{ validationRules.length }}</div>
						</b-col>
						<b-col class="small">
							<div v-if="bonus.description.length">
								<div><strong>{{ bonus.name }}</strong></div>
								{{ bonus.description }}
							</div>
							<ol class="m-0 ml-0 list-unstyled" v-else>
								<li :style="a%2 == 0 ? 'background-color: rgba(0,0,0,.05)' : ''" class="p-1" v-for="r,a in validationRules">{{ r.name }}</li>
							</ol>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="4" class="text-center small" style="line-height: 1">
					
					<div class="esmall">
						Importo
						<strong class="text-muted">
							<span v-if="bonus.multiplier_type == 'once'">1 Tantum</span>
							<span v-if="bonus.multiplier_type == 'repeat'">Ripetibile</span>
							<span v-if="bonus.multiplier_type == 'amount'">Moltiplicata</span>
						</strong>
					</div>

					<p class="lead m-0">{{bonus.importo | money }}</p>

				</b-col>
				<b-col cols="3" class="small text-center">
					<!-- Modalità con la quale si consuma il pagamento -->
					<div v-if="!bonus.payments_rules" class="text-muted">Nessuna configurazione di pagamento</div>
					<div v-else>
						<div class="mb-2" v-if="bonus.paymentsRulesJson.length > 1">In <strong v-html="bonus.paymentsRulesJson.length"></strong> pagamenti.</div>
						<div v-html="formatterRules(bonus.paymentsRulesJson)"></div>
					</div>
					<!-- Modalità con la quale si consuma il pagamento -->
				</b-col>
			</b-row>
		</div>

	</b-card>
</template>


<script>

import _ from 'lodash'
import moment from 'moment'

export default {
	props: [ 'bonus', 'rulesArchive', 'contratto', 'periodi' ],
	components: {
		BonusPagamentoPlanner: require('./BonusPagamentoPlanner.vue').default,
		Regola: require('./Regola.vue').default,
		NoteManager: require('@/components/layout/notes/manager.vue').default,
		ModalNewRule: require('./ModalNewRule.vue').default,
		ModalExplorePresets: require('./ModalExplorePresets.vue').default,
		RuleHelper: require('./RuleHelper.vue').default,
		TestBlockly: require('@/components/layout/contratti/testBlockly.vue').default
	},
	created: function() {
		this.fetchPresets();
		if(this.bonus.id == undefined)
			this.ux.show = true;
	},
	computed: {
		canInheritedWatchdogs: function() {
			//console.log(this.bonus.id);
			var p = _.filter(_.map(this.bonus.rules, function(p) {
				return _.find(this.rulesArchive, { file_id: p.regola }).behaviour !== false;
			}.bind(this))).length;
			return p;
			//console.log(p);
			return false;
			return this.bonus.rules;
			return true;
		},
		inheritedWatchdogs: function() {
			return _.filter(this.bonus.rules, function(e) { return e.multitarget !== null; });
		},
		configContextRules: function() {
			return _.filter(this.bonus.rules, function(a) {
				return this.ux.modalConfig.ids.indexOf(a.posizione) !== -1;
			}.bind(this));
		},
		validationRules: function() {
			return _.filter(this.bonus.rules, function(p) {
				return !p.fromWatchdog
			});
		},
		bonusRealRules: function() {
			return _.filter(this.bonus.rules, function(a) {
				return !a.fromWatchdog;
			});
		},
		preset: function() {
			return _.find(this.presets, { id: this.bonus.preset_id });
		},
		periodoRiferimento: function() {
			return _.find(this.periodi, { value: this.bonus.persona_contratto_periodo_id });
		},
		rulesWtarget: function() {
			return _.filter(this.bonus.rules, function(a) {
				return JSON.parse(a.input_payload).target != undefined && JSON.parse(a.input_payload).target > 0;
			});
		},
		maxPosition: function() {

			return (_.max(_.map(this.bonus.rules, 'posizione')) || 0) + 1;

		},
		validationsArea: function() {
			return [
				{ name: 'Regola di Validazione', bucket: 'validation' },
				{ name: 'Moltiplicatore', bucket: 'multiplier' },
				{ name: 'Watchdog', bucket: 'watchdog' },
			];			
		},
		qualityCheck: function() {

			var validations = this.validationsArea;

			var qualityMissingRules = { validation: [], multiplier: [], watchdog: [] };

			var regex = /rule(\d+)\.(amount|progress|result)/gm; // this is the rule seeker :D

			_.each(validations, function(a) {
				var m = false;
				var collectedRules = [];
				while ((m = regex.exec(this.bonus[a.bucket])) !== null)
					collectedRules.push(parseInt(m[1]));

				_.each(collectedRules, function(i) {

					var exists = _.find(this.bonus.rules, { posizione: i });
					if(!exists)
						qualityMissingRules[a.bucket].push('rule' + i);

				}.bind(this));

			}.bind(this));

			var noRule = this.bonus.rules.length == 0 && this.bonus.validation.length == 0

			return {
				alert: noRule || qualityMissingRules.validation.length > 0 || qualityMissingRules.multiplier.length > 0 || qualityMissingRules.watchdog.length > 0,
				missingRules: qualityMissingRules,
				noRule
			}

		}
	},
	watch: {
		'inheritedWatchdogs': {
			deep: true,
			handler: function() {
				if(this.inheritedWatchdogs) {

					var alerts = [];
					_.each(this.inheritedWatchdogs, function(p) {

						var local = [];
						var info = {
							rule: p,
							targets: null
						};

						_.each(JSON.parse(p.multitarget), function(v) {
							local.push(v);
						}.bind(this));

						info.targets = local;

						alerts.push(info);

					}.bind(this));

					this.watchDogInherith = alerts;
				}
			},
			immediate: true
		},
		'bonus.netto_garantito': function() {
			if(this.bonus.netto_garantito == false)
				this.bonus.clausola_netto_garantito = '';
		},
		'bonus.rules': {
			deep: true,
			handler: function() {
				//this.$refs.beditor.reBlockly();
			}
		},
		'bonus.multiplier_type': {
			deep: true,
			handler: function() {
				if(this.bonus.multiplier_type == 'once')
					this.bonus.multiplier = 1;
				else
					this.bonus.multiplier = '';
			}
		},
		'bonus.validation': {
			deep: true,
			handler: _.debounce(function() {
				//console.log('bonus.validation');
			}, 300)
		},
		'bonus.multiplier': {
			deep: true,
			handler: _.debounce(function() {
				//console.log('bonus.multiplier');
			}, 300)
		},
		'bonus.watchdog': {
			deep: true,
			handler: _.debounce(function() {
				//console.log('bonus.watchdog');
			}, 300)
		},
	},
	methods: {
		realRule: function(name) {
			return _.find(this.rulesArchive, { file_id: name });
		},
		concrete: function(what) {
			var rule = _.find(this.bonus.rules, { posizione: what.rule.posizione });
			//console.log(rule);
			this.$set(rule, 'multitarget', JSON.stringify(what.targets));
		},
		addPresetToWatchdog: function(watchdog) {
			this.$refs.modalExploreWatchdogs.watchdogRef = watchdog;
			this.$refs.modalExploreWatchdogs.show();
		},
		removePreset: function(watchdog, rules) {

			watchdog.preset_id = null;
			watchdog.validation = '';
			_.each(rules, function(b) {
				var k = _.findIndex(this.bonus.rules, { posizione: b });
				this.bonus.rules.splice(k, 1);
			}.bind(this));

		},
		configureModal: function(rules) {
			console.log('Set Rules', rules);
			this.ux.modalConfig = {
				ids: rules
			}

		},
		infoPreset: function(id) {
			return _.find(this.presets, { id });
		},
		emptyAlerts: function() {
			this.bonus.watchdogs = [];
			this.bonus.rules = _.filter(this.bonus.rules, function(a) {
				return !a.fromWatchdog
			})
		},
		duplicateMaturationRule: function(watchdog) {

			var rulesToDuplicate = _.filter(this.bonus.rules, function(a) {
				return !a.fromWatchdog;
			});

			var validation = this.bonus.validation;

			// Now we need to cycle all the rules and fix the index..
			var currentMax = 1;
			if(this.bonus.rules.length > 0)
				currentMax = _.maxBy(this.bonus.rules, 'posizione').posizione + 1;

			var toCreate = [];
			var toReplace = [];
			var a = 0;

			_.each(rulesToDuplicate, function(i) {

				var o = {
					fromWatchdog: true,
					name: 'WatchDog ' + i.name,
					description: 'WatchDog ' + i.description,
					regola: i.regola,
					input_payload: i.input_payload,
					multitarget: i.multitarget,
					posizione: currentMax + (a++)
				};

				toCreate.push(o);
				toReplace.push([i.posizione, o.posizione])

			}.bind(this));

			_.each(toCreate, function(i) {
				this.bonus.rules.push(i);
			}.bind(this));

			console.log(toCreate, toReplace);

			_.each(toReplace, function(pair) {
				validation = validation.replace('rule'+pair[0], 'rule'+pair[1]);
			});

			watchdog.name = this.infoPreset(this.bonus.preset_id).nome;
			watchdog.description = this.infoPreset(this.bonus.preset_id).descrizione;
			watchdog.validation = validation;
			watchdog.preset_id = this.bonus.preset_id;
			watchdog.ownedRulesJson = _.map(toCreate, 'posizione');

		},
		onAddRule: function(fromWatchdog) {

			this.$refs.modalNew.show();
			if(fromWatchdog) {
				this.$refs.modalNew.fromWatchdog = true;
			}

		},
		replaceWithPreset: function() {
			this.bonus.rules = [];
		},
		formatterRules: function(payment_rule) {

			var out = ['<table class="table table-striped">'];
			_.each(payment_rule, function(sin) {
				out.push('<tr><td>');
				if(sin.type == 'none') {
					out.push('<em>Nessuna Data</em>');
				} else if(sin.type == 'month' && sin.onComplete) { 
					out.push('<em>Mensilità di <strong>Maturazione</strong></em>');
				} else if(sin.type == 'date') {
					out.push('<em>In Data: <strong>' + (moment(sin.date).format('DD/MM/YYYY')) + '</strong></em>');
				}
				out.push('</td>');
				out.push('<td>');
				out.push(sin.amount.toFixed(2) + ' €')
				out.push('</td>');
				out.push('</tr>');
			}.bind(this));

			out.push('</table>');
			return out.join(' ');

		},
		removeAlert: function(watchdog, k) {

			if(this.bonus.watchdogs[k].validation.length == 0 || window.confirm('Confermi la rimozione dell\'Alert?')) {

				_.each(watchdog.ownedRulesJson, function(b) {
					var kk = _.findIndex(this.bonus.rules, { posizione: b });
					this.bonus.rules.splice(kk, 1);
				}.bind(this));

				this.bonus.watchdogs.splice(k, 1);

			}

		},
		addWatchdog: function() {

			// has some bad validators?
			if(_.filter(this.bonus.watchdogs, function(single) {
				return single.validation.length == 0;
			}).length > 0) return false;

			this.bonus.watchdogs.push({
				name: '',
				description: '',
				validation: '',
				preset_id: null,
				ownedRules: null,
				ownedRulesJson: []
			});

		},
		expandAll: function() {
			_.each(this.$refs.rule, function(a) {
				a.ux.show = true;
			});
		},
		collapseAll: function() {
			_.each(this.$refs.rule, function(a) {
				a.ux.show = false;
			});
		},
		do_test: function(bonus) {
			this.$http.post(
				this.$store.state.apiEndPoint + '/contratto/' + this.contratto.id + '/test-bonus', 
				{ bonus }
			).then(function(data) {
				this.bonus_test = data.data;
			})
		},
		mr: function(a,b) {
			return a[b];
		},
		do_removeRule: function(ruleIndex) {

			this.bonus.rules.splice(ruleIndex, 1);

		},
		do_removeBonus: function() {
			var confirm = window.confirm('Vuoi davvero procedere con la rimozione del bonus?');

			if(confirm)
				this.$emit('remove');

		},
		expandPreset: function() {
			if(this.expandedPreset) {
				this.expandedPreset = false;
			} else {
				this.expandAll();
				this.expandedPreset = true;
			}
		},
		fetchPresets: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/presets-rules-public').then(function(data) {
				this.presets = data.data;
			})
		}
	},
	filters: {
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return formatter.format(v).replace(' €', '');
		},
	},
	data: function() {
		return {
			expandedPreset: false,
			bonus_test: false,
			presets: null,
			watchDogInherith: null,
			ux: {
				modalConfig: null,
				area: 'editor',
				show: false,//true,//false,
				editorValidazione: false
			}
		}
	}
};

</script>