<template>
	<div>
	<b-input-group>
		<b-input-group-prepend is-text v-if="true">
			<b-img v-if="model.selected && lingue.find(item => item.id == model.selected)" :src="`assets/flags/flags-iso/shiny/32/${lingue.find(item => item.id == model.selected).codice.toUpperCase()}.png`" width="24" class="mr-1"/>
			<b-img v-else src="assets/flags/flags-iso/shiny/32/_unknown.png" width="24" class="mr-1"/>
		</b-input-group-prepend>
		<b-form-select v-model="model.selected">
			<b-form-select-option v-for="lingua in lingue" :value="lingua.id" v-if="isLinguaUsata(lingua.id)">{{lingua.nome}}</b-form-select-option>
		</b-form-select>
		<b-input-group-append>
			<b-input-group-text v-if="!mustTranslate">
				<b-form-checkbox v-if="item.id == model.selected" v-for="item in lingue" :value="item.id" v-model="model.master" :disabled="model.master == item.id || readonly">Master</b-form-checkbox>
			</b-input-group-text>
			<b-input-group-text class="pr-0" v-if="hasTranslate && !mustTranslate">
				<b-form-checkbox v-if="hasDeepL" class="mr-2" value="deepl" v-model="model.provider"><b-img :src="`assets/varie/deepl.png`" width="20" height="20"/></b-form-checkbox>
				<b-form-checkbox v-if="hasGoogle" class="mr-2" value="google" v-model="model.provider"><b-img :src="`assets/varie/google.png`" width="20" height="20"/></b-form-checkbox>
			</b-input-group-text>
			<b-input-group-text class="pr-0" v-else-if="hasTranslate && mustTranslate">
				<b-form-radio v-if="hasDeepL" class="mr-2" value="deepl" v-model="model.provider"><b-img :src="`assets/varie/deepl.png`" width="20" height="20"/></b-form-radio>
				<b-form-radio v-if="hasGoogle" class="mr-2" value="google" v-model="model.provider"><b-img :src="`assets/varie/google.png`" width="20" height="20"/></b-form-radio>
			</b-input-group-text>
		</b-input-group-append>
	</b-input-group>
</div>
</template>

<script>
var Auth = require('@/auth.js');

export default {
	props: ['lingue', 'model', 'readonly', 'mustTranslate'],
	computed: {
		auth: function() {
			return Auth;
		},
		hasTranslate: function() {
			return this.hasGoogle || this.hasDeepL;
		},
		hasGoogle: function() {
			return this.auth.isUserEnable('translate_google');
		},
		hasDeepL: function() {
			return this.auth.isUserEnable('translate_deepl');
		},
		infoLingue: function() {


			var info = _.map(this.lingue, function(i) {
				if(this.model.provider != false || !this.readonly)
					i.disabled = false;
				else {
					i.disabled = this.lingueUsate.indexOf(i.id) === -1;
				}

				return i;
			}.bind(this));

			return info;

		},
	},
	methods: {
		isLinguaUsata: function(lingua) {
			if(this.model.provider != false || !this.readonly)
				return true;
			var safeFields = [ 'master', 'provider', 'selected' ];

			var chiavi = _.filter(_.keys(this.model), function(a) {
				return safeFields.indexOf(a) === -1;
			});

			var lingueIncontrate = [];
			_.each(chiavi, function(a) {

				var lingue = _.keys(_.pickBy(this.model[a], function(b) {
					return b && b.length > 0;
				}));

				_.each(lingue, function(a) {
					if(lingueIncontrate.indexOf(a) === -1)
						lingueIncontrate.push(parseInt(a));
				})

			}.bind(this));
			return lingueIncontrate.includes(lingua);
		},
	},
	watch: {
		model: {
			immediate: true,
			handler: function() {

				if(this.hasTranslate == false)
					this.$set(this.model, 'noTranslate', true);

				if(this.model.provider == undefined) {
					if(!this.mustTranslate)
						this.$set(this.model, 'provider', false);
					else
						this.$set(this.model, 'provider', this.hasDeepL ? 'deepl' : 'google');
				}

			}
		}
	}
}
</script>
