export const ROUTES_NEWSCOUT = [
    {
        path: '/newscout/dashboard',
        name: 'newscout_dashboard',
        component: require('../components/pages/newscout/archivio_giocatori.vue').default,
        meta: {
            module: 'newscout',
            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'newscout',
        }
    },
    {
        path: '/newscout/lista/in/campo/:id',
        name: 'newscout_lista_in_campo',
        component: require('../components/pages/newscout/lista_in_campo.vue').default,
        meta: {
            module: 'newscout',
            label: 'Lista',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'newscout',
            logModel: 'Liste',
            logModelId: 'id',
        }
    },
    {
        path: '/newscout/lista/in/campo/new/:id/:formazioneId?',
        name: 'newscout_lista_in_campo_new',
        // component: require('../components/pages/newscout/lista_in_campo.vue').default,
        component: require('../components/pages/newscout/lista_in_campo_dev.vue').default,
        meta: {
            module: 'newscout',
            label: 'Lista',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'newscout',
            logModel: 'Liste',
            logModelId: 'id',
        }
    },
    {
        path: '/next/new/new/giocatore',
        name: 'new_next_new_giocatore',
        component: require('../components/pages/next-scouting/giocatori/new.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_add_player',
        }
    }, 
    {
        path: '/newscout/view/giocatore/:id',
        name: 'newscout_view_giocatore',
        component: require('../components/pages/next-scouting/giocatori/view.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_nuova_valutazione',
            menusub: [ 'newscout_edit_giocatore', 'newscout_nuova_valutazione'],
            permesso: 'newscout',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/newscout/edit/giocatore/:id',
        name: 'newscout_edit_giocatore',
        component: require('../components/pages/next-scouting/giocatori/edit.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Modifica Anagrafica',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_view_giocatore',
            menusub: ['newscout_view_giocatore'],
            permesso: 'scout_edit_player',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/newscout/edit/valutazione/:id/:val_id',
        name: 'newscout_edit_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/edit.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_view_giocatore',
            menusub: ['newscout_view_giocatore', 'newscout_nuova_valutazione'],
            permesso: 'scout_edit_val',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/newscout/nuova/valutazione/:id',
        name: 'newscout_nuova_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/new.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_view_giocatore',
            menusub: ['newscout_view_giocatore'],
            permesso: 'scout_add_val',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/newscout/view/valutazione/:id/:val_id',
        name: 'newscout_view_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/view.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Valutazione Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'newscout_view_giocatore',
            menusub: ['newscout_view_giocatore', 'newscout_edit_valutazione', 'newscout_nuova_valutazione'],
            permesso: 'newscout',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/newscout/view/valutazione/football/:id/:val_id',
        name: 'newscout_view_valutazione_football',
        component: require('../components/pages/next-scouting/valutazioni/view_football.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Valutazione Football',
            requiresAuth: true,
            showInMenu: false,
            parent: 'newscout_view_giocatore',
            menusub: ['newscout_view_giocatore'],
            permesso: 'newscout',
            logModel: 'RosaValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/newscout/agenda',
        name: 'newscout_agenda',
        component: require('../components/pages/newscout/agenda.vue').default,
        meta: {
            module: 'newscout',
            label: 'Agenda',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_agenda',
        }
    },
    {
        path: '/next/new/partite',
        name: 'new_next_partite',
        component: require('../components/pages/next-scouting/partite.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Partite',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_partite',
        }
    },
    {
        path: '/newscout/partite-video',
        name: 'newscout_partite_video',
        component: require('../components/pages/newscout/partite-video.vue').default,
        meta: {
            module: 'newscout',
            label: 'Video',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout_partite',
        }
    },
    {
        path: '/newscout/videotag/giocatore/:id',
        name: 'newscout_videotag_giocatore',
        component: require('../components/pages/next-scouting/giocatori/videotag.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Videotag Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_view_giocatore',
            permesso: 'scout_videotag',
            logModel: 'Persona',
            logModelId: 'id',
        }
    }, 
    {
        path: '/newscout/partite/giocatore/:id',
        name: 'newscout_partite_giocatore',
        component: require('../components/pages/next-scouting/giocatori/partite.vue').default,
        meta: {
            area: 'new',
            module: 'newscout',
            label: 'Partite Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_view_giocatore',
            permesso: 'scout_partite',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/newscout/osservatori',
        name: 'newscout_osservatori',
        component: require('../components/pages/newscout/osservatori.vue').default,
        meta: {
            module: 'newscout',
            label: 'Osservatori',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_utenti',        
        }
    },
    {
        path: '/newscout/osservatore/paesi/:id',
        name: 'newscout_osservatore_paesi',
        component: require('../components/pages/newscout/osservatore_paesi.vue').default,
        meta: {
            module: 'newscout',
            label: 'Paesi',
            requiresAuth: true,
            showInMenu: false,
            parent: 'newscout_osservatori',
            permesso: 'scout_utenti',
            menusub: ['newscout_osservatore_paesi', 'newscout_osservatore_liste', 'newscout_osservatore_km'],
            logModel: 'Persona_Utente',
            logModelId: 'id',         
        }
    },
    {
        path: '/newscout/osservatore/liste/:id',
        name: 'newscout_osservatore_liste',
        component: require('../components/pages/newscout/osservatore_liste.vue').default,
        meta: {
            module: 'newscout',
            label: 'Liste',
            requiresAuth: true,
            showInMenu: false,
            parent: 'newscout_osservatori',
            permesso: 'scout_utenti',  
            menusub: ['newscout_osservatore_paesi', 'newscout_osservatore_liste', 'newscout_osservatore_km'],
            logModel: 'Persona_Utente',
            logModelId: 'id',   
        }
    },
    {
        path: '/newscout/osservatore/km/:id',
        name: 'newscout_osservatore_km',
        component: require('../components/pages/newscout/osservatore_km.vue').default,
        meta: {
            module: 'newscout',
            label: 'Kilometri',
            requiresAuth: true,
            showInMenu: false,
            parent: 'newscout_osservatori',
            permesso: 'scout_km',  
            menusub: ['newscout_osservatore_paesi', 'newscout_osservatore_liste', 'newscout_osservatore_km'], 
            logModel: 'Persona_Utente',
            logModelId: 'id',  
        }
    },
    {
        path: '/newscout/squadre',
        name: 'newscout_squadre',
        component: require('../components/pages/newscout/squadre.vue').default,
        meta: {
            module: 'newscout',
            label: 'Squadre',
            requiresAuth: true,
            showInMenu: true,
            parent: '',  
            permesso: 'scout_squadre',    
        }
    },
    {
        path: '/newscout/view/squadra/:id',
        name: 'newscout_view_squadra',
        component: require('../components/pages/newscout/view_squadra.vue').default,
        meta: {
            module: 'newscout',
            label: 'Scheda Squadra',
            requiresAuth: true,
            showInMenu: true,
            permesso: 'scout_squadre',
            logModel: 'Squadre',
            logModelId: 'id',  
        }
    },
    {
        path: '/newscout/squadre/nuova',
        name: 'newscout_squadre_new',
        component: require('../components/pages/newscout/new_squadra.vue').default,
        meta: {
            module: 'newscout',

            label: 'Nuova Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_squadre',
            permesso: 'scout_squadre',
        }
    },

    {
        path: '/newscout/squadre/edit/:id',
        name: 'newscout_squadre_edit',
        component: require('../components/pages/newscout/edit_squadra.vue').default,
        meta: {
            module: 'newscout',
            label: 'Modifica Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_view_squadra',
            permesso: 'scout_squadre',
            logModel: 'Squadre',
            logModelId: 'id',
        }
    },

    {
        path: '/newscout/view/allenatore/:id',
        name: 'newscout_view_allenatore',
        component: require('../components/pages/newscout/view_allenatore.vue').default,
        meta: {
            module: 'newscout',
            label: 'Scheda Allenatore',
            requiresAuth: true,
            showInMenu: true,
            permesso: 'scout_squadre',
            logModel: 'Persona',
            logModelId: 'id',  
        }
    },
    {
        path: '/newscout/appunti/:id',
        name: 'newscout_appunti',
        component: require('../components/pages/newscout/appunti.vue').default,
        meta: {
            module: 'newscout',
            label: 'Appunti',
            requiresAuth: true,
            showInMenu: true,
            parent: 'newscout_view_giocatore',
            menusub: ['newscout_view_giocatore'],
            permesso: 'scout_appunti',
            logModel: 'Osservati',
            logModelId: 'id',  
        }
    },
];
