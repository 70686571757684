<template id="esercizifiltri">
    <form v-on:submit.prevent="submit()" id="form" action="#" method="POST">
        <div class="esercizifiltri">
            <div class="form-row">
                <b-input-group class="mb-2">
                    <b-form-input type="text" v-model="title" placeholder="Cerca per titolo/autore..."></b-form-input>
                    <b-input-group-append>
                        <b-button @click="reset"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
                        <b-button variant="primary" @click="submit"><i class="fa fa-search" aria-hidden="true"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
            <div class="text-right">
                <b-button @click="switchFilter" size="sm">{{ portiere ? 'passa ai filtri giocatore' : 'passa ai filtri portiere'}}</b-button>
            </div>

            <div class="form-row mb-3" v-for="(filter, index) in filters" :key="index" v-if="!portiere">
                <label class="color-rosso font-bold text-uppercase">{{ filter.nome }}</label>
                <div class="form-row">
                    <div class="form-group col-sm-12 mb-1" v-for="item in filter.campi" :key="item.id">
                        <b-list-group v-if="team && !team.categoria.only_root_skill">
                            <b-list-group-item>
                                <b-form-checkbox v-if="!item.campi" :id="'sk' + item.id" :value="item.id" v-model="objective_ids" style="font-size:1rem;">
                                    {{ item.nome }}
                                </b-form-checkbox>
                                <label class="font-bold mb-0" v-if="item.campi" v-b-toggle="'accordion-' + item.id" style="cursor:pointer;font-size:1rem;">{{ item.nome }} </label>

                                <b-collapse :id="'accordion-' + item.id" accordion="my-accordion" role="tabpanel">
                                    <b-form-group v-for="item2 in item.campi" :key="item2.id" v-if="item.campi">
                                        <b-form-checkbox v-if="!item2.campi" :id="'sk' + item2.id" :value="item2.id" v-model="objective_ids" style="font-size:1rem;">
                                            {{ item2.nome }}
                                        </b-form-checkbox>
                                        <label class="font-italic text-uppercase ml-3" v-if="item2.campi"> {{ item2.nome }} </label>
                                        <b-form-group v-for="item3 in item2.campi" :key="item3.id" v-if="item2.campi">
                                            <b-form-checkbox  :id="'sk' + item3.id" :value="item3.id" v-model="objective_ids" style="font-size:1rem;">
                                                {{ item3.nome }}
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-form-group>
                                </b-collapse>
                            </b-list-group-item>
                        </b-list-group>
                        <b-list-group v-if="team && team.categoria.only_root_skill">
                            <b-list-group-item>
                                <b-form-checkbox :id="'sk' + item.id" :value="item.id" v-model="objective_ids" style="font-size:1rem;">
                                    {{ item.nome }}
                                </b-form-checkbox>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </div>
            </div>

            <div class="form-row mb-3" v-for="(filter, index) in filters_portiere" :key="index" v-if="portiere">
                <label class="color-rosso font-bold text-uppercase">{{ filter.nome }}</label>
                <div class="form-row">
                    <div class="form-group col-sm-12 mb-1" v-for="item in filter.campi" :key="item.id">
                        <b-list-group v-if="team && !team.categoria.only_root_skill">
                            <b-list-group-item>
                                <b-form-checkbox v-if="!item.campi" :id="'sk' + item.id" :value="item.id" v-model="objective_ids" style="font-size:1rem;">
                                    {{ item.nome }}
                                </b-form-checkbox>
                                <label class="font-bold text-uppercase mb-0" v-if="item.campi" v-b-toggle="'accordion-' + item.id" style="cursor:pointer;font-size:1rem;">{{ item.nome }} </label>

                                <b-collapse :id="'accordion-' + item.id" accordion="my-accordion" role="tabpanel">
                                    <b-form-group v-for="item2 in item.campi" :key="item2.id" v-if="item.campi">
                                        <b-form-checkbox v-if="!item2.campi" :id="'sk' + item2.id" :value="item2.id" v-model="objective_ids" style="font-size:1rem;">
                                            {{ item2.nome }}
                                        </b-form-checkbox>
                                        <label class="font-italic text-uppercase ml-3" v-if="item2.campi"> {{ item2.nome }} </label>
                                        <b-form-group v-for="item3 in item2.campi" :key="item3.id" v-if="item2.campi">
                                            <b-form-checkbox  :id="'sk' + item3.id" :value="item3.id" v-model="objective_ids" style="font-size:1rem;">
                                                {{ item3.nome }}
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-form-group>
                                </b-collapse>
                            </b-list-group-item>
                        </b-list-group>
                        <b-list-group v-if="team && team.categoria.only_root_skill">
                            <b-list-group-item>
                                <b-form-checkbox :id="'sk' + item.id" :value="item.id" v-model="objective_ids" style="font-size:1rem;">
                                    {{ item.nome }}
                                </b-form-checkbox>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

import { mapState } from 'vuex';

export default {
    data: function(){
        return {
            portiere: false,
            team: null,
        }
    },

    props: {
        params: {
            type: Object,
            required: false
        }
    },

    created() {
        this.getTeam();
    },
    mounted() {
    },
    computed:{
        objective_ids: {
            get() {
                return this.$store.state.editor.objective_ids;
            },
            set(value) {
                this.$store.commit('editor/setObjIds', value);
            },
        },
        title: {
            get() {
                return this.$store.state.editor.title;
            },

            set(value) {
                this.$store.commit('editor/setTitle', value);
            },
        },
        filters() {
            return this.params.skills.filter(item => [1, 30, 61].includes(item.id));
        },

        filters_portiere() {
            return this.params.skills.filter(item => [86, 99, 124, 61].includes(item.id));
        },

        ...mapState('editor', ['exercises_tab']),
        ...mapState(['rosaTeamId']),
    },
    watch: {
        objective_ids() {
            this.submit();
        },
        rosaTeamId() {
            this.getTeam();   
        },  
    },
    methods: {
        submit() {
            this.$store.commit('editor/setPage', 1);
            if(this.exercises_tab == 0) {
                this.$store.dispatch('editor/filter');
            } else if(this.exercises_tab == 1) {
                this.$store.dispatch('editor/filter_asroma');
            } else if(this.exercises_tab == 2) {
                this.$store.dispatch('editor/filter_certificati');
            } else if(this.exercises_tab == 3) {
                this.$store.dispatch('editor/filter_waiting');
            } else if(this.exercises_tab == 4) {
                this.$store.dispatch('editor/filter_da_validare');
            }
            $('html,body').animate({ scrollTop: 0 }, 'slow');
        },
        reset() {
            this.title = '';
            this.submit();
        },
        switchFilter() {
            this.portiere = !this.portiere;
            this.$store.commit('editor/setObjIds', []);
        },
        getTeam() {
            if(this.rosaTeamId){
                this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
                    if (response.ok) {
                        this.team = response.data;
                    }
                }, (response) => {
                });
            }
        },
    }
}
</script>
