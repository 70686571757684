<template>
	<div>

		<div style="height: 30px; background-color: rgba(0,0,0,.1); position: relative">
			<div v-for="time in payload.intervals" :style="'position: absolute; left: '+ ((time.from/duration)*100) +'%; width: '+ (((time.to-time.from)/duration)*100) +'%; height: 100%; background-color: yellow'">
				{{time.name}} - ({{ time.to - time.from | duration }})
			</div>


			<div style="position: absolute; width: 1px; background-color: black; height: 100%" :style="'left: ' + 100*(currentTime/duration) + '%'"></div>

		</div>

		{{ currentTime }}

	</div>
</template>

<script>

import moment from 'moment';

export default {
	props: [ 'duration', 'payload', 'currentTime' ],
	filters: {
		duration: function(a) {
			return moment('2000-01-01 00:00:00').add(a, 'seconds').format('HH:mm:ss');
		}
	}
}

</script>