<template>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1452 871.3" style="enable-background:new 0 0 1452 871.3;" xml:space="preserve">
        <g id="sfondo">
          <rect id="XMLID_19_" x="64" y="718.3" class="st0" style="fill:#41903F;" width="1324" height="21.8"/>
          <rect id="XMLID_13_" x="64" y="740" class="st1" style="fill:#D1D1D1;" width="1324" height="33.8"/>
          <polygon id="XMLID_680_" class="st2" style="fill:#6AB75F;" points="64,718.2 1388,718.2 1164.3,118 287.7,118 	"/>
        </g>
        <g id="elementi">
          <polygon class="st3" style="opacity:0.7;fill:#FFFFFF;" points="110.8,591.6 1341.2,591.6 1336.7,580.5 115.3,580.5 	"/>
          <path id="XMLID_749_" class="st4" style="opacity:0.1;" d="M297.7,590.5l18.7,1.1c0.3-0.6,38.9-51.3,38.9-51.3l823.2,0.8l-44.8,50.4h16.8l51-57.7
            l-859.1-0.1L297.7,590.5z"/>
          <path id="XMLID_4780_" class="st5" style="opacity:0.25;fill:#FFFFFF;" d="M325.5,177.7c0,0,1.5,28.6,5,137.6s0.7,196.1,0.7,196.1h788c0,0-4.8-85.3-1.1-167
            c3.7-81.7,1.7-166.8,1.7-166.8s-167,2.1-396,2.1S325.5,177.7,325.5,177.7z"/>
          <rect id="XMLID_4779_" x="326.6" y="504.5" class="st6" style="fill:#B2B2B2;" width="794.3" height="12.5"/>
          <polygon id="XMLID_4778_" class="st6" style="fill:#B2B2B2;" points="326.4,504 300.6,578.4 300.6,586.6 312.6,586.6 338.5,516 	"/>
          <polygon id="XMLID_4777_" class="st6" style="fill:#B2B2B2;" points="1121.6,504 1147.4,578.4 1147.4,586.6 1135.3,586.6 1109.5,516 	"/>
          <polyline id="XMLID_4776_" class="st7" style="fill:none;stroke:#FFFFFF;stroke-width:19.2486;stroke-miterlimit:10;" points="306.6,591.6 306.6,226.2 1142,226.2 1142,591.6 	"/>
          <path id="XMLID_4775_" class="st8" style="fill:none;stroke:#FFFFFF;stroke-width:8.4961;stroke-miterlimit:10;" d="M306.6,222.5c0,0,1.9-22,10.9-36.9c10.1-16.7,11.8-4.3,8,29.9s-18.9,99.8-18.9,99.8"/>
          <path id="XMLID_21_" class="st8" style="fill:none;stroke:#FFFFFF;stroke-width:8.4961;stroke-miterlimit:10;" d="M1140.1,222.5c0,0-1.9-22-10.9-36.9c-10.1-16.7-11.8-4.3-8,29.9s18.9,99.8,18.9,99.8"/>
          <path id="XMLID_4773_" class="st5" style="opacity:0.25;fill:#FFFFFF;" d="M306.6,226.2l18.9-48.5c0,0,171.8,2.1,398.8,2.1s400.1-2.1,400.1-2.1l17.6,48.5H306.6z"/>
          <path id="XMLID_4772_" class="st9" style="opacity:0.5;fill:#FFFFFF;" d="M325.5,177.7c0,0,2,72.4,6.3,173.8c4.3,101.4,7.4,159.9,7.4,159.9s-28.4,72.9-35.3,67.8
            c-4.1-3.1,0-254.2,2.7-353l3.8-30.1L325.5,177.7z"/>
          <path id="XMLID_4771_" class="st9" style="opacity:0.5;fill:#FFFFFF;" d="M1119.8,177.7c0,0,2.1,75.4-2.2,176.8c-4.3,101.4,1.5,157,1.5,157s20.4,72.9,27.3,67.8
            c4.1-3.1,0-254.2-2.7-353L1119.8,177.7z"/>
          <rect id="XMLID_675_" x="315" y="236" class="st10" style="opacity:0.2;fill:#36A9E1;" width="818.6" height="177.8"/>
          <rect id="XMLID_734_" x="315" y="413.8" class="st11" style="opacity:0.2;fill:#F39200;" width="818.6" height="177.8"/>
          <rect id="XMLID_774_" x="315" y="236" class="st12" style="opacity:0.5;fill:#9D9D9C;" width="272.9" height="355.6"/>
          <rect id="XMLID_789_" x="860.7" y="236" class="st12" style="opacity:0.5;fill:#9D9D9C;" width="272.9" height="355.6"/>
          <rect id="XMLID_804_" x="315" y="236" class="st13" style="fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;" width="272.9" height="355.6"/>
          <rect id="XMLID_803_" x="860.7" y="236" class="st13" style="fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;" width="272.9" height="355.6"/>
          <rect id="XMLID_802_" x="587.9" y="236" class="st13" style="fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;" width="272.9" height="355.6"/>
          <line id="XMLID_2_" class="st13" style="fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;" x1="315" y1="413.8" x2="1133.6" y2="413.8"/>

          <text x="330" y="280" font-family="sans-serif" font-size="30" fill="black">1</text>
          <text x="600" y="280" font-family="sans-serif" font-size="30" fill="black">2</text>
          <text x="880" y="280" font-family="sans-serif" font-size="30" fill="black">3</text>
          <text x="330" y="460" font-family="sans-serif" font-size="30" fill="black">4</text>
          <text x="600" y="460" font-family="sans-serif" font-size="30" fill="black">5</text>
          <text x="880" y="460" font-family="sans-serif" font-size="30" fill="black">6</text>

          <text x="420" y="360" font-family="sans-serif" font-size="100" fill="black">{{ sezioni[0] }}</text>
          <text x="700" y="360" font-family="sans-serif" font-size="100" fill="black">{{ sezioni[1] }}</text>
          <text x="950" y="360" font-family="sans-serif" font-size="100" fill="black">{{ sezioni[2] }}</text>
          <text x="420" y="550" font-family="sans-serif" font-size="100" fill="black">{{ sezioni[3] }}</text>
          <text x="700" y="550" font-family="sans-serif" font-size="100" fill="black">{{ sezioni[4] }}</text>
          <text x="950" y="550" font-family="sans-serif" font-size="100" fill="black">{{ sezioni[5] }}</text>
        </g>
      </svg>
</template>

<script>
export default {
  name: 'GoalSection',

  data() {
    return {
      
    }
  },
  props: {
    sezioni: {
      type: Array,
      required: true
    },
  },
};
</script>
