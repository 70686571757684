<template>
	<div>
		<strong class="small"><a :style="'font-weight: bold;' + ( !closed ? 'text-decoration: none; color: black; cursor: default' : '')" href="javascript:;" @click.prevent="openTools">Tools utilizzati nella sessione</a></strong>
		<b-row v-if="tools && opened == true">
			<b-col v-for="tool in model" :cols="2">

				<b-form-group :label="toolsFrom(tool.tool_glo_id).name">
					<b-form-radio-group style="margin-top: -1em" @change="$emit('changed')" v-if="!disabled" size="xs" button-variant="outline-primary" v-model="tool.used" :options="[{ text: 'Si', value: 1 }, { text: 'No', value: 0 }]" buttons></b-form-radio-group>
					<em class="text-muted" v-else>{{tool.used == 1 ? 'Sì' : 'No'}}</em>
				</b-form-group>

			</b-col>
		</b-row>
	</div>
</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'model', 'disabled', 'config', 'tollerant', 'closed' ],
	created: function() {
	},
	data: function(){
		return {
			tab: null,
			tools: false,
			opened: false
		}
	},
	watch: {
		model: {
			immediate: true,
			handler: function() {
				if(!this.closed)
					this.opened = true;

				this.getTools();
				if(this.canSave)
					this.$emit('changed');
			}
		}
	},
	methods: {
		openTools: function() {
			if(!this.closed) return false;
			this.opened = !this.opened;
		},
		toolsFrom: function(id) {
			return _.find(this.tools, { id });
		},
		getTools: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/tools/list').then(function(data) {

				if(!_.isArray(this.model))
					this.$set(this, 'model', []);

				this.tools = data.data;

				var firstRun = false;
				if(this.model.length == 0 && this.tollerant == false)
					firstRun = true;

				_.each(data.data, function(a) {

					var deff = this.tollerant == true ? 0 : null;
					if(firstRun == true) {
						var def = _.find(this.config, { tool_id: a.id });
						if(def)
							deff = def.default
					}

					if(!_.find(this.model, { tool_glo_id: a.id })) {
						this.model.push({
							tool_glo_id: a.id,
							used: ( deff )
						});
					}

				}.bind(this));

			});
		},
	},
	computed: {
		canSave: function() {
			return true;
			if(this.tollerant) return true;

			return _.size(this.tools) == _.filter(this.model, function(a) {
				return a.used != null;
			}).length;

		}
	},
}
</script>