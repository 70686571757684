import Vue from 'vue';

export default {
    setAcademyEsercizi(state, exercises) {
        if(state.page == 1) {
            state.exercises = exercises;
        } else {
            state.exercises.data = state.exercises.data.concat(exercises.data);
        }
    },
    setObjIds(state, objective_ids) {
      state.objective_ids = objective_ids;
    },
    setTitle(state, title) {
        state.title = title;
    },
    setPage(state, page) {
        state.page = page;
    },
    setAcademyEserciziBozze(state, exercises) {
        if(state.page == 1) {
            state.exercises_bozze = exercises;
        } else {
            state.exercises_bozze.data = state.exercises_bozze.data.concat(exercises.data);
        }
    },
    setAcademyEserciziArchivio(state, exercises) {
        if(state.page == 1) {
            state.exercises_archivio = exercises;
        } else {
            state.exercises_archivio.data = state.exercises_archivio.data.concat(exercises.data);
        }
    },

    setAcademyEserciziDaValidare(state, exercises) {
        if(state.page == 1) {
            state.exercises_da_validare = exercises;
        } else {
            state.exercises_da_validare.data = state.exercises_da_validare.data.concat(exercises.data);
        }
    },

    setExcerciseTab(state, tab) {
        state.exercises_tab = tab;
    },

    setAcademy(state, academy) {
        state.academy = academy;
    },   
};
