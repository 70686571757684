<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		
		<div class="card" v-if="preset">
			<b-container fluid class="py-1 mb-4">
				<b-row>
					<b-col :cols="2">

						<b-nav tabs class="mb-1">
							<b-nav-item @click.prevent="ux.tab = 'all'" :active="ux.tab == 'all'">Tutte</b-nav-item>
							<b-nav-item @click.prevent="ux.tab = 'primitive'" :active="ux.tab == 'primitive'">Primit.</b-nav-item>
							<b-nav-item @click.prevent="ux.tab = 'mine'" :active="ux.tab == 'mine'">Tue</b-nav-item>
						</b-nav>

						<b-input v-model="ux.search" type="search" class="mb-1" placeholder="Cerca un Preset" size="sm"></b-input>

						<b-list-group>
							<b-list-group-item v-for="preset in presets" :to="{ name: 'contratti_editor_detail', params: { preset_id: preset.id } }" :active="preset.id == $route.params.preset_id">
								<span v-if="preset.isDraft" class="text-danger small">DRAFT</span>
								<span v-if="preset.isPrimitive" class="bg-warning px-1 small text-primary">PRI</span>
								{{ preset.nome }}
							</b-list-group-item>
						</b-list-group>
						<b-button class="mt-2" :to="{ name: 'contratti_editor_new' }" block size="sm" variant="outline-success">Nuovo Preset</b-button>
					</b-col>
					<b-col :cols="10" class="py-2 px-4">
						<h4 class="card-title">Modifica Preset: {{preset.nome}}</h4>

						<b-form @submit.prevent="updatePreset" class="mb-4">
							<b-form-group label="Nome">
								<b-input v-model="preset.nome"></b-input>
							</b-form-group>
							<b-form-group label="Descrizione del Preset">
								<b-textarea v-model="preset.descrizione" :max-rows="10"></b-textarea>
							</b-form-group>
								
							<div>
								
								<div v-if="preset.regole_json.length > 0" class="mt-2">
									<b-row>
										<b-col>
											<h6>Riepilogo Regole del Bonus</h6>
										</b-col>
										<b-col class="text-right small">
											<a href="" @click.prevent="expandAll">Espandi Tutto</a> / <a href="" @click.prevent="collapseAll">Collassa Tutto</a>
										</b-col>
									</b-row>
									<Regola ref="rule" class="mb-1" :key="rule.posizione" v-for="rule, k in preset.regole_json" :rule="rule" @removerule="do_removeRule(k)"></Regola>
								</div>

								<b-button @click.prevent="$refs.modalNew.show()" class="mt-2" block variant="outline-success" size="sm">+ Aggiungi Regola</b-button>

							</div>
							

							<!--
							<b-checkbox v-model="ux.selfAdd">Aggiungi le regole alla validazione</b-checkbox>
							<b-radio-group v-model="ux.addOperator" v-if="ux.selfAdd">
								<b-radio value="AND">In AND</b-radio>
								<b-radio value="OR">In OR</b-radio>
							</b-radio-group>
							-->

							<b-nav tabs class="mt-2">
								<b-nav-item :active="ux.mode == 'regular'" @click.prevent="ux.mode = 'regular'">Editor Regolare</b-nav-item>
								<b-nav-item :active="ux.mode == 'blockly'" @click.prevent="ux.mode = 'blockly'">Blockly</b-nav-item>
							</b-nav>

							<div style="background-color: white; border-left: 1px solid #dee2e6; border-bottom: 1px solid #dee2e6; border-right: 1px solid #dee2e6" class="p-2 px-3">
								<div v-if="ux.mode == 'regular'">
									<b-form-group label="Validazione">
										<Rule-Helper class="mb-2" :fields="[ 'result', 'progress', 'amount' ]" @addr="onAddRule" :bonus-rules="preset.regole_json" @add="preset.validation += ' ' + $event"></Rule-Helper>
										<b-input type="text" v-model="preset.validation"></b-input>
									</b-form-group>
								</div>
								<div v-else-if="ux.mode == 'blockly'">
									
									<Test-Blockly @code="preset.validation = $event" :rules="preset.regole_json" :workspace="preset.workspace_json" @workspaceupdate="preset.workspace_json = $event"></Test-Blockly>

									<b-alert :show="true" variant="secondary" class="mt-2" size="sm">
										L'editor <strong>blockly</strong> è attualmente in preview. Il suo funzionamento è incompleto.
									</b-alert>

								</div>
							</div>

							<div v-if="auth.user.id == preset.user_id">
								<b-checkbox class="mt-2" switch v-model="preset.isDraft" :value="1" :unchecked-value="0">Bozza</b-checkbox>
								<b-checkbox v-if="auth.isAdmin()" class="mt-2" switch v-model="preset.isPrimitive" :value="1" :unchecked-value="0">Questo Preset è una Primitiva</b-checkbox>

								<hr />
								<b-button-group>
									<b-button type="submit">Salva Modifiche</b-button>
									<b-button @click.prevent="testRule" variant="outline-secondary">Prova la Regola</b-button>
									

								</b-button-group>
								<b-button size="xs" class="ml-3" @click.prevent="deletePreset" variant="outline-secondary">Elimina Preset</b-button>
								<div v-if="preset.isDraft" class="mt-2">
									<div class="small text-muted">Se in bozza, non lo troverai nel glossario delle regole.</div>
								</div>
							</div>
							<div v-else>
								<b-alert variant="info" :show="true">Questo PRESET è in sola lettura.</b-alert>
							</div>

							<b-button class="mt-1" @click.prevent="duplicatePreset">Duplica</b-button>

						</b-form>

						<Modal-New-Rule ref="modalNew" :position="maxPosition" @add="addRuleToPreset"></Modal-New-Rule>
						
						<Modal-Test-Rule v-if="ux.testRule && ux.testRule.open == true" :preset="preset" @close="ux.testRule = false"></Modal-Test-Rule>

					</b-col>
				</b-row>
			</b-container>
		</div>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	data: function(){
		return {
			stagioniAsRoma: false,
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			variabiliSquadra: null,
			stagioneInfo: false,
			stagioniInfo: false,
			preset: null,
			presets: null,
			ux: {
				search: '',
				tab: 'all',
				mode: 'regular',
				selfAdd: false,
				addOperator: 'AND',
				testRule: null
			}
		}
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY')
		}
	},
	computed: {
		maxPosition: function() {
			if(this.preset.regole_json.length == 0) return 1;
			return _.max(this.preset.regole_json, 'posizione').posizione + 1;
		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	components: {
		TestBlockly: require('@/components/layout/contratti/testBlockly.vue').default,
		ModalNewRule: require('@/components/layout/contratti/ModalNewRule.vue').default,
		ModalTestRule: require('@/components/layout/contratti/ModalTestRule.vue').default,
		Regola: require('@/components/layout/contratti/Regola.vue').default,
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		RuleHelper: require('@/components/layout/contratti/RuleHelper.vue').default
	},
	watch: {
		'ux.search': {
			handler: _.debounce(function() {
				this.fetchPresets();
			}, 300)
		},
		'ux.tab': {
			handler: function() {
				this.ux.search = null;
				this.fetchPresets();
			}
		},
		'preset.regole_json': {
			handler: function() {

				if(this.ux.selfAdd) {

					var base = _.map(this.preset.regole_json, function(p) {
						return 'rule' + p.posizione + '.result';
					});

					this.preset.validation = base.join(' ' + this.ux.addOperator + ' ');

				}

				console.log(this.preset.regole_json);
				console.log(this.ux);
			}
		},
		'$route.params.preset_id': {
			immediate: true,
			handler: function() {
				this.fetchPreset(this.$route.params.preset_id);
			}
		},
		rosaTeamId() {
			this.getVariabili();
		},
		seasonId() {
			//this.getVariabili();
		},
	},
	created: function() {
		
		// this.getVariabili();
		
		this.fetchPresets();
		
	},
	methods: {
		duplicatePreset: function() {

			var newName = window.prompt('Nuovo Nome', this.preset.nome);
			this.$http.post(this.$store.state.apiEndPoint + '/contratti/presets-rules/duplicate/' + this.preset.id, { name: newName }).then(function(data) {
				this.$router.push({name: 'contratti_editor_detail', params: { preset_id: data.data.id }});
				this.fetchPresets();
			});

		},
		deletePreset: function() {
			if(window.confirm('Confermi la rimozione del preset?')) {
				this.$http.get(this.$store.state.apiEndPoint + '/contratti/presets-rules/delete/' + this.preset.id).then(function(data) {
					this.$router.push({name: 'contratti_editor'});
				});
			}
		},
		addRuleToPreset: function(event) {

			console.log(event);
			this.preset.regole_json.push(event.rule);

			if(event.operator != null && this.preset.validation.length == 0) {
				this.preset.validation = 'rule' + event.rule.posizione + '.result';
			} else if(event.operator) {
				this.preset.validation += ' ' + event.operator + ' rule' + event.rule.posizione + '.result';
			}

		},
		onAddRule: function(event) {
			console.log(event);
		},
		testRule: function() {
			this.ux.testRule = {
				open: true,
				rules: _.cloneDeep(this.preset.regole_json)
			};
		},
		expandAll: function() {
			_.each(this.$refs.rule, function(a) {
				a.ux.show = true;
			});
		},
		collapseAll: function() {
			_.each(this.$refs.rule, function(a) {
				a.ux.show = false;
			});
		},
		do_removeRule: function(ruleIndex) {
			this.preset.regole_json.splice(ruleIndex, 1);
		},
		fetchPresets: function() {

			var search = {};
			if(this.ux.tab == 'mine') {
				search.mine = true;
			}

			if(this.ux.tab == 'primitive') {
				search.primitive = true;
			}

			if(this.ux.search && this.ux.search.length > 2)
				search.search = this.ux.search;

			this.$http.post(this.$store.state.apiEndPoint + '/contratti/presets-rules', search).then(function(data) {
				this.presets = data.data;
			});

		},
		fetchPreset: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/presets-rules/' + id).then(function(data) {
				this.preset = data.data;
			});
		},
		updatePreset: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/contratti/presets-rules/'+this.preset.id+'/update-preset', { preset: this.preset }).then(function(data) {
				this.fetchPreset(data.data.id);
				this.fetchPresets();
			});
		},
		emptyPreset: function() {
			this.newPreset = {
				nome: '',
				descrizione: '',
				regole: [],
				validation: '',
				isDraft: true
			};
		},
		getStagioneInfo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagione/' + this.seasonId).then(function(data) {
				this.stagioneInfo = data.data;
			});
		},
		getStagioni: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/stagioni').then(function(data) {
				this.stagioniInfo = data.data;
				if(this.variabiliSquadra == null) {

					this.variabiliSquadra = _.findLast(this.stagioniInfo, function(a) {
						return a.stagione_id != null;
					});
				}
			});
		},
		getStagioniASR: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagioni').then(function(data) {
				this.stagioniAsRoma = data.data;
			});
		},
		updateVars: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/contratto/variabili-squadra-update', { vars: this.variabiliSquadra }).then(function(data) {
				this.getStagioni();
				this.variabiliSquadra = data.data;
			});

		},
		deleteVariabili: function() {
			if(!window.confirm("Confermi la rimozione della stagione?")) return false;
			this.$http.post(this.$store.state.apiEndPoint + '/contratto/delete-variabili-squadra/' + this.variabiliSquadra.id).then(function() {
				this.variabiliSquadra = null;
				this.getStagioni();
			});
		},
		getVariabili: function() {
			this.getStagioneInfo(this.seasonId);
			this.variabiliSquadra = false;
			this.$http.get(this.$store.state.apiEndPoint + '/contratto/variabili-squadra/' + this.rosaTeamId + '/' + this.seasonId).then((response) => {
				if (response.ok) {
					if(response.data.id)
						this.variabiliSquadra = response.data;
					else {
						this.newSeason();
					}
				}
			});
		},
	}
}
</script>