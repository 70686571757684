<template id="nuova_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card card-block title_page">
				<div class="card-body">
					<div class="card-title">
						<span>Nuova Valutazione</span>
					</div>
				</div>
			</div>	
			<div class="content_page">
				<b-form @submit="onSubmit">
					<b-row>
						<b-col cols="3">
							<div class="card" style="height:100%">
								<div style="text-align:center;">
									<b-img :src="rosa.avatar_url" :alt="rosa.cognome + ' ' + rosa.nome" :width="130"></b-img>
								</div>
								<div class="card-body">
									<div class="mb-1 font-bold text-center">{{ rosa.nome }} {{ rosa.cognome }}</div>
									<div class="text-center font-size-sm color-rosso">{{
										rosa.last_carriera_sportiva && 
										rosa.last_carriera_sportiva.ruolo_ideale && rosa.last_carriera_sportiva.ruolo_ideale.length > 0 ? rosa.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</div>
										<div class="text-center font-size-sm">Classe {{rosa.nascita_data | formatYear}}</div>
										<div class="text-center font-size-sm color-rosso">{{rosa.last_carriera_sportiva && rosa.last_carriera_sportiva.squadra ? rosa.last_carriera_sportiva.squadra.name : ''}}</div>
										<div class="text-center font-size-sm mt-3">
											<b-row align-h="center" class="mt-3">
												<b-col cols="6">
													<b-form-group id="currentGroup" label="Current" label-for="current">
														<b-form-input id="current" type="number" min="0" max="10" v-model="form.current" step="0.01" :style="'background-color:' + current_bg"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row align-h="center">
												<b-col cols="6">
													<b-form-group id="potentialGroup" label="Potential" label-for="potential">
														<b-form-input id="potential" type="number" min="0" max="10" v-model="form.potential" step="0.01" :style="'background-color:' + potential_bg"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
										</div>
									</div>
								</div>
							</b-col>
							<b-col>
								<b-row>
									<b-col cols="6">
										<b-form-group id="dataGroup" label="Dal" :label-cols="3" label-for="data">
											<b-form-input id="data" type="date" v-model="form.dal" :required="true"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col cols="6">
										<b-form-group id="dataGroup" label="Al" :label-cols="3" label-for="data">
											<b-form-input id="data" type="date" v-model="form.al" :required="true"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Caratteristiche fisiche">
											<b-form-textarea id="note2" v-model="form.note2" :rows="3"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Caratteristiche tecniche">
											<b-form-textarea id="note3" v-model="form.note3" :rows="3"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Caratteristiche tattiche">
											<b-form-textarea id="note4" v-model="form.note4" :rows="3"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Caratteristiche comportamentali">
											<b-form-textarea id="note5" v-model="form.note5" :rows="3"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Giudizio personale">
											<b-form-textarea id="note" v-model="form.note" :rows="3"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>
							</b-col>
						</b-row>

						<b-row class="mt-1" v-if="form.dal && form.al">
							<b-col v-for="item in skills">
								<b-card :title="item[$i18n.t('lang')]" v-if="!rosa.last_carriera_sportiva.squadra.categoria.only_root_skill">
									<b-list-group>
										<b-list-group-item v-for="skill in item.fields">
											<b-row style="width:100%;">
												<b-col cols="8" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													{{skill[$i18n.t('lang')]}}
												</b-col>
												<b-col cols="1" class="text-right">
													<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(skill, skill.it)">{{ skill.count_tags }}</span>
												</b-col>
												<b-col cols="1" class="text-center">
													<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="skill.count_tags" :title="skill.count_tags" @click="selectSkillTags(skill, skill.it)"></i>
												</b-col>
												<b-col cols="2" class="text-center" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
													<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
													<b-form-select v-if="!skill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id]">
														<template slot="first">
															<option value="0"></option>
														</template>
													</b-form-select>
												</b-col>
											</b-row>						
											<b-collapse :id="skill.id" style="width:100%">
												<b-list-group v-if="skill.fields">
													<b-list-group-item v-for="subskill in skill.fields">

														<b-row style="width:100%">
															<b-col cols="8" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																{{subskill[$i18n.t('lang')]}}
															</b-col>
															<b-col cols="1" class="text-right">
																<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)">{{ subskill.count_tags }}</span>
															</b-col>
															<b-col cols="1" class="text-center">
																<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill.count_tags" :title="subskill.count_tags" @click="selectSkillTags(subskill, skill.it + ' -> ' + subskill.it)"></i>
															</b-col>
															<b-col cols="2" class="text-center" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
																<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
																<b-form-select v-if="!subskill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id]">
																	<template slot="first">
																		<option value="0"></option>
																	</template>
																</b-form-select>
															</b-col>
														</b-row>						
														<b-collapse :id="subskill.id" style="width:100%">
															<b-list-group v-if="subskill.fields">
																<b-list-group-item v-for="subskill2 in subskill.fields">
																	<b-row style="width:100%">
																		<b-col cols="8">
																			{{subskill2[$i18n.t('lang')]}}
																		</b-col>
																		<b-col cols="1" class="text-right">
																			<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)">{{ subskill2.count_tags }}</span>
																		</b-col>
																		<b-col cols="1" class="text-center">
																			<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="subskill2.count_tags" :title="subskill2.count_tags" @click="selectSkillTags(subskill2, skill.it + ' -> ' + subskill.it + ' -> ' + subskill2.it)"></i>
																		</b-col>
																		<b-col cols="2" class="text-center">
																			<b-form-select :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id][subskill2.id]">
																				<template slot="first">
																					<option value="0"></option>
																				</template>
																			</b-form-select>
																		</b-col>
																	</b-row>
																</b-list-group-item>
															</b-list-group>
														</b-collapse>
													</b-list-group-item>
												</b-list-group>
											</b-collapse>
										</b-list-group-item>
									</b-list-group>
								</b-card>
								<b-card :title="item[$i18n.t('lang')]" v-if="rosa.last_carriera_sportiva.squadra.categoria.only_root_skill">
									<b-list-group>
										<b-list-group-item v-for="skill in item.fields">
											<b-row style="width:100%">
												<b-col cols="8">
													{{skill[$i18n.t('lang')]}}
												</b-col>
												<b-col cols="1" class="text-right">
													<span class="color-rosso" style="cursor:pointer;" @click="selectSkillTags(skill, skill.it)">{{ skill.count_tags }}</span>
												</b-col>
												<b-col cols="1" class="text-center">
													<i class="fa fa-youtube-play" style="cursor:pointer;color:red;font-size: 1.3rem;" v-if="skill.count_tags" :title="skill.count_tags" @click="selectSkillTags(skill, skill.it)"></i>
												</b-col>
												<b-col cols="2" class="text-center">
													<b-form-select :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id]">
														<template slot="first">
															<option value="0"></option>
														</template>
													</b-form-select>
												</b-col>
											</b-row>						
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</b-col>
						</b-row>
						<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
					</b-form>
				</div>
			</div>
			<b-modal id="tagsModal" size="xl" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
				<b-row v-if="skill_tags_sel">
					<b-col cols="3" style="overflow-y:auto;height:550px;">
						<div v-for="(tag, index) in videotags">
							<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag"></thumbtag>
						</div>
					</b-col>
					<b-col cols="9">
						<video
						id="videotags-wrapper"
						class="video-js vjs-default-skin vjs-16-9"
						controls
						data-setup="{}">
					</video>
					<b-pagination size="sm" align="center" :total-rows="videotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
					<div v-if="tag_sel">
						<p class="m-1">{{ count }} - {{ skillName }}</p>
						<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
						<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
						<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
						<p class="m-1">{{ tag_sel.nota }}</p>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</div>
	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types';
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-playlist';
import { mapState } from 'vuex';

export default {
	data: function() {
		return {
			rosa: [],
			voti: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			form: {
				id_rosa: 0,	
				gradimento: 0,
				fields: {},
				note: '',
				dal: '',
				al: '',
				note2: null,
				note3: null,
				note4: null,
				note5: null,
				current: null,
				potential: null,
			},
			errors: [],
			gradimenti: [],
			skills: [],
			skill_tags_sel: null,
			videotags: [], 
			tag_sel: null,
			count: 0,
			title: '',
			playlist: [],
		}
	},

	components: {
		headapp: 	require('../../../layout/headapp.vue').default,
		menusub:    require('../../../layout/menusub.vue').default,
		footerapp:  require('../../../layout/footerapp.vue').default,
		thumbtag:   require('../../../layout/thumbtag.vue').default,
	},
	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/rosa/giocatore/' + this.$route.params.id).then((response) => {
			if (response.ok)
			{
				this.rosa = response.data;

				this.form.id_rosa = this.rosa.id;

				this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
					if (response.ok) {  
						if(this.rosa.last_carriera_sportiva && this.rosa.last_carriera_sportiva.ruolo_ideale && this.rosa.last_carriera_sportiva.ruolo_ideale.length > 0 && this.rosa.last_carriera_sportiva.ruolo_ideale[0].id == 1) {
							this.skills = JSON.parse(response.data.special);
						} else {
							this.skills = JSON.parse(response.data.standard);
						} 
					}
				}, (response) => {

				});
			}
		}, (response) => {
		}); 
		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
			if (response.ok)
			{
				this.gradimenti = response.data;    
			}
		}, (response) => {
		});
	},
	watch: {
		skills: function () {
			var jsonObject = {};

			if(!this.rosa.last_carriera_sportiva.squadra.categoria.only_root_skill) {
				for (var k in this.skills) {
					var item = this.skills[k];          
					if(item.fields) {
						for (var j in item.fields) {
							var item2 = item.fields[j];

							if(item2.fields) {

								for (var y in item2.fields) {
									var item3 = item2.fields[y];
									if(item3.fields) {

										for (var x in item3.fields) {
											var item4 = item3.fields[x];
											jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id, item4.id], 0);
										}

									} else {
										jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id], 0);
									}	 
								}

							} else {
								jsonObject = this.nest(jsonObject, [item.id, item2.id], 0);		
							}	 
						}
					}
				}
			} else {
				for (var k in this.skills) {
					var item = this.skills[k];          
					if(item.fields) {
						for (var j in item.fields) {
							var item2 = item.fields[j];
							jsonObject = this.nest(jsonObject, [item.id, item2.id], 0);		 
						}
					}
				}
			}

			this.form.fields = jsonObject;
		},
		'form.dal': function () {
			if(this.form.dal != ""  && this.form.al != "" && this.form.dal <= this.form.al) {
				this.loadSkillTags();
			}	
		},
		'form.al': function () {
			if(this.form.dal != ""  && this.form.al != "" && this.form.dal <= this.form.al) {
				this.loadSkillTags();
			}	
		},
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		}
	},
	methods: {
		onSubmit(evt) {
			if(!this.auth.isLastSeason(this.seasonId)) {
				alert("Non puoi aggiungere valutazioni su una stagione archiviata!");
				return;
			}
			evt.preventDefault();
			this.$http.post(this.$store.state.apiEndPoint + '/rosa/add/valutazione', this.form).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					if(response.data.valutazione_id > 0) {	
						this.$router.push({ name: "football_rosa_view_valutazione", params: { id: this.rosa.id, val_id: response.data.valutazione_id} });
					} else {
						this.$router.push({ name: "football_giocatore_scheda", params: { id: this.rosa.id } });
					}
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		nest: function(obj, keys, v) {
			if (keys.length === 1) {
				obj[keys[0]] = v;
			} else {
				var key = keys.shift();
				obj[key] = this.nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v);
			}

			return obj;
		},
		selectGradimento(id) {
			this.form.gradimento = id;
		},
		selectSkillTags(skill_tags, title) {
			this.title = title;	
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields && !this.rosa.last_carriera_sportiva.squadra.categoria.only_root_skill) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.tagsModal.show();
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1); 
		},
		selectPageTag(page) {
			this.tag_sel = this.videotags[page - 1]; 
			this.$video_tags.playlist.currentItem(page - 1);   
		},
		autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.tag_sel = null;
		},
		loadSkillTags() {
			this.$http.get(this.$store.state.apiEndPoint + '/skills/tags/giocatore/by/interval/' + this.rosa.id + "/" + this.form.dal + "/" + this.form.al).then((response) => {
				if (response.ok)
				{  
					this.skills = response.data.skills_tags;
				}
			}, (response) => {

			});
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},
		current_bg: function() {
			if(this.gradimenti.length > 0) {
				if(this.form.current >= 8.5 && this.form.current <= 10) {
					return this.gradimenti[0].colore + '7F';
				} else if(this.form.current >= 6.5 && this.form.current <= 8.49) {
					return this.gradimenti[1].colore + '7F';
				} else if(this.form.current >= 4.5 && this.form.current <= 6.49) {
					return this.gradimenti[2].colore + '7F';
				} else if(this.form.current >= 2.5 && this.form.current <= 4.49) {
					return this.gradimenti[3].colore + '7F';
				} else if(this.form.current >= 0 && this.form.current <= 2.49) {
					return this.gradimenti[4].colore + '7F';
				} else {
					return '#FFFFFF';
				}
			}
			return '#FFFFFF';
		},

		potential_bg: function() {
			if(this.gradimenti.length > 0) {
				if(this.form.potential >= 8.5 && this.form.potential <= 10) {
					return this.gradimenti[0].colore + '7F';
				} else if(this.form.potential >= 6.5 && this.form.potential <= 8.49) {
					return this.gradimenti[1].colore + '7F';
				} else if(this.form.potential >= 4.5 && this.form.potential <= 6.49) {
					return this.gradimenti[2].colore + '7F';
				} else if(this.form.potential >= 2.5 && this.form.potential <= 4.49) {
					return this.gradimenti[3].colore + '7F';
				} else if(this.form.potential >= 0 && this.form.potential <= 2.49) {
					return this.gradimenti[4].colore + '7F';
				} else {
					return '#FFFFFF';
				}
			}
			return '#FFFFFF';
		},
		...mapState(['seasonId']),
	},
	beforeDestroy() {
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},
}

</script>
