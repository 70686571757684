<template id="cardosservato">
    <div class="cardosservato">

        <div class="card mb-2" :style="da_confrontare && confronta ? 'background: #CCFFFF' : ''">
            <b-row v-if="newscout && !youth">
                <b-col class="p-1">
                    <b-progress :max="10" v-if="osservato.valutazioni.length > 0" :variant="'grad-' + media_grad_bg" :title="'Media gradimento: ' + media_grad_lbl">
                        <b-progress-bar :value="10">
                        </b-progress-bar>
                    </b-progress>
                    <b-progress v-if="osservato.valutazioni.length == 0"></b-progress>
                </b-col>
            </b-row>
            <div class="row no-gutters text-center" style="height:3.5em">
                <div class="col" title="A"><div class="grad-live" v-if="da_prendere_live > 0" title="live">{{da_prendere_live}}</div><div class="grad-video" v-if="da_prendere_video > 0" title="video" style="color:red;">{{da_prendere_video}}</div><div class="grad-appunti" v-if="!newscout && da_prendere_appunti > 0" title="appunti">{{da_prendere_appunti}}</div><h4><b-badge variant="bg-1" :style="da_prendere_style">{{da_prendere}}</b-badge></h4></div>                
                <div class="col" :title="newscout ? 'B+' : 'Mi piace moltissimo'"><div class="grad-live" v-if="mi_piace_moltissimo_live > 0" title="live">{{mi_piace_moltissimo_live}}</div><div class="grad-video" v-if="mi_piace_moltissimo_video > 0" title="video" style="color:red;">{{mi_piace_moltissimo_video}}</div><div class="grad-appunti" v-if="!newscout && mi_piace_moltissimo_appunti > 0" title="appunti">{{mi_piace_moltissimo_appunti}}</div><h4><b-badge variant="bg-2" :style="mi_piace_moltissimo_style">{{mi_piace_moltissimo}}</b-badge></h4></div>
                <div class="col" :title="newscout ? 'B' : 'Mi piace'"><div class="grad-live" v-if="mi_piace_live > 0" title="live">{{mi_piace_live}}</div><div class="grad-video" v-if="mi_piace_video > 0" title="video" style="color:red;">{{mi_piace_video}}</div><div class="grad-appunti" v-if="!newscout && mi_piace_appunti > 0" title="appunti">{{mi_piace_appunti}}</div><h4><b-badge variant="bg-3" :style="mi_piace_style">{{mi_piace}}</b-badge></h4></div>
                <div v-if="!newscout" class="col" title="Normale"><div class="grad-live" v-if="normale_live > 0" title="live">{{normale_live}}</div><div class="grad-video" v-if="normale_video > 0" title="video" style="color:red;">{{normale_video}}</div><div class="grad-appunti" v-if="normale_appunti > 0" title="appunti">{{normale_appunti}}</div><h4><b-badge variant="bg-4" :style="normale_style">{{normale}}</b-badge></h4></div>
                <div class="col" :title="newscout ? 'C' : 'Non mi piace'"><div class="grad-live" v-if="nn_mi_piace_live > 0" title="live">{{nn_mi_piace_live}}</div><div class="grad-video" v-if="nn_mi_piace_video > 0" title="video" style="color:red;">{{nn_mi_piace_video}}</div><div class="grad-appunti" v-if="!newscout && nn_mi_piace_appunti > 0" title="appunti">{{nn_mi_piace_appunti}}</div><h4><b-badge variant="bg-5" :style="nn_mi_piace_style">{{nn_mi_piace}}</b-badge></h4></div>
            </div>
            <b-row class="text-center">
                <b-col class="ml-1 mr-0 p-0">
                    <div v-if="!newscout && osservato.opta && osservato.opta.length > 0 && osservato.opta[0].stats.length > 0">
                        <b-badge :style="opta_1_style" :title="opta_1_label"> {{ opta_1 }} </b-badge>
                        <b-badge :style="opta_2_style" :title="opta_2_label"> {{ opta_2 }}</b-badge>
                        <b-badge :style="opta_3_style" :title="opta_3_label"> {{ opta_3 }} </b-badge>
                        <b-badge :style="opta_4_style" :title="opta_4_label"> {{ opta_4 }} </b-badge>
                        <b-badge :style="opta_5_style" :title="opta_5_label"> {{ opta_5 }} </b-badge>
                    </div>
                </b-col>
                <b-col cols="7" class="m-0 p-0">
                    <div class="text-center">
                        <div v-if="editable">
                            <span style="position:absolute;right:5px;cursor:pointer;z-index:1" title="Aggiungi alla lista" v-if="!in_lista" @click="addGiocatoreLista(osservato.id)"><img src="/assets/varie/plus.png"/></span>
                            <span style="position:absolute;right:5px;cursor:pointer;z-index:1" title="Rimuovi dalla lista" v-if="in_lista" @click="delGiocatoreLista(osservato.id)"><img src="/assets/varie/minus.png"/></span>
                        </div>
                        <div v-if="newscout && !youth && has_funzionale" style="position:absolute;left:5px;cursor:pointer;z-index:1" title="Funzionale">
                            <i class="fa fa-star-half-o" aria-hidden="true"></i>
                        </div>
                        <div v-if="newscout && !youth && has_occasione_mercato" style="position:absolute;left:5px;top:25px;cursor:pointer;z-index:1" title="Occasione di mercato">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        </div>
                        <div v-if="newscout && !youth && has_urgente" style="position:absolute;left:5px;top:50px;cursor:pointer;z-index:1" title="Urgente">
                            <i class="fa fa-bell" aria-hidden="true"></i>
                        </div>
                        <div class="resize-image-card">
                            <router-link :to="newscout ? '/next/new/view/giocatore/' + osservato.id : '/scouting/view/giocatore/' + osservato.id" target="_blank" v-if="!confronta && academy == 0 && youth == null" style="position:absolute;width:130px;height:130px;">
                            </router-link>
                            <router-link :to="'/next/' +  youth + '/view/giocatore/' + osservato.id" target="_blank" v-if="!confronta && youth != null" style="position:absolute;width:130px;height:130px;">
                            </router-link>
                            <router-link :to="'/academy/scouting/view/giocatore/' + osservato.id" target="_blank" v-if="!confronta && academy == 1" style="position:absolute;width:130px;height:130px;">
                            </router-link>
                            <router-link :to="'/usascout/view/giocatore/' + osservato.id" target="_blank" v-if="!confronta && academy == 2" style="position:absolute;width:130px;height:130px;">
                            </router-link>
                            <span v-if="confronta" @click="editConfronto(osservato)" style="cursor:pointer;position:absolute;width:130px;height:130px;">
                            </span>
                            <b-img :src="osservato.avatar_url" fluid :alt="osservato.cognome + ' ' + osservato.nome" :width="130"/>
                        </div>
                    </div>
                </b-col>
                <b-col class="mr-1 ml-0 p-0">
                    <div v-if="newscout && !youth" :title="'Percentuale valutata: ' + (osservato.osservato && osservato.osservato.percent_val ? osservato.osservato.percent_val : 0) + '%'" class="outer_percent" style="position:absolute; top:30%;" @click="getPercentualeValutazioni(osservato.id)">
                        <div class="inner_percent" :style="'width:' + (90 - (osservato.osservato && osservato.osservato.percent_val ? osservato.osservato.percent_val*0.9 : 0)) + '%;height:' + (90 - (osservato.osservato && osservato.osservato.percent_val ? osservato.osservato.percent_val*0.9 : 0)) + '%'"></div>
                    </div>
                    <div v-if="!newscout && osservato.opta && osservato.opta.length > 0 && osservato.opta[0].stats.length > 0">
                        <b-badge style="font-weight:700;width:100%;color:#000000;" :variant="sogg_1 != ' - ' ? 'voto-' + Math.round(sogg_1) : 'primary'" :title="sogg_1_label"> {{ sogg_1 }} </b-badge>
                        <b-badge style="font-weight:700;width:100%;color:#000000;" :variant="sogg_2 != ' - ' ? 'voto-' + Math.round(sogg_2) : 'primary'" :title="sogg_2_label"> {{ sogg_2 }} </b-badge>
                        <b-badge style="font-weight:700;width:100%;color:#000000;" :variant="sogg_3 != ' - ' ? 'voto-' + Math.round(sogg_3) : 'primary'" :title="sogg_3_label"> {{ sogg_3 }} </b-badge>
                        <b-badge style="font-weight:700;width:100%;color:#000000;" :variant="sogg_4 != ' - ' ? 'voto-' + Math.round(sogg_4) : 'primary'" :title="sogg_4_label"> {{ sogg_4 }} </b-badge>
                        <b-badge style="font-weight:700;width:100%;color:#000000;" :variant="sogg_5 != ' - ' ? 'voto-' + Math.round(sogg_5) : 'primary'" :title="sogg_5_label"> {{ sogg_5 }} </b-badge>
                    </div>
                </b-col>
            </b-row>
            <div class="card-body">
              <i class="fa fa-youtube-play" style="position:absolute;left:5px;color:red" v-if="!newscout && osservato.videotags && osservato.videotags.length > 0" :title="osservato.videotags.length"></i>
              <b-img src="/assets/icons/worldscout.svg" :width="20" style="position:absolute;right:5px;border-radius:50% !important;" v-if="auth.isAdmin() && academy == 0 && osservato.academy" title="World Scout"/>
              <b-img src="/assets/images/logo.png" :width="20" style="position:absolute;right:5px;" v-if="auth.isAdmin() && academy == 2 && is_asroma" title="Da prendere su AS Roma"/>
              <b-row>
                <b-col class="small text-center">
                    <strong>{{ osservato.nome }} {{ osservato.cognome }}</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="font-size-xs text-center">
                    Classe {{ osservato.nascita_data | formatYear }} ({{ osservato.nascita_data | age }} anni)
                </b-col>
            </b-row>
            <b-row>
                <b-col class="font-size-xs color-rosso text-center">
                    {{ruolo_ideale ? ruolo_ideale.ruolo : '&nbsp;'}}
                </b-col>
            </b-row>
            <b-row>
                <b-col class="font-size-xs text-center">
                    {{ osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.squadra ?  osservato.last_carriera_sportiva.squadra.name : '&nbsp;'}}
                </b-col>
            </b-row>

            <div class="row no-gutters text-center font-size-sm" v-if="osservato.valutazioni.length > 0">
                <div class="col-3" title="Data ultima valutazione"><i class="fa fa-calendar" aria-hidden="true"></i><br><span style="font-size:75%">{{ osservato.valutazioni[0].created_at | formatDate }}</span></div>
                <div class="col-3" title="Voto ultima valutazione"><i class="fa fa-fw fa-arrow-up" aria-hidden="true"></i><br><strong>{{ osservato.valutazioni[0].voto[0]}}</strong></div>
                <div v-if="newscout && !youth" class="col-3" title="Current"><i class="fa fa-id-badge" aria-hidden="true"></i><br><strong><b-badge :style="{background: current_bg, color: '#000000', fontSize: '90%'}">{{ current }}</b-badge></strong></div>
                <div v-else class="col-3" title="Voto medio"><i class="fa fa-fw fa-plus" aria-hidden="true"></i><br><strong>{{voto_medio}}</strong></div>
                <div v-if="newscout && !youth" class="col-3" title="Potential"><i class="fa fa-line-chart" aria-hidden="true"></i><br><strong><b-badge :style="{background: potential_bg, color: '#000000', fontSize: '90%'}">{{ potential }}</b-badge></strong></div>
                <div v-else class="col-3" title="Voto medio per ruolo"><i class="fa fa-futbol-o" aria-hidden="true"></i><br><strong>{{ voto_medio_ruolo}}</strong></div>
            </div>
            <div class="row no-gutters text-center font-size-sm" v-if="osservato.valutazioni.length == 0">
                <div class="col-3" title="Data ultima valutazione"><i class="fa fa-calendar" aria-hidden="true"></i><br><strong><b-badge style="color:#000000; background: #FFFFFF; font-size:90%">N/D</b-badge></strong></div>
                <div class="col-3" title="Voto ultima valutazione"><i class="fa fa-fw fa-arrow-up" aria-hidden="true"></i><br><strong><b-badge style="color:#000000; background: #FFFFFF; font-size:90%">N/D</b-badge></strong></div>
                <div v-if="newscout && !youth" class="col-3" title="Current"><i class="fa fa-id-badge" aria-hidden="true"></i><br><strong><b-badge :style="{background: current_bg, color: '#000000', fontSize: '90%'}">{{ current }}</b-badge></strong></div>
                <div v-else class="col-3" title="'Voto medio"><i class="fa fa-fw fa-plus" aria-hidden="true"></i><br>N/D</div>
                <div v-if="newscout && !youth" class="col-3" title="Potential"><i class="fa fa-line-chart" aria-hidden="true"></i><br> <b-badge :style="{background: potential_bg, color: '#000000', fontSize: '90%'}">{{ potential }}</b-badge></strong></div>
                <div v-else class="col-3" title="Voto medio per ruolo"><i class="fa fa-futbol-o" aria-hidden="true"></i><br>N/D</div>
            </div>
            <b-row>
                <b-col>
                    <b-progress :max="10" v-if="osservato.valutazioni.length > 0" :variant="'grad-' + (osservato.valutazioni[0].id_gradimento ? osservato.valutazioni[0].id_gradimento : '')" title="Osservatore ultima valutazione">
                        <b-progress-bar :value="10">
                            {{ osservato.valutazioni[0].utente.cognome}}
                        </b-progress-bar>
                    </b-progress>
                    <b-progress v-if="osservato.valutazioni.length == 0"></b-progress>
                </b-col>
            </b-row>
            <b-row v-if="osservato.opta && osservato.opta.length > 0 && osservato.opta[0].stats.length > 0" class="text-center">
                <b-col>
                    <b-badge style="background:red;font-weight:400;width:100%" title="Partite">{{ osservato.opta[0].stats[0].gm }}</b-badge>
                </b-col>
                <b-col>
                    <b-badge style="background:green;font-weight:400;width:100%" title="Minuti">{{ osservato.opta[0].stats[0].min }}</b-badge>
                </b-col>
                <b-col>
                    <b-badge style="background:orange;font-weight:400;width:100%" title="Assist">{{ osservato.opta[0].stats[0].assist }}</b-badge>
                </b-col>
                <b-col>
                    <b-badge style="background:blue;font-weight:400;width:100%" title="Goal">{{ osservato.opta[0].stats[0].goal }}</b-badge>
                </b-col>
                <b-col>
                    <b-badge style="background:gray;font-weight:400;width:100%" title="Passaggi chiave">{{ osservato.opta[0].stats[0].keyPass }}</b-badge>
                </b-col>
            </b-row>
        </div>
    </div>

</div>
</template>

<script>
import moment from 'moment'

var Auth = require('../../auth.js');

export default {
    props:{
        osservato: {
            type: Object,
            required: true
        },
        in_lista: {
            type: Boolean,
            required: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        opta_range: {
            type: Object,
            required: false,
            default: null
        },
        confronta: {
            type: Boolean,
            default: false
        },
        da_confrontare: {
            type: Boolean,
            required: false
        },
        academy: {
            type: Number,
            required: false,
            default: 0
        },
        newscout: {
            type: Boolean,
            default: false
        },

        youth: {
            type: String,
            default: null,
        },

        gradimenti: {
            type: Array,
            default: null,
        },
    },
    data: function() {
        return {
            /*  opacity_value: [1, 0.8, 0.7, 0.6, 0.5], */
            opacity_value: [1, 1, 1, 1, 1],
            difensori: [2, 3, 4, 5, 6],
            cc_centrali: [11, 14, 15, 16],
            cc_interni: [12, 13],
            terzini: [7, 8, 9, 10, 17, 18],
            att_esterni: [19, 20, 21, 22, 23],
            att_centrali: [24, 25, 26],

            skills: {
                difensori: {
                    sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
                    sogg_2: { label: 'MEDIA: TAKLE + CONTRASTO', ids: ['18a', '18b'] },
                    sogg_3: { label: 'MEDIA: INTERC ORIZZ + VERTICALE', ids: ['6a', '6b'] },
                    sogg_4: { label: 'DUELLI AEREI', ids: ['18c'] },
                    sogg_5: { label: 'MEDIA: PASSAGGI CORTO/LANCIO/FILTRANTE', ids: ['12a', '12c', '12f'] },
                },
                cc_centrali: {
                    sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
                    sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
                    sogg_3: { label: 'MEDIA: INTERC ORIZZ + VERTICALE', ids: ['6a', '6b'] },
                    sogg_4: { label: 'MEDIA: SKILL DI PRESSING E PRESSIONE', ids: ['8a', '8b', '8c', '8d'] },
                    sogg_5: { label: 'MEDIA: PASSAGGI CORTO/LANCIO/FILTRANTE', ids: ['12a', '12c', '12f'] },
                },
                cc_interni: {
                    sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
                    sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
                    sogg_3: { label: 'MEDIA: SKILL DI TIRO IN PORTA', ids: ['11a', '11b', '11c', '11d', '11e'] },
                    sogg_4: { label: 'SMARCAMENTO TRA LE LINEE', ids: ['2b'] },
                    sogg_5: { label: 'MEDIA: PASSAGGI CORTO/LANCIO/FILTRANTE', ids: ['12a', '12c', '12f'] },
                },
                terzini: {
                    sogg_1: { label: '1VS1 DIFENSIVO/OFFENSIVO', ids: ['14a'] },
                    sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
                    sogg_3: { label: 'MEDIA: INTERC ORIZZ + VERTICALE', ids: ['6a', '6b'] },
                    sogg_4: { label: 'CROSS', ids: ['13b'] },
                    sogg_5: { label: 'MEDIA: SKILL DI DRIBBLING', ids: ['14a', '14b', '14c'] },
                },
                att_esterni: {
                    sogg_1: { label: 'MEDIA: TIRO IN PORTA', ids: ['11a', '11b', '11c', '11d', '11e'] },
                    sogg_2: { label: 'PASSAGGIO CHIAVE', ids: ['12h'] },
                    sogg_3: { label: 'MOVIMENTO DOPO PALLA PERSA', ids: ['3c'] },
                    sogg_4: { label: 'MEDIA: SKILL DI ASSIST', ids: ['13a', '13b', '13c', '13d'] },
                    sogg_5: { label: 'MEDIA: SKILL DI DRIBBLING', ids: ['14a', '14b', '14c'] },
                },
                att_centrali: {
                    sogg_1: { label: 'MEDIA: TIRO IN PORTA', ids: ['11a', '11b', '11c', '11d', '11e'] },
                    sogg_2: { label: 'ATTACCO DELLA PORTA', ids: ['9b'] },
                    sogg_3: { label: 'SENSO DEL GOAL', ids: ['30b'] },
                    sogg_4: { label: 'MEDIA: SKILL DI ASSIST', ids: ['13a', '13b', '13c', '13d'] },
                    sogg_5: { label: 'MEDIA: SKILL DI DRIBBLING', ids: ['14a', '14b', '14c'] },
                },
            },
            opta_skills: {
                difensori: {
                    ogg_1: 'duel_90min',
                    ogg_2: 'tackle_90min',
                    ogg_3: 'int_90min',
                    ogg_4: 'aerial_90min',
                    ogg_5: 'pass_90min',
                },
                cc_centrali: {
                    ogg_1: 'duel_90min',
                    ogg_2: 'keyPass_90min',
                    ogg_3: 'int_90min',
                    ogg_4: 'recovery_90min',
                    ogg_5: 'pass_90min',
                },
                cc_interni: {
                    ogg_1: 'duel_90min',
                    ogg_2: 'keyPass_90min',
                    ogg_3: 'tsr_90min',
                    ogg_4: 'psInA3rd_90min',
                    ogg_5: 'pass_90min',
                },
                terzini: {
                    ogg_1: 'duel_90min',
                    ogg_2: 'keyPass_90min',
                    ogg_3: 'int_90min',
                    ogg_4: 'crossCmp_90min',
                    ogg_5: 'oneVsOne_90min',
                },
                att_esterni: {
                    ogg_1: 'expG_90min',
                    ogg_2: 'keyPass_90min',
                    ogg_3: 'rcvryA3_90min',
                    ogg_4: 'bgChncCrtd_90min',
                    ogg_5: 'oneVsOne_90min',
                },
                att_centrali: {
                    ogg_1: 'expG_90min',
                    ogg_2: 'sog_90min',
                    ogg_3: 'shotConv_90min',
                    ogg_4: 'bgChncCrtd_90min',
                    ogg_5: 'oneVsOne_90min',
                },
            },
            skill_opta_1: '',
            skill_opta_2: '',
            skill_opta_3: '',
            skill_opta_4: '',
            skill_opta_5: '',
        }
    },
    filters: {
        formatYear: function (date) {
            var m = moment(date);
            if(m.isValid()) {
                return m.format('YYYY'); 
            }
            return "";
        },
        formatDate: function (date) {
            var m = moment(date);
            if(m.isValid()) {
                return m.format('DD/MM/YY'); 
            }
            return "";
        },
        age: function (date) {
            var birthday = moment(date);
            if(birthday.isValid()) {
                return moment().diff(birthday, 'years');
            }
            return "";
        },
    },
    computed: {
        auth: function() {
            return Auth;
        },
        ruolo_ideale: function () { 
            return this.osservato.last_carriera_sportiva && this.osservato.last_carriera_sportiva.ruolo_ideale && this.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? this.osservato.last_carriera_sportiva.ruolo_ideale[0] : null;
        },
        ruolo_group: function () {
            var ruolo_group = ""; 
            if(this.ruolo_ideale) {
                var ruolo = this.ruolo_ideale.id;
                if(this.difensori.indexOf(ruolo) > -1) {
                    ruolo_group = 'difensori';
                } else if(this.cc_centrali.indexOf(ruolo) > -1) {
                    ruolo_group = 'cc_centrali';
                } else if(this.cc_interni.indexOf(ruolo) > -1) {
                    ruolo_group = 'cc_interni';
                } else if(this.terzini.indexOf(ruolo) > -1) {
                    ruolo_group = 'terzini';
                } else if(this.att_esterni.indexOf(ruolo) > -1) {
                    ruolo_group = 'att_esterni';
                } else if(this.att_centrali.indexOf(ruolo) > -1) {
                    ruolo_group = 'att_centrali';
                }     
            }
            return ruolo_group;
        },
        da_prendere: function() {
            var count = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].id_gradimento == 1) { 
                    count++;
                }
            }
            if(this.newscout) {
                return count;
            } else {
                return count + this.da_prendere_live + this.da_prendere_video;
            }
        },
        mi_piace_moltissimo: function() {
            var count = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].id_gradimento == 2) { 
                    count++;
                }
            }
            if(this.newscout) {
                return count;
            } else {
                return count + this.mi_piace_moltissimo_live + this.mi_piace_moltissimo_video;
            }
        },
        mi_piace: function() {
            var count = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].id_gradimento == 3) { 
                    count++;
                }
            }
            if(this.newscout) {
                return count;
            } else {
                return count + this.mi_piace_live + this.mi_piace_video;
            }
        },
        normale: function() {
            var count = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].id_gradimento == 4) { 
                    count++;
                }
            }
            if(this.newscout) {
                return count;
            } else {
                return count + this.normale_live + this.normale_video;
            }
        },
        nn_mi_piace: function() {
            var count = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].id_gradimento == 5) { 
                    count++;
                }
            }
            if(this.newscout) {
                return count;
            } else {
                return count + this.nn_mi_piace_live + this.nn_mi_piace_video;
            }
        },
        da_prendere_live: function() {
            var count = 0;
            if(this.newscout) {
                for (var index in this.osservato.valutazioni) {
                    if(this.osservato.valutazioni[index].id_gradimento == 1) { 
                        for(var i in this.osservato.valutazioni[index].partite) {
                            if(this.osservato.valutazioni[index].partite[i].modalita == 'stadio') {
                                count++;
                                break;
                            }
                        }
                    }
                }
            } else {
                for (var index in this.osservato.partite_report_giocatori) {
                    if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 1 && this.osservato.partite_report_giocatori[index].id_gradimento == 1) { 
                        count++;
                    }
                }
            }
            return count;
        },
        da_prendere_video: function() {
            var count = 0;
            for (var index in this.osservato.partite_report_giocatori) {
                if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 0 && this.osservato.partite_report_giocatori[index].id_gradimento == 1) { 
                    count++;
                }
            }
            return count;
        },
        mi_piace_moltissimo_live: function() {
            var count = 0;
            if(this.newscout) {
                for (var index in this.osservato.valutazioni) {
                    if(this.osservato.valutazioni[index].id_gradimento == 2) { 
                        for(var i in this.osservato.valutazioni[index].partite) {
                            if(this.osservato.valutazioni[index].partite[i].modalita == 'stadio') {
                                count++;
                                break;
                            }
                        }
                    }
                }
            } else {
                for (var index in this.osservato.partite_report_giocatori) {
                    if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 1 && this.osservato.partite_report_giocatori[index].id_gradimento == 2) { 
                        count++;
                    }
                }
            }
            return count;
        },
        mi_piace_moltissimo_video: function() {
            var count = 0;
            for (var index in this.osservato.partite_report_giocatori) {
                if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 0 && this.osservato.partite_report_giocatori[index].id_gradimento == 2) { 
                    count++;
                }
            }
            return count;
        },
        mi_piace_live: function() {
            var count = 0;
            if(this.newscout) {
                for (var index in this.osservato.valutazioni) {
                    if(this.osservato.valutazioni[index].id_gradimento == 3) { 
                        for(var i in this.osservato.valutazioni[index].partite) {
                            if(this.osservato.valutazioni[index].partite[i].modalita == 'stadio') {
                                count++;
                                break;
                            }
                        }
                    }
                }
            } else {
                for (var index in this.osservato.partite_report_giocatori) {
                    if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 1 && this.osservato.partite_report_giocatori[index].id_gradimento == 3) { 
                        count++;
                    }
                }
            }
            return count;
        },
        mi_piace_video: function() {
            var count = 0;
            for (var index in this.osservato.partite_report_giocatori) {
                if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 0 && this.osservato.partite_report_giocatori[index].id_gradimento == 3) { 
                    count++;
                }
            }
            return count;
        },
        normale_live: function() {
            var count = 0;
            if(this.newscout) {
                for (var index in this.osservato.valutazioni) {
                    if(this.osservato.valutazioni[index].id_gradimento == 4) { 
                        for(var i in this.osservato.valutazioni[index].partite) {
                            if(this.osservato.valutazioni[index].partite[i].modalita == 'stadio') {
                                count++;
                                break;
                            }
                        }
                    }
                }
            } else {
                for (var index in this.osservato.partite_report_giocatori) {
                    if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 1 && this.osservato.partite_report_giocatori[index].id_gradimento == 4) { 
                        count++;
                    }
                }
            }
            return count;
        },
        normale_video: function() {
            var count = 0;
            for (var index in this.osservato.partite_report_giocatori) {
                if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 0 && this.osservato.partite_report_giocatori[index].id_gradimento == 4) { 
                    count++;
                }
            }
            return count;
        },
        nn_mi_piace_live: function() {
            var count = 0;
            if(this.newscout) {
                for (var index in this.osservato.valutazioni) {
                    if(this.osservato.valutazioni[index].id_gradimento == 5) { 
                        for(var i in this.osservato.valutazioni[index].partite) {
                            if(this.osservato.valutazioni[index].partite[i].modalita == 'stadio') {
                                count++;
                                break;
                            }
                        }
                    }
                }
            } else {
                for (var index in this.osservato.partite_report_giocatori) {
                    if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 1 && this.osservato.partite_report_giocatori[index].id_gradimento == 5) { 
                        count++;
                    }
                }
            }
            return count;
        },
        nn_mi_piace_video: function() {
            var count = 0;
            for (var index in this.osservato.partite_report_giocatori) {
                if(this.osservato.partite_report_giocatori[index].partita_report.partita.live == 0 && this.osservato.partite_report_giocatori[index].id_gradimento == 5) { 
                    count++;
                }
            }
            return count;
        },
        voto_medio: function() {

            var count = 0;
            var voto = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].voto[0] > 0) {
                    voto += this.osservato.valutazioni[index].voto[0];
                    count++;
                }
            }
            if(voto == 0) {
                return "N/D";
            }
            return Math.round((voto/count) * 100) / 100;
        },
        voto_medio_ruolo: function() {

            var count = 0;
            var voto = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].voto[1] > 0) {
                    voto += this.osservato.valutazioni[index].voto[1];
                    count++;
                }
            }
            if(voto == 0) {
                return "N/D";
            }
            return Math.round((voto/count) * 100) / 100;
        },

        opacity: function() {
            var count_grad = [0, 0, 0, 0, 0];
            var array_index = [0, 1, 2, 3, 4];
            var count = 0;
            for (var index in this.osservato.valutazioni) {
                if(this.osservato.valutazioni[index].id_gradimento == 1) { 
                    count_grad[0]++;
                }
                else if(this.osservato.valutazioni[index].id_gradimento == 2) { 
                    count_grad[1]++;
                }
                else if(this.osservato.valutazioni[index].id_gradimento == 3) { 
                    count_grad[2]++;
                }
                else if(this.osservato.valutazioni[index].id_gradimento == 4) { 
                    count_grad[3]++;
                }
                else if(this.osservato.valutazioni[index].id_gradimento == 5) { 
                    count_grad[4]++;
                }
            }
            for (var index in this.osservato.partite_report_giocatori) {
                if(this.osservato.partite_report_giocatori[index].id_gradimento == 1) { 
                    count_grad[0]++;
                }
                else if(this.osservato.partite_report_giocatori[index].id_gradimento == 2) { 
                    count_grad[1]++;
                }
                else if(this.osservato.partite_report_giocatori[index].id_gradimento == 3) { 
                    count_grad[2]++;
                }
                else if(this.osservato.partite_report_giocatori[index].id_gradimento == 4) { 
                    count_grad[3]++;
                }
                else if(this.osservato.partite_report_giocatori[index].id_gradimento == 5) { 
                    count_grad[4]++;
                }
            }

            var opacity = [
            {index: 0, grad: count_grad[0]},
            {index: 1, grad: count_grad[1]},
            {index: 2, grad: count_grad[2]},
            {index: 3, grad: count_grad[3]},
            {index: 4, grad: count_grad[4]}
            ];

            opacity.sort(function(a, b) {
                return b.grad > a.grad;
            });

            var array_index = [];
            for (var index in opacity) {
                array_index.push(opacity[index].index);
            }
            return array_index;
        },
        da_prendere_style: function() {
            if(this.da_prendere > 0) {
                return {
                    'padding-bottom': (0.5 + (0.1 * this.da_prendere)) + 'em',
                    'opacity': this.opacity_value[this.opacity.indexOf(0)],
                }
            } else {
                return {
                    'opacity': '0.3',
                }
            }
        },
        mi_piace_moltissimo_style: function() {
            if(this.mi_piace_moltissimo > 0) {
                return {
                    'padding-bottom': (0.5 + (0.1 * this.mi_piace_moltissimo)) + 'em',
                    'opacity': this.opacity_value[this.opacity.indexOf(1)],
                }
            } else {
                return {
                    'opacity': '0.3',
                }
            }
        },
        mi_piace_style: function() {
            if(this.mi_piace > 0) {
                return {
                    'padding-bottom': (0.5 + (0.1 * this.mi_piace)) + 'em',
                    'opacity': this.opacity_value[this.opacity.indexOf(2)],
                }
            } else {
                return {
                    'opacity': '0.3',
                }
            }
        },
        normale_style: function() {
            if(this.normale > 0) {
                return {
                    'padding-bottom': (0.5 + (0.1 * this.normale)) + 'em',
                    'opacity': this.opacity_value[this.opacity.indexOf(3)],
                }
            } else {
                return {
                    'opacity': '0.3',
                }
            }
        },
        nn_mi_piace_style: function() {
            if(this.nn_mi_piace > 0) {
                return {
                    'padding-bottom': (0.5 + (0.1 * this.nn_mi_piace)) + 'em',
                    'opacity': this.opacity_value[this.opacity.indexOf(4)],
                }
            } else {
                return {
                    'opacity': '0.3',
                }
            }
        },
        opta_1: function() {
            return this.getDatoOgg(1);
        },
        opta_1_label: function() {
            return this.getLabelOpta(this.skill_opta_1);
        },
        opta_1_style: function() {
            return this.getBgOpta(this.opta_1, this.skill_opta_1);
        },
        opta_2: function() {
            return this.getDatoOgg(2);
        },
        opta_2_label: function() {
            return this.getLabelOpta(this.skill_opta_2);
        },
        opta_2_style: function() {
            return this.getBgOpta(this.opta_2, this.skill_opta_2);
        },
        opta_3: function() {
            return this.getDatoOgg(3);
        },
        opta_3_label: function() {
            return this.getLabelOpta(this.skill_opta_3);
        },
        opta_3_style: function() {
            return this.getBgOpta(this.opta_3, this.skill_opta_3);
        },
        opta_4: function() {
            return this.getDatoOgg(4);
        },
        opta_4_label: function() {
            return this.getLabelOpta(this.skill_opta_4);
        },
        opta_4_style: function() {
            return this.getBgOpta(this.opta_4, this.skill_opta_4);
        },
        opta_5: function() {
            return this.getDatoOgg(5);
        },
        opta_5_label: function() {
            return this.getLabelOpta(this.skill_opta_5);
        },
        opta_5_style: function() {
            return this.getBgOpta(this.opta_5, this.skill_opta_5);
        },
        sogg_1: function() {
            return this.getDatoSogg(1);
        },
        sogg_1_label: function() {
            return this.getLabelSogg(1);
        },
        sogg_2: function() {
            return this.getDatoSogg(2);
        },
        sogg_2_label: function() {
            return this.getLabelSogg(2);
        },
        sogg_3: function() {
            return this.getDatoSogg(3);
        },
        sogg_3_label: function() {
            return this.getLabelSogg(3);
        },
        sogg_4: function() {
            return this.getDatoSogg(4);
        },
        sogg_4_label: function() {
            return this.getLabelSogg(4);
        },
        sogg_5: function() {
            return this.getDatoSogg(5);
        },
        sogg_5_label: function() {
            return this.getLabelSogg(5);
        },
        is_asroma: function() {
            if(this.academy == 2) {
                for (var index in this.osservato.valutazioni) {
                    if(this.osservato.valutazioni[index].asroma) { 
                     return true;
                 }
             }
         }
         return false;
     },

     da_prendere_appunti: function() {
        var count = 0;
        for (var index in this.osservato.appunti) {
            if(this.osservato.appunti[index].id_gradimento == 1) { 
                count++;
            }
        }
        return count;
    },
    mi_piace_moltissimo_appunti: function() {
        var count = 0;
        for (var index in this.osservato.appunti) {
            if(this.osservato.appunti[index].id_gradimento == 2) { 
                count++;
            }
        }
        return count;
    },
    mi_piace_appunti: function() {
        var count = 0;
        for (var index in this.osservato.appunti) {
            if(this.osservato.appunti[index].id_gradimento == 3) { 
                count++;
            }
        }
        return count;
    },
    normale_appunti: function() {
        var count = 0;
        for (var index in this.osservato.appunti) {
            if(this.osservato.appunti[index].id_gradimento == 4) { 
                count++;
            }
        }
        return count;
    },
    nn_mi_piace_appunti: function() {
        var count = 0;
        for (var index in this.osservato.appunti) {
            if(this.osservato.appunti[index].id_gradimento == 5) { 
                count++;
            }
        }
        return count;
    },

    current: function() {
        if(this.auth.isUserEnable('current_potential_avg')) {
            if(this.osservato.osservato && this.osservato.osservato.current !== null) {
                return Math.round(this.osservato.osservato.current * 100) / 100;
            }
        } else {
            var utenti_ids = [];
            var count = 0;
            var media_current = 0;
            for(var i in this.osservato.valutazioni) {
                if(!utenti_ids.includes(this.osservato.valutazioni[i].id_utente) && this.osservato.valutazioni[i].current != null) {
                    media_current += this.osservato.valutazioni[i].current;
                    utenti_ids.push(this.osservato.valutazioni[i].id_utente);
                    count ++;
                }
            }
            if(media_current > 0) {
                media_current = media_current / count;
                media_current = Math.round(media_current * 100) / 100;
            }
            if(count > 0) {
                return media_current;
            }
        }
        return 'N/D';
    },

    potential: function() {
        if(this.auth.isUserEnable('current_potential_avg')) {
            if(this.osservato.osservato && this.osservato.osservato.potential !== null) {
                return Math.round(this.osservato.osservato.potential * 100) / 100;
            }
        } else {
            var utenti_ids = [];
            var count = 0;
            var media_potential = 0;
            for(var i in this.osservato.valutazioni) {
                if(!utenti_ids.includes(this.osservato.valutazioni[i].id_utente) && this.osservato.valutazioni[i].potential != null) {
                    media_potential += this.osservato.valutazioni[i].potential;
                    utenti_ids.push(this.osservato.valutazioni[i].id_utente);
                    count ++;
                }
            }
            if(media_potential > 0) {
                media_potential = media_potential / count;
                media_potential = Math.round(media_potential * 100) / 100;
            }
            if(count > 0) {
                return media_potential;
            }
        }
        return 'N/D';
    },

    current_bg: function() {
        if(this.gradimenti.length > 0) {
            if(this.current >= 8.5 && this.current <= 10) {
                return this.gradimenti[0].colore;
            } else if(this.current >= 6.5 && this.current <= 8.49) {
                return this.gradimenti[1].colore;
            } else if(this.current >= 4.5 && this.current <= 6.49) {
                return this.gradimenti[2].colore;
            } else if(this.current >= 2.5 && this.current <= 4.49) {
                return this.gradimenti[3].colore;
            } else if(this.current >= 0 && this.current <= 2.49) {
                return this.gradimenti[4].colore;
            } else {
                return '#FFFFFF';
            }
        }
        return '#FFFFFF';
    },

    potential_bg: function() {
        if(this.gradimenti.length > 0) {
            if(this.potential >= 8.5 && this.potential <= 10) {
                return this.gradimenti[0].colore;
            } else if(this.potential >= 6.5 && this.potential <= 8.49) {
                return this.gradimenti[1].colore;
            } else if(this.potential >= 4.5 && this.potential <= 6.49) {
                return this.gradimenti[2].colore;
            } else if(this.potential >= 2.5 && this.potential <= 4.49) {
                return this.gradimenti[3].colore;
            } else if(this.potential >= 0 && this.potential <= 2.49) {
                return this.gradimenti[4].colore;
            } else {
                return '#FFFFFF';
            }
        }
        return '#FFFFFF';    
    },

    has_funzionale: function () {
        if(this.osservato.valutazioni.length > 0) {
            for(var i in this.osservato.valutazioni[0].alerts) {
                var alert = this.osservato.valutazioni[0].alerts[i];
                if(alert.alert == 1) {
                    return true;
                }

            }
        }
        return false;
    },

    has_occasione_mercato: function () {
        if(this.osservato.valutazioni.length > 0) {
            for(var i in this.osservato.valutazioni[0].alerts) {
                var alert = this.osservato.valutazioni[0].alerts[i];
                if(alert.alert == 2) {
                    return true;
                }

            }
        }
        return false;
    },

    has_urgente: function () {
        if(this.osservato.valutazioni.length > 0) {
            for(var i in this.osservato.valutazioni[0].alerts) {
                var alert = this.osservato.valutazioni[0].alerts[i];
                if(alert.alert == 3) {
                    return true;
                }

            }
        }
        return false;
    },

    media_grad_bg: function() {
        var media = 0;
        var count = 0;
        var last_grad_value = 0;
        for(var i in this.osservato.valutazioni) {
            var grad_value = 0;
            var grad_id = this.osservato.valutazioni[i].id_gradimento;
            if(grad_id == 1) {
                grad_value = 8.75;
            } else if(grad_id == 2) {
                grad_value = 6.25;
            } else if(grad_id == 3) {
                grad_value = 3.75;
            } else if(grad_id == 5) {
                grad_value = 1.25;
            }
            if(grad_value > 0) {
                if(last_grad_value == 0) {
                    last_grad_value = grad_value;
                }
                media += grad_value;
                count++;
            }
        }
        if(count > 0) {
            media = media/count;
        }

        if(media <= 10 && media > 7.5) {
            return '1';
        }
        if(media == 7.5) {
            if(last_grad_value == 8.75) {
                return '1';
            } else {
                return '2';
            }
        }
        if(media < 7.5 && media > 5) {
            return '2';
        }
        if(media == 5) {
            if(last_grad_value == 6.25) {
                return '2';
            } else {
                return '3';
            }
        }
        if(media < 5 && media > 2.5) {
            return '3';
        }
        if(media == 2.5) {
            if(last_grad_value == 3.75) {
                return '3';
            } else {
                return '5';
            }
        }
        if(media < 2.5 && media > 0) {
            return '5';
        }
        return '';
    },

    media_grad_lbl: function() {
        var media = 0;
        var count = 0;
        var last_grad_value = 0;
        for(var i in this.osservato.valutazioni) {
            var grad_value = 0;
            var grad_id = this.osservato.valutazioni[i].id_gradimento;
            if(grad_id == 1) {
                grad_value = 8.75;
            } else if(grad_id == 2) {
                grad_value = 6.25;
            } else if(grad_id == 3) {
                grad_value = 3.75;
            } else if(grad_id == 5) {
                grad_value = 1.25;
            }
            if(grad_value > 0) {
                if(last_grad_value == 0) {
                    last_grad_value = grad_value;
                }
                media += grad_value;
                count++;
            }
        }
        if(count > 0) {
            media = media/count;
        }

        if(media <= 10 && media > 7.5) {
            return 'A';
        }
        if(media == 7.5) {
            if(last_grad_value == 8.75) {
                return 'A';
            } else {
                return 'B+';
            }
        }
        if(media < 7.5 && media > 5) {
            return 'B+';
        }
        if(media == 5) {
            if(last_grad_value == 6.25) {
                return 'B+';
            } else {
                return 'B';
            }
        }
        if(media < 5 && media > 2.5) {
            return 'B';
        }
        if(media == 2.5) {
            if(last_grad_value == 3.75) {
                return 'B';
            } else {
                return 'C';
            }
        }
        if(media < 2.5 && media > 0) {
            return 'C';
        }
        return '';
    }
},
methods: {
    addGiocatoreLista(id) {
        this.$emit('addGiocatoreLista', id);
    },
    delGiocatoreLista(id) {
        this.$emit('delGiocatoreLista', id);
    },
    getMedia(skills_filter, skills_val) {
        var count_val = 0;
        var media_val = 0;
        for(var key in skills_val) {
            var obj_cat = skills_val[key];
            for(var key2 in obj_cat) {
                if(obj_cat[key2] !== null && typeof obj_cat[key2] === "object") {
                    var obj_skills = obj_cat[key2];
                    for(var key3 in obj_skills) {
                        if(obj_skills[key3] !== null && typeof obj_skills[key3] === "object") {
                            var obj_subskills = obj_skills[key3];
                            for(var key4 in obj_subskills) {
                                if(skills_filter.indexOf(key4) > -1 && obj_subskills[key4] > 0) {
                                    media_val += obj_subskills[key4];
                                    count_val ++;
                                }
                            }  
                        } else {
                            if(skills_filter.indexOf(key3) > -1 && obj_skills[key3] > 0) {
                                media_val += obj_skills[key3];
                                count_val ++;
                            }
                        }
                    }
                } else {
                    if(skills_filter.indexOf(key2) > -1 && obj_cat[key2] > 0) {
                        media_val += obj_cat[key2];
                        count_val ++;
                    }
                }  
            }       
        }
        if(count_val > 0) {
            media_val = media_val/count_val;
        }
        return media_val;
    },
    getSkills(pos) {
        var skills = [];
        if(this.ruolo_group != "") {
         skills = this.skills[this.ruolo_group]['sogg_' + pos]['ids'];
     }
     return skills;
 },
 getDatoOgg(pos) {
    if(this.ruolo_group != "") {
        var value = null;
        var opta_skill = this.opta_skills[this.ruolo_group]['ogg_' + pos];
        this['skill_opta_' + pos] = opta_skill;
        value = this.osservato.opta[0].stats[0][opta_skill];
        return (value != null ? ( Number.isInteger(value) ? value : value.toFixed(2)) : ' - ');
    }   
    return " - ";
},
getDatoSogg(pos) {
    var skills = this.getSkills(pos);
    if(skills.length > 0) {
        var value = null;
        var count_vals = 0;
        var media_vals = 0;
        for(var i in this.osservato.valutazioni) {
            var valutazione = this.osservato.valutazioni[i];
            if(valutazione.fields && valutazione.fields.attivo) {
                var skills_val = JSON.parse(valutazione.skills);
                var media_val = this.getMedia(skills, skills_val); 
                if(media_val > 0) {
                    media_vals += media_val;
                    count_vals ++;
                }
            }
        }
        if(count_vals > 0) {
            value = media_vals/count_vals;
        }
        return (value != null ? ( Number.isInteger(value) ? value : value.toFixed(2)) : ' - ');
    }    
    return " - ";
},
getLabelSogg(pos) {
    var label = "N/D";
    if(this.ruolo_group != "") {
        label = this.skills[this.ruolo_group]['sogg_' + pos]['label'];
    }
    return label;
},
getBgOpta(value, skill) {
    var bg = '#2a6496';
    if(skill != "" && this.ruolo_group != "" && value != " - " && this.opta_range) {
        var obj_range = this.opta_range[this.ruolo_group];
        if(obj_range) {
            var array_range = obj_range[skill];
            if(array_range) {
                if(value <= array_range[0].from && value >= array_range[0].to) {
                    bg = '#00B386';
                } else if(value < array_range[1].from && value >= array_range[1].to) {
                    bg = '#2EC85B';
                } else if(value < array_range[2].from && value >= array_range[2].to) {
                    bg = '#7BDE3C'; 
                } else if(value < array_range[3].from && value >= array_range[3].to) {
                    bg = '#B8F100';
                } else if(value < array_range[4].from && value >= array_range[4].to) {
                    bg = '#E9F700';
                } else if(value < array_range[5].from && value >= array_range[5].to) {
                    bg = '#FFF300';
                } else if(value < array_range[6].from && value >= array_range[6].to) {
                    bg = '#FFDB00';
                } else if(value < array_range[7].from && value >= array_range[7].to) {
                    bg = '#FFB800';  
                } else if(value < array_range[8].from && value >= array_range[8].to) {
                    bg = '#FF8900';
                } else if(value < array_range[9].from) {
                    bg = '#FF0059';
                }
            }
        }
    }
    return {
        'font-weight': '700',
        'width': '100%',
        'color': '#000000',
        'background' : bg,
    }
},
getLabelOpta(skill) {
    var label = 'N/D';
    if(skill != "") {
        switch (skill) {
            case 'duel_90min':
            label = "% DUEL (Per 90 min)";
            break;
            case 'tackle_90min':
            label = "% TACKLE (Per 90 min)";
            break;
            case 'aerial_90min':
            label = "% DUEL AEREI (Per 90 min)";
            break;
            case 'int_90min':
            label = "NUMERO INTERCETTI (Per 90 min)";
            break;
            case 'pass_90min':
            label = "% PASS RIUSCITI (Per 90 min)";
            break;
            case 'keyPass_90min':
            label = "KEY PASS (Per 90 min)";
            break;
            case 'recovery_90min':
            label = "RECOVERY (Per 90 min)";
            break;
            case 'psInA3rd_90min':
            label = "PASS RICEVUTI ULTIMO 3° DI CAMPO (Per 90 min)";
            break;
            case 'tsr_90min':
            label = "TOTAL SHOTS RATIO (Per 90 min)";
            break;
            case 'crossCmp_90min':
            label = "CROSS COMPLETATI (Per 90 min)";
            break;
            case 'oneVsOne_90min':
            label = "% 1VS1 (Per 90 min)";
            break;
            case 'rcvryA3_90min':
            label = "RECOVERY A3 (Per 90 min)";
            break;
            case 'bgChncCrtd_90min':
            label = "BIG CHANCE CREATED (Per 90 min)";
            break;
            case 'expG_90min':
            label = "EXPECTED GOAL (Per 90 min)";
            break;
            case 'shotConv_90min':
            label = "SHOT CONVERSION (Per 90 min)";
            break;
            case 'sog_90min':
            label = "NUMERO DI TIRI IN PORTA (Per 90 min)";
            break;          
        }     
    }
    return label;
},
editConfronto(osservato) {
    this.$emit('editConfronto', osservato);
},
getPercentualeValutazioni(id) {
    this.$emit('getPercentualeValutazioni', id);
},
}
}
</script>
