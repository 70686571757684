<template id="esercizi">
	<section class="esercizi">

		<headapp></headapp>

		<div>
			<menusub></menusub>

			<div class="row no-gutters">
				<div class="d-none d-md-flex col-md-3 border-right">
					<div style="height:100vh;overflow:auto;">
						<card title="Filtri" class="w-100 border-0 rounded-0" comp="esercizifiltri" :params="{'skills': skills}"></card>
					</div>
				</div>
				<div class="col-md-9">
					<b-card no-body class="rounded-0 border-0">
						<b-tabs card class="p-0" v-model="exercises_tab">
							<b-tab title="I miei esercizi" class="p-0">
								<card :title="'Esercizi (' + (exercises.total ? exercises.total : 0) + ')'" class="border-0 rounded-0" comp="esercizielenco" :params="{'skills': skills}"></card>
							</b-tab>
							<b-tab title="Archivio" class="p-0">
								<card :title="'Archivio (' + (exercises_asroma.total ? exercises_asroma.total : 0) + ')'" class="border-0 rounded-0" comp="eserciziasroma" :params="{'skills': skills}"></card>
							</b-tab>
							<b-tab title="Certificati" class="p-0">
								<card :title="'Certificati (' + (exercises_certificati.total ? exercises_certificati.total : 0) + ')'" class="border-0 rounded-0" comp="esercizicertificati" :params="{'skills': skills}"></card>
							</b-tab>
							<b-tab title="Bozze" class="p-0">
								<card :title="'Bozze (' + (exercises_waiting.total ? exercises_waiting.total : 0) + ')'" class="border-0 rounded-0" comp="esercizibozze" :params="{'skills': skills}"></card>
							</b-tab>
							<b-tab title="Da validare" class="p-0">
								<card :title="'Da validare (' + (exercises_da_validare.total ? exercises_da_validare.total : 0) + ')'" class="border-0 rounded-0" comp="esercizidavalidare" :params="{'skills': skills}"></card>
							</b-tab>
						</b-tabs>
					</b-card>

				</div>
			</div>

		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
export default {

	data: function(){
        return {
            skills: [],
        }
    },

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		card:               require('../../../layout/card.vue').default,
	},

	computed:{
		...mapState(['rosaTeamId']),
		...mapState('editor', ['exercises', 'exercises_waiting', 'exercises_asroma', 'exercises_certificati', 'exercises_da_validare']),
		exercises_tab: {
			get() {
				return this.$store.state.editor.exercises_tab;
			},

			set(value) {
				this.$store.commit('editor/setExcerciseTab', value);
			},
		},
	},

	created() {
		this.getSkills();
		this.$store.commit('editor/setTitle', '');
		this.$store.commit('editor/setDrillId', null);
		this.$store.commit('editor/setObjIds', []);
	},

	methods: {
		getSkills() {
			axios.get(`/skill/object`)
			.then((res) => {
				this.skills = res.data;
			}).catch((err) => {
			});
		}
	},

	watch: {
		rosaTeamId() {
			this.$store.commit('editor/setPage', 1);
			if(this.exercises_tab == 0) {
				this.$store.dispatch('editor/filter');
			} else if(this.exercises_tab == 1) {
				this.$store.dispatch('editor/filter_asroma');
			} else if(this.exercises_tab == 2) {
				this.$store.dispatch('editor/filter_certificati');
			} else if(this.exercises_tab == 3) {
				this.$store.dispatch('editor/filter_waiting');
			} else if(this.exercises_tab == 4) {
				this.$store.dispatch('editor/filter_da_validare');
			}
		},  
		exercises_tab() {
			this.$store.commit('editor/setPage', 1);
			if(this.exercises_tab == 0) {
				this.$store.dispatch('editor/filter');
			} else if(this.exercises_tab == 1) {
				this.$store.dispatch('editor/filter_asroma');
			} else if(this.exercises_tab == 2) {
				this.$store.dispatch('editor/filter_certificati');
			} else if(this.exercises_tab == 3) {
				this.$store.dispatch('editor/filter_waiting');
			} else if(this.exercises_tab == 4) {
				this.$store.dispatch('editor/filter_da_validare');
			}
		}
	}, 
}
</script>
<style scoped>
.card-header {
	padding-bottom: 0.5rem;
}
.tab-content {
	padding: 0 !important;
	border: 0;
}
</style>
