<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		
		<b-container fluid>
			<div class="card">
				<div class="card-body">
					<div class="content">
						<div class="card border-0 rounded-0">
							<div class="card-body">
								<div class="">
									<b-row>
										<b-col class="card-title">Indice Contratti</b-col>
										<b-col class="text-right">
											<button class="btn btn-link" @click.prevent="ux.showType = 'card'" v-b-tooltip.hover title="Vista Griglia" placement="bottom"><i class="fa fa-th"></i></button>
											<button class="btn btn-link" @click.prevent="ux.showType = 'table'" v-b-tooltip.hover title="Vista Elenco" placement="bottom"><i class="fa fa-bars"></i></button>
											<b-button variant="success" @click.prevent="newContract">Nuovo Contratto</b-button>
										</b-col>
									</b-row>
								</div>
								<div class="content">

									<!-- Vista a Card -->
									<div class="row" v-if="ux.showType == 'card'">
										<div v-for="giocatore in contratti" :key="giocatore.persona.id" class="col-2">
											<div class="figurinagiocatore" :id="'giocatore_'+giocatore.persona.id">
												<div class="card mb-2">
													<div class="row">
														<div class="col-12 text-center">
															<router-link :to="{ name: 'contratti_detail', params: { id: giocatore.id }}"><img v-bind:src="giocatore.persona.avatar_url" height="200"></router-link>
														</div>
													</div>						
													<div class="card-body">
														<div class="font-bold text-center">{{ giocatore.persona.cognome + ' ' + giocatore.persona.nome }}</div>
														<div class="mb-1 text-center font-size-xs color-rosso">{{ giocatore.persona.ruolo != '' ? giocatore.persona.ruolo : '&nbsp;' }}</div>
														<!-- <div class="row no-gutters text-center font-size-sm">
															<div class="col" title="Infortuni"><i class="fa fa-h-square"></i><br> {{ giocatore.persona.injuries }}</div>
															<div class="col" title="Malattie"><i class="fa fa-medkit"></i><br>{{ giocatore.persona.illnesses }}</div>
															<div class="col" title="Test"><i class="fa fa-heartbeat"></i><br> {{ giocatore.persona.testrtp }}</div>
														</div>    -->
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-if="ux.showType == 'table'">
										
										<table class="table table-striped table-sm" v-if="contratti">
											<thead>
												<tr>
													<th colspan="2"></th>
													<th colspan="2" class="text-center text-muted small" style="background-color: rgba(0,0,0,.05)">Stagione <em>in corso</em></th>
												</tr>
												<tr>
													<th>Contratto</th>
													<th>Scadenza</th>
													<th>Importo Fisso</th>
													<th>Bonus Maturati</th>
												</tr>
											</thead>
											<tbody>
												<tr class="cursor-pointer" v-for="c in contratti" @click.prevent="$router.push({ name: 'contratti_detail', params: { id: c.entity.id } })">
													<td>
														<strong>{{ c.entity.persona.cognome }}</strong>
														{{ c.entity.persona.nome }}
													</td>
													<td>{{ c.entity.fine | formatDate }}</td>
													<td>{{ seasonFixed(c) ? seasonFixed(c).importo_lordo : 0 | money }}</td>
													<td>{{ c.amount_display }} <small class="text-muted">({{ howManyBonus(c) }})</small></td>
												</tr>
											</tbody>
										</table>
										<div v-else>Attendi il caricamento dei contratti…</div>

									</div>
									<!-- /Vista a Card -->
									<!--
									<div class="row">
										<div v-for="giocatore in contratti" :key="giocatore.persona.id" class="col-2">
											<div class="figurinagiocatore" :id="'giocatore_'+giocatore.persona.id">
												<div class="card mb-2">
													<div class="row">
														<div class="col-12 text-center">
															<router-link :to="{ name: 'contratti_detail', params: { id: giocatore.id }}"><img v-bind:src="giocatore.persona.avatar_url" class="img-fluid" height="200"></router-link>
														</div>
													</div>						
													<div class="card-body">
														<div class="font-bold text-center">{{ giocatore.persona.cognome + ' ' + giocatore.persona.nome }}</div>
														<div class="mb-1 text-center font-size-xs color-rosso">{{ giocatore.persona.ruolo != '' ? giocatore.persona.ruolo : '&nbsp;' }}</div>
														<!- -<div class="row no-gutters text-center font-size-sm">
															<div class="col" title="Test"><i class="fa fa-heartbeat"></i><br> {{ giocatore.bonuses.length }}</div>
														</div>- ->
													</div>
												</div>
											</div>
										</div>
									</div>-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-container>

		<modal-new-contract ref="newC"></modal-new-contract>

		<footerapp></footerapp>
	</section>
</template>

<script>
var Auth = require('../../../auth.js');
//import axios from 'axios';
//import * as types from '../../../store/types';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
	data: function(){
		return {
			loading: false,
			contratti: false,
			variabiliSquadra: false,
			new_contract: false,
			ux: {
				 showType: 'card'
			}
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.getContratti();
		},
		seasonId() {
			this.getContratti();
		},
		'ux.showType': {
			immediate: true,
			handler: function() {
				this.contratti = false;
				if(this.ux.showType == 'card')
					this.getContratti();
				else
					this.getContrattiDashboard();
			}
		}
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		Contratto: require('../../layout/contratti/Contratto.vue').default,
		ModalNewContract: require('../../layout/contratti/ModalNewContract.vue').default,
	},
	created: function () {
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid())
				return m.format('DD/MM/YYYY'); 

			return "N/D";
		},
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return '€ ' + formatter.format(v).replace('€', '');
		}
	},
	methods: {
		seasonFixed: function(c) {
			return _.find(c.entity.periodi, function(e) {
				return this.seasonId == e.variabili_squadra.stagione_id
			}.bind(this));
		},
		howManyBonus: function(c) {

			var bonusValidi = _.filter(c.bonuses, function(e) {
				return e.result === true;
			});

			return bonusValidi.length + '/' + c.bonuses.length;

		},
		newContract: function() {
			this.$refs.newC.show();
			this.new_contract = true;
		},
		getContrattiDashboard: function() {

			this.$http.get(this.$store.state.apiEndPoint + '/contratti/dashboard', {params: { team_id: this.rosaTeamId, season_id: this.seasonId  }}).then((response) => {
				if (response.ok) {
					this.contratti = response.data;
					this.loading = false;
				}
			});

		},
		getContratti: function() {				
			this.players = [];
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/contratti', {params: { team_id: this.rosaTeamId, season_id: this.seasonId  }}).then((response) => {
				if (response.ok) {
					this.contratti = response.data;
					this.loading = false;
				}
			});

			// /contratto/variabili-squadra/{squadra_id}/{stagione_id}
			this.$http.get(this.$store.state.apiEndPoint + '/contratto/variabili-squadra/' + this.rosaTeamId + '/' + this.seasonId).then((response) => {
				if (response.ok) {
					this.variabiliSquadra = response.data;
				}
			});
		},
	}
}
</script>