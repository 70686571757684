<template>
	<div>
		<div v-if="show_icone">
			<b-row class="my-1">
				<b-col cols="4">
					<b-form-input
					type="search"
					v-model="search_icona"
					placeholder="Quick search"
					></b-form-input>
				</b-col>
			</b-row>
			<div>
				<b-card no-body>
					<b-tabs pills card>
						<b-tab v-for="gruppo in filtered_icone" :title="gruppo.label" style="height: 300px; overflow-y: scroll; overflow-x: hidden;" lazy>
							<b-row>
								<b-col cols="2" class="mb-1" v-for="item in gruppo.items">
									<b-card align="center" style="cursor:pointer;height:100%;" @click="settaIcona(item)">
										<b-row style="height:100%">
											<b-col>
												<div><b-img v-if="item.url" :src="item.url" fluid width="50"></b-img></div>
												<div>{{ item.nome }}</div>
											</b-col>
										</b-row>
									</b-card>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	components: {
	},
	watch: {
		show_icone: function() {
			if(this.show_icone && !this.load_icone) {
				this.getIcone();
			}
		}
	},
	props: ['tipo', 'entita', 'entita_gruppi'],
	data: function() {
		return {
			search_icona: '',
			show_icone: false,
			load_icone: false,
			icone: [],
			icona_gruppi: [],
		}
	},

	computed: {
		filtered_icone: function () {
			if (this.search_icona != "") {
				var filtered_icone = [];
				for(var i in this.icone) {
					var item = this.icone[i];
					var filtered_items = item.items.filter((item) =>
						item.nome.toLowerCase().includes(this.search_icona.toLowerCase())
						);
					if(filtered_items.length > 0) {
						var option = {
							label: item.label,
							items: filtered_items,
						}
						filtered_icone.push(option);		
					}
				}
				return filtered_icone;
			}
			return this.icone;
		},
	},
	methods: {
		getIcone() {
			this.$http.get(this.$store.state.apiEndPoint + '/icone').then((response) => {
				if (response.ok) {
					this.icone = [];
					this.icona_gruppi = [];
					for(var i in response.data) {
						var item = response.data[i];
						var gruppo = item.gruppo ? item.gruppo : 'Senza gruppo';
						var index = this.icone.findIndex((obj) => obj.label == gruppo);
						if(index == -1) {
							if(item.gruppo) {
								this.icona_gruppi.push(gruppo);
							}
							var option = {
								label: gruppo,
								items: [],
							}
							option.items.push(item);
							this.icone.push(option);
						} else {
							this.icone[index].items.push(item);
						}
					}
					this.load_icone = true;
				}
			}, (response) => {
			});
		},

		settaIcona(icona) {
			this.$emit('settaIcona', icona);
			this.show_icone = false;
		},

		showIcone() {
			this.show_icone = !this.show_icone;
		}
	}
}

</script>