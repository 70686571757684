<template>
	<div>
		<b-row class="mb-1">
			<b-col>
				<label>Tipo di Osservazione</label>
				<b-form-select v-model="estensione.tipo_osservazione" required>
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Test', 'Colloquio', 'Colloquio Online', 'Match', 'Allenamento', 'Mental Training']" @click.prevent="estensione.tipo_osservazione = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
		sessionMixin
	],
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { tipo_osservazione: null });
		}
	},
	props: [ 'estensione', 'model', 'field' ],
	methods: {
		validate: function() {
			if(this.estensione.tipo_osservazione == null)
				return 'Devi selezionare una tipologia di osservazione.';
			return false;
		}
	}
};
</script>