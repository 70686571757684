<template id="rosa">

	<section>

		<headapp></headapp>
		<menusub></menusub>

		<b-card v-if="persona">

			{{ persona.cognome }}
			{{ persona.nome }}

			<div v-if="persona">
				<TavolaPermessi @refresh="fetchPersona(persona.id)" :persona="persona"></TavolaPermessi>
			</div>
			<b-button v-if="persona" @click.prevent="fetchPersona(persona.id)">Refresh</b-button>

		</b-card>
		
		<footerapp></footerapp>

	</section>

</template>

<script>

import pageBase from '@/mixins/pageBase.js';

export default {
	mixins: [ pageBase ],
	components: {
		TavolaPermessi: require('@/components/layout/Admin/TavolaPermessiPersona.vue').default
	},
	data: function() {
		return {
			persona: null
		}
	},
	watch: {
		'$route.params.persona_id': {
			immediate: true,
			handler: function() {
				this.fetchPersona(this.$route.params.persona_id);
			}
		}
	},
	created: function() {

	},
	mounted: function() {
		this.$root.$on('fetchPersona', this.fetchPersona);
	},
	computed: {
		
	},
	methods: {
		fetchPersona: function(personaId) {
			this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + personaId).then(function(data) {
				this.persona = data.data;
			});
		}
	},
	beforeDestroy() {
		this.$root.$off('fetchPersona');
	}
}

</script>