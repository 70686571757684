<template>
  <data-table
    api-url="/admin/partite"
    :columns="columns"
    :editFormData="matchesData"
    :canDelete="false"
    :defaultFilter="false"
    @editError="editError"
    @showModal="onShowModal">

    <template slot="filters">
      <b-form-input v-model="filters.homeTeam" placeholder="Squadra casa" class="inline mr-3" />
      <b-form-input v-model="filters.awayTeam" placeholder="Squadra trasferta" class="inline mr-3" />
      <b-form-input v-model="filters.stadium" placeholder="Stadio" class="inline mr-3" />
      <b-form-input v-model="filters.competition" placeholder="Competizione" class="inline mr-3" />
      <b-form-input v-model="filters.country" placeholder="Paese stadio" class="inline mr-3" />
      <b-form-input v-model="filters.date" placeholder="Data" type="date" class="inline mr-3" />
    </template>

    <template slot="edit-modal-body" slot-scope="row">
      <b-form-group label="Stadio">
        <multiselect
          v-model="matchesData.stadium"
          :options="stadiums"
          placeholder="Seleziona uno stadio"
          label="nome"
          track-by="id"
          :customLabel="customLabelStadio"
          :show-labels="false"
          :internal-search="false"
          :loading="stadiumsLoading"
          @search-change="searchStadiums" />
      </b-form-group>

      <b-form-group label="Data">
        <b-form-input placeholder="Data" v-model="matchesData.data" />
      </b-form-group>
    </template>
  </data-table>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import DataTable from './DataTable.vue';

import { ALERTS_PUSH } from '../../../../store/types';

export default {
  name: 'AdminMatches',
  components: { DataTable },

  data() {
    return {
      columns: [
        { key: 'id', label: 'ID', sortable: true },
        {
          key: 'data',
          label: 'Data',
          sortable: true,
          formatter: value => moment.utc(value).local().format('DD/MM/YYYY HH:mm')
        },
        { key: 'casa', label: 'Squadra casa', sortable: true },
        { key: 'trasferta', label: 'Squadra trasferta', sortable: true },
        { key: 'stadio', label: 'Stadio', sortable: true },
        { key: 'competizione', label: 'Competizione', sortable: true },
        { key: 'paese', label: 'Paese stadio', sortable: true },
        { key: 'actions', label: 'Azioni' },
      ],
      matchesData: {
        stadium: null,
        data: '',
      },
      stadiumsLoading: false,
      stadiums: [],
      filters: {
        homeTeam: '',
        awayTeam: '',
        stadium: '',
        competition: '',
        country: '',
        date: '',
      },
    };
  },

  methods: {
    editError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(ALERTS_PUSH, {
          status: 0,
          msg: err.response.data.error,
        });
      }
    },

    searchStadiums(query) {
      this.stadiumsLoading = true;
      axios.get('/stadi', {
        params: {
          additional_filters: {"stadium": query},
          per_page: 100,
        },
      }).then((res) => {
        this.stadiums = res.data.data;
        this.stadiumsLoading = false;
      }).catch(() => { this.stadiumsLoading = false; });
    },

    onShowModal(row) {
      this.matchesData.data = moment.utc(row.item.data).local().format('DD/MM/YYYY HH:mm');
    },
    customLabelStadio({ nome, city, paese }) {
      return nome + ' ' + (city ? ' - ' + city : '') + (paese ? ' - ' + paese : '');
    },
  },

  watch: {
    filters: {
      handler() {
        this.$root.$emit('data-table::filter', this.filters);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.pac-container {
    z-index: 1051 !important;
}
</style>
