<template>
	<div>
		<div class="spacer" style="width: 100%"></div>

		<div style="position: relative" v-if="player.width && player.height" :style="'width: '+player.width+'px; height: '+player.height+'px'">
			<video @canplay="playerIsReady" ref="player" :width="player.width" :height="player.height">
				<source :src="video" type="video/mp4">
			</video>
			<!-- <div style="pointer-events: none; position: absolute; left: 0; top: 0; overflow: hidden; z-index: 999" :style="'width: '+player.width+'px; height: '+player.height+'px'">

				<div style="transform-origin: top left;" :style="'width:' + width + 'px; height: ' + height + 'px; transform: scale('+player.width/width+')'">
					<iframe ref="rendererframe" @load="iFrameLoad" :src="'//0.0.0.0:8011/' + rendererUrl" style="width: 100%; height: 100%" frameborder="0"></iframe>
				</div>

			</div> -->
		</div>

		<div class="text-center my-1">
			<b-form-input @change="updatePlayerTime" type="range" :step="step" :value="currentTime" :min="currentMin" :max="currentMax"></b-form-input>
			<div><strong>{{ currentTime | duration }}</strong> - {{ frame }}</div>
			<b-button-group>
				<b-button @click.prevent="po.currentTime = cutIn; sendComp({ action: 'seek', at: player.current - cutIn })">Start</b-button>
				<b-button @click.prevent="seekSec(-1)">-1 S</b-button>
				<b-button @click.prevent="seekFps(-1)">-1 F</b-button>
				<b-button @click.prevent="play">{{ player.playing ? 'Pause':'Play' }}</b-button>
				<b-button @click.prevent="seekFps(1)">+1 F</b-button>
				<b-button @click.prevent="seekSec(1)">+1 S</b-button>
				<b-button @click.prevent="po.currentTime = parseFloat(cutOut); sendComp({ action: 'seek', at: player.current - cutIn })">End</b-button>
			</b-button-group>
		</div>
		
		<!-- <input type="checkbox" v-model="isAbsolute" /> -->
		<!--
		{{rendererUrl}}
		{{player.current}}

		<!-- <pre>{{player}}</pre> -->
	</div>
</template>

<script>

import moment from 'moment'
import _ from 'lodash'

export default {
	props: [ 'cutIn', 'cutOut', 'width', 'height', 'video', 'step', 'renderer', 'trimPayload' ],
	mounted: function() {
		this.ui();
	},
	filters: {
		duration: function(a) {
			return moment('2000-01-01').add(a, 'seconds').format('HH:mm:ss.SSS')
		}
	},
	computed: {
		frame: function() {
			return _.round(this.currentTime / this.step, 0);
		},
		rendererUrl: function() {
		//	return btoa(JSON.stringify({ elements: this.renderer.elements, duration: this.cutOut - this.cutIn }));
		},
		currentTime: function() {
			if(this.isAbsolute == true) return this.player.current;
			else return this.player.current - this.cutIn;
		},
		currentMin: function() {
			return 0;
			//else return this.player.cutIn;
		},
		currentMax: function() {
			this.po;
			if(this.isAbsolute == true) return this.po ? this.po.duration : 0;
			else return this.cutOut - this.cutIn;
		}
	},
	watch: {
		isAbsolute: function() {
			if(!this.isAbsolute && this.player.current < this.cutIn) {
				this.po.currentTime = _.clone(this.cutIn);
			}
		},
		'cutIn': function() {
			this.po.currentTime = this.cutIn;
		},
		'cutOut': function() {

		},
		'player.ready': {
			handler: function() {
				this.$emit('ready');
			}
		},
		'player.current': {
			handler: function() {
				this.$emit('tc', this.player.current - this.cutIn);
				this.$emit('tcabs', this.player.current);
				if(this.player.current > this.cutOut && !this.isAbsolute) {
					this.po.pause();
					this.po.currentTime = this.cutIn;
					this.player.playing = !this.player.playing;
				}
			}
		}
	},
	data: function() {
		return {
			po: null,
			isAbsolute: false,
			player: {
				playing: false,
				current: 0,
				width: 0,
				height: 0,
				ready: false
			}
		}
	},
	methods: {
		updatePlayerTime: function(a) {
			this.po.currentTime = this.isAbsolute ? parseFloat(a) : ( this.cutIn + parseFloat(a) );
		},
		iFrameLoad: function() {
			_.delay(function() {
				this.sendComp({ action: 'seek', at: this.player.current });
			}.bind(this), 100);
		},
		sendComp: function(what) {
			// this.$refs.rendererframe.contentWindow.postMessage(JSON.stringify(what), '*');
		},
		seekFps: function(amount) {
			this.player.current += (amount * this.step);
			this.po.currentTime = this.player.current;
			this.sendComp({ action: 'seek', at: this.player.current - this.cutIn });
		},
		seekSec: function(amount) {
			this.player.current += amount;
			this.po.currentTime = this.player.current;
			this.sendComp({ action: 'seek', at: this.player.current - this.cutIn });
		},
		play: function() {
			if(this.po.paused) {
				this.po.play();
				this.sendComp({ action: 'play' });
			} else {
				this.po.pause();
				this.sendComp({ action: 'pause' });
				this.sendComp({ action: 'seek', at: this.player.current - this.cutIn });
			}

			this.player.playing = !this.player.playing;
		},
		playerIsReady: function() {

			if(this.po) return false;

			this.po = this.$refs.player;
			this.player.ready = true;

			this.$emit('duration', this.po.duration);
			this.po.current = this.cutIn;

			this.po.ontimeupdate = function() {
				this.player.current = this.po.currentTime;
			}.bind(this);

		},
		setCurrent: function(value) {
			this.player.current = this.cutIn + value;
			this.po.currentTime = this.cutIn + value;
			this.sendComp({ action: 'seek', at: this.player.current - this.cutIn });
		},
		ui: function() {
			this.player.width = $('.spacer', this.$el).width();
			this.player.height = (this.player.width/this.width) * this.height;
		}
	}
}

</script>