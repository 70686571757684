export const ROUTES_TACTICALBOARD = [
    {
        path: '/tacticalboard/dashboard',
        name: 'tacticalboard_dashboard',
        component: require('../components/pages/tacticalboard/dashboard.vue').default,
        meta: {
            module: 'tacticalboard',
            label: 'Tactical Board',
            requiresAuth: true,
            showInMenu: false,
            enableRosaTeam: true,
            parent: '',
            permesso: 'tactical_board',
            logCategory: true,
        }
    },
];
