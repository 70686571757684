<template id="edit_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card title_page">
				<div class="card-body">
					<div class="card-title">
						<span>Modifica Valutazione</span>
					</div>
				</div>
			</div>	
			<div class="content_page">
				<b-form @submit="onSubmit">
				<b-row>
					<b-col cols="3">
						<div class="card" style="height:100%">
							<div style="text-align:center;">
								<b-img :src="valutazione.osservato.giocatore.avatar_url" fluid :alt="valutazione.osservato.giocatore.cognome + ' ' + valutazione.osservato.giocatore.nome" :width="130"></b-img>
							</div>
							<div class="card-body">
								<div class="mb-1 font-bold text-center">{{ valutazione.osservato.giocatore.nome }} {{ valutazione.osservato.giocatore.cognome }}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.osservato.giocatore.ruolo_ideale && valutazione.osservato.giocatore.ruolo_ideale.length > 0 ? valutazione.osservato.giocatore.ruolo_ideale[0].ruolo : '' }}</div>
								<div class="text-center font-size-sm">Classe {{ valutazione.osservato.giocatore.data_nascita | formatYear}}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.osservato.giocatore.squadra_militante ? valutazione.osservato.giocatore.squadra_militante.name : '' }}</div>
								<div class="text-center font-size-sm mt-3">
                        			<b-form-radio-group
                        			stacked
                        			buttons
                        			v-model="form.gradimento"	
                        			size="sm">
                        			<b-form-radio @click.native="selectGradimento(grad.id)" v-for="grad in gradimenti" :value="grad.id" :class="(grad.id == form.gradimento ? 'btn-grad-active' : 'btn-grad')" :style="'background-color:' + (grad.id == form.gradimento ? grad.colore : grad.colore + '7F')">{{grad.nome}}</b-form-radio>
                        			</b-form-radio-group>
                        		</div>
							</div>
						</div>
					</b-col>
					<b-col>
						<b-tabs>
						<b-tab :title="'Partita ' + (index + 1)" v-for="(item, index) in form.partite" :key="item">	
						<b-row>
							<b-col>
								<b-form-group id="competizioneGroup" horizontal label="Competizione" :label-cols="3" label-for="competizione">
									<b-form-input id="competizione" type="text" v-model="item.competizione"></b-form-input>
								</b-form-group>
								<b-form-group id="partitaGroup" horizontal label="Partita" :label-cols="3">
									<b-input-group>
										<b-form-input id="partita" type="text" v-model="item.partita"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="searchPartita(item)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
								<b-row>
									<b-col cols="8">
								<b-form-group id="dataGroup" horizontal label="Data / Ora" :label-cols="3" label-for="data">
									<b-form-input id="data" type="date" v-model="item.data"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="4">
								<b-form-group id="oraGroup" horizontal label="/" :label-cols="1" label-for="ora">
									<b-form-input id="ora" type="time" v-model="item.ora"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
								<b-form-group id="sistemaGiocoGroup" horizontal label="Sistema di gioco" :label-cols="3">
									<b-form-select v-model="item.sistema_gioco" :options="sistemi" value-field="id" text-field="sistema_gioco"></b-form-select>
								</b-form-group>
								<b-form-group id="ruoloPrimarioGroup" horizontal label="Ruolo primario" :label-cols="3">
									<b-form-select v-model="item.ruolo_primario" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
								</b-form-group>
								<b-form-group id="ruoloSecondarioGroup" horizontal label="Ruolo secondario" :label-cols="3">
									<b-form-select v-model="item.ruolo_secondario" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group id="meteoGroup" horizontal label="Meteo" :label-cols="3">
									<b-form-select v-model="item.meteo" :options="meteo" value-field="id" text-field="nome"></b-form-select>
								</b-form-group>
								<b-form-group id="ventiGroup" horizontal label="Vento" :label-cols="3">
									<b-form-select v-model="item.vento" :options="venti" value-field="id" text-field="nome"></b-form-select>
								</b-form-group>
								<b-form-group id="tipoCampoGroup" horizontal label="Tipologia campo" :label-cols="3">
									<b-form-select v-model="item.tipo_campo" :options="tipi_campo" value-field="id" text-field="nome"></b-form-select>
								</b-form-group>
								<b-form-group id="condizioniCampoGroup" horizontal label="Condizioni campo" :label-cols="3">
									<b-form-select v-model="item.condizioni_campo" :options="condizioni_campo" value-field="id" text-field="nome"></b-form-select>
								</b-form-group>
								<b-form-group id="infortunioGroup" horizontal label="Infortunio" :label-cols="3">
									<b-form-select v-model="item.infortunio" :options="infortuni" value-field="id" text-field="nome"></b-form-select>
								</b-form-group>	
								<b-form-group id="modalitaGroup" horizontal label="Modalità" :label-cols="3" label-for="modalita">
								<b-form-radio-group id="modalita" v-model="item.modalita">
									<b-form-radio value="allenamento">Allenamento</b-form-radio>
									<b-form-radio value="stadio">Stadio</b-form-radio>
									<b-form-radio value="video">Video</b-form-radio>
								</b-form-radio-group>
								</b-form-group>
								<b-button variant="primary" class="float-right ml-1" @click="removePartita(item)" title="Rimuovi partita" v-if="form.partite.length > 1 && form.partite.length == (index + 1)"><i class="fa fa-trash" aria-hidden="true"></i>
								</b-button>
								<!--b-button variant="primary" class="float-right" title="Videotag"><i class="fa fa-file-video-o" aria-hidden="true"></i>
								</b-button-->			
							</b-col>
						</b-row>
					</b-tab>
					<b-nav-item slot="tabs" @click.prevent="addPartita" href="#" title="Aggiungi partita">
          				<i class="fa fa-plus" aria-hidden="true"></i>
        			</b-nav-item>
					</b-tabs>
						<b-row>
							<b-col>
								<b-form-textarea id="note" v-model="form.note" placeholder="Note" :rows="3"></b-form-textarea>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col v-for="item in skills">
						<b-card :title="item[$i18n.t('lang')]">
							<b-list-group>
								<b-list-group-item v-for="skill in item.fields">
									<b-row :style="'width:100%;' + (skill.fields ? 'cursor:pointer;' : '')" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
										<b-col :cols="skill.fields ? '11' : '9'">
											{{skill[$i18n.t('lang')]}}
										</b-col>
										<b-col :cols="skill.fields ? '1' : '3'">
											<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
											<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
											<b-form-select v-if="!skill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id]">
												<template slot="first">
        											<option value="0"></option>
      											</template>
											</b-form-select>
										</b-col>
									</b-row>						
									<b-collapse visible :id="skill.id" style="width:100%">
										<b-list-group v-if="skill.fields">
											<b-list-group-item v-for="subskill in skill.fields">

												<b-row :style="'width:100%;' + (subskill.fields ? 'cursor:pointer;' : '')" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
													<b-col :cols="subskill.fields ? '11' : '9'">
														{{subskill[$i18n.t('lang')]}}
													</b-col>
													<b-col :cols="subskill.fields ? '1' : '3'">
														<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
														<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
														<b-form-select v-if="!subskill.fields" :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id]">
															<template slot="first">
        														<option value="0"></option>
      														</template>
														</b-form-select>
													</b-col>
												</b-row>						
												<b-collapse visible :id="subskill.id" style="width:100%">
													<b-list-group v-if="subskill.fields">
														<b-list-group-item v-for="subskill2 in subskill.fields">
															<b-row style="width:100%">
																<b-col cols="9">
																	{{subskill2[$i18n.t('lang')]}}
																</b-col>
																<b-col cols="3">
																	<b-form-select :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id][subskill.id][subskill2.id]">
																		<template slot="first">
        																	<option value="0"></option>
      																	</template>
																	</b-form-select>
																</b-col>
															</b-row>
														</b-list-group-item>
													</b-list-group>
												</b-collapse>
											</b-list-group-item>
										</b-list-group>
									</b-collapse>
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-col>
				</b-row>
				<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
				<b-button @click="eliminaValutazione" variant="secondary" class="mt-1">Elimina</b-button>
			</b-form>
			</div>
		</div>

		<b-modal id="searchMatchesModal" ref="searchMatchesModal" title="Ricerca partita" ok-only ok-title="Chiudi">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<p v-if="!is_search && !matches.length">Nessuna partita trovata!</p>
				<div v-if="!is_search && matches.length">
					<p>Trovate {{matches.length}} partite!</p>
					<hr/>
					<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="(match, index) in matches" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPartita(match)">
						<div class="col-md-9 my-1">
								<h5 style="display: inline;">{{ match.label }}</h5><br />
								<span class="text-muted">{{ match.date | formatDateHour }}</span><br />	
							</div>
						</div>
					</div>
				</div>
			</b-modal>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

import * as types from '../../../store/types'
import moment from 'moment'

export default {
	data: function() {
		return {
			valutazione: [],
			voti: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			form: {
				partite: [],	
				gradimento: 0,
				fields: {},
				note: ''
			},
			errors: [],
			infortuni: [],
			ruoli: [],
			sistemi: [],
			gradimenti: [],
			meteo: [],
			venti: [],
			tipi_campo: [],
			condizioni_campo: [],
			skills: [],
			matches: [],
			is_search: false,
			tab_partita: '',
			skills_value: [],
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
	},
	created: function () {

		this.$http.get(this.$store.state.apiEndPoint + '/infortuni').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.infortuni = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.ruoli = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.sistemi = response.data;    
            }
        }, (response) => {
            // error callback
        });  

		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.gradimenti = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/meteo').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.meteo = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/venti').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.venti = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/tipi/campo').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.tipi_campo = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/condizioni/campo').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.condizioni_campo = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/scouting/view/valutazione/' + this.$route.params.val_id + '/old').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.valutazione = response.data;
            	if(this.valutazione.osservato.giocatore.ruolo_ideale && this.valutazione.osservato.giocatore.ruolo_ideale.length > 0 && this.valutazione.osservato.giocatore.ruolo_ideale[0].id == 1) {
            		this.skills = JSON.parse(this.valutazione.fields.special);
            	} else {
            		this.skills = JSON.parse(this.valutazione.fields.standard);
            	}
            	this.skills_value = JSON.parse(this.valutazione.skills); 

            	this.form.gradimento = this.valutazione.id_gradimento;	
            	this.form.note = this.valutazione.note;	
            	for(var i in this.valutazione.partite) {
            		var partita = this.valutazione.partite[i];
            		var match = {
						competizione: partita.competizione,
						partita: partita.partita,
						data: moment(partita.data).format('YYYY-MM-DD'),
						ora: moment(partita.data).format('HH:mm'),
						sistema_gioco: partita.id_sistema_gioco,
						ruolo_primario: partita.id_ruolo_primario,
						ruolo_secondario: partita.id_ruolo_secondario,
						meteo: partita.id_meteo,
						vento: partita.id_vento,
						tipo_campo: partita.id_tipo_campo,
						condizioni_campo: partita.id_condizioni_campo,
						infortunio: partita.id_infortunio,
						wyscout_partita_id: "",
						modalita: partita.modalita
					}
					this.form.partite.push(match);
            	}
            	var jsonObject = {};
            	for (var k in this.skills) {
            		var item = this.skills[k];          
            		if(item.fields) {
            			for (var j in item.fields) {
            				var item2 = item.fields[j];
            				
            				if(item2.fields) {

            					for (var y in item2.fields) {
            						var item3 = item2.fields[y];
            						if(item3.fields) {

            							for (var x in item3.fields) {
            								var item4 = item3.fields[x];
            								jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id, item4.id], (this.skills_value[item.id][item2.id][item3.id][item4.id] ? this.skills_value[item.id][item2.id][item3.id][item4.id] : 0));
            							}

            						} else {
            							jsonObject = this.nest(jsonObject, [item.id, item2.id, item3.id], (this.skills_value[item.id][item2.id][item3.id] ? this.skills_value[item.id][item2.id][item3.id] : 0));
            						}	 
            					}

            				} else {
            					jsonObject = this.nest(jsonObject, [item.id, item2.id], (this.skills_value[item.id][item2.id] ? this.skills_value[item.id][item2.id] : 0));		
            				}	 
            			}
            		}

            	}
            	this.form.fields = jsonObject;
		}
        }, (response) => {
        });
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		}
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/edit/valutazione/' + this.valutazione.id, this.form).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            	this.$router.push({ name: "scouting_view_valutazione", params: { id: this.valutazione.osservato.id_giocatore, val_id: this.valutazione.id} }); 
            }
        }, (response) => {  
        	if (response.status == 422) {
        		this.errors = response.data;
        		for (var index in this.errors) {
        			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        		}
    		}
            // error callback
        });
		},
		nest: function(obj, keys, v) {
			if (keys.length === 1) {
				obj[keys[0]] = v;
			} else {
				var key = keys.shift();
				obj[key] = this.nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v);
			}

			return obj;
		},

		searchPartita: function(tab_partita) {
			var wsId = this.valutazione.osservato.giocatore.wyscout_id;
			if(wsId != "" && wsId != 0 && wsId != null) {
				this.tab_partita = tab_partita;
				this.matches = [];
				this.is_search = true;
				this.$refs.searchMatchesModal.show();
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/matches/by/player/' + wsId).then((response) => {

					if (response.ok)
					{
						this.matches = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			} else {
				alert("Nessuna partita disponibile!");
			}
		},

		selezionaPartita: function(partita) {
			var competitionId = partita.competitionId;
			if(competitionId != "" && competitionId != 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competition/' + competitionId).then((response) => {

					if (response.ok)
					{	
						var competizione = response.data;
						this.tab_partita.competizione = competizione.name;
						this.tab_partita.partita = partita.label;
						this.tab_partita.data = partita.date.substr(0, 10);
						this.tab_partita.ora = partita.date.substr(11, 5);
						this.tab_partita.wyscout_partita_id = partita.matchId;

						this.$refs.searchMatchesModal.hide();   							
					}
				}, (response) => {

				});
			}
		},

		addPartita: function () {
			this.form.partite.push({ competizione: '',
					partita: '',
					data: '',
					ora: '',
					sistema_gioco: 0,
					ruolo_primario: 0,
					ruolo_secondario: 0,
					meteo: 0,
					vento: 0,
					tipo_campo: 0,
					condizioni_campo: 0,
					infortunio: 0,
					wyscout_partita_id: '',
					modalita: '' });
		},
		removePartita: function (item) {
			var index = this.form.partite.indexOf(item);	
			this.form.partite.splice(index, 1);
		},
		selectGradimento(id) {
			this.form.gradimento = id;
		},

		eliminaValutazione: function() {
			if(confirm("Confermi l'eliminazione della valutazione?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/delete/valutazione/' + this.valutazione.id).then((response) => {

					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$router.push({ name: "scouting_view_giocatore", params: { id: this.valutazione.osservato.id_giocatore } });
					}
				}, (response) => {
					this.errors = response.data;
        			for (var index in this.errors) {
        				this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        			}
				});
			}
		},
	}

}

</script>
