<template>
	<div class="my-3">
		<b-row class="mb-1">
			<b-col>
				<label>Tipologia</label>
				<b-form-select v-model="estensione.tipo">
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Pre-match', 'Post-match', 'Analisi individuale']" @click.prevent="estensione.tipo = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
			<b-col>
				<label>Location</label>
				<b-form-select v-model="estensione.location">
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Sala champions', 'Scuola', 'Hotel', 'Onesti']" @click.prevent="estensione.location = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
			<b-col cols="12">
				<label>Nota</label>
				<b-form-textarea v-model="estensione.nota"></b-form-textarea>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
	sessionMixin
	],
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { tipo: null, location: null, nota: null });
		}
	},
	props: [ 'estensione', 'model', 'field' ],
};
</script>