<template>
	<div>
		<div v-if="risultati">
			<b-table-simple striped small responsive sticky-header="80vh">
				<b-thead style="background-color: white;">
					<b-tr>
						<b-th class="border-0" sticky-column style="min-width:200px">Giocatore</b-th>
						<!--b-th v-for="head in risultati.intestazioni" :class="{ 'rotate': fase.setup.matrixLabels && fase.setup.matrixLabels == 'vertical'}"-->
						<b-th class="border-0" v-for="head in risultati.intestazioni">	
							<span>{{ head.label.replaceAll('_', ' ') }}</span>
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="player,k in theTabella">
						<b-td class="small" sticky-column style="min-width:200px">
							{{ getPlayer(player.player_id).cognome + ' ' + getPlayer(player.player_id).nome }}
							<a href="javascript:;" @click.prevent="removeLine(player)" v-if="risultati.multirow"> <i class="fa fa-times" aria-hidden="true" title="Rimuovi riga"></i></a>
						</b-td>
						<b-td v-for="head,k in risultati.intestazioni">
							<div class="d-flex align-items-center">
								<div class="flex-grow-1" :is="typeOf(head.type)" :test="player" :field="k" :options="head" :dataLayer="dataLayer"></div>
								<div class="pl-2 pr-1 small" style="cursor: pointer; color: #ba2e3b" v-if="player[k] != null" @click.prevent="askEmpty(player, k)">
									<i class="fa fa-trash" aria-hidden="true"></i>
								</div>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</div>
</template>

<style type="text/css">

th.rotate {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: left;
}

th.rotate span {
	display: block;
	padding-left: 10px;
	margin-bottom: 5px;
	transform: rotate(180deg);
}

</style>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	mixins: [
		sessionMixin
	],
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
		SessioneMatriceDate: require('@/components/layout/Sessioni/Matrix/Date.vue').default,

		SessioneMatriceCustomfms: require('@/components/layout/Sessioni/Matrix/Custom/Fms.vue').default,
		SessioneMatriceCustomcmjcpfrl: require('@/components/layout/Sessioni/Matrix/Custom/CmjConcentricPeakForceRightLeft.vue').default,
		SessioneMatriceCustomnordmvcrlf: require('@/components/layout/Sessioni/Matrix/Custom/NordMvcRightLeftForce.vue').default,
		SessioneMatriceCustomgroinmvcfrl: require('@/components/layout/Sessioni/Matrix/Custom/GroinMvcForceRightLeft.vue').default,
		SessioneMatriceCustomlrmfa: require('@/components/layout/Sessioni/Matrix/Custom/LRMaxForceAverage.vue').default,
	},
	watch: {
		risultati: {
			deep: true,
			handler: function() {

				var selectors = _.filter(this.risultati.intestazioni, { type: 'selector' });

				_.each(selectors, function(sel) {
					if(!_.find(this.dataLayer, { url: sel.url })) {
						this.$http.get(this.$store.state.apiEndPoint + sel.url).then(function(data) {
							this.dataLayer.push({
								url: sel.url,
								data: data.data
							});
						});
					}
				}.bind(this));

			},
			immediate: true
		}
	},
	created: function() {

		

	},
	props: [ 'risultati', 'fase' ],
	data: function() {
		return {
			dataLayer: [],
			ux: {
				show: true
			}
		}
	},
	
	computed: {
		theTabella: function() {

			var out = [];
			_.each(this.risultati.table, function(righe, playerId) {
				_.each(righe, function(riga) {
					riga.player_id = playerId;
					out.push(riga);
				});
			}.bind(this));

			return _.orderBy(out, function(p) {
				return this.getPlayer(p.player_id).cognome.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			}.bind(this));

			return out;

		},
		tabella: function() {
			var chiavi = _.keys(this.risultati.intestazioni);
			return chiavi;
		}
	},
	methods: {
		removeLine: function(line) {

			var c = _.cloneDeep(line);
			var p = c.player_id;
			// delete(c.player_id);

			var positionToRemove = null;
			positionToRemove = _.findKey(this.risultati.table[ p ], function(a) {
				return JSON.stringify(a) === JSON.stringify(c);
			});

			console.log(positionToRemove);

			this.risultati.table[p].splice(parseInt(positionToRemove), 1);

		},
		askEmpty: function(player, k) {
			if(player[k] != null) {
				if(confirm('Confermi la rimozione del dato?'))
					player[k] = null;
			}
		},
		getPlayer: function(id) {
			return _.find(this.fase.persone, { id: parseInt(id) });
		},
		typeOf: function(tipo) {
			return 'SessioneMatrice' + _.capitalize(tipo);
		}
	}
}

</script>