<template>
	<div>
		<b-input size="sm" type="text" v-model="test[field]" v-if="!readonly" style="min-width:100px"></b-input>
		<span v-else>
			<span v-b-popover.hover.top="test[field]" v-if="test[field]" title="Note">
				💬
			</span>
		</span>
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	computed: {
	}
}
	
</script>