<template>
  <b-modal id="add-mission-modal" ref="add-mission-modal" title="Aggiungi missione" centered size="lg">
    <b-row v-if="mission.match">
      <b-col>
        <h5>Partita</h5>
        <strong>{{ mission.match.casa }} - {{ mission.match.trasferta }}</strong><br />
        {{ matchDateFormat(mission.match.data) }}
      </b-col>
      <b-col>
        <h5>Intervallo di viaggio</h5>
        {{ tripDates }}
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <h5>Osservatori</h5>
        <b-form-checkbox-group v-model="selectedOsservatori" :options="osservatoriCheckbox">
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <h5>Note</h5>
        <b-form-textarea v-model="notes" rows="3" />
      </b-col>
    </b-row>
    <b-button type="button" variant="primary" @click="aggiungiMissione" class="mt-3" :disabled="disabled">Salva</b-button>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import matchesMixins from '../../../mixins/matches';
import * as types from '../../../store/types'

export default {
  name: 'MissionModal',
  mixins: [matchesMixins],

  data() {
    return {
      notes: '',
      selectedOsservatori: [],
      osservatori: [],
      disabled: false,
      errors: [],
    };
  },

  computed: {
    ...mapState('geomatch', ['mission', 'filters']),
    tripDates() {
      let result = '';

      if (this.filters && this.filters.from) {
        result += this.matchDateFormat(`${this.filters.from.date} ${this.filters.from.time}`);
      }

      if (this.filters && this.filters.to) {
        result += ` - ${this.matchDateFormat(`${this.filters.to.date} ${this.filters.to.time}`)}`;
      }

      return result;
    },

    osservatoriCheckbox() {
      return this.osservatori.map(item => ({
        text: item.nomeCompleto,
        value: item.id,
      }));
    },
  },

  mounted() {
    axios.get('/osservatori/newscout')
      .then((res) => {
        this.osservatori = res.data;
      }).catch(() => {});
  },
  methods: {
    aggiungiMissione() {
      this.disabled = true;
      var missione = {
        tipo: 'partita',
        scadenza: this.mission.match.data.substr(0, 10),
        osservato: null,
        osservatori: this.selectedOsservatori,
        wyscout_partita_id: this.mission.match.wyscout_id,
        partita: this.mission.match.casa + ' - ' + this.mission.match.trasferta,
        competizione: this.mission.match.competizione,
        data_partita:  this.mission.match.data.substr(0, 10),
        ora_partita: this.mission.match.data.substr(11, 5),
        evento: 'partita',
        luogo: 'stadio',
        viaggio_dal_data: this.filters.from.date,
        viaggio_dal_ora: this.filters.from.time,
        viaggio_al_data: this.filters.to.date,
        viaggio_al_ora: this.filters.to.time,
        nota: this.notes,
        newscout: 1,
      };
      this.$http.post(this.$store.state.apiEndPoint + '/scouting/add/missione', missione).then((response) => {
            // success callback
            if (response.ok)
            {
              this.disabled = false;
              this.selectedOsservatori = [];
              this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
              this.$refs['add-mission-modal'].hide();
            }
        }, (response) => {  
          if (response.status == 422) {
            this.disabled = true;
            this.errors = response.data;
            for (var index in this.errors) {
              this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
            }
          }
            // error callback
        });
    },
  }
};
</script>
