<template>

	<b-card>

		<b-row>
			<b-col>
				<b-button v-b-toggle.collapse-import variant="outline-primary" size="xs">Importa Permessi/Gruppi da Utente</b-button>
				<b-collapse id="collapse-import" class="mt-2">
					<b-row align-v="center">
						<b-col>
							<multiselect size="sm" v-model="utenteToImport" :options="utenti" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="true" placeholder="Seleziona utente" label="nomeCompleto" track-by="id" :preselect-first="false"></multiselect>
						</b-col>
						<b-col cols="2">
							<b-button variant="outline-success" :disabled="!utenteToImport" @click.prevent="importaPermessiUtente">Importa</b-button>
						</b-col>
						<b-col>
							<b-form-checkbox v-model="overridePermessi" :value="true" :unchecked-value="false">Sovrascrivi permessi/gruppi attuali</b-form-checkbox>
						</b-col>
					</b-row>
				</b-collapse>
			</b-col>
			<b-col class="text-right">
				<b-button variant="outline-primary" size="xs" @click="ux.show = true">Modifica Anagrafica Utente</b-button>
			</b-col>
		</b-row>

		<b-row v-if="catalogo.gruppi" class="mt-3">
			<b-col :cols="9">

				<TabellaPermessi @deletePermessi="action_deletePermessi" @updatePermessi="action_updatePermessi" @newPermessi="action_newPermessi" :utente="persona.utente" :permessi="permessiForniti" :catalogo="catalogo"></TabellaPermessi>

			</b-col>
			<b-col :cols="3" class="pl-2">
				<div v-if="persona.utente.squadre_societa.length > 0">
					<h5>Squadre Associate</h5>
					<ul>
						<li v-for="squadra in persona.utente.squadre_societa">{{ squadra.squadra.name }} - {{ squadra.squadra.categoria.name }}</li>
					</ul>
					<hr />
				</div>
				<h5>Gruppi</h5>
				<hr />
				<SingoloGruppo @newGruppo="action_createGroup" @updateGruppo="action_updateGroup" @removeGruppo="action_removeGroup" :gruppo="gruppo" :chiave="gruppo.id_gruppo + '_' + gruppo.categoria_id" :catalogo="catalogo" :permessi="byGroup[gruppo.id_gruppo] ? byGroup[gruppo.id_gruppo][gruppo.categoria_id]:[]" v-for="gruppo in persona.utente.utenti_gruppi"></SingoloGruppo>
				<b-button class="mt-2" variant="outline-success" block size="sm" @click.prevent="addGroup">Aggiungi Gruppo</b-button>
			</b-col>
		</b-row>

		<PersonaUtenteModal :persona="persona" :ux="ux" @close="ux.show = false"></PersonaUtenteModal>

	</b-card>

</template>

<script>

import pageBase from '@/mixins/pageBase.js';

export default {
	mixins: [ pageBase ],
	props: [ 'persona' ],
	components: {
		SingoloGruppo: require('@/components/layout/Admin/SingoloGruppo.vue').default,
		TabellaPermessi: require('@/components/layout/Admin/TabellaPermessi.vue').default,
		PersonaUtenteModal: require('@/components/layout/Admin/PersonaUtenteModal.vue').default,
	},
	data: function() {
		return {
			permessiForniti: null,
			catalogo: {
				permessi: null,
				gruppi: null,
				categorie: null
			},
			permessiUtente: null,
			utenti: [],
			utenteToImport: null,
			overridePermessi: false,
			ux: {
				show: false,
			}
		}
	},
	watch: {
		'persona': {
			immediate: true,
			handler: function() {
				this.fetchPermessi(this.persona.id);
			}
		}
	},
	created: function() {
		this.fetchCatalogoGruppi();
		this.fetchCatalogoPermessi();
		this.fetchCatalogoCategorie();
		this.fetchUtenti();
	},
	mounted: function() {

	},
	computed: {
		byGroup: function() {
			return _.mapValues(_.groupBy(this.permessiUtente, 'byGroup'), function(a) {
				return _.groupBy(a, 'categoria_id');
			});
		},
		byCategory: function() {
			return _.groupBy(_.filter(this.permessiUtente, function(a) { return a.categoria_id; }), 'categoria_id');
		},
		onlyMe: function() {
			return _.groupBy(this.permessiUtente, 'byGroup')['null'];
		}
	},
	methods: {
		addGroup: function() {
			this.persona.utente.utenti_gruppi.push({
				id_gruppo: null,
				categoria_id: null,
				utente_id: this.persona.utente.id
			});
		},
		action_removeGroup: function(group) {
			if(confirm("Confermi di voler rimuovere il gruppo ?")) {

				this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + this.persona.id + '/permessi/remove-group', group).then(function(data) {
					this.$emit('refresh');
				});

				console.log('Rimuovi Gruppo', group);
			}
		},
		action_createGroup: function(group) {

			this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + this.persona.id + '/permessi/add-group', group).then(function(data) {
				this.$emit('refresh');
			}).catch(function (error) {
				for (var index in error.data) {
					this.alertPush(error.data[index], 0);
				}
			});

			console.log('Crea Nuovo Gruppo', group);
		},
		action_updateGroup: function(group) {

			this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + this.persona.id + '/permessi/update-group', group).then(function(data) {
				this.$emit('refresh');
			});

			console.log('Aggiorna Gruppo', group);
		},
		action_newPermessi: function(permessi) {

			this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + this.persona.id + '/permessi/add', permessi).then(function(data) {
				this.$emit('refresh');
			});

			console.log('Nuovi Permessi', permessi);
		},
		action_deletePermessi: function(permessi) {
			if(confirm("Confermi di voler rimuovere i permessi ?")) {

				this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + this.persona.id + '/permessi/delete', permessi).then(function(data) {
					this.$emit('refresh');
				});

				console.log('Delete Permessi', permessi);
			}
		},
		action_updatePermessi: function(permessi) {

			this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + this.persona.id + '/permessi/update', permessi).then(function(data) {
				this.$emit('refresh');
			});

			console.log('Update Permessi', permessi);
		},
		getGruppo: function(id) {
			return _.find(this.catalogo.gruppi, {id});
		},
		fetchCatalogoGruppi: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/admin/gruppi').then(function(data) {
				this.catalogo.gruppi = data.data;
			})
		},
		fetchCatalogoCategorie: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/categories').then(function(data) {
				this.catalogo.categorie = data.data;
			})
		},
		fetchCatalogoPermessi: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/admin/permessi').then(function(data) {
				this.catalogo.permessi = data.data;
			})
		},
		fetchPermessi: function(id) {
			this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + id + '/permessi').then(function(data) {
				this.permessiUtente = data.data;
				this.impostaPermessiTutti();
			});
		},
		fetchUtenti: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/admin/utenti/football').then(function(data) {
				this.utenti = data.data;
			});
		},
		impostaPermessiSingoli: function() {
			this.permessiForniti = _.cloneDeep(this.onlyMe);
		},
		impostaPermessiTutti: function() {
			this.permessiForniti = _.cloneDeep(this.permessiUtente);
		},
		importaPermessiUtente: function() {
			if(confirm("Confermi di voler copiare i permessi/gruppi dell'utente selezionato?")) {
				this.$http.post(this.$store.state.apiEndPoint + '/admin/persona/' + this.persona.id + '/permessi/import', { persona_id: this.utenteToImport.persona_id, override: this.overridePermessi}).then(function(data) {
					this.alertPush(data.data, 1);
					this.$emit('refresh');
				}).catch(function (error) {
					for (var index in error.data) {
						this.alertPush(error.data[index], 0);
					}
				});
			}
		},
	}
}

</script>