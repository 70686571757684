<template>
	<div>
		<b-modal size="lg" v-model="ux.show">

			<template #modal-header>
				Nuovo Contratto
			</template>

			<template #modal-footer>

				<div v-if="picked">
					<b-button-group>
						<b-button @click.prevent="picked = false">Scegli altro Giocatore</b-button>
						<b-button @click.prevent="createContract" variant="success">Crea nuovo Contratto per il giocatore</b-button>
					</b-button-group>
				</div>
				<div v-else>
					<b-button @click.prevent="show" size="small" variant="outline-secondary">Chiudi</b-button>
				</div>

			</template>

			<b-form-group label="Cerca Giocatore">
				<b-input type="text" v-model="search"></b-input>
			</b-form-group>

			<div v-if="results && !picked">
				<b-row>
					<b-col>
						<strong>Giocatori Interni</strong>
						<b-list-group>
							<b-list-group-item v-for="o in results.our" @click.prevent="pick(o)">
								<div>{{o.nome}} {{o.cognome}}</div>
								<div class="small">{{o.nascita_data}} - {{ o.last_carriera_sportiva.squadra.officialName }}</div>
								<span class="text-warning" v-if="o.contratto">È già presente un contratto per questa Persona</span>
							</b-list-group-item>
						</b-list-group>

					</b-col>
					<b-col>

						<strong>Altri Giocatori</strong>
						<b-list-group>
							<b-list-group-item v-for="o in results.other" @click.prevent="pick(o)">
								<div>{{o.nome}} {{o.cognome}}</div>
								<div class="small">{{o.nascita_data}} - {{ o.last_carriera_sportiva.squadra.officialName }}</div>
							</b-list-group-item>
						</b-list-group>

					</b-col>
				</b-row>
			</div>
			<div v-else-if="picked">

				<b-row class="flex-items-middle">
					<b-col :cols="2">
						<img :src="picked.avatar_url" class="img-fluid" />
					</b-col>
					<b-col class="align-self-center">
						<div class="lead"><strong>{{ picked.cognome }}</strong> {{ picked.nome }}</div>
						<div>{{ picked.nascita_data }} - {{picked.last_carriera_sportiva.squadra.name }}</div>
					</b-col>
				</b-row>

			</div>

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'

export default {
	watch: {
		'ux.show': {
			deep: true,
			handler: function() {
				if(this.ux.show == false)
					this.rule = false;
			}
		}
	},
	props: [ 'position' ],
	data: function() {
		return {
			rule: false,
			ux: {
				show: false
			},
			picked: false,
			results: false,
			search: ''
		}
	},
	watch: {
		search: {
			deep: true,
			handler: _.debounce(function() {
				if(this.search.length > 3) {
					this.$http.get(this.$store.state.apiEndPoint + '/contratti/cerca-giocatore?search=' + this.search).then(function(data) {
						this.results = data.data;
					});
				}
			}, 400)
		}
	},
	methods: {
		createContract: function(a) {
			this.$http.post(this.$store.state.apiEndPoint + '/contratto/create', { persona_id: this.picked.id }).then(function(data) {
				this.$router.push({ name: 'contratti_edit', params: { id: data.data.id } });
			})
		},
		pick: function(a) {
			if(a.contratto) return false;
			this.picked = a;
		},
		show: function() {
			this.ux.show = !this.ux.show;
		}
	}
}

</script>