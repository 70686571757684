<template id="view_squadra">
	<section class="giocatore">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">

					<div class="row flex-items-xs-middle">
						<div class="col-4">

							<div class="text-uppercase color-rosso font-bold font-size-lg">
								{{ squadra.officialName }}
							</div>
							<div class="text-muted">
								{{ squadra.city }}
							</div>
						</div>
						<div class="col-8 text-right">
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<div class="card h-100">
							<div class="card-body">
								<div class="row flex-items-xs-middle">
									<div class="col-4 offset-sm-1">
										<b-img :src="squadra.logo_url" fluid :alt="squadra.officialName" :width="120"></b-img>
									</div>
									<div class="col-6 offset-sm-1">

										<table class="table table-sm table-giocatore">
											<tr> <td>Nome</td> <td>{{ squadra.name }}</td> </tr>
											<tr> <td>Nome ufficiale</td> <td>{{ squadra.officialName }}</td> </tr>
											<tr> <td>Tipo</td> <td>{{ squadra.type }}</td> </tr>
											<tr> <td>Categoria</td> <td> {{ squadra.categoria ? squadra.categoria.name : squadra.category }} </td> </tr>
											<tr> <td>Genere</td> <td>{{ squadra.gender }}</td> </tr>
											<tr> <td>Città</td> <td>{{ squadra.city }}</td> </tr>
											<tr> <td>Stadio</td> <td>{{ squadra.stadio ? squadra.stadio.nome : ''}}</td> </tr>
											<tr> <td>Paese</td> <td><flag :country="squadra.paese ? squadra.paese.sigla : ''" :size="16"></flag> {{squadra.paese ? squadra.paese.paese : ''}}</td> </tr>	
										</table>

										<div v-if="auth.isUserEnable('squadre_providers')">
											<entita-api-manager :entityId="$route.params.id" entity="Squadre" :readonly="true"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="card h-100"> 
							<div class="card-body">
								<div class="card-title">
									<span>Allenatore</span>
								</div>
								<div class="row flex-items-xs-middle" v-if="squadra.societa.length == 0 && squadra.allenatore && squadra.allenatore.length > 0">
									<div class="col-4 offset-sm-1">
										<a :href="'#/newscout/view/allenatore/' + squadra.allenatore[0].id" target="_blank"><b-img :src="squadra.allenatore[0].avatar_url" fluid :alt="squadra.allenatore[0].cognome + ' ' + squadra.allenatore.nome" :width="120"></b-img></a>
									</div>
									<div class="col-6 offset-sm-1">
										<table class="table table-sm table-giocatore">
											<tr> <td>Cognome</td> <td>{{ squadra.allenatore[0].cognome }}</td> </tr>
											<tr> <td>Nome</td> <td>{{ squadra.allenatore[0].nome }}</td> </tr>
											<tr> <td>Data di nascita</td> <td>{{ squadra.allenatore[0].nascita_data | formatDate }}</td> </tr>
											<tr> <td>Paese di nascita</td> <td><flag :country="squadra.allenatore[0].nascita_paese ? squadra.allenatore[0].nascita_paese.sigla : ''" :size="16"></flag> {{ squadra.allenatore[0].nascita_paese ? squadra.allenatore[0].nascita_paese.paese : ''}}</td> </tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="!auth.isUserEnable('scout_not_skills')">
					<div class="col" v-for="cat in skills_tags">
						<tagscategorie class="h-100" :skills_tags="cat.fields" :title="cat.it" @selectSkillTags="selectSkillTags"></tagscategorie>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="card h-100"> 
							<div class="card-body">
								<div class="card-title">
									<span>Giocatori osservati</span>
								</div>
								<b-row>
									<b-col cols="6" class="border-bottom" v-for="(giocatore, index) in squadra.giocatori">
										<b-row class="m-1" align-v="center">
											<b-col cols="5">
												<a :href="'#/newscout/view/giocatore/' + giocatore.id" target="_blank"><img :src="giocatore.avatar_url" height="80" /></a>
											</b-col>
											<b-col class="pl-2">
												{{giocatore.cognome}}<br/>{{giocatore.nome}}
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="card h-100"> 
							<div class="card-body">
								<div class="card-title">
									<span>Reports partite</span>
								</div>
								<b-button v-if="!auth.isUserEnable('scout_not_skills')" variant="primary" style="position:absolute;top:25px;right:25px;" @click="selectGenericTags"><i class="fa fa-youtube-play"></i> Azione generica </b-button>
								<b-table striped hover :items="squadra.report_partite_scouting_home.concat(squadra.report_partite_scouting_away)" 
								:fields="[{key: 'partita.partita', label: 'Partita'},{key: 'partita.data', label: 'Data', formatter: this.formatDateHour},{key:'report', 'class': 'text-center'}]">
								<template #cell(report)="row">
									<b-button size="sm" variant="primary" title="download report" @click="downloadReport(row.item.id)">
										<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
									</b-button>
								</template>
							</b-table>
						</div>
					</div>
				</div>
				<div class="col" v-if="!auth.isUserEnable('scout_not_skills')">
					<card class="h-100" title="Riassunto" comp="griassunto" :params="{'riassunti': riassunti}"></card>
				</div>
			</div>
		</div>
		<b-modal id="tagsModal" size="xl" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
			<b-row v-if="skill_tags_sel">
				<b-col cols="3" style="overflow-y:auto;height:550px;">
					<div v-for="(tag, index) in videotags">
						<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag" @deleteTag="deleteTag"></thumbtag>
					</div>
				</b-col>
				<b-col cols="9">
					<video
					id="videotags-wrapper"
					class="video-js vjs-default-skin vjs-16-9"
					controls
					data-setup="{}">
					</video>
					<b-pagination size="sm" align="center" :total-rows="videotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
					<div v-if="tag_sel">
						<p class="m-1">{{ count }} - {{ skillName }}</p>
						<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
						<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
						<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
						<p class="m-1">{{ tag_sel.nota }}</p>
					</div>
				</b-col>
			</b-row>
		</b-modal>
		<b-modal id="riassuntoModal" size="xl" ref="riassuntoModal" :title="titleRiassunto" class="fs-modal" @hidden="onHiddenRiassunto" @shown="onShowRiassunto" ok-only ok-title="Chiudi">
			<div>
				<video
				id="video-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
		</div>
	</b-modal>
	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-errors';
import 'videojs-playlist';

export default {
	data: function() {
		return {
			squadra: [],
			skills_tags: [],
			generic_tags: [],
			skill_tags_sel: null,
			videotags: [], 
			tag_sel: null,
			count: 0,
			title: '',
			reload: false,
			riassunti: [],
			playlist: [],
			titleRiassunto: '',
			errors_player: {
			    4: {
			      headline: 'Video non disponibile',
			      type: '',
			      message: 'La clip non è stata ancora generata.<br/>Riprova più tardi.',
			    }
			}
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,

		flag: 		require('../../layout/flag.vue').default,
		card: 		require('../../layout/card.vue').default,
		tagscategorie: 		require('../../layout/tagscategorie.vue').default,
		thumbtag:   require('../../layout/thumbtag.vue').default,
		EntitaApiManager: 	require('@/components/layout/entita_api/manager.vue').default,
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/newscout/view/squadra/' + this.$route.params.id).then((response) => {
			if (response.ok) {
				this.squadra = response.data; 

				this.$http.get(this.$store.state.apiEndPoint + '/scouting/videotag/squadra/' + this.squadra.id).then((response) => {
					if (response.ok)
					{
						this.skills_tags = response.data.skills_tags;
						this.generic_tags = response.data.generic_tags;  
					}
				}, (response) => {
		            // error callback
		        });

				this.$http.get(this.$store.state.apiEndPoint + '/scouting/riassunto/squadra/' + this.squadra.id).then((response) => {
					if (response.ok)
					{
						this.riassunti = response.data.riassunti; 
					}
				}, (response) => {
		            // error callback
		        });
			}
		}, (response) => {
            // error callback
        });

		this.$bus.$on('selectRiassunto', ($event) => {
			this.selectRiassunto($event); 		
		});
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	}, 
	methods: {
		selectSkillTags(skill_tags, title) {
			this.title = title;	
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.tagsModal.show();
		},
		selectGenericTags() {
			if(this.generic_tags.length > 0) {
				this.title = 'Azione generica';	
				this.videotags = [];
				this.skill_tags_sel = 'Azione generica';
				this.videotags = this.generic_tags;
				this.tag_sel = this.videotags[0];
				this.count = 1;
				this.playlist = [];
				for(var i in this.videotags) {
					var videotag = this.videotags[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
				this.$refs.tagsModal.show();
			}
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1); 
		},
		selectPageTag(page) {
			this.tag_sel = this.videotags[page - 1]; 
			this.$video_tags.playlist.currentItem(page - 1);   
		},
		autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		deleteTag(tag) {
			if(confirm('Sei sicuro di voler eliminare il tag?')) {
				this.$http.delete(this.$store.state.apiEndPoint + '/tag/' + tag.id).then((response) => {
					if (response.ok)
					{
						if(this.tag_sel.id == tag.id) {
							this.tag_sel = null;
						}
						var index = this.videotags.indexOf(tag);	
						this.videotags.splice(index, 1);
						this.reload = true; 
						if(this.videotags.length == 0) {
							this.$refs.tagsModal.hide();
						}
					}
				}, (response) => {
		            // error callback
		        });
			}		
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);

				this.$video_tags.errors({
				    "errors": this.errors_player,
				});
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.$video_tags.dispose();
			this.$video_tags = null;
			this.tag_sel = null;
			if(this.reload) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/videotag/squadra/' + this.squadra.id).then((response) => {
					if (response.ok)
					{
						this.skills_tags = response.data.skills_tags;
						this.generic_tags = response.data.generic_tags;  
						this.reload = false; 
					}
				}, (response) => {
		            // error callback
		        });
			}
		},
		selectRiassunto(index) {
			var videotags = this.riassunti[index].videotags;
			this.playlist = [];
			this.titleRiassunto = 'Video riassunto di ' + this.riassunti[index].utente.nomeCompleto;
			for(var i in videotags) {
				var videotag = videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.riassuntoModal.show();
		},
		onShowRiassunto() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			this.$video.play();
		},
		onHiddenRiassunto() {
			this.$video.playlist([]);
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;	
		},
		formatDateHour (data) {
			var m = moment(data);
			var format = m.format('DD/MM/YYYY HH:mm'); 
			return format;
		},
		downloadReport(id) {
			window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/report/partita/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},  
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	}, 
}
</script>
