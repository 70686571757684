<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<b-card no-body class="border-0">
				<div class="tableauPlaceholder" ref="tableau"></div>
			</b-card>
		</div>

		<footerapp></footerapp>

	</section>
</template>
<script>
export default {

	data: function() {
		return {
			// https://eu-west-1a.online.tableau.com/#/site/asromabi/views/Load_v1_5_16976522106240/TeamIntLSeason
			url: "https://eu-west-1a.online.tableau.com/t/asromabi/views/Load_v1_5_16976522106240/TeamIntLSeason",
		//	url: "https://eu-west-1a.online.tableau.com/t/asromabi/views/Load/TeamIntLSeason",
		//	url: "https://eu-west-1a.online.tableau.com/#/site/asromabi/views/Load_16425321026940/TeamIntLSeason?:iid=2",
		//	url: "https://eu-west-1a.online.tableau.com/#/site/asromabi/views/Load_16425321026940/",
			options: {
				width: "100%",
			//	height: "100%",
			//	host_url: "https://eu-west-1a.online.tableau.com/",
			//	embed_code_version: 3,
			//	site_root: "/t/asromabi",
			//	name: "Load/TeamIntLSeason",
			//	tabs: "yes",
			//	toolbar: "yes",
			//	showAppBanner: false,
			}
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
	},

	mounted() {
		this.initViz();		
	},

	methods: {
		initViz: function () {
			let viz = new tableau.Viz(this.$refs.tableau, this.url, this.options);
		}
	},

}
</script>

<style>
.tableauPlaceholder {
	height:auto !important;
	padding-bottom:60%;
	overflow:hidden;
}

.tableauPlaceholder iframe {
	height: 100% !important;
	width: 100%;
	position:absolute;
}
</style>
