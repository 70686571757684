<template id="gcarriera">
	<div class="gcarriera">
		<table class="table table-striped">
			<thead>
				<tr>
					<th title="Stagione">Anno</th>
					<th title="Squadra">Squadra</th>
					<th title="Competizione">Comp.</th>
					<th title="Minuti">Min.</th>
					<th title="Presenze">Pre.</th>
					<th title="Panchina">Pan.</th>
					<th title="In">In</th>
					<th title="Out">Out</th>
					<th title="Goal">Goal</th>
					<th title="Yellow">G.</th>
					<th title="Red">R.</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in params.carriera" v-if="index < params.count_rows || (index >= params.count_rows && show_all)">
					<td style="font-size: 0.8rem;">{{ item.season ? item.season.name : 'N/D'}}</td>
					<td style="font-size: 0.8rem;">{{ item.team ? item.team.name : 'N/D'}}</td>
					<td style="font-size: 0.8rem;">{{ item.competition ?  item.competition.name : 'N/D'}}</td>
					<td>{{ item.minutesPlayed }}</td>
					<td>{{ item.appearances }}</td>
					<td>{{ item.substituteOnBench }}</td>
					<td>{{ item.substituteIn }}</td>
					<td>{{ item.substituteOut }}</td>
					<td>{{ item.goal }}</td>
					<td>{{ item.yellowCard }}</td>
					<td>{{ item.redCards }}</td>
				</tr>
			</tbody>
		</table>
    <b-button v-if="params.carriera.length > params.count_rows" variant="primary" @click="switchShow" size="sm" :title="(show_all ? 'Nascondi' : 'Mostra') + ' carriera completa'"><i class="fa fa-arrow-down" aria-hidden="true" v-if="!show_all"></i><i class="fa fa-arrow-up" aria-hidden="true" v-if="show_all"></i></b-button>
	</div>

</template>

<script>
export default {
props: {
    params: {
    	type: Object,
		required: false
	}
  },
  data: function() {
		return {
			show_all: false,
		}
	},
  components: {
    flag: require('../flag.vue').default,
  },
  methods: {
  	switchShow: function() {
  		this.show_all = !this.show_all;
  	}
  },
}
</script>
