<template>
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 1639.8 2399.6" style="enable-background:new 0 0 1639.8 2399.6;" xml:space="preserve">
<g>
    <rect transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1639.8213 2399.6147)" style="fill:#FFFFFF;" width="1639.8" height="2399.6"/>
    
        <rect x="-259.9" y="499.9" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2019.7183 379.8967)" style"fill:none;" width="2159.6" height="1399.8"/>
    
        <rect x="765.9" y="661.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -541.8679 2181.6895)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="553.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -433.8871 2073.7085)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="445.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -325.9063 1965.7278)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="337.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -217.9256 1857.7471)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="229.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -109.9448 1749.7664)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="122" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -1.964 1641.7855)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="14" transform="matrix(6.123234e-17 -1 1 6.123234e-17 106.0167 1533.8048)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="-94" transform="matrix(6.123234e-17 -1 1 6.123234e-17 213.9975 1425.8241)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="-202" transform="matrix(6.123234e-17 -1 1 6.123234e-17 321.9782 1317.8433)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="-310" transform="matrix(6.123234e-17 -1 1 6.123234e-17 429.959 1209.8625)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="-417.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 537.9398 1101.8817)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="-525.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 645.9205 993.901)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="1525.7" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -1405.7139 3045.5354)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="1417.7" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -1297.7332 2937.5547)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="1309.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -1189.7524 2829.574)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="1201.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -1081.7716 2721.5933)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="1093.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -973.7909 2613.6123)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="985.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -865.8101 2505.6316)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="877.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -757.8293 2397.6509)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    
        <rect x="765.9" y="769.8" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -649.8486 2289.6702)" style="fill:#FFFFFF;" width="108" height="1399.8"/>
    <g>
        <path style="fill:#000000;" d="M896.6,2316.9v-37.3h623.3V120H896V83.2H737.8V120H120v2159.6h618.3v37.3H896.6z M1000.1,127.7v98.7H633
            v-98.7H1000.1z M625.4,127.7v106.4h382.4V127.7h221.1V444H412V127.7H625.4z M404.4,127.7v324h262.8c37,45.3,91.5,71.2,150,71.2
            c58.5,0,113-26,150-71.2h269.4v-324h242.5c1.8,17.4,15.6,31.2,33.1,33V1196l-504.3,0.2c-0.5-24.1-5.4-47.6-14.7-69.7
            c-9.5-22.6-23.2-42.9-40.7-60.3c-17.4-17.4-37.7-31.1-60.3-40.6c-23.4-9.9-48.2-14.9-73.9-14.9c-25.6,0-50.5,5-73.9,14.9
            c-22.6,9.5-42.9,23.2-60.3,40.6c-17.4,17.4-31.1,37.7-40.7,60.3c-9.4,22.1-14.2,45.6-14.7,69.8l-501,0.2V160.7
            c17.4-1.8,31.2-15.6,33-33H404.4z M956.8,451.6c-35.4,40.4-85.7,63.6-139.7,63.6c-54,0-104.3-23.2-139.7-63.6H956.8z M830,1196.2
            c-1.7-4.9-6.2-8.5-11.7-8.5s-10,3.6-11.7,8.5l-170.1,0c2.2-98.5,82.8-178,181.8-178s179.6,79.4,181.9,177.9L830,1196.2z
             M806.4,1203.9c1.6,5.2,6.2,9,11.9,9s10.3-3.9,11.9-9l170-0.1c-1.9,98.8-82.7,178.6-181.9,178.6c-99.2,0-179.9-79.7-181.9-178.4
            L806.4,1203.9z M628.7,1204c0.4,24.3,5.3,47.9,14.7,70.2c9.6,22.6,23.3,42.9,40.7,60.3c17.4,17.4,37.7,31.1,60.3,40.7
            c23.4,9.9,48.3,14.9,73.9,14.9c25.6,0,50.5-5,73.9-14.9c22.6-9.5,42.9-23.2,60.3-40.7c17.4-17.4,31.1-37.7,40.7-60.3
            c9.4-22.3,14.3-46,14.7-70.3l504.3-0.2v1035.2c-17.5,1.8-31.2,15.6-33.1,33.1h-243.6v-323.5H966.6c-37-45.2-91.5-71.2-150-71.2
            c-58.5,0-113,26-150,71.2H403.3v323.5H160.7c-1.8-17.5-15.6-31.3-33-33.1V1204.2L628.7,1204z M677,1948.5
            c35.4-40.3,85.7-63.6,139.7-63.6c54,0,104.3,23.2,139.7,63.6H677z M410.9,1956.2h816.9v315.8h-219.4V2166H626v105.9H410.9V1956.2z
             M633.7,2173.7h367.1v98.2H633.7V2173.7z M1486.7,2271.9c1.8-13.2,12.2-23.7,25.4-25.4v25.4H1486.7z M1512.2,153
            c-13.2-1.8-23.6-12.2-25.4-25.4h25.4V153z M745.5,90.9h142.9V120H745.5V90.9z M153.1,127.7c-1.8,13.2-12.2,23.6-25.4,25.4v-25.4
            H153.1z M127.7,2246.5c13.2,1.8,23.6,12.2,25.4,25.4h-25.4V2246.5z M746,2309.2v-29.6h142.9v29.6H746z"/>
        <path style="fill:#000000;" d="M828.7,2063.7c0-6.4-5.2-11.5-11.5-11.5c-6.3,0-11.5,5.1-11.5,11.5s5.2,11.5,11.5,11.5
            C823.5,2075.2,828.7,2070,828.7,2063.7z"/>
        <path style="fill:#000000;" d="M828.7,336.4c0-6.3-5.2-11.5-11.5-11.5c-6.3,0-11.5,5.2-11.5,11.5c0,6.4,5.2,11.5,11.5,11.5
            C823.5,348,828.7,342.8,828.7,336.4z"/>
    </g>
</g>
</svg>
</template>

<script>
export default {
    data()
    {
        return {
        }
    },

    methods:
    {
    }
}
</script>
