<template>
	<div class="text-center font-weight-bold" style="min-width:100px">
		{{ CMJ_Concentric_Peak_Force_Right_Left }}
	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	created: function() {


	},
	methods: {
		
	},
	watch: {
		dataLayer: {
			deep: true,
			handler: function() {

			}
		},
		CMJ_Concentric_Peak_Force_Right_Left: {
			immediate: true,
			handler: function() {
				this.test[this.field] = this.CMJ_Concentric_Peak_Force_Right_Left;
			}
		},
	},
	computed: {
		prefix: function() {
			if(this.field == 'CMJ_Concentric_Peak_Force_Right_Left') {
				return '';
			}
			return this.field.replace('CMJ_Concentric_Peak_Force_Right_Left', '');

		},
		CMJ_Concentric_Peak_Force_Right_Left: function() {
			var value = null;
			if(!isNaN(this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Left']) && this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Left'] != null && this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Left'] != '' && !isNaN(this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Right']) && this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Right'] != null && this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Right'] != '') {
				value = (parseFloat(this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Left']) + parseFloat(this.test[this.prefix + 'CMJ_Concentric_Peak_Force_Right'])) / 2;
				value =  Math.round(value * 100) / 100;
			}
			return value;
		},		
	}
}

</script>