<template>
	<div>
		<b-card>

			<b-row>
				<b-col>
					<div class="d-flex">
						<span class="card-title">Injury History</span>
						<span v-if="!api_response.our_availability || ux.type == 3" style="margin-top: 20px; margin-left: 10px" class="small text-muted d-flex justify-content-center">Fonte Dati: <img src="https://noisefeed.com/wp-content/uploads/2020/11/L_Noisefeed-01-e1605589312903.png.webp" style="height: 15px; margin-left: 5px" /></span>
						<span v-if="api_response.our_availability && ux.type == 1" style="margin-top: 20px; margin-left: 10px" class="small text-muted d-flex justify-content-center">Arricchito con: <img src="https://noisefeed.com/wp-content/uploads/2020/11/L_Noisefeed-01-e1605589312903.png.webp" style="height: 15px; margin-left: 5px" /></span>
					</div>
				</b-col>
				<b-col v-if="api_response.our_availability" class="text-right p-1 px-4" :cols="3">
					<b-form-select v-model="ux.type">
						<b-form-select-option :value="1">ASRomaSystem + NoiseFeed</b-form-select-option>
						<b-form-select-option :value="2">ASRomaSystem</b-form-select-option>
						<b-form-select-option :value="3">NoiseFeed</b-form-select-option>
					</b-form-select>
				</b-col>
			</b-row>
			<div v-if="persona_id && api_response">

				<b-row>
					<b-col class="pr-3">
						<b-form-group label="Filtro">
							<b-form-select size="sm" v-model="ux.season">
								<b-form-select-option :value="false">Ultimi 12 Mesi</b-form-select-option>
								<b-form-select-option v-for="year in years" :value="year">{{ year }}</b-form-select-option>
								<b-form-select-option :value="-1">Tutti</b-form-select-option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col class="pl-3">

						<b-row>
							<b-col v-if="ux.season == false">
								<span class="text-muted">Minuti Giocati</span>
								<p>
									<span class="lead">{{ superAggregated.minutesPlayed }} su {{ superAggregated.possibleMinutes }}</span>
									({{ superAggregated.minutesPercentage }}%)
								</p>
							</b-col>
							<b-col v-if="ux.season == false">
								<span class="text-muted">Match Giocati</span>
								<p>
									<span class="lead">{{ superAggregated.matchesPlayed }} su {{ superAggregated.possibleMatches }}</span>
									({{ superAggregated.matchesPercentage }}%)
								</p>
							</b-col>
							<b-col>
								<span class="text-muted">Infortuni</span>
								<p>
									<span class="lead">{{ injuries_data.length }}</span>
								</p>
							</b-col>
							<b-col class="text-center" v-if="ux.season != false" cols="8" style="line-height: 1">Non sono disponibili aggregati<br />per la stagione selezionata.</b-col>
						</b-row>



					</b-col>
				</b-row>

				<b-nav tabs>
					<b-nav-item @click.prevent="ux.navigation_type = 'table'" :active="ux.navigation_type == 'table'">Vista Tabellare</b-nav-item>
					<b-nav-item @click.prevent="ux.navigation_type = 'video'" :active="ux.navigation_type == 'video'" :disabled="videos.length == 0">Naviga per Video</b-nav-item>
					<b-nav-item @click.prevent="ux.navigation_type = 'graph'" :active="ux.navigation_type == 'graph'">Grafici</b-nav-item>
				</b-nav>

				<div class="p-2 bg-white" v-if="injuries_data">


					<b-table-simple small striped hover v-if="ux.navigation_type == 'table'">
						<b-thead>
							<b-tr>
								<b-th :colspan="2">Data</b-th>
								<b-th>Tipologia</b-th>
								<b-th>Parte del Corpo</b-th>
								<b-th>Descrizione</b-th>
								<b-th>Durata Infortunio</b-th>
								<b-th>Partite Saltate</b-th>
								<b-th></b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="i in injuries_data" style="cursor: pointer" @click.prevent="openDetail(i)">
								<b-td>
									<span v-if="api_response.our_availability && !i.in"><strong class="text-muted">NF</strong></span>
									{{ i.date | date }}
								</b-td>
								<b-td v-html="i.game_context || '<em class=\'text-muted\'>Nessuna Partita</em>'"></b-td>
								<b-td>{{ i.injury_type }}</b-td>
								<!-- <b-td>{{ i.injuried_part }} <small class="text-muted">({{ i.side }})</small> ({{ i.injuried_part_our }})</b-td> -->
								<b-td>{{ i.injuried_part_our }}</b-td>
								<b-td>{{ i.description }} <small class="text-muted">({{ i.trauma }})</small></b-td>
								<b-td>{{ i.recovery_days }}</b-td>
								<b-td>{{ i.missed_matches }}</b-td>
								<b-td class="text-right">
									<div v-if="i.media.length">
										<div v-for="media in i.media">
											<b-button size="xs" @click.stop="openMedia(media)">Guarda Video</b-button>
										</div>
									</div>
									<span v-else class="small">Nessun Video</span>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<b-row v-if="ux.navigation_type == 'video'">
						<b-col>
							<div v-if="selected_media">

								<div v-for="media in selected_media.media">
									<video style="width: 100%" class="mt-2" muted controls>
										<source :src="media" type="video/mp4">
									</video>
								</div>

								<b-row class="d-flex align-items-center mt-3">
									<b-col v-if="selected_media.recovery_days">
										<div class="small text-muted">Giorni Recupero</div>
										<div>{{ selected_media.recovery_days }}</div>
									</b-col>
									<b-col v-else class="text-center">
										<div class="lead text-danger">ATTIVO</div>
									</b-col>
									<b-col>
										<div class="small text-muted">Data</div>
										<div>
											{{ selected_media.date | date }}
											<span class="small text-muted">({{ selected_media.date | ago }})</span>
										</div>
										<div v-if="selected_media.recurring == true" class="mt-2 d-flex align-items-center">
											<div style="width: 10px; height: 10px; border-radius: 100%; background-color: orange"></div>
											<div class="ml-1">Ricorrente</div>
										</div>
									</b-col>
									<b-col>
										<div class="small text-muted">Match Saltati</div>
										<div>{{ selected_media.missed_matches }}</div>
									</b-col>
								</b-row>

								<hr />

								<b-row>
									<b-col>
										<div class="small text-muted">Tipo</div>
										<div>{{ selected_media.injury_type.replace('|', ' | ') }}</div>
									</b-col>
									<b-col>
										<div class="small text-muted">Parte del corpo</div>
										<div>
											{{ selected_media.injuried_part }}
											({{ selected_media.side }})
										</div>
									</b-col>
									<b-col>
										<div class="small text-muted">Description</div>
										<div>{{ selected_media.description }}</div>
									</b-col>
									<b-col>
										<div class="small text-muted">Trauma</div>
										<div>{{ selected_media.trauma }}</div>
									</b-col>
								</b-row>

								<b-card v-if="selected_media.clinic || selected_media.doctor" class="mt-3">
									<template #header>
										<div>Intervento</div>
									</template>
									<b-row>
										<b-col>
											<div class="small text-muted">Clinica</div>
											<div>{{ selected_media.clinic }}</div>
										</b-col>
										<b-col>
											<div class="small text-muted">Dottore</div>
											<div>{{ selected_media.doctor }}</div>
										</b-col>
									</b-row>
								</b-card>

							</div>

						</b-col>
						<b-col :cols="3" style="max-height: 80vh; overflow-y: scroll">
							<b-list-group>
								<b-list-group-item :key="injury.date" :active="selected_media && selected_media.date == injury.date" v-for="injury in videos" button @click.prevent="pickInjury(injury)">
									<b-row class="small">
										<b-col>
											{{ injury.date | date }}
										</b-col>
										<b-col class="text-right">
											<span v-if="injury.recovery_days">Durata Infortunio: <strong>{{ injury.recovery_days }}</strong></span>
											<span v-else>Infortunio Attivo</span>
										</b-col>
									</b-row>
									<div class="mt-1">
										<strong>{{ injury.injury_type }}</strong> / 
										{{ injury.injuried_part }} ({{injury.side}}) / 
										{{ injury.description }}
									</div>
								</b-list-group-item>
							</b-list-group>
						</b-col>
					</b-row>

					<div v-if="ux.navigation_type == 'graph'">

						<div v-if="!ux.season" class="mb-2">

							<b-card>
								<template #header>Disponibilità Ultimi 12 Mesi</template>

								<b-table-simple responsive>
									<b-tr>
										<b-td v-for="m in graphAvailability" class="text-center">

											<div style="height: 70px; margin-left: 5px; width: 10px; background-color: #f4f4f4" class="mb-1 d-flex align-items-end">
												<div :style="'height: ' + (100*(m.minutesPlayed/m.duration)) + '%; width: 100%; background-color: ' + (m.injuredAtMatch ? '#ac4e47' : '#2196f3') + '; position: relative'">
													<div @click.prevent="openSubDetail(m)" v-b-tooltip.hover :title="moment(m.injury.date).format('DD/MM/YYYY') + ' - ' + m.injury.description" v-if="m.injuredAtMatch" class="d-flex justify-content-center" style="position: absolute; top: -5px; width: 20px; left:-5px; height: 20px; border-radius: 100%; background-color: red; border: 1px solid white">
														<div style="line-height: 0.6; font-size: 150%; font-weight: bold; color: white">+</div>
													</div>
												</div>
											</div>

											<div class="mb-1"><img :src="m.opponentLogo" width="20" height="20" /></div>
											<small v-if="m.minutesPlayed">{{m.minutesPlayed}}'</small>
											<small v-else>&times;</small>

										</b-td>
									</b-tr>
								</b-table-simple>

								<b-row v-if="!api_response.our_availability">
									<b-col class="text-center">
										<div class="lead">{{ api_response.availability.availability.minutesPercentage }}%</div>
										<div><strong>Minuti Giocati</strong></div>
										<div class="text-muted">{{ api_response.availability.availability.minutesPlayed }}/{{ api_response.availability.availability.possibleMinutes }}</div>
									</b-col>
									<b-col class="text-center">
										<div class="lead">{{ api_response.availability.availability.matchesPercentage }}%</div>
										<div><strong>Partite Giocate</strong></div>
										<div class="text-muted">{{ api_response.availability.availability.matchesPlayed }}/{{ api_response.availability.availability.possibleMatches }}</div>
									</b-col>
								</b-row>
								<b-row v-else>
									<b-col class="text-center">
										<div class="lead">{{ api_response.our_availability.availability.minutesPercentage }}%</div>
										<div><strong>Minuti Giocati</strong></div>
										<div class="text-muted">{{ api_response.our_availability.availability.minutesPlayed }}/{{ api_response.our_availability.availability.possibleMinutes }}</div>
									</b-col>
									<b-col class="text-center">
										<div class="lead">{{ api_response.our_availability.availability.matchesPercentage }}%</div>
										<div><strong>Partite Giocate</strong></div>
										<div class="text-muted">{{ api_response.our_availability.availability.matchesPlayed }}/{{ api_response.our_availability.availability.possibleMatches }}</div>
									</b-col>
								</b-row>

							</b-card>

						</div>

						<b-card class="my-2" v-if="ux.season == -1">
							<template #header>Infortuni in carriera: <strong>{{ injuries_data.length }}</strong></template>
							<grafico-progresso :ds="graphDataset.years" width="100%"></grafico-progresso>
						</b-card>

						<b-row>
							<b-col cols="8">
								
								<b-row>
									<b-col>
										<b-card>
											<template #header><div class="text-center">Tipo Lesione</div></template>
											<grafico-torta label="Tipo Lesione" :ds="graphDataset.tipologia" width="400" height="300"></grafico-torta>
										</b-card>
									</b-col>
									<b-col>
										<b-card>
											<template #header><div class="text-center">Diretto/Indiretto</div></template>
											<grafico-torta label="Tipo Lesione" :ds="graphDataset.diretto_indiretto" width="400" height="300"></grafico-torta>
										</b-card>
									</b-col>
								</b-row>

								<b-row class="mt-2">
									<b-col>
										<b-card>
											<template #header><div class="text-center">Gravità</div></template>
											<grafico-torta label="Tipo Lesione" :ds="graphDataset.gravita" width="400" height="300"></grafico-torta>
										</b-card>
									</b-col>
									<b-col>
										<b-card>
											<template #header><div class="text-center">Partita/Allenamento</div></template>
											<grafico-torta label="Tipo Lesione" :ds="graphDataset.luogo" width="400" height="300"></grafico-torta>
										</b-card>
									</b-col>
								</b-row>

							</b-col>
							<b-col cols="4">
								<b-card>
									<template #header><div class="text-center">Mappa degli Infortuni</div></template>
									<grafico-heatmap label="Tipo Lesione" :ds="graphDataset.mappa" width="400" height="700"></grafico-heatmap>
								</b-card>
							</b-col>
						</b-row>

						<!-- <pre>{{ graphDataset }}</pre> -->

					</div>

				</div>
				<div v-else>
					<div class="p-2 bg-white">Nessun infortunio in archivio.</div>
				</div>

			</div>
			<div v-else-if="api_response == null">Attendi caricamento delle informazioni…</div>
			<div v-else-if="api_response == false">
				Nessuna informazione sul giocatore.
				<b-button @click.prevent="showSearchModal" class="mt-2">Cerca Manualmente</b-button>
			</div>

			<div v-if="api_response">
				<b-alert class="mt-2" v-if="api_response.tag.meta.lastname.toLowerCase() !== api_response.persona.cognome.toLowerCase()" :show="true">
					<p class="lead">Attento, è possibile che le meta-informazioni di NoiseFeed non siano correttamente agganciate.</p>
					<div>
						Il giocatore su AS Roma System è: <strong>{{ api_response.persona.cognome + ' ' + api_response.persona.nome }}</strong>
					</div>
					<div>
						Il giocatore fornito da NoiseFeed è: <strong>{{ api_response.tag.meta.lastname + ' ' + api_response.tag.meta.firstname }}</strong>
					</div>

					<b-button class="mt-2" variant="outline-success" @click.prevent="showSearchModal">Clicca qui per cercare e verificare su NoiseFeed</b-button>
				</b-alert>
				<div v-else class="small text-muted px-2 py-3">Noisefeed Identifica questo giocatore come: <strong><a href="javascript:;" @click.prevent="showSearchModal" class="text-muted">{{ api_response.tag.name }}</a></strong></div>
				<!-- <pre>{{api_response.tag.meta}}</pre> -->
			</div>

		</b-card>

		<b-modal size="xl" :visible="ux.media !== null" @hide="ux.media = null">
			<template #modal-footer>
				<b-button @click.prevent="ux.media = null">Chiudi</b-button>
			</template>
			<video style="width: 100%" controls="true" muted="true">
				<source :src="ux.media" type="video/mp4">
			</video>
		</b-modal>

		<NoiseFeedSearch @refresh="fetchInjuries" v-if="api_response" :current="api_response.tag.tag"  :persona="api_response.persona" ref="search_modal"></NoiseFeedSearch>
		<NoiseFeedSearch @refresh="fetchInjuries" v-else :persona="persona" ref="search_modal"></NoiseFeedSearch>

		<NoiseFeedDetail v-if="injury_detail" :detail="injury_detail" @hide="injury_detail = null"></NoiseFeedDetail>

	</div>
</template>

<script>

var Auth = require('@/auth.js');
import moment from 'moment';
import videojs from 'video.js';
import _ from 'lodash';

export default {
	components: {
		NoiseFeedDetail: require('@/components/layout/noise_feed/injury_detail.vue').default,
		NoiseFeedSearch: require('@/components/layout/noise_feed/tag_search.vue').default,
		GraficoProgresso: require('@/components/layout/noise_feed/grafico-progresso.vue').default,
		GraficoTorta: require('@/components/layout/noise_feed/grafico-torta.vue').default,
		GraficoHeatmap: require('@/components/layout/noise_feed/grafico-heatmap.vue').default
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY');
		},
		ago: function(a) {
			return moment(a).fromNow();
		}
	},
	props: [ 'persona_id', 'persona' ],
	data: function() {
		return {
			selected_media: null,
			api_response: null,
			injury_detail: null,
			ux: {
				type: 1,
				navigation_type: 'table',
				media: null,
				season: false
			}
		}
	},
	computed: {
		superAggregated: function() {

			if(this.api_response.our_availability && this.ux.type != 3) {
				var b = _.cloneDeep(this.api_response.our_availability);
			} else {
				var b = _.cloneDeep(this.api_response.availability);
			}

			return {
				minutesPlayed : b.availability.minutesPlayed,
				possibleMinutes : b.availability.possibleMinutes,
				minutesPercentage : b.availability.minutesPercentage,
				matchesPlayed : b.availability.matchesPlayed,
				possibleMatches : b.availability.possibleMatches,
				matchesPercentage : b.availability.matchesPercentage,
			};

		},
		graphAvailability: function() {
			if(this.api_response.our_availability && this.ux.type != 3)
				return this.api_response.our_availability.matches;
			else
				return this.api_response.availability.matches;
		},
		graphDataset: function() {
			return {
				tipologia: _.mapValues(_.groupBy(this.injuries_data, 'injury_type'), function(a) { return a.length; }),
				diretto_indiretto: _.mapValues(_.groupBy(this.injuries_data, 'trauma'), function(a) { return a.length; }),
				luogo: _.mapValues(_.groupBy(this.injuries_data, 'where'), function(a) { return a.length; }),
				gravita: _.mapValues(_.groupBy(this.injuries_data, function(a) {

					if(a.recovery_days < 30) return 'Meno di 30GG';
					if(a.recovery_days < 60) return '30 - 60GG';
					if(a.recovery_days < 90) return '60 - 90GG';
					if(a.recovery_days > 90) return '+90GG';

				}), function(a) { return a.length; }),
				mappa: _.mapValues(_.groupBy(this.injuries_data, 'injuried_part_our'), function(a) { return _.sum(_.map(a, 'recovery_days')); }),
				years: _.mapValues(_.groupBy(this.injuries_data, function(a) {
					return moment(a.date).format('YYYY');
				}), function(e) {
					return { amount: e.length, days: _.sum(_.map(e, 'recovery_days')) }
				})
			}
		},
		videos: function() {

			if(this.ux.season == false) {
				var base = _.map(_.map(this.api_response.availability.injuries, 'date'), function(i) {
					return _.find(this.api_response.injuries, { date: i });
				}.bind(this));
			} else if(this.ux.season == -1) { 
				var base = this.api_response.injuries;
			} else {
				var base = _.orderBy(_.filter(this.api_response.injuries, function(a) {
					return moment(a.date).format('YYYY') === this.ux.season;
				}.bind(this)), function(a) {
					return -1 * moment(a.date).format('X');
				});
			}

			console.log(base, base.length);

			var a = _.filter(base, function(a) {
			// var a = _.filter(this.api_response.injuries, function(a) {
				return a.media.length > 0;
			});

			return a;

		},
		years: function() {
			return _.uniq(_.map(_.map(this.api_response.injuries, 'date'), function(i) {
				return moment(i).format('YYYY');
			}));
		},
		injuries_data: function() {

			if(!this.api_response) return null;

			if(this.ux.season == false) {
				var base = _.filter(this.api_response.injuries, function(a) {
					return moment(a.date).format('X') >= moment().add(-1, 'years').format('X');
				})
				// var base = _.map(_.map(this.api_response.availability.injuries, 'date'), function(i) {
				// 	return _.find(this.api_response.injuries, { date: i });
				// }.bind(this));
			} else if(this.ux.season == -1) { 
				var base = this.api_response.injuries;
			} else {
				var base = _.orderBy(_.filter(this.api_response.injuries, function(a) {
					return moment(a.date).format('YYYY') === this.ux.season;
				}.bind(this)), function(a) {
					return -1 * moment(a.date).format('X');
				});
			}

			_.each(base, function(p) {
				p.news = _.filter(this.api_response.news, function(i) {

					var articleDate = moment(i.date).format('X');
					var startInjury = moment(p.date).format('X');
					var endInjury = moment(p.date).add(p.recovery_days, 'days').format('X');

					return articleDate >= startInjury && articleDate <= endInjury;

					console.log(articleDate);
					console.log(startInjury);
					console.log(endInjury);

					return true;
				});
				p.match = _.find(this.api_response.availability.matches, function(g) {

					return g.injury && g.injury.date == p.date;

				});
			}.bind(this));

			console.log(base);

			// return base;

 			if(this.ux.type == 1) {
				base = _.map(base, function(a) {
					if(a.internal) {
						console.log(a, a.internal);
						_.each(a.internal.copy, function(c, b) {
							a[b] = c;
						});
					}
					return a;
				});
 			} else if(this.ux.type == 2) {
 				base = _.filter(_.map(base, function(a) {
					if(a.internal) {
						_.each(a.internal.copy, function(c, b) {
							a[b] = c;
						});
						return a;
					}
					return false;
				}));
 			} else if(this.ux.type == 3) {
 				base = _.filter(_.map(base, function(a) {
 					console.log(a.onlyA);
					if(a.onlyA && a.onlyA == true) return false;
					return a;
				}));
 			}

			return _.orderBy(base, function(e) {
				return moment(e.date).format('X');
			});

			return base;

		}
	},
	watch: {
		'ux.season': function() {
			if(this.ux.navigation_type == 'video') {
				console.log('B');
				this.pickInjury(this.videos[0]);
				//this.selected_media = _.cloneDeep();
			}
		},
		'ux.navigation_type': function() {
			if(this.ux.navigation_type == 'video') {
				this.selected_media = _.cloneDeep(_.first(this.videos));
			}
		},
		persona_id: {
			immediate: true,
			handler: function() {
				if(this.persona_id)
					this.fetchInjuries();
			}
		}
	},
	methods: {
		showSearchModal: function() {
			this.$refs.search_modal.show();
		},
		openSubDetail: function(j) {
			this.openDetail(_.find(this.injuries_data, { date: j.date }));
		},
		moment: function(a) {
			return moment(a);
		},
		pickInjury: function(injury) {
			this.selected_media = null;
			// this.ux.season = false;
			_.delay(function() {
				this.selected_media = injury;
			}.bind(this), 50);
		},
		openDetail: function(detail) {
			this.injury_detail = detail;
			// console.log(detail);
		},
		openMedia: function(url) {
			this.ux.media = url;
		},
		fetchInjuries: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/noisefeed/of/'+this.persona_id+'/scouting-manifest').then(function(data) {
				this.api_response = data.data;
			}, function(e) {
				console.log('ERRRRRRROR', e);
				this.api_response = false;
			});
		}
	}
}

</script>