<template>
	<div>
		<b-form-rating :disabled="disabled" v-model="currentValue" :stars="stars" variant="warning" size="xs" show-clear></b-form-rating>
	</div>
</template>

<script>

import _ from 'lodash';

export default {
	components: {
		FormRating: require('@/components/layout/form-rating.vue').default,
	},
	props: {
		min: { default: 0 },
		max: { default: 1 },
		stars: { default: 5 },
		value: { default: 0 },
		disabled: { default: false }
	},
	created: function() {
		this.setFromMapped(this.value);
	},
	methods: {
		setFromMapped: function(v) {
			var range = this.max - this.min;
			var singleValue = range/this.stars;
			this.currentValue = v/singleValue;
		}
	},
	watch: {
		valueRemapped: {
			handler: function() {
				this.$emit('update', this.valueRemapped);
			}
		}
	},
	computed: {
		valueRemapped: function() {

			var range = this.max - this.min;
			var singleValue = range/this.stars;

			return _.round(this.currentValue * singleValue, 1);

		}
	},
	data: function(){
		return {
			currentValue: 0
		}
	}
}

</script>