<template id="esercizielenco">
	<div class="esercizielenco">

		<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 90vh; overflow-y: scroll;" class="row">
			<div class="col-md-4 col-lg-4 col-xl-3 mb-3" style="min-height:250px;">
				<router-link :to="{ path: '/football/esercizi/new'}" class="w-100 h-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;">
					<div class="text-center">
						<p class="mb-0"><i class="fa fa-plus-circle text-muted fa-5x"></i></p>
						<strong class="text-uppercase text-muted">Nuovo esercizio</strong>
					</div>
				</router-link>
			</div>
			<div class="col-md-4 col-lg-4 col-xl-3 mb-3" v-for="(ex, index) in exercises.data" :key="ex.id">
				<esercizicard :ex="ex" :skills="skills_standard" :skills_portiere="skills_portiere"></esercizicard>
			</div>
		</v-infinite-scroll>
	</div>
</template>

<script>

import axios from 'axios';
import { mapState } from 'vuex';
import InfiniteScroll from 'v-infinite-scroll';

export default {

	props: {
        params: {
            type: Object,
            required: false
        }
    },

	methods: {
		nextPage() {
			if(this.page < this.exercises.last_page) {
				this.$store.commit('editor/setPage', this.page + 1);
				this.$store.dispatch('editor/filter', this.page + 1);
			}
		},
	},

	computed:{
		skills_standard() {
            return this.params.skills.filter(item => [1, 30, 61].includes(item.id));
        },

        skills_portiere() {
            return this.params.skills.filter(item => [86, 99, 124, 61].includes(item.id));
        },
		... mapState('editor', ['exercises', 'page']),
	},		

	components: {
		esercizicard: require('./esercizicard.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},

	mounted() {
		this.$store.commit('editor/setPage', 1);
		this.$store.dispatch('editor/filter');
	},

}
</script>
