import Vue from 'vue';

export default {
    setEsercizi(state, esercizi) {
        if(state.page == 1) {
            state.esercizi = esercizi;
        } else {
            state.esercizi.data = state.esercizi.data.concat(esercizi.data);
        }
    },
    setObjIds(state, objective_ids) {
        state.objective_ids = objective_ids;
    },
    setTitle(state, title) {
        state.title = title;
    },
    setPage(state, page) {
        state.page = page;
    },
    setDrillId(state, drill_id) {
        state.drill_id = drill_id;
    },
    setAcademy(state, academy) {
        state.academy = academy;
    }, 
    setFootballTeamId(state, team_id) {
        state.footballTeamId = team_id;
    },
    setAcademyCategoryId(state, cat_id) {
        state.academyCategoryId = cat_id;
    }, 
    setLoadingEsercizi(state, value) {
        state.loadingEsercizi = value;
    },  
};
