<template id="dashboard">
	<b-card nobody>	
		<b-row>
			<b-col cols="4">
				<b-form-group label="Tipologia maglia">
					<b-form-select v-model="divisa.type">
						<b-form-select-option value="verticale">Verticale</b-form-select-option>
						<b-form-select-option value="orizzontale">Orizzontale</b-form-select-option>
						<b-form-select-option value="diagonale">Diagonale</b-form-select-option>
						<b-form-select-option value="tinta">Tinta</b-form-select-option>
					</b-form-select>
				</b-form-group>
				<b-form-group label="Colore primario">
					<b-form-input v-model="divisa.primario" type="color"/>
				</b-form-group>
				<b-form-group label="Colore secondario">
					<b-form-input v-model="divisa.secondario" type="color"/>		
				</b-form-group>
				<!--b-button size="sm" @click="downloadSVG" variant="outline-secondary">Download</b-button-->
				<b-button size="sm" @click="selectDivisa" variant="outline-primary">Seleziona</b-button>
			</b-col>
			<b-col cols="1">
			</b-col>
			<b-col cols="6">
				<div style="width: 200px;">
					<divisa
					ref="dvs"
					:type="divisa.type"
					:primario="divisa.primario"
					:secondario="divisa.secondario"
					></divisa>
				</div>
			</b-col>	
		</b-row>
	</b-card>

</template>

<script>

export default {

	components: {
		divisa: require('@/components/layout/divise/divisa.vue').default,
	},

	data: function() {
		return {
			divisa: {
				type:  'verticale',
				primario:  '#a80532',
				secondario: '#FFEB3B',
			},
		}
	},
	created() {
	},
	mounted() {

	},
	methods: {

		selectDivisa() {

			var svgContent = document.getElementById("divisa" + this.divisa.type).outerHTML;
			var style = this.$refs.dvs.provideCss();
			svgContent = svgContent.replace('<defs>', '<defs><style>' + style.join("\n") + '</style>');

			var blob = new Blob([svgContent], {
				type: "image/svg+xml"
			});

			this.$emit('selectDivisa', blob);

		},


		downloadSVG() {
			var svgContent = document.getElementById("divisa" + this.divisa.type).outerHTML;
			var style = this.$refs.dvs.provideCss();
			svgContent = svgContent.replace('<defs>', '<defs><style>' + style.join("\n") + '</style>');
			var blob = new Blob([svgContent], {
				type: "image/svg+xml"
			});
			let url = window.URL.createObjectURL(blob);
			this.image = url;
			const link = document.createElement('a');	
			link.href = url;
			link.setAttribute('download', "divisa_" + this.divisa.type + ".svg");
			document.body.appendChild(link);
			link.click();
		}

	},
	filters: {
	},
	watch: { 
	},
	computed: {
	}
}
</script>
