<template>
	<div>
		<b-row>
			<b-col>
				<b-card :key="random">
					<E-Chart style="width: 100%; height: 800px" :option="graphOption"></E-Chart>
				</b-card>
			</b-col>
			<b-col cols="2" class="pl-3">
				<b-form-group label="Visualizza">
					<b-form-radio-group size="sm" v-model="show"><b-form-radio value="media">Medie</b-form-radio><b-form-radio value="totale">Totali</b-form-radio></b-form-radio-group>
				</b-form-group>
				<b-form-group label="Livello di dettaglio">
					<b-form-radio-group size="sm" v-model="level"><b-form-radio value="team">Team</b-form-radio><b-form-radio value="player" :disabled="filters.players.length == 0">Giocatore</b-form-radio></b-form-radio-group>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>

function isValidNumber(a) {
	if(!_.isNumber(a) || _.isNaN(a) || a == 0 || !_.isFinite(a)) return false;
	return true;
}

function buildVelocity(dati, show, level) {
	var vel_max_perc_0_60 = 0;
	var vel_max_perc_60_70 = 0;
	var vel_max_perc_70_80 = 0;
	var vel_max_perc_80_90 = 0;
	var vel_max_perc_90_100 = 0;
	var vel_max_perc__100 = 0;
	vel_max_perc_0_60 =_.sum([
		_.sumBy(dati, 'catapult_velocity2_band1_total_distance'),
		_.sumBy(dati, 'catapult_velocity2_band2_total_distance'),
		_.sumBy(dati, 'catapult_velocity2_band3_total_distance'),
		]);
	vel_max_perc_60_70 = _.sumBy(dati, 'catapult_velocity2_band4_total_distance');
	vel_max_perc_70_80 = _.sumBy(dati, 'catapult_velocity2_band5_total_distance');
	vel_max_perc_80_90 = _.sumBy(dati, 'catapult_velocity2_band6_total_distance');
	vel_max_perc_90_100 = _.sumBy(dati, 'catapult_velocity2_band7_total_distance');
	vel_max_perc__100 = _.sumBy(dati, 'catapult_velocity2_band8_total_distance');
	return {
		vel_max_perc_0_60: isValidNumber(vel_max_perc_0_60) ? (show == 'totale' || level == 'player' ? _.round(vel_max_perc_0_60) : vel_max_perc_0_60) : 0,
		vel_max_perc_60_70: isValidNumber(vel_max_perc_60_70) ? (show == 'totale' || level == 'player' ? _.round(vel_max_perc_60_70) : vel_max_perc_60_70) : 0,
		vel_max_perc_70_80: isValidNumber(vel_max_perc_70_80) ? (show == 'totale' || level == 'player' ? _.round(vel_max_perc_70_80) : vel_max_perc_70_80) : 0,
		vel_max_perc_80_90: isValidNumber(vel_max_perc_80_90) ? (show == 'totale' || level == 'player' ? _.round(vel_max_perc_80_90) : vel_max_perc_80_90) : 0,
		vel_max_perc_90_100: isValidNumber(vel_max_perc_90_100) ? (show == 'totale' || level == 'player' ? _.round(vel_max_perc_90_100) : vel_max_perc_90_100) : 0,
		vel_max_perc__100: isValidNumber(vel_max_perc__100) ? (show == 'totale' || level == 'player' ? _.round(vel_max_perc__100) : vel_max_perc__100) : 0,
	}
}

import eChart from '@/components/layout/E-Chart/Base.vue';
export default {
	props: [ 'dataset', 'filters', 'results' ],
	components: {
		'E-Chart': eChart,
	},
	data: function() {
		return {
			kpi_keys: [
				{key: 'vel_max_perc_0_60', label: '0-60% Max Vel' },
				{key: 'vel_max_perc_60_70', label: '60-70% Max Vel' },
				{key: 'vel_max_perc_70_80', label: '70-80% Max Vel' },
				{key: 'vel_max_perc_80_90', label: '80-90% Max Vel' },
				{key: 'vel_max_perc_90_100', label: '90-100% Max Vel' },
				{key: 'vel_max_perc__100', label: '>100% Max Vel' },
				],
			show: 'totale',
			level: 'team',
			random: Math.random(),
		}
	},
	watch: {
		'filters.players': function() {
			if(this.filters.players.length == 0) {
				this.level = 'team';
			}
		},
		level: function() {
			this.random = Math.random();
		}, 
		dataset: function() {
			this.random = Math.random();
		},
		show: function() {
			this.random = Math.random();
		},
	},
	computed: {
		theDataset: function() {
			var dataset = this.dataset;
			var grouped = _.groupBy(dataset, 'date');
			if(this.show == 'media') {
				var data = _.mapValues(grouped, function(dati, date) {
					var grouped_by_person = _.groupBy(dati, 'persona_id');
					var data_person = _.map(grouped_by_person, function(dati_persona) {
						return buildVelocity(dati_persona, this.show, this.level);
					}.bind(this));
					var medie = {
						vel_max_perc_0_60: _.size(data_person.filter(item => item['vel_max_perc_0_60'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'vel_max_perc_0_60') / _.size(data_person.filter(item => item['vel_max_perc_0_60'] != 0)), 0) : 0,
						vel_max_perc_60_70: _.size(data_person.filter(item => item['vel_max_perc_60_70'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'vel_max_perc_60_70') / _.size(data_person.filter(item => item['vel_max_perc_60_70'] != 0)), 0) : 0,
						vel_max_perc_70_80: _.size(data_person.filter(item => item['vel_max_perc_70_80'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'vel_max_perc_70_80') / _.size(data_person.filter(item => item['vel_max_perc_70_80'] != 0)), 0) : 0,
						vel_max_perc_80_90: _.size(data_person.filter(item => item['vel_max_perc_80_90'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'vel_max_perc_80_90') / _.size(data_person.filter(item => item['vel_max_perc_80_90'] != 0)), 0) : 0,
						vel_max_perc_90_100: _.size(data_person.filter(item => item['vel_max_perc_90_100'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'vel_max_perc_90_100') / _.size(data_person.filter(item => item['vel_max_perc_90_100'] != 0)), 0) : 0,
						vel_max_perc__100: _.size(data_person.filter(item => item['vel_max_perc__100'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'vel_max_perc__100') / _.size(data_person.filter(item => item['vel_max_perc__100'] != 0)), 0) : 0,
					}
					return medie;
				}.bind(this));
				return data;
			} else {
				var data = _.mapValues(grouped, function(dati, date) {
					return buildVelocity(dati, this.show, this.level);
				}.bind(this));
				return data;
			}
		},
		theDatasetByPerson: function() {
			var dataset = this.dataset;
			var grouped_by_person = _.groupBy(dataset, 'persona_id');
			var dataset_persona = _.mapValues(grouped_by_person, function(a) {
				return _.groupBy(a, 'date');
			});
			return _.mapValues(dataset_persona, function(a) {
				return _.mapValues(a, function(b) {
					return buildVelocity(b, this.show, this.level);
				}.bind(this));
			}.bind(this));
		},
		days: function () {
			var days = [];
			if(this.theDataset) {
				days = _.keys(this.theDataset);
			}
			return _.sortBy(days);
		},
		graphOption: function() {
			var series = [];
			if(this.level == 'team') {
				_.forEach(this.kpi_keys, function (kpi) {
					var data = [];
					_.forEach(this.days, function (day) {
						var find = this.theDataset[day];
						if(find) {
							data.push(find[kpi.key]);
						} else {
							data.push(0);
						}
					}.bind(this));
					var serie = {
						data: data,
						type: 'bar',
						stack: 'Total',
						name: kpi.label,
						label: {
							show: true
						}
					};
					series.push(serie);

				}.bind(this));
			} else {
				_.forEach(this.theDatasetByPerson, function(dsp, person_id) {
					var index = 0;
					_.forEach(this.kpi_keys, function (kpi) {
						var data = [];
						var points = [];
						_.forEach(this.days, function (day) {
							var find = dsp[day];
							if(find) {
								data.push(find[kpi.key]);
							} else {
								data.push(0);
							}
							if(index == 0) {
								points.push({ name: 'Giocatore', value: this.getPerson(person_id).cognome, xAxis: day, yAxis: 0 });
							}
						}.bind(this));
						var serie = {
							id: person_id + '_' + kpi.key,
							data: data,
							type: 'bar',
							stack: 'Total' + person_id,
							name: kpi.label,
							label: {
								show: true
							},
						};
						if(index == 0) {
							var serie_points = {
								id: 'pt_' + person_id + '_' + kpi.key,
								data: _.map(data, function() { return null; }), 
								type: 'bar',
								stack: 'Total' + person_id,
								name: 'points',
								color: 'transparent'
							};
							serie_points.markPoint = {
								symbol: 'rect',
								symbolSize: 0,
								silent: true,
								data: points,
								label: {
									rotate: 45,
									color: '#000000',
									position: 'bottom',
									align: 'right'
								}
							}
							series.push(serie_points);
						}
						series.push(serie);

						index++;
					}.bind(this));

				}.bind(this));

			}



			var options =  {
				grid: {
					top: '10%',
					left: 60,
					right: '10%',
					bottom: 100
				},
				xAxis: {
					type: 'category',
					position: 'top',
					data: this.days,
					splitArea: {
						show: true
					}
				},
				yAxis: {
					type: 'value'
				},
				series: series,
				legend: {
					data: this.kpi_keys.map(function(item) { return item.label })
				},
			};

			if(this.level == 'team') {
				options.tooltip = {
					trigger: 'axis'
				}
			} else {
				options.tooltip = {
					trigger: 'item',
					formatter: function (params) {
						return `${this.getPerson(params.seriesId.split('_')[0]).cognome}<br>${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span>`;
					}.bind(this)
				}
			}

			return options;
			
		}
	},
	methods: {
		getPerson(person_id) {
			return this.results.raw_persone.find(item => item.id == person_id);
		}
	}
}

</script>