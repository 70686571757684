<template>
	<div>

		<b-row>
			<b-col>
				<label>Tipo di farmaco</label>
				<b-form-select :disabled="disabled" v-model="fase.entity.farmaco_glo_id">
					<option :value="null">seleziona</option>
					<option :value="opt.id" v-for="opt in theGlossario['Farmaco']" :key="opt.id">{{opt.nome}}</option>
				</b-form-select>
			</b-col>
			<b-col>
				<label>Dosaggio somministrato</label>
				<b-form-input :disabled="disabled" v-model="fase.entity.nome" type="text"></b-form-input>
			</b-col>
		</b-row>

		<label>Nome</label>
		<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>

		<!--
		<label>Note della Fase</label>
		<b-textarea :disabled="disabled" v-model="fase.entity.nota" rows="4"></b-textarea>
		-->

	</div>
</template>

<script>
import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [faseMixin],
	props: [ 'sessione', 'glossario', 'fase', 'fisioterapia_glo_options', 'medical_regions', 'medical_regions_map', 'disabled' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		updateRegionsSelected: function(content, item) {

			if(!this.disabled)
				item.entity.medical_regions = content;

		},
		createItem: function() {

		}
	},
	components: {
		treedbody: require('@/components/layout/3dbody.vue').default,
	},
}
</script>