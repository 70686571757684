<template>
	<div>
		<b-card no-body>
			<div class="text-center items-center justify-content-center d-flex text-muted">
				<small><strong>{{ fase.order + 1 }}</strong> - Presa Dati <strong>{{fase.entity.entita.replace('Fase_Dato_', '')}}</strong></small>
			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	mixins: [
	],
	props: [ 'fase' ],
	computed: {
		
	},
	filters: {
	}
}	

</script>