<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		<b-container fluid>
			<div class="card">
				<div class="card-body">
					<div class="content">
						<div class="card border-0 rounded-0">
							<div class="card-body">
								<div class="card-title">
									Variabili di Squadra
									<small v-if="variabiliSquadra">• {{variabiliSquadra.name}}</small>
								</div>
								<div class="content">


									<b-row>
										<b-col cols="2">
											<b-list-group>
												<b-list-group-item :active="variabiliSquadra && variabiliSquadra.id == season.id" v-for="season in stagioniInfo" class="cursor-pointer" @click.prevent="variabiliSquadra = season">{{ season.name }}</b-list-group-item>
												<b-list-group-item class="cursor-pointer" @click.prevent="newSeason">+ Nuova Stagione</b-list-group-item>
											</b-list-group>
										</b-col>
										<b-col v-if="variabiliSquadra">

											<b-form @submit.prevent="updateVars">

												<b-form-group label="Nome stagione">
													<b-input v-model="variabiliSquadra.name"></b-input>
												</b-form-group>

												<b-row>
													<b-col>
														<b-form-group label="Inizio">
															<b-input type="date" v-model="variabiliSquadra.inizio"></b-input>
														</b-form-group>
													</b-col>
													<b-col>
														<b-form-group label="Fine">
															<b-input type="date" v-model="variabiliSquadra.fine"></b-input>
														</b-form-group>
													</b-col>
												</b-row>

												<b-form-group v-if="stagioniAsRoma" label="Stagione AS Roma System">
													<b-form-select v-model="variabiliSquadra.stagione_id">
														<b-form-select-option disabled :value="false">Seleziona</b-form-select-option>
														<b-form-select-option v-for="s in stagioniAsRoma" :value="s.id">{{ s.stagione }} - ({{s.start_at | date }} : {{s.end_at | date }})</b-form-select-option>
													</b-form-select>
												</b-form-group>

												<label style="display: block">
													<input type="checkbox" v-model="variabiliSquadra.vittoria_serie_a" :value-true="1" :value-false="0" />
													Vittoria Serie A
												</label>

												<label style="display: block">
													<input type="checkbox" v-model="variabiliSquadra.vittoria_coppa_italia" :value-true="1" :value-false="0" />
													Vittoria Coppa Italia
												</label>

												<label style="display: block">
													<input type="checkbox" v-model="variabiliSquadra.vittoria_uel" :value-true="1" :value-false="0" />
													Vittoria UEL
												</label>

												<hr />


												<b-row>
													<b-col>
														
														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.vittoria_uecl" :value-true="1" :value-false="0" />
															Vittoria UECL
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_uecl_group_stage" :value-true="1" :value-false="0" />
															Qualificazione UECL Group Stage
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_uecl_round_16" :value-true="1" :value-false="0" />
															Qualificazione UECL Round 16
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_uecl_quarter_final" :value-true="1" :value-false="0" />
															Qualificazione UECL Quarter Final
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_uecl_semifinal" :value-true="1" :value-false="0" />
															Qualificazione UECL Semifinal
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_uecl_final" :value-true="1" :value-false="0" />
															Qualificazione UECL Final
														</label>

													</b-col>
													<b-col>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.vittoria_ucl" :value-true="1" :value-false="0" />
															Vittoria UCL
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_ucl_group_stage" :value-true="1" :value-false="0" />
															Qualificazione UCL Group Stage
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_ucl_round_16" :value-true="1" :value-false="0" />
															Qualificazione UCL Round 16
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_ucl_quarter_final" :value-true="1" :value-false="0" />
															Qualificazione UCL Quarter Final
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_ucl_semifinal" :value-true="1" :value-false="0" />
															Qualificazione UCL Semifinal
														</label>

														<label style="display: block">
															<input type="checkbox" v-model="variabiliSquadra.qualificazione_ucl_final" :value-true="1" :value-false="0" />
															Qualificazione UCL Final
														</label>
														
													</b-col>
												</b-row>


												<b-btn-group>
													<b-button class="mt-3" type="submit" variant="outline-success">Salva variabili</b-button>
													<b-button v-if="variabiliSquadra.id" class="mt-3" @click.prevent="deleteVariabili" variant="outline-danger">Cancella variabili</b-button>
												</b-btn-group>
											</b-form>
											
										</b-col>
									</b-row>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-container>
	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	data: function(){
		return {
			stagioniAsRoma: false,
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			variabiliSquadra: null,
			stagioneInfo: false,
			stagioniInfo: false
		}
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY')
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},
	watch: {
		rosaTeamId() {
			this.getVariabili();
		},
		seasonId() {
			//this.getVariabili();
		},
	},
	created: function() {
		// this.getVariabili();
		this.getStagioni();
		this.getStagioniASR();
	},
	methods: {
		newSeason: function() {
			this.variabiliSquadra = {
				name: '',
				inizio: '',
				fine: '',
				squadra_id: this.rosaTeamId,
				stagione_id: false,
				vittoria_serie_a: 0,
				vittoria_coppa_italia: 0,
				vittoria_uel: 0,
				vittoria_ucl: 0,
				qualificazione_ucl_group_stage: 0,
				qualificazione_ucl_round_16: 0,
				qualificazione_ucl_quarter_final: 0,
				qualificazione_ucl_semifinal: 0,
				qualificazione_ucl_final: 0
			};
		},
		getStagioneInfo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagione/' + this.seasonId).then(function(data) {
				this.stagioneInfo = data.data;
			});
		},
		getStagioni: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/stagioni').then(function(data) {
				this.stagioniInfo = data.data;
				if(this.variabiliSquadra == null) {

					this.variabiliSquadra = _.findLast(this.stagioniInfo, function(a) {
						return a.stagione_id != null;
					});
				}
			});
		},
		getStagioniASR: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagioni').then(function(data) {
				this.stagioniAsRoma = data.data;
			});
		},
		updateVars: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/contratto/variabili-squadra-update', { vars: this.variabiliSquadra }).then(function(data) {
				this.getStagioni();
				this.variabiliSquadra = data.data;
			});

		},
		deleteVariabili: function() {
			if(!window.confirm("Confermi la rimozione della stagione?")) return false;
			this.$http.post(this.$store.state.apiEndPoint + '/contratto/delete-variabili-squadra/' + this.variabiliSquadra.id).then(function() {
				this.variabiliSquadra = null;
				this.getStagioni();
			});
		},
		getVariabili: function() {
			this.getStagioneInfo(this.seasonId);
			this.variabiliSquadra = false;
			this.$http.get(this.$store.state.apiEndPoint + '/contratto/variabili-squadra/' + this.rosaTeamId + '/' + this.seasonId).then((response) => {
				if (response.ok) {
					if(response.data.id)
						this.variabiliSquadra = response.data;
					else {
						this.newSeason();
					}
				}
			});
		},
	}
}
</script>