import axios from 'axios';

export default {
    filter({ state, commit, rootState }, params) {        
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        return axios.post(`/academyita/esercizi?page=${page}&cat_id=${rootState.academyCatId}&tab=esercizi&academy=${state.academy}`, {
            objective_ids: state.objective_ids,
            title: state.title,
        })
        .then(function (response) {
            commit('setAcademyEsercizi', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_bozze({ state, commit, rootState }, params) {
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/academyita/esercizi?page=${page}&cat_id=${rootState.academyCatId}&tab=bozze&academy=${state.academy}`, {
            objective_ids: state.objective_ids,
            title: state.title,
        })
        .then(function (response) {
            commit('setAcademyEserciziBozze', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_archivio({ state, commit, rootState }, params) {
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/academyita/esercizi?page=${page}&cat_id=${rootState.academyCatId}&tab=archivio&academy=${state.academy}`, {
            objective_ids: state.objective_ids,
            title: state.title,
        })
        .then(function (response) {
            commit('setAcademyEserciziArchivio', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_da_validare({ state, commit, rootState }, params) {
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/academyita/esercizi?page=${page}&cat_id=${rootState.academyCatId}&tab=da_validare&academy=${state.academy}`, {
            objective_ids: state.objective_ids,
            title: state.title,
        })
        .then(function (response) {
            commit('setAcademyEserciziDaValidare', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },
};
