<template>
	<section class="calendar">
		<reportsnew v-if="reportNew" />
		<reports v-else />
	</section>
</template>

<script>
import { mapState } from 'vuex';
export default {

	data: function() {
		return {
		}
	},

	computed: {
		...mapState(['reportNew']),
	},

	components: {
		reports: require('@/components/pages/reports/SportsDirection.vue').default,
		reportsnew: require('@/components/pages/reports/WidgetsDashboard.vue').default,
	},
}
</script>
