<template>
	<div v-if="team">
		<h5>Modifica Squadra</h5>
		<b-form @submit.prevent="updateTeam">
			<team-form :team="team"></team-form>
			<hr />
			<b-button type="submit" variant="outline-success">Modifica Squadra</b-button>
		</b-form>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: [ 'competition' ],
	components: {
		TeamForm: require('@/components/redazione/components/teams/form.vue').default
	},
	watch: {
		'$route.params.team_id': {
			immediate: true,
			handler: function() {
				this.fetchTeam();
			}
		}
	},
	data: function() {
		return {
			team: null
		}
	},
	methods: {
		fetchTeam: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/team/' + this.$route.params.team_id).then(function(data) {
				this.team = data.data;
			});
		},
		updateTeam: function() {

			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/team/' + this.team.id, { team: this.team }).then(function(data) {
				this.$emit('refresh-stage');
				this.$router.push({ name: 'CompetitionStage', params: { competition_id: this.$route.params.competition_id, stage_id: this.$route.params.stage_id } });
			})

		}
	}
}

</script>