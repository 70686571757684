<template>
	<div>
		<b-row v-if="!config.gruppi">
			<b-col :cols="cols" v-for="rootId in config.radici">
				<SkillPicker :disabled="disabled" :size="size" v-if="skillsFrom(rootId)" :options="skillsFrom(rootId)" :model="model" :config="{ radice: rootId, onlyone: config.onlyone, level: config.level, hideRating: config.hideRating }"></SkillPicker>
			</b-col>
		</b-row>
		<div>

			<b-nav :pills="pills" :tabs="tabs">
				<b-nav-item @click.prevent="tab = gruppo.label" :active="tab == gruppo.label"  v-for="gruppo in config.gruppi">Skills {{ gruppo.label }} <small v-if="contatoreSkills[gruppo.label][0].totale">({{ contatoreSkills[gruppo.label][0].totale }})</small></b-nav-item>
			</b-nav>

			<b-row class="pt-1" v-if="tab == gruppo.label" v-for="gruppo in config.gruppi">
				<b-col :cols="cols" v-for="rootId in gruppo.skills">
					<SkillPicker :disabled="disabled" :size="size" v-if="skillsFrom(rootId)" :options="skillsFrom(rootId)" :model="model" :config="{ radice: rootId, onlyone: config.onlyone, level: config.level, hideRating: config.hideRating }"></SkillPicker>
				</b-col>
			</b-row>

		</div>

	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	components: {
		SkillPicker: require('@/components/layout/Sessioni/Generica/SkillPicker.vue').default,
	},
	props: [ 'model', 'disabled', 'config', 'cols', 'size', 'pills', 'tabs'],
	created: function() {
		this.getSkills();
	},
	data: function(){
		return {
			tab: null,
			skills: false
		}
	},
	methods: {
		skillsFrom: function(id) {
			return _.find(this.skills, { id });
		},
		getSkills: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skill/object').then(function(data) {
				this.skills = data.data;
			});
		},
	},
	watch: {
		config: {
			immediate: true,
			deep: true,
			handler: function() {
				if(this.config.gruppi) {
					this.tab = _.first(this.config.gruppi).label;
				}
			}
		}
	},
	computed: {
		contatoreSkills: function() {
			return _.groupBy(_.map(this.config.gruppi, function(a) {
				var amount = 0;
				_.each(a.skills, function(skill) {
					if(_.find(this.model, { skill_glo_root_id: skill }))
						amount++;
				}.bind(this));
				a.totale = amount;
				return a;
			}.bind(this)), 'label');
			console.log(this.model);
		}
	},
}
</script>