<template>
	<div v-if="payment">
		<b-modal size="lg" v-model="ux.show" @hide="close">

			<template #modal-footer>
				<b-btn-group>
					<b-btn @click.prevent="close()">Cancella</b-btn>
					<b-btn @click.prevent="doOk()">Salva</b-btn>
				</b-btn-group>
			</template>

			<template #modal-header>
				<b-row class="w-100">
					<b-col class="text-center">
						{{ {fixed: 'Importo Fisso', bonus: 'Bonus'}[payment.tipologia] }}
					</b-col>
					<b-col class="text-center">
						<span v-if="payment.pagato" class="text-success">✔︎</span>
						{{ payment.stipendio_annuo_lordo | money }}
					</b-col>
					<b-col class="text-center" v-if="!payment.pagato">
						{{ payment.data_previsto_pagamento | dt }}
					</b-col>
					<b-col class="text-center" v-else>
						<span v-if="payment.pagato_il != '0000-00-00'">{{payment.pagato_il | dt}}</span>
					</b-col>
				</b-row>
			</template>

			<b-alert variant="primary" v-if="expired" show>Il pagamento risulta scaduto <strong>{{ dayAmount }}</strong> ({{ payment.data_previsto_pagamento | dt }})</b-alert>

			<label><input type="checkbox" v-model="payment.pagato" :true-value="1" :false-value="0" /> Pagato</label>
			<b-form-group label="Data Pagamento">
				<b-input :readonly="payment.pagato == 0" type="date" v-model="payment.pagato_il"></b-input>
			</b-form-group>
			<hr />
			<b-card title="Note sul Pagamento">
				
				<NoteManager entity="Persona_Contratto_Importo" :entityId="payment.id"></NoteManager>

			</b-card>

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'

export default {
	props: [ 'payment', 'contratto' ],
	components: {
		Regola: require('./Regola.vue').default,
		ModalNewRule: require('./ModalNewRule.vue').default,
		RuleHelper: require('./RuleHelper.vue').default,
		NoteManager: require('../notes/manager.vue').default
	},
	created: function() {
		this.bk = JSON.stringify(this.payment);
	},
	computed: {
		expired: function() {
			return moment().isAfter(this.payment.data_previsto_pagamento) && this.payment.pagato == 0;
		},
		dayAmount: function() {
			return moment(this.payment.data_previsto_pagamento).fromNow();
		},
		changed: function() {
			return JSON.stringify(this.payment) !== this.bk;
		}
	},
	methods: {
		doOk: function() {
			this.ux.halt = true;
			if(this.changed) {

				if(window.confirm('Confermi le modifiche?')) {

					this.$http.post( this.$store.state.apiEndPoint + '/contratti/pagamenti/' + this.contratto.entity.id + '/' + this.payment.id, { importo: this.payment } ).then(function(data) {
						this.ux.halt = false;
						this.$emit('refresh');
						this.$emit('close');
					}.bind(this));

				} else {

					this.$emit('refresh');
					this.$emit('close');

				}

			} else {

				this.$emit('close');

			}

		},
		show: function() {
			this.ux.show = true;
		},
		close: function() {
			if(this.ux.halt == false)
				this.$emit('close');
		}
	},
	filters: {
		dt: function(a) {
			return moment(a).format('DD/MM/Y')
		},
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return '€ ' + formatter.format(v).replace('€', '');
		},
	},
	data: function() {
		return {
			bk: false,
			ux: {
				halt: false,
				changed: false,
				show: true,
			}
		}
	}
};

</script>