<template>
  <b-row>
    <b-col sm="7">
      <b-form-input type="text" v-model="comune" autocomplete="false" @input="updateValue"
      placeholder="Comune"
      @keyup.native.esc="esc" 
      @blur.native = "blur"
      @keydown.native.enter = "enter"
      @keydown.native.down = "down"
      @keydown.native.up = "up"> 
    </b-form-input>
    <b-list-group v-show="open">
      <b-list-group-item v-for="(suggestion, index) in comuni" :key="index" v-bind:class="{'active': isActive(index)}"
      @mousedown="suggestionClick(index)" href="#">{{ suggestion.nome }}</b-list-group-item>
    </b-list-group>
  </b-col>
  <b-col sm="3" v-if="show_cap">
    <b-form-input id="cap" placeholder="CAP" v-model="cap" @input="updateCap"></b-form-input>
  </b-col>
  <b-col :sm="show_cap ? '2' : '5'">
    <b-form-input id="provincia" placeholder="Provincia" v-model="provincia" readonly></b-form-input>
  </b-col>
</b-row>
</template>

<script>
export default {
  name: 'autocomplete-comune',
  props: {
    show_cap: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      comuni: [],
      open: false,
      current: 0,
      selected: null,
      comune: '',
      provincia: '',
      cap: '',
    }
  },

  computed: {

    check_valid: function () {
      if (this.selected) {
        for (var index in this.comuni)
        {
          var item = this.comuni[index];
          if (this.selected.id == item.id)
          {
            if(item.nome == this.comune) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  methods: {
    updateValue (value) {
      if(this.check_valid) {
        return;
      }
      this.open = true;
      this.current = 0;
      this.selected = null;
      this.provincia = '';
      this.cap = '';
      this.$emit('change', value);
    },
    updateCap (value) {
      this.$emit('changeCap', value);
    },
    esc () {
      this.open = false
    },
    blur () {
      this.open = false
    },
    // When enter pressed on the input
    enter () {
      this.selected = this.comuni[this.current];
      var cap = '';
      if(this.show_cap) {
        var array_cap = JSON.parse(this.selected.cap);
        if(array_cap.length > 1) {
        //  cap = array_cap[0] + ' - ' + array_cap[array_cap.length - 1];
          cap = '';
        } else {
          cap = array_cap[0];
        }
      }
      this.$emit('select', this.selected.nome, this.show_cap ? this.selected.sigla : JSON.parse(this.selected.provincia).nome, cap);
      this.open = false;
      this.comune = this.selected.nome;
      this.provincia = this.show_cap ? this.selected.sigla : JSON.parse(this.selected.provincia).nome;
      this.cap = cap;
    },
    // When up pressed while suggestions are open
    up () {
      if (this.current > 0) {
        this.current--
      }
    },
    // When up pressed while suggestions are open
    down () {
      if (this.current < this.comuni.length - 1) {
        this.current++
      }
    },
    // For highlighting element
    isActive (index) {
      return index === this.current
    },
    // When one of the suggestion is clicked
    suggestionClick (index) {
      this.selected = this.comuni[index];
      var cap = '';
      if(this.show_cap) {
        var array_cap = JSON.parse(this.selected.cap);
        if(array_cap.length > 1) {
        //  cap = array_cap[0] + ' - ' + array_cap[array_cap.length - 1];
          cap = '';  
        } else {
          cap = array_cap[0];
        }
      }
      this.$emit('select', this.selected.nome, this.show_cap ? this.selected.sigla : JSON.parse(this.selected.provincia).nome, cap);
      this.open = false;
      this.comune = this.selected.nome;
      this.provincia = this.show_cap ? this.selected.sigla : JSON.parse(this.selected.provincia).nome;
      this.cap = cap;
    },
    setComune(comune, provincia, cap = '') {
      if(comune && comune.trim() != "") {
        this.$http.get(this.$store.state.apiEndPoint + '/comuni/' + comune).then((response) => {
          if (response.ok)
          {
            this.comuni = response.data;
            for(var i in this.comuni) {
              if(this.comuni[i].nome == comune && (this.show_cap ? this.comuni[i].sigla == provincia : JSON.parse(this.comuni[i].provincia).nome == provincia)) {
                this.selected = this.comuni[i];
                break;
              }
            }
            this.$emit('select', comune, provincia, cap);
            this.open = false;
            this.comune = comune;
            this.provincia = provincia;
            this.cap = cap;
          }
        }, (response) => {
        });
      }
    },
  },
  watch: {
    comune: function () {
      if(this.comune && this.comune.trim().length > 1) {
        this.$http.get(this.$store.state.apiEndPoint + '/comuni/' + this.comune).then((response) => {
          if (response.ok)
          {
            this.comuni = response.data;
          }
        }, (response) => {
        });
      }
    }
  },

}
</script>