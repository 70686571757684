<template>
	<div>
		<b-list-group v-if="ux.ac == false">
			<b-list-group-item v-for="p, index in entity.pack" @click="do_swap(p)" :style="'background-color: ' + p.CalendarObject.colore">
				<b-row>
					<b-col cols="10">
						<b-form-checkbox style="display: inline;" v-if="ux.edit" type="checkbox" v-model="selected" :value="p.id"><strong>{{ p.CalendarObject.inizio | dt }}</strong></b-form-checkbox>
						<strong v-else>{{ p.CalendarObject.inizio | dt }}</strong>
						<span v-if="!ux.edit"> {{ p.CalendarObject.nome_ext }}</span>
						<span v-else class="ml-2">
							<label>{{ p.CalendarObject.nome_sessione }} - Giocatori</label>
							<multiselect v-model="model_sessioni[index].persone" :options="player_options" :multiple="true" placeholder="Seleziona" label="name" track-by="id" group-values="items" group-label="label" :group-select="true"></multiselect>
						</span>
					</b-col>
					<b-col class="text-right">
						<div v-if="!ux.edit">{{ p.CalendarObject.operatore }}</div>
						<div v-else>
							<label>Operatore</label>
							<b-form-select v-model="model_sessioni[index].operatore_id">
								<b-form-select-option :value="null">-- Seleziona --</b-form-select-option>
								<b-form-select-option :value="staff.id" v-for="staff in staff_options">{{ staff.cognome + ' ' + staff.nome }}</b-form-select-option>
							</b-form-select>
						</div>
					</b-col>
				</b-row>
			</b-list-group-item>
		</b-list-group>
		<div v-else>
			
			<b-card v-for="matrice in entity.pack" class="mb-1">
				<template slot="header">{{ matrice.nome }}</template>
				<ContainerMatrice @changed="linked.push($event)" :sessioneId="matrice.id" :team="[]"></ContainerMatrice>
			</b-card>

		</div>

		<div v-if="ux.edit == false" class="mt-2">
			<b-button-group>
				<b-button variant="outline-secondary" @click.prevent="ux.edit = 1">Azioni Massive</b-button>
				<b-button variant="outline-info" @click.prevent="ux.edit = 2; ux.ac = true">Activity Check</b-button>
			</b-button-group>
		</div>
		<div v-else-if="ux.edit == 1" class="mt-2">
			<b-button-group size="sm">
				<b-button class="mr-0" variant="outline-secondary" @click.prevent="selected = _.map(entity.pack, 'id')">Seleziona Tutti</b-button>
				<b-button class="mr-0" variant="outline-secondary" @click.prevent="selected = []">Deseleziona Tutti</b-button>
				<b-button class="mr-0" variant="outline-danger" @click.prevent="deleteSelected" :disabled="selected.length == 0">Elimina Selezionati</b-button>
				<b-button class="mr-0" variant="outline-success" @click.prevent="updateSessioni">Salva</b-button>
				<b-button class="mr-0" variant="outline-primary" @click.prevent="ux.clone = !ux.clone" :disabled="selected.length == 0">Duplica</b-button>
				<b-button class="mr-0" variant="outline-primary" @click.prevent="downloadPdfSelected" :disabled="selected.length == 0">Download Pdf</b-button>
			</b-button-group>
			<b-button size="xs" class="float-right" variant="secondary" @click.prevent="ux.edit = false">Anulla</b-button>
		</div>
		<div v-else-if="ux.edit == 2" class="mt-2">
			<b-button v-if="linked.length > 0" @click.prevent="saveActivityCheck">Salva Activity Check</b-button>
			<b-button size="xs" class="float-right" variant="secondary" @click.prevent="ux.edit = false; ux.ac = false">Anulla</b-button>
		</div>
		<b-row class="mt-3" align-v="center" v-if="ux.edit && ux.clone">
			<b-col cols="6">
				<b-form-group label="Dal" description="Data specifica o inizio intervallo">
					<b-form-input v-model="clone_form.from" type="date"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="6">
				<b-form-group label="Al" description="Data di fine intervallo (opzionale)">
					<b-form-input v-model="clone_form.to" type="date"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="12">
				<b-form-group label="Giorni" description="Seleziona uno o più giorni specifici su cui vuoi duplicare le sessioni selezionate nell'intervallo scelto (lascia vuoto per duplicare su tutti i giorni)">
					<b-form-checkbox-group
					v-model="clone_form.days"
					:options="days_options"
					name="days"
					></b-form-checkbox-group>
				</b-form-group>
			</b-col>
			<b-col>
				<b-button variant="outline-primary" v-if="selected.length > 0" class="mt-3" @click="duplicaSessioni">Crea duplicati</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'
var Auth = require('../../../auth.js');
import * as types from '@/store/types'
import { mapState } from 'vuex';

export default {
	components: {
		ContainerMatrice: require('@/components/layout/Sessioni/ContainerMatrice.vue').default,
	},
	props: [ 'entity', 'player_options', 'team', 'date', 'staff_options' ],
	created: function() {
	},
	computed: {
		_: function() {
			return _;
		},
		selectedIds: function() {
			return this.selected;
			return _.map(this.entity.pack, 'id');
		},
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
		
		user: function() {
			return Auth.user;
		}
	},
	watch: {
		'entity': {
			deep: true,
			handler: function() {
				if(this.entity == null) {
					this.model_sessioni = [];
				} else {
					this.model_sessioni = [];
					this.entity.pack.forEach((item) => {
						var single = {
							sessione_id: item.id,
							persone: item.persone.map((a) => {return {id: a.id, name: a.cognome + ' ' + a.nome}}),
							operatore_id: item.operatore_id
						}
						this.model_sessioni.push(single);
					});
				}
			},
			immediate: true
		}
	},
	methods: {
		saveActivityCheck: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/sessioni/updateMatrici', { tables: this.linked }).then(function(response) {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, function(response) {
				this.errors = response.data;
				for (var index in this.errors) {
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				}
			})
			console.log(this.linked);

		},
		deleteSelected: function() {

			if(!confirm('Confermi la rimozione di ' + this.selectedIds.length + ' sessioni?'))
				return false;

			this.$http.post(this.$store.state.apiEndPoint + '/sessioni/delete-multiple', { ids: this.selectedIds }).then(function(response) {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.closeModal();
				}
			}, function(response) {
				this.errors = response.data;
				for (var index in this.errors) {
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				}
			})

		},

		downloadPdfSelected() {
			var url = this.$store.state.apiEndPoint + '/sessioni/pdf-multiple/' + this.user.id + "/" + this.user.api_token;
			this.selectedIds.forEach((item, index) => {
				if(index != 0) {
					url += '&ids[]=' + item;
				} else {
					url += '?ids[]=' + item;
				}
			});
			window.open(url, "_blank");
		},

		do_swap: function(a) {
			if(this.ux.edit == false)
				this.$emit('swap', { base: a });
		},
		closeModal: function() {
			this.$emit('close');
		},

		updateSessioni() {
			this.$http.post(this.$store.state.apiEndPoint + '/sessioni/update-multiple', this.model_sessioni).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.closeModal();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		duplicaSessioni() {
			if(!confirm('Confermi la duplicazione di ' + this.selectedIds.length + ' sessioni?'))
				return false;
			this.clone_form.sessioni_id = this.selectedIds;
			this.$http.post(this.$store.state.apiEndPoint + '/sessioni/clone-multiple', this.clone_form).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.closeModal();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		refreshEvents: function() {
			this.$emit('refresh');
		},
	},
	filters: {
		dt: function(a) {
			return moment(a).format('HH:mm');
		}
	},
	data: function() {
		return {
			model_sessioni: [],
			selected: [],
			linked: [],
			ux: {
				edit: false,
				clone: false,
				ac: false
			},
			clone_form: {
				from: null,
				to: null,
				days: [],
				sessioni_id: [],
			},
			days_options: [
			{ text: 'LUN', value: 1 },
			{ text: 'MAR', value: 2 },
			{ text: 'MER', value: 3 },
			{ text: 'GIO', value: 4 },
			{ text: 'VEN', value: 5 },
			{ text: 'SAB', value: 6 },
			{ text: 'DOM', value: 0 }
			]
		}
	}
};

</script>