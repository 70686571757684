<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		
		<b-container fluid>
			<div class="card">
				<div class="card-body">
					<div class="content">
						<contratto :contratto-id="$route.params.id"></contratto>
					</div>
				</div>
			</div>
		</b-container>

		<footerapp></footerapp>
	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
export default {
	data: function(){
		return {
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			players: [],
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.getPlayers();
		},
		seasonId() {
			this.getPlayers();
		},
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		Contratto: require('../../layout/contratti/ContrattoDetail.vue').default
	},
	created: function () {
		//this.getPlayers();
	},
	methods: {
		getPlayers: function() {				
			this.players = [];
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/medical/players', {params: { team_id: this.rosaTeamId, season_id: this.seasonId  }}).then((response) => {
				if (response.ok)
				{
					if(response.data.players) {
						this.players = response.data.players.map(item => ({								
							name: item.cognome + ' ' + item.nome,
							id: item.id,
							ruolo: ( item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length > 0 ) ? item.last_carriera_sportiva.ruolo_ideale[0].nome : '',
							avatar: item.avatar_url,
							injuries: item.injuries_count,
							illnesses: item.illnesses_count,
							testrtp: item.test_count,
						}));
					}
					this.loading = false;
				}
			});
		},
	}
}
</script>