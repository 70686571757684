<template>
	<div>
		
		<div>
			<fieldset>
				<legend>Messaggi dal frame</legend>
				<pre>{{ messages }}</pre>
			</fieldset>
			<div><button @click.prevent="sendMessage('test', 1)">Send Message</button></div>
			<iframe frameborder="0" width="100%" :height="height" src="" ref="iframeContainer" @load="iframeReady"></iframe>
		</div>

	</div>
</template>

<script>

var Auth = require('../../auth.js');
import * as types from '../../store/types'
export default {
    data () {
        return {
            height: 10000,
            messages: []
        }
    },

    components: {
    },

    methods:{
    	sendMessage: function(a, payload) {
    		this.$refs.iframeContainer.contentWindow.postMessage({
    			//sender: "get_page_button1",
    			action: a,
    			payload
    		}, "*");
    	},
    	iframeReady: function() {
    		this.sendMessage('welcome', {
    			url: 'https://api.asromasystem.com/video/id/QTBVc3NTamU3VXVwYmtjUFN0MklDQT09',
    			provider: 'spiideo'
    		})
    	}
    },
    mounted() {

    	this.$refs.iframeContainer.src = 'http://0.0.0.0:8081/index.html';
    	// this.$refs.iframeContainer.src = 'https://app.asromasystem.com/plain/video-pan/index.html';
    	window.addEventListener("message", function(event) {
    		var i = event.data;
    		this.messages.push(JSON.stringify(i));
    		if(i.action == 'welcome')
    			this.height = i.payload.height;
    		else {
    			console.log(i);
    		}
    	}.bind(this));

    },
    computed: {
        
    },
    created: function () {
        
    }
}
</script>
