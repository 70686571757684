<template id="tabellagiocatori">
    <div class="tabellagiocatori">

        <table class="table table-striped">
            <thead>
                <th>ID</th>
                <th>giocatore</th>
                <th>anno</th>
                <th>ruolo</th>
                <th width="100">piede</th>
                <th width="100">conv casa</th>
                <th width="100">conv tras</th>
                <th width="100">pres partite</th>
                <th width="100">titolare</th>
                <th width="100">min gioc</th>
                <th width="100">goal</th>
                <th width="100">amm</th>
                <th width="100">es</th>
                <th width="100">pres sess</th>
                <th width="100">inf</th>
            </thead>
            <tbody>
                <tr v-for="giocatore in rosa">
                    <td>{{ giocatore.id }}</td>
                    <td><router-link :to="{ name: 'football_giocatore_scheda', params: { id: giocatore.id }}">{{ giocatore.name }} </router-link> <span v-if="giocatore.carriera.tipologia == 'prova'" title="In prova">(*)</span> </td>
                    <td>{{ giocatore.player.nascita_data | formatYear }}</td>
                    <td>{{ giocatore.ruolo }}</td>
                    <td><span v-if="giocatore.player.giocatore">{{ piede[giocatore.player.giocatore.piede] }}</span></td>
                    <td>{{ giocatore.player.convocated_home || 0 }}</td>
                    <td>{{ giocatore.player.convocated_trasf || 0 }}</td>
                    <td>{{ giocatore.player.matches_played || 0 }}</td>
                    <td>{{ giocatore.player.titolare || 0 }}</td>
                    <td>{{ giocatore.player.minutes_played || 0}}</td>
                    <td>{{ giocatore.player.goal || 0 }} </td>
                    <td>{{ giocatore.player.amm || 0 }}</td>
                    <td>{{ giocatore.player.esp || 0 }}</td>
                    <td>{{ giocatore.player.attendances || 0 }}</td>
                    <td>{{ giocatore.player.injuries || 0 }}</td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import moment from 'moment';
export default {
  data () {
    return {
        piede: [ 'Destro', 'Sinistro', 'Ambidestro' ],
      }
    },
    props:{
        rosa: {
            type: Array,
            required: true
        }
    },
    filters: {
        formatYear: function (date) {
            var m = moment(date);
            if(m.isValid()) {
                return m.format('YYYY'); 
            }
            return "";
        }
    },
}
</script>
