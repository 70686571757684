<template id="griassunto">
    <div class="griassunto">
		<table class="table table-striped">
			<thead>
				<tr>
					<th title="Utente">Utente</th>
					<th title="Titolo">Titolo</th>
					<th title="Video">Video</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(riassunto, index) in params.riassunti">
					<td :title="riassunto.utente.nomeCompleto">{{riassunto.utente.cognome}}</td>
					<td>{{riassunto.nome}}</td>
					<td><i class="fa fa-youtube-play fa-3" aria-hidden="true" style="cursor:pointer;color:red;font-size: 1.3rem;" @click="selectRiassunto(index)"></i></td>
				</tr>
			</tbody>
		</table>

    </div>
</template>

<script>
export default {
  props: {
    params: {
    	type: Object,
		required: false
	}
  },
	methods: {
		selectRiassunto(index) {
      		this.$bus.$emit('selectRiassunto', index);
    	},		
	}
}
</script>
