<template id="gruppi">
  <section class="gruppi">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>Gruppi</span>
        </div>

        <div class="content">
          <admin-table
          api-url="/admin/gruppi"
          :columns="columns"
          :editFormData="gruppiData"
          :createFormData="newGruppiData"
          :canDelete="false"
          :defaultFilter="false"
          @showModal="onShowModal"
          @createError="createError"
          @resetCreateFormData="resetCreateFormData">
          <template slot="filters">
            <b-row align-v="center">
              <b-col cols="3">
                <b-form-input v-model="filters.nome" placeholder="Nome" />
              </b-col>
              <b-col cols="3">
                <multiselect
                v-model="filters.permessi"
                :multiple="true"
                :options="permessi_options"
                placeholder="Filtra per permessi"
                label="nome"
                track-by="id"/>
              </b-col>
               <b-col cols="3">
              <b-form-checkbox v-model="filters.hasCategory" :unchecked-value="null" :value="1" >Has Category</b-form-checkbox>
              </b-col>
            </b-row>
          </template>
          <template slot="edit-modal-body" slot-scope="row">
            <!--b-form-group label="Permessi">
              <multiselect
              v-model="gruppiData.permessi"
              :multiple="true"
              :options="permessi_options"
              placeholder="Seleziona permesso"
              label="nome"
              :custom-label="customLabel"
              :show-labels="false"
              track-by="id"/>
            </b-form-group-->
            <b-form-group label="Nome">
             <b-form-input type="text" placeholder="Nome" v-model="gruppiData.nome" />
           </b-form-group> 
            <b-form-group label="Il gruppo necessita di categoria">
             <b-form-checkbox v-model="gruppiData.hasCategory" :unchecked-value="null" :value="1" />
            </b-form-group> 
            <b-form-group label="Permessi">
              <b-form-checkbox-group v-model="gruppiData.permessi" name="permessi_upd" stacked style="height:500px;overflow-y:auto;">
                <b-form-checkbox v-for="permesso in permessi_options" :value="permesso" :key="permesso.id">{{ permesso.info ? permesso.info.info : permesso.nome }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </template>
          <template slot="create-modal-body" slot-scope="row">
            <b-form-group label="Nome">
             <b-form-input type="text" placeholder="Nome" v-model="newGruppiData.nome" />
           </b-form-group> 
           <b-form-group label="Il gruppo necessita di categoria">
             <b-form-checkbox v-model="newGruppiData.hasCategory" :unchecked-value="null" :value="1" />
           </b-form-group> 
           {{newGruppiData.hasCategory}}
           <!--b-form-group label="Permessi">
            <multiselect
            v-model="newGruppiData.permessi"
            :multiple="true"
            :options="permessi_options"
            placeholder="Seleziona permesso"
            label="nome"
            :custom-label="customLabel"
            :show-labels="false"
            track-by="id"/>
          </b-form-group-->
          <b-form-group label="Permessi">
            <b-form-checkbox-group v-model="newGruppiData.permessi" name="permessi_new" stacked style="height:500px;overflow-y:auto;">
              <b-form-checkbox v-for="permesso in permessi_options" :value="permesso" :key="permesso.id">{{ permesso.info ? permesso.info.info : permesso.nome }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </template>
      </admin-table>
    </div>
  </div>
</div>
<footerapp></footerapp>
</section>
</template>

<script>
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default 
  },

  data() {
    return {
      columns: [
      { key: 'id', label: 'ID', sortable: true },
      { key: 'nome', label: 'Nome', sortable: true },
      { key: 'hasCategory', label: 'Has Category', sortable: true },
      { key: 'permessi', label: 'Permessi'},
      { key: 'actions', label: 'Azioni' },
      ],
      gruppiData: {
        nome: '',
        permessi: [],
        hasCategory: null
      },
      newGruppiData: {
        nome: '',
        permessi: [],
        hasCategory: null
      },
      permessi_options: [],
      filters: {
        nome: '',
        permessi: [],
        hasCategory: null,
      },
    };
  },
  watch: {
    filters: {
      handler() {
        this.$root.$emit('admin-table::filter', this.filters);
      },      
      deep: true,
    },
  },
  methods: {
    createError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(types.ALERTS_PUSH, { msg: err.response.data.error, status: 0 });
      }
    },
    onShowModal(row) {
      this.gruppiData.nome = row.item.nome;
      this.gruppiData.hasCategory = row.item.hasCategory;
      this.gruppiData.permessi = row.item.permessi.map(({pivot, ...rest}) => {
        return rest;
      });
    },
    customLabel ({ nome, info }) {
      if(info) {
        return info.info;
      } else 
      return nome;
    },
    resetCreateFormData() {
      this.newGruppiData.nome = '';
      this.newGruppiData.permessi = [];
      this.newGruppiData.hasCategory = null;
    },
  },
  created: function() {
    axios.get('/admin/permessi', {
        params: { no_info: true }}).then((res) => {
      this.permessi_options = res.data;
      this.permessi_options = this.permessi_options.sort(function(a, b) { 
        return (a.info ? a.info.info : a.nome).localeCompare(b.info ? b.info.info : b.nome);
      });
    });
  },
};
</script>

<style lang="scss">

.pac-container {
  z-index: 1051 !important;
}
</style>
