<template>
	<div>
		<b-row>
			<b-col cols="1">
		        <img :src="nota.persona.avatar_url" class="img-fluid" />
			</b-col>
			<b-col>
				<p class="m-0 font-bold">{{ nota.persona.nome }} {{ nota.persona.cognome }}</p>
				<p class="m-0">{{ nota.testo }}</p>
				<p class="text-muted small">{{ nota.created_at | date }}</p>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'
var Auth = require('../../../auth.js');

export default {
	props: [ 'nota' ],
	created: function() {

	},
	computed: {
		user: function() {
			return Auth.user;
		}
	},
	watch: {
		
	},
	methods: {
		addNote: function() {
			this.ux.creating = true;
		}
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY HH:mm');
		}
	},
	data: function() {
		return {
			ux: {
				creating: false
			}
		}
	}
};

</script>