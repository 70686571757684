import moment from 'moment';

export default {
    methods: {
        setCache(key, value, seconds = null) {
            const now = new Date();
            const item = {
                value: value,
                expiry: seconds ? (now.getTime() + (seconds * 1000)) : null,
            }
            localStorage.setItem(key, JSON.stringify(item));
        },

        getCache(key) {
            const itemStr = localStorage.getItem(key);
            if (!itemStr) {
                return null;
            }
            const item = JSON.parse(itemStr);
            const now = new Date();
            if (item.expiry && now.getTime() > item.expiry) {
                localStorage.removeItem(key);
                return null;
            }
            return item.value;
        },

        deleteCache(key) {
            localStorage.removeItem(key);
        }
    }
};