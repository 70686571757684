<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Performance</span>
					</div>
					<div class="content">
						<b-tabs>
							<b-tab title="TEST" active>	
								<b-row class="mb-1">
									<b-col>
										<b-form-radio-group
										buttons
										button-variant="outline-primary"
										v-model="periodo">
										<b-form-radio v-for="item in max_periodo" :value="item">Test {{ item }}</b-form-radio>
										</b-form-radio-group>
										<b-button variant="success" @click="max_periodo += 1" title="Aggiungi test">+</b-button>
									</b-col>
									<b-col class="text-right font-weight-bold" align-self="center">
										<span class="mr-5">Score: valore da 0 a 3</span> <span>Pain = 0; No Pain = 1</span>
									</b-col>
								</b-row>
								<div v-if="loading" align="center" style="width:100%">
									<img src="/assets/varie/loading.gif" style="width: 150px;" />
								</div>
								<b-form @submit="onSubmit" v-if="!loading && giocatori.length > 0">
									<cardtest v-for="giocatore in giocatori" class="mb-2" :giocatore="giocatore" :test="test_fisici[giocatore.id]" :test_media="test_media" :test_ds="test_ds" @updatefirsttest="updateFirstTest" @updatesecondtest="updateSecondTest" @updatepei="updatePei"></cardtest>
									<b-row>
										<b-col>
											<linechart :lineid="'line_' + lineid" :datachart="linedatachart" :ymin="-5" :ymax="5"></linechart>
										</b-col>
									</b-row>
									<b-button v-if="auth.isLastSeason(seasonId)" type="submit" variant="primary">Salva</b-button>
								</b-form>
							</b-tab>
							<!--b-tab title="DATI GPS">
								<b-row>
									<b-col>
										<b-form-group label="Allenamenti">
											<b-form-select v-model="allenamento">
												<option :value="null">Seleziona</option>
												<option v-for="item in allenamenti" :value="item">{{ item.start_at | formatDateHour }}</option>
											</b-form-select>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group label="Partite">
											<b-form-select v-model="partita">
												<option :value="null">Seleziona</option>
												<option v-for="item in partite" :value="item">{{ item.date | formatDateHour }}</option>
											</b-form-select>
										</b-form-group>
									</b-col>
								</b-row>
								<h5> {{ title_gps }} </h5>
								<b-table v-if="gps.length > 0" striped hover :items="gps">
									<template slot="HEAD_id" slot-scope="data">
										Giocatore
									</template>
									<template slot="HEAD_rosa" slot-scope="data">
									</template>
									<template slot="id" slot-scope="row">
										{{ row.item.rosa.giocatore.cognome }} {{ row.item.rosa.giocatore.nome }}
									</template>	
									<template slot="rosa" slot-scope="data">
									</template>							
								</b-table>	
							</b-tab-->
						</b-tabs>
					</div>
				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>

import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
var Auth = require('../../../auth.js');

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		cardtest: 	require('./cardtest.vue').default,
		linechart: 	require('../../layout/charts/line.vue').default,
	},

	data: function() {
		return {
			loading: false,
			periodo: 1,
			max_periodo: 1,
			load_max_periodo: true,
			rosa_ids: [],
			giocatori: [],
			test_fisici: {},
			test_media: {
				cmj: 0,
				t_5m: 0,
				t_30m: 0,
				ma: 0,
				fms: 0,
			},
			test_ds: {
				cmj: 0,
				t_5m: 0,
				t_30m: 0,
				ma: 0,
				fms: 0,
			},	
			pei: {},
			pei_labels: [],
			pei_values: [],
			pei_media: [],
			allenamenti: [],
			partite: [],
			allenamento: null,
			partita: null,
			gps: [],
			title_gps: '',
		}
	},
	created: function () {
		this.loadPerformance();
	//	this.loadAllenamentiGps();
	//	this.loadPartiteGps();
	},
	methods: {
		loadPerformance() {
			if(this.rosaTeamId && this.seasonId) {
				this.loading = true;
				this.resetPerformance();
				this.$http.get(this.$store.state.apiEndPoint + '/performance/rosa/' + this.rosaTeamId + '/' + this.seasonId + '/' + this.periodo).then((response) => {
					if (response.ok)
					{
						this.giocatori = response.data;  
						for(var index in this.giocatori) {
							var gio = this.giocatori[index];
							if(this.load_max_periodo) {
								this.rosa_ids.push(gio.id);
							}
							var obj = {
								value: 0,
								label: gio.cognome,
							};
							this.pei[gio.id] = obj;
							var test = {
								persona_id: gio.id,
								deep_squat_score: 0,
								hurdle_step_l_score: 0,
								hurdle_step_r_score: 0,
								inline_lounge_l_score: 0,
								inline_lounge_r_score: 0,
								shoulder_mobility_l_score: 0,
								shoulder_mobility_r_score: 0,
								shoulder_mobility_pain: 1,
								active_straight_leg_raise_l_score: 0,
								active_straight_leg_raise_r_score: 0,
								trunk_stability_pushup_score: 0,
								trunk_stability_pushup_pain: 1,
								rotary_stability_l_score: 0,
								rotary_stability_r_score: 0,
								rotary_stability_pain: 1,
								sj: 0,
								cmj: 0,
								t_5m: 0,
								t_10m: 0,
								t_30m: 0,
								t_505dx: 0,
								t_505sx: 0,
								fms: 0,
								yoyo: 0,
							};
							if(gio.last_performance) {
								var test_gio = gio.last_performance;
								test.deep_squat_score = test_gio.deep_squat_score;
								test.hurdle_step_l_score = test_gio.hurdle_step_l_score;
								test.hurdle_step_r_score = test_gio.hurdle_step_r_score;
								test.inline_lounge_l_score = test_gio.inline_lounge_l_score;
								test.inline_lounge_r_score = test_gio.inline_lounge_r_score;
								test.shoulder_mobility_l_score = test_gio.shoulder_mobility_l_score;
								test.shoulder_mobility_r_score = test_gio.shoulder_mobility_r_score;
								test.shoulder_mobility_pain = test_gio.shoulder_mobility_pain;
								test.active_straight_leg_raise_l_score = test_gio.active_straight_leg_raise_l_score;
								test.active_straight_leg_raise_r_score = test_gio.active_straight_leg_raise_r_score;
								test.trunk_stability_pushup_score = test_gio.trunk_stability_pushup_score;
								test.trunk_stability_pushup_pain = test_gio.trunk_stability_pushup_pain;
								test.rotary_stability_l_score = test_gio.rotary_stability_l_score;
								test.rotary_stability_r_score = test_gio.rotary_stability_r_score;
								test.rotary_stability_pain = test_gio.rotary_stability_pain;
								test.sj = test_gio.sj;
								test.cmj = test_gio.cmj;
								test.t_5m = test_gio.t_5m;
								test.t_10m = test_gio.t_10m;
								test.t_30m = test_gio.t_30m;
								test.t_505dx = test_gio.t_505dx;
								test.t_505sx = test_gio.t_505sx;
								test.fms = test_gio.fms;
								test.yoyo = test_gio.yoyo;
							}
							this.test_fisici[gio.id] = test;
						}
						if(this.load_max_periodo) {
							this.getMaxPeriodo();
						}
						this.calcolaMediaAndDS("cmj");
						this.calcolaMediaAndDS("t_5m");
						this.calcolaMediaAndDS("t_30m");
						this.calcolaMediaAndDS("ma");
						this.calcolaMediaAndDS("fms");	
						this.plotPei(); 
						this.loading = false; 
					}
				}, (response) => {
				});
			}
		},
		resetPerformance() {
			this.giocatori = [];
			this.test_fisici = {};
			this.test_media.cmj = 0;
			this.test_media.t_5m = 0;
			this.test_media.t_30m = 0;
			this.test_media.ma = 0;
			this.test_media.fms = 0;
			this.test_ds.cmj = 0;
			this.test_ds.t_5m = 0;
			this.test_ds.t_30m = 0;
			this.test_ds.ma = 0;
			this.test_ds.fms = 0;
			this.pei = {};
			this.pei_labels = [];
			this.pei_values = [];
			this.pei_media = [];
		},
		onSubmit(evt) {
			evt.preventDefault();
			this.$http.post(this.$store.state.apiEndPoint + '/performance/save/' + this.periodo, JSON.stringify(this.test_fisici)).then((response) => {
            if (response.ok)
            {
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            }
        }, (response) => {  
        });
		},
		updateFirstTest(test_name, test_value, id_giocatore) {
			this.test_fisici[id_giocatore][test_name] = parseInt(test_value);
		},
		updateSecondTest(test_name, test_value, id_giocatore) {
			this.test_fisici[id_giocatore][test_name] = parseFloat(test_value);
			if(test_name != 'sj' && test_name != 't_10m' && test_name != 'yoyo') {
				if(test_name == 't_505dx' || test_name == 't_505sx') {
					this.calcolaMediaAndDS('ma');
				} else {
					this.calcolaMediaAndDS(test_name);
				}
			}
		},
		calcolaMediaAndDS(test_name) {
			var k = 0;
			var count = 0;
			for(var i in this.test_fisici) {
				var test = this.test_fisici[i];
				var value = 0;
				if(test_name == 'ma') {
					var value = (parseFloat(test['t_505dx']) + parseFloat(test['t_505sx']))/2;
				} else {
					value = parseFloat(test[test_name]);
				}
				k += value;
				count ++;
			}
        //calcoliamo la media
        var media = k / count;
        var r = 0;
        for(var i in this.test_fisici) {
        	var test = this.test_fisici[i];
        	var value = 0;
        	if(test_name == 'ma') {
        		var value = (parseFloat(test['t_505dx']) + parseFloat(test['t_505sx']))/2;
        	} else {
        		value = parseFloat(test[test_name]);
        	}
        	r += Math.pow((value - media), 2);	
        }                       
        //calcoliamo la varianza
        var variance = r / count;
        //calcoliamo la deviazione standard
        var ds = Math.sqrt(variance);

        this.test_media[test_name] = media;
        this.test_ds[test_name] = ds;
    },
    updatePei(pei_value, id_giocatore) {
    	this.pei[id_giocatore]['value'] = parseFloat(pei_value);
    	this.plotPei();
    },
    plotPei() {
    	var values = [];
    	var names = [];
    	for(var i in this.pei) {
    		var obj = this.pei[i];
    		values.push(obj.value);
    		names.push(obj.label);
    	}  
    	var list = [];
    	for (var j in names) 
    		list.push({'name': names[j], 'value': values[j]});

    	list.sort(function(a, b) {
    		return ((a.value > b.value) ? -1 : ((a.value < b.value) ? 1 : 0));         
    	}); 

    	for (var k = 0; k < list.length; k++) {
    		names[k] = list[k].name;
    		values[k] = list[k].value;
    	} 
    	var pei = [];
    	var media = [];
    	var labelx = [];
    	for (var i = 0; i < values.length; i++) {
    		pei.push(values[i]);
    		media.push(0);
    		labelx.push(names[i] != "" ? names[i] : 'Unknown');
    	}
    	this.pei_labels = labelx;
    	this.pei_values = pei;
    	this.pei_media = media;
    },
    loadAllenamentiGps() {
    	if(this.rosaTeamId && this.seasonId) {
    		this.$http.get(this.$store.state.apiEndPoint + '/gps/allenamenti/' + this.rosaTeamId + '/' + this.seasonId).then((response) => {
    			if (response.ok) {
    				this.allenamenti = response.data;  
    			}
    		}, (response) => {
    		});
    	}
    },
    loadPartiteGps() {
    	if(this.rosaTeamId && this.seasonId) {
    		this.$http.get(this.$store.state.apiEndPoint + '/gps/partite/' + this.rosaTeamId + '/' + this.seasonId).then((response) => {
    			if (response.ok) {
    				this.partite = response.data;  
    			}
    		}, (response) => {
    		});
    	}
    },
    getGpsAllenamento: function(){
    	if(this.allenamento) {
    		this.$http.get(this.$store.state.apiEndPoint + '/gps/allenamento/' + this.allenamento.id).then((response) => {
    			if (response.ok)
    			{
    				this.gps = response.data;
    			}
    		}, (response) => {
    		});
    	}
    },
    getGpsPartita: function(){
    	if(this.partita) {
    		this.$http.get(this.$store.state.apiEndPoint + '/gps/partita/' + this.partita.id).then((response) => {
    			if (response.ok)
    			{
    				this.gps = response.data;
    			}
    		}, (response) => {
    		});
    	}
    },
    getMaxPeriodo: function() {
    	if(this.seasonId) {
    		this.$http.get(this.$store.state.apiEndPoint + '/performance/max/periodo/' + this.seasonId + '/' + this.rosa_ids).then((response) => {
    			if (response.ok)
    			{
    				if(!isNaN(response.data)) {
    					this.max_periodo = response.data;
    				}
    				this.load_max_periodo = false;
    			}
    		}, (response) => {
    		});
    	}
    },
},
filters: {
	formatDateHour: function (date) {
		var m = moment(date);
		if(m.isValid()) {
			return m.format('DD/MM/YYYY HH:mm'); 
		}
		return "";
	},
},
watch: {
	periodo: function() {
		this.loadPerformance();	
	},
	rosaTeamId() {
		this.title_gps = '';
		this.gps = [];
		this.periodo = 1;
		this.max_periodo = 1;
		this.load_max_periodo = true;
		this.rosa_ids = [];
		this.loadPerformance();
	},
	seasonId() {
		this.title_gps = '';
		this.gps = [];
		this.periodo = 1;
		this.max_periodo = 1;
		this.load_max_periodo = true;
		this.rosa_ids = [];
		this.loadPerformance();
	}, 
	allenamento: function() {
		this.title_gps = '';
		this.gps = [];
		if(this.allenamento) {
			this.title_gps = 'Allenamento del ' + moment(this.allenamento.start_at).format('DD/MM/YYYY HH:mm');
		}
		this.getGpsAllenamento();	
	},
	partita: function() {
		this.title_gps = '';
		this.gps = [];
		if(this.partita) {
			this.title_gps = 'Partita del ' + moment(this.partita.date).format('DD/MM/YYYY HH:mm');
		}
		this.getGpsPartita();	
	},
},
computed: {
	auth: function() {
		return Auth;
	},
	lineid: function() {
		return 1;
	},
	linedatachart: function() {
		var data = {
			labels: this.pei_labels,
			datasets: [{
				label: 'PHYSICAL EFFICIENCY INDEX',
				data: this.pei_values,
				backgroundColor: 'rgba(0, 0, 255, 0.5)',
				borderColor: '#0000FF',
				showLine: false,
			}, {
				label: 'Media',
				data: this.pei_media,
				backgroundColor: 'rgba(255, 0, 0, 0.5)',
				borderColor: '#FF0000',
			}]
		};
		return data;		
	},
	...mapState(['rosaTeamId', 'seasonId']),
},
}
</script>
