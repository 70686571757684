<template>
	<section class="players-matches">
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card title="Players Matches Attendances" :no-body="isWidget ? true : false">

			<b-row class="mb-3" align-v="center" v-if="!isWidget || isFullscreen">
				<b-col>
					<b-form-checkbox-group size="sm" v-model="selected_competizioni" buttons>
						<b-form-checkbox v-for="competizione, index in competizioni" :value="competizione" :style="'border:none;' + (selected_competizioni.includes(competizione) ? 'background-color:' + (competizioniColore[index] ? competizioniColore[index] : '#6c757d') + ';color:#FFFFFF' : 'color:' + (competizioniColore[index] ? competizioniColore[index] : '#6c757d') + ';background-color:transparent;border:1px solid ' + (competizioniColore[index] ? competizioniColore[index] : '#6c757d'))">{{ competizione }}</b-form-checkbox>
					</b-form-checkbox-group>
				</b-col>
				<b-col>
					<b-form-select size="sm" v-model="type" :options="types" value-field="value" text-field="label" placeholder="Metrica"></b-form-select>
				</b-col>
				<b-col>
					<b-form-checkbox size="sm" v-model="aggregated" button :button-variant="aggregated ? 'primary' : 'outline-secondary'">
					{{ aggregated ? 'Ordina per Data' : 'Ordina per Data' }}</b>
				</b-form-checkbox>
				<b-button size="sm" @click="print">Stampa</b-button>
			</b-col>
			<b-col class="text-right">
				<b-form-checkbox size="sm" v-model="ux.hideDisabled">
					Gestisci Giocatori Nascosti {{ ux.hideDisabled ? '(' + hidePreset.length + ')' : ''}}
				</b-form-checkbox>
			</b-col>
			<b-col>
				<b-button-group>
					<b-button v-if="ux.hideDisabled" variant="outline-success" size="sm" @click.prevent="savePersone(hidePreset)">Aggiorna</b-button>
					<b-button v-if="ux.hideDisabled" variant="outline-warning" size="sm" @click.prevent="svuotaPersone()">Svuota</b-button>
				</b-button-group>
			</b-col>
		</b-row>

		<b-table-simple  v-if="selected_competizioni.length > 0" :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 20) + 'px' : '73vh'" striped hover bordered responsive fixed>
			<b-thead>
				<b-tr>
					<b-th v-if="ux.hideDisabled" style="background-color:#FAFAFA;vertical-align: middle;padding: 0; width:40px !important;" class="text-center" sticky-column></b-th>
					<b-th style="background-color:#FAFAFA;vertical-align: middle;padding: 0; width:270px !important;" sticky-column>Player</b-th>
					<b-th style="background-color:#FAFAFA;vertical-align: middle;padding: 0; width:47px !important;" v-for="col in columns" :key="col.id" class="text-center"> 
						<div class="text-center" style="width:100%" v-b-tooltip.top.html="{ customClass: 'tooltipClassHeader' }" :title="( col.competizione_nome ? col.competizione_nome + '<br/>' : '' ) + (col.home ? col.opponent + ' - AS Roma' : 'AS Roma - ' + col.opponent) + '<br/>' + col.score + '<br/>' + col.date">
						<div :title="col.opponent"><img :src="col.logo" width="30" height="30"/></div>
						<div :style="'text-align:center;color:#FFFFFF;background-color:' + (col.home ? 'gray' : 'green')" :title="col.date">{{ col.day }}</div>
						<div :style="'height: 6px; margin-top: 1px; background-color: ' + col.competizione_colore + '; width: 100%'"></div>
					</div>
				</b-th>
				<b-th style="background-color:#CCCCCC; vertical-align:middle; padding:0; width:60px !important;" class="text-center">
					<div v-b-tooltip.top="{ customClass: 'tooltipClassHeader' }" :title="titles[type]" style="width:100%"><i class="fa fa-clock-o" style="fontSize: 1.8rem; color:#666666" aria-hidden="true"></i></div>
				</b-th>
				<b-th class="text-center" style="border:none;">	
				</b-th>
			</b-tr>
		</b-thead>
		<b-tbody>
			<b-tr v-for="player in dataset" :key="player.id" :variant="ux.hideDisabled == true ? (( hidePreset.indexOf(player.persona_id) !== -1 ) ? 'danger':'') : ''">
				<b-td v-if="ux.hideDisabled" style="padding: 0; vertical-align: middle;" class="text-center" sticky-column><input type="checkbox" v-model="hidePreset" :value="player.persona_id" /></b-td>
				<b-td style="padding: 0; vertical-align: middle;" sticky-column>{{ player.player }}</b-td>
				<b-td style="padding: 0" class="text-center" v-for="col, k in columns">

					<div :style="'width:100%;padding:5px;'">
						<span v-if="player.matches[k][type]"><i class="fa fa-check" aria-hidden="true"></i></span>
						<span v-else>&nbsp</span>
					</div>
				</b-td>
				<b-td style="padding:0;" class="text-center">
					<div style="width:100%;padding:5px;" v-b-tooltip.top="{ customClass: 'tooltipClassCell' }" :title="player.player">{{ player[totField] }}</div>
				</b-td>
				<b-td style="background-color:#FFFFFF;border:none;">	
				</b-td>
			</b-tr>
		</b-tbody>
	</b-table-simple>
</b-card>
</section>
</template>

<script>
var Auth = require('@/auth.js');
import pageBase from '@/mixins/pageBase.js';
import { mapState } from 'vuex';
export default {
	mixins: [ pageBase ],
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function() {
		return {
			type: this.configWidget && this.configWidget.typeAttendance ? this.configWidget.typeAttendance : 'titolare',
			report: null,
			aggregated: this.configWidget && this.configWidget.orderByDate ? this.configWidget.orderByDate : false,
			selected_competizioni: this.configWidget && this.configWidget.selectedCompetitions ? this.configWidget.selectedCompetitions : [],
			ux: {
				hideDisabled: false,
			},
			hidePreset: null,
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		giocatoriNascosti: function() {
			return _.map(_.values(_.groupBy(this.report, 'persona_id')), 0).length - this.dataset.length;
		},
		persone: function() {
			return _.map(_.values(_.groupBy(this.report, 'persona_id')), 0);
		},
		types: function() {
			return [
				{ label: 'Titolare', value: 'titolare' },
				{ value: 'minuti45Min', label: 'Presenza almeno 45m' },
				{ value: 'minuti30Min', label: 'Presenza almeno 30m' },
				{ value: 'minuti15Min', label: 'Presenza almeno 15m' },
				{ value: 'titolareMinuti45Min', label: '45 minuti o Titolare' },
				{ value: 'convocato', label: 'Convocato' }
				];
		},
		titles: function() {
			return {
				titolare: 'Totale Titolare',
				minuti45Min: 'Totale Minuti Giocati >= 45',
				minuti30Min: 'Totale Minuti Giocati >= 30',
				minuti15Min: 'Totale Minuti Giocati >= 15',
				titolareMinuti45Min: 'Totale Titolare e Minuti Giocati >= 45',
				convocato: 'Totale Convocazioni'
			}
		},
		totField: function() {
			return 'tot_' + this.type;
		},
		columns: function() {
			if(!this.dataset || this.dataset.length == 0) return [];
			return _.first(this.dataset).matches;
		},
		competizioni: function() {
			return _.keys(_.groupBy(this.report, 'competizione_nome'));
		},
		competizioniColore: function() {
			return _.keys(_.groupBy(this.report, 'competizione_colore'));
		},
		dataset: function() {
			var base = _.filter(this.report, function(a) {
				if(this.ux.hideDisabled == false) {
					return this.hidePreset.indexOf(a.persona_id) == -1 && 
					this.selected_competizioni.indexOf(a.competizione_nome) !== -1;
				} else 
				return this.selected_competizioni.indexOf(a.competizione_nome) !== -1;
			}.bind(this));

			return _.map(_.groupBy(base, 'persona_id'), function(item) {
				var matches = _.flatten(_.map(item, 'matches'));
				if(this.aggregated == true)
					matches = _.orderBy(matches, ['match_date'], ['asc']);
				return {
					player: _.first(item).player,
					persona_id: _.first(item).persona_id,
					tot_titolare: _.sum(_.map(item, 'tot_titolare')),
					tot_panchina: _.sum(_.map(item, 'tot_panchina')),
					tot_convocato: _.sum(_.map(item, 'tot_convocato')),
					tot_minuti: _.sum(_.map(item, 'tot_minuti')),
					tot_minuti15Min: _.sum(_.map(item, 'tot_minuti15Min')),
					tot_minuti30Min: _.sum(_.map(item, 'tot_minuti30Min')),
					tot_minuti45Min: _.sum(_.map(item, 'tot_minuti45Min')),
					tot_titolareMinuti45Min: _.sum(_.map(item, 'totTitolareMinuti45Min')),
					matches: matches
				};

			}.bind(this));

		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId: {
			immediate: true,
			handler: function() {
				this.fetchDataset();
			}
		},
		seasonId: function() {
			this.fetchDataset();
		},
		report: function() {
			if(this.selected_competizioni.length == 0) {
				this.selected_competizioni = _.uniq(_.map(this.report, 'competizione_nome'));
			}
		},
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},
		type: function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'typeAttendance', this.type);
			}
		},
		aggregated: function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'orderByDate', this.aggregated);
			}
		},
		selected_competizioni: function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'selectedCompetitions', this.selected_competizioni);
			}
		},
	},
	created: function() {
		if(this.isWidget) {
			if(this.configWidget && this.configWidget.hidePlayers) {
				this.hidePreset = this.configWidget.hidePlayers;
			} else {
				this.hidePreset = [];
			}
		} else if(this.auth.presets().matchesAttendanceHide != undefined) {
			this.hidePreset = _.cloneDeep(this.auth.presets().matchesAttendanceHide)
		} else {
			this.hidePreset = [];
		}
		this.fetchDataset();
	},
	methods: {
		refreshData: function() {
			this.fetchDataset();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		svuotaPersone: function() {
			this.hidePreset = [];
			this.savePersone(this.hidePreset);
		},
		savePersone: function(persone) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'hidePlayers', persone);
				this.ux.hideDisabled = false;
			} else {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'matchesAttendanceHide', persone: persone }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.ux.hideDisabled = false;
				});
			}
		},
		print: function() {
			var competizioniId = _.map(this.selected_competizioni, function(a) {
				return _.find(this.report, { competizione_nome: a }).competizione;
			}.bind(this));
			var urlPrint = [];
			urlPrint.push( this.rosaTeamId + '/' );
			urlPrint.push( this.seasonId + '/' );
			urlPrint.push( this.type + '/' );
			urlPrint.push( competizioniId.join(',') + '/' );
			urlPrint.push( this.aggregated + '/' );
			urlPrint.push( this.user.id + '/' );
			urlPrint.push( this.user.api_token );
			if(this.hidePreset.length > 0) {
				urlPrint.push('?hide_players=' + this.hidePreset);
			}
			window.open(this.$store.state.apiEndPoint + '/report/download/players/matches-attendance/' + urlPrint.join(''));
		},
		fetchDataset: function() {
			this.report = null;
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/report/attendances/players/matches', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then(function(data) {
				this.report = data.data;
				this.loading = false;
			});
		},
	}
};

</script> 
<style>
.tooltipClassHeader {
	text-align: center;
	min-width:250px;
}

.tooltipClassCell {
	text-align: center;
	font-size: 0.9rem !important;
	min-width:250px;
}
</style>   
