<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">

					<div class="row flex-items-xs-middle">
						<div class="col-4">

							<div class="text-uppercase color-rosso font-bold font-size-lg">
								{{osservato.giocatore.cognome}} {{osservato.giocatore.nome}}
							</div>
							<div class="text-muted">
								{{osservato.giocatore.ruolo_ideale && osservato.giocatore.ruolo_ideale.length > 0 ? osservato.giocatore.ruolo_ideale[0].ruolo : ''}}
							</div>

						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Appunti ({{ totalRows }})</span>
					</div>
					<div class="content">
						<b-row class="mb-3" align-v="center">
							<b-col md="2">
								<b-button variant="primary" @click="nuovoAppunto">Nuovo Appunto</b-button>	
							</b-col>
						</b-row>
						<!-- Main table element -->
						<b-table show-empty
						ref="appuntiTable"
						stacked="md"
						:items="appunti"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						>
						<template #cell(gradimento)="data">
						 <b-badge v-if="data.value" :variant="'bg-' + data.value.id" :style="normale_style">
						 {{ data.value.nome }}
						</b-badge>
						</template>
						<template #cell(created_at)="data">{{ data.value | formatDate }}</template>
						<template #cell(utente)="data">{{ data.value.cognome }}</template>
						<template #cell(partite)="data">
							<div v-for="item in data.value">
								{{ getLabelPartita(item.partita) }}, {{ item.partita.data | formatDateHour }}
							</div>
						</template>
						<template #cell(azioni)="row">
							<b-button size="sm" @click.stop="editAppunto(row.item, row.index, $event.target)" class="mr-1" variant="primary">
								Edit <i class="fa fa-pencil" aria-hidden="true"></i>
							</b-button>
							<b-button size="sm" @click.stop="eliminaAppunto(row.item.id)" class="mr-1">
								Canc <i class="fa fa-trash" aria-hidden="true"></i>
							</b-button>		
						</template>
					</b-table>

					<b-row>
						<b-col md="6" class="my-1">
							<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
						</b-col>
					</b-row>
					<b-modal id="appuntiModal" ref="appuntiModal" :title="form.id ? 'Modifica appunto' : 'Nuovo appunto'" ok-only ok-title="Chiudi">	
						<b-row class="mb-2">
							<b-col cols="12" class="my-2">
								<p>Inserisce l'appunto:</p>
								<b-form-textarea
								id="textarea"
								v-model="form.note"
								rows="4"
								max-rows="6"
								></b-form-textarea>
							</b-col>
							<b-col cols="12" class="my-2">
								<p>Seleziona un gradimento:</p>
								<b-form-radio-group
								buttons
								v-model="form.gradimento"	
								size="sm">
								<b-form-radio @click.native="selectGradimento(grad.id)" v-for="grad in gradimenti" :value="grad.id" :class="(grad.id == form.gradimento ? 'btn-grad-active' : 'btn-grad')" :style="'background-color:' + (grad.id == form.gradimento ? grad.colore : grad.colore + '7F')">{{grad.nome}}</b-form-radio>
							</b-form-radio-group>
						</b-col>
						<b-col cols="12" class="my-2"><p>Seleziona una o più partite a cui associare l'appunto:</p></b-col>
						<b-col cols="12">
							<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
							<p v-if="!is_search && !matches.length">Nessuna partita trovata!</p>
							<div v-if="!is_search && matches.length">
								<p>Trovate {{matches.length}} partite!</p>
							</div>
							<b-row style="max-height: 200px; overflow: auto">
								<b-col cols="12" v-for="match in matches">
									<b-form-checkbox v-model="form.partite" :value="match.matchId">
										{{ match.label }} - {{ match.date | formatDateHour }}
									</b-form-checkbox>
								</b-col>
							</b-row>	
						</b-col>
					</b-row>
					<b-row class="mt-3">
					<b-col cols="2">					
					<b-button variant="primary" @click.prevent="salvaAppunto">Salva</b-button>
					</b-col>
					<b-col>		
					<div v-if="saving">Salvataggio in corso...<img src="/assets/varie/loading.gif" style="width: 40px;"/></div>
					</b-col>
					</b-row>	
				</b-modal>
			</div>
		</div>
	</div>	
</div>

<footerapp></footerapp>

</section>
</template>

<script>

var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import moment from 'moment'
import axios from 'axios';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	data: function(){
		return {
			osservato: [],
			matches: [],
			gradimenti: [],
			is_search: false,
			form: {
				id: null,
				note: null,
				gradimento: null,
				partite: [],
				osservato: null,
			},	
			fields: [
			{ key: 'created_at', label: 'Data', 'class': 'text-center' },
			{ key: 'utente', label: 'Utente' },
			{ key: 'gradimento', label: 'Gradimento' },
			{ key: 'partite', label: 'Partite' },
			{ key: 'note', label: 'Note' },
			{ key: 'azioni', label: 'Azioni' }
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			saving: false,
		}
	},

	computed: {
		user: function() {
			return Auth.user;
		},
	},
	created() {
		this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
			if (response.ok)
			{
				this.gradimenti = response.data;    
			}
		}, (response) => {
		});
		this.$http.get(this.$store.state.apiEndPoint + '/scouting/giocatore/' + this.$route.params.id + '/old').then((response) => {
			if (response.ok)
			{
				this.osservato = response.data;
			}
		}, (response) => {
		});
	},
	methods: {
		nuovoAppunto: function() {
			this.form.id = null;
			this.form.osservato = this.osservato.id;
			this.form.note = null;
			this.form.gradimento = null;
			this.form.partite = [];
			if(this.matches.length == 0 && this.osservato.giocatore.wyscout_id) {
				this.searchPartita();
			}
			this.$refs.appuntiModal.show();
		},
		editAppunto: function(appunto) {
			this.form.id = appunto.id;
			this.form.osservato = this.osservato.id;
			this.form.note = appunto.note;
			this.form.gradimento = appunto.id_gradimento;
			this.form.partite = [];
			for(var i in appunto.partite) {
				this.form.partite.push(appunto.partite[i].partita.wyscout_id);
			}
			if(this.matches.length == 0 && this.osservato.giocatore.wyscout_id) {
				this.searchPartita();
			}
			this.$refs.appuntiModal.show();
		},
		salvaAppunto: function() {
			this.saving = true;
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/appunto', this.form).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.saving = false;
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            	this.$refs.appuntiTable.refresh();
            	this.$refs.appuntiModal.hide();   	
            }
        }, (response) => {  
        	if (response.status == 422) {
        		this.errors = response.data;
        		for (var index in this.errors) {
        			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        		}
        	}
        });
		},
		eliminaAppunto(id) {
			if(confirm("Confermi l'eliminazione dell'appunto ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/appunto/' + id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$refs.appuntiTable.refresh();			
					}
				}, (response) => {
				});
			}
		},
		searchPartita: function() {
			var wsId = this.osservato.giocatore.wyscout_id;
			if(wsId != "" && wsId != 0 && wsId != null) {
				this.matches = [];
				this.is_search = true;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/matches/by/player/' + wsId).then((response) => {

					if (response.ok)
					{
						this.matches = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			} else {
				alert("Nessuna partita disponibile!");
			}
		},
		appunti(ctx) {
			return axios.get('/scouting/appunti/' + this.$route.params.id, {
				params: {
					page: ctx.currentPage,
					per_page: ctx.perPage || 15,
				},
			}).then((res) => {
				if (!res.data) return [];

				const data = res.data.data || [];
				this.totalRows = res.data.total;
				this.currentPage = res.data.current_page;

				return data;
			}).catch(() => []);
		},
		selectGradimento(id) {
			this.form.gradimento = id;
		},
		getLabelPartita(partita) {
			var label = '';
			var squadre = partita.squadre;
			if(squadre[0].pivot.side == 'home') {
				label = squadre[0].officialName + " - " + squadre[1].officialName;
			} else {
				label = squadre[1].officialName + " - " + squadre[0].officialName;
			}
			return label;
		},	
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		}
	},
	watch: {
	}

}
</script>
