<template>

	<b-card class="mb-2" :border-variant="!gruppo.id ? 'success' : ''">

		<div v-if="gruppo.id">
			<b-row>
				<b-col>
					{{ getGruppo(gruppo.id_gruppo).nome }}
				</b-col>
				<b-col v-if="gruppo.categoria_id">
					<b-badge>{{ gruppo.categoria_id }}</b-badge>
				</b-col>
				<b-col v-else class="text-right">
					<em class="text-muted">Nessuna Categoria</em>
				</b-col>
			</b-row>

			<div v-if="ux.show">
				<b-badge v-for="permesso in permessi" class="mr-1">{{ permesso.permesso }}</b-badge>
				
				<div>
					<a href="" @click.prevent="ux.show = false">Nascondi Permessi</a>
				</div>
			</div>
			<div v-else>
				<a href="" @click.prevent="ux.show = true">Mostra Permessi</a>
			</div>

		</div>
		<div v-else>
			
			<b-form-group label="Gruppo">
				<b-form-select v-model="gruppo.id_gruppo">
					<b-form-select-option :value="null" disabled>Seleziona Gruppo</b-form-select-option>
					<b-form-select-option v-for="gruppo in catalogo.gruppi" :value="gruppo.id">{{gruppo.nome}}</b-form-select-option>
				</b-form-select>
			</b-form-group>

			<b-form-group label="Categoria">
				<b-form-select v-model="gruppo.categoria_id">
					<b-form-select-option :value="null">- Nessuna Categoria -</b-form-select-option>
					<b-form-select-option v-for="categoria in catalogo.categorie" :value="categoria.id">{{categoria.name}}</b-form-select-option>
				</b-form-select>
			</b-form-group>

		</div>



		<template #footer v-if="!gruppo.id">
			<b-button size="sm" variant="outline-success" :disabled="!gruppo.id_gruppo" @click.prevent="$emit('newGruppo', gruppo)">Salva</b-button>
		</template>
		<template #footer v-else-if="ux.action == null">
			<b-button-group size="sm">
				<b-button variant="outline-secondary" @click.prevent="ux.action = 'edit'">Modifica</b-button>
				<b-button variant="outline-secondary" @click.prevent="ux.action = 'copy'">Copia</b-button>
				<b-button variant="outline-secondary" @click.prevent="$emit('removeGruppo', gruppo)">Rimuovi</b-button>
			</b-button-group>
		</template>
		<template #footer v-else-if="ux.action == 'edit'">
			<strong>Modifica</strong>
			<div>
				
				<b-form-select v-model="gruppo.categoria_id">
					<b-form-select-option :value="null">Nessuna Categoria!</b-form-select-option>
					<b-form-select-option :value="category.id" v-for="category in catalogo.categorie">{{category.name}}</b-form-select-option>
				</b-form-select>

			</div>
			<b-button-group size="sm">
				<b-button @click.prevent="$emit('updateGruppo', gruppo)">Salva</b-button>
				<b-button @click.prevent="ux.action = null">Annulla</b-button>
			</b-button-group>
		</template>
		<template #footer v-else-if="ux.action == 'copy'">
			<strong>Copia Gruppo sulla categoria</strong>
			<div>
				<b-form-select v-model="ux.copyCategory">
					<b-form-select-option :value="null">Nessuna Categoria!</b-form-select-option>
					<b-form-select-option :value="category.id" v-for="category in catalogo.categorie">{{category.name}}</b-form-select-option>
				</b-form-select>
			</div>
			<b-button-group size="sm">
				<b-button @click.prevent="newGruppo">Salva</b-button>
				<b-button @click.prevent="ux.action = null">Annulla</b-button>
			</b-button-group>
		</template>
	</b-card>

</template>

<script>

import pageBase from '@/mixins/pageBase.js';

export default {
	mixins: [ pageBase ],
	props: [ 'catalogo', 'chiave', 'gruppo', 'permessi' ],
	components: {
	},
	data: function() {
		return {
			ux: {
				copyCategory: null,
				action: null,
				show: false
			}
		}
	},
	mounted: function() {

	},
	methods: {
		newGruppo: function() {

			var base = _.clone(this.gruppo);

			delete base.id;
			if(base.id_utente)
				delete base.id_utente;
			base.categoria_id = this.ux.copyCategory;

			this.$emit('newGruppo', _.clone(base));

			this.ux.show = false;
			this.copyCategory = null;
			this.action = null;

		},
		getGruppo: function(id) {
			return _.find(this.catalogo.gruppi, {id});
		}
	}
}

</script>