<template>
  <div :class="['thumbnail-wrapper', tag.peso]" @click="selectTag">
    <div class="thumbnail-top">
      <div class="action-number">{{ count }}</div>
      <div class="action-player">{{ tag.video.utente ? tag.video.utente.nomeCompleto : '' }}</div>
      <div class="action-actions" v-if="auth.isAdmin()">
        <a href="#" @click.prevent="deleteTag()"><i class="fa fa-times thumbnail-close" aria-hidden="true"></i></a>
      </div>
    </div>
    <b-img :src="thumbnail" center fluid-grow />
    <div class="thumbnail-time">{{ tag.inizio }}</div>
    <div class="thumbnail-bottom">
      <div class="action-wrapper">
        <div v-if="tag.tipo" :class="['action-type', `type-${tag.tipo}`]">{{ typeSubstring }}</div>
        <div class="field-section" v-if="tag.dove">{{ tag.dove }}</div>
      </div>
      <div class="skill-name"><div class="content">{{ skillName }}</div></div>
      <div class="action-actions">
        <b-btn variant="link" size="sm" v-b-tooltip.hover :title="tag.nota"><i class="fa fa-info-circle" aria-hidden="true"></i></b-btn>
      </div>
    </div>
  </div>
</template>

<script>

var Auth = require('../../auth.js');

export default {
  name: 'thumbtag',
  props:{
    tag: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
  },

  computed: {
    auth: function() {
      return Auth;
    },
    typeSubstring() {
      return this.tag.tipo.substring(0, 3);
    },
    thumbnail() {
      if(this.tag.thumbnail) {
          return this.$store.state.apiEndPoint + '/videotag/thumbnail/' + this.tag.id;
      } else return "/assets/varie/missing.jpg";
    },
    skillName() {
      if(this.tag.azioni) {
        var nome = "";
        for(var i in this.tag.azioni) {
          var azione = this.tag.azioni[i];
          if(nome != "") {
            nome += " - ";
          }
          nome += azione.nome_azione;
        }
        return nome;
      }
      return "Azione generica";
    },
  },

  methods: {
    selectTag() {
      this.$emit('selectTag', this.tag, this.count);
    },
    deleteTag() {
      this.$emit('deleteTag', this.tag);
    }
  },
}
</script>

<style lang="scss" scoped>
$background-default:  rgba(0, 0, 0, 0.7);
$background-negativo: rgba(166, 15, 33, 0.7);
$background-positivo: rgba(55, 128, 70, 0.7);

$default-padding: 5px;

.thumbnail-wrapper {
  border-radius: 5px;
  position: relative;
  color: white;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 5px;
  cursor: pointer;

  img {
    border-radius: 5px;
  }

  a, a:hover {
    color: white;
  }

  .fa {
    font-size: 16px;
  }

  .thumbnail-top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $default-padding;
    position: absolute;
    width: 100%;
    top: 0;
    background-color: $background-default;
  }

  .thumbnail-time {
    border-radius: 5px;
    position: absolute;
    top: 43%;
    left: 10px;
    background-color: white;
    padding: $default-padding 10px;
    color: #212529;
  }

  .thumbnail-bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $default-padding;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $background-default;
  }

  .action-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .action-player, .skill-name {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .content {
    width: 150px;
    word-wrap: break-word;
    margin-left: 70px;
  }

  .action-number {
    background-color: black;
    border-radius: 5px;
    padding: 5px 10px;
    z-index: 1000;
  }

  .action-actions {
    z-index: 1000;

    a {
      padding: $default-padding;

      .thumbnail-close {
        font-size: 20px;
      }
    }
  }

  .action-type {
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
    margin-right: 5px;
    z-index: 1000;

    &.type-offensivo {
      background-color: #D3B826;
    }

    &.type-difensivo {
      background-color: #D3800B;
    }
  }

  .field-section {
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #a2a321;
    z-index: 1000;
  }

  &.negativo {
    .thumbnail-top, .thumbnail-bottom {
      background-color: $background-negativo;
    }
  }

  &.positivo {
    .thumbnail-top, .thumbnail-bottom {
      background-color: $background-positivo;
    }
  }
}
</style>

<style lang="scss">
.modal .modal-dialog {
  color: #212529;

  .custom-control-description {
    font-size: 15px;
  }
}

.list-group {
  font-size: 14px;
}
</style>
