<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<b-overlay :show="loading" no-wrap></b-overlay>
			<b-card :title="'Duplicati (' + Object.keys(duplicati).length + ')'" title-tag="span">
				<b-card-body class="px-0">
					<div class="d-flex align-items-center mb-2">
						<b-form-radio-group v-model="skip_nascita_data">
							<b-form-radio value="0">Data di nascita esatta</b-form-radio>
							<b-form-radio value="1">Ignora data di nascita</b-form-radio>
							<b-form-radio value="2">Anno di nascita</b-form-radio>
						</b-form-radio-group>
					</div>
					<b-row>
						<b-col cols="3" v-for="gruppo, key in duplicati" class="mb-1">
							<b-card no-body class="h-100 p-1">
								<div style="cursor:pointer;" 
								@click="getInfoDuplicati(key, gruppo.map(function (item) { return item.id;}))">
								{{ key }} <span v-if="skip_nascita_data"><span v-for="item2, index in gruppo">{{ item2.nascita_data }}{{ index < gruppo.length - 1 ? '_' : ''}}</span></span> ({{ gruppo.length }})</div>
							</b-card>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
			<b-modal ref="infoModal" :title="titleModal" size="full" ok-only>
				<b-row>
					<b-col v-for="persona in dettaglio_duplicati" :cols="master ? 4 : 6" class="mb-1" :key="persona.id">
						<cardinfo :persona="persona">
							<template slot="selected">
								<b-col cols="1" class="text-center">
									<b-form-radio v-if="!persona.is_master" :value="persona.id" name="persona_master" v-model="master_id" :disabled="persona.is_master">
									</b-form-radio>
								</b-col>	
							</template>
						</cardinfo>
					</b-col>
				</b-row>
				<b-button variant="danger" class="mt-1" @click.prevent="accorpaDuplicati()">{{ master && master.is_master ? 'Salva modifiche' : 'Accorpa duplicati' }}</b-button>
			</b-modal>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';
import _ from 'lodash';
export default {

	data: function() {
		return {
			loading: false,
			duplicati: [],
			dettaglio_duplicati: [],
			persone_ids: null,
			titleModal: null,
			master_id: null,
			master: null,
			skip_nascita_data: 0,
		}
	},

	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		cardinfo: require('@/components/layout/persona/card_info.vue').default,
	},

	created: function () {   
		this.getDuplicati();
	},

	watch: {
		skip_nascita_data: function () {
			this.getDuplicati();
		}
	},

	methods: {
		getDuplicati() {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/persona/duplicati', {params: {skip_nascita_data: this.skip_nascita_data}}).then(function(res) {
				this.duplicati = res.data;
				this.loading = false;
			});
		},

		getInfoDuplicati(key, ids) {
			this.persone_ids = ids;
			this.master_id = null;
			this.master = null;
			this.$http.get(this.$store.state.apiEndPoint + '/persone/info/' + ids).then(function(res) {
				this.dettaglio_duplicati = res.data;
				this.titleModal = key;
				this.$refs.infoModal.show();
			});
		},

		accorpaDuplicati() {
			if(!this.master_id) {
				alert("Devi selezionare la persona 'Master' su cui accorpare i dati!");
				return;
			}
			var action = 'test';
			if(this.master && this.master.is_master) {
				action = 'save';
			}
			var msg = action == 'test' ? 'Confermi di voler accorpare questi duplicati ?' : 'Vuoi rendere definitive le modifiche ?';
			if(confirm(msg)) {
				this.$http.get(this.$store.state.apiEndPoint + '/persona/duplicati/accorpa/' + this.persone_ids + '/' + this.master_id + '/' + action).then(function(res) {
					if(action == 'test') {
						this.master = res.data;
						this.master.is_master = true;
						this.dettaglio_duplicati.unshift(this.master);
					} else if(action == 'save') {
						this.$store.commit(types.ALERTS_PUSH, { msg: 'Anagrafiche accorpate con successo!', status: 1 });
						this.getDuplicati();
						this.$refs.infoModal.hide();
					}
				});
			}
		},
	},
}
</script>
