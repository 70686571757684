<template>
	<div>
		<b-modal size="xl" :visible="true" @hide="$emit('hide')" ref="modal">
			<template #modal-footer>
				<b-button @click.prevent="$refs.modal.hide()">Chiudi</b-button>
			</template>
			<b-row>
				<b-col cols="2">
					ZONA CORPO
				</b-col>
				<b-col cols="10">
					
					<b-row class="d-flex align-items-center">
						<b-col v-if="detail.recovery_days">
							<div class="small text-muted">Giorni Recupero</div>
							<div>{{ detail.recovery_days }}</div>
						</b-col>
						<b-col v-else class="text-center">
							<div class="lead text-danger">ATTIVO</div>
						</b-col>
						<b-col>
							<div class="small text-muted">Data</div>
							<div>
								{{ detail.date | date }}
								<span class="small text-muted">({{ detail.date | ago }})</span>
							</div>
							<div v-if="detail.recurring == true" class="mt-2 d-flex align-items-center">
								<div style="width: 10px; height: 10px; border-radius: 100%; background-color: orange"></div>
								<div class="ml-1">Ricorrente</div>
							</div>
						</b-col>
						<b-col>
							<div class="small text-muted">Match Saltati</div>
							<div>{{ detail.missed_matches }}</div>
						</b-col>
					</b-row>

					<hr />

					<b-row>
						<b-col>
							<div class="small text-muted">Tipo</div>
							<div>{{ detail.injury_type.replace('|', ' | ') }}</div>
						</b-col>
						<b-col>
							<div class="small text-muted">Parte del corpo</div>
							<div>
								{{ detail.injuried_part }}
								({{ detail.side }})
							</div>
						</b-col>
						<b-col>
							<div class="small text-muted">Description</div>
							<div>{{ detail.description }}</div>
						</b-col>
						<b-col>
							<div class="small text-muted">Trauma</div>
							<div>{{ detail.trauma }}</div>
						</b-col>
					</b-row>

					<b-card v-if="detail.clinic || detail.doctor" class="mt-3">
						<template #header>
							<div>Intervento</div>
						</template>
						<b-row>
							<b-col>
								<div class="small text-muted">Clinica</div>
								<div>{{ detail.clinic }}</div>
							</b-col>
							<b-col>
								<div class="small text-muted">Dottore</div>
								<div>{{ detail.doctor }}</div>
							</b-col>
						</b-row>
					</b-card>

					<hr />

					<b-card border-variant="primary" v-if="detail.game_context">
						<b-row class="d-flex align-items-center">
							<b-col :cols="2" class="text-center">
								{{ detail.where }}
							</b-col>
							<b-col class="text-center">
								<p class="lead m-0">{{ detail.game_context }}</p>
							</b-col>
							<b-col v-if="detail.match" :cols="5">
								Minuti Giocati: <strong>{{ detail.match.minutesPlayed }}</strong>
								<b-progress :value="detail.match.minutesPlayed" :max="detail.match.duration"></b-progress>
							</b-col>
						</b-row>
					</b-card>
					<span v-else class="text-muted">L'infortunio non è avvenuto durante una partita.<span v-if="detail.where !== 'none'"> {{ detail.where }}</span></span>

					<div v-if="detail.media.length > 0">
						
						<div v-for="media in detail.media">
							<video style="width: 100%" class="mt-2" muted controls>
								<source :src="media" type="video/mp4">
							</video>
						</div>

					</div>
					<div v-else class="my-3 text-muted">Non ci sono video disponibili per l'infortunio.</div>


					<div v-if="detail.news && detail.news.length">
						<hr />
						<strong>Notizie legate all'inforunio</strong>
						<b-list-group class="mt-1">
							<b-list-group-item v-for="news in detail.news" :href="news.details.link" target="_blank">
								<div class="text-muted small">{{ news.date | date('DD/MM/YYYY HH:mm') }}</div>
								{{ news.message }}
							</b-list-group-item>
						</b-list-group>
					</div>

				</b-col>
			</b-row>

		</b-modal>
	</div>
</template>

<script>

var Auth = require('@/auth.js');
import moment from 'moment';
import videojs from 'video.js';
import _ from 'lodash';

export default {
	props: [ 'detail' ],
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY');
		},
		ago: function(a) {
			return moment(a).fromNow();
		}
	}
}

</script>