<template id="rosa">
	<section class="rosa">

		<headapp></headapp>

		<div class="card">
			<div class="card-body">
				<div class="content">
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					<div v-if="!loading">
						<div class="card-title d-flex">
							<span class="mr-auto">Giocatori in prova ({{ giocatori.length }})</span>
						</div>
						<div>
							<b-row>
								<b-col>
									<b-form-group
									label="Dal" horizontal>
									<b-form-input type="date" v-model="filters.inizio"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group
								label="Al" horizontal>
								<b-form-input type="date" v-model="filters.fine"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<multiselect 
							v-model="filters.rose"
							:multiple="true"
							:options="rose"
							placeholder="-- Rose --"
							label="name"
							track-by="id"/>
						</b-col>
						<b-col>
							<b-form-select v-model="filters.anno" :options="anni">
							</b-form-select>
						</b-col>
						<b-col cols="1">
							<b-button variant="primary" @click="getGiocatoriInProva">Filtra</b-button>
						</b-col>
					</b-row>
					<table class="table table-striped">
						<thead>
							<th>Cognome</th>
							<th>Nome</th>
							<th class="text-center">Anno di nascita</th>
							<th class="text-center">Data inizio prova</th>
							<th class="text-center">Data fine prova</th>
							<th class="text-center">Rosa</th>
						</thead>
						<tbody>
							<tr v-for="item in giocatori">
								<td style="cursor:pointer;" @click="viewScheda(item)">{{ item.cognome }} </td>
								<td> {{ item.nome }} </td>
								<td class="text-center">{{ item.nascita_data | formatYear }}</td>
								<td class="text-center"> {{ item.documenti.length > 0 ? getFormatDate(item.documenti[0].documento.emissione) : ''}} </td>
								<td class="text-center"> {{ item.documenti.length > 0 ? getFormatDate(item.documenti[item.documenti.length - 1].documento.scadenza) : ''}} </td>
								<td class="text-center"> {{ getRose(item.carriere_sportive) }} </td>	
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<footerapp></footerapp>

</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapState } from 'vuex';
var Auth = require('../../../auth.js');
export default {
	data: function(){
		return {
			giocatori: [],
			loading: false,
			errors: [],
			filters: {
				inizio: null,
				fine: null,
				anno: null,
				rose: [],
			}
		}
	},
	computed: {
		user: function() {
			return Auth.user;
		},
		anni: function() {
			var options = [];
			options.push({ value: null, text: '-- Anno di nascita --' });
			var anno = moment().subtract(25, 'years').format('YYYY'); 
			for(var i = 0; i <= 21; i++) {
				options.push({ value: anno, text: anno });
				anno++;
			}
			return options;
		},
		rose: function() {
			var rose = [];
			for(var i in this.user.rosaTeams) {
				rose.push({id: this.user.rosaTeams[i].id, name: this.user.rosaTeams[i].categoria.name });
			}
			return rose;
		}
	},
	watch: { 
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},
	created: function () {
		this.getGiocatoriInProva();
	},
	methods: {
		getGiocatoriInProva() {
			this.loading = true;
			axios.get(`/rosa/in/prova`, { params: this.filters })
			.then((res) => {
				this.giocatori = res.data;
				this.loading = false;
			}).catch((err) => {
			});
		},
		viewScheda(player) {
			this.$store.commit('changeRosaTeamId', player.last_carriera_sportiva.squadra_id);
			this.$store.commit('changeSeasonId', player.last_carriera_sportiva.stagione_id);
			if(player.last_carriera_sportiva.squadra.categoria.name != 'In prova') {
				this.$store.commit('changeInProva', false);
			}
			this.$router.push({ name: "football_giocatore_scheda", params: { id: player.id} });	
		},

		getFormatDate(date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},

		getRose(carriera) {
			var array_ids = [];
			var rose_label = "";
			for(var i in carriera) {
				var item = carriera[i];
				if(array_ids.indexOf(item.squadra_id) == -1) {
					array_ids.push(item.squadra_id);
					if(rose_label != "") {
						rose_label += ', ';
					}
					rose_label = rose_label += item.squadra.categoria.name; 
				}
			}
			return rose_label;
		}
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
	},
}
</script>
