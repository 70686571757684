import moment from 'moment';

export default {
  computed: {
    theGlossario: function() {

      return _.mapValues(_.groupBy(this.glossario, 'faseType'), function(f) {
        return _.flatten(_.map(_.flatten(_.map(f, 'items')), 'items'));
      });

    }
  }
};