export default {
	components: {
		languageselector: require('@/components/layout/multilingua/language-selector.vue').default,
		textarealingua: require('@/components/layout/multilingua/textarea.vue').default,
	},
	methods: {
		fetchLingue: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/lingue').then(function(data) {
				this.lingue = data.data;
			});
		},
	}
};