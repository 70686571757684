import axios from 'axios';
import { pick, omit, get } from 'lodash';

import { ALERTS_PUSH } from '../store/types';

export default {
  /**
   * Retrieve the data from the API
   *
   * @param {function} commit
   */
  retrieveData({ commit }) {
    axios.get('/')
      .then(res => commit('updateData', res.data))
      .catch(err => commit('updateError', err));
  },

  /**
   * Calls the API to save the new tag
   *
   * @param {Object} newTag
   * @param {Function} commit
   */
  saveTag({ state, commit, getters }, notifications) {
    commit('updateTagField', { key: 'saving', value: true });

    commit(ALERTS_PUSH, { status: 1, msg: 'Creo il tag...' });

    const path = state.newTag.editing ? `/${state.newTag.id}` : '';

    axios({
      method: state.newTag.editing ? 'put' : 'post',
      url: `/tag${path}`,
      data: {
        ...omit(state.newTag, ['skill_id', 'saving', 'saved', 'editing']),
        skill_id: state.newTag.skill ? state.newTag.skill.id : null,
        [state.newTag.team ? 'team_id' : (state.newTag.coach ? 'coach_id' : 'player_id')]: state.newTag.team ? state.newTag.team.id : (state.newTag.coach ? state.newTag.coach.id : state.newTag.player.id),
        video_id: state.data.video.id,
        private_tag: state.privateTag ? 1 : 0,
      },
    }).then((res) => {
      const tag = res.data;

      if (res.data.result) {
        tag.result = res.data.result === 'positive' ? 'positive' : 'negative';
      }
      if (res.data.type) {
        tag.type = res.data.type === 'offensive' ? 'offensive' : 'defensive';
      }
      if (res.data.skill_id) {
        tag.skill = getters.skills.find(s => s.id === res.data.skill_id);
      }
      if (res.data.player_id) {
        const player = getters.players.find(p => p.id === res.data.player_id);
        tag.player = pick(player, ['id', 'name']);
      } else if (res.data.team_id) {
        const team = getters.teams.find(t => t.id === res.data.team_id);
        tag.team = pick(team, ['id', 'name']);
      } else if (res.data.coach_id) {
        const coach = getters.coaches.find(c => c.id === res.data.coach_id);
        tag.coach = pick(coach, ['id', 'name']);
      }
      commit('tagSaved', tag);
      commit(ALERTS_PUSH, { status: 1, msg: 'Tag creato!' });
    }).catch(err => {
      commit(ALERTS_PUSH, { status: 0, msg: 'Qualcosa è andato storto, prova a ricaricare la pagina' })
      commit('cancelNewTag');
    });
  },

  /**
   * Calls the API to save the new tag goal
   *
   * @param {Object} newTag
   * @param {Function} commit
   */
  saveTagGoal({ state, commit, getters }, notifications) {
    commit('updateTagField', { key: 'saving', value: true });

    commit(ALERTS_PUSH, { status: 1, msg: 'Creo il tag goal...' });

    axios({
      method: 'post',
      url: '/tag/goal',
      data: {
        ...omit(state.newTag, ['skill_id', 'saving', 'saved', 'editing']),
        skill_id: null,
        [state.newTag.team ? 'team_id' : 'player_id']: state.newTag.team ? state.newTag.team.id : state.newTag.player.id,
        video_id: state.data.video.id,
      },
    }).then((res) => {


      const tag = res.data;

      if (res.data.result) {
        tag.result = res.data.result === 'positive' ? 'positive' : 'negative';
      }
      if (res.data.type) {
        tag.type = res.data.type === 'offensive' ? 'offensive' : 'defensive';
      }
      if (res.data.player_id) {
        const player = getters.players.find(p => p.id === res.data.player_id);
        tag.player = pick(player, ['id', 'name']);
      } else if (res.data.team_id) {
        const team = getters.teams.find(t => t.id === res.data.team_id);
        tag.team = pick(team, ['id', 'name']);
      }
      commit('tagSaved', tag);
      commit(ALERTS_PUSH, { status: 1, msg: 'Tag goal creato!' });
      if(tag.assist) {
        const assist = tag.assist;
        if (tag.assist.result) {
          assist.result = tag.assist.result === 'positive' ? 'positive' : 'negative';
        }
        if (tag.assist.type) {
          assist.type = tag.assist.type === 'offensive' ? 'offensive' : 'defensive';
        }
        if (tag.assist.player_id) {
          const player = getters.players.find(p => p.id === tag.assist.player_id);
          assist.player = pick(player, ['id', 'name']);
        } else if (tag.assist.team_id) {
          const team = getters.teams.find(t => t.id === tag.assist.team_id);
          assist.team = pick(team, ['id', 'name']);
        }
        commit('tagSaved', assist);
        commit(ALERTS_PUSH, { status: 1, msg: 'Tag assist creato!' });
      }
    }).catch(err => {
      commit(ALERTS_PUSH, { status: 0, msg: 'Qualcosa è andato storto, prova a ricaricare la pagina' })
      commit('cancelNewTag');
    });
  },

  /**
   * Calls the API that deletes the tag
   *
   * @param {Object} commit
   * @param {Object} tag
   */
  deleteTag({ state, commit }, tag) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Cancello il tag...' });

    axios.delete(`/tag/${tag.id}`)
      .then((res) => {
        commit('deleteTag', res.data.id);

        if (Number(res.data.id) === get(state, 'newTag.id')) {
          commit('cancelNewTag');
        }

        commit(ALERTS_PUSH, { status: 1, msg: 'Tag cancellato!' });
      }).catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  updateTag({ commit }, payload) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Aggiorno il tag...' });

    axios.put(`/tag/${payload.tag.id}`, payload.data)
      .then((res) => {
        commit('replaceTag', res.data);

        commit(ALERTS_PUSH, { status: 1, msg: 'Tag aggiornato!' });
      }).catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  toggleRecording({ state, commit }) {
    commit('toggleRecording');

    if (!state.isRecording && state.recording.start && state.recording.finish) {
      commit('updateTagField', {
        tag: {
          start: state.recording.start,
          end: state.recording.finish,
        },
      });

      commit('cancelRecording');
    }
  },

  shareTag({ commit }, payload) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Condivido il tag...' });

    axios.post(`/tag/${payload.id}/share`, payload.people)
      .then(() => commit(ALERTS_PUSH, { status: 1, msg: 'Tag condiviso!' }))
      .catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  filterTags({ commit }, data) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Applico i filtri...' });

    axios.post('/tag/search', data)
      .then((res) => {
        commit('setFilteredTags', res.data);
        commit(ALERTS_PUSH, { status: 1, msg: 'Filtri applicati!' });
      }) .catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  export({ commit }, data) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Preparo per l\'esportazione...' });

    axios.post('/tag/export', data)
      .then(() => commit(ALERTS_PUSH, { status: 1, msg: 'Tag esportati! Download in corso...' }))
       .catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  saveBookmark({ state, commit }, payload) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Creo il segnalibro...' });

    const start = state.currentTime || 0;

    axios.post('/bookmark', Object.assign({}, payload, { start, video_id: state.data.video.id }))
      .then((res) => {
        commit('bookmarkSaved', res.data);
        commit(ALERTS_PUSH, { status: 1, msg: 'Segnalibro creato!' });
      }).catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  deleteBookmark({ commit }, bookmarkId) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Cancello il segnalibro...' });

    axios.delete(`/bookmark/${bookmarkId}`)
      .then(() => {
        commit('deleteBookmark', bookmarkId);
        commit(ALERTS_PUSH, { status: 1, msg: 'Segnalibro cancellato!' });
      }).catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  createSetting({ commit }, settingName) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Salvo la nuova impostazione...' });

    axios.post('/setting', { name: settingName })
      .then((res) => {
        commit('createSetting', res.data);
        commit(ALERTS_PUSH, { status: 1, msg: 'Impostazione creata!' });
      }).catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  deleteSetting({ commit }, id) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Cancello l\'impostazione...' });

    axios.delete(`/setting/${id}`)
      .then(() => {
        commit('deleteSetting', id);
        commit(ALERTS_PUSH, { status: 1, msg: 'Impostazione cancellata!' });
      }).catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },

  updateSetting({ commit }, setting) {
    commit(ALERTS_PUSH, { status: 1, msg: 'Aggiorno l\'impostazione...' });

    return axios.put(`/setting/${setting.id}`, setting)
      .then((res) => {
        commit('updateSetting', res.data);
        commit(ALERTS_PUSH, { status: 1, msg: 'Impostazione aggiornata!' });

        return res.data;
      }).catch(err => commit(ALERTS_PUSH, { status: 0, msg: err.response.data.error }));
  },
};
