<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<b-overlay :show="loading" no-wrap></b-overlay>
			<b-card :title="'Persone (' + total + ')'" title-tag="span">
				<b-card-body class="px-0" style="font-size:0.9rem">
					<div class="d-flex align-items-center mb-2"><span><em> Filtra per: </em></span>
						<b-form-checkbox class="ml-3" v-model="filters.hasScoutValutazioni" :value="1" :unchecked-value="0">Con valutazioni scouting</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.hasListe" :value="1" :unchecked-value="0">In liste scouting</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.hasFootballValutazioni" :value="1" :unchecked-value="0">Con valutazioni football</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.hasFootballPartite" :value="1" :unchecked-value="0">Con partite football</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.hasVideotags" :value="1" :unchecked-value="0">Con videotags</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.isUtente" :value="1" :unchecked-value="0">Utenti</b-form-checkbox>
						<b-form-checkbox class="ml-3" v-model="filters.isArbitro" :value="1" :unchecked-value="0">Arbitri</b-form-checkbox>
						<b-form-input class="ml-3" size="sm" style="width:200px" type="text" v-model="filters.cognome" placeholder="Cognome"></b-form-input>
						<b-form-input class="ml-3" size="sm" style="width:200px" type="text" v-model="filters.nome" placeholder="Nome"></b-form-input>
						<span class="ml-5"><em> Ordina per: </em></span>
						<b-form-select size="sm" class="ml-3" style="width:250px" v-model="filters.orderBy">
							<b-form-select-option value="name">Nominativo</b-form-select-option>
							<b-form-select-option value="created_at">Ultimo inserimento</b-form-select-option>
							<b-form-select-option value="updated_at">Ultima modifica</b-form-select-option>
						</b-form-select>
					</div>
					<v-infinite-scroll :loading="loading" @bottom="nextPage" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
						<b-col cols="2" v-for="item in persone" class="mb-1">
							<card :persona="item" @getInfoPersona="getInfoPersona"></card>
						</b-col>
					</v-infinite-scroll>
				</b-card-body>
			</b-card>

			<b-modal ref="infoModal" :title="titleModal" size="xl" ok-only>
				<b-row>
					<b-col v-for="persona in dettaglio_persone" class="mb-1" :key="persona.id">
						<cardinfo :persona="persona"></cardinfo>
					</b-col>
				</b-row>
			</b-modal>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('@/auth.js');
import * as types from '@/store/types';
import InfiniteScroll from 'v-infinite-scroll';
import moment from 'moment';
import _ from 'lodash';
export default {

	data: function() {
		return {
			loading: false,
			persone: [],
			total: 0,
			page: 1,
			dettaglio_persone: [],
			titleModal: null,
			filters: {
				hasScoutValutazioni: 0,
				hasListe: 0,
				hasFootballValutazioni: 0,
				hasFootballPartite: 0,
				hasVideotags: 0,
				isUtente: 0,
				isArbitro: 0,
				cognome: '',
				nome: '',
				orderBy: 'created_at',
			},
		}
	},

	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		card: require('@/components/layout/persona/card.vue').default,
		cardinfo: require('@/components/layout/persona/card_info.vue').default,
		'v-infinite-scroll': InfiniteScroll,
	},

	created: function () {   
		this.getPersone();
	},

	watch: {
		filters: {
			handler() {
				this.getPersone();
			},      
			deep: true,
		},
	},
	methods: {
		getPersone() {
			this.loading = true;
			this.persone = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/persone', {params: this.filters}).then(function(res) {
				this.persone = res.data.data;
				this.total = res.data.total;
				this.loading = false;
			});
		},
		nextPage() {
			if(this.persone.length < this.total) {
				this.page++;
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/persone?page=' + this.page, {params: this.filters}).then(function(res) {
					this.persone = this.persone.concat(res.data.data);
					this.loading = false;
				});
			}
		},
		getInfoPersona(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/persone/info/' + id).then(function(res) {
				this.dettaglio_persone = res.data;
				if(this.dettaglio_persone.length > 0) {
					this.titleModal = this.dettaglio_persone[0].cognome + ' ' + this.dettaglio_persone[0].nome;
					this.$refs.infoModal.show();
				} else {
					this.$store.commit(types.ALERTS_PUSH, { msg: 'Nessuna persona trovata!', status: 0 });
				}
			});
		},
	},
}
</script>
