<template>
  <b-modal ref="modal" :id="modalId" centered size="lg" :title="modalTitle" hide-footer @show="initialize" @hide="reset">
    <form-wizard
      ref="formWizard"
      title=""
      subtitle=""
      nextButtonText="Avanti"
      backButtonText="Indietro"
      finishButtonText="Fine"
      color="#A50734"
      @on-complete="complete">

      <tab-content title="Giocatore" :before-change="checkSelected">
        <b-row>
          <b-col cols="12" md="6">
            <h3>Squadra di casa</h3>
            <b-form-select
              id="homePlayers"
              :value="playerOrTeam"
              :options="homePlayers"
              text-field="name"
              value-field="id"
              @change="setPlayer($event)">
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null" disabled>-- Seleziona un opzione --</option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" md="6">
            <h3>Squadra di trasferta</h3>
            <b-form-select
              id="visitingPlayers"
              :value="playerOrTeam"
              :options="visitingPlayers"
              text-field="name"
              value-field="id"
              @change="setPlayer($event, true)">
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null" disabled>-- Seleziona un opzione --</option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-form-checkbox v-model="goal.autogoal" :value="true" :unchecked-value="false">
              Autogoal
            </b-form-checkbox>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Sezione del corpo">
        <body-section ref="bodySection" @clicked="setBodySection" />
      </tab-content>

      <tab-content title="Sezione della porta">
        <goal-section
          ref="goalSection"
          @clicked="setGoalSection"
          @area-changed="changeArea"
          @action-goal-changed="goal.actionGoal = $event"
          @standstill-goal-type-changed="goal.standstillGoalType = $event" />
      </tab-content>

      <tab-content title="Provenienza assist">
        <field-section ref="fieldSection1" @clicked="setAssistSection" />
      </tab-content>

      <tab-content title="Giocatore assist">
        <assist-player-section
          ref="assistPlayer"
          :negative="negative"
          @player-clicked="setAssistPlayer"
          @skill-clicked="setAssistSkill" />
      </tab-content>

      <tab-content title="Provenienza azione">
        <field-section ref="fieldSection2" @clicked="setActionSection" />
      </tab-content>

    </form-wizard>
  </b-modal>
</template>

<script>
import _ from 'lodash';
import { FormWizard, TabContent } from 'vue-form-wizard';
import { mapState } from 'vuex';

import BodySection from './BodySection.vue';
import GoalSection from './GoalSection.vue';
import FieldSection from './FieldSection.vue';
import AssistPlayerSection from './AssistPlayerSection.vue';

export default {
  components: {
    FormWizard,
    TabContent,
    BodySection,
    GoalSection,
    FieldSection,
    AssistPlayerSection,
  },
  props: {
    negative: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      saving: false,
      goal: {
        start: null,
        end: null,
        player: null,
        team: null,
        bodySection: null,
        goalSection: null,
        assistSection: null,
        actionSection: null,
        result: null,
        autogoal: null,
        insideArea: null,
        actionGoal: null,
        standstillGoalType: null,
        assist: null,
      },
    };
  },

  computed: {
    ...mapState({
      homePlayers: state => state.data && (
        [
          { id: state.data.teams.home.id, name: state.data.teams.home.name, isTeam: true },
        ].concat(state.data.teams.home.players)),
      visitingPlayers: state => state.data && (
        [
          { id: state.data.teams.visiting.id, name: state.data.teams.visiting.name, isTeam: true },
        ].concat(state.data.teams.visiting.players)),

      currentTime: 'currentTime',
      video: state => (state.data ? state.data.video : null),
      secondsBefore: state => (state.data && state.data.tag_setting ? state.data.tag_setting.secondi_prima : 10),
      secondsAfter: state => (state.data && state.data.tag_setting ? state.data.tag_setting.secondi_dopo : 5),
    }),

    modalTitle() {
      return this.negative ? 'Goal subito' : 'Goal fatto';
    },

    modalId() {
      return `modal-goal-${this.negative ? 'negative' : 'positive'}`;
    },

    homePlayerIds() {
      if (!this.$store.state.data) return [];
      return this.$store.state.data.teams.home.players.map(p => p.id);
    },

    homeTeamId() {
      if (!this.$store.state.data) return null;
      return this.$store.state.data.teams.home.id;
    },

    visitingPlayerIds() {
      if (!this.$store.state.data) return [];
      return this.$store.state.data.teams.visiting.players.map(p => p.id);
    },

    visitingTeamId() {
      if (!this.$store.state.data) return null;
      return this.$store.state.data.teams.visiting.id;
    },

    playerOrTeam() {
      if (!this.goal.player && !this.goal.team) return null;
      return this.goal.player ? this.goal.player.id : this.goal.team.id;
    }
  },

  methods: {
    initialize() {
      this.saving = false;
      this.goal.result = this.negative ? 'negative' : 'positive';
      this.reset();

      if (!this.video) return;

      const secondsBefore = this.secondsBefore;
      const secondsAfter = this.secondsAfter;

      this.goal.start = Math.max(0, this.currentTime - secondsBefore);
      this.goal.end = Math.min(this.video.duration, this.currentTime + secondsAfter);
    },

    reset() {
      this.goal.player = null;
      this.goal.team = null;
      this.goal.start = null;
      this.goal.end = null;
      this.goal.playerId = null;
      this.goal.teamId = null;
      this.goal.bodySection = null;
      this.goal.goalSection = null;
      this.goal.assistSection = null;
      this.goal.actionSection = null;
      this.goal.autogoal = null;
      this.goal.insideArea = null;
      this.goal.actionGoal = null;
      this.goal.standstillGoalType = null;
      this.goal.assist = null;
      this.$refs.bodySection.reset();
      this.$refs.fieldSection1.reset();
      this.$refs.fieldSection2.reset();
      this.$refs.goalSection.reset();
      this.$refs.formWizard.reset();
      this.$refs.assistPlayer.reset();

      if (!this.saving) {
        this.$store.commit('cancelNewTag');
      }
    },

    setPlayer(value, visiting = false) {
      this.goal.player = null;
      this.goal.team = null;
      const players = visiting ? this.visitingPlayers : this.homePlayers;
      const playerOrTeam = players.find(p => p.id === value);
      if (!playerOrTeam) return;
      this.$set(this.goal, playerOrTeam.isTeam ? 'team' : 'player', playerOrTeam);
    },

    changeArea(area) {
      console.log(area);
      this.goal.insideArea = area;
    },

    setBodySection(bodySection) {
      this.goal.bodySection = bodySection;
    },

    setGoalSection(goalSection) {
      this.goal.goalSection = goalSection;
    },

    setAssistSection(fieldSection) {
      this.goal.assistSection = fieldSection;
    },

    setActionSection(fieldSection) {
      this.goal.actionSection = fieldSection;
    },

    activeClass(player) {
      return ((
        player.isTeam && this.goal.team && player.id === this.goal.team.id
      ) || (
          !player.isTeam && this.goal.player && player.id === this.goal.player.id
        )) && 'active';
    },

    initAssistTag() {
   // const secondsBefore = 10;
   // const secondsAfter = 5;

      this.goal.assist = {
        result: this.negative ? 'negative' : 'positive',
        start: this.goal.start,
        end: this.goal.end,
        player: null,
        skill: null,
      };
    },

    setAssistPlayer(player) {
      if (!this.goal.assist) this.initAssistTag();
      this.goal.assist.player = player;
    },

    setAssistSkill(skill) {
      if (!this.goal.assist) this.initAssistTag();
      this.goal.assist.skill = skill;
    },

    complete() {
      this.saving = true;
      this.$store.commit('updateTagField', {
        tag: { ...this.goal },
      });
    //  this.$store.dispatch('saveTag', true);
      this.$store.dispatch('saveTagGoal', true);
      this.$refs.modal.hide();
    },
    checkSelected() {
      if(!this.goal.player && !this.goal.team) {
        alert("Devi selezionare un giocatore!");
        return false;
      } else {
        return true;
      }
    }
  },
};
</script>
