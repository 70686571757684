<template id="acad_val">
	<section class="acad_val">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="academy">
			<div class="card-body p-3">
				<div class="row flex-items-xs-middle">
					<div class="col-5">
						<div class="media">
							<img v-bind:src="academy.logo_url" class="" height="80">
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{academy.nome}}</strong></h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<b-button :to="{ name: 'academy_int_societa_valutazioni_new', params: { id: $route.params.id }}" variant="primary" class="text-uppercase mb-2" v-if="auth.isUserEnable('academy_valutazioni_mng')"><strong>{{ $i18n.t('Nuova') }}</strong></b-button>
				
				<b-table striped show-empty
				:items="valutazioni"
				:fields="fields"
				>
				<template #cell(data)="row">{{ row.value | formatDate }}</template>
				<template #cell(utente)="row">{{ row.value.nomeCompleto }}</template>
				<template #cell(voto)="row">{{ getVotoMedio(row.item.voti) }}</template>
				<template #cell(azioni)="row">
					<b-button size="sm" :to="{ name: 'academy_int_societa_valutazioni_view', params: { id: $route.params.id, val_id: row.item.id }}" variant="success" class="mr-1">
						View <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
					</b-button>
					<b-button v-if="auth.isUserEnable('academy_valutazioni_mng') && (auth.user.id == row.item.utente_id || auth.isAdmin())" size="sm" :to="{ name: 'academy_int_societa_valutazioni_edit', params: { id: $route.params.id, val_id: row.item.id }}" class="mr-1" variant="primary">
						Edit <i class="fa fa-pencil" aria-hidden="true"></i>
					</b-button>
					<b-button v-if="auth.isUserEnable('academy_valutazioni_mng') && (auth.user.id == row.item.utente_id || auth.isAdmin())" size="sm" @click.stop="cancVal(row.item.id)" class="mr-1">
						Canc <i class="fa fa-trash" aria-hidden="true"></i>
					</b-button>		
				</template>
			</b-table>
		</div>
	</div>


	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types';
import axios from 'axios';
import moment from 'moment';
export default {

	data: function() {
		return {
			academy: null,
			valutazioni: [],
			fields: [
			{ key: 'data', label: this.$i18n.t('Data'), class: 'text-center' },
			{ key: 'utente', label: this.$i18n.t('Autore'), class: 'text-center' },
			{ key: 'voto', label: this.$i18n.t('Voto medio'), class: 'text-center' },
			{ key: 'azioni', label: this.$i18n.t('Azioni'), class: 'text-center' },
			],

		}
	},

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default
		,
	},

	computed: {
		auth: function() {
			return Auth;
		},
	},

	created: function () {
		if(this.$route.params.id) {
			axios.get(`/academyita/societa/get/` + this.$route.params.id)
			.then((response) => {
				this.academy = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		}

		this.getValutazioni();

	},

	methods: {
		getValutazioni() {
			axios.get(`/academyint/valutazioni/` + this.$route.params.id)
			.then((response) => {
				this.valutazioni = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		},

		getVotoMedio(voti) {
			var voto_medio = 0;
			var votiObj = JSON.parse(voti);
			var array_keys = Object.keys(votiObj);
			for(var i in array_keys) {
				var key = array_keys[i];
				var voto = votiObj[key];
				voto_medio += voto;	
			}
			voto_medio = voto_medio/array_keys.length;
			voto_medio = Math.round(voto_medio * 100) / 100;
			return voto_medio;
		},

		viewVal(id) {

		},

		editVal(id) {

		},

		cancVal(id) {
			if(confirm(this.$i18n.t('Confermi l\'eliminazione della valutazione?'))) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyint/delete/valutazione/' + id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.getValutazioni();		
					}
				}, (response) => {
				});
			}
		}
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
