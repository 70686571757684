<template>
	<b-row>
		<b-col :cols="3">
			<code>{{ player.currentTime }}</code> - <code>{{duration}}</code>
		</b-col>
		<b-col>
			<pre>{{layer}}</pre>
			<pre>{{player}}</pre>
		</b-col>
	</b-row>
</template>

<script>

import moment from 'moment'

export default {
	props: ['layer', 'player', 'duration'],
	components: {
		// SmartPlayer: require('@/components/layout/video/smart-player.vue').default
	},
	data: function() {
		return {
			a: null
		}
	},
	filters: {
		duration: function(a) {
			return moment('2000-01-01 00:00:00').add(a, 'seconds').format('HH:mm:ss');
		}
	},
	watch: {
		duration: {
			deep: true,
			handler: function() {
				if(this.duration < 0) return false;
				
				if(this.duration < this.layer.layerOut)
					this.layer.layerOut = _.clone(this.duration)
			}
		}
	},
	methods: {
		
	}
}
</script>

</script>