<template id="menuapp">
    <div class="menu" v-if="items.length > 0">
      <nav class="navbar navbar-expand-md navbar-toggleable-md navbar-light bg-white border-bottom">
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Espandi/Contrai Menu">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li v-for="item in items" :class="'nav-item' + (item.active ? ' active current-menu-item' : '')">
              <router-link :exact="item.routeExactMatch ? true : false" :to="item.to" class="nav-link">
                <span>{{ $i18n.t(item.content) }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
	</div>
</template>

<script>
import { mapState } from 'vuex';
var Auth = require('../../auth.js');
export default {

	data: function() {
		return {
            items: [],
            moduli_categoria: ['football', 'performance', 'analysis', 'medical', 'reports', 'contratti'],
    	}
	},

    methods: {
        update()
        {
            this.items = [];
            for (var index_route in this.$router.options.routes)
            {
                var route = this.$router.options.routes[index_route];
                if (route.meta && (route.meta.module == this.$route.meta.module) && (route.meta.showInMenu) && (route.meta.parent == ''))
                {
                    if(route.meta.permesso && !Auth.isUserEnable(route.meta.permesso, this.categoriaId) && this.moduli_categoria.includes(route.meta.module)) {
                        continue;
                    }
                    if(route.meta.permesso && !Auth.isUserEnable(route.meta.permesso)) {
                        continue;
                    }
                    if(route.meta.onlyInProva && !this.inProva) {    
                        continue;
                    }
                    var active = (route.path == this.$route.path);
                    this.items.push({ to: route.path, content: route.meta.label, active: active, routeExactMatch: route.meta.routeExactMatch });
                }
            }
        },
    },

    created: function () {
        this.update();
    },

    watch: {
        '$route': function (newValues) {
            this.update();
        },

        inProva: function (newValues) {
            this.update();
        },

        categoriaId: function () {
            this.update();
        },
    },
    computed: {
        ...mapState(['inProva', 'categoriaId']),
    },
}
</script>
