<template id="differenziato">
	<div class="card elencocategorie" v-if="handle || (!handle && cartelle.length > 0)">
		<div class="card-body">
			<div class="card-title">
				<span>Perfezionamento</span> <b-button variant="primary" class="ml-2" v-if="handle" @click="showAddCartella" title="Aggiungi cartella di lavoro"><i class="fa fa-plus"></i></b-button>
			</div>
			<div class="content">
				<b-row>
					<b-col>
						<h5 :class="tipo_selected == 'negativi' && id_cartella_selected ? 'color-rosso' : ''">ANALISI ERRORI</h5>
					</b-col>
					<b-col>
						<h5 :class="tipo_selected == 'esercizi' && id_cartella_selected ? 'color-rosso' : ''">PERSONALIZZATO</h5>
					</b-col>
					<b-col>
						<h5 :class="tipo_selected == 'positivi' && id_cartella_selected ? 'color-rosso' : ''">FEEDBACK</h5>
					</b-col>
				</b-row>
				<b-row v-for="cartella in cartelle" class="mb-2">
					<b-col cols="12" v-if="id_cartella_selected == cartella.id" class="mb-2">
						<b-row>
							<b-col cols="1">
								<b-button size="sm" class="mb-1" title="Chiudi" @click="chiudiGallery"><i class="fa fa-times" aria-hidden="true"></i> Chiudi</b-button>
							</b-col>
							<b-col cols="2">
								<b-form-select v-model="gallery_items" :options="gallery_items_opt" size="sm" />
							</b-col>
						</b-row>
						<carousel :nav="false" :margin="10" :items="gallery_items" v-if="gallery.length > 0" :key="tipo_selected + '_' + gallery_items" class="my_gallery">
							<b-card no-body v-for="item in gallery" class="h-100" :key="tipo_selected == 'esercizi' ? (item.videotag ? 'ese_' + item.id + '_' + item.videotag.id : 'ese_' + item.id) : 'tag_' + item.id">
								<b-embed type="video" controls v-if="tipo_selected != 'esercizi'" :poster="item.videotag.thumbnail_url" preload="none" controlsList="nodownload" oncontextmenu="return false;">
									<source :src="getUrlVideo(item.id_videotag)" type="video/mp4">
									</b-embed>
									<b-embed type="video" controls v-if="tipo_selected == 'esercizi' && item.videotag" :poster="item.videotag.thumbnail_url" preload="none" controlsList="nodownload" oncontextmenu="return false;">
										<source :src="getUrlVideo(item.videotag.id)" type="video/mp4">
										</b-embed>
										<b-img :src="item.esercizio.canvas_url" fluid v-if="tipo_selected == 'esercizi' && !item.videotag"/>
										<b-row class="p-2">
											<b-col>
												<div v-if="item.videocartella"> Cartella video: {{item.videocartella}} </div>
												<div v-for="skill in item.skills"> {{skill.nome_skill}} </div>
												<div v-if="tipo_selected == 'esercizi'"> Allenamenti: {{item.count_allen}} </div>
												<div v-if="tipo_selected == 'esercizi'">Minuti allenati:  {{item.minuti_allen}} </div>
											</b-col>
											<b-col cols="2">
												<b-button size="sm" title="Elimina" @click="eliminaTag(item.id, cartella.id, 'negativi')" v-if="handle && tipo_selected != 'esercizi'"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
												<b-row v-if="handle && tipo_selected == 'esercizi'">
													<b-col cols="12" class="mb-1">
														<b-button size="sm" title="Visualizza esercizio" @click="viewEsercizio(item.id, cartella.id)" variant="success"><i class="fa fa-search" aria-hidden="true"></i></b-button>
													</b-col>
													<b-col cols="12">
														<b-button size="sm" title="Elimina esercizio" @click="eliminaEsercizio(item.id, cartella.id, 'esercizi')" ><i class="fa fa-trash" aria-hidden="true"></i></b-button>
													</b-col>
												</b-row>
											</b-col>
										</b-row>
									</b-card>
								</carousel>	
							</b-col>
							<b-col>
								<b-card overlay
								:img-src="cartella.errori.length > 0 ? (cartella.errori[0].videotag.thumbnail ? cartella.errori[0].videotag.thumbnail_url : '/assets/varie/missing.jpg') : '/assets/varie/missing.jpg'"
								img-top
								class="h-100" :border-variant="tipo_selected == 'negativi' && id_cartella_selected == cartella.id ? 'danger' : ''">
								<div style="position: absolute; left: 0.6rem; top:0.5rem;">
									<div><strong>{{ cartella.nome_skill.toUpperCase() }}</strong></div>
									<div v-if="cartella.giocatore"><em> {{ cartella.giocatore }} </em></div>
									<div><small><em>dal {{cartella.inizio | formatDate }} <span v-if="cartella.stato"> al {{cartella.fine | formatDate }}</span></em></small></div>
									<div><strong>VIDEOTAG {{ cartella.errori.length }}</strong></div>
								</div>
								<div style="position: absolute; left: 42%; top:37%; cursor:pointer" v-if="cartella.errori.length > 0" @click="viewGallery(cartella.id, 'negativi')">
									<i class="fa fa-youtube-play fa-4x" aria-hidden="true"></i>
								</div>
								<b-button variant="primary" size="sm" class="ml-2" v-if="handle" @click="analisiErrori(cartella.id)" style="position: absolute; left: 1%; bottom:5%; cursor:pointer"><i class="fa fa-plus"></i></b-button>
								<div :class="'triangle-up-left-' + cartella.stato">	
								</div>
							</b-card>
						</b-col>
						<b-col>
							<b-card overlay
							:img-src="cartella.esercizi.length > 0 ? (cartella.esercizi[0].videocartella && cartella.esercizi[0].videocartella.videotags.length > 0 ? cartella.esercizi[0].videocartella.videotags[0].thumbnail_url : cartella.esercizi[0].esercizio.canvas_url) : '/assets/varie/missing.jpg'"
							img-top
							class="h-100" :border-variant="tipo_selected == 'esercizi' && id_cartella_selected == cartella.id ? 'danger' : ''">
							<div style="position: absolute; left: 0.6rem; top:0.5rem;">
								<div><strong>{{ cartella.nome_skill.toUpperCase() }}</strong></div>
								<div v-if="cartella.giocatore"><em> {{ cartella.giocatore }} </em></div>
								<div><small><em>dal {{cartella.inizio | formatDate }} <span v-if="cartella.stato"> al {{cartella.fine | formatDate }}</span></em></small></div>
								<div><strong>ESERCIZI {{ cartella.esercizi.length }}</strong></div>
								<div><strong>ALLENAMENTI {{ cartella.count_tot_allen }}</strong></div>
								<div><strong>MINUTI ALLENATI {{ cartella.min_tot_allen }}</strong></div>
							</div>
							<div style="position: absolute; left: 42%; top:37%; cursor:pointer" v-if="cartella.esercizi.length > 0" @click="viewGallery(cartella.id, 'esercizi')">
								<i class="fa fa-youtube-play fa-4x" aria-hidden="true"></i>
							</div>
							<b-button variant="primary" size="sm" class="ml-2" v-if="handle" @click="personalizzato(cartella.id)" style="position: absolute; left: 1%; bottom:5%; cursor:pointer"><i class="fa fa-plus"></i></b-button>
							<div :class="'triangle-up-left-' + cartella.stato">		
							</div>
						</b-card>
					</b-col>
					<b-col>
						<b-card overlay
						:img-src="cartella.feedback.length > 0 ? (cartella.feedback[0].videotag.thumbnail ? cartella.feedback[0].videotag.thumbnail_url : '/assets/varie/missing.jpg') : '/assets/varie/missing.jpg'"
						img-top
						class="h-100" :border-variant="tipo_selected == 'positivi' && id_cartella_selected == cartella.id ? 'danger' : ''">
						<div style="position: absolute; left: 0.6rem; top:0.5rem;">
							<div><strong>{{ cartella.nome_skill.toUpperCase() }}</strong></div>
							<div v-if="cartella.giocatore"><em> {{ cartella.giocatore }} </em></div>
							<div><small><em>dal {{cartella.inizio | formatDate }} <span v-if="cartella.stato"> al {{cartella.fine | formatDate }}</span></em></small></div>
							<div><strong>VIDEOTAG {{ cartella.feedback.length }}</strong></div>
						</div>
						<div style="position: absolute; left: 42%; top:37%; cursor:pointer" v-if="cartella.feedback.length > 0" @click="viewGallery(cartella.id, 'positivi')">
							<i class="fa fa-youtube-play fa-4x" aria-hidden="true"></i>
						</div>
						<b-button variant="primary" size="sm" class="ml-2" v-if="handle" @click="feedback(cartella.id)" style="position: absolute; left: 1%; bottom:5%; cursor:pointer"><i class="fa fa-plus"></i></b-button>
						<b-button variant="primary" size="sm" class="ml-2" v-if="handle && !cartella.stato" @click="showChiudiCartella(cartella.id)" style="position: absolute; right: 3%; top:5%; cursor:pointer" title="Chiudi cartella di lavoro"><i class="fa fa-hourglass-end"></i></b-button>
						<b-button variant="primary" size="sm" class="ml-2" v-if="handle && cartella.stato" @click="riapriCartella(cartella.id)" style="position: absolute; right: 3%; top:5%; cursor:pointer" title="Riapri cartella di lavoro"><i class="fa fa-folder-open"></i></b-button>
						<b-button variant="primary" size="sm" class="ml-2" v-if="handle" @click="eliminaCartella(cartella.id)" style="position: absolute; right: 3%; top:45%; cursor:pointer" title="Elimina cartella di lavoro"><i class="fa fa-trash"></i></b-button>
						<img :src="'/assets/smiles/grad_' + cartella.id_gradimento + '.png'" v-if="cartella.stato" style="position: absolute; right: 1%; bottom:1%;"/>
						<div :class="'triangle-up-left-' + cartella.stato">	
						</div>
					</b-card>
				</b-col>
			</b-row>
			<b-modal id="addCartellaModal" ref="addCartellaModal" :title="titolo" class="fs-modal" ok-only ok-title="Chiudi">
				<b-row>
					<b-col cols="12">
						<b-form-group
						label="Seleziona una macroskill:"
						>
						<multiselect
						v-model="form.macroskill"
						:multiple="false"
						:options="macroskill_options"
						group-values="opt_skills"
						group-label="macro"
						placeholder="Macroskill"
						label="name"
						track-by="id"/>
					</b-form-group>
					<b-form-group
					label="Data inizio:"
					>
					<b-form-input v-model="form.inizio" type="date"/>
				</b-form-group>
				<b-button variant="primary" @click="addCartella">Salva</b-button>
			</b-col>
		</b-row>
	</b-modal>
	<b-modal id="chiudiCartellaModal" ref="chiudiCartellaModal" :title="titolo" class="fs-modal" ok-only ok-title="Chiudi">
		<b-row>
			<b-col cols="12">
				<h5> {{ cartella_nome }} </h5>
				<p> dal {{ cartella_inizio | formatDate }} </p>
				<b-form-group
				label="Seleziona una data di fine:"
				>
				<b-form-input v-model="form.fine" type="date"/>
			</b-form-group>
			<b-form-group
			label="Seleziona un gradimento:"
			>
			<b-button-group>
				<b-button :variant="grad.id == form.gradimento ? 'dark' : 'outline-light'" v-for="grad in gradimenti" :title="grad.nome" @click="selectGradimento(grad.id)"><img :src="'/assets/smiles/grad_' + grad.id + '.png'"/></b-button>
			</b-button-group>
		</b-form-group>
		<b-button variant="primary" @click="chiudiCartella">Salva</b-button>
	</b-col>
</b-row>
</b-modal>
<b-modal id="perfezModal" ref="perfezModal" :title="titolo" class="fs-modal" ok-only ok-title="Chiudi" @hidden="onHidden" @shown="onShow">
	<b-row v-if="!form.id_perfez_ese">
		<b-col cols="12">
			<p>Seleziona una o più skills:</p>
		</b-col>
		<b-col v-for="(item, index) in skills">
			<multiselect
			:max="3"
			v-model="form.skills_selected[index]"
			:multiple="true"
			:options="getOptionsSkills(item, only_root_skill)"
			:placeholder="item.it"
			label="name"
			track-by="id"/>
		</b-col>
	</b-row>
	<b-row class="mt-2" v-if="tipo_modal == 'esercizi' && !exe_selected && show_esercizi">
		<b-col cols="12">
			<p>Seleziona un esercizio:</p>
		</b-col>
		<b-col cols="4">
			<div style="height:100vh;overflow:auto;">
				<card title="Filtri" class="w-100 border-0 rounded-0" comp="esercizifiltri"></card>
			</div>
		</b-col>
		<b-col>
			<h5 class="color-rosso">{{ 'CERTIFICATI (' + (exercises_certificati.total ? exercises_certificati.total : 0) + ')' }}</h5>
			<v-infinite-scroll @bottom="nextPageEx" :offset='30' style="max-height: 90vh; overflow-y: scroll;" class="row">
				<div class="col-md-6 col-lg-6 col-xl-6 mb-3" v-for="ex in exercises_certificati.data">
					<div class="card" :id="'exe_' + ex.id" style="cursor:pointer;" @click="selezionaEsercizio(ex)">
						<img :src="ex.canvas_url" class="card-img-top img-fluid" />
						<div class="card-body">
							<p class="text-truncate mb-0" :title="ex.name"><strong>{{ex.name}}</strong></p>
							<p class="mb-0 font-size-sm text-truncate" v-if="ex.utente">di <span class="color-rosso">{{ex.utente.nomeCompleto}}</span></p>
							<div v-if="!ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="ex.principiDiGioco ? getLabelSkill(ex.principiDiGioco, exe_skills[0]) : 'N/D'">{{ ex.principiDiGioco ? getLabelSkill(ex.principiDiGioco, exe_skills[0]) : 'N/D' }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="ex.componentiTecniche ? getLabelSkill(ex.componentiTecniche, exe_skills[1]) : 'N/D'">{{ ex.componentiTecniche ? getLabelSkill(ex.componentiTecniche, exe_skills[1]) : 'N/D' }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="ex.capacitaMotorie ? getLabelSkill(ex.capacitaMotorie, exe_skills[2]) : 'N/D'">{{ getLabelSkill(ex.capacitaMotorie, exe_skills[2]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
							</div>
							<div v-if="ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="getLabelSkill(ex.tecnicaPodalica, exe_skills_portiere[0])">{{ getLabelSkill(ex.tecnicaPodalica, exe_skills_portiere[0]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="getLabelSkill(ex.tecnicaDiBase, exe_skills_portiere[1])">{{ getLabelSkill(ex.tecnicaDiBase, exe_skills_portiere[1]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="getLabelSkill(ex.qualitaTattiche, exe_skills_portiere[2])">{{ getLabelSkill(ex.qualitaTattiche, exe_skills_portiere[2]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="getLabelSkill(ex.capacitaMotorie, exe_skills_portiere[3])">{{ getLabelSkill(ex.capacitaMotorie, exe_skills_portiere[3]) }}</span></p>
							</div>
						</div>
					</div>
				</div>	
			</v-infinite-scroll>
		</b-col>
	</b-row>
	<b-row v-if="tipo_modal == 'esercizi' && exe_selected" class="mt-2">
		<b-col cols="6">
			<div class="card">
				<img :src="exe_selected.canvas_url" class="card-img-top img-fluid" />
				<div class="card-body">
					<p class="text-truncate mb-0" :title="exe_selected.name"><strong>{{exe_selected.name}}</strong></p>
					<p class="mb-0 font-size-sm text-truncate" v-if="exe_selected.utente">di <span class="color-rosso">{{exe_selected.utente.nomeCompleto}}</span></p>
				</div>
			</div>
		</b-col>
		<b-col cols="6">
			<b-form-textarea plaintext :value="exe_selected.description" style="font-size:0.8rem" class="mb-2"></b-form-textarea>
			<div>Numero giocatori: <strong>{{ exe_selected.numeroGiocatori }}</strong></div>
			<div>Minuti esercitazione: <strong>{{ exe_selected.minutiEsercizio }}</strong></div>
			<div>Numero di serie: <strong>{{ exe_selected.numeroSerie }}</strong></div>
			<div>Minuti di recupero: <strong>{{ exe_selected.recupero }}</strong></div>
			<div>Secondi di recupero: <strong>{{ exe_selected.secondiRecupero }}</strong></div>
			<div>Numero di recuperi: <strong>{{ exe_selected.numeroRecuperi }}</strong></div>
			<div>Minuti totali: <strong>{{ exe_selected.min_tot_ex }}</strong></div>
		</b-col>
		<b-col cols="12">
			<b-button @click="resetEsercizio" size="sm" class="mt-1" v-if="!form.id_perfez_ese">Cambia esercizio</b-button>
		</b-col>
	</b-row>

	<b-container fluid class="m-0">
		<b-row align-v="center" class="mt-3">
			<b-col cols="12">
				<p v-if="tipo_modal == 'tags'">Seleziona uno o più videotags:</p>
				<p v-if="tipo_modal == 'esercizi'">Associa un cartella videotags:</p>
			</b-col>
			<b-col cols="2"><i class="fa fa-play fa-2 mr-2" aria-hidden="true" title="Numero di videotags"></i> {{videotags_count}} </b-col>
			<b-col cols="2"><i class="fa fa-futbol-o fa-2 mr-2" aria-hidden="true" title="Numero di eventi"></i> {{partite}}</b-col>
			<b-col cols="2"><i class="fa fa-clock-o fa-2 mr-2" aria-hidden="true" title="Tempo totale videotags"></i> {{videotags_time}}</b-col>
			<b-col cols="3"></b-col>
			<b-col cols="3">
				<b-form-group label="Utenti" horizontal v-if="auth.isUserEnable('videotag_utenti')" class="mb-0">
					<b-form-select v-model="utente">
						<option value="0">-- Nessuno --</option>
						<option v-for="item in utenti" :value="item.id">{{item.nomeCompleto}}</option>
					</b-form-select>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="7">
				<p v-if="utente == 0">Filtra i videotag del tuo archivio personale</p>
				<p v-if="utente != 0">Filtra i videotag dell'archivio personale di <strong class="color-rosso">{{ labelUtente }}</strong></p>
				<b-form @submit.stop.prevent="filtraArchivio()">
					<b-form-row>
						<b-col
						v-for="(filter, filterName, i) in filters"
						:key="`filter-${i}`"
						cols="2">
						<b-form-group>
							<b-form-select v-model="selectedFields[filterName]" style="font-size:0.9rem;">
								<template slot="first">
									<option value="">{{ filter.name }}</option>
								</template>
								<template v-if="valueIsArray(filter.values)">
									<option v-for="(value, i) in filter.values" :key="`value-${i}`" :value="value.id">
										{{ value.name }}
									</option>
								</template>
								<template v-else>
									<optgroup v-for="(values, labelName, i) in filter.values" :key="`group-${i}`" :label="labelName">
										<option v-for="(value, j) in values" :key="`value-${j}`" :value="value.id">
											{{ value.name }}
										</option>
									</optgroup>
								</template>

							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group>
							<b-form-input v-model="selectedFields.keyword" type="text" placeholder="Parola chiave" style="font-size:0.9rem;"/>
						</b-form-group>
					</b-col>

					<b-col cols="1" class="col-submit">
						<b-form-group>
							<b-button type="submit" variant="success" style="font-size:0.9rem;" title="Cerca"><i class="fa fa-search" aria-hidden="true"></i>
							</b-button>
						</b-form-group>
					</b-col>
					<b-col cols="1" class="col-submit">
						<b-form-group>
							<b-button variant="primary" @click.prevent="reset()" style="font-size:0.9rem;" title="Cancella"><i class="fa fa-ban" aria-hidden="true"></i>
							</b-button>
						</b-form-group>
					</b-col>
				</b-form-row>
			</b-form>

		</b-col>
		<b-col class="pb-3" align-self="end">
			<b-row>
				<b-col align-self="end">Elenco video</b-col>
			</b-row>
		</b-col>
		<b-col class="pb-3" align-self="end">
			<b-row>
				<b-col align-self="end">Cartelle</b-col>
			</b-row>
		</b-col>
	</b-row>
	<b-row>
		<b-col cols="7">
			<video
			id="perfez-wrapper"
			class="video-js vjs-default-skin"
			controls
			:data-setup="setup">
		</video>
	</b-col>
	<v-infinite-scroll :loading="loadingArchivio" @bottom="nextPage" :offset='20' style="max-height: 60vh; overflow-y: scroll;" class="col">
		<b-card no-body v-for="video in videos" class="mb-1 p-2" :key="'video_' + video.id" :style="'background:' + video.videotags[0].privato ? 'yellow' : 'red'" :bg-variant="video.videotags[0].privato ? 'info' : 'default'">
			<b-row style="cursor:pointer">
				<b-col cols="11" v-b-toggle="'video_' + video.id">
					<div v-if="video.tipo == 'wyscout' && video.id_partita && !video.id_match">
						<div>{{ partitaLabel(video.partita.squadre) }}</div>
						<small class="text-muted">{{ video.partita.competizione.nome }} <br/> {{ video.partita.data | formatDateHour }}</small>
					</div>
					<div v-if="video.id_match">
						<div>{{ matchLabel(video.match) }}</div>
						<small class="text-muted">{{ matchTypeLabel(video.match) }} <br/> {{ video.match.date | formatDateHour }}</small>
					</div>
					<div v-if="video.id_allenamento">
						<div> Allenamento </div>
						<small class="text-muted">{{ allenamentoTypeLabel(video.allenamento) }} <br/> {{ video.allenamento.start_at | formatDateHour }}</small>
					</div>
				</b-col>
				<b-col v-b-toggle="'video_' + video.id">
					<i class="when-closed fa fa-caret-right"></i>
					<i class="when-opened fa fa-caret-down"></i>
				</b-col>
			</b-row>
			<b-collapse :id="'video_' + video.id">
				<div v-for="(videotag, index) in video.videotags" :key="'tag_' + videotag.id">
					<small><strong>{{ soggettoLabel(videotag) }}</strong></small><br/>
					<small>{{ azioniLabel(videotag.azioni) }}</small>
					<b-card no-body :class="['mb-1 p-2 default', videotag.peso, form.tags_selected.indexOf(videotag.id) > -1 ? 'evidenziato' : '']">
						<b-row>
							<b-col cols="1" @click="playClip(videotag.id)">
								<i class="fa fa-play" aria-hidden="true"></i>
							</b-col>
							<b-col cols="6" @click="playClip(videotag.id)">
								<small>{{ videotag.inizio }} - {{ videotag.fine }}</small>
							</b-col>
							<b-col>
								<i title="preferiti" class="fa fa-heart" aria-hidden="true" v-if="videotag.preferiti"></i>
							</b-col>
							<b-col>
								<i v-b-tooltip.hover :title="videotag.nota" class="fa fa-info-circle" aria-hidden="true" v-if="videotag.nota"></i>
							</b-col>
							<b-col>
								<b-form-checkbox
								v-model="form.tags_selected"
								:value="videotag.id" v-if="tipo_modal == 'tags'">
							</b-form-checkbox>
						</b-col>
					</b-row>
				</b-card>
			</div>
		</b-collapse>
	</b-card>
	<div v-if="loadingArchivio" align="center" style="width:100%">
		<img src="/assets/varie/loading.gif" style="width: 120px;" />
	</div>
</v-infinite-scroll>
<b-col v-if="loadingCartelle" class="text-center">
	<img src="/assets/varie/loading.gif" style="width: 120px;" />
</b-col>
<b-col style="height:60vh;overflow:auto;" v-if="!loadingCartelle">
	<b-card no-body v-for="(cartella, index) in videocartelle" :class="['mb-1 p-2', cartella.riassunto ? 'riassunto-' + cartella.riassunto : '', form.videocartella == cartella.id ? 'cartella_selected' : '' ]" :key="'cartella_' + cartella.id">
		<b-row>
			<b-col cols="8" v-b-toggle="'cartella_' + cartella.id" style="cursor:move">
				<div>{{ cartella.nome }}</div>
				<div><small>{{ durataCartella(cartella) }}</small></div>
			</b-col>
			<b-col v-b-toggle="'cartella_' + cartella.id">
				<i class="when-closed fa fa-caret-right"></i>
				<i class="when-opened fa fa-caret-down"></i>
			</b-col>
			<b-col>
				<b-form-checkbox
				v-model="form.videocartella"
				:value="cartella.id" v-if="tipo_modal == 'esercizi'">
			</b-form-checkbox>
		</b-col>
	</b-row>

	<b-collapse :id="'cartella_' + cartella.id">
		<div v-for="(videotag, index) in cartella.videotags" :key="'cvt_' + videotag.id">
			<b-card no-body :class="['mb-1 p-2 default', videotag.peso, form.tags_selected.indexOf(videotag.id) > -1 ? 'evidenziato' : '']">
				<b-row>
					<b-col cols="1" @click="playClip(videotag.id)">
						<i class="fa fa-play" aria-hidden="true"></i>
					</b-col>
					<b-col cols="7" @click="playClip(videotag.id)">
						<small>{{ videotag.inizio }} - {{ videotag.fine }}</small>
					</b-col>
					<b-col>
						<i title="preferiti" class="fa fa-heart" aria-hidden="true" v-if="videotag.preferiti"></i>
					</b-col>
					<b-col>
						<i v-b-tooltip.hover :title="videotag.nota" class="fa fa-info-circle" aria-hidden="true" v-if="videotag.nota"></i>
					</b-col>
					<b-col>
						<b-form-checkbox
						v-model="form.tags_selected"
						:value="videotag.id" v-if="tipo_modal == 'tags'">
					</b-form-checkbox>
				</b-col>
			</b-row>
		</b-card>
	</div>
</b-collapse>
</b-card>
</b-col>
</b-row>
<b-row class="mt-2 text-right">
	<b-col>
		<b-badge style="background:red;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Riassunto</b-badge>
		<b-badge style="background:green;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Highlights</b-badge>
		<b-badge style="background:blue;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Sintesi</b-badge>
		<b-badge style="background:orange;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Goals</b-badge>
	</b-col>
</b-row>
<b-row class="mt-3" v-if="tipo_modal == 'tags'">
	<b-col>
		<p>Videotags selezionati: <strong>{{ form.tags_selected.length }}</strong></p>
	</b-col>
</b-row>
</b-container>

<b-row class="mt-3">
	<b-col>
		<b-button variant="primary" @click="addTags" v-if="tipo_modal == 'tags'">Salva</b-button>
		<b-button variant="primary" @click="addEsercizio" v-if="tipo_modal == 'esercizi' && !form.id_perfez_ese">Salva</b-button>
		<b-button variant="primary" @click="updEsercizio" v-if="tipo_modal == 'esercizi' && form.id_perfez_ese">Salva</b-button>
	</b-col>
</b-row>	
</b-modal>
</div>
</div>	
</div>
</template>

<script>
var Auth = require('../../auth.js');
import * as types from '../../store/types';
import videojs from 'video.js';
import 'videojs-errors';
import 'videojs-youtube';
import moment from 'moment';
import InfiniteScroll from 'v-infinite-scroll';
import carousel from 'vue-owl-carousel';
import { mapState } from 'vuex';
export default {
	props:{
		id_giocatore: {
			type: Number,
			default: 0
		},
		only_root_skill: {
			type: Number,
			default: 0
		},
		handle: {
			type: Boolean,
			default: false
		},
		skills: {
			type: Array,
			required: true
		},
		exe_skills: {
			type: Array,
			required: true
		},
		exe_skills_portiere: {
			type: Array,
			required: true
		},
	},
	data: function(){
		return {
			cartelle: [],
			macroskill_options: [],
			gallery: [],
			gallery_items: 3,
			gallery_items_opt: [
			{ value: 1, text: 'Mostra 1 elemento'},
			{ value: 3, text: 'Mostra 3 elementi'},
			{ value: 6, text: 'Mostra 6 elementi'},
			],
			id_cartella_selected: null,
			tipo_modal: 'tags',
			tipo_selected: 'negativi',
			titolo: '',
			cartella_nome: '',
			cartella_inizio: '',
			form: {
				tipo: 'negativo',
				skills_selected: [],
				tags_selected: [],
				id_giocatore: this.id_giocatore,
				id_perfez_ese: null,
				esercizio: null,
				videocartella: null,
				id_cartella: null,
				macroskill: null,
				inizio: null,
				fine: null,
				gradimento: null,
			},
			selectedFields: {
				playerOrTeam: '',
				action: '',
				player: '',
				team: '',
				coach: '',
				positiveOrNegative: '',
				defensiveOrOffensive: '',
				fieldSection: '',
				keyword: '',
				anno: '',
				mese: '',
			},
			loadingArchivio: false,
			loadingCartelle: false,
			pageTags: 1,
			total: 0,
			count_tag: 0,
			tot_time_tag: 0,
			videos: [],
			videocartelle: [],
			tags_cartelle_ids: [],
			giocatori: [],
			squadre: [],
			allenatori: [],
			filter_skills: [],
			values: [],
			mesi: [],
			anni: [],
			utente: 0,
			mesiLabel: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
			tipoPartita: ['Campionato', 'Coppa', 'Amichevole', 'Torneo'],
			tipoAllenamento: ['Standard', 'Perfezionamento', 'Recupero', 'Portieri', 'Differenziato', 'Match', 'Skills development', 'RTP'],
			errors_player: {
				4: {
					headline: 'Video non disponibile',
					type: '',
					message: 'La clip non è stata ancora generata.<br/>Riprova più tardi.',
				}
			},
			skills_ex: [],
			skills_portiere_ex: [],
			show_esercizi: false,
			exe_selected: null,
			gradimenti: [],
			utenti: [],
		}
	},
	methods: {

		showAddCartella() {
			this.resetForm();
			this.titolo = 'Nuova cartella di lavoro';
			this.$refs.addCartellaModal.show();
		},
		showChiudiCartella(id_cartella) {
			this.resetForm();
			var cartella = this.getCartellaById(id_cartella);
			this.cartella_nome = cartella.nome_skill;
			this.cartella_inizio = cartella.inizio;
			this.form.id_cartella = id_cartella;
			this.titolo = 'Chiudi cartella di lavoro';
			this.$refs.chiudiCartellaModal.show();
		},
		analisiErrori(id_cartella) {
			this.resetForm();
			this.form.id_cartella = id_cartella;
			this.titolo = 'Analisi errori';
			this.form.tipo = 'negativo';
			this.loadArchivio();
			this.loadVideocartelle();
			this.loadFiltri();
			if(Auth.isUserEnable('videotag_utenti')) {
				this.loadUtenti();
			}
			this.tipo_modal = 'tags';
			this.$refs.perfezModal.show();
		},
		personalizzato(id_cartella) {
			this.resetForm();
			this.exe_selected = null;
			this.form.id_cartella = id_cartella;
			this.titolo = 'Personalizzato';
			this.$store.commit('editor/setExcerciseTab', 2);
			this.$store.commit('editor/setTitle', '');
			this.$store.commit('editor/setDrillId', null);
			this.$store.commit('editor/setObjIds', []);
			this.$store.commit('editor/setPage', 1);
			this.$store.dispatch('editor/filter_certificati');
			this.tipo_modal = 'esercizi';
			this.$refs.perfezModal.show();
			this.loadArchivio();
			this.loadVideocartelle();
			this.loadFiltri();
			if(Auth.isUserEnable('videotag_utenti')) {
				this.loadUtenti();
			}
			this.show_esercizi = true;
		},
		feedback(id_cartella) {
			this.resetForm();
			this.form.id_cartella = id_cartella;
			this.titolo = 'Feedback';
			this.form.tipo = 'positivo';
			this.loadArchivio();
			this.loadVideocartelle();
			this.loadFiltri();
			if(Auth.isUserEnable('videotag_utenti')) {
				this.loadUtenti();
			}
			this.tipo_modal = 'tags';
			this.$refs.perfezModal.show();
		},
		loadDati(viewCarousel = false, cartella_id = null, tipo = 'negativi') {
			this.cartelle = [];
			var path = '';
			if(this.id_giocatore > 0) {
				path = '/perfezionamento/get/dati/giocatore/' + this.id_giocatore;
			} else if(this.rosaTeamId && this.seasonId) {
				path = '/perfezionamento/get/dati/rosa/' + this.rosaTeamId + '/' + this.seasonId;
			}
			if(path != '') {
				this.$http.get(this.$store.state.apiEndPoint + path).then((response) => {
					if (response.ok)
					{
						this.cartelle = response.data;
						if(viewCarousel) {
							this.viewGallery(cartella_id, tipo);
						}
					}
				}, (response) => {
				});
			}
		},
		resetForm() {
			this.form.skills_selected = [];
			this.form.tags_selected = [];	
			this.form.esercizio = null;
			this.form.videocartella = null;
			this.form.id_cartella = null;
			this.form.macroskill = null;
			this.form.inizio = null;
			this.form.fine = null;
			this.form.gradimento = null;
			this.form.id_perfez_ese = null;		
		},
		addCartella() {
			if(!this.form.macroskill) {
				alert("Devi selezionare una macroskill!");
				return;
			}
			if(!this.form.inizio) {
				alert("Devi selezionare una data di inizio!");
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/perfezionamento/add/cartella', this.form).then((response) => {
				if (response.ok)
				{
					this.$refs.addCartellaModal.hide();
					this.chiudiGallery();
					this.loadDati();
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, (response) => { 
			});
		},
		chiudiCartella() {
			if(!this.form.fine) {
				alert("Devi selezionare una data di fine!");
				return;
			}
			if(!this.form.gradimento) {
				alert("Devi selezionare un gradimento!");
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/perfezionamento/chiudi/cartella', this.form).then((response) => {
				if (response.ok)
				{
					this.$refs.chiudiCartellaModal.hide();
					this.chiudiGallery();
					this.loadDati();
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, (response) => { 
			});
		},
		riapriCartella(id_cartella) {
			if(confirm("Confermi la riapertura della cartella di lavoro ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/perfezionamento/riapri/cartella/' + id_cartella).then((response) => {
					if (response.ok)
					{
						this.chiudiGallery();
						this.loadDati();
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					}
				}, (response) => { 
				});
			}
		},
		eliminaCartella(id_cartella) {
			if(confirm("Confermi l'eliminazione della cartella di lavoro e di tutto il suo contenuto (errori, esercizi, feedback) ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/perfezionamento/elimina/cartella/' + id_cartella).then((response) => {
					if (response.ok)
					{
						this.chiudiGallery();
						this.loadDati();
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					}
				}, (response) => { 
				});
			}
		},
		addTags() {
			if(this.form.skills_selected.length == 0) {
				alert("Devi selezionare almeno una skill!");
				return;
			} else if(this.form.skills_selected.length > 0) {
				var selSkills = false;
				for(var i in this.form.skills_selected) {
					var obj = this.form.skills_selected[i];
					if(Array.isArray(obj) && obj.length > 0) {
						selSkills = true;
						break;
					}
				}
				if(!selSkills) {
					alert("Devi selezionare almeno una skill!");
					return;
				}
			}
			if(this.form.tags_selected.length == 0) {
				alert("Devi selezionare almeno un videotag!");
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/perfezionamento/add/videotags', this.form).then((response) => {
				if (response.ok)
				{
					this.$refs.perfezModal.hide();
					var cartella_sel = this.id_cartella_selected;
					var tipo_sel = this.tipo_selected;
					this.chiudiGallery();
					if(cartella_sel) {
						this.loadDati(true, cartella_sel, tipo_sel);
					} else {
						this.loadDati();
					}
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, (response) => { 
			});
		},
		eliminaTag(id, id_cartella, tipo) {
			if(confirm("Confermi l'eliminazione del videotag dal perfezionamento ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/perfezionamento/del/videotag/' + id).then((response) => {
					if (response.ok)
					{
						this.chiudiGallery();
						this.loadDati(true, id_cartella, tipo);
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					}
				}, (response) => { 
				});
			}
		},
		addEsercizio() {
			if(this.form.skills_selected.length == 0) {
				alert("Devi selezionare almeno una skill!");
				return;
			} else if(this.form.skills_selected.length > 0) {
				var selSkills = false;
				for(var i in this.form.skills_selected) {
					var obj = this.form.skills_selected[i];
					if(Array.isArray(obj) && obj.length > 0) {
						selSkills = true;
						break;
					}
				}
				if(!selSkills) {
					alert("Devi selezionare almeno una skill!");
					return;
				}
			}
			if(!this.exe_selected) {
				alert("Devi selezionare un esercizio!");
				return;
			}
			this.form.esercizio = this.exe_selected.id;
			this.$http.post(this.$store.state.apiEndPoint + '/perfezionamento/add/esercizio', this.form).then((response) => {
				if (response.ok)
				{
					this.$refs.perfezModal.hide();
					var cartella_sel = this.id_cartella_selected;
					var tipo_sel = this.tipo_selected;
					this.exe_selected = null;
					this.chiudiGallery();
					if(cartella_sel) {
						this.loadDati(true, cartella_sel, tipo_sel);
					} else {
						this.loadDati();
					}
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, (response) => { 
			});
		},
		eliminaEsercizio(id, id_cartella, tipo) {
			if(confirm("Confermi l'eliminazione dell'esercizio dal perfezionamento ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/perfezionamento/del/esercizio/' + id).then((response) => {
					if (response.ok)
					{
						this.chiudiGallery();
						this.loadDati(true, id_cartella, tipo);
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					}
				}, (response) => { 
				});
			}
		},
		viewEsercizio(id_ese, id_cartella) {
			this.resetForm();
			this.form.id_perfez_ese = id_ese;
			var cartella = this.getCartellaById(id_cartella);
			for(var i in cartella.esercizi) {
				if(cartella.esercizi[i].id == id_ese) {
					this.exe_selected = cartella.esercizi[i].esercizio;
					this.form.videocartella = cartella.esercizi[i].id_videocartella;
				}
			}
			this.titolo = 'Personalizzato';
			this.$store.commit('editor/setExcerciseTab', 2);
			this.$store.commit('editor/setTitle', '');
			this.$store.commit('editor/setDrillId', null);
			this.$store.commit('editor/setObjIds', []);
			this.$store.commit('editor/setPage', 1);
			this.$store.dispatch('editor/filter_certificati');
			this.tipo_modal = 'esercizi';
			this.$refs.perfezModal.show();
			this.loadArchivio();
			this.loadVideocartelle();
			this.loadFiltri();
			if(Auth.isUserEnable('videotag_utenti')) {
				this.loadUtenti();
			}
			this.show_esercizi = true;
		},
		updEsercizio() {
			this.$http.post(this.$store.state.apiEndPoint + '/perfezionamento/update/esercizio', this.form).then((response) => {
				if (response.ok)
				{
					this.$refs.perfezModal.hide();
					var cartella_sel = this.id_cartella_selected;
					var tipo_sel = this.tipo_selected;
					this.exe_selected = null;
					this.chiudiGallery();
					if(cartella_sel) {
						this.loadDati(true, cartella_sel, tipo_sel);
					} else {
						this.loadDati();
					}
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				}
			}, (response) => { 
			});
		},
		getOptionsSkills(skills, only_root_skill) {
			var options = [];
			var skill_items = skills.fields;
			for(var key in skill_items) {
				var skill = skill_items[key];

				if(!only_root_skill) {
					if(skill.fields) {
						var items = skill.fields;
						for(var key2 in items) {
							var subskill = items[key2];
							if(subskill.fields) {
								var items2 = subskill.fields;
								for(var key3 in items2) {
									var subskill2 = items2[key3];
									var option = {
										id: subskill2.id,
										name: skill.it + " -> " + subskill.it + " -> " + subskill2.it,
									}
									options.push(option);      
								}
							} else {
								var option = {
									id: subskill.id,
									name: skill.it + " -> " + subskill.it,
								}
								options.push(option);
							}
						}
					} else {
						var option = {
							id: skill.id,
							name: skill.it,
						}
						options.push(option);
					}
				} else {
					var option = {
						id: skill.id,
						name: skill.it,
					}
					options.push(option);
				}
			}
			return options;
		},
		loadMacroSkillsOptions() {
			for(var i in this.skills) {
				var option = {
					macro: this.skills[i].it,
					opt_skills: this.getOptionsSkills(this.skills[i], true),
				}
				this.macroskill_options.push(option);
			}
		},
		loadFiltri() {
			this.$http.get(this.$store.state.apiEndPoint + '/videotags/filtri/archivio/' + this.utente + '/0').then((response) => {
				if (response.ok)
				{
					this.giocatori = response.data.giocatori;
					this.squadre = response.data.squadre;
					this.allenatori = response.data.allenatori;
					this.filter_skills = response.data.skills;
					this.mesi = response.data.mesi;
					this.anni = response.data.anni;

				}
			}, (response) => {

			});
		},
		loadUtenti() {
			this.$http.get(this.$store.state.apiEndPoint + '/videotags/utenti').then((response) => {
				if (response.ok)
				{
					this.utenti = response.data;
				}
			}, (response) => {

			});
		},
		loadArchivio() {
			this.loadingArchivio = true;
			this.pageTags = 1;
			this.videos = [];
			this.$http.get(this.$store.state.apiEndPoint + '/videotags/archivio/' + this.utente + '/0').then((response) => {
				if (response.ok)
				{
					this.videos = response.data.videos.data;
					this.total = response.data.videos.total;
					this.count_tag = response.data.videotags_count;
					this.tot_time_tag = response.data.videotags_time;
					this.loadingArchivio = false;
				}
			}, (response) => {
			});
		},
		filtraArchivio() {
			this.loadingArchivio = true;
			this.pageTags = 1;
			this.videos = [];
			this.$http.get(this.$store.state.apiEndPoint + '/videotags/archivio/' + this.utente + '/0', {params: this.selectedFields}).then((response) => {
				if (response.ok)
				{
					this.videos = response.data.videos.data;
					this.total = response.data.videos.total;
					this.count_tag = response.data.videotags_count;
					this.tot_time_tag = response.data.videotags_time;
					this.loadingArchivio = false;
				}
			}, (response) => {

			});
		},
		loadVideocartelle() {
			this.loadingCartelle = true;
			this.tags_cartelle_ids = [];
			this.$http.get(this.$store.state.apiEndPoint + '/videotags/cartelle/' + this.utente + '/0').then((response) => {
				if (response.ok)
				{
					this.videocartelle = response.data;
					for(var i in this.videocartelle) {
						var cartella = this.videocartelle[i];
						for(var x in cartella.videotags) {
							var videotag = cartella.videotags[x];
							this.tags_cartelle_ids.push(videotag.id);
						} 
					}
					this.loadingCartelle = false;
				}
			}, (response) => {

			});
		},
		reset() {
			this.selectedFields = {
				playerOrTeam: '',
				action: '',
				player: '',
				team: '',
				coach: '',
				positiveOrNegative: '',
				defensiveOrOffensive: '',
				fieldSection: '',
				keyword: '',
				anno: '',
				mese: '',
			};
		},
		nextPage() {
			if(this.videos.length < this.total) {
				this.pageTags++;
				this.loadingArchivio = true;
				this.$http.get(this.$store.state.apiEndPoint + '/videotags/archivio/' + this.utente + '/0' + '?page=' + this.pageTags, {params: this.selectedFields}).then((response) => {
              // success callback
              if (response.ok)
              {
              	this.videos = this.videos.concat(response.data.videos.data);
              	this.loadingArchivio = false;
              }
          }, (response) => {
                // error callback
            });
			}
		},
		nextPageEx() {
			if(this.page < this.exercises_certificati.last_page) {
				this.$store.commit('editor/setPage', this.page + 1);
				this.$store.dispatch('editor/filter_certificati', this.page + 1);
			}
		},
		valueIsArray(value) {
			return Array.isArray(value);
		},
		nest: function(obj, keys, v) {
			if (keys.length === 1) {
				obj[keys[0]] = v;
			} else {
				var key = keys.shift();
				obj[key] = this.nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v);
			}

			return obj;
		},
		partitaLabel(squadre) {
			if(squadre.length > 0) {
				if(squadre[0].pivot.side == 'home') {
					return squadre[0].name + " - " + squadre[1].name;
				} else return squadre[1].name + " - " + squadre[0].name;
			} else {
				return "Home - Away"
			}
		},
		matchLabel(match) {
			if(match.home) {
				return match.opponent + " - Roma ";   
			} else {
				return " Roma " + " - " + match.opponent;      
			}
		},
		matchTypeLabel(match) {
			var label = match.category.name;
			if(match.type > 0) {
				label += " - " + this.tipoPartita[match.type - 1];
			} 
			return label; 
		},
		allenamentoTypeLabel(allenamento) {
			var label = allenamento.category.name;
			label += " - " + this.tipoAllenamento[allenamento.level];
			return label; 
		},
		azioniLabel(azioni) {
			if(azioni.length > 0) {
				var nome = "";
				for(var i in azioni) {
					var azione = azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			} else {
				return "Azione generica"
			}
		},
		soggettoLabel(videotag) {
			var soggetto = "";
			if(videotag.tipotag == "giocatore") {
				soggetto = videotag.giocatore.cognome + " " + videotag.giocatore.nome;
			} else if(videotag.tipotag == "squadra") {
				soggetto = videotag.squadra.name;
			} else if(videotag.tipotag == "allenatore") {
				soggetto = videotag.allenatore.cognome + " " + videotag.allenatore.nome;
			}
			return soggetto;     
		},
		playClip(id) {
			this.$video.src({type: 'video/mp4', src: this.$store.state.apiEndPoint + '/videotag/clip/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token});
			this.$video.play();
		},
		viewGallery(id_cartella, tipo) {
			this.gallery = [];
			var cartella = this.getCartellaById(id_cartella);
			if(cartella) {
				if(tipo == 'negativi') {
					this.gallery = cartella.errori;
				} else if(tipo == 'positivi') {
					this.gallery = cartella.feedback;
				} else if(tipo == 'esercizi') {
					for(var i in cartella.esercizi) {
						var esercizio = cartella.esercizi[i];
						if(esercizio.videocartella && esercizio.videocartella.videotags.length > 0) {
							for(var j in esercizio.videocartella.videotags) {
								var obj = {
									id: esercizio.id,
									videotag: esercizio.videocartella.videotags[j],
									skills: esercizio.skills,
									giocatore: esercizio.giocatore,
									count_allen: esercizio.count_allen,
									minuti_allen: esercizio.minuti_allen,
									videocartella: esercizio.videocartella.nome,
								}
								this.gallery.push(obj);
							}
						} else {
							this.gallery.push(esercizio);
						}
					}
				}
				this.id_cartella_selected = cartella.id;
				this.tipo_selected = tipo;
			} else {
				this.id_cartella_selected = null;
				this.tipo_selected = 'negativi';
			}
		},
		getCartellaById(id) {
			for(var i in this.cartelle) {
				if(this.cartelle[i].id == id) {
					return this.cartelle[i];
				}
			}
			return null;
		},
		chiudiGallery() {
			this.id_cartella_selected = null;
			this.tipo_selected = 'negativi';
			this.gallery = [];
		},
		getUrlVideo(id) {
			return this.$store.state.apiEndPoint + '/videotag/clip/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token;
		}, 
		selezionaEsercizio(item) {
			this.exe_selected = item;
		},
		resetEsercizio() {
			this.exe_selected = null;
		},
		getLabelSkill(id_skill, skills) {
			var label = "";
			var skill_items = skills.fields;
			for(var key in skill_items) {
				var skill = skill_items[key];
				if(skill.id == id_skill) {
					label = skill.it;
					return label;
				}
				if(skill.fields) {
					var items = skill.fields;
					for(var key2 in items) {
						var subskill = items[key2];
						if(subskill.fields) {
							var items2 = subskill.fields;
							for(var key3 in items2) {
								var subskill2 = items2[key3];
								if(subskill2.id == id_skill) {
									label = skill.it + " -> " + subskill.it + " -> " + subskill2.it;
									return label;
								}  
							}
						} else {
							if(subskill.id == id_skill) {
								label = skill.it + " -> " + subskill.it;
								return label;
							} 		
						}
					}
				} else {
					if(skill.id == id_skill) {
						label = skill.it;
						return label;
					}
				}
			}
			return label;
		},
		durataCartella(cartella) {
			var time = 0;
			for(var x in cartella.videotags) {
				var videotag = cartella.videotags[x];
				time = time + (moment(videotag.fine, "HH:mm:ss") - moment(videotag.inizio, "HH:mm:ss"));
			}
			return moment.utc(time).format("HH:mm:ss");
		},
		onShow() {
			if(!this.$video) {
				this.$video = videojs('perfez-wrapper');
				this.$video.errors({
					"errors": this.errors_player,
				});
			}
		},
		onHidden() {
			this.$video.pause();
			this.$video.reset();
		},
		getGradimenti() {
			this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
				if (response.ok)
				{
					this.gradimenti = response.data; 
				}
			}, (response) => {
            // error callback
        });
		},
		selectGradimento(id) {
			this.form.gradimento = id;
		},
	},
	computed: {
		auth: function() {
			return Auth;
		},
		filters() {
			return {
				playerOrTeam: {
					name: 'Tipo Tag',
					values: [
					{ id: 0, name: 'Squadra' },
					{ id: 1, name: 'Giocatore' },
					{ id: 2, name: 'Allenatore' },
					],
				},
				action: {
					name: 'Azione',
					values: this.actionValues,
				},
				player: {
					name: 'Giocatore',
					values: this.playerValues,
				},
				team: {
					name: 'Squadra',
					values: this.teamValues,
				},
				coach: {
					name: 'Allenatore',
					values: this.coachValues,
				},
				positiveOrNegative: {
					name: 'Positivo o Negativo',
					values: [
					{ id: 1, name: 'Positivo' },
					{ id: 0, name: 'Negativo' },
					],
				},
				defensiveOrOffensive: {
					name: 'Difensivo o Offensivo',
					values: [
					{ id: 0, name: 'Difensivo' },
					{ id: 1, name: 'Offensivo' },
					],
				},
				fieldSection: {
					name: 'Sezione campo',
					values: [
					{ id: 1, name: 'Zona 1' },
					{ id: 2, name: 'Zona 2' },
					{ id: 3, name: 'Zona 3' },
					{ id: 4, name: 'Zona 4' },
					{ id: 5, name: 'Zona 5' },
					{ id: 6, name: 'Zona 6' },
					{ id: 7, name: 'Zona 7' },
					{ id: 8, name: 'Zona 8' },
					{ id: 9, name: 'Zona 9' },
					],
				},
				anno: {
					name: 'Anno',
					values: this.anniValues,
				},
				mese: {
					name: 'Mese',
					values: this.mesiValues,
				}
			};
		},
		actionValues() {
			var jsonObject = {};
			for (var k in this.filter_skills) {
				var item = this.filter_skills[k];          
				if(item.fields) {
					for (var j in item.fields) {
						var item2 = item.fields[j];
						if(item2.fields) {

							var obj = {id: item2.id, name: item2.it};
							var array = [];
							if(typeof(jsonObject[item.it]) !== 'undefined') {
								array = jsonObject[item.it];
							} 
							array.push(obj);
							jsonObject = this.nest(jsonObject, [item.it], array);

							if(!this.only_root_skill) {
								for (var y in item2.fields) {
									var item3 = item2.fields[y];
									if(item3.fields) {
										for (var x in item3.fields) {
											var item4 = item3.fields[x];
											var obj = {id: item4.id, name: item2.it + " -> " + item3.it + " -> " + item4.it};
											var array = [];
											if(typeof(jsonObject[item.it]) !== 'undefined') {
												array = jsonObject[item.it];
											} 
											array.push(obj);
											jsonObject = this.nest(jsonObject, [item.it], array);
										}

									} else {
										var obj = {id: item3.id, name: item2.it + " -> " + item3.it };
										var array = [];
										if(typeof(jsonObject[item.it]) !== 'undefined') {
											array = jsonObject[item.it];
										} 
										array.push(obj);
										jsonObject = this.nest(jsonObject, [item.it], array);
									}    
								}
							}

						} else {
							var obj = {id: item2.id, name: item2.it};
							var array = [];
							if(typeof(jsonObject[item.it]) !== 'undefined') {
								array = jsonObject[item.it];
							} 
							array.push(obj); 
							jsonObject = this.nest(jsonObject, [item.it], array);      
						}    
					}
				}
			}
			return jsonObject;
		},
		playerValues() {
			var values = [];
			for(var i in this.giocatori) {
				var giocatore = {
					id: this.giocatori[i].id,
					name: this.giocatori[i].cognome + " " + this.giocatori[i].nome,
				};
				values.push(giocatore);
			}
			return values;
		},
		teamValues() {
			var values = [];
			for(var i in this.squadre) {
				var squadra = {
					id: this.squadre[i].id,
					name: this.squadre[i].name,
				};
				values.push(squadra);
			}
			return values;
		},
		coachValues() {
			var values = [];
			for(var i in this.allenatori) {
				var allenatore = {
					id: this.allenatori[i].id,
					name: this.allenatori[i].cognome + " " + this.allenatori[i].nome,
				};
				values.push(allenatore);
			}
			return values;
		},
		anniValues() {
			var values = [];
			for(var i in this.anni) {
				var anno = {
					id: this.anni[i].anno,
					name: this.anni[i].anno,
				};
				values.push(anno);
			}
			return values;
		},
		mesiValues() {
			var values = [];
			for(var i in this.mesi) {
				var mese = {
					id: this.mesi[i].mese,
					name: this.mesiLabel[this.mesi[i].mese - 1],
				};
				values.push(mese);
			}
			return values;
		},
		setup() {
			return JSON.stringify({
				techOrder: ['html5'],
				width: '100%',
				height: '100%',
				aspectRatio: '16:9',
				youtube: {
					iv_load_policy: 3,
					modestbranding: 1,
					rel: 0,
					showinfo: 0,
				},
			});
		},
		partite() {
			return this.total;
		},
		videotags_count() {
			return this.count_tag;
		},
		videotags_time() {
			return moment.utc(this.tot_time_tag * 1000).format("HH:mm:ss");
		},
		labelUtente() {
			for(var i in this.utenti) {
				if(this.utenti[i].id == this.utente) {
					return this.utenti[i].nomeCompleto; 
				}
			}
		},
		... mapState('editor', ['exercises_certificati', 'page']),
		... mapState(['rosaTeamId', 'seasonId']),
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment.utc(date).local();
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
		formatDate: function (date) {
			var m = moment.utc(date).local();
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
	watch: {
		utente: function () {
			this.$video.pause();
			this.$video.reset();
			this.reset();
			this.loadArchivio();
			this.loadVideocartelle();
			this.loadFiltri();
		},
		rosaTeamId() {
			this.chiudiGallery();
			this.loadDati();
		},
		seasonId() {
			this.chiudiGallery();
			this.loadDati();
		},
		skills() {
			this.loadMacroSkillsOptions();
		},  
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
	},
	created: function () {
		this.loadMacroSkillsOptions();
		this.getGradimenti();
		this.loadDati();
	},
	components: {
		card: require('../layout/card.vue').default,
		'v-infinite-scroll': InfiniteScroll,
		carousel,
	},
}
</script>

<style>

.card-img-overlay {
	padding: 0;
}

.triangle-up-left-0 {
	width: 0;
	height: 0;
	padding-bottom: 55%;
	padding-left: 75%;
	overflow: hidden;
}
.triangle-up-left-0:after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	margin-left: -500px;
	border-bottom: 500px solid transparent;
	border-left: 500px solid rgba(135, 206, 235, 0.9);
}

.triangle-up-left-1 {
	width: 0;
	height: 0;
	padding-bottom: 55%;
	padding-left: 75%;
	overflow: hidden;
}
.triangle-up-left-1:after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	margin-left: -500px;
	border-bottom: 500px solid transparent;
	border-left: 500px solid rgba(102, 255, 0, 0.9);
}

.my_gallery .owl-stage {
	display: flex;
	flex-direction: row;
}

.cartella_selected {
	background-color: yellow;
}

</style>
