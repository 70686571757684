export const ROUTES_VIDEOTAG = [
    {
                    path: '/videotag/dashboard',
                    redirect: '/videotag/archivio',
    },
    {
        path: '/videotag/archivio',
        name: 'videotag_archivio',
        component: require('../components/pages/videotag/videotag.vue').default,
        meta: {
            module: 'videotag',

            label: 'Videotag',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'videotag',
        }
    },
];
