<template id="editor">
    <section class="editor">

        <headapp></headapp>

        <menusub></menusub>

        <card class="border-0 rounded-0" comp="piscinaesercizieditor" :params="{ titolo: 'Esercizio' }"></card>

        <footerapp></footerapp>

    </section>
</template>

<script>
    export default {

        components: {
            headapp:            require('../../../layout/headapp.vue').default,
            menusub:            require('../../../layout/menusub.vue').default,
            footerapp:          require('../../../layout/footerapp.vue').default,
            card:               require('../../../layout/card.vue').default,
        }

    }
</script>
