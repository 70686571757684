export const ROUTES_CONTRATTI = [
    {
        path: '/contratti/dashboard',
        name: 'contratti_dashboard',
        component: require('../components/pages/contratti/dashboard.vue').default,
        meta: {
            module: 'contratti',
            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
            permesso: 'contratti_app',
            logCategory: true,
        }
    },
    {
        path: '/contratti/index',
        name: 'contratti_index',
        component: require('../components/pages/contratti/index.vue').default,
        meta: {
            module: 'contratti',
            label: 'Indice Contratti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            enableRosaTeam: true,
            showSeason: true,
            enableSeason: true,
            permesso: 'contratti_app',
            logCategory: true,
        }
    },
    {
        path: '/contratto/:id',
        name: 'contratti_detail',
        component: require('../components/pages/contratti/detail.vue').default,
        meta: {
            module: 'contratti',
            label: 'Dettaglio Contratto',
            requiresAuth: true,
            showInMenu: false,
            parent: 'contratti_dashboard',
            menusub: [ 'medical_giocatore_scheda', 'medical_injuries', 'medical_illnesses', 'medical_injuries_rtp'],
            permesso: 'contratti_app',
            logModel: 'Persona_Contratto',
            logModelId: 'id', 
            logCategory: true,
        }
    },
    {
        path: '/contratto/:id/edit',
        name: 'contratti_edit',
        component: require('../components/pages/contratti/edit.vue').default,
        meta: {
            module: 'contratti',
            label: 'Modifica Contratto',
            requiresAuth: true,
            showInMenu: false,
            parent: 'contratti_dashboard',
            menusub: [ 'medical_giocatore_scheda', 'medical_injuries', 'medical_illnesses', 'medical_injuries_rtp'],
            permesso: 'contratti_app',
            logModel: 'Persona_Contratto',
            logModelId: 'id', 
            logCategory: true,
        }
    },
    {
        path: '/contratti/player/:id/new',
        name: 'contratti_new',
        component: require('../components/pages/contratti/new.vue').default,
        meta: {
            module: 'contratti',
            label: 'Nuovo Contratto',
            requiresAuth: true,
            showInMenu: false,
            parent: 'contratti_dashboard',
            menusub: [ 'medical_giocatore_scheda', 'medical_injuries', 'medical_illnesses', 'medical_injuries_rtp'],
            permesso: 'contratti_app',
            logModel: 'Persona',
            logModelId: 'id', 
            logCategory: true,
        }
    },
    {
        path: '/contratti/variabili-squadra',
        name: 'contratti_variabili_stagione',
        component: require('../components/pages/contratti/variabili-stagione.vue').default,
        meta: {
            module: 'contratti',
            label: 'Variabili Stagione',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            menusub: [ 'medical_giocatore_scheda', 'medical_injuries', 'medical_illnesses', 'medical_injuries_rtp'],
            permesso: 'contratti_app',
            logCategory: true,
        }
    },
    {
        path: '/contratti/editor',
        name: 'contratti_editor',
        component: require('../components/pages/contratti/editor-presets.vue').default,
        meta: {
            module: 'contratti',
            label: 'Editor Preset',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            menusub: [ 'medical_giocatore_scheda', 'medical_injuries', 'medical_illnesses', 'medical_injuries_rtp'],
            permesso: 'contratti_app',
            logCategory: true,
        }
    },
    {
        path: '/contratti/editor/new',
        name: 'contratti_editor_new',
        component: require('../components/pages/contratti/new-preset.vue').default,
        meta: {
            module: 'contratti',
            label: 'Editor Preset',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            menusub: [ 'medical_giocatore_scheda', 'medical_injuries', 'medical_illnesses', 'medical_injuries_rtp'],
            permesso: 'contratti_app',
            logCategory: true,
        }
    },
    {
        path: '/contratti/editor/get/:preset_id',
        name: 'contratti_editor_detail',
        component: require('../components/pages/contratti/edit-preset.vue').default,
        meta: {
            module: 'contratti',
            label: 'Editor Preset',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            menusub: [ 'medical_giocatore_scheda', 'medical_injuries', 'medical_illnesses', 'medical_injuries_rtp'],
            permesso: 'contratti_app',
            logModel: 'Persona_Contratto_Regole_Glo',
            logModelId: 'preset_id', 
            logCategory: true,
        }
    }
];
