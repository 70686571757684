<template id="opponents">
	<section class="opponents">

		<headapp></headapp>

		<template id="menusub">
			<div class="menusub">
				<div class="card">
					<div class="card-body">
						<ul class="list-inline m-0">
							<li class="list-inline-item">
								<b-button variant="outline-primary" :to="{ path: `/football/squadre`}"><i class="fa fa-arrow-left fa-fw"></i> Torna alla ricerca della squadra</b-button>
								<b-button variant="outline-primary" :to="{ path: `/football/squadre/view/` + $route.params.id}">Scheda squadra</b-button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</template>
		<div class="card">
			<div class="card-body">

				<div class="row flex-items-xs-middle">
					<div class="col-1">
						<img v-bind:src="team.logo_url" height="80">
					</div>
					<div class="col-3">
						<div class="text-uppercase color-rosso font-bold font-size-lg">
							{{ team.officialName }}
						</div>
						<div class="text-muted">
							{{ team.city }}
						</div>
					</div>
					<div class="col-2">
						
						<div class="font-bold font-size-lg">
							{{ team.competizione.nome }}
						</div>
					</div>
					
					<div class="col-6">
						<b-row>
							<b-col cols="5">
								<b-form-group horizontal label="Periodo dal" label-cols="4" label-align-sm="right">
									<b-form-input type="date" v-model="filters.dal"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="5">
								<b-form-group horizontal label="al" label-cols="3" label-align-sm="right">
									<b-form-input type="date" v-model="filters.al"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col class="text-center">
								<b-button @click="getDataReport" :disabled="!filters.dal || !filters.al || filters.dal > filters.al">Report <i class="fa fa-search"></i></b-button>
							</b-col>
						</b-row>	
					</div>
				</div>
			</div>
		</div>

		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="content">
					<b-overlay :show="isLoading" rounded="sm">
						<players-info-card :players="players" class="mb-1"></players-info-card>
						<players-info-table :players="players" class="mb-1"></players-info-table>
						<team-stats :stats="team_stats" :goals="team_goals" :team="team" :formations="team_formations" class="mb-1"></team-stats>
						<player-stats :stats="player_stats" class="mb-1"></player-stats>
						<players-index :stats="players_index" class="mb-1"></players-index>
						<team-matches :stats="team_matches" :team="team" class="mb-1"></team-matches>
						<team-skills-offensive :stats="team_skills_offensive" :team="team" class="mb-1"></team-skills-offensive>
						<team-skills-difensive :stats="team_skills_difensive" :team="team" class="mb-1"></team-skills-difensive>
						<team-corners :stats="team_corners" :team="team" class="mb-1"></team-corners>
						<players-tiri-open-play :players="players" class="mb-1"></players-tiri-open-play>
						<players-cross-open-play :players="players"></players-cross-open-play>
					</b-overlay>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	data: function(){
		return {
			team: null,
			loading1: false,
			loading2: false,
			loading3: false,
			loading4: false,
			loading5: false,
			loading6: false,
			loading7: false,
			loading8: false,
			loading9: false,
			filters: {
				dal: null,
				al: null
			},
			player_stats: null,
			team_stats: null,
			team_goals: null,
			team_skills_offensive: null,
			team_skills_difensive: null,
			team_corners: null,
			team_matches: null,
			players_index: null,
			team_formations: null,
		}
	},
	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		playersInfoCard: require('@/components/layout/report-avversario/players-info-card.vue').default,
		playersInfoTable: require('@/components/layout/report-avversario/players-info-table.vue').default,
		playersTiriOpenPlay: require('@/components/layout/report-avversario/players-tiri-open-play.vue').default,
		playersCrossOpenPlay: require('@/components/layout/report-avversario/players-cross-open-play.vue').default,
		teamStats: require('@/components/layout/report-avversario/team-stats.vue').default,
		playerStats: require('@/components/layout/report-avversario/player-stats.vue').default,
		teamSkillsOffensive: require('@/components/layout/report-avversario/team-skills-offensive.vue').default,
		teamSkillsDifensive: require('@/components/layout/report-avversario/team-skills-difensive.vue').default,
		teamCorners: require('@/components/layout/report-avversario/team-corners.vue').default,
		teamMatches: require('@/components/layout/report-avversario/team-matches.vue').default,
		playersIndex: require('@/components/layout/report-avversario/players-index.vue').default,
	},
	created() {
		this.getTeam();
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		isLoading() {
			return this.loading1 || this.loading2 || this.loading3 || this.loading4 || this.loading5 || this.loading6 || this.loading7 || this.loading8 || this.loading9;
		},
		players: function() {
			return this.player_stats && this.player_stats.players ? this.player_stats.players : [];
		}
	},
	methods: {
		getTeam() {		
			this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.$route.params.id).then((response) => {
				this.team = response.data;
			});
		},
		getDataReport() {
			this.getPlayerStats();
			this.getTeamStats();
			this.getTeamGoals();
			this.getTeamSkillsOffensive();
			this.getTeamSkillsDifensive();
			this.getTeamCorners();
			this.getTeamMatches();
			this.getPlayerIndex();
			this.getTeamFormations();
		},
		getPlayerStats() {	
			this.loading1 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/player/stats', {params: { from: this.filters.dal, to: this.filters.al, team: this.team.id, league: this.team.competizione_id}}).then((response) => {

				this.player_stats = {
					...response.data,
					players: this.orderingPlayers( response?.data?.players )
				};

				this.loading1 = false;
			});
		},
		getTeamStats() {	
			this.loading2 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/team/stats', {params: { from: this.filters.dal, to: this.filters.al, league: this.team.competizione_id}}).then((response) => {
				this.team_stats = response.data;
				this.loading2 = false;
			});
		},
		getTeamGoals() {	
			this.loading3 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/team/goals', {params: { from: this.filters.dal, to: this.filters.al, league: this.team.competizione_id}}).then((response) => {
				this.team_goals = response.data;
				this.loading3 = false;
			});
		},
		getTeamSkillsOffensive() {	
			this.loading4 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/team/skills/offensive', {params: { from: this.filters.dal, to: this.filters.al, league: this.team.competizione_id}}).then((response) => {
				this.team_skills_offensive = response.data;
				this.loading4 = false;
			});
		},
		getTeamSkillsDifensive() {	
			this.loading5 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/team/skills/difensive', {params: { from: this.filters.dal, to: this.filters.al, league: this.team.competizione_id}}).then((response) => {
				this.team_skills_difensive = response.data;
				this.loading5 = false;
			});
		},
		getTeamCorners() {	
			this.loading6 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/team/corners', {params: { from: this.filters.dal, to: this.filters.al, league: this.team.competizione_id}}).then((response) => {
				this.team_corners = response.data;
				this.loading6 = false;
			});
		},
		getTeamMatches() {	
			this.loading7 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/team/matches', {params: { from: this.filters.dal, to: this.filters.al, league: this.team.competizione_id, team: this.team.id}}).then((response) => {
				this.team_matches = response.data;
				this.loading7 = false;
			});
		},
		getPlayerIndex() {	
			this.loading8 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/player/index', {params: { from: this.filters.dal, to: this.filters.al, team: this.team.id, league: this.team.competizione_id}}).then((response) => {
				
				console.log(response?.data);
				
				this.players_index = {
					...response.data,
					players: this.orderingPlayers( response?.data?.players )
				};

				this.loading8 = false;
			});
		},
		getTeamFormations() {	
			this.loading9 = true;	
			this.$http.get(this.$store.state.apiEndPoint + '/provision/team/formations', {params: { from: this.filters.dal, to: this.filters.al, league: this.team.competizione_id, team: this.team.id}}).then((response) => {
				this.team_formations = response.data;
				this.loading9 = false;
			});
		},
		orderingPlayers( players ){
			
			if( !players ) return []

			/** Custom ordering */
			const positionOrder = {
				'Portiere': 1,
				'Difensore': 2,
				'Centrocampista': 3,
				'Attaccante': 4
			};

			const playersOrdered = players.sort((p1, p2) => {
				const orderingIndexA = positionOrder[p1.genericPosition] || 5;
				const orderingIndexB = positionOrder[p2.genericPosition] || 5;
				return orderingIndexA - orderingIndexB;
			});
			/** END - Custom ordering */

			return playersOrdered
		}
	}
}
</script>
