<template id="form">
	<div class="card border-0 rounded-0">
		<div class="card-body">
			<b-container>

				<b-row>
					<b-col cols="12">
						<form v-on:submit.prevent="checkBeforeSubmit()" action="#" method="POST">
							<div class="content">
								<fieldset class="mb-5">
									<legend>Foto</legend>
									<b-form-group class="pb-3 border-bottom">
										<picture-input ref="picturePlayer" @change="onAvatarChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="avatar"></picture-input>
									</b-form-group>
									<legend>Generali</legend>
									<b-form-group id="nomeGroup" horizontal label="Nome" :label-cols="3" label-for="nome">
										<b-form-input id="nome" type="text" v-model="form.nome" ></b-form-input>
									</b-form-group>
									<b-form-group id="cognomeGroup" horizontal label="Cognome" :label-cols="3" label-for="cognome">
										<b-input-group>
											<b-form-input id="cognome" type="text" v-model="form.cognome" ></b-form-input>
											<b-input-group-append>
												<b-button variant="secondary" @click="searchTeam" title="Cerca per squadra"><i class="fa fa-users" aria-hidden="true"></i></b-button>
												<b-button variant="primary" @click="searchPlayer" title="Cerca per nome"><i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>

									<b-form-group id="dataDiNascitaGroup" horizontal label="Data di nascita" :label-cols="3" label-for="data_nascita">
										<b-form-input id="data_nascita" type="date" v-model="form.nascita_data"></b-form-input>
									</b-form-group>
									<b-form-group id="paeseNascitaGroup" horizontal label="Paese di nascita" :label-cols="3" label-for="paese_nascita">
										<autocomplete-paese :suggestions="paesi" id="paese_nascita" ref="paese_nascita" @select="onPaeseNascitaSelect" @change="onPaeseNascitaChange" v-model="form.nascita_paese_id"></autocomplete-paese>
									</b-form-group>
									<b-form-group id="ruoloIdealeGroup" horizontal label="Ruolo ideale" :label-cols="3">
												<b-form-select v-model="form.last_carriera_sportiva.ruolo_ideale_id" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
											</b-form-group>
									<b-form-group id="magliaGroup" horizontal label="Numero maglia" :label-cols="3">
										<b-form-select v-model="form.last_carriera_sportiva.maglia">
											<option :value="null">seleziona</option>
											<option v-for="(value, index) in 99" :value="value" :key="index">{{value}}</option>
										</b-form-select>
									</b-form-group>
									<b-form-group id="altezzaGroup" horizontal label="Altezza (cm)" :label-cols="3" label-for="altezza">
										<b-form-input id="altezza" type="text" v-model="form.info_fisica.altezza"></b-form-input>
									</b-form-group>
									<b-form-group id="piedeGroup" horizontal label="Piede" :label-cols="3" label-for="piede">
										<b-form-radio-group id="piede" v-model="form.giocatore.piede">
											<b-form-radio value="0">Destro</b-form-radio>
											<b-form-radio value="1">Sinistro</b-form-radio>
											<b-form-radio value="2">Ambidestro</b-form-radio>
										</b-form-radio-group>
									</b-form-group>

								</fieldset>
								<div class="d-flex align-items-center">
									<b-form-group class="text-right">
										<b-button type="submit" variant="primary" class="text-uppercase"><strong>Salva Informazioni</strong></b-button>
									</b-form-group>
								</div>

							</div>
						</form>
					</b-col>

				</b-row>
			</b-container>
		</div>
		<b-modal id="searchPlayerModal" ref="searchPlayerModal" title="Ricerca giocatore su wyscout">
			<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
			<p v-if="!is_search && !players.length">Nessun giocatore trovato!</p>
			<div v-if="!is_search && players.length">
				<p>Trovati {{players.length}} giocatori!, su wyscout</p>
				<hr/>
				<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
					<div v-for="(player, index) in players" :key="index" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaGiocatore(player)">
						<div class="col-md-3 my-1 mr-1" style="text-align: center;">
							<img v-if="player.imageDataURL" :src="player.imageDataURL"/>
						</div>
						<div class="col-md-6 my-1">
							<h5 style="display: inline;">{{ player.lastName }} {{ player.firstName }}</h5><br />
							<span class="text-muted" v-if="player.birthArea"><flag v-if="player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="24"></flag> {{ player.birthArea.name }} </span><br />
							<span class="text-muted">{{ player.birthDate | formatDate }}</span><br />
							<span class="text-muted">{{ player.role.name }}</span><br />
							<span class="text-muted" v-if="player.currentTeam"><flag v-if="player.birthArea.alpha2code" :country="player.currentTeam.area.alpha2code" :size="24"></flag> {{ player.currentTeam.officialName }}</span><br />     
						</div>
						<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
							<img v-if="player.currentTeam" :src="player.currentTeam.imageDataURL" width="100" />
						</div>
					</div>
				</div>
			</div>
		</b-modal>	

		
		<b-modal id="searchTeamModal" ref="searchTeamModal" title="Ricerca squadra" ok-only ok-title="Chiudi">
			<div v-if="show_aree">
				<span>Seleziona un'area: </span>
				<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
					<div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<b-row>
						<b-col cols="3" v-for="area in aree" v-bind:key="area.id" style="padding: 0.2rem">
							<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.sigla3)">
								<flag :country="area.sigla" :size="24"></flag><br/>
								{{area.paese}}
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
			<div v-if="show_competizioni">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
				<div v-if="competizioni.length > 0">
					<span>Seleziona una competizione: </span>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<b-row>
							<b-col cols="4" v-for="(comp, index) in competizioni" v-bind:key="index" style="padding: 0.2rem">
								<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp)">
									<strong>{{comp.name}}</strong><br/>
									<span class="font-size-xs color-rosso">{{$i18n.t(comp.format)}}</span><br/>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
			<div v-if="show_squadre">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
				<div v-if="squadre.length > 0">
					<span>Seleziona una squadra: </span>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<b-row>
							<b-col cols="4" v-for="team in squadre" v-bind:key="team.id" style="padding: 0.2rem">
								<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selezionaSquadra(team)">
									<img :src="team.imageDataURL" width="50" /><br/>
									<strong>{{team.officialName}}</strong><br/>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal id="checkPersonaModal" ref="checkPersonaModal" title="Attenzione!" ok-only ok-title="Chiudi">
			<b-row>
				<b-col class="border-right pr-2" v-if="$route.params.id || persona_id">
					<p>Sono già presenti su AS Roma System i seguenti individui con questo Wyscout ID ({{ form.wyscout.chiave }}):</p>
					<hr/>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;">
							<div class="col-md-3 my-1 mr-3" style="text-align: center;">
								<img :src="persona.avatar_url" height="100"/>
							</div>
							<div class="col-md-6 my-1" style="font-size:0.85rem">
								<h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
								<span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
								<span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
								<span class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli && persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</span><br />
								<span class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }}</span><br />     
							</div>
							<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
								<img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
							</div>
						</div>
					</div>
					<div>Premi su <b-button variant="link" class="py-0" @click.prevent="forceSubmit"><strong>SALVA</strong></b-button> per forzare comunque l'aggiornamento del Wyscout ID sull'anagrafica del giocatore</div>
				</b-col>
				<b-col class="border-right pr-2" v-else>
					<p>Sono già presenti su AS Roma System i seguenti individui corrispondenti ai campi inseriti, seleziona un'anagrafica tra quelle disponibili se ritieni corrisponda al giocatore che vuoi inserire</p>
					<hr/>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="(persona, index) in persone" :key="index" class="row mb-2 row_persona" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPersona(persona.id)">
							<div class="col-md-3 my-1 mr-3" style="text-align: center;">
								<img :src="persona.avatar_url" height="100"/>
							</div>
							<div class="col-md-6 my-1" style="font-size:0.85rem">
								<h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
								<span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
								<span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
								<span class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli && persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</span><br />
								<span class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }}</span><br />     
							</div>
							<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
								<img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
							</div>
						</div>
						<div class="row mb-2 row_persona" :style="'border-bottom: 1px solid #E5E5E5; cursor: pointer;background-color:' + (show_force_submit ? '#ffff00' : '')" v-on:click="show_force_submit = true">
							<div class="col-md-3 my-1 mr-3" style="text-align: center;">
							</div>
							<div class="col-md-6 my-1 py-4" style="font-size:0.85rem">
								<h6 style="display: inline;">Nessuna corrispodenza trovata.<br />Crea nuova anagrafica.</h6>
							</div>
							<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
							</div>
						</div>
					</div>
					<b-button size="sm" v-if="show_force_submit" @click.prevent="forceSubmit"><strong>SALVA</strong></b-button>
					<!--p>Come vuoi procedere?</p>
					<p>- Seleziona un'anagrafica tra quelle sopra disponibili se ritieni corrisponda al giocatore che vuoi inserire</p>
					<p><strong>oppure</strong></p>
					<div>- Premi su <b-button variant="link" class="py-0" @click.prevent="forceSubmit"><strong>SALVA</strong></b-button> per forzare l'inserimento di una nuova anagrafica</div-->
				</b-col>
			</b-row>
		</b-modal>
	</div>

</template>


<script>

import moment from 'moment'
import axios from 'axios';
import * as types from '../../../../store/types'
export default {

	data: function(){
		return {
			image_player: '',
			file_avatar: '',
			form: {			
				nome: null,
				cognome: null,
				nascita_data: null,
				nascita_paese_id: 0,
				wyscout: {
					id: 0,
					chiave: null,
				},
				last_carriera_sportiva: {
					id: 0,
					maglia: null,
					ruolo_ideale_id: null,
				},
				giocatore: {
					id: 0,
					piede: null,
				},
				info_fisica: {
					id: 0,
					altezza: null,
				}
			},
			is_search: false,
			players: [],
			paesi: [],
			aree: [],
			competizioni: [],
			squadre: [],
			show_aree: false,
			show_competizioni: false,
			show_squadre: false,
			customStrings: {
				drag: this.$i18n.t('drag'),
				fileSize: this.$i18n.t('fileSize'),
				fileType: this.$i18n.t('fileType'),
			},
			team: null,
			giocatore: null,
			persone: [],
			persona_id: null,
			show_force_submit: false,
			ruoli: [],
		}
	},

	methods: {
		checkBeforeSubmit() {
			this.show_force_submit = false;
			this.persone = [];
			var checkRequest = false;
			var formData = new FormData();
			if(this.$route.params.id) {
				formData.set('id', this.$route.params.id);  
				if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
					formData.set('wyscout_id', this.form.wyscout.chiave); 
					checkRequest = true;                      
				} 
			} else if(this.persona_id) {
				formData.set('id', this.persona_id); 
				if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
					formData.set('wyscout_id', this.form.wyscout.chiave); 
					checkRequest = true;                      
				}  
			} else {
				if(this.form.cognome && this.form.cognome.trim() != "") {
					formData.set('cognome', this.form.cognome.trim()); 
					checkRequest = true;                      
				}
				if(this.form.nome && this.form.nome.trim() != "") {
					formData.set('nome', this.form.nome.trim());
					checkRequest = true;                       
				}
				if(this.form.nascita_data && this.form.nascita_data != '0000-00-00') {
					formData.set('nascita_data', this.form.nascita_data); 
					checkRequest = true;                      
				}
				if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
					formData.set('wyscout_id', this.form.wyscout.chiave); 
					checkRequest = true;                      
				}
			}
			if(checkRequest) {
				this.$http.post(this.$store.state.apiEndPoint + '/persona/search', formData).then((response) => {
					if (response.ok)
					{
						this.persone = response.data;
						if(this.persone.length > 0) {
							this.$refs.checkPersonaModal.show();	
						} else {
							this.onSubmit();
						}
					}
				}, (response) => {

				});
			} else {
				this.onSubmit();
			}
		},

		forceSubmit() {
			if(confirm('Sei sicuro di voler creare una nuova anagrafica ?')) {
				this.$refs.checkPersonaModal.hide();
				this.onSubmit();
			}
		}, 

		selezionaPersona: function (persona_id) {
			this.persona_id = persona_id;
			this.getGiocatore(this.persona_id);
			this.$refs.searchPlayerModal.hide();
			this.$refs.checkPersonaModal.hide();
		},

		getGiocatore(id) {
			axios.get(`/opponents/`+ id)
			.then((res) => {
				this.image_player = res.data.avatar_url;
				if(res.data.wyscout) {
					this.form.wyscout.id = res.data.wyscout.id;
					this.form.wyscout.chiave = res.data.wyscout.chiave;
				}
				this.form.nome = res.data.nome;
				this.form.cognome = res.data.cognome;
				this.form.nascita_data 	= res.data.nascita_data;
				if(res.data.last_carriera_sportiva) {
					this.form.last_carriera_sportiva.maglia = res.data.last_carriera_sportiva.maglia;
					if(res.data.last_carriera_sportiva.ruolo_ideale && res.data.last_carriera_sportiva.ruolo_ideale.length > 0) {
						this.form.last_carriera_sportiva.ruolo_ideale_id = res.data.last_carriera_sportiva.ruolo_ideale[0].id;
					}
				}
				if(res.data.giocatore) {
					this.form.giocatore.id = res.data.giocatore.id;
					this.form.giocatore.piede = res.data.giocatore.piede;
				}
				if(res.data.info_fisica) {
					this.form.info_fisica.id = res.data.info_fisica.id;
					this.form.info_fisica.altezza = res.data.info_fisica.altezza;
				}
				if(res.data.nascita_paese) {
					this.$refs.paese_nascita.setPaese(res.data.nascita_paese.id, res.data.nascita_paese.paese);	
				}
			}).catch((err) => {
				this.loading = false;
			});
		},

		onSubmit: function(){
			var formData = new FormData();
			var path = '/opponents/nuovo';
			if(this.$route.params.id) {
				formData.append('id', this.$route.params.id);								
				path  = '/opponents/update';
			}
			if(this.persona_id) {
				formData.append('id', this.persona_id);
				path  = '/opponents/update';
			}
			formData.append('team_id', this.$route.params.team_id);				
			formData.append('category_id', this.$route.params.category_id);				
			formData.append("form", JSON.stringify(this.form));
			formData.append("file_avatar", this.file_avatar);
			this.$http.post(this.$store.state.apiEndPoint + path, formData).then((response) => {
            	// success callback
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: 'football_opponents_team', params: { team_id: 123, team_id: this.$route.params.team_id, category_id: this.$route.params.category_id }});
				}
			}, (response) => {
				if (response.status == 422) {
					if(response.data.giocatore) {
						this.giocatore = response.data.giocatore;
						this.$refs.playerModal.show();
					} else {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				}
				// error callback
			});
		},
		searchPlayer: function() {
			var query = this.form.cognome ? this.form.cognome.trim().replace(/ /g, "_") : '';
			if(query != "") {
				this.players = [];
				this.is_search = true;
				this.$refs.searchPlayerModal.show();
				if(this.form.nome && this.form.nome.trim() != "") {
					query = query + "_" + this.form.nome.trim().replace(/ /g, "_");                        
				}
				this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then((response) => {

					if (response.ok)
					{
						this.players = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			}
		},
		selezionaGiocatore: function (player) {
			this.form.wyscout.chiave = player.wyId;
			this.form.cognome = player.lastName;
			this.form.nome = player.firstName;
			this.form.nascita_data = player.birthDate;

			this.image_player = player.imageDataURL;
			if(player.birthArea.alpha2code) {
				for (var index in this.paesi) {
					var paese = this.paesi[index];
					if (player.birthArea.alpha2code == paese.sigla) {
						this.$refs.paese_nascita.setPaese(paese.id, paese.paese)
						break;
					}
				}
			}
			this.form.info_fisica.altezza = player.height;
			this.form.giocatore.piede = this.piede(player.foot);
			this.$refs.searchPlayerModal.hide();
		},
		piede: function (foot) {
			if(foot == "right") {        
				return "0";
			}  else if(foot == "left") {
				return "1";
			}  else if(foot == "both") {
				return "2";
			} 
			return 0;          
		},
		searchTeam: function() {
			this.show_aree = true;
			this.show_competizioni = false;
			this.show_squadre = false;
			if(this.aree.length == 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
					if (response.ok)
					{
						this.aree = response.data;
					}
				}, (response) => {
            // error callback
				});
			}
			this.$refs.searchTeamModal.show();
		},
		selezionaArea: function(sigla) {
			this.show_aree = false;
			this.competizioni = [];
			this.is_search = true;
			this.show_competizioni = true;
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competitions/' + sigla).then((response) => {

				if (response.ok)
				{
					this.competizioni = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});
		},
		selezionaSquadra: function(squadra) {
			this.$refs.searchTeamModal.hide();
			this.players = [];
			this.is_search = true;
			this.$refs.searchPlayerModal.show();
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/players/by/team/' + squadra.wyId).then((response) => {

				if (response.ok)
				{
					this.players = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});	
		},
		selezionaCompetizione: function(competizione) {

			this.show_competizioni = false;
			this.squadre = [];
			this.is_search = true;
			this.show_squadre = true;
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/teams/by/competition/' + competizione.wyId).then((response) => {

				if (response.ok)
				{
					this.squadre = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});
		},
		onAvatarChange(e) {
			if (this.$refs.picturePlayer.file) {
				this.file_avatar = this.$refs.picturePlayer.file;
			} else {
				console.log('FileReader Player API not supported: use the <form> 2')
			}
		},
		onPaeseNascitaSelect(item) {
			this.form.nascita_paese_id = item;
		},
		onPaeseNascitaChange() {
			this.form.nascita_paese_id = 0;
		},
		get_team(){
			if(this.$route.params.team_id){
				axios.get(`/squadre/get/` + this.$route.params.team_id)
				.then((res) => {
					this.team = res.data;
				}).catch((err) => {
					this.loading = false;
				});
			}
		},
	},

	components: {
		pictureInput: require('../../../layout/picture_input.vue').default,
		autocompletePaese: require('../../../layout/autocomplete_paese.vue').default,
		flag: require('../../../layout/flag.vue').default,	
	},

	mounted: function(){
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/paesi').then((response) => {
			this.paesi = response.data;
		});
		this.get_team();

		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
			this.ruoli = response.data;
		});

		if(this.$route.params.id) {
			this.getGiocatore(this.$route.params.id);
		}
	},

	watch: {

		form: {
			handler: function () {this.errors = []; },
			deep: true
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YY'); 
			}
			return "";
		}
	},
	computed: {
		avatar: function() {
			if(this.image_player != "") {
				return this.image_player;
			} 
			return '';
		},
	}

}
</script>
<style>
.row_persona:hover{
	background-color:#ffff00;
}
</style>
