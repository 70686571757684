<template>
	<div class="d-flex">
		<div style="max-height: 500px; overflow-y: auto">
			<h3>Gradimento: {{args.gradimento}}</h3>
			<b-table-simple style="min-width: 250px" small striped hover responsive>
				<b-thead>
					<b-tr>
						<b-th>Partita</b-th>
						<b-th>Osservato</b-th>
						<b-th>Osservatore</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="item in gradimenti">
						<b-td> {{ item.partita }} </b-td>
						<b-td> {{ item.osservato }} </b-td>	
						<b-td> {{ item.osservatore }} </b-td>	
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</div>
</template>

<script>

import _ from 'lodash'
import SingoloGiocatore from "@/components/layout/report-scouting/DettaglioSingoloGiocatore.vue";

export default {
	props: [ 'ds', 'args' ],
	data: function() {
		return {
		}
	},
	methods: {
	},
	components: {
		SingoloGiocatore
	},
	computed: {
		dataset: function() {
			return _.filter(this.ds, function(a) {
				if(this.args.nazione == 'mondo') return true;
				if(a.nazione_osservato_code == this.args.nazione) return true;
				if(a.continente_osservato == this.args.nazione) return true;
			}.bind(this));
		},
		gradimenti: function() {
			return _.filter(this.dataset, { gradimento: this.args.gradimento })
		}
	}
}

</script>