<template>
	<div>
		<b-row>
			<b-col cols="2">
				<b-card>
					<div class="text-center text-muted" style="line-height: 1; text-transform: uppercase">{{ sessione.inizio | week }}</div>
					<div class="text-center" style="font-size: 250%; line-height: 1">{{ sessione.inizio | giorno }}</div>
					<div class="text-center" style="font-size: 110%; line-height: 1">{{ sessione.inizio | mese }}</div>
				</b-card>
			</b-col>
			<b-col>
				<b-table-simple small hover striped>
					<b-tbody>
						<b-tr v-for="attivita in programmaAttivita">
							<b-td width="10%" class="text-center" v-if="attivita.entity.orario">{{ attivita.entity.orario | clockTime }}</b-td>
							<b-td :colspan="attivita.entity.orario ? 1 : 2">{{ attivita.entity.attivita }}</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import sessionMixin from '@/mixins/sessione.js';
import _ from 'lodash'
import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

export default {
	props: [ 'sessione' ],
	computed: {
		programmaAttivita: function() {
			return _.filter(this.sessione.fasi, { entita: 'Fase_Attivita' });
		}
	},
	filters: {
		clockTime: function(time) {
			return time.split(':')[0] + ':' + time.split(':')[1];//moment('01-01-2000 ' + time).format('HH:mm');
		},
		week: function(dt) { return ['','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato','Domenica'][moment(dt).format('e')] },
		giorno: function(dt) { return moment(dt).format('D') },
		mese: function(dt) { return ['','Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'][moment(dt).format('M')] }
	}
}

</script>