<template>
	<div>
		<b-row class="mb-1">
			<b-col>
				<label>Tipo</label>
				<b-form-select v-model="estensione.tipo" required>
					<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
					<b-form-select-option :value="help" v-for="help in ['Pre partita', 'Intervallo', 'Post partita', 'Giorno gara', 'Individuale', 'Pre attività', 'Post attività', 'Colazione', 'Pranzo', 'Cena']" @click.prevent="estensione.tipo = help" class="mr-1">{{ help }}</b-form-select-option>
				</b-form-select>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
		sessionMixin
	],
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { tipo: null });
		}
	},
	props: [ 'estensione', 'model', 'field' ],
	methods: {
		validate: function() {
			if(this.estensione.tipo == null)
				return 'Devi selezionare una tipologia di osservazione.';
			return false;
		}
	}
};
</script>