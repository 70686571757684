<template>
	<div>
		<div v-for="a,k in estensione">
			{{manifest[k].label}}
			<div class="flex-grow-1" style="height: 100%" :is="typeOf(manifest[k].type)" :test="estensione" :field="k" :options="manifest[k]"></div>
		</div>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	props: ['manifest', 'estensione'],
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceActivitycheck: require('@/components/layout/Sessioni/Matrix/ActivityCheck.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceJson: require('@/components/layout/Sessioni/Matrix/Json.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
		SessioneMatriceDate: require('@/components/layout/Sessioni/Matrix/Date.vue').default,
	},
	methods: {
		typeOf: function(tipo) {
			return 'SessioneMatrice' + _.capitalize(tipo);
		},
	},
	data: function() {
		return {
		}
	}
}

</script>