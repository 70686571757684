<template id="dashboard">	
	<div class="content">
		<div v-if="loading" align="center" style="width:100%">
			<img src="/assets/varie/loading.gif" style="width: 150px;" />
		</div>
		<b-card class="mb-2" v-for="item in theVideos" v-if="!loading">
			<b-row>
				<b-col cols="7">
					<b-embed type="video" controls aspect="16by9" v-if="item.tipo != 'youtube'" :poster="item.thumb ? item.thumb : '/assets/varie/missing.jpg'" preload="none" :controlsList="!auth.isUserEnable('video_download') ? 'nodownload' : ''" oncontextmenu="return false;">
						<source :src="item.video"/>		
					</b-embed>
					<b-embed type="iframe" aspect="16by9" :src="item.video" allowfullscreen v-if="item.tipo == 'youtube'"></b-embed>
				</b-col>
				<b-col cols="5" class="pl-3">
					<h5>{{ getTitle(item) }}</h5>
					<p class="card-text">
						Caricato da <span class="color-rosso font-bold"> {{item.utente.nomeCompleto}} </span>
					</p>
					<p class="card-text">
						il <span class="font-italic"> {{ item.data | formatDateHour}} </span>
					</p>
					<p class="card-text">
						Sorgente: <span class="font-bold"> {{ item.tipo }} </span>
					</p>
					<p v-if="item.preferiti" class="card-text" title="Video preferito" style="font-size: 1.5rem;">
						<i class="fa fa-heart color-rosso" aria-hidden="true"></i>
					</p>
				</b-col>
			</b-row>
		</b-card>
	</div>
</div>
</template>

<script>
var Auth = require('../../../auth.js');
import moment from 'moment';
import * as types from '../../../store/types';
export default {
	data: function() {
		return {
			video: [],
			loading: false,
			link: null,
		}
	},

	props: ['partita'],

	components: {
		card: require('../../layout/card.vue').default,
	},

	watch: {	
		partita: {
			immediate: true,
			handler: function() {
				if(this.partita) {
					this.loadVideo();
				}
			}
		},
	},

	methods: {
		loadVideo() {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/video/partita/' + this.partita).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.video = response.data;
            	this.loading = false;
            }
        }, (response) => {
            // error callback
        });
		},

		getTitle(video) {
			var title = "";
			if(video.titolo != "") {
				title = video.titolo;
			} else {
				if(video.tipo == 'wyscout') {
					title = 'Partita integrale';
				} else {
					title = Auth.isAdmin() ? video.video : video.file;
				}
			}
			return title;
		},

		eliminaVideo(id) {
			if(confirm("Confermi l'eliminazione del video ?")) {
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/video/elimina/' + id).then((response) => {
		            // success callback
		            if (response.ok)
		            {
		            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
		            	this.loadVideo();
		            }
		        }, (response) => {
		            // error callback
		        });
			}
		},

		settaPreferito(id) {	
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/video/preferito/' + id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.loadVideo();
				}
			}, (response) => {
			});
		},

		getLinkVideo(id) {
			this.link = null;	
			this.$http.get(this.$store.state.apiEndPoint + '/video/link/' + id).then((response) => {
				if (response.ok)
				{
					this.link = response.data;
					this.$refs.linkModal.show();
				}
			}, (response) => {
			});
		},
	},
	computed: {
		theVideos: function() {
			return _.filter(this.video, function(a) {
				return a.preferiti != null;
			})
		},
		auth: function() {
			return Auth;
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>
