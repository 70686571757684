<template id="cardsquadra">
    <div class="cardsquadra">

        <div class="card">
            <div class="row no-gutters text-center" style="height:2em">      
            </div>
            <div class="text-center">
                <div class="resize-image-card">
                    <router-link :to="'/' + (academy.academy ? 'academyint' : 'academyita') + '/societa/edit/' + academy.id">
                        <b-img :src="academy.logo_url" fluid :alt="academy.nome" :width="130"/>
                    </router-link>
                </div>
            </div>
            <div class="card-body">
                <b-row>
                    <b-col class="small text-center" title="Nome">
                        <strong>{{ academy.nome }}</strong>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="font-size-xs color-rosso text-center" title="Matricola">
                         {{ academy.matricola ? academy.matricola : '&nbsp;'}}
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props:{
        academy: {
            type: Object,
            required: true
        },
    },
    data: function() {
        return {
        }
    },
    components: {
    },
    computed: {
    },
    methods: {
    }
}
</script>
