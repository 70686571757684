<template id="giocatore">
	<section class="giocatore">
		<div>
			<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="giocatore">
				<div class="card-body">
					<div class="row flex-items-xs-middle">
						<div class="col-5">
							<div class="media">
								<img v-bind:src="giocatore.avatar_url" class="" height="100">
								<div class="media-body align-self-center ml-3">
									<h3 class="text-primary mb-0"><strong>{{giocatore.nome}} {{giocatore.cognome}}</strong></h3>
									<small class="text-muted">{{ giocatore.last_carriera_sportiva && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 ? giocatore.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</small>
								</div>
							</div>
						</div>
						<div class="col-7 text-right">
							<ul class="list-inline mb-0" v-if="auth.isLastSeason(seasonId)">	
								<li class="list-inline-item" v-if="auth.isUserEnable('football_rosa_edit')"><b-button :to="{ name: route.meta.module + '_rosa_edit', params: { id: player_id }}" variant="outline-primary">Modifica Informazioni</b-button></li>
								<li class="list-inline-item" v-if="route.meta.module == 'football' &&giocatore.valutazioni.length > 0 && auth.isUserEnable('football_rosa_val')"><b-button :to="{ name: 'football_rosa_view_valutazione', params: { id: player_id, val_id: giocatore.valutazioni[0].id }}" variant="outline-primary">Valutazioni</b-button></li>
								<li class="list-inline-item" v-if="route.meta.module == 'football' && giocatore.valutazioni.length == 0 && auth.isUserEnable('football_rosa_val')"><b-button :to="{ name: 'football_rosa_nuova_valutazione', params: { id: player_id }}" variant="outline-primary">Valutazioni</b-button></li>
								<li class="list-inline-item" v-if="route.meta.module == 'football' &&auth.isUserEnable('football_rosa_mng')"><b-button @click="disassocia(player_id)" variant="outline-primary">Disassocia</b-button></li>
								<li class="list-inline-item" v-if="route.meta.module == 'football' &&auth.isUserEnable('football_rosa_mng')"><b-button @click="showRose" variant="outline-primary">Sposta</b-button></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="row" v-if="giocatore">
				<div class="col-6" v-if="auth.isUserEnable('football_rosa_tabs')">
					<b-card no-body class="rounded-0 border-0 m-0 h-100">
						<b-tabs card>
							<b-tab title="Generali" v-if="auth.isUserEnable('rosa_tab_generali')">
								<table class="table table-sm table-giocatore mb-0 p-0">
									<tr> <td>Nome</td> <td>{{ giocatore.nome }}</td> </tr>
									<tr> <td>Cognome</td> <td>{{ giocatore.cognome }}</td> </tr>
									<tr> <td>Data di nascita</td> <td> {{ giocatore.nascita_data | formatDate }}</td> </tr>
									<tr v-if="giocatore.nascita_paese"> <td>Paese di nascita</td> <td><flag :country="giocatore.nascita_paese.sigla" :size="16"></flag> {{ giocatore.nascita_paese.paese }}</td> </tr>
									<tr> <td>Luogo di nascita</td> <td>{{ giocatore.info_extra ? giocatore.info_extra.nascita_comune : ''}} {{ giocatore.nascita_paese_id == 118 && giocatore.info_extra && giocatore.info_extra.nascita_provincia ? '(' + giocatore.info_extra.nascita_provincia + ')' : '' }}</td> </tr>
									<tr v-if="residenza && residenza.paese"> <td>Paese di residenza</td> <td><flag :country="residenza.paese.sigla" :size="16"></flag> {{ residenza.paese.paese }}</td> </tr>
									<tr v-if="residenza"> <td>Luogo di residenza</td> <td>{{residenza.citta}} {{ residenza.paese_id == 118 && residenza.cap && residenza.provincia ? ', ' + residenza.cap +' (' + residenza.provincia + ')' : '' }}</td> </tr>
									<tr v-if="residenza"> <td>Indirizzo di residenza</td> <td>{{residenza.indirizzo }} </td> </tr>
									<tr v-if="domicilio && domicilio.paese"> <td>Paese di domicilio</td> <td><flag :country="domicilio.paese.sigla" :size="16"></flag> {{ domicilio.paese.paese }}</td> </tr>
									<tr v-if="domicilio"> <td>Luogo di domicilio</td> <td>{{domicilio.citta}} {{ domicilio.paese_id == 118 && domicilio.cap && domicilio.provincia ? ', ' + domicilio.cap +' (' + domicilio.provincia + ')' : '' }}</td> </tr>
									<tr v-if="domicilio"> <td>Indirizzo di domicilio</td> <td>{{domicilio.indirizzo }} </td> </tr>
									<tr v-if="email"> <td>Email</td> <td>{{ email }}</td> </tr>
									<tr v-if="pec"> <td>Email PEC</td> <td>{{ pec }}</td> </tr>
									<tr v-for="(numero, index) in telefoni"> <td>Telefono {{ index > 0 ? index + 1 : '' }}</td> <td>{{ numero }}</td> </tr>
									<tr v-if="giocatore.giocatore && giocatore.giocatore.id_gps"> <td>ID GPS</td> <td>{{giocatore.giocatore.id_gps}}</td> </tr>
								</table>
							</b-tab>
							<b-tab title="Body Metrics" v-if="auth.isUserEnable('rosa_tab_corporatura')">
								<table class="table table-sm table-giocatore">
									<tr> <td>Altezza</td> <td><span v-if="giocatore.info_fisica">{{ giocatore.info_fisica.altezza }} cm</span></td> </tr>
									<tr> <td>Peso</td> <td><span v-if="giocatore.info_fisica">{{ giocatore.info_fisica.peso }} kg</span></td> </tr>
									<tr> <td>Piede</td> <td><span v-if="giocatore.giocatore">{{ piede[giocatore.giocatore.piede] }}</span></td> </tr>
									<tr> <td>Numero Piede</td> <td><span v-if="giocatore.info_fisica">{{ giocatore.info_fisica.num_piede }}</span></td> </tr>
									<tr> <td>Baricentro</td> <td><span v-if="giocatore.info_fisica">{{ baricentro[parseInt(giocatore.info_fisica.baricentro)] }}</span></td> </tr>
									<tr> <td>Somatotipo</td> <td ><span v-if="giocatore.info_fisica">{{ somatotipo[parseInt(giocatore.info_fisica.somatotipo)] }}</span></td> </tr>
									<tr> <td>Costituzione</td> <td><span v-if="giocatore.info_fisica">{{ costituzione[parseInt(giocatore.info_fisica.costituzione)] }}</span></td> </tr>
									<tr> <td>Max Heart Rate</td> <td><span v-if="giocatore.info_fisica">{{ giocatore.info_fisica.max_heart_rate }}</span></td> </tr>
								</table>
							</b-tab>
							<b-tab title="Carriera" v-if="auth.isUserEnable('rosa_tab_carriera')">
								<table class="table table-sm table-giocatore">
									<tr> <td>Matricola FIGC</td> <td><span v-if="giocatore.info_extra">{{ giocatore.info_extra.matricola_figc }}</span></td> </tr>
									<tr> <td>N. tessera</td> <td><span v-if="tessera">{{ tessera.numero }}</span></td> </tr>
									<tr> <td>Ultima Visita Medica / Data Emissione Certificato</td> <td><span v-if="idoneita">{{ idoneita.emissione | formatDate }}</span></td> </tr>
									<tr> <td>Prossima Visita Medica / Data Scadenza Certificato</td> <td><span v-if="idoneita">{{ idoneita.scadenza | formatDate }}</span></td> </tr>
									<tr> <td>Visita Medica Intermedia</td> <td><span v-if="idoneita">{{ idoneita.documento_tipo_glo_id == 10 ? getVisitaMedicaIntermedia(idoneita.scadenza) : 'NO' }}</span></td> </tr>
									<tr v-if="giocatore.last_carriera_sportiva"> <td>Numero Maglia</td> <td>{{ giocatore.last_carriera_sportiva.maglia }}</td> </tr>
									<tr> <td>Ruolo Ideale</td> <td> {{ giocatore.last_carriera_sportiva && giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 ? giocatore.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</td> </tr>
								</table>
							</b-tab>
							<b-tab title="Dati economici" v-if="auth.isUserEnable('rosa_tab_dati_economici')">
								<table class="table table-sm table-giocatore">
									<tr> <td>Contratto</td> <td>{{giocatore.info_economica && giocatore.info_economica.has_contratto ? 'SI' : 'NO' }}</td> </tr>
									<tr> <td>Scadenza Contratto</td> <td><span v-if="contratto"> {{ contratto.scadenza | formatDate }} </span></td> </tr>
									<tr v-for="(agente, index) in agenti"> <td>Agente {{ index > 0 ? 'genitore ' + (index + 1) : '' }}</td> <td>{{ agente.cognome + ' ' + agente.nome }}</td> </tr>
									<tr> <td>Valore Gicatore</td> <td>{{ giocatore.info_economica && giocatore.info_economica.valore && giocatore.info_economica.valore > 0 ? '€ ' + giocatore.info_economica.valore : 'N/D' }}</td> </tr>
									<tr> <td>Rimborso spese</td> <td>{{ giocatore.info_economica && giocatore.info_economica.rimborso_spese ? 'SI' : 'NO' }}</td> </tr>
									<tr> <td>Stipendio/Rimborso lordo fisso annuo</td> <td> {{ giocatore.contratto && giocatore.contratto.stipendio_lordo && giocatore.contratto.stipendio_lordo > 0 ? '€ ' + giocatore.contratto.stipendio_lordo : 'N/D'}}</td> </tr>
									<tr> <td>IBAN</td> <td>{{ giocatore.info_economica ? giocatore.info_economica.iban : ''}}</td> </tr> 
									<tr> <td>Intestatario IBAN</td> <td>{{ giocatore.info_economica ?  giocatore.info_economica.intestatario_iban : '' }}</td> </tr>
									<tr> <td>Scuola AS Roma</td> <td>{{ giocatore.giocatore && giocatore.giocatore.frequenta_scuola_asroma ? 'SI' : 'NO' }}</td> </tr>
									<tr> <td>Scuola a carico AS Roma</td> <td>{{ giocatore.info_economica && giocatore.info_economica.scuola_a_carico ? 'SI' : 'NO' }}</td> </tr>
								</table>
							</b-tab>
							<b-tab title="In prova" v-if="prova && auth.isUserEnable('rosa_tab_carriera')">
								<table class="table table-sm">
									<th colspan="3">Periodi</th>
									<tr v-for="periodo in prova_periodi"> <td>{{ periodo.emissione | formatDate }} - {{ periodo.scadenza | formatDate }} </td><td><b-button v-if="auth.isUserEnable('egnyte') && periodo.path" size="sm" variant="primary" title="Visualizza Nulla Osta" @click="downloadDoc(periodo.id)" class="ml-2">Nulla Osta <i class="fa fa-search" aria-hidden="true"></i></b-button></td></tr>
								</table>
								<table class="table table-sm">
									<th colspan="2">Carriera</th>
									<tr v-for="carriera in prova_carriera"> <td>{{ carriera.stagione ? carriera.stagione.stagione : 'N/D'}}</td> <td> {{ carriera.societa ? carriera.societa.nome : 'N/D'}}</td></tr>
								</table>
								<table class="table table-sm">
									<th colspan="2">Altri documenti</th>
									<tr v-for="doc in prova_documenti"> <td>{{ doc.nome }} </td> <td> <b-button v-if="auth.isUserEnable('egnyte') && doc.documento" size="sm" variant="primary" title="Visualizza Documento" @click="downloadDoc(doc.id)" class="ml-2">Documento <i class="fa fa-search" aria-hidden="true"></i></b-button></td></tr>
								</table>
							</b-tab>
							<b-tab title="Documenti" v-if="auth.isUserEnable('rosa_tab_documenti')">
								<table class="table table-sm table-giocatore mb-0 p-0">
									<tr v-for="(passaporto, index) in passaporti"> <td>Passaporto {{ index + 1 }} <flag v-if="passaporto.paese" :country="passaporto.paese.sigla" :size="16"></flag> </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ passaporto.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ passaporto.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && passaporto.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(passaporto.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="cartaidentita"> <td>Carta d'identità </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ cartaidentita.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ cartaidentita.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && cartaidentita.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(cartaidentita.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="codicefiscale"> <td>Tessera sanitaria / Codice fiscale </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ codicefiscale.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ codicefiscale.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && codicefiscale.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(codicefiscale.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-for="(patente, index) in patenti"> <td>Patente {{ index + 1 }} <flag v-if="patente.paese" :country="patente.paese.sigla" :size="16"></flag> </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ patente.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ patente.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && patente.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(patente.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="circolazione"> <td>Permesso di circolazione </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ circolazione.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && circolazione.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(circolazione.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="soggiorno"> <td>Permesso di soggiorno </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ soggiorno.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && soggiorno.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(soggiorno.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-for="(mandato, index) in mandati"> <td>Mandato agente {{ index > 0 ? (index + 1) : ''}}</td> <td>  <b-button v-if="auth.isUserEnable('egnyte') && mandato.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(mandato.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="idoneita && idoneita.path && auth.isUserEnable('egnyte')"> <td>Certificato idoneità sportiva </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ idoneita.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && idoneita.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(idoneita.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="tessera && tessera.path && auth.isUserEnable('egnyte')"> <td>Tessera </td> <td> <b-button v-if="auth.isUserEnable('egnyte') && tessera.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(tessera.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="contratto && auth.isUserEnable('egnyte') && auth.isUserEnable('contratti')"> <td>Contratto </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ contratto.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && auth.isUserEnable('contratti') && contratto.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(contratto.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
									<tr v-if="greenpass"> <td>Green Pass </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ greenpass.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && greenpass.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(greenpass.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
								</table>
							</b-tab>
							<b-tab title="Parenti" v-if="parenti.length > 0 && auth.isUserEnable('rosa_tab_parenti')">
								<table class="table table-sm table-giocatore">
									<tr v-for="parente in parenti"> <td>{{ parente.tipo.nome.charAt(0).toUpperCase() + parente.tipo.nome.slice(1) }}</td> <td>{{ parente.relazionante.nome }} {{ parente.relazionante.cognome }} </td></tr>
								</table>
							</b-tab>
							<b-tab title="Player Access" v-if="giocatore.player_access && auth.isUserEnable('rosa_tab_player_access')">
								<table class="table table-sm table-giocatore">
									<tr> <td>Personal engagements</td> <td>{{giocatore.player_access.personal_engagements}}</td></tr>
									<!--tr v-if="giocatore.info_extra && giocatore.info_extra.spoken_languages"> <td>Spoken Languages</td> <td> <span v-for="lingua in JSON.parse(giocatore.info_extra.spoken_languages)" :title="lingua.nome" class="mr-2"> <flag :country="lingua.sigla" :size="24"></flag> </span></td> </tr-->
									<tr> <td>Restrictions / Notes</td> <td>{{ giocatore.player_access.restrictions }}</td> </tr>
									<tr> <td>Ranking</td> <td>{{ giocatore.player_access.ranking }}</td> </tr>
								</table>
							</b-tab>
							<b-tab title="Medical" v-if="giocatore.cronologia_medica.length > 0 && auth.isUserEnable('rosa_tab_medical')" class="p-0">
								<b-tabs pills>
									<b-tab :title="tipo.gruppo" v-for="tipo in cronologia_medica_tipi" v-if="giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length > 0">
										<b-col v-if="tipo.id == 1">
											<table class="table table-sm table-giocatore" v-if="anamnesi_medica_selected">
												<tr v-if="anamnesi_medica_selected.data"> <td>Data</td> <td>{{ anamnesi_medica_selected.data | formatDate }}</td></tr>
												<tr v-if="anamnesi_medica_selected.nome"> <td>Nome</td> <td>{{ anamnesi_medica_selected.nome }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.gruppo_sanguigno"> <td>Gruppo sanguigno</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.gruppo_sanguigno }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.visita_leva_abile !== null"> <td>Visita leva abile</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.visita_leva_abile ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.tipo_studio"> <td>Tipo studio</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.tipo_studio }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.altri_sport"> <td>Altri sport</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.altri_sport }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.appetito_normale !== null"> <td>Appetito normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.appetito_normale ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.digestione_normale !== null"> <td>Digestione normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.digestione_normale ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.alvo_normale !== null"> <td>Alvo normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.alvo_normale ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.diuresi_normale !== null"> <td>Diuresi normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.diuresi_normale ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.sonno_normale !== null"> <td>Sonno normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.sonno_normale ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.fumo !== null"> <td>Fumo</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.fumo ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.alcol !== null"> <td>Alcol</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.alcol ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.caffe !== null"> <td>Caffe</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.caffe ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.nota_anamnesi_fisiologica"><td>Anamnesi fisiologica</td> <td><span class="color-rosso ml-1"><i :id="'nota_anamnesi_fisiologica_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Anamnesi fisiologica" :target="'nota_anamnesi_fisiologica_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_anamnesi_fisiologica }}</b-popover></td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.anamnesi_familiare"> <td>Anamnesi familiare</td> <td><span class="color-rosso ml-1"><i :id="'anamnesi_familiare_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Anamnesi familiare" :target="'anamnesi_familiare_' + anamnesi_medica_selected.id" triggers="hover" placement="top">{{ anamnesi_medica_selected.anamnesi_medica.anamnesi_familiare }}</b-popover></td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.vaccinazione_antitetanica !== null"> <td>Vaccinazione antitetanica</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.vaccinazione_antitetanica ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.vaccinazione_antiepatite_virale_b !== null"> <td>Vaccinazione antiepatite virale B</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.vaccinazione_antiepatite_virale_b ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.altre_visite_idoneita_agonistica !== null"> <td>Altre visite idoneità agonistica</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.altre_visite_idoneita_agonistica ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.dichiarato_idoneo_per_meno_di_12_mesi !== null"> <td>Dichiarato idoneo per meno di 12 mesi <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_idoneo_per_meno_di_12_mesi" :id="'nota_idoneo_per_meno_di_12_mesi_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota idoneo per meno di 12 mesi" :target="'nota_idoneo_per_meno_di_12_mesi_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_idoneo_per_meno_di_12_mesi }}</b-popover></td> <td>{{ anamnesi_medica_selected.anamnesi_medica.dichiarato_idoneo_per_meno_di_12_mesi ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.dichiarato_inidoneo !== null"> <td>Dichiarato inidoneo <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_inidoneo" :id="'nota_inidoneo_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota inidoneo" :target="'nota_inidoneo_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_inidoneo }}</b-popover></td> <td>{{ anamnesi_medica_selected.anamnesi_medica.dichiarato_inidoneo ? 'Si' : 'No' }}</td></tr>
												<tr v-if="anamnesi_medica_selected.anamnesi_medica.provvedimenti_disciplinari_doping !== null"> <td>Provvedimenti disciplinari doping <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_doping" :id="'nota_doping_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota doping" :target="'nota_doping_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_doping }}</b-popover> </td> <td>{{ anamnesi_medica_selected.anamnesi_medica.provvedimenti_disciplinari_doping ? 'Si' : 'No' }}</td></tr>
											</table>
											<b-pagination v-if="giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length > 1" size="sm" align="center" :total-rows="giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length" v-model="anamnesi_medica_count" :per-page="1" @change="selectAnamnesiMedica"></b-pagination>
										</b-col>
										<b-col v-else>
											<table class="table table-sm">
												<tr>
													<th v-if="tipo.has_data">Data</th>
													<th v-if="tipo.has_nome">Nome</th>
													<th v-if="tipo.has_data_scadenza">Scadenza</th>
													<th v-if="tipo.has_numero_lotto">N° lotto</th>
													<th v-if="tipo.has_data_rinnovo">Rinnovo</th>
													<th v-if="tipo.has_nota">Nota</th>
												</tr>
												<tr v-for="item in giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id)"> 
													<td v-if="tipo.has_data">{{ item.data | formatDate }}</td>
													<td v-if="tipo.has_nome">{{ item.nome }}</td>
													<td v-if="tipo.has_data_scadenza">{{ item.data_scadenza | formatDate }}</td>
													<td v-if="tipo.has_numero_lotto">{{ item.numero_lotto }}</td>
													<td v-if="tipo.has_data_rinnovo">{{ item.data_rinnovo | formatDate }}</td>
													<td v-if="tipo.has_nota"><span class="color-rosso ml-1"><i v-if="item.nota" :id="'nota_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota" :target="'nota_' + item.id" triggers="hover" placement="right">{{ item.nota }}</b-popover></td>
												</tr>
											</table>
										</b-col>
									</b-row>
								</b-tab>
							</b-tabs>
						</b-tab>
						<b-tab title="Providers" v-if="auth.isUserEnable('rosa_tab_providers')">
							<entita-api-manager :entityId="player_id" entity="Persona" :readonly="true"/>
						</b-tab>
						<b-tab title="Lingue" v-if="giocatore.lingue.length > 0 && auth.isUserEnable('rosa_tab_lingue')">
							<table class="table table-sm table-giocatore">
								<tr v-for="lingua in giocatore.lingue"> <td>{{ lingua.paese ? lingua.paese.lingua : 'N/D' }}</td> <td>{{ getLinguaLevel(lingua.level) ? getLinguaLevel(lingua.level).label : 'N/D' }} <span class="color-rosso ml-1"><i :id="'level_' + lingua.id" class="fa fa-info-circle" aria-hidden="true" title="Info"></i></span><b-popover title="Info" :target="'level_' + lingua.id" triggers="hover" placement="top">{{ getLinguaLevel(lingua.level) ? getLinguaLevel(lingua.level).description : 'N/D'}}</b-popover></td></tr>
							</table>
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
			<div class="col" v-if="auth.isUserEnable('football_rosa_val')">
				<b-card no-body class="rounded-0 border-top-0 border-right-0 border-bottom-0 m-0 h-100">
					<b-tabs card>
						<b-tab :title="item.it" v-for="(item, index) in skills" :key="item.id" v-if="item.id != -3">
							<div class="w-75 mx-auto py-4">
								<radarchart :radarid="'radar_' + index" :datachart="radardatachart(item, index)"></radarchart>
							</div>
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
			<div class="col-6" v-if="auth.isUserEnable('medical')">
				<b-card no-body class="rounded-0 border-top-0 border-right-0 border-bottom-0 m-0 h-100">
					<b-tabs card>
						<b-tab title="Riepilogo attività">
							<card class="h-100" comp="gsessions" :params="{'player_id': giocatore.id, seasons: 1}"></card>	
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
			<div class="col" v-if="auth.isUserEnable('player_injuries')">
				<b-card no-body class="rounded-0 border-top-0 border-right-0 border-bottom-0 m-0 h-100">
					<b-tabs card>
						<b-tab title="Injuries">
							<card class="h-100" comp="ginjuries" :params="{'player_id': giocatore.id}"></card>	
						</b-tab>
						<b-tab title="Illnesses">
							<card class="h-100" comp="gillnesses" :params="{'player_id': giocatore.id}"></card>	
						</b-tab>
						<b-tab title="Injuries rtp">
							<card class="h-100" comp="ginjuriesrtp" :params="{'player_id': giocatore.id}"></card>	
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
		</div>
		<perfezionamento class="h-100" :only_root_skill="team.categoria.only_root_skill" :handle="auth.isAdmin() || auth.isPerfezionamento()" :skills="skills" :id_giocatore="giocatore.id" :exe_skills="perf_exe_skills" :exe_skills_portiere="perf_exe_skills_portiere" v-if="auth.isUserEnable('perfezionamento')"></perfezionamento>
		<div class="row" v-if="auth.isUserEnable('football_rosa_tags')">
			<div class="col" v-for="cat in skills_tags">
				<tagscategorie class="h-100" :skills_tags="cat.fields" :title="cat.it" :only_root_skill="team.categoria.only_root_skill" @selectSkillTags="selectSkillTags"></tagscategorie>
			</div>
		</div>
		<div class="row">
			<div class="col" v-if="auth.isUserEnable('football_rosa_val')">
				<card class="h-100" title="Valutazioni" comp="gvalutazioni" :params="{ 'valutazioni': giocatore.valutazioni, 'rosa_id': giocatore.id }"></card>
			</div>
			<div class="col" v-if="team.categoria.name == 'In prova'">
				<card class="h-100" title="In prova" comp="gprove" :params="{ 'prove': giocatore.carriere_sportive }"></card>
			</div>
			<div class="col" v-if="auth.isUserEnable('football_rosa_tags')">
				<b-button variant="primary" style="position:absolute;top:25px;right:25px;z-index:1" @click="selectGenericTags"><i class="fa fa-youtube-play"></i> Azione generica / Goal </b-button>
				<card class="h-100" title="Riassunto" comp="griassunto" :params="{ 'riassunti': riassunti }"></card>
			</div>
		</div>

		<b-row>
			<b-col v-if="auth.isUserEnable('football_rosa_stats')">
				<card class="h-100" title="Statistiche" comp="gstats" :params="{'seasons': stats}"></card>
			</b-col>
			<b-col v-if="auth.isUserEnable('palestra_allenamenti')">
				<card class="h-100" title="Palestra" comp="gpalestra" :params="{'allenamenti': palestra_allenamenti, 'count_rows': 5}"></card>
			</b-col>
		</b-row>
		<b-row>
			<b-col v-if="auth.isUserEnable('player_access_football')">
				<card class="h-100" title="Player Access" comp="gattivita" :params="{'seasons': player_access_attivita, 'rosa_id': giocatore.id }"></card>
			</b-col>
		</b-row>
		<b-row v-if="auth.isUserEnable('player_injuries')">
			<b-col>
				<NoiseFeedInjuries :persona="giocatore" :persona_id="giocatore.id"></NoiseFeedInjuries>
			</b-col>
		</b-row>



		<b-modal id="tagsModal" size="xl" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
			<b-row v-if="skill_tags_sel">
				<b-col cols="3" style="overflow-y:auto;height:550px;">
					
					<b-form-radio-group v-model="ux.tagFiltering">
						<b-form-radio :value="null">Tutti</b-form-radio>
						<b-form-radio value="partite">Partite</b-form-radio>
						<b-form-radio value="sessioni">Sessioni</b-form-radio>
					</b-form-radio-group>

					<div v-for="(tag, index) in theVideotags">
						<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag" @deleteTag="deleteTag"></thumbtag>
					</div>
				</b-col>
				<b-col cols="9">
					<video
					id="videotags-wrapper"
					class="video-js vjs-default-skin vjs-16-9"
					controls
					data-setup="{}"></video>
					<b-pagination size="sm" align="center" :total-rows="theVideotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
					<div v-if="tag_sel">
						<p class="m-1">{{ count }} - {{ skillName }}</p>
						<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
						<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
						<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
						<p class="m-1">{{ tag_sel.nota }}</p>
					</div>
				</b-col>
			</b-row>
		</b-modal>
		<b-modal id="riassuntoModal" size="xl" ref="riassuntoModal" :title="titleRiassunto" class="fs-modal" @hidden="onHiddenRiassunto" @shown="onShowRiassunto" ok-only ok-title="Chiudi">
			<div>
				<video
				id="video-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}"></video>
			</div>
		</b-modal>
		<b-modal id="spostaModal" ref="spostaModal" title="Sposta giocatore" class="fs-modal" ok-only ok-title="Chiudi">
			<b-form-group label="Seleziona la squadra su cui spostare il giocatore:">
				<b-form-radio-group v-model="squadra_sel">
					<b-form-radio :value="item.squadra.id" v-for="item in squadre_societa" v-if="item.squadra.id != rosaTeamId"> {{ item.squadra.categoria.name }} </b-form-radio>
				</b-form-radio-group>
			</b-form-group>	
			<b-button @click="sposta" variant="outline-primary">Sposta</b-button>	
		</b-modal>
	</div>

</section>
</template>

<script>
var Auth = require('../../auth.js');
import * as types from '../../store/types';
import axios from 'axios';
import moment from 'moment';
import videojs from 'video.js';
import _ from 'lodash';
import 'videojs-errors';
import 'videojs-playlist';
import { mapState } from 'vuex';
export default {
	data: function() {
		return {
			giocatore: null,
			skills: [],
			piede: [ 'Destro', 'Sinistro', 'Ambidestro' ],
			relazioni: [ 'Padre', 'Madre', 'Tutor' ],
			stato: ["in rosa", "in prova", "archiviato"],
			skills_tags: [],
			generic_tags: [],
			skill_tags_sel: null,
			videotags: [],
			tag_sel: null,
			count: 0,
			title: '',
			reload: false,
			riassunti: [],
			playlist: [],
			titleRiassunto: '',
			squadre_societa: [],
			squadra_sel: null,
			errors_player: {
				4: {
					headline: 'Video non disponibile',
					type: '',
					message: 'La clip non è stata ancora generata.<br/>Riprova più tardi.',
				}
			},
			perf_exe_skills: [],
			perf_exe_skills_portiere: [],
			team: null,
			stats: [],
			palestra_allenamenti: [],
			player_access_attivita: [],
			baricentro: ['Basso', 'Medio', 'Alto'],
			somatotipo: ['Normolineo', 'Brevilineo', 'Longilineo'],
			costituzione: ['Esile', 'Media', 'Robusta'],
			cronologia_medica_tipi: [],
			anamnesi_medica_count: 1,
			anamnesi_medica_selected: null,
			lingua_levels: [],
			ux: {
				tagFiltering: null
			}
		}
	},

	props: {
		player_id: {
			type: Number,
			required: true
		}
	},

	methods: {
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},
		disassocia(id) {
			if(confirm("Sei sicuro di voler eliminare il giocatore dalla rosa?")) {
				axios.post(`/rosa/disassocia`, { id: id, squadra_id: this.rosaTeamId, stagione_id: this.seasonId })
				.then((res) => {				        
					this.$router.push({ name: "football_rosa"});
				}).catch((err) => {
				});
			}
		},
		showRose() {
			if(this.squadre_societa.length == 0) {
				axios.get('/societa/squadre').then((res) => {
					this.squadre_societa = res.data;
				});
			}
			this.$refs.spostaModal.show();
		},
		sposta() {
			if(this.squadra_sel) {
				if(confirm("Sei sicuro di voler spostare il giocatore?")) {
					axios.post(`/rosa/sposta`, { id: this.giocatore.id, from_team: this.rosaTeamId, to_team: this.squadra_sel })
					.then((res) => {
						this.$refs.spostaModal.hide();	
						this.$store.commit(types.ALERTS_PUSH, { msg: res.data.message, status: 1 });			        
						this.$router.push({ name: "football_rosa"});
					}).catch((err) => {
					});
				}
			}
		},
		radardatachart: function(cat) {
			var array_skill_id = [];
			var array_voti_skill = [];
			var array_count_skill = [];
			var data = {
				labels: [],
				datasets: [{
					label: '',
					data: [],
					backgroundColor: '',
					borderColor: '',
					pointBackgroundColor: '',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: '',
				}]
			};
			data.datasets[0].label = cat[this.$i18n.t('lang')];
			data.datasets[0].backgroundColor = this.backgroudColorRadar(cat.id);
			data.datasets[0].borderColor = this.borderColorRadar(cat.id);
			data.datasets[0].pointBackgroundColor = data.datasets[0].borderColor;
			data.datasets[0].pointHoverBorderColor = data.datasets[0].borderColor;
			for (var j in cat.fields) {
				data.labels.push(cat.fields[j][this.$i18n.t('lang')]);
				var id_skill  = cat.fields[j]['id'];
				array_skill_id.push(id_skill);
				array_voti_skill[id_skill] = 0;
				array_count_skill[id_skill] = 0;
				for(var i in this.giocatore.valutazioni) {
					var voto = 0;
					var count = 0;
					var voto2 = 0;
					var count2 = 0;
					var arrayVoto = [];
					var arrayCount = [];
					if(this.giocatore.valutazioni[i].skills != "") {
						var skill_val = JSON.parse(this.giocatore.valutazioni[i].skills);
						var cat_skills = skill_val[cat.id];
						for(var item in cat_skills) {
							if(item == id_skill) {
								var item2 = cat_skills[item];
								if(item2 instanceof Object) {

									for(var item3 in item2) {
										var item4 = item2[item3];

										if(item4 instanceof Object) {
											voto2 = 0;
											count2 = 0;
											for(var item5 in item4) {
												if(item4[item5] > 0) {
													voto2+= item4[item5];
													count2++;
												}
											}
											arrayVoto.push(voto2);
											arrayCount.push(count2);
										} else if(item4 > 0) {
											voto += item4;
											count++;
										}
									}
									if(arrayVoto.length > 0) {
										var votoTot = 0; 
										var countTot = 0;
										if(voto > 0) {
											votoTot = voto/count;
											countTot++;
										}
										for(var index in arrayVoto) {
											if(arrayVoto[index] > 0) {
												votoTot += arrayVoto[index]/arrayCount[index];
												countTot++;
											}            
										}
										if(votoTot > 0) {
											voto = votoTot/countTot;
										}
									} else if(voto > 0) {
										voto = voto/count;
									}
									if(voto > 0) {
										array_voti_skill[id_skill] +=  voto;
										array_count_skill[id_skill] ++;
									}
								} else {
									if(item2 > 0) {
										array_voti_skill[id_skill] +=  item2;
										array_count_skill[id_skill] ++;
									}
								}
								break;
							}
						}
					}
				}
			}
			for(var index in array_skill_id) {
				var id = array_skill_id[index];
				var voto = array_voti_skill[id];
				if(voto > 0) {
					voto = voto / array_count_skill[id];
					voto = Math.round(voto * 100) / 100;
				}
				data.datasets[0].data.push(voto);
			}
			return data;	
		},

		backgroudColorRadar: function(cat_id) {
			if(cat_id == -1) {
				return "rgba(255, 0, 89, 0.2)";
			} else if(cat_id == -2) {
				return "rgba(255, 219, 0, 0.2)";
			} else if(cat_id == -3) {
				return "rgba(46, 200, 91, 0.2)";
			} else if(cat_id == -4) {
				return "rgba(0, 0, 255, 0.2)";
			} else if(cat_id == -5) {
				return "rgba(153, 0, 255, 0.2)";
			} else if(cat_id == -6) {
				return "rgba(255, 153, 0, 0.2)";
			}
			return "rgba(168, 5, 50, 0.2)";	
		},

		borderColorRadar: function(cat_id) {
			if(cat_id == -1) {
				return "rgb(255, 0, 89)";
			} else if(cat_id == -2) {
				return "rgb(255, 219, 0)";
			} else if(cat_id == -3) {
				return "rgb(46, 200, 91)";
			} else if(cat_id == -4) {
				return "rgb(0, 0, 255)";
			} else if(cat_id == -5) {
				return "rgb(153, 0, 255)";
			} else if(cat_id == -6) {
				return "rgb(255, 153, 0)";
			}
			return "rgb(168, 5, 50)";	
		},
		selectSkillTags(skill_tags, title) {
			this.title = title;
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields && !this.team.categoria.only_root_skill) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);
			}
			this.$refs.tagsModal.show();
		},
		selectGenericTags() {
			if(this.generic_tags.length > 0) {
				this.title = 'Azione generica / Goal';
				this.videotags = [];
				this.skill_tags_sel = 'Azione generica';
				this.videotags = this.generic_tags;
				this.tag_sel = this.videotags[0];
				this.count = 1;
				this.playlist = [];
				for(var i in this.videotags) {
					var videotag = this.videotags[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);
				}
				this.$refs.tagsModal.show();
			}
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1);
		},
		selectPageTag(page) {
			this.tag_sel = this.videotags[page - 1];
			this.$video_tags.playlist.currentItem(page - 1);
		},
		autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		deleteTag(tag) {
			if(confirm('Sei sicuro di voler eliminare il tag?')) {
				this.$http.delete(this.$store.state.apiEndPoint + '/tag/' + tag.id).then((response) => {
					if (response.ok)
					{
						if(this.tag_sel.id == tag.id) {
							this.tag_sel = null;
						}
						var index = this.videotags.indexOf(tag);
						this.videotags.splice(index, 1);
						this.reload = true;
						if(this.videotags.length == 0) {
							this.$refs.tagsModal.hide();
						}
					}
				}, (response) => {
				});
			}
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);

				this.$video_tags.errors({
					"errors": this.errors_player,
				});
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.$video_tags.dispose();
			this.$video_tags = null;
			this.tag_sel = null;
			if(this.reload) {
				this.$http.get(this.$store.state.apiEndPoint + '/videotags/giocatore/' + this.giocatore.giocatore.id, {params: {all_tags: 1 }}).then((response) => {
					if (response.ok)
					{
						this.skills_tags = response.data.skills_tags;
						this.generic_tags = response.data.generic_tags;
						this.reload = false;
					}
				}, (response) => {
		            // error callback
		        });
			}
		},
		selectRiassunto(index) {
			var videotags = this.riassunti[index].videotags;
			this.playlist = [];
			this.titleRiassunto = 'Video riassunto di ' + this.riassunti[index].utente.nomeCompleto;
			for(var i in videotags) {
				var videotag = videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);
			}
			this.$refs.riassuntoModal.show();
		},
		onShowRiassunto() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			this.$video.play();
		},
		onHiddenRiassunto() {
			this.$video.playlist([]);
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;
		},
		downloadDoc(id) {
			window.open(this.$store.state.apiEndPoint + '/football/download/egnyte/doc/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
		getVisitaMedicaIntermedia: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.subtract(6, 'M').format('DD/MM/YYYY'); 
			}
			return "N/D";
		},
		selectAnamnesiMedica(page) {
			this.anamnesi_medica_selected = this.giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == 1)[page - 1];   
		},
		getLinguaLevel(level) {
			var level = this.lingua_levels.find((item) => item.value == level);
			if(level) {
				return level;
			}
			return null;
		}
	},

	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},

	computed: {
		theVideotags: function() {

			if(this.ux.tagFiltering == null)
				return this.videotags;

			if(this.ux.tagFiltering == 'partite') {
				return _.filter(this.videotags, function(a) {
					return a.video.partita_id != null && a.video.partita_id > 0;
				});
			} else {
				return _.filter(this.videotags, function(a) {
					return a.video.partita_id == null || a.video.partita_id == 0;
				});
			}

		},
		auth: function() {
			return Auth;
		},
		route: function() {
			return this.$route;
		},
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},
		...mapState(['rosaTeamId', 'seasonId']),
		email: function() {
			for(var i in this.giocatore.contatti) {
				var item = this.giocatore.contatti[i];
				if(item.contatto.tipo == 'email') {
					return item.contatto.valore;
				}
			}
			return null;
		},
		pec: function() {
			for(var i in this.giocatore.contatti) {
				var item = this.giocatore.contatti[i];
				if(item.contatto.tipo == 'pec') {
					return item.contatto.valore;
				}
			}
			return null;
		},
		telefoni: function() {
			var telefoni = [];
			for(var i in this.giocatore.contatti) {
				var item = this.giocatore.contatti[i];
				if(item.contatto.tipo == 'telefono') {
					telefoni.push(item.contatto.valore);
				}
			}
			return telefoni;
		},
		residenza: function() {
			for(var i in this.giocatore.indirizzi) {
				var item = this.giocatore.indirizzi[i];
				if(item.indirizzo.tipo == 'residenza') {
					return item.indirizzo;
				}
			}
			return null;
		},
		domicilio: function() {
			for(var i in this.giocatore.indirizzi) {
				var item = this.giocatore.indirizzi[i];
				if(item.indirizzo.tipo == 'domicilio') {
					return item.indirizzo;
				}
			}
			return null;
		},
		contratto: function() {
			var doc = null;
			for(var i in this.giocatore.documenti) {
				var item = this.giocatore.documenti[i];
				if(item.documento.documento_tipo_glo_id == 12) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
					}
				}
			}
		}
		return doc;
	},
	agenti: function() {
		var persone = [];
		for(var i in this.giocatore.relazioni) {
			var item = this.giocatore.relazioni[i];
			if(item.persona_relazioni_glo_id == 4) {
				persone.push(item.relazionante);
			}
		}
		return persone;
	},
	passaporti: function() {
		var docs = [];
		for(var i in this.giocatore.documenti) {
			var item = this.giocatore.documenti[i];
			if(item.documento.documento_tipo_glo_id == 2) {
				if(docs.length == 0) {
					docs.push(item.documento);
				} else {
					var found = false;
					for(var j in docs) {
						if(docs[j].numero == item.documento.numero) {
							found = true;
							if(item.documento.scadenza > docs[j].scadenza) {
								docs[j] = item.documento;
							}
							break;
						}
					}
					if(!found) {
						docs.push(item.documento);
					}
				}
			}
		}
		return docs;
	},
	cartaidentita: function() {
		var doc = null;
		for(var i in this.giocatore.documenti) {
			var item = this.giocatore.documenti[i];
			if(item.documento.documento_tipo_glo_id == 3) {
				if(!doc) {
					doc = item.documento;
				} else {
					if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
						!doc.scadenza || !item.documento.scadenza) {
						doc = item.documento;
				}
			}
		}
	}
	return doc;
},
codicefiscale: function() {
	var doc = null;
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 4) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},
patenti: function() {
	var docs = [];
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 5) {
			if(docs.length == 0) {
				docs.push(item.documento);
			} else {
				var found = false;
				for(var j in docs) {
					if(docs[j].numero == item.documento.numero) {
						found = true;
						if(item.documento.scadenza > docs[j].scadenza) {
							docs[j] = item.documento;
						}
						break;
					}
				}
				if(!found) {
					docs.push(item.documento);
				}
			}
		}
	}
	return docs;
},
circolazione: function() {
	var doc = null;
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 6) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},
soggiorno: function() {
	var doc = null;
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 7) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},
mandati: function() {
	var docs = [];
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 8) {
			docs.push(item.documento);
		}
	}
	return docs;
},
idoneita: function() {
	var doc = null;
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 9 || item.documento.documento_tipo_glo_id == 10) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},
tessera: function() {
	var doc = null;
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 11) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},
greenpass: function() {
	var doc = null;
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 15) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},
parenti: function() {
	var relazioni = [];
	for(var i in this.giocatore.relazioni) {
		var item = this.giocatore.relazioni[i];
		if(item.persona_relazioni_glo_id <= 3) {
			relazioni.push(item);
		}
	}
	return relazioni;
},
prova: function() {
	for(var i in this.giocatore.carriere_sportive) {
		var carriera = this.giocatore.carriere_sportive[i];
		if(carriera.tipologia == 'prova') {
			return true;
		}
	}
	return false;
},
prova_periodi: function() {
	var docs = [];
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 13) {
			docs.push(item.documento);
		}
	}
	return docs;
},
prova_carriera: function() {
	var carriere = [];
	for(var i in this.giocatore.carriere_sportive) {
		var item = this.giocatore.carriere_sportive[i];
		if(item.societa_id != 1) {
			carriere.push(item);
		}
	}
	return carriere;
},
prova_documenti: function() {
	var docs = [];
	for(var i in this.giocatore.documenti) {
		var item = this.giocatore.documenti[i];
		if(item.documento.documento_tipo_glo_id == 14) {
			docs.push(item.documento);
		}
	}
	return docs;
},
},

created: function () {

	this.getTeam();

	if(this.player_id){

		axios.get(`/rosa/` + this.player_id)
		.then((res) => {
			this.giocatore 	= res.data;
			if(this.giocatore.valutazioni && this.giocatore.valutazioni.length > 0) {
				var last_val = this.giocatore.valutazioni[0];
				if(this.giocatore.last_carriera_sportiva.ruolo_ideale && this.giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 && this.giocatore.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
					this.skills = JSON.parse(last_val.fields.special);
				} else {
					this.skills = JSON.parse(last_val.fields.standard);
				}
				this.perf_exe_skills = JSON.parse(last_val.fields.standard);
				this.perf_exe_skills_portiere = JSON.parse(last_val.fields.special);
			} else {
				this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
					if (response.ok) {
						if(this.giocatore.last_carriera_sportiva.ruolo_ideale && this.giocatore.last_carriera_sportiva.ruolo_ideale.length > 0 && this.giocatore.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
							this.skills = JSON.parse(response.data.special);
						} else {
							this.skills = JSON.parse(response.data.standard);
						}
						this.perf_exe_skills = JSON.parse(response.data.standard);
						this.perf_exe_skills_portiere = JSON.parse(response.data.special);
					}
				}, (response) => {

				});  
			}

			if(this.giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == 1).length > 0) {
				this.anamnesi_medica_selected = this.giocatore.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == 1)[0];
			}

			this.$http.get(this.$store.state.apiEndPoint + '/videotags/giocatore/' + this.giocatore.id, {params: {all_tags: 1 }}).then((response) => {
				if (response.ok)
				{
					this.skills_tags = response.data.skills_tags;
					this.generic_tags = response.data.generic_tags;
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/videotag/riassunto/giocatore/' + this.giocatore.id).then((response) => {
				if (response.ok)
				{
					this.riassunti = response.data.riassunti;
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/rosa/stats/' + this.giocatore.id).then((response) => {
				if (response.ok)
				{
					this.stats = response.data.stats;
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/palestra/allenamenti/giocatore/' + this.giocatore.id + '/' + this.seasonId + '/' + this.rosaTeamId).then((response) => {
				if (response.ok)
				{
					this.palestra_allenamenti = response.data;
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/playeraccess/attivita/giocatore/' + this.giocatore.id).then((response) => {
				if (response.ok)
				{
					this.player_access_attivita = response.data;
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/cronologia/medica/tipi').then((response) => {
				if (response.ok)
				{
					this.cronologia_medica_tipi = response.data;
				}
			}, (response) => {
			});

			this.$http.get(this.$store.state.apiEndPoint + '/lingua/levels').then((response) => {
				if (response.ok)
				{
					this.lingua_levels = response.data;
				}
			}, (response) => {
			});
		}).catch((err) => {
		});
	}

	this.$bus.$on('selectRiassunto', ($event) => {
		this.selectRiassunto($event);
	});
},

filters: {
	formatDate: function (date) {
		var m = moment(date);
		if(m.isValid()) {
			return m.format('DD/MM/YYYY'); 
		}
		return "N/D";
	},
},

components: {
	flag: 			require('./flag.vue').default,
	radarchart: require('./charts/radar.vue').default,
	card: 			require('./card.vue').default,
	tagscategorie: 		require('./tagscategorie.vue').default,
	thumbtag:   require('./thumbtag.vue').default,
	perfezionamento:   require('./perfezionamento.vue').default,
	EntitaApiManager: 	require('@/components/layout/entita_api/manager.vue').default,
		NoiseFeedInjuries: require('@/components/layout/noise_feed/injuries-by-persona.vue').default
}
}
</script>
