<template id="nuovo_giocatore">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card title_page">
				<div class="card-body">
					<div class="card-title">
						<span>Nuovo Giocatore</span>
					</div>
				</div>
			</div>	
			<div class="content_page">	
				<b-row>
					<b-col cols="8">   	
						<b-form @submit="onSubmit">
							<picture-input ref="picturePlayer" @change="onAvatarChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="avatar"></picture-input>						
							<b-form-group id="nomeGroup" horizontal label="Nome" :label-cols="3" label-for="nome" :feedback="errors.nome ? $t(errors.nome[0]) : ''" :state="errors.nome ? 'invalid' : 'null'">
								<b-form-input id="nome" type="text" v-model="form.nome" :state="errors.nome ? 'invalid' : 'null'"></b-form-input>
							</b-form-group>
							<b-form-group id="cognomeGroup" horizontal label="Cognome" :label-cols="3" label-for="cognome" :feedback="errors.cognome ? $t(errors.cognome[0]) : ''" :state="errors.cognome ? 'invalid' : 'null'">
								<b-input-group>
									<b-form-input id="cognome" type="text" v-model="form.cognome" :state="errors.cognome ? 'invalid' : 'null'"></b-form-input>
									<b-input-group-append>
										<b-button variant="secondary" @click="searchTeam" title="Cerca per squadra"><i class="fa fa-users" aria-hidden="true"></i></b-button>
										<b-button variant="primary" @click="searchPlayer" title="Cerca per nome"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
							<b-form-group id="dataDiNascitaGroup" horizontal label="Data di nascita" :label-cols="3" label-for="data_nascita">
								<b-form-input id="data_nascita" type="date" v-model="form.data_nascita"></b-form-input>
							</b-form-group>
							<b-form-group id="paeseNascitaGroup" horizontal label="Paese di nascita" :label-cols="3" label-for="paese_nascita">
								<autocomplete-paese :suggestions="paesi" id="paese_nascita" ref="paese_nascita" @select="onPaeseNascitaSelect" @change="onPaeseNascitaChange" v-model="form.paese_nascita"></autocomplete-paese>
							</b-form-group>
							<b-form-group id="passaportiGroup" horizontal label="Passasporti" :label-cols="3">					
								<b-row>
									<b-col>
										<b-button variant="primary" @click="addPassaporto">+</b-button>
									</b-col>
								</b-row>
								<b-row v-for="(item, index) in form.passaporti" class="my-1" :key="item">
									<b-col>
										<autocomplete-paese :suggestions="paesi" @select="onPassaportoSelect" @change="onPassaportoChange" v-model="item.paese_id" :row_index="index" ref="passaporto"></autocomplete-paese>
									</b-col>
									<b-col cols="1">
										<b-button variant="primary" @click="removePassaporto(item)">-</b-button>
									</b-col>
								</b-row>							
							</b-form-group>    							
							<b-form-group id="altezzaGroup" horizontal label="Altezza (cm)" :label-cols="3" label-for="altezza">
								<b-form-input id="altezza" type="text" v-model="form.altezza"></b-form-input>
							</b-form-group>
							<b-form-group id="pesoGroup" horizontal label="Peso (kg)" :label-cols="3" label-for="peso">
								<b-form-input id="peso" type="text" v-model="form.peso"></b-form-input>
							</b-form-group>
							<b-form-group id="piedeGroup" horizontal label="Piede" :label-cols="3" label-for="piede">
								<b-form-radio-group id="piede" v-model="form.piede">
									<b-form-radio value="0">Destro</b-form-radio>
									<b-form-radio value="1">Sinistro</b-form-radio>
									<b-form-radio value="2">Ambidestro</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
							<b-form-group id="squadraGroup" horizontal label="Squadra" :label-cols="3" label-for="squadra">
								<b-input-group size="sm">
									<b-input-group-prepend is-text>
          								<b-img :src="logo_squadra" width="32"/>
        							</b-input-group-prepend>
									<b-form-input id="squadra" type="text" v-model="form.squadra" style="font-size:1rem" readonly></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="loadSquadra" title="Carica squadra da archivio"><i class="fa fa-folder-open" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
							<!--b-form-group id="categoriaGroup" horizontal label="Categoria" :label-cols="3" label-for="categoria">
								<b-form-input id="categoria" type="text" v-model="form.categoria"></b-form-input>
							</b-form-group>
							<b-form-group id="agenteGroup" horizontal label="Agente" :label-cols="3" label-for="agente">
								<b-row>
									<b-col>
										<autocomplete-nome :suggestions="agenti" id="agente" @select="onAgenteSelect" @change="onAgenteChange" v-model="form.agente.id" cols="10"></autocomplete-nome>
									</b-col>
									<b-col cols="2">
										<b-button variant="primary" v-b-toggle.agente_pnl>+ info</b-button>
									</b-col>
								</b-row>
							</b-form-group> 
							<b-collapse id="agente_pnl" class="mt-2" @shown="updateImageAgente"> 
								<b-card class="p-1 mb-1">
									<picture-input ref="pictureAgente" @change="onAvatarAgenteChange" width="150" height="150" size="10" class="mb-1" :customStrings="customStrings" :prefill="avatar_agente"></picture-input>
									<b-form-group id="emailAgenteGroup" horizontal label="Email" :label-cols="3" label-for="emailAgente">
										<b-form-input id="emailAgente" type="email" v-model="form.agente.email"></b-form-input>
									</b-form-group>
									<b-form-group id="telefono1AgenteGroup" horizontal label="Telefono 1" :label-cols="3" label-for="telefono1Agente">
										<b-form-input id="telefono1Agente" type="tel" v-model="form.agente.telefono1"></b-form-input>
									</b-form-group> 
									<b-form-group id="telefono2AgenteGroup" horizontal label="Telefono 2" :label-cols="3" label-for="telefono2Agente">
										<b-form-input id="telefono2Agente" type="tel" v-model="form.agente.telefono2"></b-form-input>
									</b-form-group>
									<b-form-group id="AgenziaGroup" horizontal label="Agenzia" :label-cols="3" label-for="agenzia">
										<autocomplete-nome :suggestions="agenzie" id="agenzia" @select="onAgenziaSelect" @change="onAgenziaChange" v-model="form.agenzia.id"></autocomplete-nome>
									</b-form-group>
								</b-card>
							</b-collapse>
							<b-form-group id="valoreGroup" horizontal label="Valore giocatore" :label-cols="3" label-for="valore">
								<b-form-input id="valore" type="text" v-model="form.valore"></b-form-input>
							</b-form-group>
							<b-form-group id="stipendioGroup" horizontal label="Stipendio lordo" :label-cols="3" label-for="stipendio">
								<b-form-input id="stipendio" type="text" v-model="form.stipendio"></b-form-input>
							</b-form-group>
							<b-form-group id="bonusGroup" horizontal label="Bonus" :label-cols="3">					
								<b-row>
									<b-col>
										<b-button variant="primary" @click="addBonus">+</b-button>
									</b-col>
								</b-row>
								<b-row v-for="(item, index) in form.bonus" class="my-1" :key="item">
									<b-col>
										<b-form-group id="valoreBonusGroup" horizontal label="Valore" :label-cols="3">
											<b-form-input type="text" v-model="item.valore"></b-form-input>
										</b-form-group>
										<b-form-group id="descrizioneBonusGroup" horizontal label="Descrizione" :label-cols="3">
											<b-form-input type="text" v-model="item.descrizione"></b-form-input>
										</b-form-group>
										<b-button variant="primary" @click="removeBonus(item)">-</b-button>
									</b-col>	
								</b-row>							
							</b-form-group>
							<b-form-group id="telefono1Group" horizontal label="Telefono 1" :label-cols="3" label-for="telefono1">
								<b-form-input id="telefono1" type="tel" v-model="form.telefono1"></b-form-input>
							</b-form-group>
							<b-form-group id="telefono2Group" horizontal label="Telefono 2" :label-cols="3" label-for="telefono2">
								<b-form-input id="telefono2" type="tel" v-model="form.telefono2"></b-form-input>
							</b-form-group>
							<b-form-group id="infortuniGroup" horizontal label="Infortuni" :label-cols="3">					
								<b-row>
									<b-col>
										<b-button variant="primary" @click="addInfortunio">+</b-button>
									</b-col>
								</b-row>
								<b-row v-for="(item, index) in form.infortuni" class="my-1" :key="item">
									<b-col>
										<b-form-group id="tipoInfortunioGroup" horizontal label="Tipo" :label-cols="3">
											<b-form-select v-model="item.tipo" :options="infortuni" value-field="id" text-field="nome"></b-form-select>
										</b-form-group>
										<b-form-group id="dataInfortunioGroup" horizontal label="Data" :label-cols="3">
											<b-form-input type="date" v-model="item.data"></b-form-input>
										</b-form-group>
										<b-button variant="primary" @click="removeInfortunio(item)">-</b-button>
									</b-col>	
								</b-row>							
							</b-form-group>
							<b-form-group id="giovaniliVivaioGroup" horizontal :label-cols="3">
								<b-form-checkbox id="giovanili" v-model="form.giovanili">
									Cresciuto nelle giovanili
								</b-form-checkbox>
								<b-form-checkbox id="vivaio_italiano" v-model="form.vivaio">
									Cresciuto in un vivaio italiano
								</b-form-checkbox>
							</b-form-group--> 
							<b-form-group id="baricentroGroup" horizontal label="Baricentro" :label-cols="3">
								<b-form-select v-model="form.baricentro">
									<option value="0">Basso</option>
									<option value="1">Medio</option>
									<option value="2">Alto</option>
								</b-form-select>
							</b-form-group>
							<b-form-group id="somatotipoGroup" horizontal label="Somatotipo" :label-cols="3">
								<b-form-select v-model="form.somatotipo">
									<option value="0">Normolineo</option>
									<option value="1">Brevilineo</option>
									<option value="2">Longilineo</option>
								</b-form-select>
							</b-form-group>
							<b-form-group id="costituzioneGroup" horizontal label="Costituzione" :label-cols="3">
								<b-form-select v-model="form.costituzione">
									<option value="0">Esile</option>
									<option value="1">Media</option>
									<option value="2">Robusta</option>
								</b-form-select>
							</b-form-group>
							<b-form-group id="ruoloIdealeGroup" horizontal label="Ruolo ideale" :label-cols="3">
								<b-form-select v-model="form.ruolo_ideale" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
							</b-form-group>
							<b-form-group id="sistemaGiocoIdealeGroup" horizontal label="Sistema di gioco ideale" :label-cols="3">
								<b-form-select v-model="form.sistema_ideale" :options="sistemi" value-field="id" text-field="sistema_gioco"></b-form-select>
							</b-form-group>
							<!--b-form-group id="flagVariGroup" horizontal :label-cols="3" v-if="auth.isUserEnable('scout_flag_player')">
								<b-form-checkbox id="monitorare" v-model="form.monitorare">
									Da monitorare
								</b-form-checkbox>
								<b-form-checkbox id="prospettiva" v-model="form.prospettiva">
									Di prospettiva
								</b-form-checkbox>
								<b-form-checkbox id="prestito" v-model="form.prestito">
									In prestito
								</b-form-checkbox>
								<b-form-checkbox id="segui" v-model="form.segui">
									Segui
								</b-form-checkbox>
							</b-form-group-->
							<b-form-group id="GioSimiliGroup" horizontal label="Giocatori simili" :label-cols="3" label-for="gioSimili">
								<b-input-group>
									<b-form-input type="text" v-model="giocatore_simile" placeholder="Nominativo giocatore simile"></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="searchPlayerSimile"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
								<b-form-textarea id="gioSimili"
                     				v-model="form.giocatori_simili"
                     				placeholder="Giocatori simili"
                     				:rows="2"
                     				:max-rows="2">
    							</b-form-textarea>
							</b-form-group>
							<!--b-form-group id="SegnalatoriGroup" horizontal label="Segnalatore" :label-cols="3" label-for="segnalatore" v-if="auth.isUserEnable('scout_segnalatori')">
								<autocomplete-nome :suggestions="segnalatori" id="segnalatore" @select="onSegnalatoreSelect" @change="onSegnalatoreChange" v-model="form.segnalatore.id"></autocomplete-nome>
							</b-form-group-->
							<b-form-group id="altriRuoliGroup" horizontal label="Altri ruoli" :label-cols="3">
								<div id="altri_ruoli">{{altri_ruoli_lbl != "" ? altri_ruoli_lbl : "&nbsp"}}</div>
							</b-form-group> 
							<b-button type="submit" variant="primary">Crea</b-button>
							<b-button type="reset" variant="secondary">Annulla</b-button>
						</b-form>
					</b-col>
					<b-col class="text-center">
						<div class="outsideWrapper">
							<div class="insideWrapper">
								<div id="morf" style="margin-bottom: 10px;" class="coveredImage" v-if="form.baricentro && form.somatotipo && form.costituzione">
									<img :src="sagoma" :style="'height:' + altezza_sagoma + 'px!important;margin-top:' + (550 - altezza_sagoma) +'px'"/>
									<div id="h_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'" v-if="form.altezza > 0">H: {{form.altezza}} cm</div>
									<div id="b_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">B: {{etichette.baricentro[form.baricentro]}}</div>
									<div id="s_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">S: {{etichette.somatotipo[form.somatotipo]}}</div>
									<div id="c_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">C: {{etichette.costituzione[form.costituzione]}}</div> 
								</div>
								<canvas width="250px" height="550px" id="mycanvas" ref="mycanvas" class="coveringCanvas"></canvas>
							</div>
						</div>					
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col>
						<div class="modulo">
							<div class="campo">
								<img src="/assets/images/campo.svg">
							</div>
							<div v-for="r in ruoli" :class="'player ' + r.sigla.toLowerCase()" v-on:click="selezionaRuolo(r.id)" :title="r.ruolo">
								<span :class="'ruolo' + (form.altri_ruoli.indexOf(r.id) > -1 ? ' on' : (form.ruolo_ideale == r.id ? ' ide' : ''))">{{ r.sigla }}<div :class="'ovale' + (form.altri_ruoli.indexOf(r.id) > -1 ? ' bg_on' : (form.ruolo_ideale == r.id ? ' bg_ide' : ''))"></div></span>
							</div>
						</div>       
					</b-col>
				</b-row>
			</div>
			<b-modal id="searchPlayerModal" ref="searchPlayerModal" title="Ricerca giocatore" ok-only ok-title="Chiudi">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<p v-if="!is_search && !players.length">Nessun giocatore trovato!</p>
				<div v-if="!is_search && players.length">
					<p>Trovati {{players.length}} giocatori!</p>
					<hr/>
					<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="(player, index) in players" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaGiocatore(player)">
							<div class="col-md-3 my-1 mr-1" style="text-align: center;">
								<img v-if="player.imageDataURL" :src="player.imageDataURL"/>
							</div>
							<div class="col-md-6 my-1">
								<h5 style="display: inline;">{{ player.lastName }} {{ player.firstName }}</h5><br />
								<span class="text-muted" v-if="player.birthArea"><flag v-if="player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="24"></flag> {{ player.birthArea.name }} </span><br />
								<span class="text-muted">{{ player.birthDate | formatDate }}</span><br />
								<span class="text-muted">{{ player.role.name }}</span><br />
								<span class="text-muted" v-if="player.currentTeam"><flag v-if="player.birthArea.alpha2code" :country="player.currentTeam.area.alpha2code" :size="24"></flag> {{ player.currentTeam.officialName }} </span><br />     
							</div>
							<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
								<img v-if="player.currentTeam" :src="player.currentTeam.imageDataURL" width="100" />
							</div>
						</div>
					</div>
				</div>
			</b-modal>
			<b-modal id="searchPlayerSimileModal" ref="searchPlayerSimileModal" title="Ricerca giocatore simile" ok-only ok-title="Chiudi">
				<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
				<p v-if="!is_search && !players.length">Nessun giocatore trovato!</p>
				<div v-if="!is_search && players.length">
					<p>Trovati {{players.length}} giocatori!</p>
					<hr/>
					<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="(player, index) in players" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaGiocatoreSimile(player)">
							<div class="col-md-3 my-1 mr-1" style="text-align: center;">
								<img v-if="player.imageDataURL" :src="player.imageDataURL"/>
							</div>
							<div class="col-md-6 my-1">
								<h5 style="display: inline;">{{ player.lastName }} {{ player.firstName }}</h5><br />
								<span class="text-muted" v-if="player.birthArea"><flag v-if="player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="24"></flag> {{ player.birthArea.name }} </span><br />
								<span class="text-muted">{{ player.birthDate | formatDate }}</span><br />
								<span class="text-muted">{{ player.role.name }}</span><br />
								<span class="text-muted" v-if="player.currentTeam"><flag v-if="player.birthArea.alpha2code" :country="player.currentTeam.area.alpha2code" :size="24"></flag> {{ player.currentTeam.officialName }}</span><br />     
							</div>
							<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
								<img v-if="player.currentTeam" :src="player.currentTeam.imageDataURL" width="100" />
							</div>
						</div>
					</div>
				</div>
			</b-modal>
			<b-modal id="searchTeamModal" ref="searchTeamModal" title="Ricerca squadra" ok-only ok-title="Chiudi">
				<div v-if="show_aree">
					<span>Seleziona un'area: </span>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
						<b-row>
							<b-col cols="3" v-for="area in aree" style="padding: 0.2rem">
								<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.sigla3)">
									<flag :country="area.sigla" :size="24"></flag><br/>
									{{area.paese}}
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
				<div v-if="show_competizioni">
					<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
					<div v-if="competizioni.length > 0">
						<span>Seleziona una competizione: </span>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<b-row>
								<b-col cols="4" v-for="comp in competizioni" style="padding: 0.2rem">
									<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp)">
										<strong>{{comp.name}}</strong><br/>
										<span class="font-size-xs color-rosso">{{$i18n.t(comp.format)}}</span><br/>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
				<div v-if="show_squadre">
					<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
					<div v-if="squadre.length > 0">
						<span>Seleziona una squadra: </span>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<b-row>
								<b-col cols="4" v-for="team in squadre" style="padding: 0.2rem">
									<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selezionaSquadra(team)">
										<img :src="team.imageDataURL" width="50" /><br/>
										<strong>{{team.officialName}}</strong><br/>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
			</b-modal>
			<b-modal id="loadTeamModal" ref="loadTeamModal" title="Carica squadra" ok-only ok-title="Chiudi">
				<div v-if="show_aree">
					<span>Seleziona un'area: </span>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
						<b-row>
							<b-col cols="3" v-for="area in aree" style="padding: 0.2rem">
								<b-card class="small text-center" style="cursor: pointer;height:100%" @click="scegliArea(area.id)">
									<flag :country="area.sigla" :size="24"></flag><br/>
									{{area.paese}}
								</b-card>
							</b-col>
						</b-row>
					</div>
				</div>
				<div v-if="show_squadre">
					<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
					<div v-if="squadre.length > 0">
						<span>Seleziona una squadra: </span>
						<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
							<b-row>
								<b-col cols="4" v-for="team in squadre" style="padding: 0.2rem">
									<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="scegliSquadra(team)">
										<img :src="team.logo_url" width="50" /><br/>
										<strong>{{team.name}}</strong><br/>
										<span class="font-size-xs color-rosso">{{ team.categoria ? team.categoria.name : ''}}</span><br/>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
			</b-modal>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

import moment from 'moment'
import * as types from '../../../store/types'
var Auth = require('../../../auth.js');

export default {

	data: function() {
		return {
			image_player: '',
			image_agente: '',
			players: [],
			is_search: false,
			file_avatar: '',
			file_avatar_agente: '',
			form: {
				nome: '',
				cognome: '',
				data_nascita: '',
				paese_nascita: 0,
				passaporti: [],
				altezza: 0,
				peso: 0,
				piede: '0',
				squadra: '',
				categoria: '',
				agente: {
					id: 0,
					nome: '',
					email: '',
					telefono1: '',
					telefono2: '',
				},
				agenzia: {
					id: 0,
					nome: '',
				},
				segnalatore: {
					id: 0,
					nome: '',
				},
				valore: '',
				stipendio: '',
				bonus: [],
				telefono1: '',
				telefono2: '',
				infortuni: [],
				giovanili: false,
				vivaio: false,
				baricentro: '',
				somatotipo: '',
				costituzione: '',
				ruolo_ideale: 0,
				sistema_ideale: 0,
				monitorare: false,
				prospettiva: false,
				prestito: false,
				segui: false,
				giocatori_simili: '',
				altri_ruoli: [],
				wyscout_id: '',
				wyscout_team_id: '',
				current_team: {},
				id_squadra: 0,
			},
			paesi: [],
			agenti: [],
			agenzie: [],
			segnalatori: [],
			infortuni: [],
			ruoli: [],
			sistemi: [],
			etichette: {
				baricentro: ['Basso', 'Medio', 'Alto'],
				somatotipo: ['Normolineo', 'Brevilineo', 'Longilineo'],
				costituzione: ['Esile', 'Media', 'Robusta'],
			},
			errors: [],
			customStrings: {
  				drag: this.$i18n.t('drag'),
  				fileSize: this.$i18n.t('fileSize'),
  				fileType: this.$i18n.t('fileType'),
			},
			giocatore_simile: '',
			aree: [],
			competizioni: [],
			squadre: [],
			show_aree: false,
			show_competizioni: false,
			show_squadre: false,
		}
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		autocompletePaese: require('../../layout/autocomplete_paese.vue').default,
		autocompleteNome:  require('../../layout/autocomplete_nome.vue').default,
		pictureInput:      require('../../layout/picture_input.vue').default,
		flag: require('../../layout/flag.vue').default,
	},

	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			var formData = new FormData();
			formData.append("form", JSON.stringify(this.form));
			formData.append("file_avatar", this.file_avatar);
		//	formData.append("file_avatar_agente", this.file_avatar_agente);
			this.$http.post(this.$store.state.apiEndPoint + '/academy/scouting/add/giocatore', formData).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            	if(response.data.giocatore_id > 0) {	
            		this.$router.push({ name: "academy_scouting_view_giocatore", params: { id: response.data.giocatore_id } });
            	} else {
            		this.$router.push({ name: "academy_dashboard"});
            	}
            }
        }, (response) => {  
        	if (response.status == 422) {
        		this.errors = response.data.error;
        		for (var index in this.errors) {
        			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        		}
        		if(response.data.giocatore_id > 0) {	
            		this.$router.push({ name: "academy_scouting_view_giocatore", params: { id: response.data.giocatore_id } });
            	} else {
            		this.$router.push({ name: "academy_dashboard"});
            	}
    		}
            // error callback
        });
		},
		onAvatarChange(e) {
		//	console.log('New picture selected!')
			if (this.$refs.picturePlayer.file) {
				this.file_avatar = this.$refs.picturePlayer.file;
			} else {
				console.log('FileReader Player API not supported: use the <form>')
			}
		},
		onAvatarAgenteChange(e) {
		//	console.log('New picture selected!')
			if (this.$refs.pictureAgente.file) {
				this.file_avatar_agente = this.$refs.pictureAgente.file;
			} else {
		//		console.log('FileReader Player API not supported: use the <form>')
			}
		},
		onPaeseNascitaSelect(item) {
			this.form.paese_nascita = item;
		},
		onPaeseNascitaChange() {
			this.form.paese_nascita = 0;
		},
		addPassaporto: function () {
			this.form.passaporti.push({ paese_id: 0 });
		},
		removePassaporto: function (item) {
			var index = this.form.passaporti.indexOf(item);	
			this.form.passaporti.splice(index, 1);
		},
		onPassaportoSelect(item, index) {
			this.form.passaporti[index].paese_id = item;
		},
		onPassaportoChange(index) {
			this.form.passaporti[index].paese_id = 0;
		},
		onAgenteSelect(id) {
			this.form.agente.id = id;
			for(var index in this.agenti) {
				var agente = this.agenti[index];
				if(agente.id == id) {
					this.form.agente.nome = agente.nome;
					this.form.agente.email = agente.email;	
					this.form.agente.telefono1 = agente.telefono;	
					this.form.agente.telefono2 = agente.telefono2;
					if(agente.avatar != null && agente.avatar != "") {
						this.image_agente = agente.avatar_url;
					}
					break;
				}
			}
		},
		onAgenteChange(value) {
			this.form.agente.id = 0;
			this.form.agente.nome = value;
			this.form.agente.email = '';	
			this.form.agente.telefono1 = '';	
			this.form.agente.telefono2 = '';
			this.image_agente = '';
		},
		onAgenziaSelect(id) {
			this.form.agenzia.id = id;
			for(var index in this.agenzie) {
				var agenzia = this.agenzie[index];
				if(agenzia.id == id) {
					this.form.agenzia.nome = agenzia.nome;
					break;
				}
			}
		},
		onAgenziaChange(value) {
			this.form.agenzia.id = 0;
			this.form.agenzia.nome = value;
		},
		onSegnalatoreSelect(id) {
			this.form.segnalatore.id = id;
			for(var index in this.agenzie) {
				var segnalatore = this.segnalatori[index];
				if(segnalatore.id == id) {
					this.form.segnalatore.nome = segnalatore.nome;
					break;
				}
			}
		},
		onSegnalatoreChange(value) {
			this.form.segnalatore.id = 0;
			this.form.segnalatore.nome = value;
		},
		updateImageAgente() {
			this.$refs.pictureAgente.onResize();
		},
		addBonus: function () {
			this.form.bonus.push({ valore: 0, descrizione: '' });
		},
		removeBonus: function (item) {
			var index = this.form.bonus.indexOf(item);	
			this.form.bonus.splice(index, 1);
		},
		addInfortunio: function () {
			this.form.infortuni.push({ tipo: 0, data: '' });
		},
		removeInfortunio: function (item) {
			var index = this.form.infortuni.indexOf(item);	
			this.form.infortuni.splice(index, 1);
		},
		selezionaRuolo: function (ruolo) {
			if(ruolo != this.form.ruolo_ideale) {        
				if(this.form.altri_ruoli.indexOf(ruolo) == -1) {
					this.form.altri_ruoli.push(ruolo);
				} else {
					this.form.altri_ruoli.splice(this.form.altri_ruoli.indexOf(ruolo), 1);
				}
			}               
		},
		searchPlayer: function() {
			var query = this.form.cognome.trim().replace(/ /g, "_");
			if(query != "") {
				this.players = [];
				this.is_search = true;
				this.$refs.searchPlayerModal.show();
				if(this.form.nome.trim() != "") {
					query = query + "_" + this.form.nome.trim().replace(/ /g, "_");                        
				}
				this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then((response) => {

					if (response.ok)
					{
						this.players = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});

			}
		},
		searchPlayerSimile: function() {
			var query = this.giocatore_simile.trim().replace(/ /g, "_");
			if(query != "") {
				this.players = [];
				this.is_search = true;
				this.$refs.searchPlayerSimileModal.show();
				this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then((response) => {

					if (response.ok)
					{
						this.players = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			}
		},
		searchTeam: function() {
			this.aree = [];
			this.show_aree = true;
			this.show_competizioni = false;
			this.show_squadre = false;
			if(this.aree.length == 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
					if (response.ok)
					{
						this.aree = response.data; 
					}
				}, (response) => {
            // error callback
        		});
			}
			this.$refs.searchTeamModal.show();	
		},
		selezionaArea: function(sigla) {
			this.show_aree = false;
			this.competizioni = [];
			this.is_search = true;
			this.show_competizioni = true;
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competitions/' + sigla).then((response) => {

				if (response.ok)
				{
					this.competizioni = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});
		},
		selezionaCompetizione: function(competizione) {

			this.show_competizioni = false;
			this.squadre = [];
			this.is_search = true;
			this.show_squadre = true;
			this.form.categoria = competizione.name;
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/teams/by/competition/' + competizione.wyId).then((response) => {

				if (response.ok)
				{
					this.squadre = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});
		},
		selezionaSquadra: function(squadra) {
			this.$refs.searchTeamModal.hide();
			this.players = [];
			this.is_search = true;
			this.$refs.searchPlayerModal.show();
			this.$http.get(this.$store.state.apiEndPoint + '/ws/get/players/by/team/' + squadra.wyId).then((response) => {

				if (response.ok)
				{
					this.players = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});	
		},
		selezionaGiocatore: function (giocatore) {
			this.form.wyscout_id = giocatore.wyId;
			this.form.cognome = giocatore.lastName;
			this.form.nome = giocatore.firstName;
			this.form.data_nascita = giocatore.birthDate;
			this.form.altezza = giocatore.height;
			this.form.peso = giocatore.weight;
			this.form.piede = this.piede(giocatore.foot);

			if(giocatore.currentTeam) {
				this.form.squadra = giocatore.currentTeam.officialName;
				this.form.wyscout_team_id = giocatore.currentTeam.wyId;
				this.form.current_team = giocatore.currentTeam;
			} else {
				this.form.squadra = '';
				this.form.wyscout_team_id = '';
				this.form.current_team = {};
			}
			this.form.id_squadra = 0;
			this.image_player = giocatore.imageDataURL;
			if(giocatore.birthArea.alpha2code) {
				for (var index in this.paesi) {
					var paese = this.paesi[index];
					if (giocatore.birthArea.alpha2code == paese.sigla) {
						this.$refs.paese_nascita.setPaese(paese.id, paese.paese)
						break;
					}
				}
			}
			this.form.passaporti = [];
			if(giocatore.passportArea.alpha2code) {
				for (var index in this.paesi) {
					var paese = this.paesi[index];
					if (giocatore.passportArea.alpha2code == paese.sigla) {
						this.addPassaporto();
						this.$nextTick(function () {
							this.$refs['passaporto'][0].setPaese(paese.id, paese.paese)
						})	
						break;	
					}
				}
			}
			this.$refs.searchPlayerModal.hide();          
		},

		selezionaGiocatoreSimile: function (giocatore) {		
			if(this.form.giocatori_simili != "") {
				this.form.giocatori_simili += ", ";
			}
			this.form.giocatori_simili += giocatore.lastName + " " + giocatore.firstName;
			this.giocatore_simile = "";
			this.$refs.searchPlayerSimileModal.hide();          
		},

		piede: function (foot) {
			if(foot == "right") {        
				return "0";
			}  else if(foot == "left") {
				return "1";
			}  else if(foot == "both") {
				return "2";
			} 
			return 0;          
		},
		loadSquadra: function() {
			this.aree = [];
			this.show_aree = true;
			this.show_squadre = false;
			if(this.aree.length == 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/paesi/squadre').then((response) => {
					if (response.ok)
					{
						this.aree = response.data; 
					}
				}, (response) => {
            // error callback
        		});
			}
			this.$refs.loadTeamModal.show();	
		},
		scegliArea: function(id) {
			this.squadre = [];
			this.show_aree = false;
			this.is_search = true;
			this.show_squadre = true;
			this.$http.get(this.$store.state.apiEndPoint + '/squadre/paese/' + id).then((response) => {

				if (response.ok)
				{
					this.squadre = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});
		},
		scegliSquadra: function(squadra) {
			this.form.id_squadra = squadra.id;
			this.form.squadra = squadra.officialName;
			this.form.wyscout_team_id = squadra.wyscout_team_id;
			this.form.current_team = squadra;
			if(squadra.categoria) {
				this.form.categoria = squadra.categoria.name;
			}
			this.$refs.loadTeamModal.hide();	
		},
	},

	computed: {
		auth: function() {
            return Auth;
        },
		avatar: function() {
			if(this.image_player != "") {
				return this.image_player;
			} 
			return '';
		},
		avatar_agente: function() {
			if(this.image_agente != "") {
				return this.image_agente;
			} 
			return '';
		},
		sagoma: function(){     
			return "/assets/morfologia/s" + this.form.somatotipo + "b" + this.form.baricentro + "c" + this.form.costituzione + ".png";
		},

		altezza_sagoma: function() {
			var height = 550;
			if(this.form.baricentro > -1 && this.form.somatotipo > -1 && this.form.costituzione > -1) {
				if(!isNaN(this.form.altezza)) {
					if(this.form.altezza < 190 && this.form.altezza >= 180) {
						height = 530;
					}else if(this.form.altezza < 180 && this.form.altezza >= 170) {
						height = 510;
					} else if(this.form.altezza < 170 && this.form.altezza >= 160) {
						height = 490;
					} else if(this.form.altezza < 160) {
						height = 470;
					}     
				}
				return height;
			}
		},

		altri_ruoli_lbl: function() {
			var label = "";
			for (var index in this.form.altri_ruoli) {
				var id_ruolo = this.form.altri_ruoli[index];
				if(id_ruolo > 0) {
					label = label + this.ruoli[id_ruolo - 1].ruolo + ", ";
				}
			}
			if(label.length > 0) {
				label = label.substr(0, label.length - 2); 
			}
			return label;
		},

		logo_squadra: function() {
			if(this.form.id_squadra > 0) {
				return this.form.current_team.logo_url;
			} 
			if(this.form.wyscout_team_id > 0 && this.form.current_team.imageDataURL) {
				return this.form.current_team.imageDataURL;
			}  
			return '/assets/varie/team.png';
		},
	},

	created: function ()
	{
		this.$http.get(this.$store.state.apiEndPoint + '/paesi').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.paesi = response.data;    
            }
        }, (response) => {
            // error callback
        });

	/*	this.$http.get(this.$store.state.apiEndPoint + '/infortuni').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.infortuni = response.data;    
            }
        }, (response) => {
            // error callback
        });  */

		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.ruoli = response.data;    
            }
        }, (response) => {
            // error callback
        });

		this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.sistemi = response.data;    
            }
        }, (response) => {
            // error callback
        });

    /*    this.$http.get(this.$store.state.apiEndPoint + '/agenti').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.agenti = response.data;    
            }
        }, (response) => {
            // error callback
        });

        this.$http.get(this.$store.state.apiEndPoint + '/agenzie').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.agenzie = response.data;    
            }
        }, (response) => {
            // error callback
        });

        this.$http.get(this.$store.state.apiEndPoint + '/segnalatori').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.segnalatori = response.data;    
            }
        }, (response) => {
            // error callback
        });  */

	},
	mounted () {
		var context = this.$refs.mycanvas.getContext("2d");
		context.lineWidth = 1;
		context.strokeStyle = "#CCCCCC";
		context.lineCap = "square"; 
		context.moveTo(0, 0);
		context.lineTo(300, 0);
		context.moveTo(0, 80);
		context.lineTo(300, 80);
		context.moveTo(0, 280);
		context.lineTo(300, 280);
		context.moveTo(0, 550);
		context.lineTo(300, 550);
		context.moveTo(65, 0);
		context.lineTo(65, 550);
		context.moveTo(162, 0);
		context.lineTo(162, 550);
		context.stroke();

	},

	watch: {
		'form.ruolo_ideale': function (ruolo) {
			if(this.form.altri_ruoli.indexOf(ruolo) > -1) {
				this.form.altri_ruoli.splice(this.form.altri_ruoli.indexOf(ruolo), 1);
			}
		},
		form: {
			handler: function () {this.errors = []; },
    		deep: true
  		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	}
}
</script>
