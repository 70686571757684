<template>
	<b-card>

		<pre>{{contratto.persona.nome}} {{contratto.persona.cognome}}</pre>
		<!-- <code>{{contratto.persona}}</code> -->
		
	</b-card>
</template>


<script>

export default {
	props: [ 'contratto' ]
};

</script>