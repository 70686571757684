<template>
    <exerciseform></exerciseform>
</template>

<script>
    export default {
        components: {
            exerciseform:      require('./form.vue').default,
        }
    }
</script>
