<template>
	<div>
		<div ref="w"></div><!-- This provide the overall width -->

		<div :style="'position: relative; width: 100%; height: ' + canvas.height + 'px'">

			<div class="helper" ref="panel" :style="panelStyle">

				<div v-if="selection.length > 0" style="border-bottom: 1px solid rgba(80,80,80)">

					<div class="helper-tit">Selezionati</div>
					<div style="padding: 0.1rem 0.25rem">
						<div v-for="player in selection" style="font-size: 80%" :key="'u-' + player">
							<player-in-campo :blocked="true" :editing="ux.editLevels" :player="findPlayer(player)"></player-in-campo>
						</div>
						<div>
							<b-button @click.prevent="removePlayers" variant="outline-secondary" size="xs">Rimuovi dal campo</b-button>
						</div>
						<div>
							Livello
							<div>
								<b-button-group>
									<b-button @click.prevent="applyLevel(a-1)" variant="outline-secondary" size="xs" v-for="a in 5">{{a-1}}</b-button>
								</b-button-group>
							</div>
						</div>
						<div>
							Sposta In
							<div class="d-flex">
								<b-select v-model="ux.moveTo">
									<b-select-option v-for="a in containers" :value="a.id">{{ a.name }}</b-select-option>
								</b-select>
								<b-button @click.prevent="moveTo" variant="outline-secondary" size="xs">Sposta</b-button>
							</div>
						</div>
					</div>

				</div>

				<div class="helper-tit">
					Non In Campo
				</div>
				
				<div class="p-1">

					<div v-if="unused.length == 0" class="small text-muted">
						Tutti i giocatori sono schierati.
					</div>

					<ul class="players">
						<li :id="'p-' + player.id" v-for="player in unused" :key="player.id" style="font-size: 80%">
							<player-in-campo :blocked="true" :editing="ux.editLevels" :player="player"></player-in-campo>
						</li>
					</ul>

				</div>

				<div class="p-1 small">
					<div><b-form-checkbox v-model="ux.hideNull" switch>Nascondi Gruppi Vuoti</b-form-checkbox></div>
					<div><b-form-checkbox v-model="ux.editLevels" switch> Modifica Livelli</b-form-checkbox></div>
					<div><b-form-checkbox v-model="ux.selection" switch> Seleziona Giocatori</b-form-checkbox></div>
				</div>

				<b-button-group class="p-1">
					<b-button size="xs" variant="outline-secondary" @click.prevent="orderByLevel">Ordina per Livelli</b-button>
					<b-button size="xs" v-if="thePresets.length > 0" @click.prevent="applyPreset" variant="outline-secondary">
						<em class="fa fa-check"></em> Posizione Default
					</b-button>
				</b-button-group>

				<div class="p-1">
					<b-form-group label="Dimensione Blocchi" class="mt-2">
						<b-input type="range" min="60" max="100" v-model="ux.fontSize"></b-input>
					</b-form-group>

					<b-button-group>
						<b-button size="xs" variant="outline-danger" @click.prevent="makeBlock(-0.01)">Accorcia Blocchi</b-button>
						<b-button size="xs" variant="outline-danger" @click.prevent="makeBlock(0.01)">Allunga Blocchi</b-button>
					</b-button-group>
				</div>

				<div class="p-1 mt-2">

					<!-- <b-button variant="outline-success" @click.prevent="exportPositions">Export Positions</b-button> -->

					<b-button size="xs" variant="outline-secondary" @click.prevent="ux.panel = 2" v-if="ux.panel == 1">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 20px; height: 20px">
							<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
						</svg>
					</b-button>

					<b-button size="xs" variant="outline-secondary" @click.prevent="ux.panel = 1" v-if="ux.panel == 2">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 20px; height: 20px">
							<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
						</svg>
					</b-button>

				</div>


			</div>
			
			<div ref="canvas" class="d-flex int" :style="canvasStyle">

				<div v-if="ux.showSelectionHelp" class="small text-muted" style="position: absolute; top: 10px; left: 10px">
					Premi "<a href="javascript:;" @click.prevent="resetSelection()">R</a>" per ripristinare la selezione.
				</div>

				<div ccc ref="ccc">

					<img :src="'/assets/campi/'+campoAsset" style="position: absolute; top:0; left: 0; pointer-events: none" />

					<div v-for="cont in theContainers" class="playerContainer" ref="container" :container-id="cont.id" :key="cont.id" :style="calculatePosition(cont)">
						<div class="playerContainer-title" v-if="ruoli">
							<inline-string v-model="cont.name">{{ contName(cont.id) }}</inline-string>
							<a href="javascript:;" @click.prevent="selectContainer(cont.players)" v-if="ux.selection"><small>Select</small></a>
						</div>
						<ul class="players">
							<li :id="'p-' + player" v-for="player, k in cont.players" :b="cont.id" :key="player" c="1">
								<player-in-campo @select="doSelect(player)" :last="k == cont.players.length - 1" @splice="cont.players.splice(k, 1); ui()" :selection="ux.selection" :editing="ux.editLevels" :player="findPlayer(player)">
									<input @click.stop=";" v-model="selection" type="checkbox" :value="player">
								</player-in-campo>
							</li>
						</ul>
						<div style="position: absolute; bottom: 0; left: 0; width: 100%; text-align: center; font-size: 80%; padding-bottom: 0.02rem" v-if="cont.players.length == 0">Drop Here</div>
					</div>
				</div>



			</div>
			
			<svg :style="canvasStyle" style="pointer-events: none; z-index: 99999">
				<rect v-if="softSelection" stroke="rgba(0,0,0,.5)" stroke-dasharray="4" fill="rgba(0,0,0,.1)" :x="softSelection.x1 * canvas.width" :y="softSelection.y1 * canvas.height" :width="(softSelection.x2-softSelection.x1) * canvas.width" :height="(softSelection.y2-softSelection.y1) * canvas.height"></rect>
			</svg>

		</div>

	</div>
</template>

<style type="text/css">

.playerContainer {
	border: 1px solid rgb(150,150,150);
	border-radius: 3px;
	font-size: 13px;
}

.playerContainer-title {
	background-color: rgba(255,255,255,.5);
	padding: 0.1rem 0.25rem;
	text-transform: uppercase;
	border-bottom: 1px solid rgb(150,150,150);
	color: #a80532;
}

.playerContainer .players, .helper .players {
	list-style: none;
	margin: 0;
	padding: 0;
	min-height: 15px;
}

.playerContainer .noPlayers, .helper .noPlayers {
	min-height: 15px;
}

.playerContainer .players li {
}


.int {
	position: relative;
}

.helper {
	margin: 0.2em;
	border: 1px solid rgb(150,150,150);
	box-shadow: 1px 1px 2px rgba(100,100,100, .5);
	border-radius: 3px;
	background-color: rgba(255,255,255,.9);
	min-width: 160px;
	max-width: 20vw;
	position: absolute;
	top: 0;
	z-index: 99999;
}

.helper-tit {
	border-bottom: 1px solid  rgb(150,150,150);
	background-color: rgba(255,255,255,.5);
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 1px;
	color: rgb(80,80,80);
	padding: .25rem;
}

</style>

<script>

import _ from 'lodash';

export default {
	props: [ 'base', 'startingPlayers', 'ratio', 'presets', 'campo', 'ruoli' ],
	components: {
		InlineString: require('@/components/layout/inline-string.vue').default,
		PlayerInCampo: require('@/components/layout/scouting/player-in-campo.vue').default,
	},
	data: function() {
		return {
			mouse: {
				x: 0,
				y: 0,
				selectionSquare: false,
				dragging: false,
				selecting: false
			},
			softSelection: null,
			selection: [],
			ux: {
				blocks: [],
				fontSize: 100,
				preset: null,
				showSelectionHelp: false,
				selectionBackup: null,
				moveTo: null,
				panel: 2,
				hideNull: false,
				editLevels: false,
				selection: false
			},
			canvas: {
				width: null,
				height: null
			},
			windowWidth: 0,
			background: 'background-1',
			players: [],
			containers: [
				{ id: 1, name: 'Name 1', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 2, name: 'Name 2', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 3, name: 'Name 3', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 4, name: 'Name 4', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 5, name: 'Name 5', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 6, name: 'Name 6', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 7, name: 'Name 7', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 8, name: 'Name 8', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 9, name: 'Name 9', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 10, name: 'Name 10', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 11, name: 'Name 11', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
			]

		}
	},
	watch: {
		toStore: {
			deep: true,
			handler: function() {
				console.log('Changed', this.toStore);
				this.$emit('formazioneChanged', this.toStore);
			}
		},
		'ux.fontSize': {
			handler: function() {
				_.each(this.containers, function(b) {
					b.size = this.ux.fontSize
				}.bind(this));
			}
		},
		ratio: {
			handler: function() {
				this.ui();
			}
		},
		base: {
			immediate: true,
			// deep: true,
			handler: function() {
				this.processBase();
			}
		},
		'ux.selection': function() {
			if(this.ux.selection == false) {
				this.selection = [];
			}
		},
		'ux.hideNull': {
			deep: true,
			handler: function() {
				this.$nextTick(function() {
					this.ui();
				})
			}
		},
		selection: function(a,b) {
			if(this.selection.length == 0) {
				this.ux.showSelectionHelp = true;
				this.selectionBackup = _.cloneDeep(b);
				console.log(b);

				_.delay(function() {
					this.ux.showSelectionHelp = false;
				}.bind(this), 5000);
			}
		}
	},
	computed: {
		toStore: function() {
			_.map(this.containers, 'position');
			console.log('h');
			return {
				players: _.map(this.players, function(a) { return { id: a.id, level: a.level } }),
				containers: this.containers
			}
		},
		campoAsset: function() {

			if(this.campo == 0) return 'default.svg';
			if(this.campo == 1) return 'orizzontale.svg';
			if(this.campo == 2) return 'verticale.svg';

			if(this.campo == 3) return 'default_white.svg';
			if(this.campo == 4) return 'orizzontale_white.svg';
			if(this.campo == 5) return 'verticale_white.svg';

		},
		thePresets: function() {

			return _.filter(this.presets, function(a) {
				if(a.id == this.base.id_sistema_gioco)
					return _.find(a.ratios, { ratio: this.ratio });
				return false;
			}.bind(this));

		},
		canvasStyle: function() {

			var s = [];

			s.push('width: ' + this.canvas.width + 'px');
			s.push('height: ' + this.canvas.height + 'px');
			s.push('position: absolute');
			s.push('top: 0');
			s.push('left: ' + ( (this.windowWidth - this.canvas.width) / 2) + 'px');

			return s.join(';');

		},
		unused: function() {

			return _.filter(this.players, function(a) {

				var is = false;
				_.each(this.containers, function(b) {
					if(b.players.indexOf(a.id) !== -1)
						is = true;
				});

				return !is;

			}.bind(this));

		},
		panelStyle: function() {

			var style = [];

			style.push('position: sticky');

			if(this.ux.panel == 1)
				style.push('float: left');
			else
				style.push('float: right');

			return style.join(';');

		},
		theContainers: function() {
			return _.filter(this.containers, function(a) {
				if(this.ux.hideNull == true && a.players.length == 0) return false;
				return true
			}.bind(this));
		}
	},
	methods: {
		contName: function(id) {

			if(this.thePresets.length == 0) return '';
			return '(' + _.find(this.ruoli, { id: _.first(this.thePresets).bucketsName[id-1] }).sigla + ')';

		},
		resetContainers: function() {

			this.containers = [
				{ id: 1, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 2, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 3, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 4, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 5, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 6, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 7, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 8, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 9, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 10, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
				{ id: 11, name: '', size: 100, width: 0.22, position: { x: 0, y: 0 }, players: [] },
			];

		},
		makeBlock: function(amount) {
			_.each(this.containers, function(b) {
				b.width += amount;
			})
		},
		exportPositions: function() {

			console.log(JSON.stringify(_.map(this.containers, function(b) {
				return {
					id: b.id,
					x: b.position.x,
					y: b.position.y
				};
			})));

		},
		doSelect: function(what) {

			if(this.selection.indexOf(what) === -1)
				this.selection.push(what);
			else {
				var i = this.selection.indexOf(what);
				this.selection.splice(i, 1);
			}

		},
		processBase: function() {
			
			if(this.base.buckets_v2 != null) {

				var b = JSON.parse(this.base.buckets_v2);

				this.containers = b.containers;

				var players = _.map(this.startingPlayers, function(a) {

					var wwBase = _.find(b.players, { id: a.id });

					var annoDiNascita = a.nascita_data && a.nascita_data.split('-').length > 0 ? a.nascita_data.split('-')[0] : null;

					return {
						id: a.id,
						name: a.nome,
						surname: a.cognome,
						level: wwBase && wwBase.level ? wwBase.level : 0,
						anno: annoDiNascita,
						nascitaData: a.nascita_data,
						nascitaPaese: a.nascita_paese_id,
						carriera: a.last_carriera_sportiva
					}

				});

				this.ux.fontSize = _.first(this.containers).size;
				this.players = players;
				console.log(b.players);
				console.log(this.startingPlayers);

				console.log('This formazione is already a valid one!');

			} else if(this.base.positions) {

				var inCampo = JSON.parse(this.base.in_campo);
				var nonInCampo = JSON.parse(this.base.non_in_campo);
				var positions = _.filter(JSON.parse(this.base.positions), function(i) {
					if(i.top.length == 0 && i.left.length == 0) return false;
					return true;
				});

				if(positions.length > 0) {

					var width = this.base.width;
					var height = this.base.height;

					var westest = _.minBy(_.values(positions), function(a) { return parseInt(a.left); });
					var eastest = _.maxBy(_.values(positions), function(a) { return parseInt(a.left); });
					var southtest = _.maxBy(_.values(positions), function(a) { return parseInt(a.top); });

					console.log('_WW', parseInt(eastest.left) + 250, positions);

					if(parseInt(westest.left) + parseInt(eastest.left) + 250 > width)
						width = parseInt(westest.left) + parseInt(eastest.left) + 250;

					/* We need to fix the blocks positions */
					_.each(positions, function(position, k) {

						var bucket = _.find(this.containers, { id: parseInt(k) });
						if(bucket) {

							var newTop = parseInt(position.top);
							var newLeft = parseInt(position.left);

							console.log(width + 'x' + height, newLeft + ',' + newTop, newLeft/width + ',' + newTop/height);

							bucket.position.x = newLeft/width;
							bucket.position.y = newTop/height;

						}

					}.bind(this));

				}

				/* Create the Players Map! */
				var players = _.map(this.startingPlayers, function(a) {

					var annoDiNascita = a.nascita_data && a.nascita_data.split('-').length > 0 ? a.nascita_data.split('-')[0] : null;

					return {
						id: a.id,
						name: a.nome,
						surname: a.cognome,
						level: a.level ? a.level : 0,
						anno: annoDiNascita,
						nascitaData: a.nascita_data,
						nascitaPaese: a.nascita_paese_id,
						carriera: a.last_carriera_sportiva
					}

				});

				this.players = players;

				/* Replace `inCampo` players!  */
				_.each(inCampo, function(singleBoard, k) {
					var bucket = _.find(this.containers, { id: parseInt(k) });
					if(bucket)
						bucket.players = _.map(singleBoard, 'id');
				}.bind(this));


			} else {

				var players = _.map(this.startingPlayers, function(a) {

					var annoDiNascita = a.nascita_data && a.nascita_data.split('-').length > 0 ? a.nascita_data.split('-')[0] : null;

					return {
						id: a.id,
						name: a.nome,
						surname: a.cognome,
						level: a.level ? a.level : 0,
						anno: annoDiNascita,
						nascitaData: a.nascita_data,
						nascitaPaese: a.nascita_paese_id,
						carriera: a.last_carriera_sportiva
					}
				});

				this.players = players;

				this.resetContainers();

				console.log('If a preset exists for this, i will apply!')

				if(this.thePresets) {
					_.delay(function() {
						this.applyPreset();
					}.bind(this), 100);
				}

			}

		},
		selectContainer: function(players) {

			_.each(players, function(p) {

				if(this.selection.indexOf(p) === -1)
					this.selection.push(p);

			}.bind(this));

		},
		orderByLevel: function() {

			_.each(this.containers, function(container) {
				if(container.players.length > 0) {
					container.players = _.orderBy(container.players, function(id) {
						var p = _.find(this.players, { id });
						if(!p || !p.level) return 0;
						return p.level;
					}.bind(this));
				}

			}.bind(this));

			//console.log(this.players, this.containers);
		},
		keyBoardWatch: function(a) {
			//console.log(a);
		},
		resetSelection: function() {
			//console.log('reset');
			this.selection = _.cloneDeep(this.selectionBackup);
			this.selectionBackup = null;
		},
		moveTo: function() {

			_.each(this.selection, function(single) {

				_.each(this.containers, function(b) {
					var index = b.players.indexOf(single);
					if(index !== -1) {
						b.players.splice(index, 1);
						return false;
					}
				}.bind(this));

			}.bind(this));

			var cont = _.find(this.containers, { id: this.ux.moveTo });
			if(cont) {
				this.ux.moveTo = null;

				_.each(this.selection, function(single) {
					cont.players.push(single);
				});

			}

			this.selection = [];
			
		},
		applyLevel: function(level) {

			_.each(this.selection, function(single) {

				var player = _.find(this.players, { id: single });
				if(player)
					player.level = level;

			}.bind(this));

			this.selection = [];

		},
		removePlayers: function() {

			_.each(this.selection, function(single) {

				_.each(this.containers, function(b) {
					var index = b.players.indexOf(single);
					if(index !== -1) {
						b.players.splice(index, 1);
						return false;
					}
				}.bind(this));

			}.bind(this));

			this.selection = [];

		},
		findPlayer: function(id) {
			return _.find(this.players, { id });
		},
		applyFormation: function(formation) {

			_.each(formation, function(a) {

				var container = _.find(this.containers, { id: a.id });
				if(container) {
					container.position.x = a.x;
					container.position.y = a.y;
				}

			}.bind(this));

		},
		applyPreset: function() {
			var r = _.find(_.cloneDeep(_.first(this.thePresets)).ratios, { ratio: this.ratio });

			//return '(' + _.find(this.ruoli, { id: _.first(this.thePresets).bucketsName[id-1] }).sigla + ')';

			_.each(this.containers, function(single) {
				var ruolo = _.find(this.ruoli, { id: _.first(this.thePresets).bucketsName[single.id - 1] });
				if(single.name == null || single.name.length == 0)
					single.name = _.clone(ruolo.nome);
			}.bind(this))

			console.log('a', _.first(this.thePresets).bucketsName);
			this.applyFormation(r.positions);
			this.ux.preset = null;
		},
		applyPlayers: function(players) {},
		calculatePosition: function(container) {

			var x = container.position.x * this.canvas.width;
			var y = container.position.y * this.canvas.height;
			var w = container.width * this.canvas.width;

			var out = [];
			out.push('position: absolute');
			out.push('font-size: '+container.size+'%');
			out.push('top: ' + y + 'px');
			out.push('left: ' + x + 'px');
			out.push('width: '+ w + 'px');

			if(this.ux.blocks.indexOf(container.id) !== -1) {
				out.push('border-color: orange');
			}

			if(this.softSelection) {
				out.push('pointer-events: none');
			}

			return out.join(';');

		},
		snap: function() {

			_.each(this.containers, function(cont) {

				var dom = $('[container-id='+cont.id+']', this.$refs.canvas);

				if(dom.length == 0)
					var a = [];
				else
					var a = _.map($('ul.players', dom).sortable("toArray"), function(e) {
						return parseInt(e.replace('p-', ''));
					});

				cont.players = _.uniq(a);

			}.bind(this));

			this.mouse.dragging = false;

			this.ui();

		},
		ui: function() {

			var hLimit = 1310;
			var desiredW = $(this.$refs.w).width();
			var desiredH = desiredW/this.ratio;

			if(desiredH > hLimit) {
				desiredH = hLimit;
				desiredW = desiredH * this.ratio;
			}


			this.canvas.width = desiredW;
			this.canvas.height = desiredH;

			var self = this;

			_.each(this.containers, function(cont) {

				var dom = $('[container-id='+cont.id+']', this.$refs.canvas);
				
				$(dom).draggable({
					start: function() {
						console.log('START - 1');
						$($('[container-id]', self.$refs.canvas)).each(function() {
							var o = _.find(self.containers, { id: parseInt($(this).attr('container-id')) });
							if(o) {
								$(this).data('base', _.cloneDeep(o.position));
							}
						});
					},
					drag: function(a,b) {

						$('body').data('dragging', true);

						if(self.ux.blocks && self.ux.blocks.length > 0) {

							var xOffset = (b.position.left - b.originalPosition.left)/self.canvas.width;
							var yOffset = (b.position.top - b.originalPosition.top)/self.canvas.height;

							//xOffset -= ( (self.windowWidth - self.canvas.width) / 2);

							_.each(self.ux.blocks, function(p) {
								if(p != cont.id) {

									var ccc = _.find(self.containers, { id: p });
									var subDom = $('[container-id='+p+']', self.$refs.canvas);

									ccc.position.x = $(subDom).data('base').x + xOffset;
									ccc.position.y = $(subDom).data('base').y + yOffset;

								}
							})

						}

						var x = b.position.left / self.canvas.width;
						var y = b.position.top / self.canvas.height;

						var u = _.find(self.containers, { id: cont.id });
						u.position.x = x;
						u.position.y = y;

					},
					stop: function(a,b) {

						console.log(self.containers);
						console.log('End Dragging');
						$('body').data('dragging', false);

					}
				}).resizable({
					handles: 'e',
					stop: function(a,b) {
						var w = b.size.width/self.canvas.width;
						cont.width = w;
					}
				});

				$('ul.players', dom).sortable({
					connectWith: '.players',
					zIndex: 9999,
					start: function() {
						self.mouse.dragging = true;
						console.log('Start Dragging');
						$('body').data('dragging', true);
					},
					stop: function() {
						console.log('End Dragging');
						$('body').data('dragging', false);
						self.$nextTick(function() {
							self.snap()
						});
					}
				});
				
			}.bind(this));

			$('ul.players', this.$refs.panel).sortable({
				connectWith: '.players',
				zIndex: 9999,
				stop: function() {
					self.$nextTick(function() {
						self.snap()
					});
				}
			});

			self.$nextTick(function() {

				$('ul.players li[c=1]', this.$refs.panel).remove();

				_.each(this.containers, function(cont) {

					var dom = $('[container-id='+cont.id+']', this.$refs.canvas);
					if(dom.length > 0) {
						//console.log(cont.id);
						$('[b]', dom).each(function() {
							if( $(this).attr('b') != cont.id)
								$(this).remove();
						});

					}

				}.bind(this));

			});

		}
	},
	mounted: function() {

		this.ui();
		this.windowWidth = $(this.$refs.w).width();

		$(window).on('resize', function() {
			this.windowWidth = $(this.$refs.w).width();
		}.bind(this));

		var self = this;

		$(this.$refs.canvas).on('mousemove', function(a) { 

			var x = a.offsetX - $(self.$refs.canvas).position().left;
			var y = a.offsetY - $(self.$refs.canvas).position().top;

			self.mouse.x = x;
			self.mouse.y = y;

			if($('body').data('selection') && !$('body').data('dragging')) {

				$('body').data('selectionCurrent', { x: x , y: y });

				var points = [$('body').data('selection'), { x: x + ((self.windowWidth - self.canvas.width) / 2), y: y }];

				var x1 = _.minBy(points, 'x').x/self.canvas.width;
				var y1 = _.minBy(points, 'y').y/self.canvas.height;
				var x2 = _.maxBy(points, 'x').x/self.canvas.width;
				var y2 = _.maxBy(points, 'y').y/self.canvas.height;

				self.softSelection = {
					x1, y1, x2, y2
				};

			} else if(self.softSelection) {
				self.softSelection = null;
			}

		});

		$(this.$refs.canvas).on('mousedown', function(a) {

			if(!$('body').data('dragging')) {
				console.log('Offset', a.offsetX, a.offsetY);
				$('body').data('selection', { x: a.offsetX, y: a.offsetY });
				_.delay(function() {
					if($('body').data('dragging') == true) {
						console.log('WatchDog Dragging', $('body').data('dragging'));
						$('body').data('selection', null);
					}
				}, 80);
			}

		});

		$(this.$refs.canvas).on('mouseup', function(a) {

			if($('body').data('selection') && !$('body').data('dragging')) {
				
				console.log($('body').data('selection'));
				console.log($('body').data('selectionCurrent'));

				var points = [$('body').data('selection'), $('body').data('selectionCurrent')];

				var x1 = _.minBy(points, 'x').x/self.canvas.width;
				var y1 = _.minBy(points, 'y').y/self.canvas.height;
				var x2 = _.maxBy(points, 'x').x/self.canvas.width;
				var y2 = _.maxBy(points, 'y').y/self.canvas.height;

				var intersected = _.filter(self.containers, function(a) {

					var left = x2 < a.position.x;
					var right = x1 > a.width + a.position.x;
					var top = y1 > a.position.y;
					var bottom = y2 < a.position.y;

					return !(left||right||top||bottom);

				})

				console.log(intersected);

				$('body').data('selection', null);
				$('body').data('selectionCurrent', null);
				self.softSelection = null
				self.ux.blocks = _.map(intersected, 'id');

			} else if($('body').data('selection')) {

				$('body').data('selection', null);
				$('body').data('selectionCurrent', null);
				self.softSelection = null

			}

		});


		var self = this;
		$(document).on('keyup', function(a) {

			if(a.keyCode == 82) {
				if(self.selectionBackup && self.selectionBackup.length > 0) {
					self.ux.selection = true;
					self.resetSelection();
				}
			}

		});

	}
}

</script>