export const ROUTES_ACADEMY = [
    {
        path: '/academy/dashboard',
        name: 'academy_dashboard',
        component: require('../components/pages/academy/dashboard.vue').default,
        meta: {
            module: 'academy',

            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'academy',
        }
    },
    {
        path: '/academy/scouting/nuovo/giocatore',
        name: 'academy_scouting_nuovo_giocatore',
        component: require('../components/pages/academy/nuovo_giocatore.vue').default,
        meta: {
            module: 'academy',
            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'academy',
        }
    },
    {
        path: '/academy/scouting/view/giocatore/:id',
        name: 'academy_scouting_view_giocatore',
        component: require('../components/pages/academy/view_giocatore.vue').default,
        meta: {
            module: 'academy',
            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'academy_scouting_nuova_valutazione',
            permesso: 'academy',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/academy/scouting/edit/giocatore/:id',
        name: 'academy_scouting_edit_giocatore',
        component: require('../components/pages/academy/edit_giocatore.vue').default,
        meta: {
            module: 'academy',
            label: 'Modifica Anagrafica',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_scouting_view_giocatore',
            menusub: ['academy_scouting_view_giocatore'],
            permesso: 'academy',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/academy/scouting/edit/valutazione/:id/:val_id',
        name: 'academy_scouting_edit_valutazione',
        component: require('../components/pages/academy/edit_valutazione.vue').default,
        meta: {
            module: 'academy',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_scouting_view_giocatore',
            menusub: ['academy_scouting_view_giocatore', 'academy_scouting_nuova_valutazione'],
            permesso: 'academy',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/academy/scouting/nuova/valutazione/:id',
        name: 'academy_scouting_nuova_valutazione',
        component: require('../components/pages/academy/nuova_valutazione.vue').default,
        meta: {
            module: 'academy',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_scouting_view_giocatore',
            menusub: ['academy_scouting_view_giocatore'],
            permesso: 'academy',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/academy/scouting/view/valutazione/:id/:val_id',
        name: 'academy_scouting_view_valutazione',
        component: require('../components/pages/academy/view_valutazione.vue').default,
        meta: {
            module: 'academy',
            label: 'Valutazione Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_scouting_view_giocatore',
            menusub: ['academy_scouting_view_giocatore'],
            permesso: 'academy',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/academy/scouting/osservatori',
        name: 'academy_scouting_osservatori',
        component: require('../components/pages/scouting/osservatori.vue').default,
        meta: {
            module: 'academy',
            label: 'Osservatori',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_utenti',        
        }
    },
    {
        path: '/academy/scouting/osservatore/paesi/:id',
        name: 'academy_scouting_osservatore_paesi',
        component: require('../components/pages/scouting/osservatore_paesi.vue').default,
        meta: {
            module: 'academy',
            label: 'Paesi',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_scouting_osservatori',
            permesso: 'scout_utenti',
            menusub: ['academy_scouting_osservatore_paesi', 'academy_scouting_osservatore_liste'], 
            logModel: 'Persona_Utente',
            logModelId: 'id',        
        }
    },
    {
        path: '/academy/scouting/osservatore/liste/:id',
        name: 'academy_scouting_osservatore_liste',
        component: require('../components/pages/scouting/osservatore_liste.vue').default,
        meta: {
            module: 'academy',
            label: 'Liste',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_scouting_osservatori',
            permesso: 'scout_utenti',  
            menusub: ['academy_scouting_osservatore_paesi', 'academy_scouting_osservatore_liste'],  
            logModel: 'Persona_Utente',
            logModelId: 'id', 
        }
    },
];
