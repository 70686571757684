<template id="rosa">

	<section>

		<headapp></headapp>
		<menusub></menusub>

		<b-card no-body>
			<b-tabs card>
				<b-tab title="Overview">
					<b-row class="mb-3">
						<b-col cols="2">
							<b-datepicker v-model="filters.from" placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
						</b-col>
						<b-col cols="2">
							<b-datepicker v-model="filters.to" placeholder="To" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>		
						</b-col>
						<b-col cols="1">
							<b-button size="sm" variant="primary" @click="fetchDati"><i class="fa fa-search" aria-hidden="true"></i></b-button> 
						</b-col>
					</b-row>
					<hr/>
					<b-row>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading_utenti"><h5>Utenti Attivi</h5><h1><b-badge variant="success">{{ utenti_attivi }}</b-badge></h1></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading"><h5>Totale Accessi</h5><h1><b-badge variant="success">{{ totale_accessi }}</b-badge></h1></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading"><h5>Media Giornaliera Accessi</h5><h1><b-badge variant="success">{{ media_accessi }}</b-badge></h1></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading_utilizzo"><h5>Totale Utilizzo</h5><h1><b-badge variant="success">{{ totale_utilizzo }}</b-badge></h1></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading_utenti"><b-form-checkbox v-if="utenti_attivi_by_gruppo.length > 10" style="position:absolute;right:0" :value="1" :unchecked-value="0" v-model="show_all_gruppi" size="sm">Vedi tutti</b-form-checkbox><h5>Utenti Attivi by Gruppo</h5><b-list-group><b-list-group-item v-for="item, index in top_10_utenti_attivi_by_gruppo" class="d-flex justify-content-between align-items-center">{{ item.gruppo }}<b-badge variant="primary"><span style="font-size:1.2rem">{{ item.utenti }}</span></b-badge></b-list-group-item></b-list-group></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="h-100"><b-overlay :show="loading_accessi || loading_utenti"><b-form-checkbox v-if="dataset_accessi.length > 10" style="position:absolute;right:0" :value="1" :unchecked-value="0" v-model="show_all_accessi" size="sm">Vedi tutti</b-form-checkbox><h5 class="text-center">{{ filters.from == filters.to ? 'Accessi del Giorno' : 'Top 10 Accessi' }}</h5><b-list-group><b-list-group-item v-for="item, index in top_10_accessi" class="d-flex justify-content-between align-items-center">{{ item.utente }}<b-badge variant="primary"><span style="font-size:1.2rem">{{ item.accessi }}</span></b-badge></b-list-group-item></b-list-group></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading_gruppi"><b-form-checkbox v-if="media_accessi_by_gruppo.length > 10" style="position:absolute;right:0" :value="1" :unchecked-value="0" v-model="show_all_media_accessi_gruppi" size="sm">Vedi tutti</b-form-checkbox><h5>Media Giornaliera Accessi by Gruppo</h5><b-list-group><b-list-group-item v-for="item, index in top_10_media_accessi_by_gruppo" class="d-flex justify-content-between align-items-center">{{ item.gruppo }}<b-badge variant="primary"><span style="font-size:1.2rem">{{ item.media }}</span></b-badge></b-list-group-item></b-list-group></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="h-100"><b-overlay :show="loading_utilizzo || loading_utenti"><b-form-checkbox v-if="utenti_utilizzo.length > 10" style="position:absolute;right:0" :value="1" :unchecked-value="0" v-model="show_all_utilizzo" size="sm">Vedi tutti</b-form-checkbox><h5 class="text-center">Top 10 Utilizzo</h5><b-list-group><b-list-group-item v-for="item, index in top_10_utilizzo" class="d-flex justify-content-between align-items-center">{{ item.utente }}<b-badge variant="primary"><span style="font-size:1.2rem">{{ item.utilizzo }}</span></b-badge></b-list-group-item></b-list-group></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading_gruppi"><b-form-checkbox v-if="totale_accessi_by_gruppo.length > 10" style="position:absolute;right:0" :value="1" :unchecked-value="0" v-model="show_all_accessi_gruppi" size="sm">Vedi tutti</b-form-checkbox><h5>Totale Accessi by Gruppo</h5><b-list-group><b-list-group-item v-for="item, index in top_10_totale_accessi_by_gruppo" class="d-flex justify-content-between align-items-center">{{ item.gruppo }}<b-badge variant="primary"><span style="font-size:1.2rem">{{ item.accessi }}</span></b-badge></b-list-group-item></b-list-group></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="h-100"><b-overlay :show="loading_utilizzo"><b-form-checkbox v-if="utilizzo_aree.length > 10" style="position:absolute;right:0" :value="1" :unchecked-value="0" v-model="show_all_aree" size="sm">Vedi tutti</b-form-checkbox><h5 class="text-center">Totale Utilizzo by Area</h5><b-list-group><b-list-group-item v-for="item, index in top_10_utilizzo_aree" class="d-flex justify-content-between align-items-center">{{ item.area }}<b-badge variant="primary"><span style="font-size:1.2rem">{{ item.utilizzo }}</span></b-badge></b-list-group-item></b-list-group></b-overlay></b-card></b-col>
						<b-col cols="3" class="mb-2"><b-card class="text-center h-100"><b-overlay :show="loading_utilizzo_gruppi"><b-form-checkbox v-if="gruppi_utilizzo.length > 10" style="position:absolute;right:0" :value="1" :unchecked-value="0" v-model="show_all_utilizzo_gruppi" size="sm">Vedi tutti</b-form-checkbox><h5>Totale Utilizzo by Gruppo</h5><b-list-group><b-list-group-item v-for="item, index in top_10_gruppi_utilizzo" class="d-flex justify-content-between align-items-center">{{ item.gruppo }}<b-badge variant="primary"><span style="font-size:1.2rem">{{ item.utilizzo }}</span></b-badge></b-list-group-item></b-list-group></b-overlay></b-card></b-col>
					</b-row>
				</b-tab>
				<b-tab lazy title="Report Accessi">
					<ReportAccessi :gruppi="gruppi" :utenti="utenti"/>
				</b-tab>
				<b-tab lazy title="Report Utilizzo">
					<ReportUtilizzo :gruppi="gruppi" :utenti="utenti"/>
				</b-tab>
				<b-tab lazy title="Report Azioni">
					<ReportAzioni :gruppi="gruppi" :utenti="utenti"/>
				</b-tab>
			</b-tabs>

		</b-card>

		
		<footerapp></footerapp>

	</section>

</template>

<script>

import eChart from '@/components/layout/E-Chart/Base.vue';
import pageBase from '@/mixins/pageBase.js';

export default {
	mixins: [ pageBase ],
	components: {
		'E-Chart': eChart,
		ReportAccessi: require('@/components/pages/admin/report_accessi.vue').default,
		ReportUtilizzo: require('@/components/pages/admin/report_utilizzo.vue').default,
		ReportAzioni: require('@/components/pages/admin/report_azioni.vue').default,
	},
	data: function() {
		return {
			loading_utenti: false,
			loading_accessi: false,
			loading_utilizzo: false,
			loading: false,
			loading_gruppi: false,
			loading_utilizzo_gruppi: false,
			utenti: [],
			gruppi: [],
			dataset: null,
			dataset_gruppi: null,
			dataset_accessi: null,
			dataset_utilizzo: null,
			dataset_utilizzo_gruppi: null,
			show_all_accessi: false,
			show_all_utilizzo: false,
			show_all_gruppi: false,
			show_all_aree: false,
			show_all_accessi_gruppi: false,
			show_all_media_accessi_gruppi: false,
			show_all_utilizzo_gruppi: false,
			filters: {
				from: null,
				to: null,
			},
			moduli: [
				{ name: 'academy_ita_dashboard', label: 'AcademyItalia'},
				{ name: 'academy_int_dashboard', label: 'AcademyInt'},
				{ name: 'analysis_dashboard', label: 'Analysis'},
				{ name: 'scouting_dashboard', label: 'ArchivioScout'},
				{ name: 'camera_dashboard', label: 'Camera'},
				{ name: 'daily_check', label: 'DailyCheck'}, 
				{ name: 'filebrowser_dashboard', label: 'FileBrowser'},
				{ name: 'football_dashboard', label: 'Football'},
				{ name: 'geomatch', label: 'Geomatch'},
				{ name: 'medical_calendar', label: 'Medical'},
				{ name: 'performance_dashboard', label: 'Performance'},
				{ name: 'prestiti_dashboard', label: 'Prestiti'},
				{ name: 'player_access_attivita', label: 'PlayerAccess'},
				{ name: 'reports_dashboard', label: 'Reports'},
				{ name: 'romalab_dashboard', label: 'RomaLab'},
				{ name: 'romanext_dashboard', label: 'RomaNext'},
				{ name: 'newscout_dashboard', label: 'Scouting'},
				{ name: 'stats_dashboard', label: 'Stats'},
				{ name: 'usascout_dashboard', label: 'UsaScout'},
				{ name: 'videotag_archivio', label: 'Videotag'},
				{ name: 'witheboard_dashboard', label: 'Witheboard'},
				{ name: 'academy_dashboard', label: 'WorldScout'},
				{ name: 'youthscout_dashboard', label: 'YouthScout'},
				{ name: 'youngscout_dashboard', label: 'YoungScout'},
				{ name: 'contratti_dashboard', label: 'Contratti'}
				],
		}
	},
	watch: {
	},
	created: function() {
		this.fetchGruppi();
		this.fetchUtenti();
		this.filters.from = this.moment().format('YYYY-MM-DD');
		this.filters.to = this.moment().format('YYYY-MM-DD');
		this.fetchCountUsersByDay();
		this.fetchAccessiUtente();
		this.fetchUtilizzoUtente();
		this.fetchCountGroupByDay();
		this.fetchUtilizzoGruppi();
	},
	mounted: function() {
	},
	computed: {	
		utenti_attivi: function() {
			return this.utenti.filter(item => item.attivo).length;
		},
		utenti_attivi_by_gruppo: function() {
			var gruppi_utenti = {};
			var attivi = this.utenti.filter(item => item.attivo);
			_.forEach(attivi, function (item) {
				var gruppo = 'Senza gruppo';
				if(item.gruppi.length > 0) {
					gruppo = item.gruppi[0].nome;
				} 
				if(!gruppi_utenti[gruppo]) {
					gruppi_utenti[gruppo] = {gruppo: gruppo, utenti: 0};
				}
				gruppi_utenti[gruppo].utenti += 1;		
			});
			gruppi_utenti = _.values(gruppi_utenti);
			gruppi_utenti = _.orderBy(gruppi_utenti, ['utenti'],['desc']);

			return gruppi_utenti;
		},
		top_10_utenti_attivi_by_gruppo: function() {
			var top_10 = this.utenti_attivi_by_gruppo;
			if(!this.show_all_gruppi && top_10.length > 0) {
				top_10 = _.slice(top_10, 0, 10);
			}
			return top_10;
		},
		totale_accessi: function () {
			return _.sumBy(this.dataset, 'users');
		},
		totale_utilizzo: function () {
			return _.sumBy(this.utilizzo_aree, 'utilizzo');
		},
		totale_accessi_by_gruppo: function () {
			var results = {};
			_.forEach(this.dataset_gruppi, function (items, day) {
				_.forEach(items, function (item) {
					var gruppo = item.gruppo ? this.gruppi.find(g => g.id == item.gruppo).nome : 'Senza gruppo';
					if(!results[gruppo]) {
						results[gruppo] = {accessi: 0, gruppo: gruppo};
					}
					results[gruppo].accessi += item.users;
				}.bind(this));			
			}.bind(this));
			results = _.values(results);
			results = _.orderBy(results, ['accessi'],['desc']);
			return results;
		},
		top_10_totale_accessi_by_gruppo: function() {
			var top_10 = this.totale_accessi_by_gruppo;
			if(!this.show_all_accessi_gruppi && top_10.length > 0) {
				top_10 = _.slice(top_10, 0, 10);
			}
			return top_10;
		},
		media_accessi: function () {
			return this.dataset ? _.round(_.mean(_.map(this.dataset, 'users'))) : 0;
		},
		media_accessi_by_gruppo: function () {
			var results = {};
			_.forEach(this.totale_accessi_by_gruppo, function (item) {
				results[item.gruppo] = {gruppo: item.gruppo, media: _.round(item.accessi / _.keys(this.dataset_gruppi).length)};
			}.bind(this));
			results = _.values(results);
			results = _.orderBy(results, ['media'],['desc']);
			return results;
		},
		top_10_media_accessi_by_gruppo: function() {
			var top_10 = this.media_accessi_by_gruppo;
			if(!this.show_all_media_accessi_gruppi && top_10.length > 0) {
				top_10 = _.slice(top_10, 0, 10);
			}
			return top_10;
		},
		top_10_accessi: function () {
			var top_10 = [];
			if(this.dataset_accessi && this.utenti.length > 0) {
				top_10 = _.map(this.dataset_accessi, function(item) {
					var utente = this.utenti.find(user => user.id == item.user_id);
					return {
						utente: _.capitalize(utente.cognome) + ' ' + _.capitalize(utente.nome),
						accessi: item.days
					}
				}.bind(this));

				if(!this.show_all_accessi && top_10.length > 0) {
					top_10 = _.slice(top_10, 0, 10);
				}
			}
			return top_10;
		},

		utenti_utilizzo: function () {
			var utenti_utilizzo = [];
			if(this.dataset_utilizzo && this.utenti.length > 0) {

				var results = {};

				_.forEach(this.dataset_utilizzo, function (item) {
					if(!results[item.user_id]) {
						results[item.user_id] = {utilizzi: 0, user_id: item.user_id};
					}
					results[item.user_id].utilizzi += item.utilizzi;	
				});

				utenti_utilizzo = _.values(results);

				utenti_utilizzo = _.map(utenti_utilizzo, function(item) {
					var utente = this.utenti.find(user => user.id == item.user_id);
					return {
						utente: _.capitalize(utente.cognome) + ' ' + _.capitalize(utente.nome),
						utilizzo: item.utilizzi
					}
				}.bind(this));

				utenti_utilizzo = _.orderBy(utenti_utilizzo, ['utilizzo'],['desc']);
			}
			return utenti_utilizzo;
		},

		top_10_utilizzo: function () {
			var top_10 = this.utenti_utilizzo;
			if(!this.show_all_utilizzo && top_10.length > 0) {
				top_10 = _.slice(top_10, 0, 10);
			}
			return top_10;
		},

		utilizzo_aree: function () {
			var utilizzo_aree = [];
			if(this.dataset_utilizzo) {

				var results = {};

				_.forEach(this.dataset_utilizzo, function (item) {
					if(!results[item.area]) {
						results[item.area] = {utilizzi: 0, area: item.area};
					}
					results[item.area].utilizzi += item.utilizzi;	
				});

				utilizzo_aree = _.values(results);

				utilizzo_aree = _.map(utilizzo_aree, function(item) {
					var area = this.moduli.find(modulo => modulo.name == item.area);
					return {
						area: area.label,
						utilizzo: item.utilizzi
					}
				}.bind(this));

				utilizzo_aree = _.orderBy(utilizzo_aree, ['utilizzo'],['desc']);
			}
			return utilizzo_aree;
		},
		top_10_utilizzo_aree: function() {
			var top_10 = this.utilizzo_aree;
			if(!this.show_all_aree && top_10.length > 0) {
				top_10 = _.slice(top_10, 0, 10);
			}
			return top_10;
		},
		gruppi_utilizzo: function () {
			var results = {};
			_.forEach(this.dataset_utilizzo_gruppi, function (items, day) {
				_.forEach(items, function (item) {
					var gruppo = item.gruppo ? this.gruppi.find(g => g.id == item.gruppo).nome : 'Senza gruppo';
					if(!results[gruppo]) {
						results[gruppo] = {utilizzo: 0, gruppo: gruppo};
					}
					results[gruppo].utilizzo += item.utilizzi;
				}.bind(this));			
			}.bind(this));
			results = _.values(results);
			results = _.orderBy(results, ['utilizzo'],['desc']);
			return results;
		},
		top_10_gruppi_utilizzo: function() {
			var top_10 = this.gruppi_utilizzo;
			if(!this.show_all_utilizzo_gruppi && top_10.length > 0) {
				top_10 = _.slice(top_10, 0, 10);
			}
			return top_10;
		},
	},
	methods: {
		fetchDati: function() {
			this.fetchCountUsersByDay();
			this.fetchAccessiUtente();
			this.fetchUtilizzoUtente();
			this.fetchCountGroupByDay();
			this.fetchUtilizzoGruppi();
		},
		fetchGruppi: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/admin/gruppi').then(function(data) {
				this.gruppi = data.data;
			});
		},
		fetchUtenti: function() {
			this.loading_utenti = true;
			this.$http.get(this.$store.state.apiEndPoint + '/admin/utenti/football', {params: { all_with_gruppi: true }}).then(function(data) {
				this.utenti = data.data;
				this.loading_utenti = false;
			});
		},
		fetchCountUsersByDay: function() {
			this.dataset = null;
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/log/count/users/by/day', {params: this.filters }).then(function(data) {
				this.dataset = data.data;
				this.loading = false;
			});
		},
		fetchCountGroupByDay: function() {
			this.dataset_gruppi = null;
			this.loading_gruppi = true;
			var filters = {
				from: this.filters.from,
				to: this.filters.to,
				group_by: 1
			};
			this.$http.get(this.$store.state.apiEndPoint + '/log/count/users/by/day', {params: filters }).then(function(data) {
				this.dataset_gruppi = data.data;
				this.loading_gruppi = false;
			});
		},
		fetchAccessiUtente: function() {
			this.dataset_accessi = null;
			this.loading_accessi = true;
			this.$http.get(this.$store.state.apiEndPoint + '/log/accessi/by/utente', {params: this.filters }).then(function(data) {
				this.dataset_accessi = data.data;
				this.loading_accessi = false;
			});
		},
		fetchUtilizzoUtente: function() {
			this.dataset_utilizzo = null;
			this.loading_utilizzo = true;
			var filters = {
				from: this.filters.from,
				to: this.filters.to,
				aree: this.moduli.map(function (item) { return item.name })
			};
			this.$http.get(this.$store.state.apiEndPoint + '/log/utilizzo/aree/by/utente', {params: filters }).then(function(data) {
				this.dataset_utilizzo = data.data;
				this.loading_utilizzo = false;
			});
		},
		fetchUtilizzoGruppi: function() {
			this.dataset_utilizzo_gruppi = null;
			this.loading_utilizzo_gruppi = true;
			var filters = {
				from: this.filters.from,
				to: this.filters.to,
				group_by: 1,
				aree: this.moduli.map(function (item) { return item.name })
			};
			this.$http.get(this.$store.state.apiEndPoint + '/log/utilizzo/aree/by/day', {params: filters }).then(function(data) {
				this.dataset_utilizzo_gruppi = data.data;
				this.loading_utilizzo_gruppi = false;
			});
		}
	},
}

</script>