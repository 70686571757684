<template>

	<div>

		<headapp></headapp>

		<div class="card">
			<div class="card-body">

				<div class="row flex-items-xs-middle" v-if="osservato">
					<div class="col-4">

						<div class="text-uppercase color-rosso font-bold font-size-lg">
							{{osservato.cognome}} {{osservato.nome}}
						</div>
						<div class="text-muted">
							{{osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}
						</div>

					</div>
					<div class="col-8 text-right">
						<menuscout :personaId="$route.params.id"></menuscout>
					</div>
				</div>
			</div>
		</div>

		<div class="app-space" :style="'height: '+ux.height+'px'" ref="layout">
			
			<div style="background-color: white; height: 100%" v-if="competizione" class="px-2"><!-- 🧙‍♀️ -->
				<b-overlay :show="ready" ref="waitLayer">
					<b-row align-h="center">
						<b-col :cols="9" :style="'overflow-y: scroll; height: '+ux.height+'px'">
							<div v-if="$route.meta.contesto == 'giocatori'">
								<RosaGiocatori v-if="players" :players="players"></RosaGiocatori>
							</div>
							<div v-else-if="$route.meta.contesto == 'partita-partite'">
								<ContenitorePartitaScoutingManual ref="interfacciaPartita" @ready="isReady" @wait="isWaiting" :partiteId="$route.params.partite_id"></ContenitorePartitaScoutingManual>
							</div>
							<div v-else-if="$route.meta.contesto == 'partita-partite-wyscout'">
								<ContenitorePartitaScoutingWyDatabase ref="interfacciaPartita" @ready="isReady" @wait="isWaiting":partiteId="$route.params.partite_id" :wyId="$route.params.wy_id"></ContenitorePartitaScoutingWyDatabase>
							</div>
							<div v-else-if="$route.meta.contesto == 'partita-wyscout'">
								<ContenitorePartitaScoutingWy ref="interfacciaPartita" @ready="isReady" @wait="isWaiting" :wyId="$route.params.wy_id"></ContenitorePartitaScoutingWy>
							</div>
						</b-col>
					</b-row>
				</b-overlay>
			</div>

		</div>

		<footerapp></footerapp>

	</div>

</template>

<script>

var Auth = require('@/auth.js');
import $ from 'jquery'
import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	components: {
		RosaGiocatori: require('@/components/layout/nextscouting/rosa-giocatori.vue').default,
		ContenitorePartitaScoutingManual: require('@/components/layout/nextscouting/contenitore-partita-manual.vue').default,
		ContenitorePartitaScoutingWyDatabase: require('@/components/layout/nextscouting/contenitore-partita-wy-database.vue').default,
		ContenitorePartitaScoutingWy: require('@/components/layout/nextscouting/contenitore-partita-wy.vue').default,
		menuscout: require('@/components/layout/giocatore/menuscout.vue').default,
	},
	data: function() {
		return {
			osservato: null,
			wyScoutWait: false,
			competizione: null,
			seasonId: null, // wy scout
			squadra: null,
			players: null,
			partite: null,
			partiteWy: null,
			thePartite: [],
			ready: false,
			stagioni: null,
			ux: {
				height: null
			},

			form_partita: {
				id: null,
				id_competizione: null,
				id_squadra: null,
				id_avversario: null,
				home: 1,
				data: null,
				ora: null,
				score: null,
			},
			logo_avversario: null,
			avversario: null,

			page: 1,
			categories_opt: [],
			teams: {
				data: [],
			},
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
		}
	},
	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/scouting/giocatore/' + this.$route.params.id + '/new').then((response) => {
			this.osservato = response.data;		
		});
	},
	mounted: function() {

		this._.delay(function() {
			this.uiDesign();
		}.bind(this), 400);

		$(window).on('resize', function() {
			this.uiDesign();
		}.bind(this));

	},
	computed: {
		auth: function() {
			return Auth;
		},
		area: function() {
			return this.$route.meta.area;
		},
		partiteIn: function() {
			return _.filter(this.partiteWy, function(a) { return a.partiteId != null });
		},
		partiteWs: function() {
			return _.filter(this.partiteWy, function(a) { return a.partiteId == null });
		},
	},
	watch: {
		'$route.params': {
			deep: true,
			handler: function() {

				if(!this.$refs.interfacciaPartita) return false; // Tanto vuol dire che è la prima.. non serve resettare ^_^
				this.$refs.interfacciaPartita.reset();

			}
		},
		'$route.params.squadra_id': {
			immediate: true,
			handler: function() {
				this.getSquadra();
				// this.getPartite();
				this.getCompetizione(this.$route.params.competizione_id);
				this.getStagioni(this.$route.params.competizione_id);
			}
		},
		squadra: {
			handler: function() {
				//return false;
				if(this.squadra && this.squadra.wyscout && !this.seasonId) {
					// this.getPlayers(this.squadra.wyscout.chiave);
					// this.getWsMatches(this.squadra.wyscout.chiave, null);
				}
			}
		},
		seasonId: {
			handler: function() {
				if(this.seasonId == -1) return false;
				if(this.squadra && this.squadra.wyscout) {
					// this.getPlayers(this.squadra.wyscout.chiave);
					// this.getWsMatches(this.squadra.wyscout.chiave, this.seasonId);
				}	
			}
		}
	},
	methods: {
		uiDesign: function() {

			var space = $(window).height() - $('header').outerHeight() - $('footer').outerHeight();

			this.ux.height = space;

			//console.log($(this.$el).height(), space);

		},
		isReady: function() { this.ready = false; },
		isWaiting: function() { this.ready = true; },
		
		getStagioni: function(id) {

			this.$http.get(this.$store.state.apiEndPoint + '/competizioni/' + id + '/stagioni').then(function(data) {
				this.stagioni = data.data;
			})

		},
		getCompetizione(id) {
			this.competizione = null;
			this.$http.get(this.$store.state.apiEndPoint + '/club/competizione/' + id).then(function(data) {
				this.competizione = data.data;
			});
		},
		getSquadra: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.$route.params.squadra_id).then(function(data) {
				this.squadra = data.data;
			});
		},
		getPlayers: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/giocatori/' + id + '/' + this.area).then(function(data) {
				this.players = data.data;
			});	
		},
		getCategories() {
			this.$http.get(this.$store.state.apiEndPoint + `/exercise/categories`)
			.then(function(data) {
				this.categories_opt = data.data.map(item => ({
					name: item.name,
					id: item.id,
				}));
			});
		},
		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then(function(data) {
				this.teams = data.data;	
			});						
		},
		filtraArchivio() {
			this.teams = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then(function(data) {
				this.teams = data.data;	
			});
		},
		paginate(page) {
			this.page = page;
			this.getTeams();
		}
	}
}

</script>