<template id="partiteaggiungi">
	<section class="partiteaggiungi">

		<headapp></headapp>

		<div class="card border-0 rounded-0">
			<div class="container">
				<div class="card-body">
					<h3 class="card-title">Nuova Convocazione</h3>
					<form @submit.stop.prevent="handleSubmit">

						<div class="row">

							<div class="col-8 pr-md-4">
								<label>Lista Giocatori</label>
								<div class="card">
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center" v-for="(player, index) in players" :key="player.id">
											<span class="lead mr-auto" :title="player.prova ? 'In prova' : ''">{{player.name}} {{player.prova ? '(*)' : ''}}</span>
											<b-form-radio-group :name="'convocated_'+index" v-model="form.convocazione.convocated[player.id]">
												<b-form-radio value="0">Convocato</b-form-radio>
												<b-form-radio value="1">Non convocato</b-form-radio>
												<b-form-radio value="2">Recupero</b-form-radio>
											</b-form-radio-group>
										</b-list-group-item>
									</b-list-group>
								</div>
							</div>

							<div class="col-4">
								<fieldset class="form-group">
									<label>Tipo partita</label>
									<multiselect v-model="form.partita.type" :options="opts_partite" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false"></multiselect>
								</fieldset>

								<div class="form-row">
									<fieldset class="form-group col-6">
										<label>Turno</label>
										<b-form-select v-model="form.partita.day">
											<option :value="null">seleziona</option>
											<option v-for="(value, index) in 99" :value="value" :key="index">{{value}}</option>
										</b-form-select>
									</fieldset>

									<fieldset class="form-group col-6">
										<label>Durata partita</label>
										<input class="form-control" type="text" v-model="form.partita.duration">
									</fieldset>

									<fieldset class="form-group col-6">
										<label>N. giocatori per squadra</label>
										<multiselect v-model="form.partita.players" :options="opts_num_player_partite" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false"></multiselect>
									</fieldset>
								</div>

								<hr />

								<fieldset class="form-group">										
										<b-form-group id="squadraGroup" horizontal label="Avversario" :label-cols="3" label-for="squadra">
											<b-input-group size="sm">
												<b-input-group-prepend is-text >
													<b-img :src="logo_squadra" width="32"/>
												</b-input-group-prepend>
												<b-form-input autocomplete="off" id="squadra" type="text" v-model="form.partita.squadra" style="font-size:1rem"></b-form-input>
												<b-input-group-append>
												<b-button variant="primary" @click="selectTeam"><i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>										
								</fieldset>

								<hr/>
								
								<fieldset class="form-group">
									<label>Data della partita</label>
									<date-picker :date="form.partita.date" :option="option" :limit="limit"></date-picker>
								</fieldset>

								<fieldset class="form-group">
									<label>Dove si gioca la partita</label>
									<div>
										<div class="form-check-inline">
											<label class="form-check-label">
												<input type="radio" value="0" class="form-check-input" v-model="form.partita.home"> Casa
											</label>
										</div>
										<div class="form-check-inline">
											<label class="form-check-label">
												<input type="radio" value="1" class="form-check-input" v-model="form.partita.home"> Trasferta
											</label>
										</div>
									</div>
								</fieldset>
								<fieldset class="form-group">
									<label>Luogo</label>
									<input ref="autocomplete" class="form-control" type="text" v-model="form.partita.luogo">
								</fieldset>
								<b-row>
									<b-col>
										<div id="map" ref="map" style="height: 250px;"></div>
									</b-col>
								</b-row>
								<hr />

								<div class="row">
									<fieldset class="form-group col-6">
										<label>Arrivo</label>
										<date-picker :date="form.convocazione.arrive" :option="option" :limit="limit"></date-picker>
									</fieldset>
									<fieldset class="form-group col-6">
										<label>Riunione tecnica</label>
										<date-picker :date="form.convocazione.technical_meeting" :option="option" :limit="limit"></date-picker>
									</fieldset>
									<fieldset class="form-group col-6">
										<label>Pasto</label>
										<date-picker :date="form.convocazione.meal" :option="option" :limit="limit"></date-picker>
									</fieldset>
									<fieldset class="form-group col-6">
										<label>Partenza dallo stadio</label>
										<date-picker :date="form.convocazione.stadium_departure" :option="option" :limit="limit"></date-picker>
									</fieldset>
									<fieldset class="form-group col-6">
										<label>Ritorno</label>
										<date-picker :date="form.convocazione.return_base" :option="option" :limit="limit"></date-picker>
									</fieldset>
								</div>

								<!--hr />

								<div class="row">
									<fieldset class="form-group col-12">
										<label>Allenamento per i nn convocati</label>
										<multiselect v-model="form.convocazione.not_conv_training_id" :options="trainings" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="false" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false"></multiselect>
									</fieldset>
									<fieldset class="form-group col-12">
										<label>Allenamento successivo alla partita</label>
										<multiselect v-model="form.convocazione.post_match_training_id" :options="trainings" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="false" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false"></multiselect>
									</fieldset>
									<fieldset class="form-group col-12">
										<label>Orario Allenamento</label>
										<date-picker :date="form.convocazione.post_match_training" :option="option" :limit="limit"></date-picker>
									</fieldset>
								</div-->

							</div>

						</div>

						<hr />

						<div class="row">
							<div class="col-sm-12 text-right">
								<b-button type="reset" variant="outline-primary">Annulla</b-button>
								<b-button type="submit" variant="primary"><strong class="text-uppercase">Crea Convocazione</strong></b-button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<b-modal id="selectTeamModal" ref="selectTeamModal" title="Seleziona squadra" ok-only ok-title="Chiudi" size="lg">
			<div v-if="teams.data && teams.data.length == 0"><h5>Nessuna squadra trovata!</h5></div>
			<div v-if="teams.data && teams.data.length > 0">
				<div style="height: 250px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
					<b-row class="mb-1">
						<b-col sm="6">
							<b-form-select v-model="filters.categoria">
								<option value="0">-- Categoria --</option>
								<option v-for="item in categories_opt" :value="item.id" :key="item.id">{{item.name}}</option>
							</b-form-select>
						</b-col>
						<b-col sm="6">
							<b-input-group>
								<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
								<b-input-group-append>
									<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
								</b-input-group-append>
							</b-input-group>  	
						</b-col>
					</b-row>	
					<b-row>
						<b-col cols="4" v-for="team in teams.data" v-bind:key="team.id" style="padding: 0.2rem">
							<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="setTeam(team)">
								<img :src="team.logo_url" width="50" /><br/>
								<strong>{{team.officialName}}</strong><br/>
								<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
							</b-card>
						</b-col>
					</b-row>	
					<b-row>
						<b-col cols="6" style="padding: 0.2rem">
							<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
						</b-col>	
						
						<b-col cols="6" style="padding: 0.2rem">
								<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
						</b-col>	
					</b-row>				
				</div>
			</div>
		</b-modal>

		<footerapp></footerapp>

	</section>
</template>

<script>

import Multiselect from 'vue-multiselect';
import myDatepicker from 'vue-datepicker';
import axios from 'axios';
import { mapState } from 'vuex';
import * as types from '../../../../store/types'

export default {
	components: {
		Multiselect,
		'date-picker': 			myDatepicker,
		headapp:            	require('../../../layout/headapp.vue').default,
		footerapp:          	require('../../../layout/footerapp.vue').default,
		flag:               	require('../../../layout/flag.vue').default,
	},
	data () {
		return {
			page: 1,
			players: [],
			trainings: [],
			categories_opt: [],
			teams: [],
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
			opts_partite: [
				{id:1, name:'Campionato'},
				{id:2, name:'Coppa'},
				{id:3, name:'Amichevole'},
				{id:4, name:'Torneo'},
			],
			opts_num_player_partite: [
			/*	{id:1, name:'5'},
				{id:2, name:'6'},  */
				{id:3, name:'7'},
				{id:4, name:'8'},
				{id:5, name:'9'},
				{id:6, name:'11'},
			],
			options: [
				'Portiere',
				'Difensore',
				'Centrocampista',
				'Attaccante',
				'Riserva'
			],
			option: {
				type: 'min',
				week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
				month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				format: 'DD-MM-YYYY HH:mm',
				inputStyle: {
				},
				color: {
					header: 	'#a80532',
					headerText: '#ffffff'
				},
				buttons: {
					ok: 'Ok',
					cancel: 'Cancel'
				},
				overlayOpacity: 0.5, // 0.5 as default
				dismissible: true // as true as default
			},
			limit: [
			],
			form: {
				partita: {
					rosaTeamId: 		null,
					type: 				null,
					day: 				null,
					players: 			{id:6, name:'11'},
					duration:			90,
					home:				null,
					squadra: 			'',
					opponent_team_id: 	null,
					opponent_team: 		null,
					date: {
						time: ''
					},
					categoria: {
						id:'',
						name:''
					},
					luogo: '',
				},				
				convocazione: {
					data:	null,
					time:	null,
					convocated: {},
					meals: [],
					arrive: {
						time: ''
					},
					technical_meeting: {
						time: ''
					},
					meal: {
						time: ''
					},
					stadium_departure: {
						time: ''
					},
					return_base: {
						time: ''
					},
					post_match_training: {
						time: ''
					},
					not_conv_training_id: null,
					post_match_training_id: null,
				}
			}
		}
	},

	computed: {
		logo_squadra: function() {
			if(this.form.partita.opponent_team) {
				return this.form.partita.opponent_team.logo_url;
			}
			return '/assets/varie/team.png';
		},
		...mapState(['rosaTeamId']),
	},

	watch: {
	    rosaTeamId() {
	      	this.getPlayers();
	      //this.getTrainings();
	    },  
  	},

	created() {
	  //do something after creating vue instance
	  this.getPlayers();
	//this.getTrainings();
	  this.getTeams();

		/**
		*  RETRY CATEGORIES
		**/

		axios.get(`/exercise/categories`)
		.then((res) => {

			this.categories_opt = res.data.map(item => ({
				name: item.name,
				id: item.id,
			}));

			this.loading    = false;
		}).catch((err) => {
			this.loading = false;
		});

	},

	methods: {

		paginate(page) {
			this.page = page;
			this.getTeams();
		},

		filtraArchivio() {
			this.loading = true;
			this.teams = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then((response) => {
				// success callback
				if (response.ok)
				{
					this.teams = response.data;
				}
			}, (response) => {
				// error callback
			});
		},
		
		setTeam: function(team){
			this.form.partita.squadra = team.officialName + ' - ' + (team.categoria ? team.categoria.name : '&nbsp;');
			this.form.partita.opponent_team_id = team.id;
			this.form.partita.opponent_team = team;
			
			if( team.categoria ){
				this.form.partita.categoria.id 	= team.categoria.id
				this.form.partita.categoria.name = team.categoria.name
			}

			this.$refs.selectTeamModal.hide();
		},

		selectTeam: function(squadra) {			
			this.$refs.selectTeamModal.show();
		},

		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then((response) => {
				// success callback
				if (response.ok)
				{
					this.teams = response.data;						
				}
			}, (response) => {
				// error callback
			});
		},

		handleSubmit: function () {
			this.form.rosaTeamId = this.rosaTeamId;
			this.$http.post(this.$store.state.apiEndPoint + '/football/partite/convocati', this.form).then((response) => {
				// success callback
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ path: `/football/partite` })
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
				// error callback
			});
		},

		getPlayers: function(){
				/**
				*  RETRY players
				**/
				this.$http.get(this.$store.state.apiEndPoint + '/players', {params: { team_id: this.rosaTeamId }}).then((response) => {
					// success callback
					if (response.ok)
					{
						if(response.data.players) {
						this.players = response.data.players.map(item => ({
								name: item.nome + ' ' + item.cognome,
								id: item.id,
								prova: item.last_carriera_sportiva.tipologia == 'prova' ? 1 : 0,
							}));
						}
					}
				}, (response) => {
					// error callback
				});
			},
		getTrainings: function() {
			if(this.rosaTeamId) {
				const formData = new FormData();
				formData.append("team_id", this.rosaTeamId);
				formData.set('_method', 'POST');

				const opts = {
					url: `/allenamenti/get`,
					method: 'POST',
					data: formData,
				};

				axios(opts)
					.then((res) => {
						this.trainings = res.data.map(item => ({
								name: item.title,
								id: item.id,
							}));
					}).catch((err) => {
					console.log(err);
				});
			}
		},
		locateAddress: function() {
            this.geocoder = new window.google.maps.Geocoder();
            this.geocoder.geocode({ address: this.form.partita.luogo}, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.$map.setCenter(results[0].geometry.location);
                    this.$map.setZoom(17);
                    this.$marker.setPosition(results[0].geometry.location);
                    this.$marker.setVisible(true);
                }
            });
        }
	},
	mounted() {
    	this.autocomplete = new window.google.maps.places.Autocomplete(
          (this.$refs.autocomplete),
          {types: ['geocode']}
          );
        this.$map = new window.google.maps.Map(this.$refs.map, {
          zoom: 4,
          center: { lat: 41.871940, lng: 12.567380 },
          streetViewControl: false,
          mapTypeControl: false,
        });

        this.$marker = new window.google.maps.Marker({
          position: { lat: Number(41.871940), lng: Number(12.567380) },
          map: this.$map,
        });
        this.$marker.setVisible(false);

        this.autocomplete.addListener('place_changed', () => {
          let place = this.autocomplete.getPlace();

          const bounds = new window.google.maps.LatLngBounds();

          if (!place.geometry) {
            return;
            }
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            this.$marker.setPosition(place.geometry.location);
            this.$marker.setVisible(true);

            this.$map.fitBounds(bounds);
            this.form.partita.luogo = place.formatted_address;
        }); 
        this.locateAddress();
	}
}
</script>
