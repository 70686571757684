<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

		</div>

	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import videojs from 'video.js';
import 'videojs-playlist';
import moment from 'moment';
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	data: function(){
		return {
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
	},

	created() {
		if(this.auth.isUserEnable('football_esercizi')) {
			this.$router.push({ name: 'football_esercizi' });
		} else if(this.auth.isUserEnable('palestra_esercizi')) {
			this.$router.push({ name: 'football_palestra_esercizi' });
		} else if(this.auth.isUserEnable('piscina_esercizi')) {
			this.$router.push({ name: 'football_piscina_esercizi' });	
		}
	},
}
</script>
