export const ROUTES_FILEBROWSER = [
    {
        path: '/filebrowser/dashboard',
        name: 'filebrowser_dashboard',
        component: require('../components/pages/filebrowser/dashboard.vue').default,
        meta: {
            module: 'filebrowser',

            label: 'File Browser',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'filebrowser',
        }
    },
];
