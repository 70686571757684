<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Nuovo Video</span>
					</div>
					<div class="content">
						<b-row>
							<b-col cols="8">	
								<b-form @submit="onSubmit">
									<b-form-group label="Titolo">
										<b-form-input v-model="form.titolo"></b-form-input>
									</b-form-group>
									<b-form-group label="Stato" v-if="auth.isUserEnable('romalab_video_mng')">
										<b-form-radio-group v-model="form.stato">
											<b-form-radio value="0">Bozza</b-form-radio>
											<b-form-radio value="1">Pubblicato</b-form-radio>
											<b-form-radio value="2">Archiviato</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-form-group label="Categorie">
										<b-form-checkbox v-model="allSelected" @change="toggleAll" :indeterminate="indeterminate">
											{{ allSelected ? 'Deseleziona tutte' : 'Seleziona tutte' }}
										</b-form-checkbox>
										<b-form-checkbox-group v-model="form.categorie_ids">
											<b-form-checkbox v-for="cat in user.romalabCats" :value="cat.id"> {{ cat.name }} </b-form-checkbox>
										</b-form-checkbox-group>
									</b-form-group>
									<b-form-group label="Visibilità">
										<b-form-radio-group v-model="form.privato">
											<b-form-radio value="0">Pubblico</b-form-radio>
											<b-form-radio value="1">Privato</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<div v-if="form.privato == 1">
										<b-form-group v-for="item in staff" :label="item.cat_name" v-if="form.categorie_ids.indexOf(item.cat_id) > -1">
											<b-form-checkbox-group v-model="form.utenti_ids">
												<b-form-checkbox v-for="user in item.utenti" :value="user.id">{{ user.nomeCompleto }}</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>	
									</div>
									<b-form-group label="Mostra i video di:">
										<b-form-radio-group v-model="filters_date">
											<b-form-radio value="day">Oggi</b-form-radio>
											<b-form-radio value="week">Ultimi 7 giorni</b-form-radio>
											<b-form-radio value="month">Ultimi 30 giorni</b-form-radio>
											<b-form-radio value="all">Tutti</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-form-group label="Video caricati sul server ASRoma">
										<div v-if="!upload_loading" style="width:100%;" class="mt-1">
											<span class="font-bold">Loading...</span>
											<img src="/assets/varie/loading.gif" style="width: 30px;" />
										</div>
										<span class="font-bold" v-if="upload_loading && upload_video.length == 0">Nessun video trovato sul tuo archivio!</span>
										<b-form-select v-model="form.fileNameVideo" v-if="upload_loading && upload_video.length > 0">
											<option :value="null">Seleziona video</option>
											<optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
												<option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} caricato il {{ video.data | formatDateHour }}</option>
											</optgroup>
										</b-form-select>
									</b-form-group>
									<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
								</b-form>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col cols="8" class="border-top">
								<b-button class="mt-3" @click="showUpload">Carica nuovo video</b-button>
								<b-row v-if="show_upload" class="mt-3">
									<b-col>
										<b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
export default {
	data: function() {
		return {
			upload_loading: false,
			upload_video: [],
			show_upload: false,
			form: {
				type: 'asroma',
				titolo: '',
				fileNameVideo: null,
				stato: 0,
				categorie_ids: [],
				cartella: this.$route.params.id_cartella,
				privato: 0,
				utenti_ids: [],
			},
			staff: [],
			errors: [],
			filters_date: 'day',
			upload_iframe: '',
			allSelected: false,
			indeterminate: false,
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	created: function () {
		this.getUploadVideo();
		this.getUploadIFrame();
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
	},

	watch: {
		filters_date: function () {
			this.form.fileNameVideo = null;
			this.upload_loading = false;
			this.getUploadVideo();
		},
		'form.categorie_ids': function(newVal, oldVal) {

			if (newVal.length === 0) {
				this.indeterminate = false
				this.allSelected = false
			} else if (newVal.length === Auth.user.romalabCats.length) {
				this.indeterminate = false
				this.allSelected = true
			} else {
				this.indeterminate = true
				this.allSelected = false
			}
			for(var i in this.form.categorie_ids) {
				this.getStaffByCategoria(this.form.categorie_ids[i]);
			}
		}  
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.addVideo();
		},
		addVideo() {
			this.$http.post(this.$store.state.apiEndPoint + '/romalab/video/add', this.form).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: "romalab_video_cartella", params: { id: this.$route.params.id_cartella }});
					
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		getUploadVideo() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', {params: { filters_date: this.filters_date }}).then((response) => {
				if (response.ok)
				{
					this.upload_video = response.data;
					this.upload_loading = true;
				}
			}, (response) => {

			});
		},
		showUpload() {
			this.show_upload = !this.show_upload;		
		},
		getUploadIFrame() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
				if (response.ok) {
					this.upload_iframe = response.data;
				}
			}, (response) => {
			});
		},
		toggleAll(checked) {
			if(checked) {
				this.form.categorie_ids = []; 
				for(var i in Auth.user.romalabCats) {
					this.form.categorie_ids.push(Auth.user.romalabCats[i].id);
				}
			} else {
				this.form.categorie_ids = [];
			}
		},
		getStaffByCategoria(id) {
			for(var i in this.staff) {
				if(this.staff[i].cat_id == id) {
					return;
				}
			}
			this.$http.get(this.$store.state.apiEndPoint + '/romalab/get/staff/categoria/' + id).then((response) => {
				if (response.ok)
				{
					var label = '';
					for(var i in Auth.user.romalabCats) {
						if(Auth.user.romalabCats[i].id == id) {
							label = Auth.user.romalabCats[i].name;
						}
					}
					this.staff.push({cat_id: id, cat_name: label, utenti: response.data});
				}
			}, (response) => {
			});
		}
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>
