export const ROUTES_CAMERA = [
    {
        path: '/camera/dashboard',
        name: 'camera_dashboard',
        component: require('../components/pages/camera/dashboard.vue').default,
        meta: {
            module: 'camera',

            label: 'Camera',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'camera',
        }
    },
];
