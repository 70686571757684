<template>
	<div class="my-3">
		<b-form-group label="Tipologia Evento">
			<b-form-select v-model="estensione.tipologia">
				<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>

				<b-form-select-option value="match">Partita</b-form-select-option>
				<b-form-select-option value="training">Allenamento</b-form-select-option>
			</b-form-select>
		</b-form-group>
		<div v-if="sessione && sessione.id">

			<b-card>

				<template #header v-if="ux.showPanel == false">Importa File</template>
				<template #header v-else>Configura Righe</template>
				<template #footer v-if="ux.showPanel == true">
					<b-row>
						<b-col :cols="9">
							<b-button @click.prevent="ux.showPanel = false" size="sm">Torna alla scelta dei file</b-button>
						</b-col>
						<b-col>
							<div class="d-flex align-items-center">
								<div class="flex-grow-1 small text-muted" style="min-width: 110px"><strong>Separatore</strong> del file</div>
								<b-input v-model="ux.csvSeparator" size="sm" class="mr-1"></b-input>
								<b-button @click.prevent="process(ux.file)" size="sm" variant="outline-secondary">Riprocessa</b-button>
							</div>
						</b-col>
					</b-row>
				</template>

				<DocumentiManager v-if="ux.showPanel == false" @upload="fetchFiles" entity="Sessione" :entityId="sessione.id" :tipoId="1" path=""></DocumentiManager>
			
				<div v-if="files.length > 0 && ux.showPanel == false">
					<hr />
					<h5>Scegli un file da processare</h5>
					<b-list-group>
						<b-list-group-item href="#" @click.prevent="process(file)" v-for="file in files">{{ file.documento.nome || file.documento.path }} <span v-if="imported(file)">({{ imported(file) }})</span></b-list-group-item>
					</b-list-group>
				</div>

				<div v-if="ux.showPanel">
					<div v-if="ux.processedFile">

						<b-table-simple responsive>
							<b-thead>
								<b-tr>
									<b-th style="min-width: 200px">Giocatore</b-th>
									<b-th style="min-width: 140px" v-for="colonna in intestazione">{{ colonna }}</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="dati in processData">
									<b-td>
										<b-form-select size="sm" v-model="dati.persona_id">
											<b-form-select-option :value="null">Scarta Dato</b-form-select-option>
											<b-form-select-option v-for="persona in sessione.persone" :value="persona.id">{{ persona.cognome }} {{ persona.nome }}</b-form-select-option>
										</b-form-select>
									</b-td>
									<b-td v-for="colonna in intestazione">
										<b-input size="sm" type="text" v-model="dati.data[colonna]" :disabled="dati.persona_id == null"></b-input>
									</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>

						<b-button @click.prevent="importData" :disabled="processPayaload.length == 0" variant="outline-success" size="sm">Importa Dati</b-button>

					</div>
					<div v-else>Attendi Caricamento del file....</div>
				</div>
			</b-card>

		</div>

	</div>
</template>

<script>

import sessionMixin from '@/mixins/sessione.js';
import pageBase from '@/mixins/pageBase.js'
import _ from 'lodash';

export default {
	mixins: [ 
		pageBase,
		sessionMixin
	],
	data: function() {
		return {
			files: [],
			processData: [],
			ux: {
				file: false,
				csvSeparator: ',',
				showPanel: false,
				processedFile: null
			}
		}
	},
	components: {
		DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
	},
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { tipologia: null, imported: null  });
		}
	},
	watch: {
		sessione: {
			immediate: true,
			handler: function() {
				this.fetchFiles()
			}
		}
	},
	computed: {
		processPayaload: function() {

			return _.filter(this.processData, function(a) {
				return a.persona_id != null;
			});

		},
		intestazione: function() {
			return _.keys(this.ux.processedFile[0]);
		}
	},
	methods: {
		importData: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/sessione-nazionale/'+this.sessione.id+'/import', { dati: this.processPayaload }).then(function(data) {
			
				this.ux.showPanel = false;
				this.ux.processedFile = false;
				this.$emit('refresh');

			});			

		},
		buildData: function(data) {

			this.processData = _.map(_.cloneDeep(data), function(a) {
				return {
					persona_id: null,
					data: a
				}
			});

		},
		process: function(file) {

			this.ux.file = file;
			this.ux.showPanel = true;
			this.$http.post(this.$store.state.apiEndPoint + '/sessione-nazionale/analizza-file', { file, separator: this.ux.csvSeparator }).then(function(data) {
				this.ux.processedFile = data.data;
				this.buildData(data.data);
			});

		},
		imported: function(id) {

			if(this.estensione.imported && this.sessione) {
				// JSON.decode();
			}
			return false;
			return 'File già elaborato';
		},
		fetchFiles: function() {
			if(this.sessione) {
				this.$http.get(this.$store.state.apiEndPoint + '/documenti/of/Sessione/' + this.sessione.id).then(function(data) {
					this.files = data.data;
				});
			}
		}
	},
	props: [ 'estensione', 'model', 'field', 'sessione' ],
};
</script>