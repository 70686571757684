<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Video ({{ total }})</span>
					</div>
					<div class="content">
						<b-row class="mb-3" align-v="center">
							<b-col cols="3">
							</b-col>
							<b-col md="1">
								Filtra per:
							</b-col>
							<b-col md="4">
								<b-form-input v-model="filters.titolo" placeholder="Titolo" />
							</b-col>
							<b-col md="2">
							<b-form-select v-model="filters.stato" class="inline mr-3"> 
									<option value="">-- Stato --</option>
									<option value="2">Archiviato</option>
								</b-form-select>
							</b-col>
							<b-col md="2">
								<b-form-input type="date" v-model="filters.data" class="inline mr-3"></b-form-input>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="3">
								<v-infinite-scroll :loading="loading" @bottom="nextPage" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
									<b-col cols="12" v-for="item in video" class="mb-1">
										<b-card :bg-variant="bgCard(item.stato)">
											<h5 class="m-0">{{ item.titolo != '' ? item.titolo : 'Video' }} </h5>
											<div><small class="color-rosso"> {{ item.created_at | formatDate }} </small></div>
											<!--div v-if="auth.isUserEnable('romalab_video_mng')"><small> <span v-for="(cat, index) in item.categorie">{{ cat.name }}{{ index < item.categorie.length - 1 ? ',' : ''}} </span> </small></div-->
											<b-img :src="item.thumb != '' ? item.thumb : '/assets/varie/missing.jpg'" fluid alt="Responsive image" @click="playVideo(item.video)" style="cursor:pointer;"/>
											<!--b-row class="mt-1">
												<b-col>	
												</b-col>
												<b-col cols="2">
													<b-button variant="primary" :to="{ name: 'romalab_video_edit', params: {id: item.id} }" title="Modifica Video" v-if="auth.isUserEnable('romalab_video_mng')"><i class="fa fa-pencil" aria-hidden="true"></i></b-button>
												</b-col>
												<b-col cols="2">
													<b-button @click="eliminaVideo(item.id)" title="Elimina Video" v-if="auth.isUserEnable('romalab_video_mng')"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
												</b-col>
											</b-row-->
										</b-card>
									</b-col>
								</v-infinite-scroll>
							</b-col>
							<b-col cols="9">
								<video
								id="video-wrapper"
								class="video-js vjs-default-skin"
								controls
								:data-setup="setup">
							</video>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>	
	</div>

	<footerapp></footerapp>

</section>
</template>


<script>
var Auth = require('../../../auth.js');
import videojs from 'video.js';
import 'videojs-errors';
import { mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import InfiniteScroll from 'v-infinite-scroll';
export default {

	data: function() {
		return {
			video: [],
			total: 0,
			page: 1,
			filters: {
				titolo: '',
				data: '',
				stato: '',
			},
			errors_player: {
			    4: {
			      headline: 'Video non disponibile',
			      type: '',
			      message: 'Il video non è stato ancora transcodificato.<br/>Riprova più tardi.',
			    }
			}
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		'v-infinite-scroll': InfiniteScroll,
	},
	computed: {
		auth: function() {
			return Auth;
		},
		setup() {
			return JSON.stringify({
				techOrder: ['html5'],
				width: '100%',
				height: '100%',
				aspectRatio: '16:9',
				youtube: {
					iv_load_policy: 3,
					modestbranding: 1,
					rel: 0,
					showinfo: 0,
				},
			});
		},
		...mapState(['romalabCatId']),
	},
	created: function () {
		if(this.romalabCatId) {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/romalab/video/list/' + this.romalabCatId).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.video = response.data.data; 
	            	this.total = response.data.total;
	            	this.loading = false;
	            }
	        }, (response) => {
	            // error callback
	        });
		}
	},
	mounted() {
		this.$video = videojs('video-wrapper');
		this.$video.errors({
			"errors": this.errors_player,
		});
	},
	beforeDestroy() {
		this.$video.dispose();
		this.$video = null;
	},
	methods: {
		nextPage() {
			if(this.romalabCatId) {
				if(this.video.length < this.total) {
					this.page++;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/romalab/video/list/' + this.romalabCatId + '?page=' + this.page, {params: this.filters}).then((response) => {
		            // success callback
		            if (response.ok)
		            {
		            	this.video = this.video.concat(response.data.data);
		            	this.loading = false;
		            }
		        }, (response) => {
		            	// error callback
		            });
				}
			}
		},
		filtraVideo() {
			if(this.romalabCatId) {
				this.loading = true;
				this.video = [];
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/romalab/video/list/' + this.romalabCatId, {params: this.filters}).then((response) => {
					if (response.ok)
					{
						this.video = response.data.data;
						this.total = response.data.total;
						this.loading = false; 
					}
				}, (response) => {
				});
			}
		},
		eliminaVideo(id) {
			if(confirm("Confermi l'eliminazione del video ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/romalab/video/elimina/' + id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.filtraVideo();			
				}
			}, (response) => {
			});
			}
		},
		playVideo(url) {
			this.$video.src({type: 'video/mp4', src: url });
			this.$video.play();
		},
		bgCard(stato) {
			var bg = "";
			if(stato == 0) {
				bg = 'warning';
			} else if(stato == 2) {
				bg = 'info';
			}
			return bg;
		},
	},
	watch: {
		romalabCatId() {
			this.filtraVideo();
		}, 
		filters: {
			handler() {
				this.filtraVideo();
			},      
			deep: true,
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
}
</script>
<style lang="scss">

.video-js {
	width: 100%;
	height: 100%;
	flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
	$big-play-width: 3em;
	$big-play-height: 1.5em;
	left: 50%;
	top: 50%;
	margin-left: -($big-play-width / 2);
	margin-top: -($big-play-height / 2);
}

</style>
