<template>
	<div>
		<b-row v-if="utente_cartelle == 0 || utente_cartelle == user.id || (utente_cartelle > 0 && auth.isAdmin())">
			<b-col> <small>Nuova cartella</small></b-col>
			<b-col class="text-right">
				<b-form-select v-model="newCartella.riassunto" size="sm" class="mb-1">
					<option value="0">-- tipo --</option>
					<option value="1">riassunto</option>
					<option value="2">highlights</option>
					<option value="3">sintesi</option>
					<option value="4">goals</option>
				</b-form-select>
			</b-col>
		</b-row>
		<b-input-group size="sm" class="mb-1" v-if="utente_cartelle == 0 || utente_cartelle == user.id || (utente_cartelle > 0 && auth.isAdmin())">
			<b-form-input id="newCartella" v-model="newCartella.nome"></b-form-input>
			<b-input-group-append>
				<b-btn variant="primary" @click="creaCartella">Crea</b-btn>
			</b-input-group-append>
		</b-input-group>
	</div>
</template>

<script>
var Auth = require('../../../auth.js');
export default {

	props: ['utente_cartelle'],

	data: function(){
		return {
			newCartella: {
				nome: '',
				riassunto: 0,
			},
			
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},

	},
	methods:{
		creaCartella() {
			this.$emit('creaCartella', this.newCartella);
			this.newCartella = {
				nome: '',
				riassunto: 0
			}
		}
	},
}
</script>
