<template id="nuova_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card card-block title_page">
				<div class="card-body">
					<div class="card-title">
						<span>Nuova Valutazione</span>
					</div>
				</div>
			</div>	
			<div class="content_page">
				<b-form @submit="onSubmit">
					<b-row>
						<b-col cols="3">
							<div class="card" style="height:100%">
								<div style="text-align:center;">
									<b-img :src="osservato.giocatore.avatar_url" :alt="osservato.giocatore.cognome + ' ' + osservato.giocatore.nome" :width="130"></b-img>
								</div>
								<div class="card-body">
									<div class="mb-1 font-bold text-center">{{ osservato.giocatore.nome }} {{ osservato.giocatore.cognome }}</div>
									<div class="text-center font-size-sm color-rosso">{{ osservato.giocatore.ruolo_ideale && osservato.giocatore.ruolo_ideale.length > 0 ? osservato.giocatore.ruolo_ideale[0].ruolo : '' }}</div>
									<div class="text-center font-size-sm">Classe {{osservato.giocatore.data_nascita | formatYear}}</div>
									<div class="text-center font-size-sm color-rosso">{{osservato.giocatore.squadra_militante ? osservato.giocatore.squadra_militante.name : ''}}</div>
									<div class="text-center font-size-sm mt-3">
										<b-form-radio-group
										stacked
										buttons
										v-model="form.gradimento"	
										size="sm">
										<b-form-radio @click.native="selectGradimento(grad.id)" v-for="grad in gradimenti" :value="grad.id" :class="(grad.id == form.gradimento ? 'btn-grad-active' : 'btn-grad')" :style="'background-color:' + (grad.id == form.gradimento ? grad.colore : grad.colore + '7F')">{{grad.nome}}</b-form-radio>
									</b-form-radio-group>
								</div>
								<div class="text-center font-size-sm mt-3">
									<b-form-checkbox v-model="form.asroma">
										<b-img src="/assets/images/logo.png" width="30"></b-img>
									</b-form-checkbox>
								</div>
							</div>
						</div>
					</b-col>
					<b-col>
						<b-tabs>
							<b-tab :title="'Partita ' + (index + 1)" v-for="(item, index) in form.partite" :key="item">	
								<b-row>
									<b-col>
										<b-form-group id="competizioneGroup" horizontal label="Competizione" :label-cols="3" label-for="competizione">
											<b-form-input id="competizione" type="text" v-model="item.competizione" :required="item.modalita == 'allenamento' ? false : true"></b-form-input>
										</b-form-group>
										<b-form-group id="casaGroup" horizontal label="Squadra di casa" :label-cols="3">
											<b-input-group>
												<b-form-input id="partita" type="text" v-model="item.casa" readonly></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="selectSquadra(item, false)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
										<b-form-group id="ospiteGroup" horizontal label="Squadra ospite" :label-cols="3">

											<b-input-group>
												<b-form-input id="partita" type="text" v-model="item.ospite" readonly></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="selectSquadra(item, true)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									<!--b-form-group id="partitaGroup" horizontal label="Partita" :label-cols="3">
										<b-input-group>
											<b-form-input id="partita" type="text" v-model="item.partita"></b-form-input>
											<b-input-group-append>
												<b-button variant="primary" @click="searchPartita(item)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group-->
									<b-row>
										<b-col cols="8">
											<b-form-group id="dataGroup" horizontal label="Data / Ora" :label-cols="3" label-for="data">
												<b-form-input id="data" type="date" v-model="item.data" :required="item.modalita == 'allenamento' ? false : true"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="4">
											<b-form-group id="oraGroup" horizontal label="/" :label-cols="1" label-for="ora">
												<b-form-input id="ora" type="time" v-model="item.ora"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group id="sistemaGiocoGroup" horizontal label="Sistema di gioco" :label-cols="3">
										<b-form-select v-model="item.sistema_gioco" :options="sistemi" value-field="id" text-field="sistema_gioco"></b-form-select>
									</b-form-group>
									<b-form-group id="ruoloPrimarioGroup" horizontal label="Ruolo primario" :label-cols="3">
										<b-form-select v-model="item.ruolo_primario" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
									<b-form-group id="ruoloSecondarioGroup" horizontal label="Ruolo secondario" :label-cols="3">
										<b-form-select v-model="item.ruolo_secondario" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group id="meteoGroup" horizontal label="Meteo" :label-cols="3">
										<b-form-select v-model="item.meteo" :options="meteo" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
									<b-form-group id="ventiGroup" horizontal label="Vento" :label-cols="3">
										<b-form-select v-model="item.vento" :options="venti" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
									<b-form-group id="tipoCampoGroup" horizontal label="Tipologia campo" :label-cols="3">
										<b-form-select v-model="item.tipo_campo" :options="tipi_campo" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
									<b-form-group id="condizioniCampoGroup" horizontal label="Condizioni campo" :label-cols="3">
										<b-form-select v-model="item.condizioni_campo" :options="condizioni_campo" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
									<b-form-group id="infortunioGroup" horizontal label="Infortunio" :label-cols="3">
										<b-form-select v-model="item.infortunio" :options="infortuni" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>	
									<b-form-group id="modalitaGroup" horizontal label="Modalità" :label-cols="3" label-for="modalita">
										<b-form-radio-group id="modalita" v-model="item.modalita">
											<b-form-radio value="allenamento">Allenamento</b-form-radio>
											<b-form-radio value="stadio">Stadio</b-form-radio>
											<b-form-radio value="video">Video</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-button variant="primary" class="float-right ml-1" @click="removePartita(item)" title="Rimuovi partita" v-if="form.partite.length > 1 && form.partite.length == (index + 1)"><i class="fa fa-trash" aria-hidden="true"></i>
									</b-button>		
								</b-col>
							</b-row>
						</b-tab>
						<b-nav-item slot="tabs" @click.prevent="addPartita" href="#" title="Aggiungi partita">
							<i class="fa fa-plus" aria-hidden="true"></i>
						</b-nav-item>
					</b-tabs>
					<b-row>
						<b-col>
							<b-form-textarea id="note" v-model="form.note" placeholder="Note" :rows="3"></b-form-textarea>
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			<b-row class="mt-1">
				<b-col v-for="item in skills">
					<b-card :title="item[$i18n.t('lang')]">
						<b-list-group>
							<b-list-group-item v-for="skill in item.fields" :variant="form.fields[item.id][skill.id]">
								<b-row style="width:100%">
									<b-col cols="7">
										{{skill[$i18n.t('lang')]}}
									</b-col>
									<b-col cols="5" class="text-center">
										<b-form-select :options="voti" class="voto-skill-select" v-model="form.fields[item.id][skill.id]">
											<template slot="first">
												<option :value="0"></option>
											</template>
										</b-form-select>
									</b-col>
								</b-row>						
							</b-list-group-item>
						</b-list-group>
					</b-card>
				</b-col>
			</b-row>
			<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
		</b-form>
	</div>
</div>

<b-modal id="selectTeamModal" ref="selectTeamModal" title="Seleziona squadra" ok-only ok-title="Chiudi" size="lg">
	<div v-if="squadre.data.length == 0"><h5>Nessuna squadra trovata!</h5></div>
	<div>
		<div style="height: 250px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
			<b-row class="mb-1">
				<b-col sm="6">
					<b-form-select v-model="filters.categoria">
						<option value="0">-- Categoria --</option>
						<option v-for="item in categorie" :value="item.id" :key="item.id">{{item.name}}</option>
					</b-form-select>
				</b-col>
				<b-col sm="6">
					<b-input-group>
						<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
						<b-input-group-append>
							<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
						</b-input-group-append>
					</b-input-group>  	
				</b-col>
			</b-row>	
			<b-row>
				<b-col cols="4" v-for="team in squadre.data" v-bind:key="team.id" style="padding: 0.2rem">
					<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="setTeam(team)">
						<img :src="team.logo_url" width="50" /><br/>
						<strong>{{team.officialName}}</strong><br/>
						<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
					</b-card>
				</b-col>
			</b-row>	
			<b-row>
				<b-col cols="6" style="padding: 0.2rem">
					<a v-if="squadre.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(squadre.current_page) - 1)" >Indietro</a>
				</b-col>	

				<b-col cols="6" style="padding: 0.2rem">
					<a v-if="squadre.current_page < squadre.last_page" class="page-link" @click="paginate(parseInt(squadre.current_page) + 1)">Avanti</a>
				</b-col>	
			</b-row>				
		</div>
	</div>
</b-modal>
</div>
<footerapp></footerapp>

</section>
</template>

<script>
import * as types from '../../../store/types';
import moment from 'moment';

export default {
	data: function() {
		return {
			osservato: [],
			voti: [
			{ value: 2, text: 'Scarso' },
			{ value: 4, text: 'Insufficiente' },
			{ value: 6, text: 'Sufficiente' },
			{ value: 8, text: 'Buono' },
			{ value: 10, text: 'Ottimo' },
			],
			form: {
				giocatore_id: 0,
				partite: [{
					competizione: '',
				//	partita: '',
				casa: '',
				ospite: '',
				id_casa: 0,
				id_ospite: 0,
				data: '',
				ora: '',
				sistema_gioco: 0,
				ruolo_primario: 0,
				ruolo_secondario: 0,
				meteo: 0,
				vento: 0,
				tipo_campo: 0,
				condizioni_campo: 0,
				infortunio: 0,
				wyscout_partita_id: '',
				modalita: ''
			}],	
			gradimento: 0,
			fields: {},
			note: '',
			asroma: false,
		},
		errors: [],
		infortuni: [],
		ruoli: [],
		sistemi: [],
		gradimenti: [],
		meteo: [],
		venti: [],
		tipi_campo: [],
		condizioni_campo: [],
		skills: [],
		matches: [],
		is_search: false,
		tab_partita: '',
		ospite: false,
		squadre: [],
		page: 1,
		filters: {
			search_name: '',
			categoria: 0,
		},
		categorie: [],
	}
},

components: {
	headapp: 	require('../../layout/headapp.vue').default,
	menusub:    require('../../layout/menusub.vue').default,
	footerapp:  require('../../layout/footerapp.vue').default,
},
created: function () {
	this.$http.get(this.$store.state.apiEndPoint + '/usascout/giocatore/' + this.$route.params.id).then((response) => {
		if (response.ok)
		{
			this.osservato = response.data;

			this.form.giocatore_id = this.osservato.id_giocatore;

			this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
				if (response.ok) {  
					if(this.osservato.giocatore.ruolo_ideale && this.osservato.giocatore.ruolo_ideale.length > 0 && this.osservato.giocatore.ruolo_ideale[0].id == 1) {
						this.skills = JSON.parse(response.data.special);
					} else {
						this.skills = JSON.parse(response.data.standard);
					} 
				}
			}, (response) => {

			});
		}
	}, (response) => {
	});

	this.$http.get(this.$store.state.apiEndPoint + '/infortuni').then((response) => {
		if (response.ok)
		{
			this.infortuni = response.data;    
		}
	}, (response) => {
	});

	this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
		if (response.ok)
		{
			this.ruoli = response.data;    
		}
	}, (response) => {
	});

	this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
		if (response.ok)
		{
			this.sistemi = response.data;    
		}
	}, (response) => {
	});  

	this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
		if (response.ok)
		{
			this.gradimenti = response.data;    
		}
	}, (response) => {
	});

	this.$http.get(this.$store.state.apiEndPoint + '/meteo').then((response) => {
		if (response.ok)
		{
			this.meteo = response.data;    
		}
	}, (response) => {
	});

	this.$http.get(this.$store.state.apiEndPoint + '/venti').then((response) => {
		if (response.ok)
		{
			this.venti = response.data;    
		}
	}, (response) => {
	});

	this.$http.get(this.$store.state.apiEndPoint + '/tipi/campo').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.tipi_campo = response.data;    
            }
        }, (response) => {
        });

	this.$http.get(this.$store.state.apiEndPoint + '/condizioni/campo').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.condizioni_campo = response.data;    
            }
        }, (response) => {
        });
	this.$http.get(this.$store.state.apiEndPoint + '/exercise/categories').then((response) => {
		if (response.ok)
		{
			this.categorie = response.data; 
		}
	}, (response) => {
            // error callback
        });
	this.getSquadre();
},
watch: {
	skills: function () {
		var jsonObject = {};
		for (var k in this.skills) {
			var item = this.skills[k];          
			if(item.fields) {
				for (var j in item.fields) {
					var item2 = item.fields[j];
					jsonObject = this.nest(jsonObject, [item.id, item2.id], 0);		 
				}
			}
		}
		this.form.fields = jsonObject;
	},
	'filters.categoria': function () {
		this.filtraArchivio();
	},
},
filters: {
	formatYear: function (date) {
		var m = moment(date);
		if(m.isValid()) {
			return m.format('YYYY'); 
		}
		return "";
	},
	formatDateHour: function (date) {
		var m = moment(date);
		if(m.isValid()) {
			return m.format('DD/MM/YYYY HH:mm'); 
		}
		return "";
	}
},
methods: {
	onSubmit(evt) {
		for(var i in this.form.partite) {
			if(this.form.partite[i].modalita != 'allenamento' && (this.form.partite[i].id_casa == 0 || this.form.partite[i].id_ospite == 0)) {
				alert("Devi selezionare la squadra di casa e la squadra ospite!");
				return;
			}
		}
		evt.preventDefault();
		this.$http.post(this.$store.state.apiEndPoint + '/usascout/add/valutazione', this.form).then((response) => {
			if (response.ok)
			{
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				if(response.data.valutazione_id > 0) {	
					this.$router.push({ name: "usascout_view_valutazione", params: { id: this.osservato.id_giocatore, val_id: response.data.valutazione_id} });
				} else {
					this.$router.push({ name: "usascout_view_giocatore", params: { id: this.osservato.id_giocatore } });
				}
			}
		}, (response) => {  
			if (response.status == 422) {
				this.errors = response.data;
				for (var index in this.errors) {
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				}
			}
		});
	},
	nest: function(obj, keys, v) {
		if (keys.length === 1) {
			obj[keys[0]] = v;
		} else {
			var key = keys.shift();
			obj[key] = this.nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v);
		}

		return obj;
	},

	addPartita: function () {
		this.form.partite.push({ competizione: '',
			//partita: '',
			casa: '',
			ospite: '',
			id_casa: 0,
			id_ospite: 0,
			data: '',
			ora: '',
			sistema_gioco: 0,
			ruolo_primario: 0,
			ruolo_secondario: 0,
			meteo: 0,
			vento: 0,
			tipo_campo: 0,
			condizioni_campo: 0,
			infortunio: 0,
			wyscout_partita_id: '',
			modalita: '' });
	},
	removePartita: function (item) {
		var index = this.form.partite.indexOf(item);	
		this.form.partite.splice(index, 1);
	},
	selectGradimento(id) {
		this.form.gradimento = id;
	},
	getSquadre: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/usascout/squadre?page=' + this.page, {params: this.filters}).then((response) => {
				// success callback
				if (response.ok)
				{
					this.squadre = response.data;						
				}
			}, (response) => {
				// error callback
			});
	},
	paginate(page) {
		this.page = page;
		this.getSquadre();
	},
	filtraArchivio() {
		this.loading = true;
		this.squadre = [];
		this.page = 1;
		this.$http.get(this.$store.state.apiEndPoint + '/usascout/squadre', {params: this.filters}).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.squadre = response.data;
            }
        }, (response) => {
            // error callback
        });
	},
	selectSquadra: function(tab_partita, ospite) {
		this.tab_partita = tab_partita;
		this.ospite = ospite;			
		this.$refs.selectTeamModal.show();
	},
	setTeam(squadra) {
		if(this.ospite) {
			this.tab_partita.ospite = squadra.officialName;
			this.tab_partita.id_ospite = squadra.id;
		} else {
			this.tab_partita.casa = squadra.officialName;
			this.tab_partita.id_casa = squadra.id;
		}
		this.$refs.selectTeamModal.hide();
	},
}
}

</script>
