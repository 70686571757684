<template>
  <modal @close="$emit('close')">
    <div class="vuefinder-modal-header">
      <p>File Link</p>
    </div>
    <div>{{ data.length }} item{{ data.length > 1 ? 's':'' }} selected.</div>
    <div class="vuefinder-modal-body">
      <ul class="delete-list">
        <li 
          v-for="item in data" 
          :key="item.path"
        >
          <div class="delete-list-item">
            <span class="file_path">{{ $store.state.apiEndPoint }}{{item.type.includes('video') ? '/video/url' : '/file/url'}}?path={{ item.path }} <button class="btn btn-primary btn-xs ml-2 mb-1"  @click="copyLink(item)">Copy link</button></span>
          </div>
          <div class="delete-list-type">{{ item.type }}</div>
        </li>
      </ul>
    </div>
    <div class="vuefinder-modal-footer mt-3">
      <button 
        class="btn btn-secondary btn-sm" 
        @click="$emit('close')"
      >Close</button>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';
import axios from 'axios';

export default {
    name: 'ModalLink',
    components: { 'modal': Modal },
    props: {
        data: {
            type: Array,
            required: true
        }, 
        directory:{
            type: String,
            required: true
        }, 
        url: {
            type: String,
            required: true
        }
    },
    methods: {
      copyLink(item) {
          navigator.clipboard.writeText(this.$store.state.apiEndPoint + (item.type.includes('video') ? '/video/url' : '/file/url') + '?path=' + item.path);
        }
    }
};
</script>

<style scoped>
.delete-list {
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.delete-list li {
  display: flex;
  padding-top: 5px;
  border-bottom: 1px dashed lightgray;
}

.delete-list-item {
  flex: 1;
  user-select: text;
}

.delete-list-type {
  margin-left: 10px;
  align-self: center;
}

.file_name {
  color: crimson;
}

.file_path {
  color: darkslategrey;
}
</style>
