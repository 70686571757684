<template id="view_giocatore">
	<section class="giocatore">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">

					<div class="row flex-items-xs-middle">
						<div class="col-4">

							<div class="text-uppercase color-rosso font-bold font-size-lg">
								{{osservato.cognome}} {{osservato.nome}}
							</div>
							<div class="text-muted">
								{{ osservato.last_carriera_sportiva &&
								osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}
							</div>

						</div>
						<div class="col-8 text-right">
							<ul class="list-inline mb-0">
								<li class="list-inline-item" v-if="osservato.valutazioni.length > 0"><a :href="'#/scouting/view/valutazione/' + osservato.id + '/' + osservato.valutazioni[0].id">Valutazioni</a></li>
								<li class="list-inline-item" v-if="osservato.valutazioni.length == 0 && auth.isUserEnable('scout_add_val')"><a :href="'#/scouting/nuova/valutazione/' + osservato.id">Valutazioni</a></li>
								<!--li class="list-inline-item" v-if="auth.isUserEnable('scout_appunti')"><a :href="'#/scouting/appunti/' + osservato.id">Appunti</a></li-->
								<!--li class="list-inline-item" v-if="auth.isUserEnable('scout_videotag') && !auth.isUserEnable('scout_not_skills')"><a :href="'#/scouting/videotag/giocatore/' + osservato.id">Videotag</a></li>
								<li class="list-inline-item" v-if="auth.isUserEnable('scout_partite')"><a :href="'#/scouting/partite/giocatore/' + osservato.id">Partite</a></li-->
								<li class="list-inline-item" v-if="false"><a href="#">Video/Foto</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="card h-100">
						<div class="card-body">
							<div class="row flex-items-xs-middle">
								<div class="col-4 offset-sm-1">
									<b-img :src="osservato.avatar_url" fluid :alt="osservato.cognome + ' ' + osservato.nome" :width="130"></b-img>
								</div>
								<div class="col-6 offset-sm-1">

									<table class="table table-sm table-giocatore">
										<tr> <td>Cognome</td> <td>{{ osservato.cognome }}</td> </tr>
										<tr> <td>Nome</td> <td>{{ osservato.nome }}</td> </tr>
										<tr> <td>Altezza</td> <td>{{ osservato.info_fisica ? osservato.info_fisica.altezza : '' }} cm</td> </tr>
										<tr> <td>Peso</td> <td>{{ osservato.info_fisica ? osservato.info_fisica.peso : '' }} kg</td> </tr>
										<tr> <td>Piede</td> <td>{{ osservato.giocatore ? osservato.giocatore.label_piede : '' }}</td> </tr>
										<tr> <td>Data di nascita</td> <td>{{osservato.nascita_data | formatDate}}</td> </tr>
										<tr> <td>Paese di nascita</td> <td><flag :country="osservato.nascita_paese ? osservato.nascita_paese.sigla : ''" :size="16"></flag> {{osservato.nascita_paese ? osservato.nascita_paese.paese : ''}}</td> </tr>
										<tr v-for="(agente, index) in agenti"> <td>Agente {{ index > 0 ? 'genitore ' + (index + 1) : '' }}</td> <td>{{ agente.cognome + ' ' + agente.nome }}</td> </tr>
										<tr v-if="osservato.osservato && osservato.osservato.scadenza_contratto"> <td>Scadenza contratto</td> <td>{{osservato.osservato.scadenza_contratto | formatDate }}</td> </tr>
										<tr> <td>Squadra</td> <td>{{ osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.squadra ? osservato.last_carriera_sportiva.squadra.officialName : ''}}<br/><b-img :src="osservato.last_carriera_sportiva.squadra.logo_url" :alt="osservato.last_carriera_sportiva.squadra.officialName" :width="60" v-if="osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.squadra"></b-img></td> </tr>
									</table>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col" v-if="!auth.isUserEnable('scout_not_skills')">
					<div class="card h-100">
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<b-tabs>
										<b-tab :title="item[$i18n.t('lang')]" v-for="(item, index) in skills" :key="item">
											<radarchart :radarid="'radar_' + index" :datachart="radardatachart(item)"></radarchart>
										</b-tab>
									</b-tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="!auth.isUserEnable('scout_not_skills')">
				<div class="col" v-for="cat in skills_tags">
					<tagscategorie class="h-100" :skills_tags="cat.fields" :title="cat.it" @selectSkillTags="selectSkillTags"></tagscategorie>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<card class="h-100" title="Carriera" comp="gcarriera" :params="{'carriera': carriera, 'count_rows': (osservato.valutazioni && osservato.valutazioni.length > 4 ? osservato.valutazioni.length - 1 : 3 )}"></card>
				</div>
				<div class="col">
					<card class="h-100" title="Valutazioni" comp="scoutvalutazioni" :params="{'valutazioni': osservato.valutazioni, 'giocatore_id': osservato.id}"></card>
				</div>
				<div class="col" v-if="!auth.isUserEnable('scout_not_skills')">
					<card class="h-100" title="Riassunto" comp="griassunto" :params="{'riassunti': riassunti}"></card>
				</div>
			</div>
		</div>
		<b-modal id="tagsModal" size="xl" ref="tagsModal" :title="title" class="fs-modal" @hidden="onHidden" @shown="onShow" ok-only ok-title="Chiudi">
			<b-row v-if="skill_tags_sel">
				<b-col cols="3" style="overflow-y:auto;height:550px;">
					<div v-for="(tag, index) in videotags">
						<thumbtag :tag="tag" :count="index+1" @selectTag="selectTag" @deleteTag="deleteTag"></thumbtag>
					</div>
				</b-col>
				<b-col cols="9">	
					<video
					id="videotags-wrapper"
					class="video-js vjs-default-skin vjs-16-9"
					controls
					data-setup="{}">
				</video>
				<b-pagination size="sm" align="center" :total-rows="videotags.length" v-model="count" :per-page="1" @change="selectPageTag"></b-pagination>
				<div v-if="tag_sel">
					<p class="m-1">{{ count }} - {{ skillName }}</p>
					<p class="m-1">{{ tag_sel.inizio }} / {{ tag_sel.fine }}</p>
					<p class="m-1">{{ tag_sel.peso }} / {{ tag_sel.tipo }} / {{ tag_sel.dove }}</p>
					<p class="m-1" v-if="tag_sel.video.utente">{{ tag_sel.video.utente.nomeCompleto }}</p>
					<p class="m-1">{{ tag_sel.nota }}</p>
				</div>
			</b-col>
		</b-row>
	</b-modal>
	<b-modal id="riassuntoModal" size="xl" ref="riassuntoModal" :title="titleRiassunto" class="fs-modal" @hidden="onHiddenRiassunto" @shown="onShowRiassunto" ok-only ok-title="Chiudi">
		<div>
			<video
			id="video-wrapper"
			class="video-js vjs-default-skin vjs-16-9"
			controls
			data-setup="{}">
		</video>
	</div>
</b-modal>
<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-playlist';

export default {
	data: function() {
		return {
			osservato: [],
			skills: [],	
			carriera: [],
			skills_tags: [],
			generic_tags: [],
			skill_tags_sel: null,
			videotags: [], 
			tag_sel: null,
			count: 0,
			title: '',
			reload: false,
			riassunti: [],
			playlist: [],
			titleRiassunto: '',
			errors_player: {
			    4: {
			      headline: 'Video non disponibile',
			      type: '',
			      message: 'La clip non è stata ancora generata.<br/>Riprova più tardi.',
			    }
			}
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,

		flag: 		require('../../layout/flag.vue').default,
		radarchart: 	require('../../layout/charts/radar.vue').default,
		card: 		require('../../layout/card.vue').default,
		tagscategorie: 		require('../../layout/tagscategorie.vue').default,
		thumbtag:   require('../../layout/thumbtag.vue').default,
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/scouting/view/giocatore/' + this.$route.params.id + '/old').then((response) => {
			if (response.ok) {
				this.osservato = response.data; 
				if(this.osservato.valutazioni && this.osservato.valutazioni.length > 0) {
					var last_val = this.osservato.valutazioni[0];
					if(this.osservato.last_carriera_sportiva.ruolo_ideale && this.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 && this.osservato.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
						this.skills = JSON.parse(last_val.fields.special);
					} else {
						this.skills = JSON.parse(last_val.fields.standard);
					}
				} else {
					this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
						if (response.ok) {
							if(this.osservato.last_carriera_sportiva.ruolo_ideale && this.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 && this.osservato.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
								this.skills = JSON.parse(response.data.special);
							} else {
								this.skills = JSON.parse(response.data.standard);
							}
						}
					}, (response) => {

					});  
				}

				this.$http.get(this.$store.state.apiEndPoint + '/videotags/giocatore/' + this.osservato.id).then((response) => {
					if (response.ok)
					{
						this.skills_tags = response.data.skills_tags;
						this.generic_tags = response.data.generic_tags;  
					}
				}, (response) => {
		            // error callback
		        });

				this.$http.get(this.$store.state.apiEndPoint + '/videotag/riassunto/giocatore/' + this.osservato.id).then((response) => {
					if (response.ok)
					{
						this.riassunti = response.data.riassunti; 
					}
				}, (response) => {
		            // error callback
		        });

				if(this.osservato.wyscout) {
					this.$http.get(this.$store.state.apiEndPoint + '/ws/get/career/player/' + this.osservato.wyscout.chiave).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.carriera = response.data;    
            }
        }, (response) => {
            // error callback
        });  
				}
			}
		}, (response) => {
            // error callback
        });

		this.$bus.$on('selectGenericTags', ($event) => {
			this.selectGenericTags(); 		
		});

		this.$bus.$on('selectRiassunto', ($event) => {
			this.selectRiassunto($event); 		
		});
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	}, 
	methods: {
		radardatachart: function(cat) {
			var array_skill_id = [];
			var array_voti_skill = [];
			var array_count_skill = [];
			var data = {
				labels: [],
				datasets: [{
					label: '',
					data: [],
					backgroundColor: '',
					borderColor: '',
					pointBackgroundColor: '',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: '',
				}]
			};
			data.datasets[0].label = cat[this.$i18n.t('lang')];
			data.datasets[0].backgroundColor = this.backgroudColorRadar(cat.id);
			data.datasets[0].borderColor = this.borderColorRadar(cat.id);
			data.datasets[0].pointBackgroundColor = data.datasets[0].borderColor;
			data.datasets[0].pointHoverBorderColor = data.datasets[0].borderColor;
			for (var j in cat.fields) {
				data.labels.push(cat.fields[j][this.$i18n.t('lang')]);
				var id_skill  = cat.fields[j]['id'];
				array_skill_id.push(id_skill);
				array_voti_skill[id_skill] = 0;
				array_count_skill[id_skill] = 0;
				for(var i in this.osservato.valutazioni) {
					var voto = 0;
					var count = 0;
					var voto2 = 0;
					var count2 = 0;
					var arrayVoto = [];
					var arrayCount = [];
					if(this.osservato.valutazioni[i].skills != "") {
						var skill_val = JSON.parse(this.osservato.valutazioni[i].skills);
						var cat_skills = skill_val[cat.id];
						for(var item in cat_skills) {
							if(item == id_skill) {
								var item2 = cat_skills[item];
								if(item2 instanceof Object) {

									for(var item3 in item2) {
										var item4 = item2[item3];

										if(item4 instanceof Object) {
											voto2 = 0;
											count2 = 0;
											for(var item5 in item4) {
												if(item4[item5] > 0) {
													voto2+= item4[item5];
													count2++;
												}
											}
											arrayVoto.push(voto2);
											arrayCount.push(count2);
										} else if(item4 > 0) {
											voto += item4;
											count++;
										}
									}
									if(arrayVoto.length > 0) {
										var votoTot = 0; 
										var countTot = 0;
										if(voto > 0) {
											votoTot = voto/count;
											countTot++;
										}
										for(var index in arrayVoto) {
											if(arrayVoto[index] > 0) {
												votoTot += arrayVoto[index]/arrayCount[index];
												countTot++;
											}            
										}
										if(votoTot > 0) {
											voto = votoTot/countTot;
										}
									} else if(voto > 0) {
										voto = voto/count;
									}
									if(voto > 0) {
										array_voti_skill[id_skill] +=  voto;
										array_count_skill[id_skill] ++;
									}
								} else {
									if(item2 > 0) {
										array_voti_skill[id_skill] +=  item2;
										array_count_skill[id_skill] ++;
									}
								}
								break;
							}
						}
					}
				}
			}
			for(var index in array_skill_id) {
				var id = array_skill_id[index];
				var voto = array_voti_skill[id];
				if(voto > 0) {
					voto = voto / array_count_skill[id];
					voto = Math.round(voto * 100) / 100;
				}
				data.datasets[0].data.push(voto);
			}
			return data;	
		},

		backgroudColorRadar: function(cat_id) {
			if(cat_id == -1) {
				return "rgba(255, 0, 89, 0.2)";
			} else if(cat_id == -2) {
				return "rgba(255, 219, 0, 0.2)";
			} else if(cat_id == -3) {
				return "rgba(46, 200, 91, 0.2)";
			} else if(cat_id == -4) {
				return "rgba(0, 0, 255, 0.2)";
			} else if(cat_id == -5) {
				return "rgba(153, 0, 255, 0.2)";
			} else if(cat_id == -6) {
				return "rgba(255, 153, 0, 0.2)";
			}
			return "rgba(168, 5, 50, 0.2)";	
		},

		borderColorRadar: function(cat_id) {
			if(cat_id == -1) {
				return "rgb(255, 0, 89)";
			} else if(cat_id == -2) {
				return "rgb(255, 219, 0)";
			} else if(cat_id == -3) {
				return "rgb(46, 200, 91)";
			} else if(cat_id == -4) {
				return "rgb(0, 0, 255)";
			} else if(cat_id == -5) {
				return "rgb(153, 0, 255)";
			} else if(cat_id == -6) {
				return "rgb(255, 153, 0)";
			}
			return "rgb(168, 5, 50)";	
		},
		selectSkillTags(skill_tags, title) {
			this.title = title;	
			this.videotags = [];
			this.skill_tags_sel = skill_tags;
			if(this.skill_tags_sel.fields) {
				for(var i in this.skill_tags_sel.fields) {
					var item = this.skill_tags_sel.fields[i];
					if(item.fields) {
						for(var j in item.fields) {
							var item2 = item.fields[j];
							if(item2.videotags) {
								this.videotags.push(...item2.videotags);
							}
						}
					} else {
						if(item.videotags) {
							this.videotags.push(...item.videotags);
						}
					}
				}
			} else {
				if(this.skill_tags_sel.videotags) {
					this.videotags = this.skill_tags_sel.videotags;
				}
			}
			var idx_tags = [];
			var tags = [];
			for(var i in this.videotags) {
				var tag = this.videotags[i];
				if(idx_tags.indexOf(tag.id) == -1) {
					tags.push(tag);
					idx_tags.push(tag.id);
				}
			}
			this.videotags = tags;
			this.tag_sel = this.videotags[0];
			this.count = 1;
			this.playlist = [];
			for(var i in this.videotags) {
				var videotag = this.videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.tagsModal.show();
		},
		selectGenericTags() {
			if(this.generic_tags.length > 0) {
				this.title = 'Azione generica';	
				this.videotags = [];
				this.skill_tags_sel = 'Azione generica';
				this.videotags = this.generic_tags;
				this.tag_sel = this.videotags[0];
				this.count = 1;
				this.playlist = [];
				for(var i in this.videotags) {
					var videotag = this.videotags[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
				this.$refs.tagsModal.show();
			}
		},
		selectTag(tag, count) {
			this.tag_sel = tag;
			this.count = count;
			this.$video_tags.playlist.currentItem(count - 1); 
		},
		selectPageTag(page) {
			this.tag_sel = this.videotags[page - 1];
			this.$video_tags.playlist.currentItem(page - 1);    
		},
		autoSelectTag() {
			this.tag_sel = this.videotags[this.$video_tags.playlist.currentIndex()];
			this.count = (this.$video_tags.playlist.currentIndex() + 1);
		},
		deleteTag(tag) {
			if(confirm('Sei sicuro di voler eliminare il tag?')) {
				this.$http.delete(this.$store.state.apiEndPoint + '/tag/' + tag.id).then((response) => {
					if (response.ok)
					{
						if(this.tag_sel.id == tag.id) {
							this.tag_sel = null;
						}
						var index = this.videotags.indexOf(tag);	
						this.videotags.splice(index, 1);
						this.reload = true; 
						if(this.videotags.length == 0) {
							this.$refs.tagsModal.hide();
						}
					}
				}, (response) => {
		            // error callback
		        });
			}		
		},
		onShow() {
			if(!this.$video_tags) {
				this.$video_tags = videojs('videotags-wrapper');
				this.$video_tags.on('playlistitem', this.autoSelectTag);

				this.$video_tags.errors({
				    "errors": this.errors_player,
				});
			}
			this.$video_tags.playlist(this.playlist);
			this.$video_tags.playlist.autoadvance(0);
			this.$video_tags.play();
		},
		onHidden() {
			this.$video_tags.playlist([]);
			this.$video_tags.pause();
			this.$video_tags.reset();
			this.$video_tags.dispose();
			this.$video_tags = null;
			this.tag_sel = null;
			if(this.reload) {
				this.$http.get(this.$store.state.apiEndPoint + '/videotags/giocatore/' + this.osservato.id).then((response) => {
					if (response.ok)
					{
						this.skills_tags = response.data.skills_tags;
						this.generic_tags = response.data.generic_tags;  
						this.reload = false; 
					}
				}, (response) => {
		            // error callback
		        });
			}
		},
		selectRiassunto(index) {
			var videotags = this.riassunti[index].videotags;
			this.playlist = [];
			this.titleRiassunto = 'Video riassunto di ' + this.riassunti[index].utente.nomeCompleto;
			for(var i in videotags) {
				var videotag = videotags[i];
				var source = {
					sources: [{
						src: this.$store.state.apiEndPoint + '/videotag/clip/' + videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			}
			this.$refs.riassuntoModal.show();
		},
		onShowRiassunto() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			this.$video.play();
		},
		onHiddenRiassunto() {
			this.$video.playlist([]);
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;	
		},  
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
		if(this.$video_tags) {
			this.$video_tags.dispose();
			this.$video_tags = null;
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		skillName: function() {
			if(this.tag_sel) {
				var nome = "";
				for(var i in this.tag_sel.azioni) {
					var azione = this.tag_sel.azioni[i];
					if(nome != "") {
						nome += " - ";
					}
					nome += azione.nome_azione;
				}
				if(nome == "") {
					return "Azione generica";
				}
				return nome;
			}
			return "Azione generica";
		},

		agenti: function() {
			var persone = [];
			for(var i in this.osservato.relazioni) {
				var item = this.osservato.relazioni[i];
				if(item.persona_relazioni_glo_id == 4) {
					persone.push(item.relazionante);
				}
			}
			return persone;
		},
	},
}
</script>
