<template>
  <div class="no-outline" tabindex="1000" @keyup.delete="shouldRemoveObject">
    <canvas id="canvas" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import fabric, {
  drawCurvedArrow,
  drawLine,
  setHeadAngle,
  findAngle,
  drawFlatLine,
  drawWavyLineWithArrow,
  drawPolygon,
  polygonPositionHandler,
  actionHandler,
  anchorWrapper,
} from '../../../lib/fabric';

export default {
  data: () => ({
    line: null,
    isDown: null,
    prevActive: 0,
    layer: 0,
  }),

  computed: mapState('exercises', ['canvas', 'activeObject']),

  watch: {
    'canvas.background': function canvasBackground() {
      if (this.canvas.background) this.setCanvasBackground(this.canvas.background);
    },

    'canvas.color.hex': function color() {
      this.$canvas.freeDrawingBrush.color = this.canvas.color.hex;
      const activeObject = this.$canvas.getActiveObject();
      if (activeObject) {
        if (activeObject.type === 'polygon') {
          activeObject.fill = this.canvas.color.hex;
          activeObject.stroke = this.canvas.color.hex;
          this.$canvas.renderAll();
        } else if(activeObject.type === 'wavy-line-with-arrow') {
          activeObject.stroke = this.canvas.color.hex;
          this.$canvas.renderAll();
        } 
      }
    },

    'canvas.opacity': function color() {
      const activeObject = this.$canvas.getActiveObject();
      if (activeObject) {
        if (activeObject.type === 'polygon') {
          activeObject.opacity = this.canvas.opacity;
          this.$canvas.renderAll();
        }
      }
    },

    'canvas.drawingMode': function drawingMode() {
      if (this.canvas.drawingMode) {
        this.$root.$emit('canvas::clearSelection');
        this.$store.commit('exercises/removeActiveObject');
      }
      this.$canvas.set('isDrawingMode', this.canvas.drawingMode);
    },

    'canvas.lineWidth': function lineWidth() {
      this.$canvas.freeDrawingBrush.width = this.canvas.lineWidth;
    },

    'canvas.wavy': function toggleWavy() {
      if (this.canvas.wavy) {
        this.$root.$emit('canvas::clearSelection');
        this.$store.commit('exercises/removeActiveObject');
      }
      this.changeObjSelection(!this.canvas.wavy);
    },
  },

  mounted() {
    /* manage height and scale canvas */
    var w = document.getElementById('field').offsetWidth;
    var h = w/2.13;

    this.$canvas = new fabric.Canvas('canvas');
    this.$canvas.setHeight(h);
    this.$canvas.setWidth(w);

    this.$canvas.on('object:moving', this.onObjectMoving);
    this.$canvas.on('selection:created', this.onObjectSelected);
    this.$canvas.on('before:selection:cleared', this.onBeforeSelectionCleared);
    this.$canvas.on('mouse:up', this.onMouseUp);
    this.$canvas.on('mouse:over', this.onMouseOver);
    this.$canvas.on('mouse:out', this.onMouseOut);

    this.$canvas.on('mouse:down', this.onMouseDown);
    this.$canvas.on('mouse:move', this.onMouseMove);

    this.$canvas.on('mouse:dblclick', this.onMouseDblclick);

    this.$root.$on('canvas::addObject', this.addObject);
    this.$root.$on('canvas::deleteObjects', this.shouldRemoveObject);
    this.$root.$on('canvas::insertText', this.insertText);
    this.$root.$on('canvas::insertCurvedArrow', this.insertCurvedArrow);
    this.$root.$on('canvas::insertLine', pointer => this.insertLine(pointer));
    this.$root.$on('canvas::insertDashedLine', pointer => this.insertLine(pointer, true));
    this.$root.$on('canvas::insertTriangle', this.insertTriangle);
    this.$root.$on('canvas::insertRect', this.insertRect);
    this.$root.$on('canvas::insertFlatLine', this.insertFlatLine);
    this.$root.$on('canvas::insertFlatDashedLine', this.insertFlatDashedLine);
    this.$root.$on('canvas::export', this.export);
    this.$root.$on('canvas::clearSelection', this.clearSelection);
    this.$root.$on('canvas::cloneObjects', this.cloneObjects);

    if (this.canvas.export) {
      this.loadCanvasFromJson();
    } else if (!this.$canvas.backgroundImage && this.canvas.background) {
      this.setCanvasBackground(this.canvas.background);
    }
  },

  beforeDestroy() {
    this.$canvas.dispose();
    this.$root.$off('canvas::addObject');
    this.$root.$off('canvas::deleteObjects');
    this.$root.$off('canvas::insertText');
    this.$root.$off('canvas::insertCurvedArrow');
    this.$root.$off('canvas::insertLine');
    this.$root.$off('canvas::insertDashedLine');
    this.$root.$off('canvas::insertTriangle');
    this.$root.$off('canvas::insertRect');
    this.$root.$off('canvas::export');
    this.$root.$off('canvas::clearSelection');
    this.$root.$off('canvas::cloneObjects');
  },

  methods: {

    addObject(object, coords) {
      var w = document.getElementsByClassName('canvas-container')[0].offsetWidth;
      var h = document.getElementsByClassName('canvas-container')[0].offsetHeight;

      const imageUrl = typeof object === 'string' ? object : object.image_url;

      var scaleObj = ( w * 1 ) / 720;

      fabric.util.loadImage(imageUrl, (imgData) => {
        const img = new fabric.ObjectImage(imgData, {
          left: coords ? coords.x : 100,
          top: coords ? coords.y : 100,
          scaleX: scaleObj,
          scaleY: scaleObj,
        });

        img.setControlsVisibility({
          mt: false,
          mb: false,
          mr: false,
          ml: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
        });

        this.$canvas.add(img);
      }, null, { crossOrigin: 'Anonymous' });
    },

    setCanvasBackground(url) {

      fabric.util.loadImage(url, (imgData) => {
        const img = new fabric.Image(imgData, {
          originY: 'top',
          originX: 'left',
        });

        /* manage height and scale canvas */

        var canvas_container_w = document.getElementsByClassName('canvas-container')[0].offsetWidth;
        var canvas_container_h = document.getElementsByClassName('canvas-container')[0].offsetHeight;
        var scale = ( 1 * canvas_container_w ) / 720;

        img.set({
          scaleX: scale,
          scaleY: scale,
        });
        this.$canvas.setBackgroundImage(img, this.$canvas.renderAll.bind(this.$canvas));
      }, null, { crossOrigin: 'Anonymous' });
    },

    loadCanvasFromJson() {

      const data = JSON.parse(this.canvas.export);

      data.objects = data.objects.map((object) => {
        const result = object;

        /* gestione vecchie curve */
        if(result.type == 'bezier-path') {
          if(result.head == null && result.tail == null && result.centerPoint == null) {
            result.tail = 'tail-' + result.groupId;
            result.head = 'head-' + result.groupId;
            result.centerPoint = 'center-point-' + result.groupId;
            const tail = data.objects.find((item) => item.id == 'tail-' + result.groupId);
            const head = data.objects.find((item) => item.id == 'head-' + result.groupId);
            const centerPoint = data.objects.find((item) => item.id == 'center-point-' + result.groupId);
            result.path[0][1] = tail.left;
            result.path[0][2] = tail.top;
            result.path[1][1] = centerPoint.left;
            result.path[1][2] = centerPoint.top;
            result.path[1][3] = head.left;
            result.path[1][4] = head.top;
          }
        }

        /* manage reposition automatic object */
        var canvas_container_w = document.getElementsByClassName('canvas-container')[0].offsetWidth;
        var canvas_container_h = document.getElementsByClassName('canvas-container')[0].offsetHeight;

        var resolution_w = document.getElementById('resolution_w').value;
        var resolution_h = document.getElementById('resolution_h').value;

        var top  = result.top;
        var left = result.left;

        var scaleObj = ( canvas_container_w * 1 ) / 720;
        if( result.type == 'arrow-line' || result.type == 'wavy-line-with-arrow'){
          if( resolution_w != '' && resolution_h != '' ){
            if( resolution_w != canvas_container_w && canvas_container_h != resolution_h ){
              result.x1     = ( canvas_container_w  * result.x1 ) / resolution_w;
              result.x2     = ( canvas_container_w  * result.x2 ) / resolution_w;
              result.y1     = ( canvas_container_h  * result.y1 ) / resolution_h;
              result.y2     = ( canvas_container_h  * result.y2 ) / resolution_h;
            }
          }else{
            result.x1     = ( canvas_container_w  * result.x1 ) / 720;
            result.x2     = ( canvas_container_w  * result.x2 ) / 720;
            result.y1     = ( canvas_container_h  * result.y1 ) / 338;
            result.y2     = ( canvas_container_h  * result.y2 ) / 338;
          }
        }

        /* calcolo top and left scalando su risoluzioni */
        if( resolution_w != '' && resolution_h != '' ){
          if( resolution_w != canvas_container_w && canvas_container_h != resolution_h ){
            top     = ( canvas_container_h  * result.top ) / resolution_h;
            left    = ( canvas_container_w  * result.left ) / resolution_w;
          }
        }else{
            top     = ( canvas_container_h  * result.top ) / 338;
            left    = ( canvas_container_w  * result.left ) / 720;
        }

        /* scale for obj */
        if( result.type == 'object-image' ) {
          result.scaleX = scaleObj;
          result.scaleY = scaleObj;
        }
        result.top    = top;
        result.left   = left;


        if (result.type === 'image') {
            result.crossOrigin = 'anonymous';
          }
          return result;
        });

        /* load bkg */
        var canvas_container_w = document.getElementsByClassName('canvas-container')[0].offsetWidth;
        var canvas_container_h = document.getElementsByClassName('canvas-container')[0].offsetHeight;

        var scale = ( 1 * canvas_container_w ) / 720;
        if (data.backgroundImage) {
          data.backgroundImage.crossOrigin = 'anonymous';
          data.backgroundImage.scaleX = scale;
          data.backgroundImage.scaleY = scale;
        }

        this.$canvas.loadFromJSON(data, this.$canvas.renderAll.bind(this.$canvas));
    },

    insertText(pointer) {
      const textObj = new fabric.IText('', {
        left: pointer.x,
        top: pointer.y,
        fill: this.canvas.color.hex,
        fontSize: 20,
        cornerSize: 8,
        cornerStyle: 'circle',
        cornerColor: 'black',
        borderColor: 'black',
        transparentCorners: false,
        padding: 0,
        borderDashArray: [3, 3],
      });

      this.$canvas.add(textObj);
      this.$canvas.setActiveObject(textObj);
      textObj.enterEditing();
      this.$canvas.renderAll();
    },

    insertCurvedArrow(pointer) {
      drawCurvedArrow(this.canvas.color.hex, this.$canvas, pointer);
    },

    insertLine(pointer, dashed = false) {
      drawLine(this.$canvas, this.canvas.color.hex, dashed, pointer);
    },

    insertTriangle(pointer) {
      drawPolygon(this.$canvas, this.canvas.color.hex, pointer, 'triangle', this.canvas.opacity);
    },

    insertRect(pointer) {
      drawPolygon(this.$canvas, this.canvas.color.hex, pointer, 'rect', this.canvas.opacity);
    },

    insertFlatLine(pointer) {
      drawFlatLine(this.$canvas, this.canvas.color.hex, false, pointer);
    },

    insertFlatDashedLine(pointer) {
      drawFlatLine(this.$canvas, this.canvas.color.hex, true, pointer);
    },

    export() {
      this.$store.commit(
        'exercises/export',
        JSON.stringify(
          this.$canvas.toDatalessJSON([
            'originX',
            'originY',
            'width',
            'height',
            'toBeParsed',
            'viewboxTransform',
            'crossOrigin',
            'svgUid',
            'left',
            'top',
            'cornerSize',
            'cornerStyle',
            'cornerColor',
            'borderColor',
            'transparentCorners',
            'padding',
            'borderDashArray',
            'oCoords',
            'aCoords',
            'sourcePath',
            '_controlsVisibility',
            'scaleX',
            'scaleY',
            'objectCaching',
            'hasBorder',
            'hasControls',
            'hasRotatingPoint',
            'perPixelTargetFind',
            'noScaleCache',
            'strokeUniform',
          ]),
        ),
      );
      this.$store.commit(
        'exercises/exportImage',
        this.$canvas.toDataURL({
          format: 'png',
          quality: 1,
          multiplier: 1,
          enableRetinaScaling: true,
        }),
      );
    },

    shouldRemoveObject() {
      this.$canvas.getActiveObjects().forEach(obj => {
        if (obj.groupId) {
          this.$canvas.remove(...this.$canvas.getObjects().filter(o => o.groupId === obj.groupId));
        } else {
          this.$canvas.remove(obj);
        }
      });
    },

    cloneObjects() {
      if (this.$canvas.getActiveObject()) {
        this.$canvas.getActiveObject().clone(
          clonedObj => {
            this.$canvas.discardActiveObject();
            clonedObj.set({ left: clonedObj.left + 50, top: clonedObj.top, evented: true });
            const newIdGroups = [];
            const typeGroups = [];
            if (clonedObj.type === 'activeSelection') {
              clonedObj.canvas = this.$canvas;
              const groupIds = [];
              clonedObj.forEachObject(obj2 => {
                if (obj2.groupId) {
                  if (!groupIds.includes(obj2.groupId)) {
                    groupIds.push(obj2.groupId);
                    if (obj2.name === 'arrowStart' || obj2.name === 'arrowEnd') {
                      const line = this.$canvas
                        .getObjects()
                        .find(o => o.id === `line-${obj2.groupId}`);
                      const arrowStart = this.$canvas
                        .getObjects()
                        .find(o => o.id === `head-${obj2.groupId}`);
                      const arrowEnd = this.$canvas
                        .getObjects()
                        .find(o => o.id === `tail-${obj2.groupId}`);
                      const newIdGroup = drawLine(
                        this.$canvas,
                        line.fill,
                        line.strokeDashArray || false,
                        null,
                        {
                          x1: arrowEnd.left + 50,
                          y1: arrowEnd.top,
                          x2: arrowStart.left + 50,
                          y2: arrowStart.top,
                        },
                        arrowStart.angle,
                      );
                      newIdGroups.push(newIdGroup);
                      typeGroups.push('line');
                    } else if (
                      obj2.name === 'head' ||
                      obj2.name === 'tail' ||
                      obj2.name === 'centerPoint'
                    ) {
                      const curve = this.$canvas
                        .getObjects()
                        .find(o => o.id === `bezier-arrow-${obj2.groupId}`);
                      const head = this.$canvas
                        .getObjects()
                        .find(o => o.id === `head-${obj2.groupId}`);
                      const tail = this.$canvas
                        .getObjects()
                        .find(o => o.id === `tail-${obj2.groupId}`);
                      const centerPoint = this.$canvas
                        .getObjects()
                        .find(o => o.id === `center-point-${obj2.groupId}`);
                      const newIdGroup = drawCurvedArrow(curve.stroke, this.$canvas, null, {
                        hx: head.left + 50,
                        hy: head.top,
                        tx: tail.left + 50,
                        ty: tail.top,
                        cx: centerPoint.left + 50,
                        cy: centerPoint.top,
                      });
                      newIdGroups.push(newIdGroup);
                      typeGroups.push('curve');
                    }
                  }
                } else {
                  this.$canvas.add(obj2);
                }
              });
            } else if (clonedObj.groupId) {
              if (clonedObj.name === 'arrowStart' || clonedObj.name === 'arrowEnd') {
                const line = this.$canvas
                  .getObjects()
                  .find(o => o.id === `line-${clonedObj.groupId}`);
                const arrowStart = this.$canvas
                  .getObjects()
                  .find(o => o.id === `head-${clonedObj.groupId}`);
                const arrowEnd = this.$canvas
                  .getObjects()
                  .find(o => o.id === `tail-${clonedObj.groupId}`);
                drawLine(
                  this.$canvas,
                  line.fill,
                  line.strokeDashArray || false,
                  null,
                  {
                    x1: arrowEnd.left + 50,
                    y1: arrowEnd.top,
                    x2: arrowStart.left + 50,
                    y2: arrowStart.top,
                  },
                  arrowStart.angle,
                );
              } else if (
                clonedObj.name === 'head' ||
                clonedObj.name === 'tail' ||
                clonedObj.name === 'centerPoint'
              ) {
                const curve = this.$canvas
                  .getObjects()
                  .find(o => o.id === `bezier-arrow-${clonedObj.groupId}`);
                const head = this.$canvas
                  .getObjects()
                  .find(o => o.id === `head-${clonedObj.groupId}`);
                const tail = this.$canvas
                  .getObjects()
                  .find(o => o.id === `tail-${clonedObj.groupId}`);
                const centerPoint = this.$canvas
                  .getObjects()
                  .find(o => o.id === `center-point-${clonedObj.groupId}`);
                drawCurvedArrow(curve.stroke, this.$canvas, null, {
                  hx: head.left + 50,
                  hy: head.top,
                  tx: tail.left + 50,
                  ty: tail.top,
                  cx: centerPoint.left + 50,
                  cy: centerPoint.top,
                });
              }
            } else {
              this.$canvas.add(clonedObj);
            }
            clonedObj.setCoords();
            this.$canvas.setActiveObject(clonedObj);

            const multiSelection = [];
            for (let i = 0; i < newIdGroups.length; i += 1) {
              if (typeGroups[i] === 'line') {
                const arrowStart = this.$canvas
                  .getObjects()
                  .find(o => o.id === `head-${newIdGroups[i]}`);
                const arrowEnd = this.$canvas
                  .getObjects()
                  .find(o => o.id === `tail-${newIdGroups[i]}`);
                multiSelection.push(arrowStart);
                multiSelection.push(arrowEnd);
              } else if (typeGroups[i] === 'curve') {
                const head = this.$canvas.getObjects().find(o => o.id === `head-${newIdGroups[i]}`);
                const tail = this.$canvas.getObjects().find(o => o.id === `tail-${newIdGroups[i]}`);
                const centerPoint = this.$canvas
                  .getObjects()
                  .find(o => o.id === `center-point-${newIdGroups[i]}`);
                multiSelection.push(head);
                multiSelection.push(tail);
                multiSelection.push(centerPoint);
              }
            }
            if (multiSelection.length > 0) {
              this.$canvas.getActiveObjects().forEach(obj => {
                if (!obj.groupId) {
                  multiSelection.push(obj);
                }
              });
              this.$canvas.discardActiveObject();
              const groupSelection = new fabric.ActiveSelection(multiSelection, {
                canvas: this.$canvas,
              });
              this.$canvas.setActiveObject(groupSelection);
            }
            this.$canvas.requestRenderAll();
          },
          ['strokeUniform'],
        );
      }
    },

    findObjectById(id) {
      return this.$canvas.getObjects().find(obj => obj.id === id);
    },

    onObjectSelected(evt) {
      const activeObject = evt.target;
      if (!activeObject) return;

      this.$store.commit('exercises/removeActiveObject');

      if (activeObject.name === 'tail' || activeObject.name === 'head') {
        const centerPoint = this.findObjectById(activeObject.centerPoint);
        centerPoint.animate('opacity', 1, {
          duration: 200,
          onChange: this.$canvas.renderAll.bind(this.$canvas),
        });
        centerPoint.selectable = true;
      } else if (activeObject.type === 'activeSelection') {
        let lockScaling = false;
        activeObject.forEachObject(obj => {
          if (obj.pointType === 'arrowStart' || obj.pointType === 'arrowEnd') {
            lockScaling = true;
          }
        });
        activeObject.set({
          cornerSize: 8,
          cornerStyle: 'circle',
          cornerColor: 'black',
          borderColor: 'black',
          transparentCorners: false,
          padding: 0,
          borderDashArray: [3, 3],
          lockScalingX: lockScaling,
          lockScalingY: lockScaling,
        });
      } else {
        activeObject.set({
          cornerSize: 8,
          cornerStyle: 'circle',
          cornerColor: 'black',
          borderColor: 'black',
          transparentCorners: false,
          padding: 0,
          borderDashArray: [3, 3],
        });
      }
    },

    onMouseOver(evt) {
      const activeObject = evt.target;
      if (
        !activeObject ||
        !['arrowStart', 'arrowEnd', 'centerPoint', 'head', 'tail'].includes(activeObject.name)
      ) {
        return;
      }

      activeObject.set({
        scaleX: 2,
        scaleY: 2,
      });

      this.$canvas.renderAll();
    },

    onMouseOut(evt) {
      const activeObject = evt.target;
      if (
        !activeObject ||
        !['arrowStart', 'arrowEnd', 'centerPoint', 'head', 'tail'].includes(activeObject.name)
      ) {
        return;
      }

      activeObject.set({
        scaleX: 1,
        scaleY: 1,
      });

      this.$canvas.renderAll();
    },

    onBeforeSelectionCleared(evt) {
      const activeObject = evt.target;
      if (['tail', 'head', 'centerPoint'].includes(activeObject.name)) {
        const target =
          activeObject.name === 'centerPoint'
            ? activeObject
            : this.findObjectById(activeObject.centerPoint);

        if (!target) return;

        target.animate('opacity', 0, {
          duration: 200,
          onChange: this.$canvas.renderAll.bind(this.$canvas),
        });
        target.selectable = false;
      }
    },

    onObjectMoving(evt) {
      const activeObject = evt.target;

      const line = this.findObjectById(activeObject.line);
      const head = this.findObjectById(activeObject.head);
      const centerPoint = this.findObjectById(activeObject.centerPoint);
      const tail = this.findObjectById(activeObject.tail);

      if (activeObject.name === 'centerPoint') {
        if (line) {
          line.path[1][1] = activeObject.left;
          line.path[1][2] = activeObject.top;
        }

        if (head) {
          setHeadAngle(head, activeObject);
        }
      } else if (activeObject.name === 'tail') {
        if (line) {
          line.path[0][1] = activeObject.left;
          line.path[0][2] = activeObject.top;
        }

        if (head && centerPoint) {
          setHeadAngle(head, centerPoint);
        }
      } else if (activeObject.name === 'head') {
        if (line) {
          line.path[1][3] = activeObject.left;
          line.path[1][4] = activeObject.top;
        }

        if (centerPoint) {
          setHeadAngle(activeObject, centerPoint);
        }
      } else if (activeObject.pointType === 'arrowStart' || activeObject.pointType === 'arrowEnd') {
        if (activeObject.pointType === 'arrowStart') {
          line.set({
            x1: activeObject.left,
            y1: activeObject.top,
            x2: tail.left,
            y2: tail.top,
          });
        } else {
          line.set({
            x1: head.left,
            y1: head.top,
            x2: activeObject.left,
            y2: activeObject.top,
          });
        }

        // eslint-disable-next-line no-underscore-dangle
        line._setWidthHeight();

        const x = line.get('x2') - line.get('x1');
        const y = line.get('y2') - line.get('y1');
        const angle = findAngle(x, y);

        if (activeObject.pointType === 'arrowStart') {
          activeObject.set('angle', angle - 90);
        } else {
          head.set('angle', angle - 90);
        }
      }
    },

    clearSelection() {
      this.$canvas.discardActiveObject();
      this.$canvas.renderAll();
    },

    onMouseUp(evt) {
      this.isDown = false;
      if (this.canvas.wavy) {
        if (this.line) {
          this.line.setCoords();
        }
        this.$canvas.requestRenderAll();
      }

      const activeObject = evt.target;

      if (!activeObject) {
        if (this.activeObject) {
          if (typeof this.activeObject === 'string') {
            this.$root.$emit(`canvas::insert${this.activeObject}`, evt.pointer);
          } else {
            this.addObject(this.activeObject, evt.pointer);
          }
        }
      } else if (activeObject.name === 'bezierArrow') {
        this.$store.commit('exercises/removeActiveObject');

        const centerPoint = this.findObjectById(activeObject.centerPoint);
        centerPoint.animate('opacity', 1, {
          duration: 200,
          onChange: this.$canvas.renderAll.bind(this.$canvas),
        });
        centerPoint.selectable = true;
      } else if (activeObject.type === 'polygon') {
        const lastControl = activeObject.points.length - 1;
        activeObject.hasBorders = false;
        activeObject.objectCaching = false;
        activeObject.perPixelTargetFind = true;
        activeObject.hasRotatingPoint = false;
        activeObject.setControlsVisibility({
          bl: false,
          br: false,
          mb: false,
          ml: false,
          mr: false,
          mt: false,
          tl: false,
          tr: false,
          mtr: false,
        });
        activeObject.cornerStyle = 'circle';
        activeObject.cornerColor = 'rgba(255,255,255,0.5)';
        activeObject.controls = activeObject.points.reduce((acc, point, index) => {
          acc[`p${index}`] = new fabric.Control({
            positionHandler: polygonPositionHandler,
            actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
            actionName: 'modifyPolygon',
            pointIndex: index,
          });
          return acc;
        }, {});
      } else if (activeObject.type === 'activeSelection') {
        activeObject.forEachObject(obj => {
          if (obj.pointType === 'arrowStart' || obj.pointType === 'arrowEnd') {
            const line = this.findObjectById(obj.line);
            const head = this.findObjectById(obj.head);
            const tail = this.findObjectById(obj.tail);
            if (obj.pointType === 'arrowStart') {
              line.set({
                x1: obj.left,
                y1: obj.top,
                x2: tail.left,
                y2: tail.top,
              });
            } else {
              line.set({
                x1: head.left,
                y1: head.top,
                x2: obj.left,
                y2: obj.top,
              });
            }
            this.clearSelection();
          } else if (obj.name === 'tail' || obj.name === 'head' || obj.name === 'centerPoint') {
            const line = this.findObjectById(obj.line);
            const head = obj.name === 'head' ? obj : this.findObjectById(obj.head);
            const centerPoint =
              obj.name === 'centerPoint' ? obj : this.findObjectById(obj.centerPoint);
            const tail = obj.name === 'tail' ? obj : this.findObjectById(obj.tail);

            line.path[1][1] = centerPoint.left;
            line.path[1][2] = centerPoint.top;

            line.path[0][1] = tail.left;
            line.path[0][2] = tail.top;

            line.path[1][3] = head.left;
            line.path[1][4] = head.top;

            setHeadAngle(head, centerPoint);
            this.clearSelection();
          }
        });
      }
      if (activeObject && ['tail', 'head', 'centerPoint'].includes(activeObject.name)) {
        const target = this.findObjectById(activeObject.line);
        if (target.name === 'bezierArrow') {
          const opts = {
            id: target.id,
            groupId: target.groupId,
            stroke: target.stroke,
            tail: target.tail,
            head: target.head,
            centerPoint: target.centerPoint,
            fill: '',
          };

          this.$canvas.remove(target);
          const newPath = new fabric.BezierPath(target.path, opts);
          this.$canvas.add(newPath);
        }
      }
    },

    changeObjSelection(value) {
      this.$canvas.selection = value;
      this.$canvas.forEachObject(obj => {
        /* eslint no-param-reassign: "error" */
        obj.selectable = value;
        obj.hoverCursor = value ? 'move' : 'default';
      });
      this.$canvas.requestRenderAll();
    },

    onMouseDown(options) {
      this.isDown = true;
      if (this.canvas.wavy) {
        const pointer = this.$canvas.getPointer(options.e);
        const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        this.line = this.selectLine(points);
        this.$canvas.add(this.line);
      }
    },

    onMouseMove(options) {
      if (this.canvas.wavy) {
        if (!this.isDown) return;
        const pointer = this.$canvas.getPointer(options.e);
        this.line.set({
          x2: pointer.x,
          y2: pointer.y,
        });
        this.$canvas.renderAll();
      }
    },

    onMouseDblclick(options) {
      const pointer = this.$canvas.getPointer(options.e);
      const active = this.$canvas.getActiveObject();
      const self = this;
      const targets = this.$canvas
        .getObjects()
        .filter(
          obj =>
            obj.containsPoint(pointer) &&
            !self.$canvas.isTargetTransparent(obj, pointer.x, pointer.y),
        );

      this.$canvas.discardActiveObject();

      if (this.prevActive !== active) {
        this.layer = Math.max(targets.length - 2, 0);
      } else {
        this.layer -= 1;
        this.layer = this.layer < 0 ? Math.max(targets.length - 2, 0) : this.layer;
      }

      const obj = targets[this.layer];

      if (obj) {
        this.prevActive = obj;
        obj.bringToFront();
        this.$canvas.setActiveObject(obj).renderAll();
      }
    },

    selectLine(points) {
      return drawWavyLineWithArrow(points, this.canvas.color.hex, this.canvas.dashed);
    },
  },
};
</script>

<style lang="scss">
.canvas-container {
  margin: 0 auto;
}
</style>
