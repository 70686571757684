export const ROUTES_ANALYSIS = [
    {
        path: '/analysis/dashboard',
        name: 'analysis_dashboard',
        component: require('../components/pages/analysis/dashboard.vue').default,
        meta: {
            module: 'analysis',

            label: 'Analysis',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            enableRosaTeam: true,
            enableSeason: true,
            permesso: 'analysis',
            logCategory: true
        }
    },
];
