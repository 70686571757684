<template>
	<div class="text-center font-weight-bold" style="min-width:100px">
		{{ L_R_Max_Force_Average }}
	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	created: function() {


	},
	methods: {
		
	},
	watch: {
		dataLayer: {
			deep: true,
			handler: function() {

			}
		},
		L_R_Max_Force_Average: {
			immediate: true,
			handler: function() {
				this.test[this.field] = this.L_R_Max_Force_Average;
			}
		},
	},
	computed: {
		prefix: function() {
			if(this.field == 'L_R_Max_Force_Average') {
				return '';
			}
			return this.field.replace('L_R_Max_Force_Average', '');
		},
		L_R_Max_Force_Average: function() {
			var value = null;
			if(!isNaN(this.test[this.prefix + 'L_Max_Force']) && this.test[this.prefix + 'L_Max_Force'] != null && this.test[this.prefix + 'L_Max_Force'] != '' && !isNaN(this.test[this.prefix + 'R_Max_Force']) && this.test[this.prefix + 'R_Max_Force'] != null && this.test[this.prefix + 'R_Max_Force'] != '') {
				value = (parseFloat(this.test[this.prefix + 'L_Max_Force']) + parseFloat(this.test[this.prefix + 'R_Max_Force'])) / 2;
				value =  Math.round(value * 100) / 100;
			}
			return value;
		},		
	}
}

</script>