<template>
  <div id="container" class="svg-container">
    <svg :id="'pie_' + id"></svg>
    <div id="tooltip" class="tooltip">
      <p class="hidden">
        <strong><span id="value"></span></strong>
      </p>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const radius = Math.min(this.width, this.height) / 2 - 20;
      const innerRadius = radius - 60;

      const arc = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(innerRadius)
        .padAngle(0.01);

     const arc2 = d3
        .arc()
        .outerRadius((radius * 2) - 10)
        .innerRadius((innerRadius * 2) - 10)
        .padAngle(0.01);   

      const pie = d3
        .pie()
        .sort(null)
        .startAngle(1.1 * Math.PI)
        .endAngle(3.1 * Math.PI)
        .value(function (d) {
          return d.value;
        });

      const svg = d3
        .select("#pie_" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${this.width} ${this.height}`)
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.width / 2 + "," + this.height / 2 + ")"
        );

      this.chart
        .selectAll(".pie")
        .data(pie(this.data))
        .enter()
        .append("path")
        .attr("class", "pie")
        .attr("fill", function (d) {
          return d.data.color;
        })
        .attr('cursor', 'pointer')
        .on('click', function(b,a) {
          this.$emit('picked', a.data.label);
        }.bind(this))
        .transition()
        .delay(function (d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween("d", function (d) {
          var i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });

      this.chart
        .selectAll(".labelPie")
        .data(pie(this.data))
        .enter()
        .append("text")
        .attr("class", "labelPie")
        .attr("transform", function (d) {
          return "translate(" + arc.centroid(d) + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .style("font-size", 15)
        .style("font-weight", "bold")
        .attr("fill", "#000000")
        .transition()
        .delay(1000)
        .text(function (d) {
          return d.data.value > 0 ? d.data.label : "";
        });

        this.chart
        .selectAll(".labelPie2")
        .data(pie(this.data))
        .enter()
        .append("text")
        .attr("class", "labelPie2")
        .attr("transform", function (d) {
          return "translate(" + arc2.centroid(d) + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .style("font-size", 15)
        .style("font-weight", "bold")
        .attr("fill", "#000000")
        .transition()
        .delay(1000)
        .text(function (d) {
          return d.data.value > 0 ? d.data.value : "";
        });
    },
  },
};
</script>
<style scoped>
.tooltip {
  right: 0;
  top: 10px;
  position: absolute;
  z-index: 999;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 22px;
  line-height: 20px;
}
</style>
