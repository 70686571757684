export default {
    exercises: [],
    exercises_archivio: [],
    exercises_condivisi: [],
    title: '',
    page: 1,
    exercises_tab: 0,
    filters: {
    },
};
