<template>
	<div style="width: 100%">

		<div class="border p-2 mb-2 bg-light">
			<b-row align-v="baseline" class="mb-2">
				<b-col>
					<b-form-group class="m-0" label="Titolo / Autore">
						<b-form-input size="sm" type="text" v-model="ux.search"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group class="m-0" label="Esercitazione">
						<b-form-select size="sm" v-model="ux.drill_id">
							<option :value="null">seleziona</option>
							<option :value="drill.id" v-for="drill in drills" :key="drill.id">{{drill.name}}</option>
						</b-form-select>
					</b-form-group>
				</b-col>
				<b-col cols="2">
					<b-form-group class="m-0">
						<b-button size="sm" @click="search_ex" type="button" variant="outline-primary" :block="true"><i class="fa fa-search fa-fw"></i> <span class="text-uppercase">Cerca</span></b-button>
					</b-form-group>
				</b-col>
			</b-row>
			<SkillPickerGroup size="sm" :model="ux.skills_rel" :config="{ radici: !ux.portiere ? [1, 30, 61] : [86, 99, 124, 61], onlyone: true, hideRating: true }"></SkillPickerGroup>
			<b-button @click="switchFilter" size="xs" class="mt-2">{{ ux.portiere ? 'passa agli obiettivi giocatore' : 'passa agli obiettivi portiere'}}</b-button>	
			<b-button @click="resetFilter" size="xs" class="mt-2 ml-1">Reset filtri</b-button>				
		</div>
		<div>Esercizi totali: {{ exercises_certificati.total }}</div>
		<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll; width: 100%" class="row">
			<b-media class="py-3 border-bottom w-100"  :style="selectedEx.indexOf(null) != -1 ? 'background-color: yellow' : ''"  @click="setExercise(emptyExercise)">
				<b-img slot="aside" src="http://via.placeholder.com/320x150?text=Fase Esercizio" height="150" />
				<div class="d-flex flex-column" style="height: 80px;">
					<h5 class="flex-shrink-0 pb-2 m-0">Fase Esercizio</h5>
					<div class="flex-grow-1" style="overflow-y: auto;">
						<p class="text-muted">Esercizio Vuoto. Parti da questo per creare la tua fase personalizzata.</p>
					</div>
				</div>
			</b-media>
			<b-media @click="setExercise(ex)" class="py-3 border-bottom" :style="selectedEx.indexOf(ex.id) != -1 ? 'background-color: yellow' : ''"  v-for="ex in exercises_certificati.data" :key="ex.id">
				<b-img slot="aside" :src="ex.canvas_url || ex.immagine" height="150" />
				<div class="d-flex flex-column">
					<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
					<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
					<div v-if="!ex.portiere">
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 1), skills.find(item => item.id == 1))">{{ getLabel(getSkillId(ex, 1), skills.find(item => item.id == 1)) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 30), skills.find(item => item.id == 30))">{{ getLabel(getSkillId(ex, 30), skills.find(item => item.id == 30)) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61))">{{ getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61)) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
					</div>
					<div v-if="ex.portiere">
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 86), skills.find(item => item.id == 86))">{{ getLabel(getSkillId(ex, 86), skills.find(item => item.id == 86)) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 99), skills.find(item => item.id == 99))">{{ getLabel(getSkillId(ex, 99), skills.find(item => item.id == 99)) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="getLabel(getSkillId(ex, 124), skills.find(item => item.id == 124))">{{ getLabel(getSkillId(ex, 124), skills.find(item => item.id == 124)) }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61))">{{ getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61)) }}</span></p>
					</div>
					<div class="flex-grow-1">
						<p class="text-muted"><small>{{ ex.description | trim(420) }}</small></p>
					</div>
				</div>
			</b-media>
		</v-infinite-scroll>


	</div>
</template>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';
import InfiniteScroll from 'v-infinite-scroll';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');


export default {
	components: {
		SkillPickerGroup: require('@/components/layout/Sessioni/Generica/SkillPickerGroup.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},
	data: function() {
		return {
			drills: [],
			skills: [],
			selected: [],
			ux: {
				loading: false,
				drill_id: null,
				search: '',
				skills_rel: [],
				portiere: 0,
			}
		}
	},
	props: [ 'sessione' ],
	filters: {
		trim: function(val, length) {
			return _.truncate(val, { length: length, omission: '…' });
		}
	},
	computed: {
		...mapState('editor', ['exercises_certificati', 'page']),
		...mapState(['rosaTeamId']),
		user: function() {
			return Auth.user;
		},
		auth: function() {
			return Auth;
		},
		emptyExercise: function() {
			return {
				id: null,
				name: 'Esercizio',
				description: null,
				sizeCampo: null,
				numeroGiocatori: null,
				minutiEsercizio: null,
				numeroSerie: null,
				recupero: null,
				secondiRecupero: null,
				numeroRecuperi: null,
				drill_id: null,
				g1: null,
				g2: null,
				g3: null,
				g4: null,
				jolly: null,
				sponde: null,
				portieri: null,
				porte_grandi: null,
				porte_piccole: null,
				campo_lunghezza: null,
				campo_larghezza: null,
				skills_rel: []
			}
		},
		selectedEx: function() {
			return _.map(this.selected, 'id');
		}
	},
	created: function() {
		this.getDrills();
		this.getSkills();
		if(this.sessione && this.sessione.skills_rel && this.sessione.skills_rel.length > 0) {
			this.ux.skills_rel = this.sessione.skills_rel.slice();
		}
		this.search_ex();
	},
	watch: {
		selected: {
			deep: true,
			handler: function() {

				var esercizi = _.map(this.selected, function(exercise) {
					var ex = _.cloneDeep(exercise);
					return {
						tipologia: 'Football',
						item: {
							id: 'gen-ex-' + _.uniqueId(),
							nome: ex.name,
							tipologia: 'Esercizio',
							/*config: {
								hideActivityCheckColumns: [ "reason", "injuries", "illnesses" ],
								ifInPhaseAlsoActivityCheck: true
							},*/
							baseObject: {
								esercizio: ex,
								exercise_id: ex.id,
								nome: ex.name,
								description: ex.description,
								field_size: ex.sizeCampo,
								players: ex.numeroGiocatori,
								training_minutes: ex.minutiEsercizio,
								sets: ex.numeroSerie,
								recovery_minutes: ex.recupero,
								recovery_seconds: ex.secondiRecupero,
								recoveries: ex.numeroRecuperi,
								drill_id: ex.drill_id,
								g1: ex.g1,
								g2: ex.g2,
								g3: ex.g3,
								g4: ex.g4,
								jolly: ex.jolly,
								sponde: ex.sponde,
								portieri: ex.portieri,
								porte_grandi: ex.porte_grandi,
								porte_piccole: ex.porte_piccole,
								campo_lunghezza: ex.campo_lunghezza,
								campo_larghezza: ex.campo_larghezza,
								skills_rel: ex.skills_rel
							}
						}
					};
				});

				this.$emit('addFasi', { 
					fasi: esercizi, 
					filter: { 
						tipologia: 'Football', 
						item: { tipologia: 'Esercizio' }
					}
				});

			}
		}
	},
	methods: {
		search_ex: function() {
			this.$store.commit('editor/setObjIds', this.ux.skills_rel.map(item => item.skill_glo_id));
			this.$store.commit('editor/setTitle', this.ux.search);
			this.$store.commit('editor/setDrillId', this.ux.drill_id);
			this.$store.commit('editor/setPage', 1);
			this.$store.dispatch('editor/filter_certificati');

		},
		getDrills: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/drills').then(function(data) {
				this.drills = data.data;
			});
		},
		getSkills: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skill/object').then(function(data) {
				this.skills = data.data;
			});
		},
		nextPage: function() {
			if(this.page < this.exercises_certificati.last_page) {
				this.$store.commit('editor/setPage', this.page + 1);
				this.$store.dispatch('editor/filter_certificati', this.page + 1);
			}
		},
		setExercise: function(exercise) {

			if(!_.find(this.selected, { id: exercise ? exercise.id : null })) {
				if(exercise == null)
					this.selected.push({ id: null });
				else
					this.selected.push(exercise);
			} else {
				var k = _.findKey(this.selected, { id: exercise.id });
				this.selected.splice(k, 1);
			}
		},
		switchFilter() {
			this.ux.portiere = !this.ux.portiere;
		},

		resetFilter() {
			this.ux.drill_id = null;
			this.ux.search = '';
			this.ux.skills_rel = [];
		},

		getSkillId(ex, root) {
			var skill_rel = ex.skills_rel.find(item => item.skill_glo_root_id == root);
			if(skill_rel) {
				return skill_rel.skill_glo_id;
			}
			return null;
		},

		getLabel(id_skill, skills) {
			var label = "N/D";
			if(id_skill && skills) {
				var skill_items = skills.campi;
				for(var key in skill_items) {
					var skill = skill_items[key];
					if(skill.id == id_skill) {
						label = skill.nome;
						return label;
					}
					if(skill.campi) {
						var items = skill.campi;
						for(var key2 in items) {
							var subskill = items[key2];
							if(subskill.campi) {
								var items2 = subskill.campi;
								for(var key3 in items2) {
									var subskill2 = items2[key3];
									if(subskill2.id == id_skill) {
										label = skill.nome + " -> " + subskill.nome + " -> " + subskill2.nome;
										return label;
									}  
								}
							} else {
								if(subskill.id == id_skill) {
									label = skill.nome + " -> " + subskill.nome;
									return label;
								} 		
							}
						}
					} else {
						if(skill.id == id_skill) {
							label = skill.nome;
							return label;
						}
					}
				}
			}
			return label;
		},
	}
}

</script>