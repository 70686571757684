export const ROUTES_DAILYCHECK = [
    {
        path: '/dailycheck',
        name: 'daily_check',
        component: require('../components/pages/whiteboard/config.vue').default,
        meta: {
            module: 'dailycheck',

            label: 'Daily Check Report',
            requiresAuth: true,
            showInMenu: false,
            enableRosaTeam: true,
            parent: '',
            permesso: 'daily_check',
            logCategory: true,
        }
    },
];
