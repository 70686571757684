export default {
  components: {
    CalendarFase: require('@/components/layout/Sessioni/Generica/CalendarFase.vue').default,
    CalendarFase_Fisioterapia: require('@/components/layout/Sessioni/Medical/CalendarFase_Fisioterapia.vue').default,
    CalendarFase_Fisioterapia_Test: require('@/components/layout/Sessioni/Medical/CalendarFase_Fisioterapia_Test.vue').default,
    CalendarFase_Piscina: require('@/components/layout/Sessioni/Medical/CalendarFase_Piscina.vue').default,
    CalendarFase_Palestra: require('@/components/layout/Sessioni/Medical/CalendarFase_Palestra.vue').default,
    CalendarFase_Form: require('@/components/layout/Sessioni/Generica/CalendarFase_Form.vue').default,
    CalendarFase_Vuota: require('@/components/layout/Sessioni/Generica/CalendarFase_Vuota.vue').default,
  }
};