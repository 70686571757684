<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card-deck">
				
				<div class="card">
					<div class="card-body">
						<div class="card-title">
							<span>{{ titolo }}</span>
						</div>
						<div class="content">
							<b-row>
								<b-col>
									<div v-if="loading" align="center" style="width:100%">
										<img src="/assets/varie/loading.gif" style="width: 150px;" />
									</div>
									<div class="text-center" v-if="!loading && pdfDoc">
										<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPage" :per-page="1" @change="showPage" :limit="20">
										</b-pagination>
										<canvas id="pdf-canvas" width="650"></canvas>
										<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPage" :per-page="1" @change="showPage" :limit="20">
										</b-pagination>
									</div>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="card-title">
							<span>Video Recenti</span>
						</div>
						<div class="content">
							<div class="row">
								<div class="col-md-6 col-lg-6 col-xl-6 mb-6" v-for="item in video">
									<b-card no-body class="mb-3">
										<b-embed type="video" controls :poster="item.thumb != '' ? item.thumb : '/assets/varie/missing.jpg'" preload="none" controlsList="nodownload" oncontextmenu="return false;">
											<source :src="item.video" type="video/mp4">
											</b-embed>
											<div class="p-2">

												<h5 class="m-0">{{ item.titolo != '' ? item.titolo : 'Video' }} </h5>
												<small class="color-rosso"> {{ item.created_at | formatDate }} </small>
											</div>
										</b-card>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>	

			</div>

			<footerapp></footerapp>

		</section>
	</template>


	<script>
	var Auth = require('../../../auth.js');
	import { mapState } from 'vuex';
	import axios from 'axios';
	import moment from 'moment';

	export default {

		components: {
			headapp:            require('../../layout/headapp.vue').default,
			menusub:            require('../../layout/menusub.vue').default,
			footerapp:          require('../../layout/footerapp.vue').default,
		},

		data: function(){
			return {
				pdf: null,
				pdfDoc: null,
				totalPagesPdf: 0,
				currentPagePdf: 0,
				loading: false,
				video: [],
				filters: {
					titolo: '',
					data: '',
					stato: '1',
				},
			}
		},

		computed: {
			auth: function() {
				return Auth;
			},
			user: function() {
				return Auth.user;
			},
			...mapState(['romalabCatId']),
			titolo: function() {
				if(this.pdf && this.pdf.hasOwnProperty('titolo') && this.pdf.titolo != '') {
					return this.pdf.titolo;
				}
				return "Ultimo documento";
			}
		},
		watch: {
			romalabCatId() {
				this.getPdfAttivoByCategoria();
				this.getUltimiVideo();	
			},  
		},

		created() {
			this.getPdfAttivoByCategoria();
			this.getUltimiVideo();
		},
		methods: {
			getPdfAttivoByCategoria() {
				this.loading = true;
				if(this.romalabCatId){
					this.$http.get(this.$store.state.apiEndPoint + '/romalab/pdf/attivo/by/categoria/' + this.romalabCatId).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.pdf = response.data;
	            	if(this.pdf.hasOwnProperty('document') && this.pdf.document != '') {
	            		this.viewPdf(this.pdf.document);
	            	} else {
	            		this.pdfDoc = null;
	            		this.totalPagesPdf = 0;
	            		this.currentPagePdf = 0;
	            		this.loading = false;
	            	}
	            }
	        }, (response) => {
		            // error callback
		        });
				}
			},
			viewPdf: function(pdf) {
				this.$http.get(this.$store.state.apiEndPoint + '/pdfromalab/' + pdf).then((response) => {
					if (response.ok)
					{
						var pdf_data = atob(response.data);
						PDFJS.getDocument({ data: pdf_data }).then((pdf_doc) => {
							this.totalPagesPdf = pdf_doc.numPages;
							this.pdfDoc = pdf_doc;
							this.currentPagePdf = 0;
							this.showPage(1);
							this.loading = false;
						});
					}
				}, (response) => {
				});
			},
			showPage(page_no) {
				this.currentPagePdf = page_no;
				this.pdfDoc.getPage(page_no).then((page) => {
					var desiredWidth = document.getElementById('pdf-canvas').width;
					var viewport = page.getViewport(1);
					var scale = desiredWidth / viewport.width;
					var scaledViewport = page.getViewport(scale);
					document.getElementById('pdf-canvas').height = scaledViewport.height;
					var renderContext = {
						canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
						viewport: scaledViewport
					};
					page.render(renderContext).then(() => {
					});
				});
			},
			prevPage() {
				if(this.currentPagePdf != 1) {
					this.showPage(this.currentPagePdf - 1);
				}    
			},
			nextPage() {
				if(this.currentPagePdf != this.totalPagesPdf) {
					this.showPage(this.currentPagePdf + 1);
				}    
			},
			getUltimiVideo() {
				if(this.romalabCatId) {
					this.video = [];
					this.$http.get(this.$store.state.apiEndPoint + '/romalab/video/list/' + this.romalabCatId, {params: this.filters}).then((response) => {
						if (response.ok)
						{
							this.video = response.data.data;
						}
					}, (response) => {
					});
				}
			},
		},
		filters: {
			formatDate: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('DD/MM/YYYY'); 
				}
				return "";
			}
		},
	}
	</script>
