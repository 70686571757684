export default {
  data: null,
  error: null,
  currentTime: null,
  seekTo: null,
  selectedSetting: null,
  activeSetting: null,
  playing: false,
  fullScreen: false,
  sidebarHidden: false,
  isRecording: false,
  recording: {},
  currentPlaybackRate: 1,
  goalkeeperMode: false,
  scoutingCategory: 'all',
  rosaTeamId: parseInt(sessionStorage.getItem(`rosaTeamId`), 10) || null,
  categoriaId: parseInt(sessionStorage.getItem(`categoriaId`), 10) || null,
  academyCatId: null,
  academyId: null,
  academyIntId: null,
  privateTag: false,
  romalabCatId: null,
  seasonId: parseInt(sessionStorage.getItem(`seasonId`), 10) || null,
  inProva: false,
  levels: [0, 1, 2, 3, 4, 5, -1],
  sessionLevels: sessionStorage.getItem(`sessionLevels`) ? JSON.parse(sessionStorage.getItem(`sessionLevels`)) : [],
  sessionPlayers: sessionStorage.getItem(`sessionPlayers`) ? JSON.parse(sessionStorage.getItem(`sessionPlayers`)) : [],
  calendarNew: sessionStorage.getItem(`calendarNew`) || true,
  reportNew: sessionStorage.getItem(`reportNew`) || true,
};
