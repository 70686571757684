<template>
	<div>
		
		<div v-if="!ux.creating">
			<a href="javascript:;" @click.prevent="addNote">Aggiungi una nota</a>
		</div>
		<div v-else>
			
			<b-form @submit.prevent="create">
				<b-form-group label="Nota">
					<b-textarea v-model="model.testo"></b-textarea>
				</b-form-group>
				<label class="m-0"><input type="checkbox" v-model="model.pubblica" /> Pubblica</label>
				<div class="small text-muted m-0" v-if="model.pubblica == false">Una nota non pubblica <strong>sarà visibile solo a te</strong>!</div>
				
				<div class="mt-3">
					<b-button variant="outline-success" size="sm" type="submit">Crea Nota</b-button>
				</div>
			</b-form>

		</div>

	</div>
</template>

<script>

import _ from 'lodash'

export default {
	props: [ 'model' ],
	created: function() {

	},
	computed: {
		
	},
	watch: {
		
	},
	methods: {
		addNote: function() {
			this.ux.creating = true;
		},
		create: function() {
			this.ux.creating = false;
			this.$emit('post');
		}
	},
	filters: {
		
	},
	data: function() {
		return {
			ux: {
				creating: false
			}
		}
	}
};

</script>