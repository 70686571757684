<template>
	<div>

		<pre>{{sessione}}</pre>

		<form @submit.stop.prevent="handleSubmit">
			<SessioneIntestazione @refresh="$emit('refresh')" :illnesses="illnesses" :injuries="injuries" :playersPreset="playersPreset" :sessione="sessione" :tipoSessione="sessioneCorrente" :persone="playerOptions"></SessioneIntestazione>

			<Fase 
				ref="faseRef" 
				class="mb-2" 
				@sposta="sposta(k, $event)" 
				@remove="removeFase(k)" 
				@duplica="duplicaFase(k)" 
				:sessioneCorrente="sessioneCorrente" 
				:index="k+1" 
				:fase="fase" 
				:injuries="injuries" 
				:illnesses="illnesses" 
				:isManagerSessione="isManagerSessione" 
				:estensioniFase="estensioniFase"
				v-for="fase, k in sessione.fasi" :sessione="sessione" 
			>
				<div :is="fase.entita" :disabled="isPhaseDisabled(fase)" :sessioneCorrente="sessioneCorrente"  :sessione="sessione" :fase="fase" :glossario="glossario" :medical_regions="medical_regions" :medical_regions_original="original_medical_regions" :medical_regions_map="medical_regions_map" :medical_regions_groups="medical_regions_groups" :medical_regions_supergroups="medical_regions_supergroups" :isManagerSessione="isManagerSessione"></div>
			
			</Fase>

			<b-row>
				<b-col>
					<b-button-group class="mt-3" v-if="isManagerSessione">
						<b-button v-if="canAddPhase" class="mr-0" variant="outline-primary" @click="showAddFasi"><i class="fa fa-plus fa-fw"></i> Aggiungi Fase</b-button>
						<b-button ref="submit" type="submit" variant="primary" v-if="auth.isLastSeason(seasonId) || auth.isAdmin()" class="text-uppercase ml-0"><strong>Salva</strong></b-button>
					</b-button-group>
				</b-col>
				<b-col class="text-right">
					<b-button-group class="mt-3" v-if="(isManagerSessione && auth.isLastSeason(seasonId)) || auth.isAdmin()">
						<b-button class="mr-0" variant="outline-primary" @click="showDuplicaSessione">Duplica</b-button>
						<b-button variant="outline-primary" @click="deleteSessione">Elimina</b-button>
					</b-button-group>
				</b-col>
			</b-row>

		</form>

	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';
import sessionFasiMixin from '@/mixins/sessioneFasi.js';

import axios from 'axios';
import moment from 'moment'

import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	props: [ 'sessione', 'playersPreset', 'playerOptions', 'sessioneTipi', 'seasonId', 'fasiGeneriche', 'estensioniFase', 'team' ],
	name: 'Sessione',
	mixins: [
		sessionMixin,
		sessionFasiMixin,
	],
	data: function(){
		return{
			ux: {
				newFase: false,
				showMatrice: false,
				showVideo: false
			}
		}
	},
	created: function () {
	},
	methods: {
		handleSubmit: function () {
			this.$emit('submit');
		},
	},
	watch: {}
}
</script>
