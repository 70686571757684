<template id="dashboard">
	<section class="allenamento">
		<headapp></headapp>

		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="container">
					<div class="card-title">
						<span>Allenamento</span>
					</div>
					<div class="content">
						<h4> {{ title }} </h4>
						<form @submit.stop.prevent="handleSubmit">
							<b-row class="mb-1">
								<b-col>
									<b-form-input v-model="start_at" type="date"></b-form-input>
								</b-col>
								<b-col>
									<b-form-input v-model="time_at" type="time"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="mb-3">
								<b-col>
									<b-form-radio-group id="level" v-model="level" name="radioLevel">
										<b-form-radio value="0">Standard</b-form-radio>
										<b-form-radio value="1">Perfezionamento</b-form-radio>
										<b-form-radio value="2">Recupero</b-form-radio>
										<b-form-radio value="3">Portieri</b-form-radio>
										<b-form-radio value="4">Differenziato</b-form-radio>
										<b-form-radio value="5">Match</b-form-radio>
										<b-form-radio value="6">Skills development</b-form-radio>
										<b-form-radio value="7">RTP</b-form-radio>
									</b-form-radio-group>
								</b-col>
							</b-row>
							<b-row class="mb-2" align-v="center">
								<b-col>
									<h5>Durata totale: {{ durataTotale }} minuti</h5>
								</b-col>
								<b-col>
									<div class="pull-right">
										<b-button variant="outline-primary" :to="{ name: 'football_presenze_allenamento', params: { id: $route.params.id }}"> <i class="fa fa-users" aria-hidden="true"></i> Presenze</b-button>
										<b-button variant="outline-primary" @click="downloadPdf"><i class="fa fa-eye fa-fw"></i> stampa pdf</b-button>
										<b-button variant="outline-primary" v-show="auth.isUserEnable('video_allenamenti') && !match_id" :to="{ name: 'football_video_allenamento', params: { id: $route.params.id }}"> <i class="fa fa-video-camera" aria-hidden="true"></i> Video</b-button>
										<b-button variant="outline-primary" v-show="auth.isUserEnable('video_partite') && match_id" :to="{ name: 'football_video_partita', params: { id: match_id }}"> <i class="fa fa-video-camera" aria-hidden="true"></i> Video</b-button>
										<b-button variant="outline-primary" v-show="auth.isUserEnable('football_partite') && match_id" :to="{ name: 'football_partita', params: { id: match_id }}"> <i class="fa fa-futbol-o" aria-hidden="true"></i> Partita</b-button>
										<!--b-button variant="outline-primary" v-show="auth.isUserEnable('gps_allenamenti')" :to="{ name: 'football_gps_allenamento', params: { id: $route.params.id }}"> <i class="fa fa-map-marker" aria-hidden="true"></i> Dati GPS</b-button-->
									</div>
								</b-col>
							</b-row>
							<b-form-group v-for="(item, index) in phases"  :key="'ph_' + index + '_' + random">
								<b-card>
									<b-row>
										<b-col cols="3"><h3 class="d-inline-block card-title" style="border:0;">Fase {{index + 1}}</h3></b-col>
										<b-col class="text-right">
											<b-form-checkbox v-model="item.save_exe" @input="updateRandom" :value="1" v-show="auth.isUserEnable('football_allen_crea_ese') && !item.ex_id && !item.partita">Salva in Esercizi</b-form-checkbox>
											<b-form-checkbox v-model="item.partita" @input="updateRandom" :value="1" v-show="!item.ex_id && !item.save_exe">Partita</b-form-checkbox>
											<b-button variant="outline-primary" size="sm" v-if="phases[index - 1]" @click="sposta(index, 0)"><i class="fa fa-long-arrow-up"></i> Sposta su</b-button>
											<b-button variant="outline-primary" size="sm" v-if="phases[index + 1]" @click="sposta(index, 1)"><i class="fa fa-long-arrow-down"></i> Sposta giù</b-button>
											<b-button variant="success" size="sm" @click="clonePhase(item)"><i class="fa fa-copy fa-fw"></i> Clona fase</b-button>
											<b-button variant="danger" size="sm" @click="removePhase(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi fase</b-button>
										</b-col>
									</b-row>
									<b-row v-if="item.partita">
										<b-col>
											<formazione :modulo="item.modulo1" @updatePartita="updateModulo1" dove="casa"></formazione>
											<div class="form-group" v-for="n in 11" :key="n">
												<div class="row">
													<label class="col-1 text-sm-center col-form-label">
														{{ n }}
													</label>
													<div class="col-11">
														<multiselect v-model="item.match_players1[n - 1]" :options="players" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="name" :show-labels="false" :allow-empty="false" :id="n - 1"></multiselect>
													</div>
												</div>
											</div>
										</b-col>
										<b-col>
											<formazione :modulo="item.modulo2" @updatePartita="updateModulo2" dove="fuori"></formazione>
											<div class="form-group" v-for="n in 11" :key="n">
												<div class="row">
													<label class="col-1 text-sm-center col-form-label">
														{{ n }}
													</label>
													<div class="col-11">
														<multiselect v-model="item.match_players2[n - 1]" :options="players" :multiple="false" :clear-on-select="true" placeholder="Seleziona" label="name" track-by="name" :show-labels="false" :allow-empty="false" :id="n - 1"></multiselect>
													</div>
												</div>
											</div>
										</b-col>
									</b-row>
									<b-row v-if="!item.ex_id && !item.partita" class="border p-3 mb-2 bg-light">
										<b-col>
											<h5 class="color-rosso">Cerca esercizio</h5>
											<b-row>
												<b-col cols="6">
													<b-form-group label="Titolo / Autore">
													<b-form-input type="text" v-model="item.search"></b-form-input>
												</b-form-group>
												</b-col>
												<b-col cols="6">
													<b-form-group label="Esercitazione">
														<b-form-select v-model="item.drill_id">
															<option :value="null">seleziona</option>
															<option :value="drill.id" v-for="drill in drill_options" :key="drill.id">{{drill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row align-v="end" v-if="level != 3">
												<b-col>
													<b-form-group label="Principi di gioco" :label-cols="4">
														<b-form-select v-model="item.game_principles">
															<option :value="null">seleziona</option>
															<option :value="skill.id" v-for="skill in skills1" :key="skill.id">{{skill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group label="Componenti tecniche" :label-cols="4">
														<b-form-select v-model="item.technical_component" >
															<option :value="null">seleziona</option>
															<option :value="skill.id" v-for="skill in skills2" :key="skill.id">{{skill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group label="Capacità motorie" :label-cols="4">
														<b-form-select v-model="item.motor_skill">
															<option :value="null">seleziona</option>
															<option :value="skill.id" v-for="skill in skills3" :key="skill.id">{{skill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group>
														<b-button @click="search_ex(item, index)" type="button" variant="outline-primary" :block="true"><i class="fa fa-search fa-fw"></i> <span class="text-uppercase">Cerca</span></b-button>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row align-v="end" v-if="level == 3">
												<b-col>
													<b-form-group label="Tecnica podalica" :label-cols="4">
														<b-form-select v-model="item.tecnica_podalica">
															<option :value="null">seleziona</option>
															<option :value="skill.id" v-for="skill in skills4" :key="skill.id">{{skill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group label="Tecnica di base" :label-cols="4">
														<b-form-select v-model="item.tecnica_di_base" >
															<option :value="null">seleziona</option>
															<option :value="skill.id" v-for="skill in skills5" :key="skill.id">{{skill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group label="Qualità tattiche" :label-cols="4">
														<b-form-select v-model="item.qualita_tattiche">
															<option :value="null">seleziona</option>
															<option :value="skill.id" v-for="skill in skills6" :key="skill.id">{{skill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group label="Capacità motorie" :label-cols="4">
														<b-form-select v-model="item.motor_skill">
															<option :value="null">seleziona</option>
															<option :value="skill.id" v-for="skill in skills7" :key="skill.id">{{skill.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group>
														<b-button @click="search_ex(item, index)" type="button" variant="outline-primary" :block="true"><i class="fa fa-search fa-fw"></i> <span class="text-uppercase">Cerca</span></b-button>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12" md="6">
											<b-form-group v-if="!item.partita">
												<label>Immagine</label>
												<img class="img-fluid" :src="item.prw_image" />
											</b-form-group>
											<b-form-group class="mr-5" v-show="!item.ex_id &&auth.isUserEnable('football_allen_crea_ese') && item.save_exe">
												<b-form-file
												accept="image/*"
												v-model="item.file"
												:state="Boolean(item.file)"
												placeholder="Scegli o trascina un'immagine da caricare..."
												drop-placeholder="Trascina un'immagine qui..."
												></b-form-file>	
											</b-form-group>
										</b-col>
										<b-col cols="12" md="6">
											<b-form-group label="Nome Drill GPS">
												<b-form-input v-model="item.drill_gps" type="text"></b-form-input>
											</b-form-group>
											<b-form-group label="Titolo">
												<b-form-input v-model="item.title" type="text"></b-form-input>
											</b-form-group>
											<b-form-group label="Esercitazione *">
												<b-form-select v-model="item.drill_id" :required="true">
													<option :value="null">seleziona</option>
													<option v-for="(opt, index) in drill_options" :value="opt.id" :key="index">{{ opt.name }}</option>
												</b-form-select>
											</b-form-group>
											<b-form-group label="Descrizione">
												<textarea class="form-control" v-model="item.description" placeholder="descrizione fase dell'allenamento" rows="3" max-rows="6"></textarea>
											</b-form-group>

											<div v-if="level != 3">
												<b-form-group label="Principi di gioco" :label-cols="4">
													<b-form-select v-model="item.pg">
														<option :value="null">seleziona</option>
														<option :value="skill.id" v-for="skill in skills1" :key="skill.id">{{skill.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group label="Componenti tecniche" :label-cols="4">
													<b-form-select v-model="item.ct" >
														<option :value="null">seleziona</option>
														<option :value="skill.id" v-for="skill in skills2" :key="skill.id">{{skill.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group label="Capacità motorie" :label-cols="4">
													<b-form-select v-model="item.cm">
														<option :value="null">seleziona</option>
														<option :value="skill.id" v-for="skill in skills3" :key="skill.id">{{skill.name}}</option>
													</b-form-select>
												</b-form-group>
											</div>
											<div v-if="level == 3">
												<b-form-group label="Tecnica podalica" :label-cols="4">
													<b-form-select v-model="item.tp">
														<option :value="null">seleziona</option>
														<option :value="skill.id" v-for="skill in skills4" :key="skill.id">{{skill.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group label="Tecnica di base" :label-cols="4">
													<b-form-select v-model="item.tb" >
														<option :value="null">seleziona</option>
														<option :value="skill.id" v-for="skill in skills5" :key="skill.id">{{skill.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group label="Qualità tattiche" :label-cols="4">
													<b-form-select v-model="item.qt">
														<option :value="null">seleziona</option>
														<option :value="skill.id" v-for="skill in skills6" :key="skill.id">{{skill.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group label="Capacità motorie" :label-cols="4">
													<b-form-select v-model="item.cm">
														<option :value="null">seleziona</option>
														<option :value="skill.id" v-for="skill in skills7" :key="skill.id">{{skill.name}}</option>
													</b-form-select>
												</b-form-group>
											</div>
										</b-col>
									</b-row>

									<b-row>
										<b-col cols="6">
											<b-row>
												<b-col sm="3" class="mr-4">
													<b-form-group label="Tot. giocatori *">
														<b-form-input type="number" :required="true" min="1" v-model="item.num_player"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="2">
													<b-form-group label="G1">
														<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="item.g1"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
													</b-form-group>
												</b-col>
												<b-col sm="2">
													<b-form-group label="G2">
														<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="item.g2"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
													</b-form-group>
												</b-col>
												<b-col sm="2">
													<b-form-group label="G3">
														<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="item.g3"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
													</b-form-group>
												</b-col>
												<b-col sm="2">
													<b-form-group label="G4">
														<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input type="number" min="0" v-model="item.g4"></b-form-input></b-col><b-col class="p-0 text-center"></b-col></b-row>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="3">
													<b-form-group label="Jolly">
														<b-form-input type="number" min="0" v-model="item.jolly"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="3">
													<b-form-group label="Sponde">
														<b-form-input type="number" min="0" v-model="item.sponde"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="3">
													<b-form-group label="Portieri">
														<b-form-input type="number" min="0" v-model="item.portieri"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="2">
												</b-col>
												<b-col sm="3">
													<b-form-group label="Porte grandi">
														<b-form-input type="number" min="0" v-model="item.porte_grandi"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="3">
													<b-form-group label="Porte piccole">
														<b-form-input type="number" min="0" v-model="item.porte_piccole"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="4">
													<b-form-group label="Lunghezza campo *">
														<b-form-input type="number"  min="1" v-model="item.campo_lunghezza" :required="true"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="4">
													<b-form-group label="Larghezza campo *">
														<b-form-input type="number"  min="1" v-model="item.campo_larghezza" :required="true"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="4">
													<b-form-group label="Densità campo">
														<b-form-input plaintext :value="getDensitaCampo(item)"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>

										<b-col cols="6">
											<b-form-row>
												<b-col cols="12" md="6">
													<b-form-group label="Minuti esercitazione *">
														<b-form-input type="number" :required="true" min="1" v-model="item.min_ex"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group label="N. serie esercitazione *">
														<b-form-input type="number" :required="true" min="1" v-model="item.num_serie"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group label="Min. recupero esercitazione">
														<b-form-input type="number" min="0" v-model="item.min_rec"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group label="Sec. recupero esercitazione">
														<b-form-input type="number" min="0" v-model="item.sec_rec"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group label="N. recupero esercitazione">
														<b-form-input type="number" min="0" v-model="item.num_rec"></b-form-input>
													</b-form-group>
												</b-col>
											</b-form-row>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-form-group label="Giocatori">
												<b-form-checkbox-group
												v-model="item.players"
												:name="'players' + index"
												>
												<b-form-checkbox v-for="player in players" :value="player">{{ player.name }}</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>
									</b-col>
								</b-row>
									<div class="text-right"><small>* campi obbligatori</small></div>
								</b-card>
							</b-form-group>
							<b-form-group id="phaseGroup">
								<b-row>
									<b-col>
										<b-button variant="outline-primary" @click="addPhase"><i class="fa fa-plus fa-fw"></i> Aggiungi fase</b-button>
									</b-col>
								</b-row>
							</b-form-group>
							<b-row>
								<b-col class="text-right">
									<button v-if="auth.isLastSeason(season_id)" class="btn btn-default btn-primary btn-lg text-uppercase"><strong>Salva</strong></button>
								</b-col>
							</b-row>
						</form>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>

		<b-modal id="exModal" ref="exModal" title="Aggiungi Esercizio" size="lg" centered>
			<b-container fluid>
				<p>Esercizi totali: {{ exercises_certificati.total }}</p>
				<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
					<b-media class="py-3 border-bottom" v-for="ex in exercises_certificati.data" :key="ex.id">
						<b-img slot="aside" :src="ex.canvas_url || ex.immagine" @click="setExercise(ex.id)" height="150" />
						<div class="d-flex flex-column" style="height: 150px;">
							<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
							<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
							<div v-if="!ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="getLabel(ex.principiDiGioco, skills[0])">{{ getLabel(ex.principiDiGioco, skills[0]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="getLabel(ex.componentiTecniche, skills[1])">{{ getLabel(ex.componentiTecniche, skills[1]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills[2])">{{ getLabel(ex.capacitaMotorie, skills[2]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
							</div>
							<div v-if="ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaPodalica, skills_portiere[0])">{{ getLabel(ex.tecnicaPodalica, skills_portiere[0]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaDiBase, skills_portiere[1])">{{ getLabel(ex.tecnicaDiBase, skills_portiere[1]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="getLabel(ex.qualitaTattiche, skills_portiere[2])">{{ getLabel(ex.qualitaTattiche, skills_portiere[2]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills_portiere[3])">{{ getLabel(ex.capacitaMotorie, skills_portiere[3]) }}</span></p>
							</div>
							<div class="flex-grow-1" style="overflow-y: auto;">
								<p class="text-muted"><small>{{ex.description}}</small></p>
							</div>
						</div>
					</b-media>
				</v-infinite-scroll>
			</b-container>
		</b-modal>
	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
import InfiniteScroll from 'v-infinite-scroll';
var Auth = require('@/auth.js');
export default {
	data: function(){
		return{				
			level: 		0,
			event_id: 	null,
			preview_image: 	true,
			title: 		'',
			start_at: 	'',
			time_at: 	'',
			phases: 	[],
			skills: 	[],
			skills_portiere: 	[],
			skills1: 	[],
			skills2: 	[],
			skills3: 	[],
			skills4: 	[],
			skills5: 	[],
			skills6: 	[],
			skills7: 	[],
			players:	[],
			descr: '',
			phase_index: 0,
			team: null,
			season_id: null,
			drill_options: [],
			random: Math.random(),
			training_ids: [],
			match_id: null,
		}
	},
	created: function () {
		this.getPlayers();
		this.getDrills();
    },
    methods: {
    	getOptionsSkills(skills) {
    		var options = [];
    		var skill_items = skills.fields;
    		for(var key in skill_items) {
    			var skill = skill_items[key];
    			if(!this.team.categoria.only_root_skill) {
    				if(skill.fields) {
    					var items = skill.fields;
    					for(var key2 in items) {
    						var subskill = items[key2];
    						if(subskill.fields) {
    							var items2 = subskill.fields;
    							for(var key3 in items2) {
    								var subskill2 = items2[key3];
    								var option = {
    									id: subskill2.id,
    									name: skill.it + " -> " + subskill.it + " -> " + subskill2.it,
    								}
    								options.push(option);      
    							}
    						} else {
    							var option = {
    								id: subskill.id,
    								name: skill.it + " -> " + subskill.it,
    							}
    							options.push(option);
    						}
    					}
    				} else {
    					var option = {
    						id: skill.id,
    						name: skill.it,
    					}
    					options.push(option);
    				}
    			} else {
    				var option = {
    					id: skill.id,
    					name: skill.it,
    				}
    				options.push(option);
    			}
    		}
    		return options;
    	},
    	getAllenamento() {
			if(this.$route.params.id){
				axios.get(`/allenamento/`+this.$route.params.id)
				.then((res) => {
					this.title 		= res.data.title
					this.level 		= res.data.level
					this.start_at 	= moment(res.data.start_at).format("YYYY-MM-DD");
					this.time_at 	= moment(res.data.start_at).format("HH:mm:ss");
					this.season_id  = res.data.season_id;
					this.match_id  = res.data.match_id;

					this.getTeam();

					this.phases = [];
					var k = 0;
					res.data.phases.forEach((el, index) => {
						this.phases[k] = {
							id: el.id,
							title: el.title,
							description: el.description,
							ex_id: el.exercise_id,
							prw_image: el.exercise ? (el.exercise.canvas_url || el.exercise.immagine) : 'http://via.placeholder.com/500?text=1',
							field_size: el.field_size,
							num_player: el.players,
							min_ex: el.training_minutes,
							num_serie: el.sets,
							min_rec: el.recovery_minutes,
							sec_rec: el.recovery_seconds,
							num_rec: el.recoveries,
							players: [],
							pg: el.principiDiGioco,
							ct: el.componentiTecniche,
							cm: el.capacitaMotorie,
							tp: el.tecnicaPodalica,
							tb: el.tecnicaDiBase,
							qt: el.qualitaTattiche,
							partita: el.partita,
							modulo1: el.modulo1 ? el.modulo1 : '433',
							modulo2: el.modulo2 ? el.modulo2 : '433',
							match_players1: el.match_players1 ? JSON.parse(el.match_players1) : [],
							match_players2: el.match_players2 ? JSON.parse(el.match_players2) : [],
							drill_id: el.drill_id,
							g1: el.g1,
							g2: el.g2,
							g3: el.g3,
							g4: el.g4,
							jolly: el.jolly,
							sponde: el.sponde,
							portieri: el.portieri,
							porte_grandi: el.porte_grandi,
							porte_piccole: el.porte_piccole,
							campo_lunghezza: el.campo_lunghezza,
							campo_larghezza: el.campo_larghezza,
							drill_gps: el.drill_gps,
							file: null,
							save_exe: 0,
						};

						el.get_players.forEach((player, i) => {
							this.phases[k]['players'][i] = {
								id: player.player.id,
								name: player.player.nome + ' ' + player.player.cognome
							}
						});

						k++;
					});
				}).catch((err) => {
					this.loading = false;
				});
			}
		},
    	handleSubmit: function () {
    		if(!Auth.isLastSeason(this.season_id)) {
    			alert("Non puoi modificare un allenamento di una stagione archiviata!");
    			return;
    		}
    		const formData = new FormData();
    		formData.append("start_at",  this.start_at);
    		formData.append("time_at",  this.time_at);
    		formData.append("title",  this.title);
    		formData.append("level",  this.level);

    		this.phases.forEach(function(element, i) {
    			if( element != undefined ) {
    				formData.append("phases[]", JSON.stringify(element));
    				formData.append("phase_images_" + i, element.file);
    			}
    		});

    		this.update(formData,  this.$route.params.id);
    	},
    	update(data, id){
    		this.$http.post(this.$store.state.apiEndPoint + '/allenamenti/update/'+id, data).then((response) => {
						// success callback
						if (response.ok){
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
							this.getPhases();
						}
					}, (response) => {
						if (response.status == 422) {
							this.errors = response.data;
							for (var index in this.errors) {
								this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
							}
						}
						// error callback
					});
    	},

    	addPhase: function () {
    		var drill_gps = this.getDrillGps(this.phases.length + 1);
    		this.phases.push( { ex_id: null, prw_image: 'http://via.placeholder.com/500?text=1', cm: null, ct: null, pg: null, tp: null, tb: null, qt: null, title: '', description: '', field_size: '', num_player: 0, min_ex: 0, num_serie:0, min_rec: 0, sec_rec: 0, num_rec:0, players:[], ex_pg: null, ex_ct: null, ex_cp: null, ex_tp: null, ex_tb: null, ex_qt: null, search: '', partita: 0, modulo1: '433', modulo2: '433', match_players1: [], match_players2: [],  drill_id: null, g1: null, g2: null, g3: null, g4: null, jolly: null, sponde: null, portieri: null, porte_grandi: null, porte_piccole: null, campo_lunghezza: null, campo_larghezza: null, file: null, drill_gps: drill_gps, save_exe: 0 });
    	},

    	removePhase: function (item) {
    		var index = this.phases.indexOf(item);
    		this.phases.splice(index, 1);
    	},

    	getPlayers: function(){
				this.$http.get(this.$store.state.apiEndPoint + '/players', {params: { team_id: this.rosaTeamId, season_id: this.season_id, no_stats: true, training_id: this.$route.params.id }}).then((response) => {
					// success callback
					if (response.ok)
					{
						if(response.data.players) {
							this.players = response.data.players.map(item => ({
								name: item.nome + ' ' + item.cognome,
								id: item.id,
							}));

							this.getAllenamento();
						}
					}
				}, (response) => {
					// error callback
				});
			},
			getPhases: function(){
				/**
				*  RETRY phases
				**/
				this.$http.get(this.$store.state.apiEndPoint + '/phases/' + this.$route.params.id).then((response) => {
					// success callback
					if (response.ok)
					{
						this.phases = [];
						var k = 0;
						response.data.phases.forEach((el, index) => {
							this.phases[k] = {
								id: el.id,
								title: el.title,
								description: el.description,
								ex_id: el.exercise_id,
								prw_image: el.exercise ? (el.exercise.canvas_url || el.exercise.immagine) : 'http://via.placeholder.com/500?text=1',
								field_size: el.field_size,
								num_player: el.players,
								min_ex: el.training_minutes,
								num_serie: el.sets,
								min_rec: el.recovery_minutes,
								sec_rec: el.recovery_seconds,
								num_rec: el.recoveries,
								players: [],
								pg: el.principiDiGioco,
								ct: el.componentiTecniche,
								cm: el.capacitaMotorie,
								tp: el.tecnicaPodalica,
								tb: el.tecnicaDiBase,
								qt: el.qualitaTattiche,
								partita: el.partita,
								modulo1: el.modulo1 ? el.modulo1 : '433',
								modulo2: el.modulo2 ? el.modulo2 : '433',
								match_players1: el.match_players1 ? JSON.parse(el.match_players1) : [],
								match_players2: el.match_players2 ? JSON.parse(el.match_players2) : [],
								drill_id: el.drill_id,
								g1: el.g1,
								g2: el.g2,
								g3: el.g3,
								g4: el.g4,
								jolly: el.jolly,
								sponde: el.sponde,
								portieri: el.portieri,
								porte_grandi: el.porte_grandi,
								porte_piccole: el.porte_piccole,
								campo_lunghezza: el.campo_lunghezza,
								campo_larghezza: el.campo_larghezza,
								drill_gps: el.drill_gps,
								file: null,
								save_exe: 0,
							};

							el.get_players.forEach((player, i) => {
								this.phases[k]['players'][i] = {
									id: player.player.id,
									name: player.player.nome + ' ' + player.player.cognome
								}
							});

							k++;
						});
					}
				}, (response) => {
					// error callback
				});
			},
			search_ex: function (item, index) {
				this.$refs.exModal.hide();
				var array = [];

				if(item.motor_skill) array.push(item.motor_skill);
				if(item.technical_component) array.push(item.technical_component);
				if(item.game_principles) array.push(item.game_principles);
				if(item.tecnica_podalica) array.push(item.tecnica_podalica);
				if(item.tecnica_di_base) array.push(item.tecnica_di_base);
				if(item.qualita_tattiche) array.push(item.qualita_tattiche);
				this.$store.commit('editor/setObjIds', array);
				this.$store.commit('editor/setTitle', item.search);
				this.$store.commit('editor/setDrillId', item.drill_id);
				this.$store.commit('editor/setPage', 1);
				this.$store.dispatch('editor/filter_certificati');
				this.phase_index = index;
				this.$refs.exModal.show();
			},
			setExercise: function(id) {
				axios.get(`/exercise/get/` + id)
				.then((res) => {

					this.phases[this.phase_index].ex_id = res.data.id;
					this.phases[this.phase_index].title = res.data.name
					this.phases[this.phase_index].description = res.data.description;
					this.phases[this.phase_index].prw_image = res.data.canvas_url || res.data.immagine;
					this.phases[this.phase_index].field_size = res.data.sizeCampo;
					this.phases[this.phase_index].num_player = res.data.numeroGiocatori;
					this.phases[this.phase_index].min_ex = res.data.minutiEsercizio;
					this.phases[this.phase_index].num_serie = res.data.numeroSerie;
					this.phases[this.phase_index].min_rec = res.data.recupero;
					this.phases[this.phase_index].sec_rec = res.data.secondiRecupero;
					this.phases[this.phase_index].num_rec = res.data.numeroRecuperi;
					this.phases[this.phase_index].pg = res.data.principiDiGioco;
					this.phases[this.phase_index].ct = res.data.componentiTecniche;
					this.phases[this.phase_index].cm = res.data.capacitaMotorie;
					this.phases[this.phase_index].tp = res.data.tecnicaPodalica;
					this.phases[this.phase_index].tb = res.data.tecnicaDiBase;
					this.phases[this.phase_index].qt = res.data.qualitaTattiche;

					this.phases[this.phase_index].drill_id = res.data.drill_id;
					this.phases[this.phase_index].g1 = res.data.g1;
					this.phases[this.phase_index].g2 = res.data.g2;
					this.phases[this.phase_index].g3 = res.data.g3;
					this.phases[this.phase_index].g4 = res.data.g4;
					this.phases[this.phase_index].jolly = res.data.jolly;
					this.phases[this.phase_index].sponde = res.data.sponde;
					this.phases[this.phase_index].portieri = res.data.portieri;
					this.phases[this.phase_index].porte_grandi = res.data.porte_grandi;
					this.phases[this.phase_index].porte_piccole = res.data.porte_piccole;
					this.phases[this.phase_index].campo_lunghezza = res.data.campo_lunghezza;
					this.phases[this.phase_index].campo_larghezza = res.data.campo_larghezza;

					this.phases.sort();

					this.$refs.exModal.hide();
				}).catch((err) => {
					this.loading = false;
					this.$refs.exModal.hide();
				});
			},

			getLabel(id_skill, skills) {
				var label = "N/D";
				if(id_skill && skills) {
					var skill_items = skills.fields;
					for(var key in skill_items) {
						var skill = skill_items[key];
						if(skill.id == id_skill) {
							label = skill.it;
							return label;
						}
						if(skill.fields) {
							var items = skill.fields;
							for(var key2 in items) {
								var subskill = items[key2];
								if(subskill.fields) {
									var items2 = subskill.fields;
									for(var key3 in items2) {
										var subskill2 = items2[key3];
										if(subskill2.id == id_skill) {
											label = skill.it + " -> " + subskill.it + " -> " + subskill2.it;
											return label;
										}  
									}
								} else {
									if(subskill.id == id_skill) {
										label = skill.it + " -> " + subskill.it;
										return label;
									} 		
								}
							}
						} else {
							if(skill.id == id_skill) {
								label = skill.it;
								return label;
							}
						}
					}
				}
				return label;
			},
			downloadPdf() {
				window.open(this.$store.state.apiEndPoint + '/allenamento/' + this.$route.params.id + "/pdf", "_blank");
			},
			sposta(index, dove) {
				if(dove == 1) {
					var temp = this.phases.splice(index, 1); 
					this.phases.splice(index + 1, 0, temp[0]);
				} else {
					var temp = this.phases.splice(index, 1); 
					this.phases.splice(index - 1, 0, temp[0]);
				}
			},
			updateModulo1(modulo) {
				var newMod = this.findAndReplace(modulo.name, '-', '');
				for(var i in this.phases) {
					var fase = this.phases[i];
					if(fase.partita) {
						fase.modulo1 = newMod;
					}
				}
			},
			updateModulo2(modulo) {
				var newMod = this.findAndReplace(modulo.name, '-', '');
				for(var i in this.phases) {
					var fase = this.phases[i];
					if(fase.partita) {
						fase.modulo2 = newMod;
					}
				}
			},
			findAndReplace: function(string, target, replacement) {
				var i = 0, length = string.length;
				for (i; i < length; i++) {
					string = string.replace(target, replacement);
				}
				return string;
			},
			getTeam() {
				if(this.rosaTeamId){
					if(this.start_at) {
						this.getIdsTrainingDay();
					}
					this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
	              // success callback
	              if (response.ok)
	              {
	              	this.team = response.data;
	              	axios.get(`/skills`)
	              	.then((res) => {
	              		this.skills = JSON.parse(res.data.standard);
	              		this.skills_portiere = JSON.parse(res.data.special);

	              		this.skills1 = this.getOptionsSkills(this.skills[0]);
	              		this.skills2 = this.getOptionsSkills(this.skills[1]);
	              		this.skills3 = this.getOptionsSkills(this.skills[2]);

	              		this.skills4 = this.getOptionsSkills(this.skills_portiere[0]);
	              		this.skills5 = this.getOptionsSkills(this.skills_portiere[1]);
	              		this.skills6 = this.getOptionsSkills(this.skills_portiere[2]);
	              		this.skills7 = this.getOptionsSkills(this.skills_portiere[3]);

	              		this.loading    = false;
	              	}).catch((err) => {
	              		this.loading = false;
	              	});
	              }
	          }, (response) => {
	                // error callback
	            });
				}
			},
			getDrills() {
				this.$http.get(this.$store.state.apiEndPoint + '/exercise/drills').then((response) => {
	              // success callback
	              if (response.ok)
	              {
	              	this.drill_options = response.data;
	              }
	          }, (response) => {
	                // error callback
	            });
			},
			nextPage() {
				if(this.page < this.exercises_certificati.last_page) {
					this.$store.commit('editor/setPage', this.page + 1);
					this.$store.dispatch('editor/filter_certificati', this.page + 1);
				}
			},
			getDensitaCampo(phase) {
				var densita = "";
				if(phase.num_player > 0 && phase.campo_lunghezza > 0 && phase.campo_larghezza > 0) {
					densita = (phase.campo_lunghezza * phase.campo_larghezza) / phase.num_player;
					densita = Math.round(densita * 100) / 100;
				}
				return densita;
			},
			updateRandom() {
				this.random = Math.random();
			},
			getIdsTrainingDay() {
				this.$http.get(this.$store.state.apiEndPoint + '/allenamenti/day/ids', {params: { team_id: this.rosaTeamId, date: this.start_at }}).then((response) => {
	              // success callback
	              if (response.ok)
	              {
	              	this.training_ids = response.data;
	              }
	          }, (response) => {
	                // error callback
	            });
			},

			getDrillGps(countPhase) {
				var drill_gps = null;
				var findIndex = this.training_ids.findIndex((item) => this.$route.params.id == item);
				if(countPhase < 10) {
					countPhase = '0' + countPhase;
				}
				drill_gps = (findIndex + 1) + '' + countPhase;
				return drill_gps;		
			},

			clonePhase(item) {
				var index = this.phases.indexOf(item);
				const phase = this.phases[index];
				const clonePhase = Object.assign({}, phase);

				var drill_gps = this.getDrillGps(this.phases.length + 1);

				clonePhase.drill_gps = drill_gps;

				this.phases.push(clonePhase);
			},

		},
		components: {
			headapp:            require('../../../layout/headapp.vue').default,
			footerapp:          require('../../../layout/footerapp.vue').default,
			formazione:         require('../../../layout/formazione.vue').default,
			'v-infinite-scroll': InfiniteScroll
		},
		computed: {
			auth: function() {
				return Auth;
			},
			...mapState('editor', ['exercises_certificati', 'page']),
			...mapState(['rosaTeamId']),
			
			durataTotale: function() {
				var tot = 0;
				for(var i in this.phases) {
					var fase = this.phases[i];
					tot += (fase.min_ex * fase.num_serie) + (fase.min_rec * fase.num_rec);
					if(fase.sec_rec > 0) {
						tot += fase.sec_rec/60 * fase.num_rec;
					}	
				}
				tot = Math.round(tot * 100) / 100;
				return tot;	
			},

			player_options: function() {
				return [
				{
					label: 'Seleziona tutti',
					items: this.players,
				}
				];
			}
		},
		watch: {
			rosaTeamId() {
				this.getTeam();
				this.getPlayers();
			},  
		},
	}
	</script>
