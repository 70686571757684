<template id="dashboard">
	<section class="allenamento">
		<headapp></headapp>

		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="container">
					<div class="card-title">
						<span>{{ $i18n.t('Allenamento') }}</span>
					</div>
					<div class="content">
						<h4> {{ title }} </h4>
						<form @submit.stop.prevent="handleSubmit">
							<b-row class="mb-1">
								<b-col>
									<b-form-input v-model="start_at" type="date"></b-form-input>
								</b-col>
								<b-col>
									<b-form-input v-model="time_at" type="time"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="mb-3">
								<b-col>
									<b-form-radio-group id="level" v-model="level" name="radioLevel">
										<b-form-radio value="0">Standard</b-form-radio>
										<b-form-radio value="3">{{ $i18n.t('Portieri') }}</b-form-radio>
									</b-form-radio-group>
								</b-col>
							</b-row>
							<b-row class="mb-2" align-v="center">
								<b-col>
									<h5>{{ $i18n.t('Durata totale') }}: {{ durataTotale }} {{ $i18n.t('minuti') }}</h5>
								</b-col>
								<b-col>
									<div class="pull-right">
										<b-button variant="outline-primary" @click="downloadPdf"><i class="fa fa-eye fa-fw"></i> {{ $i18n.t('stampa pdf') }}</b-button>		
									</div>
								</b-col>
							</b-row>
							<b-form-group v-for="(item, index) in phases"  :key="item.id">
								<b-card>
									<b-row>
										<b-col><h3 class="d-inline-block card-title" style="border:0;">{{ $i18n.t('Fase') }} {{index + 1}}</h3></b-col>
										<b-col class="text-right">
											<b-form-checkbox v-model="item.partita" :value="1" v-if="!item.ex_id">{{ $i18n.t('Partita') }}</b-form-checkbox>
											<b-button variant="outline-primary" size="sm" v-if="phases[index - 1]" @click="sposta(index, 0)"><i class="fa fa-long-arrow-up"></i> {{ $i18n.t('Sposta su') }}</b-button>
											<b-button variant="outline-primary" size="sm" v-if="phases[index + 1]" @click="sposta(index, 1)"><i class="fa fa-long-arrow-down"></i> {{ $i18n.t('Sposta giù') }}</b-button>
											<b-button variant="danger" size="sm" @click="removePhase(item)"><i class="fa fa-trash fa-fw"></i> {{ $i18n.t('Rimuovi fase') }}</b-button>
										</b-col>
									</b-row>
									<b-row v-if="item.partita">
										<b-col>
											<formazione :modulo="item.modulo1" @updatePartita="updateModulo1" dove="casa"></formazione>
										</b-col>
										<b-col>
											<formazione :modulo="item.modulo2" @updatePartita="updateModulo2" dove="fuori"></formazione>
										</b-col>
									</b-row>
									<b-row v-if="!item.ex_id && !item.partita">
										<b-col>
											<b-row>
												<b-col cols="6">
													<b-form-input type="text" v-model="item.search" :placeholder="$i18n.t('Cerca per titolo/autore...')"></b-form-input>
												</b-col>
											</b-row>
											<b-row align-v="end" v-if="level != 3">
												<b-col>
													<b-form-group id="searchExGroup" :label="$i18n.t('Principi di gioco')" :label-cols="4">
														<b-form-select v-model="item.principi_gioco">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option :value="item.id" v-for="item in skills1" :key="item.id">{{item.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group id="searchExGroup" :label=" $i18n.t('Componenti tecniche')" :label-cols="4">
														<b-form-select v-model="item.componenti_tecniche" >
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option :value="item.id" v-for="item in skills2" :key="item.id">{{item.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group id="searchExGroup" :label=" $i18n.t('Capacità motorie')" :label-cols="4">
														<b-form-select v-model="item.capacita_motorie">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option :value="item.id" v-for="item in skills3" :key="item.id">{{item.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group>
														<b-button @click="search_ex(item, index)" type="button" variant="outline-primary" :block="true"><i class="fa fa-search fa-fw"></i> <span class="text-uppercase">{{ $i18n.t('Cerca') }}</span></b-button>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row align-v="end" v-if="level == 3">
												<b-col>
													<b-form-group id="searchExGroup" :label="$i18n.t('Tecnica podalica')" :label-cols="4">
														<b-form-select v-model="item.tecnica_podalica">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option :value="item.id" v-for="item in skills4" :key="item.id">{{item.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group id="searchExGroup" :label="$i18n.t('Tecnica di base')" :label-cols="4">
														<b-form-select v-model="item.tecnica_base" >
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option :value="item.id" v-for="item in skills5" :key="item.id">{{item.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group id="searchExGroup" :label="$i18n.t('Qualità tattiche')" :label-cols="4">
														<b-form-select v-model="item.qualita_tattiche">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option :value="item.id" v-for="item in skills6" :key="item.id">{{item.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group id="searchExGroup" :label=" $i18n.t('Capacità motorie')" :label-cols="4">
														<b-form-select v-model="item.capacita_motorie">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option :value="item.id" v-for="item in skills7" :key="item.id">{{item.name}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group>
														<b-button @click="search_ex(item, index)" type="button" variant="outline-primary" :block="true"><i class="fa fa-search fa-fw"></i> <span class="text-uppercase">Cerca</span></b-button>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="12" md="6">
											<b-form-group v-if="!item.partita" :label="$i18n.t('Immagine')">
												<img class="img-fluid" :src="item.prw_image" />
											</b-form-group>	
											<div v-if="level != 3">
												<b-form-group :label="$i18n.t('Principi di gioco')">
													<b-form-select v-model="item.pg">
														<option :value="null">{{ $i18n.t('seleziona') }}</option>
														<option :value="item.id" v-for="item in skills1" :key="item.id">{{item.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group :label="$i18n.t('Componenti tecniche')">
													<b-form-select v-model="item.ct" >
														<option :value="null">{{ $i18n.t('seleziona') }}</option>
														<option :value="item.id" v-for="item in skills2" :key="item.id">{{item.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group :label="$i18n.t('Capacità motorie')">
													<b-form-select v-model="item.cm">
														<option :value="null">{{ $i18n.t('seleziona') }}</option>
														<option :value="item.id" v-for="item in skills3" :key="item.id">{{item.name}}</option>
													</b-form-select>
												</b-form-group>
											</div>
											<div v-if="level == 3">
												<b-form-group :label="$i18n.t('Tecnica podalica')">
													<b-form-select v-model="item.tp">
														<option :value="null">{{ $i18n.t('seleziona') }}</option>
														<option :value="item.id" v-for="item in skills4" :key="item.id">{{item.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group :label="$i18n.t('Tecnica di base')">
													<b-form-select v-model="item.tb" >
														<option :value="null">{{ $i18n.t('seleziona') }}</option>
														<option :value="item.id" v-for="item in skills5" :key="item.id">{{item.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group :label="$i18n.t('Qualità tattiche')">
													<b-form-select v-model="item.qt">
														<option :value="null">{{ $i18n.t('seleziona') }}</option>
														<option :value="item.id" v-for="item in skills6" :key="item.id">{{item.name}}</option>
													</b-form-select>
												</b-form-group>
												<b-form-group :label="$i18n.t('Capacità motorie')">
													<b-form-select v-model="item.cm">
														<option :value="null">{{ $i18n.t('seleziona') }}</option>
														<option :value="item.id" v-for="item in skills7" :key="item.id">{{item.name}}</option>
													</b-form-select>
												</b-form-group>
											</div>
										</b-col>
										<b-col cols="12" md="6">
											<b-form-group :label="$i18n.t('Titolo')">
												<b-form-input v-model="item.title" type="text"></b-form-input>
											</b-form-group>
											<b-form-group :label="$i18n.t('Descrizione')">
												<textarea class="form-control" v-model="item.description" :placeholder="$i18n.t('descrizione fase dell\'allenamento')" rows="3" max-rows="6"></textarea>
											</b-form-group>

											<b-form-row>
												<b-col cols="12" md="6">
													<b-form-group id="fieldSizeGroup" :label="$i18n.t('Dimensioni campo di allenamento')">
														<b-form-input v-model="item.field_size" type="text"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group id="numPlayerGroup" :label="$i18n.t('Numero giocatori')">
														<b-form-select v-model="item.num_player">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option v-for="(value, index) in 40" :value="value" :key="index">{{value}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group id="minExGroup" :label="$i18n.t('Minuti esercitazione')">
														<b-form-select v-model="item.min_ex">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option v-for="(value, index) in 120" :value="value" :key="index">{{value}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group id="numSeriGroup" :label="$i18n.t('Numero di serie')">
														<b-form-select v-model="item.num_serie">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option v-for="(value, index) in 20" :value="value" :key="index">{{value}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group id="minRecGroup" :label="$i18n.t('Minuti di recupero')">
														<b-form-select v-model="item.min_rec">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option v-for="(value, index) in 120" :value="value" :key="index">{{value}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group id="secRecGroup" :label="$i18n.t('Secondi di recupero')">
														<b-form-select v-model="item.sec_rec">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option v-for="(value, index) in 11" :value="value * 5" :key="index">{{value * 5}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="12" md="6">
													<b-form-group id="numRecGroup" :label="$i18n.t('Numero di recuperi')">
														<b-form-select v-model="item.num_rec">
															<option :value="null">{{ $i18n.t('seleziona') }}</option>
															<option v-for="(value, index) in 20" :value="value" :key="index">{{value}}</option>
														</b-form-select>
													</b-form-group>
												</b-col>
											</b-form-row>
										</b-col>
									</b-row>
								</b-card>
							</b-form-group>
							<b-form-group id="phaseGroup">
								<b-row>
									<b-col>
										<b-button variant="outline-primary" @click="addPhase"><i class="fa fa-plus fa-fw"></i> {{ $i18n.t('Aggiungi fase') }}</b-button>
									</b-col>
								</b-row>
							</b-form-group>
							<b-row>
								<b-col class="text-right">
									<button class="btn btn-default btn-primary btn-lg text-uppercase"><strong>{{ $i18n.t('Salva') }}</strong></button>
								</b-col>
							</b-row>
						</form>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>

		<b-modal id="exModal" ref="exModal" :title="$i18n.t('Aggiungi esercizio')" size="lg" centered>
			<b-container fluid>
				<p>{{ $i18n.t('Esercizi totali') }}: {{ exercises.total }}</p>
				<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
					<b-media class="py-3 border-bottom" v-for="ex in exercises.data" :key="ex.id">
						<b-img slot="aside" :src="ex.canvas_url || ex.immagine" @click="setExercise(ex.id)" height="150" />
						<div class="d-flex flex-column" style="height: 150px;">
							<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
							<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
							<div v-if="!ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Principi di gioco')">PG:</span> <span class="color-rosso" :title="getLabel(ex.principiDiGioco, skills[0])">{{ getLabel(ex.principiDiGioco, skills[0]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Componenti tecniche')">CT:</span> <span class="color-rosso" :title="getLabel(ex.componentiTecniche, skills[1])">{{ getLabel(ex.componentiTecniche, skills[1]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Capacità motorie')">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills[2])">{{ getLabel(ex.capacitaMotorie, skills[2]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
							</div>
							<div v-if="ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Tecnica podalica')">TP:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaPodalica, skills_portiere[0])">{{ getLabel(ex.tecnicaPodalica, skills_portiere[0]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Tecnica di base')">TB:</span> <span class="color-rosso" :title="getLabel(ex.tecnicaDiBase, skills_portiere[1])">{{ getLabel(ex.tecnicaDiBase, skills_portiere[1]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Qualità tattiche')">QT:</span><span class="color-rosso" :title="getLabel(ex.qualitaTattiche, skills_portiere[2])">{{ getLabel(ex.qualitaTattiche, skills_portiere[2]) }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Capacità motorie')">CM:</span> <span class="color-rosso" :title="getLabel(ex.capacitaMotorie, skills_portiere[3])">{{ getLabel(ex.capacitaMotorie, skills_portiere[3]) }}</span></p>
							</div>
							<div class="flex-grow-1" style="overflow-y: auto;">
								<p class="text-muted"><small>{{ex.description}}</small></p>
							</div>
						</div>
					</b-media>
				</v-infinite-scroll>
			</b-container>
		</b-modal>
	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '../../../store/types';
import InfiniteScroll from 'v-infinite-scroll';
var Auth = require('../../../auth.js');
export default {
	data: function(){
		return{				
			level: 0,
			title: '',
			start_at: '',
			time_at: '',
			phases: [],
			skills: [],
			skills_portiere: [],
			skills1: [],
			skills2: [],
			skills3: [],
			skills4: [],
			skills5: [],
			skills6: [],
			skills7: [],
			selectedPhase: null,
			descr: '',
			phase_index: 0,
		}
	},
	created: function (){

		this.getSkills();

		if(this.$route.params.id){
			axios.get(`/academyita/allenamento/`+this.$route.params.id)
			.then((res) => {
				this.title 		= res.data.title
				this.level 		= res.data.level
				this.start_at 	= moment(res.data.start_at).format("YYYY-MM-DD");
				this.time_at 	= moment(res.data.start_at).format("HH:mm:ss");
				this.phases = [];
				var k = 0;
				res.data.phases.forEach((el, index) => {
					this.phases[k] = {
						id: el.id,
						title: el.title,
						description: el.description,
						ex_id: el.id_esercizio,
						prw_image: el.exercise ? (el.exercise.canvas_url || el.exercise.immagine) : 'http://via.placeholder.com/500?text=1',
						field_size: el.field_size,
						num_player: el.players,
						min_ex: el.training_minutes,
						num_serie: el.sets,
						min_rec: el.recovery_minutes,
						sec_rec: el.recovery_seconds,
						num_rec: el.recoveries,
						pg: el.principi_gioco,
						ct: el.componenti_tecniche,
						cm: el.capacita_motorie,
						tp: el.tecnica_podalica,
						tb: el.tecnica_base,
						qt: el.qualita_tattiche,
						partita: el.partita,
						modulo1: el.modulo1,
						modulo2: el.modulo2,
					};
					k++;
				});
			}).catch((err) => {
				this.loading = false;
			});
		}
        //   this.getPhases();

    },
    methods: {
    	getOptionsSkills(skills) {
    		var options = [];
    		var skill_items = skills.fields;
    		for(var key in skill_items) {
    			var skill = skill_items[key];
    			var option = {
    				id: skill.id,
    				name: skill[this.$i18n.t('lang')],
    			}
    			options.push(option);
    			
    		}
    		return options;
    	},
    	handleSubmit: function () {	
    		const formData = new FormData();
    		formData.append("start_at",  this.start_at);
    		formData.append("time_at",  this.time_at);
    		formData.append("title",  this.title);
    		formData.append("level",  this.level);

    		this.phases.forEach(function(element, i) {
    			if( element != undefined ){
    				formData.append("phases[]", JSON.stringify(element));
    			}
    		});

    		this.update(formData,  this.$route.params.id);
    	},
    	update(data, id){
    		this.$http.post(this.$store.state.apiEndPoint + '/academyita/update/allenamento/' + id, data).then((response) => {
						// success callback
						if (response.ok){
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
							this.getPhases();
						}
					}, (response) => {
						if (response.status == 422) {
							this.errors = response.data;
							for (var index in this.errors) {
								this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
							}
						}
						// error callback
					});
    	},

    	addPhase: function () {
    		this.phases.push( { ex_id: null, prw_image: 'http://via.placeholder.com/500?text=1', motor_skill: null, technical_component: null, game_principles: null, tecnica_podalica: null, tecnica_di_base: null, qualita_tattiche: null, title: '', description: '', field_size: '', num_player: 0, min_ex: 0, num_serie:0, min_rec: 0, sec_rec: 0, num_rec:0, ex_pg: null, ex_ct: null, ex_cp: null, ex_tp: null, ex_tb: null, ex_qt: null, search: '', partita: 0, modulo1: '433', modulo2: '433' });
    	},
    	removePhase: function (item) {
    		var index = this.phases.indexOf(item);
    		this.phases.splice(index, 1);
    	},

    	getPhases: function(){
				/**
				*  RETRY phases
				**/
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/phases/' + this.$route.params.id).then((response) => {
					// success callback
					if (response.ok)
					{
						var k = 0;
						response.data.phases.forEach((el, index) => {
							this.phases[k] = {
								id: el.id,
								title: el.title,
								description: el.description,
								ex_id: el.id_esercizio,
								prw_image: el.exercise ? (el.exercise.canvas_url || el.exercise.immagine) : 'http://via.placeholder.com/500?text=1',
								field_size: el.field_size,
								num_player: el.players,
								min_ex: el.training_minutes,
								num_serie: el.sets,
								min_rec: el.recovery_minutes,
								sec_rec: el.recovery_seconds,
								num_rec: el.recoveries,
								pg: el.principi_gioco,
								ct: el.componenti_tecniche,
								cm: el.capacita_motorie,
								tp: el.tecnica_podalica,
								tb: el.tecnica_base,
								qt: el.qualita_tattiche,
								partita: el.partita,
								modulo1: el.modulo1,
								modulo2: el.modulo2,
							};
							k++;
						});
					}
				}, (response) => {
					// error callback
				});
			},
			search_ex: function (item, index) {
				this.$refs.exModal.hide();
				var array = [];

				if(item.capacita_motorie) array.push(item.capacita_motorie);
				if(item.componenti_tecniche) array.push(item.componenti_tecniche);
				if(item.principi_gioco) array.push(item.principi_gioco);
				if(item.tecnica_podalica) array.push(item.tecnica_podalica);
				if(item.tecnica_base) array.push(item.tecnica_base);
				if(item.qualita_tattiche) array.push(item.qualita_tattiche);
				this.$store.commit('acad_esercizi/setObjIds', array);
				this.$store.commit('acad_esercizi/setTitle', item.search);
				this.$store.commit('acad_esercizi/setPage', 1);
				this.$store.dispatch('acad_esercizi/filter');
				this.phase_index = index;
				this.$refs.exModal.show();
			},
			setExercise: function(id) {
				axios.get(`/academyita/exercise/get/` + id)
				.then((res) => {

					this.phases[this.phase_index].ex_id = res.data.id
					this.phases[this.phase_index].title = res.data.name
					this.phases[this.phase_index].description = res.data.description
					this.phases[this.phase_index].prw_image = res.data.canvas_url || res.data.immagine
					this.phases[this.phase_index].field_size = res.data.sizeCampo
					this.phases[this.phase_index].num_player = res.data.numeroGiocatori
					this.phases[this.phase_index].min_ex = res.data.minutiEsercizio
					this.phases[this.phase_index].num_serie = res.data.numeroSerie
					this.phases[this.phase_index].min_rec = res.data.recupero
					this.phases[this.phase_index].sec_rec = res.data.secondiRecupero
					this.phases[this.phase_index].num_rec = res.data.numeroRecuperi
					this.phases[this.phase_index].pg = res.data.principiDiGioco
					this.phases[this.phase_index].ct = res.data.componentiTecniche
					this.phases[this.phase_index].cm = res.data.capacitaMotorie
					this.phases[this.phase_index].tp = res.data.tecnicaPodalica
					this.phases[this.phase_index].tb = res.data.tecnicaDiBase
					this.phases[this.phase_index].qt = res.data.qualitaTattiche
					this.phases.sort();

					this.$refs.exModal.hide();
				}).catch((err) => {
					this.loading = false;
					this.$refs.exModal.hide();
				});
			},

			getLabel(id_skill, skills) {
				var label = "N/D";
				if(id_skill && skills) {
					var skill_items = skills.fields;
					for(var key in skill_items) {
						var skill = skill_items[key];
						if(skill.id == id_skill) {
							label = skill[this.$i18n.t('lang')];
							return label;
						}
						if(skill.fields) {
							var items = skill.fields;
							for(var key2 in items) {
								var subskill = items[key2];
								if(subskill.fields) {
									var items2 = subskill.fields;
									for(var key3 in items2) {
										var subskill2 = items2[key3];
										if(subskill2.id == id_skill) {
											label = skill[this.$i18n.t('lang')] + " -> " + subskill[this.$i18n.t('lang')] + " -> " + subskill2[this.$i18n.t('lang')];
											return label;
										}  
									}
								} else {
									if(subskill.id == id_skill) {
										label = skill[this.$i18n.t('lang')] + " -> " + subskill[this.$i18n.t('lang')];
										return label;
									} 		
								}
							}
						} else {
							if(skill.id == id_skill) {
								label = skill[this.$i18n.t('lang')];
								return label;
							}
						}
					}
				}
				return label;
			},
			downloadPdf() {
				window.open(this.$store.state.apiEndPoint + '/academyita/allenamento/' + this.$route.params.id + "/pdf/" + this.$i18n.t('lang'), "_blank");
			},
			sposta(index, dove) {
				if(dove == 1) {
					var temp = this.phases.splice(index, 1); 
					this.phases.splice(index + 1, 0, temp[0]);
				} else {
					var temp = this.phases.splice(index, 1); 
					this.phases.splice(index - 1, 0, temp[0]);
				}
			},
			updateModulo1(modulo) {
				var newMod = this.findAndReplace(modulo.name, '-', '');
				for(var i in this.phases) {
					var fase = this.phases[i];
					if(fase.partita) {
						fase.modulo1 = newMod;
					}
				}
			},
			updateModulo2(modulo) {
				var newMod = this.findAndReplace(modulo.name, '-', '');
				for(var i in this.phases) {
					var fase = this.phases[i];
					if(fase.partita) {
						fase.modulo2 = newMod;
					}
				}
			},
			findAndReplace: function(string, target, replacement) {
				var i = 0, length = string.length;
				for (i; i < length; i++) {
					string = string.replace(target, replacement);
				}
				return string;
			},
			getSkills() {
				axios.get(`/skills`)
				.then((res) => {
					this.skills = JSON.parse(res.data.standard);
					this.skills_portiere = JSON.parse(res.data.special);

					this.skills1 = this.getOptionsSkills(this.skills[0]);
					this.skills2 = this.getOptionsSkills(this.skills[1]);
					this.skills3 = this.getOptionsSkills(this.skills[2]);

					this.skills4 = this.getOptionsSkills(this.skills_portiere[0]);
					this.skills5 = this.getOptionsSkills(this.skills_portiere[1]);
					this.skills6 = this.getOptionsSkills(this.skills_portiere[2]);
					this.skills7 = this.getOptionsSkills(this.skills_portiere[3]);

					this.loading    = false;
				}).catch((err) => {
					this.loading = false;
				});          
			},
			nextPage() {
				if(this.page < this.exercises.last_page) {
					this.$store.commit('acad_esercizi/setPage', this.page + 1);
					this.$store.dispatch('acad_esercizi/filter', this.page + 1);
				}
			}
		},
		components: {
			headapp:            require('../../layout/headapp.vue').default,
			footerapp:          require('../../layout/footerapp.vue').default,
			formazione:         require('../../layout/formazione.vue').default,
			'v-infinite-scroll': InfiniteScroll
		},
		computed: {
			auth: function() {
				return Auth;
			},
			user: function() {
				return Auth.user;
			},
			...mapState('acad_esercizi', ['exercises', 'page']),
			
			durataTotale: function() {
				var tot = 0;
				for(var i in this.phases) {
					var fase = this.phases[i];
					tot += (fase.min_ex * fase.num_serie) + (fase.min_rec * fase.num_rec);
					if(fase.sec_rec > 0) {
						tot += fase.sec_rec/60 * fase.num_rec;
					}	
				}
				tot = Math.round(tot * 100) / 100;
				return tot;	
			},
		},
		watch: { 
		},
	}
	</script>
