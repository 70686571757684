<template id="dashboard">
	<section class="allenamenti">

		<headapp></headapp>

		<div class="">
			<menusub></menusub>
			<div class="card border-0 rounded-0">
				<div class="card-body">
					<div class="card-title">
						<span>Allenamenti</span>
					</div>
					<div class="content">
						<full-calendar ref="calendar" :event-sources="eventSources" @event-render="eventRender" @event-selected="eventSelected" @day-click="dayClick" :config="config" @event-drop="eventDrop" @event-resize="eventResize"></full-calendar>
						<b-row class="my-2">
							<b-col>
								<b-form-checkbox-group v-model="levels">
									<b-form-checkbox :value="0"><b-badge style="background:red;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Standard</b-badge></b-form-checkbox>
									<b-form-checkbox :value="1"><b-badge style="background:green;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Perfezionamento</b-badge></b-form-checkbox>
									<b-form-checkbox :value="2"><b-badge style="background:blue;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Recupero</b-badge></b-form-checkbox>
									<b-form-checkbox :value="3"><b-badge style="background:orange;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Portieri</b-badge></b-form-checkbox>
									<b-form-checkbox :value="4"><b-badge style="background:magenta;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Differenziato</b-badge> </b-form-checkbox>
									<b-form-checkbox :value="5"><b-badge style="background:grey;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Match</b-badge> </b-form-checkbox>
									<b-form-checkbox :value="-1" v-if="auth.isUserEnable('palestra_allenamenti')"><b-badge style="background:turquoise;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Palestra</b-badge> </b-form-checkbox>
									<b-form-checkbox :value="6"><b-badge style="background:purple;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Skills development</b-badge> </b-form-checkbox>
									<b-form-checkbox :value="7"><b-badge style="background:olive;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">RTP</b-badge> </b-form-checkbox>
								</b-form-checkbox-group>
							</b-col>
						</b-row>
						<b-tabs>
							<b-tab title="Percentuali allenate skills giocatore" active id="tab_giocatore">
								<b-row class="mt-2">
									<b-col cols="3">
										<b-form-group
										label="Dal" :label-cols="2" label-text-align="right" horizontal>
										<b-form-input type="date" v-model="start_perc"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col cols="3">
									<b-form-group
									label="Al" :label-cols="2" label-text-align="right" horizontal>
									<b-form-input type="date" v-model="end_perc"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col>
								<b-button variant="primary" @click.prevent="getAllenPercentSkills()">Visualizza</b-button>
								<b-button class="ml-1" variant="primary" @click.prevent="pdfAllenPercentSkills()">Stampa</b-button>
							</b-col>
						</b-row>
						<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
						<b-row v-if="!loading">
							<b-col v-for="item in percent_skills">
								<b-card :title="item.it +  ' - ' + getPercent(item.fields) + ' %'">
									<b-list-group v-if="team && !team.categoria.only_root_skill">
										<b-list-group-item v-for="skill in item.fields">
											<b-row :style="'width:100%;' + (skill.fields ? 'cursor:pointer;' : '')">
												<b-col cols="11">
													<b-row align-v="center">
														<b-col cols="5" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
															{{skill[$i18n.t('lang')]}}
														</b-col>
														<b-col cols="5" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
															<b-progress v-if="!skill.fields" :value="skill.percent ? skill.percent : 0" :max="max" variant="success">
															</b-progress>
															<b-progress v-if="skill.fields" :value="getPercent(skill.fields)" :max="max" variant="success">	
															</b-progress>
														</b-col>
														<b-col cols="2">
															{{ !skill.fields ? (skill.percent ? skill.percent + ' %' : '0 %') : getPercent(skill.fields) + ' %' }}
														</b-col>
													</b-row>
												</b-col>
												<b-col cols="1" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
													<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
												</b-col>
											</b-row>						
											<b-collapse :id="skill.id" style="width:100%">
												<b-list-group v-if="skill.fields">
													<b-list-group-item v-for="subskill in skill.fields">

														<b-row :style="'width:100%;' + (subskill.fields ? 'cursor:pointer;' : '')">
															<b-col cols="11">
																<b-row align-v="center">
																	<b-col cols="5" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																		{{subskill[$i18n.t('lang')]}}
																	</b-col>
																	<b-col cols="5" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																		<b-progress v-if="!subskill.fields" :value="subskill.percent ? subskill.percent : 0" :max="max" variant="success">
																		</b-progress>
																		<b-progress v-if="subskill.fields" :value="getPercent(subskill.fields)" :max="max" variant="success">	
																		</b-progress>
																	</b-col>
																	<b-col cols="2">
																		{{ !subskill.fields ? (subskill.percent ? subskill.percent + ' %' : '0 %') : getPercent(subskill.fields) + ' %' }}
																	</b-col>
																</b-row>
															</b-col>
															<b-col cols="1">
																<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
																<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
															</b-col>
														</b-row>						
														<b-collapse :id="subskill.id" style="width:100%">
															<b-list-group v-if="subskill.fields">
																<b-list-group-item v-for="subskill2 in subskill.fields">
																	<b-row style="width:100%">
																		<b-col cols="11">
																			<b-row align-v="center">
																				<b-col cols="5">
																					{{subskill2[$i18n.t('lang')]}}	
																				</b-col>
																				<b-col cols="5">
																					<b-progress :value="subskill2.percent ? subskill2.percent : 0" :max="max" variant="success">
																					</b-progress>
																				</b-col>
																				<b-col cols="2">
																					{{ subskill2.percent ? subskill2.percent + ' %' : '0 %' }}
																				</b-col>
																			</b-row>								
																		</b-col>
																		<b-col cols="1">
																		</b-col>
																	</b-row>
																</b-list-group-item>
															</b-list-group>
														</b-collapse>
													</b-list-group-item>
												</b-list-group>
											</b-collapse>
										</b-list-group-item>
									</b-list-group>

									<b-list-group v-if="team && team.categoria.only_root_skill">
										<b-list-group-item v-for="skill in item.fields">
											<b-row style="'width:100%;">
												<b-col cols="12">
													<b-row align-v="center">
														<b-col cols="5">
															{{skill[$i18n.t('lang')]}}
														</b-col>
														<b-col cols="5">
															<b-progress :value="skill.percent ? skill.percent : 0" :max="max" variant="success">
															</b-progress>
														</b-col>
														<b-col cols="2">
															{{ skill.percent ? skill.percent + ' %' : '0 %' }}
														</b-col>
													</b-row>
												</b-col>
											</b-row>
										</b-list-group-item>
									</b-list-group>

								</b-card>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab title="Percentuali allenate skills portiere" id="tab_portiere">
						<b-row class="mt-2">
							<b-col cols="3">
								<b-form-group
								label="Dal" :label-cols="2" label-text-align="right" horizontal>
								<b-form-input type="date" v-model="start_perc"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<b-form-group
							label="Al" :label-cols="2" label-text-align="right" horizontal>
							<b-form-input type="date" v-model="end_perc"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col>
						<b-button variant="primary" @click.prevent="getAllenPercentSkills()">Visualizza</b-button>
						<b-button class="ml-1" variant="primary" @click.prevent="pdfAllenPercentSkills()">Stampa</b-button>
					</b-col>
				</b-row>
				<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				<b-row v-if="!loading">
					<b-col v-for="item in percent_skills_portiere">
						<b-card :title="item.it +  ' - ' + getPercent(item.fields) + ' %'">
							<b-list-group v-if="team && !team.categoria.only_root_skill">
								<b-list-group-item v-for="skill in item.fields">
									<b-row :style="'width:100%;' + (skill.fields ? 'cursor:pointer;' : '')">
										<b-col cols="11">
											<b-row align-v="center">
												<b-col cols="5" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													{{skill[$i18n.t('lang')]}}
												</b-col>
												<b-col cols="4" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''">
													<b-progress v-if="!skill.fields" :value="skill.percent ? skill.percent : 0" :max="max" variant="danger">
													</b-progress>
													<b-progress v-if="skill.fields" :value="getPercent(skill.fields)" :max="max" variant="danger">	
													</b-progress>
												</b-col>
												<b-col cols="3">
													{{ !skill.fields ? (skill.percent ? skill.percent + ' %' : '0 %') : getPercent(skill.fields) + ' %' }}
												</b-col>
											</b-row>
										</b-col>
										<b-col cols="1" v-b-toggle="skill.id" :href="skill.fields ? '#' : ''" align-self="center">
											<i v-if="skill.fields" class="skill-closed fa fa-plus-square"></i>
											<i v-if="skill.fields" class="skill-opened fa fa-minus-square"></i>
										</b-col>
									</b-row>						
									<b-collapse :id="skill.id" style="width:100%">
										<b-list-group v-if="skill.fields">
											<b-list-group-item v-for="subskill in skill.fields">

												<b-row :style="'width:100%;' + (subskill.fields ? 'cursor:pointer;' : '')">
													<b-col cols="11">
														<b-row align-v="center">
															<b-col cols="5" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																{{subskill[$i18n.t('lang')]}}
															</b-col>
															<b-col cols="4" v-b-toggle="subskill.id" :href="subskill.fields ? '#' : ''">
																<b-progress v-if="!subskill.fields" :value="subskill.percent ? subskill.percent : 0" :max="max" variant="danger">
																</b-progress>
																<b-progress v-if="subskill.fields" :value="getPercent(subskill.fields)" :max="max" variant="danger">	
																</b-progress>
															</b-col>
															<b-col cols="3">
																{{ !subskill.fields ? (subskill.percent ? subskill.percent + ' %' : '0 %') : getPercent(subskill.fields) + ' %' }}
															</b-col>
														</b-row>
													</b-col>
													<b-col cols="1" align-self="center">
														<i v-if="subskill.fields" class="subskill-closed fa fa-plus-square"></i>
														<i v-if="subskill.fields" class="subskill-opened fa fa-minus-square"></i>
													</b-col>
												</b-row>						
												<b-collapse :id="subskill.id" style="width:100%">
													<b-list-group v-if="subskill.fields">
														<b-list-group-item v-for="subskill2 in subskill.fields">
															<b-row style="width:100%">
																<b-col cols="11">
																	<b-row align-v="center">
																		<b-col cols="5">
																			{{subskill2[$i18n.t('lang')]}}	
																		</b-col>
																		<b-col cols="4">
																			<b-progress :value="subskill2.percent ? subskill2.percent : 0" :max="max" variant="danger">
																			</b-progress>
																		</b-col>
																		<b-col cols="3">
																			{{ subskill2.percent ? subskill2.percent + ' %' : '0 %' }}
																		</b-col>
																	</b-row>								
																</b-col>
																<b-col cols="1">
																</b-col>
															</b-row>
														</b-list-group-item>
													</b-list-group>
												</b-collapse>
											</b-list-group-item>
										</b-list-group>
									</b-collapse>
								</b-list-group-item>
							</b-list-group>

							<b-list-group v-if="team && team.categoria.only_root_skill">
								<b-list-group-item v-for="skill in item.fields">
									<b-row style="'width:100%;">
										<b-col cols="12">
											<b-row align-v="center">
												<b-col cols="5">
													{{skill[$i18n.t('lang')]}}
												</b-col>
												<b-col cols="5">
													<b-progress :value="skill.percent ? skill.percent : 0" :max="max" variant="success">
													</b-progress>
												</b-col>
												<b-col cols="2">
													{{ skill.percent ? skill.percent + ' %' : '0 %' }}
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</b-list-group-item>
							</b-list-group>

						</b-card>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>
	</div>
</div>
</div>
</div>

<footerapp></footerapp>

<b-modal id="eventModal" ref="eventModal" :title="event_id ? 'Allenamento' : 'Aggiungi Allenamento'" centered  onlyCancel @ok="handleOk" @cancel="handleCancel" size="lg">
	<b-container fluid>
		<form @submit.stop.prevent="handleSubmit">
			<b-row class="mb-1">
				<b-col>
					<b-row class="mb-1" v-if="level != 3 && level != -1">

						<b-col>
							<label>Principi di gioco</label>
							<b-form-select v-model="game_principles">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in skills1" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>
						<b-col>

							<label>	Componenti tecniche</label>

							<b-form-select v-model="technical_component">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in skills2" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>
						<b-col>

							<label>	Capacità motorie</label>
							<b-form-select v-model="motor_skill">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in skills3" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>

					</b-row>

					<b-row class="mb-2" v-if="level == 3">

						<b-col>
							<label>Tecnica podalica</label>
							<b-form-select v-model="tecnica_podalica">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in skills4" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>
						<b-col>

							<label>	Tecnica di base </label>

							<b-form-select v-model="tecnica_di_base">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in skills5" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>
						<b-col>

							<label>	Qualità tattiche</label>
							<b-form-select v-model="qualita_tattiche">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in skills6" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>
						<b-col>
							<label>	Capacità motorie</label>
							<b-form-select v-model="motor_skill">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in skills7" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>

					</b-row>

					<b-row class="mb-2" v-if="level == -1">

						<b-col>
							<label>Work</label>
							<b-form-select v-model="work">
								<option :value="null">seleziona</option>
								<option :value="item.id" v-for="item in works" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>
						<b-col>

							<label>	Giocatore / Squadra </label>

							<b-form-select v-model="rosa">
								<option :value="-1">seleziona</option>
								<option :value="0">Squadra</option>
								<option :value="-2">Portieri</option>
								<option :value="item.id" v-for="item in players" :key="item.id">{{item.name}}</option>
							</b-form-select>

						</b-col>
					</b-row>
					<b-row class="mb-2" v-if="!event_id && level == -1">
						<b-col>
							<label>Dal</label>
							<b-form-input v-model="from" type="date"></b-form-input>
						</b-col>
						<b-col>

							<label>Al</label>
							<b-form-input v-model="to" type="date"></b-form-input>
						</b-col>
					</b-row>

					<b-row class="mb-1" v-if="!event_id || (event_id && level != -1)">
						<b-col>
							<b-form-radio-group id="level" v-model="level" name="radioLevel">
								<b-form-radio value="0">Standard</b-form-radio>
								<b-form-radio value="1">Perfez.</b-form-radio>
								<b-form-radio value="2">Recupero</b-form-radio>
								<b-form-radio value="3">Portieri</b-form-radio>
								<b-form-radio value="4">Differenziato</b-form-radio>
								<b-form-radio value="5">Match</b-form-radio>
								<b-form-radio value="-1" v-if="!event_id && auth.isUserEnable('palestra_allenamenti')">Palestra</b-form-radio>
								<b-form-radio value="6">Skills development</b-form-radio>
								<b-form-radio value="7">RTP</b-form-radio>
							</b-form-radio-group>
						</b-col>
					</b-row>
					<b-row class="mt-3" v-if="event_id && level != -1">
						<b-col>
							<b-button v-show="event_id" :to="{ path: `/football/allenamento/${this.event_id}/presenze` }" variant="outline-primary">Presenze</b-button>
							<b-button v-show="event_id" :to="{ path: `/football/allenamento/${this.event_id}` }" variant="outline-primary">Personalizza</b-button>
							<b-button v-show="event_id" @click="duplica" variant="outline-primary"><i class="fa fa-arrow-up" aria-hidden="true" v-if="clona"></i><i class="fa fa-arrow-down" aria-hidden="true" v-if="!clona"></i> Duplica</b-button>
							<b-button v-show="event_id && auth.isLastSeason(season_id)" @click="eliminaAllenamento" variant="outline-primary">Elimina</b-button>
							<b-button variant="outline-primary" v-if="event_id" @click="downloadPdf">Stampa pdf</b-button>
							<b-button variant="outline-primary" v-if="event_id && auth.isUserEnable('video_allenamenti') && !match_id" :to="{ name: 'football_video_allenamento', params: { id: event_id }}"> <i class="fa fa-video-camera" aria-hidden="true"></i> Video</b-button>
							<b-button variant="outline-primary" v-if="event_id && auth.isUserEnable('video_partite') && match_id" :to="{ name: 'football_video_partita', params: { id: match_id }}"> <i class="fa fa-video-camera" aria-hidden="true"></i> Video</b-button>
						</b-col>
					</b-row>
					<b-row class="mt-3" align-v="center" v-if="clona">
						<b-col>
							<b-form-group label="Data">
								<b-form-input v-model="data_clone" type="date"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="Ora">
								<b-form-input v-model="ora_clone" type="time"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-button variant="outline-primary" v-if="event_id" class="mt-3" @click="duplicaAllenamento">Crea duplicato</b-button>
						</b-col>
					</b-row>
					<b-row class="mt-3" v-if="event_id && level == -1">
						<b-col>
							<b-button v-show="event_id" :to="{ path: `/football/palestra/allenamento/${this.event_id}` }" variant="outline-primary">Personalizza</b-button>
							<b-button v-show="event_id" @click="duplicaAllenPalestra" variant="outline-primary"><i class="fa fa-arrow-up" aria-hidden="true" v-if="clona_palestra"></i><i class="fa fa-arrow-down" aria-hidden="true" v-if="!clona_palestra"></i> Duplica</b-button>
							<b-button v-show="event_id && auth.isLastSeason(season_id)" @click="eliminaAllenamentoPalestra" variant="outline-primary">Elimina</b-button>
							<b-button variant="outline-primary" v-if="event_id" @click="downloadPdfPalestra">Stampa pdf</b-button>
						</b-col>
					</b-row>
					<b-row class="mt-3" align-v="center" v-if="clona_palestra">
						<b-col>
							<b-form-group label="Data">
								<b-form-input v-model="data_clone" type="date"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="Ora">
								<b-form-input v-model="ora_clone" type="time"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-button variant="outline-primary" v-if="event_id" class="mt-3" @click="duplicaAllenamentoPalestra">Crea duplicato</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</form>
	</b-container>
</b-modal>
<b-modal ref="dateModal" title="Vai alla data" ok-only ok-title="Chiudi">
	<b-form-group label="Seleziona una data">
		<b-form-input v-model="go_to_date" type="date"></b-form-input>
	</b-form-group>
</b-modal>

</section>
</template>

<script>
import moment from 'moment'
import { FullCalendar } from 'vue-full-calendar'
//import '@/lang/agenda.js'
import * as types from '@/store/types'
import axios from 'axios';
import { mapState } from 'vuex';
var Auth = require('@/auth.js');
export default {

	data: function(){
		return{
			game_principles:     	null,
			technical_component: 	null,
			motor_skill:        	null,
			tecnica_podalica:     	null,
			tecnica_di_base: 		null,
			qualita_tattiche:       null,
			skills: 	            [],
			skills_portiere: 	    [],
			skills1: 	            [],
			skills2: 	            [],
			skills3: 	            [],
			skills4: 	            [],
			skills5: 	            [],
			skills6: 	            [],
			skills7: 	            [],
			events: 				[],
			percent_skills: 		[],
			percent_skills_portiere:[],
			max: 100,
			config:
			{
				disableResizing: false,
				editable: true,
				defaultView: 'month',
				locale: 'it',
				header: {
					left: 'prev,next prevYear,nextYear today custom',
				},
				customButtons: {
					custom: {
						text: 'date',
						click: function() {
							this.showDateModal();
						}.bind(this)
					},
				},
				viewRender: (view) => {
					var start = moment(view.intervalStart).format("YYYY-MM-DD");
					var end = moment(view.intervalEnd).format("YYYY-MM-DD"); 
					this.start_perc = start;
					this.end_perc = end;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/allenamenti/percent/skills', {params: { team_id: this.rosaTeamId, start: start, end: end }}).then((response) => {
						if (response.ok) {
							this.percent_skills = response.data.percent_skills;
							this.percent_skills_portiere = response.data.percent_skills_portiere;
							this.loading = false;
						}
					}, (response) => {
					});
				},
			},
			selected: null,
			event_id: null,
			title: '',
			level: 0,
			start_at: '',
			end_at: '',
			team: null,
			clona: false,
			data_clone: null,
			ora_clone: null,
			season_id: null,
			start_perc: null,
			end_perc: null,
			loading: false,
			players: [],
			works: [],
			work: null,
			rosa: null,
			clona_palestra: false,
			from: null,
			to: null, 
			match_id: null,
			view_calendar: false,
			go_to_date: null,
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
		levels: {
			get () {
				return this.$store.state.levels;
			},
			set (value) {
				this.$store.commit('changeLevels', value);
			}
		},

		eventSources: function() {
			const self = this;

			var events = [];

			if((self.levels.length == 1 && !self.levels.includes(-1)) || self.levels.length > 1) {
				events.push({
					events(start, end, timezone, callback) {
						var rosaTeamId = $("#team_football option:selected").val();
						var start = moment(start).format("YYYY-MM-DD HH:mm:ss")
						var end = moment(end).format("YYYY-MM-DD HH:mm:ss")					
						const formData = new FormData();
						formData.append("start_at", start);
						formData.append("end_at", end);
						formData.append("team_id", rosaTeamId);
						formData.append("levels", self.levels);
						formData.set('_method', 'POST');

						const opts = {
							url: `/allenamenti/get`,
							method: 'POST',
							data: formData,
						};

						axios(opts)
						.then((res) => {
							var data = res.data.filter(item => self.levels.includes(item.level)).map(item => ({
								title: '\n' + item.title.replace(/ - /g, '\n'),
								start: item.start_at,
								end: item.end_at,
								game_principles: item.game_principles,
								technical_component: item.technical_component,
								motor_skill: item.motor_skill,
								tecnica_podalica: item.tecnica_podalica,
								tecnica_di_base: item.tecnica_di_base,
								qualita_tattiche: item.qualita_tattiche,
								id: item.id,
								level: item.level,
								color: (item.level == 0 ? 'red' : (item.level == 1 ? 'green' : (item.level == 2 ? 'blue' : (item.level == 3 ? 'orange' : (item.level == 4 ? 'magenta' : (item.level == 5 ? 'gray' : (item.level == 6 ? 'purple' : 'red'))))))),
								textColor: '#FFF',
								season_id: item.season_id,
								match_id: item.match_id,
							}));

							callback(data);
						}).catch((err) => {
							console.log(err);
						});
					},
					color: '#F7CA18',
					textColor: '#a80532',
				});

			}

			if(self.auth.isUserEnable('palestra_allenamenti') && self.levels.includes(-1)) {
				events.push({
					events(start, end, timezone, callback) {
						var rosaTeamId = $("#team_football option:selected").val();

						var start = moment(start).format("YYYY-MM-DD HH:mm:ss")
						var end = moment(end).format("YYYY-MM-DD HH:mm:ss")					
						const formData = new FormData();
						formData.append("start_at", start);
						formData.append("end_at", end);
						formData.append("team_id", rosaTeamId);
						formData.set('_method', 'POST');

						const opts = {
							url: `/palestra/allenamenti`,
							method: 'POST',
							data: formData,
						};

						axios(opts)
						.then((res) => {
							var data = res.data.map(item => ({
								title: item.work.name + '\n' + (item.rosa ? item.rosa.cognome + " " + item.rosa.nome : (item.portieri ? 'Portieri' : 'Squadra')),
								start: item.start_at,
								end: item.start_at,
								level: -1,
								id: item.id,
								color: 'turquoise',
								textColor: '#FFF',
								season_id: item.id_stagione,
								rosa_id: item.persona_id ? item.persona_id : (item.portieri ? -2 : 0),
								work_id: item.id_work,
							}));

							callback(data);
						}).catch((err) => {
							console.log(err);
						});
					},
					color: '#F7CA18',
					textColor: '#a80532',
				});

			}
			return events;
		} 
	},

	watch: {
		rosaTeamId() {
			this.getTeam();
			this.getPlayers();
			this.refreshEvents();
		}, 
		level: function (val, oldVal) {
			if(oldVal == 3) {
				this.tecnica_podalica = null;
				this.tecnica_di_base = null;
				this.qualita_tattiche = null;
				this.motor_skill = null;
			}
			if(val == 3) {
				this.game_principles = null;
				this.technical_component = null;
				this.motor_skill = null;
			}
		}, 
		go_to_date() {
			if(this.go_to_date) {
				this.$refs.calendar.fireMethod('gotoDate', this.go_to_date);
			}	
		}
	},

	created() {
		this.getTeam();
		this.getPlayers();
		this.getWork();
	},

	components: {
		headapp: require('../../../layout/headapp.vue').default,
		menusub: require('../../../layout/menusub.vue').default,
		footerapp: require('../../../layout/footerapp.vue').default,
		FullCalendar,
	},

	methods: {

		handleCancel (evt) {
			this.event_id = null;
			this.title = '';
			this.level = 0;
			this.game_principles = null;
			this.technical_component = null;
			this.motor_skill = null;
			this.tecnica_podalica = null;
			this.tecnica_di_base = null;
			this.qualita_tattiche = null;
			this.season_id 	= null;
			this.work = null;
			this.rosa = -1;
			this.match_id = null;
		},
		handleOk (evt) {
			evt.preventDefault();
			this.handleSubmit();
		},

		handleSubmit () {
			if(this.level == -1) {
				if(!this.work) {
					alert("Campo 'Work' obbligatorio!");
					return;
				}
				if(this.rosa == -1) {
					alert("Devi selezionare la squadra o un giocatore della rosa!");
					return;
				}
				if(!this.event_id){
					this.addAllenamentoPalestra();
				} else {
					if(Auth.isLastSeason(this.season_id)) {
						const formData = new FormData();
						formData.append("start_at",  this.start_at);
						formData.append("work",  this.work);
						formData.append("rosa",  this.rosa);
						formData.append("no_delete_exe",  true);
						this.updateAllenamentoPalestra(formData,  this.event_id);
					} else {
						this.$refs.eventModal.hide();
					}
				}
				this.event_id = null;
				this.level = 0;
				this.rosa = -1;
				this.work = null;
				this.season_id = null;

			} else {
				if(!this.event_id){
					this.create();
				} else {
					if(Auth.isLastSeason(this.season_id)) {
						const formData = new FormData();
						formData.append("start_at",  this.start_at);
						formData.append("end_at",  this.end_at);
						formData.append("title",  this.getTitle());
						formData.append("level",  this.level);
						formData.append("game_principles",  this.game_principles);
						formData.append("technical_component",  this.technical_component);
						formData.append("motor_skill",  this.motor_skill);
						formData.append("tecnica_podalica",  this.tecnica_podalica);
						formData.append("tecnica_di_base",  this.tecnica_di_base);
						formData.append("qualita_tattiche",  this.qualita_tattiche);
						formData.append("no_delete_phases",  true);
						this.update(formData,  this.event_id);
					} else {
						this.$refs.eventModal.hide();
					}
				}
				this.event_id = null;
				this.title = '';
				this.level = 0;
				this.game_principles = null;
				this.technical_component = null;
				this.motor_skill = null;
				this.tecnica_podalica = null;
				this.tecnica_di_base = null;
				this.qualita_tattiche = null;
				this.season_id = null;
				this.match_id = null;
			}
		},

		removeEvent() {
			this.$refs.calendar.$emit('remove-event', this.selected);
			this.selected = {};
		},
		eventSelected(event) {
			if(event.level == -1) {
				this.event_id = event.id;
				this.level = event.level;
				this.start_at = moment(event.start).format("YYYY-MM-DD HH:mm:ss");
				this.selected = event;
				this.work = event.work_id;
				this.rosa = event.rosa_id;
				this.season_id = event.season_id;
			} else {
				this.get_skills();

				this.event_id = event.id;
				this.title = event.title;
				this.level = event.level;
				this.start_at = moment(event.start).format("YYYY-MM-DD HH:mm:ss");
				this.end_at = moment(event.end).format("YYYY-MM-DD HH:mm:ss");
				this.selected  = event;
				this.game_principles = event.game_principles;
				this.technical_component = event.technical_component;
				this.motor_skill = event.motor_skill;
				this.tecnica_podalica = event.tecnica_podalica;
				this.tecnica_di_base = event.tecnica_di_base;
				this.qualita_tattiche = event.qualita_tattiche;
				this.season_id = event.season_id;
				this.match_id = event.match_id;
			}
			this.$refs.eventModal.show();
		},
		eventRender(event) {
			this.game_principles = null;
			this.technical_component = null;
			this.motor_skill = null;
			this.tecnica_podalica = null;
			this.tecnica_di_base = null;
			this.qualita_tattiche = null;
			this.work = null;
			this.rosa = -1;
		},

		dayClick(date, jsEvent, view) {
			this.selected = null;

			this.event_id = null;
			this.game_principles = null;
			this.technical_component = null;
			this.motor_skill = null;
			this.tecnica_podalica = null;
			this.tecnica_di_base = null;
			this.qualita_tattiche = null;
			this.work = null;
			this.rosa = -1;
			this.level = 0;
			this.match_id = null;

			this.start_at = moment(date['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss");
			this.end_at = moment(date['_d']).add({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss");
			this.from = moment(date['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD");
			this.to = moment(date['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD");
			this.$refs.eventModal.show();

			this.get_skills();

		},
		eventDrop(event, delta, revertFunc) {
			const formData = new FormData();
			formData.append("drop",  true);
			formData.append("start_at",  moment(event['start']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			if(event['end']) {
				formData.append("end_at",  moment(event['end']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			} else {
				formData.append("end_at",  moment(event['start']['_d']).add({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			}
			if(event['level'] != -1) {
				this.update(formData,  event['id']);
			} else {
				this.updateAllenamentoPalestra(formData,  event['id']);
			}
		},
		eventResize(event, delta, revertFunc) {
			const formData = new FormData();
			formData.append("drop",  true);
			formData.append("start_at",  moment(event['start']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			formData.append("end_at",  moment(event['end']['_d']).subtract({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
			if(event['level'] != -1) {
				this.update(formData,  event['id']);
			} else {
				this.updateAllenamentoPalestra(formData,  event['id']);
			}
		},
		refreshEvents() {
			this.$refs.calendar.$emit('refetch-events');
			this.$refs.calendar.fireMethod('changeView', 'basicDay');
			this.$refs.calendar.fireMethod('changeView', 'month');
		},
		update(data, id){
			this.$http.post(this.$store.state.apiEndPoint + '/allenamenti/update/'+id, data).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		updateAllenamentoPalestra(data, id){
			this.$http.post(this.$store.state.apiEndPoint + '/palestra/allenamento/edit/'+id, data).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		get_skills(){
			axios.get(`/skills`)
			.then((res) => {
				this.skills = JSON.parse(res.data.standard);
				this.skills_portiere = JSON.parse(res.data.special);

				this.skills1 = this.getOptionsSkills(this.skills[0]);
				this.skills2 = this.getOptionsSkills(this.skills[1]);
				this.skills3 = this.getOptionsSkills(this.skills[2]);

				this.skills4 = this.getOptionsSkills(this.skills_portiere[0]);
				this.skills5 = this.getOptionsSkills(this.skills_portiere[1]);
				this.skills6 = this.getOptionsSkills(this.skills_portiere[2]);
				this.skills7 = this.getOptionsSkills(this.skills_portiere[3]);
			}).catch((err) => {
			});
		},

		getOptionsSkills(skills) {
			var options = [];
			var skill_items = skills.fields;
			for(var key in skill_items) {
				var skill = skill_items[key];

				var option = {
					id: skill.id,
					name: skill.it,
				}
				options.push(option);
			}
			return options;
		},

		create(){
			const formData = new FormData();
			formData.append("title", this.getTitle());
			formData.append("team_id", this.rosaTeamId);
			formData.append("level", this.level);
			formData.append("start_at", this.start_at);
			formData.append("end_at", this.end_at);
			formData.append("game_principles",  this.game_principles);
			formData.append("technical_component",  this.technical_component);
			formData.append("motor_skill",  this.motor_skill);
			formData.append("tecnica_podalica",  this.tecnica_podalica);
			formData.append("tecnica_di_base",  this.tecnica_di_base);
			formData.append("qualita_tattiche",  this.qualita_tattiche);

			this.$http.post(this.$store.state.apiEndPoint + '/allenamenti', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		addAllenamentoPalestra(){
			const formData = new FormData();
			formData.append("team_id", this.rosaTeamId);
			formData.append("start_at", this.start_at);
			formData.append("work",  this.work);
			formData.append("rosa",  this.rosa);
			formData.append("from", this.from);
			formData.append("to", this.to);

			this.$http.post(this.$store.state.apiEndPoint + '/palestra/allenamento/add', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		show(){
			var event_id = this.event_id;
			this.$router.push({ path: `/football/allenamento/${event_id}` })
		},
		downloadPdf(){
			window.open(this.$store.state.apiEndPoint + '/allenamento/' + this.event_id + "/pdf", "_blank");
		},
		downloadPdfPalestra(){
			window.open(this.$store.state.apiEndPoint + '/palestra/allenamento/' + this.event_id + "/pdf", "_blank");
		},
		eliminaAllenamento(){
			if(confirm("Confermi l'eliminazione dell'allenamento ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/allenamenti/delete/' + this.event_id).then((response) => {

					if (response.ok)
					{
						this.event_id = null;
						this.match_id = null;
						this.title = '';
						this.level = 0;
						this.game_principles = null;
						this.technical_component = null;
						this.motor_skill = null;
						this.tecnica_podalica = null;
						this.tecnica_di_base = null;
						this.qualita_tattiche = null;
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
				});

			}
		},
		eliminaAllenamentoPalestra() {
			if(confirm("Confermi l'eliminazione dell'allenamento ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/palestra/allenamento/delete/' + this.event_id).then((response) => {

					if (response.ok)
					{
						this.event_id = null;
						this.title = '';
						this.level = 0;
						this.work = null;
						this.rosa = -1;
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
				});

			}
		},
		getTitle() {
			var title = "";
			if(this.level == 3) {
				if(this.tecnica_podalica != null && this.tecnica_podalica != 'null') {
					title = this.getLabel(this.tecnica_podalica, this.skills_portiere[0]);
				}
				if(this.tecnica_di_base != null && this.tecnica_di_base != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.tecnica_di_base, this.skills_portiere[1]);
				}
				if(this.qualita_tattiche != null && this.qualita_tattiche != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.qualita_tattiche, this.skills_portiere[2]);
				}
				if(this.motor_skill != null && this.motor_skill != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.motor_skill, this.skills_portiere[3]);
				}
			} else {
				if(this.game_principles != null && this.game_principles != 'null') {
					title = this.getLabel(this.game_principles, this.skills[0]);
				}
				if(this.technical_component != null && this.technical_component != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.technical_component, this.skills[1]);
				}
				if(this.motor_skill != null && this.motor_skill != 'null') {
					if(title != "") {
						title += " - ";
					}
					title += this.getLabel(this.motor_skill, this.skills[2]);
				}
			}
			return title;
		},
		getLabel(id_skill, skills) {
			var label = "N/D";
			if(id_skill && skills) {
				var skill_items = skills.fields;
				for(var key in skill_items) {
					var skill = skill_items[key];
					if(skill.id == id_skill) {
						label = skill.it;
						return label;
					}
				}
			}
			return label;
		},
		getPercent(skills) {
			var tot = 0;
			for(var i in skills) {
				var item = skills[i];
				if(!this.team.categoria.only_root_skill) {
					if(item.fields) { 
						var subTot = 0;
						var fields = item.fields;
						for(var x in fields) {
							var subitem = fields[x];
							if(subitem.fields) {
								var subTot2 = 0;
								var fields2 = subitem.fields;
								for(var y in fields2) {
									var subitem2 = fields2[y];
									if(subitem2.percent) {
										subTot2 += subitem2.percent;
									}
								}
								subTot += subTot2;
							} else {
								if(subitem.percent) {
									subTot += subitem.percent;
								}
							}
						}
						tot += subTot;
					} else {
						if(item.percent) {
							tot += item.percent;
						}
					}
				} else {
					if(item.percent) {
						tot += item.percent;
					}
				}
			}
			return Math.round(tot * 100) / 100;
		},
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},
		duplica() {
			this.clona = !this.clona;
		},
		duplicaAllenPalestra() {
			this.clona_palestra = !this.clona_palestra;
		},
		duplicaAllenamento(){
			if(this.data_clone && this.ora_clone) {
				const formData = new FormData();
				formData.append("start_at", this.data_clone + ' ' + this.ora_clone);
				formData.append("end_at", moment(this.data_clone + ' ' + this.ora_clone).add({ hours: 1 }).format("YYYY-MM-DD HH:mm:ss"));
				this.$http.post(this.$store.state.apiEndPoint + '/allenamento/duplica/' + this.event_id, formData).then((response) => {
					if (response.ok){
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
						this.clona = false;
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			} else {
				alert("Devi selezionare la data e l'ora dell'allenamento da duplicare!");
			}
		},
		duplicaAllenamentoPalestra(){
			if(this.data_clone && this.ora_clone) {
				const formData = new FormData();
				formData.append("start_at", this.data_clone + ' ' + this.ora_clone);
				this.$http.post(this.$store.state.apiEndPoint + '/palestra/allenamento/duplica/' + this.event_id, formData).then((response) => {
					if (response.ok){
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
						this.clona_palestra = false;
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			} else {
				alert("Devi selezionare la data e l'ora dell'allenamento da duplicare!");
			}
		},
		getAllenPercentSkills() {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/allenamenti/percent/skills', {params: { team_id: this.rosaTeamId, start: this.start_perc, end: this.end_perc }}).then((response) => {
				if (response.ok) {
					this.percent_skills = response.data.percent_skills;
					this.percent_skills_portiere = response.data.percent_skills_portiere;
					this.loading = false;
				}
			}, (response) => {
			});
		},

		pdfAllenPercentSkills() {
			window.location = this.$store.state.apiEndPoint + '/pdf/allenamenti/percent/skills/' + this.rosaTeamId + "/" + this.start_perc + "/" + this.end_perc + "/" + Auth.user.id + "/" + Auth.user.api_token;	
		},

		getPlayers: function() {				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then((response) => {
				if (response.ok)
				{
					this.players = response.data.map(item => ({								
						name: item.cognome + ' ' + item.nome,
						id: item.id,
					}));
				}
			}, (response) => {
			});
		},

		getWork: function() {				
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/work').then((response) => {
				if (response.ok)
				{
					this.works = response.data;
				}
			}, (response) => {
			});
		},

		showDateModal: function() {
			this.go_to_date = null;
			this.$refs.dateModal.show();
		},
	}

}
</script>

<style>
.fc-resizer.fc-end-resizer {
	display: none;
}
</style>
