<template>
	<div>

		<label>Nome</label>
		<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>
		<b-row>
			<b-col>
				<label>Durata</label>
				<b-form-input :disabled="disabled" v-model="fase.durata" type="time"></b-form-input>
			</b-col>
			<b-col>
				<label>Durata effettiva</label>
				<b-form-input :disabled="disabled" v-model="fase.durata_reale" type="time"></b-form-input>
			</b-col>
		</b-row>
		
		<b-row class="mb-2">
			<b-col>
				<label>Rep/Tempo</label>
				<b-form-radio-group :disabled="disabled" v-model="fase.entity.has_reps">
					<b-form-radio :value="1">Rep</b-form-radio>
					<b-form-radio :value="0">Tempo</b-form-radio>
				</b-form-radio-group>
			</b-col>
			<b-col v-if="fase.entity.reps > 0">
				<label>{{ fase.entity.has_reps ? 'Reps' : 'Tempo di esecuzione (minuti)'}}</label>
				<b-form-input :disabled="disabled" type="number" v-model.number="fase.entity.reps"></b-form-input>
			</b-col>
			<b-col v-if="fase.entity.has_reps && fase.entity.reps_dx > 0">
				<label>Reps dx</label>
				<b-form-input :disabled="disabled" type="number" min="0" v-model="fase.entity.reps_dx"></b-form-input>
			</b-col>
			<b-col v-if="fase.entity.has_reps && fase.entity.reps_sx > 0">
				<label>Reps sx</label>
				<b-form-input :disabled="disabled" type="number" min="0" v-model="fase.entity.reps_sx"></b-form-input>
			</b-col>
			<b-col>
				<label>Sets</label>
				<b-form-input type="number" :disabled="disabled" min="0" v-model="fase.entity.sets"></b-form-input>
			</b-col>
			<b-col>
				<label>Rest (minuti)</label>
				<b-form-input :disabled="disabled" v-model="fase.entity.rest"></b-form-input>
			</b-col>
		</b-row>
		<b-row v-if="!fase.entity.esercizio_id" class="mt-3">
			<b-col cols="6">
				<b-input-group>
					<b-form-input v-on:keydown.enter.prevent="search_ex" type="text" v-model="ux.search" placeholder="Cerca esercizio da Rosa per titolo/autore..."></b-form-input>
					<b-input-group-append>
						<b-button variant="primary" @click="search_ex()"><i class="fa fa-search" aria-hidden="true"></i></b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<b-col cols="6">
				<b-input-group>
					<b-form-input v-on:keydown.enter.prevent="search_ex_condivisi" type="text" v-model="ux.search_condivisi" placeholder="Cerca esercizio da Archivio per titolo/autore..."></b-form-input>
					<b-input-group-append>
						<b-button variant="primary" @click="search_ex_condivisi()"><i class="fa fa-search" aria-hidden="true"></i></b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
		</b-row>
		
		<b-row v-else class="mt-3">
			<b-col cols="12" style="text-align:center;">
				<h4>{{ fase.entity.esercizio.name }}</h4>
			</b-col>
			<b-col cols="12">
				<div style="text-align:justify;">
					{{ fase.entity.esercizio.description }}
				</div>		
			</b-col>
			<b-col cols="6" v-for="media in fase.entity.esercizio.media">
				<div v-if="media.id && media.tipo == 'foto' && media.image && media.image != ''" class="text-center">
					<b-img :src="media.foto_url" :height="400"/>
				</div>
				<div v-if="media.id && media.tipo == 'video' && media.video && media.video != ''" class="text-center">
					<b-embed type="video" controls aspect="16by9" :poster="media.image" preload="none" controlsList="nodownload" oncontextmenu="return false;">
						<source :src="media.video_url"/>    
					</b-embed>
				</div>
			</b-col>
		</b-row>


		<b-modal id="exModal" ref="exModal" title="Aggiungi Esercizio da Rosa" size="lg" centered>
			<b-container fluid>
				<p>Esercizi totali: {{ exercises_archivio.total }}</p>
				<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
					<b-media class="py-3 border-bottom" v-for="ex in exercises_archivio.data" :key="ex.id">
						<b-img slot="aside" :src="ex.media.length > 0 ? (ex.media[0].tipo == 'foto' ? ex.media[0].foto_url : ex.media[0].image) : '/assets/varie/missing.jpg'" @click="setExercise(ex.id)" height="170" style="cursor:pointer;"/>
						<div class="d-flex flex-column" style="height: 170px;">
							<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
							<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>	
							<div class="flex-grow-1" style="overflow-y: auto;">
								<p class="text-muted"><small>{{ex.description}}</small></p>
							</div>
						</div>
					</b-media>
				</v-infinite-scroll>
			</b-container>
		</b-modal>
		
		<b-modal id="exCondivisiModal" ref="exCondivisiModal" title="Aggiungi Esercizio da Archivio" size="lg" centered>
			<b-container fluid>
				<p>Esercizi totali: {{ exercises_condivisi.total }}</p>
				<v-infinite-scroll @bottom="nextPageCondivisi" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
					<b-media class="py-3 border-bottom" v-for="ex in exercises_condivisi.data" :key="ex.id">
						<b-img slot="aside" :src="ex.media.length > 0 ? (ex.media[0].tipo == 'foto' ? ex.media[0].foto_url : ex.media[0].image) : '/assets/varie/missing.jpg'" @click="setExercise(ex.id)" height="170" style="cursor:pointer;"/>
						<div class="d-flex flex-column" style="height: 170px;">
							<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
							<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
							<div class="flex-grow-1" style="overflow-y: auto;">
								<p class="text-muted"><small>{{ex.description}}</small></p>
							</div>
						</div>
					</b-media>
				</v-infinite-scroll>
			</b-container>
		</b-modal>




	</div>
</template>

<script>

import InfiniteScroll from 'v-infinite-scroll';
import axios from 'axios';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	components: {
		FaseTestString: require('@/components/layout/Sessioni/Test/FaseTestString.vue').default,
		FaseTestNumber: require('@/components/layout/Sessioni/Test/FaseTestNumber.vue').default,
		FaseTestBoolean: require('@/components/layout/Sessioni/Test/FaseTestBoolean.vue').default,
		FaseTestSelect: require('@/components/layout/Sessioni/Test/FaseTestSelect.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},
	props: [ 'sessione', 'fase', 'disabled' ],
	created: function() {
	},
	data: function(){
		return {
			ux: {
				loading: false,
				search: '',
				search_condivisi: ''
			},
		}
	},
	methods: {
		doNothing: function() {
			console.log('Hello! :D')
		},
		nextPage() {
			if(this.page < this.exercises_archivio.last_page) {
				this.$store.commit('piscina/setPage', this.page + 1);
				this.$store.dispatch('piscina/filter_archivio', this.page + 1);
			}
		},
		nextPageCondivisi() {
			if(this.page < this.exercises_condivisi.last_page) {
				this.$store.commit('piscina/setPage', this.page + 1);
				this.$store.dispatch('piscina/filter_condivisi', this.page + 1);
			}
		},
		search_ex: function () {
			this.$refs.exModal.hide();
			this.$refs.exCondivisiModal.hide();
			var array = [];
			this.$store.commit('piscina/setTitle', this.ux.search);
			this.$store.commit('piscina/setPage', 1);
			this.$store.dispatch('piscina/filter_archivio');
			this.$refs.exModal.show();
		},
		search_ex_condivisi: function () {
			this.$refs.exModal.hide();
			this.$refs.exCondivisiModal.hide();
			var array = [];
			this.$store.commit('piscina/setTitle', this.ux.search_condivisi);
			this.$store.commit('piscina/setPage', 1);
			this.$store.dispatch('piscina/filter_condivisi');
			this.$refs.exCondivisiModal.show();
		},
		setExercise: function(id) {
			
			this.ux.loading = true;

			this.$http.get(this.$store.state.apiEndPoint + '/piscina/esercizio/' + id).then(function(res) {
				
				this.ux.loading = false;

				this.fase.entity.esercizio_id = res.data.id;
				this.fase.entity.esercizio = res.data;

				this.$refs.exModal.hide();
				this.$refs.exCondivisiModal.hide();

			}, function(data) {
				this.ux.loading = false;
				this.$refs.exModal.hide();
				this.$refs.exCondivisiModal.hide();
			});

		}
	},
	watch: {
		'fase.entity.reps': {
			deep: true,
			handler: function() {
				if(!this.fase.entity.has_reps) {
					this.fase.durata = moment('2000-01-01').add(this.fase.entity.reps, 'minutes').format('HH:mm'); // non funziona davvero, perché da una parte sono minuti dall'altra un time ^_^
				}
			}
		}
	},
	computed: {
		...mapState('piscina', ['exercises_archivio', 'exercises_condivisi', 'page']),
		...mapState(['rosaTeamId']),
		user: function() {
			return Auth.user;
		},
		auth: function() {
			return Auth;
		}
	}
}
</script>