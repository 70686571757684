<template id="form">
	<div class="card border-0 rounded-0">
		<div class="card-body">
			<b-container fluid>				
				<b-row>
					<b-col cols="8">
						<div v-if="title" class="card-title">
							<span>{{ title }}</span>
						</div>
						<form v-on:submit.prevent="checkBeforeSubmit()" action="#" method="POST">
							<div class="content">

								<!-- Foto -->
								<fieldset class="mb-5">
									<legend>Foto</legend>
									<b-form-group class="pb-3 border-bottom">
										<picture-input ref="pictureStaff" @change="onAvatarChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="avatar"></picture-input>
									</b-form-group>
								</fieldset>

								<!-- Generali -->
								<fieldset class="mb-5">
									<legend>Generali</legend>
									<b-form-group id="nomeGroup" horizontal label="Nome" :label-cols="3" label-for="nome">
										<b-form-input id="nome" type="text" v-model="form.nome" :required="true"></b-form-input>
									</b-form-group>

									<b-form-group id="cognomeGroup" horizontal label="Cognome" :label-cols="3" label-for="cognome">
										<b-input-group>
											<b-form-input id="cognome" type="text" v-model="form.cognome" :required="true"></b-form-input>
											<b-input-group-append>
												<b-button v-if="!$route.params.id" variant="primary" @click="searchStaff" title="Cerca per nome e cognome"><i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>

									<b-form-group id="dataDiNascitaGroup" horizontal label="Data di nascita" :label-cols="3" label-for="data_nascita">
										<b-form-input id="data_nascita" type="date" v-model="form.nascita_data"></b-form-input>
									</b-form-group>

									<b-form-group id="paeseNascitaGroup" horizontal label="Paese di nascita" :label-cols="3" label-for="paese_nascita">
										<autocomplete-paese :suggestions="paesi" id="paese_nascita" ref="paese_nascita" @select="onPaeseNascitaSelect" @change="onPaeseNascitaChange" v-model="form.nascita_paese_id"></autocomplete-paese>
									</b-form-group>
									<b-form-group horizontal label="Luogo di nascita" :label-cols="3">
										<autocomplete-comune v-show="form.nascita_paese_id == 118" id="comune" ref="comune_nascita" @select="onComuneNascitaSelect" @change="onComuneNascitaChange"></autocomplete-comune>
										<b-form-input v-show="form.nascita_paese_id != 118" id="comune" v-model="form.info_extra.nascita_comune" placeholder="Città di nascita"></b-form-input>
									</b-form-group>

									<b-form-group id="paeseResidenzaGroup" horizontal label="Paese di residenza" :label-cols="3" label-for="paese_residenza">
										<autocomplete-paese :suggestions="paesi" id="paese_residenza" ref="paese_residenza" @select="onPaeseResidenzaSelect" @change="onPaeseResidenzaChange" v-model="form.paese_residenza"></autocomplete-paese>
									</b-form-group>

									<b-form-group horizontal label="Luogo di residenza" :label-cols="3">
										<autocomplete-comune v-show="form.residenza.paese == 118" id="comune_residenza" ref="comune_residenza" @select="onComuneResidenzaSelect" @change="onComuneResidenzaChange" @changeCap="onCapResidenzaChange" :show_cap="true"></autocomplete-comune>
										<b-form-input v-show="form.residenza.paese != 118" id="citta_residenza" v-model="form.residenza.citta" placeholder="Città di residenza"></b-form-input>
									</b-form-group>

									<b-form-group id="indirizzoResGroup" horizontal label="Indirizzo residenza" :label-cols="3" label-for="indirizzo_residenza">
										<input ref="autocomplete" class="form-control" type="text" v-model="form.residenza.indirizzo">
									</b-form-group>

									<b-form-group id="paeseDomicilioGroup" horizontal label="Paese di domicilio" :label-cols="3" label-for="paese_domicilio">
										<autocomplete-paese :suggestions="paesi" id="paese_domicilio" ref="paese_domicilio" @select="onPaeseDomicilioSelect" @change="onPaeseDomicilioChange" v-model="form.paese_domicilio"></autocomplete-paese>
									</b-form-group>

									<b-form-group horizontal label="Luogo di domicilio" :label-cols="3">
										<autocomplete-comune v-show="form.domicilio.paese == 118" id="comune_domicilio" ref="comune_domicilio" @select="onComuneDomicilioSelect" @change="onComuneDomicilioChange" @changeCap="onCapDomicilioChange" :show_cap="true"></autocomplete-comune>
										<b-form-input v-show="form.domicilio.paese != 118" id="citta_domicilio" v-model="form.domicilio.citta" placeholder="Città di domicilio"></b-form-input>
									</b-form-group>

									<b-form-group id="indirizzoDomGroup" horizontal label="Indirizzo domicilio" :label-cols="3" label-for="indirizzo_domicilio">
										<input ref="autocomplete_dom" class="form-control" type="text" v-model="form.domicilio.indirizzo">
									</b-form-group>

									<b-form-group id="emailGroup" horizontal label="Email" :label-cols="3" label-for="email">
										<b-form-input id="email" type="email" v-model="form.email.valore"></b-form-input>
									</b-form-group>
									<b-form-group id="pecGroup" horizontal label="Email PEC" :label-cols="3" label-for="pec">
										<b-form-input id="pec" type="email" v-model="form.pec.valore"></b-form-input>
									</b-form-group>

									<b-form-group id="telefonoGroup" horizontal label="Telefono" :label-cols="3" label-for="telefono">
										<b-form-input id="telefono" type="tel" v-model="form.telefono.valore"></b-form-input>
									</b-form-group>

									<b-form-group id="telefono2Group" horizontal label="Telefono 2" :label-cols="3" label-for="telefono2">
										<b-form-input id="telefono2" type="tel" v-model="form.telefono2.valore"></b-form-input>
									</b-form-group>
								</fieldset>

								<!-- Carriera -->
								<fieldset class="mb-2">
									<legend>Carriera</legend>
									<b-form-group horizontal label="Ruolo" :label-cols="3">
										<b-form-select v-model="form.last_carriera_sportiva.ruolo_id" :options="ruoli_opt" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
									<b-form-group horizontal label="Qualifica" :label-cols="3">
										<multiselect v-model="form.qualifiche" :options="qualifiche_opt" :multiple="true" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="nome" track-by="id" group-values="items"
										group-label="label" :group-select="true"></multiselect>
									</b-form-group>
									<b-form-group id="matrFigcGroup" horizontal label="Matricola FIGC" :label-cols="3" label-for="matricola_figc">
										<b-form-input id="matricola_figc" type="text" v-model="form.info_extra.matricola_figc"></b-form-input>
									</b-form-group>

									<b-form-group id="numTesseraGroup" horizontal label="Numero Tessera" :label-cols="3" label-for="num_tessera">
										<b-form-input id="num_tessera" type="text" v-model="form.documenti.tessera.numero"></b-form-input>
									</b-form-group>
									<b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Documento Tessera" :label-cols="3">
										<b-row>
											<b-col sm="6" v-if="form.documenti.tessera.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.tessera.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.tessera.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.tessera"
													:state="Boolean(documenti_files.tessera)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-form-group>

								</fieldset>

								<fieldset class="my-5" v-if="auth.isUserEnable('contratti')">
									<legend>Dati economici</legend>
									<b-form-group id="contrattoGroup" horizontal label="Contratto" :label-cols="3" label-for="contratto">
										<b-form-radio-group id="contratto" v-model="form.info_economica.has_contratto">
											<b-form-radio :value="0">No</b-form-radio>
											<b-form-radio :value="1">Si</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-form-group id="scadenzaContrattoGroup" horizontal label="Scadenza Contratto" :label-cols="3" label-for="scadenza_contratto">
										<b-form-input id="scadenza_contratto" type="date" v-model="form.documenti.contratto.scadenza"></b-form-input>
									</b-form-group>
									<b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Documento Contratto" :label-cols="3">
										<b-row>
											<b-col sm="6" v-if="form.documenti.contratto.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.contratto.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.contratto.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.contratto"
													:state="Boolean(documenti_files.contratto)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-form-group>
									<b-form-group id="rimborsoGroup" horizontal label="Rimborso spese" :label-cols="3" label-for="rimborso">
										<b-form-radio-group id="rimborso" v-model="form.info_economica.rimborso_spese">
											<b-form-radio :value="0">No</b-form-radio>
											<b-form-radio :value="1">Si</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-form-group id="stipendioGroup" horizontal label="Stipendio/Rimborso lordo fisso annuo" :label-cols="3" label-for="stipendio">
										<b-form-input id="stipendio" type="text" v-model="form.contratto.stipendio_lordo"></b-form-input>
									</b-form-group>
									<b-form-group id="ibanGroup" horizontal label="IBAN" :label-cols="3" label-for="iban">
										<b-form-input id="iban" type="text" v-model="form.info_economica.iban"></b-form-input>
									</b-form-group>
									<b-form-group id="intestatarioGroup" horizontal label="Intestatario IBAN" :label-cols="3" label-for="intestatario">
										<b-form-input id="intestatario" type="text" v-model="form.info_economica.intestatario_iban"></b-form-input>
									</b-form-group>
								</fieldset>

								<fieldset class="my-5">
									<legend>Visite mediche</legend>
									<b-row>
										<b-col sm="4">
											<b-form-group id="ultimaVisitaMedicaGroup" horizontal label="Ultima" :label-cols="3" label-for="ultima_visita_medica">
												<b-form-input id="ultima_visita_medica" type="date" v-model="form.documenti.idoneita.emissione"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col sm="4">
											<b-form-group id="prossimaVisitaMedicaGroup" horizontal label="Prossima" :label-cols="3" label-for="prossima_visita">
												<b-form-input id="prossima_visita" type="date" v-model="form.documenti.idoneita.scadenza"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col sm="4">
											<b-form-checkbox v-model="form.documenti.idoneita.intermedia" class="mt-2 ml-3">
												Controllo medico intermedio
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Certificato idoneità sportiva" :label-cols="3">
										<b-row>
											<b-col sm="6" v-if="form.documenti.idoneita.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.idoneita.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.idoneita.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.idoneita"
													:state="Boolean(documenti_files.idoneita)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-form-group>
								</fieldset>

								<!-- Documenti -->
								<fieldset class="mb-5">
									<legend>Documenti</legend>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Passaporto 1</h5>
										<b-row>
											<b-col sm="4">
												<b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
													<autocomplete-paese :suggestions="paesi" id="passaporto1" ref="passaporto1" @select="onPassaporto1Select" @change="onPassaporto1Change" v-model="form.documenti.passaporto1.nazione"></autocomplete-paese>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.passaporto1.numero"
													placeholder="Numero"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.passaporto1.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.passaporto1.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.passaporto1.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.passaporto1.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.passaporto1"
													:state="Boolean(documenti_files.passaporto1)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Passaporto 2</h5>
										<b-row>
											<b-col sm="4">
												<b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
													<autocomplete-paese :suggestions="paesi" id="passaporto2" ref="passaporto2" @select="onPassaporto2Select" @change="onPassaporto2Change" v-model="form.documenti.passaporto2.nazione"></autocomplete-paese>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.passaporto2.numero"
													placeholder="Numero"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.passaporto2.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.passaporto2.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.passaporto2.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.passaporto2.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.passaporto2"
													:state="Boolean(documenti_files.passaporto2)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Carta d'identità</h5>
										<b-row>
											<b-col sm="6">
												<b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.cartaidentita.numero"
													placeholder="Numero"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.cartaidentita.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.cartaidentita.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.cartaidentita.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.cartaidentita.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.cartaidentita"
													:state="Boolean(documenti_files.cartaidentita)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Tessera sanitaria / Codice fiscale</h5>
										<b-row>
											<b-col sm="6">
												<b-form-group horizontal label="Codice fiscale" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.codicefiscale.numero"
													placeholder="Codice fiscale"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.codicefiscale.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.codicefiscale.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.codicefiscale.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.codicefiscale.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.codicefiscale"
													:state="Boolean(documenti_files.codicefiscale)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Patente di guida 1</h5>
										<b-row>
											<b-col sm="4">
												<b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
													<autocomplete-paese :suggestions="paesi" id="patente1" ref="patente1" @select="onPatente1Select" @change="onPatente1Change" v-model="form.documenti.patente1.nazione"></autocomplete-paese>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.patente1.numero"
													placeholder="Numero"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.patente1.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.patente1.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.patente1.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.patente1.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.patente1"
													:state="Boolean(documenti_files.patente1)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Patente di guida 2</h5>
										<b-row>
											<b-col sm="4">
												<b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
													<autocomplete-paese :suggestions="paesi" id="patente2" ref="patente2" @select="onPatente2Select" @change="onPatente2Change" v-model="form.documenti.patente2.nazione"></autocomplete-paese>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.patente2.numero"
													placeholder="Numero"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="4">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.patente2.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.patente2.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.patente2.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.patente2.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.patente2"
													:state="Boolean(documenti_files.patente2)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Permesso di circolazione</h5>
										<b-row>
											<b-col sm="4">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.circolazione.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="8">
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.circolazione.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.circolazione.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.circolazione.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.circolazione"
													:state="Boolean(documenti_files.circolazione)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Permesso di soggiorno</h5>
										<b-row>
											<b-col sm="4">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.soggiorno.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="8">
											</b-col>
											<b-col sm="6" v-if="form.documenti.soggiorno.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.soggiorno.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.soggiorno.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.soggiorno"
													:state="Boolean(documenti_files.soggiorno)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
									<b-card bg-variant="transparent" class="mb-2">
										<h5 class="card-subtitle mb-3 color-rosso">Green pass</h5>
										<b-row>
											<b-col sm="4">
												<b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
													<b-form-input
													v-model="form.documenti.greenpass.scadenza"
													type="date"
													></b-form-input>
												</b-form-group>
											</b-col>
											<b-col sm="8">
											</b-col>
											<b-col sm="6" v-if="form.documenti.greenpass.path">
												<b-button variant="primary" @click="downloadDoc(form.documenti.greenpass.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
												<b-form-checkbox v-model="form.documenti.greenpass.delete" class="ml-3">
													Rimuovi da Egnyte
												</b-form-checkbox>
											</b-col>
											<b-col sm="6" v-if="auth.isUserEnable('egnyte')">
												<b-form-group horizontal label="Nuovo file" :label-cols="3">
													<b-form-file
													v-model="documenti_files.greenpass"
													:state="Boolean(documenti_files.greenpass)"
													placeholder="Scegli un file da caricare..."
													drop-placeholder="Cancella file..."
													></b-form-file>
												</b-form-group>
											</b-col>
										</b-row>
									</b-card>
								</fieldset>
								<!-- Whiteboard -->
								<fieldset class="mb-5" v-show="rosaTeamId == 13">
									<legend>Whiteboard</legend>
									<b-form-group horizontal label="Avatar">
										<picture-input ref="pictureBoard" @change="onBoardAvatarChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="board_avatar"></picture-input>
									</b-form-group>
									<b-form-group horizontal label="Ruolo" :label-cols="3">
										<b-form-select v-model="form.info_extra.board_ruolo_id" :options="ruoli_opt" value-field="id" text-field="nome"></b-form-select>
									</b-form-group>
									<b-form-group horizontal label="Capo area" :description="!form.last_carriera_sportiva.ruolo_id ? 'Bisogna selezionare un Ruolo su Carriera per abilitare la checkbox!' : ''">
										<b-form-checkbox :value="1" v-model="form.last_carriera_sportiva.capo_area" class="ml-3" :disabled="!form.last_carriera_sportiva.ruolo_id">
										</b-form-checkbox>
									</b-form-group>
									<b-form-group horizontal label="Vice capo" :description="!form.last_carriera_sportiva.ruolo_id ? 'Bisogna selezionare un Ruolo su Carriera per abilitare la checkbox!' : ''">
										<b-form-checkbox :value="2" v-model="form.last_carriera_sportiva.capo_area" class="ml-3" :disabled="!form.last_carriera_sportiva.ruolo_id">
										</b-form-checkbox>
									</b-form-group>
									<b-form-group horizontal label="Istruzione - Formazione / Carriera lavorativa">
										<b-form-textarea
										id="textarea"
										v-model="form.info_extra.info"
										rows="6"
										></b-form-textarea>
									</b-form-group>
								</fieldset>

								<div class="d-flex align-items-center">
									<b-form-group class="text-right">
										<b-button type="reset" variant="outline-primary">Annulla</b-button>
										<b-button type="submit" variant="primary" class="text-uppercase"><strong>Salva Informazioni</strong></b-button>
									</b-form-group>
								</div>

							</div>
						</form>
					</b-col>
					<b-col md="4" class="text-center">
					</b-col>
				</b-row>
			</b-container>
		</div>	

		<b-modal id="waitingModal" ref="waitingModal" ok-only>
			<div align="center">
				<h4>Attendere salvataggio dei dati...</h4>
				<img src="/assets/varie/loading.gif" style="width: 150px;" />
			</div>
		</b-modal>
		<b-modal id="searchPersonaModal" ref="searchPersonaModal" title="Ricerca persona">
			<b-row>
				<b-col class="border-right pr-2">
					<h5>AS Roma System</h5>
					<div v-if="is_search"><p>Attendere... <img src="/assets/varie/loading.gif" style="width: 100px;" /></p></div>
					<p v-if="!is_search && !persone.length">Nessuna persona trovata su AS Roma System!</p>
					<div v-if="!is_search_persona && persone.length">
						<p>Trovate {{persone.length}} persone!, su AS Roma System</p>
						<hr/>
						<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
							<div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPersona(persona.id)">
								<div class="col-md-3 my-1 mr-3" style="text-align: center;">
									<img :src="persona.avatar_url" height="100"/>
								</div>
								<div class="col-md-6 my-1" style="font-size:0.85rem">
									<h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
									<span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
									<span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
									<span class="text-muted">{{ persona.ruoli.length > 0 ? persona.ruoli[0].nome : '' }}</span><br />
									<span class="text-muted" v-if="persona.last_carriera && persona.last_carriera.squadra"><flag v-if="persona.last_carriera.squadra.paese.sigla" :country="persona.last_carriera.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera.squadra.officialName }} {{persona.last_carriera.stagione ? ' - ' + persona.last_carriera.stagione.stagione : ''}}</span><br />     
								</div>
								<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
									<img v-if="persona.last_carriera && persona.last_carriera.squadra" :src="persona.last_carriera.squadra.logo_url" width="70" />
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-modal>
		<b-modal id="checkPersonaModal" ref="checkPersonaModal" title="Attenzione!" ok-only ok-title="Chiudi">
			<b-row>
				<b-col class="border-right pr-2">
					<p>Sono già presenti su AS Roma System i seguenti individui corrispondenti ai campi inseriti:</p>
					<hr/>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
						<div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPersona(persona.id)">
							<div class="col-md-3 my-1 mr-3" style="text-align: center;">
								<img :src="persona.avatar_url" height="100"/>
							</div>
							<div class="col-md-6 my-1" style="font-size:0.85rem">
								<h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
								<span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
								<span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
								<span class="text-muted">{{ persona.ruoli.length > 0 ? persona.ruoli[0].nome : '' }}</span><br />
								<span class="text-muted" v-if="persona.last_carriera && persona.last_carriera.squadra"><flag v-if="persona.last_carriera.squadra.paese.sigla" :country="persona.last_carriera.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera.squadra.officialName }}</span><br />     
							</div>
							<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
								<img v-if="persona.last_carriera && persona.last_carriera.squadra" :src="persona.last_carriera.squadra.logo_url" width="70" />
							</div>
						</div>
					</div>
					<p>Come vuoi procedere?</p>
					<p>- Seleziona un'anagrafica tra quelle sopra disponibili se ritieni corrisponda al membro dello staff che vuoi inserire</p>
					<p><strong>oppure</strong></p>
					<div>- Premi su <b-button variant="link" @click.prevent="forceSubmit" class="py-0"><strong>SALVA</strong></b-button> per forzare l'inserimento di una nuova anagrafica</div>
				</b-col>
			</b-row>
		</b-modal>
	</div>

</template>


<script>

import moment from 'moment';
import axios from 'axios';
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types';
import { mapState } from 'vuex';
export default {

	data: function(){
		return {
			image_staff: '',
			file_avatar: '',
			image_board: '',
			file_board_avatar: '',
			is_search: false,
			is_search_persona: false,
			persone: [],
			persona_id: null,
			form: {
				id: null,
				nome: null,
				cognome: null,
				nascita_data: null,
				nascita_paese_id: 0,
				info_extra: {
					id: 0,
					nascita_comune: null,
					nascita_provincia: null,
					matricola_figc: null,
					info: null,
					board_ruolo_id: null,
				},
				residenza: {
					id: 0,
					paese: 0,
					citta: null,
					cap: null,
					provincia: null,
					indirizzo: null,
				},
				domicilio: {
					id: 0,
					paese: 0,
					citta: null,
					cap: null,
					provincia: null,
					indirizzo: null,
				},
				email: {
					id: 0,
					valore: null,
				},
				pec: {
					id: 0,
					valore: null,
				},
				telefono: {
					id: 0,
					valore: null,
				},
				telefono2: {
					id: 0,
					valore: null,
				},
				info_economica: {
					id: 0,
					has_contratto: 0,
					rimborso_spese: 0,
					intestatario_iban: null,
					iban: null,
				},
				contratto: {
					id: 0,
					stipendio_lordo: null,
				},
				documenti: {
					passaporto1: {
						id: 0,
						nazione: 0,
						numero: null,
						scadenza: null,
						path: null,
						delete: false,
					},
					passaporto2: {
						id: 0,
						nazione: 0,
						numero: null,
						scadenza: null,
						path: null,
						delete: false,
					},
					cartaidentita: {
						id: 0,
						numero: null,
						scadenza: null,
						path: null,
						delete: false,
					},
					codicefiscale: {
						id: 0,
						numero: null,
						scadenza: null,
						path: null,
						delete: false,
					},
					patente1: {
						id: 0,
						nazione: 0,
						numero: null,
						scadenza: null,
						path: null,
						delete: false,
					},
					patente2: {
						id: 0,
						nazione: 0,
						numero: null,
						scadenza: null,
						path: null,
						delete: false,
					},
					circolazione: {
						id: 0,
						scadenza: null,
						path: null,
						delete: false,
					},
					soggiorno: {
						id: 0,
						scadenza: null,
						path: null,
						delete: false,
					},
					idoneita: {
						id: 0,
						emissione: null,
						scadenza: null,
						intermedia: false,
						path: null,
						delete: false,
					},
					tessera: {
						id: 0,
						numero: null,
						path: null,
						delete: false,
					},
					contratto: {
						id: 0,
						scadenza: null,
						path: null,
						delete: false,
					},
					greenpass: {
						id: 0,
						scadenza: null,
						path: null,
						delete: false,
					},
				},
				qualifiche: [],
				last_carriera_sportiva: {
					id: 0,
					capo_area: false,
					ruolo_id: null,
				}
			},
			documenti_files: {
				passaporto1: null,
				passaporto2: null,
				cartaidentita: null,
				codicefiscale: null,
				patente1: null,
				patente2: null,
				circolazione: null,
				soggiorno: null,
				idoneita: null,
				tessera: null,
				contratto: null,
				greenpass: null,
			},
			paesi:      [],
			customStrings: {
				drag: this.$i18n.t('drag'),
				fileSize: this.$i18n.t('fileSize'),
				fileType: this.$i18n.t('fileType'),
			},
			qualifiche_opt: [
			{
				label: 'Qualifiche',
				items: [],
			}
			],
			ruoli_opt: [{id: null, nome: 'Seleziona'}],
		}
	},

	methods: {

		checkBeforeSubmit() {
			this.persone = [];
			var checkRequest = false;
			var formData = new FormData();
			if(!this.$route.params.id && !this.persona_id) {
				if(this.form.cognome && this.form.cognome.trim() != "") {
					formData.set('cognome', this.form.cognome.trim());                       
				}
				if(this.form.nome && this.form.nome.trim() != "") {
					formData.set('nome', this.form.nome.trim());                       
				}
				if(this.form.nascita_data && this.form.nascita_data != '0000-00-00') {
					formData.set('nascita_data', this.form.nascita_data);                       
				}
				checkRequest = true;
			}
			if(checkRequest) {
				this.$http.post(this.$store.state.apiEndPoint + '/persona/search', formData).then((response) => {
					if (response.ok)
					{
						this.persone = response.data;
						if(this.persone.length > 0) {
							this.$refs.checkPersonaModal.show();	
						} else {
							this.onSubmit();
						}
					}
				}, (response) => {

				});
			} else {
				this.onSubmit();
			}
		},

		forceSubmit() {
			this.$refs.checkPersonaModal.hide();
			this.onSubmit();
		},

		onSubmit: function(){
			var formData = new FormData();
			var path = '/staff/nuovo';
			if(this.$route.params.id) {
				formData.set('id', this.$route.params.id);
				path  = '/staff/update';
			}
			if(this.persona_id) {
				formData.set('id', this.persona_id);
				path  = '/staff/update';
			}
			formData.set('team_id', this.rosaTeamId);
			formData.append("form", JSON.stringify(this.form));
			formData.append("file_avatar", this.file_avatar);
			formData.append("file_board_avatar", this.file_board_avatar);
			formData.append("file_passaporto1", this.documenti_files.passaporto1);
			formData.append("file_passaporto2", this.documenti_files.passaporto2);
			formData.append("file_cartaidentita", this.documenti_files.cartaidentita);
			formData.append("file_codicefiscale", this.documenti_files.codicefiscale);
			formData.append("file_patente1", this.documenti_files.patente1);
			formData.append("file_patente2", this.documenti_files.patente2);
			formData.append("file_circolazione", this.documenti_files.circolazione);
			formData.append("file_soggiorno", this.documenti_files.soggiorno);
			formData.append("file_idoneita", this.documenti_files.idoneita);
			formData.append("file_tessera", this.documenti_files.tessera);
			formData.append("file_contratto", this.documenti_files.contratto);
			formData.append("file_greenpass", this.documenti_files.greenpass);

			this.$refs.waitingModal.show();

			this.$http.post(this.$store.state.apiEndPoint + path, formData).then((response) => {
				if (response.ok)
				{
					this.$refs.waitingModal.hide();
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: 'football_staff'});
				}
			}, (response) => {
				if (response.status == 422) {
					this.$refs.waitingModal.hide();
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},		

		onPaeseNascitaSelect(item) {
			this.form.nascita_paese_id = item;
		},
		onPaeseNascitaChange() {
			this.form.nascita_paese_id = 0;
		},
		onPaeseResidenzaSelect(item) {
			this.form.residenza.paese = item;
		},
		onPaeseResidenzaChange() {
			this.form.residenza.paese = 0;
		},
		onPaeseDomicilioSelect(item) {
			this.form.domicilio.paese = item;
		},
		onPaeseDomicilioChange() {
			this.form.domicilio.paese = 0;
		},
		onComuneNascitaSelect(comune, provincia) {
			this.form.info_extra.nascita_comune = comune;
			this.form.info_extra.nascita_provincia = provincia;
		},
		onComuneNascitaChange() {
			this.form.info_extra.nascita_comune = "";
			this.form.info_extra.nascita_provincia = "";
		},
		onComuneResidenzaSelect(comune, provincia, cap) {
			this.form.residenza.citta = comune;
			this.form.residenza.provincia = provincia;
			this.form.residenza.cap = cap;
		},
		onComuneResidenzaChange() {
			this.form.residenza.citta = "";
			this.form.residenza.provincia = "";
			this.form.residenza.cap = "";
		},
		onCapResidenzaChange(value) {
			this.form.residenza.cap = value;
		},
		onComuneDomicilioSelect(comune, provincia, cap) {
			this.form.domicilio.citta = comune;
			this.form.domicilio.provincia = provincia;
			this.form.domicilio.cap = cap;
		},
		onComuneDomicilioChange() {
			this.form.domicilio.citta = "";
			this.form.domicilio.provincia = "";
			this.form.domicilio.cap = "";
		},
		onCapDomicilioChange(value) {
			this.form.domicilio.cap = value;
		},
		searchStaff: function() {
			if(!this.form.cognome || this.form.cognome == "" || !this.form.nome || this.form.nome == "") {
				alert('Devi inserire nome e cognome del membro dello staff!');
				return;
			}
			this.persone = [];
			this.is_search = true;
			this.$refs.searchPersonaModal.show();
			var formData = new FormData();
			if(this.form.cognome && this.form.cognome.trim() != "") {
				formData.set('cognome', this.form.cognome.trim());                       
			}
			if(this.form.nome && this.form.nome.trim() != "") {
				formData.set('nome', this.form.nome.trim());                       
			}
			this.$http.post(this.$store.state.apiEndPoint + '/persona/search', formData).then((response) => {

				if (response.ok)
				{
					this.persone = response.data;
					this.is_search = false;
				}
			}, (response) => {

			});
		},
		onAvatarChange(e) {
			if (this.$refs.pictureStaff.file) {
				this.file_avatar = this.$refs.pictureStaff.file;
			} else {
				console.log('FileReader Player API not supported: use the <form> 2')
			}
		},

		onBoardAvatarChange(e) {
			if (this.$refs.pictureBoard.file) {
				this.file_board_avatar = this.$refs.pictureBoard.file;
			} else {
				console.log('FileReader Player API not supported: use the <form> 2')
			}
		},

		onPassaporto1Select(item) {
			this.form.documenti.passaporto1.nazione = item;
		},
		onPassaporto1Change() {
			this.form.documenti.passaporto1.nazione = 0;
		},
		onPassaporto2Select(item) {
			this.form.documenti.passaporto2.nazione = item;
		},
		onPassaporto2Change() {
			this.form.documenti.passaporto2.nazione = 0;
		},
		onPatente1Select(item) {
			this.form.documenti.patente1.nazione = item;
		},
		onPatente1Change() {
			this.form.documenti.patente1.nazione = 0;
		},
		onPatente2Select(item) {
			this.form.documenti.patente2.nazione = item;
		},
		onPatente2Change() {
			this.form.documenti.patente2.nazione = 0;
		},
		downloadDoc(id) {
			window.open(this.$store.state.apiEndPoint + '/football/download/egnyte/doc/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},

		getStaff(id) {
			axios.get(`/staff/` + id, {params: { team_id: this.rosaTeamId }})
			.then((res) => {
				this.image_staff = res.data.avatar ? res.data.avatar_url : null;
				this.image_board = res.data.info_extra && res.data.info_extra.board_avatar ? res.data.board_avatar_url : null;
				this.form.nome = res.data.nome;
				this.form.cognome = res.data.cognome;
				this.form.nascita_data = res.data.nascita_data;
				if(res.data.nascita_paese) {
					this.$refs.paese_nascita.setPaese(res.data.nascita_paese.id, res.data.nascita_paese.paese);	
				}

				if(res.data.info_extra) {
					this.form.info_extra.id = res.data.info_extra.id;
					this.form.info_extra.nascita_comune = res.data.info_extra.nascita_comune;
					this.form.info_extra.nascita_provincia = res.data.info_extra.nascita_provincia;
					this.form.info_extra.matricola_figc = res.data.info_extra.matricola_figc;
					this.form.info_extra.info = res.data.info_extra.info;
					this.form.info_extra.board_ruolo_id = res.data.info_extra.board_ruolo_id;
				}

				if(res.data.last_carriera_sportiva) {
					this.form.last_carriera_sportiva.id = res.data.last_carriera_sportiva.id;
					if(res.data.last_carriera_sportiva.ruoli.length > 0) {
						this.form.last_carriera_sportiva.ruolo_id = res.data.last_carriera_sportiva.ruoli[0].id;
						this.form.last_carriera_sportiva.capo_area = res.data.last_carriera_sportiva.ruoli[0].pivot.capo_area;
					}
				}

				for(var i in res.data.indirizzi) {
					var item = res.data.indirizzi[i];
					if(item.indirizzo.tipo == 'residenza') {
						this.form.residenza.id = item.indirizzo.id;
						this.form.residenza.citta = item.indirizzo.citta;
						this.form.residenza.cap = item.indirizzo.cap;
						this.form.residenza.provincia = item.indirizzo.provincia;
						if(item.indirizzo.paese) {
							this.$refs.paese_residenza.setPaese(item.indirizzo.paese.id, item.indirizzo.paese.paese);	
						}
						this.form.residenza.indirizzo = item.indirizzo.indirizzo;
					} else if(item.indirizzo.tipo == 'domicilio') {
						this.form.domicilio.id = item.indirizzo.id;
						this.form.domicilio.citta = item.indirizzo.citta;
						this.form.domicilio.cap = item.indirizzo.cap;
						this.form.domicilio.provincia = item.indirizzo.provincia;
						if(item.indirizzo.paese) {
							this.$refs.paese_domicilio.setPaese(item.indirizzo.paese.id, item.indirizzo.paese.paese);	
						}
						this.form.domicilio.indirizzo = item.indirizzo.indirizzo;
					}
				}

				var telefoni = [];
				for(var i in res.data.contatti) {
					var item = res.data.contatti[i];
					if(item.contatto.tipo == 'email') {
						this.form.email.id = item.contatto.id;
						this.form.email.valore = item.contatto.valore;
					} else if(item.contatto.tipo == 'pec') {
						this.form.pec.id = item.contatto.id;
						this.form.pec.valore = item.contatto.valore;
					} else if(item.contatto.tipo == 'telefono') {
						telefoni.push(item.contatto);
					}
				}

				if(telefoni.length > 0) {
					this.form.telefono.id = telefoni[0].id;
					this.form.telefono.valore = telefoni[0].valore;
				}
				if(telefoni.length > 1) {
					this.form.telefono2.id = telefoni[1].id;
					this.form.telefono2.valore = telefoni[1].valore;
				}

				for(var i in res.data.carriere) {
					this.form.qualifiche.push(res.data.carriere[i].qualifica);
				}
				if(res.data.info_economica) {
					this.form.info_economica.id = res.data.info_economica.id;
					this.form.info_economica.iban = res.data.info_economica.iban;
					this.form.info_economica.intestatario_iban = res.data.info_economica.intestatario_iban;
					this.form.info_economica.has_contratto = res.data.info_economica.has_contratto;
					this.form.info_economica.rimborso_spese = res.data.info_economica.rimborso_spese;
				}
				if(res.data.contratto) {
					this.form.contratto.id = res.data.contratto.id;
					this.form.contratto.stipendio_lordo = res.data.contratto.stipendio_lordo;
				}

				/* passaporti */
				var docs = [];
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 2) {
						if(docs.length == 0) {
							docs.push(item.documento);
						} else {
							var found = false;
							for(var j in docs) {
								if(docs[j].numero == item.documento.numero) {
									found = true;
									if(item.documento.scadenza > docs[j].scadenza) {
										docs[j] = item.documento;
									}
									break;
								}
							}
							if(!found) {
								docs.push(item.documento);
							}
						}
					}
				}

				if(docs.length > 0) {
					this.form.documenti.passaporto1.id = docs[0].id;
					this.form.documenti.passaporto1.numero = docs[0].numero;
					this.form.documenti.passaporto1.scadenza = docs[0].scadenza;
					this.form.documenti.passaporto1.path = docs[0].path;
					if(docs[0].paese) {
						this.$refs.passaporto1.setPaese(docs[0].paese.id, docs[0].paese.paese);	
					}
				}
				if(docs.length > 1) {
					this.form.documenti.passaporto2.id = docs[1].id;
					this.form.documenti.passaporto2.numero = docs[1].numero;
					this.form.documenti.passaporto2.scadenza = docs[1].scadenza;
					this.form.documenti.passaporto2.path = docs[1].path;
					if(docs[1].paese) {
						this.$refs.passaporto2.setPaese(docs[1].paese.id, docs[1].paese.paese);	
					}
				}

				/* carta identita */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 3) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.cartaidentita.id = doc.id;
					this.form.documenti.cartaidentita.numero = doc.numero;
					this.form.documenti.cartaidentita.scadenza = doc.scadenza;
					this.form.documenti.cartaidentita.path = doc.path;
				}

				/* codice fiscale */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 4) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.codicefiscale.id = doc.id;
					this.form.documenti.codicefiscale.numero = doc.numero;
					this.form.documenti.codicefiscale.scadenza = doc.scadenza;
					this.form.documenti.codicefiscale.path = doc.path;
				}

				/* patenti */
				var docs = [];
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 5) {
						if(docs.length == 0) {
							docs.push(item.documento);
						} else {
							var found = false;
							for(var j in docs) {
								if(docs[j].numero == item.documento.numero) {
									found = true;
									if(item.documento.scadenza > docs[j].scadenza) {
										docs[j] = item.documento;
									}
									break;
								}
							}
							if(!found) {
								docs.push(item.documento);
							}
						}
					}
				}	
				if(docs.length > 0) {
					this.form.documenti.patente1.id = docs[0].id;
					this.form.documenti.patente1.numero = docs[0].numero;
					this.form.documenti.patente1.scadenza = docs[0].scadenza;
					this.form.documenti.patente1.path = docs[0].path;
					if(docs[0].paese) {
						this.$refs.patente1.setPaese(docs[0].paese.id, docs[0].paese.paese);	
					}
				}
				if(docs.length > 1) {
					this.form.documenti.patente2.id = docs[1].id;
					this.form.documenti.patente2.numero = docs[1].numero;
					this.form.documenti.patente2.scadenza = docs[1].scadenza;
					this.form.documenti.patente2.path = docs[1].path;
					if(docs[1].paese) {
						this.$refs.patente2.setPaese(docs[1].paese.id, docs[1].paese.paese);	
					}
				}

				/* circolazione */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 6) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.circolazione.id = doc.id;
					this.form.documenti.circolazione.scadenza = doc.scadenza;
					this.form.documenti.circolazione.path = doc.path;
				}

				/* soggiorno */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 7) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.soggiorno.id = doc.id;
					this.form.documenti.soggiorno.scadenza = doc.scadenza;
					this.form.documenti.soggiorno.path = doc.path;
				}

				/* idoneità */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 9 || item.documento.documento_tipo_glo_id == 10) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.idoneita.id = doc.id;
					this.form.documenti.idoneita.emissione = doc.emissione;
					this.form.documenti.idoneita.scadenza = doc.scadenza;
					this.form.documenti.idoneita.intermedia = doc.documento_tipo_glo_id == 10 ? true : false;
					this.form.documenti.idoneita.path = doc.path;
				}

				/* tessera */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 11) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.tessera.id = doc.id;
					this.form.documenti.tessera.numero = doc.numero;
					this.form.documenti.tessera.path = doc.path;
				}

				/* contratto */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 12) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.contratto.id = doc.id;
					this.form.documenti.contratto.scadenza = doc.scadenza;
					this.form.documenti.contratto.path = doc.path;
				}

				/* greenpass */
				var doc = null;
				for(var i in res.data.documenti) {
					var item = res.data.documenti[i];
					if(item.documento.documento_tipo_glo_id == 15) {
						if(!doc) {
							doc = item.documento;
						} else {
							if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
								doc = item.documento;
							}
						}
					}
				}
				if(doc) {
					this.form.documenti.greenpass.id = doc.id;
					this.form.documenti.greenpass.scadenza = doc.scadenza;
					this.form.documenti.greenpass.path = doc.path;
				}

			}).catch((err) => {
				this.loading = false;
			});
		},

		selezionaPersona: function (persona_id) {
			this.persona_id = persona_id;
			this.getStaff(this.persona_id);
			this.$refs.searchPersonaModal.hide();
			this.$refs.checkPersonaModal.hide();
		},
	},

	props: {

		title: {
			type: String,
			required: false
		},

	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YY'); 
			}
			return "";
		}
	},

	components: {
		pictureInput: require('../../../layout/picture_input.vue').default,
		autocompletePaese: require('../../../layout/autocomplete_paese.vue').default,
		autocompleteComune: require('../../../layout/autocomplete_comune.vue').default,
		flag: require('../../../layout/flag.vue').default,
	},

	computed: {
		auth: function() {
			return Auth;
		},
		avatar: function() {
			if(this.image_staff != "") {
				return this.image_staff;
			} 
			return '';
		},
		board_avatar: function() {
			if(this.image_board != "") {
				return this.image_board;
			} 
			return '';
		},
		...mapState(['rosaTeamId']),

	},

	mounted: function(){ 
		this.autocomplete = new window.google.maps.places.Autocomplete(
			(this.$refs.autocomplete),
			{types: ['geocode']}
			);

		this.autocomplete.addListener('place_changed', () => {
			let place = this.autocomplete.getPlace();
			this.form.residenza.indirizzo = place.formatted_address;
		}); 

		this.autocomplete_dom = new window.google.maps.places.Autocomplete(
			(this.$refs.autocomplete_dom),
			{types: ['geocode']}
			);

		this.autocomplete_dom.addListener('place_changed', () => {
			let place = this.autocomplete_dom.getPlace();
			this.form.domicilio.indirizzo = place.formatted_address;
		});
	},

	created: function ()
	{

		this.$http.get(this.$store.state.apiEndPoint + '/paesi').then((response) => {
			if (response.ok)
			{
				this.paesi = response.data;
			}
		}, (response) => {
		});

		this.$http.get(this.$store.state.apiEndPoint + '/staff/ruoli').then((response) => {
			if (response.ok)
			{
				this.ruoli_opt = this.ruoli_opt.concat(response.data);
				this.qualifiche_opt[0].items = response.data;
			}
		}, (response) => {
		});


		if(this.$route.params.id){
			this.getStaff(this.$route.params.id);
		}
	},

	watch: {
		'form.nascita_paese_id': function () {
			if(this.$route.params.id && this.form.nascita_paese_id == 118) {
				this.$refs.comune_nascita.setComune(this.form.info_extra.nascita_comune, this.form.info_extra.nascita_provincia);
			}
		},
		'form.residenza.paese': function () {
			if(this.$route.params.id && this.form.residenza.paese == 118) {
				this.$refs.comune_residenza.setComune(this.form.residenza.citta, this.form.residenza.provincia, this.form.residenza.cap);
			}
		},
		'form.domicilio.paese': function () {
			if(this.$route.params.id && this.form.domicilio.paese == 118) {
				this.$refs.comune_domicilio.setComune(this.form.domicilio.citta, this.form.domicilio.provincia, this.form.domicilio.cap);
			}
		},
		form: {
			handler: function () {this.errors = []; },
			deep: true
		},
	},

}
</script>
