<template id="menuscout">
	<section>
		<div class="pull-right">
			<b-button size="sm" :href="'#/next/' + area + '/view/giocatore/' + personaId" variant="outline-primary" v-if="$route.name != this.area + '_next_view_giocatore'">Scheda giocatore</b-button>
			<b-button size="sm" :href="'#/next/' + area + '/edit/giocatore/' + personaId" variant="outline-primary" v-if="$route.name == this.area + '_next_view_giocatore'">Modifica Anagrafica</b-button>
			<b-button size="sm" :href="'#/next/' + area + '/view/valutazione/' + personaId + '/' + valutazioneId" variant="outline-primary" v-if="valutazioneId && ($route.name == this.area + '_next_view_giocatore' || $route.name == this.area + '_next_edit_valutazione')">Valutazioni</b-button>
			<b-button size="sm" :href="'#/next/' + area + '/edit/valutazione/' + personaId + '/' + valutazioneId" variant="outline-primary" v-if="$route.name == this.area + '_next_view_valutazione'">Modifica Valutazione</b-button>
			<b-button v-if="auth.isUserEnable('scout_add_val') && ($route.name == this.area + '_next_view_giocatore' || $route.name == this.area + '_next_view_valutazione' || $route.name == this.area + '_next_edit_valutazione')" size="sm" :href="'#/next/' + area + '/new/valutazione/' + personaId" variant="outline-primary">Nuova Valutazione</b-button>
			<b-button v-if="auth.isUserEnable('scout_videotag') && !auth.isUserEnable('scout_not_skills') && ($route.name == this.area + '_next_view_giocatore')" size="sm" :href="'#/next/' + area + '/videotag/giocatore/' + personaId" variant="outline-primary">Videotag</b-button>
			<b-button v-if="auth.isUserEnable('scout_partite') && ($route.name == this.area + '_next_view_giocatore')" size="sm" :href="'#/next/' + area + '/partite/giocatore/' + personaId" variant="outline-primary">Partite</b-button>
			<b-button variant="outline-primary" size="sm" title="Download scheda" @click="openDownloadModal" v-if="auth.isUserEnable('scout_down_val_player') && $route.name == this.area + '_next_view_giocatore'" class="mr-1">Download Scheda <i class="fa fa-download" aria-hidden="true"></i></b-button>
		</div>
		<b-modal id="downloadModal" ref="downloadModal" title="Stampa scheda giocatore" ok-only ok-title="Chiudi">
				<div>
					<b-form-group label="Stampa in:">
						<b-form-radio-group v-model="download_mode">
        					<b-form-radio value="originale">Lingua originale</b-form-radio>
        					<b-form-radio value="traduzione">Lingua specifica</b-form-radio>
      					</b-form-radio-group>
				    </b-form-group>
				    <languageselector v-if="download_mode == 'traduzione'" :lingue="lingue" :model="download_lingua" :readonly="true" :mustTranslate="true"></languageselector>
				    <b-button size="sm" class="mt-3" @click="downloadScheda">Download scheda</b-button>
				</div>
			</b-modal>
	</section>
</template>

<script>
var Auth = require('@/auth.js');
export default {

	data: function() {
		return {
			lingue: [],
			download_mode: 'originale',
			download_lingua: {
				selected: 1,
				master: null,
			}
		}
	},

	props: [ 'personaId', 'valutazioneId' ],

	components: {
		languageselector: require('@/components/layout/multilingua/language-selector.vue').default,
	},

	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		auth: function() {
			return Auth;
		},
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/lingue').then((response) => {
			this.lingue = response.data;
		});
	},

	methods: {
		openDownloadModal() {
			this.$refs.downloadModal.show();
		},
		downloadScheda() {
			var lingua_sel = 0;
			var provider = 'google';
			if(this.download_mode == 'traduzione' && this.download_lingua.selected) {
				lingua_sel = this.download_lingua.selected;
				if(this.download_lingua.provider) {
					provider = this.download_lingua.provider;
				}
			}
			window.open(this.$store.state.apiEndPoint + '/nextscouting/pdf/giocatore/' + this.personaId + "/" + lingua_sel + '/' +  provider + '/' + this.area + '/' + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
	}
}
</script>
