<template>
	<section>
		<headapp></headapp>
		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Video</span>
					</div>
					<div class="content" v-if="sessione">
						<VideoManager nolabel=" " :key="sessione.id" ref="cvideomanager_gen" @sposta="do_sposta" :buttons="[ { name: 'Sposta', event: 'sposta' } ]" :collapsed="false" entity="Sessione" :entityId="$route.params.id" :readonly="!isManagerSessione" :personeIds="personeIds" class="mb-2" :id="$route.params.id" :tipo="$route.params.tipo"></VideoManager>
						<div v-if="sessione">
							<VideoManager :key="fase.id" ref="cvideomanager" @sposta="do_sposta" v-for="fase in sessione.fasi" :buttons="[ { name: 'Sposta', event: 'sposta' } ]" :collapsed="false" entity="Fase" :entityId="fase.id" :readonly="!isManagerSessione" :personeIds="personeIds" class="mb-2" :id="$route.params.id" :tipo="$route.params.tipo" :hideIfEmpty="true" :hideNewVideo="true">
								<p class="card-text">
									Fase: <span class="font-bold"> {{ fase.nome }} </span>
								</p>
							</VideoManager>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal v-model="ux.moveModal.show" title="Sposta Video" @ok="do_action_sposta">

			<b-form v-if="ux.moveModal.data">
				
				<div v-if="ux.moveModal.data.entita == 'Fase'">
					<b-button @click.prevent="ux.moveModal.data.entita = 'Sessione'; ux.moveModal.data.entita_id = sessione.id">Sposta alla sessione</b-button>
					<b-form-group label="Fase" class="mt-3">
						<b-form-select v-model="ux.moveModal.data.entita_id">
							<b-select-option v-for="fase in sessione.fasi" :value="fase.id">{{fase.nome}}</b-select-option>
						</b-form-select>
					</b-form-group>
				</div>
				<div v-else>
					<b-button @click.prevent="ux.moveModal.data.entita = 'Fase'; ux.moveModal.data.entita_id = false">Sposta in una fase</b-button>
				</div>

			</b-form>

			<!-- <pre>{{ux.moveModal.data}}</pre> -->
		</b-modal>

		<footerapp></footerapp>
	</section>
</template>

<script>
var Auth = require('@/auth.js');
import axios from 'axios';
import { mapState } from 'vuex';
export default {
	data: function() {
		return {
			ux: {
				moveModal: {
					show: false,
					data: {}
				}
			},
			sessione: null,
			team: null,
		}
	},
	created: function () {
		this.getSessione();
		this.getTeam();
	},
	components: {
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
		VideoManager: require('@/components/layout/video/manager.vue').default,
	},
	methods: {
		do_action_sposta: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/video/move/' + this.ux.moveModal.data.id, { video: this.ux.moveModal.data }).then(function(data) {
				
				this.ux.moveModal.show = false;
				_.each(this.$refs.cvideomanager, function(a) {
					a.fetch();
				});

				this.$refs.cvideomanager_gen.fetch();

			});


		},
		do_sposta: function(e) {
			this.ux.moveModal.show = true;
			this.ux.moveModal.data = _.cloneDeep(e);
		},
		getSessione() {
			if(this.$route.params.id) {
				axios.get(`/sessione/` + this.$route.params.id).then((res) => { this.sessione = res.data; }).catch((err) => {});
			}
		},
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},
	},
	computed: {
		auth: function() {
			return Auth;
		},
		isManagerSessione: function() {
			if(this.sessione && this.sessione.sessione_tipo) {
				if(this.team && this.sessione.categoria_id != this.team.id_categoria) {
					return false;
				}
				return this.auth.isUserEnable('sessione_' + this.sessione.sessione_tipo.nome.toLowerCase().replaceAll(' ', '') + '_mng');
			}
			return false;
		},
		personeCorrenti: function() {
			if(this.sessione) {
				return _.map(this.sessione.persone, function(a) {
					return {
						name: a.cognome + ' ' + a.nome,
						id: a.id
					}
				});
			}
			return [];
		},
		personeIds: function() {
			return _.map(this.personeCorrenti, 'id');
		},
		...mapState(['rosaTeamId']),
	}
}
</script>
