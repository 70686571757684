<template>
	<div>
		<b-modal id="iconaModal" ref="iconaModal" :title="icona && icona.id ? 'Icona' : 'Nuova icona'" size="lg" ok-only ok-title="Chiudi" v-model="showModal">
			<div v-if="icona">
				<b-form-group label="Nome">
					<b-form-input v-model="icona.nome"></b-form-input>
				</b-form-group>
				<b-form-group label="Gruppo">
					<multiselect v-model="icona.gruppo" :options="icona_gruppi" :multiple="false" placeholder="Seleziona gruppo" :taggable="true" @tag="addGruppoIcona" tag-placeholder="Aggiungi nuovo gruppo"></multiselect>
				</b-form-group>
				<b-form-group label="Icona">
					<b-img v-if="icona.file" :src="icona.url" fluid width="50"></b-img>
				</b-form-group>
				<b-form-group label="Nuovo file">
					<b-form-file
					accept="image/*"
					v-model="icona.file_icon"
					:state="Boolean(icona.file_icon)"
					placeholder="Scegli o trascina un'immagine da caricare..."
					drop-placeholder="Trascina un'immagine qui..."
					></b-form-file>
				</b-form-group>
			</div>
			<b-row class="mt-2">
				<b-col>
					<b-button variant="outline-primary" @click="salvaIcona" class="mr-2">Salva</b-button>
					<b-button v-if="icona && icona.id" variant="outline-secondary" @click="eliminaIcona">Elimina</b-button>
				</b-col>
			</b-row>
		</b-modal>

	</div>
</template>

<script>

export default {
	components: {
	},
	watch: {
	},
	props: ['icona', 'icona_gruppi'],
	data: function() {
		return {
			showModal: false,
		}
	},

	created: function() {
	},

	computed: {
	},
	methods: {
		show: function() {
			this.showModal = true;
		},
		hide: function() {
			this.showModal = false;
		},
		addGruppoIcona(gruppo) {
			this.icona_gruppi.push(gruppo);
			this.icona.gruppo = gruppo;
		},
		salvaIcona() {
			this.$emit('salvaIcona');
		},
		eliminaIcona() {
			this.$emit('eliminaIcona');
		},
	}
}

</script>