<template>
	<div class="mb-2">
		<b-card>
			<b-row>
				<b-col cols="1"><img :src="player.board_avatar_url" class="img-fluid" style="border: 1px solid rgba(0,0,0,.2); border-radius: 100%; box-shadow: 0px 0px 10px rgba(0,0,0,.1)" /></b-col>
				<b-col cols="11" class="align-self-center pl-3">
					<div style="font-size: 115%; line-height: 0.5">
						<span class="font-weight-bold" style="text-transform: uppercase">{{ player.cognome }}</span> {{ player.nome }}
					</div>
					<span class="text-muted small">{{ruolo.join(', ')}}</span>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

import lodash from 'lodash';

export default {
	props: [ 'player' ],
	computed: {
		ruolo: function() {
			if(!this.player.last_carriera_sportiva) return [];
			return _.map(this.player.last_carriera_sportiva.ruolo_ideale, 'nome');

		}
	}
}

</script>