export const ROUTES_YOUTHSCOUT = [
    {
        path: '/youthscout/dashboard',
        name: 'youthscout_dashboard',
        component: require('../components/pages/youthscout/dashboard.vue').default,
        meta: {
            module: 'youthscout',

            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'youthscout',
        }
    },
    {
        path: '/youthscout/lista/in/campo/:id',
        name: 'youthscout_lista_in_campo',
        component: require('../components/pages/youthscout/lista_in_campo.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Lista',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'youthscout',
            logModel: 'Liste',
            logModelId: 'id',
        }
    },
    {
        path: '/youthscout/lista/in/campo/new/:id/:formazioneId?',
        name: 'youthscout_lista_in_campo_new',
        component: require('../components/pages/youthscout/lista_in_campo_dev.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Lista',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'youthscout',
            logModel: 'Liste',
            logModelId: 'id',
        }
    },
    {
        path: '/next/youth/new/giocatore',
        name: 'youth_next_new_giocatore',
        component: require('../components/pages/next-scouting/giocatori/new.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Nuovo Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_add_player',
        }
    },  
    {
        path: '/youthscout/view/giocatore/:id',
        name: 'youthscout_view_giocatore',
        component: require('../components/pages/next-scouting/giocatori/view.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Scheda Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youthscout_nuova_valutazione',
            menusub: [ 'youthscout_edit_giocatore', 'youthscout_nuova_valutazione'],
            permesso: 'youthscout',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youthscout/edit/giocatore/:id',
        name: 'youthscout_edit_giocatore',
        component: require('../components/pages/next-scouting/giocatori/edit.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Modifica Anagrafica',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youthscout_view_giocatore',
            menusub: ['youthscout_view_giocatore'],
            permesso: 'scout_edit_player',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youthscout/edit/valutazione/:id/:val_id',
        name: 'youthscout_edit_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/edit.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Modifica Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youthscout_view_giocatore',
            menusub: ['youthscout_view_giocatore', 'youthscout_nuova_valutazione'],
            permesso: 'scout_edit_val',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/youthscout/nuova/valutazione/:id',
        name: 'youthscout_nuova_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/new.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Nuova Valutazione',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youthscout_view_giocatore',
            menusub: ['youthscout_view_giocatore'],
            permesso: 'scout_add_val',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youthscout/view/valutazione/:id/:val_id',
        name: 'youthscout_view_valutazione',
        component: require('../components/pages/next-scouting/valutazioni/view.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Valutazione Giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youthscout_view_giocatore',
            menusub: ['youthscout_view_giocatore', 'youthscout_edit_valutazione', 'youthscout_nuova_valutazione'],
            permesso: 'youthscout',
            logModel: 'OsservatiValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/youthscout/view/valutazione/football/:id/:val_id',
        name: 'youthscout_view_valutazione_football',
        component: require('../components/pages/next-scouting/valutazioni/view_football.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Valutazione Football',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youthscout_view_giocatore',
            menusub: ['youthscout_view_giocatore'],
            permesso: 'youthscout',
            logModel: 'RosaValutazioni',
            logModelId: 'val_id',
        }
    },
    {
        path: '/youthscout/videotag/giocatore/:id',
        name: 'youthscout_videotag_giocatore',
        component: require('../components/pages/next-scouting/giocatori/videotag.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Videotag Giocatore',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youthscout_view_giocatore',
            permesso: 'scout_videotag',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
    {
        path: '/youthscout/agenda',
        name: 'youthscout_agenda',
        component: require('../components/pages/youthscout/agenda.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Agenda',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_agenda',
        }
    },
    {
        path: '/next/youth/partite',
        name: 'youth_next_partite',
        component: require('../components/pages/next-scouting/partite.vue').default,
        meta: {
            area: 'youth',
            module: 'youthscout',
            label: 'Partite',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_partite',
        }
    },
    {
        path: '/youthscout/osservatori',
        name: 'youthscout_osservatori',
        component: require('../components/pages/youthscout/osservatori.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Osservatori',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'scout_utenti',        
        }
    },
    {
        path: '/youthscout/osservatore/paesi/:id',
        name: 'youthscout_osservatore_paesi',
        component: require('../components/pages/youthscout/osservatore_paesi.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Paesi',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youthscout_osservatori',
            permesso: 'scout_utenti',
            menusub: ['youthscout_osservatore_paesi', 'youthscout_osservatore_liste'],
            logModel: 'Persona_Utente',
            logModelId: 'id',         
        }
    },
    {
        path: '/youthscout/osservatore/liste/:id',
        name: 'youthscout_osservatore_liste',
        component: require('../components/pages/youthscout/osservatore_liste.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Liste',
            requiresAuth: true,
            showInMenu: false,
            parent: 'youthscout_osservatori',
            permesso: 'scout_utenti',  
            menusub: ['youthscout_osservatore_paesi', 'youthscout_osservatore_liste'], 
            logModel: 'Persona_Utente',
            logModelId: 'id',  
        }
    },
     {
        path: '/youthscout/squadre',
        name: 'youthscout_squadre',
        component: require('../components/pages/youthscout/squadre.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Squadre',
            requiresAuth: true,
            showInMenu: true,
            parent: '',  
            permesso: 'scout_squadre',    
        }
    },
    {
        path: '/youthscout/squadre/nuova',
        name: 'youthscout_squadre_new',
        component: require('../components/pages/youthscout/new_squadra.vue').default,
        meta: {
            module: 'youthscout',

            label: 'Nuova Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youthscout_squadre',
            permesso: 'scout_squadre',
        }
    },
    {
        path: '/youthscout/squadre/edit/:id',
        name: 'youthscout_squadre_edit',
        component: require('../components/pages/youthscout/edit_squadra.vue').default,
        meta: {
            module: 'youthscout',
            label: 'Modifica Squadra',
            requiresAuth: true,
            showInMenu: true,
            parent: 'youthscout_view_squadra',
            permesso: 'scout_squadre',
            logModel: 'Squadre',
            logModelId: 'id',
        }
    },
];
