<template>
  <div :class="['thumbnail-wrapper', tag.result]">
    <div class="thumbnail-top">
      <div class="action-number">{{ count }}</div>
      <div class="preferiti">
        <i class="fa fa-heart-o" aria-hidden="true" v-if="!tag.preferiti" @click.prevent="addPreferiti()" title="Aggiungi ai preferiti"></i>
        <i class="fa fa-heart" aria-hidden="true" v-if="tag.preferiti" @click.prevent="removePreferiti()" title="Rimuovi dai preferiti"></i>
      </div>
      <div class="action-player">{{ teamOrPlayerName }}</div>
      <div class="action-actions">
        <a href="javascript:;"><i class="fa fa-futbol-o" aria-hidden="true"></i></a>
        <!--a href="#" v-b-modal="`share-tag-${tag.id}`"><i class="fa fa-share-square-o" aria-hidden="true"></i></a-->
        <a href="javascript:;"><i class="fa fa-share-square-o" aria-hidden="true"></i></a>
        <a href="#" @click.prevent="deleteTag()"><i class="fa fa-times thumbnail-close" aria-hidden="true"></i></a>
      </div>
    </div>
    <b-img :src="thumbnail" center fluid-grow @click="seekTo" />
    <div class="thumbnail-time">{{ formatDuration(relativeStart) }}</div>
    <div class="thumbnail-bottom">
      <div class="action-wrapper">
        <div v-if="tag.type" :class="['action-type', `type-${tag.type}`]">{{ typeSubstring }}</div>
        <div class="field-section" v-if="tag.field_section">{{ tag.field_section }}</div>
      </div>
      <div class="skill-name">{{ skillNames }}</div>
      <div class="action-actions">
        <a href="#" @click.prevent="downloadTag()" v-if="auth.isUserEnable('video_down_clip')" title="download"><i class="fa fa-download" aria-hidden="true"></i></a>
        <a href="#" v-b-modal="`info-tag-${tag.id}-modal`"><i class="fa fa-info-circle" aria-hidden="true"></i></a>
        <a href="#" @click.prevent="editDescription()"><i class="fa fa-pencil" aria-hidden="true"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import { minBy, filter } from 'lodash';
import tagMixin from '../../../../mixins/tags.js';

var Auth = require('../../../../auth.js');

export default {
  name: 'VideoTag',
  data() {
    return {
      timer: false,
    };
  },
  props: ['tag', 'count'],
  mixins: [tagMixin],

  created: function () {
  if(!this.tag.snapshot_url) {
        this.$interval = setInterval(() => {
        this.loadThumbnail();
        }, 5000);
    this.timer = true;
    }
  },

  computed: {
    ...mapGetters(['videoBookmarks']),

    typeSubstring() {
      return this.tag.type.substring(0, 3);
    },

    relativeStart() {
      if (this.videoBookmarks.length === 0) return this.tag.start;

      const filteredBookmarks = filter(this.videoBookmarks, bookmark =>
        Number(bookmark.start) < Number(this.tag.start));

      if (!filteredBookmarks || filteredBookmarks.length === 0) return this.tag.start;

      const closestBookmark = minBy(filteredBookmarks, bookmark =>
        Math.abs(Number(this.tag.start) - Number(bookmark.start)));
      return Math.abs(closestBookmark.time_match + (Number(this.tag.start) - Number(closestBookmark.start)));
    },
    thumbnail() {
      if(this.tag.snapshot_url != null && this.tag.snapshot_url != "") {
        clearInterval(this.$interval);
        this.timer = false;
        return this.tag.snapshot_url;
      } else {
        if(!this.timer) {
          this.$interval = setInterval(() => {
             this.loadThumbnail();
          }, 5000);
          this.timer = true;
        }
      } 
      return '/assets/varie/missing.jpg';
    },
    auth: function() {
        return Auth;
    },
  },

  methods: {
    seekTo() {
      this.$root.$emit('seekTo', Number(this.tag.start));
      this.$store.commit('editTag', this.tag);
      this.$root.$emit('setTimelineWindow', Number(this.tag.start));
    },

    deleteTag() {
      this.confirm('Sei sicuro di voler eliminare il tag?')
        .then((result) => {
          if (result.value) {
            this.$store.dispatch('deleteTag', this.tag);
            clearInterval(this.$interval); 
            this.timer = false;
          }
        }).catch(() => { /* Do nothing */ });
    },
    downloadTag() {
      window.open(this.$store.state.apiEndPoint + '/videotag/download/clip/' + this.tag.id + "/" + Auth.user.id + "/" + Auth.user.api_token);
    },

    editDescription() {
      swal.fire({
        title: 'Modifica la descrizione',
        input: 'textarea',
        showCancelButton: true,
        allowOutsideClick: false,
        inputValue: this.tag.description || '',
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('updateTag', {
            tag: this.tag,
            data: { description: result.value },
          });
        }
      }).catch(() => { /* Do nothing */ });
    },
    addPreferiti() {
      console.log("addPreferiti");
      this.$store.dispatch('updateTag', {
        tag: this.tag,
        data: { preferiti: 1 },
      });
    },
    removePreferiti() {
       console.log("remPreferiti");
       this.$store.dispatch('updateTag', {
        tag: this.tag,
        data: { preferiti: 0 },
        });
    },
    loadThumbnail() {
      this.$http.get(this.$store.state.apiEndPoint + '/videotag/thumbnail/base64/' + this.tag.id).then((response) => {
        if (response.ok)
        {
          this.tag.snapshot_url = response.data;
        }
      }, (response) => {
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.$interval);
    this.timer = false;
  },
};
</script>

<style lang="scss" scoped>
$background-default:  rgba(0, 0, 0, 0.7);
$background-negative: rgba(166, 15, 33, 0.7);
$background-positive: rgba(55, 128, 70, 0.7);

$default-padding: 5px;

.thumbnail-wrapper {
  border-radius: 5px;
  position: relative;
  color: white;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    border-radius: 5px;
    cursor: pointer;
  }

  a, a:hover {
    color: white;
  }

  .fa {
    font-size: 16px;
  }

  .thumbnail-top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $default-padding;
    position: absolute;
    width: 100%;
    top: 0;
    background-color: $background-default;
  }

  .thumbnail-time {
    border-radius: 5px;
    position: absolute;
    top: 43%;
    left: 10px;
    background-color: white;
    padding: $default-padding 10px;
    color: #212529;
  }

  .thumbnail-bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: $default-padding;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $background-default;
  }

  .action-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .action-player, .skill-name {
    position: absolute;
    text-align: center;
    white-space: nowrap;
    width: 40%;
    left: 30%;
    overflow: hidden;
 /*   text-overflow: ellipsis; */
  }

  .action-number {
    background-color: black;
    border-radius: 5px;
    padding: 5px 10px;
    z-index: 1000;
  }

  .preferiti {
    position: absolute;
    left: 40px;
    cursor:pointer;
 /*   text-overflow: ellipsis; */
  }

  .action-actions {
    z-index: 1000;

    a {
      padding: $default-padding;

      .thumbnail-close {
        font-size: 20px;
      }
    }
  }

  .action-type {
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
    margin-right: 5px;
    z-index: 1000;

    &.type-offensive {
      background-color: #D3B826;
    }

    &.type-defensive {
      background-color: #D3800B;
    }
  }

  .field-section {
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #a2a321;
    z-index: 1000;
  }

  &.negative {
    .thumbnail-top, .thumbnail-bottom {
      background-color: $background-negative;
    }
  }

  &.positive {
    .thumbnail-top, .thumbnail-bottom {
      background-color: $background-positive;
    }
  }
}
</style>

<style lang="scss">
.modal .modal-dialog {
  color: #212529;

  .custom-control-description {
    font-size: 15px;
  }
}

.list-group {
  font-size: 14px;
}
</style>
