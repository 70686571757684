<template id="costi">
	<section class="osservatori">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Costi</span>
					</div>
					<div class="content">
						<b-row>
							<b-col sm="3">
								<b-form-group horizontal label="Periodo dal" label-cols="4">
									<b-form-input type="date" v-model="filters.dal"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="3">
								<b-form-group horizontal label="al" label-cols="3" label-text-align="right">
									<b-form-input type="date" v-model="filters.al"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-card no-body>
							<b-tabs pills card>
								<b-tab :title="'Partite (' + items_partite.length + ')'" active>
									<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
									<b-row v-if="!loading">
										<b-col>
											<b-table striped hover :items="items_partite" :fields="fields_partite">
												<template #cell(sigla)="data">
													<flag :country="data.value" :size="32"></flag>
												</template>
												<template #cell(giocatori)="data">
													<div v-for="item in data.value">{{ item.cognome }} {{ item.nome }}</div>
												</template>
												<template #cell(osservatori)="data">
													<div v-for="item in data.value">{{ item.nomeCompleto }}</div>
												</template>
												<template #cell(costo)="row">
													<b-form-input v-model="costi[row.item.id]" type="text" :value="row.item.costo" />
												</template>
											</b-table>
											<b-button @click="salvaCosti" variant="primary">Salva</b-button>
										</b-col>
									</b-row>
								</b-tab>
								<b-tab :title="'Paesi (' + items_paesi.length + ')'">
									<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
									<b-row v-if="!loading">
										<b-col>
											<b-table striped hover :items="items_paesi" :fields="fields_paesi">
												<template #cell(sigla)="data">
													<flag :country="data.value" :size="32"></flag>
												</template>
											</b-table>
										</b-col>
									</b-row>
								</b-tab>
								<b-tab :title="'Competizioni (' + items_competizioni.length + ')'">
									<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
									<b-row v-if="!loading">
										<b-col>
											<b-table striped hover :items="items_competizioni" :fields="fields_competizioni">
												<template #cell(sigla)="data">
													<flag :country="data.value" :size="32"></flag>
												</template>
											</b-table>
										</b-col>
									</b-row>
								</b-tab>
								<b-tab :title="'Giocatori (' + items_giocatori.length + ')'">
									<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
									<b-row v-if="!loading">
										<b-col>
											<b-table striped hover :items="items_giocatori" :fields="fields_giocatori">
											</b-table>
										</b-col>
									</b-row>
								</b-tab>
							</b-tabs>
						</b-card>
						<h4 class="text-right mt-2"> Totale: {{totale}} €uro</h4>
					</div>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

import moment from 'moment';
import * as types from '../../../store/types';
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		flag: 				require('../../layout/flag.vue').default,
	},
	data: function(){
		return {
			loading: false,
			filters: {
				dal: moment().startOf('month').format('YYYY-MM-DD'), 
				al: moment().endOf('month').format('YYYY-MM-DD'),
			},
			costi: {},
			fields_partite: [
			{ key: 'sigla', label: ''},
			{ key: 'paese', label: 'Paese', sortable: true},
			{ key: 'competizione', label: 'Competizione', sortable: true},
			{ key: 'partita', label: 'Partita', sortable: true},
			{ key: 'data', label: 'Data', sortable: true},
			{ key: 'costo', label: 'Costo (€uro)', sortable: true},
			{ key: 'giocatori', label: 'Giocatori valutati'},
			{ key: 'osservatori', label: 'Osservatori'},
			],
			items_partite: [],	
			fields_paesi: [
			{ key: 'sigla', label: ''},
			{ key: 'nome', label: 'Paese', sortable: true},
			{ key: 'partite', label: 'Partite', sortable: true},
			{ key: 'costo', label: 'Costo totale (€uro)', sortable: true},
			],
			items_paesi: [],
			fields_competizioni: [
			{ key: 'sigla', label: ''},
			{ key: 'paese', label: 'Paese', sortable: true},
			{ key: 'competizione', label: 'Competizione', sortable: true},
			{ key: 'partite', label: 'Partite', sortable: true},
			{ key: 'costo', label: 'Costo totale (€uro)', sortable: true},
			],
			items_competizioni: [],
			fields_giocatori: [
			{ key: 'giocatore', label: 'Giocatore', sortable: true},
			{ key: 'partite', label: 'Partite', sortable: true},
			{ key: 'costo', label: 'Costo totale (€uro)', sortable: true},
			],
			items_giocatori: [],
			totale: 0,
		}
	},
	created: function () {
		this.getCosti();      
	},
	methods: {

		getCosti() {
			if(!this.filters.dal || !this.filters.al) {
				return;
			}
			this.loading = true;
			this.items_partite = [];
			this.items_paesi = [];
			this.items_competizioni = [];
			this.items_giocatori = [];
			this.costi = {};
			this.totale = 0;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/costi', {params: this.filters}).then((response) => {
				if (response.ok)
				{
					var partite = response.data; 
					for(var i in partite) {
						var partita = partite[i];
						var giocatori_partita = this.getGiocatoriValutati(partita);
						var osservatori_partita = this.getOsservatori(partita);
						var item = {
							id: partita.id,
							sigla: partita.competizione.paese.sigla,
							paese: partita.competizione.paese.paese,
							competizione: partita.competizione.nome,
							partita: this.getLabelPartita(partita),
							data: moment(partita.data).format("DD/MM/YYYY HH:mm"),
							costo: partita.costo ? partita.costo.costo : 0,
							giocatori: giocatori_partita,
							osservatori: osservatori_partita,
						};
						this.items_partite.push(item);
						this.costi[partita.id] = partita.costo ? partita.costo.costo : 0;

						this.totale = parseFloat(this.totale) + parseFloat(partita.costo ? partita.costo.costo : 0);
						
						var paese = null;
						for(var x in this.items_paesi) {
							if(this.items_paesi[x].id == partita.competizione.paese.id) {
								paese = this.items_paesi[x];
								break;
							}
						}
						if(!paese) {
							paese = {
								id: partita.competizione.paese.id,
								sigla: partita.competizione.paese.sigla,
								nome: partita.competizione.paese.paese,
								partite: 1,
								costo: partita.costo ? partita.costo.costo : 0,
							};
							this.items_paesi.push(paese);
						} else {
							paese.partite ++;
							paese.costo = parseFloat(paese.costo) + parseFloat(partita.costo ? partita.costo.costo : 0);
							paese.costo = Math.round(paese.costo * 100) / 100;
						} 

						var competizione = null;
						for(var x in this.items_competizioni) {
							if(this.items_competizioni[x].id == partita.competizione.id) {
								competizione = this.items_competizioni[x];
								break;
							}
						}
						if(!competizione) {
							competizione = {
								id: partita.competizione.id,
								sigla: partita.competizione.paese.sigla,
								paese: partita.competizione.paese.paese,
								competizione: partita.competizione.nome,
								partite: 1,
								costo: partita.costo ? partita.costo.costo : 0,
							};
							this.items_competizioni.push(competizione);
						} else {
							competizione.partite ++;
							competizione.costo = parseFloat(competizione.costo) + parseFloat(partita.costo ? partita.costo.costo : 0);
							competizione.costo = Math.round(competizione.costo * 100) / 100;
						}

						for(var y in giocatori_partita) {
							var gio_partita = giocatori_partita[y]; 

							var giocatore = null;
							for(var x in this.items_giocatori) {
								if(this.items_giocatori[x].id == gio_partita.id) {
									giocatore = this.items_giocatori[x];
									break;
								}
							}
							if(!giocatore) {
								giocatore = {
									id: gio_partita.id,
									giocatore: gio_partita.cognome + ' ' + gio_partita.nome,
									partite: 1,
									costo: partita.costo ? partita.costo.costo : 0,
								};
								this.items_giocatori.push(giocatore);
							} else {
								giocatore.partite ++;
								giocatore.costo = parseFloat(giocatore.costo) + parseFloat(partita.costo ? partita.costo.costo : 0);
								giocatore.costo = Math.round(giocatore.costo * 100) / 100;
							}
						}
					}
					this.totale = Math.round(this.totale * 100) / 100;
					this.loading = false;
				}
			}, (response) => {
            // error callback
        });
		},	

		getLabelPartita(partita) {
			var label = '';
			var squadre = partita.squadre;
			if(squadre[0].pivot.side == 'home') {
				label = squadre[0].officialName + " - " + squadre[1].officialName;
			} else {
				label = squadre[1].officialName + " - " + squadre[0].officialName;
			}
			return label;
		},
		getGiocatoriValutati(partita) {
			var giocatori = [];
			var giocatori_ids = [];
			for(var i in partita.osservati_valutazioni_partite) {
				var giocatore = partita.osservati_valutazioni_partite[i].valutazione.osservato;
				if(giocatori_ids.indexOf(giocatore.id) == -1) {
					giocatori.push(giocatore);
					giocatori_ids.push(giocatore.id);
				}
			}
			for(var i in partita.partite_scouting) {
				for(var j in partita.partite_scouting[i].reports) {
					for(var x in partita.partite_scouting[i].reports[j].giocatori) {
						var giocatore = partita.partite_scouting[i].reports[j].giocatori[x];
						if(giocatori_ids.indexOf(giocatore.id) == -1) {
							giocatori.push(giocatore);
							giocatori_ids.push(giocatore.id);
						}
					}
				}
			}
			return giocatori;	
		},
		getOsservatori(partita) {
			var osservatori = [];
			var osservatori_ids = [];
			for(var i in partita.osservati_valutazioni_partite) {
				var osservatore = partita.osservati_valutazioni_partite[i].valutazione.utente;
				if(osservatori_ids.indexOf(osservatore.id) == -1) {
					osservatori.push(osservatore);
					osservatori_ids.push(osservatore.id);
				}
			}
			for(var i in partita.partite_scouting) {
				for(var j in partita.partite_scouting[i].reports) {
					var osservatore = partita.partite_scouting[i].reports[j].utente;
						if(osservatori_ids.indexOf(osservatore.id) == -1) {
							osservatori.push(osservatore);
							osservatori_ids.push(osservatore.id);
						}
				}
			}
			return osservatori;	
		},
		salvaCosti() {
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/salva/costi', this.costi).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getCosti();
				}
			}, (response) => {
            // error callback
        });
		},
	},
	computed: {
		
	},
	watch: {
		'filters.dal': function () {
			this.getCosti();
		},
		'filters.al': function () {
			this.getCosti();
		},
	}

}
</script>
