<template id="presenze">
	<section class="presenze">
		<headapp></headapp>

		<div class="card border-0 rounded-0">
			<div class="card-body">
				<b-row align-v="center">
					<b-col>
						<div class="card-title"><span>Presenze</span></div>
					</b-col>
					<b-col>
						<div class="pull-right">
							<b-button variant="outline-primary" :to="{ name: 'football_allenamento', params: { id: $route.params.id }}"> <i class="fa fa-clock-o" aria-hidden="true"></i> Allenamento</b-button>
						</div>
					</b-col>
				</b-row>
				<div class="content" :key="'att_' + random">
					<b-row class="my-2">
						<b-col sm="2">
						</b-col>
						<b-col sm="1" class="text-center font-weight-bold">
							<span title="Presenza">PRES. </span><b-button size="sm" @click.prevent="clone('attendance')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
						<b-col sm="1" class="text-center font-weight-bold">
							TQR <b-button size="sm" @click.prevent="clone('tqr')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
						<b-col sm="1" class="text-center font-weight-bold">
							VAS <b-button size="sm" @click.prevent="clone('vas')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
						<b-col sm="2" class="text-center font-weight-bold">
							ZONA <b-button size="sm" @click.prevent="clone('id_zona')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
						<b-col sm="1" class="text-center font-weight-bold">
							VAS 2 <b-button size="sm" @click.prevent="clone('vas2')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
						<b-col sm="2" class="text-center font-weight-bold">
							ZONA 2 <b-button size="sm" @click.prevent="clone('id_zona2')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
						<b-col sm="1" class="text-center font-weight-bold">
							<span title="minuti">DURATA</span> <b-button size="sm" @click.prevent="clone('durata')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
						<b-col sm="1" class="text-center font-weight-bold">
							RPE <b-button size="sm" @click.prevent="clone('rpe')" title="clona prima cella"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
						</b-col>
					</b-row>
					<b-row :class="'py-2 ' + (index % 2 == 0 ? 'table-national' : '')" v-for="(item, index) in players" :key="index" :style="'background:' + (index == 10 ? 'red' : '')">
						<b-col sm="2" class="d-flex flex-column justify-content-center" :title="item.prova ? 'In prova' : ''">{{ item.name }} {{ item.prova ? '(*)' : ''}}</b-col>
						<b-col sm="1">
							<b-form-select v-model="attendances[item.id].attendance" @input="setAttendance(item.id)" title="Presenza">
								<option :value="null"></option>
								<option v-for="(value, index) in options" :value="value.id" :key="index">{{value.name}}</option>
							</b-form-select>
						</b-col>
						<b-col sm="1">
							<b-form-select v-model="attendances[item.id].tqr" @input="setAttendance(item.id)" title="TQR">
								<option :value="null"></option>
								<option v-for="(value, index) in 15" :value="value + 5" :key="index">{{ value + 5 }}</option>
							</b-form-select>
						</b-col>
						<b-col sm="1">
							<b-form-select v-model="attendances[item.id].vas" @input="setAttendance(item.id)" title="VAS">
								<option :value="null"></option>
								<option v-for="(value, index) in 10" :value="value" :key="index">{{ value }}</option>
							</b-form-select>
						</b-col>
						<b-col sm="2">
							<b-form-select v-model="attendances[item.id].id_zona" @input="setAttendance(item.id)" title="ZONA">
								<option :value="null"></option>
								<option v-for="(value, index) in aree" :value="value.id" :key="index">{{ value.area }}</option>
							</b-form-select>
						</b-col>
						<b-col sm="1">
							<b-form-select v-model="attendances[item.id].vas2" @input="setAttendance(item.id)" title="VAS 2">
								<option :value="null"></option>
								<option v-for="(value, index) in 10" :value="value" :key="index">{{ value }}</option>
							</b-form-select>
						</b-col>
						<b-col sm="2">
							<b-form-select v-model="attendances[item.id].id_zona2" @input="setAttendance(item.id)" title="ZONA 2">
								<option :value="null"></option>
								<option v-for="(value, index) in aree" :value="value.id" :key="index">{{ value.area }}</option>
							</b-form-select>
						</b-col>
						<b-col sm="1">
							<b-form-input v-model="attendances[item.id].durata" @change="setAttendance(item.id)" title="Durata allenamento"></b-form-input>
						</b-col>
						<b-col sm="1">
							<b-form-input v-model="attendances[item.id].rpe" @change="setAttendance(item.id)" title="RPE"></b-form-input>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>
import { mapState } from 'vuex';
import * as types from '../../../../store/types';
import axios from 'axios';
var Auth = require('../../../../auth.js');
export default {
	data: function(){
		return {
			players: [],
			options: [{id: 0, name:'Presente'},{id: 1, name:'Assente'},{id: 2, name:'Infortunato'},{id: 3, name:'Malato'},{id: 4, name:'Differenziato'}, {id: 5, name:'Altra squadra'}, {id: 6, name:'Nazionale'}, {id: 7, name:'Recovery'}],
			attendances: {},
			aree: [],
			attendancesSaved: [],
			season_id: null,
			random: Math.random(),
		}
	},
	created: function () {
		this.getAreeAnatomiche();
		this.getAttendences();
	},
	computed: {
		...mapState(['rosaTeamId']),
	},
	watch: {
		rosaTeamId() {
			this.getAttendences();
		},  
	},
	methods: {
		getPlayers: function(){
			this.$http.get(this.$store.state.apiEndPoint + '/players', {params: { team_id: this.rosaTeamId, season_id: this.season_id, no_stats: true, training_id: this.$route.params.id }}).then((response) => {
				if (response.ok)
				{
					if(response.data.players) {
						this.players = response.data.players.map(item => ({
							name: item.cognome + ' ' + item.nome,
							id: item.id,
							prova: item.last_carriera_sportiva.tipologia == 'prova' ? true : false,
						}));
						this.attendances = {};
						for(var i in this.players) {
							var obj = {
								attendance: null,
								durata: null,
								id_zona: null,
								id_zona2: null,
								persona_id: this.players[i].id,
								rpe: null,
								tqr: null,
								vas: null,
								vas2: null,
							}
							this.attendances[this.players[i].id] = obj;
						}
						for(var i in this.attendancesSaved) {
							var item = this.attendancesSaved[i];
							this.attendances[item.persona_id] = item;
						}
					}
				}
			}, (response) => {
			});
		},
		getAttendences: function() {
			const opts = {
				url: `/allenamento/${this.$route.params.id}/attendances`,
				method: 'GET',
			};

			axios(opts)
			.then((res) => {
				this.season_id = res.data.season_id;
				this.getPlayers();
				this.attendancesSaved = res.data.attendances;
			}).catch((err) => {
				this.loading = false;
			});
		},
		setAttendance: function(ID) {
			if(!Auth.isLastSeason(this.season_id)) {
				alert("Non puoi modificare le presenze allenamento di una stagione archiviata!");
				return;
			}
			var data = this.attendances[ID];

			if(data.rpe) {
				data.rpe = ('' + data.rpe).replace(",", ".");
				if(isNaN(data.rpe) || (!isNaN(data.rpe) && ((parseFloat(data.rpe)-0)*(parseFloat(data.rpe)-10) > 0))) {
					alert("RPE non valido! Deve essere un numero compreso fra 0 e 10 e può contenere valori decimali separati dal punto.");	
					return;
				}
			}
			if(data.durata) {
				if(!Number.isInteger(Number(data.durata)) || (Number.isInteger(Number(data.durata)) && parseInt(data.durata) < 0)) {
					alert("DURATA non valida! Deve essere un numero intero positivo.");	
					return;
				}
			}
			if(data.rpe == '') {
				data.rpe = null;
			}
			if(data.durata == '') {
				data.durata = null;
			}
			const opts = {
				url: `/allenamento/${this.$route.params.id}/save/attendance`,
				method: 'POST',
				data: data,
			};

			axios(opts)
			.then((res) => {
				var item = res.data.attendance;
				this.attendances[item.persona_id] = item;
				this.random = Math.random();
				this.$store.commit(types.ALERTS_PUSH, { msg: res.data.message, status: 1 });
			}).catch((err) => {
				this.errors = err.data;
				for (var index in this.errors) {
					this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				}
			});
		},
		getAreeAnatomiche: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/allenamento/aree/anatomiche').then((response) => {
				if (response.ok)
				{
					this.aree = response.data;
				}
			}, (response) => {
			});
		},
		clone(prop) {
			var index = 0;
			var value = null;
			for(var i in this.players) {
				if(index == 0) {
				value = this.attendances[this.players[i].id][prop];
				} else {
					this.attendances[this.players[i].id][prop] = value;
				//	if(prop == 'durata' || prop == 'rpe') {
						this.setAttendance(this.players[i].id);
				//	}
				}
				index++;
			}
		}
	},
	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
	},
}
</script>
