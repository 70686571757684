<template>
	<div>
		<div class="eeeee" @click.prevent="$emit('selectfase', i.fase_originale_id)" @mouseenter="showTimes = i.fase_id" @mouseleave="showTimes = null" style="position: relative; height: 20px; margin-bottom: 2px" v-for="i in ttl">
			<div v-b-popover.html.hover.top="'Durata: ' + calcolaDurata(i) + '<br />Inizio: ' + format(i.start) + '<br />Fine: ' + format(i.end)" :title="i.nome_fase" :style="'border-radius: 4px; box-shadow: 2px 2px 10px rgba(0,0,0,.2); width: '+(100*i.percentage)+'%; background-color: '+ (i.selected == true ? 'rgba(0,0,0,.8)' : i.almostHide ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,.4)') +'; height: 20px; left: '+(i.start_p*100)+'%; position: absolute'">
				
				<div v-if="i.selected" class="small" style="z-index: 99; position: absolute; left: -36px; width: 35px; height: 17px; background-color: white">{{ i.start | h }}</div>
				<div v-if="i.selected" class="small" style="z-index: 99; position: absolute; right: -36px; width: 35px; height: 17px; background-color: white; text-right">{{ i.end | h }}</div>

			</div>
		</div>
		<div class="d-flex justify-content-between mt-3">
			<div>{{ inizio }}</div>
			<div>{{ fine }}</div>
		</div>
	</div>
</template>

<style type="text/css">

.eeeee:hover {
	background-color: rgba(0,0,0,.1);
}

</style>

<script>

import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	props: [ 'timeline', 'filters' ],
	filters: {
		r: function(a, b) {
			return _.round(a, b);
		},
		h: function(a) {
			return moment.unix(a).utcOffset('+0100').format('HH:mm');
		}
	},
	methods: {
		format: function(a) {
			return moment.unix(a).utcOffset('+0100').format('DD/MM/YYYY HH:mm');
		},
		calcolaDurata: function(i) {
			return moment('2000-01-01').add(i.end - i.start, 'seconds').format('HH:mm');
		}
	},
	data: function() {
		return {
			showTimes: null
		}
	},
	computed: {
		inizio: function() {
			return moment.unix(_.first(_.sortBy(this.timeline, ['start'])).start).utcOffset('+0100').format('HH:mm');
		},
		fine: function() {
			return moment.unix(_.last(_.sortBy(this.timeline, ['end'])).end).utcOffset('+0100').format('HH:mm');
		},
		ttl: function() {
			var overallLength = _.last(_.sortBy(this.timeline, ['end'])).end - _.first(_.sortBy(this.timeline, ['start'])).start;
			var prev = 0;
			return _.map(this.timeline, function(a) {

				console.log(this.filters.fasi.indexOf(a.fase_originale_id), a.fase_originale_id, this.filters.fasi)

				a.duration = a.end - a.start;
				a.percentage = a.duration/overallLength;
				a.start_p = (a.start - _.first(this.timeline).start) /overallLength;
				a.selected = (this.filters.fasi.indexOf(a.fase_originale_id) !== -1);

				a.almostHide = false;

				if(this.filters.players.length > 0) {
					if( _.difference(this.filters.players, a.persone).length !== 0)
						a.almostHide = true;
				}

				prev += a.percentage;
				return a;

			}.bind(this));
		}
	}
}

</script>