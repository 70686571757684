<template>
	<b-card title="Corners">
		<p>Corner a favore</p>
		<b-row>
			<b-col cols="8" class="pr-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th>CrnrWon</th>
							<th>Shots Analysis: Corner</th>
							<th>CrnrShot</th>
							<th>GoalCrnrs</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams" :class="item.optaId == team.opta.chiave ? 'bg-warning' : ''">	
							<td>{{ item.name }}</td>
							<td>{{ item.crnrWon }}</td>	
							<td>{{ item.shotsAnalysisCorner }}</td>
							<td>{{ item.crnrShot }}</td>
							<td>{{ item.goalCrnrs }}</td>			
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
			<b-col class="pl-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th>%CornerShot</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams_2" :class="item.optaId == team.opta.chiave ? 'bg-warning' : ''">	
							<td>{{ item.name }}</td>
							<td>{{ item.crnrShotPerc }}</td>	
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
		</b-row>
		<p>Corner contro</p>
		<b-row>
			<b-col cols="8" class="pr-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th>CrnrWon (opponent)</th>
							<th>Shots Analysis: Corner (opponent)</th>
							<th>CrnrShot (opponent)</th>
							<th>GoalCrnrs (opponent)</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams_3" :class="item.optaId == team.opta.chiave ? 'bg-warning' : ''">	
							<td>{{ item.name }}</td>
							<td>{{ item.crnrWonOpp }}</td>	
							<td>{{ item.shotsAnalysisCornerOpp }}</td>
							<td>{{ item.crnrShotOpp }}</td>
							<td>{{ item.goalCrnrsOpp }}</td>			
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
			<b-col class="pl-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th>%CornerShot (opponent)</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams_4" :class="item.optaId == team.opta.chiave ? 'bg-warning' : ''">	
							<td>{{ item.name }}</td>
							<td>{{ item.crnrShotOppPerc }}</td>	
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team'],
	data: function() {
		return {

		}
	},
	computed: {
		teams: function() {
			return this.stats && this.stats.teams ? this.stats.teams : [];
		},
		sorted_teams: function() {
			return _.orderBy(this.teams, ['crnrWon'],['desc']);
		},
		sorted_teams_2: function() {
			return _.orderBy(this.teams, ['crnrShotPerc'],['desc']);
		},
		sorted_teams_3: function() {
			return _.orderBy(this.teams, ['crnrWonOpp'],['desc']);
		},
		sorted_teams_4: function() {
			return _.orderBy(this.teams, ['crnrShotOppPerc'],['desc']);
		},
	},
}
</script>
