<template>
	<div>
		<b-row class="mb-2">
			<b-col>
				<b-form-group label="Seleziona una data">
					<b-form-input v-model="filters.date" type="date"></b-form-input>
				</b-form-group>
				<b-button size="sm" variant="primary" @click="getPlayersSessions" :disabled="ux.disabled || filters.type_ids.length == 0">Visualizza risultati</b-button>
				<b-button size="sm" variant="primary" @click="download" :disabled="filters.type_ids.length == 0"><i class="fa fa-download" aria-hidden="true"></i> Download</b-button>
			</b-col>
			<b-col v-if="!session_type_ids">
				<b-row>
					<b-col>
						Filtra per sessioni
					</b-col>
					<b-col class="text-right" cols="3">
						<a href="javascript:;" class="small text-muted" v-if="filters.type_ids.length" @click.prevent="sessioni_tipi = []">Svuota ({{ filters.type_ids.length }})</a>
					</b-col>
				</b-row>
				<multiselect v-model="sessioni_tipi" :options="session_options_sorted" :multiple="true" placeholder="Seleziona" label="nome" track-by="tipologia_id" group-values="items" group-label="label" :group-select="true"></multiselect>
			</b-col>
			<b-col>
				<b-row>
					<b-col>
						Filtra per giocatori
					</b-col>
					<b-col class="text-right" cols="3">
						<a href="javascript:;" class="small text-muted" v-if="filters.player_ids.length" @click.prevent="players = []">Svuota ({{ filters.player_ids.length }})</a>
					</b-col>
				</b-row>
				<multiselect v-model="players" :options="player_options" :multiple="true" placeholder="Seleziona" label="name" track-by="id" group-values="items" group-label="label" :group-select="true"></multiselect>
			</b-col>
		</b-row>
		<b-overlay :show="ux.waiting">
			<b-table-simple small responsive bordered sticky-header="60vh">
				<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
					<b-tr>
						<b-th width="10%">
							Giocatore
						</b-th>
						<b-th width="10%">
							Orario
						</b-th>
						<b-th v-for="column in columns" width="20%">
							{{ session_type_ids ? getLabelColumn(column) : column }}	
						</b-th>
						<b-th width="4%">
							Gps
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="item in table_rows">
						<b-td :colspan="3 + columns.length" style="padding:0">
							<b-table-simple hover small responsive bordered style="margin:0">
								<b-tbody>
									<b-tr v-for="session, index in item.sessioni">
										<b-td width="10%">{{ index == 0 ? item.player : ''}}</b-td>
										<b-td width="10%">{{ session.dopo_allenamento && injured ? 'Dopo Allenamento' : session.orario }}</b-td>
										<!--b-td v-for="column in columns" width="20%">{{ session_type_ids ? (session.sessione_tipo_id == column && index == getIndexSession(item.sessioni, session.sessione_id) ? ( session.dopo_controllo_medico && injured ? 'Controllo Medico' + (session.nota && session.nota != '' ? ' - ' + session.nota : '') : session.operatore) : '&nbsp;') : (column == 'Sessione' ?  session.sessione : (session.operatore != '✓' ? session.operatore : '')) }}</b-td-->
										<b-td v-for="column in columns" width="20%">{{ session_type_ids ? (session.sessione_tipo_id == column && index == getIndexSession(item.sessioni, session.sessione_id) ? ( session.dopo_controllo_medico && injured ? 'Controllo Medico' + (session.nota && session.nota != '' ? ' - ' + session.nota : '') : session.operatore) : '&nbsp;') : (column == 'Sessione' ? session.sessione : ((session.operatore != '✓' ? session.operatore + (session.dopo_controllo_medico ? ' / ' : '') : '') + (session.dopo_controllo_medico ? 'Controllo Medico' + (session.nota && session.nota != '' ? ' - ' + session.nota : '') : ''))) }}</b-td>
										<b-td width="4%">{{ session_type_ids ? (index == getIndexSession(item.sessioni, session.sessione_id) && session.gps ? '✓' : '&nbsp;') : (session.gps ? '✓' : '&nbsp;') }}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>	
						</b-td>	
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<b-table-simple small responsive bordered sticky-header="60vh" v-if="injured">
				<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
					<b-tr>
						<b-th width="10%">
							Giocatore
						</b-th>
						<b-th width="10%">
							Orario
						</b-th>
						<b-th v-for="column in columns" width="20%">
							{{ session_type_ids ? getLabelColumn(column) : column }}	
						</b-th>
						<b-th width="4%">
							Gps
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="item in table_rows_injured">
						<b-td :colspan="3 + columns.length" style="padding:0">
							<b-table-simple hover small responsive bordered style="margin:0">
								<b-tbody>
									<b-tr v-for="session, index in item.sessioni">
										<b-td width="10%">{{ index == 0 ? item.player : ''}}</b-td>
										<b-td width="10%">{{ session.dopo_allenamento && injured ? 'Dopo Allenamento' : session.orario }}</b-td>
										<b-td v-for="column in columns" width="20%">{{ session_type_ids ? (session.sessione_tipo_id == column && index == getIndexSession(item.sessioni, session.sessione_id) ? ( session.dopo_controllo_medico && injured ? 'Controllo Medico' + (session.nota && session.nota != '' ? ' - ' + session.nota : '') : session.operatore) : '&nbsp;') : (column == 'Sessione' ?  session.sessione : (session.operatore != '✓' ? session.operatore : '')) }}</b-td>
										<b-td width="4%">{{ session_type_ids ? (index == getIndexSession(item.sessioni, session.sessione_id) && session.gps ? '✓' : '&nbsp;') : (session.gps ? '✓' : '&nbsp;') }}</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>	
						</b-td>	
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</div>
</template>

<script>

import moment from 'moment';
var Auth = require('@/auth.js');
export default {
	props: [ 'team', 'player_options', 'session_options', 'session_type_ids', 'injured'],
	data: function() {
		return {
			results: [],
			filters: {
				date: moment().format("YYYY-MM-DD"),
				categoria_id: null,
				player_ids: [],
				type_ids: [],
				injured: this.injured ? 1 : 0
			},
			ux: {
				waiting: false,
				disabled: false,
			},
			sessioni_tipi: [],
			players: []
		}
	},
	watch: {
		filters: {
			deep: true,
			handler: function() {
				this.ux.disabled = false;		
			}
		},
		sessioni_tipi: function() {
			this.filters.type_ids = _.map(this.sessioni_tipi, 'tipologia_id');
		},
		players: function() {
			this.filters.player_ids = _.map(this.players, 'id');
		}
	},
	created: function() {
		this.filters.categoria_id = this.team.id_categoria;
		if(this.session_type_ids) {
			this.filters.type_ids = this.session_type_ids;
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		columns: function() {
			if(this.session_type_ids) {
				return _.sortBy(_.filter(_.uniq(_.map(_.flatten(_.map(this.results, 'sessioni')), function(a) {
					return  a && a.sessione_tipo_id;		
				})), function(b) {
					return b;		
				}));
			} else {
				return ['Sessione', 'Staff'];
			}
		},
		session_options_sorted: function() {
			return [{
				label: 'Seleziona tutti',
				items: _.sortBy(this.session_options, ['nome'])
			}];
		},
		table_rows: function() {
			if(this.injured) {
				return _.filter(this.results, { injuried: false });
			}
			return this.results;
		},
		table_rows_injured: function() {
			if(this.injured) {
				return _.filter(this.results, { injuried: true });
			}
			return [];		
		}
	},
	methods: {
		getPlayersSessions() {
			this.ux.waiting = true;
			this.ux.disabled = true;
			this.$http.get(this.$store.state.apiEndPoint + '/report/players/sessions', { params: this.filters }).then(function(res) {
				this.results = res.data;
				this.ux.waiting = false;
			});
		},
		getIndexSession(sessioni, sessione_id) {
			return sessioni.findIndex(item => item.sessione_id == sessione_id);
		},
		getLabelColumn(sessione_tipo_id) {
			return _.find(_.flatten(_.map(this.results, 'sessioni')), { sessione_tipo_id: sessione_tipo_id }).colonna;
		},
		download: function() {
			var url = [this.$store.state.apiEndPoint + '/report/download/players/sessions/' + this.user.id + '/' + this.user.api_token];
			url.push('?date=' + this.filters.date);
			url.push('&categoria_id=' + this.filters.categoria_id);
			url.push('&type_ids=' + this.filters.type_ids);
			if(this.filters.player_ids.length > 0) {
				url.push('&player_ids=' + this.filters.player_ids);
			}
			url.push('&mode=' + (this.session_type_ids ? 'fisioterapia' : 'generico' ));
			url.push('&injured=' + this.filters.injured);
			window.open(url.join(''));
		},
	}
}

</script>