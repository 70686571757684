<template id="resalert">
	<div class="resalert" v-show="this.$parent.showResalert">

		<div>
			<p>
				Ti informiamo che il sistema è stato ottimizzato per una risoluzione di almeno 1024 x 768 px.<br>
				Per gli schermi di dimensioni inferiori la vista potrebbe non essere ottimale.<br>
				Prova ad usare l'app con il tuo tablet o direttamente da computer.
			</p>

			<button v-on:click="hideresalert()" class="btn btn-sm btn-primary">Chiudi</button>
		</div>

	</div>
</template>

<script>
export default {
	methods:{

		hideresalert: function(){

			this.$parent.showResalert = false;

		}

	},
}
</script>
