<template>
	<div>
		<h5>Nuova Squadra</h5>

		<b-form @submit.prevent="createTeam">
			<team-form :team="team"></team-form>
			<hr />
			<b-button type="submit" variant="outline-success">Crea Squadra</b-button>
		</b-form>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: [ 'competition' ],
	components: {
		TeamForm: require('@/components/redazione/components/teams/form.vue').default
	},
	data: function() {
		return {
			team: {
				competition_stage_id: null,
				name: null,
				image: null,
				url: null
			}
		}
	},
	methods: {
		createTeam: function() {

			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/new-team', { team: this.team }).then(function(data) {
				this.$emit('refresh-stage');
				this.$router.push({ name: 'CompetitionStage', params: { competition_id: this.$route.params.competition_id, stage_id: this.$route.params.stage_id } });
			})

		}
	}
}

</script>