<style>
.over{ cursor: pointer; }
.over:hover{
  background-color: #dedede;
  cursor: pointer;
}
.selactive {
  background-color: #cecece;
}
</style>

<template id="exerciseform">
  <div class="container-fluid" :key="random">
    <div class="card-title">
      <span>Editor Esercizi</span>
      <div class="pull-right">
        <b-button variant="outline-primary" @click="showGuida"><i class="fa fa-question-circle"></i> guida </b-button>
        <b-button variant="outline-primary" @click="downloadPdf" v-if="$route.params.id"><i class="fa fa-eye fa-fw"></i> stampa pdf</b-button>
        <b-button variant="outline-primary" :to="{ name: 'football_video_esercizio', params: { id: $route.params.id }}" v-if="$route.params.id && auth.isUserEnable('video_esercizi')"> <i class="fa fa-video-camera" aria-hidden="true"></i> Video 
        </b-button>
    </div>
</div> 
<b-card class="mb-2" v-if="certificato == 2">
  <h4 class="color-rosso">RIFIUTATO</h4>
  <b-form-textarea id="textarea3" plaintext :value="motivo">   
  </b-form-textarea>
</b-card>
<form v-on:submit.prevent="submit()" action="#" method="POST">
  <input id="resolution_w" type="hidden" name="resolution_w" :value="resolution_w"/>
  <input id="resolution_h" type="hidden" name="resolution_h" :value="resolution_h"/>
  <div>
    <div class="card mb-2">
      <div class="card-body">
        <div class="form-group row">
          <label for="titolo" class="col-2 col-form-label">Titolo esercizio</label>
          <div class="col-10">
            <input class="form-control" type="text" id="title" v-model="title" name="title" value="" placeholder="Titolo esercizio" />
        </div>
    </div>
    <div class="form-group row flex-items-xs-bottom">
      <label class="col-2 col-form-label">Difficoltà</label>

      <div class="col-4">

        <b-form-select v-model="difficulty" :options="difficulties">
          <template slot="first">
            <!-- this slot appears above the options from 'options' prop -->
            <option :value="null" disabled>seleziona</option>
        </template>
    </b-form-select>
</div>
<label class="col-1 col-form-label"></label>
<label class="col-1 col-form-label">Tipologia</label>

<div class="col-4">

    <b-form-select v-model="drill_id">
      <option :value="null">seleziona</option>
      <option v-for="(opt, index) in drill_options" :value="opt.id" :key="index">{{ opt.name }}</option>

  </b-form-select>
</div>
</div>
</div>
</div>
<div v-show="editorActive">
  <div class="card-deck d-block d-xl-flex">
    <div class="card col-xl-3 px-0">
      <div class="card-header pb-2" id="tabs">
        <ul class="nav nav-tabs card-header-tabs" role="tablist">
          <li class="nav-item m-0 p-0" v-for="(objects, category, id) in canvas_objs" :key="category">
            <a :href="'#' + category" data-toggle="tab" class="nav-link text-capitalize" role="tab" v-bind:class="{'active':(id === 0)}" style="padding: 0.5rem 0.47rem;">{{ category == 'backgrounds' ? 'fields' : category }}</a>
        </li>
        <li class="nav-item m-0 p-0">
            <a href="#editor" data-toggle="tab" class="nav-link text-capitalize" role="tab" style="padding: 0.5rem 0.47rem;">editor</a>
        </li>
    </ul>
</div>
<div class="card-body tab-content border-0">
    <div class="tab-pane clearfix" :id="category" role="tabpanel" v-for="(objects, category, id) in canvas_objs" :key="category" v-bind:class="{'active':(id === 0)}">

      <b-form-row v-if="category == 'players'" class="p-3">
        <b-col>
          <select v-model="shirt" class="form-control">
            <option value="all">Maglie</option>
            <option value="first">Prima</option>
            <option value="second">Seconda</option>
            <option value="third">Terza</option>
            <option value="fourth">Quarta</option>
        </select>
    </b-col>
    <b-col>
      <select v-model="move" class="form-control">
        <option value="all">Movimenti</option>
        <option value="static">Statico</option>
        <option value="dynamic">Dinamico</option>
    </select>
</b-col>
</b-form-row>

<div class="d-inline-block text-center" :style="'min-width: 50px; height: 50px;cursor:pointer;border-radius: 5px;' + (activeObject && obj.id === activeObject.id ? 'background: #a80532;' : '')" @click="changeCanvasBkg(obj, category)" v-for="(obj, index) in objects" :key="index" >
    <img :src="obj.image_url" :class="obj.shirt +' '+ obj.move" style="max-width: 40px;" />
</div>

</div>

<div class="tab-pane clearfix" id="editor" role="tabpanel">
  <b-row align-v="center">
    <b-col cols="3" class="mb-2">
        <b-form-input style="height:38px" type="color" v-model="text_color" :title="'Colore: ' + text_color"></b-form-input>
    </b-col>
    <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'Text' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Text')" title="Testo"><i class="fa fa-font" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button variant="outline-primary" style="width:100%" @click="cloneCanvasObject" title="Clona selezionati"><i class="fa fa-clone" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button variant="outline-primary" style="width:100%" @click="deleteCanvasObject" title="Cancella selezionati"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'Line' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Line')" title="Freccia"><i class="fa fa-long-arrow-right" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'DashedLine' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('DashedLine')" title="Freccia tratteggiata"><i class="fa fa-ellipsis-h" aria-hidden="true"></i> <i class="fa fa-caret-right" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'FlatLine' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('FlatLine')" title="Linea"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'FlatDashedLine' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('FlatDashedLine')" title="Linea tratteggiata"><i class="fa fa-ellipsis-h" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'CurvedArrow' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('CurvedArrow')" title="Curva"><i class="fa fa-share" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'Triangle' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Triangle')" title="Triangolo"><i class="fa fa-play" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
      <b-button style="width:100%" :variant="activeObject === 'Rect' ? 'primary' : 'outline-primary'" @click="clearAndSetActiveObject('Rect')" title="Rettangolo"><i class="fa fa-stop" aria-hidden="true"></i></b-button>
  </b-col>
  <b-col cols="3" class="mb-2">
    <b-form-input type="range" v-model="opacity" min="0.1" max="1" step="0.1" class="p-0" :title="'Trasparenza triangolo / rettangolo: ' + opacity"></b-form-input>
</b-col>
<b-col cols="3" class="mb-2">
  <b-button style="width:100%" :variant="canvasObj.drawingMode ? 'primary' : 'outline-primary'" @click="toggleDrawingMode" :title="canvasObj.drawingMode ? 'Esci dalla modalità disegno' : 'Entra in modalità disegno'"><i class="fa fa-pencil" aria-hidden="true"></i></b-button>
</b-col>
<b-col cols="3" class="mb-2" v-show="canvasObj.drawingMode">
    <b-form-input :title="'Spessore linea: ' + line_width" type="range" v-model="line_width" min="1" max="10" class="p-0"></b-form-input>
</b-col>
<b-col cols="3" class="mb-2">
  <b-button style="width:100%" :variant="canvasObj.wavy ? 'primary' : 'outline-primary'" @click="toggleWavy" :title="canvasObj.wavy ? 'Esci dalla modalità disegno freccia ondulata' : 'Entra in modalità disegno freccia ondulata'"><i class="fa fa-line-chart" aria-hidden="true"></i></b-button>
</b-col>
<b-col cols="3" class="mb-2" v-show="canvasObj.wavy">
    <b-form-checkbox v-model="canvasObj.dashed">
      Tratteggiata
  </b-form-checkbox>
</b-col>
</b-row>
</div>

</div>
</div>

<div class="card col-xl-9">
  <div class="">
      <div id="field" class="card-body" ref="my_editor">

          <img v-if="this.$route.params.id && immagine != ''" :src="immagine" class="img-fluid mb-2" />
          <div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
          <canvas-obj v-if="exercise" ref="my_canvas"></canvas-obj>

      </div>
  </div>
</div>

</div>
</div>
<div v-show="!editorActive">
  <b-row align-h="center" class="my-3">
    <b-col cols="9">
      <img class="img-fluid" :src="no_canvas_url"/>
  </b-col>
</b-row>
<b-form-file
accept="image/*"
v-model="image_file"
:state="Boolean(image_file)"
placeholder="Scegli o trascina un'immagine da caricare..."
drop-placeholder="Trascina un'immagine qui..."
></b-form-file> 
</div>
<b-button @click="switchEditor" variant="primary" size="sm" class="mt-3">{{ editorActive ? "Carica un'immagine" : "Passa all'editor esercizi " }}</b-button>
<hr/>
<div class="row">
  <div class="col-sm">

    <div class="form-group">
      <label>Descrizione</label>
      <textarea
      name="description"
      rows="6"
      class="form-control"
      v-model="description"
      placeholder="Inserisci una descrizione per far capire lo svolgimento dell'esercizio"
      ></textarea>
  </div>
</div>
<div class="col-sm">

    <div class="form-group">
      <label>Materiale Occorrente</label>
      <textarea
      name="material_required"
      rows="6"
      class="form-control"
      v-model="material_required"
      placeholder="Inserisci il materiale che occorre per lo svolgimento dell'esercizio"
      ></textarea>
  </div>
</div>
</div>

<hr />
<SkillPickerGroup :model="skills_rel" :config="{ radici: !portiere ? [1, 30, 61] : [86, 99, 124, 61] }"></SkillPickerGroup>
<b-button @click="switchFilter" size="sm" class="mt-2">{{ portiere ? 'passa agli obiettivi giocatore' : 'passa agli obiettivi portiere'}}</b-button>

<hr />
<div class="row">
    <div class="col-sm">
        <label>	Minuti esercitazione</label>
        <b-form-select v-model="min_exs">
          <option :value="null">seleziona</option>
          <option v-for="(value, index) in 120" :value="value" :key="index">{{value}}</option>
      </b-form-select>

  </div>
  <div class="col-sm">
    <label>	Numero di serie</label>
    <b-form-select v-model="num_serie">
      <option :value="null">seleziona</option>
      <option v-for="(value, index) in 20" :value="value" :key="index">{{value}}</option>
  </b-form-select>
</div>
<div class="col-sm">
    <label>	Minuti di recupero</label>
    <b-form-select v-model="min_rec">
      <option :value="null">seleziona</option>
      <option v-for="(value, index) in 120" :value="value" :key="index">{{value}}</option>
  </b-form-select>

</div>
<div class="col-sm">
    <label> Secondi di recupero</label>
    <b-form-select v-model="sec_rec">
      <option :value="null">seleziona</option>
      <option v-for="(value, index) in 11" :value="value * 5" :key="index">{{value * 5}}</option>
  </b-form-select>

</div>
<div class="col-sm">

    <label>	Numero di recuperi</label>
    <b-form-select v-model="num_rec">
      <option :value="null">seleziona</option>
      <option v-for="(value, index) in 20" :value="value" :key="index">{{value}}</option>
  </b-form-select>

</div>
<div class="col-sm">
    <label style="display:block;">	Minuti totali </label>
    <span>{{this.calculate_tot_min}}</span>
</div>
</div>

<hr />

<div class="row">
  <div class="col-sm">
    <label>Tot. giocatori</label>
    <input
    name="num_player"
    type="number"
    v-model="num_player"
    class="form-control"
    min="0"
    ></input>  
</div>
<div class="col-sm">
    <label>G1</label>
    <input
    name="g1"
    type="number"
    v-model="g1"
    class="form-control"
    min="0"
    ></input>  
</div>
<div class="col-sm">
    <label>G2</label>
    <input
    name="g2"
    type="number"
    v-model="g2"
    class="form-control"
    min="0"
    ></input>  
</div>
<div class="col-sm">
    <label>G3</label>
    <input
    name="g3"
    type="number"
    v-model="g3"
    class="form-control"
    min="0"
    ></input>  
</div>
<div class="col-sm">
    <label>G4</label>
    <input
    name="g4"
    type="number"
    v-model="g4"
    class="form-control"
    min="0"
    ></input>  
</div>
</div>

<div class="row mt-2">
    <div class="col-sm">
        <label>Jolly</label>
        <input
        name="jolly"
        type="number"
        v-model="jolly"
        class="form-control"
        min="0"
        ></input>  
    </div>
    <div class="col-sm">
        <label>Sponde</label>
        <input
        name="sponde"
        type="number"
        v-model="sponde"
        class="form-control"
        min="0"
        ></input>  
    </div>
    <div class="col-sm">
        <label>Portieri</label>
        <input
        name="portieri"
        type="number"
        v-model="portieri"
        class="form-control"
        min="0"
        ></input>  
    </div>
    <div class="col-sm">
        <label>Porte grandi</label>
        <input
        name="porte_grandi"
        type="number"
        v-model="porte_grandi"
        class="form-control"
        min="0"
        ></input>  
    </div>
    <div class="col-sm">
        <label>Porte piccole</label>
        <input
        name="porte_piccole"
        type="number"
        v-model="porte_piccole"
        class="form-control"
        min="0"
        ></input>  
    </div>
</div>

<div class="row mt-2">
    <div class="col-sm">
        <div class="form-group">
          <label>Lunghezza campo</label>
          <input
          name="lunghezzaCampo"
          type="number"
          v-model="campo_lunghezza"
          class="form-control"
          min="0"
          ></input>  
      </div>
  </div>
  <div class="col-sm">
    <div class="form-group">
      <label>Larghezza campo</label>
      <input
      name="larghezzaCampo"
      type="number"
      v-model="campo_larghezza"
      class="form-control"
      min="0"
      ></input>  
  </div>
</div>
<div class="col-sm">
    <label style="display:block;">  Densità campo </label>
    <span>{{ densita_campo }}</span>
</div>
</div>

<hr />

<div class="row">
  <div class="col-sm ">
    <label style="display:block;"> Esercizio differenziato </label>
    <b-form-select v-model="differenziato">
      <option :value="null">seleziona</option>
      <option v-for="(value, index) in checklist" :value="value.value" :key="index">{{value.name}}</option>
  </b-form-select>
  <small id="passwordHelpBlock" class="form-text text-muted">
      flaggare nel caso in qui l'esercizio si rivolga a migliorare un'abilità o un programma di recupero
  </small>
</div>
</div>

<div class="action-actions" v-if="!auth.isUserEnable('football_valida_ese') && stato == 0">
  <hr />
  <label style="display:block;">  Metti in validazione </label>
  <b-form-select v-model="new_stato">
    <option :value="null">seleziona</option>
    <option v-for="(value, index) in checklist2" :value="value.value" :key="index">{{value.name}}</option>
</b-form-select>
<small id="passwordHelpBlock" class="form-text text-muted">
    flaggare nel caso in qui si desidera far validare l'esercizio, l'esercizio non potrà più essere modificato
</small>
</div>

<div class="action-actions" v-if="auth.isUserEnable('football_valida_ese') && certificato == 0 && exe_id > 0">
  <hr />
  <label style="display:block;">	Valida esercizio </label>
  <b-form-textarea id="textarea1"
  v-model="motivo"
  placeholder="Inserisci la motivazione del rifiuto"
  :rows="2"
  :max-rows="4" class="mb-2">
</b-form-textarea>
<b-button variant="primary" class="mr-2" @click="accetta">Accetta</b-button>
<b-button @click="rifiuta">Rifiuta</b-button>
<small id="passwordHelpBlock" class="form-text text-muted">
   premi su accetta per certificare l'esercizio ed essere utilizzato nelle fasi di allenamento, premi su rifiuta per rimandare l'esercizio in bozza
</small>
</div>

<hr />

<div class="row">
  <div class="col text-right">
    <button class="btn btn-primary btn-lg text-uppercase" v-if="auth.isAdmin() || auth.isAdminFootball() || exe_id == undefined || (exe_id > 0 && user_id == auth.user.id && stato != 2)">Salva</button>
    <b-button class="btn btn-success btn-lg text-uppercase ml-2" @click="clonaEsercizio" v-if="exe_id > 0">Clona</b-button>
    <b-button class="btn btn-lg text-uppercase ml-2" @click="eliminaEsercizio" v-if="exe_id > 0 && (auth.isAdmin() || auth.isAdminFootball() || (user_id == auth.user.id && stato == 0))">Elimina</b-button>
</div>
</div>

</div>
</form>
<b-modal ref="infoModal" title="Guida esercizi" size="lg" ok-only ok-title="Chiudi">
  <div>
    <b-img src="/assets/varie/guida_esercizi.jpg" fluid/>
</div>
</b-modal>
</div>
</template>


<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import { base64ToBlob } from '../../../lib/image';
import { mapState } from 'vuex';
import { jsPDF } from 'jspdf';

export default {
  components: {
    Multiselect,
    exerciseform:      require('./form.vue').default,
    canvasObj:         require('./canvas.vue').default,
    SkillPickerGroup: require('@/components/layout/Sessioni/Generica/SkillPickerGroup.vue').default,
},

data: function() {
    return {
      resolution_w: null,
      resolution_h: null,
      show_font_size: 0,
      exe_id: this.$route.params.id,
      shirt: 'first',
      move: 'all',  
      checklist: [{value:1, name: 'si'}, {value:0, name:'no'}],
      checklist2: [{value:2, name: 'si'}, {value:0, name:'no'}],
      bkg_default: '',
      canvas: null,
      sfondo: null,
      is_drawing_mode: false,
      line_color: '#000000',
      testo: '',
      editorActive: true,
      exercise: false,

      title: '',
      difficulty: null,
      description: 'Obiettivi secondari: \n\n' + 'Dislocazione giocatori: \n\n' + 'Descrizione: \n\n' + 'Varianti: ',
      material_required: '',
      num_player: null,
      min_exs: null,
      num_serie: null,
      min_rec: null,
      sec_rec: null,
      num_rec: null,
      stato: null,
      new_stato: 0,
      certificato: null,
      differenziato: null,
      min_tot_ex: null,
      soccer_field_size: null,

      difficulties: [{value: 0, text: "Facile"}, {value: 1, text: "Medio"}, {value: 2, text: "Difficile"}],
      skills: [],
      soccer_field_sizes: [],

      canvas_objs: null,
      canvas_players_objs: null,
      canvasImage: '',
      immagine: '',
      loading: false,
      user_id: 0,
      show_fields_size: false,
      portiere: 0,
      motivo: '',
      header_pdf: null,
      autore: '',
      team: null,
      categoria: null,
      no_canvas_url: null,

      drill_options: [],
      drill_id: null,
      g1: null,
      g2: null,
      g3: null,
      g4: null,
      jolly: null,
      sponde: null,
      portieri: null,
      porte_grandi: null,
      porte_piccole: null,
      campo_lunghezza: null,
      campo_larghezza: null,

      image_file: null,
      skills_rel: [],

      random: Math.random(),
  }
},

methods: {
    clearAndSetActiveObject(obj) {
        this.$root.$emit('canvas::clearSelection');
        this.$store.commit('exercises/setActiveObject', obj);
    },

    toggleDrawingMode() {
        this.$store.commit('exercises/toggleDrawingMode');
    },

    toggleWavy() {
        this.$store.commit('exercises/toggleWavy');
    },

    cloneCanvasObject() {
        this.$root.$emit('canvas::cloneObjects');
    },

    deleteCanvasObject() {
        this.$root.$emit('canvas::deleteObjects');
    },

    submit: function(){
        this.resolution_w  = document.getElementsByClassName('canvas-container')[0].offsetWidth;
        this.resolution_h  = document.getElementsByClassName('canvas-container')[0].offsetHeight;

        const formData = new FormData();
        if(this.exe_id > 0) formData.set('id', this.exe_id);

        formData.set('resolution_w', this.resolution_w);
        formData.set('resolution_h', this.resolution_h);
        formData.set('name', this.title);
        formData.set('difficulty', this.difficulty);
        formData.set('description', this.description);
        formData.set('materials', this.material_required);
        formData.set('portiere', this.portiere);
        formData.set('sizeCampo', this.soccer_field_size);
        formData.set('numeroGiocatori', this.num_player);
        formData.set('minutiEsercizio', this.min_exs);
        formData.set('numeroSerie', this.num_serie);
        formData.set('recupero', this.min_rec);
        formData.set('secondiRecupero', this.sec_rec);
        formData.set('numeroRecuperi', this.num_rec);
        formData.set('min_tot_ex', this.calculate_tot_min);
        formData.set('stato', this.new_stato);
        formData.set('certificato', this.certificato);
        formData.set('differenziato', this.differenziato);
        formData.set('team_id', this.rosaTeamId);

        formData.set('drill_id', this.drill_id);
        formData.set('g1', this.g1);
        formData.set('g2', this.g2);
        formData.set('g3', this.g3);
        formData.set('g4', this.g4);
        formData.set('jolly', this.jolly);
        formData.set('sponde', this.sponde);
        formData.set('portieri', this.portieri);
        formData.set('porte_grandi', this.porte_grandi);
        formData.set('porte_piccole', this.porte_piccole);
        formData.set('campo_lunghezza', this.campo_lunghezza);
        formData.set('campo_larghezza', this.campo_larghezza);

        formData.set('skills_rel', JSON.stringify(this.skills_rel));

        formData.set('_method', 'POST');

        if (this.editorActive) {

            this.$root.$emit('canvas::export');
            const png = this.canvasObj.image.split(',')[1];
            const file = base64ToBlob(png, 'image/png');

            formData.set('canvasImage', file);
            formData.set('canvas', this.canvasObj.export);
        } else {
            formData.set('image_file', this.image_file);
        }

                /**
                 *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
                 **/

                 const opts = {
                  url: `/exercises${(this.exe_id > 0) ? `/edit` : ''}`,
                  method: 'POST',
                  data: formData,
              };

              axios(opts)
              .then((res) => {
                  this.$router.push({ name: 'football_esercizi'});
              }).catch((err) => {
                  console.log(err);
              });
          },

            /**
             * @canvas
             * Begin
             */

             changeCanvasBkg(obj, category) {               
              if( category == 'backgrounds' ){
                this.$store.commit('exercises/setBackground', obj.image_url);
            } else {
              this.clearAndSetActiveObject(obj);
          }
      },

      getRandomCoords() {
          var max = 50;
          var min = - 50;
          var coords = {
            x: 350 + Math.floor(Math.random() * (max - min + 1) ) + min,
            y: 150 + Math.floor(Math.random() * (max - min + 1) ) + min,
        };
        return coords;
    },

    setCanvasBkg(path){
      this.$store.commit('exercises/setBackground', path);
  },

  onDragStart(object, evt) {
      evt.dataTransfer.setData('text/plain', JSON.stringify(object));
  },

            /**
             * @canvas
             * End
             */

             selectSoccerFieldSize(ID){
              for(var i in this.soccer_field_sizes) {
                var field_size = this.soccer_field_sizes[i];
                if(field_size.id == ID) {
                  var size = field_size.modality + " - Small: " + field_size.small  + " m - Standard: " + field_size.standard  + " m - Large: " + field_size.large + " m";
                  this.soccer_field_size = size;
              }
          }
          this.show_fields_size = false;
      },

      downloadPdf(){
          var imgData = null;
          if(this.editorActive) {
            var canvas = document.getElementById('canvas');
            imgData = canvas.toDataURL("png");
        } else {
            if(this.no_canvas_url) {
                imgData = this.no_canvas_url;
            }
        }
        var doc = new jsPDF("p", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight(); 
        var fontSize = 12;
        var ptsPerInch = 72;
        doc.addImage(this.header_pdf, 'PNG', 0, 0, width, 33);
        doc.addImage(imgData, 'PNG', 12, 35, 190, 95);
        doc.setFontSize(fontSize);
        doc.setFont(undefined, "bold");
        doc.text(5, 137, 'Nome:');
        doc.text(5, 142, 'Autore:');
        doc.text(5, 147, 'Difficoltà:');
        doc.text(5, 152, 'Tipologia:');
        doc.setFont(undefined, "normal");
        doc.text(20, 137, this.title);
        doc.text(22, 142, this.autore);
        doc.text(27, 147, this.getDifficoltaLabel(this.difficulty));
        doc.text(27, 152, this.getDrillLabel(this.drill_id));

        var y = 0;

        if(this.portiere) {
            doc.setFont(undefined, "bold");
            doc.text(5, 157, 'Tecnica podalica:');
            doc.text(5, 162, 'Tecnica di base:');
            doc.text(5, 167, 'Qualità tattiche:');
            doc.text(5, 172, 'Capacità motorie:');
            doc.setFont(undefined, "normal");
            doc.text(42, 157, this.getLabelSkills(this.getSkillId(86), this.skills.find(item => item.id == 86)));
            doc.text(39, 162, this.getLabelSkills(this.getSkillId(99), this.skills.find(item => item.id == 99)));
            doc.text(38, 167, this.getLabelSkills(this.getSkillId(124), this.skills.find(item => item.id == 124)));
            doc.text(42, 172, this.getLabelSkills(this.getSkillId(61), this.skills.find(item => item.id == 61)));
            y = 182;

        } else {
            doc.setFont(undefined, "bold");
            doc.text(5, 157, 'Principi di gioco:');
            doc.text(5, 162, 'Componenti Tecniche:');
            doc.text(5, 167, 'Capacità motorie:');
            doc.setFont(undefined, "normal");
            doc.text(41, 157, this.getLabelSkills(this.getSkillId(1), this.skills.find(item => item.id == 1)));
            doc.text(52, 162, this.getLabelSkills(this.getSkillId(30), this.skills.find(item => item.id == 30)));
            doc.text(42, 167, this.getLabelSkills(this.getSkillId(61), this.skills.find(item => item.id == 61)));
            y = 177;
        }

        doc.setFont(undefined, "bold");
        doc.text(152, 142, 'Minuti Esercitazione:');
        doc.text(152, 147, 'Numero di serie:');
        doc.text(152, 152, 'Minuti di recupero:');
        doc.text(152, 157, 'Secondi di recupero:');
        doc.text(152, 162, 'Numero di recuperi:');
        doc.text(152, 167, 'Tempo totale:');
        doc.setFont(undefined, "normal");
        doc.text(195, 142, '' + this.min_exs);
        doc.text(187, 147, '' + this.num_serie);
        doc.text(191, 152, '' + this.min_rec);
        doc.text(195, 157, '' + this.sec_rec);
        doc.text(193, 162, '' + this.num_rec);
        doc.text(181, 167, '' + this.calculate_tot_min + ' minuti');

        doc.setFont(undefined, "bold");
        doc.text(152, 177, 'Tot. Giocatori:');
        doc.text(152, 182, 'G1:');
        doc.text(152, 187, 'G2:');
        doc.text(152, 192, 'G3:');
        doc.text(152, 197, 'G4:');
        doc.text(152, 207, 'Jolly:');
        doc.text(152, 212, 'Sponde:');
        doc.text(152, 222, 'Portieri:');
        doc.text(152, 227, 'Porte grandi:');
        doc.text(152, 232, 'Porte piccole:');

        doc.setFont(undefined, "normal");
        doc.text(182, 177, '' + this.num_player);
        doc.text(161, 182, '' + this.g1);
        doc.text(161, 187, '' + this.g2);
        doc.text(161, 192, '' + this.g3);
        doc.text(161, 197, '' + this.g4);
        doc.text(165, 207, '' + this.jolly);
        doc.text(170, 212, '' + this.sponde);
        doc.text(170, 222, '' + this.portieri);
        doc.text(182, 227, '' + this.porte_grandi);
        doc.text(182, 232, '' + this.porte_piccole);

        doc.setFont(undefined, "bold");
        doc.text(5, y, 'Misure del campo:');
        doc.setFont(undefined, "normal");
        doc.text(45, y, this.campo_lunghezza + ' X '+ this.campo_larghezza);
        y += 5;
        doc.setFont(undefined, "bold");
        doc.text(5, y, 'Densità del campo:');
        doc.setFont(undefined, "normal");
        doc.text(45, y, this.densita_campo);
        y += 10;

        doc.setFont(undefined, "bold");
        doc.text(5, y, 'Materiale:');
        doc.setFont(undefined, "normal");

        if(this.material_required != '') {
            y += 5;
            var textMater = doc.splitTextToSize(this.material_required, width - 10);
            doc.text(5, y, textMater);
            var heightMaterial = textMater.length * fontSize * doc.internal.getLineHeight() / ptsPerInch;
            y += heightMaterial;
        }
        y += 10;

        doc.setFont(undefined, "bold");
        doc.text(5, y, 'Descrizione:');
        doc.setFont(undefined, "normal");
        y += 5;
        var textDescr = doc.splitTextToSize(this.description, width - 10);
        doc.text(5, y, textDescr);

        doc.save('esercizio_' + this.exe_id + '.pdf');

    },

    getDifficoltaLabel(difficolta) {
      for(var i in this.difficulties) {
        var item = this.difficulties[i];
        if(item.value == difficolta) {
          return item.text;
      }
  }
  return '';
},

getDrillLabel(drill_id) {
  for(var i in this.drill_options) {
    var item = this.drill_options[i];
    if(item.id == drill_id) {
      return item.name;
  }
}
return '';
},

getSkillId(root) {
  var skill_rel = this.skills_rel.find(item => item.skill_glo_root_id == root);
  if(skill_rel) {
    return skill_rel.skill_glo_id;
}
return null;
},

getLabelSkills(id, skills) {
  var label = "N/D";
  var skill_items = skills.campi;
  for(var key in skill_items) {
    var skill = skill_items[key];
    if(skill.id == id) {
      label = skill.nome;
      return label;
  }
  if(skill.campi) {
      var items = skill.campi;
      for(var key2 in items) {
        var subskill = items[key2];
        if(subskill.campi) {
          var items2 = subskill.campi;
          for(var key3 in items2) {
            var subskill2 = items2[key3];
            if(subskill2.id == id) {
              label = skill.nome + " -> " + subskill.nome + " -> " + subskill2.nome;
              return label;
          }  
      }
  } else {
      if(subskill.id == id) {
        label = skill.nome + " -> " + subskill.nome;
        return label;
    }       
}
}
} else {
  if(skill.id == id) {
    label = skill.nome;
    return label;
}
}
}
return label;
},

filtraPlayersCanvasEditor() {
  this.canvas_objs.players = [];
  if(this.move == 'all' && this.shirt == 'all') {
    this.canvas_objs.players = this.canvas_players_objs;
} else {
    for(var i in this.canvas_players_objs) {
      var player = this.canvas_players_objs[i];
      if(this.move != 'all' && this.shirt != 'all') {
        if(player.move == this.move && player.shirt == this.shirt) {
          this.canvas_objs.players.push(player);
      }
  } else if(this.move != 'all' && this.shirt == 'all') {
    if(player.move == this.move) {
      this.canvas_objs.players.push(player);
  }
} else if(this.move == 'all' && this.shirt != 'all') {
    if(player.shirt == this.shirt) {
      this.canvas_objs.players.push(player);
  }         
}
}
}
},

eliminaEsercizio() {
  if(confirm("Confermi l'eliminazione dell'esercizio?")) {
    this.$http.get(this.$store.state.apiEndPoint + '/exercise/delete/' + this.$route.params.id).then((response) => {

      if (response.ok)
      {
        this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
        this.$store.commit('exercises/resetCanvasEditor');
        this.$router.push({ name: "football_esercizi" });
    }
}, (response) => {
});
}
},

async clonaEsercizio() {
  if(confirm("Confermi la clonazione dell'esercizio?")) {
    this.exe_id = 0;
    if(!this.editorActive && this.no_canvas_url) {
        var imgExt = this.no_canvas_url.split(/[#?]/)[0].split('.').pop().trim();
        const response = await fetch(this.no_canvas_url);
        const blob = await response.blob();
        const file = new File([blob], "image_clone." + imgExt, {
            type: blob.type,
        });
        this.image_file = file;
    }
    this.submit();    
}
},

switchFilter() {
  if(this.portiere == 0) {
    this.portiere = 1;
} else {
    this.portiere = 0;
}
},

switchEditor() {
    if(this.editorActive) {
        this.editorActive = false;
    } else {
        this.editorActive = true;
        this.image_file = null;
        this.immagine = null;
        this.random = Math.random();
    }
},

changeFontSize(fsize){
  this.$refs.my_canvas.insertText(fsize);
  this.show_font_size = 0;
},

accetta() {
  if(confirm("Sei sicuro di voler accettare l'esercizio?")) {
    this.$http.get(this.$store.state.apiEndPoint + '/exercise/accetta/' + this.$route.params.id).then((response) => {

      if (response.ok)
      {
        this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
        this.$router.push({ name: "football_esercizi" });
    }
}, (response) => {
});
}
},

rifiuta() {
  if(confirm("Sei sicuro di voler rifiutare l'esercizio?")) {
    const formData = new FormData();
    formData.append("motivo",  this.motivo);
    this.$http.post(this.$store.state.apiEndPoint + '/exercise/rifiuta/' + this.$route.params.id, formData).then((response) => {

      if (response.ok)
      {
        this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
        this.$router.push({ name: "football_esercizi" });
    }
}, (response) => {
});
}
},

showGuida() {
  this.$refs.infoModal.show()
},
getTeam() {
  if(this.rosaTeamId){
    this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
      if (response.ok)
      {
        this.team = response.data;
        if(!this.categoria) {
          this.categoria = this.team.categoria;
      }
      axios.get(`/skill/object`)
      .then((res) => {
        this.skills = res.data;
    }).catch((err) => {
    });
}
}, (response) => {
});
}
},
getDrills() {
  this.$http.get(this.$store.state.apiEndPoint + '/exercise/drills').then((response) => {
    if (response.ok)
    {
      this.drill_options = response.data;
  }
}, (response) => {
});
},
},

created: function () {
    this.$http.get(this.$store.state.apiEndPoint + '/headerpdf').then((response) => {
      if (response.ok)
      {
        this.header_pdf = response.data;    
    }
}, (response) => {
});

    this.getDrills();
},

computed: {
    auth: function() {
      return Auth;
  },    

  ...mapState('exercises', {
      canvasObj: 'canvas',
      activeObject: 'activeObject',
  }),
  ...mapState(['rosaTeamId']),

  calculate_tot_min: function () {
      var tot = ( this.min_exs * this.num_serie ) + ( this.min_rec * this.num_rec );
      if(this.sec_rec > 0) {
        tot += this.sec_rec/60 * this.num_rec;
    }
    tot = Math.round(tot * 100) / 100;
    return tot;
},
text_color: {
  get() {
    return this.canvasObj.color.hex;
},

set(value) {
    this.$store.commit('exercises/setCanvasColor', value);
},
},
opacity: {
  get() {
    return this.canvasObj.opacity;
},

set(value) {
    this.$store.commit('exercises/setOpacity', value);
},
},
line_width: {
  get() {
    return this.canvasObj.lineWidth;
},

set(value) {
    this.$store.commit('exercises/setLineWidth', value);
},
},
densita_campo: function() {
  var densita = '';
  if(this.num_player > 0 && this.campo_lunghezza > 0 && this.campo_larghezza > 0) {
    densita = Math.round((this.campo_lunghezza * this.campo_larghezza / this.num_player) * 100)/100;
}
return '' + densita;
},
},

watch: {
    rosaTeamId() {
      this.getTeam();
  },  

  move(){   
      this.filtraPlayersCanvasEditor();       
  },

  shirt(){
      this.filtraPlayersCanvasEditor();
  },

},

mounted()
{ 
            /**
             *  RETRY SIZES SOCCER FIELD
             **/

             axios.get(`/exercise/soccer_field_sizes`)
             .then((res) => {

              this.soccer_field_sizes     = res.data.map(item => ({
                id:         item.id,
                category:   item.category,
                modality:   item.modality,
                num_player: item.num_player,
                small:      item.small ,
                standard:   item.standard ,
                large:      item.large ,
                time:       item.time,
                value:      item.id,
            }));
          }).catch((err) => {
          });


            /**
            *   Retry canvas Objs
            */
            this.$store.commit('exercises/resetCanvasEditor');

            axios.get('/exercises/canvas-objects')
            .then((res) => {
              this.canvas_objs = res.data;
              this.canvas_players_objs = res.data.players;
              this.filtraPlayersCanvasEditor();

              this.bkg_default = res.data['backgrounds'][0].image_url;

              if(!this.$route.params.id){
                this.$store.commit('exercises/setBackground', res.data['backgrounds'][0].image_url);
            }

              /**
             *  RETRY EXCERCISE IF EDIT
             **/

             if(this.$route.params.id){
              this.loading = true;
              axios.get(`/exercise/get/`+this.$route.params.id)
              .then((res) => {
                this.title = res.data.name;
                this.difficulty = res.data.difficulty;
                this.description = res.data.description;
                this.material_required = res.data.materials;
                this.portiere = res.data.portiere;
                this.soccer_field_size = res.data.sizeCampo;
                this.num_player = res.data.numeroGiocatori;
                this.min_exs = res.data.minutiEsercizio;
                this.num_serie = res.data.numeroSerie;
                this.min_rec = res.data.recupero;
                this.sec_rec = res.data.secondiRecupero;
                this.num_rec = res.data.numeroRecuperi;
                this.min_tot_ex = res.data.min_tot_ex;
                this.immagine = res.data.immagine;
                this.stato = res.data.stato;
                this.certificato = res.data.certificato;
                this.differenziato = res.data.differenziato;
                this.canvasImage = res.data.canvasImage;
                this.old_id = res.data.old_id;
                this.user_id = res.data.utente_id;
                this.exe_id = res.data.id;
                this.motivo = res.data.commento;
                this.resolution_w = res.data.resolution_w;
                this.resolution_h = res.data.resolution_h;
                this.autore = res.data.utente.nomeCompleto;
                this.categoria = res.data.categoria;
                
                this.drill_id = res.data.drill_id;
                this.g1 = res.data.g1;
                this.g2 = res.data.g2;
                this.g3 = res.data.g3;
                this.g4 = res.data.g4;
                this.jolly = res.data.jolly;
                this.sponde = res.data.sponde;
                this.portieri = res.data.portieri;
                this.porte_grandi = res.data.porte_grandi;
                this.porte_piccole = res.data.porte_piccole;
                this.campo_lunghezza = res.data.campo_lunghezza;
                this.campo_larghezza = res.data.campo_larghezza;

                this.skills_rel = res.data.skills_rel;

                if(this.soccer_field_size && this.soccer_field_size != "") {
                  this.show_fields_size = false;
              }

              this.$store.commit('exercises/export', res.data.canvas);

              if( res.data.old_id ){
                this.$store.commit('exercises/setBackground', this.bkg_default);
            }

            if((!res.data.canvas && res.data.canvasImage) || res.data.immagine) {
                this.editorActive = false;
                this.no_canvas_url = res.data.canvasImage ? res.data.canvas_url : 'https://api.asromasystem.com/canvas/exercise/' + res.data.id;
            }
            this.getTeam();
            this.loading    = false;
            this.exercise   = true;
        }).catch((err) => {
          this.loading = false;
      });
    }else{
        this.getTeam();
        this.exercise   = true;
    }

}).catch((error) => {
  console.log(error);
});
},
}
</script>
