<template id="simplemessage">
	<div>
    {{ params.message }}
	</div>
</template>

<script>
export default {
    data: function(){
        return {

            dummy: null

        }
    },

    props: {

        params: {
            type: Object,
            required: false
        }

    },
}
</script>
