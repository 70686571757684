<template>
	<div>
		<b-modal size="lg" v-model="ux.show" @hide="close">
			
			<template #modal-footer>
				<b-btn @click.prevent="close()">Chiudi</b-btn>
			</template>

			<template #modal-header>
				Note
			</template>

			<NoteManager :entity="entity" :entityId="entityId" @post="$emit('refresh')"></NoteManager>

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'
import moment from 'moment'

export default {
	props: [ 'entity', 'entityId' ],
	components: {
		NoteManager: require('../notes/manager.vue').default
	},
	computed: {
		
	},
	methods: {
		show: function() {
			this.ux.show = true;
		},
		close: function() {
			this.ux.show = false;
			this.$emit('close');
		}
	},
	filters: {
		dt: function(a) {
			return moment(a).format('DD/MM/Y')
		},
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return '€ ' + formatter.format(v).replace('€', '');
		},
	},
	data: function() {
		return {
			bk: false,
			ux: {
				halt: false,
				changed: false,
				show: true,
			}
		}
	}
};

</script>