<template>
	<div>

		<b-input type="date" v-if="!readonly" size="sm" v-model="test[field]"></b-input>
		<div :style="style" v-else>
			{{ test[field] }}
		</div>
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	created: function() {


	},
	methods: {
		
	},
	watch: {
		dataLayer: {
			deep: true,
			handler: function() {

			}
		}
	},
	computed: {
		style: function() {

			var style = [];

			style.push('display: inline-block; padding: 0.1em 0.4em; border-radius: 4px');

			if(this.options.soglie && this.options.soglie.type == 'stringa') {
				if(this.options.soglie.config[this.test[this.field]]) {
					style.push(this.options.soglie.config[this.test[this.field]])
				}
			} else return '';
			
			return style.join(';');
			/*
			$soglie['vas'] = [
	            'range' => [ 4, 6 ],
	            'negative' => 'font-weight: bold; color: red',
	            'positive' => false
	        ];
			 */
		}
	}
}
	
</script>