<template id="acad_val">
	<section class="acad_val">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<b-button :to="{ name: 'player_access_richieste_new'}" variant="primary" class="text-uppercase mb-2"><strong>New</strong></b-button>
				
				<b-table striped show-empty
				:items="richieste"
				:fields="fields"
				>
				<template #cell(created_at)="row">{{ row.value | formatDate }}</template>
				<template #cell(department)="row">{{ getDepartment(row.value) }}</template>
				<template #cell(content)="row">{{ getContent(row.value) }} - {{ row.item.content_text }}</template>
				<template #cell(players)="row">{{ getPlayers(row.item) }}</template>
				<template #cell(status)="row">{{ getStatus(row.value) }}</template>
				<template #cell(azioni)="row">
					<div style="min-width: 270px">
					<b-button size="sm" :to="{ name: 'player_access_richieste_view', params: { id: row.item.id }}" variant="success" class="mr-1">
						View <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
					</b-button>
					<b-button size="sm" variant="info" class="mr-1" @click="downloadRichiesta(row.item.id)">
						Pdf <i class="fa fa-eye" aria-hidden="true"></i>
					</b-button>
					<b-button v-if="auth.isUserEnable('player_access_req_mng') && (auth.user.id == row.item.utente_id || auth.isAdmin() || auth.isUserEnable('player_access_req_user'))" size="sm" :to="{ name: 'player_access_richieste_edit', params: { id: row.item.id }}" class="mr-1" variant="primary">
						Edit <i class="fa fa-pencil" aria-hidden="true"></i>
					</b-button>
					<b-button v-if="auth.isUserEnable('player_access_req_mng') && (auth.user.id == row.item.utente_id || auth.isAdmin() || auth.isUserEnable('player_access_req_user'))" size="sm" @click.stop="deleteRichiesta(row.item.id)" class="mr-1">
						Canc <i class="fa fa-trash" aria-hidden="true"></i>
					</b-button>
					</div>		
				</template>
			</b-table>
			<b-btn v-b-toggle.collapse variant="primary" class="mb-2">Archived <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
			<b-collapse id="collapse" ref="collapse">
				<b-table striped show-empty
				:items="archiviate"
				:fields="fields"
				>
				<template #cell(created_at)="row">{{ row.value | formatDate }}</template>
				<template #cell(department)="row">{{ getDepartment(row.value) }}</template>
				<template #cell(content)="row">{{ getContent(row.value) }} - {{ row.item.content_text }}</template>
				<template #cell(players)="row">{{ getPlayers(row.item) }}</template>
				<template #cell(status)="row">{{ getStatus(row.value) }}</template>
				<template #cell(azioni)="row">
					<div style="min-width: 200px">
					<b-button size="sm" :to="{ name: 'player_access_richieste_view', params: { id: row.item.id }}" variant="success" class="mr-1">
						View <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
					</b-button>
					<b-button size="sm" variant="info" class="mr-1" @click="downloadRichiesta(row.item.id)">
						Pdf <i class="fa fa-eye" aria-hidden="true"></i>
					</b-button>
					</div>		
				</template>
			</b-table>
			</b-collapse>
		</div>
	</div>


	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import axios from 'axios';
import moment from 'moment';
export default {

	data: function() {
		return {
			richieste: [],
			archiviate: [],
			fields: [
			{ key: 'created_at', label: 'Date', class: 'text-center' },
			{ key: 'department', label: 'Department', class: 'text-center' },
			{ key: 'content', label: 'Content', class: 'text-center' },
			{ key: 'players', label: 'Players', class: 'text-center' },
			{ key: 'status', label: 'Status', class: 'text-center' },
			{ key: 'azioni', label: 'Actions', class: 'text-center'},
			],
			options_department: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Communication'},
			{ value: 2, text: 'Partnerships'},
			{ value: 3, text: 'Brand'},
			{ value: 4, text: 'Roma Department'},
			{ value: 5, text: 'Digital'},
			{ value: 7, text: 'Sustainability Department'},
			{ value: 6, text: 'Other'}
			],
			options_content: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Interviews'},
			{ value: 2, text: 'Shootings (foto&video)'},
			{ value: 3, text: 'Events'},
			{ value: 4, text: 'Appereances'},
			{ value: 5, text: 'Other'}
			],

		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	computed: {
		auth: function() {
			return Auth;
		},
	},

	created: function () {
		this.getRichieste();
		this.getRichiesteArchiviate();
	},

	methods: {
		getRichieste() {
			axios.get(`/playeraccess/richieste`)
			.then((response) => {
				this.richieste = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		},

		getRichiesteArchiviate() {
			axios.get(`/playeraccess/richieste/archiviate`)
			.then((response) => {
				this.archiviate = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		},

		editRichiesta(id) {

		},

		deleteRichiesta(id) {
			if(confirm('Confermi l\'eliminazione della richiesta?')) {
				this.$http.get(this.$store.state.apiEndPoint + '/playeraccess/richiesta/delete/' + id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.getRichieste();		
					}
				}, (response) => {
				});
			}
		},

		getDepartment(department) {
			var index = this.options_department.findIndex((item) => item.value == department);
			if(index > -1) {
				return this.options_department[index].text;
			}
			return "";
		},

		getContent(content) {
			var index = this.options_content.findIndex((item) => item.value == content);
			if(index > -1) {
				return this.options_content[index].text;
			}
			return "";
		},

		getStatus(status) {
			if(status == 0) {
				return "In progress";
			} else if(status == 1) {
				return "Approved";
			} else if(status == 2) {
				return "Canceled";
			}
		},

		getPlayers(item) {
			var player_ids = [];
			var label = "";
			var count = 0;
			var players_desiderata = JSON.parse(item.players_desiderata);
			for(var i in players_desiderata) {
				var obj = players_desiderata[i];
				if(!player_ids.includes(obj.id)) {
					player_ids.push(obj.id);
					if(label != "") {
						label += ', ';
					}
					label += obj.name;
				}
			}
			var players_alternative = JSON.parse(item.players_alternative);
			for(var i in players_alternative) {
				var obj = players_alternative[i];
				if(!player_ids.includes(obj.id)) {
					player_ids.push(obj.id);
					if(label != "") {
						label += ', ';
					}
					label += obj.name;
				}
			}
			return label;
		},

		downloadRichiesta(id) {
			window.open(this.$store.state.apiEndPoint + '/playeraccess/richiesta/pdf/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
