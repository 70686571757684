<template id="rosa">

	<section class="rosa">

		<headapp></headapp>
		<menusub></menusub>
		
		<RecoveryDashboard />

		<footerapp></footerapp>

	</section>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import RecoveryDashboard from '@/components/layout/reports/RecoveryDashboard.vue'


export default {
	mixins: [ pageBase ],
	components: {
		RecoveryDashboard
	},
}

</script>