<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<div class="container-fluid m-y-1">
			<b-card no-body class="border-0" style="height: 90vh; overflow-y:scroll;">
				<div class="text-right">
				<b-button class="m-0" variant="link" @click.prevent="$store.commit('changeReportNew', true)" title="Nuova dashboard report!"><i class="fa fa-th-large" style="font-size:2rem" aria-hidden="true"></i><img src="/assets/varie/new.png" width="30px" style="position:relative; left:-13px;"></b-button>
			</div>
				<div class="my-1">
					<div class="d-flex jc-center">	
						<div class="m-2 ai-strech" v-if="auth.isUserEnable('report_players_matches')">
							<b-card>
								<template #header>
									<h5 @click="goTo('reports_players_matches')" style="cursor:pointer;white-space: pre;"> Players Matches </h5>
								</template>	
								<router-link :to="{ name: 'reports_players_matches' }">
									<div class="p-1">
										<img alt="Players Matches" src="/assets/screens/player-matches.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</router-link>
							</b-card>
						</div>
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_players_yellows')">
							<b-card>
								<template #header>
									<h5 @click="goTo('reports_players_yellows')" style="cursor:pointer;white-space: pre;"> Players Disciplinary </h5>
								</template>	
								<router-link :to="{ name: 'reports_players_yellows' }">
									<div class="p-1">
										<img alt="Players Yellows" src="/assets/screens/player-disciplinary.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</router-link>
							</b-card>
						</div>
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_players_onloan')">
							<b-card>
								<template #header>
									<h5 @click="goTo('reports_players_onloan')" style="cursor:pointer; white-space: pre;"> Players On Loan </h5>
								</template>	
								<router-link :to="{ name: 'reports_players_onloan' }">
									<div class="p-1">
										<img alt="Players On Loan" src="/assets/screens/player-on-loan.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</router-link>
							</b-card>
						</div>
					</div>
				</div>
				<div class="my-1">
					<div class="d-flex jc-center">
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_scouting')">
							<b-card>
								<template #header>
									<h5 @click="goTo('reports_scouting')" style="cursor:pointer; white-space: pre;"> Scouting Report </h5>
								</template>	
								<router-link :to="{ name: 'reports_scouting' }">
									<div class="p-1">
										<img alt="Scouting Report" src="/assets/screens/scouting.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</router-link>
							</b-card>
						</div>	
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_recovery')">
							<b-card>
								<template #header>
									<h5 @click="goTo('medical_recovery_dashboard')" style="cursor:pointer; white-space: pre;"> Report Recovery </h5>
								</template>	
								<a href="" @click.prevent="goTo('medical_recovery_dashboard')">
									<div class="p-1">
										<img alt="Scouting Report" src="/assets/screens/recovery.jpeg" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</a>
							</b-card>
						</div>
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_fisioterapia')">
							<b-card>
								<template #header>
									<h5 @click="goTo('medical_fisioterapia_dashboard')" style="cursor:pointer; white-space: pre;"> Report Fisioterapia </h5>
								</template>	
								<a href="" @click.prevent="goTo('medical_fisioterapia_dashboard')">
									<div class="p-1">
										<img alt="Scouting Report" src="/assets/screens/fisioterapia.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</a>
							</b-card>
						</div>
					</div>
				</div>
				<div class="my-1">
					<div class="d-flex jc-center">
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_test_fisioterapia')">
							<b-card>
								<template #header>
									<h5 @click="goTo('medical_fisioterapia_test_dashboard')" style="cursor:pointer; white-space: pre;"> Report Test Fisioterapia </h5>
								</template>	
								<a href="" @click.prevent="goTo('medical_fisioterapia_test_dashboard')">
									<div class="p-1">
										<img alt="Scouting Report" src="/assets/screens/test-fisioterapia.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</a>
							</b-card>
						</div>
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_nutrition')">
							<b-card>
								<template #header>
									<h5 @click="goTo('medical_nutrition_dashboard')" style="cursor:pointer; white-space: pre;"> Report Nutrition </h5>
								</template>	
								<a href="" @click.prevent="goTo('medical_nutrition_dashboard')">
									<div class="p-1">
										<img alt="Scouting Report" src="/assets/screens/nutrition.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</a>
							</b-card>
						</div>
						<div class="m-2 ai-stretch" v-if="auth.isUserEnable('report_farmaco')">
							<b-card>
								<template #header>
									<h5 @click="goTo('medical_farmaco_dashboard')" style="cursor:pointer; white-space: pre;"> Report Farmaci </h5>
								</template>	
								<a href="" @click.prevent="goTo('medical_farmaco_dashboard')">
									<div class="p-1">
										<img alt="Scouting Report" src="/assets/screens/farmaco.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</a>
							</b-card>
						</div>				
					</div>
				</div>
				<div class="my-1">
					<div class="d-flex jc-center">
						<div class="m-2 ai-stretch" style="width: 32.5%" v-if="auth.isUserEnable('report_players_matches_attendance')">
							<b-card>
								<template #header>
									<h5 @click="goTo('reports_players_matches_attendances')" style="cursor:pointer;white-space: pre;"> Players Matches Attendances </h5>
								</template>	
								<router-link :to="{ name: 'reports_players_matches_attendances' }">
									<div class="p-1">
										<img alt="Players Matches" src="/assets/screens/attendance.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</router-link>
							</b-card>
						</div>
						<div class="m-2 ai-stretch" style="width: 32.5%" v-if="auth.isUserEnable('report_infortuni')">
							<b-card>
								<template #header>
									<h5 @click="goTo('medical_injuries_dashboard')" style="cursor:pointer;white-space: pre;"> Report Infortuni </h5>
								</template>	
								<a href="" @click.prevent="goTo('medical_injuries_dashboard')">
									<div class="p-1">
										<img alt="Players Matches" src="/assets/screens/infortuni.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</a>
							</b-card>
						</div>
						<div class="m-2 ai-stretch" style="width: 32.5%" v-if="auth.isUserEnable('report_peso')">
							<b-card>
								<template #header>
									<h5 @click="goTo('medical_peso_dashboard')" style="cursor:pointer;white-space: pre;">Report Peso</h5>
								</template>	
								<a href="" @click.prevent="goTo('medical_peso_dashboard')">
									<div class="p-1">
										<img alt="Players Matches" src="/assets/screens/peso.png" style="height: 100%; width: 100%; object-fit: contain">
									</div>
								</a>
							</b-card>
						</div>
					</div>
				</div>
			</b-card>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
var Auth = require('@/auth.js');
export default {
	mixins: [ pageBase ],
	data: function() {
		return {
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
	},
	methods: {
		goTo: function(where) {
			this.$router.push({name: where});	
		}
	}
};

</script>