<template>
  <div class="bookmarks w-100">
    <b-dropdown dropup no-caret variant="primary" class="w-100">
      <template slot="button-content">
        Aggiungi segnalibro <i class="fa fa-caret-up ml-2" aria-hidden="true"></i>
      </template>

      <b-dropdown-item v-for="bookmark in bookmarks" :key="bookmark.id" @click="saveBookmark(bookmark)">
        {{ bookmark.name }} ({{ bookmark.tempo_partita }})
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="otherBookmark()">Altro</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapState } from 'vuex';

export default {
  name: 'Bookmarks',

  computed: mapState({
    bookmarks: state => state.data.bookmarks,
  }),

  methods: {
    saveBookmark(bookmark) {
      this.$store.dispatch('saveBookmark', bookmark);
    },

    otherBookmark() {
    /*  swal({
        title: 'Inserisci il titolo del segnalibro',
        input: 'text',
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          this.saveBookmark({ name: result.value });
        }
      }).catch(() => {  });  */

        swal.fire({
        title: 'Inserisci segnalibro personalizzato',
        html:
        '<span>Titolo</span><input id="swal-input1" class="swal2-input">' +
        '<span>Minuto partita (hh:mm:ss)</span><input id="swal-input2" type="time" step="1" class="swal2-input" value="00:00:00">',
        focusConfirm: false,
        preConfirm: () => {
          var titolo = document.getElementById('swal-input1').value;
          var tempo_partita = document.getElementById('swal-input2').value;
          if(titolo != '' && tempo_partita != '') {
            this.saveBookmark({ name: titolo, tempo_partita: tempo_partita });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.dropdown .btn {
  font-weight: 400;
  border-radius: 4px !important;
  width: 100%;
}
</style>

<style lang="scss" scoped>
div.bookmarks {
  display: inline-block;
}

.btn-group:not(:last-child) {
  margin-right: 5px;
}
</style>

