<template>
  <data-table
  api-url="/stadi"
  :columns="columns"
  :editFormData="stadiumData"
  :createFormData="newStadiumData"
  :defaultFilter="false"
  @showModal="onShowModal"
  @editError="editError"
  @shownEditModal="shownEditModal"
  @shownCreateModal="shownCreateModal">

  <template slot="filters">
    <b-form-input v-model="filters.stadium" placeholder="Stadio" class="inline mr-3" />
    <b-form-input v-model="filters.country" placeholder="Paese" class="inline mr-3" />
    <!--b-form-input v-model="filters.team" placeholder="Squadra" class="inline mr-3" /-->
  </template>

  <template slot="edit-modal-body" slot-scope="row">
    <b-form-group label="Nome">
      <b-form-input type="text" placeholder="Nome" v-model="stadiumData.nome" />
    </b-form-group>
    <b-form-group label="Paese">
      <b-form-select v-model="stadiumData.id_paese">
        <b-form-select-option :value=" null">Seleziona</b-form-select-option>
        <b-form-select-option v-for="paese in paesi" :value="paese.id">{{ paese.paese }}</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group label="Indirizzo">
      <b-form-input type="text" placeholder="Indirizzo" ref="autocomplete" v-model="stadiumData.address"/>
    </b-form-group>

    <b-form-group label="Latitudine">
      <b-form-input type="text" placeholder="Latitudine" v-model="stadiumData.lat" />
    </b-form-group>

    <b-form-group label="Longitudine">
      <b-form-input type="text" placeholder="Longitudine" v-model="stadiumData.lng" />
    </b-form-group>
    <b-form-group label="Città">
      <b-form-input type="text" placeholder="Città" v-model="stadiumData.city"/>
    </b-form-group>
    <b-form-group label="Capacità">
      <b-form-input type="number" placeholder="Capacità" v-model="stadiumData.capacity"/>
    </b-form-group>
    <b-form-group label="Superficie">
      <b-form-input type="text" placeholder="Superficie" v-model="stadiumData.surface"/>
    </b-form-group>
    <b-form-group label="TimeZone">
      <b-form-input type="text" placeholder="TimeZone" v-model="stadiumData.timeZone"/>
    </b-form-group>
  </template>

  <template slot="create-modal-body">
    <b-form-group label="Nome">
      <b-form-input type="text" placeholder="Nome" v-model="newStadiumData.nome" />
    </b-form-group>
    <b-form-group label="Paese">
      <b-form-select v-model="newStadiumData.id_paese">
        <b-form-select-option :value=" null">Seleziona</b-form-select-option>
        <b-form-select-option v-for="paese in paesi" :value="paese.id">{{ paese.paese }}</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group label="Indirizzo">
      <b-form-input type="text" placeholder="Indirizzo" ref="createAutocomplete" v-model="newStadiumData.address"/>
    </b-form-group>
    <b-form-group label="Latitudine">
      <b-form-input type="text" placeholder="Latitudine" v-model="newStadiumData.lat" />
    </b-form-group>
    <b-form-group label="Longitudine">
      <b-form-input type="text" placeholder="Longitudine" v-model="newStadiumData.lng" />
    </b-form-group>
    <b-form-group label="Città">
      <b-form-input type="text" placeholder="Città" v-model="newStadiumData.city"/>
    </b-form-group>
    <b-form-group label="Capacità">
      <b-form-input type="number" placeholder="Capacità" v-model="newStadiumData.capacity"/>
    </b-form-group>
    <b-form-group label="Superficie">
      <b-form-input type="text" placeholder="Superficie" v-model="newStadiumData.surface"/>
    </b-form-group>
    <b-form-group label="TimeZone">
      <b-form-input type="text" placeholder="TimeZone" v-model="newStadiumData.timeZone"/>
    </b-form-group>
  </template>
</data-table>
</template>

<script>
import DataTable from './DataTable.vue';
import { ALERTS_PUSH } from '../../../../store/types';
import axios from 'axios';

export default {
  name: 'Stadiums',
  components: { DataTable },

  data() {
    return {
      paesi: [],
      columns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'nome', label: 'Nome', sortable: true },
        { key: 'paese', label: 'Paese', sortable: true },
      //{ key: 'id_paese', label: 'Paese ID', sortable: true },
      /*  {
          key: 'squadre',
          label: 'squadre',
          formatter: value => value.map(item => item.name).join(', '),
        }, */
        { key: 'lat', label: 'Latitudine', sortable: true },
        { key: 'lng', label: 'Longitudine', sortable: true },
        { key: 'city', label: 'Città', sortable: true },
        { key: 'address', label: 'Indirizzo', sortable: true },
        { key: 'capacity', label: 'Capacità', sortable: true },
        { key: 'surface', label: 'Superficie', sortable: true },
        { key: 'timeZone', label: 'TimeZone', sortable: true },
        { key: 'actions', label: 'Azioni' },
        ],
      stadiumData: {
        nome: '',
        lat: '',
        lng: '',
        id_paese: null,
        city: null,
        address: null,
        capacity: null,
        surface: null,
        timeZone: null,
      },
      newStadiumData: {
        nome: '',
        lat: '',
        lng: '',
        id_paese: null,
        city: null,
        address: null,
        capacity: null,
        surface: null,
        timeZone: null,
      },
      filters: {
        stadium: '',
        country: '',
      //team: '',
      },
    };
  },

  methods: {
    onShowModal(row) {
      this.stadiumData.nome = row.item.nome;
      this.stadiumData.lat = row.item.lat;
      this.stadiumData.lng = row.item.lng;
      this.stadiumData.id_paese = row.item.id_paese;
      this.stadiumData.city = row.item.city;
      this.stadiumData.address = row.item.address;
      this.stadiumData.capacity = row.item.capacity;
      this.stadiumData.surface = row.item.surface;
      this.stadiumData.timeZone = row.item.timeZone;
    },

    shownEditModal() {
      this.autocomplete = new window.google.maps.places.Autocomplete((this.$refs.autocomplete.$el),
        {types: ['geocode']});
      this.autocomplete.addListener('place_changed', this.fillLatLng);
    },

    shownCreateModal() { 
      this.createAutocomplete = new window.google.maps.places
      .Autocomplete((this.$refs.createAutocomplete.$el),
        {types: ['geocode']});
      this.createAutocomplete.addListener('place_changed', this.fillLatLngInCreate);
    },

    editError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(ALERTS_PUSH, {
          status: 0,
          msg: err.response.data.error,
        });
      }
    },

    fillLatLng() {
      const place = this.autocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        this.stadiumData.lat = place.geometry.location.lat();
        this.stadiumData.lng = place.geometry.location.lng();
      }
    },

    fillLatLngInCreate() {
      const place = this.createAutocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        this.newStadiumData.lat = place.geometry.location.lat();
        this.newStadiumData.lng = place.geometry.location.lng();
      }
    },
    getPaesi() {
      axios.get('/paesi').then((res) => {
        this.paesi = res.data;
      }).catch(() => { 
      });
    }
  },

  watch: {
    filters: {
      handler() {
        this.$root.$emit('data-table::filter', this.filters);
      },
      deep: true,
    },
  },

  created() {
    this.getPaesi();
  },

  mounted() {
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051 !important;
}
</style>
