<template id="view_valutazione">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card title_page">
				<div class="card-body">
				<div class="card-title">
					<span>Valutazione Giocatore</span>
				</div>
			</div>
			</div>	
			<div class="content_page">
				<b-row>
					<b-col cols="3">
						<div class="card" style="height:100%">
							<div style="text-align:center;">
								<b-img :src="valutazione.osservato.avatar_url" :alt="valutazione.osservato.cognome + ' ' + valutazione.osservato.nome" :width="130"></b-img>
							</div>
							<div class="card-block">
								<div class="mb-1 font-bold text-center">{{ valutazione.osservato.nome }} {{ valutazione.osservato.cognome }}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.osservato.last_carriera_sportiva.ruolo_ideale && valutazione.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? valutazione.osservato.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</div>
								<div class="text-center font-size-sm">Classe {{ valutazione.osservato.nascita_data | formatYear }}</div>
								<div class="text-center font-size-sm color-rosso">{{ valutazione.osservato.last_carriera_sportiva.squadra ? valutazione.osservato.last_carriera_sportiva.squadra.name : ''}}</div>
								<div class="text-center font-size-sm mt-1" v-if="valutazione.gradimento">
									<h5><b-badge :style="{background: valutazione.gradimento.colore}">{{ valutazione.gradimento.nome}}</b-badge></h5>
								</div>
								<div class="text-center"><b-button variant="primary" size="sm" title="Download scheda" @click="downloadScheda" v-if="auth.isUserEnable('scout_down_val_player')"><i class="fa fa-download" aria-hidden="true"></i>
								</b-button>
								<!--b-button variant="primary" size="sm" class="ml-1" title="Condividi scheda" @click="condividiScheda" v-if="auth.isUserEnable('scout_share_val_player')"><i class="fa fa-share-alt" aria-hidden="true"></i>
								</b-button-->
							</div>
							</div>
						</div>
					</b-col>
					<b-col>
						<b-tabs>
							<b-tab :title="'Partita ' + (index + 1)" v-for="(item, index) in valutazione.partite" :key="(index + 1)">	
								<b-row>
									<b-col>
										<b-row>
											<b-col>Competizione</b-col>
											<b-col class="label-value">{{item.competizione}}</b-col>
										</b-row>
										<b-row>
											<b-col>Partita</b-col>
											<b-col class="label-value">{{item.partita}}</b-col>
										</b-row>
										<b-row>
											<b-col>Data / Ora</b-col>
											<b-col class="label-value">{{item.data | formatDateHour}}</b-col>
										</b-row>
										<b-row>
											<b-col>Sistema di gioco</b-col>
											<b-col class="label-value">{{item.sistema_gioco ? item.sistema_gioco.sistema_gioco : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Ruolo primario</b-col>
											<b-col class="label-value">{{item.ruolo_primario ? item.ruolo_primario.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Ruolo secondario</b-col>
											<b-col class="label-value">{{item.ruolo_secondario ? item.ruolo_secondario.nome : ''}}</b-col>
										</b-row>		
									</b-col>
									<b-col>
										<b-row>
											<b-col>Meteo</b-col>
											<b-col class="label-value">{{item.meteo ? item.meteo.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Vento</b-col>
											<b-col class="label-value">{{item.vento ? item.vento.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Tipologia campo</b-col>
											<b-col class="label-value">{{item.tipo_campo ? item.tipo_campo.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Condizioni campo</b-col>
											<b-col class="label-value">{{item.condizioni_campo ? item.condizioni_campo.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Infortunio</b-col>
											<b-col class="label-value">{{item.infortunio ? item.infortunio.nome : ''}}</b-col>
										</b-row>
										<b-row>
											<b-col>Modalità</b-col>
											<b-col class="label-value">{{item.modalita}}</b-col>
										</b-row>	
									</b-col>
								</b-row>
							</b-tab>
							<b-tab title="Note" :key="(valutazione.partite.length + 1)">
								{{ valutazione.note }}
							</b-tab>
						</b-tabs>
						<b-row class="mt-1">
							<b-col>
								<b-card class="p-1">
									<b-row>
										<b-col>Data</b-col>
										<b-col class="label-value">{{ valutazione.created_at | formatDate }}</b-col>
									</b-row>
									<b-row>
										<b-col>Osservatore</b-col>
										<b-col class="label-value">{{ valutazione.utente.cognome }} {{ valutazione.utente.nome }}</b-col>
									</b-row>
									<b-row>
										<b-col>Voto</b-col>
										<b-col class="label-value">{{ valutazione.voto[0] }}</b-col>
									</b-row>
								</b-card>
							</b-col>
							<b-col>
								<b-card class="p-1">
									<b-row>
										<b-col>Media/N° valutazioni</b-col>
										<b-col class="label-value">{{voto_medio}} / {{num_val}}</b-col>
									</b-row>
									<b-row>
										<b-col>Elenco valutazioni</b-col>
										<b-col class="label-value"><b-pagination-nav size="sm" :number-of-pages="valutazione.osservato.valutazioni.length" base-url="#" :link-gen="linkGen" v-model="currentPage" use-router/></b-col>
									</b-row>						 
								</b-card>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col v-for="item in fields" v-if="skills[item.id]">
						<b-card :title="item[$i18n.t('lang')]">
							<b-list-group>
								<b-list-group-item v-for="skill in item.fields" :variant="skills[item.id][skill.id]" v-if="skills[item.id][skill.id] > 0">
									<b-row style="width:100%">
										<b-col cols="12">
											<b-row align-v="center">
												<b-col cols="8">
													{{skill[$i18n.t('lang')]}}
												</b-col>
												<b-col cols="4">
													{{ voto(skills[item.id][skill.id]) }}
												</b-col>
											</b-row>
										</b-col>
									</b-row>						
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-col>
				</b-row>
				<b-modal id="shareModal" ref="shareModal" title="Condividi scheda giocatore" ok-only ok-title="Chiudi">
				<div v-if="sending" class="text-center"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
    			<b-form @submit="inviaSchedaGiocatore" v-if="!sending">
    				<b-form-group id="toInputGroup"
                    label="Email destinatari (divisi da virgola):"
                    label-for="toInput">
	        			<b-form-input id="toInput"
	                      type="text"
	                      v-model="form.to"
	                      required>
	        			</b-form-input>
        			</b-form-group>
        			<b-form-group id="ccInputGroup"
                    label="Cc (divisi da virgola):"
                    label-for="ccInput">
        			<b-form-input id="ccInput"
                      type="text"
                      v-model="form.cc">
        			</b-form-input>
        			</b-form-group>
        			<b-form-group id="ccnInputGroup"
                    label="Ccn (divisi da virgola):"
                    label-for="ccnInput">
        			<b-form-input id="ccnInput"
                      type="text"
                      v-model="form.ccn">
        			</b-form-input>
        			</b-form-group>
        			<b-form-group id="textInputGroup"
                    label="Testo del messaggio:"
                    label-for="textInput">
        			<b-form-textarea id="textInput"
                     v-model="form.message"
                     :rows="3"
                     :max-rows="3">
    				</b-form-textarea>
					</b-form-group>
					<b-form-group>
					<b-form-checkbox id="checkbox1"
                     v-model="form.onlyThis"
                     value="1">
      					Invia solo la valutazione corrente
    				</b-form-checkbox>
    				</b-form-group>
					<b-button type="submit" variant="primary">Invia</b-button>
					<b-button type="button" variant="success" @click="getLastSchedaInviata">Carica ultimi destinatari</b-button>
      				<b-button type="reset" variant="secondary">Reset</b-button>
    			</b-form>
  			</b-modal>
			</div>	
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>

var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';

export default {
	data: function() {
		return {
			valutazione: [],
			skills: [],
			fields: [],
			currentPage: 0,
			form: {
				to: '',
				cc: '',
				ccn: '',
				message: '',
				onlyThis: '',
			},
			sending: false,
			errors: [],
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
	},
	created: function () {
		this.loadData();
	},
	filters: {
		formatYear: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YYYY'); 
			}
			return "";
		},
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		},
	},
	methods: {
		loadData() {
			this.skills = [];
			this.fields = [];
			this.$http.get(this.$store.state.apiEndPoint + '/academy/scouting/view/valutazione/' + this.$route.params.val_id).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.valutazione = response.data;

            	this.fields = this.valutazione.skills_tags;
            	this.skills = JSON.parse(this.valutazione.skills); 	
            	var count = 1;
            	for (var index in this.valutazione.osservato.valutazioni) {
            		if(this.valutazione.osservato.valutazioni[index].id == this.valutazione.id) {
            			this.currentPage = (count);
            			break;
            		}
            		count++;
            	}
            }
        }, (response) => {
        });
		},
		linkGen (pageNum) {
			return {
				path: '/academy/scouting/view/valutazione/' + this.valutazione.osservato.id + '/' + this.valutazione.osservato.valutazioni[pageNum - 1].id
			}
		},

		voto(value) {
			if(value == 2) {
				return "Scarso";
			} else if(value == 4) {
				return "Insufficiente";
			} else if(value == 6) {
				return "Sufficiente";
			} else if(value == 8) {
				return "Buono";
			} else if(value == 10) {
				return "Ottimo";
			}
			
		},
		downloadScheda() {
			window.location = this.$store.state.apiEndPoint + '/academy/scouting/pdf/giocatore/' + this.valutazione.osservato.id + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		condividiScheda() {
			this.$refs.shareModal.show();		
		},
		inviaSchedaGiocatore(evt) {
			evt.preventDefault();
			this.sending = true;
			this.$http.post(this.$store.state.apiEndPoint + '/scouting/invia/scheda/giocatore/' + this.valutazione.id, this.form).then((response) => {
            // success callback
            if (response.ok)
            {   
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            	this.sending = false;
            	this.$refs.shareModal.hide();
            }
        }, (response) => {  
        	if (response.status == 422) {
        		this.errors = response.data;
        		for (var index in this.errors) {
        			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        		}
        		this.sending = false;
        		this.$refs.shareModal.hide();
        	}
            // error callback
        });
		},
		getLastSchedaInviata() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/get/last/scheda/inviata').then((response) => {
            if (response.ok)
            {
            	var last_scheda = response.data;
            	this.form.to = last_scheda.mailto;
            	this.form.cc = last_scheda.mailcc;
            	this.form.ccn = last_scheda.mailccn;       	
            }
        }, (response) => {
        });
		},
	},
	computed: {
		auth: function() {
            return Auth;
        },
		voto_medio: function() {

			var count = 0;
			var voto = 0;
			for (var index in this.valutazione.osservato.valutazioni) {
				if(this.valutazione.osservato.valutazioni[index].voto[0] > 0) {
					voto += this.valutazione.osservato.valutazioni[index].voto[0];
					count++;
				}
			}
			return Math.round((voto/count) * 100) / 100;
		},
		num_val: function() {

			var count = 0;
			for (var index in this.valutazione.osservato.valutazioni) {
				if(this.valutazione.osservato.valutazioni[index].voto[0] > 0) {
					count++;
				}
			}
			return count;
		},
	},
	watch: {
		'$route': function () {
			this.loadData();
		},
	},
}

</script>
