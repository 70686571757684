<template>
	<exerciseform :params="params"></exerciseform>
</template>

<script>
export default {
	components: {
		exerciseform: require('./form.vue').default,
	},
	props: {
		params: {
			type: Object,
			required: false
		}
	},
}
</script>
