<template>
	<div>

		<b-row v-if="!fase.entity.exercise_id && !fase.entity.partita" class="border p-3 mb-2 bg-light">
			<b-col>
				<h5 class="color-rosso">Cerca esercizio</h5>
				<b-row>
					<b-col cols="6">
						<b-form-group label="Titolo / Autore">
							<b-form-input type="text" v-model="ux.search"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label="Esercitazione">
							<b-form-select v-model="ux.drill_id">
								<option :value="null">seleziona</option>
								<option :value="drill.id" v-for="drill in drills" :key="drill.id">{{drill.name}}</option>
							</b-form-select>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col>
						<SkillPickerGroup size="sm" :model="ux.skills_rel" :config="{ radici: !ux.portiere ? [1, 30, 61] : [86, 99, 124, 61], onlyone: true, hideRating: true }"></SkillPickerGroup>
						<b-button @click="switchFilter" size="xs" class="mt-2">{{ ux.portiere ? 'passa agli obiettivi giocatore' : 'passa agli obiettivi portiere'}}</b-button>	
						<b-button @click="resetFilter" size="xs" class="mt-2 ml-1">Reset filtri</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group>
							<b-button @click="search_ex" type="button" variant="outline-primary" :block="true"><i class="fa fa-search fa-fw"></i> <span class="text-uppercase">Cerca</span></b-button>
						</b-form-group>
					</b-col>
				</b-row>
				
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" md="6">
				<b-form-group label="Immagine">
					<img v-if="fase.entity.exercise_id && fase.entity.esercizio && fase.entity.esercizio.canvas_url" class="img-fluid" :src="fase.entity.esercizio.canvas_url" />
					<img v-else class="img-fluid" src="http://via.placeholder.com/500?text=1" />
				</b-form-group>
				<b-form-group class="mr-2" v-show="!fase.entity.exercise_id && auth.isUserEnable('football_allen_crea_ese') && fase.entity.save_exe">
					<b-form-file accept="image/jpeg, image/png, image/gif" v-model="fase.entity.file" :state="Boolean(fase.entity.file)" placeholder="Scegli o trascina un'immagine da caricare..." drop-placeholder="Trascina un'immagine qui..."></b-form-file>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="6">
				<div class="text-right">
				<b-form-checkbox v-model="fase.entity.save_exe" :value="1" v-show="auth.isUserEnable('football_allen_crea_ese') && !fase.entity.exercise_id">Salva in Esercizi</b-form-checkbox>
				</div>
				<b-form-group label="Nome Drill GPS">
					<b-form-input :disabled="disabled" v-model="fase.entity.drill_gps" type="text"></b-form-input>
				</b-form-group>
				<b-form-group label="Titolo">
					<b-form-input :disabled="disabled" v-model="fase.entity.nome" type="text"></b-form-input>
				</b-form-group>
				<b-form-group :label="'Esercitazione ' + (!hasNotRequiredField('drill_id') ? '*' : '')">
					<b-form-select :disabled="disabled" v-model="fase.entity.drill_id" :required="!hasNotRequiredField('drill_id')">
						<option :value="null">seleziona</option>
						<option :value="drill.id" v-for="drill in drills" :key="drill.id">{{drill.name}}</option>
					</b-form-select>
				</b-form-group>
				<b-form-group label="Descrizione">
					<textarea :disabled="disabled" class="form-control" v-model="fase.entity.description" placeholder="descrizione fase dell'allenamento" rows="3" max-rows="6"></textarea>
				</b-form-group>

			</b-col>
		</b-row>

		<b-row>
			<b-col cols="6">
				<b-row>
					<b-col sm="3" class="mr-4">
						<b-form-group :label="'Tot. giocatori ' + (!hasNotRequiredField('players') ? '*' : '')">
							<b-form-input :disabled="disabled" type="number" :required="!hasNotRequiredField('players')" min="1" v-model="fase.entity.players"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G1">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input :disabled="disabled" type="number" min="0" v-model="fase.entity.g1"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G2">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input :disabled="disabled" type="number" min="0" v-model="fase.entity.g2"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G3">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input :disabled="disabled" type="number" min="0" v-model="fase.entity.g3"></b-form-input></b-col><b-col class="p-0 text-center">vs</b-col></b-row>
						</b-form-group>
					</b-col>
					<b-col sm="2">
						<b-form-group label="G4">
							<b-row align-v="center"><b-col cols="8" class="p-0"><b-form-input :disabled="disabled" type="number" min="0" v-model="fase.entity.g4"></b-form-input></b-col><b-col class="p-0 text-center"></b-col></b-row>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="3">
						<b-form-group label="Jolly">
							<b-form-input type="number" min="0" v-model="fase.entity.jolly" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group label="Sponde">
							<b-form-input type="number" min="0" v-model="fase.entity.sponde" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="3">
						<b-form-group label="Portieri">
							<b-form-input type="number" min="0" v-model="fase.entity.portieri" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="2">
					</b-col>
					<b-col sm="3">
						<b-form-group label="Porte grandi">
							<b-form-input type="number" min="0" v-model="fase.entity.porte_grandi" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group label="Porte piccole">
							<b-form-input type="number" min="0" v-model="fase.entity.porte_piccole" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="4">
						<b-form-group :label="'Lunghezza campo ' + (!hasNotRequiredField('campo_lunghezza') ? '*' : '')">
							<b-form-input type="number"  min="1" v-model="fase.entity.campo_lunghezza" :required="!hasNotRequiredField('campo_lunghezza')" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group :label="'Larghezza campo ' + (!hasNotRequiredField('campo_larghezza') ? '*' : '')">
							<b-form-input type="number"  min="1" v-model="fase.entity.campo_larghezza" :required="!hasNotRequiredField('campo_larghezza')" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group label="Densità campo">
							<b-form-input plaintext :value="getDensitaCampo(fase.entity)" :disabled="disabled"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>

			<b-col cols="6">
				<b-form-row>
					<b-col cols="12" md="6">
						<b-form-group :label="'Minuti esercitazione ' + (!hasNotRequiredField('training_minutes') ? '*' : '')">
							<b-form-input type="number" :disabled="disabled" :required="!hasNotRequiredField('training_minutes')" min="1" v-model="fase.entity.training_minutes"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group :label="'N. serie esercitazione ' + (!hasNotRequiredField('sets') ? '*' : '')">
							<b-form-input type="number" :disabled="disabled" :required="!hasNotRequiredField('sets')" min="1" v-model="fase.entity.sets"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group label="Min. recupero esercitazione">
							<b-form-input type="number" :disabled="disabled" min="0" v-model="fase.entity.recovery_minutes"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group label="Sec. recupero esercitazione">
							<b-form-input type="number" :disabled="disabled" min="0" v-model="fase.entity.recovery_seconds"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6">
						<b-form-group label="N. recupero esercitazione">
							<b-form-input type="number" :disabled="disabled" min="0" v-model="fase.entity.recoveries"></b-form-input>
						</b-form-group>
					</b-col>
				</b-form-row>
			</b-col>
		</b-row>

		<template v-if="!acquireToolsPrevisionalDatas">
			<hr />
				<b-button @click="acquireToolsPrevisionalDatas = true">
					Acquisisci dati previsionali
				</b-button>
			<hr />
		</template>
		<template v-else>
			<tools-finder :pos="pos" :sessionDataLayer="sessionDataLayer" v-if="auth.isUserEnable('tools_prevision') && fase.entity.exercise_id" :fase="fase"></tools-finder>
		</template>


		<!-- <v-for...> -->
			<div v-if="hasSkills">
				<SkillPickerGroup :disabled="disabled" :model="fase.entity.skills_rel" tabs :config="{ gruppi: hasSetup(sessione, 'hasSkillsGroups', false), radici: hasSkills }"></SkillPickerGroup>
			</div>

			<b-modal id="exModal" ref="exModal" title="Aggiungi Esercizio" size="lg" centered>
				<b-container fluid>
					<div>Esercizi totali: {{ exercises_certificati.total }}</div>
					<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
						<b-media class="py-3 border-bottom" v-for="ex in exercises_certificati.data" :key="ex.id">
							<b-img slot="aside" :src="ex.canvas_url || ex.immagine" @click="setExercise(ex.id)" height="150" />
							<div class="d-flex flex-column" style="height: 150px;">
								<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
								<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
								<div v-if="!ex.portiere">
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Principi di gioco">PG:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 1), skills.find(item => item.id == 1))">{{ getLabel(getSkillId(ex, 1), skills.find(item => item.id == 1)) }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Componenti tecniche">CT:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 30), skills.find(item => item.id == 30))">{{ getLabel(getSkillId(ex, 30), skills.find(item => item.id == 30)) }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacita motorie">CM:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61))">{{ getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61)) }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
								</div>
								<div v-if="ex.portiere">
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica podalica">TP:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 86), skills.find(item => item.id == 86))">{{ getLabel(getSkillId(ex, 86), skills.find(item => item.id == 86)) }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tecnica di base">TB:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 99), skills.find(item => item.id == 99))">{{ getLabel(getSkillId(ex, 99), skills.find(item => item.id == 99)) }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Qualità tattiche">QT:</span><span class="color-rosso" :title="getLabel(getSkillId(ex, 124), skills.find(item => item.id == 124))">{{ getLabel(getSkillId(ex, 124), skills.find(item => item.id == 124)) }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Capacità motorie">CM:</span> <span class="color-rosso" :title="getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61))">{{ getLabel(getSkillId(ex, 61), skills.find(item => item.id == 61)) }}</span></p>
								</div>
							<div class="flex-grow-1" style="overflow-y: auto;">
								<p class="text-muted"><small>{{ ex.description | trim(420) }}</small></p>
							</div>
						</div>
					</b-media>
				</v-infinite-scroll>
			</b-container>
		</b-modal>


	</div>
</template>

<script>

import sessioneMixin from '@/mixins/sessione.js';
import InfiniteScroll from 'v-infinite-scroll';
import axios from 'axios';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
		sessioneMixin
	],
	components: {
		ToolsFinder: require('@/components/layout/Sessioni/Football/ToolsFinder.vue').default,
		FaseTestString: require('@/components/layout/Sessioni/Test/FaseTestString.vue').default,
		FaseTestNumber: require('@/components/layout/Sessioni/Test/FaseTestNumber.vue').default,
		FaseTestBoolean: require('@/components/layout/Sessioni/Test/FaseTestBoolean.vue').default,
		FaseTestSelect: require('@/components/layout/Sessioni/Test/FaseTestSelect.vue').default,
		SkillPickerGroup: require('@/components/layout/Sessioni/Generica/SkillPickerGroup.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},
	props: [ 'sessionDataLayer', 'pos', 'sessione', 'fase', 'disabled' ],
	created: function() {
		this.getDrills();
		this.getSkills();
	},
	data: function(){
		return {
			acquireToolsPrevisionalDatas: false,
			drills: [],
			skills: [],
			ux: {
				loading: false,
				drill_id: null,
				search: '',
				skills_rel: [],
				portiere: 0,
			}
		}
	},
	computed: {
		hasSkills: function() {

			if(this.hasSetupTipo(this.fase, 'hasSkills', false))
				return this.hasSetupTipo(this.fase, 'hasSkills', false);
			else if(this.hasSetup(this.sessione, 'hasSkills', false))
				return this.hasSetup(this.sessione, 'hasSkills', false);

			return false;

		},
		...mapState('editor', ['exercises_certificati', 'page']),
		...mapState(['rosaTeamId']),
		user: function() {
			return Auth.user;
		},
		auth: function() {
			return Auth;
		}
	},
	methods: {
		skillsFrom: function(id) {
			return _.find(this.skills, { id });
		},
		getDrills: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/drills').then(function(data) {
				this.drills = data.data;
			});
		},
		getSkills: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skill/object').then(function(data) {
				this.skills = data.data;
			});
		},
		getDensitaCampo(phase) {
			var densita = "";
			if(phase.players > 0 && phase.campo_lunghezza > 0 && phase.campo_larghezza > 0) {
				densita = (phase.campo_lunghezza * phase.campo_larghezza) / phase.players;
				densita = Math.round(densita * 100) / 100;
			}
			return densita;
		},
		search_ex: function () {
			this.$refs.exModal.hide();
			this.$store.commit('editor/setObjIds', this.ux.skills_rel.map(item => item.skill_glo_id));
			this.$store.commit('editor/setTitle', this.ux.search);
			this.$store.commit('editor/setDrillId', this.ux.drill_id);
			this.$store.commit('editor/setPage', 1);
			this.$store.dispatch('editor/filter_certificati');
			this.$refs.exModal.show();
		},
		setExercise: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/get/' + id).then((res) => {

				this.fase.entity.exercise_id = res.data.id;
				this.fase.entity.nome = res.data.name
				this.fase.entity.description = res.data.description;
				// this.fase.entity.prw_image = res.data.canvas_url || res.data.immagine;
				this.fase.entity.field_size = res.data.sizeCampo;
				this.fase.entity.players = res.data.numeroGiocatori;
				this.fase.entity.training_minutes = res.data.minutiEsercizio;
				this.fase.entity.sets = res.data.numeroSerie;
				this.fase.entity.recovery_minutes = res.data.recupero;
				this.fase.entity.recovery_seconds = res.data.secondiRecupero;
				this.fase.entity.recoveries = res.data.numeroRecuperi;
				this.fase.entity.drill_id = res.data.drill_id;
				this.fase.entity.g1 = res.data.g1;
				this.fase.entity.g2 = res.data.g2;
				this.fase.entity.g3 = res.data.g3;
				this.fase.entity.g4 = res.data.g4;
				this.fase.entity.jolly = res.data.jolly;
				this.fase.entity.sponde = res.data.sponde;
				this.fase.entity.portieri = res.data.portieri;
				this.fase.entity.porte_grandi = res.data.porte_grandi;
				this.fase.entity.porte_piccole = res.data.porte_piccole;
				this.fase.entity.campo_lunghezza = res.data.campo_lunghezza;
				this.fase.entity.campo_larghezza = res.data.campo_larghezza;
				this.fase.entity.esercizio = res.data;
				this.fase.entity.skills_rel = res.data.skills_rel;

				this.phases.sort();

				this.$refs.exModal.hide();
			}).catch((err) => {
				this.loading = false;
				this.$refs.exModal.hide();
			});
		},
		nextPage: function() {
			if(this.page < this.exercises_certificati.last_page) {
				this.$store.commit('editor/setPage', this.page + 1);
				this.$store.dispatch('editor/filter_certificati', this.page + 1);
			}
		},
		switchFilter() {
			this.ux.portiere = !this.ux.portiere;
		},

		resetFilter() {
			this.ux.drill_id = null;
			this.ux.search = '';
			this.ux.skills_rel = [];
		},

		getSkillId(ex, root) {
			var skill_rel = ex.skills_rel.find(item => item.skill_glo_root_id == root);
			if(skill_rel) {
				return skill_rel.skill_glo_id;
			}
			return null;
		},

		getLabel(id_skill, skills) {
			var label = "N/D";
			if(id_skill && skills) {
				var skill_items = skills.campi;
				for(var key in skill_items) {
					var skill = skill_items[key];
					if(skill.id == id_skill) {
						label = skill.nome;
						return label;
					}
					if(skill.campi) {
						var items = skill.campi;
						for(var key2 in items) {
							var subskill = items[key2];
							if(subskill.campi) {
								var items2 = subskill.campi;
								for(var key3 in items2) {
									var subskill2 = items2[key3];
									if(subskill2.id == id_skill) {
										label = skill.nome + " -> " + subskill.nome + " -> " + subskill2.nome;
										return label;
									}  
								}
							} else {
								if(subskill.id == id_skill) {
									label = skill.nome + " -> " + subskill.nome;
									return label;
								} 		
							}
						}
					} else {
						if(skill.id == id_skill) {
							label = skill.nome;
							return label;
						}
					}
				}
			}
			return label;
		},

		hasNotRequiredField(field) {
			if(this.hasSetup(this.sessione, 'notRequiredFields', false)) {
				if(this.hasSetup(this.sessione, 'notRequiredFields', false)['Fase_Esercizio']) {
					if(this.hasSetup(this.sessione, 'notRequiredFields', false)['Fase_Esercizio'].indexOf(field) > -1) {
						return true;
					}
				}
			}
			return false;
		}
	},
	filters: {
		trim: function(val, length) {
			return _.truncate(val, { length: length, omission: '…' });
		}
	},
	watch: {
		'fase.entity.nome': function() {
			this.fase.nome = this.fase.entity.nome;
		}
		
	}
}
</script>