<style>
.over{ cursor: pointer; }
.over:hover{
  background-color: #dedede;
  cursor: pointer;
}
.selactive {
  background-color: #cecece;
}
</style>

<template id="exerciseform">
  <div class="container-fluid">
    <div class="card-title">
      <span>{{ params ? params.titolo : 'Editor Esercizi'}}</span>
    </div> 
    <form v-on:submit.prevent="submit()" action="#" method="POST">
      <div>
        <div class="row">
          <div class="col-sm">

            <div class="form-group">
              <label>Titolo</label>
              <b-form-input v-model="title" placeholder="Titolo esercizio" :required="true"></b-form-input>
            </div>
          </div>
        </div>
        <!--hr/-->
        <div class="row">
          <div class="col-sm">

            <div class="form-group">
              <label>Descrizione</label>
              <textarea
              name="description"
              rows="6"
              class="form-control"
              v-model="description"
              placeholder="Inserisci una descrizione per l'esercizio (facoltativa)"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="action-actions mt-4" v-if="auth.isUserEnable('piscina_ese_mng') && (auth.isAdmin() || auth.isAdminFootball() || exe_id == undefined || (exe_id > 0 && utente_id == auth.user.id))">
          <div>
            <label style="display:block;">  Visibilità esercizio </label>
            <b-form-radio-group v-model="stato">
              <b-form-radio value="0">Privato</b-form-radio>
              <b-form-radio value="1">Rosa</b-form-radio>
              <b-form-radio value="2">Archivio</b-form-radio>
            </b-form-radio-group>
            
          </div>
        </div>
        <!--hr /-->
        <div class="mt-3">  Media </div>
        <b-row class="border-bottom py-3">
          <b-col v-for="item in media" :key="item.id" cols="6">
            <div v-if="!item.id">
              <label style="display:block;">  Tipo </label>
              <b-form-radio-group v-model="item.tipo" class="mb-3">
                <b-form-radio value="foto">Foto</b-form-radio>
                <b-form-radio value="video">Video</b-form-radio>
              </b-form-radio-group>
            </div>

            <div v-if="item.id && item.tipo == 'foto' && item.image && item.image != ''" class="text-center">
              <b-img :src="item.foto_url" fluid/>
            </div>

            <div v-if="!item.id && item.tipo == 'foto'">
              <b-form-file
              accept="image/jpeg, image/png, image/gif"
              v-model="item.file"
              :state="Boolean(item.file)"
              placeholder="Scegli una foto da caricare..."
              drop-placeholder="Cancella file..."
              ></b-form-file>
            </div>

            <div v-if="!item.id && item.tipo == 'video'">
              <b-button @click="showUploaded" v-if="auth.isUserEnable('piscina_ese_mng')">Seleziona video <i class="fa fa-arrow-up" aria-hidden="true" v-if="show_uploaded"></i><i class="fa fa-arrow-down" aria-hidden="true" v-if="!show_uploaded"></i></b-button>
              <b-row class="mt-3" v-if="show_uploaded">
                <b-col cols="8">
                  <b-form-group label="Mostra i video di:">
                    <b-form-radio-group v-model="filters_date">
                      <b-form-radio value="day">Oggi</b-form-radio>
                      <b-form-radio value="week">Ultimi 7 giorni</b-form-radio>
                      <b-form-radio value="month">Ultimi 30 giorni</b-form-radio>
                      <b-form-radio value="all">Tutti</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <b-form-group label="Video caricati sul server ASRoma">
                    <div v-if="!upload_loading" style="width:100%;" class="mt-1">
                      <span class="font-bold">Loading...</span>
                      <img src="/assets/varie/loading.gif" style="width: 30px;" />
                    </div>
                    <span class="font-bold" v-if="upload_loading && upload_video.length == 0">Nessun video trovato sul tuo archivio!</span>
                    <b-form-select v-model="item.video" v-if="upload_loading && upload_video.length > 0">
                      <option :value="null">Seleziona video</option>
                      <optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
                        <option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} caricato il {{ video.data | formatDateHour }}</option>
                      </optgroup>
                    </b-form-select>
                  </b-form-group>
                  <b-row class="mt-3">
                    <b-col cols="12">
                      <b-button class="mt-3" @click="showUpload">Carica nuovo video</b-button>
                      <b-row v-if="show_upload" class="mt-3">
                        <b-col>
                          <b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>

            <div v-if="item.id && item.tipo == 'video' && item.video && item.video != ''" class="text-center">
              <b-embed type="video" controls aspect="16by9" :poster="item.image" preload="none" controlsList="nodownload" oncontextmenu="return false;">
                <source :src="item.video_url"/>    
              </b-embed>
            </div>

            <b-button variant="danger" size="sm" class="mt-3" @click="removeMedia(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi Media</b-button>
          </b-col>
        </b-row>
        <b-button variant="outline-primary" class="mt-3" @click="addMedia"><i class="fa fa-plus fa-fw"></i> Aggiungi Media</b-button>
        
        <div class="row" v-if="auth.isUserEnable('piscina_ese_mng')">
          <div class="col text-right">
            <button class="btn btn-primary btn-lg text-uppercase" v-if="auth.isAdmin() || auth.isAdminFootball() || exe_id == undefined || (exe_id > 0 && utente_id == auth.user.id)">Salva</button>
            <b-button class="btn btn-success btn-lg text-uppercase ml-2" @click="clonaEsercizio" v-if="exe_id > 0">Clona</b-button>
            <b-button class="btn btn-lg text-uppercase ml-2" @click="eliminaEsercizio" v-if="exe_id > 0 && (auth.isAdmin() || auth.isAdminFootball() || (utente_id == auth.user.id))">Elimina</b-button>
          </div>
        </div>

      </div>
    </form>
  </div>
</template>


<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import axios from 'axios';
import { mapState } from 'vuex';
import { jsPDF } from 'jspdf';
import moment from 'moment';

export default {
  components: {
    exerciseform: require('./form.vue').default,
  },

  props: {
    params: {
      type: Object,
      required: false
    }
  },

  data: function() {
    return {
      exe_id: this.$route.params.id,
      title: '',
      description: '',
      stato: 0,
      
      media: [],
      media_remove: [],
      
      loading: false,
      utente_id: 0,
      header_pdf: null,
      autore: '',

      thumb_video: '',
      src_video: '',

      upload_loading: false,
      upload_video: [],
      show_upload: false,
      show_uploaded: false,
      filters_date: 'day',
      upload_iframe: '',
    }
  },

  methods: {

    submit: function(){

      const formData = new FormData();
      if(this.exe_id > 0) formData.set('id', this.exe_id);

      formData.set('name', this.title);
      formData.set('description', this.description);
      formData.set('stato', this.stato);
      formData.set('team_id', this.rosaTeamId);
      for(var i in this.media) {
        var item = this.media[i];
        if(!item.id && item.tipo == 'foto' && item.file != '') {
          formData.append("foto_" + i, item.file);
          item.foto_index = i;
        }
      }
      formData.set('media', JSON.stringify(this.media));
      if(this.media_remove.length > 0) {
        formData.set('media_remove', this.media_remove);
      }
      formData.set('_method', 'POST');

      const opts = {
        url: `/piscina/esercizio${(this.exe_id > 0) ? `/edit` : '/add'}`,
        method: 'POST',
        data: formData,
      };

      axios(opts)
      .then((res) => {
        this.$router.push({ name: this.$route.meta.module + '_piscina_esercizi'});
      }).catch((err) => {
        console.log(err);
      });
    },    

    eliminaEsercizio() {
      if(confirm("Confermi l'eliminazione dell'esercizio?")) {
        this.$http.get(this.$store.state.apiEndPoint + '/piscina/esercizio/delete/' + this.$route.params.id).then((response) => {

          if (response.ok)
          {
            this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            this.$router.push({ name: this.$route.meta.module + "_piscina_esercizi" });
          }
        }, (response) => {
        });
      }
    },

    clonaEsercizio() {
      if(confirm("Confermi la clonazione dell'esercizio?")) {
        this.$http.get(this.$store.state.apiEndPoint + '/piscina/esercizio/duplica/' + this.$route.params.id + '/' + this.rosaTeamId).then((response) => {

          if (response.ok)
          {
            this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            this.$router.push({ name: this.$route.meta.module + "_piscina_esercizi" });
          }
        }, (response) => {
        });  
      }
    },

    getUploadVideo() {
      this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', {params: { filters_date: this.filters_date }}).then((response) => {
        if (response.ok)
        {
          this.upload_video = response.data;
          this.upload_loading = true;
        }
      }, (response) => {

      });
    },
    showUpload() {
      this.show_upload = !this.show_upload;   
    },
    showUploaded() {
      this.show_uploaded = !this.show_uploaded;  
      if(this.upload_iframe == '') {
        this.getUploadVideo();
        this.getUploadIFrame();
      } 
    },

    getUploadIFrame() {
      this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
        if (response.ok) {
          this.upload_iframe = response.data;
        }
      }, (response) => {
      });
    },

    addMedia: function () {
      this.media.push({ tipo: 'foto', file: '', video: null });
    },

    removeMedia: function (item) {
      if(item.id) {
        this.media_remove.push(item.id);
      }
      var index = this.media.indexOf(item);
      this.media.splice(index, 1);
    },
  },

  created: function () {
  },

  computed: {
    ...mapState(['rosaTeamId']),

    auth: function() {
      return Auth;
    }, 

    user: function() {
      return Auth.user;
    },   
  },

  watch: { 
    filters_date: function () {
      this.upload_loading = false;
      this.getUploadVideo();
    },
  },

  mounted()
  { 

   if(this.$route.params.id){
    this.loading = true;
    axios.get(`/piscina/esercizio/`+this.$route.params.id)
    .then((res) => {
      this.title = res.data.name;
      this.description = res.data.description;
      this.stato  = res.data.stato;
      this.utente_id = res.data.utente_id;
      this.exe_id = res.data.id;
      this.autore = res.data.utente.nomeCompleto;
      this.media = res.data.media;
      this.media_remove = [];

      this.loading    = false;
    }).catch((err) => {
      this.loading = false;
    });

  }

},

filters: {
  formatDateHour: function (date) {
    var m = moment(date);
    if(m.isValid()) {
      return m.format('DD/MM/YYYY HH:mm'); 
    }
    return "";
  },
},
}
</script>
