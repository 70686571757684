import axios from 'axios';

export default {
    filter({ state, commit, rootState }, params) {        
        var page = (state.page === undefined) ? 1 : state.page;
        return axios.post(`/palestra/esercizi?page=${page}&team_id=${rootState.rosaTeamId}&tab=esercizi`, {
            filters: state.filters,
            title: state.title,
        })
        .then(function (response) {
            commit('setPalestraEsercizi', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_archivio({ state, commit, rootState }, params) {
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/palestra/esercizi?page=${page}&team_id=${rootState.rosaTeamId}&tab=archivio`, {
            filters: state.filters,
            title: state.title,
        })
        .then(function (response) {
            commit('setPalestraEserciziArchivio', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_condivisi({ state, commit, rootState }, params) {
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/palestra/esercizi?page=${page}&team_id=${rootState.rosaTeamId}&tab=condivisi`, {
            filters: state.filters,
            title: state.title,
        })
        .then(function (response) {
            commit('setPalestraEserciziCondivisi', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },
};
