<template id="players">
	<div class="elencogiocatori">

		<div class="card-title d-flex">
			<span class="mr-auto">Rosa</span>
		</div>
		<div v-if="vista == 'griglia'">
			<div class="row">
				<div v-for="giocatore in players" :key="giocatore.id" class="col-2">
					<figurinagiocatore :giocatore="giocatore"></figurinagiocatore>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {

	components: {
		figurinagiocatore: 	require('./figure.vue').default,
	},

	data: function(){
		return {
			vista: 'griglia',
			players: [],
		}
	},
	computed: {
		...mapState(['seasonId']),
	},
	watch: {
		seasonId() {
			this.getPlayers();
		},
	},
	methods: {
		getPlayers: function(){
			/**
			*  RETRY players
			**/
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/opponents/' + this.$route.params.team_id + '/' + this.$route.params.category_id + '/' + this.seasonId).then((response) => {
				// success callback
				if (response.ok)
				{	
					if(response.data.players) {
						this.players = response.data.players.map(item => ({
							name: item.nome + ' ' + item.cognome,
							id: item.id,
							avatar: item.avatar_url,
						}));
					}
				}
			}, (response) => {
				// error callback
			});
		},
	},

	created: function (){
		this.getPlayers();
	},

}
</script>
