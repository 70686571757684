<template id="tabellaosservati">
    <div class="tabellaosservati">

        <table class="table table-striped">
            <thead>
                <th></th>
                <th></th>
                <th width="100"><i class="fa fa-fw fa-flag"></i></th>
                <th width="100"><i class="fa fa-fw fa-arrow-up"></i></th>
                <th width="100"><i class="fa fa-fw fa-futbol-o"></i></th>
                <th width="100"><i class="fa fa-fw fa-plus"></i></th>
            </thead>
            <tbody>
                <tr v-for="giocatore in rosa">
                    <td><router-link to="/rosa/giocatore">{{ giocatore.nome }} {{ giocatore.cognome }}</router-link></td>
                    <td>{{ giocatore.ruolo }}</td>
                    <td>18</td>
                    <td>7</td>
                    <td>15</td>
                    <td>220</td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
export default {

    props:{
        rosa: {
            type: Object,
            required: true
        }
    }

}
</script>
