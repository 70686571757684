<template id="profilo">
    <section class="profilo">
        <headapp></headapp>
        <div class="container-fluid m-y-1" style="background-color: white">
        	
        	<video-panorama-player></video-panorama-player>

        </div>
    </div>
    <footerapp></footerapp>
</section>
</template>

<script>
var Auth = require('../../auth.js');
import * as types from '../../store/types'
export default {
    data () {
        return {
            image_user: '',
            file_avatar: '',
            password: '',
            errors: [],
            indirizzo: '',
        }
    },

    components: {
        headapp:    require('../layout/headapp.vue').default,
        footerapp:  require('../layout/footerapp.vue').default,
        VideoPanoramaPlayer: require('../layout/video-panorama-player.vue').default
    },

    methods:{
    },
    mounted() {
       
    },
    computed: {
        user: function() {
            return Auth.user;
        },
        avatar: function() {
            if(this.image_user != "") {
                return this.image_user;
            } 
            return '';
        },
    },
    created: function () {
        
    }
}
</script>
