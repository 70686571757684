<template>
	<b-card>
		<b-overlay :show="loading">
			<b-row v-if="player" align-v="center">
				<b-col cols="2" class="text-center">
					<img :src="player.board_avatar_url" class="img-fluid" style="object-fit: contain; width: 100px; height: 100px; border: 1px solid rgba(0,0,0,.2); border-radius: 100%; box-shadow: 0px 0px 10px rgba(0,0,0,.1)" />
					<div class="my-1">
						<span class="font-weight-bold">{{ player.cognome }}</span>
						{{ player.nome }}
					</div>
				</b-col>
				<b-col cols="10">
					<E-Chart style="width: 100%; height: 300px" :option="graphOption"></E-Chart>
				</b-col>
			</b-row>
		</b-overlay>
	</b-card>
</template>

<script>

import eChart from '@/components/layout/E-Chart/Base.vue'
import moment from 'moment';

export default {
	props: [ 'player_id', 'filters', 'max_convocazioni', 'categoria_id' ],
	data: function() {
		return {
			loading: false,
			player: null,
			data: null,
		}
	},
	components: {
		'E-Chart': eChart,
	},
	computed: {

		filtered_data: function() {
			if(this.categoria_id) {
				return _.pickBy(this.data, function(item, key) { return item.categoria_id == this.categoria_id || item.convocazioni > 0 }.bind(this));
			}
			return this.data;
		},

		categorie: function() {
			return _.keys(this.filtered_data);
		},

		graphOption: function() {

			console.log(_.map(this.filtered_data, 'presenze'));

			return {tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					crossStyle: {
						color: '#999'
					}
				}
			},
			toolbox: {
				feature: {
					saveAsImage: { show: true }
				}
			},
			legend: {
				data: ['Convocazioni, Panchine', 'Convocazioni, Giocate', '% Minuti Giocati']
			},
			xAxis: [
			{
				type: 'category',
				data: this.categorie,
				axisPointer: {
					type: 'shadow'
				},
				splitArea: {
         			show:true,
         			areaStyle: {
          			color:["rgba(176,196,222, 0.2)",'rgba(255, 255, 255, 0.2)','rgba(176,196,222, 0.2)','rgba(255, 255, 255, 0.2)','rgba(176,196,222, 0.2)']
          			}
        		}
			}
			],
			yAxis: [
			{
				type: 'value',
				name: 'Convocazioni / Presenze',
				// min: 0,
				// max: this.max_convocazioni ? this.max_convocazioni : 100,
				interval: 2,
				axisLabel: {
					formatter: '{value}'
				}
			},
			{
				type: 'value',
				name: '% Minuti Giocati',
				// min: 0,
				max: 100,
				interval: 100,
				axisLabel: {
					formatter: '{value} %'
				}
			}
			],
			series: [
			{
				name: 'Convocazioni, Panchine',
				type: 'bar',
				stack: 'Total',
				tooltip: {
					valueFormatter: function (value) {
						return value;
					}
				},
				data: _.map(this.filtered_data, function(a,b) { return a.convocazioni - a.presenze }),
			},
			{
				name: 'Convocazioni, Giocate',
				type: 'bar',
				stack: 'Total',
				tooltip: {
					valueFormatter: function (value) {
						return value;
					}
				},
				data: _.map(this.filtered_data, 'presenze'),
			},
			{
				name: '% Minuti Giocati',
				type: 'line',
				yAxisIndex: 1,
				tooltip: {
					valueFormatter: function (value) {
						return value + ' %';
					}
				},
				itemStyle: {
					normal: {
						color: 'red'
					}
				},
				lineStyle: {
					normal: {
						color: 'red',
						width: 3
					}
				},
				data: _.map(this.filtered_data, 'percentuale_giocata')
			}
			]}
		},
	},
	mounted: function() {
		this.fetchMinutaggioPartiteGiocatore();
	},
	methods: {
		fetchMinutaggioPartiteGiocatore: function() {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/atls/minutaggio/partite/giocatore/' + this.player_id, {params: this.filters}).then(function(data) {
				this.player = data.data.player;
				this.data =  data.data.categorie;
				this.loading = false;
			});
		}
	}
}

</script>