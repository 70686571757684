<template>
	<b-card title="Report Skills Difensive">
		<b-table-simple small striped bordered responsive v-if="myStats" style="font-size:0.9rem">
			<thead>
				<tr>
					<th style="min-width:200px;"></th>
					<th class="text-center">AVG</th>
					<th class="text-center">{{ myStats.name }}</th>
					<th class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.name }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>	
					<td>Falli fatti</td>
					<td class="text-center">{{ stats.average.falliFatti }}</td>
					<td class="text-center">{{ myStats.falliFatti }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.falliFatti }}</td>				
				</tr>
				<tr>	
					<td>Tackles</td>
					<td class="text-center">{{ stats.average.tackles }}</td>
					<td class="text-center">{{ myStats.tackles }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.tackles }}</td>				
				</tr>
				<tr>	
					<td>Possessi Individuali Concessi Metà Campo Difensiva</td>
					<td class="text-center">{{ stats.average.possessiConcessiMetaDifensiva }}</td>
					<td class="text-center">{{ myStats.possessiConcessiMetaDifensiva }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.possessiConcessiMetaDifensiva }}</td>				
				</tr>
				<tr>	
					<td>Possessi Individuali Concessi Metà Campo Offensiva</td>
					<td class="text-center">{{ stats.average.possessiConcessiMetaOffensiva }}</td>
					<td class="text-center">{{ myStats.possessiConcessiMetaOffensiva }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.possessiConcessiMetaOffensiva }}</td>				
				</tr>
				<tr>	
					<td>Palloni Recuperati in Metà Campo Difensiva</td>
					<td class="text-center">{{ stats.average.recuperiMetaDifensiva }}</td>
					<td class="text-center">{{ myStats.recuperiMetaDifensiva }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.recuperiMetaDifensiva }}</td>				
				</tr>
				<tr>	
					<td>Palloni Recuperati in Metà Campo Offensiva</td>
					<td class="text-center">{{ stats.average.recuperiMetaOffensiva }}</td>
					<td class="text-center">{{ myStats.recuperiMetaOffensiva }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.recuperiMetaOffensiva }}</td>				
				</tr>
				<tr>	
					<td>Progressive Pass Concessi</td>
					<td class="text-center">{{ stats.average.progressivePassConcessi }}</td>
					<td class="text-center">{{ myStats.progressivePassConcessi }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.progressivePassConcessi }}</td>				
				</tr>
				<tr>	
					<td>Progressive Carries Concessi</td>
					<td class="text-center">{{ stats.average.progressiveCarriesConcessi }}</td>
					<td class="text-center">{{ myStats.progressiveCarriesConcessi }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.progressiveCarriesConcessi }}</td>				
				</tr>
				<tr>	
					<td>Cross Subiti (Open Play)</td>
					<td class="text-center">{{ stats.average.crossSubitiOP }}</td>
					<td class="text-center">{{ myStats.crossSubitiOP }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.crossSubitiOP }}</td>				
				</tr>
				<tr>	
					<td>Occasioni Subite (Open Play)</td>
					<td class="text-center">{{ stats.average.occasioniSubiteOP }}</td>
					<td class="text-center">{{ myStats.occasioniSubiteOP }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.occasioniSubiteOP }}</td>				
				</tr>
				<tr>	
					<td>Occasioni Subite (Set Pieces)</td>
					<td class="text-center">{{ stats.average.occasioniSubiteSP }}</td>
					<td class="text-center">{{ myStats.occasioniSubiteSP }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.occasioniSubiteSP }}</td>				
				</tr>
				<tr>	
					<td>Shots Subiti da Corner</td>
					<td class="text-center">{{ stats.average.shotsSubitiDaCorner }}</td>
					<td class="text-center">{{ myStats.shotsSubitiDaCorner }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.shotsSubitiDaCorner }}</td>				
				</tr>
				<tr>	
					<td>Shots subiti da Open Play</td>
					<td class="text-center">{{ stats.average.shotsSubitiDaOP }}</td>
					<td class="text-center">{{ myStats.shotsSubitiDaOP }}</td>	
					<td class="text-center" v-for="team in stats.teams" v-if="team.optaId != myStats.optaId">{{ team.shotsSubitiDaOP }}</td>				
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team'],
	data: function() {
		return {

		}
	},
	computed: {
		myStats: function () {
			if(this.stats && this.stats.teams) 
				return this.stats.teams.find(item => item.optaId == this.team.opta.chiave);
			return null;
		},
	},
}
</script>
