export const ROUTES_PRESTITI = [
    {
        path: '/prestiti/dashboard',
        name: 'prestiti_dashboard',
        component: require('../components/pages/prestiti/dashboard.vue').default,
        meta: {
            module: 'prestiti',

            label: 'Prestiti',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'prestiti',
        }
    },
    {
        path: '/prestiti/giocatore/:id',
        name: 'prestiti_giocatore',
        component: require('../components/pages/prestiti/giocatore.vue').default,
        meta: {
            module: 'prestiti',

            label: 'Prestiti giocatore',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'prestiti',
            logModel: 'Persona',
            logModelId: 'id',
        }
    },
];
