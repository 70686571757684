<template id="gvalutazioni">
	<div class="gvalutazioni">
		<table class="table table-striped">
			<thead>
				<tr>
					<th title="Intervallo">Periodo</th>
					<th title="Utente">Utente</th>
					<!--th title="Gradimento">Grad.</th-->
					<th title="Voto">Voto</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(valutazione, index) in params.valutazioni">
					<td><a :href="'#/football/rosa/view/valutazione/' + params.rosa_id + '/' + valutazione.id">{{valutazione.dal | formatDate}} - {{valutazione.al | formatDate}}</a></td>
					<td :title="valutazione.utente.nomeCompleto">{{valutazione.utente.cognome}}</td>
					<td class="text-center">{{valutazione.voto[0] > 0 ? valutazione.voto[0] : 'N/D'}}</td>
				</tr>
			</tbody>
		</table>
	</div>

	</div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    params: {
    	type: Object,
		required: false
	}
  },
  filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
}
</script>
