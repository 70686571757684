<template>
	<b-card title="Player stats">

		<div class="text-center">Goal e Assist</div>
		<b-table-simple small striped bordered responsive style="font-size:0.9rem">
			<thead>
				<tr>
					<th rowspan="3"></th>
					<th rowspan="3" class="text-center" style="vertical-align: middle;">Totale complessivo</th>
					<th class="text-center" :colspan="attaccanti.length">Attaccante</th>
					<th class="text-center" :colspan="centrocampisti.length">Centrocampista</th>
					<th class="text-center" :colspan="difensori.length">Difensore</th>
					<th class="text-center" :colspan="unknownRole.length">Ruolo Sconosciuto</th>
				</tr>
				<tr>
					<th class="text-center" v-for="player in attaccanti">{{ player.player }}</th>
					<th class="text-center" v-for="player in centrocampisti">{{ player.player }}</th>
					<th class="text-center" v-for="player in difensori">{{ player.player }}</th>
					<th class="text-center" v-for="player in unknownRole">{{ player.player }}</th>
				</tr>
				<tr>
					<td class="text-center" v-for="player in attaccanti">{{ player.minutes }} min.</td>
					<td class="text-center" v-for="player in centrocampisti">{{ player.minutes }} min.</td>
					<td class="text-center" v-for="player in difensori">{{ player.minutes }} min.</td>
					<td class="text-center" v-for="player in unknownRole">{{ player.minutes }} min.</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td style="min-width:200px;border-bottom: 2px #000000 solid;">Goal OP</td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center">{{ stats.total.goalOP }}</td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center" v-for="player in attaccanti">{{ player.goalOP }}</td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center" v-for="player in centrocampisti">{{ player.goalOP }}</td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center" v-for="player in difensori">{{ player.goalOP }}</td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center" v-for="player in unknownRole">{{ player.goalOP }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;">GoalSetPieces_IND</td>
					<td class="text-center">{{ stats.total.goalSetPieces_IND }}</td>
					<td class="text-center" v-for="player in attaccanti">{{ player.goalSetPieces_IND }}</td>
					<td class="text-center" v-for="player in centrocampisti">{{ player.goalSetPieces_IND }}</td>
					<td class="text-center" v-for="player in difensori">{{ player.goalSetPieces_IND }}</td>
					<td class="text-center" v-for="player in unknownRole">{{ player.goalSetPieces_IND }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;">GoalDirFK</td>
					<td class="text-center">{{ stats.total.goalDirFK }}</td>
					<td class="text-center" v-for="player in attaccanti">{{ player.goalDirFK }}</td>
					<td class="text-center" v-for="player in centrocampisti">{{ player.goalDirFK }}</td>
					<td class="text-center" v-for="player in difensori">{{ player.goalDirFK }}</td>
					<td class="text-center" v-for="player in unknownRole">{{ player.goalDirFK }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;">PenGoal</td>
					<td class="text-center">{{ stats.total.penGoal }}</td>
					<td class="text-center" v-for="player in attaccanti">{{ player.penGoal }}</td>
					<td class="text-center" v-for="player in centrocampisti">{{ player.penGoal }}</td>
					<td class="text-center" v-for="player in difensori">{{ player.penGoal }}</td>
					<td class="text-center" v-for="player in unknownRole">{{ player.penGoal }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;">IntentAst by OP</td>
					<td class="text-center">{{ stats.total.intentAstByOP }}</td>
					<td class="text-center" v-for="player in attaccanti">{{ player.intentAstByOP }}</td>
					<td class="text-center" v-for="player in centrocampisti">{{ player.intentAstByOP }}</td>
					<td class="text-center" v-for="player in difensori">{{ player.intentAstByOP }}</td>
					<td class="text-center" v-for="player in unknownRole">{{ player.intentAstByOP }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;">IntentAst by SP</td>
					<td class="text-center">{{ stats.total.intentAstBySP }}</td>
					<td class="text-center" v-for="player in attaccanti">{{ player.intentAstBySP }}</td>
					<td class="text-center" v-for="player in centrocampisti">{{ player.intentAstBySP }}</td>
					<td class="text-center" v-for="player in difensori">{{ player.intentAstBySP }}</td>
					<td class="text-center" v-for="player in unknownRole">{{ player.intentAstBySP }}</td>
				</tr>
			</tbody>
		</b-table-simple>

		<b-row>
			<b-col cols="8">
				<div class="text-center">Skills offensive</div>
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th colspan="2"></th>
							<th class="text-center">Verticalizzazione da 3D</th>
							<th class="text-center">Break Line Pass</th>
							<th class="text-center">Prog Carry</th>
							<th class="text-center">Prog Pass</th>
							<th class="text-center">Cross</th>
							<th class="text-center">Dribbling</th>
							<th class="text-center">Filtrante</th>
							<th class="text-center">Duelli aerei offensivi</th>
							<th class="text-center">Shots OutBox (no blocks) Open Play</th>
							<th class="text-center">XG Open Play</th>
							<th class="text-center">XG aerei su SetPieces</th>
							<th class="text-center">XG total SetPieces IND</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="player, index in attaccanti">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="attaccanti.length">Attaccante</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.vertAttfrom3D }}</td>
							<td class="text-center">{{ player.breakLinePass }}</td>
							<td class="text-center">{{ player.progCarry }}</td>
							<td class="text-center">{{ player.progPass }}</td>
							<td class="text-center">{{ player.crosses }}</td>
							<td class="text-center">{{ player.dribbling }}</td>
							<td class="text-center">{{ player.filtrante }}</td>
							<td class="text-center">{{ player.duelliAereiOffensivi }}</td>
							<td class="text-center">{{ player.shotsOutBoxNoBlocksOpenPlay }}</td>
							<td class="text-center">{{ player.xGFromOP }}</td>
							<td class="text-center">{{ player.xGAereiSetPieces }}</td>
							<td class="text-center">{{ player.xGFromSetPieces_IND }}</td>
						</tr>
						<tr v-for="player, index in centrocampisti">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="centrocampisti.length">Centrocampista</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.vertAttfrom3D }}</td>
							<td class="text-center">{{ player.breakLinePass }}</td>
							<td class="text-center">{{ player.progCarry }}</td>
							<td class="text-center">{{ player.progPass }}</td>
							<td class="text-center">{{ player.crosses }}</td>
							<td class="text-center">{{ player.dribbling }}</td>
							<td class="text-center">{{ player.filtrante }}</td>
							<td class="text-center">{{ player.duelliAereiOffensivi }}</td>
							<td class="text-center">{{ player.shotsOutBoxNoBlocksOpenPlay }}</td>
							<td class="text-center">{{ player.xGFromOP }}</td>
							<td class="text-center">{{ player.xGAereiSetPieces }}</td>
							<td class="text-center">{{ player.xGFromSetPieces_IND }}</td>
						</tr>
						<tr v-for="player, index in difensori">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="difensori.length">Difensore</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.vertAttfrom3D }}</td>
							<td class="text-center">{{ player.breakLinePass }}</td>
							<td class="text-center">{{ player.progCarry }}</td>
							<td class="text-center">{{ player.progPass }}</td>
							<td class="text-center">{{ player.crosses }}</td>
							<td class="text-center">{{ player.dribbling }}</td>
							<td class="text-center">{{ player.filtrante }}</td>
							<td class="text-center">{{ player.duelliAereiOffensivi }}</td>
							<td class="text-center">{{ player.shotsOutBoxNoBlocksOpenPlay }}</td>
							<td class="text-center">{{ player.xGFromOP }}</td>
							<td class="text-center">{{ player.xGAereiSetPieces }}</td>
							<td class="text-center">{{ player.xGFromSetPieces_IND }}</td>
						</tr>
						<tr v-for="player, index in unknownRole">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="unknownRole.length">Ruolo sconosciuto</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.vertAttfrom3D }}</td>
							<td class="text-center">{{ player.breakLinePass }}</td>
							<td class="text-center">{{ player.progCarry }}</td>
							<td class="text-center">{{ player.progPass }}</td>
							<td class="text-center">{{ player.crosses }}</td>
							<td class="text-center">{{ player.dribbling }}</td>
							<td class="text-center">{{ player.filtrante }}</td>
							<td class="text-center">{{ player.duelliAereiOffensivi }}</td>
							<td class="text-center">{{ player.shotsOutBoxNoBlocksOpenPlay }}</td>
							<td class="text-center">{{ player.xGFromOP }}</td>
							<td class="text-center">{{ player.xGAereiSetPieces }}</td>
							<td class="text-center">{{ player.xGFromSetPieces_IND }}</td>
						</tr>
					</tbody>
				</b-table-simple>
				<div class="text-center">Skills difensive</div>
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th colspan="2"></th>
							<th class="text-center">Duello aereo difensivo</th>
							<th class="text-center">Intercetti metà difensiva</th>
							<th class="text-center">Intercetti metà offensiva</th>
							<th class="text-center">Recuperi metà difensiva</th>
							<th class="text-center">Recuperi metà offensiva</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="player, index in attaccanti">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="attaccanti.length">Attaccante</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.duelliAereiDifensivi }}</td>
							<td class="text-center">{{ player.intercettiMetaDifensiva }}</td>
							<td class="text-center">{{ player.intercettiMetaOffensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaDifensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaOffensiva }}</td>
						</tr>
						<tr v-for="player, index in centrocampisti">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="centrocampisti.length">Centrocampista</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.duelliAereiDifensivi }}</td>
							<td class="text-center">{{ player.intercettiMetaDifensiva }}</td>
							<td class="text-center">{{ player.intercettiMetaOffensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaDifensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaOffensiva }}</td>
						</tr>
						<tr v-for="player, index in difensori">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="difensori.length">Difensore</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.duelliAereiDifensivi }}</td>
							<td class="text-center">{{ player.intercettiMetaDifensiva }}</td>
							<td class="text-center">{{ player.intercettiMetaOffensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaDifensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaOffensiva }}</td>
						</tr>
						<tr v-for="player, index in unknownRole">
							<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="unknownRole.length">Ruolo sconosciuto</td>
							<td>{{ player.player }}</td>
							<td class="text-center">{{ player.duelliAereiDifensivi }}</td>
							<td class="text-center">{{ player.intercettiMetaDifensiva }}</td>
							<td class="text-center">{{ player.intercettiMetaOffensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaDifensiva }}</td>
							<td class="text-center">{{ player.recuperiMetaOffensiva }}</td>
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
			<b-col>
				<div class="text-center">Valori condizionali (TODO)</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
export default {
	props: ['stats'],
	data: function() {
		return {

		}
	},
	computed: {
		players: function() {
			return this.stats && this.stats.players ? this.stats.players : [];
		},
		difensori: function() {
			return this.players.filter(item => item.genericPosition == 'Difensore');
		},
		centrocampisti: function() {
			return this.players.filter(item => item.genericPosition == 'Centrocampista');
		},
		attaccanti: function() {
			return this.players.filter(item => item.genericPosition == 'Attaccante');
		},
		unknownRole: function() {
			return this.players.filter(item => item.genericPosition == '');
		}
	},
}
</script>
