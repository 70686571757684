<template>
	<div>

		<b-card class="mt-2 mb-4">
			<template slot="header">
				Dati Previsionali 
				<span v-if="!preview || ux.wait">
					<span v-if="ux.fasiFound">(<strong>{{ux.fasiFound}}</strong> fasi trovate)</span>
					<span v-else class="italic text-muted">Attendi…</span>
				</span>
				<strong v-else class="text-muted">(su {{ preview.total_results }} fasi trovate)</strong>
				<div class="small">Vengono rappresentati i valori medi per partecipante alla fase.</div>
			</template>
			<!-- <b-alert v-if="ux.wait" variant="secondary" :show="true">Attendi il caricamento dei dati previsionali…</b-alert> -->
			<b-alert :show="true" v-if="preview && preview.total_results == 0" variant="warning">Nessuna fase corrisponde alla ricerca.</b-alert>
			<div v-else-if="preview && preview.total_results > 0">

				<b-overlay :show="ux.wait">
					<div v-if="preview">
						<b-table-simple striped small>
							<b-thead>
								<b-tr>
									<b-th></b-th>
									<b-th class="text-center">Tot. Dist</b-th>
									<b-th class="text-center">Distanza > 15</b-th>
									<b-th class="text-center">Velocità Massima</b-th>

									<b-th class="text-center">Acc2</b-th>
									<b-th class="text-center">Dec2</b-th>
									<b-th class="text-center">Velocità > 25km/h</b-th>

									<b-th class="text-center">Max HR %</b-th>
									<b-th class="text-center">HR > 85%</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr>
									<b-th>Media su {{ preview.total_results }} fasi</b-th>
									<b-td class="text-center">{{previewMean.table.drill.total_distance}}</b-td>
									<b-td class="text-center">{{previewMean.table.drill.cc_dist_15}}</b-td>
									<b-td class="text-center">{{previewMean.table.drill.max_vel}}</b-td>

									<b-td class="text-center">{{previewMean.table.neurom.acc2}}</b-td>
									<b-td class="text-center">{{previewMean.table.neurom.dec2}}</b-td>
									<b-td class="text-center">{{previewMean.table.velocity.velocity__25}}</b-td>

									<b-td class="text-center">{{previewMean.table.hr.max_hr_p}}</b-td>
									<b-td class="text-center">{{previewMean.table.hr.hr_85}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>SD su {{ preview.total_results }} fasi</b-th>
									<b-td class="text-center">{{previewMean.ds.drill.total_distance}}</b-td>
									<b-td class="text-center">{{previewMean.ds.drill.cc_dist_15}}</b-td>
									<b-td class="text-center">{{previewMean.ds.drill.max_vel}}</b-td>

									<b-td class="text-center">{{previewMean.ds.neurom.acc2}}</b-td>
									<b-td class="text-center">{{previewMean.ds.neurom.dec2}}</b-td>
									<b-td class="text-center">{{previewMean.ds.velocity.velocity__25}}</b-td>

									<b-td class="text-center">{{previewMean.ds.hr.max_hr_p}}</b-td>
									<b-td class="text-center">{{previewMean.ds.hr.hr_85}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Totale Sessione <span class="text-muted small">{{loadedTools}}</span></b-th>
									<b-td class="text-center">{{previewTotal.total.drill.total_distance}}</b-td>
									<b-td class="text-center">{{previewTotal.total.drill.cc_dist_15}}</b-td>
									<b-td class="text-center">{{previewTotal.total.drill.max_vel}}</b-td>

									<b-td class="text-center">{{previewTotal.total.neurom.acc2}}</b-td>
									<b-td class="text-center">{{previewTotal.total.neurom.dec2}}</b-td>
									<b-td class="text-center">{{previewTotal.total.velocity.velocity__25}}</b-td>

									<b-td class="text-center">{{previewTotal.total.hr.max_hr_p}}</b-td>
									<b-td class="text-center">{{previewTotal.total.hr.hr_85}}</b-td>
								</b-tr>
								<b-tr>
									<b-th>Media Sessione <span class="text-muted small">{{loadedTools}}</span></b-th>
									<b-td class="text-center">{{previewTotal.mean.drill.total_distance}}</b-td>
									<b-td class="text-center">{{previewTotal.mean.drill.cc_dist_15}}</b-td>
									<b-td class="text-center">{{previewTotal.mean.drill.max_vel}}</b-td>

									<b-td class="text-center">{{previewTotal.mean.neurom.acc2}}</b-td>
									<b-td class="text-center">{{previewTotal.mean.neurom.dec2}}</b-td>
									<b-td class="text-center">{{previewTotal.mean.velocity.velocity__25}}</b-td>

									<b-td class="text-center">{{previewTotal.mean.hr.max_hr_p}}</b-td>
									<b-td class="text-center">{{previewTotal.mean.hr.hr_85}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>

						<b-row class="mt-2">
							<b-col cols="3">
								<h5 class="text-center">Densità Estratte (±{{ux.fasiRange}}%)</h5>
								<b-table-simple small striped hover class="mt-2">
									<b-thead>
										<b-tr>
											<b-th class="text-center">Densità</b-th>
											<b-th class="text-center">Qty</b-th>
											<b-th class="text-center"></b-th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="d in densities">
											<b-td class="text-center">{{ d.densita }} <span class="small text-muted">({{ percentuale(d) }})</span></b-td>
											<b-td class="text-center">{{ d.value }}</b-td>
											<b-td class="text-center" width="60%">
												<b-progress :value="remap(d.value, densities)" :max="100"></b-progress>
											</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
								<!-- <code>{{densities}}</code> -->
							</b-col>
							<b-col>
								<div>Durate</div>
								<code>{{durations}}</code>
							</b-col>
						</b-row>
					</div>
				</b-overlay>

				<p class="text-danger" v-if="ux.noResult">L'attuale configurazione non ha precedenti storici.</p>


			</div>
			<div v-if="!preview && ux.wait">Attendi caricamento dei dati previsionali di <strong>{{ux.fasiFound}}</strong> fasi.</div>
		</b-card>

		<!--
		<pre>{{filters}}</pre>
		<pre>{{fase.entity}}</pre>
		<pre>{{fase.entity.exercise_id}}</pre>
		-->

	</div>
</template>


<script>

import th from '@/tools_helper.js';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'pos', 'fase', 'sessionDataLayer' ],
	data: function() {
		return {
			ux: {
				fasiFound: null,
				fasiRange: null,
				wait: false
			},
			noResult: false,
			preview: null
		}
	},
	computed: {
		densities: function() {

			return _.map(_.mapValues(_.groupBy(_.map(this.preview.results, 0), 'densita'), function(a) {
				return a.length;
			}), function(a,b) {
				return {
					densita: b,
					value: a
				}
			});

			return _.uniq(_.map(_.flatten(this.preview.results), 'densita'));
		},
		durations: function() {
			return _.uniq(_.map(_.flatten(this.preview.results), 'total_original_duration'));
		},
		loadedTools: function() {
			return _.size(_.filter(this.sessionDataLayer)) + '/' + (_.size(this.sessionDataLayer));
		},
		previewTotal: function() {

			if(!this.previewMean) return false;

			var total = {};
			var mean = {};
			_.each(_.keys(this.previewMean.ds), function(a) {
				total[a] = {};
				mean[a] = {};
				_.each(_.keys(this.previewMean.ds[a]), function(b) {

					var values = _.map(this.sessionDataLayer, function(i) {

						if(_.isObject(i) && i.table && i.table[a] && i.table[a][b])
							return i.table[a][b];

					});

					total[a][b] = _.round( _.sum(values), 1 );
					mean[a][b] = _.round( _.sum(values) / values.length, 1 );

				}.bind(this));
			}.bind(this));

			var u = { total, mean };
			return u;

		},
		previewMean: function() {

			if(!this.preview || !this.preview.results) return false;

			var tmp = _.map(_.filter(this.preview.results, function(a) {
				return a.length > 0;
			}), function(a) {

				var keys = _.keys(a[0]);
				var test = {};
				_.each(keys, function(k) {
					var p = _.map(a, k);
					test[k] = _.sum(p)/p.length;
				});

				delete test.id;
				delete test.fase_id;
				delete test.persona_id;
				delete test.fase_persona_id;
				delete test.fase_originale_id;
				delete test.categoria_id;
				delete test.sessione_id;
				delete test.start_time;
				delete test.end_time;
				delete test.alert_d;
				delete test.alert_v;
				delete test.alert_h;
				delete test.date;
				delete test.created_at;
				delete test.updated_at;

				return {
					neurom: th.buildNeurom(a, null, true, this.fase.entity.training_minutes),
					drill: th.buildDrill(a, null, true, this.fase.entity.training_minutes),
					velocity: th.buildVelocity(a, null, true, this.fase.entity.training_minutes),
					hr: th.buildHeartRate(a, null, true, this.fase.entity.training_minutes)
				}

				return test;
			}.bind(this));

			var table = {};
			var ds = {};

			console.log(tmp);
			_.each(_.keys(tmp[0]), function(a) {
				table[a] = {};
				ds[a] = {};
				_.each(_.keys(tmp[0][a]), function(b) {

					var values = _.map(tmp, function(i) {
						return i[a][b];
					});

					table[a][b] = _.round(_.sum(values)/values.length, 1);
					ds[a][b] = _.round(th.deviazioneStandard(values), 1);

				})
			});

			return { table, ds };

		},
		filters: function() {

			if(!this.fase.entity) return false;
			var base = _.cloneDeep(this.fase.entity);

			delete base.esercizio;
			delete base.skills_rel;
			delete base.created_at;
			delete base.updated_at;
			delete base.drill_gps;
			delete base.id;
			delete base.nome;
			delete base.description;
			delete base.exercise_id;
			delete base.drill_id;

			_.each(base, function(a, b) {
				if(b === 0) {
					delete base[a];
				} else if(_.isNumber(b)) {
					base[a] = parseFloat(b);
				}
			});

			return base;

		}
	},
	watch: {
		'fase.entity.exercise_id': {
			deep: true,
			immediate: true,
			handler: function() {
				this.getPrevision();
			}
		},
		'fase.entity': {
			deep: true,
			handler: function() {
				this.getPrevisionTick();
			}
		}
	},
	methods: {
		remap: function(a, b) {

			return 100*(a/_.sumBy(b, 'value'));

		},
		percentuale: function(val) {
			var i = (val.densita/((this.filters.campo_larghezza * this.filters.campo_lunghezza)/this.filters.players))-1;
			if(i > 0)
				return '+' + _.round(i*100) + '%';
			else
				return _.round(i*100) + '%';
		},
		getPrevisionTick: _.debounce(function() {
			this.getPrevision();
		}, 500),
		getPrevision: function() {

			if(this.fase.entity.exercise_id !== null) {
				this.ux.wait = true;
				var f = _.cloneDeep(this.filters);
				delete f.training_minutes;
				delete f.recovery_minutes;
				delete f.recovery_seconds;
				delete f.field_size;
				
				f.densita = (f.campo_larghezza * f.campo_lunghezza)/f.players;

				delete f.campo_larghezza;
				delete f.campo_lunghezza;
				this.ux.fasiFound = null;
				this.$http.post(this.$store.state.apiEndPoint + '/tools/exercise-preview/' + this.fase.entity.exercise_id, { filters: f, exclude: this.fase.sessione_id }).then(function(data) {
					
					this.ux.fasiFound = data.data.num_results;
					this.ux.fasiRange = data.data.difference;
					console.log('Acquired!');
					this.getPrevisionReal();

				});
			}

			// this.getPrevisionReal();
		},
		getPrevisionReal: function() {

			if(this.fase.entity.exercise_id !== null) {
				this.ux.wait = true;
				var f = _.cloneDeep(this.filters);
				delete f.training_minutes;
				delete f.recovery_minutes;
				delete f.recovery_seconds;
				delete f.field_size;
				
				f.densita = (f.campo_larghezza * f.campo_lunghezza)/f.players;

				delete f.campo_larghezza;
				delete f.campo_lunghezza;

				this.$http.post(this.$store.state.apiEndPoint + '/tools/exercise-preview/' + this.fase.entity.exercise_id, { calculate: true, filters: f, exclude: this.fase.sessione_id }).then(function(data) {
					
					this.ux.wait = false;
					if(this.preview != null && data.data.total_results == 0) {
						this.ux.noResult = true;
					} else {
						this.ux.noResult = false;
						this.preview = data.data;
						this.$set(this.sessionDataLayer, 'fase_' + this.pos, _.cloneDeep(this.previewMean));
					}

				});
			}

		}
	}
}

</script>