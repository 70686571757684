<style>
.over{ cursor: pointer; }
.over:hover{
  background-color: #dedede;
  cursor: pointer;
}
.selactive {
  background-color: #cecece;
}
</style>

<template id="exerciseform">
  <div class="container-fluid">
    <div class="card-title">
      <span>{{ params ? params.titolo : 'Editor Esercizi'}}</span>
      <div class="pull-right" v-if="false">
        <b-button variant="outline-primary" @click="downloadPdf" v-if="$route.params.id"><i class="fa fa-eye fa-fw"></i> stampa pdf</b-button>
      </div>
    </div> 
    <form v-on:submit.prevent="submit()" action="#" method="POST">
      <div>
        <div class="row">
          <div class="col-sm">

            <div class="form-group">
              <label>Titolo</label>
              <b-form-input v-model="title" placeholder="Titolo esercizio" :required="true"></b-form-input>
            </div>
          </div>
        </div>
        <!--hr/-->
        <div class="row">
          <div class="col-sm">

            <div class="form-group">
              <label>Descrizione</label>
              <textarea
              name="description"
              rows="6"
              class="form-control"
              v-model="description"
              placeholder="Inserisci una descrizione per l'esercizio (facoltativa)"
              ></textarea>
            </div>
          </div>
        </div>

        <!--hr /-->
        <div class="row">
          <div class="col-sm">
            <label>Facility</label>
            <b-form-select v-model="facility">
              <option :value="0">seleziona</option>
              <option :value="item.id" v-for="item in facility_opt" :key="item.id">{{item.name}}</option>
            </b-form-select>
          </div>
          <div class="col-sm">
            <label>Goal</label>
            <b-form-select v-model="goal">
              <option :value="0">seleziona</option>
              <option :value="item.id" v-for="item in goal_opt" :key="item.id">{{item.name}}</option>
            </b-form-select>
          </div>
          <div class="col-sm">
            <label>Tt</label>
            <b-form-select v-model="tt" :disabled="goal != 17">
              <option :value="0">seleziona</option>
              <option :value="item.id" v-for="item in tt_opt" :key="item.id">{{item.name}}</option>
            </b-form-select>
          </div>
          <div class="col-sm">
            <label>Intensity</label>
            <b-form-select v-model="intensity">
              <option :value="0">seleziona</option>
              <option :value="item.id" v-for="item in intensity_opt" :key="item.id">{{item.name}}</option>
            </b-form-select>
          </div>
        </div>

        <div class="action-actions mt-4" v-if="auth.isUserEnable('palestra_ese_mng') && (auth.isAdmin() || auth.isAdminFootball() || exe_id == undefined || (exe_id > 0 && id_utente == auth.user.id))">
          <div>
            <label style="display:block;">  Visibilità esercizio </label>
            <b-form-radio-group v-model="stato">
              <b-form-radio value="0">Privato</b-form-radio>
              <b-form-radio value="1">Rosa</b-form-radio>
              <b-form-radio value="2">Archivio</b-form-radio>
            </b-form-radio-group>
            
          </div>
        </div>
        <!--hr /-->
        <div class="mt-3">  Media </div>
        <b-row class="border-bottom py-3">
          <b-col v-for="item in media" :key="item.id" cols="6">
            <div v-if="!item.id">
              <label style="display:block;">  Tipo </label>
              <b-form-radio-group v-model="item.tipo" class="mb-3">
                <b-form-radio value="foto">Foto</b-form-radio>
                <b-form-radio value="video">Video</b-form-radio>
              </b-form-radio-group>
            </div>

            <div v-if="item.id && item.tipo == 'foto' && item.image && item.image != ''" class="text-center">
              <b-img :src="item.foto_url" fluid/>
            </div>

            <div v-if="!item.id && item.tipo == 'foto'">
              <b-form-file
              accept="image/jpeg, image/png, image/gif"
              v-model="item.file"
              :state="Boolean(item.file)"
              placeholder="Scegli una foto da caricare..."
              drop-placeholder="Cancella file..."
              ></b-form-file>
            </div>

            <div v-if="!item.id && item.tipo == 'video'">
              <b-button @click="showUploaded" v-if="auth.isUserEnable('palestra_ese_mng')">Seleziona video <i class="fa fa-arrow-up" aria-hidden="true" v-if="show_uploaded"></i><i class="fa fa-arrow-down" aria-hidden="true" v-if="!show_uploaded"></i></b-button>
              <b-row class="mt-3" v-if="show_uploaded">
                <b-col cols="8">
                  <b-form-group label="Mostra i video di:">
                    <b-form-radio-group v-model="filters_date">
                      <b-form-radio value="day">Oggi</b-form-radio>
                      <b-form-radio value="week">Ultimi 7 giorni</b-form-radio>
                      <b-form-radio value="month">Ultimi 30 giorni</b-form-radio>
                      <b-form-radio value="all">Tutti</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <b-form-group label="Video caricati sul server ASRoma">
                    <div v-if="!upload_loading" style="width:100%;" class="mt-1">
                      <span class="font-bold">Loading...</span>
                      <img src="/assets/varie/loading.gif" style="width: 30px;" />
                    </div>
                    <span class="font-bold" v-if="upload_loading && upload_video.length == 0">Nessun video trovato sul tuo archivio!</span>
                    <b-form-select v-model="item.video" v-if="upload_loading && upload_video.length > 0">
                      <option :value="null">Seleziona video</option>
                      <optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
                        <option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} caricato il {{ video.data | formatDateHour }}</option>
                      </optgroup>
                    </b-form-select>
                  </b-form-group>
                  <b-row class="mt-3">
                    <b-col cols="12">
                      <b-button class="mt-3" @click="showUpload">Carica nuovo video</b-button>
                      <b-row v-if="show_upload" class="mt-3">
                        <b-col>
                          <b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>

            <div v-if="item.id && item.tipo == 'video' && item.video && item.video != ''" class="text-center">
              <b-embed type="video" controls aspect="16by9" :poster="item.image" preload="none" controlsList="nodownload" oncontextmenu="return false;">
                <source :src="item.video_url"/>    
              </b-embed>
            </div>

            <b-button variant="danger" size="sm" class="mt-3" @click="removeMedia(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi Media</b-button>
          </b-col>
        </b-row>
        <b-button variant="outline-primary" class="mt-3" @click="addMedia"><i class="fa fa-plus fa-fw"></i> Aggiungi Media</b-button>
        
        <div class="row" v-if="auth.isUserEnable('palestra_ese_mng')">
          <div class="col text-right">
            <button class="btn btn-primary btn-lg text-uppercase" v-if="auth.isAdmin() || auth.isAdminFootball() || exe_id == undefined || (exe_id > 0 && id_utente == auth.user.id)">Salva</button>
            <b-button class="btn btn-success btn-lg text-uppercase ml-2" @click="clonaEsercizio" v-if="exe_id > 0">Clona</b-button>
            <b-button class="btn btn-lg text-uppercase ml-2" @click="eliminaEsercizio" v-if="exe_id > 0 && (auth.isAdmin() || auth.isAdminFootball() || (id_utente == auth.user.id))">Elimina</b-button>
          </div>
        </div>

      </div>
    </form>
  </div>
</template>


<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import axios from 'axios';
import { mapState } from 'vuex';
import { jsPDF } from 'jspdf';
import moment from 'moment';

export default {
  components: {
    exerciseform:      require('./form.vue').default,
  },

  props: {
    params: {
      type: Object,
      required: false
    }
  },

  data: function() {
    return {
      facility_opt: [],
      goal_opt: [],
      tt_opt: [],
      intensity_opt: [],
      exe_id: this.$route.params.id,
      title: '',
      description: '',
      
      facility: 0,
      goal: 0,
      tt: 0,
      intensity: 0,
      stato: 0,
      
      media: [],
      media_remove: [],
      
      loading: false,
      id_utente: 0,
      header_pdf: null,
      autore: '',

      thumb_video: '',
      src_video: '',

      upload_loading: false,
      upload_video: [],
      show_upload: false,
      show_uploaded: false,
      filters_date: 'day',
      upload_iframe: '',
    }
  },

  methods: {

    submit: function(){

      const formData = new FormData();
      if(this.exe_id > 0) formData.set('id', this.exe_id);

      formData.set('name', this.title);
      formData.set('description', this.description);
      formData.set('facility', this.facility);
      formData.set('goal', this.goal);
      formData.set('tt', this.tt);
      formData.set('intensity', this.intensity);
      formData.set('stato', this.stato);
      formData.set('team_id', this.rosaTeamId);
      for(var i in this.media) {
        var item = this.media[i];
        if(!item.id && item.tipo == 'foto' && item.file != '') {
          formData.append("foto_" + i, item.file);
          item.foto_index = i;
        }
      }
      formData.set('media', JSON.stringify(this.media));
      if(this.media_remove.length > 0) {
        formData.set('media_remove', this.media_remove);
      }
      formData.set('_method', 'POST');

      const opts = {
        url: `/palestra/esercizio${(this.exe_id > 0) ? `/edit` : '/add'}`,
        method: 'POST',
        data: formData,
      };

      axios(opts)
      .then((res) => {
        this.$router.push({ name: this.$route.meta.module + '_palestra_esercizi'});
      }).catch((err) => {
        console.log(err);
      });
    },

    downloadPdf(){
      var canvas = document.getElementById('canvas');
      var imgData = canvas.toDataURL("png");
      var doc = new jsPDF("p", "mm", "a4");
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight(); 
      var fontSize = 12;
      var ptsPerInch = 72;
      doc.addImage(this.header_pdf, 'PNG', 0, 0, width, 33);
      doc.addImage(imgData, 'PNG', 12, 35, 190, 95);
      doc.setFontSize(fontSize);
      doc.setFontType("bold");
      doc.text(5, 137, 'Nome:');
      doc.text(5, 142, 'Autore:');
      doc.text(5, 147, 'Difficoltà:');
      doc.setFontType("normal");
      doc.text(20, 137, this.title);
      doc.text(22, 142, this.autore);
      doc.text(27, 147, this.getDifficoltaLabel(this.difficulty));

      var y = 0;

      if(this.portiere) {
        doc.setFontType("bold");
        doc.text(5, 157, 'Tecnica podalica:');
        doc.text(5, 162, 'Tecnica di base:');
        doc.text(5, 167, 'Qualità tattiche:');
        doc.text(5, 172, 'Capacità motorie:');
        doc.setFontType("normal");
        doc.text(42, 157, this.getLabelSkills(this.tecnica_podalica, this.skills_portiere[0]));
        doc.text(39, 162, this.getLabelSkills(this.tecnica_di_base, this.skills_portiere[1]));
        doc.text(38, 167, this.getLabelSkills(this.qualita_tattiche, this.skills_portiere[2]));
        doc.text(42, 172, this.getLabelSkills(this.motor_skill, this.skills_portiere[3]));
        y = 182;

      } else {
        doc.setFontType("bold");
        doc.text(5, 157, 'Principi di gioco:');
        doc.text(5, 162, 'Componenti Tecniche:');
        doc.text(5, 167, 'Capacità motorie:');
        doc.setFontType("normal");
        doc.text(41, 157, this.getLabelSkills( this.game_principles, this.skills[0]));
        doc.text(52, 162, this.getLabelSkills( this.technical_component, this.skills[1]));
        doc.text(42, 167, this.getLabelSkills( this.motor_skill, this.skills[2]));
        y = 177;
      }

      doc.setFontType("bold");
      doc.text(152, 137, 'Numero giocatori:');
      doc.text(152, 142, 'Minuti Esercitazione:');
      doc.text(152, 147, 'Numero di serie:');
      doc.text(152, 152, 'Minuti di recupero:');
      doc.text(152, 157, 'Secondi di recupero:');
      doc.text(152, 162, 'Numero di recuperi:');
      doc.text(152, 167, 'Tempo totale:');
      doc.setFontType("normal");
      doc.text(189, 137, '' + this.num_player);
      doc.text(195, 142, '' + this.min_exs);
      doc.text(187, 147, '' + this.num_serie);
      doc.text(191, 152, '' + this.min_rec);
      doc.text(195, 157, '' + this.sec_rec);
      doc.text(193, 162, '' + this.num_rec);
      doc.text(181, 167, '' + this.calculate_tot_min + ' minuti');

      doc.setFontType("bold");
      doc.text(5, y, 'Misure del campo:');
      doc.setFontType("normal");
      doc.text(45, y, this.soccer_field_size);
      y += 10;

      doc.setFontType("bold");
      doc.text(5, y, 'Materiale:');
      doc.setFontType("normal");

      if(this.material_required != '') {
        y += 5;
        var textMater = doc.splitTextToSize(this.material_required, width - 10);
        doc.text(5, y, textMater);
        var heightMaterial = textMater.length * fontSize * doc.internal.getLineHeight() / ptsPerInch;
        y += heightMaterial;
      }
      y += 10;

      doc.setFontType("bold");
      doc.text(5, y, 'Descrizione:');
      doc.setFontType("normal");
      y += 5;
      var textDescr = doc.splitTextToSize(this.description, width - 10);
      doc.text(5, y, textDescr);

      doc.save('esercizio_' + this.exe_id + '.pdf');

    },

    eliminaEsercizio() {
      if(confirm("Confermi l'eliminazione dell'esercizio?")) {
        this.$http.get(this.$store.state.apiEndPoint + '/palestra/esercizio/delete/' + this.$route.params.id).then((response) => {

          if (response.ok)
          {
            this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            this.$router.push({ name: this.$route.meta.module + "_palestra_esercizi" });
          }
        }, (response) => {
        });
      }
    },

    clonaEsercizio() {
      if(confirm("Confermi la clonazione dell'esercizio?")) {
        this.$http.get(this.$store.state.apiEndPoint + '/palestra/esercizio/duplica/' + this.$route.params.id + '/' + this.rosaTeamId).then((response) => {

          if (response.ok)
          {
            this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            this.$router.push({ name: this.$route.meta.module + "_palestra_esercizi" });
          }
        }, (response) => {
        });  
      }
    },

    getUploadVideo() {
      this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', {params: { filters_date: this.filters_date }}).then((response) => {
        if (response.ok)
        {
          this.upload_video = response.data;
          this.upload_loading = true;
        }
      }, (response) => {

      });
    },
    showUpload() {
      this.show_upload = !this.show_upload;   
    },
    showUploaded() {
      this.show_uploaded = !this.show_uploaded;  
      if(this.upload_iframe == '') {
        this.getUploadVideo();
        this.getUploadIFrame();
      } 
    },

    getUploadIFrame() {
      this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
        if (response.ok) {
          this.upload_iframe = response.data;
        }
      }, (response) => {
      });
    },

    addMedia: function () {
      this.media.push({ tipo: 'foto', file: '', video: null });
    },

    removeMedia: function (item) {
      if(item.id) {
        this.media_remove.push(item.id);
      }
      var index = this.media.indexOf(item);
      this.media.splice(index, 1);
    },
  },

  created: function () {
    axios.get(`/palestra/opzioni/esercizi`)
    .then((res) => {
      this.facility_opt = res.data.facility;
      this.goal_opt = res.data.goal;
      this.tt_opt = res.data.tt;
      this.intensity_opt = res.data.intensity;

    }).catch((err) => {
    });
    this.$http.get(this.$store.state.apiEndPoint + '/headerpdf').then((response) => {
      if (response.ok)
      {
        this.header_pdf = response.data;    
      }
    }, (response) => {
    });
  },

  computed: {
    ...mapState(['rosaTeamId']),

    auth: function() {
      return Auth;
    }, 

    user: function() {
      return Auth.user;
    },   
  },

  watch: { 
    filters_date: function () {
      this.upload_loading = false;
      this.getUploadVideo();
    },
  },

  mounted()
  { 

   if(this.$route.params.id){
    this.loading = true;
    axios.get(`/palestra/esercizio/`+this.$route.params.id)
    .then((res) => {
      this.title = res.data.name;
      this.description = res.data.description;
      this.facility = res.data.id_facility ? res.data.id_facility : 0;
      this.goal = res.data.id_goal ? res.data.id_goal : 0;
      this.tt = res.data.id_tt ? res.data.id_tt : 0;
      this.intensity = res.data.id_intensity ? res.data.id_intensity : 0;
      this.stato  = res.data.stato;
      this.id_utente = res.data.id_utente;
      this.exe_id = res.data.id;
      this.autore = res.data.utente.nomeCompleto;
      this.media = res.data.media;
      this.media_remove = [];

      this.loading    = false;
    }).catch((err) => {
      this.loading = false;
    });

  }

},

filters: {
  formatDateHour: function (date) {
    var m = moment(date);
    if(m.isValid()) {
      return m.format('DD/MM/YYYY HH:mm'); 
    }
    return "";
  },
},
}
</script>
