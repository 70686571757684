<template id="utenti">
  <section class="utenti">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>Agenti</span>
        </div>
        <div class="content">
          <admin-table
          api-url="/admin/agenti"
          :columns="columns"
          :canDelete="false"
          :defaultFilter="false">
          <template slot="filters">
            <b-row>
              <b-col cols="3">
                <b-form-input v-model="filters.cognome" placeholder="Cognome" />
              </b-col>
            </b-row>
          </template>
        </admin-table>
      </div>
    </div>
  </div>
  <footerapp></footerapp>
</section>
</template>

<script>
var Auth = require('../../../auth.js');
import moment from 'moment';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default
  },

  data() {
    return {
      columns: [
      { key: 'id', label: 'ID', sortable: true },
      { key: 'avatar_url', label: 'Avatar'},
      { key: 'cognome', label: 'Cognome', sortable: true },
      { key: 'nome', label: 'Nome', sortable: true },
      { key: 'paese', label: 'Nazione', sortable: true },
      { key: 'luogo_nascita', label: 'Nato a', sortable: true },
      { key: 'data_nascita', label: 'Nato il', sortable: true, formatter: this.dateFormat },
      { key: 'indirizzo', label: 'Indirizzo', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'telefono', label: 'Telefono 1', sortable: true },
      { key: 'telefono2', label: 'Telefono 2', sortable: true },
      { key: 'count_gio', label: 'Giocatori', sortable: true },
      ],
      filters: {
        cognome: '',
      },
    };
  },
  methods: {
    dateFormat (value) {
      var m = moment.utc(value).local();
      if(m.isValid()) {
        return m.format('DD/MM/YYYY'); 
      }
      return "";
    },
  },
  watch: {
    filters: {
      handler() {
        this.$root.$emit('admin-table::filter', this.filters);
      },      
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051 !important;
}
</style>
