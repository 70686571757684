<template>
	<div>
		<b-card v-for="kpi,k in kpi_selected" class="mb-2">
			<b-row>
				<b-col cols="2">
					<span class="small text-muted">{{ getLabelKpi(kpi) }}</span>
				</b-col>
				<b-col cols="10">
					<E-Chart style="width: 480px; height: 200px" :option="graphOption[k]" v-if="level == 'team'"></E-Chart>
					<b-row v-else v-for="person_id in person_ids" class="border mb-2">
						<b-col>
							<div class="text-center mt-2 font-weight-bold" style="font-size:0.8rem;">{{ getPerson(person_id).cognome + ' ' + getPerson(person_id).nome }}</div>
							<E-Chart style="width: 480px; height: 100px" :option="graphOptionByPerson[person_id][k]"></E-Chart>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

import toolsHelper from '@/tools_helper.js'

import moment from 'moment';
import eChart from '@/components/layout/E-Chart/Base.vue';
export default {
	props: [ 'dataset', 'days', 'results', 'kpi_options', 'kpi_selected', 'level', 'person_ids', 'dataset_by_person', 'matchCalendar'],
	components: {
		'E-Chart': eChart,
	},
	data: function() {
		return {
		}
	},
	computed: {
		theDataset: function() {
			var dataset = this.dataset;
			return dataset;
		},

		theDatasetByPerson: function() {
			var dataset = this.dataset_by_person;
			return dataset;
		},

		graphOption: function() {

			var colors = [
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3',
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3'
				];

			var ds = this.theDataset;
			var options = [];

			_.each(this.kpi_selected, function(kpi, u) {

				var series = [];
				var serie = {
					data: _.map(ds, kpi),
					type: 'bar',
					name: this.getLabelKpi(kpi),
					label: {
						show: true,
						position: 'top'
					},
					color: [
						colors[u]
						]
				};
				series.push(serie);

				options.push({
					grid: {
						top: 25,
						left: 45,
						right: 45,
						bottom: 25
					},
					xAxis: {
						type: 'category',
						data: _.sortBy(_.map(ds, function(item) {
							var dayVal = this.matchCalendar[item.date];
							return item.date + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
						}.bind(this))),
						axisTick: {
							show: false
						},
					},
					yAxis: {
						type: 'value',
						splitNumber: 1,
						axisTick: {
							show: false
						}
					},
					series: series,
					legend: {
						show: false
					}, 
					tooltip: {
						trigger: 'item',
						formatter: function(params) {
							if(params.name.includes('(M)')) {
								var partita = this.matchCalendar[params.name.split(' ')[0]];
								return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span><br/><span>${partita}</span>`;
							} else {
								return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span>`;
							}
						}.bind(this)
					}
				});

			}.bind(this));

			return options;

		},

		graphOptionByPerson: function() {

			var colors = [
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3',
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3'
				];

			var optionsPerson = {};

			var index = 0;

			_.forEach(this.theDatasetByPerson, function(ds, person_id) {

				var options = [];

				_.each(this.kpi_selected, function(kpi, u) {

					var series = [];
					var serie = {
						data: _.map(ds, kpi),
						type: 'bar',
						name: this.getLabelKpi(kpi),
						label: {
							show: true,
							position: 'top'
						},
						color: [
							colors[index]
							]
					};
					series.push(serie);

					var max = this.getMaxValueKpi(kpi);

					options.push({
						grid: {
							top: 25,
							left: 45,
							right: 5,
							bottom: 25
						},
						xAxis: {
							type: 'category',
							data: _.sortBy(_.map(ds, function(item) {
								var dayVal = this.matchCalendar[item.date];
								return item.date + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
							}.bind(this))),
							axisTick: {
								show: false
							},
							axisLabel: {
							formatter: function (value) {
								if(value.includes('(M)')) {
									return '{match|' + value + '}'
								}
								else {
									return '{day|' + value + '}'
								}
							},
							rich: {
								match: {
									color: '#000',
									fontWeight: 'bold',
									fontSize: 11,
								},
								day: {
									color: '#999',
									fontSize: 11,
								}
							}
						}
						},
						yAxis: {
							type: 'value',
							splitNumber: 1,
							axisTick: {
								show: false
							},
							max: max
						},
						series: series,
						legend: {
							show: false
						}, 
						tooltip: {
							trigger: 'item',
							formatter: function(params) {
								if(params.name.includes('(M)')) {
									var partita = this.matchCalendar[params.name.split(' ')[0]];
									return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span><br/><span>${partita}</span>`;
								} else {
									return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span>`;
								}
							}.bind(this)
						}
					});

				}.bind(this));

				optionsPerson[person_id] = options;

				index++;

			}.bind(this));

			return optionsPerson;

		}
	},

	methods: {
		getMaxValueKpi: function (kpi) {
			var array_kpi = [];
			_.forEach(this.theDatasetByPerson, function(ds) {
				var value = _.max(_.map(ds, kpi));
				array_kpi.push(value);
			});	
			return _.round(_.ceil(_.max(array_kpi)));
		},
		getLabelKpi: function(kpi) {
			return this.kpi_options.find(item => item.value == kpi).text;
		},
		getPerson(person_id) {
			return this.results.raw_persone.find(item => item.id == person_id);
		}
	},
}

</script>