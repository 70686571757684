<template>
	<span v-if="!editing" @dblclick.prevent="startEditing"><slot></slot> {{ value.length > 0 ? value + ' ' : '' }}</span>
	<span v-else>
		<span class="w-full inline-block" contenteditable ref="box" @blur="endEditing" @keydown.enter="$refs.box.blur()" v-html="content"></span>
	</span>
</template>

<script>

export default {
	props: [ 'value' ],
	data: function() {
		return {
			editing: false,
			content: this.value
		}
	},
	watch: {
		value: {
			handler: function() {
				this.content = this.value;
			}
		}
	},
	methods: {
		startEditing: function() {
			if(this.isReading == true) return false;
			//console.log('Disable Workspace');
			this.editing = true;
			$('body').data('disabled-workspace', true);
			this.$nextTick(function() {
				this.$refs.box.focus();
			})
		},
		endEditing: function() {
			//console.log('Reinable Workspace');
			$('body').data('disabled-workspace', false);
			var content = this.$refs.box.innerText;
			this.$emit('input', content);			
			this.content = content;
			this.editing = false;
		}
	},
	watch: {
		editing: {
			handler: function() {
				if(this.editing == true) {
					
				} else {

				}
			}
		}
	}
};

</script>