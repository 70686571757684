<template id="radarchart">

	<canvas :id="radarid" :height="hchart" :width="wchart"></canvas>

</template>

<script>
export default {
	props: {

		radarid: {
			type: String,
			required: true

		},

		datachart: {
			type: Object,
			required: false
		},

		max: {
			type: Number,
			default: 10
		}, 

		min: {
			type: Number,
			default: 0
		},
		
		wchart: {
			type: Number,
			default: 630
		},

		hchart: {
			type: Number,
			default: 350
		}
	},

	mounted: function(){

		var ctx = document.getElementById(this.radarid);
		ctx.height = this.hchart;
		this.myChart = new Chart(ctx, {

		    type: 'radar',

		    data: this.datachart,

		    options: {
		    	responsive: false,
		    	maintainAspectRatio: false,
	            scale: {
	                // reverse: true,
	                ticks: {
	                  /*  beginAtZero: true,*/
	                    max: this.max,
	                    min: this.min,
	                }
	            }     
		    }

		});

	},
	watch: {
		datachart: function() {
			this.myChart.data.datasets = this.datachart.datasets;
			this.myChart.update();			
		},
	},

}
</script>
