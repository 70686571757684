<template>
	<div v-if="contratto_data">
		<headgiocatore :player_id="contratto_data.entity.persona_id">
			<b-btn-group v-if="contratto_data.entity.attivo == 1">
				<b-btn v-if="!contratto_data.entity.locked" size="sm" style="margin-right: 0" variant="outline-secondary" :to="{ name: 'contratti_edit', params: { id: contratto_data.entity.id } }">Modifica</b-btn>

				<b-btn @click.prevent="archiveContract" v-if="!contratto_data.entity.locked" size="sm" variant="outline-danger">Archivia</b-btn>
				
				<b-btn size="sm" variant="outline-secondary" @click.prevent="toggleLock(contratto_data.entity.id)"><em class="fa fa-lock" :class="{ 'fa-unlock': !contratto_data.entity.locked, 'fa-lock': contratto_data.entity.locked }"></em> {{ contratto_data.entity.locked ? 'Sblocca' : 'Blocca' }}</b-btn>

			</b-btn-group>
			<b-btn variant="outline-secondary" size="xs" @click.prevent="ripristinaContratto" v-else>Ripristina Contratto</b-btn>
		</headgiocatore>

		<div class="card border-0 rounded-0 mt-1">
			
			<div class="card-body">
				<div class="content">
					<b-tabs v-model="tabIndex" align="center" @activate-tab="changeTab">
						<b-tab disabled title="Riepilogo per Stagione"></b-tab>
						<b-tab v-for="season, k in seasons" :title="season.variabili_squadra?season.variabili_squadra.name:'Stagione Assente' + ' ('+(bonusBySeason[season.id] ? bonusBySeason[season.id].length : 0)+')'" class="py-0">
							<SeasonBonusDetails :contratto_data="contratto_data" :bonuses="bonusBySeason[season.id]" :season="season"></SeasonBonusDetails>
						</b-tab>
						<b-tab title="Riepilogo">

							<table class="table table-striped table-sm m-2">
								<thead>
									<tr>
										<th width="20%" class="text-right">Bonus</th>
										<th class="text-right" v-for="season,k in seasons">{{ (season.variabili_squadra ? season.variabili_squadra.name : 'Stagione Assente') }}</th>
										<th class="text-muted small text-right">Riepilogo</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="bonuses, group in bonusByName">
										<td class="text-right">{{ group }}</td>
										<td class="text-right" v-for="season,k in seasons">
											<div v-if="bonuses[season.id]">
												<span v-if="!bonuses[season.id].isBonusMaturated" class="text-muted small">Non Maturato</span>
												<span v-else class="text-success small">
													Maturato
													<span v-if="bonuses[season.id].multiplier_value > 1" class="text-muted">x {{ bonuses[season.id].multiplier_value }}</span>
												</span>
												<div class="small" :class="{ 'text-muted': !bonuses[season.id].isBonusMaturated }">
													{{ bonuses[season.id].importo | money }}
												</div>
												<div v-if="bonuses[season.id].multiplier_value > 1 && bonuses[season.id].isBonusMaturated">
													{{ bonuses[season.id].value | money }}
												</div>
											</div>
											<span v-else>&times;</span>
										</td>
										<td class="cell-riepilogo">{{ calculateTotal(bonuses) | money }}</td>
									</tr>
								</tbody>
								
								<tfoot style="border-top: 2px solid rgba(180,180,180)">
									<tr>
										<th class="text-right">Gran Totale</th>
										<td class="text-right" v-for="b in seasons">
											{{ bonusOf(b.id) + parseFloat(b.importo_lordo) | money }}
										</td>
										<th class="cell-riepilogo">
											{{ sumBy(seasons, 'importo_lordo') + contratto_data.analysis.amount | money }}
										</th>
									</tr>
								</tfoot>

								<tfoot style="border-top: 2px solid rgba(180,180,180)">
									<tr>
										<th class="text-right">Bonus Maturati</th>
										<td class="text-right" v-for="b in seasons">
											{{ bonusOf(b.id) | money }}
										</td>
										<th class="cell-riepilogo">
											{{ contratto_data.analysis.amount | money }}
										</th>
									</tr>
									<tr>
										<th class="text-right">Stipendio Fisso</th>
										<td class="text-right" v-for="b in seasons">
											{{ b.importo_lordo | money }}
										</td>
										<th class="cell-riepilogo">
											{{ sumBy(seasons, 'importo_lordo') | money }}
										</th>
									</tr>
								</tfoot>
							</table>
							<!-- <pre>{{bonusByName}}</pre> -->
							<!-- <b-row>
								<b-col cols="6"><pre>{{ contratto_data.analysis }}</pre></b-col>
								<b-col cols="6"><pre>{{ contratto_data.entity }}</pre></b-col>
							</b-row> -->
						</b-tab>
					</b-tabs>

				</div>
			</div>
		</div>
		
		<div v-if="pickedPayment">
			<ModalPayment :payment="pickedPayment" :contratto="contratto_data" @close="pickedPayment = false" @refresh="fetchContratto(contratto_data.entity.id)"></ModalPayment>
		</div>

	</div>
</template>

<style type="text/css">

.cell-riepilogo {
	background-color: #ffeb3b2e;
	text-align: right;
}

</style>

<script>

import _ from 'lodash'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
	props: [ 'contrattoId' ],
	components: {
		ContrattoAnagrafica: require('./ContrattoAnagrafica.vue').default,
		ModalPayment: require('./ModalPayment.vue').default,
		SeasonBonusDetails: require('./SeasonBonusDetails.vue').default,
		NotesHas: require('./../notes/has.vue').default,
		Bonus: require('./Bonus.vue').default,
		headgiocatore: require('../../layout/headgiocatore.vue').default,
		FontAwesomeIcon
	},
	computed: {
		seasons: function() {
			return _.orderBy(this.contratto_data.entity.periodi, ['variabili_squadra.inizio'],['asc']);
		},
		bonusBySeason: function() {
			return _.groupBy(this.contratto_data.analysis.bonuses, function(a) { return a.persona_contratto_periodo_id; });
		},
		bonusByName: function() {
			var grouped = _.groupBy(this.contratto_data.analysis.bonuses, function(a) { return a.name; });
			var out = _.mapValues(grouped, function(a) {
				return _.keyBy(a, function(e) { return e.persona_contratto_periodo_id });
			});
			return out;
		}
	},
	filters: {
		dt: function(a) {
			return moment(a).format('DD/MM/Y')
		},
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return '€ ' + formatter.format(v).replace('€', '');
		},
	},
	data: function() {
		return {
			tabIndex: 1,
			expanded: [],
			contratto_data: false,
			rules: false,
			hasModified: false,
			modifierCheck: false,
			picked: false,
			pickedData: false,
			pickedPayment: false,
			showFixedAmounts: false,
		}
	},
	watch: {
		'$route.query.season': {
			immediate: true,
			deep: true,
			handler: function() {
				this.tabIndex = parseInt(this.$route.query.season)
			}
		}
	},
	methods: {
		ripristinaContratto: function() {

			this.$bvModal.msgBoxConfirm("Intendi ripristinare il contratto?", {
				title: 'Ripristina Contratto',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Ok, Procedi',
				cancelTitle: 'Annulla',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(function(value) {
				if(value == true) {
					this.$http.get(this.$store.state.apiEndPoint + '/contratti/archive/'+this.contratto_data.entity.id).then(function(data) {
						this.fetchContratto(this.contratto_data.entity.id);
					});
				}
			}.bind(this));

			
		},
		archiveContract: function() {

			this.$bvModal.msgBoxConfirm("Confermi l'archiviazione del contratto? Il contratto potrà essere riattivato finché non sopraggiungerà un nuovo contratto.", {
				title: 'Archivia Contratto',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Ok, Procedi',
				cancelTitle: 'Annulla',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(function(value) {
				if(value == true) {
					this.$http.get(this.$store.state.apiEndPoint + '/contratti/archive/'+this.contratto_data.entity.id).then(function(data) {
						this.fetchContratto(this.contratto_data.entity.id);
					});
				}
			}.bind(this));

		},
		toggleLock: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/lock/'+id).then(function(data) {
				this.fetchContratto(id);
			});
		},
		bonusOf: function(season_id) {
			return _.sum(_.map(this.bonusBySeason[season_id], function(p) { if(p.value) return parseFloat(p.value); return 0; }));
		},
		sumBy: function(a,b) {
			return _.sum(_.map(a,function(i) { return parseFloat(i[b]) }));
		},
		calculateTotal: function(collection) {

			return _.sum(_.map(collection, function(i) {
				return parseFloat(i.value||0);
			}));

			return 0;
		},
		changeTab: function(a,b) {
			this.$router.push({ query: { season: a } });
		},
		isExpanded: function(k) {
			return this.expanded.indexOf(k) !== -1;
		},
		fetchContratto: function(id) {
			console.log('Im');
			this.contratto_data = false;
			this.modifierCheck = false;
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/ispect/'+id).then(function(data) {
			// this.$http.get(this.$store.state.apiEndPoint + '/contratto/'+id+'/analyze').then(function(data) {
				this.contratto_data = data.data;
				this.modifierCheck = JSON.stringify(data.data.entity);
			});
		},
		fetchRules: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/rules').then(function(data) {
				this.rules = data.data;
			})
		},
		pick: function(r) {
			this.pickedData = false;
			this.$http.get(this.$store.state.apiEndPoint + '/contratto/'+this.contratto_data.entity.entity.id+'/detail-new/' + r.entity.persona_contratto_bonus_id + '?rule_id=' + r.id).then(function(data) {
				this.pickedData = data.data;
			});
			this.picked = r;
		},
		unpick: function(r) {
			this.picked = false;
			this.pickedData = false;
			console.log(r);
		},
		pickPayment: function(i) {
			this.pickedPayment = i;
		}
	},
	created: function() {

		this.fetchRules();
		this.fetchContratto(this.contrattoId);

	}
};

</script>