<template>
	<div>

		<b-table-simple small>
			<b-thead>
				<b-tr>
					<b-th width="20%" class="small"></b-th>
					<b-th width="15%" class="small">Motivo</b-th>
					<b-th width="15%" class="small">Infortuni</b-th>
					<b-th width="15%" class="small">Malattie</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="player in sessione.setup.actity_check_preset.players">
					<b-td>
						<label>
							{{ getName(player.persona_id) }}
						</label>
					</b-td>
					<b-td class="small">
						<b-form-select size="sm" v-model="player.reason">
							<b-form-select-option :value="null" disabled>Scegli</b-form-select-option>
							<b-form-select-option value="Mantenimento">Mantenimento</b-form-select-option>
							<b-form-select-option value="Prevenzione">Prevenzione</b-form-select-option>
							<b-form-select-option value="Defaticante">Defaticante</b-form-select-option>
							<b-form-select-option value="Pregara">Pregara</b-form-select-option>
							<b-form-select-option value="Postgara">Postgara</b-form-select-option>
							<b-form-select-option value="Osteo pre gara">Osteo pre gara</b-form-select-option>
							<b-form-select-option value="Osteo pre training">Osteo pre training</b-form-select-option>
							<b-form-select-option value="Altro">Altro</b-form-select-option>
						</b-form-select>
					</b-td>
					<b-td class="small">
						<b-form-checkbox-group v-model="player.injuries">
							<b-form-checkbox v-for="injury, idx in injuriesOf(player.persona_id)" :value="injury" v-if="ux.showAllInjuries[player.persona_id] || (!ux.showAllInjuries[player.persona_id] && idx < 2)">{{ injury.data | formatDate }}
								<span>{{ injury.pathology ? injury.pathology.name : 'Missing' }}</span>
							</b-form-checkbox>
						</b-form-checkbox-group>
						<b-button v-if="injuriesOf(player.persona_id).length > 2" class="mt-2" variant="secondary" size="xs" @click="switchShowInjuries(player.persona_id)"><i :class="'fa fa-arrow-' + (ux.showAllInjuries[player.persona_id] ? 'up' : 'down')" aria-hidden="true"></i>
						{{ ux.showAllInjuries[player.persona_id] ? 'Mostra meno' : 'Mostra tutti'}}</b-button>
					</b-td>
					<b-td class="small">
						<b-form-checkbox-group v-model="player.illnesses">
							<b-form-checkbox v-for="illness, idx in illnessOf(player.persona_id)" :value="illness" v-if="ux.showAllIllnesses[player.persona_id] || (!ux.showAllIllnesses[player.persona_id] && idx < 2)">{{ illness.data | formatDate }}
								<span v-for="type in illness.illness_types">{{ type.name ? type.name : 'Missing' }}</span>
								<span v-for="type in illness.illness_other_types">{{ type.name ? type.name : 'Missing' }}</span>
							</b-form-checkbox>
						</b-form-checkbox-group>
						<b-button v-if="illnessOf(player.persona_id).length > 2" class="mt-2" variant="secondary" size="xs" @click="switchShowIllnesses(player.persona_id)"><i :class="'fa fa-arrow-' + (ux.showAllIllnesses[player.persona_id] ? 'up' : 'down')" aria-hidden="true"></i>
						{{ ux.showAllIllnesses[player.persona_id] ? 'Mostra meno' : 'Mostra tutti'}}</b-button>
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>

	</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	components: {
		SkillPicker: require('@/components/layout/Sessioni/Generica/SkillPicker.vue').default,
	},
	props: [ 'sessione', 'playersPreset', 'injuries', 'illnesses' ],
	created: function() {

		if(this.sessione.setup == null)
			this.$set(this.sessione, 'setup', {});

		if(this.sessione.setup.actity_check_preset == undefined) {
			this.$set(this.sessione.setup, 'actity_check_preset', {
				players: []
			});
		}

		this.sessione.setup.actity_check_preset == null;
		this.updateSetup();
		// this.getSkills();
	},
	watch: {
		'sessione.players': {
			deep: true,
			//immediate: true,
			handler: function(a,b) {
				if(b)
					this.updateSetup();
			}
		}
	},
	data: function(){
		return {
			ux: {
				showAllInjuries: {},
				showAllIllnesses: {},
			}
		}

	},
	methods: {
		getName: function(id) {
			return _.find(this.sessione.players, { id: id }) ? _.find(this.sessione.players, { id: id }).name : ''
		},
		updateSetup: function() {

			var alreadyIds = _.map(this.sessione.setup.actity_check_preset.players, 'persona_id');

			_.each(this.sessione.players, function(player) {

				var already = _.find(this.sessione.setup.actity_check_preset.players, { persona_id: player.id });
				if(!already) {
					this.sessione.setup.actity_check_preset.players.push({ persona_id: player.id, reason: '', injuries: [], illnesses: [] });
				}

			}.bind(this));

			var toRemove = [];
			_.each(alreadyIds, function(id) {
				if(_.map(this.sessione.players, 'id').indexOf(id) == -1) {
					toRemove.push(id);
				}
			}.bind(this));

			_.each(toRemove, function(a) {
				var key = _.find(this.sessione.setup.actity_check_preset.players, { persona_id: a });
				this.sessione.setup.actity_check_preset.players.splice(key, 1);
			}.bind(this));

		},
		switchShowInjuries: function(playerId) {
			if(this.ux.showAllInjuries[playerId] == undefined) {
				this.ux.showAllInjuries[playerId] = true;
				this.ux.showAllInjuries = Object.assign({}, this.ux.showAllInjuries);

			} else {
				this.ux.showAllInjuries[playerId] = !this.ux.showAllInjuries[playerId];
			}
		},
		switchShowIllnesses: function(playerId) {
			if(this.ux.showAllIllnesses[playerId] == undefined) {
				this.ux.showAllIllnesses[playerId] = true;
				this.ux.showAllIllnesses = Object.assign({}, this.ux.showAllIllnesses);
			} else {
				this.ux.showAllIllnesses[playerId] = !this.ux.showAllIllnesses[playerId];
			}
		},
		injuriesOf: function(pId) {
			return _.filter(this.injuries, function(inj) {
				return inj.persona_id == pId;
			}.bind(this));
		},
		illnessOf: function(pId) {
			return _.filter(this.illnesses, function(inj) {
				return inj.persona_id == pId;
			}.bind(this));
		}
	},
	computed: {
		
	},
	filters: {
		time: function(data) {
			return moment('2021-01-01 ' + data).format('HH:mm'); 
		},
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YY'); 
			}
			return date;
		}
	}
}
</script>