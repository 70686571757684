<template id=" acad_val_new">
	<section class="acad_val_new">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="academy">
			<div class="card-body p-3">
				<div class="row flex-items-xs-middle">
					<div class="col-5">
						<div class="media">
							<img v-bind:src="academy.logo_url" class="" height="80">
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{academy.nome}}</strong></h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="card-title">
					<span>{{ $i18n.t('Nuova valutazione') }}</span>
				</div>
				<div class="content">
					<b-row align-v="center">
						<b-col>
							<form @submit="onSubmit">
								<b-row align-v="center" class="mb-2">
									<b-col cols="3">
										{{ $i18n.t('Data') }}
									</b-col>
									<b-col cols="3">	
										<b-form-input v-model="form.data" type="date"></b-form-input>
									</b-col>
									<b-col>
									</b-col>
								</b-row>
								<b-row align-v="center" v-for="item, key in voti" class="mb-2">
									<b-col cols="3">
										{{ $i18n.t(item) }}
									</b-col>
									<b-col cols="1">	
										<b-form-select v-model="form.voti[key]" :options="options"></b-form-select>
									</b-col>
									<b-col>
										<b-form-input v-model="form.note[key]" :placeholder="$i18n.t('Note')"></b-form-input>
									</b-col>
								</b-row>
								<b-button type="submit" variant="primary" class="mt-1 text-uppercase">{{ $i18n.t('Salva') }}</b-button>
							</form>
						</b-col>
						<b-col cols="4">
							<radarchart radarid="radar_val" :datachart="radardatachart" :wchart="500" :hchart="278"></radarchart>
						</b-col>
					</b-row>
				</div>	
			</div>
		</div>


		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../../store/types'
export default {

	data: function() {
		return {
			academy: null,
			form: {
				academy_id: this.$route.params.id,
				data: null,
				voti: {
					1: 0,
					2: 0,
					3: 0,
					4: 0,
					5: 0,
					6: 0,
				},
				note: {
					1: '',
					2: '',
					3: '',
					4: '',
					5: '',
					6: '',
				},
			},
			voti: {
				1: this.$i18n.t('Utilizzo software'),
				2: this.$i18n.t('Interesse apprendimento'),
				3: this.$i18n.t('Qualità allenatori'),
				4: this.$i18n.t('Qualità giocatori'),
				5: this.$i18n.t('Struttura centro sportivo'),
				6: this.$i18n.t('Struttura organizzativa societaria'),
			},
			options: [{ value: 0, text: '1-10' }, 1,2,3,4,5,6,7,8,9,10],
		}
	},

	components: {
		headapp: require('../../../layout/headapp.vue').default,
		menusub: require('../../../layout/menusub.vue').default,
		footerapp: require('../../../layout/footerapp.vue').default,
		radarchart: require('../../../layout/charts/radar.vue').default
		,
	},

	created: function () {
		if(this.$route.params.id) {
			axios.get(`/academyita/societa/get/` + this.$route.params.id)
			.then((response) => {
				this.academy = response.data;
			}).catch((err) => {
				this.loading = false;
			});
		}

	},

	computed: {
		radardatachart: function() {
			var data = {
				labels: [],
				datasets: [{
					label: '',
					data: [],
					backgroundColor: 'rgba(255, 102, 0, 0.5)',
					borderColor: 'rgb(255, 102, 0)',
					pointBackgroundColor: 'rgb(255, 102, 0)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgb(255, 102, 0)',
				}]
			};
			for(var i in this.voti) {
				data.labels.push(this.voti[i]);
			}
			for(var i in this.form.voti) {
				data.datasets[0].data.push(this.form.voti[i]);
			}
			return data;	
		},
	},

	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.addValutazione();
		},

		addValutazione() {
			if(!this.form.data) {
				alert(this.$i18n.t('Devi inserire una data per la valutazione!'));
				return;
			}
			var array_keys = Object.keys(this.voti);
			for(var i in array_keys) {
				var key = array_keys[i];
				if(!this.form.voti[key]) {
					alert(this.voti[key] + ': ' + this.$i18n.t('voto obbligatorio') + '!');
					return;
				}
			}
			this.$http.post(this.$store.state.apiEndPoint + '/academyint/valutazione/add', this.form).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
					this.$router.push({ name: "academy_int_societa_valutazioni", params: { id: this.$route.params.id }});
					
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
