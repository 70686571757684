<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>{{ $i18n.t('Documenti') }} ({{ totalRows }})</span>
					</div>
					<div class="content">
						<b-row class="mb-3" align-v="center">
							<b-col md="2">
								<b-button variant="primary" @click="uploadPdf">{{ $i18n.t('Nuovo PDF') }}</b-button>	
							</b-col>
							<b-col md="1">
								{{ $i18n.t('Filtra per') }}:
							</b-col>
							<b-col md="2">
								<b-form-input v-model="filters.titolo" :placeholder="$i18n.t('Titolo')" />
							</b-col>
							<b-col md="3">
								<multiselect 
								v-model="filters.categorie"
								:multiple="true"
								:options="user.academyCats"
								:placeholder="$i18n.t('Categorie')"
								label="nome"
								track-by="id"/>
							</b-col>
							<b-col md="2">
								<b-form-select v-model="filters.stato" class="inline mr-3"> 
									<option value="">-- {{ $i18n.t('Stato') }} --</option>
									<option value="0">{{ $i18n.t('Bozza') }}</option>
									<option value="1">{{ $i18n.t('Pubblicato') }}</option>
									<option value="2">{{ $i18n.t('Archiviato') }}</option>
								</b-form-select>
							</b-col>
							<b-col md="2">
								<b-form-input type="date" v-model="filters.data" class="inline mr-3"></b-form-input>
							</b-col>
						</b-row>

						<!-- Main table element -->
						<b-table show-empty
						ref="pdfTable"
						stacked="md"
						:items="docs"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:sort-direction="sortDirection"
						@filtered="onFiltered"
						>
						<template #cell(stato)="row">{{row.value == 0 ? $i18n.t('Bozza') : (row.value == 1 ? $i18n.t('Pubblicato') : $i18n.t('Archiviato'))}}</template>
						<template #cell(created_at)="row">{{ row.value | formatDate }}</template>
						<template #cell(utente)="row">{{ row.value.cognome }}</template>
						<template #cell(categorie)="row">
							<span v-for="(item, index) in row.value">{{item.nome}}{{ index < (row.value.length - 1) ? ', ' : ''}}</span>
						</template>
						<template #cell(privato)="row">{{row.value == 1 ? 'Si' : 'No'}}</template>
						<template #cell(academies)="row">
							<span v-for="(item, index) in row.value">{{item.nome}} {{ index < (row.value.length - 1) ? ', ' : ''}}</span>
						</template>
						<template #cell(azioni)="row">
							<b-button size="sm" @click.stop="viewPdf(row.item.document)" variant="success">
								View <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
							</b-button>
							<b-button size="sm" @click.stop="info(row.item, row.index, $event.target)" class="mr-1" variant="primary">
								Edit <i class="fa fa-pencil" aria-hidden="true"></i>
							</b-button>
							<b-button size="sm" @click.stop="eliminaPdf(row.item.id)" class="mr-1">
								Canc <i class="fa fa-trash" aria-hidden="true"></i>
							</b-button>		
						</template>
					</b-table>

					<b-row>
						<b-col md="6" class="my-1">
							<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
						</b-col>
					</b-row>
					<!--b-row class="mt-3">
						<b-col>
							<em>Informazione: è possibile pubblicare un solo documento alla volta per categoria, gli altri saranno automaticamente archiviati. </em>
						</b-col>
					</b-row-->
					<b-modal id="modalInfo" ref="modalInfo" @hide="resetModal" :title="modalInfo.title" ok-only :ok-title="$i18n.t('Chiudi')">
						<b-row class="mb-3">
							<b-col cols="12"><p>{{ $i18n.t('Inserisci un titolo') }}:</p></b-col>
							<b-col cols="12">
								<b-form-input type="text" v-model="titolo"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="mb-3">
							<b-col cols="12"><p>{{ $i18n.t('Seleziona uno stato') }}:</p></b-col>
							<b-col cols="12">
								<b-form-radio-group v-model="stato">
									<b-form-radio value="0">{{ $i18n.t('Bozza') }}</b-form-radio>
									<b-form-radio value="1">{{ $i18n.t('Pubblicato') }}</b-form-radio>
									<b-form-radio value="2">{{ $i18n.t('Archiviato') }}</b-form-radio>
								</b-form-radio-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12"><p>{{ $i18n.t('Seleziona una o più categorie a cui associare il pdf') }}:</p></b-col>
							<b-col cols="4">
								<b-form-checkbox v-model="allSelected" @change="toggleAll" :indeterminate="indeterminate" style="float:left">
									{{ allSelected ? $i18n.t('Nessuna') : $i18n.t('Tutte') }}
								</b-form-checkbox>
							</b-col>
							<b-col cols="4" v-for="cat in user.academyCats">
								<b-form-checkbox v-model="ids_categorie" :value="cat.id">
									{{ cat.nome }}
								</b-form-checkbox>
							</b-col>
						</b-row>
						<b-row class="my-3">
							<b-col cols="12"><p>{{ $i18n.t('Seleziona visibilità') }}:</p></b-col>
							<b-col cols="12">
								<b-form-radio-group v-model="privato">
									<b-form-radio value="0">{{ $i18n.t('Pubblico') }}</b-form-radio>
									<b-form-radio value="1">{{ $i18n.t('Privato') }}</b-form-radio>
								</b-form-radio-group>
							</b-col>
							<b-col cols="12" v-if="privato == 1">
								<b-form-checkbox-group v-model="academy_ids">
									<b-form-checkbox v-for="item in academy" :value="item.id"> <img :src="item.logo_url" style="width: 30px;" /> {{ item.nome }} </b-form-checkbox>
								</b-form-checkbox-group>
							</b-col>
						</b-row>
						<b-button variant="primary" class="mt-3" @click.prevent="modificaPdf(modalInfo.id)">{{ $i18n.t('Salva') }}</b-button>
					</b-modal>

					<b-modal id="uploadModal" ref="uploadModal" :title="$i18n.t('Aggiungi pdf')" ok-only :ok-title="$i18n.t('Chiudi')">	
						<b-row class="mb-2">
							<b-col cols="12">
								<file-upload class="btn btn-success" v-model="files" :headers="headers" @input-file="inputFile" ref="upload" :accept="accept" :post-action="postAction">
									{{ $i18n.t('Seleziona pdf') }}
								</file-upload>
							</b-col>
							<b-col cols="12" class="my-2">
								<b-form-input type="text" v-model="titolo" :placeholder="$i18n.t('Inserisci un titolo')"></b-form-input>
							</b-col>
							<b-col cols="12" class="my-2">
								<b-form-checkbox v-model="pubblica">
									{{ $i18n.t('Pubblica direttamente') }}
								</b-form-checkbox>
							</b-col>
							<b-col cols="12"><p>{{ $i18n.t('Seleziona una o più categorie a cui associare il pdf') }}:</p></b-col>
							<b-col cols="12">
								<b-row>
									<b-col cols="4">
										<b-form-checkbox v-model="allSelected" @change="toggleAll" :indeterminate="indeterminate" style="float:left">
											{{ allSelected ? $i18n.t('Nessuna') : $i18n.t('Tutte') }}
										</b-form-checkbox>
									</b-col>
									<b-col cols="4" v-for="cat in user.academyCats">
										<b-form-checkbox v-model="ids_categorie" :value="cat.id">
											{{ cat.nome }}
										</b-form-checkbox>
									</b-col>
								</b-row>	
							</b-col>
						</b-row>
						<b-row class="my-3">
							<b-col cols="12"><p>{{ $i18n.t('Seleziona visibilità') }}:</p></b-col>
							<b-col cols="12">
								<b-form-radio-group v-model="privato">
									<b-form-radio value="0">{{ $i18n.t('Pubblico') }}</b-form-radio>
									<b-form-radio value="1">{{ $i18n.t('Privato') }}</b-form-radio>
								</b-form-radio-group>
							</b-col>
							<b-col cols="12" v-if="privato == 1">
								<b-form-checkbox-group v-model="academy_ids">
									<b-form-checkbox v-for="item in academy" :value="item.id"> <img :src="item.logo_url" style="width: 30px;" /> {{ item.nome }} </b-form-checkbox>
								</b-form-checkbox-group>
							</b-col>
						</b-row>	
						<div v-for="(file, index) in files" :key="file.id">
							<p> {{file.name}}</p>
							<div class="progress" v-if="file.active || (file.progress !== '0.00' && file.progress !== '100.00')">
								<div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
							</div>
						</div>					
						<b-button variant="primary" class="mt-3" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="startUpload">Upload</b-button>	
					</b-modal>
					<b-modal id="pdfModal" ref="pdfModal" title="Pdf" ok-only :ok-title="$i18n.t('Chiudi')" size="lg">
						<div v-if="loading" align="center" style="width:100%">
							<img src="/assets/varie/loading.gif" style="width: 150px;" />
						</div>
						<div class="text-center" v-if="!loading">
							<div class="row align-items-center"><div class="col-8 text-center"><button type="button" class="btn btn-link" @click="prevPage">PREV</button><button type="button" class="btn btn-link" @click="nextPage">NEXT</button></div><div class="col-4 text-left" style="font-size:1rem;">{{currentPagePdf}} di {{totalPagesPdf}}</div></div>
							<canvas id="pdf-canvas" width="700"></canvas>
						</div>
					</b-modal>

				</div>
			</div>
		</div>	
	</div>

	<footerapp></footerapp>

</section>
</template>

<script>

var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import FileUpload from 'vue-upload-component'
import moment from 'moment'
import axios from 'axios';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		FileUpload,
	},

	data: function(){
		return {
			ids_categorie: [],
			pubblica: false,
			stato: 0,
			titolo: '',
			files: [],
			headers: {
				'tokenauth': 'Bearer ' + Auth.user.api_token
			},
			postAction: this.$store.state.apiEndPoint + '/academyita/upload/pdf', 
			accept: 'application/pdf',
			fields: [
			{ key: 'id', label: 'ID' },
			{ key: 'titolo', label: this.$i18n.t('Documento') },
			{ key: 'document', label: this.$i18n.t('Titolo') },
			{ key: 'stato', label: this.$i18n.t('Stato'), 'class': 'text-center' },
			{ key: 'created_at', label: this.$i18n.t('Data'), 'class': 'text-center' },
			{ key: 'utente', label: this.$i18n.t('Utente') },
			{ key: 'categorie', label: this.$i18n.t('Categorie') },
			{ key: 'privato', label: this.$i18n.t('Privato'), 'class': 'text-center'},
			{ key: 'academies', label: 'Academy' },
			{ key: 'azioni', label: this.$i18n.t('Azioni') }
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			pageOptions: [ 5, 10, 15 ],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			modalInfo: { title: '', item: null, id: 0 },
			pdfDoc: null,
			totalPagesPdf: 0,
			currentPagePdf: 0,
			loading: false,
			filters: {
				stato: '',
				data: '',
				categorie: [],
				titolo: '',
			},
			allSelected: false,
			indeterminate: false,
			privato: 0,
			academy: [],
			academy_ids: [],
		}
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/academyita/get/list/societa/1').then((response) => {
			if (response.ok)
			{
				this.academy = response.data;    
			}
		}, (response) => {
		});
	},

	computed: {
		user: function() {
			return Auth.user;
		},
	},
	methods: {
		docs(ctx) {
			return axios.get('/academyita/get/pdf/1', {
				params: {
					page: ctx.currentPage,
					per_page: ctx.perPage || 15,
					sort_by: ctx.sortBy,
					sort_direction: ctx.sortDesc ? 'DESC' : 'ASC',
					filter: ctx.filter,
					filters: this.filters,
				},
			}).then((res) => {
				if (!res.data) return [];

				const data = res.data.data || [];
				this.totalRows = res.data.total;
				this.currentPage = res.data.current_page;

				return data;
			}).catch(() => []);
		},
		uploadPdf() {
			this.files = [];
			this.ids_categorie = [];
			this.academy_ids = [];
			this.pubblica = false;
			this.privato = 0;
			this.titolo = '';
			this.$refs.uploadModal.show();
		},
		startUpload() {
			if(this.files.length > 0) {
				this.files[0].data = {
					'ids_categorie' : this.ids_categorie,
					'pubblica' : this.pubblica,
					'titolo' : this.titolo,
					'academy' : 1,
					'privato': this.privato,
					'academy_ids' : this.academy_ids,
				};
				this.$refs.upload.active = true;
			}
		},
		inputFile(newFile, oldFile) {
			if (newFile && oldFile) {
				if (newFile.success && !oldFile.success) {
					this.$refs.uploadModal.hide();
					this.$store.commit(types.ALERTS_PUSH, { msg: newFile.response.message, status: 1 });
					this.$refs.pdfTable.refresh();
				}
			}
		},
		viewPdf: function(pdf) {
			this.loading = true;
			this.$refs.pdfModal.show();
			this.$http.get(this.$store.state.apiEndPoint + '/pdfacademy/' + pdf).then((response) => {
				if (response.ok)
				{
					var pdf_data = atob(response.data);
					PDFJS.getDocument({ data: pdf_data }).then((pdf_doc) => {
						this.totalPagesPdf = pdf_doc.numPages;
						this.pdfDoc = pdf_doc;
						this.currentPagePdf = 0;
						this.showPage(1);
						this.loading = false;
					});
				}
			}, (response) => {
			});
		},
		showPage(page_no) {
			this.currentPagePdf = page_no;
			this.pdfDoc.getPage(page_no).then((page) => {
				var desiredWidth = document.getElementById('pdf-canvas').width;
				var viewport = page.getViewport(1);
				var scale = desiredWidth / viewport.width;
				var scaledViewport = page.getViewport(scale);
				document.getElementById('pdf-canvas').height = scaledViewport.height;
				var renderContext = {
					canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
					viewport: scaledViewport
				};
				page.render(renderContext).then(() => {
				});
			});
		},
		prevPage() {
			if(this.currentPagePdf != 1) {
				this.showPage(this.currentPagePdf - 1);
			}    
		},
		nextPage() {
			if(this.currentPagePdf != this.totalPagesPdf) {
				this.showPage(this.currentPagePdf + 1);
			}    
		},
		info (item, index, button) {
			this.ids_categorie = [];
			this.academy_ids = [];
			this.modalInfo.title = item.document;
			this.modalInfo.item = item;
			this.modalInfo.id = item.id;
			for(var i in item.categorie) {
				var cat = item.categorie[i];
				this.ids_categorie.push(cat.id);
			}
			this.stato = item.stato;
			this.titolo = item.titolo;
			this.privato = item.privato;
			for(var i in item.academies) {
				var acad = item.academies[i];
				this.academy_ids.push(acad.id);
			}
			this.$root.$emit('bv::show::modal', 'modalInfo', button)
		},
		resetModal () {
			this.modalInfo.title = '';
			this.modalInfo.item = null;
			this.modalInfo.id = 0;
		},
		modificaPdf (id) {
			var form = {
				stato: this.stato,
				ids_categorie: this.ids_categorie,
				titolo: this.titolo,
				privato: this.privato,
				academy_ids: this.academy_ids,
			};
			this.$http.post(this.$store.state.apiEndPoint + '/academyita/edit/pdf/' + id, form).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            	this.$refs.pdfTable.refresh();
            	this.$refs.modalInfo.hide();     	
            }
        }, (response) => {  
        	if (response.status == 422) {
        		this.errors = response.data;
        		for (var index in this.errors) {
        			this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
        		}
        	}
        });
		},
		eliminaPdf(id) {
			if(confirm(this.$i18n.t('Confermi l\'eliminazione del file pdf ?'))) {
				this.$http.get(this.$store.state.apiEndPoint + '/academyita/delete/pdf/' + id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$refs.pdfTable.refresh();			
					}
				}, (response) => {
				});
			}
		},
		filtra() {
			this.$refs.pdfTable.refresh();
		},
		onFiltered (filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		toggleAll(checked) {
			if(checked) {
				this.ids_categorie = []; 
				for(var i in Auth.user.academyCats) {
					this.ids_categorie.push(Auth.user.academyCats[i].id);
				}
			} else {
				this.ids_categorie = [];
			}
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
	watch: {
		filters: {
			handler() {
				this.filtra();
			},      
			deep: true,
		},
		ids_categorie(newVal, oldVal) {

			if (newVal.length === 0) {
				this.indeterminate = false
				this.allSelected = false
			} else if (newVal.length === Auth.user.academyCats.length) {
				this.indeterminate = false
				this.allSelected = true
			} else {
				this.indeterminate = true
				this.allSelected = false
			}
		}
	}

}
</script>
