<template>
	<div>

		<VideoManager :readonly="readonly" uploadType="cutter" cutterSource="scouting" :skipRosa="true" :entity="partita.entita" :entityId="partita.entita_id"></VideoManager>
		
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

// this.$http.get(this.$store.state.apiEndPoint + '/calendar/aree').then(function(data) {

export default {
	components: {
		VideoManager: require('@/components/layout/video/manager.vue').default
	},
	props: [ 'partita', 'readonly' ],
	mixins: [ pageBase ],
	data: function() {
		return {
			entita: null,
			entita_id: null
		}
	},
	watch: {
		partita: {
			immediate: true,
			handler: function() {
				if(this.partita.entita_id != undefined) {
					this.entita = 'Partite';
					this.entita_id = this.partita.entita_id;
				} else if(this.partita.id != undefined) {
					this.entita = 'Partite';
					this.entita_id = this.partita.id;
				} else {
					this.findMatch(this.partita.matchId);
				}
			}
		}
	},
	methods: {
		findMatch: function(partitaId) {
			this.$http.get(this.$store.state.apiEndPoint + '/partite-scouting/get/' + partitaId).then(function(data) {

				if(data.data.id != undefined) {
					this.entita_id = data.data.id;
					this.entita = 'Partite';
				}

			});
		}
	}
}

</script>