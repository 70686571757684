<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>{{ cartella ? cartella.nome : 'Cartella'}} ({{ totalRows }})</span>
					</div>
					<div class="content">
						<b-row class="mb-3" align-v="center">
							<b-col md="2">
								<b-button variant="primary" :to="{ name: 'romalab_video_new', params: {id_cartella: $route.params.id }}" v-if="auth.isUserEnable('romalab_video_mng')">Nuovo Video</b-button>	
							</b-col>
							<b-col md="1">
								Filtra per:
							</b-col>
							<b-col md="2">
								<b-form-input v-model="filters.titolo" placeholder="Titolo" />
							</b-col>
							<b-col md="3">
								<multiselect 
								v-model="filters.categorie"
								:multiple="true"
								:options="user.romalabCats"
								placeholder="-- Categorie --"
								label="name"
								track-by="id"/>
							</b-col>
							<b-col md="2">
								<b-form-select v-model="filters.stato" class="inline mr-3"> 
									<option value="">-- Stato --</option>
									<option value="0">Bozza</option>
									<option value="1">Pubblicato</option>
									<option value="2">Archiviato</option>
								</b-form-select>
							</b-col>
							<b-col md="2">
								<b-form-input type="date" v-model="filters.data" class="inline mr-3"></b-form-input>
							</b-col>
						</b-row>

						<!-- Main table element -->
						<b-table show-empty
						ref="videoTable"
						stacked="md"
						:items="videos"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:sort-direction="sortDirection"
						@filtered="onFiltered"
						>
						<template #cell(thumb)="row">
							<b-img :src="row.value != '' ? row.value : '/assets/varie/missing.jpg'" width="100"/>
						</template>
						<template #cell(stato)="row">{{row.value == 0 ? 'Bozza' : (row.value == 1 ? 'Pubblicato' : 'Archiviato')}}</template>
						<template #cell(created_at)="row">{{ row.value | formatDate }}</template>
						<template #cell(utente)="row">{{ row.value.cognome }}</template>
						<template #cell(categorie)="row">
							<span v-for="(item, index) in row.value">{{item.name}}{{ index < (row.value.length - 1) ? ', ' : ''}}</span>
						</template>
						<template #cell(privato)="row">{{row.value == 1 ? 'Si' : 'No'}}</template>
						<template #cell(utenti)="row">
							<span v-for="(item, index) in row.value">{{item.cognome}}{{ index < (row.value.length - 1) ? ', ' : ''}}</span>
						</template>
						<template #cell(azioni)="row">
							<b-button size="sm" @click.stop="playVideo(row.item)" variant="success">
								Play <i class="fa fa-play" aria-hidden="true"></i>
							</b-button>
							<b-button size="sm" :to="{ name: 'romalab_video_edit', params: {id: row.item.id, id_cartella: row.item.id_cartella }}" class="mr-1" variant="primary">
								Edit <i class="fa fa-pencil" aria-hidden="true"></i>
							</b-button>
							<b-button size="sm" @click.stop="eliminaVideo(row.item.id)" title="Elimina Video" class="mr-1">Canc <i class="fa fa-trash" aria-hidden="true"></i></b-button>
						</template>
					</b-table>

					<b-row>
						<b-col md="6" class="my-1">
							<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
						</b-col>
					</b-row>
				</div>
			</div>
		</div>	
		<b-modal id="videoModal" size="xl" ref="videoModal" :title="videoTitle" class="fs-modal" @hidden="onHiddenVideo" @shown="onShowVideo" ok-only ok-title="Chiudi">
			<div>
				<video
				id="video-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
		</div>
	</b-modal>
</div>

<footerapp></footerapp>

</section>
</template>

<script>

var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import FileUpload from 'vue-upload-component'
import moment from 'moment'
import axios from 'axios';
import videojs from 'video.js';
import 'videojs-errors';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		FileUpload,
	},

	data: function(){
		return {
			cartella: null,
			cartelle: [],
			ids_categorie: [],
			stato: 0,
			titolo: '',
			cartella_selected: 0,
			fields: [
			{ key: 'id', label: 'ID' },
			{ key: 'titolo', label: 'Titolo' },
			{ key: 'thumb', label: 'Video' },
			{ key: 'stato', label: 'Stato', 'class': 'text-center' },
			{ key: 'created_at', label: 'Data', 'class': 'text-center' },
			{ key: 'utente', label: 'Utente' },
			{ key: 'categorie', label: 'Categorie' },
			{ key: 'privato', label: 'Privato', 'class': 'text-center'},
			{ key: 'utenti', label: 'Staff' },
			{ key: 'azioni', label: 'Azioni' }
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			pageOptions: [ 5, 10, 15 ],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			loading: false,
			filters: {
				stato: '',
				data: '',
				categorie: [],
				titolo: '',
			},
			allSelected: false,
			indeterminate: false,
			videoTitle: '',
			video_url: '',
			errors_player: {
				4: {
					headline: 'Video non disponibile',
					type: '',
					message: 'Il video non è stato ancora transcodificato.<br/>Riprova più tardi.',
				}
			}
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
	},
	created() {
		this.$http.get(this.$store.state.apiEndPoint + '/romalab/get/cartella/' + this.$route.params.id).then((response) => {
			if (response.ok)
			{
				this.cartella = response.data;
			}
		}, (response) => {
		});

		this.$http.get(this.$store.state.apiEndPoint + '/romalab/get/cartelle/video').then((response) => {
			if (response.ok)
			{
				this.cartelle = response.data;
			}
		}, (response) => {
		});
	},
	methods: {
		videos(ctx) {
			return axios.get('/romalab/get/video/cartella/' + this.$route.params.id, {
				params: {
					page: ctx.currentPage,
					per_page: ctx.perPage || 15,
					sort_by: ctx.sortBy,
					sort_direction: ctx.sortDesc ? 'DESC' : 'ASC',
					filter: ctx.filter,
					filters: this.filters,
				},
			}).then((res) => {
				if (!res.data) return [];

				const data = res.data.data || [];
				this.totalRows = res.data.total;
				this.currentPage = res.data.current_page;

				return data;
			}).catch(() => []);
		},
		filtra() {
			this.$refs.videoTable.refresh();
		},
		onFiltered (filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		toggleAll(checked) {
			if(checked) {
				this.ids_categorie = []; 
				for(var i in Auth.user.romalabCats) {
					this.ids_categorie.push(Auth.user.romalabCats[i].id);
				}
			} else {
				this.ids_categorie = [];
			}
		},
		eliminaVideo(id) {
			if(confirm("Confermi l'eliminazione del video ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/romalab/video/elimina/' + id).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$refs.videoTable.refresh();			
					}
				}, (response) => {
				});
			}
		},
		playVideo(item) {
			this.videoTitle = item.titolo;
			this.video_url = item.video;
			this.$refs.videoModal.show();
		},
		onShowVideo() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
				this.$video.errors({
					"errors": this.errors_player,
				});
			}
			this.$video.src({type: 'video/mp4', src: this.video_url });
			this.$video.play();
		},
		onHiddenVideo() {
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;	
		},
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
	watch: {
		filters: {
			handler() {
				this.filtra();
			},      
			deep: true,
		},
		ids_categorie(newVal, oldVal) {

			if (newVal.length === 0) {
				this.indeterminate = false
				this.allSelected = false
			} else if (newVal.length === Auth.user.romalabCats.length) {
				this.indeterminate = false
				this.allSelected = true
			} else {
				this.indeterminate = true
				this.allSelected = false
			}
		}
	}

}
</script>
