<template id="profilo">
    <section class="profilo">
        <headapp></headapp>
        <div class="container-fluid m-y-1">
            <div class="card title_page">
                <div class="card-body">
                    <div class="card-title">
                        <span>Profilo</span>
                    </div>
                </div>
            </div>  
            <div class="content_page">
                <b-row>
                    <b-col cols="8">
                        <b-form @submit="aggiornaProfilo">
                            <picture-input ref="pictureUser" @change="onAvatarChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="avatar"></picture-input> 
                            <b-form-group id="nomeGroup" horizontal label="Nome" :label-cols="3" label-for="nome" :feedback="errors.nome" :state="errors.nome ? false : null">
                                <b-form-input id="nome" type="text" v-model="user.nome" :state="errors.nome ? false : null"></b-form-input>
                            </b-form-group>
                            <b-form-group id="cognomeGroup" horizontal label="Cognome" :label-cols="3" label-for="cognome" :feedback="errors.cognome" :state="errors.cognome ? false : null">
                                <b-form-input id="cognome" type="text" v-model="user.cognome" :state="errors.cognome ? false : null"></b-form-input>
                            </b-form-group>
                            <b-form-group id="emailGroup" horizontal label="Email" :label-cols="3" label-for="email" :feedback="errors.email" :state="errors.email ? 'invalid' : null">
                                <b-form-input id="email" type="email" v-model="user.email" :state="errors.email ? false : null"></b-form-input>
                            </b-form-group>
                            <b-form-group id="usernameGroup" horizontal label="Username" :label-cols="3" label-for="username">
                                <b-form-input id="username" type="text" v-model="user.username" plaintext></b-form-input>
                            </b-form-group>
                            <b-form-group id="passwordGroup" horizontal label="Nuova password" :label-cols="3" label-for="password" :feedback="errors.password" v-if="!user.auth_ldap">
                                <b-form-input id="password" type="password" v-model="user.password" :state="errors.password ? false : null"></b-form-input>
                            </b-form-group>
                            <b-form-group id="password2Group" horizontal label="Ripeti password" :label-cols="3" label-for="password2" :feedback="errors.password_confirmation" v-if="!user.auth_ldap">
                                <b-form-input id="password2" type="password" v-model="password" :state="errors.password_confirmation ? false : null"></b-form-input>
                            </b-form-group>
                            <b-form-group id="indirizzoBroup" horizontal label="Indirizzo" :label-cols="3" label-for="indirizzo" :feedback="errors.indirizzo" 
                            description="Utilizza il 'Google Maps search box' a lato per settare l'indirizzo">
                            <b-form-input id="indirizzo" type="text" v-model="indirizzo" :state="errors.indirizzo ? false : null"></b-form-input>
                        </b-form-group>
                        <b-button type="submit" variant="primary">Salva</b-button>      
                    </b-form>
                </b-col>
                <b-col cols="4">
                    <input ref="autocomplete" 
                    placeholder="Cerca indirizzo..."
                    onfocus="value = ''"
                    type="text" style="width:100%"/>
                    <div id="map" ref="map" style="height: 90%;"></div>
                </b-col>
            </b-row>
        </div>
    </div>
    <footerapp></footerapp>
</section>
</template>

<script>
var Auth = require('../../auth.js');
import * as types from '../../store/types'
export default {
    data () {
        return {
            image_user: '',
            file_avatar: '',
            password: '',
            errors: [],
            indirizzo: '',
        }
    },

    components: {
        headapp:    require('../layout/headapp.vue').default,
        footerapp:  require('../layout/footerapp.vue').default,
        pictureInput: require('../layout/picture_input.vue').default,
    },

    methods:{
        aggiornaProfilo(evt) {
            evt.preventDefault();
            var formData = new FormData();
            formData.append("user", JSON.stringify(this.user));
            formData.append("conferma_password", this.password);
            formData.append("file_avatar", this.file_avatar);
            formData.append("indirizzo", this.indirizzo);
            this.$http.post(this.$store.state.apiEndPoint + '/update/user/' + this.user.id, formData).then((response) => {
                if (response.ok)
                {
                    this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
                    this.errors = [];
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                }
            }, (response) => {  
                this.errors = response.data;
                for (var index in this.errors) {
                    this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
                }
            });
        },
        onAvatarChange(e) {
            if (this.$refs.pictureUser.file) {
                this.file_avatar = this.$refs.pictureUser.file;
            } else {
                console.log('FileReader Player API not supported: use the <form>')
            }
        },
        locateAddress: function() {
            this.geocoder = new window.google.maps.Geocoder();
            this.geocoder.geocode({ address: this.indirizzo}, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.$map.setCenter(results[0].geometry.location);
                    this.$map.setZoom(17);
                    this.$marker.setPosition(results[0].geometry.location);
                    this.$marker.setVisible(true);
                }
            });
        }
    },
    mounted() {
        this.autocomplete = new window.google.maps.places.Autocomplete(
          (this.$refs.autocomplete),
          {types: ['geocode']}
          );
        this.$map = new window.google.maps.Map(this.$refs.map, {
          zoom: 4,
          center: { lat: 41.871940, lng: 12.567380 },
          streetViewControl: false,
          mapTypeControl: false,
        });

        this.$marker = new window.google.maps.Marker({
          position: { lat: Number(41.871940), lng: Number(12.567380) },
          map: this.$map,
        });
        this.$marker.setVisible(false);

        this.autocomplete.addListener('place_changed', () => {
          let place = this.autocomplete.getPlace();

          const bounds = new window.google.maps.LatLngBounds();

          if (!place.geometry) {
            return;
            }
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            this.$marker.setPosition(place.geometry.location);
            this.$marker.setVisible(true);

            this.$map.fitBounds(bounds);
            this.indirizzo = place.formatted_address;
        }); 
        this.locateAddress(); 
    },
    computed: {
        user: function() {
            return Auth.user;
        },
        avatar: function() {
            if(this.image_user != "") {
                return this.image_user;
            } 
            return '';
        },
    },
    created: function () {
        if(this.user.avatar != null && this.user.avatar != "") {
            this.image_user = this.user.avatar_url;
        }
        this.indirizzo = this.user.indirizzo;
    }
}
</script>
