<template id="gruppi">
  <section class="gruppi">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>Tipi di notifiche</span>
        </div>
        <div class="content">
          <admin-table
          api-url="/admin/notifiche/tipi"
          :columns="columns"
          :createFormData="newData"
          :editFormData="editData"
          :canDelete="true"
          :defaultFilter="false"
          @resetCreateFormData="resetCreateFormData"
          @editError="showError"
          @createError="showError"
          @showModal="onShowModal">
          <template slot="create-modal-body" slot-scope="row">
            <b-form-group label="Nome">
              <b-form-input type="text" placeholder="Nome" v-model="newData.nome" />
            </b-form-group>
            <b-form-group label="Canali">
              <multiselect
              v-model="newData.canali"
              :multiple="true"
              :options="canali_options"
              placeholder="Seleziona canali"/>
            </b-form-group>
            <b-form-group label="Configurazione (JSON Object)">
              <b-form-textarea :rows="10" v-model="newData.config"></b-form-textarea>
            </b-form-group>
          </template>
          <template slot="edit-modal-body" slot-scope="row">
            <b-form-group label="Nome">
              <b-form-input type="text" placeholder="Nome" v-model="editData.nome" />
            </b-form-group>
            <b-form-group label="Canali">
              <multiselect
              v-model="editData.canali"
              :multiple="true"
              :options="canali_options"
              placeholder="Seleziona canali"/>
            </b-form-group>
            <b-form-group label="Configurazione (JSON Object)">
              <b-form-textarea :rows="10" v-model="editData.config"></b-form-textarea>
            </b-form-group>
          </template>
        </admin-table>
      </div>
    </div>
  </div>
  <footerapp></footerapp>
</section>
</template>

<script>
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default
  },

  data() {
    return {
      columns: [
      { key: 'id', label: 'ID' },
      { key: 'nome', label: 'Nome' },
      { key: 'canali', label: 'Canali'},
      { key: 'config', label: 'Config'},
      { key: 'actions', label: 'Azioni' },
      ],
      newData: {
        nome: null,
        canali: null,
        config: null,
      },
      editData: {
        nome: null,
        canali: null,
        config: null,
      },
      canali_options: [],
    };
  },
  methods: {
    onShowModal(row) {
      this.editData.nome = row.item.nome;
      this.editData.canali = JSON.parse(row.item.canali);
      this.editData.config = row.item.config;
    },
    resetCreateFormData() {
      this.newData.nome = null;
      this.newData.canali = null;
      this.newData.config = null;
    },
    showError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(types.ALERTS_PUSH, { msg: err.response.data.error, status: 0 });
      }
    },
  },
  created: function() {
    axios.get('/admin/notifiche/canali').then((res) => {
      this.canali_options = res.data;
    });
  },
};
</script>

<style lang="scss">

.pac-container {
  z-index: 1051 !important;
}
</style>
