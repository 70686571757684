<template>
	<div>

		<b-form-group label="Data">
			<clock v-model="event.date"></clock>
		</b-form-group>
		<b-form-group label="Home">
			<b-form-select v-model.number="event.home_id">
				<b-form-select-option :value="null">>> Seleziona <<</b-form-select-option>
				<b-form-select-option v-for="team in stage.teams" :value="team.id">{{ team.name }}</b-form-select-option>
			</b-form-select>
		</b-form-group>
		<b-form-group label="Away">
			<b-form-select v-model.number="event.away_id">
				<b-form-select-option :value="null">>> Seleziona <<</b-form-select-option>
				<b-form-select-option v-for="team in stage.teams" :value="team.id">{{ team.name }}</b-form-select-option>
			</b-form-select>
		</b-form-group>

		<span class="small text-muted" v-if="!event.home_id || !event.away_id">Scegli le squadre per gestire il <strong>risultato</strong> e lo <strong>stato</strong>.</span>
		
		<b-overlay v-else :show="!event.home_id || !event.away_id">
			<b-row>
				<b-col>
					<b-form-group label="Home Res">
						<b-input min="0" type="number" v-model="event.home_result"></b-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Away Res">
						<b-input min="0" type="number" v-model="event.away_result"></b-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group label="Status">
				<b-form-select v-model="event.status">
					<b-form-select-option :value="0">Pianificata (planned)</b-form-select-option>
					<b-form-select-option :value="1">In Corso (live)</b-form-select-option>
					<b-form-select-option :value="2">Conclusa (played)</b-form-select-option>
					<b-form-select-option :value="3">Completata (completed)</b-form-select-option>
				</b-form-select>
			</b-form-group>

		</b-overlay>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	components: {
		Clock: require('@/components/redazione/components/clock.vue').default,
	},
	props: [
		'event', 'match', 'competition', 'stage'
	]
}

</script>