<template>
  <div>
    <h5>Totali: {{ totalRows }}</h5>
    <b-row class="mb-2">
      <b-col cols="10">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
        <b-form-input v-if="defaultFilter" v-model="filter" placeholder="Filtra" class="inline mr-3" />
        <slot name="filters" />
      </b-col>
      <b-col v-if="!!createFormData" class="new-button">
        <b-btn variant="success" v-b-modal="newModalId">Nuovo</b-btn>
        <b-btn v-if="sendMail" variant="secondary" @click="showSendMailModal">Invia Mail <i class="fa fa-paper-plane" aria-hidden="true"></i></b-btn>
      </b-col>
    </b-row>
    <b-row class="row-table">
      <b-col>
        <b-table
        ref="adminTable"
        hover bordered outlined
        :fixed="fixed"
        :items="fetch"
        :fields="columns"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc="sortDesc"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter">
        <template #cell(selected_id)="row">
          <b-form-checkbox :value="row.item.id" v-model="selected_ids"></b-form-checkbox>
        </template>
        <template #cell(id)="data">
          <div><strong>{{data.value}}</strong></div>
          <span>{{data.item.username}}</span>
        </template>
        <template #cell(avatar_url)="data">
          <b-img :src="data.value" width="80"/>
        </template>
        <template #cell(utenti_gruppi)="data">
          <div v-for="item in data.value">{{item.gruppo.nome}} <div v-if="item.categoria" class="small text-muted">({{item.categoria.name}})</div></div>
        </template>
        <template #cell(permessi)="data">
          <div class="small" v-for="item in data.value">{{item.info ? item.info.info : item.nome}}</div>
        </template>
        <template #cell(squadre_societa)="data">
          <div class="small" v-for="item in data.value">{{item.squadra.categoria.name}}</div>
        </template>
        <template #cell(squadra)="data">
          <div class="small">{{ data.value.categoria.name }}</div>
        </template>
        <template  #cell(referenti)="data">
          <b-row align-v="center" v-for="item in data.value" class="border px-1 py-3">
            <b-col cols="3">
              {{ item.nomeCompleto }}
            </b-col>
            <b-col>
              <b-form-checkbox-group v-model="item.ref_team.notifiche">
                <b-form-checkbox v-for="notifica in item.notifiche_opt" :value="notifica.tipo">{{ notifica.label }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-col>
            <b-col cols="1">
              <b-button size="sm" variant="success" title="Salva notifiche" @click="salvaNotificheReferente(item.ref_team)">Salva</b-button>
            </b-col>
          </b-row>
          <!--div v-for="item in data.value">{{ item.nomeCompleto }}</div-->
        </template>
        <template #cell(paese)="data">
          <div v-if="data.value">
            <flag v-if="data.value" :country="data.value.sigla" :size="16"></flag> {{ data.value.paese }}
          </div>
        </template>
        <template #cell(count_gio)="data">
          {{ data.item.relazionanti_count }}
        </template>
        <template v-if="!noActions" #cell(actions)="row">
          <b-button-group>
            <b-btn size="sm" v-if="!!editFormData" @click="showEditModal(row)">Modifica</b-btn>
            <b-btn size="sm" variant="outline-secondary" :to="{ name: 'admin_utenti_permessi', params: { persona_id: row.item.persona_id } }" v-if="!!editFormData && row.item.persona_id">🔐</b-btn>
            <b-btn size="sm" v-if="canDelete" variant="primary" @click="showDeleteModal(row)">Elimina</b-btn>
            <b-btn size="sm" v-if="viewDetails && row.item.properties !=''" @click="showViewModal(row)" title="Dettagli">
              <i class="fa fa-search" aria-hidden="true"></i>
            </b-btn>
          </b-button-group>
        </template>
        <template #cell(utente)="data">
          {{ data.item.nomeCompleto }}
        </template>
        <template #cell(notificheUtenti)="data">
          <b-row align-v="center" v-for="item in getNotificheUtenti(data.item.notifiche_utenti)" class="border px-1 py-3">
            <b-col cols="3">
              {{ item.nome }}
            </b-col>
            <b-col cols="3">
              {{ item.canali }}
            </b-col>
          </b-row>
        </template>
        <template #cell(email)="data">
          <div v-if="data.item.persona">
           {{ data.item.persona.contatti && data.item.persona.contatti.filter((item) => item.contatto.tipo == 'email').length > 0 ? data.item.persona.contatti.filter((item) => item.contatto.tipo == 'email')[0].contatto.valore : ''}}
         </div>
         <div v-else>
          {{ data.item.contatti && data.item.contatti.filter((item) => item.contatto.tipo == 'email').length > 0 ? data.item.contatti.filter((item) => item.contatto.tipo == 'email')[0].contatto.valore : ''}}
        </div>
      </template>
      <template #cell(telefono)="data">
        {{ data.item.contatti && data.item.contatti.filter((item) => item.contatto.tipo == 'telefono').length > 0 ? data.item.contatti.filter((item) => item.contatto.tipo == 'telefono')[0].contatto.valore : ''}}
      </template>
      <template #cell(telefono2)="data">
        {{ data.item.contatti && data.item.contatti.filter((item) => item.contatto.tipo == 'telefono').length > 1 ? data.item.contatti.filter((item) => item.contatto.tipo == 'telefono')[1].contatto.valore : ''}}
      </template>
    </b-table>
  </b-col>
</b-row>
<b-row>
  <b-col cols="10">
    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
    <b-form-input v-if="defaultFilter" v-model="filter" placeholder="Filtra" class="inline mr-3" />
    <slot name="filters" />
  </b-col>
  <b-col v-if="!!createFormData" class="new-button">
    <b-btn variant="success" v-b-modal="newModalId">Nuovo</b-btn>
    <b-btn v-if="sendMail" variant="secondary" @click="showSendMailModal">Invia Mail <i class="fa fa-paper-plane" aria-hidden="true"></i></b-btn>
  </b-col>
</b-row>

<b-modal v-if="!!editFormData" :id="modalId" centered size="lg" @ok="edit" title="Modifica" ok-title="Salva" cancel-title="Chiudi">
  <slot name="edit-modal-body" :row="currentRow" />
</b-modal>

<b-modal v-if="canDelete" :id="deleteModalId" centered @ok="deleteRecord" title="Elimina" ok-title="Si" cancel-title="No">
  Sei sicuro di voler eliminare questo record?
</b-modal>

<b-modal v-if="!!createFormData" :id="newModalId" centered size="lg" @ok="create" title="Nuovo" ok-title="Salva" cancel-title="Chiudi">
  <slot name="create-modal-body" />
</b-modal>

<b-modal v-if="viewDetails" :id="viewModalId" centered size="lg" title="Dettagli" ok-only ok-title="Chiudi">
  <slot name="view-modal-body" :row="currentRow" />
</b-modal>
<b-modal id="checkPersonaModal" ref="checkPersonaModal" title="Attenzione!" ok-only ok-title="Chiudi">
  <b-row>
    <b-col class="border-right pr-2">
      <p>Sono già presenti su AS Roma System i seguenti individui corrispondenti ai campi inseriti:</p>
      <hr/>
      <div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
        <div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="!persona.utente ? selezionaPersona(persona.id) : ''">
          <div class="col-md-3 my-1 mr-3" style="text-align: center;">
            <img :src="persona.avatar_url" height="100"/>
          </div>
          <div class="col-md-6 my-1" style="font-size:0.85rem">
            <h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
            <div class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </div>
            <div class="text-muted">{{ persona.nascita_data | formatDate }}</div>
            <div class="text-muted" v-if="persona.utente">username: {{ persona.utente.username }}</div>
            <div v-if="persona.utente">
              <span v-for="gruppo in persona.utente.gruppi" class="text-muted"> {{ gruppo.nome }} </span> 
            </div>
            <div v-if="persona.contatti">
              <div v-for="contatto in persona.contatti" class="text-muted">{{ contatto.contatto.tipo + ': ' + contatto.contatto.valore}}</div>
            </div>
            <div v-if="persona.indirizzi">
              <div v-for="indirizzo in persona.indirizzi" class="text-muted">{{ indirizzo.indirizzo.tipo + ': ' + indirizzo.indirizzo.valore}}</div>
            </div>
            <div class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli &&
              persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</div>
              <div class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }}</div>     
            </div>
            <div class="col-md-2 my-1 align-self-center" style="text-align: center;">
              <img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
            </div>
          </div>
        </div>
        <p>Come vuoi procedere?</p>
        <p>- Seleziona un'anagrafica tra quelle sopra disponibili se ritieni corrisponda all'utente che vuoi inserire (non puoi selezionare anagrafiche che hanno già un account associato!)</p>
        <p><strong>oppure</strong></p>
        <div>- Premi su <b-button variant="link" class="py-0" @click="submitCreate()"><strong>SALVA</strong></b-button> per forzare l'inserimento di una nuova anagrafica</div>
      </b-col>
    </b-row>
  </b-modal>
</div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'adminTable',

  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    default: [],
    },
    noActions: {
      type: Boolean,
    default: false,
    },
    editFormData: {
      type: Object,
    },
    createFormData: {
      type: Object,
    },
    canDelete: {
      type: Boolean,
    default: true,
    },
    defaultFilter: {
      type: Boolean,
    default: true,
    },
    viewDetails: {
      type: Boolean,
    default: false,
    },
    fixed: {
      type: Boolean,
    default: false,
    },
    sendMail: {
      type: Boolean,
    default: false,
    },
  },

  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      isBusy: false,
      totalRows: 0,
      perPage: 15,
      currentPage: 1,
      filter: '',
      modalId: `modal-${(new Date()).getTime()}`,
      tableId: `table-${(new Date()).getTime()}`,
      deleteModalId: `modal-${(new Date()).getTime()}-delete`,
      newModalId: `modal-${(new Date()).getTime()}-create`,
      viewModalId: `modal-${(new Date()).getTime()}-view`,
      currentRow: null,
      additionalFilters: null,
      persone: [],
      selected_ids: [],
    };
  },

  methods: {
    fetch(ctx) {
      var filters = null;
      if(this.additionalFilters) {
        filters = _.cloneDeep(this.additionalFilters);
        if(filters.utenti) {
          filters.utenti = filters.utenti.map(item => ({
          id: item.id,
          }));
        }
      }
      return axios.get(this.apiUrl, {
        params: {
          page: ctx.currentPage,
          per_page: ctx.perPage || 15,
          sort_by: ctx.sortBy,
          sort_direction: ctx.sortDesc ? 'DESC' : 'ASC',
          filter: ctx.filter,
          additional_filters: filters,
        },
      }).then((res) => {
        if (!res.data) return [];

        const data = res.data.data || [];
        this.totalRows = res.data.total;
        this.currentPage = res.data.current_page;

        return data;
      }).catch(() => []);
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    showEditModal(row) {
      this.$emit('showModal', row);
      this.currentRow = row;
      this.$root.$emit('bv::show::modal', this.modalId);
    },

    showDeleteModal(row) {
      this.currentRow = row;
      this.$root.$emit('bv::show::modal', this.deleteModalId);
    },

    showViewModal(row) {
      this.$emit('showModal', row);
      this.currentRow = row;
      this.$root.$emit('bv::show::modal', this.viewModalId);
    },
    showSendMailModal() {
      this.$emit('showSendMailModal', this.selected_ids);
    },

    edit() {
      if (!this.currentRow) return;

      if (this.$listeners && this.$listeners.edit) {
        this.$emit('edit', this.currentRow);
        return;
      }

      if (!this.editFormData) return;

      axios.put(`${this.apiUrl}/${this.currentRow.item.id}`, {
        data: this.editFormData,
      }).then(() => {
        this.currentRow = null;
        this.$refs.adminTable.refresh();
      }).catch((err) => {
        this.$emit('editError', err);
        this.currentRow = null;
        this.$refs.adminTable.refresh();
      });
    },

    deleteRecord() {
      if (!this.currentRow) return;

      if (this.$listeners && this.$listeners.deleteRecord) {
        this.$emit('deleteRecord', this.currentRow);
        return;
      }

      axios.delete(`${this.apiUrl}/${this.currentRow.item.id}`).then(() => {
        this.currentRow = null;
        this.$refs.adminTable.refresh();
      }).catch((err) => {
        this.$emit('deleteError', err);
        this.currentRow = null;
        this.$refs.adminTable.refresh();
      });
    },

    create() {
      if (this.$listeners && this.$listeners.createRecord) {
        this.$emit('createRecord');
        return;
      }

      if (!this.createFormData) return;

      if(this.apiUrl == '/admin/utenti') {
        this.checkBeforeSubmit();
      } else {
        axios.post(this.apiUrl, {
          data: this.createFormData,
        }).then(() => {
          this.$refs.adminTable.refresh();
          this.$emit('resetCreateFormData');
        }).catch((err) => {
          this.$emit('createError', err);
          this.$refs.adminTable.refresh();
        });
      }
    },

    checkBeforeSubmit() {
      this.persone = [];
      this.createFormData.persona_id = null;
      this.$http.post(this.$store.state.apiEndPoint + '/persona/search', this.createFormData).then((response) => {
        if (response.ok)
        {
          this.persone = response.data;
          if(this.persone.length > 0) {
            this.$refs.checkPersonaModal.show();  
          } else {
            this.submitCreate(); 
          }
        }
      }, (response) => {

      });
    },

    submitCreate() {
      this.$refs.checkPersonaModal.hide();
      axios.post(this.apiUrl, {
        data: this.createFormData,
      }).then(() => {
        this.$refs.adminTable.refresh();
        this.$emit('resetCreateFormData');
      }).catch((err) => {
        this.$emit('createError', err);
        this.$refs.adminTable.refresh();
      });
    },

    selezionaPersona: function (persona_id) {
      this.createFormData.persona_id = persona_id;
      this.submitCreate();
    },

    salvaNotificheReferente(referente) {
      axios.put('/admin/update/notifiche/referente', referente).then((res) => {
        this.referenti_options = res.data;
      }).then(() => {
        this.$refs.adminTable.refresh();
      }).catch((err) => {
        this.$emit('editError', err);
        this.$refs.adminTable.refresh();
      });
    },

    getNotificheUtenti(notificheUtenti) {
     var array = []; 
     var array_ids = [];  
     for(var i in notificheUtenti) {
      var notificaUtente = notificheUtenti[i];
      var item = null;
      if(!array_ids.includes(notificaUtente.notifica_tipo_id)) {
        array_ids.push(notificaUtente.notifica_tipo_id);
        item = {
          nome: notificaUtente.notifica_tipo.nome,
          canali: [notificaUtente.canale],
        }
        array.push(item);
      } else {
        var index = array_ids.indexOf(notificaUtente.notifica_tipo_id);
        item = array[index].canali.push(notificaUtente.canale);
      }
    }
    return array;
  }
},

mounted() {
  this.$root.$on('admin-table::filter', _.debounce((filters) => {
    this.additionalFilters = filters;
    this.$refs.adminTable.refresh();
  }, 500));
},
beforeDestroy() {
  this.$root.$off('admin-table::filter');
},
components: {
  flag:     require('../../layout/flag.vue').default, 
},
filters: {
  formatDate: function (date) {
    var m = moment(date);
    if(m.isValid()) {
      return m.format('DD/MM/YY'); 
    }
    return "";
  }
},
};
</script>

<style lang="scss" scoped>
.row.row-table {
  width: 100%;
}

input.inline, select.inline {
  display: inline-block;
  width: auto;
  min-width: 200px;
}

.pagination {
  display: inline-flex;
  margin-right: 20px;
}

.new-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;

  .btn {
    padding: .375rem 1.50rem;
  }
}
</style>
