<template>
	<div v-if="player" :style="playerStyle" class="d-flex align-items-center" @click="asClick" @mouseenter="isHover" @mouseleave="isLeave">
		<div v-if="selection == true">
			<slot></slot>
		</div>
		<select v-if="editing == true && player && player.level >= 0" v-model="player.level">
			<option v-for="a in 5" :value="a-1">{{a-1}}</option>
		</select>
		<div style="margin-right: 3px">
			<strong>{{ player.surname }}</strong>
			<small v-if="player.name"> {{ player.name | n }}</small>
		</div>
		<div style="margin-right: 3px;">
			<div style="border-radius: 4px; display: inline-block; font-size: 85%; padding: 0px 2px; border: 1px solid rgba(0,0,0,.1);">{{ player.anno | y }}</div>
			<b-link v-if="!blocked" style="margin-left: 2px" :id="'gio_' + player.id" href="#"><em class="fa fa-info-circle"></em></b-link>
		</div>
		<div style="margin-right: 3px; font-size: 85%; text-align: right" class="flex-grow-1">
			{{ player.carriera.squadra.name }}
		</div>
		<div class="ml-auto" v-if="ux.hover && !selection && !editing">
			<button style="border: 1px solid red; border-radius: 2px; font-size: 10px" @click.prevent="$emit('splice')">&times;</button>
		</div>


		<b-popover :target="'gio_' + player.id" placement="top" triggers="hover" style="z-index: 999999" @show="loadDetails">
			<div class="color-rosso bg-light p-1 mb-1"><strong>{{ player.surname.toUpperCase() + ' ' + player.name }}</strong></div>
			<div style="width:250px">
				<div v-if="loading_val" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 50px;" /></div>
				<b-row v-if="!loading_val">
					<b-col cols="3">
					</b-col>
					<b-col cols="2">
					</b-col>
					<b-col class="border text-center bg-light" title="Current">
						<strong>C</strong>
					</b-col>
					<b-col class="border text-center bg-light" title="Potential">
						<strong>P</strong>
					</b-col>
					<b-col class="border text-center color-rosso bg-light" title="Grade">
						<strong>G</strong>
					</b-col>
				</b-row>
				<b-row v-for="val in valutazioni">
					<b-col :cols="val.is_media ? '5' : '3'" :class="'border-bottom ' + (val.is_media ? 'color-rosso text-right' : '')">
						<span :style="(val.is_media ? 'font-weight:bold;' : '')">{{ val.label }}</span>
					</b-col>
					<b-col cols="2" class="border-bottom text-right" v-if="!val.is_media" title="Valutazioni">
						<span style="font-size:0.7rem;">({{ val.value }} val.)</span>
					</b-col>
					<b-col :class="'border text-center ' + (val.is_media ? val.current_bg : '')" title="Current">
						<span :style="(val.is_media ? 'font-weight:bold;' : '')">{{ val.current }}</span>
					</b-col>
					<b-col :class="'border text-center ' + (val.is_media ? val.potential_bg : '')" title="Potential">
						<span :style="(val.is_media ? 'font-weight:bold;' : '')">{{ val.potential }}</span>
					</b-col>
					<b-col :class="'border text-center ' + (val.is_media ? val.grade_bg : '')" title="Grade">
						<strong>{{ val.grade }}</strong>
					</b-col>
				</b-row>

			</div>
		</b-popover>


	</div>
</template>

<style type="text/css">

</style>

<script>

import _ from 'lodash';
import moment from 'moment';
import * as types from '../../../store/types'

export default {
	props: [ 'player', 'editing', 'blocked', 'last', 'selection' ],
	created: function() {
		if(this.player && !this.player.level)
			this.$set(this.player, 'level', 0);
	},
	filters: {
		n: function(a) {
			//if(a && a.length > 0)
				return a[0] + '.';
			return ''
		},
		y: function(a) {
			return moment(a).format('YY')
		}
	},
	data: function() {
		return {
			loading_val: null,
			valutazioni: null,
			val_max: null,
			ux: {
				hover: false,
				panel: 2,
				hideNull: true,
			}
		}
	},
	watch: {
		
	},
	computed: {
		playerStyle: function() {
			var s = [];

			var opacity = this.ux.hover ? 1 : 0.5;

			if(this.player && this.player.level) {

				if(this.player.level == 0)
					s.push('background-color: rgba(100,100,100,' + opacity + ')');
				else if(this.player.level == 1)
					s.push('background-color: rgba(23,162,184,' + opacity + ')');
				else if(this.player.level == 2)
					s.push('background-color: rgba(40,167,69,' + opacity + ')');
				else if(this.player.level == 3)
					s.push('background-color: rgba(255,193,7,' + opacity + ')');
				else if(this.player.level == 4)
					s.push('background-color: rgba(220,53,69,' + opacity + ')');

			} else
				s.push('background-color: rgba(180,180,180,' + opacity + ')');

			if(this.last == true) {
				s.push('border-bottom-left-radius: 3px');
				s.push('border-bottom-right-radius: 3px');
			}

			s.push('padding: 0.1rem 0.25rem');

			return s.join(';');
		}
	},
	methods: {
		loadDetails: function() {

			this.loading_val = true;
			this.valutazioni = [];
			this.val_max = 0;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/valutazioni/giocatore/' + this.player.id).then(function(response) {

				this.valutazioni = response.data;
				for(var i in this.valutazioni) {
					var value = this.valutazioni[i].value;
					if(value > this.val_max) {
						this.val_max = value;
					}
				}
				this.loading_val = false;

			});



		},
		asClick: function() {
			if(this.selection) {			
				this.$emit('select');
			}
		},
		isHover: function() {
			if(this.blocked == true) return true;
			this.ux.hover = true;
		},
		isLeave: function() {
			if(this.blocked == true) return true;
			this.ux.hover = false;
		}
	}
}

</script>