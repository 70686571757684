<template id="view_giocatore">
	<section class="giocatore">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card" v-if="rosa">
				<div class="card-body">

					<div class="row align-items-center">
						<div class="col">
							<div class="media">
								<img v-bind:src="rosa.avatar_url" class="" height="100">
								<div class="media-body align-self-center ml-3">
									<h3 class="text-primary mb-0"><strong><a :href="'#/medical/player/' + $route.params.id">{{ rosa.cognome }} {{ rosa.nome }}</a></strong></h3>
									<small class="text-muted">{{ rosa.last_carriera_sportiva.ruolo_ideale && rosa.last_carriera_sportiva.ruolo_ideale.length > 0 ? rosa.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</small>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">

					<div class="row">
						<div class="col-10">	
							<b-table striped show-empty
							ref="injuriesTable"
							:items="injuries"
							:fields="fields"
							>
							<template #cell(data)="row"><a href="javascript:;" @click="selectInjury(row.item.id)">{{ row.value | formatDate }}</a></template>
							<template #cell(return)="row">{{ row.value | formatDate }}</template>
							<template #cell(reinjury)="row">{{ row.value ? reinjury_label[row.value] : 'No'}}</template>
							<template #cell(pathology)="row">{{ row.value ? row.value.name : 'N/D' }}</template>
						</b-table>
					</div>
					<div class="col-2">
						<b-button variant="link" class="w-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;height: 200px;" @click="newInjury">
							<div class="text-center">
								<p class="mb-0"><i class="fa fa-plus-circle text-muted fa-5x"></i></p>
								<strong class="text-uppercase text-muted">NEW INJURY</strong>
							</div>
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';

export default {
	data: function() {
		return {
			rosa: null,
			injuries: [],
			reinjury_label: [ 'No', 'Reinjury', 'Subsequent new injury', 'Subsequent local injury', 'Exacerbation'], 
			fields: [
				{ key: 'data', label: 'Date of Injury' },
				{ key: 'return', label: 'Date of return' },
				{ key: 'reinjury', label: 'Second Injury' },
				{ key: 'pathology', label: 'Type of Injury' },
			],
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,
	},

	created: function () {
		this.getInjuries();
	},
	watch: {
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 
	methods: { 
		getInjuries() {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/injuries/player/' + this.$route.params.id).then((response) => {
				if (response.ok) {
					this.rosa = response.data.rosa;
					this.injuries = response.data.injuries;
				}
			}, (response) => {
			});
		},
		newInjury() {
			this.$router.push({ name: "medical_injury_new", params: { id: this.$route.params.id } });
		},
		selectInjury(id) {
			this.$router.push({ name: "medical_injury", params: { id: this.$route.params.id, injury_id: id } });	
		},
	},
	computed: {
	}
}
</script>