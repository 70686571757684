<template>
	<div>
		<label>Nome</label>
		<b-form-input v-model="fase.nome" type="text"></b-form-input>
		<label>Identificativo</label>
		<b-form-select v-model="fase.entity.identificativo" :required="true">
			<b-form-select-option disabled :value="null">Seleziona un identificativo</b-form-select-option>
			<b-form-select-option value="generico">Generico</b-form-select-option>
			<b-form-select-option value="1">1 Controllo</b-form-select-option>
			<b-form-select-option value="2">2 Controllo</b-form-select-option>
			<b-form-select-option value="3">3 Controllo</b-form-select-option>
			<b-form-select-option value="4">4 Controllo</b-form-select-option>
			<b-form-select-option value="5">5 Controllo</b-form-select-option>
			<b-form-select-option value="6">6 Controllo</b-form-select-option>
			<b-form-select-option value="7">7 Controllo</b-form-select-option>
			<b-form-select-option value="8">8 Controllo</b-form-select-option>
			<b-form-select-option value="9">9 Controllo</b-form-select-option>
			<b-form-select-option value="10">10 Controllo</b-form-select-option>
			<b-form-select-option value="infortunio">Infortunio</b-form-select-option>
		</b-form-select>
		<!--b-row>
			<b-col>
				<label>Durata</label>
				<b-form-input :disabled="disabled" v-model="fase.durata" type="time"></b-form-input>
			</b-col>
			<b-col>
				<label>Durata effettiva</label>
				<b-form-input :disabled="disabled" v-model="fase.durata_reale" type="time"></b-form-input>
			</b-col>
		</b-row-->
		<!--<label>Note della Fase</label>
		<b-textarea :disabled="disabled" v-model="fase.entity.nota" rows="4"></b-textarea>-->

	</div>
</template>

<script>
import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [faseMixin],
	props: [ 'sessione', 'glossario', 'fase', 'fisioterapia_glo_options', 'medical_regions', 'medical_regions_map', 'disabled' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	components: {
		treedbody: require('@/components/layout/3dbody.vue').default,
	},
}
</script>