<template id="rosa">

	<section class="rosa">

		<headapp></headapp>
		<menusub></menusub>
		
		<b-card title="Analizza Sessioni">

			<div v-if="manifest">
				
				<QueryBuilder :manifest="manifest" :query="query" v-for="query in queries"></QueryBuilder>

				<hr class="mt-5" />

				<b-button @click.prevent="addQuery">Aggiungi</b-button>

				<hr />

			</div>


			<pre>{{ payload }}</pre>
			<code>{{ categoriaId }}</code>
			<b-button @click.prevent="fetchManifest">Refresh Manifest</b-button>
			<pre>{{manifest}}</pre>

		</b-card>

		<footerapp></footerapp>

	</section>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	components: {
		'QueryBuilder': require('@/components/layout/reports/QueryBuilderAnalizzaSessioni.vue').default
	},
	data: function() {
		return {
			manifest: null,
			queries: []
		}
	},
	created: function() {
		this.fetchManifest();
	},
	computed: {
		payload: function() {

			return {
				categoria_id: this.categoriaId,
				query: this.queries
			}

		}
	},
	watch: {
		categoriaId: {
			handler: function() {
				this.fetchManifest();
			}
		}
	},
	methods: {
		addQuery: function() {

			this.queries.push({});

		},
		fetchManifest: function() {

			this.$http.get(this.$store.state.apiEndPoint + '/sessioni-statistiche/manifest/' + this.categoriaId).then(function(data) {
				this.manifest = data.data;
			});

		}
		// sessioni-statistiche/analizza
		/*
		
		{
			sessione: { fasgloid: }
			sessioneEstensione: .....
			fasi: { Fase_Esercizio: ...., Fase_Palestra: ..... },
			activityCheck: ....  <contesto persone in fase/activity check>
			persone: ... <contesto persone in sessione>
			skills: ....
			fasiForm: .... <contesto fase tipo form>
		}

		 */
	}
}

</script>