<template>
	<span>
		<span>{{ title }}</span>
	</span>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import moment from 'moment'
import PlayerPicker from '@/components/layout/Football/PlayerPicker.vue';

export default {
	mixins: [ pageBase ],
	props: [ 'guideline', 'start', 'results', 'prefix' ],
	data: function() {
		return {
			filter: null,
			ux: {
				search: ''
			}
		}
	},
	created: function() {

		if(this.start) {
			this.filter = _.cloneDeep(this.start);
		} else {
			this.emptyFilter();
		}

	},
	computed: {
		title: function() {

			var t = _.filter([
				this.regions,
				this.classifications,
				this.pathology,
				this.reinjury,
				this.cause,
				this.contact,
				this.mechanism,
				this.event
			]);

			if(t.length > 0)
				return (this.prefix || 'Report Infortuni: ') + '' + t.join(' - ');

			return (this.prefix || 'Report Infortuni');
		},
		regions: function() {

			if(!this.start.region_id || this.start.region_id.length == 0) return null;

			var regions = _.map(this.start.region_id, function(a) {
				return _.find(this.guideline.regions, { id: a }).name;
			}.bind(this));

			if(regions.length == 1) return regions[0];
			if(regions.length == 2) return regions.join(' and ');
			if(regions.length == 3) return regions[0] + ', ' + regions[1] + ' and ' + regions[2];

			return regions.length + ' Regions';

		},
		pathology: function() {

			var reinjury = _.map(this.start.pathology_id, function(a) {
				return _.find(this.guideline.pathologies, { id: a }).name;
			}.bind(this));

			if(reinjury.length == 1) return reinjury[0];
			if(reinjury.length == 2) return reinjury.join(' and ');
			if(reinjury.length == 3) return reinjury[0] + ', ' + reinjury[1] + ' and ' + reinjury[2];

			return reinjury.join(', ');
		},
		classifications: function() {

			var guideline = _.map(_.uniq(_.map(_.flatten(_.map(this.guideline.regions, 'classifications')), function(a) { return JSON.stringify(a); })), function(b) { return JSON.parse(b); });
			
			var reinjury = _.map(this.start.classification_id, function(a) {
				return _.find(guideline, { id: a }).name;
			}.bind(this));

			if(reinjury.length == 1) return reinjury[0];
			if(reinjury.length == 2) return reinjury.join(' and ');
			if(reinjury.length == 3) return reinjury[0] + ', ' + reinjury[1] + ' and ' + reinjury[2];

			return reinjury.join(', ');

		},
		reinjury: function() {

			var glossary = [ { id: 0, name: 'No' }, { id: 1, name: 'Reinjury' }, { id: 2, name: 'Subsequent new injury' }, { id: 3, name: 'Subsequent local injury' }, { id: 4, name: 'Exacerbation' } ];

			var reinjury = _.map(this.start.reinjury, function(a) {
				return _.find(glossary, { id: a }).name;
			}.bind(this));

			if(reinjury.length == 1) return reinjury[0];
			if(reinjury.length == 2) return reinjury.join(' and ');
			if(reinjury.length == 3) return reinjury[0] + ', ' + reinjury[1] + ' and ' + reinjury[2];

			return reinjury.join(', ');

		},
		cause: function() {

			var cause = _.map(this.start.cause, function(i) { return _.startCase(i) });

			if(cause.length == 1) return cause[0];
			if(cause.length == 2) return cause.join(' and ');
			if(cause.length == 3) return cause[0] + ', ' + cause[1] + ' and ' + cause[2];

		},
		contact: function() {
			if(this.start.contact == null) return null;
			return this.start.contact == 1 ? 'Contact' : 'Not Contact';
		},
		mechanism: function() {

			var glossary = this.guideline.mechanisms;

			var mechanism = _.map(this.start.mechanism, function(a) {
				return _.find(glossary, { id: a }).name;
			}.bind(this));

			if(mechanism.length == 1) return mechanism[0];
			if(mechanism.length == 2) return mechanism.join(' and ');
			if(mechanism.length == 3) return mechanism[0] + ', ' + mechanism[1] + ' and ' + mechanism[2];

			return mechanism.join(', ');

		},
		event: function() {

			var event = _.map(this.start.event, function(i) { return _.startCase(i) });

			if(event.length == 1) return event[0];
			if(event.length == 2) return event.join(' and ');
			if(event.length == 3) return event[0] + ', ' + event[1] + ' and ' + event[2];

		},
	}
}

</script>