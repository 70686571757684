<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Analysis</span>
					</div>
					<div class="content">
						<b-form-radio-group v-model="peso" class="mb-2">
							<b-form-radio :value="1">Goal fatti</b-form-radio>
							<b-form-radio :value="0">Goal subiti</b-form-radio>
						</b-form-radio-group>
						<div v-if="loading" align="center" style="width:100%">
							<img src="/assets/varie/loading.gif" style="width: 150px;" />
						</div>
						<div v-if="printing" align="center" style="width:100%">
							<h5>Creazione pdf...</h5>
							<img src="/assets/varie/loading.gif" style="width: 150px;" />
						</div>
						<b-row class="mb-1" v-if="!loading">
							<b-col>
								<b-card ref="full" id="fullcard">
									<div ref="page_1">
										<b-row class="my-1">
											<b-col cols="5">
												<b-card header-tag="header" class="h-100">
													<h5 slot="header" class="mb-0">GOAL {{ peso == 1 ? 'FATTI' : 'SUBITI'}}: <strong> {{tot_goals}} </strong></h5>
													<half-field-section ref="fieldSection" :dentro="tot_goal_dentro_area" :fuori="tot_goal_fuori_area"></half-field-section>
												</b-card>
											</b-col>
											<b-col>
												<b-card class="h-100">
													<b-table striped hover :items="items_goal_area" :fields="fields_goal_area" sort-by="goal" :sort-desc="true">
														<template #cell(n)="data">
															{{data.index + 1}}
														</template>
													</b-table>
												</b-card>
											</b-col>
										</b-row>
										<b-row class="my-1">
											<b-col cols="2">
												<b-card title="Goal per quarti" class="h-100">
													<div>
														<h5>0-15 minuti</h5>
														<b-progress :value="goal_min[0]" :max="max" show-value class="mb-1"></b-progress>
														<h5>15-30 minuti</h5>
														<b-progress :value="goal_min[1]" :max="max" show-value class="mb-1"></b-progress>
														<h5>30-45 minuti</h5>
														<b-progress :value="goal_min[2]" :max="max" show-value class="mb-1"></b-progress>
														<h5>45-60 minuti</h5>
														<b-progress :value="goal_min[3]" :max="max" show-value class="mb-1"></b-progress>
														<h5>60-75 minuti</h5>
														<b-progress :value="goal_min[4]" :max="max" show-value class="mb-1"></b-progress>
														<h5>75-90 minuti</h5>
														<b-progress :value="goal_min[5]" :max="max" show-value class="mb-1"></b-progress>
														<h5>>90 minuti</h5>
														<b-progress :value="goal_min[6]" :max="max" show-value class="mb-1"></b-progress>
													</div>
												</b-card>
											</b-col>
											<b-col cols="4">
												<b-card title="Testa / Destro / Sinistro" class="h-100">
													<body-section ref="bodySection" :sezioni="tot_goal_body"></body-section>
												</b-card>
											</b-col>
											<b-col>
												<b-card title="Sezione porta" class="h-100">
													<goal-section ref="goalSection" :sezioni="tot_goal_sezioni"></goal-section>
												</b-card>
											</b-col>
										</b-row>
									</div>
									<div ref="page_2">
										<b-row class="my-1">
											<b-col cols="6">
												<b-card header-tag="header" class="h-100">
													<h5 slot="header" class="mb-0">PARTE DEL CORPO</h5>
													<b-table striped hover :items="items_parti_corpo" :fields="fields_parti_corpo">
														<template #cell(n)="data">
															{{data.index + 1}}
														</template>
													</b-table>			
												</b-card>
											</b-col>
											<b-col cols="6">
												<b-card header-tag="header" class="h-100">
													<h5 slot="header" class="mb-0">SEZIONE PORTA</h5>
													<b-table striped hover :items="items_sezione_porta" :fields="fields_sezione_porta">
														<template #cell(n)="data">
															{{data.index + 1}}
														</template>							
													</b-table>			
												</b-card>
											</b-col>
										</b-row>
										<b-row class="my-1">
											<b-col>
												<b-card header-tag="header" class="h-100">
													<h5 slot="header" class="mb-0">GOAL DA AZIONE: <strong> {{tot_goals_azione}} </strong></h5>
													<b-table striped hover :items="items_goal_azione" :fields="fields_goal_azione" sort-by="goal" :sort-desc="true">
														<template #cell(n)="data">
															{{data.index + 1}}
														</template>
													</b-table>			
												</b-card>
											</b-col>
											<b-col>
												<b-card header-tag="header" class="h-100">
													<h5 slot="header" class="mb-0">GOAL DA FERMO: <strong> {{tot_goals_fermo}} </strong></h5>
													<b-table striped hover :items="items_goal_fermo" :fields="fields_goal_fermo" sort-by="goal" :sort-desc="true">
														<template #cell(n)="data">
															{{data.index + 1}}
														</template>
													</b-table>			
												</b-card>
											</b-col>
										</b-row>
									</div>
									<div ref="page_3">
										<b-row class="my-1">
											<b-col>
												<b-card header-tag="header">
													<h5 slot="header" class="mb-0">ORIGINE AZIONE</h5>
													<field-section ref="fieldSection" :sezioni="azione_sezioni"></field-section>		
												</b-card>
											</b-col>
											<b-col>
												<b-card header-tag="header">
													<h5 slot="header" class="mb-0">ORIGINE ASSIST</h5>
													<field-section ref="fieldSection" :sezioni="assist_sezioni"></field-section>		
												</b-card>
											</b-col>
										</b-row>
										<b-row>
											<b-col>
												<b-card>
													<b-table striped hover :items="items_assist" :fields="fields_assist" sort-by="assist" :sort-desc="true">
														<template #cell(n)="data">
															{{data.index + 1}}
														</template>
													</b-table>	
												</b-card>
											</b-col>
										</b-row>
									</div>
								</b-card>
							</b-col>
						</b-row>
						<b-button @click="download" variant="primary"><i class="fa fa-download" aria-hidden="true"></i> Download</b-button>	
					</div>
				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>

import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
import BodySection from './BodySection.vue';
import FieldSection from './FieldSection.vue';
import HalfFieldSection from './HalfFieldSection.vue';
import GoalSection from './GoalSection.vue';
import { jsPDF } from 'jspdf';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		BodySection,
		FieldSection,
		HalfFieldSection,
		GoalSection,
	},

	data: function() {
		return {
			loading: false,
			printing: false,
			goals: [],
			giocatori: [],
			giocatori_assist: [],
			tot_goals: 0,
			tot_goals_azione: 0,
			tot_goals_fermo: 0,
			tot_goal_dentro_area: 0,
			tot_goal_fuori_area: 0,
			tot_goal_body: [0, 0, 0],
			tot_goal_sezioni: [0, 0, 0, 0, 0, 0],
			goal_min: [0, 0, 0, 0, 0, 0, 0, 0],
			fields_goal_area: [
			{ key: 'n' },
			{ key: 'giocatore' },
			{ key: 'goal', 'class': 'text-center' },
			{ key: 'dentro_area', 'class': 'text-center' },
			{ key: 'fuori_area', 'class': 'text-center' }
			],
			fields_goal_azione: [
			{ key: 'n' },
			{ key: 'giocatore' },
			{ key: 'goal', 'class': 'text-center' },
			{ key: 'manovrata', 'class': 'text-center' },
			{ key: 'profondita', 'class': 'text-center' },
			{ key: 'pressione', 'class': 'text-center' },
			],
			fields_goal_fermo: [
			{ key: 'n' },
			{ key: 'giocatore' },
			{ key: 'goal' },
			{ key: 'punizione', 'class': 'text-center' },
			{ key: 'diretta', 'class': 'text-center' },
			{ key: 'rigore', 'class': 'text-center' },
			{ key: 'angolo', 'class': 'text-center' }
			],
			fields_parti_corpo: [
			{ key: 'n' },
			{ key: 'giocatore' },
			{ key: 'testa', 'class': 'text-center' },
			{ key: 'destro', 'class': 'text-center' },
			{ key: 'sinistro', 'class': 'text-center' },
			],
			fields_sezione_porta: [
			{ key: 'n' },
			{ key: 'giocatore' },
			{ key: '1', 'class': 'text-center' },
			{ key: '2', 'class': 'text-center' },
			{ key: '3', 'class': 'text-center' },
			{ key: '4', 'class': 'text-center' },
			{ key: '5', 'class': 'text-center' },
			{ key: '6', 'class': 'text-center' },
			],
			fields_assist: [
			{ key: 'n' },
			{ key: 'giocatore' },
			{ key: 'assist', 'class': 'text-center' },
			{ key: 'passaggio_decisivo', 'class': 'text-center' },
			{ key: 'cross', 'class': 'text-center' },
			{ key: 'rimorchio', 'class': 'text-center' },
			{ key: 'traversone', 'class': 'text-center' }
			],
			items_goal_area: [],
			items_goal_azione: [],
			items_goal_fermo: [],
			items_parti_corpo: [],
			items_sezione_porta: [],
			items_assist: [],
			azione_sezioni: [ 0, 0, 0, 0, 0, 0, 0, 0, 0],
			assist_sezioni: [ 0, 0, 0, 0, 0, 0, 0, 0, 0],
			header_pdf: null,
			page_count: 0,
			page_hratio: [1, 1, 1],
			page_pdf: [ null, null, null],
			peso: 1,
		}
	},
	created: function () {
		if(this.rosaTeamId && this.seasonId) {
			this.loadStatsGoal();
		}
		this.$http.get(this.$store.state.apiEndPoint + '/headerpdf').then((response) => {
			if (response.ok)
			{
				this.header_pdf = response.data;    
			}
		}, (response) => {
		});
	},
	methods: {
		loadStatsGoal() {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/analysis/stats/goal/' + this.rosaTeamId + '/' + this.seasonId +'/' + this.peso).then((response) => {
				if (response.ok)
				{
					this.goals = response.data;	
					for(var i in this.goals) {
						this.tot_goals++;
						var goal = this.goals[i];
						var giocatore = null;
						for(var j in this.giocatori) {
							var gio = this.giocatori[j];
							if(gio.id == goal.videotag.giocatore.id) {
								giocatore = gio;
								break;
							}
						}
						if(!giocatore) {
							giocatore = {
								id: goal.videotag.giocatore.id,
								name: goal.videotag.giocatore.nome + " " + goal.videotag.giocatore.cognome,
								goal: 0,
								goal_dentro_area: 0,
								goal_fuori_area: 0,
								goal_body: [0, 0, 0],
								goal_sezioni: [0, 0, 0, 0, 0, 0],
								goal_azione: 0,
								goal_fermo: 0,
								goal_azione_tipi: [0, 0, 0, 0],
								goal_azione_tipi2: [0, 0, 0],
							}
							this.giocatori.push(giocatore);
						}
						giocatore.goal++;
						if(goal.inside_area != null) {
							if(goal.inside_area == 1) {
								this.tot_goal_dentro_area++;
								giocatore.goal_dentro_area++;
							} else if(goal.inside_area == 0) {
								this.tot_goal_fuori_area++;
								giocatore.goal_fuori_area++;
							}
						}
						if(goal.body_section != null) {
							this.tot_goal_body[goal.body_section - 1]++;
							giocatore.goal_body[goal.body_section - 1]++;
						}
						if(goal.goal_section != null) {
							this.tot_goal_sezioni[goal.goal_section - 1]++;
							giocatore.goal_sezioni[goal.goal_section - 1]++;
						}
						if(goal.action_goal != null) {
							if(goal.action_goal == 1) {
								this.tot_goals_azione++;
								giocatore.goal_azione++;
								if(goal.stand_still_goal_type != null) {
									giocatore.goal_azione_tipi2[goal.stand_still_goal_type - 1]++;
								}
							} else if(goal.action_goal == 0) {
								this.tot_goals_fermo++;
								giocatore.goal_fermo++;
								if(goal.stand_still_goal_type != null) {
									giocatore.goal_azione_tipi[goal.stand_still_goal_type - 1]++;
								}
							}
						}
						if(goal.action_section != null) {
							this.azione_sezioni[goal.action_section - 1]++;		
						}

						if(goal.assist && goal.assist.giocatore) {

							var giocatore_assist = null;
							for(var j in this.giocatori_assist) {
								var gio = this.giocatori_assist[j];
								if(gio.id == goal.assist.giocatore.id) {
									giocatore_assist = gio;
									break;
								}
							}
							if(!giocatore_assist) {
								giocatore_assist = {
									id: goal.assist.giocatore.id,
									name: goal.assist.giocatore.nome + " " + goal.assist.giocatore.cognome,
									assist: 0, 
									passaggio_decisivo: 0, 
									cross: 0, 
									rimorchio: 0,
									traversone: 0
								}
								this.giocatori_assist.push(giocatore_assist);
							}
							giocatore_assist.assist++;
							if(goal.assist.azioni.length > 0) {
								var azione = goal.assist.azioni[0];
								if(azione.id_azione == '13a') {
									giocatore_assist.passaggio_decisivo++;
								} else if(azione.id_azione == '13b') {
									giocatore_assist.cross++;
								} else if(azione.id_azione == '13c') {
									giocatore_assist.rimorchio++;
								} else if(azione.id_azione == '13d') {
									giocatore_assist.traversone++;
								}
							}
						}

						if(goal.assist_section != null) {
							this.assist_sezioni[goal.assist_section - 1]++;		
						}

						var index = this.getQuartoGoal(goal.videotag);

						this.goal_min[index]++;
					}

					for(var i in this.giocatori) {
						var giocatore = this.giocatori[i];
						var item_goal_area = {
							giocatore: giocatore.name,
							goal: giocatore.goal,
							dentro_area: giocatore.goal_dentro_area,
							fuori_area: giocatore.goal_fuori_area,
						}
						this.items_goal_area.push(item_goal_area);
						
						var item_goal_azione = {
							giocatore: giocatore.name,
							goal: giocatore.goal_azione,
							manovrata: giocatore.goal_azione_tipi2[0], 
							profondita: giocatore.goal_azione_tipi2[1], 
							pressione:giocatore.goal_azione_tipi2[2], 
						}
						this.items_goal_azione.push(item_goal_azione);

						var item_goal_fermo = {
							giocatore: giocatore.name,
							goal: giocatore.goal_fermo,
							punizione: giocatore.goal_azione_tipi[0], 
							diretta: giocatore.goal_azione_tipi[1], 
							rigore:giocatore.goal_azione_tipi[2], 
							angolo: giocatore.goal_azione_tipi[3], 
						}
						this.items_goal_fermo.push(item_goal_fermo);

						var item_parti_corpo = {
							giocatore: giocatore.name,
							testa: giocatore.goal_body[0],
							destro: giocatore.goal_body[1],
							sinistro: giocatore.goal_body[2],
						}
						this.items_parti_corpo.push(item_parti_corpo);

						var item_sezione_porta = {
							giocatore: giocatore.name,
							'1': giocatore.goal_sezioni[0],
							'2': giocatore.goal_sezioni[1],
							'3': giocatore.goal_sezioni[2],
							'4': giocatore.goal_sezioni[3],
							'5': giocatore.goal_sezioni[4],
							'6': giocatore.goal_sezioni[5],
						}
						this.items_sezione_porta.push(item_sezione_porta);
					}

					for(var i in this.giocatori_assist) {
						var giocatore = this.giocatori_assist[i];
						var item_assist = {
							giocatore: giocatore.name,
							assist: giocatore.assist, 
							passaggio_decisivo: giocatore.passaggio_decisivo, 
							cross: giocatore.cross, 
							rimorchio: giocatore.rimorchio, 
							traversone: giocatore.traversone,
						}
						this.items_assist.push(item_assist);
					}

					this.loading = false;	
				}
			}, (response) => {
			});
},
resetStatsGoal() {
	this.giocatori = [];
	this.giocatori_assist = [];
	this.tot_goals = 0;
	this.tot_goals_fermo = 0;
	this.tot_goals_azione = 0;
	this.tot_goal_dentro_area = 0;
	this.tot_goal_fuori_area = 0;
	this.tot_goal_body = [0, 0, 0];
	this.tot_goal_sezioni = [0, 0, 0, 0, 0, 0];
	this.items_sezione_porta = [];
	this.items_parti_corpo = [];
	this.items_goal_fermo = [];
	this.items_goal_azione = [];
	this.items_goal_area = [];
	this.items_assist = [];
	this.azione_sezioni = [ 0, 0, 0, 0, 0, 0, 0, 0, 0];
	this.assist_sezioni = [ 0, 0, 0, 0, 0, 0, 0, 0, 0];
	this.goal_min = [0, 0, 0, 0, 0, 0, 0, 0];
},

getQuartoGoal(videotag) {

	var time = 0;

	var parts_tag = videotag.inizio.split(':');
	var sec_tag = (+parts_tag[0]) * 60 * 60 + (+parts_tag[1]) * 60 + (+parts_tag[2]); 

	if(videotag.video.segnalibri.length > 0) {
		var segnalibro_rif = null;
		var diff = -1;
		for(var i in videotag.video.segnalibri) {
			var segnalibro = videotag.video.segnalibri[i];
			var parts_segn = segnalibro.tempo.split(':');
			var sec_segn = (+parts_segn[0]) * 60 * 60 + (+parts_segn[1]) * 60 + (+parts_segn[2]); 
			if(sec_segn <= sec_tag) {
				if(diff == -1) {
					segnalibro_rif = segnalibro;
					diff = sec_tag - sec_segn;
				} else {
					if(sec_tag - sec_segn < diff) {
						segnalibro_rif = segnalibro;
						diff = sec_tag - sec_segn;
					}
				}
			}
		}
		if(segnalibro_rif) {
			var parts_segn = segnalibro_rif.tempo_partita.split(':');
			var sec_segn = (+parts_segn[0]) * 60 * 60 + (+parts_segn[1]) * 60 + (+parts_segn[2]); 
			time = sec_segn + diff;
		} else {
			time = sec_tag;
		}
	} else {
		time = sec_tag;
	}

	if(time >= 0 && time <= 900) {
		return 0;
	} else if(time >= 900 && time <= 1800) {
		return 1;
	} else if(time >= 1800 && time <= 2700) {
		return 2;
	}
	else if(time >= 2700 && time <= 3600) {
		return 3;
	} else if(time >= 3600 && time <= 4500) {
		return 4;
	} else if(time >= 4500 && time <= 5400) {
		return 5;
	} else if(time >= 5400) {
		return 6;
	}
},

download () { 

	this.page_count = 0;
	this.page_hratio = [1, 1, 1];
	this.page_pdf = [ null, null, null];

	this.printing = true;

	$("#fullcard")
	.find("svg")
	.map(function () {
		var svg = $(this);
		svg.attr("width", svg.width());
		svg.attr("height", svg.height());
	});
	window.scrollTo(0, 0);

	for(var page = 1; page <=3; page++) {

		const el = this.$refs['page_' + page];

		this.page_hratio[page - 1] = el.clientHeight/el.clientWidth;

		const options = {
			type: 'dataURL',
			index: page,
		}
		this.$html2canvas(el, options).then(canvas => {
			this.page_pdf[options.index - 1] = canvas;
			this.page_count++;

		});
	}
}

},
filters: {
},
watch: {
	rosaTeamId() {
		if(this.rosaTeamId && this.seasonId) {
			this.resetStatsGoal();
			this.loadStatsGoal();
		}
	},

	seasonId() {
		if(this.rosaTeamId && this.seasonId) {
			this.resetStatsGoal();
			this.loadStatsGoal();
		}
	},

	peso() {
		if(this.rosaTeamId && this.seasonId) {
			this.resetStatsGoal();
			this.loadStatsGoal();
		}
	},

	page_count() {
		if(this.page_count == 3) {
			var doc = new jsPDF("p", "mm", "a4");
			var width = doc.internal.pageSize.getWidth();
			var height = doc.internal.pageSize.getHeight();	
			doc.addImage(this.header_pdf, 'PNG', 0, 0, width, 33);
			doc.addImage(this.page_pdf[0], 'JPEG', 0, 33, width, (width * this.page_hratio[0]));
			doc.addPage();
			doc.addImage(this.header_pdf, 'PNG', 0, 0, width, 33);
			doc.addImage(this.page_pdf[1], 'JPEG', 0, 33, width, (width * this.page_hratio[1]));
			doc.addPage();
			doc.addImage(this.header_pdf, 'PNG', 0, 0, width, 33);
			doc.addImage(this.page_pdf[2], 'JPEG', 0, 33, width, (width * this.page_hratio[2]));

			doc.save('analysis.pdf');

			this.printing = false;

			this.resetStatsGoal();
			this.loadStatsGoal();
			
		}
	}, 
},
computed: {
	max: function() {
		return Math.max(...this.goal_min);
	},

	...mapState(['rosaTeamId', 'seasonId']),
}
}
</script>
