<template id="academyaggiungi">
    <section class="academyaggiungi">

        <headapp></headapp>

        <menusub></menusub>

        <v-form title="Nuova Academy"></v-form>

        <footerapp></footerapp>

    </section>
</template>

<script>
export default {

    components: {
        headapp:            require('../../../layout/headapp.vue').default,
        menusub:            require('../../../layout/menusub.vue').default,
        footerapp:          require('../../../layout/footerapp.vue').default,
        vForm:              require('./form.vue').default,
    }

}
</script>