export default {
	lang: 'it',
	cognome_required: 'Campo Cognome obbligatorio!',
	nome_required: 'Campo Nome obbligatorio!',
	giocatore_presente: 'Giocatore già presente in archivio!',
	giocatore_salvato_ok: 'Giocatore salvato con successo!',
	drag: 'Trascina un immagine o <br>clicca qui per selezionare un file',
	fileSize: 'La dimensione del file eccede il limite massimo',
  	fileType: 'Tipo di file non supportato.',
  	add_valutazione_ok: 'Valutazione salvata con successo!',
  	'Domestic cup': 'Coppa nazionale',
  	'Domestic league': 'Lega nazionale',
  	'Domestic super cup': 'Super coppa nazionale',
  	'International cup': 'Coppa internazionale',
  	'International super cup': 'Super coppa internazionale',
  	'male': 'maschile',
  	'female': 'femminile',
    add_missioni_ok: 'Missione aggiunta con successo!',
    elimina_missione_ok: 'Missione eliminata con successo!',
    update_missione_ok: 'Missione aggiornata con successo!',
    add_promemoria_ok: 'Promemoria aggiunto con successo!',
    elimina_promemoria_ok: 'Promemoria eliminato con successo!',
    update_promemoria_ok: 'Promemoria aggiornato con successo!',
    'Programma': 'Programma', 
  	'Documenti': 'documenti',
  	'Esercizi': 'Esercizi',
  	'Allenamenti': 'Allenamenti',
  	'Esercizi recenti': 'Esercizi recenti',
}