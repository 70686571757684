<template>
	<div>
		<iframe @mouseenter="mouseIn" @mouseleave="mouseOut" class="humanpick" :src="theUrl" style="width: 100%" height="500" frameborder="0"></iframe>
	</div>
</template>

<script>
var Auth = require('../../auth.js');
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import _ from 'lodash'

export default {
	props: [ 'regioniSelezionate', 'zones', 'mapping', 'item', 'field', 'hash', 'disabled', 'gh' ],
	data: function() {
		return {
			theUrl: false,
			ignore: false,
			viewer: false,
			frameId: false,
			percorso: false,
			selected: [],
			regioniSelezionateId: []
		}
	},
	computed: {
		url: function() {
			return 'https://app.asromasystem.com/3dbody-v2/index.html' + ( this.hash ? '#' + this.hash : '' );
		},
		sels: function() {
			var accumulated = [];
			_.each(this.selected, function(a) {
				if(this.zones[a]) {
					var base = _.find(this.mapping, { id: this.zones[a] });
					if(!_.find(accumulated, {id: base.id}))
						accumulated.push(base);
				}
			}.bind(this));
			return accumulated;
		}
	},
	watch: {
		'disabled': {
			deep: true,
			handler: function() {
				this.send({ action: 'block' });
			}
		},
		'regioniSelezionate': {
			deep: true,
			handler: function() {
				if(this.ignore == true) {
					this.ignore = false;
					return false;
				}
				this.send({ action: 'select', ids: _.uniq(_.map(this.regioniSelezionate, 'id')) });
			}
		},
		'selected': {
			deep: true,
			handler: function(a,b) {
				this.$emit('upd', this.sels);
			}
		},
		'percorso': {
			deep: true,
			handler: function() {
				this.$emit('path', this.percorso);
			}
		}
	},
	created: function() {
		this.theUrl = _.cloneDeep(this.url).replace('NaN',0);
	},
	methods: {
		mouseIn: function() {
			if(this.disabled == false) {
				var w = $(window).width();
				$('html,body').css('overflow', 'hidden');
				var w = $(window).width() - w;
				$('body').css('margin-right', w);
			}
		},
		mouseOut: function() {
			if(this.disabled == false) {
				$('html,body').css('overflow', 'auto');
				$('body').css('margin-right', 0);
			}
		},
		send: function(message) {
			if(this.viewer)
				this.viewer.postMessage(message, '*');
		}
	},
	mounted: function () {

		var b = $('iframe.humanpick', this.$el).get(0);
		this.viewer = b.contentWindow;

		_.delay(function() {
			this.frameId = _.uniqueId('frame-');
			this.send({ action: 'link', id: this.frameId });
		}.bind(this), 1000);

		window.addEventListener("message", function(event) {
			if(event.data.id == this.frameId) {

				if(event.data.action == 'pick') {
					if(JSON.stringify(event.data.parts) != JSON.stringify(this.selected)) {
						//console.log('Receive Selections', event.data.parts, this.selected);
						this.ignore = true;
						this.selected = event.data.parts;
					}
				} else if(event.data.action == 'hash') {
					//console.log('Receive Hash', event.data.hash);
					this.percorso = event.data.hash.replace('NaN',0);
				} else if(event.data.action == 'linked') {
					//console.log('Linked');
					if(this.disabled == true) {
						//console.log('Emit?');
						_.delay(function() {
							this.send({ action: 'block' });
						}.bind(this), 100);
					}

				} else if(event.data.action == 'pickGroups') {
					//this.gh.group = true;
					console.log('GroupyThingyMaginghy')
				} else if(event.data.action == 'unpickGroups') {
					// this.gh.group = false;
					// console.log('GroupyThingyMaginghy')
				}

			}
		}.bind(this), '*');

	}
}
</script>
