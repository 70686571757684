<template id="esercizifiltri">
  <form v-on:submit.prevent="submit()" id="form" action="#" method="POST">
    <div class="esercizifiltri">
      <div class="form-row">
        <b-input-group class="mb-2">
          <b-form-input type="text" v-model="title" :placeholder="$i18n.t('Cerca per titolo/autore...')"></b-form-input>
          <b-input-group-append>
            <b-button @click="reset"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
            <b-button variant="primary" @click="submit"><i class="fa fa-search" aria-hidden="true"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="text-right">
        <b-button @click="switchFilter" size="sm">{{ portiere ? $i18n.t('passa ai filtri giocatore') : $i18n.t('passa ai filtri portiere') }}</b-button>
      </div>

      <div class="form-row mb-3" v-for="(filter, index) in filters" :key="index" v-if="!portiere">

        <label class="color-rosso font-bold text-uppercase">{{ filter[$i18n.t('lang')] }}</label>

        <div class="form-row">

          <div class="form-group col-sm-12 mb-1" v-for="item in filter.fields" :key="item.id">
           <b-list-group>
            <b-list-group-item>
              <b-form-checkbox :id="item.id" :value="item.id" v-model="objective_ids" style="font-size:1rem;">
               {{ item[$i18n.t('lang')] }}
             </b-form-checkbox>
           </b-list-group-item>
         </b-list-group>
       </div>
     </div>
   </div>

   <div class="form-row mb-3" v-for="(filter, index) in filters_portiere" :key="index" v-if="portiere">

    <label class="color-rosso font-bold text-uppercase">{{ filter[$i18n.t('lang')] }}</label>

    <div class="form-row">

      <div class="form-group col-sm-12 mb-1" v-for="item in filter.fields" :key="item.id">
        <b-list-group>
          <b-list-group-item>
            <b-form-checkbox :id="item.id" :value="item.id" v-model="objective_ids" style="font-size:1rem;">
             {{ item[$i18n.t('lang')] }}
           </b-form-checkbox>
         </b-list-group-item>
       </b-list-group>
     </div>
   </div>
 </div>

</div>
</form>
</template>

<script>

import axios from 'axios';
import { mapState } from 'vuex';

export default {
  data: function(){
    return {
      filters:   [],
      filters_portiere:   [],
      portiere: false,
    }
  },
  created() {
  },
  mounted() {
          /**
           *  RETRY OBJECTIVES
           **/
           axios.get(`/skills`)
           .then((res) => {
            this.filters = JSON.parse(res.data.standard);
            this.filters_portiere = JSON.parse(res.data.special);
            this.loading    = false;
          }).catch((err) => {
            this.loading = false;
          });
        },
        computed:{
         objective_ids: {
           get() {
             return this.$store.state.acad_esercizi.objective_ids;
           },

           set(value) {
             this.$store.commit('acad_esercizi/setObjIds', value);
           },
         },
         title: {
           get() {
             return this.$store.state.acad_esercizi.title;
           },

           set(value) {
             this.$store.commit('acad_esercizi/setTitle', value);
           },
         },
         ...mapState('acad_esercizi', ['exercises_tab']),
         ...mapState(['rosaTeamId']),
       },
       watch: {
        objective_ids() {
          this.submit();
        },  
      },
      methods: {
        submit() {
          this.$store.commit('acad_esercizi/setPage', 1);
          if(this.exercises_tab == 0) {
            this.$store.dispatch('acad_esercizi/filter');
          } else if(this.exercises_tab == 1) {
            this.$store.dispatch('acad_esercizi/filter_archivio');
          } else if(this.exercises_tab == 2) {
            this.$store.dispatch('acad_esercizi/filter_bozze');
          } 
          $('html,body').animate({ scrollTop: 0 }, 'slow');
        },
        reset() {
          this.title = '';
          this.submit();
        },
        switchFilter() {
          this.portiere = !this.portiere;
          this.$store.commit('acad_esercizi/setObjIds', []);
        },
      }

    }
    </script>
