<template>
	<div>
		<div ref="chart" :style="'margin: auto; width: '+(width||400)+'px; height: '+ (height||800) +'px'"></div>
	</div>
</template>

<script>

var Auth = require('@/auth.js');
import moment from 'moment';
import videojs from 'video.js';
import _ from 'lodash';

export default {
	props: [ 'ds', 'label', 'width', 'height' ],
	methods: {
		buildGraph: function() {
			var chartDom = this.$refs.chart;

			$.get(require('@/assets/corpo_umano.svg'), function (svg) {

				echarts.registerMap('body_parts', { svg: svg });

				var myChart = echarts.init(chartDom);
				myChart.setOption(this.options);

			}.bind(this));

		}
	},
	watch: {
		ds: {
			deep: true,
			handler: function() {
				this.buildGraph();
			}
		}
	},
	mounted: function() {

		this.buildGraph();

	},
	computed: {
		options: function() {

			return {
				tooltip: {},
				visualMap: {
					left: 'center',
					bottom: '10%',
					min: 0,
					max: _.max(_.values(this.ds)),
					orient: 'horizontal',
					text: ['', 'Giorni di Recupero Totali'],
					realtime: true,
					calculable: true,
					inRange: {
						color: ['#dbac00', '#db6e00', '#cf0000']
					}
				},
				series: [
					{
						name: 'Mappa degli Infortuni',
						type: 'map',
						map: 'body_parts',
						roam: true,
						emphasis: {
							label: {
								show: false
							}
						},
						selectedMode: false,
						data: _.map(this.ds, function(a, b) {
							return { name: b, value: a || 1 };
						})
					}
				]
			};

		}
	}
}

</script>