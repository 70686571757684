<template id="rosa">

	<section class="rosa">

		<headapp></headapp>
		<menusub></menusub>
		
		<InjuriesDashboard />

		<footerapp></footerapp>

	</section>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import InjuriesDashboard from '@/components/layout/reports/InjuriesDashboard.vue'


export default {
	mixins: [ pageBase ],
	components: {
		InjuriesDashboard
	},
}

</script>