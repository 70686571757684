<template id="giocatore">
	<section class="giocatore">

		<headapp></headapp>

		<schedagiocatore :player_id="$route.params.id"></schedagiocatore>

		<footerapp></footerapp>

	</section>
</template>

<script>
export default {
	data: function() {
		return {
		}
	},
	components: {
		headapp: require('../../../layout/headapp.vue').default,
		footerapp: require('../../../layout/footerapp.vue').default,
		schedagiocatore: require('../../../layout/schedagiocatore.vue').default,
	}
}
</script>
