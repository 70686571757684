<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Modifica video</span>
					</div>
					<div class="content">
						<b-row>
							<b-col cols="8">	
								<b-form @submit="onSubmit">
									<b-form-group label="Titolo (facoltativo)" class="mb-3">
										<b-form-input v-model="form.titolo"></b-form-input>
									</b-form-group>
									<b-embed type="video" controls aspect="16by9" v-if="show_video && form.type != 'youtube'" :poster="thumb_video && thumb_video != '' ? thumb_video : '/assets/varie/missing.jpg'" preload="none" controlsList="nodownload" oncontextmenu="return false;">
										<source :src="src_video"/>		
									</b-embed>
									<b-embed type="iframe" aspect="16by9" :src="src_video" allowfullscreen v-if="form.type == 'youtube'"></b-embed>
									<b-form-group label="Seleziona la sorgente del video:" class="mt-3">
										<b-form-radio-group v-model="form.type" :options="type_options">
										</b-form-radio-group>
									</b-form-group>
									<b-form-group label="Video" v-if="form.type == 'upload'">
										<div v-if="!aws3_loading" style="width:100%;" class="mt-1">
											<span class="font-bold">Loading...</span>
											<img src="/assets/varie/loading.gif" style="width: 30px;" />
										</div>
										<span class="font-bold" v-if="aws3_loading && aws3_video.length == 0">Nessun video trovato sul tuo archivio!</span>
										<b-form-select v-model="form.fileNameVideo" v-if="aws3_loading && aws3_video.length > 0">
											<option :value="null">Seleziona video</option>
											<optgroup :label="video_obj.utente" v-for="video_obj in aws3_video">
												<option :value="video.path" v-for="video in video_obj.fileList">{{ video.basename }} caricato il {{ video.timestamp | formatDateVideo }}</option>
											</optgroup>
										</b-form-select>
									</b-form-group>
									<b-form-group label="Video caricati sul server ASRoma" v-if="form.type == 'asroma'">
										<div v-if="!upload_loading" style="width:100%;" class="mt-1">
											<span class="font-bold">Loading...</span>
											<img src="/assets/varie/loading.gif" style="width: 30px;" />
										</div>
										<span class="font-bold" v-if="upload_loading && upload_video.length == 0">Nessun video trovato sul tuo archivio!</span>
										<b-form-select v-model="form.fileNameVideo" v-if="upload_loading && upload_video.length > 0">
											<option :value="null">Seleziona video</option>
											<optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
												<option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} caricato il {{ video.data | formatDateHour }}</option>
											</optgroup>
										</b-form-select>
									</b-form-group>
									<b-form-group label="Url video Youtube" v-if="form.type == 'youtube'">
										<b-form-input v-model="form.url"></b-form-input>
									</b-form-group>
									<b-button type="submit" variant="primary" class="mt-1">Salva</b-button>
								</b-form>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col cols="8" class="border-top">
								<b-button class="mt-3" @click="showUpload">Carica nuovo video</b-button>
								<b-row v-if="show_upload" class="mt-3">
									<b-col>
										<b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../../store/types';
export default {
	data: function() {
		return {
			aws3_loading: false,
			aws3_video: [],
			upload_loading: false,
			upload_video: [],
			show_upload: false,
			type_options: [
			/*	{ text: 'Youtube', value: 'youtube' },  */
			{ text: 'AS Roma', value: 'asroma' },
			{ text: 'Upload', value: 'upload' },
			],
			form: {
				id: this.$route.params.id_video,
				type: 'asroma',
				url: '',
				titolo: '',
				fileNameVideo: null,
			},
			thumb_video: '',
			src_video: '',
			show_video: false,
			errors: [],
			upload_iframe: '',
		}
	},

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
	},

	created: function () {
		this.getUploadVideo();
		this.getUploadIFrame();
		this.getAws3Video();
		this.$http.get(this.$store.state.apiEndPoint + '/video/view/' +  this.$route.params.id_video).then((response) => {
			if (response.ok)
			{
				this.form.type = response.data.tipo;
				if(this.form.type == 'youtube') {
					this.form.url = response.data.video;
				}
				this.form.titolo = response.data.titolo;
				this.src_video = response.data.video;
				this.thumb_video = response.data.thumb;
				this.show_video = true;
			}
		}, (response) => {

		});
	},

	computed: {
		...mapState(['rosaTeamId']),
	},
	watch: {
		rosaTeamId() {
			this.form.fileNameVideo = null;
			this.getUploadVideo();
			this.getAws3Video();
		},
		'form.type': function () {
			this.form.fileNameVideo = null;
			if(!this.aws3_loading && this.form.type == 'upload') {
				this.getAws3Video();
			} else if(!this.upload_loading && this.form.type == 'asroma') {
				this.getUploadVideo();
			}
		},  
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.editVideo();
		},

		editVideo() {
			this.$http.post(this.$store.state.apiEndPoint + '/video/edit', this.form).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: "football_video_esercizio", params: { id: this.$route.params.id }});
					
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		getAws3Video() {
			if(this.rosaTeamId) {
				this.$http.get(this.$store.state.apiEndPoint + '/video/files/list', {params: { team_id: this.rosaTeamId }}).then((response) => {

					if (response.ok)
					{
						this.aws3_video = response.data;
						this.aws3_loading = true;
					}
				}, (response) => {

				});
			}
		},
		getUploadVideo() {
			if(this.rosaTeamId) {
				this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', {params: { team_id: this.rosaTeamId }}).then((response) => {
					if (response.ok)
					{
						this.upload_video = response.data;
						this.upload_loading = true;
					}
				}, (response) => {

				});
			}
		},
		showUpload() {
			this.show_upload = !this.show_upload;		
		},
		getUploadIFrame() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
				if (response.ok) {
					this.upload_iframe = response.data;
				}
			}, (response) => {
			});
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
		formatDateVideo: function (timestamp) {
			var m = moment.unix(timestamp);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>
