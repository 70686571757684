<template>
	<div>
		<b-overlay :show="ux.waiting">

			<b-progress v-if="video && !probed" :value="100" variant="success" :animated="true" class="mt-3"></b-progress>
			<b-row v-if="!video">
				<b-col>
					Seleziona un video
				</b-col>
				<b-col>
					<b-form-file ref="videoUpload" @change="fileSelected"></b-form-file>
				</b-col>
			</b-row>

			<b-row v-else-if="probed && isValidVideo" class="mb-2">
				<b-col cols="6">
					<strong>Dimensioni</strong>
					<div>{{ probedVideo.Width }}x{{ probedVideo.Height }}</div>
				</b-col>
				<b-col cols="6">
					<strong>Durata</strong>
					<div>{{ probedVideo.Duration | duration }}</div>
				</b-col>
			</b-row>


			<div v-else-if="probed && !isValidVideo">
				<b-alert variant="secondary" :show="true">
					<div class="m-3 lead text-center">Il video <strong>non è un MP4</strong>, pertanto non è possibile editarlo prima della trascodifica.</div>
				</b-alert>
			</div>

			<div v-if="probed && isValidVideo">
				
				<b-row v-if="!entity || !entity.entityId">
					<b-col>
						<b-form-group label="ID Partita">
							<b-input v-model="matchId" @change="fetchMatch(matchId)"></b-input>
						</b-form-group>
					</b-col>
				</b-row>

				<b-alert variant="secondary" :show="true" v-if="ux.bookmarking == null">
					<p>Il processo è diviso in due step:</p>
					<ol>
						<li>A partire dall'intero video è possibile andare ad individuare: <strong>inizio e fine di un tempo</strong> ed <strong>eventi</strong> (gol per ora) della partita</li>
						<li>È possibile tagliare il video in più parti e decidere se stampigliare in overlay la HUD</li>
					</ol>
					<hr />
					<b-button-group>
						<b-button variant="outline-success" @click.prevent="ux.bookmarking = true">Procedi con lo Step 1: Individua tempi ed eventi</b-button>
						<b-button variant="outline-primary" @click.prevent="ux.bookmarking = false">Procedi con lo Step 2: Taglia il video</b-button>
					</b-button-group>
				</b-alert>
				
				<div v-if="ux.bookmarking == false">

					<div v-if="payloadTrim.intervals.length > 0">
						<b-button @click.prevent="cutByIntervals">Crea i tagli a partire dagli intervalli</b-button>
					</div>

					<b-card v-for="trim in payloadTrim.trims">
						<Smart-Video-Cutter :video="video" :probedVideo="probedVideo" :trim="trim"></Smart-Video-Cutter>
						<b-button size="xs" v-if="payloadTrim.trims.length > 1">Rimuovi Taglio</b-button>
					</b-card>

					<b-button @click.prevent="addNewTrim" size="xs">Nuovo Taglio</b-button>

				</div>
				<div v-else-if="ux.bookmarking == true">
					
					<b-row>
						<b-col :cols="3">

							<b-nav tabs class="mb-1">
								<b-nav-item @click.prevent="ux.bookmarking_tab = 'intervals'" :active="ux.bookmarking_tab == 'intervals'">Tempi</b-nav-item>
								<b-nav-item @click.prevent="ux.bookmarking_tab = 'events'" :active="ux.bookmarking_tab == 'events'">Eventi</b-nav-item>
							</b-nav>

							<div v-if="ux.bookmarking_tab == 'intervals'">

								<b-list-group>
									<b-list-group-item v-for="interval in payloadTrim.intervals">
										<b-row>
											<b-col>
												<b-form-group class="m-0" label="Inizio">
													<b-input size="sm" type="number" :min="0" :max="probedVideo.Duration" v-model.number="interval.from"></b-input>
													<a href="#" @click.prevent="setCurrentAt(interval, 'from')" class="small muted">Current</a> <a href="" class="small text-muted" @click.prevent="focusAt(interval.from)">Focus</a>
												</b-form-group>
											</b-col>
											<b-col>
												<b-form-group class="m-0" label="Fine">
													<b-input size="sm" type="number" :min="0" :max="probedVideo.Duration" v-model.number="interval.to"></b-input>
													<div><a href="#" @click.prevent="setCurrentAt(interval, 'to')" class="small muted">Current</a> <a href="" class="small text-muted" @click.prevent="focusAt(interval.to)">Focus</a></div>
													<div><a href="#" @click.prevent="setCurrentAt(interval, 'to', 45*60)" class="small muted">45m</a></div>
												</b-form-group>
											</b-col>
											<b-col>
												<b-form-group class="m-0" label="Nome">
													<b-input size="sm" v-model="interval.name"></b-input>
												</b-form-group>
											</b-col>
										</b-row>
									</b-list-group-item>
								</b-list-group>

								<div class="mt-2">
									<a href="#" @click.prevent="addInterval">Aggiungi Tempo</a>
								</div>

							</div>
							<div v-else-if="ux.bookmarking_tab == 'events'">
								
								<pre>{{payloadTrim.events}}</pre>

							</div>

							<!-- <pre>{{ payloadTrim.intervals }}</pre> -->

						</b-col>
						<b-col :cols="9">
							<Smart-Player ref="player" @tcabs="player.currentTime = $event" :video="makeUrl(video)" :step="seekStep" :cutIn="0" :cutOut="probedVideo.Duration" :width="probedVideo.Width" :height="probedVideo.Height"></Smart-Player>
							<Smart-Timeline-Guide :currentTime="player.currentTime" :duration="probedVideo.Duration" :payload="payloadTrim"></Smart-Timeline-Guide>
						</b-col>
					</b-row>


					<div class="mt-3">
						<b-button size="sm" variant="outline-primary" @click.prevent="goToTrim">Procedi con la segmentazione del video</b-button>
					</div>

				</div>



			</div>

			<!-- <b-button @click.prevent="submitVideo">Upload & Trim</b-button> -->
		</b-overlay>

		<div v-if="false">
			<pre>{{payloadTrim}}</pre>
			<pre>{{matchData}}</pre>
			<pre>{{probed}}</pre>
		</div>
	</div>
</template>

<script>


import moment from 'moment';

var mediainfo = false;
MediaInfo({
	format: 'JSON',
	locateFile: (path, prefix) => prefix + path, // Make sure WASM file is loaded from CDN location
}, function(m) {
	mediainfo = m;
})

function probe(file, th) {

	if(file) {
		
		const getSize = () => file.size

		const readChunk = (chunkSize, offset) =>
			new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onload = (event) => {
					if (event.target.error) {
						reject(event.target.error)
					}
					resolve(new Uint8Array(event.target.result))
				}
				reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
			})

		mediainfo.analyzeData(getSize, readChunk).then(th).catch(function(error) {
			console.log(`An error occured:\n${error.stack}`);
		});

	}

}

export default {
	props: ['cutterSource', 'entity'],
	components: {
		SmartTimelineGuide: require('@/components/layout/video/smart-timeline-guide.vue').default,
		SmartPlayer: require('@/components/layout/video/smart-player.vue').default,
		SmartVideoCutter: require('@/components/layout/video/video-cutter.vue').default
	},
	created: function() {

		if(this.entity && this.entity.entityId) {
			this.fetchMatch();
		}

	},
	data: function() {
		return {
			player: {
				currentTime: 0
			},
			ux: {
				bookmarking_tab: 'intervals',
				bookmarking: null,
				waiting: false
			},
			matchId: null,
			video: null,
			probed: null,
			matchData: null,
			payloadTrim: {
				intervals: [],
				events: [],
				trims: []
			}
		}
	},
	filters: {
		duration: function(a) {

			return moment('2000-01-01 00:00:00').add(a, 'seconds').format('HH:mm:ss');

		}
	},
	watch: {
		isValidVideo: function() {
			if(this.isValidVideo == true) {

				this.$nextTick(function() {
					this.payloadTrim.trims.push(this.emptyPayloadTrim());
				});

			}
		}
	},
	computed: {
		eventUrl: function() {

			if(this.entity.entity == 'Partite') {
				var k = 'scouting';
			} else if(this.entity.entity == 'Partita') {
				var k = 'football';
			}

			return this.$store.state.apiEndPoint + '/fed/match-events/' + this.entity.entityId + '/' + k;

		},
		seekStep: function() {
			return 1/parseFloat(this.probedVideo.FrameRate);
		},
		isValidVideo: function() {
			if(!this.probed || this.probedVideo.Format != 'AVC') return false;
			return true;
		},
		probedVideo: function() {
			return _.find(this.probed.media.track, { '@type': 'Video' });
		},
		probedAudio: function() {
			return _.find(this.probed.media.track, { '@type': 'Audio' });
		}
	},
	methods: {
		goToTrim: function() {
			this.ux.bookmarking = false;
			this.payloadTrim.trims = [];
		},
		cutByIntervals: function() {

			var padding = prompt('Inserisci gli eventuali secondi che puoi prependere e appendere al taglio.', 0);

			this.payloadTrim.trims = [];

			_.each(this.payloadTrim.intervals, function(p) {

				var from, to;
				if(p.from - padding > 0)
					from = p.from - padding;
				else
					from = p.from;

				if(p.to + padding < this.probedVideo.Duration)
					to = p.to + padding;
				else
					to = p.to;

				this.payloadTrim.trims.push(this.emptyPayloadTrim(from, to));

			}.bind(this));


		},
		focusAt: function(time) {
			this.$refs.player.updatePlayerTime(time);
		},
		setCurrentAt: function(where, what, amount = 0) {

			var desiredEnd = _.clone(this.player.currentTime) + amount;
			if(desiredEnd < this.probedVideo.Duration)
				where[what] = desiredEnd;
			else
				where[what] = _.clone(this.probedVideo.Duration);
		},
		addInterval: function() {

			var desiredEndTime = _.clone(this.player.currentTime) + 45*60;

			if(desiredEndTime > this.probedVideo.Duration)
				desiredEndTime = this.probedVideo.Duration;

			this.payloadTrim.intervals.push({
				name: '',
				from: _.clone(this.player.currentTime),
				to: desiredEndTime
			});

		},
		addNewTrim: function() {

			this.payloadTrim.trims.push(this.emptyPayloadTrim(this.player.currentTime));

		},
		emptyPayloadTrim: function(cut_in = 0, cut_out = null) {

			if(cut_out == null)
				cut_out = this.probedVideo.Duration;

			return {
				transcoding: { fps: parseFloat(this.probedVideo.FrameRate), width: this.probedVideo.Width, height: this.probedVideo.Height, in: cut_in, out: parseFloat(cut_out) },
				rendering: []
			}

		},
		fetchMatch: function(id) {

			if(id) {
				this.$http.get(this.$store.state.apiEndPoint + '/fed/match-events/' + id + '/' + (this.cutterSource||'football')).then(function(data) {
					this.matchData = data.data;
				});
			} else {
				this.$http.get(this.eventUrl).then(function(data) {
					this.matchData = data.data;
				});
			}

		},
		fileSelected: function(e) {
			
			this.video = e.target.files[0];
			console.log('Do Probe!');
			probe(e.target.files[0], function(result) {
				var probed = JSON.parse(result);
				this.probed = probed;
			}.bind(this));

		},
		makeUrl: function(a) {
			return URL.createObjectURL(a);
		},
		submitVideo: function() {

			var formData = new FormData();
			formData.append('file', this.video);
			formData.append('type', 'cutter');
			formData.append('titolo', 'Titolo Test');
			this.ux.waiting = true;
			this.$http.post(this.$store.state.apiEndPoint + '/video/upload', formData).then(function(data) {

				console.log(data.data);
				this.$http.post(this.$store.state.apiEndPoint + '/video/cutter/add', {
					titolo: '',
					type: 'cutter',
					fileNameVideo: data.data.path,
					payload: this.payloadTrim,
					width: this.probedVideo.Width,
					height: this.probedVideo.Height,
					fps: this.seekStep,
					codec: this.probedVideo.Format,
					entity: this.entity
					/*entity: {

					}*/
				}).then(function(data2) {
					this.ux.waiting = false;

					this.$emit('refresh');

					console.lod(data2.data);
				});

			});

		}
	}
}
</script>