module.exports = {
    authenticated: false,
    user: {},
    presets: function() {
        if(this.user && this.user.preset)
            return JSON.parse(this.user.preset);
        return {}
    },
    checkLocalStorage()
    {
        if (localStorage.user) {
            this.user = JSON.parse(localStorage.user);
            this.authenticated = true;

            return true;
        }
        else
        {
            this.user = {};
            this.authenticated = false;

            return false;
        }
    },
    refreshUser(vm) {
        vm.$http.post(vm.$store.state.apiEndPoint + '/whoami').then(function(i) {
            this.interpretaUtente(vm, i.data);
        }.bind(this));
    },
    interpretaUtente: function(vm, utente) {

        localStorage.setItem('user', JSON.stringify(utente))
        this.authenticated = true
        this.user = utente
        
        //this.interpretaUtente(response.body);
        // Queste sono logiche dello storage
        if(vm.$store.state.rosaTeamId == null) {
            if(this.user.rosaTeams.length > 0) {
                vm.$store.commit('changeRosaTeamId', this.user.rosaTeams[0].id);
                vm.$store.commit('changeCategoriaId', this.user.rosaTeams[0].categoria.id);
            }
        } else {
            if(this.user.rosaTeams.length > 0) {
                var found_team = false;
                for(var i in this.user.rosaTeams) {
                    var team = this.user.rosaTeams[i];
                    if(team.id == vm.$store.state.rosaTeamId) {
                         found_team = true;
                         break;
                    }
                }
                if (!found_team) {   
                    vm.$store.commit('changeRosaTeamId', this.user.rosaTeams[0].id);
                    vm.$store.commit('changeCategoriaId', this.user.rosaTeams[0].categoria.id);
                }
            } else {
                vm.$store.commit('changeRosaTeamId', null);
                 vm.$store.commit('changeCategoriaId', null);
            }
        }

    },
    login(vm, creds, redirect, caller) {
        vm.$http.post(vm.$store.state.apiEndPoint + '/login', creds).then((response) => {
            // success callback
            if (response.ok)
            {

                // Logiche sul cosa succede dopo il login..
                
                /*
                localStorage.setItem('user', JSON.stringify(response.body))
                this.authenticated = true
                this.user = response.body*/

                this.interpretaUtente(vm, response.body);

                if (redirect) {
                    vm.$router.push(redirect)
                    if (caller != undefined && (typeof caller.resetErrors === 'function')) caller.resetErrors();
                }
                else
                {
                    vm.$router.push('/dashboard')
                    if (caller != undefined && (typeof caller.resetErrors === 'function')) caller.resetErrors();
                }
                /*
                // Queste sono logiche dello storage
                if(vm.$store.state.rosaTeamId == null) {
                    if(this.user.rosaTeams.length > 0) {
                        vm.$store.commit('changeRosaTeamId', this.user.rosaTeams[0].id);
                    }
                } else {
                    if(this.user.rosaTeams.length > 0) {
                        var found_team = false;
                        for(var i in this.user.rosaTeams) {
                            var team = this.user.rosaTeams[i];
                            if(team.id == vm.$store.state.rosaTeamId) {
                                 found_team = true;
                                 break;
                            }
                        }
                        if (!found_team) {   
                            vm.$store.commit('changeRosaTeamId', this.user.rosaTeams[0].id);
                        }
                    } else {
                        vm.$store.commit('changeRosaTeamId', null);
                    }
                }
                //*/
            }
            else
            {
                if (caller != undefined && (typeof caller.resetErrors === 'function')) caller.errors = response.body;
            }
        }, (response) => {
            if (caller != undefined && (typeof caller.resetErrors === 'function')) caller.errors = response.body;
        });
    },

    // To log out
    logout: function(vm) {
        vm.$http.get(vm.$store.state.apiEndPoint + '/logout').then(() => {
        }, () => {
            // success callback
        }, () => {
            // error callback
        });

    //  localStorage.removeItem('user');
        window.localStorage.clear();
        this.user = {};
        this.authenticated = false;
        vm.$router.push('/login')
    },

    isUserEnable(permesso, category = null) {

        if (!this.user) return false;

        return this.user.table_rights.filter(function(e) {
            if(e.permesso !== permesso) {
                return false;
            }
            if(e.hasCategory !== null && category == null) {
                var cat = parseInt(sessionStorage.getItem(`categoriaId`), 10);
            } else {
                var cat = category;
            }
            if(cat != null && (cat == e.categoria_id || this.isAdmin()))
                return true;
            if(cat == null) 
                return true;
            return false;
        }.bind(this)).length > 0;
    },

    isUserEnableOld(permesso) {
        if (this.user) {
            if (this.user.permessi.filter(e => e.nome === permesso).length > 0) {
                return true;
            } 
            for(var index in this.user.gruppi) {
                if (this.user.gruppi[index].permessi.filter(e => e.nome === permesso).length > 0) {
                    return true;
                }  
            }
        }
        return false;
    },

    isAdmin() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 1).length > 0) {
                return true;
            } 
        }
        return false;
    },

    isCoach() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 2).length > 0) {
                return true;
            } 
        }
        return false;
    },

    isOsservCapo() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 3).length > 0) {
                return true;
            } 
        }
        return false;
    },

    isOwner() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 5).length > 0) {
                return true;
            } 
        }
        return false;
    },
    isAdminFootball() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 12).length > 0) {
                return true;
            } 
        }
        return false;
    },
    isPerfezionamento() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 13).length > 0) {
                return true;
            } 
        }
        return false;
    },
    isAcademyCoordinatore() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 16).length > 0) {
                return true;
            } 
        }
        return false;
    },
    isSegretario() {
        if (this.user) {
            if (this.user.gruppi.filter(e => e.id === 7).length > 0) {
                return true;
            } 
        }
        return false;
    },
    isLastSeason(season_id) {
        if (this.user) {
            if (this.user.seasons.length > 0 && this.user.seasons[0].id === season_id) {
                return true;
            } 
        }
        return false;
    },

}
