<style>
.input-group-sm > .input-group-append > .btn { height:auto; }
</style>
<template id="partiteaggiungi">
	<section class="partiteaggiungi">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<form @submit.stop.prevent="handleSubmit">
				<div class="card">
					<div class="card-body">
						<div class="card-title">
							Nuova Partita
						</div>

						<div class="row">

							<div class="col">
								<div class="row">
									<div class="col">
										<fieldset class="form-group">
											<label>Data della partita</label>
											<date-picker :date="form.partita.date" :option="option" :limit="limit"></date-picker>
										</fieldset>
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Fuso orario</label>
											<b-form-input v-model="form.partita.fuso_orario" type="number"></b-form-input>
										</fieldset>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<fieldset class="form-group">
											<label>Tipo partita</label>
											<multiselect v-model="form.partita.type" :options="opts_partite" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false"></multiselect>
										</fieldset>
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Turno</label>
											<b-form-select v-model="form.partita.day">
												<option :value="null">seleziona</option>
												<option v-for="(value, index) in 99" :value="value" :key="'ng_' + index">{{value}}</option>
											</b-form-select>
										</fieldset>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<fieldset class="form-group">
											<label>Competizione</label>
											<b-form-select v-model="form.partita.competition_id">
												<option :value="null">Seleziona</option>
												<option v-for="comp in filtered_competizioni" :value="comp.id">{{ comp.nome }}</option>
											</b-form-select>
										</fieldset>	
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Fase</label>
											<b-form-select v-model="form.partita.fase_id">
												<option :value="null">Seleziona</option>
												<option v-for="fase in competizione_fasi" :value="fase.id">{{ fase.nome }}</option>
											</b-form-select>
										</fieldset>	
									</div>
								</div>
							</div>
							<div class="col">
								<div class="row" >
									<div class="col">
										<fieldset class="form-group">
											<label>Dove si gioca la partita</label>
											<b-form-select v-model="form.partita.home">
												<option :value="null">Seleziona</option>
												<option value="0">casa</option>
												<option value="1">trasferta</option>
											</b-form-select>
										</fieldset>
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Città</label>
											<b-form-input type="text" v-model="form.partita.citta"></b-form-input>
										</fieldset>
									</div>
								</div>
								<div class="row" v-if="form.partita.type && form.partita.type.id == 5">
									<div class="col">
										<fieldset class="form-group">
											<label>Nazionale casa / Goal</label>
											<b-row>
												<b-col>
													<b-form-input type="text" v-model="form.partita.team_home"></b-form-input>
												</b-col>
												<b-col cols="3">
													<b-form-input type="text" v-model="form.partita.score_home"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Nazionale ospite / Goal</label>
											<b-row>
												<b-col>
													<b-form-input type="text" v-model="form.partita.team_away"></b-form-input>
												</b-col>
												<b-col cols="3">
													<b-form-input type="text" v-model="form.partita.score_away"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>
									</div>
								</div>
								<fieldset v-else class="form-group">
									<label style="display:block; margin-bottom:8px;">Avversario</label>
									<b-button v-if="!form.partita.opponent_team" variant="outline-primary" style="width:100%;" @click="selectTeam"> ricerca avversario <i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
									<b-row v-else>
										<b-col>
											<b-form-input type="text" v-model="form.partita.opponent_team.name" disabled></b-form-input>
										</b-col>
										<b-col>
											<b-button variant="outline-primary" style="width:100%;" @click="selectTeam">  cambia avversario <i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
										</b-col>
									</b-row>
								</fieldset>
								<fieldset class="form-group">
									<label>Stadio</label>
									<b-form-input type="text" v-model="form.partita.stadium"></b-form-input>
								</fieldset>	
								
							</div>

							<div class="col">
								<div class="row">
									<div class="col">
										<fieldset class="form-group">
											<label>Condizioni climatiche</label>
											<b-form-select v-model="form.partita.meteo_id">
												<option :value="null">Seleziona</option>
												<option v-for="item in meteo" :value="item.id">{{ item.nome }}</option>
											</b-form-select>
										</fieldset>	
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Condizioni terreno</label>
											<b-form-select v-model="form.partita.condizioni_campo_id">
												<option :value="null">Seleziona</option>
												<option v-for="cond in condizioni_campo" :value="cond.id">{{ cond.nome }}</option>
											</b-form-select>
										</fieldset>	
									</div>
								</div>
								<div class="row">
									<div class="col">
										<fieldset class="form-group">
											<label>Tipologia manto</label>
											<b-form-select v-model="form.partita.tipologia_campo_id">
												<option :value="null">Seleziona</option>
												<option v-for="item in tipi_campo" :value="item.id">{{ item.nome }}</option>
											</b-form-select>
										</fieldset>	
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Diretta TV</label>
											<b-form-input type="text" v-model="form.partita.diretta_tv"></b-form-input>
										</fieldset>	
									</div>
								</div>		
								<div class="row">
									<div class="col">
										<fieldset class="form-group">
											<label>Recupero 1° tempo</label>
											<b-form-input type="number" min="0" v-model="form.partita.recovery_first_half"></b-form-input>
										</fieldset>	
									</div>
									<div class="col">
										<fieldset class="form-group">
											<label>Recupero 2° tempo</label>
											<b-form-input type="number" min="0" v-model="form.partita.recovery_second_half"></b-form-input>
										</fieldset>	
									</div>
								</div>
							</div>
						</div>

						<div class="card mb-2" v-if="!form.partita.type || (form.partita.type && form.partita.type.id != 5)">
							<div class="card-body">

								<div class="card-title">Arbitri</div>
								<div class="row">
									<div class="col pr-5">
										<fieldset class="form-group">
											<label>Arbitro</label>
											<b-row v-for="item, index in form.partita.arbitro">
												<b-col>
													<autocomplete-nome :suggestions="arbitri" :id="'arbitro' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'arbitro'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="arbitro"></autocomplete-nome>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.nome" placeholder="Nome"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.sezione" placeholder="Sezione"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.stato" placeholder="Stato"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>	
										<fieldset class="form-group">
											<label>1° Assistente</label>
											<b-row v-for="item, index in form.partita.primo_assistente">
												<b-col>
													<autocomplete-nome :suggestions="arbitri" :id="'primo_assistente' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'primo_assistente'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="primo_assistente"></autocomplete-nome>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.nome" placeholder="Nome"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.sezione" placeholder="Sezione"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.stato" placeholder="Stato"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>	
										<fieldset class="form-group">
											<label>2° Assistente</label>
											<b-row v-for="item,index in form.partita.secondo_assistente">
												<b-col>
													<autocomplete-nome :suggestions="arbitri" :id="'secondo_assistente' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'secondo_assistente'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="secondo_assistente"></autocomplete-nome>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.nome" placeholder="Nome"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.sezione" placeholder="Sezione"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.stato" placeholder="Stato"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>	
									</div>

									<div class="col">
										<fieldset class="form-group">
											<label>4° Ufficiale</label>
											<b-row v-for="item,index in form.partita.quarto_ufficiale">
												<b-col>
													<autocomplete-nome :suggestions="arbitri" :id="'quarto_ufficiale' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'quarto_ufficiale'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="quarto_ufficiale"></autocomplete-nome>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.nome" placeholder="Nome"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.sezione" placeholder="Sezione"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.stato" placeholder="Stato"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>	
										<fieldset class="form-group">
											<label>VAR</label>
											<b-row v-for="item,index in form.partita.var">
												<b-col>
													<autocomplete-nome :suggestions="arbitri" :id="'var' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'var'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="var"></autocomplete-nome>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.nome" placeholder="Nome"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.sezione" placeholder="Sezione"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.stato" placeholder="Stato"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>	
										<fieldset class="form-group">
											<label>AVAR</label>
											<b-row v-for="item,index in form.partita.avar">
												<b-col>
													<autocomplete-nome :suggestions="arbitri" :id="'avar' + index" @select="onArbitroSelect" @change="onArbitroChange" v-model="item.cognome" cols="10" :ref="'avar'+ index" placeholder="Cognome" field="cognome" :row_index="index" category="avar"></autocomplete-nome>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.nome" placeholder="Nome"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.sezione" placeholder="Sezione"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input type="text" v-model="item.stato" placeholder="Stato"></b-form-input>
												</b-col>
											</b-row>
										</fieldset>	
									</div>
								</div>

							</div>
						</div>
						<div class="row mt-3">
							<div class="col-sm-12 text-right">
								<b-button type="reset" variant="outline-primary">Annulla</b-button>
								<b-button type="submit" variant="primary"><strong class="text-uppercase">Salva partita</strong></b-button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

		<b-modal id="selectTeamModal" ref="selectTeamModal" title="Seleziona squadra" ok-only ok-title="Chiudi" size="lg">
			<div v-if="teams.data.length == 0"><h5>Nessuna squadra trovata!</h5></div>
			<div>
				<div style="height: 250px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
					<b-row class="mb-1">
						<b-col sm="6">
							<b-form-select v-model="filters.categoria">
								<option value="0">-- Categoria --</option>
								<option v-for="item in categories_opt" :value="item.id" :key="'cat_' + item.id">{{item.name}}</option>
							</b-form-select>
						</b-col>
						<b-col sm="6">
							<b-input-group>
								<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
								<b-input-group-append>
									<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
								</b-input-group-append>
							</b-input-group>  	
						</b-col>
					</b-row>	
					<b-row>
						<b-col cols="4" v-for="team in teams.data" v-bind:key="'team_' + team.id" style="padding: 0.2rem">
							<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="setTeam(team)">
								<img :src="team.logo_url" width="50" /><br/>
								<strong>{{team.officialName}}</strong><br/>
								<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
							</b-card>
						</b-col>
					</b-row>	
					<b-row>
						<b-col cols="6" style="padding: 0.2rem">
							<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
						</b-col>	
						
						<b-col cols="6" style="padding: 0.2rem">
							<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
						</b-col>	
					</b-row>				
				</div>
			</div>
		</b-modal>

		<footerapp></footerapp>

	</section>
</template>

<script>
import Multiselect from 'vue-multiselect';
import myDatepicker from 'vue-datepicker';
import axios from 'axios';
import { mapState } from 'vuex';
import * as types from '../../../../store/types';
var Auth = require('../../../../auth.js');

export default {
	components: {
		Multiselect,
		'date-picker': myDatepicker,
		headapp: require('../../../layout/headapp.vue').default,
		menusub: require('../../../layout/menusub.vue').default,
		footerapp: require('../../../layout/footerapp.vue').default,
		card: require('../../../layout/card.vue').default,
		autocompleteNome: require('../../../layout/autocomplete_nome.vue').default,

	},
	data () {
		return {
			categories_opt: [],
			teams: {
				data: []
			},
			opts_partite: [
				{id:1, name:'Campionato'},
				{id:2, name:'Coppa'},
				{id:3, name:'Amichevole'},
				{id:4, name:'Torneo'},
				{id:5, name:'Nazionale'},
				],
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
			option: {
				type: 'min',
				week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
				month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				format: 'DD-MM-YYYY HH:mm',
				inputStyle: {
				},
				color: {
					header: '#a80532',
					headerText: '#ffffff'
				},
				buttons: {
					ok: 'Ok',
					cancel: 'Cancel'
				},
				overlayOpacity: 0.5, // 0.5 as default
				dismissible: true // as true as default
			},
			limit: [],
			form: {
				partita: {
					type: null,
					day: null,
					modulo:	'433',
					modulo_opponent: '433',
					duration: 90,
					players: {id:6, name:'11'},
					opponent_team_id: null,
					opponent_team: null,
					home: null,
					squadra: null,
					formation: 3,
					formation_opponent: 3,
					date: {
						time: ''
					},
					categoria: {
						id:	'',
						name: ''
					},
					competition_id: null,
					fase_id: null,
					stadium: null,
					recovery_first_half: null,
					recovery_second_half: null,
					meteo_id: null,
					condizioni_campo_id: null,
					tipologia_campo_id: null,
					diretta_tv: null,
					citta: null,
					fuso_orario: null,
					arbitro:[{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					primo_assistente: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					secondo_assistente: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					quarto_ufficiale: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					var: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					avar: [{
						cognome: null,
						nome: null,
						sezione: null,
						stato: null,
					}],
					coach_id: null,
					opp_coach: {
						surname: null,
						name: null,
					},
					team_home: null,
					team_away: null,
					score_home: null,
					score_away: null,
				}
			},
			competizioni: [],
			competizione_fasi: [],
			meteo: [],
			condizioni_campo: [],
			tipi_campo: [],
			staff: [],
			team: null,
			arbitri: [],
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		logo_squadra: function() {
			if(this.form.partita.opponent_team) {
				return this.form.partita.opponent_team.logo_url;
			}
			return '/assets/varie/team.png';
		},
		...mapState(['rosaTeamId']),

		filtered_competizioni: function() {
			if(this.form.partita.type) {
				return this.competizioni.filter((item) => item.match_type == this.form.partita.type.id);
			}
			return this.competizioni;
		},
	},

	watch: {
		rosaTeamId() {
			this.getTeam();
			this.getCompetizioni();
			this.getStaff();
		},  
		'form.partita.type': function () {
			if(this.form.partita.type && this.form.partita.type.id == 5) {
				this.form.partita.squadra = this.team.officialName + ' - ' + (this.team.categoria ? this.team.categoria.name : '&nbsp;');
				this.form.partita.opponent_team_id = this.team.id;
				this.form.partita.opponent_team = this.team;

			} else {
				this.form.partita.squadra = null;
				this.form.partita.opponent_team_id = null;
				this.form.partita.opponent_team = null;
			}
		},
	},

	created(){
		this.getTeam();
		this.getStaff();
		this.getCategories();
		this.getTeams();
		this.getCompetizioni();
		this.getMeteo();
		this.getCondizioniCampo();
		this.getTipiCampo();
		this.getCompetizioneFasi();
		this.getArbitri();
	},

	methods: {
		paginate(page) {
			this.page = page;
			this.getTeams();
		},

		setTeam: function(team) {
			this.form.partita.squadra = team.officialName + ' - ' + (team.categoria ? team.categoria.name : '&nbsp;');
			this.form.partita.opponent_team_id = team.id;
			this.form.partita.opponent_team = team;

			if( team.categoria ){
				this.form.partita.categoria.id 	= team.categoria.id
				this.form.partita.categoria.name = team.categoria.name
			}
			if(team.allenatore && team.allenatore.length > 0) {
				this.form.partita.opp_coach.surname = team.allenatore[0].cognome;
				this.form.partita.opp_coach.name = team.allenatore[0].nome;
			}
			this.$refs.selectTeamModal.hide();
		},

		filtraArchivio() {
			this.loading = true;
			this.teams = {
				data: []
			};
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then((response) => {
				if (response.ok)
				{
					this.teams = response.data;
				}
			}, (response) => {
			});
		},

		getCompetizioni: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/football/competizioni/by/category', {params: { team_id: this.rosaTeamId }}).then((response) => {
				if (response.ok)
				{
					this.competizioni = response.data;
				}
			}, (response) => {
			});
		},

		getCompetizioneFasi: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/football/competizione/fasi').then((response) => {
				if (response.ok)
				{
					this.competizione_fasi = response.data;
				}
			}, (response) => {
			});
		},

		getMeteo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/meteo').then((response) => {
				if (response.ok)
				{
					this.meteo = response.data;
				}
			}, (response) => {
			});
		},

		getCondizioniCampo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/condizioni/campo').then((response) => {
				if (response.ok)
				{
					this.condizioni_campo = response.data;
				}
			}, (response) => {
			});
		},

		getTipiCampo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/tipi/campo').then((response) => {
				if (response.ok)
				{
					this.tipi_campo = response.data;    
				}
			}, (response) => {
			});
		},

		getStaff: function() {				
			this.staff = [];
			this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: this.rosaTeamId }}).then((response) => {
				if (response.ok)
				{
					this.staff = response.data;
					var mister = this.staff.find((item) => item.last_carriera_sportiva.ruoli.length > 0 && item.last_carriera_sportiva.ruoli[0].nome == 'Mister');
					if(typeof mister != 'undefined') {
						this.form.partita.coach_id = mister.id;
					} else {
						var allen = this.staff.find((item) => item.last_carriera_sportiva.ruoli.length > 0 && item.last_carriera_sportiva.ruoli[0].nome == 'Allenatore');
						if(typeof allen != 'undefined') {
							this.form.partita.coach_id = allen.id;
						}
					}
					this.staff = this.staff.map(item => ({
						name: item.nome + ' ' + item.cognome,
						id: item.id,
					}));
				}
			}, (response) => {
			});
		},

		selectTeam: function(squadra) {			
			this.$refs.selectTeamModal.show();
		},

		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then((response) => {
				// success callback
				if (response.ok)
				{
					this.teams = response.data;						
				}
			}, (response) => {
				// error callback
			});
		},

		getCategories(){
			axios.get(`/exercise/categories`)
			.then((res) => {

				this.categories_opt = res.data.map(item => ({
					name: item.name,
					id: item.id,
				}));

				this.loading    = false;
			}).catch((err) => {
				this.loading = false;
			});
		},

		handleSubmit: function () {
			this.form.rosaTeamId = this.rosaTeamId;
			this.$http.post(this.$store.state.apiEndPoint + '/football/partita/create', this.form).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ path: `/football/partita/${response.data.id}` });
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},	

		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},
		getArbitri: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/arbitri').then((response) => {
				if (response.ok)
				{
					this.arbitri = response.data;
				}
			}, (response) => {
			});
		},
		onArbitroSelect(id, index, category) {
			if(id > 0 && index > -1) {
				var persona = this.arbitri.find(item => item.id == id);
				if(persona) {
					this.form.partita[category][index].cognome = persona.cognome;
					this.form.partita[category][index].nome = persona.nome;
					this.form.partita[category][index].sezione = persona.arbitro.sezione;
					this.form.partita[category][index].stato = persona.arbitro.stato;
				}
			}
		},
		onArbitroChange(value, index, category) {
			this.form.partita[category][index].cognome = value;
			this.form.partita[category][index].nome = null;
			this.form.partita[category][index].sezione = null;
			this.form.partita[category][index].stato = null;
		},	
	}
}
</script>
