<template id="partite">
	<section class="partite">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Partite ({{ total }})</span>
					</div>
					<div class="mb-1">
						<b-btn v-b-toggle.collapse variant="primary">Filtra <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
						<b-collapse id="collapse" class="mt-2">
							<b-row class="mb-1">
								<b-col sm="2">
									<b-form-group label-cols="3" content-cols="9" label="Data">
										<b-form-input type="date" v-model="filters.data"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="2">
									<b-input-group>
										<b-form-input type="text" v-model="filters.squadra" placeholder="Squadra"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="filtraPartite"><i class="fa fa-search" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-col>
							</b-row>
						</b-collapse>
					</div>
				</div>
			</div>


				<v-infinite-scroll :loading="loading" @bottom="scrollPartite" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
					<div v-for="partita in partite" class="col-3 mb-1">
						<div class="card h-100">
							<div class="card-body">
								<div>
									<span class="mr-2 color-rosso"><h5>{{ partita.competizione.paese.paese }} - {{ partita.competizione.nome }}</h5></span>
								</div>

								<div class="cardpartita">
									<a :href="'#/newscout/partita/' + partita.id" style="text-decoration:none">
										<div class="versus">
											<div class="row flex-items-xs-middle flex-items-xs-center text-sm-center">
												<div class="col-5" v-if="getTeamHome(partita)">

													<div><b-img :src="getTeamHome(partita).logo_url" fluid :alt="getTeamHome(partita).officialName" :width="80"></b-img></div>
													<div>
														<span>{{ getTeamHome(partita).officialName }}</span>
													</div>

												</div>
												<div class="col-5" v-else>	
													 Home
												</div>
												<div class="col-2">

													<div class="versus__vs">
														<span>VS.</span>
													</div>
													<div class="text-center mt-4">
														<span class="color-rosso pl-1">{{ partita.score }}</span>
													</div>
												</div>
												<div class="col-5" v-if="getTeamAway(partita)">

													<div><b-img :src="getTeamAway(partita).logo_url" fluid :alt="getTeamAway(partita).officialName" :width="80"></b-img></div>
													<div>
														<span>{{ getTeamAway(partita).officialName }}</span>
													</div>

												</div>
												<div class="col-5" v-else>	
													Away
												</div>
											</div>
										</div>
									</a>
									<div class="row">
										<div class="col-12 text-sm-center">
											{{ partita.data | formatDateHour }}
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>

					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>

				</v-infinite-scroll>	
			</div>

			<footerapp></footerapp>

		</section>
	</template>

	<script>

	import moment from 'moment'

	import * as types from '../../../store/types'

	import InfiniteScroll from 'v-infinite-scroll'

	var Auth = require('../../../auth.js');

	export default {

		data: function() {
			return {
				partite: [],
				osservatori: [],
				filters: {
					data: '',
					squadra: '',
					osservatore: 0,
				},
				page: 1,
				total: 0,
			}
		},

		components: {
			headapp:             require('../../layout/headapp.vue').default,
			menusub:             require('../../layout/menusub.vue').default,
			footerapp:           require('../../layout/footerapp.vue').default,
			flag:                require('../../layout/flag.vue').default,
			'v-infinite-scroll': InfiniteScroll,
		},

		created: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/osservatori/newscout').then((response) => {
				if (response.ok)
				{
					this.osservatori = response.data; 
				}
			}, (response) => {
			});
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/archivio/video/new').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.partite = response.data.data; 
            	this.total = response.data.total;
            	this.loading = false;
            }
        }, (response) => {
        }); 
		},
		methods: {
			scrollPartite() {
				if(this.partite.length < this.total) {
					this.page++;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/archivio/video/new?page=' + this.page, {params: this.filters}).then((response) => {
						if (response.ok)
						{
							this.partite = this.partite.concat(response.data.data);
							this.loading = false;
						}
					}, (response) => {
					});
				}
			},
			filtraPartite() {
				this.partite = [];
				this.total = 0;
				this.loading = true;
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/archivio/video/new', {params: this.filters}).then((response) => {
					if (response.ok)
					{
						this.partite = response.data.data;
						this.total = response.data.total;
						this.loading = false; 
					}
				}, (response) => {
				});
			},

			getTeamHome(partita) {
				var squadre = partita.squadre;
				if(squadre && squadre.length > 1) {
					if(squadre[0].pivot.side == 'home') {
						return squadre[0];
					} else {
						return squadre[1];
					}
				}
				return null;
			},

			getTeamAway(partita) {
				var squadre = partita.squadre;
				if(squadre && squadre.length > 1) {
					if(squadre[0].pivot.side == 'home') {
						return squadre[1];
					} else {
						return squadre[0];
					}
				}
				return null;
			},

			getOsservatoriPartita(partita) {
				var label_utenti = [];
				for(var i in partita.osservati_valutazioni_partite) {
					var val = partita.osservati_valutazioni_partite[i].valutazione;
					if(val) {
						var utente = val.utente.nomeCompleto;
						if(!label_utenti.includes(utente)) {
							label_utenti.push(utente);
						}
					}
				}
				return label_utenti;
			},
			
		},
		watch: {
			'filters.data': function () {
				this.filtraPartite();
			},
			'filters.osservatore': function () {
				this.filtraPartite();
			},
		},
		filters: {
			formatDateHour: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('DD/MM/YYYY HH:mm'); 
				}
				return "";
			},

		},
		computed: {
			auth: function() {
				return Auth;
			},
		},
	}
	</script>