/* eslint-disable no-underscore-dangle */

import uuid from 'uuid/v4';
import { fabric } from 'fabric';

fabric.TriangleHead = fabric.util.createClass(fabric.Triangle, {
  type: 'triangle-head',
  name: 'head',

  id: null,
  groupId: null,
  line: null,
  centerPoint: null,
  tail: null,
  hasBorders: false,
  hasControls: false,
  pointType: null,
  originX: 'center',
  originY: 'center',

  toObject(propertiesToInclude) {
    return this.callSuper(
      'toObject',
      ['line', 'centerPoint', 'tail', 'id', 'groupId', 'pointType', 'name'].concat(
        propertiesToInclude,
      ),
    );
  },
});

fabric.TriangleHead.fromObject = function fromObject(object, callback) {
  return fabric.Object._fromObject('TriangleHead', object, callback);
};

fabric.CircleTail = fabric.util.createClass(fabric.Circle, {
  type: 'circle-tail',
  name: 'tail',

  id: null,
  groupId: null,
  head: null,
  line: null,
  centerPoint: null,
  hasBorders: false,
  hasControls: false,
  pointType: null,
  originX: 'center',
  originY: 'center',

  toObject(propertiesToInclude) {
    return this.callSuper(
      'toObject',
      ['line', 'centerPoint', 'head', 'id', 'groupId', 'pointType', 'name'].concat(
        propertiesToInclude,
      ),
    );
  },
});

fabric.CircleTail.fromObject = function fromObject(object, callback) {
  return fabric.Object._fromObject('CircleTail', object, callback);
};

fabric.CircleCenterPoint = fabric.util.createClass(fabric.Circle, {
  type: 'circle-center-point',
  name: 'centerPoint',

  id: null,
  groupId: null,
  head: null,
  line: null,
  tail: null,
  hasBorders: false,
  hasControls: false,
  originX: 'center',
  originY: 'center',

  toObject(propertiesToInclude) {
    return this.callSuper(
      'toObject',
      ['line', 'tail', 'head', 'id', 'groupId', 'name'].concat(propertiesToInclude),
    );
  },
});

fabric.CircleCenterPoint.fromObject = function fromObject(object, callback) {
  return fabric.Object._fromObject('CircleCenterPoint', object, callback);
};

fabric.BezierPath = fabric.util.createClass(fabric.Path, {
  type: 'bezier-path',
  name: 'bezierArrow',

  id: null,
  groupId: null,
  head: null,
  tail: null,
  centerPoint: null,

  objectCaching: false,
  selectable: false,

  initialize(path, options) {
    this.callSuper('initialize', path, options);
    const dims = this._calcDimensions();
    this.left = dims.left;
    this.top = dims.top;
    this.width = dims.width;
    this.height = dims.height;
    this.pathOffset = {
      x: this.left + this.width / 2,
      y: this.top + this.height / 2,
    };
  },

  toObject(propertiesToInclude) {
    return this.callSuper(
      'toObject',
      ['centerPoint', 'tail', 'head', 'id', 'groupId', 'pathOffset'].concat(propertiesToInclude),
    );
  },
});

fabric.BezierPath.fromObject = function fromObject(object, callback) {
  fabric.Object._fromObject('BezierPath', object, callback, 'path');
};

fabric.ArrowLine = fabric.util.createClass(fabric.Line, {
  type: 'arrow-line',
  pointType: 'arrowLine',

  id: null,
  groupId: null,
  head: null,
  tail: null,

  selectable: false,
  strokeWidth: 1,

  initialize(path, options) {
    this.callSuper('initialize', path, options);
    const points = this.calcLinePoints();
    this.x1 = points.x1;
    this.x2 = points.x2;
    this.y1 = points.y1;
    this.y2 = points.y2;
  },

  toObject(propertiesToInclude) {
    return this.callSuper(
      'toObject',
      ['tail', 'head', 'id', 'groupId', 'pointType'].concat(propertiesToInclude),
    );
  },
});

fabric.ArrowLine.fromObject = function fromObject(object, callback) {
  function _callback(instance) {
    delete instance.points; // eslint-disable-line no-param-reassign
    if (callback) callback(instance);
  }
  const options = fabric.util.object.clone(object, true);
  options.points = [object.x1, object.y1, object.x2, object.y2];
  fabric.Object._fromObject('ArrowLine', options, _callback, 'points');
};

fabric.ObjectImage = fabric.util.createClass(fabric.Image, {
  type: 'object-image',

  originX: 'center',
  originY: 'center',

  toObject(propertiesToInclude) {
    return this.callSuper('toObject', propertiesToInclude);
  },
});

fabric.ObjectImage.fromObject = function fromObject(_object, callback) {
  const object = fabric.util.object.clone(_object);
  fabric.util.loadImage(
    object.src,
    (img, error) => {
      if (error) {
        if (callback) callback(null, error);
        return;
      }
      fabric.Image.prototype._initFilters.call(object, object.filters, filters => {
        object.filters = filters || [];
        fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], resizeFilters => {
          [object.resizeFilter] = resizeFilters;
          const image = new fabric.Image(img, object);
          image.setControlsVisibility({
            mt: false,
            mb: false,
            mr: false,
            ml: false,
          });
          callback(image);
        });
      });
    },
    null,
    { crossOrigin: 'Anonymous' },
  );
};

fabric.Arrow = fabric.util.createClass(fabric.Line, fabric.Observable, {
  initialize(e, t) {
    this.callSuper('initialize', e, t);
    this.set({ type: 'arrow' });
  },

  _render(e) {
    e.beginPath();
    const r = this.calcLinePoints();
    const headlen = 8; // length of head in pixels
    const angle = Math.atan2(r.y2 - r.y1, r.x2 - r.x1);
    e.moveTo(r.x1, r.y1);
    e.lineTo(r.x2, r.y2);
    e.lineTo(
      r.x2 - headlen * Math.cos(angle - Math.PI / 6),
      r.y2 - headlen * Math.sin(angle - Math.PI / 6),
    );
    e.moveTo(r.x2, r.y2);
    e.lineTo(
      r.x2 - headlen * Math.cos(angle + Math.PI / 6),
      r.y2 - headlen * Math.sin(angle + Math.PI / 6),
    );

    e.lineWidth = this.strokeWidth;
    const s = e.strokeStyle;
    e.strokeStyle = this.stroke || e.fillStyle;
    if (this.stroke) this._renderStroke(e);
    e.strokeStyle = s;
  },

  complexity() {
    return 2;
  },
});

fabric.Arrow.fromObject = function fromObject(e) {
  const n = [e.x1, e.y1, e.x2, e.y2];
  return new fabric.Arrow(n, e);
};

function makeArrowHead(left, top, line, centerPoint, tail, color) {
  const t = new fabric.TriangleHead({
    left,
    top,
    width: 10,
    height: 10,
    fill: color,
  });

  t.line = line;
  t.centerPoint = centerPoint;
  t.tail = tail;

  return t;
}

function makeArrowTail(left, top, head, line, centerPoint, color) {
  const c = new fabric.CircleTail({
    left,
    top,
    radius: 5,
    fill: color,
  });

  c.head = head;
  c.line = line;
  c.centerPoint = centerPoint;

  return c;
}

function makeCurvePoint(left, top, head, line, tail) {
  const c = new fabric.CircleCenterPoint({
    left,
    top,
    strokeWidth: 4,
    radius: 5,
    fill: 'white',
    stroke: 'black',
  });

  c.head = head;
  c.line = line;
  c.tail = tail;

  return c;
}

export function findAngle(x, y) {
  let angle;

  if (x === 0) {
    if (y === 0) {
      angle = 0;
    } else if (y > 0) {
      angle = Math.PI / 2;
    } else {
      angle = (Math.PI * 3) / 2;
    }
  } else if (y === 0) {
    if (x > 0) {
      angle = 0;
    } else {
      angle = Math.PI;
    }
  } else if (x < 0) {
    angle = Math.atan(y / x) + Math.PI;
  } else if (y < 0) {
    angle = Math.atan(y / x) + 2 * Math.PI;
  } else {
    angle = Math.atan(y / x);
  }

  return (angle * 180) / Math.PI;
}

export default fabric;

export function setHeadAngle(head, centerPoint) {
  const x = centerPoint.left - head.left;
  const y = centerPoint.top - head.top;
  // eslint-disable-next-line no-param-reassign
  head.angle = findAngle(x, y) - 90;
}

export function drawCurvedArrow(color, canvas, pointer, points = null) {
  const id = uuid();

  const p1 = {
    x: points ? points.tx : pointer.x - 50,
    y: points ? points.ty : pointer.y,
  };
  const c = {
    x: points ? points.cx : pointer.x,
    y: points ? points.cy : pointer.y + 50,
  };
  const p2 = {
    x: points ? points.hx : pointer.x + 50,
    y: points ? points.hy : pointer.y,
  };
  const line = new fabric.BezierPath(`M ${p1.x} ${p1.y} Q ${c.x} ${c.y} ${p2.x} ${p2.y}`, {
    fill: '',
    stroke: color,
    objectCaching: false,
    //  padding: 1,
  });

  line.id = `bezier-arrow-${id}`;
  line.groupId = id;
  canvas.add(line);

  const centerPoint = makeCurvePoint(c.x, c.y, null, line.id, null);
  centerPoint.id = `center-point-${id}`;
  centerPoint.groupId = id;
  centerPoint.opacity = 0;
  canvas.add(centerPoint);

  const tail = makeArrowTail(p1.x, p1.y, null, line.id, centerPoint.id, color);
  tail.id = `tail-${id}`;
  tail.groupId = id;
  canvas.add(tail);

  const head = makeArrowHead(p2.x, p2.y, line.id, centerPoint.id, tail.id, color);
  head.id = `head-${id}`;
  head.groupId = id;

  setHeadAngle(head, centerPoint);

  tail.head = head.id;
  centerPoint.head = head.id;
  centerPoint.tail = tail.id;
  line.tail = tail.id;
  line.head = head.id;
  line.centerPoint = centerPoint.id;

  canvas.add(head);

  return id;
}

export function drawLine(canvas, color, dashed, pointer, points = null, angle = null) {
  const id = uuid();

  const p1 = {
    x: points ? points.x1 : pointer.x - 50,
    y: points ? points.y1 : pointer.y,
  };
  const p2 = {
    x: points ? points.x2 : pointer.x + 50,
    y: points ? points.y2 : pointer.y,
  };
  const line = new fabric.ArrowLine([p1.x, p1.y, p2.x, p2.y], {
    stroke: color,
    fill: color,
  });

  line.id = `line-${id}`;
  line.groupId = id;
  if (dashed) line.strokeDashArray = [5, 5];

  const head = new fabric.TriangleHead({
    left: p2.x,
    top: p2.y,
    originX: 'center',
    originY: 'center',
    opacity: 1,
    width: 10,
    height: 10,
    fill: color,
    angle: angle || 90,
  });

  head.name = 'arrowStart';
  head.line = line.id;
  head.pointType = 'arrowStart';
  head.id = `head-${id}`;
  head.groupId = id;

  const tail = new fabric.CircleTail({
    left: p1.x,
    top: p1.y,
    radius: 4,
    opacity: 1,
    fill: color,
  });

  tail.line = line.id;
  tail.head = head.id;
  tail.name = 'arrowEnd';
  tail.pointType = 'arrowEnd';
  tail.id = `tail-${id}`;
  tail.groupId = id;
  head.tail = tail.id;
  line.head = head.id;
  line.tail = tail.id;

  canvas.add(line);
  canvas.add(head);
  canvas.add(tail);

  return id;
}

export function drawTriangle(canvas, color, pointer) {
  const triangle = new fabric.Triangle({
    width: 30,
    height: 30,
    left: pointer.x,
    top: pointer.y,
    originX: 'center',
    originY: 'center',
    fill: 'rgba(0, 0, 0, 0)',
    stroke: color,
    strokeWidth: 1,
    cornerSize: 8,
    cornerStyle: 'circle',
    cornerColor: 'black',
    borderColor: 'black',
    transparentCorners: false,
    padding: 1,
    borderDashArray: [3, 3],
  });

  canvas.add(triangle);
}

export function drawRect(canvas, color, pointer) {
  const rect = new fabric.Rect({
    width: 30,
    height: 30,
    left: pointer.x,
    top: pointer.y,
    originX: 'center',
    originY: 'center',
    fill: 'rgba(0, 0, 0, 0)',
    stroke: color,
    strokeWidth: 1,
    cornerSize: 8,
    cornerStyle: 'circle',
    cornerColor: 'black',
    borderColor: 'black',
    transparentCorners: false,
    padding: 1,
    borderDashArray: [3, 3],
  });

  canvas.add(rect);
}

export function drawPolygon(canvas, color, pointer, shape = 'rect', transparency = 0.5) {
  let points = [];
  if (shape === 'rect') {
    points = [
      { x: pointer.x, y: pointer.y },
      { x: pointer.x + 50, y: pointer.y },
      { x: pointer.x + 50, y: pointer.y + 50 },
      { x: pointer.x, y: pointer.y + 50 },
    ];
  } else if (shape === 'triangle') {
    points = [
      { x: pointer.x, y: pointer.y },
      { x: pointer.x - 25, y: pointer.y + 50 },
      { x: pointer.x + 25, y: pointer.y + 50 },
    ];
  }
  const rect = new fabric.Polygon(points, {
    fill: color,
    strokeWidth: 1,
    stroke: color,
    opacity: transparency,
    objectCaching: false,
    hasBorders: false,
    hasRotatingPoint: false,
    perPixelTargetFind: true,
  });

  rect.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: false,
  });

  canvas.add(rect);
}

export function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
  const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
  return fabric.util.transformPoint(
    { x, y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix(),
    ),
  );
}

export function actionHandler(eventData, transform, x, y) {
  const polygon = transform.target;
  const currentControl = polygon.controls[polygon.__corner];
  const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
  const polygonBaseSize = polygon._getNonTransformedDimensions();
  const size = polygon._getTransformedDimensions(0, 0);
  const finalPointPosition = {
    x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
    y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y,
  };
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

export function anchorWrapper(anchorIndex, fn) {
  return (eventData, transform, x, y) => {
    const fabricObject = transform.target;
    const absolutePoint = fabric.util.transformPoint(
      {
        x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
        y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
      },
      fabricObject.calcTransformMatrix(),
    );
    const actionPerformed = fn(eventData, transform, x, y);
    // eslint-disable-next-line no-unused-vars
    const newDim = fabricObject._setPositionDimensions({});
    const polygonBaseSize = fabricObject._getNonTransformedDimensions();
    const newX =
      (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
    const newY =
      (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  };
}

export function drawFlatLine(canvas, color, dashed = false, pointer) {
  const options = {
    fill: color,
    stroke: color,
    strokeWidth: 2,
    cornerSize: 8,
    cornerStyle: 'circle',
    cornerColor: 'black',
    borderColor: 'black',
    transparentCorners: false,
    padding: 1,
    borderDashArray: [3, 3],
    noScaleCache: false,
    strokeUniform: true,
  };
  if (dashed) options.strokeDashArray = [5, 5];

  const line = new fabric.Line(
    [
      pointer.x - 50, // x1
      pointer.y, // y1
      pointer.x + 50, // x2
      pointer.y, // y2
    ],
    options,
  );

  canvas.add(line);
}

export function drawDashedFlatLine(canvas, color) {
  drawFlatLine(canvas, color, true);
}

export function drawWavyLineWithArrow(points, color, dashed) {
  return new fabric.WavyLineWithArrow(points, {
    strokeWidth: 2,
    stroke: color,
    objectCaching: false,
    selectable: false,
    strokeDashArray: dashed ? [5, 5] : null,
    hoverCursor: 'default',
    perPixelTargetFind: true,
  });
}

fabric.WavyLineWithArrow = fabric.util.createClass(fabric.Line, {
  type: 'wavy-line-with-arrow',

  initialize: function initialize(element, options) {
    if (!options) {
      options = {}; // eslint-disable-line no-param-reassign
    }
    this.callSuper('initialize', element, options);

    // Set default options
    this.set({
      hasBorders: true,
      hasControls: true,
      cornerSize: 8,
      cornerStyle: 'circle',
      cornerColor: 'black',
      borderColor: 'black',
      transparentCorners: false,
      padding: 1,
      borderDashArray: [3, 3],
    });
  },

  _render: function _render(ctx) {
    // this.callSuper('_render', ctx);
    ctx.save();
    const xDiff = this.x2 - this.x1;
    const yDiff = this.y2 - this.y1;
    const angle = Math.atan2(yDiff, xDiff);
    ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
    ctx.rotate(angle);
    ctx.beginPath();
    // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
    ctx.moveTo(5, 0);
    ctx.lineTo(-5, 5);
    ctx.lineTo(-5, -5);
    ctx.closePath();
    ctx.fillStyle = this.stroke; // eslint-disable-line no-param-reassign
    ctx.fill();
    ctx.restore();
    const p = this.calcLinePoints();
    const point = this.pointOnLine(this.point(p.x2, p.y2), this.point(p.x1, p.y1), 10);
    this.wavy(this.point(p.x1, p.y1), point, this.point(p.x2, p.y2), ctx);
    ctx.strokeStyle = this.stroke; // eslint-disable-line no-param-reassign
    ctx.lineWidth = 2;
    ctx.stroke();
  },

  point: function point(x, y) {
    return {
      x,
      y,
    };
  },

  wavy: function wavy(from, to, endPoint, ctx) {
    let cx = 0;
    let cy = 0;
    let i = 0;
    let waveOffsetLength = 0;

    const fx = from.x;
    const fy = from.y;
    const tx = to.x;
    const ty = to.y;
    const step = 4;
    const ang = Math.atan2(ty - fy, tx - fx);
    const distance = Math.sqrt((fx - tx) * (fx - tx) + (fy - ty) * (fy - ty));
    const amplitude = -10;
    const f = (Math.PI * distance) / 30;

    for (i; i <= distance; i += step) {
      waveOffsetLength = Math.sin((i / distance) * f) * amplitude;
      cx = from.x + Math.cos(ang) * i + Math.cos(ang - Math.PI / 2) * waveOffsetLength;
      cy = from.y + Math.sin(ang) * i + Math.sin(ang - Math.PI / 2) * waveOffsetLength;
      if (i > 0) {
        ctx.lineTo(cx, cy);
      } else {
        ctx.moveTo(cx, cy);
      }
    }
    if (this.strokeDashArray) {
      ctx.setLineDash([5]);
    }
    ctx.lineTo(to.x, to.y);
    ctx.lineTo(endPoint.x, endPoint.y);
  },

  pointOnLine: function pointOnLine(point1, point2, dist) {
    const len = Math.sqrt(
      (point2.x - point1.x) * (point2.x - point1.x) + (point2.y - point1.y) * (point2.y - point1.y),
    );
    const t = dist / len;
    const x3 = (1 - t) * point1.x + t * point2.x;
    const y3 = (1 - t) * point1.y + t * point2.y;
    return new fabric.Point(x3, y3);
  },

  toObject: function toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      customProps: this.customProps,
    });
  },
});

fabric.WavyLineWithArrow.fromObject = function fromObject(object, callback) {
  function _callback(instance) {
    delete instance.points; // eslint-disable-line no-param-reassign
    if (callback) callback(instance);
  }
  const options = fabric.util.object.clone(object, true);
  options.points = [object.x1, object.y1, object.x2, object.y2];
  fabric.Object._fromObject('WavyLineWithArrow', options, _callback, 'points');
};
