<template id="view_giocatore">
	<section class="giocatore">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">

					<div class="row flex-items-xs-middle">
						<div class="col-4">

							<div class="text-uppercase color-rosso font-bold font-size-lg">
								{{osservato.cognome}} {{osservato.nome}}
							</div>
							<div class="text-muted">
								{{ osservato.last_carriera_sportiva &&
								osservato.last_carriera_sportiva.ruolo_ideale && osservato.last_carriera_sportiva.ruolo_ideale.length > 0 ? osservato.last_carriera_sportiva.ruolo_ideale[0].nome : ''}}
							</div>

						</div>
						<div class="col-8 text-right">
							<ul class="list-inline mb-0">
								<li class="list-inline-item" v-if="osservato.valutazioni.length > 0">
									<b-button :to="{ name: 'usascout_view_valutazione', params: { id: osservato.id, val_id: osservato.valutazioni[0].id }}" variant="outline-primary">Valutazioni</b-button>
								</li>
								<!--li class="list-inline-item" v-if="osservato.valutazioni.length == 0">
									<b-button :to="{ name: 'usascout_nuova_valutazione', params: { id: osservato.giocatore.id }}" variant="outline-primary">Valutazioni</b-button>
								</li>
								<li class="list-inline-item" v-if="auth.isAdmin() && osservato.esterno">
									<b-button @click="importa" variant="outline-primary">Importa in Scouting AS Roma</b-button>
								</li-->
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="card h-100">
						<div class="card-body">
							<div class="row flex-items-xs-middle">
								<div class="col-4 offset-sm-1">
									<b-img :src="osservato.avatar_url" fluid :alt="osservato.cognome + ' ' + osservato.nome" :width="130"></b-img>
								</div>
								<div class="col-6 offset-sm-1">

									<table class="table table-sm table-giocatore">
										<tr> <td>Cognome</td> <td>{{ osservato.cognome }}</td> </tr>
										<tr> <td>Nome</td> <td>{{ osservato.nome }}</td> </tr>
										<tr> <td>Altezza</td> <td>{{ osservato.info_fisica ? osservato.info_fisica.altezza : '' }} cm</td> </tr>
										<tr> <td>Peso</td> <td>{{ osservato.info_fisica ? osservato.info_fisica.peso : '' }} kg</td> </tr>
										<tr> <td>Piede</td> <td>{{ osservato.giocatore ? osservato.giocatore.label_piede : '' }}</td> </tr>
										<tr> <td>Data di nascita</td> <td>{{osservato.nascita_data | formatDate}}</td> </tr>
										<tr> <td>Paese di nascita</td> <td><flag :country="osservato.nascita_paese ? osservato.nascita_paese.sigla : ''" :size="16"></flag> {{osservato.nascita_paese ? osservato.nascita_paese.paese : ''}}</td> </tr>
										<tr> <td>Squadra</td> <td>{{ osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.squadra ? osservato.last_carriera_sportiva.squadra.officialName : ''}}<br/><b-img :src="osservato.last_carriera_sportiva.squadra.logo_url" :alt="osservato.last_carriera_sportiva.squadra.officialName" :width="60" v-if="osservato.last_carriera_sportiva && osservato.last_carriera_sportiva.squadra"></b-img></td> </tr>
									</table>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="card h-100">
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<b-tabs>
										<b-tab :title="item[$i18n.t('lang')]" v-for="(item, index) in skills" :key="item">
											<radarchart :radarid="'radar_' + index" :datachart="radardatachart(item)"></radarchart>
										</b-tab>
									</b-tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<card class="h-100" title="Carriera" comp="gcarriera" :params="{'carriera': carriera, 'count_rows': (osservato.valutazioni && osservato.valutazioni.length > 4 ? osservato.valutazioni.length - 1 : 3 )}"></card>
				</div>
				<div class="col">
					<card class="h-100" title="Valutazioni" comp="scoutvalutazioni" :params="{'valutazioni': osservato.valutazioni, 'giocatore_id': osservato.id, 'academy': 2}"></card>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import * as types from '../../../store/types';
import moment from 'moment';

export default {
	data: function() {
		return {
			osservato: [],
			skills: [],	
			carriera: [],
		}
	},

	components: {
		headapp: 	require('../../layout/headapp.vue').default,
		menusub:    require('../../layout/menusub.vue').default,
		footerapp:  require('../../layout/footerapp.vue').default,

		flag: 		require('../../layout/flag.vue').default,
		radarchart: 	require('../../layout/charts/radar.vue').default,
		card: 		require('../../layout/card.vue').default,
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/usascout/view/giocatore/' + this.$route.params.id).then((response) => {
			if (response.ok) {
				this.osservato = response.data; 
				if(this.osservato.valutazioni && this.osservato.valutazioni.length > 0) {
					var last_val = this.osservato.valutazioni[0];
					if(this.osservato.last_carriera_sportiva.ruolo_ideale && this.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 && this.osservato.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
						this.skills = JSON.parse(last_val.fields.special);
					} else {
						this.skills = JSON.parse(last_val.fields.standard);
					}
				} else {
					this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
						if (response.ok) {
							if(this.osservato.last_carriera_sportiva.ruolo_ideale && this.osservato.last_carriera_sportiva.ruolo_ideale.length > 0 && this.osservato.last_carriera_sportiva.ruolo_ideale[0].id == 3) {
								this.skills = JSON.parse(response.data.special);
							} else {
								this.skills = JSON.parse(response.data.standard);
							}
						}
					}, (response) => {

					});  
				}


				if(this.osservato.wyscout) {
					this.$http.get(this.$store.state.apiEndPoint + '/ws/get/career/player/' + this.osservato.wyscout.chiave).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.carriera = response.data;    
            }
        }, (response) => {
            // error callback
        });  
				}
			}
		}, (response) => {
            // error callback
        });
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	}, 
	methods: {
		radardatachart: function(cat) {
			var array_skill_id = [];
			var array_voti_skill = [];
			var array_count_skill = [];
			var data = {
				labels: [],
				datasets: [{
					label: '',
					data: [],
					backgroundColor: '',
					borderColor: '',
					pointBackgroundColor: '',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: '',
				}]
			};
			data.datasets[0].label = cat[this.$i18n.t('lang')];
			data.datasets[0].backgroundColor = this.backgroudColorRadar(cat.id);
			data.datasets[0].borderColor = this.borderColorRadar(cat.id);
			data.datasets[0].pointBackgroundColor = data.datasets[0].borderColor;
			data.datasets[0].pointHoverBorderColor = data.datasets[0].borderColor;
			for (var j in cat.fields) {
				data.labels.push(cat.fields[j][this.$i18n.t('lang')]);
				var id_skill  = cat.fields[j]['id'];
				array_skill_id.push(id_skill);
				array_voti_skill[id_skill] = 0;
				array_count_skill[id_skill] = 0;
				for(var i in this.osservato.valutazioni) {
					var voto = 0;
					var count = 0;
					var voto2 = 0;
					var count2 = 0;
					var arrayVoto = [];
					var arrayCount = [];
					if(this.osservato.valutazioni[i].skills != "") {
						var skill_val = JSON.parse(this.osservato.valutazioni[i].skills);
						var cat_skills = skill_val[cat.id];
						for(var item in cat_skills) {
							if(item == id_skill) {
								var item2 = cat_skills[item];
								if(item2 instanceof Object) {

									for(var item3 in item2) {
										var item4 = item2[item3];

										if(item4 instanceof Object) {
											voto2 = 0;
											count2 = 0;
											for(var item5 in item4) {
												if(item4[item5] > 0) {
													voto2+= item4[item5];
													count2++;
												}
											}
											arrayVoto.push(voto2);
											arrayCount.push(count2);
										} else if(item4 > 0) {
											voto += item4;
											count++;
										}
									}
									if(arrayVoto.length > 0) {
										var votoTot = 0; 
										var countTot = 0;
										if(voto > 0) {
											votoTot = voto/count;
											countTot++;
										}
										for(var index in arrayVoto) {
											if(arrayVoto[index] > 0) {
												votoTot += arrayVoto[index]/arrayCount[index];
												countTot++;
											}            
										}
										if(votoTot > 0) {
											voto = votoTot/countTot;
										}
									} else if(voto > 0) {
										voto = voto/count;
									}
									if(voto > 0) {
										array_voti_skill[id_skill] +=  voto;
										array_count_skill[id_skill] ++;
									}
								} else {
									if(item2 > 0) {
										array_voti_skill[id_skill] +=  item2;
										array_count_skill[id_skill] ++;
									}
								}
								break;
							}
						}
					}
				}
			}
			for(var index in array_skill_id) {
				var id = array_skill_id[index];
				var voto = array_voti_skill[id];
				if(voto > 0) {
					voto = voto / array_count_skill[id];
					voto = Math.round(voto * 100) / 100;
				}
				data.datasets[0].data.push(voto);
			}
			return data;	
		},

		backgroudColorRadar: function(cat_id) {
			if(cat_id == -1) {
				return "rgba(255, 0, 89, 0.2)";
			} else if(cat_id == -2) {
				return "rgba(255, 219, 0, 0.2)";
			} else if(cat_id == -3) {
				return "rgba(46, 200, 91, 0.2)";
			} else if(cat_id == -4) {
				return "rgba(0, 0, 255, 0.2)";
			} else if(cat_id == -5) {
				return "rgba(153, 0, 255, 0.2)";
			} else if(cat_id == -6) {
				return "rgba(255, 153, 0, 0.2)";
			}
			return "rgba(168, 5, 50, 0.2)";	
		},

		borderColorRadar: function(cat_id) {
			if(cat_id == -1) {
				return "rgb(255, 0, 89)";
			} else if(cat_id == -2) {
				return "rgb(255, 219, 0)";
			} else if(cat_id == -3) {
				return "rgb(46, 200, 91)";
			} else if(cat_id == -4) {
				return "rgb(0, 0, 255)";
			} else if(cat_id == -5) {
				return "rgb(153, 0, 255)";
			} else if(cat_id == -6) {
				return "rgb(255, 153, 0)";
			}
			return "rgb(168, 5, 50)";	
		}, 
		importa() {
			if(confirm("Sei sicuro di voler importare il giocatore sullo scouting AS Roma?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/academy/esporta/osservato/' + this.osservato.id).then((response) => {

					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
            			this.$router.push({ name: "usascout_dashboard"}); 
					}
				}, (response) => {

				});
			}
		}, 
	},
	computed: {
		auth: function() {
			return Auth;
		},
	}
}
</script>
