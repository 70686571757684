<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Documenti ({{ totalRows }})</span>
					</div>
					<div class="content">
						<b-row class="mb-3" align-v="center">
							<b-col md="1">
								Filtra per:
							</b-col>
							<b-col md="2">
								<b-form-input v-model="filters.titolo" placeholder="Titolo" />
							</b-col>
							<b-col md="3">
								<multiselect 
								v-model="filters.categorie"
								:multiple="true"
								:options="user.romalabCats"
								placeholder="-- Categorie --"
								label="name"
								track-by="id"/>
							</b-col>
							<b-col md="2">
								<b-form-select v-model="filters.stato" class="inline mr-3"> 
									<option value="">-- Stato --</option>
									<option value="2">Archiviato</option>
								</b-form-select>
							</b-col>
							<b-col md="2">
								<b-form-input type="date" v-model="filters.data" class="inline mr-3"></b-form-input>
							</b-col>
						</b-row>

						<!-- Main table element -->
						<b-table show-empty
						ref="pdfTable"
						stacked="md"
						:items="docs"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:sort-direction="sortDirection"
						@filtered="onFiltered"
						>
						<template #cell(titolo)="row">{{ row.value != '' ? row.value : 'Programma' }}</template>
						<template #cell(created_at)="row">{{ row.value | formatDate }}</template>
						<template #cell(categorie)="row">
							<span v-for="(item, index) in row.value" v-if="hasCategoria(item.id)"> {{item.name}} </span>
						</template>
						<template #cell(privato)="row">{{row.value == 1 ? 'Si' : 'No'}}</template>
						<template #cell(azioni)="row">
							<b-button size="sm" @click.stop="viewPdf(row.item.document, row.item.titolo)">
								Visualizza <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
							</b-button>	
						</template>
					</b-table>

					<b-row>
						<b-col md="6" class="my-1">
							<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
						</b-col>
					</b-row>
					<b-modal id="pdfModal" ref="pdfModal" :title="titolo != '' ? titolo : 'Programma'" ok-only ok-title="Chiudi" size="lg">
						<div v-if="loading" align="center" style="width:100%">
							<img src="/assets/varie/loading.gif" style="width: 150px;" />
						</div>
						<div class="text-center" v-if="!loading">
							<div class="row align-items-center"><div class="col-8 text-center"><button type="button" class="btn btn-link" @click="prevPage">PREV</button><button type="button" class="btn btn-link" @click="nextPage">NEXT</button></div><div class="col-4 text-left" style="font-size:1rem;">{{currentPagePdf}} di {{totalPagesPdf}}</div></div>
							<canvas id="pdf-canvas" width="700"></canvas>
						</div>
					</b-modal>

				</div>
			</div>
		</div>	
	</div>

	<footerapp></footerapp>

</section>
</template>

<script>

var Auth = require('../../../auth.js');
import moment from 'moment'
import axios from 'axios';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	data: function(){
		return {
			fields: [
			{ key: 'id', label: 'ID' },
			{ key: 'titolo', label: 'Documento' },
			{ key: 'created_at', label: 'Data', 'class': 'text-center' },
			{ key: 'categorie', label: 'Categorie' },
			{ key: 'privato', label: 'Privato', 'class': 'text-center' },
			{ key: 'azioni', label: 'Azioni' }
			],
			titolo: '',
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			pageOptions: [ 5, 10, 15 ],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			pdfDoc: null,
			totalPagesPdf: 0,
			currentPagePdf: 0,
			loading: false,
			filters: {
				data: '',
				categorie: [],
				titolo: '',
				stato: '',
			},
		}
	},

	computed: {
		user: function() {
			return Auth.user;
		},
	},
	methods: {
		docs(ctx) {
			return axios.get('/romalab/get/archivio/pdf', {
				params: {
					page: ctx.currentPage,
					per_page: ctx.perPage || 15,
					sort_by: ctx.sortBy,
					sort_direction: ctx.sortDesc ? 'DESC' : 'ASC',
					filter: ctx.filter,
					filters: this.filters,
				},
			}).then((res) => {
				if (!res.data) return [];

				const data = res.data.data || [];
				this.totalRows = res.data.total;
				this.currentPage = res.data.current_page;

				return data;
			}).catch(() => []);
		},
		viewPdf: function(pdf, titolo) {
			this.loading = true;
			this.titolo = titolo;
			this.$refs.pdfModal.show();
			this.$http.get(this.$store.state.apiEndPoint + '/pdfromalab/' + pdf).then((response) => {
				if (response.ok)
				{
					var pdf_data = atob(response.data);
					PDFJS.getDocument({ data: pdf_data }).then((pdf_doc) => {
						this.totalPagesPdf = pdf_doc.numPages;
						this.pdfDoc = pdf_doc;
						this.currentPagePdf = 0;
						this.showPage(1);
						this.loading = false;
					});
				}
			}, (response) => {
			});
		},
		showPage(page_no) {
			this.currentPagePdf = page_no;
			this.pdfDoc.getPage(page_no).then((page) => {
				var desiredWidth = document.getElementById('pdf-canvas').width;
				var viewport = page.getViewport(1);
				var scale = desiredWidth / viewport.width;
				var scaledViewport = page.getViewport(scale);
				document.getElementById('pdf-canvas').height = scaledViewport.height;
				var renderContext = {
					canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
					viewport: scaledViewport
				};
				page.render(renderContext).then(() => {
				});
			});
		},
		prevPage() {
			if(this.currentPagePdf != 1) {
				this.showPage(this.currentPagePdf - 1);
			}    
		},
		nextPage() {
			if(this.currentPagePdf != this.totalPagesPdf) {
				this.showPage(this.currentPagePdf + 1);
			}    
		},
		filtra() {
			this.$refs.pdfTable.refresh();
		},
		onFiltered (filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		hasCategoria(id_cat) {
			if (this.user.romalabCats.filter(e => e.id === id_cat).length > 0) {
                return true;
            }
            return false;   
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
	watch: {
		filters: {
			handler() {
				this.filtra();
			},      
			deep: true,
		},
	}

}
</script>
