<template>
  <b-col cols="4">
    <b-row style="flex-wrap: no-wrap;">
      <b-col cols="4">
        <team-tabs :teams="teams" />
      </b-col>
      <b-col cols="8">
        <skill-tabs />
      </b-col>
    </b-row>

    <b-row style="max-height: 200px;">
      <b-col class="text-center">
        <field />
      </b-col>
    </b-row>

    <!--b-row v-if="newTag">
      <b-col>
        <tag-summary
          :tag="newTag"
          @cancel="cancelNewTag()"
          @update="updateTag()" />
      </b-col>
    </b-row-->
  </b-col>
</template>

<script>
import { mapState } from 'vuex';
import TeamTabs from './TeamTabs.vue';
import SkillTabs from './SkillTabs.vue';
import Field from './Field.vue';
import TagSummary from './TagSummary.vue';

export default {
  name: 'NewTag',
  props: ['teams'],
  components: {
    TeamTabs,
    SkillTabs,
    Field,
    TagSummary,
  },
/*computed: mapState(['newTag']),  */
/*methods: {
    cancelNewTag() {
      this.$store.commit('cancelNewTag');
    },

    updateTag() {
      this.$store.dispatch('saveTag');
    },  
  },  */
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.row .col, .row [class*=col-] {
  padding: 0;
}
</style>

<style lang="scss">
.tab-content {
  padding: 5px !important;
}

.skill-tabs .tab-content {
  padding-bottom: 2px !important;
}

.nav-tabs {
  display: inline-flex;
  width: 100%;

  .nav-item {
    white-space: nowrap;
    text-align: center;

    a {
      overflow: hidden;
   /* text-overflow: ellipsis; */
    }
  }
}

.skill-tabs.player-tabs .nav-tabs .nav-item {
  width: 33.3333%;
}

.skill-tabs.goalkeeper-tabs .nav-tabs .nav-item {
  width: 25%;
}

.team-tabs .nav-tabs .nav-item {
  width: 50%;
}
</style>
