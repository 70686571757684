<template>
	<div>
		<b-table-simple :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 70) + 'px' : '80vh'" striped hover responsive small>
			<b-thead>
				<b-tr>
					<b-th style="min-width: 80px" sticky-column>Giocatore</b-th>
					<b-th class="text-center" colspan="2">Dato</b-th>
					<b-th class="text-center">Data</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="p in dati" :variant="p.isNew ? 'warning' : ''" :style="p.isNew ? 'font-weight:bold' : ''">
					<b-td style="max-width: 150px; vertical-align: middle" class="text-truncate" sticky-column>
						<router-link v-if="!isWidget" :to="{ name: $route.meta.module + '_giocatore_scheda', params: { id: p.id }}">{{ p.name_table }}</router-link>
						<span v-else>{{ p.name_table }}</span>
						<i class="fa fa-ambulance ml-2" v-if="p.injured" title="Infortunato"></i>
					</b-td>
					<b-td style="vertical-align: middle" class="text-center">
						{{ p.fase_dato_type }}
					</b-td>
					<b-td style="vertical-align: middle" class="text-center">
						<b-row><b-col v-for="item, k in p.intestazione">
							<div full :readonly="true" :is="typeOfDato(item)" :dataLayer="dataLayer" :test="p" :field="k" :options="item"></div>
						</b-col></b-row>
					</b-td>
					<b-td style="vertical-align: middle" class="text-center">
						{{ p.updated_at | formatDateHour }}
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
	</div>
</template>

<script>
import $ from 'jquery';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	props: [ 'dati', 'config', 'isWidget', 'contentHeight', 'isFullscreen'],
	data: function(){
		return {
			dataLayer: [],
		}
	},
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceActivitycheck: require('@/components/layout/Sessioni/Matrix/ActivityCheck.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
	},
	watch: {
		dati: {
			deep: true,
			immediate: true,
			handler: function() {

				var b = {};
				_.each(_.map(this.dati, 'intestazione'), function(a,k) {
					_.each(a, function(c,kk) {
						b[kk] = c;
					})
				});

				var selectors = _.filter(b, { type: 'selector' });
				_.each(selectors, function(sel) {
					if(!_.find(this.dataLayer, { url: sel.url })) {
						this.$http.get(this.$store.state.apiEndPoint + sel.url).then(function(data) {
							this.dataLayer.push({
								url: sel.url,
								data: data.data
							});
						});
					}
				}.bind(this));
			}
		}
	},
	computed: {
	},
	methods: {
		typeOfDato: function(colonna) {
			return 'SessioneMatrice' + _.capitalize(colonna.type);
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>