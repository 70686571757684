<template>
	<span>
		{{caret ? '»' : ''}}
		<span v-for="i in items" v-if="i.value">
			<strong v-if="i.type != 'boolean'">{{i.value}}</strong>
			{{i.label}}
			<FisioItemsSpan :caret="true" v-if="i.items" :items="i.items"></FisioItemsSpan>
		</span>
	</span>
</template>

<script>

// import FisioItemsSpan from '@/components/layout/Sessioni/Medical/FisioItemsSpan.vue';

export default {
	/*components: {
		FisioItemsSpan: FisioItemsSpan
	},*/
	name: 'FisioItemsSpan',
	props: [ 'items', 'caret' ]
}	

</script>