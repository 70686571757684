export default {
    exercises: [],
    exercises_bozze: [],
    exercises_archivio: [],
    exercises_da_validare: [],
    objective_ids: [],
    title: '',
    page: 1,
    exercises_tab: 0,
    academy: 0,
};
