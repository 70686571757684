<template>
	<div>

		<label>Nutrition</label>
		<b-form-select :disabled="disabled" v-model="fase.entity.nutrition_glo_id">
			<option :value="null">seleziona</option>
			<option :value="opt.id" v-for="opt in theGlossario['Nutrition']" :key="opt.id">{{opt.nome}}</option>
		</b-form-select>

		<label>Nome</label>
		<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>

		<b-row>
			<b-col>
				<b-form-group label="Unità di Misura">
					<b-form-select :disabled="disabled" v-model="fase.entity.unita_misura">
						<b-form-select-option value="gr">Grammi</b-form-select-option>
						<b-form-select-option value="ml">Millilitri</b-form-select-option>
						<b-form-select-option value="unita">Unità</b-form-select-option>
					</b-form-select>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group label="Dosaggio">
					<b-form-input :disabled="disabled" step="0.1" type="number" v-model.number="fase.entity.dosaggio"></b-form-input>					
				</b-form-group>
			</b-col>
		</b-row>

		<!--
		<label>Note della Fase</label>
		<b-textarea :disabled="disabled" v-model="fase.entity.nota" rows="4"></b-textarea>
		-->

	</div>
</template>

<script>
import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [ 
		faseMixin
	],
	props: [ 'sessione', 'glossario', 'fase', 'fisioterapia_glo_options', 'medical_regions', 'medical_regions_map', 'disabled' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		updateRegionsSelected: function(content, item) {

			if(!this.disabled)
				item.entity.medical_regions = content;

		},
		createItem: function() {

		}
	},
	components: {
		treedbody: require('@/components/layout/3dbody.vue').default,
	},
}
</script>