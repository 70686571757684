<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<PlayersYellows />
		<footerapp></footerapp>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
export default {
	mixins: [ pageBase ],
	components: {
		'PlayersYellows': require('@/components/layout/reports/PlayersYellows.vue').default,
	},
};
</script> 
