<template>
	<div>
		<b-modal title="Configura soglie" size="xl" ok-only ok-title="Chiudi" v-model="showModal">
			<b-card no-body>

				<b-tabs pills card vertical>
					<b-tab v-for="items, gruppo in soglie" :title="gruppo">

						<b-card no-body>
							<b-tabs pills card>
								<b-tab v-for="item in items" :title="item.field">
									<div v-if="item.type == 'stringa'">
										<b-row v-for="row, key in item.config">
											<b-col>
												<b-form-group :label="key">
													<b-form-input v-model="item.config[key]"></b-form-input>
												</b-form-group>
											</b-col>
										</b-row>
									</div>
									<div v-if="item.type == 'scala'">
										<b-row v-for="row, index in item.config" align-v="baseline">
											<b-col v-for="col, key in row" :cols="key == 'min' || key == 'max' ? '2' : ''">
												<b-form-group :label="key">
													<b-form-input v-model="row[key]"></b-form-input>
												</b-form-group>
											</b-col>
											<b-col cols="1">
												<b-button size="sm" @click="removeRow(item.config, index)"><i class="fa fa-trash" aria-hidden="true"></i>
												</b-button>
											</b-col>
										</b-row>
										<b-button size="sm" @click="addRange(item.config)">Add range <i class="fa fa-plus" aria-hidden="true"></i></b-button>
									</div>
								</b-tab>
							</b-tabs>
						</b-card>

					</b-tab>
				</b-tabs>
				<b-row class="my-2">
				<b-col>
					<b-button variant="outline-primary" @click="updateSoglie" class="mr-2">Aggiorna soglie</b-button>
				</b-col>
			</b-row>
			</b-card>
		</b-modal>
	</div>
</template>

<script>

import * as types from '@/store/types';
export default {
	components: {
	},
	watch: {
	},
	props: ['gruppi'],
	data: function() {
		return {
			showModal: false,
			soglie: [],
		}
	},

	created: function() {
		this.getSoglie();
	},

	computed: {
	},
	methods: {
		show: function() {
			this.showModal = true;
		},
		hide: function() {
			this.showModal = false;
		},
		getSoglie() {
			this.$http.get(this.$store.state.apiEndPoint + '/soglie', {params: {gruppi: this.gruppi}}).then((response) => {
				if (response.ok) {
					this.soglie = response.data;
				}
			}, (response) => {
			});
		},
		updateSoglie() {
			this.hide();
			this.$http.post(this.$store.state.apiEndPoint + '/soglie/update', this.soglie).then((response) => {
				if (response.ok) {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getSoglie();
					this.reloadData();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		removeRow(config, index) {
			config.splice(index, 1);
		},
		addRange(config) {
			config.push({min: 0, max: 0, style: ''});
		},
		reloadData: function() {
			this.$emit('reloadData');
		},
	}
}

</script>