<template>
	<div>

		<form @submit.stop.prevent="handleSubmit">
			<SessioneIntestazione :sessionDataLayer="sessionDataLayer" @refresh="$emit('refresh')" :illnesses="illnesses" :injuries="injuries" :playersPreset="playersPreset" :sessione="sessione" :tipoSessione="sessioneCorrente" :persone="playerOptions" :importDatiToolReport="importDatiToolReport" :staffOptions="staffOptions"></SessioneIntestazione>
			
			<b-row class="my-2">
				<b-col>
					<b-button size="xs" variant="primary" class="mr-0" @click.prevent="ux.showVideo = !ux.showVideo"><i :class="'fa fa-arrow-' + (ux.showVideo ? 'up' : 'down')"></i> Video ({{ countVideo }})</b-button>
				</b-col>
				<b-col class="text-right">
					<b-button-group>
						<b-button size="xs" class="mr-0" @click.prevent="expandAll">Espandi Tutto</b-button>
						<b-button size="xs" class="mr-0" @click.prevent="collapseAll">Collassa Tutto</b-button>
					</b-button-group>
				</b-col>
			</b-row>
			<VideoManager :collapsed="!ux.showVideo" entity="Sessione" :entityId="sessione.id" :readonly="!isManagerSessione" :personeIds="personeIds" @countVideo="updateCountVideo" class="mb-2" :id="$route.params.id" :tipo="$route.params.tipo"></VideoManager>

			<hr />
			<b-nav tabs class="my-2">
				<b-nav-item @click.prevent="ux.fase_gruppo_tab = t.name" :active="t.name === ux.fase_gruppo_tab" v-for="t in tabsToShow">{{ t.name }}</b-nav-item>
			</b-nav>
			
			<template v-if="loadingFase">
				<b-spinner variant="primary" label="Spinning"></b-spinner>
			</template>

			<template v-else>
				<Fase 
					ref="faseRef"
					class="mb-2" 
					@refresh="$emit('refresh')"
					@sposta="sposta(k, $event)" 
					@remove="removeFase(k)" 
					@duplica="duplicaFase(k)" 
					:sessioneCorrente="sessioneCorrente" 
					:index="k+1" 
					:fase="fase" 
					:injuries="injuries" 
					:illnesses="illnesses" 
					:isManagerSessione="isManagerSessione" 
					:estensioniFase="estensioniFase"
					:disabled="isPhaseDisabled(fase)"
					v-for="fase, k in sessione_fasi" :sessione="sessione"
					v-show="isInCurrentTab(fase)" 
				>
					<div ref="fase" :pos="k" :sessionDataLayer="sessionDataLayer" :is="fase.entita" :disabled="isPhaseDisabled(fase)" :sessioneCorrente="sessioneCorrente"  :sessione="sessione" :fase="fase" :glossario="glossario" :isManagerSessione="isManagerSessione" @refresh="$emit('refresh')" :countDoc="$refs.faseRef && $refs.faseRef[k] ? $refs.faseRef[k].countDoc : -1"></div>
				
				</Fase>
			</template>

			<b-row>
				<b-col>
					<b-button-group class="mt-3" v-if="isManagerSessione">
						<b-button v-if="canAddPhase" class="mr-0" variant="outline-primary" @click="showAddFasi"><i class="fa fa-plus fa-fw"></i> Aggiungi Fase</b-button>
						<b-button 
							v-if="auth.isLastSeason(seasonId) || auth.isAdmin()" 
							ref="submit" type="submit" variant="primary" 
							class="text-uppercase ml-0"
							:disabled="saveLoading || loadingFase"
						>
							<strong>Salva</strong>
						</b-button>
					</b-button-group>
				</b-col>
				<b-col class="text-right">
					<b-button-group class="mt-3" v-if="(isManagerSessione && auth.isLastSeason(seasonId)) || auth.isAdmin()">
						<b-button class="mr-0" variant="outline-primary" :disabled="loadingFase" @click="showDuplicaSessione">Duplica</b-button>
						<b-button variant="outline-primary" :disabled="loadingFase" @click="deleteSessione">Elimina</b-button>
					</b-button-group>
				</b-col>
			</b-row>

		</form>

	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';
import sessionFasiMixin from '@/mixins/sessioneFasi.js';

import axios from 'axios';
import moment from 'moment'

import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	props: [ 'sessionDataLayer', 'sessione', 'playersPreset', 'playerOptions', 'sessioneTipi', 'seasonId', 'fasiGeneriche', 'estensioniFase', 'team', 'importDatiToolReport', 'staffOptions', 'loadingFase', 'saveLoading' ],
	name: 'Sessione',
	mixins: [
		sessionMixin,
		sessionFasiMixin,
	],
	data: function(){
		return{
			ux: {
				fase_gruppo_tab: 'Fasi',
				newFase: false,
				showMatrice: false,
				showVideo: false
			},
			injuries: [],
			illnesses: [],
			countVideo: 0,
		}
	},
	mounted: function() {

		this.buildToolsPrevision();

	},
	methods: {
		buildToolsPrevision: function() {
			
			_.each(this.sessione.fasi, function(a, b) {

				if(a.entita === "Fase_Esercizio") {
					if(!this.sessionDataLayer['fase_' + b])
						this.$set(this.sessionDataLayer, 'fase_' + b, null);
				}

			}.bind(this));

		},
		emitToAll: function() {

			console.log(this.$refs.faseRef);

			_.each(this.$refs.faseRef, function(a) {

				if(a.fase.entita == 'Fase_Esercizio') {

					_.delay(function() {

						if(a.visible !== true) {
							a.visible = true;
							_.delay(function() {
								a.visible = false;
							}, 10);
						} else {
							
						}

					}, 100);

				}

				/*
				console.log(a.fase.entita)

				_.delay(function() {
					if(a.visible !== true) {
						a.visible = true;
						_.delay(function() {
							a.visible = false;
						}, 5);
					}
				}.bind(this), 100);
				*/

			}.bind(this));

		},
		isInCurrentTab: function(fase) {

			if(!this.hasFasiDatiGenerate || this.ux.fase_gruppo_tab == 'Tutte') return true;

			if(this.ux.fase_gruppo_tab == 'Fasi') {
				if(!fase.setup || !fase.setup.automationPhase || fase.setup.automationPhase == false)
					return true;
			} else if(this.ux.fase_gruppo_tab == 'Fasi Generate') {
				if(fase.setup && fase.setup.automationPhase && fase.setup.automationPhase == true)
					return true;
			}

			return false;
		},
		expandAll: function() {
			_.each(this.$refs.faseRef, function(fase) {
				fase.visible = true;
			})
		},
		collapseAll: function() {
			_.each(this.$refs.faseRef, function(fase) {
				fase.visible = false;
			})
		},
		getInjuriesByPlayers(player_ids) {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/injury/list/players', {params: { player_ids: player_ids }}).then((response) => {
				if (response.ok) {
					this.injuries = response.data.map(item => ({	
						id: item.id,							
						data: item.data,
						pathology: item.pathology,
						persona_id: item.persona_id
					}));
				}
			}, (response) => {});
		},
		getIllnessesByPlayers(player_ids) {
			this.$http.get(this.$store.state.apiEndPoint + '/medical/illness/list/players', {params: { player_ids: player_ids }}).then((response) => {
				if (response.ok) {
					this.illnesses = response.data.map(item => ({	
						id: item.id,							
						data: item.data,
						illness_types: item.illness_types,
						illness_other_types: item.illness_other_types,
						persona_id: item.persona_id
					}));
				}
			}, (response) => {});
		},
		handleSubmit: function () {
			this.$emit('submit');
		},
		updateCountVideo: function(count) {
			this.countVideo = count;
		}
	},
	watch: {
		'sessione.players': {
			deep: true,
			immediate: true,
			handler: function() {

				var utentiPresenti = _.map(this.sessione.players, 'id');
				_.each(this.sessione.fasi, function(fase) {
					_.each(fase.players, function(singola, k) {
						if(utentiPresenti.indexOf(singola.id) === -1)
							fase.players.splice(k, 1);
					});
				});

				this.getInjuriesByPlayers(utentiPresenti);
				this.getIllnessesByPlayers(utentiPresenti);

			}
		},
		rosaTeamId() {
			this.getPlayers();
		}, 
	},
	computed: {
		hasFasiDatiGenerate: function() {

			var amount = _.filter(this.sessione_fasi, function(a) {
				return a.setup && a.setup.automationPhase && a.setup.automationPhase == true;
			});

			if(amount.length > 0) return true;

		},
		tabsToShow: function() {

			var tabs = [];
			tabs.push({ name: 'Fasi' });

			if(this.hasFasiDatiGenerate) {
				tabs.push({ name: 'Fasi Generate' });
				tabs.push({ name: 'Tutte' });
			}

			return tabs;

		},
		sessione_fasi: function() {
			console.log(this.sessione.fasi);
			return _.filter(this.sessione.fasi, function(a) {

				if(a.setup && a.setup.right !== undefined) return this.auth.isUserEnable(a.setup.right);
				console.log(a.setup);
				return true;

			}.bind(this));
		}
	}
}
</script>