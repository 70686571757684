<template id="cardtest">

	<b-card class="mb-2" :title="giocatore.cognome + ' ' + giocatore.nome">
		<b-row>
			<b-col cols="4">
				<b-row class="font-weight-bold">
					<b-col cols="5">
						Test
					</b-col>
					<b-col cols="1" class="text-center">
						Side
					</b-col>
					<b-col cols="1" class="text-center" title="Score">
						Sc.
					</b-col>
					<b-col cols="1" class="text-center">
						Pain
					</b-col>
					<b-col cols="3" class="text-center">
						Asimmetria
					</b-col>
					<b-col cols="1" class="text-center">
						Fms
					</b-col>
				</b-row>
				<b-row class="bg-light py-1" align-v="center">
					<b-col cols="5">
						Deep Squat
					</b-col>
					<b-col cols="1" class="text-center">
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" v-model="deep_squat_score"></b-form-input>
					</b-col>
					<b-col cols="1" class="text-center">
					</b-col>
					<b-col cols="3" class="text-center">
					</b-col>
					<b-col cols="1" class="text-center">
						{{ deep_squat_fms }}
					</b-col>
				</b-row>
				<b-row class="py-1" align-v="center">
					<b-col cols="5">
						Hurdle Step
					</b-col>
					<b-col cols="1" class="text-center">
						L<br/>R
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" class="mb-1" v-model="hurdle_step_l_score"></b-form-input>
						<b-form-input size="sm" v-model="hurdle_step_r_score"></b-form-input>	
					</b-col>
					<b-col cols="1" class="text-center">

					</b-col>
					<b-col cols="3" class="text-center">
						{{ hurdle_step_asi }}
					</b-col>
					<b-col cols="1" class="text-center">
						{{ hurdle_step_fms }}	
					</b-col>
				</b-row>
				<b-row class="bg-light py-1" align-v="center">
					<b-col cols="5">
						Inline Lounge
					</b-col>
					<b-col cols="1" class="text-center">
						L<br/>R
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" class="mb-1" v-model="inline_lounge_l_score"></b-form-input>
						<b-form-input size="sm" v-model="inline_lounge_r_score"></b-form-input>	
					</b-col>
					<b-col cols="1" class="text-center">

					</b-col>
					<b-col cols="3" class="text-center">
						{{ inline_lounge_asi }}
					</b-col>
					<b-col cols="1" class="text-center">
						{{ inline_lounge_fms }}
					</b-col>
				</b-row>
				<b-row class="py-1" align-v="center">
					<b-col cols="5">
						Shoulder Mobility
					</b-col>
					<b-col cols="1" class="text-center">
						L<br/>R
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" class="mb-1" v-model="shoulder_mobility_l_score"></b-form-input>
						<b-form-input size="sm" v-model="shoulder_mobility_r_score"></b-form-input>	
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" v-model="shoulder_mobility_pain"></b-form-input>			
					</b-col>
					<b-col cols="3" class="text-center">
						{{ shoulder_mobility_asi }}
					</b-col>
					<b-col cols="1" class="text-center">
						{{ shoulder_mobility_fms }}
					</b-col>
				</b-row>
				<b-row class="bg-light py-1" align-v="center">
					<b-col cols="5">
						Active Straight-Leg Raise
					</b-col>
					<b-col cols="1" class="text-center">
						L<br/>R
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" class="mb-1" v-model="active_straight_leg_raise_l_score"></b-form-input>
						<b-form-input size="sm" v-model="active_straight_leg_raise_r_score"></b-form-input>	
					</b-col>
					<b-col cols="1" class="text-center">
					</b-col>
					<b-col cols="3" class="text-center">
						{{ active_straight_leg_raise_asi }}
					</b-col>
					<b-col cols="1" class="text-center">
						{{ active_straight_leg_raise_fms }}
					</b-col>
				</b-row>
				<b-row class="py-1" align-v="center">
					<b-col cols="5">
						Trunk Stability Pushup
					</b-col>
					<b-col cols="1" class="text-center">

					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" v-model="trunk_stability_pushup_score"></b-form-input>
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" v-model="trunk_stability_pushup_pain"></b-form-input>		
					</b-col>
					<b-col cols="3" class="text-center">

					</b-col>
					<b-col cols="1" class="text-center">
						{{ trunk_stability_pushup_fms }}
					</b-col>
				</b-row>
				<b-row class="bg-light py-1" align-v="center">
					<b-col cols="5">
						Rotary Stability
					</b-col>
					<b-col cols="1" class="text-center">
						L<br/>R
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" class="mb-1" v-model="rotary_stability_l_score"></b-form-input>
						<b-form-input size="sm" v-model="rotary_stability_r_score"></b-form-input>	
					</b-col>
					<b-col cols="1" class="text-center">
						<b-form-input size="sm" v-model="rotary_stability_pain"></b-form-input>	
					</b-col>
					<b-col cols="3" class="text-center">
						{{ rotary_stability_asi }}
					</b-col>
					<b-col cols="1" class="text-center">
						{{ rotary_stability_fms }}
					</b-col>
				</b-row>
				<b-row class="font-weight-bold">
					<b-col cols="5">
						FMS_PL_1
					</b-col>
					<b-col cols="1" class="text-center">
					</b-col>
					<b-col cols="1" class="text-center">
					</b-col>
					<b-col cols="1" class="text-center">
					</b-col>
					<b-col cols="3" class="text-center">
						{{ asimmetria }}
					</b-col>
					<b-col cols="1" class="text-center">
						{{ fms_pl1 }}
					</b-col>
				</b-row>
			</b-col>
			<b-col cols="2" class="pl-1 pr-3" align-self="center">
				<b-row>
					<b-col>
						<barchart :barid="'bar_' + giocatore.id" :datachart="bardatachart"></barchart>
					</b-col>
				</b-row>
			</b-col>
			<b-col cols="6" class="pl-3 border-left">
				<b-row>
					<b-col>
						<b-row>
							<b-col>
								<b-form-group label="SJ (cm)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="sj"></b-form-input>
								</b-form-group>
								<b-form-group label="CMJ (cm)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="cmj"></b-form-input>
								</b-form-group>
								<b-form-group label="5m (s)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="t_5m"></b-form-input>
								</b-form-group>
								<b-form-group label="10m (s)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="t_10m"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col class="pl-3">
								<b-form-group label="30m (s)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="t_30m"></b-form-input>
								</b-form-group>
								<b-form-group label="5-0-5 dx (s)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="t_505dx"></b-form-input>
								</b-form-group>
								<b-form-group label="5-0-5 sx (s)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="t_505sx"></b-form-input>
								</b-form-group>
								<b-form-group label="FMS (n)" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="fms"></b-form-input>
								</b-form-group>
								<b-form-group label="YOYO" :label-cols="6"  class="mb-2" horizontal>
									<b-form-input v-model="yoyo"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col class="pl-3">
								<b-form-group label="Power" :label-cols="6"  class="mb-0 font-weight-bold" horizontal>
									{{ !isNaN(power) ? Math.round(power * 100) / 100 : power }}
								</b-form-group>
								<b-form-group label="Acceleration" :label-cols="6"  class="mb-0 font-weight-bold" horizontal>
									{{ !isNaN(acceleration) ? Math.round(acceleration * 100) / 100 : acceleration }}
								</b-form-group>
								<b-form-group label="Speed" :label-cols="6"  class="mb-0 font-weight-bold" horizontal>
									{{ !isNaN(speed) ? Math.round(speed * 100) / 100 : speed }}
								</b-form-group>
								<b-form-group label="Agility" :label-cols="6"  class="mb-0 font-weight-bold" horizontal>
									{{ !isNaN(agility) ? Math.round(agility * 100) / 100 : agility }}
								</b-form-group>
								<b-form-group label="Mobility" :label-cols="6"  class="mb-0 font-weight-bold" horizontal>
									{{ !isNaN(mobility) ? Math.round(mobility * 100) / 100 : mobility }}
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<!--b-row>
					<b-col>
						<radarchart :radarid="'radar_' + giocatore.id" :datachart="radardatachart" :min="-5" :max="5"></radarchart>
					</b-col>
				</b-row-->
			</b-col>
		</b-row>
	</b-card>
</template>

<script>

import moment from 'moment';
import * as types from '../../../store/types';

export default {
	props:{
		giocatore: {
			type: Object,
			required: true
		},
		test: {
			type: Object,
			required: true
		},
		test_media: {
			type: Object,
			required: true
		}, 
		test_ds: {
			type: Object,
			required: true
		}
	},

	components: {
		radarchart: 	require('../../layout/charts/radar.vue').default,
		barchart: 	require('../../layout/charts/stackedbar.vue').default,
	},

	data: function(){
		return {
			deep_squat_score: this.test.deep_squat_score,
			hurdle_step_l_score: this.test.hurdle_step_l_score,
			hurdle_step_r_score: this.test.hurdle_step_r_score,
			inline_lounge_l_score: this.test.inline_lounge_l_score,
			inline_lounge_r_score: this.test.inline_lounge_r_score,
			shoulder_mobility_l_score: this.test.shoulder_mobility_l_score,
			shoulder_mobility_r_score: this.test.shoulder_mobility_r_score,
			shoulder_mobility_pain: this.test.shoulder_mobility_pain,
			active_straight_leg_raise_l_score: this.test.active_straight_leg_raise_l_score,
			active_straight_leg_raise_r_score: this.test.active_straight_leg_raise_r_score,
			trunk_stability_pushup_score: this.test.trunk_stability_pushup_score,
			trunk_stability_pushup_pain: this.test.trunk_stability_pushup_pain,
			rotary_stability_l_score: this.test.rotary_stability_l_score,
			rotary_stability_r_score: this.test.rotary_stability_r_score,
			rotary_stability_pain: this.test.rotary_stability_pain,
			
			sj: this.test.sj,
			cmj: this.test.cmj,
			t_5m: this.test.t_5m,
			t_10m: this.test.t_10m,
			t_30m: this.test.t_30m,
			t_505dx: this.test.t_505dx,
			t_505sx: this.test.t_505sx,
			fms: this.test.fms,
			yoyo: this.test.yoyo,
		}
	},
	created: function () {
	},
	mounted: function () {	
		this.$emit('updatepei', this.pei, this.giocatore.id);
	},
	methods: {
	},
	filters: {
	},
	watch: {
		deep_squat_score: function () {
			this.$emit('updatefirsttest', 'deep_squat_score', this.deep_squat_score, this.giocatore.id);
		},
		hurdle_step_l_score: function () {
			this.$emit('updatefirsttest', 'hurdle_step_l_score', this.hurdle_step_l_score, this.giocatore.id);
		},
		hurdle_step_r_score: function () {
			this.$emit('updatefirsttest', 'hurdle_step_r_score', this.hurdle_step_r_score, this.giocatore.id);
		},
		inline_lounge_l_score: function () {
			this.$emit('updatefirsttest', 'inline_lounge_l_score', this.inline_lounge_l_score, this.giocatore.id);
		},
		inline_lounge_r_score: function () {
			this.$emit('updatefirsttest', 'inline_lounge_r_score', this.inline_lounge_r_score, this.giocatore.id);
		},
		shoulder_mobility_l_score: function () {
			this.$emit('updatefirsttest', 'shoulder_mobility_l_score', this.shoulder_mobility_l_score, this.giocatore.id);
		},
		shoulder_mobility_r_score: function () {
			this.$emit('updatefirsttest', 'shoulder_mobility_r_score', this.shoulder_mobility_r_score, this.giocatore.id);
		},
		shoulder_mobility_pain: function () {
			this.$emit('updatefirsttest', 'shoulder_mobility_pain', this.shoulder_mobility_pain, this.giocatore.id);
		},
		active_straight_leg_raise_l_score: function () {
			this.$emit('updatefirsttest', 'active_straight_leg_raise_l_score', this.active_straight_leg_raise_l_score, this.giocatore.id);
		},
		active_straight_leg_raise_r_score: function () {
			this.$emit('updatefirsttest', 'active_straight_leg_raise_r_score', this.active_straight_leg_raise_r_score, this.giocatore.id);
		},
		trunk_stability_pushup_score: function () {
			this.$emit('updatefirsttest', 'trunk_stability_pushup_score', this.trunk_stability_pushup_score, this.giocatore.id);
		},
		trunk_stability_pushup_pain: function () {
			this.$emit('updatefirsttest', 'trunk_stability_pushup_pain', this.trunk_stability_pushup_pain, this.giocatore.id);
		},
		rotary_stability_l_score: function () {
			this.$emit('updatefirsttest', 'rotary_stability_l_score', this.rotary_stability_l_score, this.giocatore.id);
		},
		rotary_stability_r_score: function () {
			this.$emit('updatefirsttest', 'rotary_stability_r_score', this.rotary_stability_r_score, this.giocatore.id);
		},
		rotary_stability_pain: function () {
			this.$emit('updatefirsttest', 'rotary_stability_pain', this.rotary_stability_pain, this.giocatore.id);
		},
		sj: function () {
			this.sj = this.sj.replace(",", ".");
			this.$emit('updatesecondtest', 'sj', this.sj, this.giocatore.id);
		},
		cmj: function () {
			this.cmj = this.cmj.replace(",", ".");
			this.$emit('updatesecondtest', 'cmj', this.cmj, this.giocatore.id);
		},
		t_5m: function () {
			this.t_5m = this.t_5m.replace(",", ".");
			this.$emit('updatesecondtest', 't_5m', this.t_5m, this.giocatore.id);
		},
		t_10m: function () {
			this.t_10m = this.t_10m.replace(",", ".");
			this.$emit('updatesecondtest', 't_10m', this.t_10m, this.giocatore.id);
		},
		t_30m: function () {
			this.t_30m = this.t_30m.replace(",", ".");
			this.$emit('updatesecondtest', 't_30m', this.t_30m, this.giocatore.id);
		},
		t_505dx: function () {
			this.t_505dx = this.t_505dx.replace(",", ".");
			this.$emit('updatesecondtest', 't_505dx', this.t_505dx, this.giocatore.id);
		},
		t_505sx: function () {
			this.t_505sx = this.t_505sx.replace(",", ".");
			this.$emit('updatesecondtest', 't_505sx', this.t_505sx, this.giocatore.id);
		},
		fms: function () {
			this.fms = this.fms.replace(",", ".");
			this.$emit('updatesecondtest', 'fms', this.fms, this.giocatore.id);
		},
		yoyo: function () {
			this.yoyo = this.yoyo.replace(",", ".");
			this.$emit('updatesecondtest', 'yoyo', this.yoyo, this.giocatore.id);
		},
		pei: function () {
			this.$emit('updatepei', this.pei, this.giocatore.id);
		},
	},
	computed: {
		bardatachart: function() {
			var data = {
				labels: ['FMS_PL_1'],
				datasets: [{
					label: 'asimmetria',
					data: [this.asimmetria],
					backgroundColor: 'rgba(0, 0, 255, 0.5)',
					borderColor: '#0000FF',
					borderWidth: 2,
				}, {
					label: 'FMS',
					data: [this.fms_pl1],
					backgroundColor: 'rgba(255, 0, 0, 0.5)',
					borderColor: '#FF0000',
					borderWidth: 2,
				}]
			};
			return data;		
		},
		radardatachart: function() {
			var data = {
				labels: ['Power', 'Acceleration', 'Speed', 'Agility', 'Mobility'],
				datasets: [{
					label: 'media',
					data: [0, 0, 0, 0, 0],
					backgroundColor: 'rgba(0, 0, 255, 0.5)',
					borderColor: '#0000FF',
					borderWidth: 2,
				}, {
					label: 'giocatore',
					data: [this.power, this.acceleration, this.speed, this.agility, this.mobility],
					backgroundColor: 'rgba(255, 0, 0, 0.5)',
					borderColor: '#FF0000',
					borderWidth: 2,
				}]
			};
			return data;		
		},
		deep_squat_fms: function() {
			if(!isNaN(this.deep_squat_score) && this.deep_squat_score >=0 && this.deep_squat_score <=3) {
				return this.deep_squat_score;
			}
			return 0;
		},
		hurdle_step_fms: function() {
			if(!isNaN(this.hurdle_step_l_score) && this.hurdle_step_l_score >=0 && this.hurdle_step_l_score <=3 && !isNaN(this.hurdle_step_r_score) && this.hurdle_step_r_score >=0 && this.hurdle_step_r_score <=3) {
				if(this.hurdle_step_l_score > this.hurdle_step_r_score) {
					return this.hurdle_step_r_score;
				}
				return this.hurdle_step_l_score;
			}
			return 0;
		},
		hurdle_step_asi: function() {
			if(this.hurdle_step_l_score == this.hurdle_step_r_score) {
				return 0;
			}
			return 1;
		},
		inline_lounge_fms: function() {
			if(!isNaN(this.inline_lounge_l_score) && this.inline_lounge_l_score >=0 && this.inline_lounge_l_score <=3 && !isNaN(this.inline_lounge_r_score) && this.inline_lounge_r_score >=0 && this.inline_lounge_r_score <=3) {
				if(this.inline_lounge_l_score > this.inline_lounge_r_score) {
					return this.inline_lounge_r_score;
				}
				return this.inline_lounge_l_score;
			}
			return 0;
		},
		inline_lounge_asi: function() {
			if(this.inline_lounge_l_score == this.inline_lounge_r_score) {
				return 0;
			}
			return 1;
		},
		shoulder_mobility_fms: function() {
			if(!isNaN(this.shoulder_mobility_l_score) && this.shoulder_mobility_l_score >=0 && this.shoulder_mobility_l_score <=3 && !isNaN(this.shoulder_mobility_r_score) && this.shoulder_mobility_r_score >=0 && this.shoulder_mobility_r_score <=3 && !isNaN(this.shoulder_mobility_pain) && (this.shoulder_mobility_pain == 0 || this.shoulder_mobility_pain == 1)) {
				if(this.shoulder_mobility_l_score > this.shoulder_mobility_r_score) {
					return (this.shoulder_mobility_r_score * this.shoulder_mobility_pain);
				}
				return (this.shoulder_mobility_l_score * this.shoulder_mobility_pain);
			}
			return 0;
		},
		shoulder_mobility_asi: function() {
			if(this.shoulder_mobility_l_score == this.shoulder_mobility_r_score) {
				return 0;
			}
			return this.shoulder_mobility_pain;
		},
		active_straight_leg_raise_fms: function() {
			if(!isNaN(this.active_straight_leg_raise_l_score) && this.active_straight_leg_raise_l_score >=0 && this.active_straight_leg_raise_l_score <=3 && !isNaN(this.active_straight_leg_raise_r_score) && this.active_straight_leg_raise_r_score >=0 && this.active_straight_leg_raise_r_score <=3) {
				if(this.active_straight_leg_raise_l_score > this.active_straight_leg_raise_r_score) {
					return this.active_straight_leg_raise_r_score;
				}
				return this.active_straight_leg_raise_l_score;
			}
			return 0;
		},
		active_straight_leg_raise_asi: function() {
			if(this.active_straight_leg_raise_l_score == this.active_straight_leg_raise_r_score) {
				return 0;
			}
			return 1;
		},
		trunk_stability_pushup_fms: function() {
			if(!isNaN(this.trunk_stability_pushup_score) && this.trunk_stability_pushup_score >=0 && this.trunk_stability_pushup_score <=3 && !isNaN(this.trunk_stability_pushup_pain) && (this.trunk_stability_pushup_pain == 0 || this.trunk_stability_pushup_pain == 1)) {
				return (this.trunk_stability_pushup_score * this.trunk_stability_pushup_pain);
			}
			return 0;
		},
		rotary_stability_fms: function() {
			if(!isNaN(this.rotary_stability_l_score) && this.rotary_stability_l_score >=0 && this.rotary_stability_l_score <=3 && !isNaN(this.rotary_stability_r_score) && this.rotary_stability_r_score >=0 && this.rotary_stability_r_score <=3 && !isNaN(this.rotary_stability_pain) && (this.rotary_stability_pain == 0 || this.rotary_stability_pain == 1)) {
				if(this.rotary_stability_l_score > this.rotary_stability_r_score) {
					return (this.rotary_stability_r_score * this.rotary_stability_pain);
				}
				return (this.rotary_stability_l_score * this.rotary_stability_pain);
			}
			return 0;
		},
		rotary_stability_asi: function() {
			if(this.rotary_stability_l_score == this.rotary_stability_r_score) {
				return 0;
			}
			return this.rotary_stability_pain;
		},
		fms_pl1: function() {
			var fms = 0;
			if(!isNaN(this.deep_squat_fms)) {
				fms += parseInt(this.deep_squat_fms);
			}
			if(!isNaN(this.hurdle_step_fms)) {
				fms += parseInt(this.hurdle_step_fms);
			}
			if(!isNaN(this.inline_lounge_fms)) {
				fms += parseInt(this.inline_lounge_fms);
			}
			if(!isNaN(this.shoulder_mobility_fms)) {
				fms += parseInt(this.shoulder_mobility_fms);
			}
			if(!isNaN(this.active_straight_leg_raise_fms)) {
				fms += parseInt(this.active_straight_leg_raise_fms);
			}
			if(!isNaN(this.trunk_stability_pushup_fms)) {
				fms += parseInt(this.trunk_stability_pushup_fms);
			}
			if(!isNaN(this.rotary_stability_fms)) {
				fms += parseInt(this.rotary_stability_fms);
			}
			return fms;
		},
		asimmetria: function() {
			var asi = 0;
			if(!isNaN(this.hurdle_step_asi)) {
				asi += parseInt(this.hurdle_step_asi);
			}
			if(!isNaN(this.inline_lounge_asi)) {
				asi += parseInt(this.inline_lounge_asi);
			}
			if(!isNaN(this.shoulder_mobility_asi)) {
				asi += parseInt(this.shoulder_mobility_asi);
			}
			if(!isNaN(this.active_straight_leg_raise_asi)) {
				asi += parseInt(this.active_straight_leg_raise_asi);
			}
			if(!isNaN(this.rotary_stability_asi)) {
				asi += parseInt(this.rotary_stability_asi);
			}
			return asi;
		},
		power: function() {
			return (parseFloat(this.cmj) - parseFloat(this.test_media.cmj))/parseFloat(this.test_ds.cmj);
		},
		acceleration: function() {
			return (parseFloat(this.t_5m) - parseFloat(this.test_media.t_5m))/parseFloat(this.test_ds.t_5m);
		},
		speed: function() {
			return (parseFloat(this.t_30m) - parseFloat(this.test_media.t_30m))/parseFloat(this.test_ds.t_30m);
		},
		agility: function() {
			var ma = (parseFloat(this.t_505dx) + parseFloat(this.t_505sx))/2;
			return (parseFloat(ma) - parseFloat(this.test_media.ma))/parseFloat(this.test_ds.ma);
		},
		mobility: function() {
			return (parseFloat(this.fms) - parseFloat(this.test_media.fms))/parseFloat(this.test_ds.fms);
		},

		pei: function() {
			return (parseFloat(this.power) + parseFloat(this.acceleration) + parseFloat(this.speed) + parseFloat(this.agility) + parseFloat(this.mobility))/5;
		},
	}
}
</script>
