<template>
	<div>
		<div class="d-flex align-items-end text-asryellow">
			<!--div class="self-center flex flex-col items-center mt-2" style="margin-right: 8rem">
				<div class="text-3xl font-black leading-none uppercase">{{ days[clock.wd] }} <span class="text-asrred">{{ clock.day }}</span></div>
				<div class="uppercase leading-none italic font-bold tracking-widest text-asrred">{{ months[clock.month] }}</div>
			</div-->
			<div class="font-weight-bold font-italic text-8xl">{{ clock.hour }}</div>
			<div class="text-5xl font-italic font-weight-bold text-gray-300 mx-1">:</div>
			<div class="font-weight-bold text-asrred font-italic text-7xl">{{ clock.min }}</div>
			<div class="text-5xl font-italic font-weight-bold text-gray-300 mx-1">:</div>
			<div class="text-6xl font-italic">{{ clock.sec }}</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
	name: 'Home',
	methods: {
		watchClock: function() {
			this.clock = {
				day: moment().date(),
				wd: moment().isoWeekday(),
				month: moment().month(),
				hour: moment().format('HH'),
				min: moment().format('mm'),
				sec: moment().format('ss')
			}
			_.delay(this.watchClock, 100);
		}
	},
	created: function() {
		this.watchClock();
	},
	data: function() {
		return {
		//	days: [ '', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica' ],
		//	months: [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ],
			clock: false
		}
	}
};
</script>
<style>
.text-asryellow {
    color: rgba(251,186,0, 1);
}
.text-asrred {
    color: rgba(153,10,44, 1);
}
.text-5xl {
   	font-size: 2.5rem;
	line-height: 1; 
}
.text-6xl {
   	font-size: 2rem;
	line-height: 1; 
}
.text-7xl {
   	font-size: 3rem;
	line-height: 0.9; 
}
.text-8xl {
   	font-size: 4rem;
	line-height: 0.9; 
}
.text-gray-300 {
	color: #D1D5DB; 
}
</style>

