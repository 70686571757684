<template>
	<div>

		<div v-if="competition && stage">

			<div class="text-center mb-2">
				<b-button-group>
					<b-button :disabled="!canPrev" variant="outline-primary" size="sm" @click.prevent="goPrev">Prev ({{ _matchDay-1 }})</b-button>
					<b-button disabled variant="outline-primary" size="sm" @click.prevent="load">{{ first.name }}</b-button>
					<b-button :disabled="!canNext" variant="outline-primary" size="sm" @click.prevent="goNext">Next ({{ _matchDay+1 }})</b-button>
				</b-button-group>
			</div>

			<b-row>
				<b-col v-if="first">
					Classifica
					<hr />
					<b-table-simple small striped>
						<b-thead>
							<b-tr>
								<b-th>Team</b-th>
								<b-th></b-th>
								<b-th>V</b-th>
								<b-th>P</b-th>
								<b-th>S</b-th>
								<b-th>GF</b-th>
								<b-th>GS</b-th>
								<b-th>DR</b-th>
								<b-th>PT</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr v-for="team in first.rank">
								<b-td>{{ team.team.name }}</b-td>
								<b-td>{{ team.played }}</b-td>
								<b-td>{{ team.vittoria_home + team.vittoria_away || '' }}</b-td>
								<b-td>{{ team.pareggio_home + team.pareggio_away || '' }}</b-td>
								<b-td>{{ team.sconfitta_home + team.sconfitta_away || '' }}</b-td>
								<b-td>{{ team.gol_home + team.gol_away || '' }}</b-td>
								<b-td>{{ team.golsubiti_home + team.golsubiti_away || '' }}</b-td>
								<b-td>{{ (team.gol_home + team.gol_away) - (team.golsubiti_home + team.golsubiti_away) || '' }}</b-td>
								<b-td>{{ team.pts }}</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-col>
				<b-col v-if="first">
					<strong>{{first.name}}</strong>
					<hr />

					<b-list-group>
						<b-list-group-item href="javascript:;" @click.prevent="click_editEvent(event)" v-for="event in first.events">
							<span>{{ event.home.name }} <span class="small text-muted">({{ event.home_result }} - {{ event.away_result }})</span> {{ event.away.name }}</span>
							<b-badge class="float float-right ml-1">{{ event.status }}</b-badge>
							<b-badge class="float float-right">{{ event.date | date('DD/MM/YYYY HH:mm') }}</b-badge>
						</b-list-group-item>
					</b-list-group>

					<div class="text-right">
						<b-button class="mt-2" size="sm" variant="outline-primary" @click.prevent="newEvent">Nuovo Evento</b-button>
					</div>
				</b-col>
				<b-col v-if="next">
					{{next.name}}
					<hr />

					<b-list-group>
						<b-list-group-item href="javascript:;" @click.prevent="click_editEvent(event)" v-for="event in next.events">
							<span>{{ event.home.name }} <span class="small text-muted">({{ event.home_result }} - {{ event.away_result }})</span> {{ event.away.name }}</span>
							<b-badge class="float float-right ml-1">{{ event.status }}</b-badge>
							<b-badge class="float float-right">{{ event.date | date('DD/MM/YYYY HH:mm') }}</b-badge>
						</b-list-group-item>
					</b-list-group>

				</b-col>
			</b-row>

		</div>

		<b-sidebar width="400px" ref="NewMatchRef" :visible="ux.newEvent" right @hidden="ux.newEvent = false">
			
			<div class="px-4" v-if="ux.newEvent">

				<b-form @submit.prevent="createEvent">
					<Match-Event-Form :event="emptyEvent" :competition="competition" :stage="stage"></Match-Event-Form>
					<hr />
					<b-button type="submit" variant="outline-success">Crea Evento</b-button>
				</b-form>

			</div>

		</b-sidebar>

		<b-sidebar width="400px" ref="EditMatchRef" :visible="ux.editEvent" right @hidden="ux.editEvent = false">
			
			<div class="px-4" v-if="ux.editEvent">

				<b-form @submit.prevent="updateEvent">
					<Match-Event-Form :event="editEvent" :match="match" :competition="competition" :stage="stage"></Match-Event-Form>
					<hr />
					<b-button block size="sm" type="submit" variant="outline-success">Modifica Evento</b-button>
					<div class="mt-2 small text-right">
						<a class="text-muted" @click.prevent="deleteEvent" href="javascript:;">Elimina</a>
					</div>
				</b-form>

			</div>

		</b-sidebar>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: [ 'competitionId', 'stageId', 'matchDay', 'nextDay' ],
	created: function() {
		this._matchDay = _.cloneDeep(this.matchDay);
		this._nextDay = _.cloneDeep(this.nextDay);
		this.fetchPackage();
		this.emptyEvent = this.gen_emptyEvent();
	},
	components: {
		MatchEventForm: require('@/components/redazione/components/matches/event-form.vue').default,
	},
	computed: {
		canPrev: function() {
			if( parseInt(this._matchDay) > 1 )
				return true;
		},
		canNext: function() {
			return true;
		}
	},
	filters: {
		date: function(a,b) {
			return moment(a).format(b);
		}
	},
	data: function() {
		return {
			competition: null,
			stage: null,
			ux: {
				newEvent: false,
				editEvent: false
			},
			_matchDay: null,
			_nextDay: null,
			first: null,
			next: null,
			emptyEvent: null,
			editEvent: null
		}
	},
	watch: {
		'competition': {
			handler: function() {
				this.load();
			}
		},
		'stage': {
			handler: function() {
				this.load();
			}
		}
	},
	methods: {
		fetchCompetition: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.competitionId).then(function(data) {
				this.competition = data.data;
			})
		},
		fetchStage: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.competitionId + '/stage/' + this.stageId).then(function(data) {
				this.stage = data.data;
			})
		},
		fetchPackage: function() {

			this.fetchCompetition();
			this.fetchStage();

		},
		goNext: function() {
			this._matchDay++;
			this._nextDay++;
			this.load();
		},
		goPrev: function() {
			this._matchDay--;
			this._nextDay--;
			this.load();
		},
		load: function() {

			if(!this.competition || !this.stage) return false;

			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.competitionId + '/stage/' + this.stageId + '/match-day/' + this._matchDay).then(function(data) {
				this.first = data.data;
			});

			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.competitionId + '/stage/' + this.stageId + '/match-day/' + this._nextDay).then(function(data) {
				this.next = data.data;
			});

		},
		click_editEvent: function(event) {
			this.editEvent = _.cloneDeep(event);
			this.ux.editEvent = true;
		},
		gen_emptyEvent: function() {
			return {
				home_id: null,
				away_id: null,
				home_result: null,
				away_result: null,
				date: null,
				status: 0,
				infos: null
			}
		},
		newEvent: function() {
			this.ux.newEvent = true;
		},
		createEvent: function() {
			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.competitionId + '/stage/' + this.stageId + '/match/' + this.first.id + '/new-event', { event: this.emptyEvent }).then(function(data) {
				
				this.load();
				this.$emit('refresh-match');
				this.ux.newEvent = false;
				this.emptyEvent = this.gen_emptyEvent();

			})
		},
		deleteEvent: function() {

			if(!confirm('?')) return false;

			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.competitionId + '/stage/' + this.stageId + '/match/' + this.editEvent.match.id + '/event/' + this.editEvent.id + '/delete').then(function(data) {
				
				this.load();
				this.$emit('refresh-match');
				this.ux.editEvent = false;
				this.editEvent = null;

			})
		},
		updateEvent: function() {
			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.editEvent.match.competition_id + '/stage/' + this.editEvent.match.competition_stage_id + '/match/' + this.editEvent.match.id + '/event/' + this.editEvent.id, { event: this.editEvent }).then(function(data) {
				
				this.load();
				this.$emit('refresh-match');
				this.ux.editEvent = false;
				this.editEvent = null;

			})
		}
	}
}

</script>