<template>
  <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 2928 1474" style="enable-background:new 0 0 2928 1474;" xml:space="preserve">
<g>
  <polygon id="XMLID_6261_" class="st0" style="fill:#4F8947;" points="2584.2,1339.4 232.3,1339.6 554.2,89.4 2260.2,87.2   "/>
  <polygon id="XMLID_2_" class="st1" style="fill:#F0F0F0;" points="2562.5,1402.3 254,1402.5 570,152.3 2244.5,150.1  "/>
  <rect id="XMLID_6260_" x="232.2" y="1229.7" class="st2" style="fill:#42903F;" width="2352.1" height="38.5"/>
  <polygon id="XMLID_6146_" class="st3" style="fill:#6AB65F;" points="2584.3,1229.7 2277.6,73.5 539.3,73.5 232.3,1229.7   "/>
  <polygon id="XMLID_6145_" class="st4" style="opacity:0.5;fill:#489E46;enable-background:new;" points="2584.3,1229.7 2277.6,73.5 539.3,73.5 232.3,1229.7   "/>
  <polygon id="XMLID_6144_" class="st3" style="fill:#6AB65F;" points="2502.2,1229.7 2238,151.8 576.6,151.8 313.2,1229.7   "/>
  <g id="XMLID_6587_">
    <g>
      <defs>
        <polygon id="SVGID_1_" points="467.8,1231.4 681.1,220.2 2134.5,220.2 2345.8,1229.7        "/>
      </defs>
      <clipPath id="SVGID_2_">
        <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
      </clipPath>
      <g id="XMLID_6292_" class="st5" style="clip-path:url(#SVGID_2_);">
        <rect id="XMLID_6347_" x="372.9" y="221.5" class="st6" style="fill:#59AA51;" width="2066.5" height="80.6"/>
        <rect id="XMLID_6346_" x="333.6" y="292.5" class="st3" style="fill:#6AB65F;" width="2145" height="89.1"/>
        <rect id="XMLID_6345_" x="294.3" y="372" class="st6" style="fill:#59AA51;" width="2223.5" height="97.5"/>
        <rect id="XMLID_6344_" x="255.1" y="460" class="st3" style="fill:#6AB65F;" width="2302.1" height="106"/>
        <rect id="XMLID_6343_" x="215.8" y="556.3" class="st6" style="fill:#59AA51;" width="2380.6" height="114.4"/>
        <rect id="XMLID_6342_" x="176.6" y="661.2" class="st3" style="fill:#6AB65F;" width="2459.1" height="122.8"/>
        <rect id="XMLID_6341_" x="137.3" y="774.4" class="st6" style="fill:#59AA51;" width="2537.6" height="131.3"/>
        <rect id="XMLID_6340_" x="98" y="896.1" class="st3" style="fill:#6AB65F;" width="2616.2" height="139.7"/>
        <rect id="XMLID_6339_" x="58.8" y="1026.3" class="st6" style="fill:#59AA51;" width="2694.7" height="148.2"/>
        <rect id="XMLID_6338_" x="19.5" y="1164.9" class="st3" style="fill:#6AB65F;" width="2773.2" height="156.6"/>
        <rect id="XMLID_6586_" x="-21.8" y="1318.4" class="st6" style="fill:#59AA51;" width="2855.8" height="191.4"/>
      </g>
    </g>
  </g>
  <polygon id="XMLID_773_" class="st22" style="fill:#ccc;stroke:#FFFFFF;stroke-width:0.7117;stroke-miterlimit:10;" points="919.7,458.9 951.5,218.9 1863.9,218.9 1895.6,458.9  "/>
  <text x="1150" y="390" font-family="sans-serif" font-size="170" fill="black">{{ dentro }} / {{ dentro_percent }}%</text>
  <text x="1150" y="900" font-family="sans-serif" font-size="170" fill="black"> {{ fuori }} / {{ fuori_percent }}%</text>
  <polygon id="XMLID_769_" class="st7" style="fill:none;stroke:#FFFFFF;stroke-width:2.7666;stroke-miterlimit:10;" points="1194.9,292.5 1199.3,218.9 1616,218.9 1620.4,292.5  "/>
  <g id="XMLID_6659_">
    <rect id="XMLID_6811_" x="232.2" y="1268.3" class="st8" style="fill:#BFBFBF;" width="2352" height="71.3"/>
  </g>
  <path id="XMLID_6201_" class="st9" style="fill:none;stroke:#FFFFFF;stroke-width:3.8787;stroke-miterlimit:10;" d="M1594.7,458.9c-39.7,50-108.8,83.1-187.4,83.1c-77,0-144.9-31.8-185-80.1"/>
  <line id="XMLID_6200_" class="st10" style="fill:none;stroke:#FFFFFF;stroke-width:2.0921;stroke-miterlimit:10;" x1="679.4" y1="220.2" x2="2133.5" y2="220.2"/>
  <polyline id="XMLID_6199_" class="st7" style="fill:none;stroke:#FFFFFF;stroke-width:2.7666;stroke-miterlimit:10;" points="467.8,1231.4 681.1,218.9 2134.5,218.9 2345.8,1229.7  "/>
  <path id="XMLID_6202_" class="st11" style="fill:none;stroke:#FFFFFF;stroke-width:5.1292;stroke-miterlimit:10;" d="M1159.2,1231.4c37.3-100.3,134.5-171.8,248.6-171.8c114.1,0,211.3,71.5,248.6,171.8"/>
  <path id="XMLID_5627_" class="st12" style="opacity:0.1;fill:#090400;enable-background:new;" d="M1314.5,220.5l3.7,0.4c0.1-0.2,15.1-17.7,15.1-17.7l175.5,0.3l-14.7,17.4h4l16.8-19.9
    h-184.1L1314.5,220.5z"/>
  <path id="XMLID_5626_" class="st13" style="opacity:0.25;fill:#FFFFFF;enable-background:new;" d="M1320,128.9c-1,21-0.5,23.1,0,42.3c0.6,22.7,0.4,8.3,1.7,19.7h169.5c0,0,0.6,3.4,1.4-15.5
    c0.5-12.9,0.8-15,0.3-46.5c0,0-33.3,1.9-79,1.9C1368.2,130.8,1320,128.9,1320,128.9z"/>
  <polyline id="XMLID_5625_" class="st14" style="fill:none;stroke:#FFFFFF;stroke-width:4.1175;stroke-miterlimit:10;" points="1316.3,220.9 1316.3,149.3 1496.2,149.3 1496.2,220.9   "/>
  <path id="XMLID_5624_" class="st13" style="opacity:0.25;fill:#FFFFFF;enable-background:new;" d="M1314.5,146.5l5.4-17.7c0,0,48.8,1.9,94,1.9c45.2,0,78.9-1.9,78.9-1.9l5.1,17.4
    L1314.5,146.5z"/>
  <path id="XMLID_5623_" class="st15" style="opacity:0.5;fill:#FFFFFF;enable-background:new;" d="M1320,128.9c-0.7,30-0.3,32.1,0.3,49.6c0.9,23.5,1.4,12.5,1.4,12.5s-5.7,28.3-7,27.1
    c-1.6-1.4,0.1-79.1-0.1-65.4L1320,128.9z"/>
  <path id="XMLID_5622_" class="st15" style="opacity:0.5;fill:#FFFFFF;enable-background:new;" d="M1492.9,128.9c0.6,28.2,0.2,30.2-0.4,47.7c-0.9,23.5-1.3,14.3-1.3,14.3s7,29.1,7,27.1
    c0.2-8.2,0.1,10.2-0.3-65.7L1492.9,128.9z"/>
  <path id="XMLID_6168_" class="st16" style="fill:none;stroke:#FFFFFF;stroke-width:3.3151;stroke-miterlimit:10;" d="M2116.3,220.2c5.8,14.9,22.2,14.9,22.2,14.9"/>
  <path id="XMLID_6851_" class="st7" style="fill:none;stroke:#FFFFFF;stroke-width:2.7666;stroke-miterlimit:10;" d="M699,220.2c-5.8,14.9-22.2,14.9-22.2,14.9"/>
  <g id="XMLID_1006_">
    <polygon id="XMLID_1463_" class="st17" style="fill:#1D1D1B;" points="1190,98.3 1192.4,141.6 1168,141.6    "/>
    <path id="XMLID_1462_" class="st18" style="opacity:0.15;fill:#090400;enable-background:new;" d="M1187.7,156c0,0,2.6-2.3,3.9-5.9c1.3-3.6,1-8.4,1-8.4l-395.6-11
      c-21.3,0.3-40.2-8.5-15,25.2C813.7,156.1,1187.7,156,1187.7,156z"/>
    <polygon id="XMLID_1056_" class="st17" style="fill:#1D1D1B;" points="1187.7,156.1 781.9,156.1 783.9,98.3 1189.8,98.3    "/>
    <g id="XMLID_1007_">
      <path id="XMLID_1033_" class="st19" style="fill:#FFFFFF;" d="M849,131.4c-0.2,0-0.2,0.1-0.3,0.3l-2,4.8c-0.4,0.9-0.6,1.7-0.6,2.1
        c0,0.6,0.3,1.1,1.3,1.1h0.5c0.4,0,0.5,0.1,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1.1,0-2.6-0.1-3.7-0.1c-0.4,0-2.3,0.1-4.1,0.1
        c-0.5,0-0.7-0.1-0.6-0.3c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.8,0,1.1-0.1c1.6-0.2,2.3-1.4,3.1-3.1l9.5-21.5c0.5-1,0.7-1.4,1-1.4
        c0.3,0,0.5,0.3,0.8,1.1c0.8,1.9,5.8,15.8,7.9,21c1.2,3.1,2.2,3.6,2.9,3.8c0.5,0.1,1,0.1,1.5,0.1c0.3,0,0.4,0,0.4,0.3
        s-0.3,0.3-1.6,0.3c-1.2,0-3.8,0-6.6-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.5-0.4,0.3-0.8l-2.5-7.1
        c-0.1-0.2-0.2-0.2-0.3-0.2L849,131.4L849,131.4z M856.2,129.7c0.2,0,0.2-0.1,0.2-0.2l-2.8-8.5c0-0.1-0.1-0.3-0.2-0.3
        s-0.2,0.2-0.2,0.3l-3.5,8.5c0,0.1,0,0.2,0.1,0.2H856.2z"/>
      <path id="XMLID_1031_" class="st19" style="fill:#FFFFFF;" d="M869.4,139.8c-0.5-0.2-0.6-0.4-0.6-1.2c0.1-1.9,0.3-4,0.3-4.5c0.1-0.5,0.2-0.9,0.4-0.9
        c0.3,0,0.3,0.3,0.3,0.6c0,0.5,0.1,1.2,0.2,1.8c0.7,2.6,2.7,3.5,4.8,3.5c3.1,0,4.7-2.1,4.8-4c0.1-1.7-0.4-3.3-3.2-5.5l-1.6-1.2
        c-3.7-3-4.9-5.4-4.8-8.2c0.1-3.8,3.4-6.6,8.2-6.6c2.3,0,3.7,0.3,4.6,0.6c0.3,0.1,0.5,0.2,0.5,0.4c0,0.5-0.2,1.5-0.3,4.2
        c0,0.8-0.1,1-0.4,1c-0.2,0-0.3-0.2-0.3-0.6c0-0.3-0.1-1.4-0.8-2.3c-0.5-0.7-1.5-1.7-3.7-1.7c-2.6,0-4.2,1.5-4.3,3.6
        c-0.1,1.6,0.7,2.8,3.5,5l0.9,0.7c4.1,3.2,5.5,5.5,5.4,8.8c-0.1,2-0.9,4.4-3.5,6c-1.8,1.1-3.7,1.4-5.6,1.4
        C872.4,140.7,870.8,140.5,869.4,139.8z"/>
      <path id="XMLID_1028_" class="st19" style="fill:#FFFFFF;" d="M902.4,124.1c0.2-5.3,0.2-6.2,0.2-7.4c0-1.2-0.3-1.7-1.4-2c-0.3-0.1-0.9-0.1-1.3-0.1
        s-0.6-0.1-0.6-0.3c0-0.2,0.3-0.3,0.8-0.3c1.8,0,4,0.1,4.7,0.1c1.2,0,3.8-0.1,4.9-0.1c2.2,0,4.5,0.2,6.3,1.5
        c0.9,0.7,2.3,2.4,2.2,4.8c-0.1,2.6-1.2,4.9-4.8,7.8c3,3.9,5.2,7,7.2,9.1c1.9,2,3.3,2.3,3.8,2.4c0.4,0.1,0.7,0.1,1,0.1
        s0.4,0.1,0.4,0.3c0,0.3-0.3,0.3-0.7,0.3h-3.3c-1.9,0-2.8-0.2-3.7-0.7c-1.5-0.8-2.7-2.4-4.6-5.1c-1.3-1.9-2.8-4.3-3.3-4.8
        c-0.2-0.2-0.4-0.2-0.6-0.2l-3-0.1c-0.2,0-0.3,0.1-0.3,0.3v0.5c-0.1,3.2-0.2,6-0.1,7.4c0.1,1,0.3,1.8,1.3,1.9
        c0.5,0.1,1.3,0.1,1.7,0.1c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.3,0.3-0.7,0.3c-2,0-4.6-0.1-5.1-0.1c-0.7,0-2.8,0.1-4.2,0.1
        c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.6-0.3s0.9-0.1,1.3-0.1c0.7-0.1,0.9-0.9,1.1-1.9c0.2-1.5,0.3-4.2,0.4-7.4L902.4,124.1z
         M906.4,126.8c0,0.4,0.1,0.5,0.3,0.6c0.7,0.2,1.8,0.3,2.6,0.3c1.4,0,1.8-0.1,2.5-0.6c1.1-0.8,2.1-2.4,2.2-5.2
        c0.2-4.9-3-6.3-5.1-6.3c-0.9,0-1.5,0-1.8,0.1c-0.2,0.1-0.3,0.2-0.3,0.5L906.4,126.8z"/>
      <path id="XMLID_1025_" class="st19" style="fill:#FFFFFF;" d="M924.5,127.2c0.2-5.9,4.4-13.6,14.8-13.6c8.6,0,13.8,5,13.6,13c-0.3,7.9-6,14.2-14.9,14.2
        C928.1,140.7,924.3,133.3,924.5,127.2z M948,127.8c0.3-7.8-4-12.6-9.7-12.6c-4,0-8.6,2.2-8.9,10.9c-0.3,7.2,3.5,12.9,10.2,12.9
        C942,139,947.6,137.8,948,127.8z"/>
      <path id="XMLID_1023_" class="st19" style="fill:#FFFFFF;" d="M962,114.5c0.1-0.6,0.3-0.9,0.6-0.9c0.3,0,0.5,0.2,0.9,1.1l8.8,19.3l10.1-19.5
        c0.3-0.6,0.5-0.8,0.8-0.8c0.3,0,0.5,0.3,0.6,1.1l2.4,21.2c0.2,2.2,0.5,3.2,1.6,3.5c1,0.3,1.7,0.3,2.1,0.3c0.3,0,0.6,0,0.5,0.2
        c0,0.3-0.4,0.4-0.9,0.4c-0.9,0-5.6-0.1-7-0.2c-0.8-0.1-1-0.2-1-0.4c0-0.2,0.1-0.3,0.4-0.4c0.2-0.1,0.3-0.5,0.2-1.5l-1.6-15.1
        h-0.1l-8.1,15.5c-0.9,1.6-1,1.9-1.4,1.9c-0.3,0-0.6-0.7-1.2-1.7c-0.8-1.6-3.3-6.9-3.7-7.9c-0.3-0.7-2.2-4.8-3.3-7.4h-0.1
        l-2.3,13.5c-0.1,0.6-0.1,1.1-0.2,1.6c0,0.7,0.4,1,1,1.1s1.1,0.2,1.5,0.2c0.3,0,0.5,0.1,0.5,0.2c0,0.3-0.3,0.4-0.8,0.4
        c-1.5,0-3-0.1-3.6-0.1c-0.7,0-2.3,0.1-3.4,0.1c-0.3,0-0.6-0.1-0.6-0.4c0-0.2,0.2-0.2,0.6-0.2c0.3,0,0.5,0,1-0.1
        c1-0.2,1.3-1.6,1.5-2.7L962,114.5z"/>
      <path id="XMLID_1020_" class="st19" style="fill:#FFFFFF;" d="M998,131.4c-0.2,0-0.2,0.1-0.3,0.3l-2,4.8c-0.4,0.9-0.6,1.7-0.6,2.1
        c0,0.6,0.3,1.1,1.3,1.1h0.5c0.4,0,0.5,0.1,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1.1,0-2.6-0.1-3.7-0.1c-0.4,0-2.3,0.1-4.1,0.1
        c-0.5,0-0.7-0.1-0.6-0.3c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.8,0,1.1-0.1c1.6-0.2,2.3-1.4,3.1-3.1l9.5-21.5c0.5-1,0.7-1.4,1-1.4
        c0.3,0,0.5,0.3,0.8,1.1c0.8,1.9,5.8,15.8,7.9,21c1.2,3.1,2.2,3.6,2.9,3.8c0.5,0.1,1,0.1,1.5,0.1c0.3,0,0.4,0,0.4,0.3
        s-0.3,0.3-1.6,0.3c-1.2,0-3.8,0-6.6-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.5-0.4,0.3-0.8l-2.5-7.1
        c-0.1-0.2-0.2-0.2-0.3-0.2L998,131.4L998,131.4z M1005.2,129.7c0.2,0,0.2-0.1,0.2-0.2l-2.8-8.5c0-0.1-0.1-0.3-0.2-0.3
        s-0.2,0.2-0.2,0.3l-3.5,8.5c0,0.1,0,0.2,0.1,0.2H1005.2z"/>
      <path id="XMLID_1018_" class="st19" style="fill:#FFFFFF;" d="M1029.1,139.8c-0.5-0.2-0.6-0.4-0.6-1.2c0.1-1.9,0.3-4,0.3-4.5c0.1-0.5,0.2-0.9,0.4-0.9
        c0.3,0,0.3,0.3,0.3,0.6c0,0.5,0.1,1.2,0.2,1.8c0.7,2.6,2.7,3.5,4.8,3.5c3.1,0,4.7-2.1,4.8-4c0.1-1.7-0.4-3.3-3.2-5.5l-1.6-1.2
        c-3.7-3-4.9-5.4-4.8-8.2c0.1-3.8,3.4-6.6,8.2-6.6c2.3,0,3.7,0.3,4.6,0.6c0.3,0.1,0.5,0.2,0.5,0.4c0,0.5-0.2,1.5-0.3,4.2
        c0,0.8-0.1,1-0.4,1c-0.2,0-0.3-0.2-0.3-0.6c0-0.3-0.1-1.4-0.8-2.3c-0.5-0.7-1.5-1.7-3.7-1.7c-2.6,0-4.2,1.5-4.3,3.6
        c-0.1,1.6,0.7,2.8,3.5,5l0.9,0.7c4.1,3.2,5.5,5.5,5.4,8.8c-0.1,2-0.9,4.4-3.5,6c-1.8,1.1-3.7,1.4-5.6,1.4
        C1032.1,140.7,1030.6,140.5,1029.1,139.8z"/>
      <path id="XMLID_1016_" class="st19" style="fill:#FFFFFF;" d="M1055.4,131.3c0.1-1.9-0.3-2.6-0.7-3.6c-0.2-0.6-4.6-8.2-5.8-10c-0.8-1.3-1.7-2.1-2.3-2.5
        c-0.4-0.2-1-0.5-1.4-0.5c-0.3,0-0.5-0.1-0.5-0.3c0-0.2,0.3-0.3,0.7-0.3c0.6,0,3.2,0.1,4.4,0.1c0.7,0,2.2-0.1,3.7-0.1
        c0.3,0,0.5,0.1,0.5,0.3c0,0.2-0.3,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.5,0.6c0,0.3,0.1,0.7,0.4,1.2c0.4,1,5,9.4,5.5,10.4
        c0.4-0.8,5-8.5,5.5-9.5c0.4-0.8,0.5-1.4,0.5-1.8c0-0.3-0.1-0.7-0.7-0.9c-0.3-0.1-0.6-0.1-0.6-0.3c0-0.2,0.2-0.3,0.6-0.3
        c1.1,0,2.3,0.1,3.3,0.1c0.8,0,3.1-0.1,3.7-0.1c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.3,0.3-0.7,0.3c-0.3,0-1,0.1-1.5,0.4
        c-0.7,0.3-1.1,0.8-1.9,1.7c-1.2,1.4-6.3,9.5-7,11c-0.6,1.2-0.6,2.3-0.6,3.5l-0.1,2.8c0,0.6-0.1,2,0,3.5c0,1,0.3,1.8,1.4,1.9
        c0.5,0.1,1.2,0.1,1.7,0.1c0.4,0,0.6,0.1,0.5,0.3c0,0.2-0.3,0.3-0.7,0.3c-2.2,0-4.4-0.1-5.3-0.1s-3.1,0.1-4.3,0.1
        c-0.5,0-0.8-0.1-0.8-0.3c0-0.2,0.2-0.3,0.6-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,1-0.9,1.1-1.9c0.2-1.5,0.2-2.9,0.2-3.5
        L1055.4,131.3z"/>
      <path id="XMLID_1014_" class="st19" style="fill:#FFFFFF;" d="M1072.5,139.8c-0.5-0.2-0.6-0.4-0.6-1.2c0.1-1.9,0.3-4,0.3-4.5c0.1-0.5,0.2-0.9,0.4-0.9
        c0.3,0,0.3,0.3,0.3,0.6c0,0.5,0.1,1.2,0.2,1.8c0.7,2.6,2.7,3.5,4.8,3.5c3.1,0,4.7-2.1,4.8-4c0.1-1.7-0.4-3.3-3.2-5.5l-1.6-1.2
        c-3.7-3-4.9-5.4-4.8-8.2c0.1-3.8,3.4-6.6,8.2-6.6c2.3,0,3.7,0.3,4.6,0.6c0.3,0.1,0.5,0.2,0.5,0.4c0,0.5-0.2,1.5-0.3,4.2
        c0,0.8-0.1,1-0.4,1c-0.2,0-0.3-0.2-0.3-0.6c0-0.3-0.1-1.4-0.8-2.3c-0.5-0.7-1.5-1.7-3.7-1.7c-2.6,0-4.2,1.5-4.3,3.6
        c-0.1,1.6,0.7,2.8,3.5,5l0.9,0.7c4.1,3.2,5.5,5.5,5.4,8.8c-0.1,2-0.9,4.4-3.5,6c-1.8,1.1-3.7,1.4-5.6,1.4
        C1075.5,140.7,1074,140.5,1072.5,139.8z"/>
      <path id="XMLID_1012_" class="st19" style="fill:#FFFFFF;" d="M1100,116l-5.2,0.1c-2,0.1-2.9,0.2-3.4,1c-0.4,0.5-0.6,0.9-0.6,1.2
        c-0.1,0.3-0.2,0.4-0.4,0.4c-0.2,0-0.3-0.2-0.3-0.6c0-0.6,0.8-3.7,0.9-4c0.1-0.5,0.2-0.7,0.4-0.7c0.3,0,0.6,0.3,1.5,0.4
        c1,0.1,2.3,0.2,3.5,0.2h13.7c1.1,0,1.9-0.1,2.4-0.2s0.8-0.2,0.9-0.2c0.2,0,0.3,0.2,0.3,0.7c0,0.7-0.2,3.1-0.2,4
        c0,0.3-0.1,0.6-0.3,0.6c-0.3,0-0.3-0.2-0.4-0.7v-0.4c0-0.9-0.9-1.9-4-1.9l-4.4-0.1l-0.5,14.2c-0.1,3.2-0.2,5.9-0.1,7.4
        c0.1,1,0.3,1.7,1.3,1.9c0.5,0.1,1.2,0.1,1.8,0.1c0.4,0,0.6,0.1,0.5,0.3c0,0.2-0.3,0.3-0.7,0.3c-2.3,0-4.5-0.1-5.4-0.1
        c-0.8,0-3,0.1-4.4,0.1c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.6-0.3s0.9-0.1,1.3-0.1c0.7-0.1,0.9-0.9,1.1-1.9
        c0.2-1.5,0.3-4.2,0.4-7.4L1100,116z"/>
      <path id="XMLID_1010_" class="st19" style="fill:#FFFFFF;" d="M1119.8,124.1c0.2-5.3,0.2-6.2,0.2-7.4c0-1.2-0.3-1.7-1.4-2c-0.3-0.1-0.9-0.1-1.3-0.1
        c-0.4,0-0.6-0.1-0.6-0.3c0-0.2,0.3-0.3,0.8-0.3c0.9,0,1.9,0,2.8,0c0.9,0,1.7,0.1,2.2,0.1c1.1,0,8,0,8.7,0
        c0.7-0.1,1.2-0.1,1.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.2,0.1,0.2,0.3c0,0.2-0.2,0.7-0.4,2.3c0,0.3-0.2,1.9-0.3,2.3
        c0,0.2-0.1,0.4-0.4,0.4c-0.3,0-0.3-0.2-0.3-0.5c0-0.2,0-0.8-0.2-1.2c-0.2-0.6-0.6-1-2.3-1.2c-0.6-0.1-4.2-0.1-4.9-0.1
        c-0.2,0-0.2,0.1-0.3,0.3l-0.3,8.8c0,0.2,0,0.4,0.2,0.4c0.8,0,4.7,0,5.5-0.1c0.8-0.1,1.4-0.2,1.7-0.5s0.4-0.5,0.6-0.5
        c0.1,0,0.2,0.1,0.2,0.3c0,0.2-0.2,0.8-0.4,2.5c-0.1,0.7-0.2,2.1-0.2,2.3c0,0.3,0,0.7-0.3,0.7c-0.2,0-0.3-0.1-0.3-0.3
        c0-0.3,0-0.8-0.1-1.2c-0.1-0.7-0.6-1.2-1.9-1.4c-0.7-0.1-4.1-0.1-4.9-0.1c-0.2,0-0.2,0.1-0.2,0.3l-0.1,2.7c0,1.2-0.2,4.4-0.2,5.4
        c0,2.4,0.5,2.9,4.1,2.9c0.9,0,2.4,0,3.3-0.4s1.4-1.1,1.6-2.6c0.1-0.4,0.2-0.5,0.4-0.5c0.3,0,0.3,0.3,0.3,0.6
        c0,0.8-0.4,3.2-0.6,3.8c-0.3,0.9-0.6,0.9-1.9,0.9c-2.6,0-4.5-0.1-6-0.1c-1.5-0.1-2.6-0.1-3.6-0.1c-0.4,0-1.1,0-2,0
        c-0.8,0-1.7,0.1-2.4,0.1c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.6-0.3s0.9-0.1,1.3-0.1c0.7-0.1,0.9-0.9,1.1-1.9
        c0.2-1.5,0.3-4.2,0.4-7.4L1119.8,124.1z"/>
      <path id="XMLID_1008_" class="st19" style="fill:#FFFFFF;" d="M1143.3,114.5c0.1-0.6,0.3-0.9,0.6-0.9c0.3,0,0.5,0.2,0.9,1.1l8.8,19.3l10.1-19.5
        c0.3-0.6,0.5-0.8,0.8-0.8s0.5,0.3,0.6,1.1l2.4,21.2c0.2,2.2,0.5,3.2,1.6,3.5c1,0.3,1.7,0.3,2.1,0.3c0.3,0,0.6,0,0.5,0.2
        c0,0.3-0.4,0.4-0.9,0.4c-0.9,0-5.6-0.1-7-0.2c-0.8-0.1-1-0.2-1-0.4c0-0.2,0.1-0.3,0.4-0.4c0.2-0.1,0.3-0.5,0.2-1.5l-1.6-15.1
        h-0.1l-8.1,15.5c-0.9,1.6-1,1.9-1.4,1.9c-0.3,0-0.6-0.7-1.2-1.7c-0.8-1.6-3.3-6.9-3.7-7.9c-0.3-0.7-2.2-4.8-3.3-7.4h-0.1
        l-2.3,13.5c-0.1,0.6-0.1,1.1-0.2,1.6c0,0.7,0.4,1,1,1.1s1.1,0.2,1.5,0.2c0.3,0,0.5,0.1,0.5,0.2c0,0.3-0.3,0.4-0.8,0.4
        c-1.5,0-3-0.1-3.6-0.1c-0.7,0-2.3,0.1-3.4,0.1c-0.3,0-0.6-0.1-0.6-0.4c0-0.2,0.2-0.2,0.6-0.2c0.3,0,0.5,0,1-0.1
        c1-0.2,1.3-1.6,1.5-2.7L1143.3,114.5z"/>
    </g>
    <g>
      <path class="st20" style="fill:#A31C30;" d="M799.7,113.7c0,0,4.5-1.1,8.4-3.1c4.7-2.4,7.6-5.1,7.6-5.1s3.2,2.9,7.2,5.1c4.3,2.3,8.2,3.1,8.2,3.1
        s0.5,3.6,0.3,6.2c-0.3,4-1.1,11.5-7.5,19.4c-5.3,6.6-9.7,8.3-9.7,8.3s-5.8-3.6-9-8.1s-6.7-11.2-6.2-20
        C799.2,115.3,799.7,113.7,799.7,113.7z"/>
      <path class="st21" style="fill:#EC7004;" d="M830.4,126.5c0.7-2.6,0.9-4.9,1-6.5c0.2-2.6-0.3-6.2-0.3-6.2s-3.9-0.8-8.2-3.1c-4-2.2-7.2-5.1-7.2-5.1
        s-2.9,2.7-7.6,5.1c-3.9,2-8.4,3.1-8.4,3.1s-0.5,1.6-0.7,5.7c-0.1,2.5,0,4.9,0.5,7H830.4z"/>
    </g>
  </g>
  <g id="XMLID_1107_">
    <polygon id="XMLID_1461_" class="st17" style="fill:#1D1D1B;" points="1631.4,98.3 1629,141.6 1653.4,141.6    "/>
    <path id="XMLID_1458_" class="st18" style="opacity:0.15;fill:#090400;enable-background:new;" d="M1633.7,156c0,0-2.6-2.3-3.9-5.9c-1.3-3.6-1-8.4-1-8.4l395.6-11c21.3,0.3,40.2-8.5,15,25.2
      C2007.7,156.1,1633.7,156,1633.7,156z"/>
    <polygon id="XMLID_1156_" class="st17" style="fill:#1D1D1B;" points="2039.2,156.1 1633.4,156.1 1631.4,98.3 2037.2,98.3    "/>
    <g id="XMLID_1108_">
      <path id="XMLID_1134_" class="st19" style="fill:#FFFFFF;" d="M1698.8,131.4c-0.2,0-0.2,0.1-0.3,0.3l-1.7,4.8c-0.3,0.9-0.5,1.7-0.4,2.1
        c0,0.6,0.4,1.1,1.4,1.1h0.5c0.4,0,0.5,0.1,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1.1,0-2.6-0.1-3.7-0.1c-0.4,0-2.3,0.1-4.1,0.1
        c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.8,0,1.1-0.1c1.6-0.2,2.2-1.4,2.8-3.1l8-21.5c0.4-1,0.6-1.4,0.9-1.4
        c0.3,0,0.5,0.3,0.9,1.1c0.9,1.9,6.9,15.8,9.3,21c1.4,3.1,2.4,3.6,3.2,3.8c0.5,0.1,1,0.1,1.5,0.1c0.3,0,0.5,0,0.5,0.3
        s-0.3,0.3-1.6,0.3c-1.2,0-3.8,0-6.6-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.5-0.4,0.3-0.8l-3-7.1
        c-0.1-0.2-0.2-0.2-0.4-0.2L1698.8,131.4L1698.8,131.4z M1705.8,129.7c0.2,0,0.2-0.1,0.2-0.2l-3.4-8.5c0-0.1-0.1-0.3-0.2-0.3
        s-0.2,0.2-0.2,0.3l-2.9,8.5c0,0.1,0,0.2,0.1,0.2H1705.8z"/>
      <path id="XMLID_1132_" class="st19" style="fill:#FFFFFF;" d="M1719.7,139.8c-0.6-0.2-0.7-0.4-0.7-1.2c-0.1-1.9,0-4,0-4.5s0.1-0.9,0.4-0.9
        c0.3,0,0.4,0.3,0.4,0.6c0,0.5,0.2,1.2,0.4,1.8c0.9,2.6,2.9,3.5,5.1,3.5c3.1,0,4.6-2.1,4.5-4c-0.1-1.7-0.6-3.3-3.6-5.5l-1.6-1.2
        c-3.9-3-5.3-5.4-5.4-8.2c-0.1-3.8,3-6.6,7.8-6.6c2.3,0,3.7,0.3,4.6,0.6c0.3,0.1,0.5,0.2,0.5,0.4c0,0.5-0.1,1.5,0,4.2
        c0,0.8-0.1,1-0.3,1c-0.2,0-0.4-0.2-0.4-0.6c0-0.3-0.2-1.4-1-2.3c-0.5-0.7-1.6-1.7-3.8-1.7c-2.6,0-4.1,1.5-4,3.6
        c0.1,1.6,0.9,2.8,3.9,5l1,0.7c4.3,3.2,5.9,5.5,6,8.8c0.1,2-0.6,4.4-3,6c-1.7,1.1-3.6,1.4-5.5,1.4
        C1722.8,140.7,1721.2,140.5,1719.7,139.8z"/>
      <path id="XMLID_1129_" class="st19" style="fill:#FFFFFF;" d="M1751.7,124.1c-0.2-5.3-0.2-6.2-0.3-7.4c-0.1-1.2-0.4-1.7-1.6-2c-0.3-0.1-0.9-0.1-1.4-0.1
        c-0.4,0-0.6-0.1-0.6-0.3c0-0.2,0.2-0.3,0.8-0.3c1.8,0,4,0.1,4.8,0.1c1.2,0,3.8-0.1,4.9-0.1c2.2,0,4.5,0.2,6.4,1.5
        c1,0.7,2.4,2.4,2.5,4.8c0.1,2.6-0.9,4.9-4.3,7.8c3.2,3.9,5.7,7,7.8,9.1c2,2,3.4,2.3,4,2.4c0.4,0.1,0.7,0.1,1,0.1
        c0.3,0,0.4,0.1,0.4,0.3c0,0.3-0.2,0.3-0.6,0.3h-3.3c-1.9,0-2.8-0.2-3.7-0.7c-1.5-0.8-2.9-2.4-4.9-5.1c-1.5-1.9-3.1-4.3-3.6-4.8
        c-0.2-0.2-0.4-0.2-0.6-0.2l-3-0.1c-0.2,0-0.3,0.1-0.3,0.3v0.5c0.1,3.2,0.2,6,0.4,7.4c0.1,1,0.4,1.8,1.4,1.9
        c0.5,0.1,1.3,0.1,1.7,0.1c0.3,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.7,0.3c-2,0-4.6-0.1-5.1-0.1c-0.7,0-2.8,0.1-4.2,0.1
        c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,0.8-0.9,0.9-1.9c0.1-1.5,0-4.2-0.1-7.4
        L1751.7,124.1z M1755.9,126.8c0,0.4,0.1,0.5,0.3,0.6c0.7,0.2,1.8,0.3,2.6,0.3c1.4,0,1.8-0.1,2.4-0.6c1-0.8,2-2.4,1.9-5.2
        c-0.2-4.9-3.5-6.3-5.5-6.3c-0.9,0-1.5,0-1.8,0.1c-0.2,0.1-0.3,0.2-0.3,0.5L1755.9,126.8z"/>
      <path id="XMLID_1126_" class="st19" style="fill:#FFFFFF;" d="M1774,127.2c-0.2-5.9,3.4-13.6,13.8-13.6c8.6,0,14.2,5,14.5,13
        c0.3,7.9-5.1,14.2-13.9,14.2C1778.5,140.7,1774.2,133.3,1774,127.2z M1797.5,127.8c-0.3-7.8-4.9-12.6-10.6-12.6
        c-4,0-8.5,2.2-8.1,10.9c0.3,7.2,4.4,12.9,11.1,12.9C1792.3,139,1797.9,137.8,1797.5,127.8z"/>
      <path id="XMLID_1124_" class="st19" style="fill:#FFFFFF;" d="M1810.6,114.5c0.1-0.6,0.3-0.9,0.6-0.9c0.3,0,0.5,0.2,1,1.1l10.1,19.3l8.7-19.5
        c0.3-0.6,0.4-0.8,0.7-0.8c0.3,0,0.5,0.3,0.7,1.1l3.9,21.2c0.4,2.2,0.8,3.2,1.8,3.5s1.7,0.3,2.2,0.3c0.3,0,0.6,0,0.6,0.2
        c0,0.3-0.4,0.4-0.9,0.4c-0.9,0-5.7-0.1-7-0.2c-0.8-0.1-1-0.2-1-0.4c0-0.2,0.1-0.3,0.4-0.4c0.2-0.1,0.3-0.5,0.1-1.5l-2.6-15.1
        h-0.1l-7,15.5c-0.7,1.6-0.9,1.9-1.2,1.9c-0.3,0-0.7-0.7-1.3-1.7c-0.9-1.6-3.8-6.9-4.3-7.9c-0.3-0.7-2.6-4.8-3.9-7.4h-0.1
        l-1.3,13.5c0,0.6-0.1,1.1,0,1.6c0,0.7,0.5,1,1.1,1.1c0.6,0.1,1.1,0.2,1.5,0.2c0.3,0,0.5,0.1,0.5,0.2c0,0.3-0.3,0.4-0.8,0.4
        c-1.5,0-3-0.1-3.6-0.1c-0.7,0-2.3,0.1-3.4,0.1c-0.3,0-0.6-0.1-0.6-0.4c0-0.2,0.2-0.2,0.5-0.2s0.5,0,1-0.1c1-0.2,1.2-1.6,1.3-2.7
        L1810.6,114.5z"/>
      <path id="XMLID_1121_" class="st19" style="fill:#FFFFFF;" d="M1847.8,131.4c-0.2,0-0.2,0.1-0.3,0.3l-1.7,4.8c-0.3,0.9-0.5,1.7-0.4,2.1
        c0,0.6,0.4,1.1,1.4,1.1h0.5c0.4,0,0.5,0.1,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1.1,0-2.6-0.1-3.7-0.1c-0.4,0-2.3,0.1-4.1,0.1
        c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.8,0,1.1-0.1c1.6-0.2,2.2-1.4,2.8-3.1l8-21.5c0.4-1,0.6-1.4,0.9-1.4
        c0.3,0,0.5,0.3,0.9,1.1c0.9,1.9,6.9,15.8,9.3,21c1.4,3.1,2.4,3.6,3.2,3.8c0.5,0.1,1,0.1,1.5,0.1c0.3,0,0.5,0,0.5,0.3
        s-0.3,0.3-1.6,0.3c-1.2,0-3.8,0-6.6-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.5-0.4,0.3-0.8l-3-7.1
        c-0.1-0.2-0.2-0.2-0.4-0.2L1847.8,131.4L1847.8,131.4z M1854.8,129.7c0.2,0,0.2-0.1,0.2-0.2l-3.4-8.5c0-0.1-0.1-0.3-0.2-0.3
        c-0.1,0-0.2,0.2-0.2,0.3l-2.9,8.5c0,0.1,0,0.2,0.1,0.2H1854.8z"/>
      <path id="XMLID_1119_" class="st19" style="fill:#FFFFFF;" d="M1879.5,139.8c-0.6-0.2-0.7-0.4-0.7-1.2c-0.1-1.9,0-4,0-4.5s0.1-0.9,0.4-0.9
        s0.4,0.3,0.4,0.6c0,0.5,0.2,1.2,0.4,1.8c0.9,2.6,2.9,3.5,5.1,3.5c3.1,0,4.6-2.1,4.5-4c-0.1-1.7-0.6-3.3-3.6-5.5l-1.6-1.2
        c-3.9-3-5.3-5.4-5.4-8.2c-0.1-3.8,3-6.6,7.8-6.6c2.3,0,3.7,0.3,4.6,0.6c0.3,0.1,0.5,0.2,0.5,0.4c0,0.5-0.1,1.5,0,4.2
        c0,0.8-0.1,1-0.3,1c-0.2,0-0.4-0.2-0.4-0.6c0-0.3-0.2-1.4-1-2.3c-0.5-0.7-1.6-1.7-3.8-1.7c-2.6,0-4.1,1.5-4,3.6
        c0.1,1.6,0.9,2.8,3.9,5l1,0.7c4.3,3.2,5.9,5.5,6,8.8c0.1,2-0.6,4.4-3,6c-1.7,1.1-3.6,1.4-5.5,1.4
        C1882.6,140.7,1881,140.5,1879.5,139.8z"/>
      <path id="XMLID_1117_" class="st19" style="fill:#FFFFFF;" d="M1905.1,131.3c-0.1-1.9-0.4-2.6-0.9-3.6c-0.3-0.6-5.2-8.2-6.5-10
        c-0.9-1.3-1.8-2.1-2.5-2.5c-0.5-0.2-1.1-0.5-1.5-0.5c-0.3,0-0.5-0.1-0.5-0.3c0-0.2,0.2-0.3,0.6-0.3c0.6,0,3.2,0.1,4.4,0.1
        c0.7,0,2.1-0.1,3.7-0.1c0.3,0,0.5,0.1,0.5,0.3c0,0.2-0.2,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.5,0.6c0,0.3,0.2,0.7,0.5,1.2
        c0.5,1,5.7,9.4,6.3,10.4c0.3-0.8,4.4-8.5,4.9-9.5c0.3-0.8,0.4-1.4,0.4-1.8c0-0.3-0.2-0.7-0.8-0.9c-0.3-0.1-0.6-0.1-0.6-0.3
        c0-0.2,0.2-0.3,0.6-0.3c1.1,0,2.3,0.1,3.3,0.1c0.8,0,3-0.1,3.7-0.1c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.3,0.3-0.6,0.3s-1,0.1-1.5,0.4
        c-0.7,0.3-1.1,0.8-1.8,1.7c-1.1,1.4-5.7,9.5-6.2,11c-0.5,1.2-0.4,2.3-0.4,3.5l0.1,2.8c0,0.6,0.1,2,0.2,3.5c0.1,1,0.4,1.8,1.5,1.9
        c0.5,0.1,1.3,0.1,1.7,0.1s0.6,0.1,0.6,0.3c0,0.2-0.3,0.3-0.7,0.3c-2.2,0-4.4-0.1-5.3-0.1s-3,0.1-4.3,0.1c-0.5,0-0.8-0.1-0.8-0.3
        c0-0.2,0.2-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,1-0.9,1-1.9c0.1-1.5,0-2.9,0-3.5L1905.1,131.3z"/>
      <path id="XMLID_1115_" class="st19" style="fill:#FFFFFF;" d="M1922.9,139.8c-0.6-0.2-0.7-0.4-0.7-1.2c-0.1-1.9,0-4,0-4.5s0.1-0.9,0.4-0.9
        c0.3,0,0.4,0.3,0.4,0.6c0,0.5,0.2,1.2,0.4,1.8c0.9,2.6,2.9,3.5,5.1,3.5c3.1,0,4.6-2.1,4.5-4c-0.1-1.7-0.6-3.3-3.6-5.5l-1.6-1.2
        c-3.9-3-5.3-5.4-5.4-8.2c-0.1-3.8,3-6.6,7.8-6.6c2.3,0,3.7,0.3,4.6,0.6c0.3,0.1,0.5,0.2,0.5,0.4c0,0.5-0.1,1.5,0,4.2
        c0,0.8-0.1,1-0.3,1c-0.2,0-0.4-0.2-0.4-0.6c0-0.3-0.2-1.4-1-2.3c-0.5-0.7-1.6-1.7-3.8-1.7c-2.6,0-4.1,1.5-4,3.6
        c0.1,1.6,0.9,2.8,3.9,5l1,0.7c4.3,3.2,5.9,5.5,6,8.8c0.1,2-0.6,4.4-3,6c-1.7,1.1-3.6,1.4-5.5,1.4
        C1926,140.7,1924.4,140.5,1922.9,139.8z"/>
      <path id="XMLID_1113_" class="st19" style="fill:#FFFFFF;" d="M1948.7,116l-5.2,0.1c-2,0.1-2.8,0.2-3.3,1c-0.3,0.5-0.5,0.9-0.5,1.2
        c-0.1,0.3-0.2,0.4-0.4,0.4c-0.2,0-0.3-0.2-0.3-0.6c0-0.6,0.5-3.7,0.6-4c0.1-0.5,0.2-0.7,0.4-0.7c0.3,0,0.6,0.3,1.5,0.4
        c1,0.1,2.3,0.2,3.5,0.2h13.7c1.1,0,1.9-0.1,2.4-0.2s0.8-0.2,0.9-0.2c0.2,0,0.3,0.2,0.3,0.7c0,0.7,0,3.1,0,4
        c0,0.3-0.1,0.6-0.3,0.6c-0.3,0-0.4-0.2-0.4-0.7v-0.4c-0.1-0.9-1.1-1.9-4.2-1.9l-4.4-0.1l0.5,14.2c0.1,3.2,0.2,5.9,0.4,7.4
        c0.1,1,0.4,1.7,1.4,1.9c0.5,0.1,1.3,0.1,1.8,0.1c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.3,0.3-0.6,0.3c-2.3,0-4.5-0.1-5.4-0.1
        c-0.8,0-3,0.1-4.4,0.1c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,0.9-0.9,0.9-1.9
        c0.1-1.5,0-4.2-0.1-7.4L1948.7,116z"/>
      <path id="XMLID_1111_" class="st19" style="fill:#FFFFFF;" d="M1969,124.1c-0.2-5.3-0.2-6.2-0.3-7.4c-0.1-1.2-0.4-1.7-1.6-2c-0.3-0.1-0.9-0.1-1.4-0.1
        c-0.4,0-0.6-0.1-0.6-0.3s0.2-0.3,0.8-0.3c0.9,0,1.9,0,2.8,0c0.9,0,1.7,0.1,2.2,0.1c1.1,0,8,0,8.7,0c0.7-0.1,1.2-0.1,1.5-0.2
        c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.2,0.1,0.2,0.3c0,0.2-0.2,0.7-0.2,2.3c0,0.3,0,1.9-0.1,2.3c0,0.2-0.1,0.4-0.3,0.4
        s-0.3-0.2-0.3-0.5c0-0.2-0.1-0.8-0.3-1.2c-0.3-0.6-0.6-1-2.4-1.2c-0.6-0.1-4.2-0.1-4.9-0.1c-0.2,0-0.2,0.1-0.2,0.3l0.3,8.8
        c0,0.2,0,0.4,0.3,0.4c0.8,0,4.7,0,5.5-0.1c0.8-0.1,1.3-0.2,1.6-0.5c0.2-0.3,0.4-0.5,0.5-0.5c0.1,0,0.2,0.1,0.3,0.3
        c0,0.2-0.1,0.8-0.2,2.5c0,0.7-0.1,2.1-0.1,2.3c0,0.3,0,0.7-0.3,0.7c-0.2,0-0.3-0.1-0.3-0.3c0-0.3-0.1-0.8-0.2-1.2
        c-0.2-0.7-0.7-1.2-2-1.4c-0.7-0.1-4.1-0.1-4.9-0.1c-0.2,0-0.2,0.1-0.2,0.3l0.1,2.7c0,1.2,0.1,4.4,0.2,5.4
        c0.2,2.4,0.7,2.9,4.3,2.9c0.9,0,2.4,0,3.2-0.4c0.9-0.4,1.3-1.1,1.5-2.6c0.1-0.4,0.1-0.5,0.4-0.5s0.3,0.3,0.3,0.6
        c0,0.8-0.2,3.2-0.3,3.8c-0.2,0.9-0.5,0.9-1.8,0.9c-2.6,0-4.5-0.1-6-0.1c-1.5-0.1-2.6-0.1-3.6-0.1c-0.4,0-1.1,0-2,0
        c-0.8,0-1.7,0.1-2.4,0.1c-0.5,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,0.8-0.9,0.9-1.9
        c0.1-1.5,0-4.2-0.1-7.4L1969,124.1z"/>
      <path id="XMLID_1109_" class="st19" style="fill:#FFFFFF;" d="M1991.9,114.5c0.1-0.6,0.3-0.9,0.6-0.9s0.5,0.2,1,1.1l10.1,19.3l8.7-19.5
        c0.3-0.6,0.4-0.8,0.7-0.8s0.5,0.3,0.7,1.1l3.9,21.2c0.4,2.2,0.8,3.2,1.8,3.5s1.7,0.3,2.2,0.3c0.3,0,0.6,0,0.6,0.2
        c0,0.3-0.4,0.4-0.9,0.4c-0.9,0-5.7-0.1-7-0.2c-0.8-0.1-1-0.2-1-0.4c0-0.2,0.1-0.3,0.4-0.4c0.2-0.1,0.3-0.5,0.1-1.5l-2.6-15.1
        h-0.1l-7,15.5c-0.7,1.6-0.9,1.9-1.2,1.9s-0.7-0.7-1.3-1.7c-0.9-1.6-3.8-6.9-4.3-7.9c-0.3-0.7-2.6-4.8-3.9-7.4h-0.1l-1.3,13.5
        c0,0.6-0.1,1.1,0,1.6c0,0.7,0.5,1,1.1,1.1c0.6,0.1,1.1,0.2,1.5,0.2c0.3,0,0.5,0.1,0.5,0.2c0,0.3-0.3,0.4-0.8,0.4
        c-1.5,0-3-0.1-3.6-0.1c-0.7,0-2.3,0.1-3.4,0.1c-0.3,0-0.6-0.1-0.6-0.4c0-0.2,0.2-0.2,0.5-0.2s0.5,0,1-0.1c1-0.2,1.2-1.6,1.3-2.7
        L1991.9,114.5z"/>
    </g>
    <g>
      <path class="st20" style="fill:#A31C30;" d="M1648.4,113.7c0,0,4.5-1.1,8.2-3.1c4.6-2.4,7.3-5.1,7.3-5.1s3.3,2.9,7.5,5.1c4.4,2.3,8.3,3.1,8.3,3.1
        s0.7,3.6,0.7,6.2c0,4-0.5,11.5-6.5,19.4c-5,6.6-9.3,8.3-9.3,8.3s-6-3.6-9.4-8.1c-3.4-4.5-7.3-11.2-7.3-20
        C1647.9,115.3,1648.4,113.7,1648.4,113.7z"/>
      <path class="st21" style="fill:#EC7004;" d="M1679.7,126.5c0.5-2.6,0.6-4.9,0.6-6.5c0-2.6-0.7-6.2-0.7-6.2s-3.9-0.8-8.3-3.1c-4.1-2.2-7.5-5.1-7.5-5.1
        s-2.7,2.7-7.3,5.1c-3.8,2-8.2,3.1-8.2,3.1s-0.4,1.6-0.4,5.7c0,2.5,0.3,4.9,0.8,7H1679.7z"/>
    </g>
  </g>
</g>
</svg>
</template>

<script>
export default {
  name: 'HalfFieldSection',

  data() {
    return {
    }
  },
  props: {
    dentro: {
      type: Number,
      required: true
    },
    fuori: {
      type: Number,
      required: true
    },
  },
  computed: {
    totale: function() {
      return this.dentro + this.fuori;
    },
    dentro_percent: function() {
      if(this.totale > 0) {
        return Math.round((this.dentro * 100) / this.totale);
      } else {
        return 0;
      }
    },
    fuori_percent: function() {
      if(this.totale > 0) {
        return Math.round((this.fuori * 100) / this.totale);
      } else {
        return 0;
      }
    },
  }
};
</script>
