<template>
	<b-card title="Index giocate utili">
		<b-table-simple small striped bordered style="font-size:0.9rem">
			<thead>
				<tr>
					<th colspan="2"></th>
					<th>Minutes</th>
					<th>BLP Ground 1-2-3</th>
					<th>1V1 riusciti</th>
					<th>Shots from individual play</th>
					<th>Prog Carry</th>
					<th>Filtrante</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="player, index in attaccanti">
					<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="attaccanti.length">Attaccante</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.blpGround123 }}</td>
					<td>{{ player.dribblingSucc }}</td>
					<td>{{ player.shotsFromIndividualPlay }}</td>
					<td>{{ player.progCarry }}</td>
					<td>{{ player.filtrante }}</td>
				</tr>
				<tr v-for="player, index in centrocampisti">
					<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="centrocampisti.length">Centrocampista</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.blpGround123 }}</td>
					<td>{{ player.dribblingSucc }}</td>
					<td>{{ player.shotsFromIndividualPlay }}</td>
					<td>{{ player.progCarry }}</td>
					<td>{{ player.filtrante }}</td>
				</tr>
				<tr v-for="player, index in difensori">
					<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="difensori.length">Difensore</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.blpGround123 }}</td>
					<td>{{ player.dribblingSucc }}</td>
					<td>{{ player.shotsFromIndividualPlay }}</td>
					<td>{{ player.progCarry }}</td>
					<td>{{ player.filtrante }}</td>
				</tr>
				<tr v-for="player, index in unknownRole">
					<td v-if="index == 0" style="vertical-align: middle;" class="text-center" :rowspan="unknownRole.length">Ruolo sconosciuto</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.blpGround123 }}</td>
					<td>{{ player.dribblingSucc }}</td>
					<td>{{ player.shotsFromIndividualPlay }}</td>
					<td>{{ player.progCarry }}</td>
					<td>{{ player.filtrante }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats'],
	data: function() {
		return {

		}
	},
	computed: {
		players: function() {
			return this.stats && this.stats.players ? this.stats.players : [];
		},
		difensori: function() {
			return this.players.filter(item => item.genericPosition == 'Difensore');
		},
		centrocampisti: function() {
			return this.players.filter(item => item.genericPosition == 'Centrocampista');
		},
		attaccanti: function() {
			return this.players.filter(item => item.genericPosition == 'Attaccante');
		},
		unknownRole: function() {
			return this.players.filter(item => item.genericPosition == '');
		}
	},
}
</script>
