<template>
	<span>

		<div @click.prevent="open = !open" v-if="open" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: transparent; z-index: 1000"></div>

		<b-button ref="obj" variant="outline-primary" size="sm" block @click.prevent="open = !open">
			<span v-if="options" v-html="message"></span>
		</b-button>
		<div ref="panel" v-if="options && open" style="margin-top: 3px; position: absolute; border: 1px solid rgba(0,0,0,.1); box-shadow: 3px 3px 3px rgba(0,0,0,.1); padding: 1em; z-index: 1001; background-color: white; width: 80vw; min-width: 400px; max-width: 1000px">

			<b-row>
				<b-col v-for="s,k in squadra" style="min-width: 200px">
					<div class="mb-1">
						<strong v-if="k != '_'">{{k}}</strong>
						<a class="float-right small text-muted" style="margin-right: 1em" href="" @click.prevent="toggle(s)"><em>Sel. Tutti</em></a>
					</div>
					<div v-for="option in s">
						<label style="line-height: 0">
							<input type="checkbox" v-model="selected" :value="option[value]" />
							{{ option.cognome }} {{ option.nome[0] }}.
						</label>
					</div>
				</b-col>
			</b-row>

			<div class="d-flex justify-content-between mt-4">
				<b-button-group>
					<b-button variant="success" size="sm" @click.prevent="submit">Applica</b-button>
					<b-button variant="outline-secondary" size="sm" @click.prevent="discard">&times;</b-button>
				</b-button-group>
				<b-button-group>
					<b-button variant="outline-secondary" @click.prevent="selected = []" size="xs">DESeleziona Tutti</b-button>
					<b-button variant="outline-secondary" @click.prevent="selected = _.map(options, value)" size="xs">SELeziona Tutti</b-button>
				</b-button-group>
			</div>

		</div>
	</span>
</template>

<script>

var portiere = ["Portiere"]

var difensore = [
	"Difensore Centrale",
	"Difensore Centrale Destro",
	"Difensore Centrale Sinistro",
	"Difensore Interno Destro",
	"Difensore Interno Sinistro",
	"Terzino Destro",
	"Terzino Sinistro"
];

var centrocampista = [
	"Esterno Basso Destro",
	"Esterno Basso Sinistro",
	"Centrocampista Metodista",
	"Centrocampista Interno Destro",
	"Centrocampista Interno Sinistro",
	"Centrocampista Centrale",
	"Centrocampista Centrale Destro",
	"Centrocampista Centrale Sinistro",
	"Laterale Destro",
	"Laterale Sinistro",
	"Trequartista Centrale",
	"Trequartista Destro",
	"Trequartista Sinistro"
];

var attaccante = [
	"Attaccante Destro",
	"Attaccante Sinistro",
	"Attaccante Centrale",
	"Punta Centrale"
]

import pageBase from '@/mixins/pageBase.js'
import moment from 'moment'
import PlayerPicker from '@/components/layout/Football/PlayerPicker.vue';

export default {
	mixins: [ pageBase ],
	props: [ 'options', 'value', 'base', 'max' ],
	data: function() {
		return {
			selected: [],
			open: false,
			bk: null
		}
	},
	computed: {
		hasCarriere: function() {
			return _.map(this.options, 'last_carriera_sportiva').length > 0;
		},
		message: function() {

			if(this.selected.length == this.options.length) return 'Tutta la Squadra';
			if(this.selected.length == 0) return 'Seleziona Giocatori';
			var team_flatten =_.flatten(_.values(this.squadra));

			if(this.selected.length < (this.max || 3)) {
				var message = [];
				_.each(this.selected, function(a) {

					var ob = {};
					ob[this.value] = a;

					var player = _.find(team_flatten, ob);

					if( !! player ) message.push(player.cognome + ' ' + player.nome[0] + '.');

				}.bind(this));

				return message.join(', ');
			}

			var counters = {
				Portiere: 0,
				AllPortiere: false,
				Difensore: 0,
				AllDifensore: false,
				Centrocampista: 0,
				AllCentrocampista: false,
				Attaccante: 0,
				AllAttaccante: false
			};

			_.each(this.selected, function(a) {

				var ob = {};
				ob[this.value] = a;

				var player = _.find(team_flatten, ob);

				if(player)
					counters[player.filtering]++;

			}.bind(this));

			var team_selected = _.filter(team_flatten, function(a) { return this.selected.indexOf(a.id) !== -1; }.bind(this));

			counters.AllPortiere = _.filter(team_flatten, { filtering: 'Portiere' }).length == counters.Portiere;
			counters.AllDifensore = _.filter(team_flatten, { filtering: 'Difensore' }).length == counters.Difensore;
			counters.AllCentrocampista = _.filter(team_flatten, { filtering: 'Centrocampista' }).length == counters.Centrocampista;
			counters.AllAttaccante = _.filter(team_flatten, { filtering: 'Attaccante' }).length == counters.Attaccante;

			var sparePlayers = [];
			var message = [];
			if(counters.Portiere > 1)
				message.push( counters.AllPortiere ? 'Portieri' : '<strong>' + counters.Portiere + '</strong>x Portieri' );
			else if(counters.Portiere == 1)
				sparePlayers = _.concat(sparePlayers, _.filter(team_selected, { filtering: 'Portiere' }));

			if(counters.Difensore > 1)
				message.push( counters.AllDifensore ? 'Difensori' : '<strong>' + counters.Difensore + '</strong>x Difensori' );
			else if(counters.Difensore == 1)
				sparePlayers = _.concat(sparePlayers, _.filter(team_selected, { filtering: 'Difensore' }));

			if(counters.Centrocampista > 1)
				message.push( counters.AllCentrocampista ? 'Centrocampisti' : '<strong>' + counters.Centrocampista + '</strong>x Centrocampisti' );
			else if(counters.Centrocampista == 1)
				sparePlayers = _.concat(sparePlayers, _.filter(team_selected, { filtering: 'Centrocampista' }));

			if(counters.Attaccante > 1)
				message.push( counters.AllAttaccante ? 'Attaccanti' : '<strong>' + counters.Attaccante + '</strong>x Attaccanti' );
			else if(counters.Attaccante == 1)
				sparePlayers = _.concat(sparePlayers, _.filter(team_selected, { filtering: 'Attaccante' }));

			if(sparePlayers.length > 0) {
				_.each(sparePlayers, function(a) {
					message.push(a.cognome + ' ' + a.nome)
				});
			}

			return message.join(', ');

		},
		squadra: function() {

			// check if last_carriera_sportiva
			var carriere = _.map(this.options, 'last_carriera_sportiva');

			if(carriere && carriere.length == this.options.length) {

				var squadra = _.map(this.options, function(giocatore) {
					//console.log('@@', giocatore, giocatore.last_carriera_sportiva);
					if(!giocatore.last_carriera_sportiva || !giocatore.last_carriera_sportiva.ruolo_ideale || giocatore.last_carriera_sportiva.ruolo_ideale.length == 0) {
						giocatore.filtering = 'Sconosciuto';
					} else {

						if(portiere.indexOf(giocatore.last_carriera_sportiva.ruolo_ideale[0].nome) != -1) {
							giocatore.filtering = 'Portiere';
						} else if(difensore.indexOf(giocatore.last_carriera_sportiva.ruolo_ideale[0].nome) != -1) {
							giocatore.filtering = 'Difensore';
						} else if(centrocampista.indexOf(giocatore.last_carriera_sportiva.ruolo_ideale[0].nome) != -1) {
							giocatore.filtering = 'Centrocampista';
						} else if(attaccante.indexOf(giocatore.last_carriera_sportiva.ruolo_ideale[0].nome) != -1) {
							giocatore.filtering = 'Attaccante';
						}

					}

					return giocatore;

				});

				squadra = _.orderBy(squadra, function(a) {
					if(a.filtering == 'Portiere') return 1;
					if(a.filtering == 'Difensore') return 2;
					if(a.filtering == 'Centrocampista') return 3;
					if(a.filtering == 'Attaccante') return 4;
				});

				return _.groupBy(squadra, 'filtering');

			} else {
				return { '_': this.options };
			}

		}
	},
	methods: {
		toggle: function(what) {

			var ids = _.map(what, this.value);

			var has = false;
			_.each(ids, function(a) {
				if(this.selected.indexOf(a) !== -1)
					has = true;
			}.bind(this));

			if(!has) {
				_.each(ids, function(a) {
					if(this.selected.indexOf(a) === -1)
						this.selected.push(a);
				}.bind(this));
			} else {
				_.each(ids, function(a) {
					var p = this.selected.indexOf(a);
					if(p !== -1)
						this.selected.splice(p, 1);
				}.bind(this));
			}

			this.$emit('change', this.selected);

		},
		submit: function() {
			this.$emit('change', this.selected);
			this.$emit('submit');
			this.open = false;
		},
		discard: function() {
			this.$emit('change', this.bk);
			this.open = false;
		},
		reset: function() {
			this.selected = _.cloneDeep(this.base);
		}
	},
	watch: {
		open: function(a) {
			if(this.open == true) {
				this.bk = _.cloneDeep(this.base);
				this.$nextTick(function() {
					var offset = $(this.$refs.panel).offset();
					var width = $(this.$refs.panel).width();
					var buttonSize = $(this.$refs.obj).width();
					if(offset.left + width > $(window).width()) {

						if(offset.left - (width-buttonSize) < 7) {

							$(this.$refs.panel).css({
								left: (offset.left-7) * -1
							});

							console.log('!! - there\'s not enought space on the left.. well have to resize. - !!');
						} else {

							$(this.$refs.panel).css({
								left: (width-buttonSize)*-1
							});

						}

					}
					//console.log(offset, width);
				});
			} else {
				this.bk = null;
			}
		},
		selected: function(a, b) {
			if(JSON.stringify(a) != JSON.stringify(b))
				this.$emit('change', this.selected);
		},
		base: {
			immediate: true,
			handler: function() {
				this.reset()
			}
		}
	}
}

</script>