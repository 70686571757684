<template id="partite">
	<section class="partite">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Partite ({{ total }})</span>
					</div>
					<div class="mb-1">
						<b-btn v-b-toggle.collapse variant="primary">Filtra <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
						<b-collapse id="collapse" class="mt-2">
							<b-row class="mb-1">
								<b-col sm="2">
									<b-form-group label-cols="3" content-cols="9" label="Data">
										<b-form-input type="date" size="sm" v-model="filters.data"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="2">
									<b-form-checkbox v-model="filters.video"> Con Video</b-form-checkbox>
								</b-col>
							</b-row>
						</b-collapse>
					</div>
				</div></div>
				<v-infinite-scroll :loading="loading" @bottom="scrollPartite" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
					<div v-for="partita in partite" class="col-3 mb-1">
						<div class="card h-100">
							<div class="card-body">
								<div>
									<span class="mr-2 color-rosso"><h5>{{ partita.competizione.paese.paese }} - {{ partita.competizione.nome }}</h5></span>
								</div>

								<div class="cardpartita">
									<router-link :to="createPartitaRoute(partita)" :href="'#/newscout/partita/' + partita.id" style="text-decoration:none">
										<div class="versus">
											<div class="row flex-items-xs-middle flex-items-xs-center text-sm-center">
												<div class="col-5" v-if="getTeamHome(partita)">

													<div><b-img :src="getTeamHome(partita).logo_url" fluid :alt="getTeamHome(partita).officialName" :width="80"></b-img></div>
													<div>
														<span>{{ getTeamHome(partita).officialName }}</span>
													</div>

												</div>
												<div class="col-5" v-else>	
													 Home
												</div>
												<div class="col-2">

													<div class="versus__vs">
														<span>VS.</span>
													</div>
													<div class="text-center mt-4">
														<span class="color-rosso pl-1">{{ partita.score }}</span>
													</div>
												</div>
												<div class="col-5" v-if="getTeamAway(partita)">

													<div><b-img :src="getTeamAway(partita).logo_url" fluid :alt="getTeamAway(partita).officialName" :width="80"></b-img></div>
													<div>
														<span>{{ getTeamAway(partita).officialName }}</span>
													</div>

												</div>
												<div class="col-5" v-else>	
													Away
												</div>
											</div>
										</div>
									</router-link>
									<div class="row">
										<div class="col-12 text-sm-center">
											{{ partita.data | formatDateHour }}
											<div v-if="partita.video_rel.length > 0"><em class="fa fa-video-camera"></em></div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				</v-infinite-scroll>	
			</div>

			<footerapp></footerapp>

		</section>
	</template>

	<script>

	import moment from 'moment'

	import * as types from '@/store/types'

	import InfiniteScroll from 'v-infinite-scroll'

	var Auth = require('@/auth.js');

	export default {

		data: function() {
			return {
				partite: [],
				filters: {
					data: '',
					video: false
				},
				page: 1,
				total: 0,
			}
		},
		components: {
			headapp:             require('@/components/layout/headapp.vue').default,
			menusub:             require('@/components/layout/menusub.vue').default,
			footerapp:           require('@/components/layout/footerapp.vue').default,
			flag:                require('@/components/layout/flag.vue').default,
			'v-infinite-scroll': InfiniteScroll,
		},

		created: function () {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/squadra/' + this.$route.params.id ).then(function(response) {
				this.partite = response.data.data; 
				this.total = response.data.total;
				this.loading = false;
			});
		},
		methods: {
			createPartitaRoute: function(partita) {


				if(!partita.wyscout_id) {
					return {
						name: 'football_squadre_partita',
						params: {
							partite_id: partita.id
						}
					}
				} else {
					return {
						name: 'football_squadre_partita_wy',
						params: {
							partite_id: partita.id,
							wy_id: partita.wyscout_id
						}
					}
				}

			},
			scrollPartite() {
				if(this.partite.length < this.total) {
					this.page++;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/squadra/' + this.$route.params.id + '?page=' + this.page, { params: this.filters }).then(function(response) {
						this.partite = this.partite.concat(response.data.data);
						this.loading = false;
					});
				}
			},
			filtraPartite() {
				this.partite = [];
				this.total = 0;
				this.loading = true;
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/squadra/' + this.$route.params.id, { params: this.filters }).then(function(response) {
					this.partite = response.data.data;
					this.total = response.data.total;
					this.loading = false; 
				});
			},

			getTeamHome(partita) {
				var squadre = partita.squadre;
				if(squadre && squadre.length > 1) {
					if(squadre[0].pivot.side == 'home') {
						return squadre[0];
					} else {
						return squadre[1];
					}
				}
				return null;
			},

			getTeamAway(partita) {
				var squadre = partita.squadre;
				if(squadre && squadre.length > 1) {
					if(squadre[0].pivot.side == 'home') {
						return squadre[1];
					} else {
						return squadre[0];
					}
				}
				return null;
			}
		},
		watch: {
			'filters': {
				deep: true,
				handler: function () {
					this.filtraPartite();
				}
			}
		},
		filters: {
			formatDateHour: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('DD/MM/YYYY HH:mm'); 
				}
				return "";
			},

		},
		computed: {
			auth: function() {
				return Auth;
			},
			area: function() {
				return this.$route.meta.area;
			}
		},
	}
	</script>