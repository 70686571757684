<template id="cardosservatore">
    <div class="cardosservatore">

        <div class="card" :style="!osservatore.attivo ? 'background: rgba(0, 0, 0, 0.075)' : ''">
            <div class="row no-gutters text-center" style="height:2em">  
                <span style="position:absolute;right:1rem;top:1rem;" title="Osservatore capo" v-if="isOsservatoreCapo"><i class="fa fa-user-secret" aria-hidden="true"></i>
                </span>      
            </div>
            <div class="text-center">
                <div class="resize-image-card">
                    <router-link :to="'/' + (newscout ? 'newscout' : 'scouting') + '/osservatore/paesi/' + osservatore.id" target="_blank" v-if="academy == 0">
                        <b-img :src="osservatore.avatar_url" fluid :alt="osservatore.nomeCompleto" :width="130"/>
                    </router-link>
                    <router-link :to="'/academy/scouting/osservatore/paesi/' + osservatore.id" target="_blank" v-if="academy == 1">
                        <b-img :src="osservatore.avatar_url" fluid :alt="osservatore.nomeCompleto" :width="130"/>
                    </router-link>
                    <router-link :to="'/usascout/osservatore/paesi/' + osservatore.id" target="_blank" v-if="academy == 2">
                        <b-img :src="osservatore.avatar_url" fluid :alt="osservatore.nomeCompleto" :width="130"/>
                    </router-link>
                    <router-link :to="'/youthscout/osservatore/paesi/' + osservatore.id" target="_blank" v-if="academy == 3">    
                        <b-img :src="osservatore.avatar_url" fluid :alt="osservatore.nomeCompleto" :width="130"/>
                    </router-link>
                    <router-link :to="'/youngscout/osservatore/paesi/' + osservatore.id" target="_blank" v-if="academy == 4">    
                        <b-img :src="osservatore.avatar_url" fluid :alt="osservatore.nomeCompleto" :width="130"/>
                    </router-link>
                </div>
            </div>
            <div class="card-body">
                <b-row>
                    <b-col class="small text-center">
                        <strong>{{ osservatore.nomeCompleto }}</strong>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="font-size-xs text-center" title="username">
                        {{ osservatore.username }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="font-size-xs color-rosso text-center" title="email">
                        {{ osservatore.email }}
                    </b-col>
                </b-row>
                <b-row v-if="academy == 1 || academy == 2 || academy == 3 || academy == 4" class="mb-1">
                    <b-col class="font-size-xs text-center">
                        Giocatori osservati: {{ osservatore.osservati_count }}
                    </b-col>
                </b-row>

                <div class="row no-gutters text-center font-size-sm" v-if="academy == 0">
                    <div class="col-3" title="Giocatori osservati"><i class="fa fa-futbol-o" aria-hidden="true"></i><br><span style="font-size:90%">{{ osservatore.osservati_count }}</span></div>
                    <div class="col-3" title="Videotags"><i class="fa fa-youtube-play" aria-hidden="true"></i><br><span style="font-size:90%">{{ osservatore.tags_count }}</span></div>
                    <div class="col-3" title="Report partite"><i class="fa fa-file-pdf-o" aria-hidden="true"></i><br>{{ osservatore.reports_count }}</div>
                    <div class="col-3" title="Missioni"><i class="fa fa-calendar" aria-hidden="true"></i><br>{{ osservatore.missioni_count }}</div>
                </div>
                <b-row v-if="academy == 0">
                    <b-col>
                        <b-progress :max="10" v-if="!newscout && osservatore.ultima_valutazione.length > 0" :variant="'grad-' + (osservatore.ultima_valutazione[0].id_gradimento ? osservatore.ultima_valutazione[0].id_gradimento : '')" title="Ultima valutazione">
                            <b-progress-bar :value="10">
                                <a :href="'#/scouting/view/valutazione/' + osservatore.ultima_valutazione[0].osservato.id + '/' + osservatore.ultima_valutazione[0].id" target="_blank" style="color:#000000">
                                    {{ osservatore.ultima_valutazione[0].osservato.cognome }} {{ osservatore.ultima_valutazione[0].created_at | formatDate }}
                                </a>
                            </b-progress-bar>
                        </b-progress>
                        <b-progress :max="10" v-if="newscout && osservatore.ultima_valutazione_new_scout.length > 0" :variant="'grad-' + (osservatore.ultima_valutazione_new_scout[0].id_gradimento ? osservatore.ultima_valutazione_new_scout[0].id_gradimento : '')" title="Ultima valutazione">
                            <b-progress-bar :value="10">
                                <a :href="'#/newscout/view/valutazione/' + osservatore.ultima_valutazione_new_scout[0].osservato.id + '/' + osservatore.ultima_valutazione_new_scout[0].id" target="_blank" style="color:#000000">
                                    {{ osservatore.ultima_valutazione_new_scout[0].osservato.cognome }} {{ osservatore.ultima_valutazione_new_scout[0].created_at | formatDate }}
                                </a>
                            </b-progress-bar>
                        </b-progress>
                        <b-progress v-if="!newscout && osservatore.ultima_valutazione.length == 0"></b-progress>
                        <b-progress v-if="newscout && osservatore.ultima_valutazione_new_scout.length == 0"></b-progress>
                        <div class="row no-gutters text-center font-size-sm" v-if="auth.isUserEnable('scout_km')">
                            <div class="col-12" title="Km percorsi"><i class="fa fa-car" aria-hidden="true"></i> {{ osservatore.km }} km</div>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="academy == 1">
                    <b-col>
                        <b-progress :max="10" v-if="osservatore.ultima_valutazione_academy.length > 0" :variant="'grad-' + (osservatore.ultima_valutazione_academy[0].id_gradimento ? osservatore.ultima_valutazione_academy[0].id_gradimento : '')" title="Ultima valutazione">
                            <b-progress-bar :value="10">
                                <a :href="'#/academy/scouting/view/valutazione/' + osservatore.ultima_valutazione_academy[0].osservato.id + '/' + osservatore.ultima_valutazione_academy[0].id" target="_blank" style="color:#000000">
                                    {{ osservatore.ultima_valutazione_academy[0].osservato.cognome }} {{ osservatore.ultima_valutazione_academy[0].created_at | formatDate }}
                                </a>
                            </b-progress-bar>
                        </b-progress>
                        <b-progress v-if="osservatore.ultima_valutazione_academy.length == 0"></b-progress>
                    </b-col>
                </b-row>
                <b-row v-if="academy == 2">
                    <b-col>
                        <b-progress :max="10" v-if="osservatore.ultima_valutazione_usa_scout.length > 0" :variant="'grad-' + (osservatore.ultima_valutazione_usa_scout[0].id_gradimento ? osservatore.ultima_valutazione_usa_scout[0].id_gradimento : '')" title="Ultima valutazione">
                            <b-progress-bar :value="10">
                                <a :href="'#/usascout/view/valutazione/' + osservatore.ultima_valutazione_usa_scout[0].osservato.id + '/' + osservatore.ultima_valutazione_usa_scout[0].id" target="_blank" style="color:#000000">
                                    {{ osservatore.ultima_valutazione_usa_scout[0].osservato.cognome }} {{ osservatore.ultima_valutazione_usa_scout[0].created_at | formatDate }}
                                </a>
                            </b-progress-bar>
                        </b-progress>
                        <b-progress v-if="osservatore.ultima_valutazione_usa_scout.length == 0"></b-progress>
                    </b-col>
                </b-row>
                <b-row v-if="academy == 3">
                    <b-col>
                        <b-progress :max="10" v-if="osservatore.ultima_valutazione_youth.length > 0" :variant="'grad-' + (osservatore.ultima_valutazione_youth[0].id_gradimento ? osservatore.ultima_valutazione_youth[0].id_gradimento : '')" title="Ultima valutazione">
                            <b-progress-bar :value="10">
                                <a :href="'#/youngscout/view/valutazione/' + osservatore.ultima_valutazione_youth[0].osservato.id + '/' + osservatore.ultima_valutazione_youth[0].id" target="_blank" style="color:#000000">
                                    {{ osservatore.ultima_valutazione_youth[0].osservato.cognome }} {{ osservatore.ultima_valutazione_youth[0].created_at | formatDate }}
                                </a>
                            </b-progress-bar>
                        </b-progress>
                        <b-progress v-if="osservatore.ultima_valutazione_youth.length == 0"></b-progress>
                    </b-col>
                </b-row>
                <b-row v-if="academy == 4">
                    <b-col>
                        <b-progress :max="10" v-if="osservatore.ultima_valutazione_young.length > 0" :variant="'grad-' + (osservatore.ultima_valutazione_young[0].id_gradimento ? osservatore.ultima_valutazione_young[0].id_gradimento : '')" title="Ultima valutazione">
                            <b-progress-bar :value="10">
                                <a :href="'#/youngscout/view/valutazione/' + osservatore.ultima_valutazione_young[0].osservato.id + '/' + osservatore.ultima_valutazione_young[0].id" target="_blank" style="color:#000000">
                                    {{ osservatore.ultima_valutazione_young[0].osservato.cognome }} {{ osservatore.ultima_valutazione_young[0].created_at | formatDate }}
                                </a>
                            </b-progress-bar>
                        </b-progress>
                        <b-progress v-if="osservatore.ultima_valutazione_young.length == 0"></b-progress>
                    </b-col>
                </b-row>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'

var Auth = require('../../auth.js');

export default {
    props:{
        osservatore: {
            type: Object,
            required: true
        },
        academy: {
            type: Number,
            default: 0,
        },
        newscout: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
        }
    },
    filters: {
        formatDate: function (date) {
            var m = moment(date);
            if(m.isValid()) {
                return m.format('DD/MM/YY'); 
            }
            return "";
        },
    },
    computed: {
        isOsservatoreCapo:  function() {
            if(this.academy == 0) {
                if (this.osservatore.gruppi.filter(e => e.id === 3).length > 0) {
                    return true;
                } 
            } else if(this.academy == 1) {
                if (this.osservatore.gruppi.filter(e => e.id === 15).length > 0) {
                    return true;
                }
            } else if(this.academy == 2) {
                if (this.osservatore.gruppi.filter(e => e.id === 21).length > 0) {
                    return true;
                }
            } else if(this.academy == 3) {
                if (this.osservatore.gruppi.filter(e => e.id === 25).length > 0) {
                    return true;
                }
            } else if(this.academy == 4) {
                if (this.osservatore.gruppi.filter(e => e.id === 27).length > 0) {
                    return true;
                }
            }
            return false;
        },
        auth: function() {
            return Auth;
        },
    },
    methods: {
    }
}
</script>
