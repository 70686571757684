<template id="gruppi">
  <section class="gruppi">
    <headapp></headapp>
    <div class="container-fluid m-y-1">
      <menusub></menusub>
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <span>Notifiche Utenti</span>
          </div>
          <div class="content">
            <admin-table
            api-url="/admin/notifiche/utenti"
            :columns="columns"
            :createFormData="newData"
            :editFormData="editData"
            :canDelete="true"
            :defaultFilter="false"
            @resetCreateFormData="resetCreateFormData"
            @editError="showError"
            @createError="showError"
            @showModal="onShowModal">
            <template slot="filters">
              <b-row>
                <b-col cols="3">
                  <b-form-input v-model="filters.cognome" placeholder="Cognome" />
                </b-col>
                <b-col cols="3">
                <multiselect 
                v-model="filters.notifiche_tipi"
                :multiple="true"
                :options="notifiche_tipi_options"
                placeholder="Filtra per tipi notifiche"
                label="nome"
                track-by="id"/>
            </b-col>
              </b-row>
            </template>
            <template slot="create-modal-body" slot-scope="row">
              <b-form-group label="Utente">
                <multiselect
                v-model="newData.utente"
                :multiple="false"
                :options="utenti_options"
                placeholder="Seleziona utente"
                label="nomeCompleto"
                track-by="id"/>
              </b-form-group>
              <b-form-group label="Tipi di notifiche">
                <b-row v-for="item in newData.notificheUtenti" class="mb-2" align-v="center">
                  <b-col cols="6">
                    <multiselect
                    v-model="item.notificaTipo"
                    :multiple="false"
                    :options="notifiche_tipi_options"
                    placeholder="Seleziona tipo notifica"
                    label="nome"
                    track-by="id"/>
                  </b-col>
                  <b-col>
                    <b-form-checkbox-group v-model="item.canali" v-if="item.notificaTipo">
                      <b-form-checkbox v-for="canale in JSON.parse(item.notificaTipo.canali)" :value="canale">{{ canale }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>
                  <b-col cols="1">
                   <b-button size="sm" @click="removeNotificaTipo(item, 'new')" title="Rimuovi"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
                 </b-col>
               </b-row>
               <b-button size="sm" title="Aggiungi" @click="addNotificaTipo('new')"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
             </b-form-group>
           </template>
           <template slot="edit-modal-body" slot-scope="row">
            <b-form-group label="Utente">
             <strong> {{ editData.utente ? editData.utente.nomeCompleto : '' }} </strong>
           </b-form-group>
           <b-form-group label="Tipi di notifiche">
            <b-row v-for="item in editData.notificheUtenti" class="mb-2" align-v="center">
              <b-col cols="6">
                <multiselect
                v-model="item.notificaTipo"
                :multiple="false"
                :options="notifiche_tipi_options"
                placeholder="Seleziona tipo notifica"
                label="nome"
                track-by="id"/>
              </b-col>
              <b-col>
                <b-form-checkbox-group v-model="item.canali" v-if="item.notificaTipo">
                  <b-form-checkbox v-for="canale in JSON.parse(item.notificaTipo.canali)" :value="canale">{{ canale }}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
              <b-col cols="1">
               <b-button size="sm" @click="removeNotificaTipo(item, 'edit')" title="Rimuovi"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
             </b-col>
           </b-row>
           <b-button size="sm" title="Aggiungi" @click="addNotificaTipo('edit')"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
         </b-form-group>
       </template>
     </admin-table>
   </div>
 </div>
</div>
</div>
<footerapp></footerapp>
</section>
</template>

<script>
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    menusub: require('../../layout/menusub.vue').default,
    footerapp: require('../../layout/footerapp.vue').default, 
  },

  data() {
    return {
      columns: [
      { key: 'id', label: 'ID' },
      { key: 'utente', label: 'Utente' },
      { key: 'notificheUtenti', label: 'Tipi notifiche'},
      { key: 'actions', label: 'Azioni' },
      ],
      newData: {
        utente: null,
        notificheUtenti: [],
      },
      editData: {
        utente: null,
        notificheUtenti: [],
      },
      utenti_options: [],
      notifiche_tipi_options: [],
      filters: {
        cognome: '',
        notifiche_tipi: [],    
      },
    };
  },
  methods: {
    onShowModal(row) {
      this.editData.utente = row.item;
      var array = []; 
      var array_ids = [];  
      for(var i in row.item.notifiche_utenti) {
        var notificaUtente = row.item.notifiche_utenti[i];
        if(!array_ids.includes(notificaUtente.notifica_tipo_id)) {
          array_ids.push(notificaUtente.notifica_tipo_id);
          var item = {
            notificaTipo: notificaUtente.notifica_tipo,
            canali: [notificaUtente.canale],
          }
          array.push(item);
        } else {
          var index = array_ids.indexOf(notificaUtente.notifica_tipo_id);
          var item = array[index].canali.push(notificaUtente.canale);
        }
      }
      this.editData.notificheUtenti = array;
    },
    addNotificaTipo: function (mode) {
      if(mode == 'edit') {
        this.editData.notificheUtenti.push({notificaTipo: null, canali: []});
      } else {
        this.newData.notificheUtenti.push({notificaTipo: null, canali: []});

      }
    },
    removeNotificaTipo: function (item, mode) {
      if(mode == 'edit') {
        var index = this.editData.notificheUtenti.indexOf(item);
        this.editData.notificheUtenti.splice(index, 1);
      } else {
        var index = this.newData.notificheUtenti.indexOf(item);
        this.newData.notificheUtenti.splice(index, 1);
      }
    },
    resetCreateFormData() {
      this.newData.utente = null;
      this.newData.notificheUtenti = [];
    },
    showError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(types.ALERTS_PUSH, { msg: err.response.data.error, status: 0 });
      }
    },
  },
  created: function() {
    axios.get('/admin/utenti/football').then((res) => {
      this.utenti_options = res.data;
    });
    axios.get('/admin/notifiche/tipi').then((res) => {
      this.notifiche_tipi_options = res.data;
    });
  },
  watch: {
    filters: {
      handler() {
        this.$root.$emit('admin-table::filter', this.filters);
      },      
      deep: true,
    },
  }
};
</script>

<style lang="scss">

.pac-container {
  z-index: 1051 !important;
}
</style>
