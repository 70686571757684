<template>

	<div>

		<headapp></headapp>
		<menusub></menusub>
		

		<div class="app-space" :style="'height: '+ux.height+'px'" ref="layout">
			
			<div style="background-color: white; height: 100%" class="px-2"><!-- 🧙‍♀️ -->
				<b-overlay :show="ready" ref="waitLayer">
					<b-row align-h="center">
						<b-col :cols="9" :style="'overflow-y: scroll; height: '+ux.height+'px'">
							<div v-if="$route.meta.contesto == 'partita-partite'">
								<ContenitorePartitaScoutingManual :hideValutazioni="true" ref="interfacciaPartita" @ready="isReady" @wait="isWaiting" :partiteId="$route.params.partite_id"></ContenitorePartitaScoutingManual>
							</div>
							<div v-else-if="$route.meta.contesto == 'partita-partite-wyscout'">
								<ContenitorePartitaScoutingWyDatabase :hideValutazioni="true" ref="interfacciaPartita" @ready="isReady" @wait="isWaiting":partiteId="$route.params.partite_id" :wyId="$route.params.wy_id"></ContenitorePartitaScoutingWyDatabase>
							</div>
						</b-col>
					</b-row>
				</b-overlay>
			</div>

		</div>

		<footerapp></footerapp>

	</div>

</template>

<script>

import $ from 'jquery'
import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	components: {
		RosaGiocatori: require('@/components/layout/nextscouting/rosa-giocatori.vue').default,
		ContenitorePartitaScoutingManual: require('@/components/layout/nextscouting/contenitore-partita-manual.vue').default,
		ContenitorePartitaScoutingWyDatabase: require('@/components/layout/nextscouting/contenitore-partita-wy-database.vue').default,
		ContenitorePartitaScoutingWy: require('@/components/layout/nextscouting/contenitore-partita-wy.vue').default,
	},
	data: function() {
		return {
			wyScoutWait: false,
			competizione: null,
			seasonId: null, // wy scout
			squadra: null,
			players: null,
			partite: null,
			partiteWy: null,
			thePartite: [],
			ready: false,
			stagioni: null,
			ux: {
				height: null
			},

			form_partita: {
				id: null,
				id_competizione: null,
				id_squadra: null,
				id_avversario: null,
				home: 1,
				data: null,
				ora: null,
				score: null,
			},
			logo_avversario: null,
			avversario: null,

			page: 1,
			categories_opt: [],
			teams: {
				data: [],
			},
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
		}
	},
	mounted: function() {

		this._.delay(function() {
			this.uiDesign();
		}.bind(this), 400);

		$(window).on('resize', function() {
			this.uiDesign();
		}.bind(this));

	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		partiteIn: function() {
			return _.filter(this.partiteWy, function(a) { return a.partiteId != null });
		},
		partiteWs: function() {
			return _.filter(this.partiteWy, function(a) { return a.partiteId == null });
		},
	},
	watch: {
		'$route.params': {
			deep: true,
			handler: function() {

				if(!this.$refs.interfacciaPartita) return false; // Tanto vuol dire che è la prima.. non serve resettare ^_^
				this.$refs.interfacciaPartita.reset();

			}
		},
		// 'competizione': function() { this.mergeMatches(); },
		// 'partite': function() { this.mergeMatches(); },
		// 'partiteWs': function() { this.mergeMatches(); },
		'$route.params.id': {
			immediate: true,
			handler: function() {
				this.getSquadra();
				// this.getPartite();
				this.getCompetizione(this.$route.params.competizione_id);
				this.getStagioni(this.$route.params.competizione_id);
			}
		},
		squadra: {
			handler: function() {
				//return false;
				if(this.squadra && this.squadra.wyscout && !this.seasonId) {
					// this.getPlayers(this.squadra.wyscout.chiave);
					// this.getWsMatches(this.squadra.wyscout.chiave, null);
				}
			}
		},
		seasonId: {
			handler: function() {
				if(this.seasonId == -1) return false;
				if(this.squadra && this.squadra.wyscout) {
					// this.getPlayers(this.squadra.wyscout.chiave);
					// this.getWsMatches(this.squadra.wyscout.chiave, this.seasonId);
				}	
			}
		}
	},
	methods: {
		uiDesign: function() {

			var space = $(window).height() - $('header').outerHeight() - $('footer').outerHeight();

			this.ux.height = space;

			//console.log($(this.$el).height(), space);

		},
		isReady: function() { this.ready = false; },
		isWaiting: function() { this.ready = true; },
		
		getStagioni: function(id) {

			this.$http.get(this.$store.state.apiEndPoint + '/competizioni/' + id + '/stagioni').then(function(data) {
				this.stagioni = data.data;
			})

		},
		getCompetizione(id) {
			this.competizione = null;
			this.$http.get(this.$store.state.apiEndPoint + '/club/competizione/' + id).then(function(data) {
				this.competizione = data.data;
			});
		},
		// getPartite: function() {
		// 	this.partite = [];
		// 	this.$http.get(this.$store.state.apiEndPoint + '/nazionale/partite/inserite/' +  this.$route.params.id + '/' + this.area).then(function(data) {
		// 		this.partite = data.data;
		// 	});
		// },
		// getWsMatches: function(id, idSeason) {
		// 	this.wyScoutWait = true;
		// 	this.partiteWy = [];
		// 	this.$http.get(this.$store.state.apiEndPoint + '/nazionale/partite/' +  id + '/' + this.area, {
		// 		params: {
		// 			id_stagione: idSeason != -1 ? idSeason : null,
		// 			id_competizione: idSeason != -1 ? this.$route.params.competizione_id : null
		// 		}
		// 	}).then(function(data) {
		// 		this.wyScoutWait = false;
		// 		this.partiteWy = data.data;
		// 		if(this.$route.params.competizione_id == undefined)
		// 			this.seasonId = -1;
		// 		else if(this.partiteWy.length && idSeason != -1) {
		// 			this.seasonId = _.first(this.partiteWy).seasonId;
		// 		}
		// 	});
		// },
		getSquadra: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.$route.params.id).then(function(data) {
				this.squadra = data.data;
			});
		},
		getPlayers: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/giocatori/' + id + '/' + this.area).then(function(data) {
				this.players = data.data;
			});	
		},
		getCategories() {
			this.$http.get(this.$store.state.apiEndPoint + `/exercise/categories`)
			.then(function(data) {
				this.categories_opt = data.data.map(item => ({
					name: item.name,
					id: item.id,
				}));
			});
		},
		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then(function(data) {
				this.teams = data.data;	
			});						
		},
		filtraArchivio() {
			this.teams = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then(function(data) {
				this.teams = data.data;	
			});
		},
		paginate(page) {
			this.page = page;
			this.getTeams();
		}
	}
}

</script>