<template>
	<div v-if="match && stage">
		<b-row>
			<b-col>
				Ranking
				<hr />

				<b-nav tabs class="mb-1">
					<b-nav-item @click.prevent="ux.rank = 'preview'" :active="ux.rank == 'preview'">Classifica Locale</b-nav-item>
					<b-nav-item @click.prevent="ux.rank = 'local'" :active="ux.rank == 'local'">Classifica alla Giornata</b-nav-item>
				</b-nav>
				<div v-if="ux.rank == 'preview'">
					<div v-if="match.standings.length > 0">
						<b-table-simple small striped>
							<b-thead>
								<b-tr>
									<b-th>Team</b-th>
									<b-th>V</b-th>
									<b-th>P</b-th>
									<b-th>S</b-th>
									<b-th>GF</b-th>
									<b-th>GS</b-th>
									<b-th>DR</b-th>
									<b-th>PT</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="team in match.standings">
									<b-td>{{ team.team.name }}</b-td>
									<b-td>{{ team.vittoria_home + team.vittoria_away ? '•' : '' }}</b-td>
									<b-td>{{ team.pareggio_home + team.pareggio_away ? '•' : '' }}</b-td>
									<b-td>{{ team.sconfitta_home + team.sconfitta_away ? '•' : '' }}</b-td>
									<b-td>{{ team.gol_home + team.gol_away || '' }}</b-td>
									<b-td>{{ team.golsubiti_home + team.golsubiti_away || '' }}</b-td>
									<b-td>{{ (team.gol_home + team.gol_away) - (team.golsubiti_home + team.golsubiti_away) || '' }}</b-td>
									<b-td>{{ team.pts }}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>
					<div v-else>
						<b-alert show>Deve essere conclusa almeno una partita, per iniziare a vedere la classifica...</b-alert>
					</div>
				</div>
				<div v-else>
					<div v-if="match.rank_local.length > 0">
						<b-table-simple small striped>
							<b-thead>
								<b-tr>
									<b-th>Team</b-th>
									<b-th></b-th>
									<b-th>V</b-th>
									<b-th>P</b-th>
									<b-th>S</b-th>
									<b-th>GF</b-th>
									<b-th>GS</b-th>
									<b-th>DR</b-th>
									<b-th>PT</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="team in match.rank_local">
									<b-td>{{ team.team.name }}</b-td>
									<b-td>{{ team.played }}</b-td>
									<b-td>{{ team.vittoria_home + team.vittoria_away || '' }}</b-td>
									<b-td>{{ team.pareggio_home + team.pareggio_away || '' }}</b-td>
									<b-td>{{ team.sconfitta_home + team.sconfitta_away || '' }}</b-td>
									<b-td>{{ team.gol_home + team.gol_away || '' }}</b-td>
									<b-td>{{ team.golsubiti_home + team.golsubiti_away || '' }}</b-td>
									<b-td>{{ (team.gol_home + team.gol_away) - (team.golsubiti_home + team.golsubiti_away) || '' }}</b-td>
									<b-td>{{ team.pts }}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>
				</div>

			</b-col>
			<b-col>
				Events
				<hr />

				<b-list-group>
					<b-list-group-item href="javascript:;" @click.prevent="click_editEvent(event)" v-for="event in match.events">
						<span>{{ event.home.name }} <span class="small text-muted">({{ event.home_result }} - {{ event.away_result }})</span> {{ event.away.name }}</span>
						<b-badge class="float float-right ml-1">{{ event.status }}</b-badge>
						<b-badge class="float float-right">{{ event.date | date('DD/MM/YYYY HH:mm') }}</b-badge>
					</b-list-group-item>
				</b-list-group>

				<b-button class="mt-2" block size="sm" variant="outline-primary" @click.prevent="newEvent">Nuovo Evento</b-button>
			</b-col>
		</b-row>

		<b-sidebar width="400px" ref="NewMatchRef" :visible="ux.newEvent" right @hidden="ux.newEvent = false">
			
			<div class="px-4" v-if="ux.newEvent">

				<b-form @submit.prevent="createEvent">
					<Match-Event-Form :event="emptyEvent" :match="match" :competition="competition" :stage="stage"></Match-Event-Form>
					<hr />
					<b-button type="submit" variant="outline-success">Crea Evento</b-button>
				</b-form>

			</div>

		</b-sidebar>

		<b-sidebar width="400px" ref="EditMatchRef" :visible="ux.editEvent" right @hidden="ux.editEvent = false">
			
			<div class="px-4" v-if="ux.editEvent">

				<b-form @submit.prevent="updateEvent">
					<Match-Event-Form :event="editEvent" :match="match" :competition="competition" :stage="stage"></Match-Event-Form>
					<hr />
					<b-button block size="sm" type="submit" variant="outline-success">Modifica Evento</b-button>
					<div class="mt-2 small text-right">
						<a class="text-muted" @click.prevent="deleteEvent" href="javascript:;">Elimina</a>
					</div>
				</b-form>

			</div>

		</b-sidebar>

		<div v-if="stage.feeder_api">
			<a :href="$store.state.endpointRedazione + 'import/' + competition.id + '/stage/' + stage.id + '/match/' + match.id" target="_blank">Reimporta Giornata</a>
		</div>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	components: {
		MatchEventForm: require('@/components/redazione/components/matches/event-form.vue').default,
	},
	created: function() {
		this.emptyEvent = this.gen_emptyEvent();
	},
	data: function() {
		return {
			ux: {
				rank: 'preview',
				newEvent: false,
				editEvent: false
			},
			emptyEvent: null,
			editEvent: null
		}
	},
	methods: {
		click_editEvent: function(event) {
			this.editEvent = _.cloneDeep(event);
			this.ux.editEvent = true;
		},
		gen_emptyEvent: function() {
			return {
				home_id: null,
				away_id: null,
				home_result: null,
				away_result: null,
				date: null,
				status: 0,
				infos: null
			}
		},
		newEvent: function() {
			this.ux.newEvent = true;
		},
		createEvent: function() {
			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/match/' + this.$route.params.match_id + '/new-event', { event: this.emptyEvent }).then(function(data) {
				
				this.$emit('refresh-match');
				this.ux.newEvent = false;
				this.emptyEvent = this.gen_emptyEvent();

			})
		},
		deleteEvent: function() {

			if(!confirm('?')) return false;

			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/match/' + this.$route.params.match_id + '/event/' + this.editEvent.id + '/delete').then(function(data) {
				
				this.$emit('refresh-match');
				this.ux.editEvent = false;
				this.editEvent = null;

			})
		},
		updateEvent: function() {
			this.$http.post(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/match/' + this.$route.params.match_id + '/event/' + this.editEvent.id, { event: this.editEvent }).then(function(data) {
				
				this.$emit('refresh-match');
				this.ux.editEvent = false;
				this.editEvent = null;

			})
		}
	},
	props: [ 'competition', 'stage', 'match' ]
}	

</script>