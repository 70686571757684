<template>
    <div>
        <resalert></resalert>
        <router-view></router-view>
    </div>
</template>
<script>
var Auth = require('../auth.js');

export default {
    name: 'App',
    data () {
        return {
            dummy: '',
        }
    },
    components: {
        resalert: require('./layout/resalert.vue').default,
  },
  computed: {
    auth: function() {
        return Auth;
    },
    user: function() {
        return Auth.user;
    }
},
methods: {
    checkLocalStorage: function() {
        this.auth.checkLocalStorage();
    },
    logout: function() {
        this.user = {};
        Auth.logout(this);
    }
},
ready: function() {
    this.checkLocalStorage();
}
}
</script>
<style lang="scss">
@import "@/css/style.scss";
</style>
