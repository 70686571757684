<template>
	<div class="mt-2">
		
		<b-button v-if="!ux.show" block size="sm" @click.prevent="openPrevisionTable">Acquisisci dati Previsionali</b-button>
		
		<b-card v-if="ux.show">
			<template slot="header">
				<b-row>
					<b-col>
						Dati Previsionali <span>{{toolsAcquired}}<small>/{{toolsTotals}}</small></span>
					</b-col>
					<b-col class="text-right">
						<a href="javascript:;" @click.prevent="ux.show = false">Chiudi</a>
					</b-col>
				</b-row>
			</template>

			<b-overlay :show="toolsAcquired != toolsTotals">
				<b-table-simple small striped hover class="mt-2">
					<b-thead>
						<b-tr>
							<b-th>Fase</b-th>
							<b-th class="text-center">Tot. Dist</b-th>
							<b-th class="text-center">Distanza > 15</b-th>
							<b-th class="text-center">Velocità Massima</b-th>

							<b-th class="text-center">Acc2</b-th>
							<b-th class="text-center">Dec2</b-th>
							<b-th class="text-center">Velocità > 25km/h</b-th>

							<b-th class="text-center">Max HR %</b-th>
							<b-th class="text-center">HR > 85%</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="fase in coupled">
							<b-td>{{ fase.fase.nome }}</b-td>
							<b-td class="text-center">{{ fase.table.drill.total_distance || '/' }}</b-td>
							<b-td class="text-center">{{ fase.table.drill.cc_dist_15 || '/' }}</b-td>
							<b-td class="text-center">{{ fase.table.drill.max_vel || '/' }}</b-td>

							<b-td class="text-center">{{ fase.table.neurom.acc2 || '/' }}</b-td>
							<b-td class="text-center">{{ fase.table.neurom.dec2 || '/' }}</b-td>
							<b-td class="text-center">{{ fase.table.velocity.velocity__25 || '/' }}</b-td>

							<b-td class="text-center">{{ fase.table.hr.max_hr_p || '/' }}</b-td>
							<b-td class="text-center">{{ fase.table.hr.hr_85 || '/' }}</b-td>
						</b-tr>
					</b-tbody>
					<b-tfoot>
						<b-tr>
							<b-th>Totale</b-th>
							<b-th class="text-center">{{ sumOf('drill', 'total_distance') }}</b-th>
							<b-th class="text-center">{{ sumOf('drill', 'cc_dist_15') }}</b-th>
							<b-th class="text-center">{{ sumOf('drill', 'max_vel') }}</b-th>

							<b-th class="text-center">{{ sumOf('neurom', 'acc2') }}</b-th>
							<b-th class="text-center">{{ sumOf('neurom', 'dec2') }}</b-th>
							<b-th class="text-center">{{ sumOf('velocity', 'velocity__25') }}</b-th>

							<b-th class="text-center">{{ sumOf('hr', 'max_hr_p') }}</b-th>
							<b-th class="text-center">{{ sumOf('hr', 'hr_85') }}</b-th>
						</b-tr>
						<b-tr>
							<b-th>Media</b-th>
							<b-th class="text-center">{{ meanOf('drill', 'total_distance') }}</b-th>
							<b-th class="text-center">{{ meanOf('drill', 'cc_dist_15') }}</b-th>
							<b-th class="text-center">{{ meanOf('drill', 'max_vel') }}</b-th>

							<b-th class="text-center">{{ meanOf('neurom', 'acc2') }}</b-th>
							<b-th class="text-center">{{ meanOf('neurom', 'dec2') }}</b-th>
							<b-th class="text-center">{{ meanOf('velocity', 'velocity__25') }}</b-th>

							<b-th class="text-center">{{ meanOf('hr', 'max_hr_p') }}</b-th>
							<b-th class="text-center">{{ meanOf('hr', 'hr_85') }}</b-th>
						</b-tr>
						<b-tr>
							<b-th>DS</b-th>
							<b-th class="text-center">{{ dsOf('drill', 'total_distance') }}</b-th>
							<b-th class="text-center">{{ dsOf('drill', 'cc_dist_15') }}</b-th>
							<b-th class="text-center">{{ dsOf('drill', 'max_vel') }}</b-th>

							<b-th class="text-center">{{ dsOf('neurom', 'acc2') }}</b-th>
							<b-th class="text-center">{{ dsOf('neurom', 'dec2') }}</b-th>
							<b-th class="text-center">{{ dsOf('velocity', 'velocity__25') }}</b-th>

							<b-th class="text-center">{{ dsOf('hr', 'max_hr_p') }}</b-th>
							<b-th class="text-center">{{ dsOf('hr', 'hr_85') }}</b-th>
						</b-tr>
					</b-tfoot>
				</b-table-simple>
			</b-overlay>
		</b-card>

	</div>
</template>


<script>

import th from '@/tools_helper.js';
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'sessione', 'ds' ],
	data: function() {
		return {
			ux: {
				show: false,
				wait: false
			},
			preview: null
		}
	},
	computed: {
		toolsAcquired: function() {
			return _.size(_.filter(this.ds));
		},
		toolsTotals: function() {
			return _.size(this.ds);
		},
		coupled: function() {

			if(_.size(_.filter(this.ds)) == 0) return false;

			var table = [];
			_.each(_.cloneDeep(this.ds), function(a, b) {

				if(a !== null) {
					var posizione = b.split('_')[1];
					var fase = this.sessione.fasi[posizione];
					a.fase = fase;
					table.push(a);

				}
			}.bind(this));

			return table;

		}
	},
	methods: {
		sumOf: function(main, key) {
			return _.round(_.sum(_.map(this.coupled, 'table.' + main + '.' + key)));
		},
		meanOf: function(main, key) {
			var items = _.map(this.coupled, 'table.' + main + '.' + key);
			return _.round(_.sum(items)/items.length);
		},
		dsOf: function(main, key) {
			var items = _.map(this.coupled, 'table.' + main + '.' + key);
			return _.round(th.deviazioneStandard(items));
		},
		openPrevisionTable: function() {
			this.$emit('open');
			this.ux.show = true;
		}
	}
}

</script>