<template id="giocatore">
	<section class="giocatore">

		<headapp></headapp>

		<!--menusub></menusub-->

		<div class="card rounded-0 border-top-0 border-right-0 border-left-0">
			<div class="card-body">
				<div class="row flex-items-xs-middle">
					<div class="col-5">
						<div class="media">
							<img v-bind:src="staff.avatar_url" class="" height="100">
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{staff.nome}} {{staff.cognome}}</strong></h3>
							</div>
						</div>
					</div>
					<div class="col-7 text-right">

						<ul class="list-inline mb-0" v-if="auth.isLastSeason(seasonId)">
							<li class="list-inline-item" v-if="auth.isUserEnable('football_staff_mng')"><b-button :to="{ name: 'football_staff_edit', params: { id: staff_id }}" variant="outline-primary">Modifica Informazioni</b-button></li>	
							<li class="list-inline-item" v-if="auth.isUserEnable('football_staff_mng')"><b-button @click="disassocia(staff)" variant="outline-primary">Disassocia</b-button></li>
							<li class="list-inline-item" v-if="auth.isUserEnable('football_staff_mng')"><b-button @click="elimina(staff)" variant="outline-primary">Elimina</b-button></li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="card-deck" v-if="auth.isUserEnable('football_rosa_tabs')">

			<b-card no-body class="rounded-0 border-0 m-0">
				<b-tabs card>
					<b-tab title="Generali" active>
						<table class="table table-sm table-giocatore mb-0 p-0">
							<tr> <td>Nome</td> <td>{{ staff.nome }}</td> </tr>
							<tr> <td>Cognome</td> <td>{{ staff.cognome }}</td> </tr>
							<tr> <td>Data di nascita</td> <td> {{ staff.nascita_data | formatDate }}</td> </tr>
							<tr v-if="staff.nascita_paese"> <td>Paese di nascita</td> <td><flag :country="staff.nascita_paese.sigla" :size="16"></flag> {{ staff.nascita_paese.paese }}</td> </tr>
							<tr> <td>Luogo di nascita</td> <td><span v-if="staff.info_extra">{{ staff.info_extra.nascita_comune }} {{ staff.nascita_paese_id == 118 && staff.info_extra.nascita_provincia ? '(' + staff.info_extra.nascita_provincia + ')' : '' }}</span></td> </tr>
							<tr v-if="residenza && residenza.paese"> <td>Paese di residenza</td> <td><flag :country="residenza.paese.sigla" :size="16"></flag> {{ residenza.paese.paese }}</td> </tr>
							<tr v-if="residenza"> <td>Luogo di residenza</td> <td>{{residenza.citta}} {{ residenza.paese_id == 118 && residenza.cap && residenza.provincia ? ', ' + residenza.cap +' (' + residenza.provincia + ')' : '' }}</td> </tr>
							<tr v-if="residenza"> <td>Indirizzo di residenza</td> <td>{{residenza.indirizzo }} </td> </tr>
							<tr v-if="domicilio && domicilio.paese"> <td>Paese di domicilio</td> <td><flag :country="domicilio.paese.sigla" :size="16"></flag> {{ domicilio.paese.paese }}</td> </tr>
							<tr v-if="domicilio"> <td>Luogo di domicilio</td> <td>{{domicilio.citta}} {{ domicilio.paese_id == 118 && domicilio.cap && domicilio.provincia ? ', ' + domicilio.cap +' (' + domicilio.provincia + ')' : '' }}</td> </tr>
							<tr v-if="domicilio"> <td>Indirizzo di domicilio</td> <td>{{domicilio.indirizzo }} </td> </tr>
							<tr v-if="email"> <td>Email</td> <td>{{ email }}</td> </tr>
							<tr v-if="pec"> <td>Email PEC</td> <td>{{ pec }}</td> </tr>
							<tr v-for="(numero, index) in telefoni"> <td>Telefono {{ index > 0 ? index + 1 : '' }}</td> <td>{{ numero }}</td> </tr>
						</table>
					</b-tab>
					<b-tab title="Carriera">
						<table class="table table-sm table-giocatore">
							<tr> <td>Matricola FIGC</td> <td> <span v-if="staff.info_extra">{{ staff.info_extra.matricola_figc }}</span></td> </tr>
							<tr> <td>N. tessera</td> <td><span v-if="tessera">{{ tessera.numero }}</span></td> </tr>
							<tr> <td>Ultima Visita Medica / Data Emissione Certificato</td> <td><span v-if="idoneita">{{ idoneita.emissione | formatDate }}</span></td> </tr>
							<tr> <td>Prossima Visita Medica / Data Scadenza Certificato</td> <td><span v-if="idoneita">{{ idoneita.scadenza | formatDate }}</span></td> </tr>
							<tr> <td>Visita Medica Intermedia</td> <td><span v-if="idoneita">{{ idoneita.documento_tipo_glo_id == 10 ? getVisitaMedicaIntermedia(idoneita.scadenza) : 'NO' }}</span></td> </tr>
							<tr> <td>Ruolo</td> <td> {{ staff.last_carriera_sportiva && staff.last_carriera_sportiva.ruoli.length > 0 ? staff.last_carriera_sportiva.ruoli[0].nome : '' }} </td> </tr>
							<tr> <td>Qualifica</td> <td><div v-for="item in staff.carriere">{{ item.qualifica.nome }}<br/></div></td> </tr>		
						</table>
					</b-tab>
					<b-tab title="Dati economici" v-if="auth.isUserEnable('contratti')">
						<table class="table table-sm table-giocatore">
							<tr> <td>Contratto</td> <td>{{staff.info_economica && staff.info_economica.has_contratto ? 'SI' : 'NO' }}</td> </tr>
							<tr> <td>Scadenza Contratto</td> <td><span v-if="contratto"> {{ contratto.scadenza | formatDate }} </span></td> </tr>
							<tr> <td>Rimborso spese</td> <td>{{ staff.info_economica && staff.info_economica.rimborso_spese ? 'SI' : 'NO' }}</td> </tr>
							<tr> <td>Stipendio/Rimborso lordo fisso annuo</td> <td> {{ staff.contratto && staff.contratto.stipendio_lordo && staff.contratto.stipendio_lordo > 0 ? '€ ' + staff.contratto.stipendio_lordo : 'N/D'}}</td> </tr>
							<tr> <td>IBAN</td> <td>{{ staff.info_economica ? staff.info_economica.iban : ''}}</td> </tr> 
							<tr> <td>Intestatario IBAN</td> <td>{{ staff.info_economica ?  staff.info_economica.intestatario_iban : '' }}</td> </tr>
						</table>
					</b-tab>
					<b-tab title="Documenti">
						<table class="table table-sm table-giocatore mb-0 p-0">
							<tr v-for="(passaporto, index) in passaporti"> <td>Passaporto {{ index + 1 }} <flag v-if="passaporto.paese" :country="passaporto.paese.sigla" :size="16"></flag> </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ passaporto.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ passaporto.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && passaporto.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(passaporto.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="cartaidentita"> <td>Carta d'identità </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ cartaidentita.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ cartaidentita.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && cartaidentita.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(cartaidentita.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="codicefiscale"> <td>Tessera sanitaria / Codice fiscale </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ codicefiscale.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ codicefiscale.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && codicefiscale.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(codicefiscale.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-for="(patente, index) in patenti"> <td>Patente {{ index + 1 }} <flag v-if="patente.paese" :country="patente.paese.sigla" :size="16"></flag> </td> <td> <span style="color:#000000;font-weight:normal;"> n°: </span> {{ patente.numero }} <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ patente.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && patente.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(patente.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="circolazione"> <td>Permesso di circolazione </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ circolazione.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && circolazione.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(circolazione.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="soggiorno"> <td>Permesso di soggiorno </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ soggiorno.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && soggiorno.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(soggiorno.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="idoneita && idoneita.path && auth.isUserEnable('egnyte')"> <td>Certificato idoneità sportiva </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ idoneita.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && idoneita.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(idoneita.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="tessera && tessera.path && auth.isUserEnable('egnyte')"> <td>Tessera </td> <td> <b-button v-if="auth.isUserEnable('egnyte') && tessera.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(tessera.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="contratto && auth.isUserEnable('egnyte') && auth.isUserEnable('contratti')"> <td>Contratto </td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ contratto.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && auth.isUserEnable('contratti') && contratto.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(contratto.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
							<tr v-if="greenpass"> <td>Green Pass</td> <td> <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> {{ greenpass.scadenza | formatDate}} <b-button v-if="auth.isUserEnable('egnyte') && greenpass.path" size="sm" variant="primary" title="Visualizza file" @click="downloadDoc(greenpass.id)" class="ml-2"><i class="fa fa-search" aria-hidden="true"></i></b-button></td> </tr>
						</table>
					</b-tab>

				</b-tabs>
			</b-card>
			<b-card v-if="auth.isUserEnable('football_rosa_val')" no-body class="rounded-0 border-top-0 border-right-0 border-bottom-0 m-0">
				<b-tabs card>
					<b-tab v-for="tab in tabs_carriera" :title="tab.anno">
						<b-row>
							<b-col cols="3" v-for="item in tab.items" class="text-center">
								<b-img height="80" :src="item.squadra.logo_url"></b-img>
								
								<h5 class="color-rosso"> {{ item.squadra.categoria.name }} </h5>
							</b-col>
						</b-row>	
					</b-tab>
				</b-tabs>
			</b-card>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types';
import axios from 'axios';
import moment from 'moment';
import { mapState } from 'vuex';
export default {
	data: function() {
		return {
			staff_id: this.$route.params.id,
			staff: null,
			loading: true,
			team: null,
			squadre_societa: [],
		}
	},

	methods: {
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.team = response.data;
	            	this.loading = false;
	            }
	        }, (response) => {
		            // error callback
		        });
			}
		},
		disassocia(staff) {
			if(confirm("Sei sicuro di voler disassociare il membro dallo staff ?")) {
				axios.post(`/staff/disassocia`, { id: staff.id, team_id: this.rosaTeamId})
				.then((res) => {				        
					this.loading    = false;
					this.$router.push({ name: "football_staff"});
				}).catch((err) => {
					this.loading = false;
				});
			}
		},

		elimina(staff) {
			if(confirm("Sei sicuro di voler eliminare il membro dallo staff ?")) {
				axios.post(`/staff/elimina`, { id: staff.id, team_id: this.rosaTeamId})
				.then((res) => {				        
					this.loading    = false;
					this.$router.push({ name: "football_staff"});
				}).catch((err) => {
					this.loading = false;
				});
			}
		},
		downloadDoc(id) {
			window.open(this.$store.state.apiEndPoint + '/football/download/egnyte/doc/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
		getVisitaMedicaIntermedia: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.subtract(6, 'M').format('DD/MM/YYYY'); 
			}
			return "N/D";
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),

		tabs_carriera: function() {
			var tabs = [];
			var anni = [];
			var carriera = this.staff.carriere_sportive;
			carriera.sort(function(a, b) {
				return b.id - a.id;
			});
			for(var i in carriera) {
				var item = carriera[i];
				var tab = null;
				if(!anni.includes(item.stagione.stagione)) {
					tab = {
						anno: item.stagione.stagione,
						items: [],
					};
					tabs.push(tab);
					anni.push(item.stagione.stagione);
				} else {
					tab = tabs.find(obj => obj.anno == item.stagione.stagione);
				}
				if(tab.items.findIndex(obj => obj.squadra_id == item.squadra_id) == -1) {
					tab.items.push(item);
				}
			}
			return tabs;
		},
		email: function() {
			for(var i in this.staff.contatti) {
				var item = this.staff.contatti[i];
				if(item.contatto.tipo == 'email') {
					return item.contatto.valore;
				}
			}
			return null;
		},

		pec: function() {
			for(var i in this.staff.contatti) {
				var item = this.staff.contatti[i];
				if(item.contatto.tipo == 'pec') {
					return item.contatto.valore;
				}
			}
			return null;
		},

		telefoni: function() {
			var telefoni = [];
			for(var i in this.staff.contatti) {
				var item = this.staff.contatti[i];
				if(item.contatto.tipo == 'telefono') {
					telefoni.push(item.contatto.valore);
				}
			}
			return telefoni;
		},
		residenza: function() {
			for(var i in this.staff.indirizzi) {
				var item = this.staff.indirizzi[i];
				if(item.indirizzo.tipo == 'residenza') {
					return item.indirizzo;
				}
			}
			return null;
		},
		domicilio: function() {
			for(var i in this.staff.indirizzi) {
				var item = this.staff.indirizzi[i];
				if(item.indirizzo.tipo == 'domicilio') {
					return item.indirizzo;
				}
			}
			return null;
		},

		contratto: function() {
			var doc = null;
			for(var i in this.staff.documenti) {
				var item = this.staff.documenti[i];
				if(item.documento.documento_tipo_glo_id == 12) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
					}
				}
			}
		}
		return doc;
	},

	passaporti: function() {
		var docs = [];
		for(var i in this.staff.documenti) {
			var item = this.staff.documenti[i];
			if(item.documento.documento_tipo_glo_id == 2) {
				if(docs.length == 0) {
					docs.push(item.documento);
				} else {
					var found = false;
					for(var j in docs) {
						if(docs[j].numero == item.documento.numero) {
							found = true;
							if(item.documento.scadenza > docs[j].scadenza) {
								docs[j] = item.documento;
							}
							break;
						}
					}
					if(!found) {
						docs.push(item.documento);
					}
				}
			}
		}
		return docs;
	},

	cartaidentita: function() {
		var doc = null;
		for(var i in this.staff.documenti) {
			var item = this.staff.documenti[i];
			if(item.documento.documento_tipo_glo_id == 3) {
				if(!doc) {
					doc = item.documento;
				} else {
					if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
						!doc.scadenza || !item.documento.scadenza) {
						doc = item.documento;
				}
			}
		}
	}
	return doc;
},

codicefiscale: function() {
	var doc = null;
	for(var i in this.staff.documenti) {
		var item = this.staff.documenti[i];
		if(item.documento.documento_tipo_glo_id == 4) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},

patenti: function() {
	var docs = [];
	for(var i in this.staff.documenti) {
		var item = this.staff.documenti[i];
		if(item.documento.documento_tipo_glo_id == 5) {
			if(docs.length == 0) {
				docs.push(item.documento);
			} else {
				var found = false;
				for(var j in docs) {
					if(docs[j].numero == item.documento.numero) {
						found = true;
						if(item.documento.scadenza > docs[j].scadenza) {
							docs[j] = item.documento;
						}
						break;
					}
				}
				if(!found) {
					docs.push(item.documento);
				}
			}
		}
	}
	return docs;
},

circolazione: function() {
	var doc = null;
	for(var i in this.staff.documenti) {
		var item = this.staff.documenti[i];
		if(item.documento.documento_tipo_glo_id == 6) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},

soggiorno: function() {
	var doc = null;
	for(var i in this.staff.documenti) {
		var item = this.staff.documenti[i];
		if(item.documento.documento_tipo_glo_id == 7) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},

idoneita: function() {
	var doc = null;
	for(var i in this.staff.documenti) {
		var item = this.staff.documenti[i];
		if(item.documento.documento_tipo_glo_id == 9 || item.documento.documento_tipo_glo_id == 10) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},

tessera: function() {
	var doc = null;
	for(var i in this.staff.documenti) {
		var item = this.staff.documenti[i];
		if(item.documento.documento_tipo_glo_id == 11) {
			if(!doc) {
				doc = item.documento;
			} else {
				if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
					!doc.scadenza || !item.documento.scadenza) {
					doc = item.documento;
			}
		}
	}
}
return doc;
},

greenpass: function() {
			var doc = null;
			for(var i in this.staff.documenti) {
				var item = this.staff.documenti[i];
				if(item.documento.documento_tipo_glo_id == 15) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento;
					}
				}
			}
		}
		return doc;
	},
},

created: function () {

	this.getTeam();

	if(this.$route.params.id){

		axios.get(`/staff/`+this.$route.params.id, {params: { team_id: this.rosaTeamId }})
		.then((res) => {
			this.staff 	= res.data;
		}).catch((err) => {
			this.loading = false;
		});
	}
},

filters: {

	formatDate: function (date) {
		var m = moment(date);
		if(m.isValid()) {
			return m.format('DD/MM/YYYY'); 
		}
		return "";
	},
},

	components: {
		headapp: require('../../../layout/headapp.vue').default,
		menusub: require('../../../layout/menusub.vue').default,
		footerapp: require('../../../layout/footerapp.vue').default,
		flag: require('../../../layout/flag.vue').default,
		card: require('../../../layout/card.vue').default,
	}
}
</script>
