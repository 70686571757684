<template>
	<div style="border: 1px solid #a80532; background-color: #a805320d; border-radius: 5px" class="p-2">
		<b-row align-v="stretch">
			<b-col :cols="6" v-for="r in rulesNames" class="mb-2">
				<div style="height: 100%; background-color: rgba(255,255,255,.9); border-radius: 5px; position: relative" class="mb-1 p-2">
					<div style="position: absolute; right: 0; top: 0; padding: 0 0.3em; background-color: rgba(0,0,0,.4); color: rgb(230,230,230); border-radius: 5px" class="small">{{r.name}}</div>

					<strong>{{ r.verb }}</strong>
					<div class="mt-1">
						<b-btn-group>
							<b-btn size="sm" class="mr-2px" variant="primary" href="" @click.prevent="place(r.name + '.' + c)" v-for="c in r.childs">{{labelsOfValues[c]}}</b-btn>
						</b-btn-group>
						<div class="small mt-2" v-if="!watchdog || r.ruleEntity.fromWatchdog">
							<b-button-group>
								<b-btn size="xs" variant="secondary" @click.prevent="editRule(r)">Configura</b-btn>
								<b-btn v-if="watchdog && r.ruleEntity.fromWatchdog" size="xs" variant="warning" @click.prevent="removeRule(r)">Elimina</b-btn>
							</b-button-group>
						</div>
						<div v-else-if="watchdog">
							<b-btn size="xs" variant="outline-secondary" @click.prevent="duplicateRule(r)">Duplica</b-btn>
						</div>
					</div>

				</div>
			</b-col>
		</b-row>

		<div v-show="ruleSelected">
			<Modal-Edit-Rule :rule="ruleSelected" ref="edtrl"></Modal-Edit-Rule>
		</div>
		<!--
		<Regola class="mb-1" :key="rule.posizione" v-for="rule, k in bonus.rules" :rule="rule" @removerule="do_removeRule(k)"></Regola>

		<div class="text-right">
			<b-btn variant="outline-success" size="sm" @click.prevent="$emit('addr', watchdog)">+ Aggiungi Regola</b-btn>
		</div>
		-->

		<div>
			<b-btn-group block>
				<b-btn size="xs" class="mr-2px" disabled>Operatori Logici</b-btn>
				<b-btn variant="success" class="mr-2px" size="xs" @click.prevent="place('AND')">AND</b-btn>
				<b-btn variant="success" class="mr-2px" size="xs" @click.prevent="place('OR')">OR</b-btn>
			</b-btn-group>
		</div>
		<div>
			<b-btn-group>
				<b-btn size="xs" class="mr-2px" disabled>Operatori di Confronto</b-btn>
				<b-btn variant="success" class="mr-2px" size="xs" @click.prevent="place('>')">MAGGIORE</code></b-btn>
				<b-btn variant="success" class="mr-2px" size="xs" @click.prevent="place('<')">MINORE</b-btn>
				<b-btn variant="success" class="mr-2px" size="xs" @click.prevent="place('=')">UGUALE</b-btn>
				<b-btn variant="success" class="mr-2px" size="xs" @click.prevent="place('!=')">DIVERSO</b-btn>
			</b-btn-group>
		</div>
	</div>
</template>

<script>

import _ from 'lodash'

export default {
	props: [ 'bonusRules', 'rules', 'fields', 'watchdog' ],
	components: {
		ModalEditRule: require('./ModalEditRule.vue').default,
	},
	computed: {
		rulesNames: function() {
			return _.map(this.bonusRules, function(a) {
				var master = _.find(this.rules, { file_id: a.regola });
				console.log(master);

				var childs = [];
				//if(master.type != 'Counter')
				if(this.fields.indexOf('result') !== -1)
					childs.push('result');

				//if(master.type != 'Boolean') {
				if(this.fields.indexOf('amount') !== -1)
					childs.push('amount');

				if(this.fields.indexOf('progress') !== -1)
					childs.push('progress');
				//}

				return {
					verb: a.name,
					name: 'rule' + a.posizione,
					childs,
					ruleEntity: a
				}

				//return 'rule' + a.posizione;
			}.bind(this));
		}
	},
	data: function() {
		return {
			ruleSelected: false,
			labelsOfValues: {
				result: 'Risultato',
				amount: 'Quantità',
				progress: 'Percentuale'
			}
		}
	},
	methods: {
		duplicateRule: function(rule) {

			var deeply = _.cloneDeep(_.find(this.bonusRules, { id: rule.ruleEntity.id }));
			var positionDesired = _.maxBy(this.bonusRules, 'posizione').posizione+1;
			deeply.posizione = positionDesired;
			deeply.fromWatchdog = true;
			deeply.fromWatchdog = true;
			deeply.name = 'Duplicata ' + deeply.name;
			delete deeply.id;

			console.log('Cloned',deeply);

			this.bonusRules.push(deeply);

		},
		removeRule: function(rule) {
			var index = _.findIndex(this.bonusRules, { id: rule.ruleEntity.id });
			this.bonusRules.splice(index, 1);
		},
		editRule: function(rule) {
			this.ruleSelected = _.find(this.bonusRules, { name: rule.verb });
			this.$refs.edtrl.show();
		},
		place: function(v) {
			this.$emit('add', v);
		}
	}
};

</script>