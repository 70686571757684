<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>{{ $i18n.t('Modifica video') }}</span>
					</div>
					<div class="content">
						<b-row>
							<b-col cols="8">	
								<b-form @submit="onSubmit">
									<b-form-group :label="$i18n.t('Titolo')" class="mb-3">
										<b-form-input v-model="form.titolo"></b-form-input>
									</b-form-group>
									<b-form-group :label="$i18n.t('Stato')" v-if="auth.isUserEnable('academy_ita_video_stato')">
										<b-form-radio-group v-model="form.stato">
											<b-form-radio value="0">{{ $i18n.t('Bozza') }}</b-form-radio>
											<b-form-radio value="1">{{ $i18n.t('Pubblicato') }}</b-form-radio>
											<b-form-radio value="2">{{ $i18n.t('Archiviato') }}</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-form-group :label="$i18n.t('Categorie')">
										<b-form-checkbox v-model="allSelected" @change="toggleAll" :indeterminate="indeterminate" style="float:left">
											{{ allSelected ? $i18n.t('Nessuna') : $i18n.t('Tutte') }}
										</b-form-checkbox>
										<b-form-checkbox-group v-model="form.categorie_ids">
											<b-form-checkbox v-for="cat in user.academyCats" :value="cat.id"> {{ cat.nome }} </b-form-checkbox>
										</b-form-checkbox-group>
									</b-form-group>
									<b-form-group :label="$i18n.t('Visibilità')">
										<b-form-radio-group v-model="form.privato">
											<b-form-radio value="0">{{ $i18n.t('Pubblico') }}</b-form-radio>
											<b-form-radio value="1">{{ $i18n.t('Privato') }}</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-form-group label="Academy" v-if="form.privato == 1">
										<b-form-checkbox-group v-model="form.academy_ids">
											<b-form-checkbox v-for="item in academy" :value="item.id"> <img :src="item.logo_url" style="width: 30px;" /> {{ item.nome }} </b-form-checkbox>
										</b-form-checkbox-group>
									</b-form-group>
									<b-form-group :label="$i18n.t('Macroprincipi')">
										<multiselect
										v-model="form.skills"
										:multiple="true"
										:options="skills_options"
										group-values="skills"
										group-label="macro"
										:placeholder="$i18n.t('Seleziona macroprincipi')"
										label="name"
										track-by="id"/>
									</b-form-group>
									<b-embed type="video" controls aspect="16by9" v-if="show_video" :poster="thumb_video" preload="none" controlsList="nodownload" oncontextmenu="return false;">
										<source :src="src_video"/>		
									</b-embed>
									<b-form-group :label="$i18n.t('Mostra i video di:')">
										<b-form-radio-group v-model="filters_date">
											<b-form-radio value="day">{{ $i18n.t('Oggi') }}</b-form-radio>
											<b-form-radio value="week">{{ $i18n.t('Ultimi 7 giorni') }}</b-form-radio>
											<b-form-radio value="month">{{ $i18n.t('Ultimi 30 giorni') }}</b-form-radio>
											<b-form-radio value="all">{{ $i18n.t('Tutti') }}</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
									<b-form-group :label="$i18n.t('Video caricati sul server ASRoma')" v-if="form.type == 'asroma'">
										<div v-if="!upload_loading" style="width:100%;" class="mt-1">
											<span class="font-bold">Loading...</span>
											<img src="/assets/varie/loading.gif" style="width: 30px;" />
										</div>
										<span class="font-bold" v-if="upload_loading && upload_video.length == 0">{{ $i18n.t('Nessun video trovato sul tuo archivio!') }}</span>
										<b-form-select v-model="form.fileNameVideo" v-if="upload_loading && upload_video.length > 0">
											<option :value="null">{{ $i18n.t('Seleziona video') }}</option>
											<optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
												<option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} {{ $i18n.t('caricato il') }} {{ video.data | formatDateHour }}</option>
											</optgroup>
										</b-form-select>
									</b-form-group>
									<b-button type="submit" variant="primary" class="mt-1" v-if="auth.isUserEnable('academy_ita_video_stato') || form.stato == 0">{{ $i18n.t('Salva') }}</b-button>
								</b-form>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col cols="8" class="border-top">
								<b-button class="mt-3" @click="showUpload">{{ $i18n.t('Carica nuovo video') }}</b-button>
								<b-row v-if="show_upload" class="mt-3">
									<b-col>
										<b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
export default {
	data: function() {
		return {
			upload_loading: false,
			upload_video: [],
			show_upload: false,
			form: {
				id: this.$route.params.id,
				type: 'asroma',
				titolo: '',
				fileNameVideo: null,
				stato: 0,
				privato: 0,
				categorie_ids: [],
				academy_ids: [],
				skills: [],
			},
			src_video: '',
			thumb_video: '',
			show_video: false,
			errors: [],
			upload_iframe: '',
			filters_date: 'day',
			academy: [],
			skills_options: [],
			allSelected: false,
			indeterminate: false,
		}
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/academyita/get/list/societa/1').then((response) => {
			if (response.ok)
			{
				this.academy = response.data;    
			}
		}, (response) => {
		});

		axios.get(`/skills`)
		.then((res) => {
			var skills = JSON.parse(res.data.standard);
			var skills_portiere = JSON.parse(res.data.special);

			var skills1 = this.getOptionsSkills(skills[0]);
			var skills2 = this.getOptionsSkills(skills[1]);
			var skills3 = this.getOptionsSkills(skills[2]);

			var skills4 = this.getOptionsSkills(skills_portiere[0]);
			var skills5 = this.getOptionsSkills(skills_portiere[1]);
			var skills6 = this.getOptionsSkills(skills_portiere[2]);
			var skills7 = this.getOptionsSkills(skills_portiere[3]);

			this.skills_options = [
			{
				macro: skills[0][this.$i18n.t('lang')],
				skills: skills1,
			},
			{
				macro: skills[1][this.$i18n.t('lang')],
				skills: skills2,
			},
			{
				macro: skills[2][this.$i18n.t('lang')],
				skills: skills3,
			},
			{
				macro: skills_portiere[0][this.$i18n.t('lang')],
				skills: skills4,
			},
			{
				macro: skills_portiere[1][this.$i18n.t('lang')],
				skills: skills5,
			},
			{
				macro: skills_portiere[2][this.$i18n.t('lang')],
				skills: skills6,
			},
			];
		}).catch((err) => {
		});

		this.getUploadVideo();
		this.getUploadIFrame();

		this.$http.get(this.$store.state.apiEndPoint + '/academyita/video/view/' +  this.$route.params.id).then((response) => {
			if (response.ok)
			{
				this.form.type = response.data.tipo;
				this.form.titolo = response.data.titolo;
				this.src_video = response.data.video;
				this.thumb_video = response.data.thumb;
				this.form.stato = response.data.stato;
				this.form.privato = response.data.privato;
				this.show_video = true;

				for(var i in response.data.academies) {
					var acad = response.data.academies[i];
					this.form.academy_ids.push(acad.id);
				}
				for(var i in response.data.categorie) {
					var cat = response.data.categorie[i];
					this.form.categorie_ids.push(cat.id);
				}
				for(var i in response.data.skills) {
					var skill = response.data.skills[i];
					var item = {
						id: skill.id_skill,
						name: this.$i18n.t(skill.nome_skill)
					}
					this.form.skills.push(item);
				}
			}
		}, (response) => {

		});
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
	},

	watch: {
		filters_date: function () {
			this.form.fileNameVideo = null;
			this.upload_loading = false;
			this.getUploadVideo();
		},
		'form.categorie_ids'(newVal, oldVal) {

			if (newVal.length === 0) {
				this.indeterminate = false
				this.allSelected = false
			} else if (newVal.length === Auth.user.academyCats.length) {
				this.indeterminate = false
				this.allSelected = true
			} else {
				this.indeterminate = true
				this.allSelected = false
			}
		}  
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.editVideo();
		},

		editVideo() {
			if(this.form.categorie_ids.length == 0) {
				alert(this.$i18n.t('Devi selezionare almeno una categoria per il video!'));
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/academyita/video/edit', this.form).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: "academy_int_video" });
					
				}
			}, (response) => {  
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		getUploadVideo() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', {params: { filters_date: this.filters_date }}).then((response) => {
				if (response.ok)
				{
					this.upload_video = response.data;
					this.upload_loading = true;
				}
			}, (response) => {

			});
		},
		showUpload() {
			this.show_upload = !this.show_upload;		
		},
		getUploadIFrame() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
				if (response.ok) {
					this.upload_iframe = response.data;
				}
			}, (response) => {
			});
		},
		getOptionsSkills(skills) {
			var options = [];
			var skill_items = skills.fields;
			for(var key in skill_items) {
				var skill = skill_items[key];
				var option = {
					id: skill.id,
					name: skill[this.$i18n.t('lang')],
				}
				options.push(option);

			}
			return options;
		},
		toggleAll(checked) {
			if(checked) {
				this.form.categorie_ids = []; 
				for(var i in Auth.user.academyCats) {
					this.form.categorie_ids.push(Auth.user.academyCats[i].id);
				}
			} else {
				this.form.categorie_ids = [];
			}
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>
