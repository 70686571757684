<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<!--div class="card-deck"-->
			<div class="row">
				<div class="col">
					<card class="h-100" title="Ultime partite" comp="elencopartite" :params="{'partite': ultime_partite, 'team': team}"></card>
					<div class="text-right">
						<b-button style="position:relative; top: -50px; right:20px;" variant="primary" @click="switchShow" size="sm" :title="(show_all ? 'Nascondi' : 'Mostra') + ' tutte le partite'"><i class="fa fa-arrow-down" aria-hidden="true" v-if="!show_all"></i><i class="fa fa-arrow-up" aria-hidden="true" v-if="show_all"></i></b-button>
					</div>
				</div>
				<div class="col">
					<card class="h-100" title="Prossime partite" comp="elencopartite" :params="{'partite': prossime_partite , 'team': team}"></card>
				</div>
			</div>

			<!--/div-->
			<perfezionamento class="h-100" :only_root_skill="team ? team.categoria.only_root_skill : 0" :skills="skills" :exe_skills="perf_exe_skills" :exe_skills_portiere="perf_exe_skills_portiere" v-if="auth.isAdmin()"></perfezionamento>

		</div>
		<b-modal id="clipsModal" size="xl" ref="clipsModal" :title="titoloClips" class="fs-modal" @hidden="onHiddenClips" @shown="onShowClips" ok-only ok-title="Chiudi">
			<div>
				<video
				id="video-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
			<b-pagination v-show="playlist.length > 1" size="sm" align="center" :total-rows="playlist.length" v-model="count" :per-page="1" @change="selectPageClips" class="mt-1" :limit="40"></b-pagination>
		</div>
	</b-modal>

	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import videojs from 'video.js';
import 'videojs-playlist';
import moment from 'moment';
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
		perfezionamento:    require('../../layout/perfezionamento.vue').default,
	},

	data: function(){
		return {
			team: null,
			ultime_partite: [],
			prossime_partite: [],
			titoloClips: '',
			playlist: [],
			count: 0,
			skills: [],
			perf_exe_skills: [],
			perf_exe_skills_portiere: [],
			show_all: false,
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		rosaTeamId() {
			this.getTeam();
			this.ultimePartite();
			this.prossimePartite();
		},
		seasonId() {
			this.ultimePartite();
			this.prossimePartite();
		},
		show_all() {
			this.ultimePartite();
		},  
	},

	created() {
		this.getTeam();
		this.getSkills();
		this.ultimePartite();
		this.prossimePartite();

		this.$bus.$on('selectClips', ($index, $tipo) => {
			this.selectClips($index, $tipo); 		
		});
	},

	methods: {
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.team = response.data;
	            	this.loading = false;
	            }
	        }, (response) => {
		            // error callback
		        });
			}
		},
		ultimePartite() {
			if(this.rosaTeamId && this.seasonId) {
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/football/ultime/partite', {params: { team_id: this.rosaTeamId, season_id: this.seasonId, show_all: this.show_all ? 'yes' : 'no'}}).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.ultime_partite = response.data;
	            	this.loading = false;
	            }
	        }, (response) => {
		            // error callback
		        });
			}
		},
		prossimePartite() {
			if(this.rosaTeamId && this.seasonId) {
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/football/prossime/partite', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.prossime_partite = response.data;
	            	this.loading = false;
	            }
	        }, (response) => {
		            // error callback
		        });
			}
		},
		getSkills() {
			this.$http.get(this.$store.state.apiEndPoint + '/skills').then((response) => {
				if (response.ok) {
					this.skills = JSON.parse(response.data.standard);
					this.perf_exe_skills = JSON.parse(response.data.standard);
					this.perf_exe_skills_portiere = JSON.parse(response.data.special);
				}
			}, (response) => {

			});
		},
		selectClips(index, tipo) {
			var titolo = '';
			var clips = [];
			var video = null;
			var partita = this.ultime_partite[index];
			if(tipo == 1) {
				video = partita.video;
				titolo = 'Video';
			} else if(tipo == 2) {
				clips = partita.highlights;
				titolo = 'Highlights';
			} else if(tipo == 3) {
				clips = partita.sintesi;
				titolo = 'Sintesi';
			} else if(tipo == 4) {
				clips = partita.goals;
				titolo = 'Goals';
			}
			this.count = 1;
			this.playlist = [];
			this.titoloClips = titolo + ' di ' + this.getTitoloPartita(partita);
			if(video) {
				for(var i in video) {
					var clip = video[i];
					var source = {
						sources: [{
							src: clip.video,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
			} else {
				for(var i in clips) {
					var clip = clips[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + clip.videotag.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
			}
			this.$refs.clipsModal.show();
		},
		onShowClips() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
				this.$video.on('playlistitem', this.autoSelectPage);
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			this.$video.play();
		},
		onHiddenClips() {
			this.$video.playlist([]);
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;		
		},
		selectPageClips(page) {
			this.$video.playlist.currentItem(page - 1);    
		},
		autoSelectPage() {
			this.count = (this.$video.playlist.currentIndex() + 1);
		},
		getTitoloPartita(partita) {
			var titolo = (partita.home ? partita.home.name : partita.home_team) + " - " + (partita.away ? partita.away.name : partita.away_team);
			var m = moment(partita.data);
			if(m.isValid()) {
				titolo += ' del ' + m.format('DD/MM/YYYY HH:mm'); 
			}
			return titolo;
		},
		switchShow: function() {
			this.show_all = !this.show_all;
		}
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
	},
}
</script>
