<template id="esercizibozze">
	<div class="esercizibozze">

		<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 90vh; overflow-y: scroll;" class="row">
			<div class="col-md-4 col-lg-4 col-xl-3 mb-3" style="min-height:250px;" v-if="auth.isUserEnable('palestra_ese_mng')">
				<router-link :to="{ name: $route.meta.module + '_palestra_esercizi_new'}" class="w-100 h-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;">
					<div class="text-center">
						<p class="mb-0"><i class="fa fa-plus-circle text-muted fa-5x"></i></p>
						<strong class="text-uppercase text-muted">Nuovo esercizio</strong>
					</div>
				</router-link>
			</div>
			<div class="col-md-4 col-lg-4 col-xl-3 mb-3" v-for="ex in exercises_condivisi.data">
				<esercizicard :ex="ex"></esercizicard>
			</div>
		</v-infinite-scroll>
	</div>
</template>

<script>
var Auth = require('../../../auth.js');

import axios from 'axios';
import { mapState } from 'vuex';
import InfiniteScroll from 'v-infinite-scroll';

export default {
	methods: {
		nextPage() {
			if(this.page < this.exercises_condivisi.last_page) {
				this.$store.commit('palestra/setPage', this.page + 1);
				this.$store.dispatch('palestra/filter_condivisi', this.page + 1);
			}
		},
	},

	computed: {
		auth: function() {
          return Auth;
        },
		...mapState('palestra', ['exercises_condivisi', 'page']),
	},

	components: {
		esercizicard: require('./esercizicard.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},

	mounted() {
		this.$store.commit('palestra/setPage', 1);
		this.$store.dispatch('palestra/filter_condivisi');
	},

}
</script>
