export const ROUTES_SESSIONS = [
    {
        path: '/sessioni/calendario',
        name: 'reports_eee_dashboard',
        component: require('../components/pages/sessioni/calendar.vue').default,
        meta: {
            module: 'reports',
            //showMenu: false,
            label: 'Reports',
            requiresAuth: true,
            showInMenu: false,
            enableRosaTeam: true,
        //  permessoCategory: true,
            //parent: 'medical_recovery_dashboard',
            permesso: 'reports',
            logCategory: true,
        }
    }
];
