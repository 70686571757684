export default {
	lang: 'en',
  cognome_required: 'The Surname field is required!',
  nome_required: 'The Name field is required!',
  giocatore_presente: 'Giocatore già presente in archivio!',
  giocatore_salvato_ok: 'Giocatore salvato con successo!',
  drag: 'Drag an image or <br>click here to select a file', 
  fileSize: 'The file size exceeds the limit',
  fileType: 'This file type is not supported.',
  add_valutazione_ok: 'Valutazione salvata con successo!',
  'Programma': 'Plan', 
  'Documenti': 'Documents',
  'Esercizi': 'Exercises',
  'Allenamenti': 'Trainings',
  'Esercizi recenti': 'Recent exercises',
  'Archivio documenti': 'Documents archive',
  'Archivio': 'Archive',
  'Bozze': 'Proofs',
  'Nuovo esercizio': 'New exercise',
  'Condividi da Football': 'Share from Football',
  'Condividi da Academy Italia': 'Share from Academy Italia',
  'Filtri': 'Filters',
  'Cerca per titolo...': 'Search by title...',
  'passa ai filtri giocatore': 'switch to player filters',
  'passa ai filtri portiere': 'switch to goalkeeper filters',
  'Titolo esercizio': 'Exercise title',
  'Esercizio': 'Exercise',
  'Difficoltà': 'Difficulty',
  'Facile': 'Easy',
  'Medio': 'Medium',
  'Difficile': 'Hard',
  'seleziona': 'select',
  'guida': 'guide',
  'stampa pdf': 'print pdf',
  'Maglie': 'Shirts',
  'Prima': 'First',
  'Seconda': 'Second',
  'Terza': 'Third',
  'Quarta': 'Fourth',
  'Movimenti': 'Movements',
  'Statico': 'Static',
  'Dinamico': 'Dynamic',
  'Linea': 'Line',
  'Curva': 'Curve',
  'Freccia': 'Arrow',
  'Tratteggiata': 'Dotted',
  'Testo': 'Text',
  'Cancella': 'Delete',
  'Clona': 'Clone',
  'Colore testo/curve/freccie': 'Text/curve/arrow color',
  'Seleziona una dimensione per il testo': 'Select a size for the text',
  'Modalità disegno': 'Draw mode',
  'Esci dalla modalità disegno': 'Exit to draw mode',
  'Modalità disegno freccia ondulata': 'Wavy arrow drawing mode',
  'Esci da modalità disegno freccia ondulata': 'Exit to wavy arrow drawing mode',
  'Spessore linea': 'Line thickness',
  'Misure campo': 'Field measurements',
  'Seleziona una misura fra quelle disponibili e/o digitala manualmente': 'Select a measurement from those available and/or digitize manually',
  'Misure': 'Measurements',
  'Categoria': 'Category',
  'Numero giocatori': 'Players number',
  'Modalità di gioco': 'Game mode',
  'Dimensioni': 'Measurements',
  'Durata': 'Duration',
  'Descrizione': 'Description',
  'Inserisci una descrizione per far capire lo svolgimento dell\'esercizio': 'Enter a description to help understand how the exercise unfolds',
  'Materiale occorrente': 'Required material',
  'Inserisci il materiale che occorre per lo svolgimento dell\'esercizio': 'Insert the material you need for the exercise',
  'passa agli obiettivi giocatore': 'switch to player goals',
  'passa agli obiettivi portiere': 'switch to goalkeeper goals',
  'Minuti esercitazione': 'Minutes exercise',
  'Numero di serie': 'Sets number',
  'Minuti di recupero': 'Recovery minutes',
  'Secondi di recupero': 'Recovery seconds',
  'Numero di recuperi': 'Recovery number',
  'Minuti totali': 'Total minutes',
  'Categorie esercizio': 'Exercise categories',
  'Obiettivi secondari': 'Secondary objectives',
  'Dislocazione giocatori': 'Players location',
  'Varianti': 'Variants',
  'Principi di gioco': 'Game principles',
  'Componenti tecniche': 'Technical components',
  'Capacità motorie': 'Motor skills',
  'Tecnica podalica' : 'Breech technique',
  'Tecnica di base' : 'Basic technique',
  'Qualità tattiche' : 'Tactical qualities',
  'Seleziona video': 'Select video',
  'Mostra i video di:': 'Show videos of:',
  'Oggi': 'Today',
  'Ultimi 7 giorni': 'Last 7 days',
  'Ultimi 30 giorni': 'Last 30 days',
  'Tutti': 'All',
  'Nessun video trovato sul tuo archivio!': 'No videos found on your archive!',
  'caricato il': 'uploaded on',
  'Carica nuovo video': 'Upload new video',
  'Stato esercizio': 'Status exercise',
  'Bozza': 'Proof',
  'Pubblicato': 'Published',
  'Archiviato': 'Filed',
  'Visibilità esercizio': 'Visibility exercise',
  'Pubblico': 'Public',
  'Privato': 'Private',
  'Seleziona una o più academy': 'Select one or more academies',
  'Salva': 'Save',
  'Elimina': 'Delete',
  'Chiudi': 'Close',
  'Video caricati sul server ASRoma': 'Videos uploaded to the ASRoma server',
  'Tutte': 'All',
  'Nessuna': 'None',
  'Cerca esercizio football': 'Look for football exercise',
  'Cerca esercizio Academy Italia': 'Look for Academy Italia exercise',
  'Cerca per titolo/autore...': 'Search by title/author...',
  'Esercizi totali': 'Total exercises',
  'di': 'of',
  'Portieri': 'Goalkeepers',
  'Aggiungi allenamento': 'Add training',
  'Personalizza': 'Customize',
  'Duplica': 'Duplicate',
  'Visualizza esercizio': 'View exercise',
  'Nuova Academy': 'New Academy',
  'Filtra': 'Filter',
  'Nome': 'Name',
  'Matricola': 'Number',
  'Indirizzo': 'Address',
  'Telefono': 'Telephone',
  'Allenatore': 'Coach',
  'Anagrafica': 'Registry',
  'Stampa pdf': 'Print pdf',
  'Data': 'Date',
  'Ora': 'Hour',
  'Crea duplicato': 'Create duplicate',
  'Autore': 'Author',
  'Misure del campo': 'Field size',
  'Materiale': 'Material',
  'Tempo totale': 'Total time',
  'minuti': 'minutes',
  'Allenamento': 'Training',
  'Durata totale': 'Total duration',
  'Fase': 'Phase',
  'Partita': 'Match',
  'Sposta su': 'Move up',
  'Sposta giù': 'Move down',
  'Rimuovi fase': 'Remove phase',
  'Immagine': 'Image',
  'Titolo': 'Title',
  'descrizione fase dell\'allenamento': 'training phase description',
  'Dimensioni campo di allenamento': 'Training field size',
  'Cerca': 'Search',
  'Aggiungi fase': 'Add phase',
  'Aggiungi esercizio': 'Add exercise',
  'Filtra per' : 'Filter by',
  'Visualizza': 'View',
  'Documento': 'Document',
  'Categorie': 'Categories',
  'Azioni': 'Actions',
  'Nuovo PDF': 'New PDF',
  'Stato': 'State',
  'Utente': 'User',
  'Confermi l\'eliminazione del file pdf ?': 'Confirm the deletion of the pdf file?',
  'Inserisci un titolo': 'Insert a title',
  'Seleziona uno stato': 'Select a state',
  'Seleziona una o più categorie a cui associare il pdf': 'Select one or more categories to associate the pdf with',
  'Seleziona visibilità': 'Select visibility',
  'Aggiungi pdf': 'Add pdf',
  'Seleziona pdf': 'Select pdf',
  'Pubblica direttamente': 'Publish directly',
  'Nuovo video': 'New video',
  'Macroprincipi': 'Macro principles',
  'Confermi l\'eliminazione del video ?': 'Confirm the deletion of the video ?',
  'Visibilità': 'Visibility',
  'Seleziona macroprincipi': 'Select macro principles',
  'Modifica video': 'Edit video',
  'Devi selezionare almeno una categoria per il video!': 'You must select at least one category for the video!',
  'Costruzione': 'Building from the back',
  'Smarcamento': 'Unmarking',
  'Transizioni off e dif': 'Off and def transitions',
  'Fluidità di gioco': 'Game flow',
  'Marcatura': 'Marking',
  'Intercettamento': 'Interception',
  'Elastico difensivo': 'Movement of defensive line',
  'Pressing/Pressione': 'Pressing/Pressure',
  'Posizionamento': 'Positioning',
  'Palle inattive': 'Set pieces',
  'Tiro in porta': 'Shot on goal',
  'Passaggio': 'Pass',
  'Assist': 'Assist',
  'Dribbling': '1vs1',
  'Guida della palla': 'Running with the ball',
  'Stop / Controllo orientato': 'Ball control / Oriented control',
  'Colpo di testa': 'Header',
  'Duello': 'Duel',
  'Sensibilità': 'Ball mastery',
  'Mobilità': 'Mobility',
  'Stabilità': 'Stability',
  'Forza': 'Strenght',
  'Potenza': 'Power',
  'Movimento': 'Movement',
  'Agilità': 'Agility',
  'Coordinazione': 'Coordination',
  'Resistenza': 'Stamina',
  'Aspetti cognitivi': 'Cognitive aspects',
  'Piede debole': 'Weak foot',
  'Valutazioni': 'Evaluations',
  'Nuova': 'New',
  'Voto medio': 'Average rating',
  'Nuova valutazione': 'New evaluation',
  'Note': 'Note',
  'Utilizzo software': 'Software use',
  'Interesse apprendimento': 'Interest in learning',
  'Qualità allenatori': 'Coaches quality',
  'Qualità giocatori': 'Players quality',
  'Struttura centro sportivo': 'Sports center structure',
  'Struttura organizzativa societaria': 'Corporate organizational structure',
  'Devi inserire una data per la valutazione!' : 'You must enter a date for the evaluation!', 
  'voto obbligatorio': 'required rating',
  'Valutazione academy salvata con successo!': 'Academy evaluation saved successfully!',
  'Valutazione academy aggiornata con successo!': 'Academy evaluation updated successfully!',
  'Valutazione academy eliminata con successo!': 'Academy evaluation deleted successfully!',
  'Confermi l\'eliminazione della valutazione?': 'Confirm the deletion of the rating?',
  'Modifica valutazione': 'Edit evaluation',
  'Attività': 'Activities',
  'Nuova attività': 'New activity',
  'Dal': 'From',
  'Al': 'To',
  'Devi inserire una data per l\'attivita!' : 'You must enter a date for the activity!',
  'Devi inserire un orario per l\'attivita!':  'You must enter a time for the activity!',
  'Devi selezionare una o più academy!': 'You have to select one or more academies!',
  'Devi inserire un intervallo di date!': 'You must enter a date range!',
  'Devi selezionare un\'academy!': 'You must select an academy!',
  'Attività salvata con successo!': 'Activity saved successfully!',
  'Attività aggiornata con successo!': 'Activity updated successfully!',
  'Confermi l\'eliminazione dell\'attività ?': 'Confirm the deletion of the activity?',
  'Attività eliminata con successo!': 'Activity deleted successfully!',
  'Tipo': 'Type',
  'Compila report': 'Fill report',
  'Modifica report': 'Edit report',
  'Academy director presenza': 'Academy director presence',
  'AS Roma coaches presenti': 'AS Roma coaches present',
  'Academy coaches presenti': 'Academy coaches present',
  'Argomento prestabilito': 'Predetermined topic',
  'Argomento trattato': 'Topic covered',
  'Partecipazione - Interazione': 'Participation - Interaction',
  'Valutazione generale Video Call': 'Video Call Overall Rating',
  'Descrizione generale e dettagliata Video Call': 'General and detailed description Video Call',
  'Obiettivi futuri': 'Going forward',
  'Data arrivo': 'Arrival date',
  'Data partenza': 'Departure date',
  'Giorni': 'Days',
  'Academy coaches interazione': 'Academy coaches interaction',
  'Struttura organizzativa': 'Organizational structure',
  'Panoramica': 'Overview',
  'Valutazione generale': 'Overall Rating',
  'Giorno': 'Day',
  'Dalle': 'From',
  'Alle': 'To',
  'Azione': 'Action',
  'Programma visita': 'Visit program',
  'Giocatori interessati': 'Interested players',
  'Cognome': 'Surname',
  'Data nascita': 'Date of birth',
  'Ruolo': 'Role',
  'Passaporto': 'Passport',
  'Livello': 'Level',
  'Report attività salvato con successo!': 'Activity report saved successfully!',
  'Report attività aggiornato con successo!': 'Activity report updated successfully!',
  'Confermi l\'eliminazione del report?': 'Confirm the deletion of the report?',
  'Report attività eliminato con successo!': 'Activity report deleted successfully!',
  'Collaboratori': 'Collaborators',
  'Da validare': 'To be validated',
  'RIFIUTATO': 'REFUSED',
  'Metti in validazione': 'Validate',
  'si': 'yes',
  'no': 'no',
  'flaggare nel caso in qui si desidera far validare l\'esercizio, l\'esercizio non potrà più essere modificato': 'flag if you want to validate the exercise, the exercise can no longer be modified',
  'Valida esercizio': 'Valid exercise',
  'Inserisci la motivazione del rifiuto': 'Enter the reason for the rejection',
  'Accetta': 'Accept',
  'Rifiuta': 'Refuses',
  'premi su accetta per certificare l\'esercizio ed essere utilizzato nelle fasi di allenamento, premi su rifiuta per rimandare l\'esercizio in bozza': 'press on accept to certify the exercise and be used in the training phases, press on reject to postpone the exercise in draft',
  'Sei sicuro di voler accettare l\'esercizio?': 'Are you sure you want to accept the exercise?',
  'Sei sicuro di voler rifiutare l\'esercizio?': 'Are you sure you want to decline the exercise?',
  'Visualizza file': 'View file',
  'Rimuovi da Egnyte': 'Remove from Egnyte',
  'Nuovo file': 'New file',
  'Scegli un file da caricare...': 'Choose a file to upload ...',
  'Cancella file...': 'Delete file...',
  'Rimuovi file': 'Remove file',
  'Aggiungi file': 'Add file',
  'Attendere salvataggio dei dati...': 'Wait for data saving...',
  'guida_esercizi.jpg': 'exercise_protocol.png',
  'Guida esercizi': 'Exercise Protocol',

}