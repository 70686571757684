<template>
	<div class="evnt" :style="calendarStyle" @click.stop="$emit('select')" @dragstart="$emit('dragstart', $event)">
		<strong>{{ moment(event.CalendarObject.inizio).format('HH:mm') }}</strong> {{ event.CalendarObject.nome }} <div style="float:right">{{ event.CalendarObject.operatore }}</div>
	</div>
</template>

<style type="text/css">

.evnt {
	font-size: 12px;
	background-color: rgba(0,0,0,.1);
	position: relative;
	padding: 3px;
	margin-bottom: 2px;
	border-radius: 3px;
}	

</style>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	props: [ 'event', 'isCurrentMonth'],
	mixins: [ pageBase ],
	data: function() {
		return {
			ux: {
				collapsed: true
			}
		}
	},
	computed: {
		calendarStyle: function() {
			var s = [];

			s.push('background-color: ' + this.event.CalendarObject.colore);
			s.push('color: white');
			s.push('cursor: pointer');
			if(!this.isCurrentMonth) {
				s.push('opacity: 0.3');
			}

			return s.join('; ');
		}
	},
}

</script>