<template>
	<div>
		<div class="text-center" :style="titleStyle">{{ options.nome }}</div>
		<b-table-simple small striped hover v-if="modelFiltered.length">
			<b-tbody>
				<b-tr v-for="skill, k in modelFiltered">
					<b-td style="vertical-align: middle">{{ getSkill(skill.skill_glo_id) ? getSkill(skill.skill_glo_id).etichetta : 'N/D'}}</b-td>
					<b-td style="vertical-align: middle" width="18%" v-if="!config.hideRating || config.hideRating == false">
						<form-rating :disabled="disabled" :value="skill.peso" @update="skill.peso = $event"></form-rating>
					</b-td>
					<b-td style="vertical-align: middle" class="text-right" v-if="!disabled">
						<b-btn size="xs" @click.prevent="rimuoviSelezione(skill.skill_glo_id)" style="margin-top: -4px">
							<i class="fa fa-trash"></i>
						</b-btn>
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
		<b-select :disabled="disabled" @change="do_addSkill(baseSkill)" size="sm" v-model="baseSkill" v-if="config.onlyone ? !modelFiltered.length : true">
			<b-select-option :value="false">Seleziona {{ options.nome }}</b-select-option>
			<b-select-option v-for="item in items.scelte" :value="item.value" :disabled="item.disabled">{{ item.etichetta }}</b-select-option>
		</b-select>

	</div>
</template>

<script>

import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

function createSelectOptions(contenitore, etichetta, item, disabled, depth, maxDepth) {

	if(maxDepth > 0)
		if(maxDepth + 1 == depth) return contenitore;

	etichetta = etichetta + ( etichetta.length > 0 ? ' > ' : '') + '' + item.nome;

	if(item.parent_id !== null) {
		contenitore.scelte.push({
			etichetta,
			disabled: disabled.indexOf(item.id) !== -1,
			value: {
				skill_glo_root_id: null,
				skill_glo_id: item.id,
				peso: 1,
				secondaria: false
			}
		});
	}

	if(item.campi && item.campi.length > 0) {
		_.each(item.campi, function(singoloCampo) {
			createSelectOptions(contenitore, etichetta, singoloCampo, disabled, depth + 1, maxDepth);
		});
	}

	return contenitore;

}

export default {
	mixins: [faseMixin],
	components: {
		FormRating: require('@/components/layout/form-rating.vue').default,
	},
	props: [ 'options', 'model', 'disabled', 'config', 'size', 'onlyone', 'level' ],
	created: function() {
		this.baseSkill = this.emptySkill();
	},
	data: function(){
		return {
			baseSkill: false
		}
	},
	methods: {
		rimuoviSelezione: function(id) {

			if(confirm('Procedi alla rimozione della skill?')) {
				var pos = _.findKey(this.model, { skill_glo_id: id });
				this.model.splice(pos, 1);
			}

		},
		getSkill: function(id) {
			return _.find(this.items.scelte, function(i) {
				return i.value.skill_glo_id == id;
			});
			return null;
		},
		emptySkill: function() {
			this.baseSkill = false;
			return false;
		},
		do_addSkill: function(baseSkill) {
			baseSkill.skill_glo_root_id = this.options.id;
			this.model.push(baseSkill);
			this.emptySkill();
		}
	},
	computed: {
		titleStyle: function() {
			var s = [];
			if(this.size !== 'sm') {
				s.push('font-weight: bold');
			}

			return s.join(';');
		},
		modelFiltered: function() {
			//return this.model;
			return _.filter(this.model, { skill_glo_root_id: this.config.radice });
		},
		items: function() {

			var options = _.cloneDeep(this.options);
			options.nome = '';
			var contenitore = { scelte: [] };
			var disabled = _.map(this.model, 'skill_glo_id');

			contenitore = createSelectOptions(contenitore, '', options, disabled, 0, this.config.level || 0);
			return contenitore;

		}
	},
}
</script>