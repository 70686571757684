<template id=" acad_val_new">
	<section class="acad_val_new">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card rounded-0 border-top-0 border-right-0 border-left-0" v-if="academy">
			<div class="card-body p-3">
				<div class="row flex-items-xs-middle">
					<div class="col-7">
						<div class="media">
							<img v-bind:src="academy.logo_url" class="" height="80">
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{academy.nome}}</strong></h3>
							</div>
						</div>
					</div>
					<div class="col-5 pt-2" v-if="attivita">
						<h4>{{ attivita.remota ? 'Video Call' : 'On Site'}}</h4>
						<div v-if="attivita.remota">{{ attivita.dal | formatDate }} {{ attivita.ora.substr(0, 5) }}</div>
						<div v-else>{{ attivita.dal | formatDate }} - {{ attivita.al | formatDate }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="card-title">
					<span>{{ $i18n.t('Compila report') }}</span>
				</div>
				<div class="content">
					<b-row align-v="center" v-if="attivita">
						<b-col>
							<form @submit="onSubmit">
								<div v-if="attivita.remota">
									<b-row>
										<b-col cols="4">
											<b-form-group :label="$i18n.t('Data')">
												<b-form-input v-model="form.dal" type="date" :disabled="true"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="4">
											<b-form-group :label="$i18n.t('Ora')">
												<b-form-input v-model="form.ora" type="time" :disabled="true"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="4">
											<b-form-group :label="$i18n.t('Durata')">
												<b-form-input v-model="form.durata" type="time"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="12">
											<b-form-group :label="$i18n.t('AS Roma coaches presenti')">
												<multiselect v-model="form.coaches_asroma_presenti" :options="staff_asroma" :multiple="true" :searchable="true" :placeholder="$i18n.t('Seleziona')"></multiselect>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="$i18n.t('Academy coaches presenti')">
												<multiselect v-model="form.coaches_academy_presenti" :options="staff_academy" :multiple="true" :searchable="true" :placeholder="$i18n.t('Seleziona')"></multiselect>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="$i18n.t('Academy director presenza')">
												<b-form-select v-model="form.is_director_presente" :options="options_presenza"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="$i18n.t('Argomento prestabilito')">
												<b-form-input v-model="form.argomento_prestabilito" type="text"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="$i18n.t('Argomento trattato')">
												<b-form-input v-model="form.argomento_trattato" type="text"></b-form-input>
											</b-form-group>
										</b-col>

										<b-col cols="3">
											<b-form-group :label="$i18n.t('Partecipazione - Interazione')">
												<b-form-select v-model="form.interazione.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="9">
											<b-form-group label-cols="1" label="&nbsp;">
												<b-form-input v-model="form.interazione.note" :placeholder="$i18n.t('Note')"></b-form-input>
											</b-form-group>
										</b-col>

										<b-col cols="3">
											<b-form-group :label="$i18n.t('Valutazione generale Video Call')">
												<b-form-select v-model="form.valutazione_generale.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="9">
											<b-form-group label-cols="1" label="&nbsp;">
												<b-form-input v-model="form.valutazione_generale.note" :placeholder="$i18n.t('Note')"></b-form-input>
											</b-form-group>
										</b-col>

										<b-col cols="12">
											<b-form-group :label="$i18n.t('Descrizione generale e dettagliata Video Call')">
												<b-form-textarea
												id="textarea"
												v-model="form.descrizione"
												rows="3"
												max-rows="6"
												></b-form-textarea>
											</b-form-group>
										</b-col>
										<b-col cols="12">
											<b-form-group :label="$i18n.t('Obiettivi futuri')">
												<b-form-textarea
												id="textarea"
												v-model="form.obiettivi"
												rows="3"
												max-rows="6"
												></b-form-textarea>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
								<div v-else>
									<b-row>
										<b-col cols="4">
											<b-form-group :label="$i18n.t('Data arrivo')">
												<b-form-input v-model="form.dal" type="date" :disabled="true"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="4">
											<b-form-group :label="$i18n.t('Data partenza')">
												<b-form-input v-model="form.al" type="date" :disabled="true"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="4">
											<b-form-group :label="$i18n.t('Giorni')">
												<b-form-input v-model="form.giorni" type="number"></b-form-input>
											</b-form-group>
										</b-col>
										<!--b-col cols="6">
										</b-col-->
										<b-col cols="12">
											<b-form-group :label="$i18n.t('AS Roma coaches presenti')">
												<multiselect v-model="form.coaches_asroma_presenti" :options="staff_asroma" :multiple="true" :searchable="true" :placeholder="$i18n.t('Seleziona')"></multiselect>
											</b-form-group>
										</b-col>
										<!--b-col cols="6">
										</b-col-->
										<b-col cols="6">
											<b-form-group :label="$i18n.t('Academy director presenza')">
												<b-form-select v-model="form.presenza_director.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="$i18n.t('Academy coaches interazione')">
												<b-form-select v-model="form.interazione.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<!--b-col cols="6">
										</b-col-->
										<b-col cols="3">
											<b-form-group :label="$i18n.t('Utilizzo software')">
												<b-form-select v-model="form.utilizzo_software.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="9">
											<b-form-group label-cols="1" label="&nbsp;">
												<b-form-input v-model="form.utilizzo_software.note" :placeholder="$i18n.t('Note')"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="3">
											<b-form-group :label="$i18n.t('Interesse apprendimento')">
												<b-form-select v-model="form.apprendimento.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="9">
											<b-form-group label-cols="1" label="&nbsp;">
												<b-form-input v-model="form.apprendimento.note" :placeholder="$i18n.t('Note')"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="3">
											<b-form-group :label="$i18n.t('Qualità allenatori')">
												<b-form-select v-model="form.qualita_allenatori.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="9">
											<b-form-group label-cols="1" label="&nbsp;">
												<b-form-input v-model="form.qualita_allenatori.note" :placeholder="$i18n.t('Note')"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="3">
											<b-form-group :label="$i18n.t('Qualità giocatori')">
												<b-form-select v-model="form.qualita_giocatori.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="9">
											<b-form-group label-cols="1" label="&nbsp;">
												<b-form-input v-model="form.qualita_giocatori.note" :placeholder="$i18n.t('Note')"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col cols="3">
											<b-form-group :label="$i18n.t('Struttura organizzativa')">
												<b-form-select v-model="form.struttura.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="9">
											<b-form-group label-cols="1" label="&nbsp;">
												<b-form-input v-model="form.struttura.note" :placeholder="$i18n.t('Note')"></b-form-input>
											</b-form-group>
										</b-col>

										<b-col cols="12">
											<p class="color-rosso"><strong>{{ $i18n.t('Programma visita') }}</strong></p>
											<b-row align-v="center" v-if="form.programma.length > 0">
												<b-col cols="2">
													{{$i18n.t('Giorno')}}
												</b-col>
												<b-col cols="1">
													{{$i18n.t('Dalle')}}
												</b-col>
												<b-col cols="1">
													{{$i18n.t('Alle')}}
												</b-col>
												<b-col cols="2">
													{{$i18n.t('Azione')}}
												</b-col>
												<b-col>
													{{$i18n.t('Note')}}
												</b-col>
												<b-col cols="1">
												</b-col>
											</b-row>
											<b-row align-v="center" v-for="(item, index) in form.programma" class="mb-1">
												<b-col cols="2">
													<b-form-input v-model="item.data" type="date" :max="form.al" :min="form.dal"></b-form-input>
												</b-col>
												<b-col cols="1">
													<b-form-input v-model="item.da" type="time"></b-form-input>
												</b-col>
												<b-col cols="1">
													<b-form-input v-model="item.a" type="time"></b-form-input>
												</b-col>
												<b-col cols="2">
													<b-form-select v-model="item.azione" :options="options_azione"></b-form-select>
												</b-col>
												<b-col>
													<b-form-input v-model="item.note" type="text" :placeholder="$i18n.t('Note')"></b-form-input>
												</b-col>
												<b-col cols="1">
													<b-button size="sm" @click="delProgramma(index)" class="ml-3"><i class="fa fa-minus" aria-hidden="true"></i></b-button>	
												</b-col>
											</b-row>
											<b-button class="mt-1 mb-2" size="sm" @click="addProgramma"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
										</b-col>
										<b-col cols="12">
											<p class="color-rosso"><strong>{{ $i18n.t('Giocatori interessati') }}</strong></p>
											<b-row align-v="center" v-if="form.giocatori.length > 0">
												<b-col cols="2">
													{{$i18n.t('Cognome')}}
												</b-col>
												<b-col cols="2">
													{{$i18n.t('Nome')}}
												</b-col>
												<b-col cols="2">
													{{$i18n.t('Data nascita')}}
												</b-col>
												<b-col cols="2">
													{{$i18n.t('Ruolo')}}
												</b-col>
												<b-col cols="1">
													{{$i18n.t('Passaporto')}}
												</b-col>
												<b-col cols="1">
													{{$i18n.t('Livello')}}
												</b-col>
												<b-col cols="1">
												</b-col>
												<b-col cols="1">
												</b-col>
											</b-row>	
											<b-row align-v="center" v-for="item in form.giocatori" class="mb-1">

												<b-col cols="2">
													<b-form-input v-model="item.cognome" type="text" :placeholder="$i18n.t('Cognome')"></b-form-input>
												</b-col>
												<b-col cols="2">			
													<b-form-input v-model="item.nome" type="text" :placeholder="$i18n.t('Nome')"></b-form-input>
												</b-col>
												<b-col cols="2">
													<b-form-input v-model="item.dob" type="date"></b-form-input>
												</b-col>
												<b-col cols="2">
													<b-form-select v-model="item.ruolo" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
												</b-col>
												<b-col cols="1">
													<b-form-input v-model="item.passaporto" type="text" :placeholder="$i18n.t('Passaporto')"></b-form-input>
												</b-col>
												<b-col cols="1">
													<b-form-select v-model="item.livello" :options="options"></b-form-select>
												</b-col>
												<b-col cols="1" class="text-center">
													<span v-if="item.livello >= 5" :style="'border-radius: 5px; background:' + getColor(item.livello)" class="p-1">{{ getLabel(item.livello) }}</span>
												</b-col>
												<b-col cols="1">
													<b-button size="sm" @click="delGiocatore(index)" class="ml-3"><i class="fa fa-minus" aria-hidden="true"></i></b-button>	
												</b-col>	
											</b-row>
											<b-button size="sm" @click="addGiocatore" class="mt-1 mb-2"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
										</b-col>


										<b-col cols="12">
											<b-form-group :label="$i18n.t('Panoramica')">
												<b-form-textarea
												id="textarea"
												v-model="form.descrizione"
												rows="3"
												max-rows="6"
												></b-form-textarea>
											</b-form-group>
										</b-col>
										<b-col cols="12">
											<b-form-group :label="$i18n.t('Obiettivi futuri')">
												<b-form-textarea
												id="textarea"
												v-model="form.obiettivi"
												rows="3"
												max-rows="6"
												></b-form-textarea>
											</b-form-group>
										</b-col>
										<b-col cols="12">
											<b-form-group :label="$i18n.t('Valutazione generale')">
												<b-form-select v-model="form.valutazione_generale.voto" :options="options"></b-form-select>
											</b-form-group>
										</b-col>
									</b-row>
								</div>
								<fieldset v-show="auth.isUserEnable('egnyte')">
									<legend>Files</legend>
									<b-form-group id="fileGroup">
										<b-form-row v-for="(item, index) in form.files" :key="index" class="mb-3 pt-3 border-bottom">
											<b-col cols="12">
												<!--b-form-group horizontal :label-cols="1"-->
													<b-row>
														<b-col cols="5" v-if="item.documento">
															{{ getNomeFile(item.documento) }}
															<b-button class="ml-1" variant="primary" @click="downloadFile(item.id)" :title="$i18n.t('Visualizza file')"><i class="fa fa-search" aria-hidden="true"></i></b-button>
														</b-col>
														<b-col cols="5">
															<b-form-group horizontal :label="$i18n.t('Nuovo file')" :label-cols="3">
																<b-form-file
																v-model="item.file"
																:state="Boolean(item.file)"
																:placeholder="$i18n.t('Scegli un file da caricare...')"
																:drop-placeholder="$i18n.t('Cancella file...')"
																></b-form-file>
															</b-form-group>
														</b-col>
														<b-col cols="2">
															<b-button variant="danger" @click="removeFile(index)"><i class="fa fa-trash fa-fw"></i> {{$i18n.t('Rimuovi file')}}</b-button>
														</b-col>
													</b-row>
												<!--/b-form-group-->
											</b-col>
										</b-form-row>

										<b-button variant="outline-primary" @click="addFile"><i class="fa fa-plus fa-fw"></i> {{$i18n.t('Aggiungi file')}}</b-button>
									</b-form-group>
								</fieldset>
								<b-button type="submit" variant="primary" class="mt-1 text-uppercase">{{ $i18n.t('Salva') }}</b-button>
							</form>
						</b-col>
					</b-row>
					<b-modal id="waitingModal" ref="waitingModal" ok-only>
						<div align="center">
							<h4>{{ $i18n.t('Attendere salvataggio dei dati...') }}</h4>
							<img src="/assets/varie/loading.gif" style="width: 150px;" />
						</div>
					</b-modal>
				</div>	
			</div>
		</div>


		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../../store/types'
var Auth = require('../../../../auth.js');
export default {

	data: function() {
		return {
			academy: null,
			attivita: null,
			form: {
				id_attivita_academy: this.$route.params.att_acad_id,
				dal: null,
				al: null,
				ora: null,
				durata: null,
				giorni: null,
				coaches_asroma_presenti: [],
				coaches_academy_presenti: [],
				is_director_presente: 0,
				presenza_director: {
					voto: 0,
					note: null,
				},
				interazione: {
					voto: 0,
					note: null,
				},
				utilizzo_software: {
					voto: 0,
					note: null,
				},
				apprendimento: {
					voto: 0,
					note: null,
				},
				qualita_allenatori: {
					voto: 0,
					note: null,
				},
				qualita_giocatori: {
					voto: 0,
					note: null,
				},
				struttura: {
					voto: 0,
					note: null,
				},
				valutazione_generale: {
					voto: 0,
					note: null,
				},
				descrizione: null,
				obiettivi: null,
				argomento_prestabilito: null,
				argomento_trattato: null,
				programma: [],
				giocatori: [],
				files: [],
			},
			options: [{ value: 0, text: '1-10' }, 1,2,3,4,5,6,7,8,9,10],
			options_presenza: [{ value: 0, text: 'No' }, { value: 1, text: 'Si' }],
			options_azione: [{ value: 1, text: 'Match' }, { value: 2, text: 'Meeting' }, { value: 3, text: 'Training' }],
			ruoli: [],
			staff_asroma: [],
			staff_academy: [],
		}
	},

	components: {
		headapp: require('../../../layout/headapp.vue').default,
		menusub: require('../../../layout/menusub.vue').default,
		footerapp: require('../../../layout/footerapp.vue').default,
	},

	created: function () {
		if(this.$route.params.att_acad_id) {
			axios.get(`/academyint/attivita/academy/` + this.$route.params.att_acad_id)
			.then((response) => {
				this.academy = response.data.academy;
				if(this.academy.staff) {
					var collaboratori = JSON.parse(this.academy.staff);
					for(var j in collaboratori) {
						this.staff_academy.push(collaboratori[j].cognome + ' ' + collaboratori[j].nome);
					}
				}
				this.attivita = response.data.attivita;
				if(this.attivita.remota) {
					this.form.dal = this.attivita.dal;
					this.form.ora = this.attivita.ora;
				} else {
					this.form.dal = this.attivita.dal;
					this.form.al = this.attivita.al;
					var startDate = moment(this.form.dal);
  					var endDate = moment(this.form.al);
  					var giorni = endDate.diff(startDate, 'days');
  					this.form.giorni = giorni + 1;
				}
			}).catch((err) => {
				this.loading = false;
			});
		}

		this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
			if (response.ok)
			{
				this.ruoli = response.data; 
				this.ruoli.unshift({id: null, ruolo: '-- ' + this.$i18n.t('Ruolo') + ' --'});   
			}
		}, (response) => {
		});

		this.$http.get(this.$store.state.apiEndPoint + '/staff/get/all/football').then((response) => {
			if (response.ok)
			{
				var staff = response.data;
				for(var i in staff) {
					this.staff_asroma.push(staff[i].cognome + ' ' + staff[i].nome);
				} 
			}
		}, (response) => {
		});

		axios.get(`/academyita/societa/all/staff/` + this.$route.params.id)
		.then((res) => {
			var staff = res.data;		
			for(var j in staff) {
				this.staff_academy.push(staff[j].cognome + ' ' + staff[j].nome);
			}
		}).catch((err) => {
			this.loading = false;
		});
	},

	computed: {
		auth: function() {
			return Auth;
		},

		user: function() {
			return Auth.user;
		},
	},

	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.addReport();
		},

		addReport() {
			var formData = new FormData();
			formData.append("form", JSON.stringify(this.form));

			for(var i in this.form.files) {
				formData.append("files_report_" + i, this.form.files[i].file);
			}
			this.$refs.waitingModal.show();

			this.$http.post(this.$store.state.apiEndPoint + '/academyint/report/attivita/add', formData).then((response) => {
				if (response.ok)
				{
					this.$refs.waitingModal.hide();
					this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
					this.$router.push({ name: "academy_int_societa_attivita", params: { id: this.$route.params.id }});
					
				}
			}, (response) => {  
				if (response.status == 422) {
					this.$refs.waitingModal.hide();
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		addProgramma() {
			this.form.programma.push({
				data: null,
				da: null,
				a: null,
				azione: 1,
				note: null,
			});
		},

		delProgramma(index) {
			this.form.programma.splice(index, 1);
		},

		addGiocatore() {
			this.form.giocatori.push({
				cognome: null,
				nome: null,
				dob: null,
				ruolo: null,
				passaporto: null,
				livello: 0,
			});
		},

		delGiocatore(index) {
			this.form.giocatori.splice(index, 1);
		},
		
		getLabel(livello) {
			if(livello == 5 || livello == 6) {
				return 'da rivedere';
			} else if(livello == 7 || livello == 8) {
				return 'da visionare';
			} else if(livello == 9 || livello == 10) {
				return 'Trigoria try-out';
			}
		},

		getColor(livello) {
			if(livello == 5 || livello == 6) {
				return 'orange';
			} else if(livello == 7 || livello == 8) {
				return 'yellow';
			} else if(livello == 9 || livello == 10) {
				return 'green';
			}
			return '#FFFFFF';
		},

		addFile() {
			this.form.files.push({ documento: null, file: ''});
		},

		removeFile(index) {
			this.form.files.splice(index, 1);
		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
