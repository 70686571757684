<template>
	<b-col class="text-center datisingle">
		<b-form-checkbox :value="1" :unchecked-value="0" v-if="item.type == 'boolean'" size="sm" v-model="item.value" :name="item.name" button :button-variant="item.value ? 'primary' : 'outline-secondary'">
			{{ item.label }}
		</b-form-checkbox>
		<b-form-group :label="item.label" v-if="item.type == 'number'">
			<b-form-input v-model="item.value" type="number" :min="item.typeConfig && item.typeConfig.min !== 'undefined' ? item.typeConfig.min : ''" :max="item.typeConfig && item.typeConfig.max ? item.typeConfig.max : ''" :step="item.typeConfig && item.typeConfig.step ? item.typeConfig.step : ''"></b-form-input>
		</b-form-group>
		<div v-if="item.items && item.items.length > 0 && item.value" class="border p-1 mt-1">
			<FaseGlossarioDati :items="item.items"></FaseGlossarioDati>
		</div>
			
	</b-col>
</template>

<style type="text/css">

.datisingle .d-inline-block {
	display: block !important;
}	

.datisingle .btn {
	display: block !important;
}	

</style>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

// import FaseGlossarioDati from '@/components/layout/Sessioni/Generica/FaseGlossarioDati.vue';

export default {
	name: 'Fase_Glossario_Dati_Single',
	props: [ 'item' ],
	data: function(){
		return {
			
		}
	},
	watch: {
		item: {
			immediate: true,
			deep: true,
			handler: function() {
				if(this.item.value == undefined) {
					console.log(this.item);
					this.$set(this.item, 'value', null);
				}
			}
		}
	},
	created: function() {
		
	},
	methods: {
		
	},
	components: {
		FaseGlossarioDati: () => import('@/components/layout/Sessioni/Generica/FaseGlossarioDati.vue')
	},
}
</script>