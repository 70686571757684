<template>
	<div>
		<b-modal size="lg" v-model="ux.show" @hide="$emit('close')">

			<template #modal-header>
				Testa il preset su un contratto esistente
			</template>

			<template #modal-footer>

				<b-button @click.prevent="doTest" variant="outline-success">Prova la Regola</b-button>

			</template>

			<b-form-group label="ID Contratto">
				<b-input v-model="contratto_id"></b-input>
			</b-form-group>

			<div v-if="results">
				<pre>{{ results }}</pre>
			</div>

		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'

export default {
	props: [ 'preset' ],
	created: function() {
		this.ux.show = true;
	},
	methods: {
		doTest: function() {
			this.results = null;
			this.$http.post(this.$store.state.apiEndPoint + '/contratto/test-rules-preset', { rules: this.theRules, validation: this.preset.validation, contratto_id: this.contratto_id }).then(function(data) {
				this.results = data.data;
			});
		}
	},
	computed: {
		theRules: function() {
			return this.preset.regole_json
		}
	},
	data: function() {
		return {
			contratto_id: null,
			results: null,
			rule: false,
			ux: {
				show: false
			},
			ruleInfo: false
		}
	}
}

</script>