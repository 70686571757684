<template>
	<div>
		<b-row>
			<b-col>
				<b-card :key="random">
					<E-Chart style="width: 100%; height: 800px" :option="graphOption"></E-Chart>
				</b-card>
			</b-col>
			<b-col cols="2" class="pl-3">
				<b-form-group label="Visualizza">
					<b-form-radio-group size="sm" v-model="show"><b-form-radio value="media">Medie</b-form-radio><b-form-radio value="totale">Totali</b-form-radio></b-form-radio-group>
				</b-form-group>
				<b-form-group label="Livello di dettaglio">
					<b-form-radio-group size="sm" v-model="level"><b-form-radio value="team">Team</b-form-radio><b-form-radio value="player" :disabled="filters.players.length == 0">Giocatore</b-form-radio></b-form-radio-group>
				</b-form-group>
				<b-form-group label="Tipologia di dati">
					<b-form-radio-group size="sm" v-model="type"><b-form-radio value="distance">Distanze (m)</b-form-radio><b-form-radio value="duration">Durate</b-form-radio></b-form-radio-group>
				</b-form-group>
				<b-form-group label="Unità di misura" v-if="type == 'duration'">
					<b-form-radio-group size="sm" v-model="misura"><b-form-radio value="secondi">Secondi</b-form-radio><b-form-radio value="minuti">Minuti</b-form-radio></b-form-radio-group>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>

function isValidNumber(a) {
	if(!_.isNumber(a) || _.isNaN(a) || a == 0 || !_.isFinite(a)) return false;
	return true;
}

function buildVelocity(dati, type, show, misura, level) {
	var velocity_0_8 = 0;
	var velocity_8_13 = 0;
	var velocity_13_15 = 0;
	var velocity_15_20 = 0;
	var velocity_20_25 = 0;
	var velocity__25 = 0;

	if(type == 'distance') {
		velocity_0_8 =_.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
			]);
		velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_distance');
		velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_distance');
		velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_distance');
		velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_distance');
		velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_distance');
	} else if(type == 'duration') {
		velocity_0_8 =_.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_duration'),
			_.sumBy(dati, 'catapult_velocity_band2_total_duration'),
			_.sumBy(dati, 'catapult_velocity_band3_total_duration'),
			]);
		velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_duration');
		velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_duration');
		velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_duration');
		velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_duration');
		velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_duration');
		if(misura == 'minuti') {
			velocity_0_8 = velocity_0_8/60;
			velocity_8_13 = velocity_8_13/60;
			velocity_13_15 = velocity_13_15/60;
			velocity_15_20 = velocity_15_20/60;
			velocity_20_25 = velocity_20_25/60;
			velocity__25 = velocity__25/60;
		}
	}
	return {
		velocity_0_8: isValidNumber(velocity_0_8) ? (show == 'totale' || level == 'player' ? _.round(velocity_0_8) : velocity_0_8) : 0,
		velocity_8_13: isValidNumber(velocity_8_13) ? (show == 'totale' || level == 'player' ? _.round(velocity_8_13) : velocity_8_13) : 0,
		velocity_13_15: isValidNumber(velocity_13_15) ? (show == 'totale' || level == 'player' ? _.round(velocity_13_15) : velocity_13_15) : 0,
		velocity_15_20: isValidNumber(velocity_15_20) ? (show == 'totale' || level == 'player' ? _.round(velocity_15_20) : velocity_15_20) : 0,
		velocity_20_25: isValidNumber(velocity_20_25) ? (show == 'totale' || level == 'player' ? _.round(velocity_20_25) : velocity_20_25) : 0,
		velocity__25: isValidNumber(velocity__25) ? (show == 'totale' || level == 'player' ? _.round(velocity__25) : velocity__25) : 0,
	}
}

import eChart from '@/components/layout/E-Chart/Base.vue';
export default {
	props: [ 'dataset', 'filters', 'results' ],
	components: {
		'E-Chart': eChart,
	},
	data: function() {
		return {
			kpi_keys: [
				{key: 'velocity_0_8', label: '0-8 km/h' },
				{key: 'velocity_8_13', label: '8-13 km/h' },
				{key: 'velocity_13_15', label: '13-15 km/h' },
				{key: 'velocity_15_20', label: '15-20 km/h' },
				{key: 'velocity_20_25', label: '20-25 km/h' },
				{key: 'velocity__25', label: '>25 km/h' },
				],
			show: 'totale',
			type: 'distance',
			misura: 'secondi',
			level: 'team',
			random: Math.random(),
		}
	},
	watch: {
		'filters.players': function() {
			if(this.filters.players.length == 0) {
				this.level = 'team';
			}
		},
		level: function() {
			this.random = Math.random();
		}, 
		dataset: function() {
			this.random = Math.random();
		},
		type: function() {
			this.random = Math.random();
		},
		show: function() {
			this.random = Math.random();
		},
	},
	computed: {
		theDataset: function() {
			var dataset = this.dataset;
			var grouped = _.groupBy(dataset, 'date');
			if(this.show == 'media') {
				var data = _.mapValues(grouped, function(dati, date) {
					var grouped_by_person = _.groupBy(dati, 'persona_id');
					var data_person = _.map(grouped_by_person, function(dati_persona) {
						return buildVelocity(dati_persona, this.type, this.show, this.misura, this.level);
					}.bind(this));
					var medie = {
						velocity_0_8: _.size(data_person.filter(item => item['velocity_0_8'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'velocity_0_8') / _.size(data_person.filter(item => item['velocity_0_8'] != 0)), 0) : 0,
						velocity_8_13: _.size(data_person.filter(item => item['velocity_8_13'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'velocity_8_13') / _.size(data_person.filter(item => item['velocity_8_13'] != 0)), 0) : 0,
						velocity_13_15: _.size(data_person.filter(item => item['velocity_13_15'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'velocity_13_15') / _.size(data_person.filter(item => item['velocity_13_15'] != 0)), 0) : 0,
						velocity_15_20: _.size(data_person.filter(item => item['velocity_15_20'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'velocity_15_20') / _.size(data_person.filter(item => item['velocity_15_20'] != 0)), 0) : 0,
						velocity_20_25: _.size(data_person.filter(item => item['velocity_20_25'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'velocity_20_25') / _.size(data_person.filter(item => item['velocity_20_25'] != 0)), 0) : 0,
						velocity__25: _.size(data_person.filter(item => item['velocity__25'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'velocity__25') / _.size(data_person.filter(item => item['velocity__25'] != 0)), 0) : 0,
					}
					return medie;
				}.bind(this));
				return data;
			} else {
				var data = _.mapValues(grouped, function(dati, date) {
					return buildVelocity(dati, this.type, this.show, this.misura, this.level);
				}.bind(this));
				return data;
			}
		},
		theDatasetByPerson: function() {
			var dataset = this.dataset;
			var grouped_by_person = _.groupBy(dataset, 'persona_id');
			var dataset_persona = _.mapValues(grouped_by_person, function(a) {
				return _.groupBy(a, 'date');
			});
			return _.mapValues(dataset_persona, function(a) {
				return _.mapValues(a, function(b) {
					return buildVelocity(b, this.type, this.show, this.misura, this.level);
				}.bind(this));
			}.bind(this));
		},
		days: function () {
			var days = [];
			if(this.theDataset) {
				days = _.keys(this.theDataset);
			}
			return _.sortBy(days);
		},
		graphOption: function() {
			var series = [];
			if(this.level == 'team') {
				_.forEach(this.kpi_keys, function (kpi) {
					var data = [];
					_.forEach(this.days, function (day) {
						var find = this.theDataset[day];
						if(find) {
							data.push(find[kpi.key]);
						} else {
							data.push(0);
						}
					}.bind(this));
					var serie = {
						data: data,
						type: 'bar',
						stack: 'Total',
						name: kpi.label,
						label: {
							show: true
						}
					};
					series.push(serie);

				}.bind(this));
			} else {
				_.forEach(this.theDatasetByPerson, function(dsp, person_id) {
					var index = 0;
					_.forEach(this.kpi_keys, function (kpi) {
						var data = [];
						var points = [];
						_.forEach(this.days, function (day) {
							var find = dsp[day];
							if(find) {
								data.push(find[kpi.key]);
							} else {
								data.push(0);
							}
							if(index == 0) {
								points.push({ name: 'Giocatore', value: this.getPerson(person_id).cognome, xAxis: day, yAxis: 0 });
							}
						}.bind(this));
						var serie = {
							id: person_id + '_' + kpi.key,
							data: data,
							type: 'bar',
							stack: 'Total' + person_id,
							name: kpi.label,
							label: {
								show: true
							},
						};
						if(index == 0) {
							var serie_points = {
								id: 'pt_' + person_id + '_' + kpi.key,
								data: _.map(data, function() { return null; }), 
								type: 'bar',
								stack: 'Total' + person_id,
								name: 'points',
								color: 'transparent'
							};
							serie_points.markPoint = {
								symbol: 'rect',
								symbolSize: 0,
								silent: true,
								data: points,
								label: {
									rotate: 45,
									color: '#000000',
									position: 'bottom',
									align: 'right'
								}
							}
							series.push(serie_points);
						}
						series.push(serie);

						index++;
					}.bind(this));

				}.bind(this));

			}



			var options =  {
				grid: {
					top: '10%',
					left: 60,
					right: '10%',
					bottom: 100
				},
				xAxis: {
					type: 'category',
					position: 'top',
					data: this.days,
					splitArea: {
						show: true
					}
				},
				yAxis: {
					type: 'value'
				},
				series: series,
				legend: {
					data: this.kpi_keys.map(function(item) { return item.label })
				},
			};

			if(this.level == 'team') {
				options.tooltip = {
					trigger: 'axis'
				}
			} else {
				options.tooltip = {
					trigger: 'item',
					formatter: function (params) {
						return `${this.getPerson(params.seriesId.split('_')[0]).cognome}<br>${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span>`;
					}.bind(this)
				}
			}

			return options;
			
		}
	},
	methods: {
		getPerson(person_id) {
			return this.results.raw_persone.find(item => item.id == person_id);
		}
	}
}

</script>