import * as types from '../types'

const state = {
    messages: [],
}

// getters
const getters = {
}

// actions
const actions = {
}

// mutations
const mutations = {
    [types.ALERTS_REMOVE] (state, message) {
        var i = state.messages.indexOf(message);
        state.messages.splice(i, 1);
    },

    [types.ALERTS_PUSH] (state, message) {
        state.messages.push(message);
    },
}

export default {
  state,
  getters,
  actions,
  mutations
}