<template>
	<div class="d-flex">
		<div style="max-height: 500px; overflow-y: auto">
			<b-table-simple style="min-width: 250px" small striped hover responsive>
				<b-thead>
					<b-tr>
						<b-th>Osservatore</b-th>
						<b-th class="text-center">M.Parole</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="item in osservatori" :variant="selected == item  ? 'info': ''" @click="onRowSelect(item)" style="cursor:pointer;">
						<b-td>{{ item.osservatore }}</b-td>	
						<b-td class="text-center text-muted small" style="vertical-align: middle">{{ item.parole }}</b-td>	
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
		<SingoloOsservatore class="ml-2" v-if="args.dettaglio" :id="args.dettaglio" :ds="ds"></SingoloOsservatore>
	</div>
</template>

<script>

import _ from 'lodash'
import SingoloOsservatore from "@/components/layout/report-scouting/DettaglioSingoloOsservatore.vue";

export default {
	props: [ 'ds', 'args', 'k' ],
	data: function() {
		return {
			selected: null,
			sort: false,
			key: 'year',
		}
	},
	methods: {
		onRowSelect: function(a) {
			this.args.dettaglio = a;
			this.selected = a;
		},
	},
	components: {
		SingoloOsservatore
	},
	computed: {
		dataset: function() {
			if(this.k == 'giocatori') {
				return _.filter(this.ds, function(a) {
					if(this.args.nazione == 'mondo') return true;
					if(a.nazione_osservato_code == this.args.nazione) return true;
					if(a.continente_osservato == this.args.nazione) return true;
				}.bind(this));
			} else {
				return _.filter(this.ds, function(a) {
					if(this.args.nazione == 'mondo') return true;
					if(a.nazione_home_code == this.args.nazione) return true;
					if(a.nazione_away_code == this.args.nazione) return true;
					if(a.continente_home == this.args.nazione) return true;
					if(a.continente_away == this.args.nazione) return true;
				}.bind(this));
			}
		},
		osservatori: function() {
			return _.orderBy(_.map(_.uniq(_.map(this.dataset, 'osservatore')), function(a) {
				return {
					osservatore: a,
					osservati: _.groupBy(_.filter(this.dataset, { osservatore: a }), 'raw_osservato_id'),
					partite: _.groupBy(_.filter(this.dataset, { osservatore: a }), 'raw_id_partita'),
					parole: _.round(_.sumBy(_.filter(this.dataset, { osservatore: a }), 'parole')/_.filter(this.dataset, { osservatore: a }).length,2),
				}
			}.bind(this)), [ this.key ], [ this.sort ? 'asc' : 'desc' ]);
		}
	}
}

</script>