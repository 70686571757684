<template>
	<div class="text-center font-weight-bold" style="min-width:100px">
		{{ Nord_MVC_Right_Left_Force }}
	</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	created: function() {


	},
	methods: {
		
	},
	watch: {
		dataLayer: {
			deep: true,
			handler: function() {

			}
		},
		Nord_MVC_Right_Left_Force: {
			immediate: true,
			handler: function() {
				this.test[this.field] = this.Nord_MVC_Right_Left_Force;
			}
		},
	},
	computed: {
		prefix: function() {
			if(this.field == 'Nord_MVC_Right_Left_Force') {
				return '';
			}
			return this.field.replace('Nord_MVC_Right_Left_Force', '');
		},
		Nord_MVC_Right_Left_Force: function() {
			var value = null;
			if(!isNaN(this.test[this.prefix + 'Nord_MVC_Left_Force']) && this.test[this.prefix + 'Nord_MVC_Left_Force'] != null && this.test[this.prefix + 'Nord_MVC_Left_Force'] != '' && !isNaN(this.test[this.prefix + 'Nord_MVC_Right_Force']) && this.test[this.prefix + 'Nord_MVC_Right_Force'] != null && this.test[this.prefix + 'Nord_MVC_Right_Force'] != '') {
				value = (parseFloat(this.test[this.prefix + 'Nord_MVC_Left_Force']) + parseFloat(this.test[this.prefix + 'Nord_MVC_Right_Force'])) / 2;
				value =  Math.round(value * 100) / 100;
			}
			return value;
		},		
	}
}

</script>