<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>File Browser</span>
					</div>
					<filemanager url="/filebrowser" theme="asrs"></filemanager>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
		filemanager: 		 require('../../layout/filemanager/Finder.vue').default,
	}

}
</script>
