<template id="partite">
	<section class="partite">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Partite ({{ total }})</span>
					</div>
					<div class="mb-1">
						<!--b-btn variant="primary" title="Aggiungi partita" class="mr-1" @click="nuovaPartita" v-if="auth.isUserEnable('scout_add_match')"><i class="fa fa-plus" aria-hidden="true"></i></b-btn-->
						<b-btn v-b-toggle.collapse variant="primary">Filtra <span class="when-opened"><i class="fa fa-arrow-up" aria-hidden="true"></i></span><span class="when-closed"><i class="fa fa-arrow-down" aria-hidden="true"></i></span></b-btn>
						<b-collapse id="collapse" class="mt-2">
							<b-row class="mb-1">
								<b-col sm="2">
									<b-form-group horizontal label="Data">
										<b-form-input type="date" v-model="filters.data"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col sm="2">
									<b-input-group>
										<b-form-input type="text" v-model="filters.squadra" placeholder="Squadra"></b-form-input>
										<b-input-group-append>
											<b-button variant="primary" @click="filtraPartite"><i class="fa fa-search" aria-hidden="true"></i></b-button>
										</b-input-group-append>
									</b-input-group>
								</b-col>
								<b-col sm="2" v-if="auth.isUserEnable('scout_utenti')">
									<b-form-select v-model="filters.osservatore">
										<option value="0">-- Osservatore --</option>
										<option v-for="item in osservatori" :value="item.id">{{item.nomeCompleto}}</option>
									</b-form-select>
								</b-col>	
							</b-row>
						</b-collapse>
					</div>
				</div></div>
				<v-infinite-scroll :loading="loading" @bottom="scrollPartite" :offset='20' style="max-height: 80vh; overflow-y: scroll;" class="row">
					<div v-for="partita in partite" class="col-6">
						<div class="card h-100">
							<div class="card-body">
								<div class="card-title mb-0">
									<span class="mr-2">{{ partita.competizione.paese.paese }} - {{ partita.competizione.nome }}</span> <i :class="partita.live ? 'fa fa-car' : 'fa fa-video-camera'" aria-hidden="true" :title="partita.live ? 'Live' : 'Video'"></i>
									<b-btn variant="primary" title="Elimina partita" class="ml-3" @click="eliminaPartita(partita.id)" v-if="auth.isUserEnable('scout_del_match')"><i class="fa fa-trash" aria-hidden="true"></i></b-btn>
								</div>
								<cardpartita :partita="partita" @aggiungiReport="aggiungiReport" @eliminaReport="eliminaReport" @zoomReport="zoomReport" @setGradimento="setGradimento"></cardpartita>
							</div>
						</div>
					</div>
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
				</v-infinite-scroll>	
				<b-modal id="matchModal" ref="matchModal" title="Aggiungi partita" ok-only ok-title="Chiudi">
					<b-form @submit="aggiungiPartita">
						<b-form-group id="competizioneGroup" label="Competizione">
							<b-form-input id="competizione" type="text" v-model="form.competizione"></b-form-input>
						</b-form-group>
						<b-form-group id="partitaGroup" label="Partita">
							<b-input-group>
								<b-form-input id="partita" type="text" v-model="form.partita" required></b-form-input>
								<b-input-group-append>
									<b-button variant="primary" @click="cercaPartita"><i class="fa fa-search" aria-hidden="true"></i></b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
						<div v-if="show_aree">
							<span>Seleziona un'area: </span>
							<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
								<b-row>
									<b-col cols="3" v-for="area in aree" style="padding: 0.2rem">
										<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.sigla3)">
											<flag :country="area.sigla" :size="24"></flag><br/>
											{{area.paese}}
										</b-card>
									</b-col>
								</b-row>
							</div>
						</div>
						<div v-if="show_competizioni">
							<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
							<div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
							<div v-if="competizioni.length > 0">
								<span>Seleziona una competizione: </span>
								<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
									<b-row>
										<b-col cols="4" v-for="comp in competizioni" style="padding: 0.2rem">
											<b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp)">
												<strong>{{comp.name}}</strong><br/>
												<span class="font-size-xs color-rosso">{{$i18n.t(comp.format)}}</span><br/>
											</b-card>
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
						<div v-if="show_matches">
							<div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
							<div v-if="!is_search && matches.length == 0"><h5>Nessuna partita trovata!</h5></div>
							<div v-if="matches.length > 0">
								<span>Seleziona una partita: </span>
								<div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
									<b-row class="py-1" v-for="partita in matches" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" @click="selezionaPartita(partita)">
										<b-col>
											<h5>{{ partita.nome_partita }}</h5>	
										</b-col>
										<b-col>
											{{ partita.data_partita + " " + partita.ora_partita  | formatDateHour }}
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
						<b-form-group id="dataGroup" label="Data / Ora">
							<b-row>
								<b-col cols="8">
									<b-form-input id="data" type="date" v-model="form.data_partita"></b-form-input>
								</b-col>
								<b-col>
									<b-form-input id="ora" type="time" v-model="form.ora_partita"></b-form-input>
								</b-col>
							</b-row>
						</b-form-group>
						<b-form-group id="modalitaGroup" label="Live" :label-cols="3" label-for="modalita">
							<b-form-radio-group id="modalita" v-model="form.live">
								<b-form-radio :value="1">Si</b-form-radio>
								<b-form-radio :value="0">No</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
						<b-button type="submit" variant="primary" class="mt-3">Salva</b-button>
					</b-form>	
				</b-modal>
				<b-modal id="playersModal" ref="playersModal" title="Aggiungi report" ok-only ok-title="Chiudi">	
					<b-row v-if="!loading">
						<b-col cols="12">
							<file-upload class="btn btn-success" v-model="files" :headers="headers" @input-file="inputFile" ref="upload" :accept="accept" :post-action="postAction">
								Seleziona report
							</file-upload>
							<div v-for="(file, index) in files" :key="file.id">
								<p> {{file.name}}</p>
								<div class="progress" v-if="file.active || (file.progress !== '0.00' && file.progress !== '100.00')">
									<div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
								</div>
							</div>
						</b-col>
						<b-col cols="12"><p>Seleziona uno o più giocatori a cui associare il report:</p></b-col>
						<b-col>
							<b-row v-for="player in home" class="align-items-center">
								<b-col cols="2">
									<b-form-checkbox v-model="giocatori" :value="player.wyId"></b-form-checkbox>
								</b-col>
								<b-col cols="10" class="text-center">
									<img :src="player.imageDataURL" width="60"/><br/>
									{{ player.shortName}}
								</b-col>
							</b-row>	
						</b-col>
						<b-col>
							<b-row v-for="player in away" class="align-items-center">
								<b-col cols="10" class="text-center">
									<img :src="player.imageDataURL" width="60"/><br/>
									{{ player.shortName}}
								</b-col>
								<b-col cols="2">
									<b-form-checkbox v-model="giocatori" :value="player.wyId"></b-form-checkbox>
								</b-col>	
							</b-row>
						</b-col>
					</b-row>
					<div v-if="loading" align="center" style="width:100%">
						<p>Carico formazioni giocatori...</p>
						<img src="/assets/varie/loading.gif" style="width: 150px;" />
					</div>	
					<div v-for="(file, index) in files" :key="file.id">
						<p> {{file.name}}</p>
						<div class="progress" v-if="file.active || (file.progress !== '0.00' && file.progress !== '100.00')">
							<div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
						</div>
					</div>					
					<b-button variant="primary" class="mt-3" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="startUpload">Upload</b-button>
					<b-button variant="primary" class="mt-3" v-if="!live" @click.prevent="salvaReportSenzaUpload">Salva senza report</b-button>	
				</b-modal>
				<b-modal id="reportModal" ref="reportModal" title="Report partita" ok-only ok-title="Chiudi">
					<div class="text-center">
						<div class="row align-items-center"><div class="col-8 text-center"><button type="button" class="btn btn-link" @click="prevPage">PREV</button><button type="button" class="btn btn-link" @click="nextPage">NEXT</button></div><div class="col-4 text-left" style="font-size:1rem;">{{currentPage}} di {{totalPages}}</div></div>
						<canvas id="pdf-canvas" width="450"></canvas>
					</div>
				</b-modal>
				<b-modal id="gradModal" ref="gradModal" title="Scegli gradimento" ok-only ok-title="Chiudi">
					<h5 v-if="report_giocatore">{{report_giocatore.giocatore.cognome}} {{report_giocatore.giocatore.nome}}</h5>
					<div class="text-center">
						<b-button-group>
							<b-button variant="outline-light" v-for="grad in gradimenti" :title="grad.nome" @click="settaGradimentoGiocatore(grad.id)"><img :src="'/assets/smiles/grad_' + grad.id + '.png'"/></b-button>
						</b-button-group>
					</div>
				</b-modal>
			</div>

			<footerapp></footerapp>

		</section>
	</template>

	<script>

	import moment from 'moment'

	import * as types from '../../../store/types'

	import InfiniteScroll from 'v-infinite-scroll'

	import FileUpload from 'vue-upload-component'

	var Auth = require('../../../auth.js');

	export default {

		data: function() {
			return {
				partite: [],
				filters: {
					data: '',
					squadra: '',
					osservatore: 0,
				},
				form: {
					wyscout_partita_id: null,
					partita: null,
					competizione: null,
					data_partita: null,
					ora_partita: null,
					live: 1,
				},
				aree: [],
				competizioni: [],
				matches: [],
				show_aree: false,
				show_competizioni: false,
				show_matches: false,
				is_search: false,
				osservatori: [],
				loading: false,
				total: 0,
				page: 1,
				errors: [],

				files: [],
				home: [], 
				away: [],
				giocatori: [],
				id_partita: 0,
				headers: {
					'tokenauth': 'Bearer ' + Auth.user.api_token
				},
				postAction: this.$store.state.apiEndPoint + '/scouting/upload/report/partita', 
				accept: 'application/pdf',
				pdfDoc: null,
				totalPages: 0,
				currentPage: 0,
				report_giocatore: null,
				gradimenti: [],
				gradimento: 0,
				live: 1,
			}
		},

		components: {
			headapp:             require('../../layout/headapp.vue').default,
			menusub:             require('../../layout/menusub.vue').default,
			footerapp:           require('../../layout/footerapp.vue').default,
			cardpartita:         require('../../layout/cardpartita.vue').default,
			flag:                require('../../layout/flag.vue').default,
			'v-infinite-scroll': InfiniteScroll,
			FileUpload,
		},

		created: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/osservatori').then((response) => {
				if (response.ok)
				{
					this.osservatori = response.data; 
				}
			}, (response) => {
            // error callback
        });
			this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
				if (response.ok)
				{
					this.aree = response.data; 
				}
			}, (response) => {
            // error callback
        });
			this.$http.get(this.$store.state.apiEndPoint + '/gradimenti').then((response) => {
				if (response.ok)
				{
					this.gradimenti = response.data; 
				}
			}, (response) => {
            // error callback
        });
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/old').then((response) => {
            // success callback
            if (response.ok)
            {
            	this.partite = response.data.data; 
            	this.total = response.data.total;
            	this.loading = false;
            }
        }, (response) => {
            // error callback
        }); 
		},
		methods: {
			scrollPartite() {
				if(this.partite.length < this.total) {
					this.page++;
					this.loading = true;
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/old?page=' + this.page, {params: this.filters}).then((response) => {
	            // success callback
	            if (response.ok)
	            {
	            	this.partite = this.partite.concat(response.data.data);
	            	this.loading = false;
	            }
	        }, (response) => {
	            // error callback
	        });
				}
			},
			filtraPartite() {
				this.loading = true;
				this.page = 1;
				this.$http.get(this.$store.state.apiEndPoint + '/scouting/partite/old', {params: this.filters}).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.partite = response.data.data;
            	this.total = response.data.total;
            	this.loading = false; 
            }
        }, (response) => {
            // error callback
        });
			},
			nuovaPartita() {		
				this.form.wyscout_partita_id = null;
				this.form.partita = null;
				this.form.competizione = null;
				this.form.data_partita = null;
				this.form.ora_partita = null;
				this.form.live = 1;
				this.competizioni = [];
				this.show_aree = false;
				this.show_competizioni = false;
				this.show_matches = false;
				this.$refs.matchModal.show();
			},
			cercaPartita() {
				this.show_aree = true;
				this.show_competizioni = false;
				this.show_matches = false;
			},
			selezionaArea: function(sigla) {
				this.show_aree = false;
				this.competizioni = [];
				this.is_search = true;
				this.show_competizioni = true;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competitions/' + sigla).then((response) => {

					if (response.ok)
					{
						this.competizioni = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			},
			selezionaCompetizione: function(competizione) {

				var data = 0;
				if(this.form.data_partita != null) {
					data = moment(this.form.data_partita + " 23:59:59")/1000;
				}
				this.show_competizioni = false;
				this.matches = [];
				this.is_search = true;
				this.show_matches = true;
				this.form.competizione = competizione.name;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/get/matches/by/competition/and/date/' + competizione.wyId + "/" + data).then((response) => {

					if (response.ok)
					{
						this.matches = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			},

			selezionaPartita: function(partita) {
				this.show_matches = false;
				this.form.partita = partita.nome_partita;
				this.form.data_partita = partita.data_partita;
				this.form.ora_partita = partita.ora_partita;
				this.form.wyscout_partita_id = partita.matchId;
			},

			aggiungiPartita(evt) {	
				evt.preventDefault();
				this.$http.post(this.$store.state.apiEndPoint + '/scouting/add/partita', this.form).then((response) => {
					if (response.ok)
					{
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.$refs.matchModal.hide();
						this.filtraPartite();
					}
				}, (response) => {  
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});	

			},

			eliminaPartita(id) {
				if(confirm("Confermi l'eliminazione della partita ?")) {
					this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/partita/' + id).then((response) => {
						if (response.ok)
						{
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
							this.filtraPartite();
						}
					}, (response) => {
					});
				}
			},

			inputFile(newFile, oldFile) {
				if (newFile && oldFile) {
	        // update
	        if (newFile.active && !oldFile.active) {
	          // beforeSend
	          // min size
	     /*   if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
	            this.$refs.upload.update(newFile, { error: 'size' })
	        }  */
	    }
	    if (newFile.progress !== oldFile.progress) {
	          // progress
	      }
	      if (newFile.error && !oldFile.error) {
	          // error
	      }
	      if (newFile.success && !oldFile.success) {
	      	this.$refs.playersModal.hide();
	      	this.$store.commit(types.ALERTS_PUSH, { msg: newFile.response.message, status: 1 });
	      	this.filtraPartite();
	          // success
	      }
	  }
	  if (!newFile && oldFile) {
	        // remove
	        if (oldFile.success && oldFile.response.id) {
	          // $.ajax({
	          //   type: 'DELETE',
	          //   url: '/upload/delete?id=' + oldFile.response.id,
	          // })
	      }
	  }
	},
	aggiungiReport(id_partita, wyscout_partita_id, live) {
		this.files = [];
		this.giocatori = [];
		this.home = []; 
		this.away = [];
		this.id_partita = 0;
		this.live = 1;
		this.loading = true;
		this.$refs.playersModal.show();
		this.$http.get(this.$store.state.apiEndPoint + '/ws/get/formations/match/' + wyscout_partita_id).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.loading = false;
            	this.home = response.data.home;
            	this.away = response.data.away;
            	this.id_partita = id_partita;
            	this.live = live;
            }
        }, (response) => {
            // error callback
        });
	},
	startUpload() {
		if(this.files.length > 0) {
			this.files[0].data = {
				'id_partita' : this.id_partita,
				'id_ws_players': this.giocatori,
				'live': this.live,
			};
			this.$refs.upload.active = true;
		}
	},
	salvaReportSenzaUpload() {
		var formData = new FormData();
		formData.append("id_partita", this.id_partita);
		formData.append("id_ws_players", this.giocatori);
		formData.append("live", this.live);
		this.$http.post(this.$store.state.apiEndPoint + '/scouting/upload/report/partita', formData).then((response) => {
			if (response.ok)
			{
				this.$refs.playersModal.hide();
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				this.filtraPartite();
			}
		}, (response) => {  
			if (response.status == 422) {
			}
		});
	},
	eliminaReport(id) {
		if(confirm("Confermi l'eliminazione del report partita ?")) {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/elimina/report/partita/' + id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.filtraPartite();
				}
			}, (response) => {
	            // error callback
	        });
		}
	},
	zoomReport: function(pdf, pages) {
		this.pdfDoc = pdf;
		this.totalPages = pages;
		this.currentPage = 0;
		this.showPage(1);
		this.$refs.reportModal.show();

	},
	showPage(page_no) {
		this.currentPage = page_no;
		this.pdfDoc.getPage(page_no).then((page) => {
			var desiredWidth = document.getElementById('pdf-canvas').width;
			var viewport = page.getViewport(1);
			var scale = desiredWidth / viewport.width;
			var scaledViewport = page.getViewport(scale);
			document.getElementById('pdf-canvas').height = scaledViewport.height;
			var renderContext = {
				canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
				viewport: scaledViewport
			};
			page.render(renderContext).then(() => {
			});
		});
	},
	prevPage() {
		if(this.currentPage != 1) {
			this.showPage(this.currentPage - 1);
		}    
	},
	nextPage() {
		if(this.currentPage != this.totalPages) {
			this.showPage(this.currentPage + 1);
		}    
	},
	setGradimento(report_giocatore) {
		this.report_giocatore = report_giocatore;
		this.$refs.gradModal.show();         	
	},
	settaGradimentoGiocatore(id_grad) {	
		this.$refs.gradModal.hide();   
		this.$http.get(this.$store.state.apiEndPoint + '/scouting/set/gradimento/report/giocatore/' + this.report_giocatore.id + "/" + id_grad).then((response) => {
			if (response.ok)
			{
				this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
				this.filtraPartite();
			}
		}, (response) => {
	            // error callback
	        });      	
	},
},
watch: {
	'filters.data': function () {
		this.filtraPartite();
	},
	'filters.osservatore': function () {
		this.filtraPartite();
	},
},
filters: {
	formatDateHour: function (date) {
		var m = moment(date);
		if(m.isValid()) {
			return m.format('DD/MM/YYYY HH:mm'); 
		}
		return "";
	},
},
computed: {
	auth: function() {
		return Auth;
	},
},
}
</script>