<template id=" acad_val_new">
	<section class="acad_val_new">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="card-title">
					<span>New request</span>
				</div>
				<div class="content">
					<b-row align-v="center">
						<b-col>
							<form @submit="onSubmit">
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Department: 
									</b-col>
									<b-col cols="2">	
										<b-form-select v-model="form.department" :options="options_department" :required="true"></b-form-select>
									</b-col>
									<b-col>
										<b-form-input v-model="form.department_text" placeholder="Tipe here more specification" :required="form.department == 6 ? true : false"></b-form-input>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Content: 
									</b-col>
									<b-col cols="2">	
										<b-form-select v-model="form.content" :options="options_content" :required="true"></b-form-select>
									</b-col>
									<b-col>
										<b-form-input v-model="form.content_text" placeholder="Tipe here more specification" :required="true"></b-form-input>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Required by: 
									</b-col>
									<b-col>
										<b-form-input v-model="form.required_by" placeholder="es. Sponsor name, etc..."></b-form-input>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Period: 
									</b-col>
									<b-col>
										<b-form-input v-model="form.period" placeholder="es. First week of February, Within the month of..."></b-form-input>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Location: 
									</b-col>
									<b-col cols="2">	
										<b-form-select v-model="form.location" :options="options_location"></b-form-select>
									</b-col>
									<b-col>
										<b-form-input v-model="form.location_text" placeholder="es. Sala Champions, Campo, etc..."></b-form-input>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Player/s: 
									</b-col>
									<b-col cols="2">	
										<strong><em>Desiderata</em></strong>
									</b-col>
									<b-col>
										<multiselect v-model="form.players_desiderata" track-by="id" label="name" :options="players" :multiple="true" :searchable="true" placeholder="Select player/s" :max="4"></multiselect>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
									</b-col>
									<b-col cols="2">	
										<strong><em>Alternative</em></strong>
									</b-col>
									<b-col>
										<multiselect v-model="form.players_alternative" track-by="id" label="name" :options="players" :multiple="true" :searchable="true" placeholder="Select player/s" :max="4"></multiselect>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Timing: 
									</b-col>
									<b-col>
										<b-form-input v-model="form.timing" placeholder=" es. 10 minutes, 20 minutes, ..."></b-form-input>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Dress code: 
									</b-col>
									<b-col>
										<b-form-input v-model="form.dress_code" placeholder="es. Home kit, training kit, etc..."></b-form-input>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Description: 
									</b-col>
									<b-col>
										<b-form-textarea
										id="textarea"
										v-model="form.description"
										rows="3"
										max-rows="6"
										placeholder="All the event details"
										></b-form-textarea>
									</b-col>
								</b-row>
								<b-row align-v="center" class="mb-2">
									<b-col cols="2">
										Note: 
									</b-col>
									<b-col>
										<b-form-input v-model="form.note" placeholder="es. English speaker, no Adidas player, ..."></b-form-input>
									</b-col>
								</b-row>
								<fieldset v-show="auth.isUserEnable('egnyte')">
									<b-row>
										<b-col cols="2">
											Attachments:
										</b-col>
										<b-col cols="10">
											<b-form-group id="fileGroup">
												<b-form-row v-for="(item, index) in form.files" :key="index" class="mb-3 pt-3 border-bottom">
													<b-col cols="12">
														<b-row>
															<b-col v-if="item.path">
																{{ getNomeFile(item.path) }}
																<b-button class="ml-1" variant="primary" @click="downloadFile(item.id)" title="View file"><i class="fa fa-search" aria-hidden="true"></i></b-button>
															</b-col>
															<b-col>
																<b-form-group horizontal label="New file" :label-cols="3">
																	<b-form-file
																	v-model="item.file"
																	:state="Boolean(item.file)"
																	placeholder="Select a file to upload..."
																	drop-placeholder="Cancel file..."
																	></b-form-file>
																</b-form-group>
															</b-col>
															<b-col cols="2">
																<b-button variant="danger" @click="removeFile(index)"><i class="fa fa-trash fa-fw"></i> Remove file</b-button>
															</b-col>
														</b-row>
													</b-col>
												</b-form-row>

												<b-button variant="outline-primary" @click="addFile"><i class="fa fa-plus fa-fw"></i> Add file</b-button>
											</b-form-group>
										</b-col>
									</b-row>
								</fieldset>
								<b-button type="submit" variant="primary" class="mt-1 text-uppercase">Save</b-button>
							</form>
						</b-col>
					</b-row>
					<b-modal id="waitingModal" ref="waitingModal" ok-only>
						<div align="center">
							<h4>Wait for data saving...</h4>
							<img src="/assets/varie/loading.gif" style="width: 150px;" />
						</div>
					</b-modal>
				</div>	
			</div>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
var Auth = require('../../../auth.js');
export default {

	data: function() {
		return {
			players: [],
			form: {
				department: 0,
				department_text: null,
				content: 0,
				content_text: null,
				required_by: null,
				period: null,
				location: 0,
				location_text: null,
				players_desiderata: [],
				players_alternative: [],
				timing: null,
				dress_code: null,
				description: null,
				note: null,
				files: [],
			},
			options_department: [
			{ value: null, text: '-- Select --'}, 
			{ value: 1, text: 'Communication'},
			{ value: 2, text: 'Partnerships'},
			{ value: 3, text: 'Brand'},
			{ value: 4, text: 'Roma Department'},
			{ value: 5, text: 'Digital'},
			{ value: 7, text: 'Sustainability Department'},
			{ value: 6, text: 'Other'}
			],
			options_content: [
			{ value: null, text: '-- Select --'}, 
			{ value: 1, text: 'Interviews'},
			{ value: 2, text: 'Shootings (foto&video)'},
			{ value: 3, text: 'Events'},
			{ value: 4, text: 'Appereances'},
			{ value: 5, text: 'Other'}
			],
			options_location: [
			{ value: 0, text: '-- Select --'}, 
			{ value: 1, text: 'Internal'},
			{ value: 2, text: 'External'}
			],
		}
	},

	components: {
		headapp: require('../../layout/headapp.vue').default,
		menusub: require('../../layout/menusub.vue').default,
		footerapp: require('../../layout/footerapp.vue').default,
	},

	created: function () {
		this.getPlayers();
	},

	computed: {
		auth: function() {
			return Auth;
		},

		user: function() {
			return Auth.user;
		},
	},

	methods: {
		onSubmit(evt) {
			evt.preventDefault();
			this.addRequest();
		},

		addRequest() {
			var formData = new FormData();
			formData.append("form", JSON.stringify(this.form));

			for(var i in this.form.files) {
				formData.append("files_request_" + i, this.form.files[i].file);
			}
			this.$refs.waitingModal.show();
			this.$http.post(this.$store.state.apiEndPoint + '/playeraccess/richiesta/add', formData).then((response) => {
				if (response.ok)
				{
					this.$refs.waitingModal.hide();
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: "player_access_richieste"});
					
				}
			}, (response) => {  
				if (response.status == 422) {
					this.$refs.waitingModal.hide();
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		addFile() {
			this.form.files.push({ path: null, file: ''});
		},

		removeFile(index) {
			this.form.files.splice(index, 1);
		},

		getPlayers: function() {				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: 13 }}).then((response) => {
				if (response.ok)
				{
					this.players = response.data.map(item => ({								
						name: item.cognome + ' ' + item.nome,
						id: item.id,
					}));
					this.getStaff();
				}
			}, (response) => {
			});
		},

		getStaff: function() {				
			this.staff = [];
			this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: 13 }}).then((response) => {
				if (response.ok)
				{
					var members = response.data;
					for(var i in members) {
						var member = members[i];
						if(member.last_carriera_sportiva.ruoli && member.last_carriera_sportiva.ruoli.length > 0 && (member.last_carriera_sportiva.ruoli[0].persona_ruolo_glo_id == 46 || member.last_carriera_sportiva.ruoli[0].persona_ruolo_glo_id == 29)) {
							this.players.unshift({name: member.cognome + ' ' + member.nome, id: 's' + member.id });
						}
					}
					this.players.unshift({name: 'Team', id: -1});
				}
			}, (response) => {
			});
		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
