<template id="linechart">

	<canvas :id="lineid" :height="hchart" :width="wchart"></canvas>

</template>

<script>
export default {
	props: {

		lineid: {
			type: String,
			required: true

		},

		datachart: {
			type: Object,
			required: false
		},
		wchart: {
			type: Number,
			default: 100
		},

		hchart: {
			type: Number,
			default: 25
		},
		ymax: {
			type: Number,
			default: 5
		}, 

		ymin: {
			type: Number,
			default: -5
		}
	},

	mounted: function(){

		var ctx = document.getElementById(this.lineid);
		this.myChart = new Chart(ctx, {

			type: 'line',

			data: this.datachart,

			options: {
				scales: {
					xAxes: [{
					}],
					yAxes: [{
						ticks: {
							min: this.ymin,
							max: this.ymax
						}
					}]
				}     
			}
		});
	},
	watch: {
		datachart: function() {
			this.myChart.data.labels = this.datachart.labels;
			this.myChart.data.datasets = this.datachart.datasets;
			this.myChart.update();			
		},
	},

}
</script>
