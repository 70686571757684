<template>
    <div class="alert-messages">
        <alert v-for="message, index in messages" :message="message" :key="index"></alert>
    </div>
</template>

<script>

export default {
    name: 'alerts',
    computed: {
        messages: function() {
            return this.$store.state.alerts.messages;
        }
    },
    components: {
        'alert': require('./alert.vue').default,
    },
}

</script>