<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<b-card no-body class="border-0">
					<b-embed type="iframe"
					src="https://577ce8085324d.streamlock.net:8443/ASRCameraControl2/"
					allowfullscreen
					></b-embed>
			</b-card>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
	}

}
</script>
