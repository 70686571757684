<template>
  <b-row class="mt-2">

    <b-col cols="1">
      <b-button
        variant="primary"
        :class="['w-100 result-positive', newTag && newTag.result === 'positive' ? 'active' : '']"
        @click="setNewTagField('result', 'positive')">
        +
      </b-button>
    </b-col>

    <b-col cols="1">
      <b-button
        variant="primary"
        :class="['w-100 result-negative', newTag && newTag.result === 'negative' ? 'active' : '']"
        @click="setNewTagField('result', 'negative')">
        -
      </b-button>
    </b-col>

    <b-col cols="1">
      <b-button
        variant="primary"
        :class="['w-100 type-defensive', newTag && newTag.type === 'defensive' ? 'active' : '']"
        @click="setNewTagField('type', 'defensive')">
        DEF
      </b-button>
    </b-col>
    <b-col cols="1">
      <b-button
        variant="primary"
        :class="['w-100 type-offensive', newTag && newTag.type === 'offensive' ? 'active' : '']"
        @click="setNewTagField('type', 'offensive')">
        OFF
      </b-button>
    </b-col>

    <b-col v-for="team in teams" :key="team.id" cols="1">
      <team-dropdown :team="team" />
    </b-col>

    <b-col cols="1">
      <b-button
        variant="primary"
        :class="[isRecording && 'active', 'w-100 rec-button']"
        @click="toggleRecording">
        <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
      </b-button>
    </b-col>

    <b-col cols="1">
      <b-button
        variant="primary"
        class="w-100 playback-light"
        @click="rewind">-10 sec</b-button>
    </b-col>

    <b-col cols="1">
      <b-button
        :pressed="currentPlaybackRate === 0.5"
        variant="primary"
        class="w-100 playback-light"
        @click="changePlaybackRate(0.5)">0.5x</b-button>
    </b-col>

    <b-col v-for="rate in [1, 2]" :key="rate" cols="1">
      <b-button
        :pressed="currentPlaybackRate === rate"
        variant="primary"
        class="w-100 playback-dark"
        @click="changePlaybackRate(rate)">{{ rate }}x</b-button>
    </b-col>

    <b-col cols="1">
      <b-button variant="primary" class="w-100" @click="exitFullScreen()">
        Esci
      </b-button>
    </b-col>

  </b-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

import TeamDropdown from './TeamDropdown.vue';

export default {
  name: 'NewTagFullscreen',
  components: { TeamDropdown },

  computed: {
    ...mapGetters(['teams']),
    ...mapState(['newTag', 'isRecording', 'currentPlaybackRate']),
  },

  methods: {
    setNewTagField(key, value) {
      this.$store.commit('updateTagField', { key, value });
    },

    setTeam(team) {
      this.$store.commit('updateTagField', {
        key: 'team',
        value: _.pick(team, ['id', 'name']),
      });

      this.$store.dispatch('saveTag', true);
    },

    exitFullScreen() {
      this.$store.commit('exitFullScreen');
    },

    toggleRecording() {
      this.$store.dispatch('toggleRecording');
    },

    rewind() {
      this.$root.$emit('updateCurrentTime', -10);
    },

    changePlaybackRate(rate) {
      this.$store.commit('changePlaybackRate', rate);
    },
  },
};
</script>

<style lang="scss">
.btn-primary {
  &.result-positive {
    background-color: #58AB86 !important;
    border-color: #58AB86 !important;

    &.active, &:hover, &:active {
      background-color: darken(#58AB86, 20%) !important;
      border-color: darken(#58AB86, 20%) !important;
    }

    &:active:focus, &.active:focus, &:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 171, 134, 0.5) !important;
    }
  }

  &.type-offensive {
    background-color: #EF8629 !important;
    border-color: #EF8629 !important;

    &.active, &:hover, &:active {
      background-color: darken(#EF8629, 20%) !important;
      border-color: darken(#EF8629, 20%) !important;
    }

    &:active:focus, &.active:focus, &:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 134, 41, 0.5) !important;
    }
  }

  &.type-defensive {
    background-color: #F8B431 !important;
    border-color: #F8B431 !important;

    &.active, &:hover, &:active {
      background-color: darken(#F8B431, 20%) !important;
      border-color: darken(#F8B431, 20%) !important;
    }

    &:active:focus, &.active:focus, &:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 180, 49, 0.5) !important;
    }
  }
}
</style>
