import axios from 'axios';

export default {
  filter({ state, commit }) {
    commit('loading', true);

    axios.post('/partite', state.filters || {})
      .then((res) => {
        commit('setMatches', res.data.results);
        commit('setPlayers', res.data.players);
        commit('setMatchesByCity', res.data.results2);
        commit('loading', false);
      })
      .catch(() => { commit('loading', false); });
  },
};
