<template>
	<div>
		<label>Nome</label>
		<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>
		<b-form-group class="mt-2" label="Sorgente del documento">
			<b-form-radio-group v-model="fase.entity.sorgente" :disabled="fase.entity.id  && fase.entity.id > 0">
				<b-form-radio value="upload">Upload</b-form-radio>
				<b-form-radio value="filebrowser">Pre/Post Partita</b-form-radio>
			</b-form-radio-group>
		</b-form-group>
		<div v-if="fase.entity.sorgente == 'upload'">
			<div v-if="!fase.entity.id" class="color-rosso">
				Devi prima salvare la fase per poter uplodare un documento!
			</div>
			<div v-if="fase.entity.id" :key="'doc_' + random">
				<DocumentiManager v-if="!fase.entity.documento_rel" :collapsed="false" entity="Fase_Documento" :entityId="fase.entity.id" :tipoId="1" path="" :readonly="!isManagerSessione || faseStato.length == 0" @getDocs="getDocs"></DocumentiManager>
				<div v-else>
					<b-card class="mb-2">
						<b-row>
							<b-col cols="6">
								<b-img v-if="isFileImage" :src="file.url" fluid></b-img>
								<div class="text-center" v-if="pdfDoc">
									<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPagePdf" :per-page="1" @change="showPage" :limit="20">
									</b-pagination>
									<canvas id="pdf-canvas"  width="500"></canvas>
									<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPagePdf" :per-page="1" @change="showPage" :limit="20">
									</b-pagination>
								</div>
							</b-col>
							<b-col class="ml-3">
								<h5>{{ fase.entity.documento_rel.documento.nome ? fase.entity.documento_rel.documento.nome : 'Documento' }}</h5>
								<p class="card-text">
							<span class="color-rosso font-bold"> {{ fase.entity.documento_rel.documento.path.split('/')[2] }} </span>
							</p>
							</b-col>
						</b-row>
					</b-card>
				</div>
			</div>
		</div>
		<div v-if="fase.entity.sorgente == 'filebrowser'">
			<div v-if="!fase.entity.filebrowser_url">
				Seleziona partita
				<b-form-select v-model="partita_id" size="sm" class="mb-1">
					<option value="null">-- partite --</option>
					<option v-for="item in partite" :value="item.id" :key="item.id">
						{{ item.nome }}
					</option>
				</b-form-select>
				Pre/post partita
				<b-form-select v-model="fase.entity.filebrowser_path" size="sm" class="mb-1">
					<option value="" disabled>-- pre partita --</option>
					<option v-for="item in files_pre" :value="item.path">
						{{ item.basename }}
					</option>
					<option value="" disabled>-- post partita --</option>
					<option v-for="item in files_post" :value="item.path">
						{{ item.basename }}
					</option>
				</b-form-select>
			</div>
			<div v-else>
				<b-card class="mb-2">
					<b-row>
						<b-col cols="6">
							<b-img v-if="fase.entity.filebrowser_mimetype.includes('image/')" :src="fase.entity.filebrowser_url" fluid></b-img>
							<div class="text-center" v-if="pdfDoc">
								<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPagePdf" :per-page="1" @change="showPage" :limit="20">
								</b-pagination>
								<canvas id="pdf-canvas"  width="500"></canvas>
								<b-pagination size="sm" align="center" :total-rows="totalPagesPdf" v-model="currentPagePdf" :per-page="1" @change="showPage" :limit="20">
								</b-pagination>
							</div>
						</b-col>
						<b-col class="ml-3">
							<h5>{{ filename }}</h5>
							<p class="card-text">
								<span class="color-rosso font-bold"> {{ partita_sel ? partita_sel.nome : '' }} </span>
							</p>
							<p class="card-text">
								<span class="font-italic"> {{ root == 'pre' ? 'Pre partita' : 'Post partita'}} </span>
							</p>
						</b-col>
					</b-row>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [faseMixin],
	props: [ 'sessione', 'fase', 'disabled', 'isManagerSessione' ],
	data: function(){
		return {
			partite: [],
			partita_id: null,
			files_pre: [],
			files_post: [],
			root: '',
			filename: '',

			pdfDoc: null,
			totalPagesPdf: 0,
			currentPagePdf: 0,
			random: Math.random(),
			file: null,
		}
	},
	created: function () {
		if(this.fase.entity.sorgente == 'filebrowser') {
			this.getPartite();
		}
		if(this.fase.entity.filebrowser_path && this.fase.entity.filebrowser_path != '') {
			var array = this.fase.entity.filebrowser_path.split('/'); 
			this.partita_id = array[2];
			this.root = array[3];
			this.filename = array[4];
		}
		if(this.fase.entity.filebrowser_mimetype 
			&& this.fase.entity.filebrowser_mimetype.includes('application/pdf') 
			&& this.fase.entity.filebrowser_url) {
			PDFJS.getDocument(this.fase.entity.filebrowser_url).then((pdf_doc) => {
				this.totalPagesPdf = pdf_doc.numPages;
				this.pdfDoc = pdf_doc;
				this.currentPagePdf = 0;
				this.showPage(1);
			});
	}
	if(this.fase.entity.sorgente == 'upload' && this.fase.entity.documento_rel) {
		this.getFile();
	}
},
computed: {
	auth: function() {
		return Auth;
	},
	user: function() {
		return Auth.user;
	},
	partita_sel: function() {
		if(this.partite.length > 0 && this.partita_id) {
			return this.partite.find(item => item.id == this.partita_id);
		}
		return null;
	},
	faseStato: function() {
		return _.filter(this.fase.players, function(a) {
			return a.inPhase == true && (a.activity_check.checked == 1 || a.activity_check.checked == null);
		});
	},
	isFileImage: function() {
		if(this.contentType.includes('image/')) {
			return true;
		}
		return false;	
	},
	isFilePdf: function() {
		if(this.contentType.includes('application/pdf')) {
			return true;
		}
		return false;	
	},
	contentType: function() {
		if(this.file) {
			var mimetype = this.file.headers.map['content-type'][0];
			return mimetype;
		}
		return '';	
	},
	...mapState(['rosaTeamId', 'seasonId']),
},
methods: {
	getPartite() {
		if(this.rosaTeamId && this.seasonId) {
			const formData = new FormData();
			formData.append("team_id", this.rosaTeamId);
			formData.append("season_id", this.seasonId);
			this.$http.post(this.$store.state.apiEndPoint + '/football/partite', formData).then((response) => {
				if (response.ok)
				{
					this.partite = response.data.map(item => ({
						nome: item.tipo_categoria.tipo.nome == 'Nazionale' ? '['+ item.tipo_categoria.tipo.nome + '] ' + item.home_team + ' VS ' + item.away_team : '['+ item.tipo_categoria.tipo.nome + '] ' + item.home.name + ' VS ' + item.away.name + ' - ' + moment(item.data).format('DD/MM/YYYY HH:mm'),
						id: item.id,
					}));
				}
			}, (response) => {

			});
		}
	},

	getFilesPreSpostPartita() {
		if(this.partita_id) {
			this.$http.get(this.$store.state.apiEndPoint + '/prepost/match/files/' + this.partita_id + '/file-image,file-pdf').then((response) => {
				if (response.ok)
				{
					this.files_pre = response.data.files_pre;
					this.files_post = response.data.files_post;
				}
			}, (response) => {

			});
		}
	},

	getFile() {
		if(this.fase.entity.documento_rel) {
			this.$http.get(this.$store.state.apiEndPoint + '/documenti/download/' + this.fase.entity.documento_rel.id + '/' + this.user.id + '/' + this.user.api_token).then((response) => {
				if (response.ok)
				{
					this.file = response;
					if(this.isFilePdf) {
						PDFJS.getDocument(this.file.url).then((pdf_doc) => {
							this.totalPagesPdf = pdf_doc.numPages;
							this.pdfDoc = pdf_doc;
							this.currentPagePdf = 0;
							this.showPage(1);
						});
					}					
				}
			}, (response) => {

			});
		}
	},
	
	showPage(page_no) {
		this.currentPagePdf = page_no;
		this.pdfDoc.getPage(page_no).then((page) => {
			var desiredWidth = document.getElementById('pdf-canvas').width;
			var viewport = page.getViewport(1);
			var scale = desiredWidth / viewport.width;
			var scaledViewport = page.getViewport(scale);
			document.getElementById('pdf-canvas').height = scaledViewport.height;
			var renderContext = {
				canvasContext: document.getElementById('pdf-canvas').getContext('2d'),
				viewport: scaledViewport
			};
			page.render(renderContext).then(() => {
			});
		});
	},

	prevPage() {
		if(this.currentPagePdf != 1) {
			this.showPage(this.currentPagePdf - 1);
		}    
	},
	
	nextPage() {
		if(this.currentPagePdf != this.totalPagesPdf) {
			this.showPage(this.currentPagePdf + 1);
		}    
	},
	getDocs(docs) {
		this.fase.entity.documento_rel = docs[0];
		this.getFile();
		this.random = Math.random();
	}

},
watch: {
	'fase.entity.sorgente': function() {
		if(this.fase.entity.sorgente == 'filebrowser' && this.partite.length == 0) {
			this.getPartite();
		}
	},

	partita_id: function() {
		if(this.partita_id != 'null') {
			this.getFilesPreSpostPartita();
		} else {
			this.files_pre = [];
			this.files_post = [];
			this.fase.entity.filebrowser_path = null;
		}
	},
},
components: {
	DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
},
}
</script>