<template>
	<div>
		<h5 class="color-rosso">Giocatori</h5>
		<b-card class="mb-1" v-for="i in giocatori" :title="i.name">
			<b-row>
				<b-col sm="2" class="mb-1" v-for="player in i.players">
					<b-card no-body class="p-2 h-100"> 
						<div class="text-center">
							<router-link :to="'/next/'+area+'/view/giocatore/' + player.osservato.id" target="_blank" v-if="player.osservato" style="position:absolute;width:120px;height:120px;"></router-link>
							<b-img :src="player.imageDataURL" :alt="player.shortName" :height="120"/>
							<div class="mt-1">
								<span>{{ player.shortName }}</span>
								<br/>
								<small class="text-muted"><span>('{{ player.birthDate | formatYear }})</span> <span>POR</span></small>
								<br/>
								<flag v-if="player.birthArea && player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="16"></flag>
								<flag v-if="player.birthArea && player.passportArea && player.passportArea.alpha2code != player.birthArea.alpha2code" :country="player.passportArea.alpha2code" :size="16"></flag>	
							</div>
							<b-row v-if="player.osservato" no-gutters>
								<b-col :cols="12/4" title="A"><b-badge variant="bg-1" :style="da_prendere_style(player.osservato.valutazioni)">{{ da_prendere(player.osservato.valutazioni) }}</b-badge></b-col>
								<b-col :cols="12/4" title="B+"><b-badge variant="bg-2" :style="monitorare_con_interesse_style(player.osservato.valutazioni)">{{monitorare_con_interesse(player.osservato.valutazioni)}}</b-badge></b-col>
								<b-col :cols="12/4" title="B"><b-badge variant="bg-3" :style="da_monitorare_style(player.osservato.valutazioni)">{{da_monitorare(player.osservato.valutazioni)}}</b-badge></b-col>
								<b-col :cols="12/4" title="C"><b-badge variant="bg-5" :style="negativo_style(player.osservato.valutazioni)">{{negativo(player.osservato.valutazioni)}}</b-badge></b-col>
							</b-row>
							<div v-else class="row no-gutters text-center mt-1"></div>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import moment from 'moment'

export default {
	mixins: [ pageBase ],
	components: {
		flag: require('@/components/layout/flag.vue').default,
	},
	props: ['players'],
	filters: {
		formatYear: function(date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('YY'); 
			}
			return "";
		}
	},
	data: function() {
		return {
			opacity_value: [1, 0.8, 0.7, 0.6, 0.5],
		}
	},
	methods: {

		da_prendere(valutazioni) {
			var count = 0;
			for (var index in valutazioni) {
				if(valutazioni[index].id_gradimento == 1) { 
					count++;
				}
			}
			return count;
		},

		monitorare_con_interesse(valutazioni) {
			var count = 0;
			for (var index in valutazioni) {
				if(valutazioni[index].id_gradimento == 2) { 
					count++;
				}
			}
			return count;
		},

		da_monitorare(valutazioni) {
			var count = 0;
			for (var index in valutazioni) {
				if(valutazioni[index].id_gradimento == 3) { 
					count++;
				}
			}
			return count;
		},

		negativo(valutazioni) {
			var count = 0;
			for (var index in valutazioni) {
				if(valutazioni[index].id_gradimento == 5) { 
					count++;
				}
			}
			return count;
		},

		opacity(valutazioni) {
			var count_grad = [0, 0, 0, 0, 0];
			var array_index = [0, 1, 2, 3, 4];
			var count = 0;
			for (var index in valutazioni) {
				if(valutazioni[index].id_gradimento == 1) { 
					count_grad[0]++;
				}
				else if(valutazioni[index].id_gradimento == 2) { 
					count_grad[1]++;
				}
				else if(valutazioni[index].id_gradimento == 3) { 
					count_grad[2]++;
				}
				else if(valutazioni[index].id_gradimento == 4) { 
					count_grad[3]++;
				}
				else if(valutazioni[index].id_gradimento == 5) { 
					count_grad[4]++;
				}
			}
			var opacity = [
			{index: 0, grad: count_grad[0]},
			{index: 1, grad: count_grad[1]},
			{index: 2, grad: count_grad[2]},
			{index: 3, grad: count_grad[3]},
			{index: 4, grad: count_grad[4]}
			];

			opacity.sort(function(a, b) {
				return b.grad > a.grad;
			});

			var array_index = [];
			for (var index in opacity) {
				array_index.push(opacity[index].index);
			}
			return array_index;
		},

		da_prendere_style(valutazioni) {
			//console.log(valutazioni);
			if(this.da_prendere(valutazioni) > 0) {
				return {
					'padding-bottom': (0.5 + (0.1 * this.da_prendere(valutazioni))) + 'em',
					'opacity': this.opacity_value[this.opacity(valutazioni).indexOf(0)],
				}
			} else {
				return {
					'opacity': '0.3',
				}
			}
		},

		monitorare_con_interesse_style(valutazioni) {
			if(this.monitorare_con_interesse(valutazioni) > 0) {
				return {
					'padding-bottom': (0.5 + (0.1 * this.monitorare_con_interesse(valutazioni))) + 'em',
					'opacity': this.opacity_value[this.opacity(valutazioni).indexOf(0)],
				}
			} else {
				return {
					'opacity': '0.3',
				}
			}
		},

		da_monitorare_style(valutazioni) {
			if(this.da_monitorare(valutazioni) > 0) {
				return {
					'padding-bottom': (0.5 + (0.1 * this.da_monitorare(valutazioni))) + 'em',
					'opacity': this.opacity_value[this.opacity(valutazioni).indexOf(0)],
				}
			} else {
				return {
					'opacity': '0.3',
				}
			}
		},

		negativo_style(valutazioni) {
			if(this.negativo(valutazioni) > 0) {
				return {
					'padding-bottom': (0.5 + (0.1 * this.negativo(valutazioni))) + 'em',
					'opacity': this.opacity_value[this.opacity(valutazioni).indexOf(0)],
				}
			} else {
				return {
					'opacity': '0.3',
				}
			}
		},
	},
	computed: {
		giocatori: function() {

			return [ 
				{ name: 'Portiere', players: this.portieri }, 
				{ name: 'Difensore', players: this.difensori }, 
				{ name: 'Centrocampista', players: this.centrocampisti }, 
				{ name: 'Attaccanti', players: this.attaccanti }
			];

		},
		area: function() {
			return this.$route.meta.area;
		},
		portieri: function() {
			return this.players.filter(item => item.role.code3 == 'GKP');
		},
		difensori: function() {
			return this.players.filter(item => item.role.code3 == 'DEF');
		},
		centrocampisti: function() {
			return this.players.filter(item => item.role.code3 == 'MID');
		},
		attaccanti: function() {
			return this.players.filter(item => item.role.code3 == 'FWD');
		},
	}
}

</script>