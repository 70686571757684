<template>
	<div>
		<b-row class="my-2">
			<b-col sm="6">
				<b-row>
					<b-col>
						<b-form-checkbox v-model="estensione.post_training" :value="1" :unchecked-value="null" switch>Dopo Allenamento</b-form-checkbox>
					</b-col>
					<b-col>
						<b-form-checkbox v-model="estensione.post_visita_medica" :value="1" :unchecked-value="null" switch>Dopo Visita Medica</b-form-checkbox>
					</b-col>
				</b-row>
				<b-row class="mb-1" v-if="estensione.post_visita_medica == 1">
					<b-form-textarea v-model="estensione.nota" placeholder="Note aggiuntive"></b-form-textarea>
				</b-row>
			</b-col>
			<b-col sm="6">
				<b-row v-if="!estensione.sessione_id">
					<b-col>
						<b-form-checkbox v-model="model.create_rtp" :value="1" :unchecked-value="null" switch>Crea sessione RTP/Differenziato</b-form-checkbox>
					</b-col>
					<b-col v-if="!model.id && model.create_rtp">
						<label>Operatore sessione RTP/Differenziato</label>
						<b-form-select v-model="model.rtp_operatore_id">
							<b-form-select-option :value="null">-- Operatore --</b-form-select-option>
							<b-form-select-option :value="staff.id" v-for="staff in staff_options">{{ staff.cognome + ' ' + staff.nome }}</b-form-select-option>
						</b-form-select>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import sessionMixin from '@/mixins/sessione.js';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [
		sessionMixin
		],
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { post_training: null, post_visita_medica: null, nota: null });
		}
	},
	props: [ 'estensione', 'model', 'field', 'staff_options' ],
	methods: {
	}
};
</script>