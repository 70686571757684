<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<b-card title="AS Roma System" title-tag="span">
				<b-card-body>
					<b-img thumbnail :src="user.avatar_url" :alt="user.nomeCompleto" width="150" class="mb-3"/>
					<p>Ciao <span class="font-weight-bold">{{ user.nomeCompleto }}</span>, </p>
					<p>Puoi accedere alle Apps utilizzando il menu 
						<span class="fa-stack fa-sm">
							<i class="fa fa-circle color-rosso fa-stack-2x"></i>
							<i class="fa fa-th fa-stack-1x color-white fa-inverse"></i>
						</span> 
					in alto.</p>
					<p>Sei un: <span class="color-rosso">{{ gruppi }}</span></p>
					<p>Ultimo login: <span class="font-weight-bold">{{ (last_login ? last_login.created_at : '') | formatDateHour }}</span></p>
				</b-card-body>
			</b-card>
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../auth.js');
import moment from 'moment';
export default {

	data: function() {
		return {
			last_login: null,
		}
	},

	components: {
		headapp:            require('../layout/headapp.vue').default,
		menusub:            require('../layout/menusub.vue').default,
		footerapp:          require('../layout/footerapp.vue').default,
	},

	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/last/login').then((response) => {
			if (response.ok)
			{
				this.last_login = response.data; 
			}
		}, () => {
            // error callback
        });     
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		gruppi: function() {
			return _.map(_.map(_.groupBy(Auth.user.gruppi, 'id'), 0), 'nome').join(', ');
			var gruppi_lbl = '';
			for(var i in Auth.user.gruppi) {
				if(gruppi_lbl != "") {
					gruppi_lbl += ", ";
				}
				gruppi_lbl += Auth.user.gruppi[i].nome;
			}
			return gruppi_lbl;
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment.utc(date).local();
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>
