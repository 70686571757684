<template>
	<div>
		<headapp></headapp>

		<div class="container-fluid pb-8" style="background-color: white">

			<menusub></menusub>
			<b-container v-if="competition" class="py-2 mb-8">
				<div v-if="competition" class="pt-3">
					<router-link :to="{ name: 'CompetitionGet', query: { first: true }, params: { competition_id: competition.id } }">{{ competition.name }}</router-link>
					<span v-if="stage">» <router-link :to="{ name: 'CompetitionStage', params: { competition_id: competition.id, stage_id: stage.id } }">{{ stage.name }}</router-link></span>
					<span v-if="match">» <router-link :to="{ name: 'CompetitionStageMatch', params: { competition_id: competition.id, stage_id: stage.id, match_id: match.id } }">{{ match.name }}</router-link></span>
				</div>
				<hr />
				<router-view class="my-3" :competition="competition" :stage="stage" :match="match" @refresh-match="fetchMatch" @refresh-stage="fetchStage"></router-view>
			</b-container>

	</div>
		<footerapp></footerapp>
	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	components: {
		headapp: 	require('@/components/layout/headapp.vue').default,
		menusub:    require('@/components/layout/menusub.vue').default,
		footerapp:  require('@/components/layout/footerapp.vue').default,
	},
	data: function() {
		return {
			competition: null,
			stage: null,
			match: null
		}
	},
	methods: {
		fetchCompetition: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id).then(function(data) {
				this.competition = data.data;
			})
		},
		fetchStage: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id).then(function(data) {
				this.stage = data.data;
			})
		},
		fetchMatch: function() {
			this.$http.get(this.$store.state.endpointRedazione + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/match/' + this.$route.params.match_id).then(function(data) {
				this.match = data.data;
			})
		}
	},
	watch: {
		'$route.params.competition_id': {
			immediate: true,
			handler: function() {
				this.fetchCompetition();
			}
		},
		'$route.params.stage_id': {
			immediate: true,
			handler: function() {
				if(this.$route.params.stage_id !== undefined) this.fetchStage();
				else
					this.stage = null;
			}
		},
		'$route.params.match_id': {
			immediate: true,
			handler: function() {
				if(this.$route.params.match_id !== undefined) this.fetchMatch();
				else
					this.match = null;
			}
		}
	}
}

</script>