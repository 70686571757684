import axios from 'axios';

export default {
    filter({ state, commit, rootState }, params) {        
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        return axios.post(`/exercises/filters?page=${page}&team_id=${rootState.rosaTeamId}&tab=esercizi`, {
            objective_ids: state.objective_ids,
            title: state.title,
            drill_id: state.drill_id
        })
        .then(function (response) {
            commit('setExercises', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_waiting({ state, commit, rootState }, params) {
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/exercises/filters?page=${page}&team_id=${rootState.rosaTeamId}&tab=waiting`, {
            objective_ids: state.objective_ids,
            title: state.title,
            drill_id: state.drill_id
        })
        .then(function (response) {
            commit('setExercisesWaiting', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_asroma({ state, commit, rootState }, params) {
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/exercises/filters?page=${page}&team_id=${rootState.rosaTeamId}&tab=asroma`, {
            objective_ids: state.objective_ids,
            title: state.title,
            drill_id: state.drill_id
        })
        .then(function (response) {
            commit('setExercisesASRoma', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_certificati({ state, commit, rootState }, params) {
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/exercises/filters?page=${page}&team_id=${rootState.rosaTeamId}&tab=certificati`, {
            objective_ids: state.objective_ids,
            title: state.title,
            drill_id: state.drill_id
        })
        .then(function (response) {
            commit('setExercisesCertificati', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },

    filter_da_validare({ state, commit, rootState }, params) {
        /**
         *  DETECTED IS EDIT TO CHANGE ACTION URL IN FORM
         *  */
        var page = (state.page === undefined) ? 1 : state.page;
        axios.post(`/exercises/filters?page=${page}&team_id=${rootState.rosaTeamId}&tab=da_validare`, {
            objective_ids: state.objective_ids,
            title: state.title,
            drill_id: state.drill_id
        })
        .then(function (response) {
            commit('setExercisesDaValidare', response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    },


    exercises({ state, commit, rootState }, params ) {
        var page = (params === undefined) ? 1 : params;
        return axios.get(`/exercise?page=${page}&team_id=${rootState.rosaTeamId}`)
            .then((res) => {
                commit('setExercises', res.data);
            }).catch((err) => {
                console.log('KO');
        });
    },

    exercises_waiting({
            state,
            commit,
            rootState
        }, params) {
        var page = (params === undefined) ? 1 : params;
        axios.get(`/exercise/waiting?page=${page}&team_id=${rootState.rosaTeamId}`)
            .then((res) => {
                commit('setExercisesWaiting', res.data);
            }).catch((err) => {
                console.log('KO');
        });
    },

    exercises_asroma({
            state,
            commit,
            rootState
        }, params) {
        var page = (params === undefined) ? 1 : params;
        axios.get(`/exercise/asroma?page=${page}&team_id=${rootState.rosaTeamId}`)
            .then((res) => {
                commit('setExercisesASRoma', res.data);
            }).catch((err) => {
                console.log('KO');
        });
    }
};
