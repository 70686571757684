export const ROUTES_ACADEMY_INT = [
    {
        path: '/academyint/dashboard',
        name: 'academy_int_dashboard',
        component: require('../components/pages/academy_int/dashboard.vue').default,
        meta: {
            module: 'academy_int',

            label: 'Dashboard',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
            parent: '',
            permesso: 'academy_int',
        }
    },

    {
        path: '/academyint/societa',
        name: 'academy_int_societa',
        component: require('../components/pages/academy_int/societa/societa.vue').default,
        meta: {
            module: 'academy_int',

            label: 'Academy',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'academy_societa',
        }
    },

    {
        path: '/academyint/societa/nuova',
        name: 'academy_int_societa_new',
        component: require('../components/pages/academy_int/societa/new.vue').default,
        meta: {
            module: 'academy_int',

            label: 'Nuova Academy',
            requiresAuth: true,
            showInMenu: true,
            parent: 'academy_int_societa',
            permesso: 'academy_societa_mng',
        }
    },

    {
        path: '/academyint/societa/edit/:id',
        name: 'academy_int_societa_edit',
        component: require('../components/pages/academy_int/societa/edit.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Anagrafica',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_societa',
            logModel: 'Academy',
            logModelId: 'id',
        }
    },

    {
        path: '/academyint/societa/allenamenti/:id',
        name: 'academy_int_societa_allenamenti',
        component: require('../components/pages/academy_int/societa/allenamenti.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Allenamenti',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_societa_allen',
            logModel: 'Academy',
            logModelId: 'id',
        }
    },

    {
        path: '/academyint/societa/allenamento/:id/:allen_id',
        name: 'academy_int_societa_allenamento',
        component: require('../components/pages/academy_int/societa/allenamento.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa_allenamenti',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'], 
            permesso: 'academy_societa_allen',  
            logModel: 'AcademyTraining',
            logModelId: 'allen_id',         
        }
    },

    {
        path: '/academyint/societa/valutazioni/:id',
        name: 'academy_int_societa_valutazioni',
        component: require('../components/pages/academy_int/societa/valutazioni.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Valutazioni',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_valutazioni',
            logModel: 'Academy',
            logModelId: 'id',
        }
    },

    {
        path: '/academyint/societa/attivita/:id',
        name: 'academy_int_societa_attivita',
        component: require('../components/pages/academy_int/societa/attivita.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Attività',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_attivita',
            logModel: 'Academy',
            logModelId: 'id',
        }
    },

    {
        path: '/academyint/societa/attivita/report/new/:id/:att_acad_id',
        name: 'academy_int_societa_attivita_report_new',
        component: require('../components/pages/academy_int/societa/attivita_report_new.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Report attività',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa_attivita',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_attivita_report',
            logModel: 'AcademyAttivitaAcademy',
            logModelId: 'att_acad_id',
        }
    },

    {
        path: '/academyint/societa/attivita/report/edit/:id/:report_id',
        name: 'academy_int_societa_attivita_report_edit',
        component: require('../components/pages/academy_int/societa/attivita_report_edit.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Report attività',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa_attivita',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_attivita_report',
            logModel: 'AcademyAttivitaReport',
            logModelId: 'report_id',
        }
    },

    {
        path: '/academyint/societa/valutazioni/new/:id',
        name: 'academy_int_societa_valutazioni_new',
        component: require('../components/pages/academy_int/societa/valutazioni_new.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Nuova valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa_valutazioni',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_valutazioni_mng',
            logModel: 'Academy',
            logModelId: 'id',

        }
    },

    {
        path: '/academyint/societa/valutazioni/edit/:id/:val_id',
        name: 'academy_int_societa_valutazioni_edit',
        component: require('../components/pages/academy_int/societa/valutazioni_edit.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa_valutazioni',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_valutazioni_mng',
            logModel: 'AcademyValutazioni',
            logModelId: 'val_id',
        }
    },

    {
        path: '/academyint/societa/valutazioni/view/:id/:val_id',
        name: 'academy_int_societa_valutazioni_view',
        component: require('../components/pages/academy_int/societa/valutazioni_view.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Valutazione',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_societa_valutazioni',
            menusub: [ 'academy_int_societa_edit', 'academy_int_societa_allenamenti', 'academy_int_societa_valutazioni', 'academy_int_societa_attivita'],
            permesso: 'academy_valutazioni',
            logModel: 'AcademyValutazioni',
            logModelId: 'val_id',
        }
    },

    {
        path: '/academyint/pdf',
        name: 'academy_int_pdf',
        component: require('../components/pages/academy_int/pdf.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Documenti',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'academy_pdf',
        }
    },

    {
        path: '/academyint/pdf/archivio',
        name: 'academy_int_pdf_archivio',
        component: require('../components/pages/academy_int/pdf_archivio.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Archivio Documenti',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'academy_int',
        }
    },

    {
        path: '/academyint/esercizi',
        name: 'academy_int_esercizi',
        component: require('../components/pages/academy_int/esercizi.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Esercizi',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
            parent: '',
            permesso: 'academy_ita_esercizi',
        }
    },

    {
        path: '/academyint/esercizi/new',
        name: 'academy_int_esercizi_new',
        component: require('../components/pages/academy_int/esercizio_new.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Nuovo Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'academy_ita_ese_mng',
        }
    },

    {
        path: '/academyint/esercizio/:id',
        name: 'academy_int_esercizio',
        component: require('../components/pages/academy_int/esercizio.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Esercizio',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'academy_ita_esercizi',
            logModel: 'AcademyEsercizi',
            logModelId: 'id',
        }
    },

    {
        path: '/academyint/allenamenti',
        name: 'academy_int_allenamenti',
        component: require('../components/pages/academy_int/allenamenti.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Allenamenti',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
        //  showAcademyList: true,
            parent: '',
            permesso: 'academy_ita_allenamenti',
        }
    },

    {
        path: '/academyint/allenamento/:id',
        name: 'academy_int_allenamento',
        component: require('../components/pages/academy_int/allenamento.vue').default,
        meta: {
            module: 'academy_int',

            label: 'Allenamento',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_allenamenti', 
            permesso: 'academy_ita_allenamenti',  
            logModel: 'AcademyTraining',
            logModelId: 'id',         
        }
    },

    {
        path: '/academyint/video',
        name: 'academy_int_video',
        component: require('../components/pages/academy_int/video.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Video',
            requiresAuth: true,
            showInMenu: true,
            showAcademyCategory: true,
            parent: '',
            permesso: 'academy_ita_video',
        }
    },

    {
        path: '/academyint/video/new',
        name: 'academy_int_video_new',
        component: require('../components/pages/academy_int/video_new.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Nuovo Video',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_video',
            permesso: 'academy_ita_video_mng',
        }
    },

    {
        path: '/academyint/video/edit/:id',
        name: 'academy_int_video_edit',
        component: require('../components/pages/academy_int/video_edit.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Modifica Video',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_video',
            permesso: 'academy_ita_video_mng',
            logModel: 'AcademyVideo',
            logModelId: 'id',
        }
    },

    {
        path: '/academyint/attivita',
        name: 'academy_int_attivita',
        component: require('../components/pages/academy_int/attivita.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Attività',
            requiresAuth: true,
            showInMenu: true,
            parent: '',
            permesso: 'academy_attivita',
        }
    },

    {
        path: '/academyint/attivita/report/new/:id',
        name: 'academy_int_attivita_report_new',
        component: require('../components/pages/academy_int/attivita_report_new.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Report attività',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_attivita',
            permesso: 'academy_attivita_report',
            logModel: 'AcademyAttivitaAcademy',
            logModelId: 'id',
        }
    },

    {
        path: '/academyint/attivita/report/edit/:id',
        name: 'academy_int_attivita_report_edit',
        component: require('../components/pages/academy_int/attivita_report_edit.vue').default,
        meta: {
            module: 'academy_int',
            label: 'Report attività',
            requiresAuth: true,
            showInMenu: false,
            parent: 'academy_int_attivita',
            permesso: 'academy_attivita_report',
            logModel: 'AcademyAttivitaReport',
            logModelId: 'id',
        }
    },
];
