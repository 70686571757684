<template id="esercizicard">
	<div class="esercizicard h-100">

		<div class="card h-100" :id="'exe_' + ex.id">
			<router-link :to="'/' + $route.meta.module + '/palestra/esercizi/edit/'+ex.id" class="text-center">
				<b-img :src="image" fluid></b-img>
			</router-link>
			<div class="card-body">
				<b-row>
					<b-col>
						<p class="text-truncate mb-0" :title="ex.name"><strong>{{ex.name}}</strong></p>
						<p class="mb-0 font-size-sm text-truncate" v-if="ex.utente">di <span class="color-rosso">{{ex.utente.nomeCompleto}}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.8rem"><span title="Facility">Facility:</span> <span class="color-rosso" :title="ex.facility ? ex.facility.name : ''">{{ ex.facility ? ex.facility.name : '' }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.8rem"><span title="Facility">Goal:</span> <span class="color-rosso" :title="ex.goal ? ex.goal.name : ''">{{ ex.goal ? ex.goal.name : '' }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.8rem"><span title="Tt">Tt:</span> <span class="color-rosso" :title="ex.tt ? ex.tt.name : ''">{{ ex.tt ? ex.tt.name : '' }}</span></p>
						<p class="mb-0 text-truncate" style="font-size: 0.8rem"><span title="Intensity">Intensity:</span> <span class="color-rosso" :title="ex.intensity ? ex.intensity.name : ''">{{ ex.intensity ? ex.intensity.name : '' }}</span></p>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
var Auth = require('../../../auth.js');
export default {
	props: {
		ex: { type: Object },
	},

	computed: {
		auth: function() {
			return Auth;
		},
		image: function() {
			for(var i in this.ex.media) {
				var item = this.ex.media[i];
				if(item.tipo == 'foto') {
					return `${item.foto_url}?size=thumb`;
				} else {
					return item.image;
				}
			}
			return '/assets/varie/missing.jpg';
		},
	},

	methods: {
	},
}
</script>
