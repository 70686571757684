<template>
	<div style="width: 100%; position: relative">
   		<span style="display: inline-block; position: absolute; right: 0" v-if="note && note.length > 0">
   			<span v-b-popover.hover.top="note" title="Note">
   			   💬
   			 </span>
   		</span>

   		<b-button-group>
   			<b-button class="mr-0" size="xs" @click="checkChange(1)" :variant="test[field] == 1 ? 'primary': 'outline-secondary'" :disabled="readonly">Sì</b-button>
   			<b-button size="xs" @click="checkChange(0)" :variant="test[field] == 0 ? 'primary': 'outline-secondary'" :disabled="readonly">No</b-button>
   		</b-button-group>

	</div>
</template>

<style type="text/css">
.hidden {
	visibility: hidden;
}
</style>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		checkChange: function(value) {
			if(this.test[this.field] == value)
				this.test[this.field] = null;
			else
				this.test[this.field] = value;
		}
	},
	computed: {
		note: function() {
			return this.test[this.field.split('_')[0] + '_activity_check_nota'];
		}
	}
}
	
</script>