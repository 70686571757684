<template id="gsessions">
	<div>

		<b-overlay :show="ux.load" rounded="sm">
			
			<div class="mb-1">
				<b-nav tabs>
					<b-nav-item @click.prevent="ux.preselect = 0" :active="ux.preselect == 0">Oggi</b-nav-item>
					<b-nav-item @click.prevent="ux.preselect = -1" :active="ux.preselect == -1">Settimana</b-nav-item>
					<b-nav-item @click.prevent="ux.preselect = -2" :active="ux.preselect == -2">Settimana Scorsa</b-nav-item>
					<b-nav-item @click.prevent="ux.preselect = -3" :active="ux.preselect == -3">Stagione</b-nav-item>
					<!-- <b-nav-item @click.prevent="ux.preselect = 1" disabled>Stagione</b-nav-item> -->
				</b-nav>
			</div>

			<b-table-simple small striped fixed v-if="stats && size(stats.sessione) > 0">
				<b-thead>
					<b-tr>
						<b-th>Sessione</b-th>
						<b-th class="text-center">Numero</b-th>
						<b-th class="text-center">Ultima Sessione</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="sessioni, sessione_nome in stats.sessione">
						<b-td :colspan="!isSelezionato(sessione_nome) ? 1 : 3">
							<a href="" @click.prevent="!isSelezionato(sessione_nome) ? addSelection(sessione_nome):removeSelection(sessione_nome)">{{ sessione_nome }}</a>
							<div v-if="isSelezionato(sessione_nome)" class="p-2">
								<b-table-simple small striped fixed class="m-0">
									<b-thead>
										<b-tr>
											<b-th>Nome</b-th>
											<b-th class="text-center"># Fasi</b-th>
											<b-th class="text-center" colspan="2">Data</b-th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="sessione in sessioni">
											<b-td>
												<router-link target="_blank" :to="{ name: 'medical_sessione', params: { id: sessione.sessione.id, tipo: sessione.sessione.sessione_tipo_glo_id } }">{{ sessione.sessione.nome }}</router-link>
											</b-td>
											<b-td class="text-center">{{ sessione.sessione.fasi.length }}</b-td>
											<b-td class="text-center">{{ sessione.sessione.inizio | formatDate }}</b-td>
											<b-td class="text-center">{{ sessione.sessione.inizio | formatHour }} - {{ sessione.sessione.fine | formatHour }}</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</div>
						</b-td>
						<b-td class="text-center" v-if="!isSelezionato(sessione_nome)">{{ sessioni.length }}</b-td>
						<b-td class="text-center" v-if="!isSelezionato(sessione_nome)"><router-link target="_blank" :to="{ name: 'medical_sessione', params: { id: tail(sessioni).sessione.id, tipo: tail(sessioni).sessione.sessione_tipo_glo_id } }">{{ tail(sessioni).sessione.inizio | formatDate }}</router-link></b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

		</b-overlay>
		<b-alert v-if="stats && size(stats.sessione) == 0" show>
			Non ci sono sessioni per questo giocatore!
		</b-alert>
	</div>
</div>

</template>

<script>
import _ from 'lodash';
import moment from 'moment'
export default {
	props: {
		params: {
			type: Object,
			required: false
		}
	},
	data: function() {
		return {
			stats: false,
			selected: [],
			ux: {
				preselect: 0,
				load: false
			}
		}
	},
	components: {
	},
	methods: {
		size: function(obj) {
			return _.size(obj);
		},
		removeSelection: function(name) {
			var pos = this.selezionato(name);
			if(pos !== -1) {
				this.selected.splice(pos, 1);
			}
		},
		addSelection: function(name) {
			if(this.selezionato(name) === -1)
				this.selected.push(name);
		},
		isSelezionato: function(name) {
			return this.selezionato(name) !== -1;
		},
		selezionato: function(name) {
			return this.selected.indexOf(name);
		},
		tail: function(what) {
			return _.last(what);
		},
		getStats: function(what) {

			var string = [];
			if(what.from != undefined)
				string.push('from=' + what.from);
			if(what.to != undefined)
				string.push('to=' + what.to);

			if(this.ux.load == false) {
				this.ux.load = true;
				this.$http.get(this.$store.state.apiEndPoint + '/sessioni/of/' + this.params.player_id + '?' + string.join('&')).then(function(data) {
					this.ux.load = false;
					this.stats = data.data;
				});
			}

		}
	},
	filters: {
		formatHour: function(date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('HH:mm'); 
			}
			return "";
		},
		formatDate2: function(date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
	created: function () {
		
	},
	watch: {
		'ux.preselect': {
			immediate: true,
			handler: function() {

				var args = {
					from: null,
					to: null
				}

				if(this.ux.preselect == 0) {
					args.from = moment().format('YYYY-MM-DD');
					args.to = moment().format('YYYY-MM-DD');
				} else if(this.ux.preselect == -1) {
					args.from = moment().startOf('isoWeek').format('YYYY-MM-DD');
					args.to = moment().format('YYYY-MM-DD');
				} else if(this.ux.preselect == -2) {
					args.from = moment().startOf('isoWeek').add(-7, 'days').format('YYYY-MM-DD');
					args.to = moment().startOf('isoWeek').add(-1, 'days').format('YYYY-MM-DD');
				} else if(this.ux.preselect == -3) {
					args.from = moment('2022-07-01').format('YYYY-MM-DD');
					args.to = moment().format('YYYY-MM-DD');
				}

				this.getStats(args);

			}
		},
		'params': {
			deep: true,
			immediate: true,
			handler: function(a,b) {
				console.log(a);
			}
		}
	},
	computed: {
		
	}
}
</script>
