<template>
	<div>
		<h5>Nuova Partita</h5>

		<b-form @submit.prevent="createMatch">
			<match-form :match="match"></match-form>
			<hr />
			<b-button type="submit" variant="outline-success">Crea Partita</b-button>
		</b-form>

	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: [ 'competition' ],
	components: {
		MatchForm: require('@/components/redazione/components/matches/form.vue').default
	},
	data: function() {
		return {
			match: {
				competition_stage_id: null,
				name: null,
				image: null,
				url: null
			}
		}
	},
	methods: {
		createMatch: function() {

			this.$http.post(this.stage.nextMatch.events + 'competition/' + this.$route.params.competition_id + '/stage/' + this.$route.params.stage_id + '/new-match', { match: this.match }).then(function(data) {
				console.log('Cool');
			})

		}
	}
}

</script>