<template>
	<div v-if="ux.show">

		<b-table-simple small striped>
			<b-thead>
				<b-tr>
					<b-th class="small">Provider</b-th>
					<b-th class="small">Chiave</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="item in entita_api">
					<b-td width="60%">{{ item.fornitore }}</b-td>
					<b-td width="60%">{{ item.chiave }}</b-td>
					<b-td width="40%" class="text-right">
						<b-button-group>
							<b-button class="mr-0" size="xs" @click="editEntitaApi(item.id)" v-if="!readonly && auth.isUserEnable('providers_' + item.fornitore)">
								<i class="fa fa-edit" aria-hidden="true"></i> Modifica
							</b-button>
							<b-button size="xs" @click.prevent="deleteEntitaApi(item.id)" v-if="!readonly && auth.isUserEnable('providers_' + item.fornitore)">
								<i class="fa fa-trash" aria-hidden="true"></i> Rimuovi
							</b-button>
						</b-button-group>
					</b-td>
				</b-tr>
				<!-- <b-tr v-if="entita_api.length == 0">
					<b-td colspan="2">
						Non ci sono providers associati
					</b-td>
				</b-tr> -->

				<b-tr>
					<b-td>AS Roma System</b-td>
					<b-td colspan="2">{{ entityId }}</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
		<b-button variant="outline-secondary" size="sm" v-if="!readonly || (model && model.id && !readonly)" @click.prevent="action_toggleMng">{{ model && model.id ? 'Modifica Provider' : 'Nuovo Provider' }} <i v-if="ux.mng == true" class="fa fa-arrow-up" aria-hidden="true"></i><i v-else class="fa fa-arrow-down" aria-hidden="true"></i></b-button>
		<Form v-if="ux.mng == true && !readonly" :model="model" @save="saveEntitaApi" :providers="providers" class="mt-3"></Form>

		<div v-if="exist_entita" class="mt-2">
			<p>La chiave di questo provider è già associata alla seguente {{ entity }}:</p>
			<div v-if="entity == 'Persona'">
				<b-row>
					<b-col cols="3" class="text-center">
						<b-img :src="exist_entita.avatar_url" :width="130"></b-img>
      					<div class="font-bold text-center">{{ exist_entita.cognome }} {{ exist_entita.cognome }}</div>
						<div>{{ exist_entita.nascita_data | formatDate}}</div>
					</b-col>
				</b-row>	
			</div>
			<div v-else>
				{{ exist_entita }}		
			</div>
			<p class="mt-2">Vuoi spostarla sulla {{ entity }} attuale ?</p>
			<b-button variant="outline-success" size="sm" type="button" @click="saveEntitaApi(true)">Si</b-button>
			<b-button variant="outline-danger" size="sm" type="button" @click="annulla">Annulla</b-button>

		</div>
		
	</div>
</template>

<script>

import _ from 'lodash';
import * as types from '../../../store/types';
var Auth = require('../../../auth.js');
import moment from 'moment';
import cache from '@/mixins/cache.js';

export default {
	props: [ 'entity', 'collapsed', 'entityId', 'tipoId', 'readonly' ],
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		}
	},
	mixins: [ cache ],
	components: {
		Form: require('@/components/layout/entita_api/form.vue').default,
	},
	watch: {
		collapsed: {
			immediate: true,
			handler: function() {
				if(this.collapsed == true)
					this.ux.show = false;
				else
					this.ux.show = true;
			}
		},
		entity: {
			deep: true,
			handler: function() {
				this.fetch();
			},
			immediate: true
		}
	},
	created: function() {
		this.getProviders();
	},
	methods: {
		action_toggleMng: function() {
			this.ux.mng = !this.ux.mng;
			if(!this.ux.mng) {
				this.model = this.createModel();
			}
		},
		getProviders: function() {
			var providers = this.getCache('providers');
			if (providers) {
				this.providers = providers;
			} else {
			this.$http.get(this.$store.state.apiEndPoint + '/entita/api/providers').then(function(data) {
				this.providers = data.data;
				this.setCache('providers', this.providers);
			});
			}	
		},
		fetch: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/entita/api/of/' + this.entity + '/' + this.entityId).then(function(data) {
				this.entita_api = data.data;
				this.model = this.createModel();
			});
		},
		deleteEntitaApi: function(entityId) {
			if(confirm("Confermi la rimozione del provider?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/entita/api/delete/' + entityId).then(function(data) {
					this.fetch();
				}).catch(function (data) {
					var response = data.data;
					for (var index in response) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response[index], status: 0 });
					}
				});
			}
		},
		editEntitaApi: function(id) {
			var item = this.entita_api.find(item => item.id == id);
			this.model = {
				id: item.id,
				fornitore: item.fornitore,
				chiave: item.chiave
			};
			this.ux.mng = true;
		},
		createModel: function() {
			return {
				fornitore: null,
				chiave: null,
			}
		},
		saveEntitaApi: function(move = null) {
			var url = '';
			if(this.model.id) {
				url = '/entita/api/' + this.model.id + '/update';
			} else {
				url = '/entita/api/of/' + this.entity + '/' + this.entityId + '/create';
			}
			if(move) {
				this.model.move_from_entita_api_id = this.exist_entita.entita_api_id;
				this.exist_entita = null;
			}
			this.$http.post(this.$store.state.apiEndPoint + url, this.model).then(function(data) {
				this.ux.mng = false;
				this.fetch();
			}).catch(function (data) {
				var response = data.data;
				if(response.entita) {
					this.exist_entita = response.entita;
				} else {
					for (var index in response) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response[index], status: 0 });
					}
				}
			});
		},
		annulla() {
			this.exist_entita = null;
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
		
	},
	data: function() {
		return {
			model: false,
			providers: [],
			entita_api: false,
			exist_entita: null,
			ux: {
				mng: false,
				show: false,
			}
		}
	}
};

</script>