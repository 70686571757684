<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Video esercizio</span>
					</div>
					<div class="content">
						<div v-if="loading" align="center" style="width:100%">
							<img src="/assets/varie/loading.gif" style="width: 150px;" />
						</div>
						<b-card class="mb-2" v-for="item in video" v-if="!loading">
							<b-row>
								<b-col cols="5">
									<b-embed type="video" controls aspect="16by9" v-if="item.tipo != 'youtube'" :poster="item.thumb ? item.thumb : '/assets/varie/missing.jpg'" preload="none" :controlsList="!auth.isUserEnable('video_download') ? 'nodownload' : ''" oncontextmenu="return false;">
										<source :src="item.video"/>		
									</b-embed>
									<b-embed type="iframe" aspect="16by9" :src="item.video" allowfullscreen v-if="item.tipo == 'youtube'"></b-embed>
								</b-col>
								<b-col cols="5" class="ml-3">
									<h5>{{ getTitle(item) }}</h5>
									<p class="card-text">
										Caricato da <span class="color-rosso font-bold"> {{item.utente.nomeCompleto}} </span>
									</p>
									<p class="card-text">
										il <span class="font-italic"> {{ item.data | formatDateHour}} </span>
									</p>
									<p class="card-text">
										Sorgente: <span class="font-bold"> {{ item.tipo }} </span>
									</p>
								</b-col>
								<b-col>
									<b-button variant="primary" class="w-100 mb-2" :to="{ name: 'football_video_esercizio_edit', params: { id: $route.params.id, id_video: item.id }}">Modifica Video</b-button>
									<b-button variant="primary" class="w-100 mb-2" @click="eliminaVideo(item.id)" v-if="auth.isAdmin() || auth.user.id == item.utente_id">Elimina Video</b-button>
									<b-button variant="primary" class="w-100 mb-2" @click="getLinkVideo(item.id)" v-if="auth.isUserEnable('video_link')">Link Video</b-button>
									<b-button variant="primary" class="w-100 mb-2" v-if="auth.isUserEnable('video_download')" @click="downloadVideo(item.id)">Download Video</b-button>
								</b-col>
							</b-row>
						</b-card>
					</div>
				</div>
			</div>
			<b-modal ref="linkModal" title="Link Video" size="lg" ok-only ok-title="Chiudi">
				<div>
					{{ link }}
				</div>
			</b-modal>		
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>
var Auth = require('../../../../auth.js');
import moment from 'moment';
import * as types from '../../../../store/types';
export default {
	data: function() {
		return {
			video: [],
			loading: false,
			link: null,
		}
	},

	components: {
		headapp:            require('../../../layout/headapp.vue').default,
		menusub:            require('../../../layout/menusub.vue').default,
		footerapp:          require('../../../layout/footerapp.vue').default,
		card:               require('../../../layout/card.vue').default,
	},

	created: function () {	
		this.loadVideo();
	},

	methods: {
		loadVideo() {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/video/esercizio/' + this.$route.params.id).then((response) => {
            // success callback
            if (response.ok)
            {
            	this.video = response.data;
            	this.loading = false;
            }
        }, (response) => {
            // error callback
        });
		},

		getTitle(video) {
			var title = "";
			if(video.titolo != "") {
				title = video.titolo;
			} else {
				title = Auth.isAdmin() ? video.video : video.file;
			}
			return title;
		},

		eliminaVideo(id) {
			if(confirm("Confermi l'eliminazione del video ?")) {
				this.loading = true;
				this.$http.get(this.$store.state.apiEndPoint + '/video/elimina/' + id).then((response) => {
		            // success callback
		            if (response.ok)
		            {
		            	this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
		            	this.loadVideo();
		            }
		        }, (response) => {
		            // error callback
		        });
			}
		},

		getLinkVideo(id) {
			this.link = null;	
			this.$http.get(this.$store.state.apiEndPoint + '/video/link/' + id).then((response) => {
				if (response.ok)
				{
					this.link = response.data;
					this.$refs.linkModal.show();
				}
			}, (response) => {
			});
		},
		downloadVideo(id) {
			window.open(this.$store.state.apiEndPoint + '/video/download/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token);
		},
	},
	computed: {
		auth: function() {
			return Auth;
		},
	},
	filters: {
	formatDateHour: function (date) {
		var m = moment(date);
		if(m.isValid()) {
			return m.format('DD/MM/YYYY HH:mm'); 
		}
		return "";
	},
},
}
</script>
