<template id="home">
    <section class="home container-login d-flex justify-content-center align-items-center" style="height: 100vh; width: 100vw">

        <div style="width: 50%">
            

            <div class="card">

                <div class="card-body">
                <img src="/assets/images/logo.png" alt="A.S. Roma" class="center-block mb-2 mx-auto d-block" height="80">

                <!--<langswitch></langswitch>-->

                <p class="lead text-center">Benvenuto</p>

                <form method="post" accept-charset="utf-8" v-on:submit.prevent="login()">

                    <div :class="'form-group' + (errors.username ? ' has-danger' : '')">
                        <label class="sr-only" for="username">Username</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa fa-user fa-fw"></i>
                                </span>
                            </div>
                            <input 
                                id="username" 
                                name="username" 
                                type="text"
                                :class="'form-control' + (errors.username ? ' form-control-danger' : '')" 
                                placeholder="Username" 
                                v-model="username" 
                                required
                            >
                        </div>

                        <template v-if="errors.username">
                            <div class="form-control-feedback">
                                <strong>{{ errors.username }}</strong>
                            </div>
                        </template>
                    </div>

                    <div :class="'form-group' + (errors.password ? ' has-danger' : '')">
                        <label class="sr-only" for="password">Password</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                <i class="fa fa-lock fa-fw"></i>
                                </span>
                            </div>
                            <input 
                                id="password" 
                                name="password" 
                                type="password"
                                :class="'form-control' + (errors.password ? ' form-control-danger' : '')" 
                                placeholder="Password" 
                                v-model="password" 
                                required
                            >
                        </div>

                        <template v-if="errors.password">
                            <div class="form-control-feedback">
                                <strong>{{ errors.password }}</strong>
                            </div>
                        </template>
                    </div>

                    <div class="form-group">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" name="remember"> Remember Me
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block" @click.prevent="doLogin">Accedi</button>
                    </div>

                </form>

                <!--hr>

                <router-link :to="'recover'" class="btn btn-secondary btn-block">Recupera password</router-link-->
                </div>
            </div>


        </div>

      

        <div v-if="false" class="container-login">
            

        </div>

    </section>
</template>

<script>
export default {
    data () {
        return {
            countries: [ 'it', 'us', 'es', 'jp' ],
            errors: {
                username: '',
                password: '',
            },
            username: '',
            password: '',
        }
    },
    components: {
        //langswitch: require('../layout/langswitch.vue'),
    },
    created ()
    {
        var root = this.getRoot();
        if (root.auth.authenticated) {
            root.$router.push('/dashboard');
        }
    },
    methods: {
        resetErrors: function () {
            this.errors = {
                username: '',
                password: '',
            };
        },
        getRoot: function () {
            return this.$root.$children[0];
        },
        doLogin: function() {
            this.resetErrors();
            var root = this.getRoot();
            root.auth.login(root, { username: this.username, password: this.password }, this.$route.query.redirect, this);
            if (this.errors == undefined) this.resetErrors();
        }
    }
}
</script>
