<template id="rosa">
	<section class="rosa">
		<b-sidebar width="610px" :visible="selected != null ? true : false" @hidden="selected = null" shadow>
			<div v-if="selected">

				<div v-if="selected.kpi1">
					<strong>Kpi1 {{ selected.kpi1.fatto + '/' + selected.kpi1.programmato }}</strong>
					<b-table-simple small striped>
						<b-thead>
							<b-tr>
								<b-th>Partita</b-th>
								<b-th>Data</b-th>
								<b-th>Durata</b-th>
								<b-th>Fase</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr :variant="record.fatto == 1 ? 'success' : ''" v-for="record in selected.kpi1.record">
								<b-td>{{ record.partita }}</b-td>
								<b-td>{{ record.data | data }}</b-td>
								<b-td>{{ record.durata | duration }}</b-td>
								<b-td>
									{{ record.fase_nome }}
									<div class="small">({{record.fase}}, Sessione {{record.sessione}})</div>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<pre>{{ selected.kpi1.record }}</pre>
					
				</div>

				<pre>{{selected}}</pre>
			</div>
		</b-sidebar>

		<div class="card" v-if="!isWidget || isFullscreen">
			<div class="card-body">
				<div>
					<b-overlay :show="!dati">
						<div class="mb-4">
							<b-row>
								<b-col cols="4">
									<h3 class="font-bold font-italic m-0" style="text-transform: uppercase; color: #990a2c">Recovery <span v-if="datiOk.length">({{ datiOk.length }})</span></h3>
									<label><input type="checkbox" v-model="ux.hideDisabled" /> Gestisci Giocatori Nascosti</label>
									<b-button-group class="float-right">
										<b-button v-if="ux.hideDisabled" variant="outline-success" size="xs" class="mr-0 " @click.prevent="savePersone(hidePreset)">Aggiorna</b-button>
										<b-button v-if="ux.hideDisabled" variant="outline-warning" size="xs" @click.prevent="svuotaPersone()">Svuota</b-button>
									</b-button-group>
								</b-col>
								<b-col cols="8">
									
									<div class="d-flex justify-content-end align-content-center">
										<div class="mr-1">
											<div class="d-flex" style="min-width: 440px">
												<b-datepicker v-model="ux.from" placeholder="Dal" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
												<b-datepicker v-model="ux.to" placeholder="Al" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
												<b-button @click.prevent="getDashboard" variant="primary" :disabled="!canFilter" size="sm">
													<em class="fa fa-search"></em>
												</b-button>
												<b-button @click.prevent="downloadDati" variant="outline-primary" :disabled="!dati || dati.length == 0" size="sm">
													<em class="fa fa-download"></em>
												</b-button>
											</div>
											<div class="text-center mt-1 small font-italic">
												<a href="" @click.prevent="applyPreset('lastWeek')">Ultima Settimana</a>, 
												<a href="" @click.prevent="applyPreset('lastMonth')">Ultimo Mese</a>, 
												<a href="" @click.prevent="applyPreset('lastTrimester')">Ultimo Trimestre</a>
											</div>
										</div>
										<div style="position: relative">
											<div style="position: absolute; top: -20px; text-transform: uppercase; letter-spacing: 1px; font-weight: bold" class="small text-muted">Ordina per</div>
											<b-button-group>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'overall' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('overall')">
													Overall

													<span v-if="ux.sort == 'overall'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'kpi6' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('kpi6')">
													Acqua Fredda

													<span v-if="ux.sort == 'kpi6'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'kpi1' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('kpi1')">
													Comp. Garments

													<span v-if="ux.sort == 'kpi1'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'kpi2' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('kpi2')">
													Palestra

													<span v-if="ux.sort == 'kpi2'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'kpi3' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('kpi3')">
													Campo

													<span v-if="ux.sort == 'kpi3'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'kpi4' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('kpi4')">
													Fisioterapia

													<span v-if="ux.sort == 'kpi4'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button size="sm" :disabled="!dati || dati.length == 0" class="mr-0" :variant="ux.sort != 'kpi5' ? 'outline-secondary' : 'secondary'" @click.prevent="setOrder('kpi5')">
													Nutrition

													<span v-if="ux.sort == 'kpi5'">
														<em class="fa" :class="{ 'fa-arrow-up': ux.order == -1, 'fa-arrow-down': ux.order == 1 }"></em>
													</span>

												</b-button>
												<b-button variant="outline-danger" class="mr-0" @click.prevent="ux.sort = null" v-if="ux.sort != null" size="sm">&times;</b-button>
											</b-button-group>
										</div>
									</div>

								</b-col>
							</b-row>
						</div>

						<hr />

						<b-row v-if="dati && dati.length" :style="contentHeight ? 'max-height: ' + (contentHeight - 80) + 'px;overflow:auto;' : ''">

							<b-col cols="4" v-for="persona in datiOk" :key="persona.persona.id" class="mb-4 px-3">

								<b-card class="h-100" :border-variant="ux.hideDisabled == true ? (( hidePreset.indexOf(persona.persona.id) !== -1 ) ? 'danger':'success') : ''" no-body>

									<div class="p-1 d-flex align-items-center" style="border-bottom: 1px solid rgb(100,100,100); background-color: rgb(240,240,240); border-top-left-radius: 3px; border-top-right-radius: 3px">

										<div class="ml-1 small text-muted">
											<span v-if="persona.persona.last_carriera_sportiva && persona.persona.last_carriera_sportiva.ruoli.length > 0">{{ persona.persona.last_carriera_sportiva.ruoli[0].sigla }}</span>
										</div>

										<div class="flex-grow-1 text-center">
											<label>
												<input v-if="ux.hideDisabled" type="checkbox" v-model="hidePreset" :value="persona.persona.id" />

												<strong style="color: ; text-transform: uppercase;">
													{{ persona.persona.cognome }}
												</strong>
												<span>
													{{ persona.persona.nome }}
												</span>
											</label>
										</div>

										<div class="mr-1 small text-muted">
											<span v-if="persona.persona.last_carriera_sportiva && persona.persona.last_carriera_sportiva.maglia">{{ persona.persona.last_carriera_sportiva.maglia }}</span>
										</div>
									</div>

									<div class="d-flex">

										<div :style="'min-width: 150px; background-image: url('+persona.persona.avatar_url+'); background-size: cover; background-position: center center'"></div>
										<div class="flex-grow-1 px-1">

											<div class="mb-1">
												<div class="small">
													<span><strong>Acqua Fredda</strong></span>
												</div>
												<div v-if="persona.kpi6">

													<b-row no-gutters>
														<b-col :cols="6" :xl="8">
															<b-progress :max="persona.kpi6.programmato" height="1.2rem">
																<b-progress-bar :variant="persona.kpi6.fatto == 0 ? 'lightGray' : ''" :value="persona.kpi6.fatto || persona.kpi6.programmato">
																</b-progress-bar>
															</b-progress>
														</b-col>
														<b-col :cols="2 + (persona.kpi6.minuti_programmato > 0 ? 0 : 2)" class="small font-weight-bold text-center">
															{{ persona.kpi6.fatto }}/{{ persona.kpi6.programmato }}
														</b-col>
														<b-col v-if="persona.kpi6.minuti_programmato > 0" cols="2" class="small text-center">
															<span>
																<em class="fa fa-clock-o"></em> {{ Math.round(persona.kpi6.minuti_fatto/60) }}
															</span>
														</b-col>
													</b-row>

												</div>
												<div v-else>
													<b-row>
														<b-col cols="8" xl="8">
															<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
														</b-col>
														<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
													</b-row>
												</div>
											</div>

											<div class="mb-1">
												<div class="small">
													<span><strong>Compression Garments</strong></span>
												</div>
												<div v-if="persona.kpi1">

													<b-row no-gutters>
														<b-col :cols="6" :xl="8">
															<b-progress :max="persona.kpi1.programmato" height="1.2rem">
																<b-progress-bar :variant="persona.kpi1.fatto == 0 ? 'lightGray' : ''" :value="persona.kpi1.fatto || persona.kpi1.programmato">
																</b-progress-bar>
															</b-progress>
														</b-col>
														<b-col :cols="2 + (persona.kpi1.minuti_programmato > 0 ? 0 : 2)" class="small font-weight-bold text-center">
															{{ persona.kpi1.fatto }}/{{ persona.kpi1.programmato }}
														</b-col>
														<b-col v-if="persona.kpi1.minuti_programmato > 0" cols="2" class="small text-center">
															<span>
																<em class="fa fa-clock-o"></em> {{ Math.round(persona.kpi1.minuti_fatto/60) }}
															</span>
														</b-col>
													</b-row>

												</div>
												<div v-else>
													<b-row>
														<b-col cols="8" xl="8">
															<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
														</b-col>
														<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
													</b-row>
												</div>
											</div>

											<div class="mb-1">
												<div class="small">
													<span><strong>Palestra</strong></span>
												</div>
												<div v-if="persona.kpi2">

													<b-row no-gutters>
														<b-col :cols="6" :xl="8">
															<b-progress :max="persona.kpi2.programmato" height="1.2rem">
																<b-progress-bar :variant="persona.kpi2.fatto == 0 ? 'lightGray' : ''" :value="persona.kpi2.fatto || persona.kpi2.programmato">
																</b-progress-bar>
															</b-progress>
														</b-col>
														<b-col :cols="2 + (persona.kpi2.minuti_programmato > 0 ? 0 : 2)" class="small font-weight-bold text-center">
															{{ persona.kpi2.fatto }}/{{ persona.kpi2.programmato }}
														</b-col>
														<b-col v-if="persona.kpi2.minuti_programmato > 0" cols="2" class="small text-center">
															<span>
																<em class="fa fa-clock-o"></em> {{ Math.round(persona.kpi2.minuti_fatto/60) }}
															</span>
														</b-col>
													</b-row>

												</div>
												<div v-else>
													<b-row>
														<b-col cols="8" xl="8">
															<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
														</b-col>
														<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
													</b-row>
												</div>
											</div>

											<div class="mb-1">
												<div class="small">
													<span><strong>Campo</strong></span>
												</div>
												<div v-if="persona.kpi3">

													<b-row no-gutters>
														<b-col :cols="6" :xl="8">
															<b-progress :max="persona.kpi3.programmato" height="1.2rem">
																<b-progress-bar :variant="persona.kpi3.fatto == 0 ? 'lightGray' : ''" :value="persona.kpi3.fatto || persona.kpi3.programmato">
																</b-progress-bar>
															</b-progress>
														</b-col>
														<b-col :cols="2 + (persona.kpi3.minuti_programmato > 0 ? 0 : 2)" class="small font-weight-bold text-center">
															{{ persona.kpi3.fatto }}/{{ persona.kpi3.programmato }}
														</b-col>
														<b-col v-if="persona.kpi3.minuti_programmato > 0" cols="2" class="small text-center">
															<span>
																<em class="fa fa-clock-o"></em> {{ Math.round(persona.kpi3.minuti_fatto/60) }}
															</span>
														</b-col>
													</b-row>

												</div>
												<div v-else>
													<b-row>
														<b-col cols="8" xl="8">
															<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
														</b-col>
														<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
													</b-row>
												</div>
											</div>

											<div class="mb-1">
												<div class="small">
													<span><strong>Fisioterapia</strong></span>
												</div>
												<div v-if="persona.kpi4">

													<b-row no-gutters>
														<b-col :cols="6" :xl="8">
															<b-progress :max="persona.kpi4.programmato" height="1.2rem">
																<b-progress-bar :variant="persona.kpi4.fatto == 0 ? 'lightGray' : ''" :value="persona.kpi4.fatto || persona.kpi4.programmato">
																</b-progress-bar>
															</b-progress>
														</b-col>
														<b-col :cols="2 + (persona.kpi4.minuti_programmato > 0 ? 0 : 2)" class="small font-weight-bold text-center">
															{{ persona.kpi4.fatto }}/{{ persona.kpi4.programmato }}
														</b-col>
														<b-col v-if="persona.kpi4.minuti_programmato > 0" cols="2" class="small text-center">
															<span>
																<em class="fa fa-clock-o"></em> {{ Math.round(persona.kpi4.minuti_fatto/60) }}
															</span>
														</b-col>
													</b-row>

												</div>
												<div v-else>
													<b-row>
														<b-col cols="8" xl="8">
															<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
														</b-col>
														<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
													</b-row>
												</div>
											</div>

											<div class="mb-1">
												<div class="small">
													<span><strong>Nutrition</strong></span>
												</div>
												<div v-if="persona.kpi5">

													<b-row no-gutters>
														<b-col :cols="6" :xl="8">
															<b-progress :max="persona.kpi5.programmato" height="1.2rem">
																<b-progress-bar :variant="persona.kpi5.fatto == 0 ? 'lightGray' : ''" :value="persona.kpi5.fatto || persona.kpi5.programmato">
																</b-progress-bar>
															</b-progress>
														</b-col>
														<b-col :cols="2 + (persona.kpi5.minuti_programmato > 0 ? 0 : 2)" class="small font-weight-bold text-center">
															{{ persona.kpi5.fatto }}/{{ persona.kpi5.programmato }}
														</b-col>
														<b-col v-if="persona.kpi5.minuti_programmato > 0" cols="2" class="small text-center">
															<span>
																<em class="fa fa-clock-o"></em> {{ Math.round(persona.kpi5.minuti_fatto/60) }}
															</span>
														</b-col>
													</b-row>

												</div>
												<div v-else>
													<b-row>
														<b-col cols="8" xl="8">
															<b-progress :max="10" height="1.2rem"><b-progress-bar variant="slightGray" :value="10"></b-progress-bar></b-progress>
														</b-col>
														<b-col class="text-center small text-muted" cols="4">N.D.</b-col>
													</b-row>
												</div>
											</div>

										</div>

									</div>


									<div class="text-center" style="border-top: 1px solid rgb(100,100,100); position: relative;">

										<b-progress class="overall_progress" :max="100" height="2rem">
											<b-progress-bar style="border-radius: none"  :variant="persona.overall.variant" :value="persona.overall.value"></b-progress-bar>
										</b-progress>

										<div :style="persona.overall.value > 60 ? 'color: white' : ''" style="width: 100%; position: absolute; text-align: center; top: 0; left: 0; height: 2rem; padding-top: 0.2rem; font-size: 110%" class="font-weight-bold">
											<div>{{ persona.overall.value }}<small>%</small></div>
										</div>


										<b-popover :target="'popover-target'+persona.persona.id" triggers="hover" placement="top">
											<template #title>Informazioni sui Dati</template>

											<p class="small">Tutti i dati si riferiscono a <strong>Giorno Gara, +1 giorno, +2 giorni</strong> dal <strong>{{ ux.from | data('DD/MM/YYYY') }}</strong> al <strong>{{ ux.to | data('DD/MM/YYYY') }}</strong></p>

											<b-row tag="dl" class="small">

												<b-col class="text-right" tag="dt" cols="4">Acqua Fredda</b-col>
												<b-col tag="dd" cols="8">
													<em>Numero e Durata</em> di fasi ACQUA FREDDA eseguite nelle sessioni di tipo <em>RECOVERY</em> in relazione al TOTALE PROGRAMMATO.
												</b-col>

												<b-col class="text-right" tag="dt" cols="4">Compression Garments</b-col>
												<b-col tag="dd" cols="8">
													<em>Numero e Durata</em> di fasi COMPRESSION GARMENTS eseguite nelle sessioni di tipo <em>RECOVERY</em> in relazione al TOTALE PROGRAMMATO.
												</b-col>

												<b-col class="text-right" tag="dt" cols="4">Palestra</b-col>
												<b-col tag="dd" cols="8">
													<em>Numero e Durata</em> di fasi ESERCIZI PALESTRA eseguite nelle sessioni di tipo <em>RECOVERY</em> in relazione al TOTALE PROGRAMMATO.
												</b-col>

												<b-col class="text-right" tag="dt" cols="4">Campo</b-col>
												<b-col tag="dd" cols="8">
													<em>Numero e Durata</em> di fasi ESERCIZI FOOTBALL eseguite nelle sessioni di tipo <em>RECOVERY</em> in relazione al TOTALE PROGRAMMATO.
												</b-col>

												<b-col class="text-right" tag="dt" cols="4">Fisioterapia</b-col>
												<b-col tag="dd" cols="8">
													<em>Numero e Durata</em> di fasi con motivo DEFATICANTE eseguite nelle sessioni di tipo <em>FISIOTERAPIA</em> in relazione al TOTALE PROGRAMMATO.
												</b-col>

												<b-col class="text-right" tag="dt" cols="4">Nutrition</b-col>
												<b-col tag="dd" cols="8">
													<em>Numero</em> di sessioni eseguite calcolate in base alla percentuale di completamento delle fasi contenute in relazione al TOTALE PROGRAMMATO.<br /><span class="text-muted">(esempio: <em>una sessione da 10 fasi, di cui 5 eseguite conterà: 0.5</em>)</span>
												</b-col>

												<b-col class="text-right" tag="dt" cols="4">Overall</b-col>
												<b-col tag="dd" cols="8">
													Le KPI vengono normalizzate in percentuale e messe in media tra loro. I valori N.D. vengono ignorati
												</b-col>
											</b-row>


										</b-popover>

										<div style="position: absolute; right: 0.5rem; top: 0.2rem">
											<em :id="'popover-target'+persona.persona.id" class="fa fa-info-circle text-muted"></em>
										</div>

									</div>

								</b-card>

							</b-col>

						</b-row>

						<b-alert v-else-if="dati && dati.length == 0" variant="warning" show>
							Nessun dato trovato per la <strong>Squadra</strong> e il <strong>Periodo</strong> selezionato.
						</b-alert>
					</b-overlay>
				</div>
			</div>
		</div>
		
		<b-card no-body v-else>
			<b-table-simple v-if="dati && dati.length" :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 20) + 'px' : '90vh'" striped hover bordered responsive fixed small>
				<b-thead>
					<b-tr>
						<b-th style="vertical-align: middle;padding: 0; width:190px !important;" sticky-column>Player</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:40px !important;" title="Acqua Fredda">AF</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:40px !important;" title="Compression Garments">CG</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:40px !important;" title="Palestra">P</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:40px !important;" title="Campo">C</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:40px !important;" title="Fisioterapia">F</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:40px !important;" title="Nutrition">N</b-th>
						<b-th class="text-center" style="vertical-align:middle; padding:0; width:120px !important;">Overall</b-th>		
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="persona in datiOk" :key="persona.persona.id">	
						<b-td>	
							<strong style="color: ; text-transform: uppercase;">
								{{ persona.persona.cognome }}
							</strong>
							<span>
								{{ persona.persona.nome }}
							</span>
						</b-td>
						<b-td class="text-center">
							<div v-if="persona.kpi6">
								{{ persona.kpi6.fatto }}/{{ persona.kpi6.programmato }}
							</div>
							<div v-else>
							</div>
						</b-td>
						<b-td class="text-center">
							<div v-if="persona.kpi1">
								{{ persona.kpi1.fatto }}/{{ persona.kpi1.programmato }}
							</div>
							<div v-else>
							</div>
						</b-td>
						<b-td class="text-center">
							<div v-if="persona.kpi2">
								{{ persona.kpi2.fatto }}/{{ persona.kpi2.programmato }}
							</div>
							<div v-else>
							</div>
						</b-td>
						<b-td class="text-center">
							<div v-if="persona.kpi3">
								{{ persona.kpi3.fatto }}/{{ persona.kpi3.programmato }}
							</div>
							<div v-else>
							</div>
						</b-td>
						<b-td class="text-center">
							<div v-if="persona.kpi4">
								{{ persona.kpi4.fatto }}/{{ persona.kpi4.programmato }}
							</div>
							<div v-else>
							</div>
						</b-td>
						<b-td class="text-center">
							<div v-if="persona.kpi5">
								{{ persona.kpi5.fatto }}/{{ persona.kpi5.programmato }}
							</div>
							<div v-else>
							</div>
						</b-td>
						<b-td>
							<div class="text-center" style="position: relative;">
								<b-progress class="overall_progress" :max="100" height="1.3rem">
									<b-progress-bar style="border-radius: none"  :variant="persona.overall.variant" :value="persona.overall.value"></b-progress-bar>
								</b-progress>

								<div :style="persona.overall.value > 60 ? 'color: white' : ''" style="width: 100%; position: absolute; text-align: center; top: 0; left: 0; height: 1.3rem; padding-top: 0rem; font-size: 100%" class="font-weight-bold">
									<div>{{ persona.overall.value }}<small>%</small></div>
								</div>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-card>
	</section>
</template>

<style type="text/css">

.overall_progress {
	border-radius: 0px !important;
}

.bg-slightGray {
	background-color: #fbfbfb;
}

.bg-lightGray {
	background-color: #e9ecef;
	color: black;
}

</style>

<script>
// fbba00
// 990a2c
var Auth = require('../../../auth.js');
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
export default {
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function(){
		return {
			selected: null,
			i: 0,
			ux: {
				hideDisabled: false,
				sort: this.configWidget && this.configWidget.sortBy ? this.configWidget.sortBy : null,
				order: this.configWidget && this.configWidget.order ? this.configWidget.order : 1,
				to: moment().format('YYYY-MM-DD'),
				from: moment().add(this.getDays(), 'days').format('YYYY-MM-DD'),
			},
			canFilter: false,
			dati: null,
			hidePreset: null
		}
	},
	computed: {
		datiOk: function() {

			this.i++;

			if(this.ux.hideDisabled == false) {
				var dati = _.filter(this.dati, function(a) {
					return this.hidePreset.indexOf(a.persona_id) == -1;
				}.bind(this));
			} else {
				var dati = this.dati;
			}

			if(this.ux.sort == null)
				return dati;

			return dati.sort(function(a,b) {

				if(this.ux.sort == 'overall')
					return this.ux.order * ((b.overall.value) - (a.overall.value))
				else
					return this.ux.order * ((b[this.ux.sort] && b[this.ux.sort].fatto ? b[this.ux.sort].fatto : 0) - (a[this.ux.sort] && a[this.ux.sort].fatto ? a[this.ux.sort].fatto : 0))
			}.bind(this));

		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		'ux.from': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		'ux.to': function(a,b) {
			if(b != null)
				this.canFilter = true;
		},
		rosaTeamId: {
			immediate: true,
			handler: function() {
				this.getDashboard();
			}
		},
		'ux.sort': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'sortBy', this.ux.sort);
			}
		},
		'ux.order': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'order', this.ux.order);
			}	
		},
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},
	},
	components: {
	},
	created: function () {
		if(this.isWidget) {
			if(this.configWidget && this.configWidget.hidePlayers) {
				this.hidePreset = this.configWidget.hidePlayers;
			} else {
				this.hidePreset = [];
			}
		} else if(this.user.presets().recoveryDashboardHide != undefined) {
			this.hidePreset = _.cloneDeep(this.user.presets().recoveryDashboardHide)
		} else {
			this.hidePreset = [];
		}

	},
	filters: {
		duration: function(seconds) {
			return new Date(parseFloat(seconds*1000)).toISOString().slice(11, 19);
		},
		data: function(i, format) {
			return moment(i).format(format || 'D/M/YY HH:mm')
		}
	},
	methods: {
		refreshData: function() {
			this.getDashboard();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		svuotaPersone: function() {
			this.hidePreset = [];
			this.savePersone(this.hidePreset);
		},
		savePersone: function(persone) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'hidePlayers', persone);
				this.ux.hideDisabled = false;
			} else {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/recovery-set-persone', { persone }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.ux.hideDisabled = false;
				});
			}
		},
		downloadDati: function() {

			var url = [this.$store.state.apiEndPoint + '/atlas/dashboard/recovery/csv/' + this.user.user.id + '/' + this.user.user.api_token];

			url.push('?from=' + this.ux.from);
			url.push('&to=' + this.ux.to);
			url.push('&team_id=' + this.rosaTeamId);

			if(this.hidePreset.length > 0) {
				url.push('&hide_players=' + this.hidePreset);
			}
			window.open(url.join(''));

		},
		test: function(e) {
			$(e.target).fadeIn();
		},
		applyPreset: function(period) {

			if(this.isWidget) {
		  		this.$emit('updateConfigWidget', this.isWidget, 'period', period);
		  	}

		  	var days = -7;
		  	if(period == 'lastWeek') {
		  		days = -7;
		  	} else if(period == 'lastMonth') {
		  		days = -7*4;
		  	} else if(period == 'lastTrimester') {
		  		days = -7*4*3;
		  	}

			this.ux.from = moment().add(days, 'days').format('YYYY-MM-DD');
			this.ux.to = moment().format('YYYY-MM-DD');

			this.getDashboard();

		},
		setOrder: function(order) {

			if(this.ux.sort == order) {
				this.ux.order *= -1;
			} else
			this.ux.order = 1;

			this.ux.sort = order;
			$('.face').fadeOut();
		},
		getDashboard: function() {

			this.dati = null;
			this.canFilter = null;

			this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/recovery', {
				team_id: this.rosaTeamId,
				from: this.ux.from,
				to: this.ux.to
			}).then(function(data) {
				this.dati = data.data;
			});

		},

		getDays: function() {

		  	var days = -7;
			if(this.configWidget) {
				if(this.configWidget.period) {
					var days = -7;
					if(this.configWidget.period == 'lastWeek') {
						days = -7;
					} else if(this.configWidget.period == 'lastMonth') {
						days = -7*4;
					} else if(this.configWidget.period == 'lastTrimester') {
						days = -7*4*3;
					}
				}
			}
			return days;
		},
	}
}

</script>