<template id=" acad_val_new">
	<section class="acad_val_new">

		<headapp></headapp>

		<menusub></menusub>
		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="card-title">
					<span>Request</span>
				</div>
				<div class="content">
					<b-row align-v="center">
						<b-col>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Department: 
								</b-col>
								<b-col cols="2">	
									<b-form-select v-model="form.department" :options="options_department"></b-form-select>
								</b-col>
								<b-col>
									<b-form-input v-model="form.department_text" placeholder="Tipe here more specification"></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Content: 
								</b-col>
								<b-col cols="2">	
									<b-form-select v-model="form.content" :options="options_content"></b-form-select>
								</b-col>
								<b-col>
									<b-form-input v-model="form.content_text" placeholder="Tipe here more specification"></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Required by: 
								</b-col>
								<b-col>
									<b-form-input v-model="form.required_by" placeholder="es. Sponsor name, etc..."></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Period: 
								</b-col>
								<b-col>
									<b-form-input v-model="form.period" placeholder="es. First week of February, Within the month of..."></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Location: 
								</b-col>
								<b-col cols="2">	
									<b-form-select v-model="form.location" :options="options_location"></b-form-select>
								</b-col>
								<b-col>
									<b-form-input v-model="form.location_text" placeholder="es. Sala Champions, Campo, etc..."></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Player/s: 
								</b-col>
								<b-col cols="2">	
									<strong><em>Desiderata</em></strong>
								</b-col>
								<b-col>
									<multiselect v-model="form.players_desiderata" track-by="id" label="name" :options="players" :multiple="true" :searchable="true" placeholder="Select player/s" :max="4"></multiselect>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
								</b-col>
								<b-col cols="2">	
									<strong><em>Alternative</em></strong>
								</b-col>
								<b-col>
									<multiselect v-model="form.players_alternative" track-by="id" label="name" :options="players" :multiple="true" :searchable="true" placeholder="Select player/s" :max="4"></multiselect>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Timing: 
								</b-col>
								<b-col>
									<b-form-input v-model="form.timing" placeholder=" es. 10 minutes, 20 minutes, ..."></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Dress code: 
								</b-col>
								<b-col>
									<b-form-input v-model="form.dress_code" placeholder="es. Home kit, training kit, etc..."></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Description: 
								</b-col>
								<b-col>
									<b-form-textarea
									id="textarea"
									v-model="form.description"
									rows="3"
									max-rows="6"
									placeholder="All the event details"
									></b-form-textarea>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2">
								<b-col cols="2">
									Note: 
								</b-col>
								<b-col>
									<b-form-input v-model="form.note" placeholder="es. English speaker, no Adidas player, ..."></b-form-input>
								</b-col>
							</b-row>
							<b-row align-v="center" class="mb-2" v-if="auth.isAdmin() || auth.isUserEnable('player_access_req_user') || auth.user.id == utente_id">
									<b-col cols="2">
										Status: 
									</b-col>
									<b-col cols="3">
										<b-form-radio-group
										v-model="form.status"
										>
										<b-form-radio :value="0">In progress</b-form-radio>
										<b-form-radio :value="1">Approved</b-form-radio>
										<b-form-radio :value="2">Canceled</b-form-radio>
									</b-form-radio-group>

								</b-col>
								<b-col>
									<b-form-input v-model="form.status_text" placeholder="Enter the reason for the cancellation"></b-form-input>
								</b-col>
							</b-row>
							<fieldset v-show="auth.isUserEnable('egnyte')">
								<b-row>
									<b-col cols="2">
										Attachments:
									</b-col>
									<b-col cols="10">
										<b-form-group id="fileGroup">
											<b-form-row v-for="(item, index) in form.files" :key="index" class="mb-3 pt-3 pb-2 border-bottom" v-show="!item.removed || auth.isUserEnable('player_access_req_user') || auth.isAdmin()">
												<b-col cols="12">
													<b-row>
														<b-col v-if="item.path">
															{{ getNomeFile(item.path) }}
															<b-button class="ml-1" variant="primary" @click="downloadFile(item.id)" title="View file"><i class="fa fa-search" aria-hidden="true"></i></b-button>
														</b-col>
													</b-row>
												</b-col>
											</b-form-row>
										</b-form-group>
									</b-col>
								</b-row>
							</fieldset>

						</b-col>
					</b-row>
					<b-button class="ml-1 mt-4" variant="primary" @click="downloadRichiesta" title="Print PDF">Print PDF</b-button>
				</div>	
			</div>
		</div>


		<footerapp></footerapp>

	</section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
var Auth = require('../../../auth.js');
export default {

	data: function() {
		return {
			players: [],
			form: {
				department: null,
				department_text: null,
				content: null,
				content_text: null,
				required_by: null,
				period: null,
				location: 0,
				location_text: null,
				players_desiderata: [],
				players_alternative: [],
				timing: null,
				dress_code: null,
				description: null,
				note: null,
				status: null,
				status_text: null,
				files: [],
			},
			options_department: [
			{ value: null, text: '-- Select --'}, 
			{ value: 1, text: 'Communication'},
			{ value: 2, text: 'Partnerships'},
			{ value: 3, text: 'Brand'},
			{ value: 4, text: 'Roma Department'},
			{ value: 5, text: 'Digital'},
			{ value: 7, text: 'Sustainability Department'},
			{ value: 6, text: 'Other'}
			],
			options_content: [
			{ value: null, text: '-- Select --'}, 
			{ value: 1, text: 'Interviews'},
			{ value: 2, text: 'Shootings (foto&video)'},
			{ value: 3, text: 'Events'},
			{ value: 4, text: 'Appereances'},
			{ value: 5, text: 'Other'}
			],
			options_location: [
			{ value: 0, text: '-- Select --'}, 
			{ value: 1, text: 'Internal'},
			{ value: 2, text: 'External'}
			],
			utente_id: null,
		}
	},

	components: {
		headapp: require('../../layout/headapp.vue').default,
		menusub: require('../../layout/menusub.vue').default,
		footerapp: require('../../layout/footerapp.vue').default,
	},

	created: function () {
		this.getPlayers();
		if(this.$route.params.id) {
			axios.get(`/playeraccess/richiesta/` + this.$route.params.id)
			.then((response) => {
				var data = response.data;

				this.form.department = data.department;
				this.utente_id = data.utente_id;
				this.form.department_text = data.department_text;
				this.form.content = data.content;
				this.form.content_text = data.content_text;
				this.form.required_by = data.required_by;
				this.form.period = data.period;
				this.form.location = data.location;
				this.form.location_text = data.location_text;
				this.form.players_desiderata = JSON.parse(data.players_desiderata);
				this.form.players_alternative = JSON.parse(data.players_alternative);
				this.form.timing = data.timing;
				this.form.dress_code = data.dress_code;
				this.form.description = data.description;
				this.form.note = data.note;
				this.form.status = data.status;
				this.form.status_text = data.status_text;
				
				var k = 0;
				data.files.forEach((el, index) => {
					this.form.files[k] = {
						id: el.id,
						path: el.path,
						removed: el.removed,
						file: '',
					};
					k++;
				});

			}).catch((err) => {
				this.loading = false;
			});
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},

		user: function() {
			return Auth.user;
		},
	},

	methods: {
		downloadFile(id) {
			window.open(this.$store.state.apiEndPoint + '/playeraccess/download/egnyte/request/file/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},

		getNomeFile(doc) {
			var array = doc.split('/');
			return array[array.length - 1];
		},

		getPlayers: function() {				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: 13 }}).then((response) => {
				if (response.ok)
				{
					this.players = response.data.map(item => ({								
						name: item.cognome + ' ' + item.nome,
						id: item.id,
					}));
					this.players.unshift({name: 'Team', id: -1});
				}
			}, (response) => {
			});
		},

		downloadRichiesta() {
			window.open(this.$store.state.apiEndPoint + '/playeraccess/richiesta/pdf/' + this.$route.params.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
	},

	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return date;
		}
	}, 

}
</script>
