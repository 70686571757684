<template>
	<b-form>
		<b-row align-v="center">
			<b-col cols="2">
				<b-form-group label="Data Previsto Pagamento" class="small">
					<b-form-input :disabled="disabled" type="date" size="sm" v-model="importo.data_previsto_pagamento"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="2">
				<b-form-group label="Dal" class="small">
					<b-form-input :disabled="disabled" type="date" size="sm" v-model="importo.dal"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="2">
				<b-form-group label="Al" class="small">
					<b-form-input :disabled="disabled" type="date" size="sm" v-model="importo.al"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="2">
				<b-form-group>
					<template #label>
						<b-row>
							<b-col class="small">Stipendio Lordo</b-col>
						</b-row>
					</template>
					<b-form-input :disabled="disabled" type="number" size="sm" v-model.number="importo.stipendio_annuo_lordo"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="2">
				<b-form-group>
					<template #label>
						<b-row>
							<b-col class="small">Stipendio Netto</b-col>
						</b-row>
					</template>
					<b-form-input :disabled="disabled" type="number" size="sm" v-model.number="importo.stipendio_annuo_netto"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="2" class="text-center">
				<b-button-group>
					<b-btn size="xs" :disabled="disabled" variant="outline-success" class="mr-0" @click.prevent="applyRow">Applica</b-btn>
					<b-btn v-if="importo.id" size="xs" variant="outline-secondary" class="mr-0"><NotesHas entity="Persona_Contratto_Importo" :entityId="importo.id" :interact="true" @refresh="$emit('refresh')"></NotesHas></b-btn>
					<b-btn size="xs" :disabled="disabled" variant="outline-danger" @click.prevent="$emit('rim')" class="mr-0">Rimuovi</b-btn>
				</b-button-group>

				<div class="small text-muted" v-if="disabled">(Già pagato)</div>
			</b-col>
		</b-row>
	</b-form>
</template>

<script>


export default {
	props: [ 'importo' ],
	components: {
		NotesHas: require('./../notes/has.vue').default,
	},
	computed: {
		disabled: function() {
			return this.importo.pagato == 1;
		}
	},
	methods: {
		applyRow: function() {

			console.log('Apply!');
			this.$emit('netto', this.importo.stipendio_annuo_netto);
			this.$emit('lordo', this.importo.stipendio_annuo_lordo);

		}
	}
};

</script>