<template>
	<div class="my-3">
		<b-form-group label="Lingua">
			<b-form-select v-model="estensione.paese_id">
				<b-form-select-option v-for="a in thePaesi" :value="a.id">{{a.lingua}}</b-form-select-option>
			</b-form-select>
		</b-form-group>
	</div>
</template>

<script>

import sessionMixin from '@/mixins/sessione.js';
import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ 
		pageBase,
		sessionMixin
	],
	data: function() {
		return {
			paesi: null
		}
	},
	created: function() {
		if(this.model != undefined && _.size(this.estensione) == 0) {
			this.$set(this.model, this.field, { paese_id: null  });
		}
		this.getPaesi();
	},
	computed: {
		thePaesi: function() {
			return _.sortBy(_.filter(this.paesi, function(a) { return a.lingua; }), ['lingua'],['asc']);
		}
	},
	methods: {
		getPaesi: function() {

			this.$http.get(this.apiPath + '/paesi').then(function(data) {
				this.paesi = data.data;
			})

		}
	},
	props: [ 'estensione', 'model', 'field' ],
};
</script>