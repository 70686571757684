<template id="permessi">
  <section class="permessi">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>Permessi</span>
        </div>

        <div class="content">
          <admin-table
          api-url="/admin/permessi"
          :columns="columns"
          :editFormData="infoData"
          :createFormData="newData"
          :canDelete="false"
          :defaultFilter="false"
          @showModal="onShowModal"
          @createError="createError"
          @resetCreateFormData="resetCreateFormData">
            <template slot="filters">
            <b-row align-v="center">
              <b-col cols="3">
                <b-form-input v-model="filters.nome" placeholder="Nome" />
              </b-col>
              <b-col cols="3">
                <b-form-input v-model="filters.descrizione" placeholder="Descrizione" />
              </b-col>
              <b-col cols="3">
              <b-form-checkbox v-model="filters.hasCategory" :unchecked-value="null" :value="1" >Has Category</b-form-checkbox>
              </b-col>
            </b-row>
          </template>
            <template slot="edit-modal-body" slot-scope="row">
              <b-form-group label="Il permesso necessita di categoria">
             <b-form-checkbox v-model="infoData.hasCategory" :unchecked-value="null" :value="1">Has Category</b-form-checkbox>
            </b-form-group>  
            <b-form-group label="Descrizione">
              <b-form-textarea placeholder="Inserisci una descrizione per il permesso" v-model="infoData.info" />
            </b-form-group> 
            </template>
            <template slot="create-modal-body" slot-scope="row">
              <b-form-group label="Nome">
             <b-form-input type="text" placeholder="Nome" v-model="newData.nome" />
            </b-form-group>
            <b-form-group label="Il permesso necessita di categoria">
             <b-form-checkbox v-model="newData.hasCategory" :unchecked-value="null" :value="1">Has Category</b-form-checkbox>
            </b-form-group>  
            <b-form-group label="Descrizione">
              <b-form-textarea placeholder="Inserisci una descrizione per il permesso" v-model="newData.info" />
            </b-form-group> 
            </template>
        </admin-table>
      </div>
    </div>
  </div>
  <footerapp></footerapp>
</section>
</template>

<script>
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default
  },

  data() {
    return {
      columns: [
      { key: 'id', label: 'ID', sortable: true },
      { key: 'nome', label: 'Nome', sortable: true },
      { key: 'info', label: 'Descrizione', formatter: value => value ? value.info : '', sortable: true },
      { key: 'hasCategory', label: 'Has Category', sortable: true },
      { key: 'actions', label: 'Azioni' },
      ],
      infoData: {
        info: null,
        hasCategory: null
      },
      newData: {
        nome: '',
        info: null,
        hasCategory: null
      },
      filters: {
        nome: '',
        descrizione: '',
        hasCategory: null,
      },
    };
  },
  watch: {
    filters: {
      handler() {
        this.$root.$emit('admin-table::filter', this.filters);
      },      
      deep: true,
    },
  },
  methods: {
    createError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(types.ALERTS_PUSH, { msg: err.response.data.error, status: 0 });
      }
    },
    onShowModal(row) {
      if(row.item.info) {
      this.infoData.info = row.item.info.info;
      } else {
        this.infoData.info = null;
      }
      this.infoData.hasCategory = row.item.hasCategory;
    },
    resetCreateFormData() {
      this.newData.nome = '';
      this.newData.info = null;
      this.newData.hasCategory = null;
    },
  }
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051 !important;
}
</style>
