<template>
	<div>
		<label>Nome</label>
		<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>
		<b-form-group class="mt-2" label="Sorgente del video">
			<b-form-radio-group v-model="fase.entity.sorgente" :disabled="fase.entity.id  && fase.entity.id > 0">
				<b-form-radio value="videocartella">Cartella videotags</b-form-radio>
				<b-form-radio value="upload">Video AS Roma</b-form-radio>
				<b-form-radio value="filebrowser">Pre/Post Partita</b-form-radio>
			</b-form-radio-group>
		</b-form-group>
		<div v-if="fase.entity.sorgente == 'videocartella'">
			<b-form-group label="Seleziona una cartella videotags" v-if="!fase.entity.videocartella_id">
				<b-row class="mt-1">
					<b-col>
						<b-form-select v-model="rosa_cartelle" size="sm" class="mb-1">
							<option value="0">-- rosa --</option>
							<option v-for="team in user.rosaTeams" :value="team.id" :key="team.id">
								{{ team.categoria.name }} 
							</option>
						</b-form-select>
					</b-col>
					<b-col>
						<b-form-select v-model="utente_cartelle" size="sm" class="mb-1">
							<option value="0">-- utente --</option>
							<option v-for="item in utenti_cartelle" :value="item.id" :key="item.id">
								{{ item.nomeCompleto }} 
							</option>
						</b-form-select>
					</b-col>
					<b-col>
						<b-form-select v-model="cartella_id" size="sm" class="mb-1">
							<option value="null">-- cartelle --</option>
							<option v-for="item in cartelle" :value="item.id" :key="item.id">
								{{ item.nome }} 
							</option>
						</b-form-select>
					</b-col>
					<b-col cols="2">
						<b-button v-if="cartella_id" size="sm" @click="selezionaCartella">Scegli</b-button>
					</b-col>
				</b-row>	
			</b-form-group>
			<div v-else>
				<b-card no-body :class="['mb-1 p-2', fase.entity.videocartella.riassunto ? 'riassunto-' + fase.entity.videocartella.riassunto : '' ]">
					<b-row>
						<b-col cols="11" v-b-toggle="'cartella_' + fase.entity.videocartella_id" style="cursor:move">
							<div>{{ fase.entity.videocartella.nome }}</div>
							<div><small>{{ durataCartella(fase.entity.videocartella) }}</small></div>
							<div><small>{{ fase.entity.videocartella.utente.nomeCompleto }}</small></div>
						</b-col>
						<b-col v-b-toggle="'cartella_' + fase.entity.videocartella_id">
							<i class="when-closed fa fa-caret-right"></i>
							<i class="when-opened fa fa-caret-down"></i>
						</b-col>
					</b-row>
					<b-collapse :id="'cartella_' + fase.entity.videocartella_id">
						<div v-for="(videotag, index) in fase.entity.videocartella.videotags" :key="'cvt_' + videotag.id">
							<b-card no-body :class="['mb-1 p-2 default', videotag.peso ]">
								<b-row>
									<b-col cols="1" @click="selectClips(index)">
										<i class="fa fa-play" aria-hidden="true"></i>
									</b-col>
									<b-col cols="7" @click="selectClips(index)">
										<small>{{ videotag.inizio }} - {{ videotag.fine }}</small>
									</b-col>
									<b-col>
										<i title="preferiti" class="fa fa-heart" aria-hidden="true" v-if="videotag.preferiti"></i>
									</b-col>
									<b-col>
										<i v-b-tooltip.hover :title="videotag.nota" class="fa fa-info-circle" aria-hidden="true" v-if="videotag.nota"></i>
									</b-col>
								</b-row>
							</b-card>
						</div>
					</b-collapse>
				</b-card>
			</div>
		</div>
		<div v-if="fase.entity.sorgente == 'upload'">
			<div v-if="!fase.entity.video.id">
				<b-form-group label="Titolo (facoltativo)">
					<b-form-input v-model="fase.entity.video.titolo"></b-form-input>
				</b-form-group>
				<b-form-group label="Mostra i video di:">
					<b-form-radio-group v-model="filters_date">
						<b-form-radio value="day">Oggi</b-form-radio>
						<b-form-radio value="week">Ultimi 7 giorni</b-form-radio>
						<b-form-radio value="month">Ultimi 30 giorni</b-form-radio>
						<b-form-radio value="all">Tutti</b-form-radio>
					</b-form-radio-group>
				</b-form-group>
				<b-form-group label="Video caricati sul server ASRoma" v-if="fase.entity.video.type == 'asroma' && filters_date">
					<div v-if="!upload_loading" style="width:100%;" class="mt-1">
						<span class="font-bold">Loading...</span>
						<img src="/assets/varie/loading.gif" style="width: 30px;" />
					</div>
					<span class="font-bold" v-if="upload_loading && upload_video.length == 0">Nessun video trovato sul tuo archivio!</span>
					<b-form-select v-model="fase.entity.video.fileNameVideo" v-if="upload_loading && upload_video.length > 0">
						<option :value="null">Seleziona video</option>
						<optgroup :label="video_obj.utente" v-for="video_obj in upload_video">
							<option :value="video.url" v-for="video in video_obj.fileList">{{ video.titolo }} caricato il {{ video.data | formatDateHour }}</option>
						</optgroup>
					</b-form-select>
				</b-form-group>
				<b-row class="mt-3">
					<b-col cols="8" class="border-top">
						<b-button size="sm" class="mt-3" @click="showUpload">Carica nuovo video</b-button>
						<b-row v-if="show_upload" class="mt-3">
							<b-col>
								<b-embed type="iframe" aspect="16by9" :src="upload_iframe" style="height:300px;"></b-embed>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<div v-else>
				<b-card class="mb-2">
					<b-row>
						<b-col cols="6">
							<b-embed type="video" controls aspect="16by9" :poster="fase.entity.video.thumb ? fase.entity.video.thumb : '/assets/varie/missing.jpg'" preload="none" :controlsList="!auth.isUserEnable('video_download') ? 'nodownload' : ''" oncontextmenu="return false;">
								<source :src="fase.entity.video.video"/>		
							</b-embed>
						</b-col>
						<b-col class="ml-3">
							<h5>{{ getTitle(fase.entity.video) }}</h5>
							<p class="card-text">
								Caricato da <span class="color-rosso font-bold"> {{fase.entity.video.utente.nomeCompleto}} </span>
							</p>
							<p class="card-text">
								il <span class="font-italic"> {{ fase.entity.video.data | formatDateHour}} </span>
							</p>
							<p class="card-text">
								Sorgente: <span class="font-bold"> {{ fase.entity.video.tipo }} </span>
							</p>
						</b-col>
					</b-row>
				</b-card>
			</div>
		</div>
		<div v-if="fase.entity.sorgente == 'filebrowser'">
			<div v-if="!fase.entity.filebrowser_url">
				Seleziona partita
				<b-form-select v-model="partita_id" size="sm" class="mb-1">
					<option value="null">-- partite --</option>
					<option v-for="item in partite" :value="item.id" :key="item.id">
						{{ item.nome }}
					</option>
				</b-form-select>
				Pre/post partita
				<b-form-select v-model="fase.entity.filebrowser_path" size="sm" class="mb-1">
					<option value="" disabled>-- pre partita --</option>
					<option v-for="item in files_pre" :value="item.path">
						{{ item.basename }}
					</option>
					<option value="" disabled>-- post partita --</option>
					<option v-for="item in files_post" :value="item.path">
						{{ item.basename }}
					</option>
				</b-form-select>
			</div>
			<div v-else>
				<b-card class="mb-2">
					<b-row>
						<b-col cols="6">
							<b-embed type="video" controls aspect="16by9" poster="/assets/varie/missing.jpg" preload="none" :controlsList="!auth.isUserEnable('video_download') ? 'nodownload' : ''" oncontextmenu="return false;">
								<source :src="fase.entity.filebrowser_url"/>		
							</b-embed>
						</b-col>
						<b-col class="ml-3">
							<h5>{{ filename }}</h5>
							<p class="card-text">
								<span class="color-rosso font-bold"> {{ partita_sel ? partita_sel.nome : '' }} </span>
							</p>
							<p class="card-text">
								<span class="font-italic"> {{ root == 'pre' ? 'Pre partita' : 'Post partita'}} </span>
							</p>
						</b-col>
					</b-row>
				</b-card>
			</div>
		</div>
		<b-modal id="clipsModal" size="xl" ref="clipsModal" :title="titoloClips" class="fs-modal" @hidden="onHiddenClips" @shown="onShowClips" ok-only ok-title="Chiudi">
			<div>
				<video
				id="video-wrapper"
				class="video-js vjs-default-skin vjs-16-9"
				controls
				data-setup="{}">
			</video>
			<b-pagination v-show="playlist.length > 1" size="sm" align="center" :total-rows="playlist.length" v-model="count" :per-page="1" @change="selectPageClips" class="mt-1" :limit="40"></b-pagination>
		</div>
	</b-modal>
</div>
</template>

<script>
import faseMixin from '@/mixins/fase.js';
import axios from 'axios';
import moment from 'moment';
import videojs from 'video.js';
import 'videojs-playlist';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	mixins: [faseMixin],
	props: [ 'fase', 'disabled' ],
	data: function() {
		return {
			showPickCartella: true,
			cartella_id: null,
			utente_cartelle: 0,
			rosa_cartelle: 0,
			utenti_cartelle: [],	
			cartelle: [],	
			titoloClips: '',
			count: 0,
			playlist: [],

			upload_loading: true,
			upload_video: [],
			show_upload: false,
			filters_date: null,
			upload_iframe: '',

			partite: [],
			partita_id: null,
			files_pre: [],
			files_post: [],
			root: '',
			filename: '',
		}
	},
	created: function () {
		this.loadCartelle();
		if(this.fase.entity.sorgente == 'filebrowser') {
			this.getPartite();
		}
		if(this.fase.entity.filebrowser_path && this.fase.entity.filebrowser_path != '') {
			var array = this.fase.entity.filebrowser_path.split('/'); 
			this.partita_id = array[2];
			this.root = array[3];
			this.filename = array[4];
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		partita_sel: function() {
			if(this.partite.length > 0 && this.partita_id) {
				return this.partite.find(item => item.id == this.partita_id);
			}
			return null;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	methods: {
		loadUtentiCartelle() {
			this.utenti_cartelle = [];
			if(this.rosa_cartelle != 0) {
				this.$http.get(this.$store.state.apiEndPoint + '/videotags/utenti/rosa/' + this.rosa_cartelle).then((response) => {
					if (response.ok)
					{
						this.utenti_cartelle = response.data;
					}
				}, (response) => {

				});
			} 
		},
		loadCartelle() {
			this.loadingCartelle = true;
			this.$http.get(this.$store.state.apiEndPoint + '/videotags/cartelle/' + this.utente_cartelle + '/' + this.rosa_cartelle).then((response) => {
				if (response.ok)
				{
					this.cartelle = response.data;
					this.loadingCartelle = false;
				}
			}, (response) => {

			});
		},

		selezionaCartella() {
			var cartella = this.cartelle.find(item => item.id == this.cartella_id);
			console.log(cartella);
			if(cartella) {
				this.fase.entity.videocartella_id = cartella.id;
				this.fase.entity.videocartella = cartella;
			}
		},
		durataCartella(cartella) {
			var time = 0;
			for(var x in cartella.videotags) {
				var videotag = cartella.videotags[x];
				time = time + (moment(videotag.fine, "HH:mm:ss") - moment(videotag.inizio, "HH:mm:ss"));
			}
			return moment.utc(time).format("HH:mm:ss");
		},
		selectClips(index) {
			var titolo = '';
			var clips = [];
			var video = null;
			this.count = 1;
			this.playlist = [];
			if(this.fase.entity.sorgente == 'videocartella') {
				titolo = this.fase.entity.videocartella.nome + ' di ' + this.fase.entity.videocartella.utente.nomeCompleto;
				clips = this.fase.entity.videocartella.videotags;
			}
			this.titoloClips = titolo;
			if(video) {
				var source = {
					sources: [{
						src: video.video,
						type: 'video/mp4'
					}]
				};
				this.playlist.push(source);	
			} else {
				for(var i in clips) {
					var clip = clips[i];
					var source = {
						sources: [{
							src: this.$store.state.apiEndPoint + '/videotag/clip/' + clip.id + "/" + Auth.user.id + "/" + Auth.user.api_token,
							type: 'video/mp4'
						}]
					};
					this.playlist.push(source);	
				}
				this.count = index + 1;
			}
			this.$refs.clipsModal.show();
		},
		onShowClips() {
			if(!this.$video) {
				this.$video = videojs('video-wrapper');
				this.$video.on('playlistitem', this.autoSelectPage);
			}
			this.$video.playlist(this.playlist);
			this.$video.playlist.autoadvance(0);
			if(this.count > 1) {
				this.selectPageClips(this.count);
			}
			this.$video.play();
		},
		onHiddenClips() {
			this.$video.playlist([]);
			this.$video.pause();
			this.$video.reset();
			this.$video.dispose();
			this.$video = null;		
		},
		selectPageClips(page) {
			this.$video.playlist.currentItem(page - 1);    
		},
		autoSelectPage() {
			this.count = (this.$video.playlist.currentIndex() + 1);
		},
		closeModal() {
			this.$refs.clipsModal.hide();
		},
		showUpload() {
			this.show_upload = !this.show_upload;		
		},
		getUploadIFrame() {
			this.$http.get(this.$store.state.apiEndPoint + '/video/upload/iframe').then((response) => {
				if (response.ok) {
					this.upload_iframe = response.data;
				}
			}, (response) => {
			});
		},
		getUploadVideo() {
			if(this.rosaTeamId) {
				this.$http.get(this.$store.state.apiEndPoint + '/video/upload/list', {params: { team_id: this.rosaTeamId, filters_date: this.filters_date }}).then((response) => {
					if (response.ok)
					{
						this.upload_video = response.data;
						this.upload_loading = true;
					}
				}, (response) => {

				});
			}
		},
		getTitle(video) {
			var title = "";
			if(video.titolo != "") {
				title = video.titolo;
			} else {
				title = this.auth.isAdmin() ? video.video : video.file;
			}
			return title;
		},	
		getPartite() {
			if(this.rosaTeamId && this.seasonId) {
				const formData = new FormData();
				formData.append("team_id", this.rosaTeamId);
				formData.append("season_id", this.seasonId);
				this.$http.post(this.$store.state.apiEndPoint + '/football/partite', formData).then((response) => {
					if (response.ok)
					{
						this.partite = response.data.map(item => ({
							nome: item.tipo_categoria.tipo.nome == 'Nazionale' ? '['+ item.tipo_categoria.tipo.nome + '] ' + item.home_team + ' VS ' + item.away_team : '['+ item.tipo_categoria.tipo.nome + '] ' + item.home.name + ' VS ' + item.away.name + ' - ' + moment(item.data).format('DD/MM/YYYY HH:mm'),
							id: item.id,
						}));
					}
				}, (response) => {

				});
			}
		},

		getFilesPreSpostPartita() {
			if(this.partita_id) {
				this.$http.get(this.$store.state.apiEndPoint + '/prepost/match/files/' + this.partita_id + '/file-video').then((response) => {
					if (response.ok)
					{
						this.files_pre = response.data.files_pre;
						this.files_post = response.data.files_post;
					}
				}, (response) => {

				});
			}
		},
	},
	watch: {
		rosa_cartelle: function () {
			this.cartella_id = null;
			this.utente_cartelle = 0;
			this.loadUtentiCartelle();
		},

		utente_cartelle: function () {
			this.cartella_id = null;
			this.loadCartelle();
		},
		
		filters_date: function () {
			this.fase.entity.video.fileNameVideo = null;
			this.upload_loading = false;
			this.getUploadVideo(); 
		},

		show_upload: function() {
			if(this.show_upload && this.upload_iframe == '') {
				this.getUploadIFrame();
			}
		},

		'fase.entity.sorgente': function() {
			if(this.fase.entity.sorgente == 'filebrowser' && this.partite.length == 0) {
				this.getPartite();
			}
		},

		partita_id: function() {
			if(this.partita_id != 'null') {
				this.getFilesPreSpostPartita();
			} else {
				this.files_pre = [];
				this.files_post = [];
				this.fase.entity.filebrowser_path = null;
			}
		},
	},
	beforeDestroy() {
		if(this.$video) {
			this.$video.dispose();
			this.$video = null;
		}
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},		
	},
	components: {
	},
}
</script>
<style scoped>
.overlayDiv {
	text-align: center;
	margin: 0px auto;
	background: rgba(255, 255, 255, 0);
	position: absolute;
	top: 0;
	left: 0;
	width:100%;
	height: 100%;
	z-index:10001;
}
</style>