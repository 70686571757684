<template>
  <b-col :cols="last ? 12 : 6" :class="[last ? 'last' : '']">
    <b-button
      variant="primary"
      @click.prevent="setNewTagSkill(skill)"
      :class="{ active: isSkillActive }" :title="skill.name">
      <span>{{ skill.name }}</span> 
      <i class="fa fa-caret-right ml-1" aria-hidden="true" v-if="!!skill.children"></i>
    </b-button>
  </b-col>
</template>

<script>
import { pick } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'Skill',
  props: {
    skill: Object,
    child: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      selectedSkillIds: 'newTagSkillIds',
    }),

    isSkillActive() {
      return this.selectedSkillIds && this.selectedSkillIds.includes(this.skill.id);
    },
  },

  methods: {
    setNewTagSkill(skill) {
      if (skill.children) {
        this.$emit('childClicked', skill);
        return;
      }

      if (this.selectedSkillIds.includes(skill.id)) {
        this.$store.commit('removeSkill', skill.id);
      } else {
        this.$store.commit('updateTagField', {
          key: 'skill',
          value: pick(skill, ['id', 'name']),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  height: 69px;
  font-weight: 400;
  font-size: 0.9rem;
  white-space: nowrap;
  display: flex;
  align-items: center;

  span {
    flex: 1;
    overflow: hidden;
/*  text-overflow: ellipsis; */
  }
}

.col, [class*=col-] {
  padding-right: 3px;
  padding-left: 3px;
  padding-bottom: 3px;
}
</style>
