<template id="player">
  <div class="figurinagiocatore h-100 pb-2">
    <div class="card mb-2 h-100">
      <div class="row">
        <div class="col-12 text-center">
          <router-link :to="{ name: 'football_opponent_view', params: { id: giocatore.id, team_id: this.$route.params.team_id, category_id: this.$route.params.category_id }}"><img :src="giocatore.avatar" class="img-fluid"></router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="font-bold text-center">{{ giocatore.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        giocatore: {
            type: Object,
            required: true
        }
    },
}
</script>
