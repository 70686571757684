<template>
  <b-row>
    <b-col cols="12">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1452 871.3" style="enable-background:new 0 0 1452 871.3;" xml:space="preserve">
        <g id="sfondo">
          <rect id="XMLID_19_" x="64" y="718.3" class="st0" width="1324" height="21.8"/>
          <rect id="XMLID_13_" x="64" y="740" class="st1" width="1324" height="33.8"/>
          <polygon id="XMLID_680_" class="st2" points="64,718.2 1388,718.2 1164.3,118 287.7,118 	"/>
        </g>
        <g id="elementi">
          <polygon class="st3" points="110.8,591.6 1341.2,591.6 1336.7,580.5 115.3,580.5 	"/>
          <path id="XMLID_749_" class="st4" d="M297.7,590.5l18.7,1.1c0.3-0.6,38.9-51.3,38.9-51.3l823.2,0.8l-44.8,50.4h16.8l51-57.7
            l-859.1-0.1L297.7,590.5z"/>
          <path id="XMLID_4780_" class="st5" d="M325.5,177.7c0,0,1.5,28.6,5,137.6s0.7,196.1,0.7,196.1h788c0,0-4.8-85.3-1.1-167
            c3.7-81.7,1.7-166.8,1.7-166.8s-167,2.1-396,2.1S325.5,177.7,325.5,177.7z"/>
          <rect id="XMLID_4779_" x="326.6" y="504.5" class="st6" width="794.3" height="12.5"/>
          <polygon id="XMLID_4778_" class="st6" points="326.4,504 300.6,578.4 300.6,586.6 312.6,586.6 338.5,516 	"/>
          <polygon id="XMLID_4777_" class="st6" points="1121.6,504 1147.4,578.4 1147.4,586.6 1135.3,586.6 1109.5,516 	"/>
          <polyline id="XMLID_4776_" class="st7" points="306.6,591.6 306.6,226.2 1142,226.2 1142,591.6 	"/>
          <path id="XMLID_4775_" class="st8" d="M306.6,222.5c0,0,1.9-22,10.9-36.9c10.1-16.7,11.8-4.3,8,29.9s-18.9,99.8-18.9,99.8"/>
          <path id="XMLID_21_" class="st8" d="M1140.1,222.5c0,0-1.9-22-10.9-36.9c-10.1-16.7-11.8-4.3-8,29.9s18.9,99.8,18.9,99.8"/>
          <path id="XMLID_4773_" class="st5" d="M306.6,226.2l18.9-48.5c0,0,171.8,2.1,398.8,2.1s400.1-2.1,400.1-2.1l17.6,48.5H306.6z"/>
          <path id="XMLID_4772_" class="st9" d="M325.5,177.7c0,0,2,72.4,6.3,173.8c4.3,101.4,7.4,159.9,7.4,159.9s-28.4,72.9-35.3,67.8
            c-4.1-3.1,0-254.2,2.7-353l3.8-30.1L325.5,177.7z"/>
          <path id="XMLID_4771_" class="st9" d="M1119.8,177.7c0,0,2.1,75.4-2.2,176.8c-4.3,101.4,1.5,157,1.5,157s20.4,72.9,27.3,67.8
            c4.1-3.1,0-254.2-2.7-353L1119.8,177.7z"/>
          <rect id="XMLID_675_" x="315" y="236" class="st10" width="818.6" height="177.8"/>
          <rect id="XMLID_734_" x="315" y="413.8" class="st11" width="818.6" height="177.8"/>
          <rect id="XMLID_774_" x="315" y="236" class="st12" width="272.9" height="355.6"/>
          <rect id="XMLID_789_" x="860.7" y="236" class="st12" width="272.9" height="355.6"/>
          <rect id="XMLID_804_" x="315" y="236" class="st13" width="272.9" height="355.6"/>
          <rect id="XMLID_803_" x="860.7" y="236" class="st13" width="272.9" height="355.6"/>
          <rect id="XMLID_802_" x="587.9" y="236" class="st13" width="272.9" height="355.6"/>
          <line id="XMLID_2_" class="st13" x1="315" y1="413.8" x2="1133.6" y2="413.8"/>
        </g>
        <g id="alto_sx" @mouseover="hoverSection = 'alto_sx'" @mouseout="hoverSection = null" @click="clicked(1)">
          <rect id="XMLID_16_" x="315" y="236" class="st14" width="272.9" height="177.8"/>
        </g>
        <g id="check_alto_sx" v-if="hoverSection === 'alto_sx' || selectedSection === 1">
          <path class="st2" d="M481.1,300.8c-1.2-1.2-3.2-1.2-4.4,0l-38.3,38.3l-17.1-17.1c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
            l19.3,19.3c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l40.3-40.3
            C482.3,304,482.3,302,481.1,300.8z"/>
        </g>
        <g id="basso_dx" @mouseover="hoverSection = 'basso_dx'" @mouseout="hoverSection = null" @click="clicked(6)">
          <rect id="XMLID_4_" x="860.7" y="413.8" class="st14" width="272.9" height="177.8"/>
        </g>
        <g id="check_basso_dx" v-if="hoverSection === 'basso_dx' || selectedSection === 6">
          <path class="st2" d="M1026.8,478.6c-1.2-1.2-3.2-1.2-4.4,0l-38.3,38.3l-17.1-17.1c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
            l19.3,19.3c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l40.3-40.3
            C1028.1,481.8,1028.1,479.8,1026.8,478.6z"/>
        </g>
        <g id="basso_centro" @mouseover="hoverSection = 'basso_centro'" @mouseout="hoverSection = null" @click="clicked(5)">
          <rect id="XMLID_5_" x="587.9" y="413.8" class="st14" width="272.9" height="177.8"/>
        </g>
        <g id="check_basso_centro" v-if="hoverSection === 'basso_centro' || selectedSection === 5">
          <path class="st2" d="M754,478.6c-1.2-1.2-3.2-1.2-4.4,0l-38.3,38.3l-17.1-17.1c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
            l19.3,19.3c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1L754,483
            C755.2,481.8,755.2,479.8,754,478.6z"/>
        </g>
        <g id="basso_sx" @mouseover="hoverSection = 'basso_sx'" @mouseout="hoverSection = null" @click="clicked(4)">
          <rect id="XMLID_6_" x="315" y="413.8" class="st14" width="272.9" height="177.8"/>
        </g>
        <g id="check_basso_sx" v-if="hoverSection === 'basso_sx' || selectedSection === 4">
          <path class="st2" d="M481.1,478.6c-1.2-1.2-3.2-1.2-4.4,0l-38.3,38.3l-17.1-17.1c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
            l19.3,19.3c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l40.3-40.3
            C482.3,481.8,482.3,479.8,481.1,478.6z"/>
        </g>
        <g id="alto_centro" @mouseover="hoverSection = 'alto_centro'" @mouseout="hoverSection = null" @click="clicked(2)">
          <rect id="XMLID_1_" x="587.9" y="236" class="st14" width="272.9" height="177.8"/>
        </g>
        <g id="check_alto_centro" v-if="hoverSection === 'alto_centro' || selectedSection === 2">
          <path class="st2" d="M754,300.8c-1.2-1.2-3.2-1.2-4.4,0l-38.3,38.3l-17.1-17.1c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
            l19.3,19.3c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l40.3-40.3
            C755.2,304,755.2,302,754,300.8z"/>
        </g>
        <g id="alto_dx" @mouseover="hoverSection = 'alto_dx'" @mouseout="hoverSection = null" @click="clicked(3)">
          <rect id="XMLID_3_" x="860.7" y="236" class="st14" width="272.9" height="177.8"/>
        </g>
        <g id="check_alto_dx" v-if="hoverSection === 'alto_dx' || selectedSection === 3">
          <path class="st2" d="M1026.8,300.8c-1.2-1.2-3.2-1.2-4.4,0l-38.3,38.3l-17.1-17.1c-1.2-1.2-3.2-1.2-4.4,0c-1.2,1.2-1.2,3.2,0,4.4
            l19.3,19.3c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1l40.3-40.3
            C1028.1,304,1028.1,302,1026.8,300.8z"/>
        </g>
      </svg>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <b-form-radio-group v-model="insideArea" :options="areas" />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <b-form-radio-group v-model="actionGoal" :options="actions" />
      </b-form-group>
    </b-col>
    <b-col v-if="actionGoal === false" cols="12">
      <b-form-group label="Tipo di goal da fermo">
        <b-form-select v-model="standstillGoalType" :options="standstillGoalTypes" />
      </b-form-group>
    </b-col>
    <b-col v-if="actionGoal === true" cols="12">
      <b-form-group label="Tipo di goal da azione">
        <b-form-select v-model="standstillGoalType" :options="actionGoalTypes" />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'GoalSection',

  data() {
    return {
      selectedSection: null,
      hoverSection: null,
      insideArea: null,
      actionGoal: null,
      standstillGoalType: null,
    };
  },

  computed: {
    areas() {
      return [
        { text: 'Da dentro area', value: true },
        { text: 'Da fuori area', value: false }
      ];
    },

    actions() {
      return [
        { text: 'Goal da azione', value: true },
        { text: 'Goal da fermo', value: false }
      ];
    },

    standstillGoalTypes() {
      return [
        { text: 'seleziona', value: null },
        { text: 'Punizione', value: 1 },
        { text: 'Punizione diretta', value: 2 },
        { text: 'Rigore', value: 3 },
        { text: 'Calcio d\'angolo', value: 4 }
      ];
    },

    actionGoalTypes() {
      return [
        { text: 'seleziona', value: null },
        { text: 'Azione manovrata', value: 1 },
        { text: 'Attacco profondità', value: 2 },
        { text: 'Pressione', value: 3 },
      ];
    },
  },

  watch: {
    insideArea() {
      this.$emit('area-changed', this.insideArea);
    },

    actionGoal() {
      this.standstillGoalType = null;
      this.$emit('action-goal-changed', this.actionGoal);
    },

    standstillGoalType() {
      this.$emit('standstill-goal-type-changed', this.standstillGoalType);
    },
  },

  methods: {
    clicked(section) {
      this.selectedSection = section;
      this.$emit('clicked', section);
    },

    reset() {
      this.selectedSection = null;
      this.hoverSection = null;
      this.insideArea = null;
      this.actionGoal = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.st0{fill:#41903F;}
.st1{fill:#D1D1D1;}
.st2{fill:#6AB75F;}
.st3{opacity:0.7;fill:#FFFFFF;}
.st4{opacity:0.1;}
.st5{opacity:0.25;fill:#FFFFFF;}
.st6{fill:#B2B2B2;}
.st7{fill:none;stroke:#FFFFFF;stroke-width:19.2486;stroke-miterlimit:10;}
.st8{fill:none;stroke:#FFFFFF;stroke-width:8.4961;stroke-miterlimit:10;}
.st9{opacity:0.5;fill:#FFFFFF;}
.st10{opacity:0.2;fill:#36A9E1;}
.st11{opacity:0.2;fill:#F39200;}
.st12{opacity:0.5;fill:#9D9D9C;}
.st13{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}
.st14{opacity:0.6;fill:#FFFFFF;}

#alto_sx, #check_alto_sx,
#basso_dx, #check_basso_dx,
#basso_centro, #check_basso_centro,
#basso_sx, #check_basso_sx,
#alto_centro, #check_alto_centro,
#alto_dx, #check_alto_dx {
  cursor: pointer;
}
</style>
