<template>
	<div>
		<h4 class="card-title">Report di rieducazione sportiva {{ injury.return ? ' del ' +  dateFormat(injury.return) : '' }}</h4>
		<b-row class="mb-1">
			<b-col cols="6"><b-card class="h-100"><div><strong>1. ANAMNESI</strong> <em>(meccanismo d'infortunio)</em> : in data {{ injury.data | formatDate}} {{ injury.note }}</div><div><strong>ESITO DIAGNOSTICO (localizzazione e gravità)</strong> : {{ injury.diagnosis }}</div></b-card></b-col>
			<b-col cols="6"><b-card class="h-100"><div><strong>2. PROGNOSI (BAMC/BAC)</strong> : {{ injury.prognosis_days_min ? injury.prognosis_days_min : 'min'}} - {{ injury.prognosis_days_max ? injury.prognosis_days_max : 'max' }} gg , average {{ injury.prognosis_days_avg ? injury.prognosis_days_avg : 'N/D'}} gg</div><div><strong>RITORNO ALL'ALLENAMENTO CON LA SQUADRA(RTT) : {{ days_diff }} gg</strong></div></b-card></b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="4">
				<b-card class="h-100"><div><strong>3. FISIOTERAPIA</strong></div> 
					<b-row>
						<b-col cols="10"><E-Chart :key="injury.id + 'g1'" style="width: 100%; height: 300px" :option="graphOptionFisio"></E-Chart></b-col>
						<b-col cols="2" align-self="center"><div class="text-center" style="font-size:0.9rem">Minuti totali<br/><b-badge style="background-color:#a0cd62"> <span style="font-size:1.5rem">{{ minuti_totali_fisio }}</span></b-badge></div></b-col>
					</b-row>
				</b-card>
			</b-col>
			<b-col cols="4">
				<b-card class="h-100"><div><strong>4. RIATLETIZZAZIONE</strong></div> 
					<b-row>
						<b-col cols="10"><E-Chart :key="injury.id + 'g2'" style="width: 100%; height: 300px" :option="graphOptionRiatletizzazione"></E-Chart></b-col>
						<b-col cols="2" align-self="center"><div class="text-center" style="font-size:0.9rem">Minuti totali<br/><b-badge style="background-color:olive"> <span style="font-size:1.5rem">{{ minuti_totali_riatletizzazione }}</span></b-badge></div></b-col>
					</b-row>
				</b-card>
			</b-col>
			<b-col cols="4"><b-card class="h-100"><div><strong>5. TOTALI</strong><E-Chart :key="injury.id + 'g3'" style="width: 100%; height: 300px" :option="graphOptionTotali"></E-Chart></div></b-card></b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="2">
				<b-card class="h-100">
					<div><strong>6. ADERENZA ALLA PROPOSTA DI RIATLETIZZAZIONE:</strong><b-button size="xs" class="ml-2" @click="$refs.aderenzaModal.show()"><i class="fa fa-pencil"/></b-button><div><E-Chart key="aderenza" style="width: 100%; height: 130px" :option="graphAderenza"></E-Chart></div></div>
				</b-card>
			</b-col>
			<b-col cols="5">
				<b-card class="h-100"><div><strong>7. PROGRAMMA DI RIATLETIZZAZIONE:</strong><b-button title="Gestisci programma" size="xs" class="ml-2 mb-1" :variant="programma_edit_mode ? 'primary' : 'outline-secondary'" @click="programma_edit_mode = !programma_edit_mode" v-if="injury && injury.rtp && injury.rtp.id"><i class="fa fa-pencil"/></b-button><DocumentiManager v-if="injury && injury.rtp && injury.rtp.id" :collapsed="false" entity="MedicalInjury_Rtp" :entityId="injury.rtp.id" :tipoId="17" :preview="!programma_edit_mode"></DocumentiManager>
					<div v-else class="text-muted">
						<em>Per poter inserire il programma devi prima salvare il report</em>
					</div></div></b-card>
				</b-col>
				<b-col cols="5">
					<b-card class="h-100">
						<div class="mb-1"><strong>8. SPECIFICITA' DEL PROGRAMMA </strong> <b-button title="Gestisci kpi" size="xs" class="ml-2" :variant="kpi_edit_mode ? 'primary' : 'outline-secondary'" @click="kpi_edit_mode = !kpi_edit_mode"><i class="fa fa-pencil"/></b-button><b-button size="xs" class="ml-2" variant="primary" @click="kpi_show_chart = !kpi_show_chart">{{ kpi_show_chart ? 'tabella' : 'radar' }}</b-button></div>
						<b-table-simple small striped hover bordered v-if="!kpi_show_chart">
							<b-thead>
								<b-tr>
									<b-th></b-th>
									<b-th>ATTUALE</b-th>
									<b-th colspan="2" class="text-center">MOD. PRESTATIVO</b-th>
									<b-th v-if="kpi_edit_mode"></b-th>
								</b-tr>
								<b-tr>
									<b-th></b-th>
									<b-th></b-th>
									<b-th>ALLENAMENTI</b-th>
									<b-th>PARTITE</b-th>
									<b-th v-if="kpi_edit_mode"></b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="kpi in form.kpi">
									<b-td>{{ getLabelKpi(kpi) }}</b-td>
									<b-td>{{ kpi_values_attuale[kpi] != '' ? kpi_values_attuale[kpi] : '&nbsp;' }}</b-td>
									<b-td>{{ kpi_values_mod_prestativo_allenamenti[kpi] != '' ? kpi_values_mod_prestativo_allenamenti[kpi] : '&nbsp;' }}</b-td>
									<b-td>{{ kpi_values_mod_prestativo_partite[kpi] != '' ? kpi_values_mod_prestativo_partite[kpi] : '&nbsp;' }}</b-td>
									<b-td v-if="kpi_edit_mode"><b-button size="xs" variant="primary" @click="removeKpi(kpi)"><i class="fa fa-minus"/></b-button></b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
						<E-Chart key="kpi" style="width: 100%; height: 350px" :option="graphKpi" v-if="kpi_show_chart"/>
						<div class="small">ATTUALE: valore massimo registrato durante il periodo riabilitativo </div>
						<div class="small">MOD. PRESTATIVO: valore massimo registrato su partite e allenamenti negli ultimi 30 giorni prima dell'infortunio </div>

						<b-row class="mt-2" v-if="kpi_edit_mode"><b-col><b-form-select size="sm" class="mb-2" v-model="kpi_selected" :options="kpi_options"></b-form-select></b-col><b-col cols="2"><b-button size="sm" variant="primary" @click="addKpi"><i class="fa fa-plus"/></b-button></b-col></b-row>
						<div v-if="kpi_edit_mode"><b-button size="xs" variant="primary" @click="addAllKpi()">Aggiungi tutti</b-button><b-button size="xs" variant="secondary" @click="removeAllKpi">Rimuovi tutti</b-button></div>
					</b-card>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col>
					<b-card class="h-100">
						<strong>9. ELEMENTI DEL PROGRAMMA</strong> 
						<b-row>
							<b-col>
								<div><strong><em>tipo di esercizio</em></strong></div>
								<div><b-form-textarea class="mb-1" v-model="form.esercizi" rows="8"></b-form-textarea></div>
								<div><strong><em>Frequenza degli allenamenti : </em></strong> {{ frequenza_allenamenti }} al giorno </div>
								<div><strong><em>Durata media/range degli allenamenti : </em></strong>{{ durata_media_allenamenti }} minuti</div>
								<div><strong><em>Intensità dell'allenamento RPE : </em></strong> range {{ min_rpe }} - {{ max_rpe }}</div>
							</b-col>
							<b-col>
								<div><strong>PROGRESSIONE DEL PROGRAMMA:</strong></div>
								<div><b-form-textarea class="mb-1" v-model="form.progressione_programma" rows="8"></b-form-textarea></div>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="6">
					<b-card class="h-100"><strong>10. CONSIDERAZIONE FINALE:</strong> <b-button size="xs" class="ml-2" @click="$refs.finalModal.show()"><i class="fa fa-pencil"/></b-button>
						<b-row>
							<b-col cols="3"><E-Chart key="evoluzione" style="width: 100%; height: 130px" :option="graphEvoluzione"></E-Chart></b-col>
							<b-col cols="3"><E-Chart key="rendimento" style="width: 100%; height: 130px" :option="graphRendimento"></E-Chart></b-col>
							<b-col cols="3"><E-Chart key="fiducia" style="width: 100%; height: 130px" :option="graphFiducia"></E-Chart></b-col>
							<b-col cols="3" align-self="center" class="text-center"><div class="text-center" :style="'margin:auto;line-height: 130px;color:#FFFFFF;width:130px;height:130px;border-radius:50%;background-color:' + (form.pronto > 0 ? (form.pronto == 1 ? '#FF0059' : '#00B386') : '#CCCCCC')"><span style="display: inline-block;vertical-align: middle;font-weight:bold;font-size:1.3rem;">{{ form.pronto > 0 ? (form.pronto == 1 ? 'NON PRONTO' : 'PRONTO') : '&nbsp;' }}</span></div></b-col>
						</b-row>
					</b-card>
				</b-col>
				<b-col>
					<b-card class="h-100"><strong>11. RACCOMANDAZIONE: </strong> 
						<div><b-form-textarea rows="5" class="mb-1" v-model="form.raccomandazione"></b-form-textarea></div>
					</b-card>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col>
					<b-card class="h-100"><strong>12. ALLEGATI </strong><b-button title="Gestisci allegati" size="xs" class="ml-2 mb-1" :variant="allegati_edit_mode ? 'primary' : 'outline-secondary'" @click="allegati_edit_mode = !allegati_edit_mode" v-if="injury && injury.rtp && injury.rtp.id"><i class="fa fa-pencil"/></b-button>
						<DocumentiManager v-if="injury && injury.rtp && injury.rtp.id" :collapsed="false" entity="MedicalInjury_Rtp" :entityId="injury.rtp.id" :tipoId="18" :preview="!allegati_edit_mode" :preview_cols="6"></DocumentiManager>
						<div v-else class="text-muted">
							<em>Per poter inserire allegati devi prima salvare il report</em>
						</div>
					</b-card>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col>
					<b-card class="h-100"><div class="text-center"><strong>TOTALE CARICO DEGLI ALLENAMENTI </strong></div>
						<div class="my-2">
							<b-table-simple small striped hover responsive bordered>
								<b-thead>
									<b-tr>
										<th colspan="16" style="font-size:1.1rem;backgroundColor:#FFCA28">General Analysis</th>
									</b-tr>
									<b-tr>
										<th colspan="2"></th>
										<th colspan="4" class="text-center" style="backgroundColor: #dde6f1">Cinematico</th>
										<th colspan="2" class="text-center" style="backgroundColor: #e4dfed">Metabolico</th>
										<th colspan="4" class="text-center" style="backgroundColor: #f2dcdb">Neuromuscolare</th>
										<th colspan="4" class="text-center" style="backgroundColor: #feeada">Interno</th>
									</b-tr>
									<b-tr>
										<b-th width="15%" class="text-right">
											<a href="" @click.prevent="setOrdering('drill', 'date')">Date</a> <span v-if="'date' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
										</b-th>
										<b-th class="text-center">
											<a href="" @click.prevent="setOrdering('drill', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(min)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
											<a href="" @click.prevent="setOrdering('drill', 'total_distance')">Tot. Dist.</a> <span v-if="'total_distance' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
											<a href="" @click.prevent="setOrdering('drill', 'relative_distance')">D. Rel</a> <span v-if="'relative_distance' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m/min)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
											<a href="" @click.prevent="setOrdering('drill', 'cc_dist_15')">Dist > 15</a> <span v-if="'cc_dist_15' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #dde6f1">	
											<a href="" @click.prevent="setOrdering('drill', 'max_vel')">Vel Max</a> <span v-if="'max_vel' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(km/h)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
											<a href="" @click.prevent="setOrdering('drill', 'mp')">MP</a> <span v-if="'mp' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(W/Kg)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #e4dfed">	
											<a href="" @click.prevent="setOrdering('drill', 'edi')">EDI</a> <span v-if="'edi' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
											<a href="" @click.prevent="setOrdering('drill', 'pow_ev')">Pow. Ev.</a> <span v-if="'pow_ev' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
											<a href="" @click.prevent="setOrdering('drill', 'num_acc')">Acc</a> <span v-if="'num_acc' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
											<a href="" @click.prevent="setOrdering('drill', 'num_dec')">Dec</a> <span v-if="'num_dec' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #f2dcdb">	
											<a href="" @click.prevent="setOrdering('drill', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(n.)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
											<a href="" @click.prevent="setOrdering('drill', 'edward')">Edwards TL</a> <span v-if="'edward' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(ua)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
											<a href="" @click.prevent="setOrdering('drill', 'session_minutes')">Session</a> <span v-if="'session_minutes' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(min)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
											<a href="" @click.prevent="setOrdering('drill', 'rpe')">RPE</a> <span v-if="'rpe' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(ua)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #feeada">	
											<a href="" @click.prevent="setOrdering('drill', 'sessione_tl')">Session TL</a> <span v-if="'sessione_tl' == orderings.drill.key">{{ orderings.drill.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(ua)</div>
										</b-th>
									</b-tr>
								</b-thead>
								<b-tbody>
									<b-tr v-for="p in drillAnalysis">
										<b-td class="text-right">{{ p.date }} </b-td>
										<b-td class="text-center" width="5%">{{ p.gps_minutes }}</b-td>
										<b-td class="text-center" width="5%">{{ p.total_distance }}</b-td>
										<b-td class="text-center" width="5%">{{ p.relative_distance }}</b-td>
										<b-td class="text-center" width="5%">{{ p.cc_dist_15 }}</b-td>
										<b-td class="text-center" width="5%">{{ p.max_vel }}</b-td>
										<b-td class="text-center" width="5%">{{ p.mp }}</b-td>
										<b-td class="text-center" width="5%">{{ p.edi }}</b-td>
										<b-td class="text-center" width="5%">{{ p.pow_ev }}</b-td>
										<b-td class="text-center" width="5%">{{ p.num_acc }}</b-td>
										<b-td class="text-center" width="5%">{{ p.num_dec }}</b-td>
										<b-td class="text-center" width="5%">{{ p.sp_ev }}</b-td>
										<b-td class="text-center" width="5%">{{ p.edward }}</b-td>
										<b-td class="text-center" width="5%">{{ p.session_minutes }}</b-td>
										<b-td class="text-center" width="5%">{{ p.rpe }}</b-td>
										<b-td class="text-center" width="5%">{{ p.sessione_tl }}</b-td>
									</b-tr>
								</b-tbody>
								<b-tfoot>
									<b-tr>
										<b-th class="text-right">Media</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'gps_minutes') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'total_distance') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'relative_distance') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'cc_dist_15') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'max_vel') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'mp') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'edi') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'pow_ev') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'num_acc') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'num_dec') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'sp_ev') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'edward') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'session_minutes') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'rpe') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('drill', 'sessione_tl') }}</b-th>
									</b-tr>
									<b-tr>
										<b-th class="text-right">SD</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'gps_minutes') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'total_distance') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'relative_distance') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'cc_dist_15') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'max_vel') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'mp') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'edi') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'pow_ev') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'num_acc') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'num_dec') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'sp_ev') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'edward') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'session_minutes') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'rpe') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('drill', 'sessione_tl') }}</b-th>
									</b-tr>
								</b-tfoot>
							</b-table-simple>
						</div>

						<div>
							<b-table-simple small striped hover responsive bordered>
								<b-thead>
									<b-tr>
										<th colspan="16" style="font-size:1.1rem;backgroundColor:#FFCA28">Velocity Analysis</th>
									</b-tr>
									<b-tr>
										<b-th width="15%" class="text-right">
											<a href="" @click.prevent="setOrdering('velocity', 'date')">Date</a> <span v-if="'date' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
										</b-th>
										<b-th class="text-center">
											<a href="" @click.prevent="setOrdering('velocity', 'gps_minutes')">GPS</a> <span v-if="'gps_minutes' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(min)</div>
										</b-th>
										<b-th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('velocity', 'total_distance')">Tot Distance</a> <span v-if="'total_distance' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity_0_8')">0-8 km/h</a> <span v-if="'velocity_0_8' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity_8_13')">8-13 km/h</a> <span v-if="'velocity_8_13' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity_13_15')">13-15 km/h</a> <span v-if="'velocity_13_15' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity_15_20')">15-20 km/h</a> <span v-if="'velocity_15_20' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity_20_25')">20-25 km/h</a> <span v-if="'velocity_20_25' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity__25')">>25 km/h</a> <span v-if="'velocity__25' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity__15')">>15 km/h</a> <span v-if="'velocity__15' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity__20')">>20 km/h</a> <span v-if="'velocity__20' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(m)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
											<a href="" @click.prevent="setOrdering('velocity', 'sp_ev')">Sp. Ev.</a> <span v-if="'sp_ev' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(ua)</div>
										</b-th>
										<b-th rowspan="1" class="text-center" style="backgroundColor: #d0c6db">	
											<a href="" @click.prevent="setOrdering('velocity', 'velocity__15_p')">> 15 km/h</a> <span v-if="'velocity__15_p' == orderings.velocity.key">{{ orderings.velocity.order == -1 ? '↥' : '↧' }}</span>
											<div class="small text-muted">(%)</div>
										</b-th>
									</b-tr>
								</b-thead>
								<b-tbody>
									<b-tr v-for="p in velocityAnalysis">
										<b-td class="text-right">{{ p.date }} </b-td>
										<b-td class="text-center" width="6.5%">{{ p.gps_minutes }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.total_distance }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity_0_8 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity_8_13 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity_13_15 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity_15_20 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity_20_25 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity__25 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity__15 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity__20 }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.sp_ev }}</b-td>
										<b-td class="text-center" width="6.5%">{{ p.velocity__15_p }}</b-td>
									</b-tr>
								</b-tbody>
								<b-tfoot>
									<b-tr>
										<b-th class="text-right">Media</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'gps_minutes') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'total_distance') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_0_8') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_8_13') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_13_15') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_15_20') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity_20_25') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__25') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__15') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__20') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'sp_ev') }}</b-th>
										<b-th class="text-center" width="5%">{{ meanOf('velocity', 'velocity__15_p') }}</b-th>
									</b-tr>
									<b-tr>
										<b-th class="text-right">SD</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'gps_minutes') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'total_distance') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_0_8') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_8_13') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_13_15') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_15_20') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity_20_25') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__25') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__15') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__20') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'sp_ev') }}</b-th>
										<b-th class="text-center" width="5%">{{ standardDeviation('velocity', 'velocity__15_p') }}</b-th>
									</b-tr>
								</b-tfoot>
							</b-table-simple>
						</div>
						<div class="small"> Valori di tools analysis giornalieri registrati durante il periodo riabilitativo</div>
					</b-card>
				</b-col>
			</b-row>
			<b-button @click="saveRtp" variant="success">Save</b-button><b-button variant="primary" @click="download"><i class="fa fa-download" aria-hidden="true"></i> Download</b-button>
			<b-modal ref="aderenzaModal" title="Aderenza alla proposta di riatletizzazione">
				<b-form-group>
					<b-form-select size="sm" class="mb-2" v-model="form.aderenza" :options="aderenza_options"></b-form-select>
				</b-form-group>
			</b-modal>

			<b-modal ref="finalModal" title="Considerazioni finali">
				<b-form-group label="Evoluzione durante la fase RT">
					<b-form-select size="sm" class="mb-2" v-model="form.evoluzione" :options="evoluzione_options"></b-form-select>
				</b-form-group>
				<b-form-group label="Rendimento delle valutazioni funzionali con test in campo">
					<b-form-select size="sm" class="mb-2" v-model="form.rendimento" :options="rendimento_options"></b-form-select>
				</b-form-group>
				<b-form-group label="Questionario di fiducia (0/50)">
					<b-form-input type="number" :min="0" :max="50" :step="1" v-model="form.fiducia"></b-form-input>
				</b-form-group>
				<b-form-group label="Pronto per iniziale l'allenamento con la squadra">
					<b-form-select size="sm" class="mb-2" v-model="form.pronto" :options="pronto_options"></b-form-select>
				</b-form-group>
			</b-modal>
		</div>
	</template>

	<script>

	function buildKpiValues(datiBySessione) {
		var duration = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(_.filter(a, function(b) { return b.total_duration != null; }), 'total_duration')/60;
		}));
		var total_distance = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(_.filter(a, function(b) { return b.total_distance != null; }), 'total_distance');
		}));
		var relative_distance = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(_.filter(a, function(b) { return b.total_distance != null; }), 'total_distance') / (_.sumBy(_.filter(a, function(b) { return b.total_duration != null; }), 'total_duration')/60);
		}));
		var cc_dist_15 = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'catapult_velocity_band6_total_distance'),
				_.sumBy(a, 'catapult_velocity_band7_total_distance'),
				_.sumBy(a, 'catapult_velocity_band8_total_distance'),
				]);
		}));
		var max_vel = _.max(_.map(datiBySessione, function(a) {
			return _.maxBy(a, 'catapult_max_vel') ? _.maxBy(a, 'catapult_max_vel').catapult_max_vel : 0;
		}));
		var mp = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_mp_average')/_.size(a);
		}));
		var edi = _.max(_.map(datiBySessione, function(a) {
			return 100 * ((_.sumBy(a, 'catapult_equivalent_distance') / _.sumBy(a, 'total_distance'))-1);
		}));
		var pow_ev = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'catapult_metabolic_power_band3_total_effort_count'),
				_.sumBy(a, 'catapult_metabolic_power_band4_total_effort_count'),
				_.sumBy(a, 'catapult_metabolic_power_band5_total_effort_count'),
				_.sumBy(a, 'catapult_metabolic_power_band6_total_effort_count')
				])
		}));
		var num_acc = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'catapult_gen2_acceleration_band6_total_effort_count'),
				_.sumBy(a, 'catapult_gen2_acceleration_band7_total_effort_count'),
				_.sumBy(a, 'catapult_gen2_acceleration_band8_total_effort_count')
				])
		}));
		var num_dec = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'catapult_gen2_acceleration_band1_total_effort_count'),
				_.sumBy(a, 'catapult_gen2_acceleration_band2_total_effort_count'),
				_.sumBy(a, 'catapult_gen2_acceleration_band3_total_effort_count')
				])
		}));
		var sp_ev = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_gen2_velocity_band8_total_effort_count');
		}));
		var edward = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'firstbeat_heart_rate_50_60'),
				_.sumBy(a, 'firstbeat_heart_rate_60_70') * 2,
				_.sumBy(a, 'firstbeat_heart_rate_70_80') * 3,
				_.sumBy(a, 'firstbeat_heart_rate_80_90') * 4,
				_.sumBy(a, 'firstbeat_heart_rate_90') * 5,
				])/12;
		}));
		var session_minutes = _.max(_.map(datiBySessione, function(a) {
			return a[0].fase_durata;
		}));
		var rpe = _.max(_.map(datiBySessione, function(a) {
			return a[0].fase_rpe;
		}));
		var sessione_tl = _.max(_.map(datiBySessione, function(a) {
			return a[0].fase_rpe * a[0].fase_durata;
		}));
		var velocity_0_8 = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'catapult_velocity_band1_total_distance'),
				_.sumBy(a, 'catapult_velocity_band2_total_distance'),
				_.sumBy(a, 'catapult_velocity_band3_total_distance'),
				]);
		}));
		var velocity_8_13 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_velocity_band4_total_distance');	
		}));
		var velocity_13_15 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_velocity_band5_total_distance');	
		}));
		var velocity_15_20 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_velocity_band6_total_distance');	
		}));
		var velocity_20_25 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_velocity_band7_total_distance');	
		}));
		var velocity__25 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_velocity_band8_total_distance');	
		}));
		var velocity__15 = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'catapult_velocity_band6_total_distance'),
				_.sumBy(a, 'catapult_velocity_band7_total_distance'),
				_.sumBy(a, 'catapult_velocity_band8_total_distance'),
				]);
		}));
		var velocity__20 = _.max(_.map(datiBySessione, function(a) {
			return _.sum([
				_.sumBy(a, 'catapult_velocity_band7_total_distance'),
				_.sumBy(a, 'catapult_velocity_band8_total_distance'),
				]);
		}));
		var velocity__15_p = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sum([
				_.sumBy(a, 'catapult_velocity_band6_total_distance'),
				_.sumBy(a, 'catapult_velocity_band7_total_distance'),
				_.sumBy(a, 'catapult_velocity_band8_total_distance'),
				]) / _.sum([
				_.sumBy(a, 'catapult_velocity_band1_total_distance'),
				_.sumBy(a, 'catapult_velocity_band2_total_distance'),
				_.sumBy(a, 'catapult_velocity_band3_total_distance'),
				_.sumBy(a, 'catapult_velocity_band4_total_distance'),
				_.sumBy(a, 'catapult_velocity_band5_total_distance'),
				_.sumBy(a, 'catapult_velocity_band6_total_distance'),
				_.sumBy(a, 'catapult_velocity_band7_total_distance'),
				_.sumBy(a, 'catapult_velocity_band8_total_distance')
				]));
		}));
		var acc1 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_gen2_acceleration_band6_total_effort_count')/_.size(a);
		}));
		var acc2 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_gen2_acceleration_band7_total_effort_count')/_.size(a);
		}));
		var acc3 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_gen2_acceleration_band8_total_effort_count')/_.size(a);
		}));
		var dec3 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_gen2_acceleration_band1_total_effort_count')/_.size(a);
		}));
		var dec2 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_gen2_acceleration_band2_total_effort_count')/_.size(a);
		}));
		var dec1 = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(a, 'catapult_gen2_acceleration_band3_total_effort_count')/_.size(a);
		}));
		var avg_hr = _.max(_.map(datiBySessione, function(a) {
			return _.sumBy(_.filter(a, function(b) { return b.firstbeat_avg_hb != null; }), 'firstbeat_avg_hb')/_.filter(a, function(b) { return b.firstbeat_avg_hb != null; }).length;
		}));
		var avg_hr_p = _.max(_.map(datiBySessione, function(a) {
			var find_max_hr = a.find(b => b.firstbeat_max_hb_absolute);
			var max_hb_absolute = find_max_hr ? find_max_hr.firstbeat_max_hb_absolute :  null;
			return 100 * (_.sumBy(_.filter(a, function(b) { return b.firstbeat_avg_hb != null; }), 'firstbeat_avg_hb')/_.filter(a, function(b) { return b.firstbeat_avg_hb != null; }).length / max_hb_absolute);
		}));
		var max_hr = _.max(_.map(datiBySessione, function(a) {
			return _.maxBy(_.filter(a, function(b) { return b.firstbeat_max_hb != null; }), 'firstbeat_max_hb') ? _.maxBy(_.filter(a, function(b) { return b.firstbeat_max_hb != null; }), 'firstbeat_max_hb').firstbeat_max_hb : 0;
		}));
		var max_hr_p = _.max(_.map(datiBySessione, function(a) {
			var find_max_hr = a.find(b => b.firstbeat_max_hb_absolute);
			var max_hb_absolute = find_max_hr ? find_max_hr.firstbeat_max_hb_absolute :  null;
			return 100 * ((_.maxBy(_.filter(a, function(b) { return b.firstbeat_max_hb != null; }), 'firstbeat_max_hb') ? _.maxBy(_.filter(a, function(b) { return b.firstbeat_max_hb != null; }), 'firstbeat_max_hb').firstbeat_max_hb : 0) / max_hb_absolute);
		}));
		var hr_0_55 = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sumBy(a, 'firstbeat_heart_rate_55')/_.sumBy(a, 'firstbeat_heart_rate_check'));
		}));
		var hr_55_65 = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sumBy(a, 'firstbeat_heart_rate_55_65')/_.sumBy(a, 'firstbeat_heart_rate_check'));
		}));
		var hr_65_75 = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sumBy(a, 'firstbeat_heart_rate_65_75')/_.sumBy(a, 'firstbeat_heart_rate_check'));
		}));
		var hr_75_85 = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sumBy(a, 'firstbeat_heart_rate_75_85')/_.sumBy(a, 'firstbeat_heart_rate_check'));
		}));
		var hr_85_95 = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sumBy(a, 'firstbeat_heart_rate_85_95')/_.sumBy(a, 'firstbeat_heart_rate_check'));
		}));
		var hr_95 = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sumBy(a, 'firstbeat_heart_rate_95')/_.sumBy(a, 'firstbeat_heart_rate_check'));
		}));
		var hr_85 = _.max(_.map(datiBySessione, function(a) {
			return 100 * (_.sumBy(a, 'firstbeat_heart_rate_85')/_.sumBy(a, 'firstbeat_heart_rate_check'));
		}));
		var hr_85_raw = _.max(_.map(datiBySessione, function(a) {
			return  _.sumBy(a, 'firstbeat_heart_rate_85')/12;
		}));

		return {
			gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
			total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : '',
			relative_distance: isValidNumber(relative_distance) ? _.round(relative_distance, 0) : '',
			cc_dist_15: isValidNumber(cc_dist_15) ? _.round(cc_dist_15, 0) : '',
			max_vel: isValidNumber(max_vel) ? _.round(max_vel, 1) : '',
			mp: isValidNumber(mp) ? _.round(mp, 1) : '',
			edi: isValidNumber(edi) ? _.round(edi, 1) : '',
			pow_ev: isValidNumber(pow_ev) ? _.round(pow_ev, 0) : '',
			num_acc: isValidNumber(num_acc) ? _.round(num_acc, 2) : '',
			num_dec: isValidNumber(num_dec) ? _.round(num_dec, 2) : '',
			sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : '',
			edward: isValidNumber(edward) ? _.round(edward, 0) : '',
			session_minutes: isValidNumber(session_minutes) ? _.round(session_minutes, 0) : '',
			rpe: isValidNumber(rpe) ? _.round(rpe, 1) : '',
			sessione_tl: isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : '',
			velocity_0_8: isValidNumber(velocity_0_8) ? _.round(velocity_0_8, 0) : '',
			velocity_8_13: isValidNumber(velocity_8_13) ? _.round(velocity_8_13, 0) : '',
			velocity_13_15: isValidNumber(velocity_13_15) ? _.round(velocity_13_15, 0) : '',
			velocity_15_20: isValidNumber(velocity_15_20) ? _.round(velocity_15_20, 0) : '',
			velocity_20_25: isValidNumber(velocity_20_25) ? _.round(velocity_20_25, 0) : '',
			velocity__25: isValidNumber(velocity__25) ? _.round(velocity__25, 0) : '',
			velocity__15: isValidNumber(velocity__15) ? _.round(velocity__15, 0) : '',
			velocity__20: isValidNumber(velocity__20) ? _.round(velocity__20, 0) : '',
			velocity__15_p: isValidNumber(velocity__15_p) ? _.round(velocity__15_p, 1) : '',
			acc1: isValidNumber(acc1) ? _.round(acc1, 2) : '',
			acc2: isValidNumber(acc2) ? _.round(acc2, 2) : '',
			acc3: isValidNumber(acc3) ? _.round(acc3, 2) : '',
			dec3: isValidNumber(dec3) ? _.round(dec3, 2) : '',
			dec2: isValidNumber(dec2) ? _.round(dec2, 2) : '',
			dec1: isValidNumber(dec1) ? _.round(dec1, 2) : '',
			avg_hr: isValidNumber(avg_hr) ? _.round(avg_hr, 2) : '',
			avg_hr_p: isValidNumber(avg_hr_p) ? _.round(avg_hr_p, 1) : '',
			max_hr: isValidNumber(max_hr) ? _.round(max_hr, 0) : '',
			max_hr_p: isValidNumber(max_hr_p) ? _.round(max_hr_p, 0) : '',
			hr_0_55: isValidNumber(hr_0_55) ? _.round(hr_0_55, 2) : '',
			hr_55_65: isValidNumber(hr_55_65) ? _.round(hr_55_65, 2) : '',
			hr_65_75: isValidNumber(hr_65_75) ? _.round(hr_65_75, 2) : '',
			hr_75_85: isValidNumber(hr_75_85) ? _.round(hr_75_85, 2) : '',
			hr_85_95: isValidNumber(hr_85_95) ? _.round(hr_85_95, 2) : '',
			hr_95: isValidNumber(hr_95) ? _.round(hr_95, 2) : '',
			hr_85: isValidNumber(hr_85) ? _.round(hr_85, 2) : '',
			hr_85_raw: isValidNumber(hr_85_raw) ? _.round(hr_85_raw, 0) : '',
		}
	}

	function buildRpe(dati) {
		var rpeByDay = [];
		var datiWithRpe = _.filter(dati, function(a) { return a.fase_rpe != null; });
		var datiBySession = _.groupBy(datiWithRpe, 'sessione_id');
		var rpe = 0;
		_.forEach(datiBySession, function(b) {
			rpe += b[0].fase_rpe;
		});
		rpe = rpe/_.keys(datiBySession).length;
		return rpe;
	}

	function buildSessionTl(dati) {

		var datiWithRpeAndDurata = _.filter(dati, function(a) { return a.fase_rpe != null && a.fase_durata != null; });
		var datiBySession = _.groupBy(datiWithRpeAndDurata, 'sessione_id');
		var sessione_tl = 0;
		_.forEach(datiBySession, function(a) {
			sessione_tl += a[0].fase_rpe * a[0].fase_durata;
		});
		if(_.keys(datiBySession).length > 0) {
			return sessione_tl;
		}
		return null;
	}

	function buildDrill(date, dati) {

		var duration = _.sumBy(dati, 'total_duration')/60;
		var total_distance = _.sumBy(dati, 'total_distance');
		var relative_distance =  _.sumBy(dati, 'total_distance')/duration;
		var cc_dist_15 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]);
		var max_vel = _.maxBy(dati, 'catapult_max_vel') ? _.maxBy(dati, 'catapult_max_vel').catapult_max_vel : 0;
		var mp = _.sumBy(dati, 'catapult_mp_average')/_.size(dati);
		var edi = 100 * ((_.sumBy(dati, 'catapult_equivalent_distance') / _.sumBy(dati, 'total_distance'))-1);
		var pow_ev = _.sum([
			_.sumBy(dati, 'catapult_metabolic_power_band3_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band4_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band5_total_effort_count'),
			_.sumBy(dati, 'catapult_metabolic_power_band6_total_effort_count')
			]);
		var num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
			]);
		var num_dec =_.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
			]);
		var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count');
		var edward = _.sum([
			_.sumBy(dati, 'firstbeat_heart_rate_50_60'),
			_.sumBy(dati, 'firstbeat_heart_rate_60_70') * 2,
			_.sumBy(dati, 'firstbeat_heart_rate_70_80') * 3,
			_.sumBy(dati, 'firstbeat_heart_rate_80_90') * 4,
			_.sumBy(dati, 'firstbeat_heart_rate_90') * 5,
			])/12;
		var session_minutes = _.sum(_.map(_.groupBy(dati, 'sessione_id'), function(a) { return a[0].fase_durata; }));
		var rpe = buildRpe(dati);
		var sessione_tl = buildSessionTl(dati);

		return {
			date: date,
			gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
			total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : '',
			relative_distance: isValidNumber(relative_distance) ? _.round(relative_distance) : '',
			cc_dist_15: isValidNumber(cc_dist_15) ? _.round(cc_dist_15) : '',
			max_vel: isValidNumber(max_vel) ? _.round(max_vel, 1) : '',
			mp: isValidNumber(mp) ? _.round(mp, 1) : '',
			edi: isValidNumber(edi) ? _.round(edi, 1) : '',
			pow_ev: isValidNumber(pow_ev) ? _.round(pow_ev, 0) : '',
			num_acc: isValidNumber(num_acc) ? _.round(num_acc, 2) : '',
			num_dec: isValidNumber(num_dec) ? _.round(num_dec, 2) : '',
			sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : '',
			edward: isValidNumber(edward) ? _.round(edward, 0) : '',
			session_minutes: isValidNumber(session_minutes) ? _.round(session_minutes, 0) : '',
			rpe: isValidNumber(rpe) ? _.round(rpe, 1) : '',
			sessione_tl: isValidNumber(sessione_tl) ? _.round(sessione_tl, 0) : '',
			alert_d: _.filter(_.map(dati, 'alert_d')).length > 0,
			alert_v: _.filter(_.map(dati, 'alert_v')).length > 0,
			alert_h: _.filter(_.map(dati, 'alert_h')).length > 0
		}
	}

	function buildVelocity(date, dati) {

		var duration = _.sumBy(dati, 'total_duration')/60;
		var total_distance = _.sumBy(dati, 'total_distance');
		var velocity_0_8 =_.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
			]);
		var velocity_8_13 = _.sumBy(dati, 'catapult_velocity_band4_total_distance');
		var velocity_13_15 = _.sumBy(dati, 'catapult_velocity_band5_total_distance');
		var velocity_15_20 = _.sumBy(dati, 'catapult_velocity_band6_total_distance');
		var velocity_20_25 = _.sumBy(dati, 'catapult_velocity_band7_total_distance');
		var velocity__25 = _.sumBy(dati, 'catapult_velocity_band8_total_distance');
		var velocity__15 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]);
		var velocity__20 = _.sum([
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]);
		var sp_ev = _.sumBy(dati, 'catapult_gen2_velocity_band8_total_effort_count');
		var velocity__15_p = 100 * (_.sum([
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance'),
			]) / _.sum([
			_.sumBy(dati, 'catapult_velocity_band1_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band2_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band3_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band4_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band5_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band6_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band7_total_distance'),
			_.sumBy(dati, 'catapult_velocity_band8_total_distance')
			]));

		return {
			date: date,
			gps_minutes: isValidNumber(duration) ? _.round(duration, 0) : '',
			total_distance: isValidNumber(total_distance) ? _.round(total_distance, 0) : '',
			velocity_0_8: isValidNumber(velocity_0_8) ? _.round(velocity_0_8, 0) : '',
			velocity_8_13: isValidNumber(velocity_8_13) ? _.round(velocity_8_13, 0) : '',
			velocity_13_15: isValidNumber(velocity_13_15) ? _.round(velocity_13_15, 0) : '',
			velocity_15_20: isValidNumber(velocity_15_20) ? _.round(velocity_15_20, 0) : '',
			velocity_20_25: isValidNumber(velocity_20_25) ? _.round(velocity_20_25, 0) : '',
			velocity__25: isValidNumber(velocity__25) ? _.round(velocity__25, 0) : '',
			velocity__15: isValidNumber(velocity__15) ? _.round(velocity__15, 0) : '',
			velocity__20: isValidNumber(velocity__20) ? _.round(velocity__20, 0) : '',
			sp_ev: isValidNumber(sp_ev) ? _.round(sp_ev, 0) : '',
			velocity__15_p: isValidNumber(velocity__15_p) ? _.round(velocity__15_p, 1) : '',
			alert_d: _.filter(_.map(dati, 'alert_d')).length > 0,
			alert_v: _.filter(_.map(dati, 'alert_v')).length > 0,
			alert_h: _.filter(_.map(dati, 'alert_h')).length > 0
		}
	}

	function isValidNumber(a) {
		if(!_.isNumber(a) || _.isNaN(a) || a == 0 || !_.isFinite(a)) return false;
		return true;
	}


	import eChart from '@/components/layout/E-Chart/Base.vue';
	import moment from 'moment';
	import { mapState } from 'vuex';
	var Auth = require('@/auth.js');

	export default {
		data: function() {
			return {
				sessioni: [],
				sessioni_perse: [],
				partite_perse: [],
				dati_tools_attuale: [],
				dati_tools_mod_prestativo: [],
				sessioni_tools_mod_prestativo: [],
				sessioni_tools_attuale: [],
				aderenza_options: [ { value: 0, text: ''}, { value: 1, text: 'non buono'}, { value: 2, text: 'buono'}, { value: 3, text: 'eccellente'}],
				evoluzione_options: [ { value: 0, text: ''}, { value: 1, text: 'non favorevole'}, { value: 2, text: 'favorevole'}],
				rendimento_options: [ { value: 0, text: ''}, { value: 1, text: 'scarso'}, { value: 2, text: 'discreto'}, { value: 3, text: 'buono'}, { value: 4, text: 'ottimo'}],
				pronto_options: [ { value: 0, text: ''}, { value: 1, text: 'NO'}, { value: 2, text: 'SI'}],
				form: {
					aderenza: 0,
					kpi: [],
					esercizi: null,
					progressione_programma: null,
					raccomandazione: "- sarebbe raccomandato un programma preventivo di attivazione specifica di almeno 30gg (prioritario)\n- avendo un suo preparatore esterno  riteniamo utile una comunicazione per continuare la progressione.",
					evoluzione: 0,
					rendimento: 0,
					fiducia: 0,
					pronto: 0,
				},
				kpi_edit_mode: false,
				kpi_options: [
					{value: null, text: ' -- Seleziona -- '},
					{value: 'gps_minutes', text: 'GPS Minutes'},
					{value: 'total_distance', text: 'Total Distance'},
					{value: 'relative_distance', text: 'Relative Distance'},
					{value: 'cc_dist_15', text: 'Dist > 15'},
					{value: 'max_vel', text: 'Max Velocity'},
					{value: 'mp', text: 'MP'},
					{value: 'edi', text: 'EDI'},
					{value: 'pow_ev', text: 'Pow. Ev.'},
					{value: 'num_acc', text: 'Acc'},
					{value: 'num_dec', text: 'Dec'},
					{value: 'sp_ev', text: 'Sp. Ev.'},
					{value: 'edward', text: 'Edwards TL'},
					{value: 'session_minutes', text: 'Session Minutes'},
					{value: 'rpe', text: 'RPE'},
					{value: 'sessione_tl', text: 'Session TL'},

					{value: 'velocity_0_8', text: '0-8 km/h'},
					{value: 'velocity_8_13', text: '8-13 km/h'},
					{value: 'velocity_13_15', text: '13-15 km/h'},
					{value: 'velocity_15_20', text: '15-20 km/h'},
					{value: 'velocity_20_25', text: '20-25 km/h'},
					{value: 'velocity__25', text: '>25 km/h'},
					{value: 'velocity__15', text: '>15 km/h'},
					{value: 'velocity__20', text: '>20 km/h'},
					{value: 'velocity__15_p', text: '> 15 km/h %'},

					{value: 'acc1', text: 'Acc 1'},
					{value: 'acc2', text: 'Acc 2'},
					{value: 'acc3', text: 'Acc 3'},
					{value: 'dec3', text: 'Dec 3'},
					{value: 'dec2', text: 'Dec 2'},
					{value: 'dec1', text: 'Dec 1'},

					{value: 'avg_hr', text: 'AVG HR'},
					{value: 'avg_hr_p', text: 'AVG HR %'},
					{value: 'max_hr', text: 'Max HR'},
					{value: 'max_hr_p', text: 'Max HR %'},
					{value: 'hr_0_55', text: 'HR 0-55 %'},
					{value: 'hr_55_65', text: 'HR 55-65 %'},
					{value: 'hr_65_75', text: 'HR 65-75 %'},
					{value: 'hr_75_85', text: 'HR 75-85 %'},
					{value: 'hr_85_95', text: 'HR 85-95 %'},
					{value: 'hr_95', text: 'HR 95 %'},
					{value: 'hr_85', text: 'HR 85 %'},
					{value: 'hr_85_raw', text: 'HR 85'},
					],
				kpi_selected: null,
				orderings: {
					velocity: { key: 'date', order: -1 },
					drill: { key: 'date', order: -1 },
				},
				kpi_show_chart: false, 
				programma_edit_mode: false,
				allegati_edit_mode: false,
			}
		},

		props: [ 'injury' ],

		components: {
			'E-Chart': eChart,
			DocumentiManager: require('@/components/layout/documenti/manager.vue').default,
		},

		created: function () {
		},
		watch: {
			'injury': {
				immediate: true,
				deep: true,
				handler: function() {

					if(this.injury && this.injury.rtp) {
						this.form = _.cloneDeep(this.injury.rtp);
						this.form.kpi = this.form.kpi ? JSON.parse(this.form.kpi) : [];
					}

					if(this.injury && this.injury.persona_id && this.injury.data) {

						var endDate = this.injury.return ? this.injury.return : moment().format('YYYY-MM-DD');

						var from = moment(this.injury.data).format('YYYY-MM-DD');
						var to = moment(endDate).subtract(1, 'days').format('YYYY-MM-DD');

						this.fetchSessioni(this.injury.persona_id, from, to);
						this.fetchSessioniPerse(this.injury.persona_id, from, to, this.categoriaId);
						this.fetchPartitePerse(this.injury.persona_id, from, to, this.categoriaId);

						from = moment(this.injury.data).add(1, 'days').format('YYYY-MM-DD');
						this.fetchDatiToolsAttuale(this.injury.persona_id, from, to, this.categoriaId);

						from = moment(this.injury.data).subtract(31, 'days').format('YYYY-MM-DD');
						to = moment(this.injury.data).subtract(1, 'days').format('YYYY-MM-DD');
						this.fetchDatiToolsModPrestativo(this.injury.persona_id, from, to, this.categoriaId);
					}
				}
			},
		},
		filters: {
			formatDate: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('DD/MM/YYYY'); 
				}
				return date;
			}
		}, 
		methods: { 
			download: function() {
				var url = [this.$store.state.apiEndPoint + '/report/download/injury/rtp/' + this.user.id + '/' + this.user.api_token];
				url.push('?injury_id=' + this.injury.id);
				url.push('&categoria_id=' + this.categoriaId);
				window.open(url.join(''));
			},
			setOrdering: function(table, key) {
				if(this.orderings[table].key != key) {
					this.orderings[table].key = key;
					this.orderings[table].order = -1;
				} else {
					this.orderings[table].order *= -1;
				}
			},
			meanOf: function(table, key) {
				var data = table == 'drill' ? this.drillAnalysis : this.velocityAnalysis;
				var ds = _.map(data, function(i) {
					return isValidNumber(i[key]) ? i[key] : 0;
				});
				if(ds.length)
					return _.round(_.sum(ds)/ds.length, 0);
				return ''
			},
			standardDeviation: function(table, key) {
				var data = table == 'drill' ? this.drillAnalysis : this.velocityAnalysis;
				var ds = _.map(data, function(i) {
					return isValidNumber(i[key]) ? i[key] : 0;
				});
				if(!ds.length)
					return '';
				var avg = _.sum(ds) / ds.length;
				var ii = _.round(Math.sqrt(_.sum(_.map(ds, (i) => Math.pow((i - avg), 2))) / ds.length), 0);

				if(ii !== 0)
					return ii;
				return '';
			},
			getLabelKpi: function(kpi) {
				return this.kpi_options.find(item => item.value == kpi).text;
			},
			addKpi: function() {
				if(this.kpi_selected && !this.form.kpi.includes(this.kpi_selected)) {
					this.form.kpi.push(this.kpi_selected);
				}
			},
			removeKpi(kpi) {
				var i = this.form.kpi.indexOf(kpi);
				this.form.kpi.splice(i, 1);
			},
			addAllKpi() {
				for(var i in this.kpi_options) {
					var opt = this.kpi_options[i].value;
					if(opt != null && !this.form.kpi.includes(opt)) {
						this.form.kpi.push(opt);
					}
				}
			},
			removeAllKpi() {
				this.form.kpi = [];
			},
			dateFormat: function (date) {
				var m = moment(date);
				if(m.isValid()) {
					return m.format('DD/MM/YYYY'); 
				}
				return date;
			},
			fetchSessioni: function(playerId, from, to) {
				this.$http.get(this.$store.state.apiEndPoint + '/atlas/persona/sessioni', { params: { person_id: playerId, from: from, to: to, types: [ 'Fisioterapia', 'Rtp'] } }).then(function(data) {
					this.sessioni = data.data.results;
					if(!this.form.esercizi) {
						var esercizi = [];
						_.forEach(this.sessioni, function (item) {
							_.forEach(item.phases, function (fase) {
								if(fase.data && fase.data.esercizio && !esercizi.includes(fase.data.esercizio.name))
									esercizi.push(fase.data.esercizio.name);
							});						
						});
						if(esercizi.length > 0) {
							this.form.esercizi = esercizi.join('\n');
						}
					}
				});
			},
			fetchSessioniPerse: function(playerId, from, to, categoryId) {
				this.$http.get(this.$store.state.apiEndPoint + '/medical/sessioni/perse', { params: { persona_id: playerId, from: from, to: to, categoria_id: categoryId } }).then(function(data) {
					this.sessioni_perse = data.data;
				});
			},
			fetchPartitePerse: function(playerId, from, to, categoryId) {
				this.$http.get(this.$store.state.apiEndPoint + '/medical/partite/perse', { params: { persona_id: playerId, from: from, to: to, categoria_id: categoryId } }).then(function(data) {
					this.partite_perse = data.data;
				});
			},
			fetchDatiToolsAttuale: function(playerId, from, to, categoryId) {
				var params = {
					category_id: categoryId,
					dateFrom: from,
					dateTo: to,
					players: [playerId]
				}
				this.$http.post(this.$store.state.apiEndPoint + '/tools/table', params).then(function(data) {
					this.dati_tools_attuale = data.data.raw_results;
					this.sessioni_tools_attuale = data.data.sessioni;
				});
			},
			fetchDatiToolsModPrestativo: function(playerId, from, to, categoryId) {
				var params = {
					category_id: categoryId,
					dateFrom: from,
					dateTo: to,
					players: [playerId]
				}
				this.$http.post(this.$store.state.apiEndPoint + '/tools/table', params).then(function(data) {
					this.dati_tools_mod_prestativo = data.data.raw_results;
					this.sessioni_tools_mod_prestativo = data.data.sessioni;
				});
			},
			saveRtp: function() {
				this.$http.post(this.$store.state.apiEndPoint + '/medical/rtp/save/' + this.injury.id, this.form).then(function(data) {
				});
			},
		},
		computed: {
			...mapState(['categoriaId']),
			user: function() {
				return Auth.user;
			},

			days_off_riatletizzazione: function() {
				var days_session = _.uniq(_.map(this.sessioni.filter(item => item.type != 'Fisioterapia'), function (item) {
					return moment(item.date_start).format('YYYY-MM-DD');
				})).length;
				return this.days_diff - days_session;
			},

			days_off_fisio: function() {
				var days_session = _.uniq(_.map(this.sessioni.filter(item => item.type == 'Fisioterapia'), function (item) {
					return moment(item.date_start).format('YYYY-MM-DD');
				})).length;
				return this.days_diff - days_session;
			},

			frequenza_allenamenti: function() {
				return Math.round((this.sessioni.filter(item => item.type != 'Fisioterapia').length / this.days_diff) * 10) / 10;
			},

			durata_media_allenamenti: function() {
				var durata = 0;
				var count = 0;
				_.forEach(this.sessioni.filter(item => item.type != 'Fisioterapia'), function (item) {
					var findDurata = item.forms.find(a => a.type == 'Fase_Dato_Durata');
					if(findDurata && findDurata.data && findDurata.data.durata) {
						durata += findDurata.data.durata;
						count ++;
					}		
				});
				if(count > 0) {
					return Math.round(durata / count);
				}
				return 'N/D';
			},

			min_rpe: function() {
				return _.min(_.map(this.sessioni.filter(item => item.type != 'Fisioterapia'), function (item) {
					var findRpe = item.forms.find(a => a.type == 'Fase_Dato_Rpe');
					if(findRpe && findRpe.data && findRpe.data.rpe) {
						return findRpe.data.rpe;
					}
					return 0;
				}));
			},

			max_rpe: function() {
				return _.max(_.map(this.sessioni.filter(item => item.type != 'Fisioterapia'), function (item) {
					var findRpe = item.forms.find(a => a.type == 'Fase_Dato_Rpe');
					if(findRpe && findRpe.data && findRpe.data.rpe) {
						return findRpe.data.rpe;
					}
					return 0;
				}));
			},

			sessioni_fisio: function() {
				return _.groupBy(this.sessioni.filter(item => item.type == 'Fisioterapia'), 'type');
			},

			sessioni_riatletizzazione: function() {
				return _.groupBy(this.sessioni.filter(item => item.type != 'Fisioterapia'), 'type');
			},

			sessioni_allenamento: function() {
				return this.sessioni_tools_mod_prestativo.filter(item => item.sessione_tipo_glo_id != 15).map( function(a) { return a.id} );
			},

			sessioni_partita: function() {
				return this.sessioni_tools_mod_prestativo.filter(item => item.sessione_tipo_glo_id == 15).map( function(a) { return a.id} );
			},

			kpi_values_attuale: function() {
				var datiBySessione = _.groupBy(this.dati_tools_attuale, 'sessione_id');
				return buildKpiValues(datiBySessione);
			},

			kpi_values_mod_prestativo_allenamenti: function() {
				var datiBySessione = _.groupBy(this.dati_tools_mod_prestativo.filter(item => this.sessioni_allenamento.includes(item.sessione_id)), 'sessione_id');
				return buildKpiValues(datiBySessione);
			},

			kpi_values_mod_prestativo_partite: function() {
				var datiBySessione = _.groupBy(this.dati_tools_mod_prestativo.filter(item => this.sessioni_partita.includes(item.sessione_id)), 'sessione_id');
				return buildKpiValues(datiBySessione);
			},

			days_diff: function() {
				var diff = null;
				if(this.injury && this.injury.data && this.injury.return) {
					var end = moment(this.injury.return);
					var start = moment(this.injury.data);
					diff = end.diff(start, 'days');
				}
				return diff;
			},

			minuti_totali_fisio: function() {
				var minuti = 0;
				_.each(this.sessioni_fisio, function(items, k) {

					_.each(items, function(item) {
						var mom_from = moment(item.date_start);
						var mom_to = moment(item.date_end);
						var duration = mom_to.diff(mom_from, 'minute');
						minuti += duration;
					});
				});
				return minuti;
			},

			minuti_totali_riatletizzazione: function() {
				var minuti = 0;
				_.each(this.sessioni_riatletizzazione, function(items, k) {
					_.each(items, function(item) {
						var duration = 0;
						var findDurata = item.forms.find(a => a.type == 'Fase_Dato_Durata');
						if(findDurata && findDurata.data && findDurata.data.durata) {
							duration = findDurata.data.durata;
						} else {
							var mom_from = moment(item.date_start);
							var mom_to = moment(item.date_end);
							duration = mom_to.diff(mom_from, 'minute');
						}
						minuti += duration;
					});
				});
				return minuti;
			},

			graphOptionFisio: function() {

				var mom_from = moment(moment(this.injury.data).format('YYYY-MM-DD'));
				var mom_to = this.injury.return ? moment(this.injury.return) : moment();
				var labels = [];
				while(mom_from.isBefore(mom_to)) {
					labels.push(mom_from.format('DD/MM/YYYY'));
					mom_from = mom_from.add(1, 'day');
				}
				var markArea = {
					itemStyle: {
						color: 'rgba(255, 173, 177, 0.4)'
					},
					data: [
						[
							{ name: 'Minuti', xAxis: moment(this.injury.data).format('DD/MM/YYYY') },
							{ xAxis: this.injury.return ? moment(this.injury.return).format('DD/MM/YYYY') : _.last(labels) }
							]
						]
				};

				var series = [];

				_.each(this.sessioni_fisio, function(a,b) {

					series.push({
						name: b,
						data: _.map(labels, function(l) {

							var has = _.filter(a, function(k) {
								return moment(k.date_start).format('DD/MM/YYYY') === l;
							});

							return _.sum(_.map(has, function(e) {

								var mom_from = moment(e.date_start);
								var mom_to = moment(e.date_end);
								var duration = mom_to.diff(mom_from, 'minute');
								return duration;

							}));

						}.bind(this)),
						type: 'bar',
						color: ['#a0cd62'],
						stack: 'Search Engine',
						markArea
					});

				});

				return {
					legend: {
						left: 'left'
					},
					toolbox: {
						show: true,
						feature: {
							saveAsImage: {}
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						}
					},
					dataZoom: (labels.length < 50 ? null : [
					{
						type: 'slider',
						filterMode: 'weakFilter',
						showDataShadow: false,
						labelFormatter: ''
					},
					{
						type: 'inside',
						filterMode: 'weakFilter'
					}
					]),
					xAxis: {
						type: 'category',
						data: labels
					},
					yAxis: {
						type: 'value',
						min: 0
					},
					series
				}

			},

			graphOptionRiatletizzazione: function() {

				var mom_from = moment(moment(this.injury.data).format('YYYY-MM-DD'));
				var mom_to = this.injury.return ? moment(this.injury.return) : moment();

				var labels = [];
				while(mom_from.isBefore(mom_to)) {
					labels.push(mom_from.format('DD/MM/YYYY'));
					mom_from = mom_from.add(1, 'day');
				}

				var markArea = {
					itemStyle: {
						color: 'rgba(255, 173, 177, 0.4)'
					},
					data: [
						[
							{ name: 'Minuti', xAxis: moment(this.injury.data).format('DD/MM/YYYY') },
							{ xAxis: this.injury.return ? moment(this.injury.return).format('DD/MM/YYYY') : _.last(labels) }
							]
						]
				};

				var series = [];

				_.each(this.sessioni_riatletizzazione, function(a,b) {

					series.push({
						name: b,
						data: _.map(labels, function(l) {

							var has = _.filter(a, function(k) {
								return moment(k.date_start).format('DD/MM/YYYY') === l;
							});

							return _.sum(_.map(has, function(e) {
								var duration = 0;
								var findDurata = e.forms.find(a => a.type == 'Fase_Dato_Durata');
								if(findDurata && findDurata.data && findDurata.data.durata) {
									duration = findDurata.data.durata;
								} else {
									var mom_from = moment(e.date_start);
									var mom_to = moment(e.date_end);
									duration = mom_to.diff(mom_from, 'minute');
								}
								return duration;
							}));

						}.bind(this)),
						type: 'bar',
						color: ['olive'],
						stack: 'Search Engine',
						markArea
					});

				});

				return {
					legend: {
						left: 'left'
					},
					toolbox: {
						show: true,
						feature: {
							saveAsImage: {}
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						}
					},
					dataZoom: (labels.length < 50 ? null : [
					{
						type: 'slider',
						filterMode: 'weakFilter',
						showDataShadow: false,
						labelFormatter: ''
					},
					{
						type: 'inside',
						filterMode: 'weakFilter'
					}
					]),
					xAxis: {
						type: 'category',
						data: labels
					},
					yAxis: {
						type: 'value',
						min: 0
					},
					series
				}
			},

			graphOptionTotali: function() {

				var dataset_1 = [];
				var dataset_2 = [];
				var dataset_3 = [];

				dataset_1.push({ value: this.sessioni_perse.length, name: 'Allenamenti'});
				dataset_1.push({ value: this.partite_perse.length, name: 'Partite'});

				dataset_2.push({ value: this.sessioni_fisio['Fisioterapia'] ? this.sessioni_fisio['Fisioterapia'].length : 0, name: 'Fisioterapia'});
				dataset_2.push({ value: this.days_off_fisio, name: 'Days Off'});

				_.forEach(this.sessioni_riatletizzazione, function (value, key) {
					dataset_3.push({ value: value.length, name: key });		
				});
				dataset_3.push({ value: this.days_off_riatletizzazione, name: 'Days Off'});


				return {
					tooltip: {
						trigger: 'item',
					}, 
					series: [
					{
						name: 'Partite/Allenamenti Persi',
						type: 'pie',
						radius: [0, '30%'],
						data: dataset_1,
						label: {
							position: 'inner',
							fontSize: 12,
							formatter(param) {
								return param.name + '\n' + param.value;
							}
						},
						color: ['#FFD700 ', '#FF8C00'],		
					},
					{
						name: 'Fisioterapia',
						type: 'pie',
						radius: ['40%', '55%'],
						data: dataset_2,
						labelLine: {
							length: 30
						},	
						label: {
							formatter(param) {
								return param.name + '\n' + param.value;
							}
						},
						color: ['#a0cd62', 'gray'],

					},
					{
						name: 'Riatletizzazione',
						type: 'pie',
						radius: ['65%', '80%'],
						data: dataset_3,
						labelLine: {
							length: 5
						},	
						label: {
							formatter(param) {
								return param.name + '\n' + param.value;
							}
						},	
						color: ['olive', 'gray'],
					},
					]
				}; 
			},

			graphAderenza: function() {
				return {
					grid: {
						left: 0,
						top: 0,
						right: 0,
						bottom: -100 
					}, 
					series: [
					{
						type: 'gauge',
						startAngle: 180,
						endAngle: 0,
				//	center: ['50%', '75%'],
				//	radius: '90%',
					radius: '120%', // this
					center: ['50%', '80%'],
					min: 0,
					max: 4,
					axisLine: {
						lineStyle: {
							width: 22,
							color: [
								[0.3, this.form.aderenza == 1 ? '#FF0059' : '#FF00594D'],
								[0.7, this.form.aderenza == 2 ? '#7BDE3C' : '#7BDE3C4D'],
								[1, this.form.aderenza == 3 ? '#00B386' : '#00B3864D']
								]
						}
					},
					pointer: {
						icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
						length: '12%',
						width: 12,
						offsetCenter: [0, '-60%'],
						itemStyle: {
							color: 'auto'
						}
					},
					axisTick: {
						length: 12,
						lineStyle: {
							color: 'none',
							width: 2
						}
					},
					splitLine: {
						length: 20,
						lineStyle: {
							color: 'none',
							width: 5
						}
					},
					axisLabel: {
						color: 'none',
						fontSize: 20,
						distance: -60,
						rotate: 'tangential',
					},
					title: {
						offsetCenter: [0, '-10%'],
						fontSize: 13
					},
					detail: {
						fontSize: 15,
						offsetCenter: [0, '-35%'],
						valueAnimation: true,
						formatter: function (value) {
							return this.aderenza_options.find(item => item.value == value).text;
						}.bind(this),
						color: 'inherit'
					},
					data: [
					{
						value: this.form.aderenza,
						name: 'Aderenza'
					}
					]
				}
				]
				}
			},

			graphEvoluzione: function() {

				return {
					grid: {
						left: 0,
						top: 0,
						right: 0,
						bottom: -100 
					},
					series: [
					{
						type: 'gauge',
						startAngle: 180,
						endAngle: 0,
						center: ['50%', '80%'],
						radius: '120%',
						min: 0,
						max: 3,
						axisLine: {
							lineStyle: {
								width: 22,
								color: [
									[0.5, this.form.evoluzione == 0 || this.form.evoluzione == 2 ? '#FF00594D' : '#FF0059'],
									[1, this.form.evoluzione == 0 || this.form.evoluzione == 1 ? '#00B3864D' : '#00B386']
									]
							}
						},
						pointer: {
							icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
							length: '12%',
							width: 12,
							offsetCenter: [0, '-60%'],
							itemStyle: {
								color: 'auto'
							}
						},
						axisTick: {
							length: 12,
							lineStyle: {
								color: 'none',
								width: 2
							}
						},
						splitLine: {
							length: 20,
							lineStyle: {
								color: 'none',
								width: 5
							}
						},
						axisLabel: {
							color: 'none',
							fontSize: 1,
							distance: -60,
							rotate: 'tangential',
						},
						title: {
							offsetCenter: [0, '-10%'],
							fontSize: 15
						},
						detail: {
							fontSize: 13,
							offsetCenter: [0, '-35%'],
							valueAnimation: true,
							formatter: function (value) {
								return this.evoluzione_options.find(item => item.value == value).text;
							}.bind(this),
							color: 'inherit'
						},
						data: [
						{
							value: this.form.evoluzione,
							name: 'Evoluzione'
						}
						]
					}
					]
				}
			},

			graphRendimento: function() {

				return {
					grid: {
						left: 0,
						top: 0,
						right: 0,
						bottom: -100 
					},
					series: [
					{
						type: 'gauge',
						startAngle: 180,
						endAngle: 0,
						center: ['50%', '80%'],
						radius: '120%',
						min: 0,
						max: 4,
						axisLine: {
							lineStyle: {
								width: 22,
								color: [
									[0.25, '#FF00594D'],
									[0.5, '#FF89004D'],
									[0.75, '#7BDE3C4D'],
									[1, '#00B3864D']
									]
							}
						},
						progress: {
							show: true,
							width: 22,
							itemStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 1,
									x2: 1,
									y2: 1, 
									colorStops: [{
										offset: 0.25,
										color: "#FF0059"
									}, {
										offset: 0.5,
										color: "#FF8900"
									}, {
										offset: 0.75,
										color: "#7BDE3C"
									}, {
										offset: 1,
										color: "#00B386"
									}],
									global: false
								}
							}
						},
						pointer: {
							icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
							length: '12%',
							width: 12,
							offsetCenter: [0, '-60%'],
							itemStyle: {
								color: 'none'
							}
						},
						axisTick: {
							length: 12,
							lineStyle: {
								color: 'none',
								width: 2
							}
						},
						splitLine: {
							length: 20,
							lineStyle: {
								color: 'none',
								width: 5
							}
						},
						axisLabel: {
							color: 'none',
							fontSize: 20,
							distance: -60,
							rotate: 'tangential',
						},
						title: {
							offsetCenter: [0, '-10%'],
							fontSize: 15
						},
						detail: {
							fontSize: 13,
							offsetCenter: [0, '-35%'],
							valueAnimation: true,
							formatter: function (value) {
								return this.rendimento_options.find(item => item.value == value).text;
							}.bind(this),
					//	color: 'inherit'
							color: this.form.rendimento == 1 ? '#FF0059' : (this.form.rendimento == 2 ? '#FF8900' : (this.form.rendimento == 3 ? '#7BDE3C' : (this.form.rendimento == 4 ? '#00B386' : 'inherit')))
						},
						data: [
						{
							value: this.form.rendimento,
							name: 'Rendimento'
						}
						]
					}
					]
				}
			},

			graphFiducia: function() {

				return {
					grid: {
						left: 0,
						top: 0,
						right: 0,
						bottom: -100 
					},
					series: [
					{
						type: 'gauge',
						progress: {
							show: true,
							width: 22
						},
						startAngle: 180,
						endAngle: 0,
						center: ['50%', '80%'],
						radius: '120%',
						min: 0,
						max: 50,
						axisLine: {
							lineStyle: {
								width: 22,
							}
						},
						pointer: {
							icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
							length: '12%',
							width: 12,
							offsetCenter: [0, '-60%'],
							itemStyle: {
								color: 'auto'
							}
						},
						axisTick: {
							length: 12,
							lineStyle: {
								color: 'none',
								width: 2
							}
						},
						splitLine: {
							length: 20,
							lineStyle: {
								color: 'none',
								width: 5
							}
						},
						axisLabel: {
							color: 'none',
							fontSize: 20,
							distance: -60,
							rotate: 'tangential',
						},
						title: {
							offsetCenter: [0, '-10%'],
							fontSize: 15
						},
						detail: {
							fontSize: 13,
							offsetCenter: [0, '-35%'],
							valueAnimation: true,
							formatter: function (value) {
								return value + ' / ' + '50';
							},
							color: 'inherit'
						},
						data: [
						{
							value: this.form.fiducia,
							name: 'Fiducia'
						}
						]
					}
					]
				}
			},

			graphKpi: function() {

				var indicator = [];
				var attuale = [];
				var allenamenti = [];
				var partite = [];
				_.forEach(this.form.kpi, function (value) {
					indicator.push({ name: this.getLabelKpi(value) });	
					attuale.push(this.kpi_values_attuale[value] ? this.kpi_values_attuale[value] : '');	
					allenamenti.push(this.kpi_values_mod_prestativo_allenamenti[value] ? this.kpi_values_mod_prestativo_allenamenti[value] : '');	
					partite.push(this.kpi_values_mod_prestativo_partite[value] ? this.kpi_values_mod_prestativo_partite[value] : '');	
				}.bind(this));

				return {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						data: ['Attuale', 'Mod. Prest. Allenamenti', 'Mod. Prest. Partite']
					},
					radar: {
						nameGap: 5, 
						indicator: indicator,
					},
					series: [
					{
						name: 'Attuale vs Modello Prestativo',
						type: 'radar',
						data: [
						{
							value: attuale,
							name: 'Attuale',
						},
						{
							value: allenamenti,
							name: 'Mod. Prest. Allenamenti',
						},
						{
							value: partite,
							name: 'Mod. Prest. Partite',
						}
						]
					}
					]
				}
			},

			drillAnalysis: function() {
				var dataset = this.dati_tools_attuale;
				var grouped = _.groupBy(dataset, 'date');
				var table = _.map(grouped, function(dati, date) {
					return buildDrill(date, dati);
				}.bind(this));
				return _.orderBy(table, function(a) {
					var value = a[this.orderings['drill'].key];
					if(value == '-') {
						value = 0;
					}
					return value;
				}.bind(this), [ this.orderings['drill'].order == -1 ? 'asc' : 'desc' ]);
			},

			velocityAnalysis: function() {
				var dataset =this.dati_tools_attuale;
				var grouped = _.groupBy(dataset, 'date');
				var table = _.map(grouped, function(dati, date) {
					return buildVelocity(date, dati);
				}.bind(this));
				return _.orderBy(table, function(a) {
					var value = a[this.orderings['velocity'].key];
					if(value == '-') {
						value = 0;
					}
					return value;
				}.bind(this), [ this.orderings['velocity'].order == -1 ? 'asc' : 'desc' ]);
			},
		}
	}
	</script>