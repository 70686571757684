<template id="videotag">
	<section class="videotag">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Videotag</span>
					</div>
					<Videotag :videoId="$route.params.id_video" :personeIds="personeIds"></Videotag>
				</div>
			</div>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>

import moment from 'moment';
import * as types from '../../../../store/types';
import { mapState } from 'vuex';
import axios from 'axios';

export default {

	data: function() {
		return {
			sessione: null,
			team: null,
		}
	},

	components: {
		headapp:             require('../../../layout/headapp.vue').default,
		menusub:             require('../../../layout/menusub.vue').default,
		footerapp:           require('../../../layout/footerapp.vue').default,
		Videotag: require('@/components/layout/video/videotag.vue').default,

	},

	created: function () {
		this.getSessione();
		this.getTeam();	 
	},
	computed: {
		auth: function() {
			return Auth;
		},
		isManagerSessione: function() {
			if(this.sessione && this.sessione.sessione_tipo) {
				if(this.team && this.sessione.categoria_id != this.team.id_categoria) {
					return false;
				}
				return this.auth.isUserEnable('sessione_' + this.sessione.sessione_tipo.nome.toLowerCase().replaceAll(' ', '') + '_mng');
			}
			return false;
		}, 
		personeCorrenti: function() {
			if(this.sessione) {
				return _.map(this.sessione.persone, function(a) {
					return {
						name: a.cognome + ' ' + a.nome,
						id: a.id
					}
				});
			}
			return [];
		},
		personeIds: function() {
			return _.map(this.personeCorrenti, 'id');
		},
		...mapState(['rosaTeamId']),
	},

	methods: {
		getSessione() {
			if(this.$route.params.id) {
				axios.get(`/sessione/` + this.$route.params.id)
				.then((res) => {
					this.sessione = res.data;
				}).catch((err) => {
				});
			}
		},
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},
	}
}
</script>