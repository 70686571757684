<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		
		<b-card class="card">
			<b-container fluid class="py-1 mb-4">
				<b-row>
					<b-col :cols="1"></b-col>
					<b-col :cols="10">
						<h4 class="card-title">Nuovo Preset</h4>
						<b-form @submit.prevent="createPreset">
							
							<b-form-group label="Nome del Preset">
								<b-input type="text" v-model="newPreset.nome"></b-input>
							</b-form-group>
							<b-form-group label="Descrizione del Preset">
								<b-textarea v-model="newPreset.descrizione" :max-rows="10"></b-textarea>
							</b-form-group>
							
							<b-alert show variant="light">
								Dopo la creazione potrai inserire le regole primitive e scriver.
							</b-alert>

							<hr />
							<b-button type="submit" :disabled="!canCreate" variant="outline-success" size="sm">Salva Preset</b-button>

						</b-form>
						
					</b-col>
				</b-row>
			</b-container>
		</b-card>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	data: function(){
		return {
			stagioniAsRoma: false,
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			variabiliSquadra: null,
			stagioneInfo: false,
			stagioniInfo: false,
			newPreset: null
		}
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY')
		}
	},
	computed: {
		canCreate: function() {
			if(this.newPreset.nome.length == 0) return false;
			//if(this.newPreset.regole.length == 0) return false;

			return true;
		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},
	watch: {
		rosaTeamId() {
			this.getVariabili();
		},
		seasonId() {
			//this.getVariabili();
		},
	},
	created: function() {
		// this.getVariabili();
		this.emptyPreset();
		this.getStagioni();
		this.getStagioniASR();
	},
	methods: {
		createPreset: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/contratti/create-presets-rules', { preset: this.newPreset }).then(function(data) {
				this.$router.push({ name: 'contratti_editor_detail', params: { preset_id: data.data.id } });
			});
		},
		emptyPreset: function() {
			this.newPreset = {
				nome: '',
				descrizione: '',
				regole: [],
				validation: '',
				isDraft: true
			};
		},
		getStagioneInfo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagione/' + this.seasonId).then(function(data) {
				this.stagioneInfo = data.data;
			});
		},
		getStagioni: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/stagioni').then(function(data) {
				this.stagioniInfo = data.data;
				if(this.variabiliSquadra == null) {

					this.variabiliSquadra = _.findLast(this.stagioniInfo, function(a) {
						return a.stagione_id != null;
					});
				}
			});
		},
		getStagioniASR: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagioni').then(function(data) {
				this.stagioniAsRoma = data.data;
			});
		},
		updateVars: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/contratto/variabili-squadra-update', { vars: this.variabiliSquadra }).then(function(data) {
				this.getStagioni();
				this.variabiliSquadra = data.data;
			});

		},
		deleteVariabili: function() {
			if(!window.confirm("Confermi la rimozione della stagione?")) return false;
			this.$http.post(this.$store.state.apiEndPoint + '/contratto/delete-variabili-squadra/' + this.variabiliSquadra.id).then(function() {
				this.variabiliSquadra = null;
				this.getStagioni();
			});
		},
		getVariabili: function() {
			this.getStagioneInfo(this.seasonId);
			this.variabiliSquadra = false;
			this.$http.get(this.$store.state.apiEndPoint + '/contratto/variabili-squadra/' + this.rosaTeamId + '/' + this.seasonId).then((response) => {
				if (response.ok) {
					if(response.data.id)
						this.variabiliSquadra = response.data;
					else {
						this.newSeason();
					}
				}
			});
		},
	}
}
</script>