<template>
	<div class="m-1">

		<div>
			<b-button class="mr-2" size="xs" variant="outline-secondary" @click.prevent="resetFilters">resetta filtri</b-button>
			<span class="text-muted small">La selezione corrente ha <strong>{{ calculateResults(results, local).length }}</strong> risultati</span>
		</div>

		<b-row>
			<b-col class="small" :cols="3" v-for="f,k in filters">
				<strong>{{field(k).label}}</strong>
				<div style="height: 145px; overflow-y: scroll; border-radius: 3px; border: 1px solid lightgray; padding: 3px">
					<div v-for="l in sorted(lines[k], k)">
						<label>
							<input type="checkbox" v-model="local[k]" :value="l">

							<span :style="('margin-left: 2px; margin-right: 2px; ')+(filterOf(k,l) == 0 ? 'font-style: italic; color: gray' : '')">{{ _.toString(guided(k, l)) }}</span>

							<span class="text-muted small d-inline-flex">({{ filterOfLive(k,l) }}<span v-if="filterOf(k,l) == 0"> - 0</span>)<span v-if="filterOf(k,l) == 0">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style="width: 12px; height: 12px; opacity: 0.5; margin-left: 0.25em">
									<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
								</svg>
							</span></span>
						</label>
					</div>
				</div>
				<div class="mt-1">
					<b-button size="xs" variant="secondary" v-if="isChanged[k]" @click.prevent="applyLocal">Applica</b-button>
					<div>
						<small><a href="javascript:;" @click.prevent="selectAll(k)">Seleziona Tutti</a></small>
					</div>
				</div>
				<!-- <pre>{{f}}</pre> -->
			</b-col>
		</b-row>
	</div>
</template>

<script>
	
export default {
	props: [ 'guide', 'filter', 'results', 'autoemit', 'trim' ],
	data: function() {
		return {
			filters: [],
			local: []
		}
	},
	watch: {
		filter: {
			immediate: true,
			handler: function() {
				if(this.filter && this.filter.filter) {

					if(JSON.stringify(this.filter.filter) !== JSON.stringify(this.filters)) {

						var bb = {};

						_.each(this.filter.filter, function(a,b) {
							if(!isNaN(a) && a != null) {
								bb[b] = a;
							} else {
								bb[b] = [];
							}
						}.bind(this));

						this.$nextTick(function() {
							this.filters = _.cloneDeep(bb);
							this.local = _.cloneDeep(bb);
						});

					}

				} else
					this.resetFilters();
			},
		},
		filters: {
			deep: true,
			handler: function() {
				if(this.autoemit && this.autoemit == true)
					this.emitFilters();
			}
		}
	},
	created: function() {
		//if(!this.filter)
		//	this.resetFilters();
	},
	methods: {
		selectAll: function(k) {
			
			var realKey = _.find(this.guide, { name: k });
			var vals = _.mapValues(_.groupBy(this.calculateResults(this.results, this.local), realKey.remap || k), 'length');
			
			var u = [];
			_.each(vals, function(b,k) {
				if(b > 0)
					u.push(k);
			})

			this.local[k] = u;

		},
		sorted: function(a, k, l) {

			var realKey = _.find(this.guide, { name: k });
			var vals = _.mapValues(_.groupBy(this.calculateResults(this.results, this.filters), realKey.remap || k), 'length');

			return a.sort(function(b,c) {
				return vals[ b ] > vals[ c ] ? -1 : 1;
			});
			

		},
		emitFilters: _.debounce(function() {
			this.$emit('filters', this.filters);
		}, 400),
		filterOf: function(k,l) {

			var kGuide = _.find(this.guide, { key: k });
			var search = {};
			search[kGuide.remap || k] = parseInt(l);

			return _.filter(this.calculateResults(this.results, this.local), search).length;
		},
		filterOfLive: function(k,l) {

			var kGuide = _.find(this.guide, { key: k });
			var search = {};
			search[kGuide.remap || k] = parseInt(l);

			return _.filter(this.calculateResults(this.results, this.filters), search).length;
		},
		calculateResults: function(dataset, filters_base) {

			var base = _.cloneDeep(dataset);
			var filters = _.cloneDeep(filters_base);

			base = _.filter(base, function(e) {

				var inSet = true;
				_.each(filters, function(f,k) {

					var kGuide = _.find(this.guide, { key: k });

					if(!f || f.length == 0) return true;

					var i = false;
					_.each(f, function(single) {

						var kkk = single;
						if(single == 'null')
							kkk = null;

						if(e[kGuide.remap || kGuide.key] == kkk)
							i = true;

					}.bind(this));

					if(i == false)
						inSet = false;


				}.bind(this));

				return inSet;

			}.bind(this));

			return base;

		},
		resetFilters: function() {

			var filters = {};
			var local = {};
			_.each(this.guide, function(para) {
				filters[para.key] = [];
				local[para.key] = [];
			}.bind(this));

			this.filters = filters;
			this.local = local;

		},
		field: function(k) {
			return _.find(this.guide, { key: k });
		},
		guided: function(k,l) {
			var guide = this.field(k);

			if(guide.arr == true && guide.guide) {
				var g = _.find(guide.guide, { id: parseInt(l) });
				if(g)
					return g[guide.guideKey];
			} else if(l == null) {
				return 'null';
			} else return l;

		},
		applyLocal: function() {
			this.filters = _.cloneDeep(this.local);
		}
	},
	computed: {
		_: function() {
			return _;
		},
		isChanged: function() {

			var i = {};
			_.each(this.local, function(a,b) {

				if(JSON.stringify(a) != JSON.stringify(this.filters[b]))
					i[b] = true;
				else
					i[b] = false;

			}.bind(this));

			return i;

		},
		liveResults: function() {

			var base = _.cloneDeep(this.results);
			return this.calculateResults(this.results, this.filters);

			return base;
		},
		lines: function() {

			var mapped = {};
			_.each(this.guide, function(para) {
				mapped[para.key] = _.keys(_.groupBy(this.liveResults, para.remap || para.key));
			}.bind(this));

			return mapped;
		}
	}
}

</script>