<template id="rosa">

	<section class="rosa">

		<headapp></headapp>
		<menusub></menusub>

		<b-overlay :show="loading" no-wrap>
		</b-overlay>

		<div style="position: fixed; bottom: 0; left: 0; width: 100%; z-index: 9999" @mouseenter="openFilters" @mouseleave="closeFilters">
			
			<div style="position: absolute; bottom: 30px; left: 20px; background-color: maroon; padding: 0.7em; padding-top: 0.9em; border-radius: 100%;">
				<svg xmlns="http://www.w3.org/2000/svg" style="width: 35px; height: 35px;" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" /></svg>
			</div>

			<div ref="filter" class="d-none" style="background-color: white; width: 100%; padding: 20px 0; border-top: 2px solid black; z-index: 1; position: relative">
				
				<b-row class="mb-1">
					<b-col cols="1" class="text-right font-weight-bold">
						<!--i @click="emptyIDB" class="fa fa-database pointer" v-b-tooltip.hover title="Pulisci dati salvati" aria-hidden="true"></i-->
					</b-col>
					<b-col cols="11" class="font-weight-bold">Filtri del Calendario</b-col>
				</b-row>

				<b-row class="mb-1">
					<b-col cols="1" class="text-right align-items-center small font-weight-bold">Sessioni</b-col>
					<b-col cols="1" class="small"><span @click.prevent="selectAll" style="cursor:pointer">Tutte</span> / <span @click.prevent="unselectAll" style="cursor:pointer">Nessuna</span></b-col>
					<b-col class="small"><label class="mr-2">🔗 <input type="checkbox" v-model="aggregate"> Aggrega</label></b-col>
				</b-row>

				<b-row v-for="a, k in calendar_areas">
					<b-col cols="1" class="text-right align-items-center small" @click.prevent="selectUnselect(k)" style="cursor:pointer">{{k}}</b-col>
					<b-col cols="11" class="d-flex">

						<div v-for="item in a.tipologie" class="mr-1">

							<label class="d-flex align-items-center">
								<input v-model="ux.areas" :value="item.tipologia_id" type="checkbox" class="mr-1" />
								<b-badge :style="'background-color: ' + item.colore">
									{{ item.label }}
								</b-badge>
							</label>

						</div>

					</b-col>
				</b-row>

			</div>

		</div>

		<div style="position: fixed; bottom: 0; right: 0; width: 100%; z-index: 9999" v-if="ux.view == 'days' && !isPortrait">
			<b-dropdown dropup no-caret variant="link" toggle-class="p-3" style="position: absolute; bottom: 30px; right: 30px;background-color:maroon;border-radius:100%">
				<template #button-content>
					<i class="fa fa-search-plus" style="font-size:1.5rem;color:#FFFFFF" aria-hidden="true"></i>
				</template>
				<b-dropdown-form>
					<b-form-group :label="'Zoom: ' + ux.zoom + 'x'">
						<b-form-input id="range-1" v-model="ux.zoom" type="range" min="1" max="5"></b-form-input>
					</b-form-group>
				</b-dropdown-form>
			</b-dropdown>
		</div>

		<div ref="content" style="background-color: white">
			<b-container fluid>
				<b-row align-v="center" class="fc fc-unthemed fc-ltr" :style="isPortrait ? 'font-size: 0.8rem' : 'font-size: 0.9rem'">
					<b-col class="mt-1">
						
						<div class="fc-button-group mr-2">
							<button type="button" class="small fc-prev-button fc-button fc-state-default fc-corner-left" :disabled="fetchStarted" @click="prev(ux.view)"><span class="fc-icon fc-icon-left-single-arrow"></span></button>
							<button type="button" class="fc-next-button fc-button fc-state-default fc-corner-right" :disabled="fetchStarted" @click="next(ux.view)"> <span class="fc-icon fc-icon-right-single-arrow"></span></button>
						</div>

						<div class="fc-button-group mr-2">
							<button type="button" class="fc-prevYear-button fc-button fc-state-default fc-corner-left" :disabled="fetchStarted" @click="prev('years')"> <span class="fc-icon fc-icon-left-double-arrow"></span></button>
							<button type="button" class="fc-nextYear-button fc-button fc-state-default fc-corner-right" :disabled="fetchStarted" @click="next('years')"> <span class="fc-icon fc-icon-right-double-arrow"></span></button>
						</div>

						<div class="fc-button-group">
							<button type="button" :disabled="fetchStarted" @click="goTo(moment().format('YYYY-MM-DD'))" class="fc-today-button fc-button fc-state-default fc-corner-left">{{ isPortrait ? '2D' : 'Today'}}</button>
							<button type="button" :disabled="fetchStarted" @click="showDateModal" class="fc-custom-button fc-button fc-state-default fc-corner-right">{{ isPortrait ? 'Go' : 'Date'}}</button>
						</div>

					</b-col>
					<b-col class="text-center color-rosso" :style="isPortrait ? 'font-size: 1rem' : 'font-size: 1.5rem'">
						{{ calendarTitle }} 
						<b-spinner v-if="fetchStarted" class="title-fetching-loader" label="loading..."></b-spinner>
						<!-- <i v-else @click="emptyIDB" class="fa fa-database pointer" v-b-tooltip.hover title="Pulisci dati salvati" aria-hidden="true"></i> -->
					</b-col>
					<b-col class="mt-1 text-right">	
						<div class="fc-button-group mr-2">
							<player-picker :max="3" :options="player_options" value="id" @submit="fetchCalendar" @change="ux.person_ids = $event" :base="ux.person_ids"></player-picker>
						</div>
						<div class="fc-button-group mr-2"><button type="button" @click="ux.view = 'months'" :class="'fc-month-button fc-button fc-state-default fc-corner-left' + (ux.view == 'months' ? ' fc-state-active' : '')">{{ isPortrait ? 'M' : 'Month'}}</button><button type="button" @click="ux.view = 'weeks'" :class="'fc-agendaWeek-button fc-button fc-state-default' + (ux.view == 'weeks' ? ' fc-state-active' : '')">{{ isPortrait ? 'W' : 'Week'}}</button><button type="button" @click="ux.view = 'days'" :class="'fc-agendaDay-button fc-button fc-state-default fc-corner-right' + (ux.view == 'days' ? ' fc-state-active' : '')">{{ isPortrait ? 'D' : 'Day'}}</button></div>
						<div class="fc-button-group mr-2" v-if="auth.isUserEnable('print_daily_program') || auth.isUserEnable('print_fisio_program')">
							<b-dropdown variant="outline-primary" class="mr-1">
								<template #button-content>
									<i class="fa fa-file-pdf-o" aria-hidden="true"></i> Stampa
								</template>
								<b-dropdown-item v-if="auth.isUserEnable('print_daily_program')" @click="showReportPlayers">Programma giornaliero</b-dropdown-item>
								<b-dropdown-item v-if="auth.isUserEnable('print_fisio_program')" @click="showReportFisioPlayers">Programma fisioterapia</b-dropdown-item>
							</b-dropdown>
						</div>
						<div class="fc-button-group mr-3">
							<b-button block size="sm" @click.prevent="$store.commit('changeCalendarNew', false)" variant="outline-primary" title="Torna alla vecchia versione del calendario"> <i class="fa fa-calendar" aria-hidden="true"></i> Old</b-button>
						</div>
					</b-col>
				</b-row>
				<div class="d-flex" style="margin-right: 15px" v-if="ux.view != 'days'" :style="isPortrait ? 'font-size: 0.8rem' : 'font-size: 1.rem'">
					<div class="calendarDayHead px-2" v-for="i in 7">
						<span><strong>{{ ['', 'Lunedì', 'Martedì', 'Mercoledì' ,'Giovedì', 'Venerdì', 'Sabato', 'Domenica'][i] }}</strong></span>
					</div>
				</div>
				<div class="d-flex" style="margin-right: 15px" v-else>
					<div class="calendarDayHead px-2" style="width:100%">
						<span><strong>{{ ['', 'Lunedì', 'Martedì', 'Mercoledì' ,'Giovedì', 'Venerdì', 'Sabato', 'Domenica'][moment(ux.focus).isoWeekday()] }}</strong></span>
					</div>
				</div>
				<div class="calendar" ref="calendar">
					<div v-for="a, week in days.weeks">
						<div class="calendarWeek d-flex">
							<!-- :events="uiCalendar_data[i]"  -->
							<template v-for="i in a">
								<GiornoCalendario 
									ref="giorno" class="calendarDay" :class="{ 'dayHeight': ux.focusedWeek.indexOf(week) === -1 && ux.view == 'months'}" 
									:day="i" 
									:events="day_events[i]"
									:selected="ux.focus == i" 
									:focus="ux.focusedWeek.indexOf(week) !== -1" 
									:style="ux.view == 'days' ? 'width:100%' : ''" 
									:view="ux.view" :zoom="ux.zoom" 
									:focusDate="ux.focus" 
									:isPortrait="isPortrait" 
									@outFocus="toggleWeek(week)" 
									@onFocus="toggleWeek(week)" 
									@eventSelected="eventSelected" 
									@dayClick="dayClick" 
									@eventDrag="eventDrag" 
									@eventDrop="eventDrop" 
									@refresh="fetchCalendar" 
									@scrollTo="scrollTo"
								>
								</GiornoCalendario>
							</template>
						</div>
					</div>
				</div>
			</b-container>
		</div>

		<b-modal hide-footer id="eventModal" ref="eventModal" :title="modalInteractionTitle" centered @close="closeModalCalendar" @hide="closeModalCalendar" @ok="handleCancel" size="xl" ok-only ok-title="Chiudi">

			<b-container fluid v-if="selected">
				<div @activityCheck="pickMatrix" @changeTitle="ux.modalTitle = $event" @swap="eventSelected($event)" @close="closeModalCalendar" @refresh="fetchCalendar" :team="team" :is="selectedFormCalendar" :player_options="player_options_map" :staff_options="staff_options" :entity="selected.base ? selected.base : selected" @toolsAnalysis="pickToolsAnalysis"></div>
			</b-container>
			<div v-else>
				<div :date="start_at" :time="time" @changeTitle="ux.modalTitle = $event" @close="closeModalCalendar" @refresh="fetchCalendar" :team="team" :is="createFormCalendar" :player_options="player_options_map" :staff_options="staff_options"></div>
			</div>

		</b-modal>

		<b-modal ref="dateModal" title="Vai alla data" ok-title="Ok" @ok="goTo(go_to_date)">
			<b-form-group label="Seleziona una data">
				<b-form-input v-model="go_to_date" type="date"></b-form-input>
			</b-form-group>
		</b-modal>

		<ModalMatrice v-if="ux.showMatrice" @close="hideMatrix" @save="hideMatrix" :sessioneId="ux.showMatrice" :team="team"></ModalMatrice>

		<b-modal size="full" ref="reportModal" title="Programma Giornaliero" ok-only ok-title="Chiudi">
			<ReportGiocatoriSessioni :team="team" :player_options="player_options_map" :session_options="sessione_tipi"></ReportGiocatoriSessioni>
		</b-modal>
		<b-modal size="full" ref="reportFisioModal" title="Programma Fisioterapia" ok-only ok-title="Chiudi">
			<ReportGiocatoriSessioni :team="team" :player_options="player_options_map" :session_type_ids="[1,10,13]" :injured="true"></ReportGiocatoriSessioni>
		</b-modal>
		<b-modal size="full" ref="toolsModal" :title="sessione ? sessione.nome : 'Sessione'" ok-only ok-title="Chiudi">
			<b-overlay :show="!sessione" no-wrap></b-overlay>
			<tools-table v-if="auth.isUserEnable('report_tools_analysis') && sessione" class="mt-4 mb-1" :sessione="sessione"></tools-table>
		</b-modal>

		<footerapp></footerapp>

	</section>

</template>

<style type="text/css">

.pointer{
	cursor: pointer;
}

.calendar {
	overflow-y: scroll;
}

.calendarDay {
	width: 14.2857%;
	min-height: 10px;
	border-right: 1px solid rgba(0,0,0,.2);
	border-bottom: 1px solid rgba(0,0,0,.2);
}

.calendarDayHead {
	width: 14.2857%;
	min-height: 10px;
	border-right: 1px solid rgba(0,0,0,.2);
	border-top: 1px solid rgba(0,0,0,.2);
}

.calendarDayHead:first-child {
	border-left: 1px solid rgba(0,0,0,.2);
}

.calendarWeek {
	width: 100%;
}

.calendarWeek .calendarDay:first-child {
	border-left: 1px solid rgba(0,0,0,.2);
}

.calendarWeek:first-child .calendarDay {
	border-top: 1px solid rgba(0,0,0,.2);
}

.title-fetching-loader{
	width: 1.3rem; 
	height: 1.3rem; 
	border: 0.13em solid currentColor;
	border-right-color: transparent;
}

</style>

<script>

import pageBase from '@/mixins/pageBase.js';
import sessionMixin from '@/mixins/sessione.js';
import { mapState } from 'vuex';

export default {
	mixins: [ pageBase, sessionMixin ],
	components: {
		GiornoCalendario: require('@/components/layout/Sessioni/Calendario/Giorno.vue').default,
		FormCalendarGenerica: require('@/components/layout/Sessioni/FormCalendar.vue').default,
		FormCalendarPack: require('@/components/layout/Calendar/PackCalendar.vue').default,
		PlayerPicker: require('@/components/layout/Football/PlayerPicker.vue').default,
		ModalMatrice: require('@/components/layout/Sessioni/Matrice.vue').default,
		ReportGiocatoriSessioni: require('@/components/layout/Sessioni/ReportGiocatoriSessioni.vue').default,
		ToolsTable: require('@/components/layout/Sessioni/Generica/Tools_Table.vue').default,
	},
	data: function() {
		return {
			loading: false,
			fetchStarted: false,
			calendar_data: null,
			calendar_areas: null,
			area: false,
			areaFromRoute: false,
			ux: {
				areas: [],
				focus: null,
				focusedWeek: [],
				ratio: 5.5,
				from: null,
				to: null,
				modalTitle: null,
				showMatrice: false,
				view: 'months',
				person_ids: [],
				zoom: 1,
			},
			selected: null,
			event_id: null,
			nome: null,
			level: 0,
			start_at: '',
			end_at: '',
			from_hour: null,
			to_hour: null, 
			season_id: null,
			players: [],
			player_options: [],
			staff_options: [],
			team: null,
			go_to_date: null,
			aggregate: true,
			time: null,
			dimesions: {
				width: document.documentElement.clientWidth,
				height: document.documentElement.clientHeight
			},
			sessione_tipi: [],
			sessione: null
		}
	},
	watch: {
		'ux': {
			deep: true,
			handler: function() {
				this.ui();
				localStorage.setItem('sessions-calendars-ux', JSON.stringify(this.ux));
			}
		},
		rosaTeamId() {
			this.$store.commit('calendar_events/resetDayEvents' )

			this.calendar_data = null;
			this.ux.person_ids = [];

			this.getTeam();
			this.getPlayers();
			this.getStaff();
			this.fetchCalendar();
		},
		'ux.view': function() {
			/*	if(this.ux.view != 'months') {
					this.ux.focusedWeek = [];
				}  */
			if(!this.isUpdateInterval()) return false;
			this.fetchCalendar();
		},
		'ux.areas': function() {
			if( !!this.calendar_data )
				this.setDayEvents()
		},
		'ux.zoom': function(newValue, oldValue) {
			if(this.ux.view == 'days'  && !this.isPortrait) {
				var oldScrollTop = this.$refs.calendar.scrollTop;
				var oldOffsetHeight = this.$refs.giorno[0].$refs.content_day.offsetHeight;

				var heightSlot = oldOffsetHeight / oldValue;
				var newHeight = heightSlot * this.ux.zoom;	
				this.$refs.calendar.scrollTop = (oldScrollTop * newHeight) / oldOffsetHeight;
			}
		},
		aggregate() {
			this.fetchCalendar();
		},
		calendar_areas: {
			deep: true,
			handler: function() {

				var selected = [];

				_.each(this.calendar_areas, function(a, b) {

					var base = b;
					if(this.area == undefined || _.capitalize(this.area) == base) {
						_.each(a.tipologie, function(b) {
							selected.push(base + '.' + b.nome);
						}.bind(this));
					}

				}.bind(this));

				if(this.ux.areas.length == 0) {
					this.selectAll();
				}
			}
		},
	},
	created: function() {
		this.ux.from = null;
		this.ux.to = null;

		this.ux.focus = this.moment().format('YYYY-MM-DD');

		// Check if we have something in the history ^_^
		var uxStored = localStorage.getItem('sessions-calendars-ux');
		if(uxStored != null) {
			this.ux = JSON.parse(uxStored);
		}

		this.getTeam();
		this.getPlayers();
		this.getStaff();
		this.getCalendarAreas();
		this.fetchCalendar();

		window.addEventListener('resize', this.getDimensions);

	},

	destroyed() {
		window.removeEventListener('resize', this.getDimensions);
	},

	mounted: function() {

		$(window).on('resize', function() {

			_.delay(function(){

				$(this.$refs.calendar).css({
					height: $(window).height() - (this.$refs.content ? $(this.$refs.content).offset().top : 0) - 120
				});

				this.ui();

			}.bind(this), 100);


		}.bind(this)).trigger('resize');

	},
	computed: {
		isPortrait() {
			if(this.dimesions.height > this.dimesions.width) {
				return true;
			}
			return false;
		},
		uiCalendar_data: function() {
			var dati = _.filter(this.calendar_data, function(item) {
				return item.CalendarObject.inizio != undefined;
			});
			
			var dati = _.filter(dati, function(a) {

				if(this.ux.areas.indexOf(a.CalendarObject.sessione_tipo_glo_id) !== -1) return true;

			}.bind(this));

			return _.groupBy(dati, function(a) {
				return this.moment(a.CalendarObject.inizio).format('YYYY-MM-DD');
			}.bind(this))
		},
		uiCalendar_areas: function() {
			return _.flatten(_.map(this.calendar_areas, 'tipologie'));
		},
		days: function() {

			this.ux.focusedWeek = [];

			var firstDay = null;
			var lastDay = null;

			if(this.ux.view == 'months') {
				firstDay = this.moment(this.ux.focus).startOf('month');
				lastDay = this.moment(this.ux.focus).endOf('month');
			} else if (this.ux.view == 'weeks') {
				firstDay = this.moment(this.ux.focus).startOf('isoWeek');
				lastDay = this.moment(this.ux.focus).endOf('isoWeek');
			} else if (this.ux.view == 'days') {
				firstDay = this.moment(this.ux.focus);
				lastDay = this.moment(this.ux.focus);
			}
			if(this.ux.view != 'days') {
				// Check if we need to go back some day in order to obtain the start of week day!
				if(firstDay.format('E') - 1 > 0)
					firstDay.add( -1 * (firstDay.format('E') - 1), 'days' );

				if(lastDay.format('E') < 7)
					lastDay.add(7 - lastDay.format('E'), 'days' );
			}

			firstDay = firstDay.format('YYYY-MM-DD');
			lastDay = lastDay.format('YYYY-MM-DD');

			var current = firstDay;
			var weeks = [];

			var count = this.ux.view != 'days' ? 7 : 1;

			while( this.moment(current).format('X') <= this.moment(lastDay).format('X') ) {

				var week = [];
				var currentWeek = false;
				for(var i = 1; i <= count; i++) {
					if(this.moment(current).format('YYYY-MM-DD') == this.moment().format('YYYY-MM-DD')) {
						currentWeek = true;
					}
					week.push(this.moment(current).format('YYYY-MM-DD'));
					current = this.moment(current).add(1, 'days').format('YYYY-MM-DD');
				}

				weeks.push(week);
				if(currentWeek) {
					this.ux.focusedWeek = [weeks.length - 1];
				}
			}

			return { weeks, firstDay, lastDay };

		},
		modalInteractionTitle: function() {
			if(this.event_id) {
				if(this.ux.modalTitle != null) return this.ux.modalTitle;
				return 'Sessione';
			}
			return 'Nuova Sessione';

		},
		createFormCalendar: function() {
			return 'FormCalendarGenerica';
			return 'FormCalendar' + _.capitalize(this.area);
		},
		selectedFormCalendar: function() {
			if(this.selected.CalendarObject && this.selected.CalendarObject.area == 'Pack')
				return 'FormCalendar' + this.selected.CalendarObject.area;
			return 'FormCalendarGenerica';
		},
		calendarTitle() {
			if(this.ux.view == 'months') {
				return this.moment(this.ux.focus).format('MMMM YYYY');
			} else if(this.ux.view == 'weeks') {
				var startWeek = this.moment(this.ux.focus).startOf('isoWeek').format('DD MMM');
				var endWeek = this.moment(this.ux.focus).endOf('isoWeek').format('DD MMM');
				return startWeek + ' - ' + endWeek + ' ' + this.moment(this.ux.focus).format('YYYY');
			} else if(this.ux.view == 'days') {
				return this.moment(this.ux.focus).format('DD MMMM YYYY');	
			}
		},
		player_options_map() {
			return  [
			{
				label: 'Seleziona tutti',
				items: this.player_options.map(item => ({								
					name: (item?.cognome || '') + ' ' + (item?.nome || ''),
					id: item.id,
				})),
			}
			]	
		},

		...mapState('calendar_events', ['day_events']),
	},
	methods: {
		getIDBStoreData: async function() {
			return this.$store.dispatch('calendar_events/fetchIndexedDBDaysEvents', this.$store.state.rosaTeamId )
		},
		emptyIDB: async function() {
			this.$store.dispatch('calendar_events/flushIndexedDBDaysEvents', this.$store.state.rosaTeamId )
				.then( res => alert('Dati svuotati con successo') )
		},
		setDayEvents: function(){

			var dati = _.filter(this.calendar_data, function(item) {
				return item.CalendarObject.inizio != undefined;
			});
			
			var dati = _.filter(dati, function(a) {
				if(this.ux.areas.indexOf(a.CalendarObject.sessione_tipo_glo_id) !== -1) return true;
			}.bind(this));

			const grouped_datas = _.groupBy(dati, function(a) {
				return this.moment(a.CalendarObject.inizio).format('YYYY-MM-DD');
			}.bind(this))

			/** Create base dates object **/
			const firstDay = this.moment(this.days.firstDay)
			const lastDay  = this.ux.view == 'days' ? this.moment(this.days.firstDay) : this.moment(this.days.lastDay)
			let all_grouped_datas = {}
			for ( const loopDate = this.moment(firstDay.format('YYYY-MM-DD')); loopDate.isSameOrBefore(lastDay); loopDate.add(1, 'days') ){
				all_grouped_datas[loopDate.format('YYYY-MM-DD')] = grouped_datas[loopDate.format('YYYY-MM-DD')] || {}
			}

			Object.entries(all_grouped_datas).forEach( ( entry ) => {
				const datas = {
					entry: entry,
					category: this.$store.state.rosaTeamId
				}

				this.$store.commit('calendar_events/setDayEvents', datas )
			})
		},
		pickToolsAnalysis: function(sessioneId) {
			this.sessione = null;
			this.$refs.toolsModal.show();
			this.apiGetSessione(sessioneId).then(function(data) {
				this.sessione = data;
			});
		},	
		showReportPlayers: function() {
			this.$refs.reportModal.show();
		},
		showReportFisioPlayers: function() {
			this.$refs.reportFisioModal.show();
		},
		hideMatrix: function() {
			this.ux.showMatrice = false;
		},
		pickMatrix: function(sessioneId) {
			this.ux.showMatrice = sessioneId;
		},
		scrollTo(y) {
			this.$refs.calendar.scrollTop = y;	
		},
		getDimensions() {
			this.dimesions.width = document.documentElement.clientWidth;
			this.dimesions.height = document.documentElement.clientHeight;
		},
		closeFilters: function() {
			$(this.$refs.filter).addClass('d-none');
		},
		openFilters: function() {
			$(this.$refs.filter).removeClass('d-none');
		},
		toggleWeek: function(week) {

			var pos = this.ux.focusedWeek.indexOf(week);
			if(pos === -1) {
				this.ux.focusedWeek.push(week);
			} else {
				this.ux.focusedWeek.splice(pos, 1);
			}

		},
		ui: function() {

			$('.calendarDay').css('height', 'auto');

			this.$nextTick(function() {
				$('.calendarDay').css({
					minHeight: ($(window).height() - (this.$refs.content ? $(this.$refs.content).offset().top : 0) - 38)/this.ux.ratio
				});

				$('.dayHeight').css({
					height: ($(window).height() - (this.$refs.content ? $(this.$refs.content).offset().top : 0) - 38)/this.ux.ratio
				});
			})

		},
		getCalendarAreas: function() {

			this.$http.get(this.$store.state.apiEndPoint + '/calendar/aree').then(function(data) {
				var auth_calendar_areas = {};
				var calendar_areas = data.data;
				this.sessione_tipi = [];
				for(var key in calendar_areas) {
					var area = calendar_areas[key];
					var auth_tipologie = [];
					for(var j in area.tipologie) {
						if(this.auth.isUserEnable('sessione_' + area.tipologie[j].nome.toLowerCase().replaceAll(' ', '') + '_cal')) {
							auth_tipologie.push(area.tipologie[j]);
							this.sessione_tipi.push(area.tipologie[j]);
						}
					}
					if(auth_tipologie.length > 0) {
						area.tipologie = auth_tipologie;
						auth_calendar_areas[key] = area;
					}
				}
				this.calendar_areas = auth_calendar_areas;
			});
		},
		fetchCalendar: _.debounce( async function() {

			/* Check if there is something in IDB */
			await this.getIDBStoreData();

			this.fetchStarted = true

			const firstDay = this.moment(this.days.firstDay)
			let lastDay    = this.ux.view == 'days' ? this.moment(this.days.firstDay) : this.moment(this.days.lastDay)

			var request = {
				start_at: firstDay.format("YYYY-MM-DD HH:mm:ss"),
				end_at: lastDay.add(1, 'days').format("YYYY-MM-DD HH:mm:ss"),
				team_id: this.rosaTeamId,
				aggregate: true,
				players: this.ux.person_ids.toString()
			};

			if(!this.aggregate) delete request.aggregate;

			/* Check se le date nello store sono almeno la metà di quelle in visualizzazione */			
			
			const today = this.moment()
			if( today.format("YYYY-MM-DD") == this.ux.focus ) lastDay = today

			const datesInView      = Number(lastDay.diff(firstDay, 'days'))
			let dateAlreadyInStore = 0
			
			for ( const loopDate = this.moment(firstDay.format('YYYY-MM-DD')); loopDate.isBefore(lastDay); loopDate.add(1, 'days') ) {
				if( !!this.day_events[loopDate.format('YYYY-MM-DD')] && Object.keys(this.day_events[loopDate.format('YYYY-MM-DD')]).length > 0 ){
					dateAlreadyInStore++
				}
			}

			if( dateAlreadyInStore < Math.ceil(datesInView / 2) )
				this.loading = true;
			
			this.$http.post(this.$store.state.apiEndPoint + '/calendar', request).then(function(data) {
				this.calendar_data = data.data;
				this.ux.from = request.start_at.split(" ")[0];
				this.ux.to = request.end_at.split(" ")[0];
				this.loading = false;

				this.setDayEvents()
			})
			.finally( () => this.fetchStarted = false );

		}, 400 ) /** { 'leading': true } */, 

		getTeam() {
			if(this.rosaTeamId) {
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then(function(data) {	
					this.team = data.data;	
				});
			}
		},
		getPlayers: function() {				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then(function(response) {	
				this.player_options = response.data;
			});
		},
		getStaff: function() {				
			this.staff_options = [];
			this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: this.rosaTeamId, season_id: this.seasonId }}).then(function(response) {	
				this.staff_options = response.data;
			});
		},
		dayClick(date, time = null) {
			this.selected = null;
			this.players = [];
			this.nome = null;
			this.event_id = null;
			this.level = 0;
			this.start_at = date;
			this.end_at = date;
			this.time = time;
			this.$refs.eventModal.show();
		},
		eventSelected(event) {
			this.time = null;
			var calendarObject = null;
			if(event.CalendarObject) {
				calendarObject = event.CalendarObject;
			}
			if(event.base && event.base.CalendarObject) {
				calendarObject = event.base.CalendarObject;
			}
			if(calendarObject && calendarObject.interactive == false) return false;

			var request = {
				event_session_id: calendarObject.id
			};
			
			this.start_at  = this.moment(calendarObject.inizio).format("YYYY-MM-DD");
			this.end_at    = this.moment(calendarObject.fine).format("YYYY-MM-DD");
			this.from_hour = this.moment(calendarObject.inizio).format("HH:mm:ss");
			this.to_hour   = this.moment(calendarObject.fine).format("HH:mm:ss");

			if( calendarObject.area === 'Pack' ){
				
				/* Pack Events */
				this.selected  = event;
				this.$refs.eventModal.show();

			}else{	
				
				/* Generic Event */
				this.fetchStarted = true
				this.$http.post(this.$store.state.apiEndPoint + '/calendar_single_event', request).then(function({ data }) {
	
					this.event_id = data.id;
					this.nome = data.nome;
					this.level = data.level;
					this.selected  = data;
					this.season_id = data.stagione_id;
					this.players = data.persone;
					this.$refs.eventModal.show();
	
				})
				.finally( () => this.fetchStarted = false );		
			}

		},
		closeModalCalendar: function() {
			this.$refs.eventModal.hide();
			if(this.areaFromRoute == false)
				this.area = false;
		},
		handleCancel (evt) {
			this.closeModalCalendar();
			this.event_id = null;
			this.ux.modalTitle = null;
			this.nome = null;
			this.level = 0;
			this.season_id 	= null;
			this.players = [];
		},
		pickMatrix: function(sessioneId) {
			this.ux.showMatrice = sessioneId;
		},
		eventDrag(evt, item) { 
			evt.dataTransfer.dropEffect = 'move';      
			evt.dataTransfer.effectAllowed = 'move';      
			evt.dataTransfer.setData('itemID', item.id);   
		},    

		eventDrop(evt, day) {  
			if(this.ux.view == 'days') return; 
			const itemID = evt.dataTransfer.getData('itemID');      
			const item = this.calendar_data.find((item) => item.id == itemID);      

			if(item.CalendarObject.interactive == false) return false;

			if(this.moment(item.CalendarObject.inizio).format("YYYY-MM-DD") == day) return false;

			item.CalendarObject.inizio = day + ' ' + this.moment(item.CalendarObject.inizio).format("HH:mm:ss");
			item.CalendarObject.fine = day + ' ' + this.moment(item.CalendarObject.fine).format("HH:mm:ss");

			this.$http.post(this.$store.state.apiEndPoint + '/' + item.CalendarObject.controller + '/sposta-evento/' + item.id, {
				inizio: item.CalendarObject.inizio,
				fine: item.CalendarObject.fine,
			});

		},

		isUpdateInterval() {
			var start_at = this.moment(this.days.firstDay).format("YYYY-MM-DD");
			var end_at = this.ux.view == 'days' ? this.moment(this.days.firstDay).add(1, 'days').format("YYYY-MM-DD") : this.moment(this.days.lastDay).format("YYYY-MM-DD");
			if(start_at >= this.ux.from && end_at <= this.ux.to) {
				return false;
			}
		//	this.ux.from = start_at;
		//	this.ux.to = end_at;
			return true;
		},

		next(type) {
			this.ux.focus = this.moment(this.ux.focus).add(1, type).format('YYYY-MM-DD');
			if(!this.isUpdateInterval()) return false;
			this.fetchCalendar();
		},
		prev(type) {
			this.ux.focus = this.moment(this.ux.focus).subtract(1, type).format('YYYY-MM-DD');
			if(!this.isUpdateInterval()) return false;
			this.fetchCalendar();
		},
		goTo(date) {
			if(date) {
				this.ux.focus = this.moment(date).format('YYYY-MM-DD');
				if(!this.isUpdateInterval()) return false;
				this.fetchCalendar();
			}
		},
		showDateModal: function() {
			this.go_to_date = null;
			this.$refs.dateModal.show();
		},
		selectUnselect: function(key) {
			var tipologie_ids = this.calendar_areas[key].tipologie.map((item) => item.tipologia_id);
			var has = _.filter(this.ux.areas, function(a) {
				return tipologie_ids.indexOf(a) !== -1;
			});

			if(has.length > 0) {
				var newLevels = _.filter(this.ux.areas, function(a) {
					return tipologie_ids.indexOf(a) === -1;
				});
				this.ux.areas = newLevels;

			} else {
				tipologie_ids.forEach((id) => {
					if(this.ux.areas.indexOf(id) === -1)
						this.ux.areas.push(id);

				});
			}
		},

		selectAll: function() {
			this.uiCalendar_areas.forEach((area) => {
				if(this.ux.areas.indexOf(area.tipologia_id) === -1)
					this.ux.areas.push(area.tipologia_id);
			});
		},

		unselectAll: function() {
			this.ux.areas = [];
		},
	}
}

</script>