<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<PlayersOnLoan />
		<footerapp></footerapp>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
export default {
	mixins: [ pageBase ],
	components: {
		'PlayersOnLoan': require('@/components/layout/reports/PlayersOnLoan.vue').default,
	},
};
</script> 