<template>
	<div v-if="sessione.fasi && sessione.fasi.length > 0" class="d-none d-lg-block">

		<strong class="text-muted">Fasi</strong>
		<div class="d-flex">

			<div class="flex-grow-1">
				<div class="my-1" v-for="fase in sessione.fasi" :fase="fase" :is="componentCalendar(fase)"></div>
			</div>
			<div style="width: 10%; padding-left: 1em" v-if="sessione.persone.length == 1">
				<b-aspect aspect="1:1" :style="'border-radius: 100%; background-size: cover; background-image: url('+sessione.persone[0].avatar_url+')'"></b-aspect>
			</div>

		</div>

	</div>
</template>

<script>

import sessionMixin from '@/mixins/sessione.js';
import calendarFasi from '@/mixins/calendarFasi.js';
import _ from 'lodash'
import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

export default {
	mixins: [
		calendarFasi
	],
	props: [ 'sessione' ],
	computed: {
		
	},
	filters: {
		matchDate: function(d) {
			return moment(d).format('DD/MM/YY HH:mm')
		}
	},
	data: function() {
		return {
			match: null
		}
	},
	watch: {
		'sessione.partita_rel': {
			immediate: true,
			handler: function() {
				if(this.sessione.partita_rel)
					this.getPartita(this.sessione.partita_rel.partita_id);
			}
		}
	},
	methods: {
		componentCalendar: function(component) {
			var desiredComp = 'Calendar' + component.entita;
			if(this.$options.components[desiredComp])
				return desiredComp;
			return 'CalendarFase';
		},
		getPartita: function(partitaId) {
			this.$http.get(this.$store.state.apiEndPoint + '/football/partita-lite/' + partitaId).then(function(data) {
				this.match = data.data;
			})
		}
	}
}

</script>