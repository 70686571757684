<template id="gstats">
	<div class="gstats">
		<b-tabs card>
			<b-tab v-for="(season, index) in params.seasons" :title="season.stagione">
				<table class="table table-striped">
					<thead>
						<tr>
							<th title="Rosa">Rosa</th>
							<th class="text-center" title="Convocazioni in casa">Conv casa</th>
							<th class="text-center" title="Convocazioni in trasferta">Conv tras</th>
							<th class="text-center" title="Presenze partite">Pres partite</th>
							<th class="text-center" title="Partite da titolare">Titolare</th>
							<th class="text-center" title="Minuti giocati">Min gioc</th>
							<th class="text-center" title="Goal fatti">Goal</th>
							<th class="text-center" title="Ammonizioni">Amm</th>
							<th class="text-center" title="Esplulsioni">Esp</th>
							<th class="text-center" title="Presenze sessioni">Pres sess</th>
							<th class="text-center" title="Infortuni">Inf</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="team in season.teams">
							<td>{{ team.categoria.name }}</td>
							<td class="text-center">{{ team.stats.convocated_home || 0 }}</td>
							<td class="text-center">{{ team.stats.convocated_trasf || 0 }}</td>
							<td class="text-center">{{ team.stats.matches_played || 0 }}</td>
							<td class="text-center">{{ team.stats.titolare || 0 }}</td>
							<td class="text-center">{{ team.stats.minutes_played || 0 }}</td>
							<td class="text-center">{{ team.stats.goal || 0 }}</td>
							<td class="text-center">{{ team.stats.amm || 0 }}</td>
							<td class="text-center">{{ team.stats.esp || 0 }}</td>
							<td class="text-center">{{ team.stats.attendances || 0 }}</td>
							<td class="text-center">{{ team.stats.injuries || 0 }}</td>
						</tr>
					</tbody>
				</table>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
export default {
	props: {
		params: {
			type: Object,
			required: false
		}
	},
}
</script>
