<template>
	<div>
		<b-modal size="full" hide-header scrollable v-model="ux.show" v-if="glossario" @hide="close()" @close="close()" @ok="close()" @cancel="close()">

			<template #modal-header>
				Aggiungi Fasi/Test
			</template>

			<template #modal-footer>
				<b-row class="w-100">
					<b-col>
						<b-button @click.prevent="close()">Chiudi</b-button>
					</b-col>
					<b-col class="text-right">
						<b-button-group>
							<b-button class="mr-0" variant="outline-secondary" size="sm" @click.prevent="fasi = []" v-if="fasi.length > 0">Resetta</b-button>
							<b-button variant="outline-success" :disabled="fasi.length == 0" @click.prevent="emitAdd">Aggiungi i selezionati <span v-if="fasi.length > 0">({{ fasi.length }})</span></b-button>
						</b-button-group>
					</b-col>
				</b-row>
			</template>


			<b-form-input v-model="ux.quick_search" placeholder="Quick search"></b-form-input>

			<b-alert class="mt-2" :show="!hasResults" variant="warning">La ricerca non ha prodotto risultati.. <a @click.prevent="ux.quick_search = ''" href="">Riprova</a></b-alert>

			<b-tabs pills card vertical v-if="hasResults">
				<b-tab v-for="glo in theGlossario" :title="glo.name">

					<b-card no-body>
						<b-tabs pills card>
							<b-tab v-for="gruppo in glo.items" :title="gruppo.label">
								<b-row>

									<b-col cols="12" v-if="gruppo.component">
										<div @addFasi="appendFasi" :is="gruppo.component" :sessione="sessione"></div>
									</b-col>
									<b-col cols="2" class="mb-1" v-for="item in gruppo.items" v-else>

										<b-card align="center" style="cursor:pointer;height:100%;" :style="inBucket(item, glo.name) ? 'background-color: yellow' : ''" @click="selectItem(item, glo.name, glo.faseType)">
											<b-row style="height:100%">
												<b-col>
													<div><b-img v-if="item.icona_url" :src="item.icona_url" fluid width="50"></b-img></div>
													<div>{{ item.nome }}</div>
												</b-col>
											</b-row>
										</b-card>

									</b-col>

								</b-row>	
							</b-tab>
						</b-tabs>
					</b-card>
				</b-tab>
			</b-tabs>
			
			<!--
			<pre>{{ fasi }}</pre>
			<pre>{{ fasi.length }}</pre>
			<pre>{{ fasiToAdd }}</pre>
			<pre>{{theGlossario}}</pre>
			<pre>{{glossario}}</pre>

			<pre>{{sessioneCorrente}}</pre>
			<pre>{{sessione}}</pre>
		-->
	</b-modal>
</div>
</template>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';


export default {
	mixins: [ sessionMixin ],
	components: {
		ExercisePicker: require('@/components/layout/esercizi/Picker.vue').default,
		GymPicker: require('@/components/layout/palestra/Picker.vue').default,
		PoolPicker: require('@/components/layout/piscina/Picker.vue').default,
	},
	watch: {
		'ux.show': {
			deep: true,
			handler: function() {}
		}
	},
	props: [ 'sessioneCorrente', 'sessione', 'items', 'glossario' ],
	data: function() {
		return {
			ux: {
				quick_search: '',
				show: true
			},
			fasi: []
		}
	},
	
	computed: {
		hasResults: function() {
			return this.theGlossario.length > 0;
		},
		theGlossario: function() {

			if(this.ux.quick_search.length == 0) return this.glossario;

			var ilGlossario = [];

			for(var i in this.glossario) {

				var glossarioOggetto = {
					name: this.glossario[i].name,
					items: []
				};

				for(var j in this.glossario[i].items) {
					var item = this.glossario[i].items[j];
					var risultati = _.filter(item.items, function(itm) {
						return itm.nome.toLowerCase().includes(this.ux.quick_search.toLowerCase());
					}.bind(this));
					if(risultati.length > 0) {
						var gruppoOggetto = {
							label: item.label,
							items: risultati
						};
						glossarioOggetto.items.push(gruppoOggetto);
					}
				}

				if(glossarioOggetto.items.length > 0) {
					ilGlossario.push(glossarioOggetto);
				}
			}

			return ilGlossario;

		},
		fasiToAdd: function() {

			var fasi = [];

			var playerInPhase = !(this.hasSetup(this.sessione, 'disableAutoPlayerInPhase', false));

			var base = {
				visible: true,
				id: null,
				nome: '',
				durata: 0,
				persone: [],
				entita: null,
				config: null,
				setup: null,
				order: null,
				players: _.map(this.sessione.players, function(a) {
					return {
						id: a.id,
						name: a.name,
						inPhase: playerInPhase,
						activity_check: {
							checked: null,
							nota: '',
							reason: null
						}
					}
				}),
				injuries: [],
				illnesses: [],
				entity: {
					id: 0,
					entita_glo_id: null,
					nome: null,
					nota: null
				}
			};

			

			_.each(this.fasi, function(_item) {

				var item = _item.item;

				if(_item.faseType != undefined)
					item.tipologia = _item.faseType;

				var nomeSessioneDaAggiungere = item.tipologia != undefined ? item.tipologia : this.sessioneCorrente.nome;

				var nomeAtteso = 'createModel' + nomeSessioneDaAggiungere + _item.tipologia;

				var metodo = this[nomeAtteso] != undefined ? nomeAtteso : 'createModel' + nomeSessioneDaAggiungere;

				if(metodo == nomeAtteso) {
					var entityName = 'Fase_' + nomeSessioneDaAggiungere + '_' + _item.tipologia;
					var entityContainer = _.lowerCase(nomeSessioneDaAggiungere) + '_' + _.lowerCase(_item.tipologia);
				} else {
					var entityName = 'Fase_' + nomeSessioneDaAggiungere;
					var entityContainer = _.lowerCase(nomeSessioneDaAggiungere).replace(' ', '_');
				}
				
				if(item.baseObject == undefined) {

					var entitaModelGenerated = _.extend({
						nome: item.nome,
					}, this[metodo](item));

					this.$set(entitaModelGenerated, entityContainer + '_glo_id', item.id);

				} else {
					var entitaModelGenerated = _.cloneDeep(item.baseObject)
				}

				var effectiveBase = _.cloneDeep(base);

				if(this.sessione.setup && this.sessione.setup.actity_check_preset) {
					_.each(effectiveBase.players, function(player) {

						var def = _.find(this.sessione.setup.actity_check_preset.players, { persona_id: player.id });

						if(def) {
							player.activity_check.reason = def.reason;

							_.each(def.illnesses, function(a) {
								effectiveBase.illnesses.push(a);
							}.bind(this));

							_.each(def.injuries, function(a) {
								effectiveBase.injuries.push(a);
							}.bind(this));

						}

					}.bind(this))
				}

				// Controlli specifici per la configurazione della fase!
				if(item.config && item.config.ifInPhaseAlsoActivityCheck != undefined) {
					_.each(effectiveBase.players, function(p) {
						if(p.inPhase == true)
							p.activity_check.checked = true;
					});
				}

				// Sessione di tipo Prevenzione
				if(this.sessione.sessione_tipo_glo_id == 18) {
					_.each(effectiveBase.players, function(p) {
						p.activity_check.reason = 'Prevenzione';	
					});
				}

				if(entitaModelGenerated.__config != undefined) {
					item.config = _.extend(item.config, entitaModelGenerated.__config);
				}

				var fase_to_push = _.cloneDeep(_.extend(effectiveBase, {
					nome: item.nome,
					config: JSON.stringify(item.config),
					setup: item.config,
					entita: entityName,
					entity: entitaModelGenerated
				}));

								
				// Qui se hai delle fasi estensioni, viene anche iniettato, se è stata la fase a richiederlo.
				if(entitaModelGenerated.__config && entitaModelGenerated.__config.hasFaseExt != undefined) {
					_.each(entitaModelGenerated.__config.hasFaseExt, function(a) {
						fase_to_push[a]= {};
					});
				}
								

				delete entitaModelGenerated.__config;

				fasi.push(fase_to_push);

			}.bind(this));

			return fasi;

		}
	},
	methods: {
		appendFasi: function(event) {

			//console.log(event.filter);
			var k = true;
			while(k = _.findKey(this.fasi, event.filter)) {
				var indice = _.findKey(this.fasi, event.filter);
				this.fasi.splice(indice, 1);
			}

			_.each(event.fasi, function(fase) {
				this.fasi.push(fase);
			}.bind(this));
		},
		close: function() {
			this.ux.show = false;
			this.$emit('close');
		},
		inBucket: function(item, tipologia) {
			return _.find(this.fasi, function(a) {
				return a.item.id == item.id && a.tipologia === tipologia
			});
		},
		emitAdd: function() {
			this.$emit('selected', _.cloneDeep(this.fasiToAdd));
			this.fasi = [];
			this.close();
		},
		selectItem: function(item, tipologia, faseType) {

			if(!this.inBucket(item, tipologia))
				this.fasi.push({tipologia, item,faseType});
			else {
				this.fasi.splice(_.findKey(this.fasi, function(a) {
					return a.item.id == item.id
				}), 1);
			}
		},
		show: function() {
			this.ux.show = true;
		},
		createModel: function() {

			var baseObject = {
				sessione_id: this.sessione.id
			};

		},
		createModelFisioterapia: function(item) {

			return {
				__config: { hasFaseExt: [ 'Fase_Ext_BodyParts' ] },
				medical_regions: [], 
				percorso: null,
				nota: '',
				items: (item.fisioterapia_glo_config != undefined ? item.fisioterapia_glo_config.items : []),
				fisioterapia_glo_config_id: (item.fisioterapia_glo_config != undefined ? item.fisioterapia_glo_config.id : null)
			}

		},
		createModelFisioterapia_Test: function(item) {
			return {
				test: item, 
				value_1: null, 
				value_2: null, 
				value_3: null, 
				value_4: null, 
				value_5: null, 
				value_6: null
			}
		},
		createModelNutrition: function(item) {
			return {
				unita_misura: null,
				dosaggio: null,
				nota: ''
			}
		},
		createModelFarmaco: function(item) {
			return {
				nota: '',
				dosaggio_somministrato: ''
			}
		},
		createModelPsicologia_Test: function(item) {
			return {
				test: item, 
				value_1: null, 
				value_2: null, 
				value_3: null, 
				value_4: null, 
				value_5: null, 
				value_6: null
			}
		},
		createModelPalestra: function(item) {
			return {
				id_esercizio: null,
				reps: null,
				sets: null,
				rest: null,
				id_facility: null,
				id_goal: null,
				id_tt: null,
				id_intensity: null,
				reps_dx: null,
				reps_sx: null,
				has_reps: null,
				intensity_value_1: null,
				intensity_value_2: null,
				intensity_value_3: null,
				intensity_value_4: null,
				drill_gps: null
			}
		},
		createModelEsercizio: function(item) {
			return {
				description: null,
				players: null,
				training_minutes: null,
				sets: null,
				recovery_minutes: null,
				recoveries: null,
				exercise_id: null,
				field_size: null,
				recovery_seconds: null,
				drill_id: null,
				g1: null,
				g2: null,
				g3: null,
				g4: null,
				jolly: null,
				sponde: null,
				portieri: null,
				porte_grandi: null,
				porte_piccole: null,
				campo_lunghezza: null,
				campo_larghezza: null,
				drill_gps: null,
				esercizio: null,
				skills_rel: []
			}
		},
		createModelPartita: function(item) {
			return {
				description: null,
				modulo1: null,
				modulo2: null,
				match_players1: [],
				match_players2: [],
				players: null,
				training_minutes: null,
				sets: null,
				recovery_minutes: null,
				recoveries: null,
				field_size: null,
				recovery_seconds: null,
				drill_id: null,
				g1: null,
				g2: null,
				g3: null,
				g4: null,
				jolly: null,
				sponde: null,
				portieri: null,
				porte_grandi: null,
				porte_piccole: null,
				campo_lunghezza: null,
				campo_larghezza: null,
				drill_gps: null,
				skills_rel: []
			}
		},
		createModelVideo: function(item) {
			return {
				sorgente: null,
				videocartella_id: null,
				videocartella: null,
				filebrowser_path: null,
				video: {
					type: 'asroma',
					titolo: '',
					fileNameVideo: null,
				}
			}
		},
		createModelDocumento: function(item) {
			return {
				sorgente: null,
				filebrowser_path: null,
			}
		},
	}
}

</script>