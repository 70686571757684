<template>
  <data-table
  api-url="/admin/squadre"
  :columns="columns"
  :editFormData="teamsData"
  :canDelete="false"
  :defaultFilter="false"
  @showModal="onShowModal"
  @editError="editError">

  <template slot="filters">
    <b-form-input v-model="filters.name" placeholder="Nome squadra" class="inline mr-3" />
    <!--b-form-input v-model="filters.stadium" placeholder="Stadio" class="inline mr-3" /-->
  </template>

  <template slot="edit-modal-body" slot-scope="row">
    <b-form-group label="Città">
      <b-form-input v-model="teamsData.city"/>
    </b-form-group>
    <b-form-group label="Paese">
      <b-form-select v-model="teamsData.area">
        <b-form-select-option :value=" null">Seleziona</b-form-select-option>
        <b-form-select-option v-for="paese in paesi" :value="paese.id">{{ paese.paese }}</b-form-select-option>
      </b-form-select>
    </b-form-group>
      <!--b-form-group label="Nome">
        <multiselect
          v-model="teamsData.stadium"
          :options="stadiums"
          placeholder="Seleziona uno stadio"
          label="nome"
          track-by="id"
          :internal-search="false"
          :loading="stadiumsLoading"
          :customLabel="customLabelStadio"
          :show-labels="false"
          @search-change="searchStadiums" />
        </b-form-group-->
      </template>
    </data-table>
  </template>

  <script>
  import axios from 'axios';
  import DataTable from './DataTable.vue';

  import { ALERTS_PUSH } from '../../../../store/types';

  export default {
    name: 'AdminTeams',
    components: { DataTable },

    data() {
      return {
        columns: [
          { key: 'id', label: 'ID', sortable: true },
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'officialName', label: 'Nome Ufficiale', sortable: true },
          { key: 'city', label: 'Città', sortable: true },
          { key: 'paese', label: 'Paese', sortable: true },
      /*{ key: 'stadio', label: 'Stadio', sortable: true },
        { key: 'cityStadio', label: 'Città Stadio', sortable: true },
        { key: 'paeseStadio', label: 'Paese Stadio', sortable: true }, */
          { key: 'actions', label: 'Azioni' },
          ],
        teamsData: {
      //stadium: null,
          city: '',
          area: null,
        },
    //stadiumsLoading: false,
    //stadiums: [],
        filters: {
          name: '',
      //stadium: ''
        },
        paesi: [],
      };
    },

    methods: {
      onShowModal(row) {
        this.teamsData.city = row.item.city;
        this.teamsData.area = row.item.area;
      },
      editError(err) {
        if (err.response && err.response.data.error) {
          this.$store.commit(ALERTS_PUSH, {
            status: 0,
            msg: err.response.data.error,
          });
        }
      },
      getPaesi() {
        axios.get('/paesi').then((res) => {
          this.paesi = res.data;
        }).catch(() => { 
        });
      }

  /*searchStadiums(query) {
      this.stadiumsLoading = true;
      axios.get('/stadi', {
        params: {
          additional_filters: {"stadium": query},
          per_page: 300,
        },
      }).then((res) => {
        this.stadiums = res.data.data;
        this.stadiumsLoading = false;
      }).catch(() => {
        this.stadiumsLoading = false;
        this.$store.commit(ALERTS_PUSH, {
          status: 0,
          msg: 'Qualcosa è andato storto, prova a ricaricare la pagina',
        });
      });
    },  
    customLabelStadio({ nome, city, paese }) {
      return nome + ' ' + (city ? ' - ' + city : '') + (paese ? ' - ' + paese : '');
    },  */
    },

    watch: {
      filters: {
        handler() {
          this.$root.$emit('data-table::filter', this.filters);
        },
        deep: true,
      },
    },

    created() {
      this.getPaesi();
    },
  };
  </script>

  <style lang="scss">
  .pac-container {
    z-index: 1051 !important;
  }
  </style>
