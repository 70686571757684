<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		
		<div class="card">
			<b-container fluid class="py-1 mb-4">
				<b-row>
					<b-col :cols="2">
						
						<b-nav tabs class="mb-1">
							<b-nav-item @click.prevent="ux.tab = 'all'" :active="ux.tab == 'all'">Tutte</b-nav-item>
							<b-nav-item @click.prevent="ux.tab = 'primitive'" :active="ux.tab == 'primitive'">Primit.</b-nav-item>
							<b-nav-item @click.prevent="ux.tab = 'mine'" :active="ux.tab == 'mine'">Tue</b-nav-item>
						</b-nav>

						<b-input v-model="ux.search" type="search" class="mb-1" placeholder="Cerca un Preset" size="sm"></b-input>

						<b-list-group>
							<b-list-group-item v-for="preset in presets" :to="{ name: 'contratti_editor_detail', params: { preset_id: preset.id } }" :active="preset.id == $route.params.preset_id">
								<span v-if="preset.isDraft" class="text-danger small">DRAFT</span>
								<span v-if="preset.isPrimitive" class="bg-warning px-1 small">PRI</span>
								{{ preset.nome }}
							</b-list-group-item>
						</b-list-group>

						<b-button class="mt-2" :to="{ name: 'contratti_editor_new' }" block size="sm" variant="outline-success">Nuovo Preset</b-button>
					</b-col>
					<b-col :cols="10" class="py-2 px-4">
						<h4 class="card-title">Editor dei Preset</h4>
						<b-alert show variant="light">
							<p class="lead m-0">In quest'area puoi costruire le regole da utilizzare nei <strong>bonus</strong> e nei rispettivi <strong>alert</strong>.</p>
						</b-alert>

						<div class="my-2">
							<p class="lead mx-4">Seleziona un preset dal menù di sinistra, o <router-link :to="{ name: 'contratti_editor_new' }">creane uno nuovo.</router-link></p>

							<div class="m-4">
								Le voci in <strong class="bg-warning px-1">Arancione</strong> sono le regole Primitive.
							</div>
						</div>

					</b-col>
				</b-row>
			</b-container>
		</div>

	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	data: function(){
		return {
			stagioniAsRoma: false,
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			variabiliSquadra: null,
			stagioneInfo: false,
			stagioniInfo: false,
			presets: null,
			ux: {
				tab: 'all',
				search: ''
			}
		}
	},
	filters: {
		date: function(a) {
			return moment(a).format('DD/MM/YYYY')
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},
	watch: {
		'ux.search': {
			handler: _.debounce(function() {
				this.fetchPresets();
			}, 300)
		},
		'ux.tab': {
			handler: function() {
				this.ux.search = null;
				this.fetchPresets();
			}
		},
		rosaTeamId() {
			this.getVariabili();
		},
		seasonId() {
			//this.getVariabili();
		},
	},
	created: function() {
		// this.getVariabili();
		this.fetchPresets();
		//this.getStagioni();
		//this.getStagioniASR();
	},
	methods: {
		newSeason: function() {
			this.variabiliSquadra = {
				name: '',
				inizio: '',
				fine: '',
				squadra_id: this.rosaTeamId,
				stagione_id: false,
				vittoria_serie_a: 0,
				vittoria_coppa_italia: 0,
				vittoria_uel: 0,
				vittoria_ucl: 0,
				qualificazione_ucl_group_stage: 0,
				qualificazione_ucl_round_16: 0,
				qualificazione_ucl_quarter_final: 0,
				qualificazione_ucl_semifinal: 0,
				qualificazione_ucl_final: 0
			};
		},
		fetchPresets: function() {
			
			var search = {};
			if(this.ux.tab == 'mine') {
				search.mine = true;
			}

			if(this.ux.tab == 'primitive') {
				search.primitive = true;
			}

			if(this.ux.search && this.ux.search.length > 2)
				search.search = this.ux.search;

			this.$http.post(this.$store.state.apiEndPoint + '/contratti/presets-rules', search).then(function(data) {
				this.presets = data.data;
			});

		},
		getStagioneInfo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagione/' + this.seasonId).then(function(data) {
				this.stagioneInfo = data.data;
			});
		},
		getStagioni: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/stagioni').then(function(data) {
				this.stagioniInfo = data.data;
				if(this.variabiliSquadra == null) {

					this.variabiliSquadra = _.findLast(this.stagioniInfo, function(a) {
						return a.stagione_id != null;
					});
				}
			});
		},
		getStagioniASR: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/contratti/info-stagioni').then(function(data) {
				this.stagioniAsRoma = data.data;
			});
		},
		updateVars: function() {

			this.$http.post(this.$store.state.apiEndPoint + '/contratto/variabili-squadra-update', { vars: this.variabiliSquadra }).then(function(data) {
				this.getStagioni();
				this.variabiliSquadra = data.data;
			});

		},
		deleteVariabili: function() {
			if(!window.confirm("Confermi la rimozione della stagione?")) return false;
			this.$http.post(this.$store.state.apiEndPoint + '/contratto/delete-variabili-squadra/' + this.variabiliSquadra.id).then(function() {
				this.variabiliSquadra = null;
				this.getStagioni();
			});
		},
		getVariabili: function() {
			this.getStagioneInfo(this.seasonId);
			this.variabiliSquadra = false;
			this.$http.get(this.$store.state.apiEndPoint + '/contratto/variabili-squadra/' + this.rosaTeamId + '/' + this.seasonId).then((response) => {
				if (response.ok) {
					if(response.data.id)
						this.variabiliSquadra = response.data;
					else {
						this.newSeason();
					}
				}
			});
		},
	}
}
</script>