<template>
	<div>
		<b-overlay :show="!glossario" rounded="sm">
			<div style="min-width: 300px; min-height: 100px">
			<div v-if="glossario">

				<div v-for="item in glossario">
					<strong>{{item.name}}</strong>

					<div class="ml-2" v-if="item.items && item.items.length <= 9">
						<div v-for="i in item.items">
							- {{ i.label }}

							<!-- <div class="ml-2" v-if="i.items && i.items.length <= 9">
								<span class="text-muted" v-for="a, k in i.items">
									{{ a.nome }}<span v-if="k != i.items.length-1">,</span>
								</span>
							</div>
							<p v-else="i.items.length > 10">10</p> -->

						</div>
					</div>


				</div>
	<!-- 

				<b-list-group>
					<b-list-group-item v-for="item in glossario">
						{{ item.name }}
						<b-list-group v-if="item.items && item.items.length < 10">
							<b-list-group-item v-for="i in item.items">
								{{ i.label }}
								<b-list-group v-if="i.items && i.items.length < 10">
									<b-list-group-item v-for="u in i.items">
										{{ u.nome }}
									</b-list-group-item>
								</b-list-group>
							</b-list-group-item>
						</b-list-group>
					</b-list-group-item>
				</b-list-group> -->
			</div>
			</div>
		</b-overlay>

	</div>
</template>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'area', 'teamId' ],
	data: function() {
		return {
			glossario: null
		};
	},
	created: function() {
		this.fetchGlossario(this.area.tipologia_id);
	},
	methods: {
		fetchGlossario: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/sessioni/glossario', {params: { area: id, team_id: this.teamId }} ).then(function(data) {
				this.glossario = data.data;
			})
		}
	}
}

</script>