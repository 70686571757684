<template>
	<div>
		<div ref="chart" :style="'margin: auto; width: '+(width||400)+'px; height: '+ (height||400) +'px'"></div>
	</div>
</template>

<script>

var Auth = require('@/auth.js');
import moment from 'moment';
import videojs from 'video.js';
import _ from 'lodash';

export default {
	props: [ 'ds', 'label', 'width', 'height' ],
	methods: {
		buildGraph: function() {
			var chartDom = this.$refs.chart;
			var myChart = echarts.init(chartDom);
			myChart.setOption(this.options);
		}
	},
	watch: {
		ds: {
			deep: true,
			handler: function() {
				this.buildGraph();
			}
		}
	},
	mounted: function() {

		this.buildGraph();

	},
	computed: {
		options: function() {

			return {
				tooltip: {
					trigger: 'item'
				},
				series: [
					{
						name: this.label,
						type: 'pie',
						radius: '50%',
						data: _.map(this.ds, function(a, b) {
							return { name: b.replace('|', ' | '), value: a };
						}),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			};

		}
	}
}

</script>