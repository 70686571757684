<template id="statistiche">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Statistiche</span>
					</div>
					<div class="content">
						<div class="row">
							<div class="col-3" style="height:65vh;overflow:auto;">
								<div class="row">
									<div class="col-1 mr-2">
										<flag :country="paese" :size="32"></flag>
									</div>
									<div class="col"> 
										<span class="m-1" style="font-size: 1.25rem;">{{ title }}</span>
									</div>
								</div>
								<div v-if="loading" align="center" style="width:100%">
									<img src="/assets/varie/loading.gif" style="width: 150px;" />
								</div>
								<div class="row my-2 border-bottom" v-if="stats.squadre && !loading">
									<h5>Squadre osservate: {{ stats.squadre.length }}</h5>
								</div>
								<div class="row my-3 pb-2 align-items-center border-bottom" v-for="squadra in stats.squadre" v-if="!loading">
									<div class="col-2">
										<img :src="squadra.logo_url" height="50" />
									</div>
									<div class="col"> 
										<span style="font-size: 1.2rem;">{{ squadra.name }}</span><br/>
										<span>partite: {{ squadra.partite_count }}</span><br/>
										<span>valutazioni: {{ squadra.count_val_gio }}</span><br/>
										<span>live: {{ squadra.count_val_reports }}</span>
									</div>
								</div>
							</div>
							<div class="col-9">
								<div id="chartdiv" style="width: 100%; height: 500px;"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>

import AmCharts from 'ammap3';
import 'ammap3/ammap/ammap.js';
import 'ammap3/ammap/maps/js/worldLow.js';
import 'ammap3/ammap/maps/js/continentsLow.js';
import 'ammap3/ammap/maps/js/unitedKingdomLow.js';
import 'ammap3/ammap/plugins/export/export.min.js';
import 'ammap3/ammap/lang/it.js';
import moment from 'moment';

export default {

	data: function() {
		return {
			paesi: [],
			title: 'Mondo',
			paese: '',
			stats: {},
			loading: false,
		}
	},
	created: function () {
		this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
			if (response.ok)
			{
				this.paesi = response.data; 
			}
		}, (response) => {
            // error callback
        });
	},
	computed: {
		ukDataProvider: function() {
			return {
				"map": "unitedKingdomLow",
				"getAreasFromMap": true,
			}
		},

		worldDataProvider: function() {
			return {
				"map": "worldLow",
				"getAreasFromMap": true,
			}
		},

		continentsDataProvider: function() {
			return {
				"map": "continentsLow",

				"areas": [ {
					"id": "africa",
					"linkToObject": this.worldDataProvider,
					"color": "#7E7E7E",
					"passZoomValuesToTarget": true,
				}, {
					"id": "asia",
					"linkToObject": this.worldDataProvider,
					"color": "#FFC64F",
					"passZoomValuesToTarget": true
				}, {
					"id": "australia",
					"linkToObject": this.worldDataProvider,
					"color": "#74D039",
					"passZoomValuesToTarget": true
				}, {
					"id": "europe",
					"linkToObject": this.worldDataProvider,
					"color": "#00AAE5",
					"passZoomValuesToTarget": true
				}, {
					"id": "north_america",
					"linkToObject": this.worldDataProvider,
					"color": "#FF3C00",
					"passZoomValuesToTarget": true
				}, {
					"id": "south_america",
					"linkToObject": this.worldDataProvider,
					"color": "#FF8C00",
					"passZoomValuesToTarget": true
				} ],
			}
		}
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		flag: 				require('../../layout/flag.vue').default,
	},

	mounted () {
		this.$map = new window.AmCharts.makeChart( "chartdiv", {
			"type": "map",
			"theme": "light",
			"projection": "miller",
			"language": "it",
			"areasSettings": {
				"autoZoom": true,
				"rollOverOutlineColor": "#A80532",
				"selectedColor": "#A80532",
				"color": "#FFC64F",
				"rollOverColor": "#A80532"
			},

			"dataProvider": this.continentsDataProvider,

			"listeners": [ {
				"event": "homeButtonClicked",
				"method": this.handleGoHome
			}, {
				"event": "clickMapObject",
				"method": this.handleMapObjectClick
			}]
		});
	},
	methods: {
		handleGoHome() {
			this.title = 'Mondo';
			this.paese = '';
			this.stats = {};
			this.$map.dataProvider = this.continentsDataProvider;
			this.$map.validateNow();
		},

		handleMapObjectClick( event ) {
			this.title = event.mapObject.titleTr;
			this.paese = event.mapObject.id;
			if(this.paese == "GB") {
				this.paese = '';
				this.stats = {};
				this.$map.dataProvider = this.ukDataProvider;
				this.$map.validateNow();	
			} else {
				var id = this.getId(this.paese);
				this.loadStats(id);
			}
		},

		loadStats(id) {
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/statistiche/' + id).then((response) => {
				if (response.ok)
				{
					this.stats = response.data; 
					this.loading = false;
				}
			}, (response) => {
	            // error callback
	        });
		},
		getId(paese) {
			if(paese.length == 2) {
				for(var i in this.paesi) {
					var item = this.paesi[i];
					if(item.sigla == paese) {
						return item.id;
					}
				}
			} else {
				switch(paese) {
					case 'africa': return 250;
					case 'asia': return 267;
					case 'australia': return 259;
					case 'europe': return 253;
					case 'north_america': return 255;
					case 'south_america': return 262;
				}
			}
		},
		getLabelPartita(partita) {
			if(partita.squadre[0].pivot.side == 'home') {
				return partita.squadre[0].name + " - " + partita.squadre[1].name;
			} else {
				return partita.squadre[1].name + " - " + partita.squadre[0].name;
		   }
		}
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		},
	},
}
</script>
