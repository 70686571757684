<template>
	<div v-if="selezioni">

		<b-form-select :multiple="options.multiple" v-if="!readonly" size="sm" v-model="test[field]">
			<b-form-select-option v-for="option in selezioni.data" :value="option[options.sel_value]">
				{{ option[options.sel_label] }}
			</b-form-select-option>
		</b-form-select>
		<span v-else-if="realValue" class="truncate">
			{{ realValue[options.sel_label] }}
		</span>
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			alternativeData: null
		}
	},
	created: function() {

		if(this.dataLayer == undefined) {
			this.$http.get(this.$store.state.apiEndPoint + this.options.url).then(function(data) {
				this.alternativeData = { data: data.data, url: this.options.url };
			});
		}

	},
	methods: {
		
	},
	watch: {
		dataLayer: {
			deep: true,
			handler: function() {

			}
		}
	},
	computed: {
		realValue: function() {

			var selezione = _.find(this.selezioni.data, { id: this.test[this.field] });
			if(selezione) return selezione;
			return false;

		},
		selezioni: function() {
			
			var item = _.find(this.dataLayer, { url: this.options.url });
			if(item) return item;

			if(this.alternativeData)
				return this.alternativeData;

			return false;
		}
	}
}
	
</script>