export const ROUTES_ROMANEXT = [
    {
        path: '/romanext/dashboard',
        name: 'romanext_dashboard',
        component: require('../components/pages/romanext/dashboard.vue').default,
        meta: {
            module: 'romanext',

            label: 'Romanext',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'romanext',
        }
    },
];
