<template id="elencopartite">
	<div class="elencopartite">
		<b-row v-for="(partita, index) in params.partite" class="py-3">
			<b-col>
				<a :href="auth.isUserEnable('football_partite') ? '#/football/partita/' + partita.id : 'javascript:;'" :style="'text-decoration:none;' + (!auth.isUserEnable('football_partite') ? 'cursor:default' : '')">
					<div class="ultimapartita">
						<div class="versus mb-0">
							<div class="row flex-items-xs-middle flex-items-xs-center text-sm-center">
								<div class="col-4">

									<div><img :src="partita.home ? partita.home.logo_url : 'assets/varie/team.png'"></div>
									<div>
										<span>{{ partita.home ? partita.home.name : partita.home_team }}</span>
										<span class="color-rosso pl-1" v-if="partita.eventi">{{ homeGoals(partita) }}</span>
									</div>

								</div>
								<div class="col-4">

									<div class="versus__vs">
										<span>VS.</span>
									</div>

								</div>
								<div class="col-4">

									<div><img :src="partita.away ? partita.away.logo_url : 'assets/varie/team.png'"></div>
									<div>
										<span>{{ partita.away ? partita.away.name : partita.away_team }}</span>
										<span class="color-rosso pl-1" v-if="partita.eventi">{{ awayGoals(partita) }}</span>
									</div>

								</div>
							</div>
						</div>
						<div v-if="partita.competizione" class="text-sm-center">
							{{ partita.competizione.nome }}
						</div>
						<div class="text-sm-center">
							{{ partita.data | formatDateHour }}
						</div>
				</div>
			</a>
		</b-col>
		<b-col align-self="center" v-if="partita.sintesi">
			<div class="row px-1">
				<div class="col-3">
					<button :class="'btn btn-sm btn-block ' + (partita.video.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(index, 1)" :disabled="partita.video.length == 0">Partita</button>
				</div>
				<div class="col-3">
					<button :class="'btn btn-sm btn-block ' + (partita.goals.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(index, 4)" :disabled="partita.goals.length == 0">Goals</button>
				</div>
				<div class="col-3">
					<button :class="'btn btn-sm btn-block ' + (partita.highlights.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(index, 2)" :disabled="partita.highlights.length == 0">Highlights</button>
				</div>
				<div class="col-3">
					<!--button :class="'btn btn-sm btn-block ' + (partita.sintesi.length == 0 ? 'btn-outline-primary' : 'btn-primary')" @click="selectClips(index, 3)" :disabled="partita.sintesi.length == 0">Sintesi</button-->
					<button class="btn btn-sm btn-block btn-primary" @click="downloadPdf(partita.id)">Cartellino</button>
				</div>
			</div>
		</b-col>
	</b-row>
</div>
</template>

<script>
var Auth = require('../../auth.js');
import moment from 'moment';
export default {
	data: function(){
		return {

		}
	},
	props: {
		params: {
			type: Object,
			required: false
		}
	},
	computed: {
		auth: function() {
			return Auth;
		}
	},
	methods: {
		selectClips(index, tipo) {
			this.$bus.$emit('selectClips', index, tipo);
		},
		homeGoals(partita) {
			var count = 0;
			for(var i in partita.eventi) {
				if(partita.eventi[i].partita_evento_tipo_glo_id != 4) {
					if(partita.eventi[i].team_id == partita.home_id) {
						count++;
					}
				} else {
					if(partita.eventi[i].team_id != partita.home_id) {
						count++;
					}
				}
			}
			return count;
		},
		awayGoals(partita) {
			var count = 0;
			for(var i in partita.eventi) {
				if(partita.eventi[i].partita_evento_tipo_glo_id != 4) {
					if(partita.eventi[i].team_id == partita.away_id) {
						count++;
					}
				} else {
					if(partita.eventi[i].team_id != partita.away_id) {
						count++;
					}
				}
			}
			return count;
		},
		downloadPdf(id){
			window.open(this.$store.state.apiEndPoint + '/partita/pdf/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date).locale('IT');
			if(m.isValid()) {
				return m.format('DD MMMM YYYY, HH:mm'); 
			}
			return "";
		},
	},
}
</script>
