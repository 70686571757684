<template id="esercizibozze">
	<div class="esercizibozze">
		<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 90vh; overflow-y: scroll;" class="row">
			<div class="col-md-4 col-lg-4 col-xl-4 mb-4" style="min-height:250px;" v-if="auth.isUserEnable('academy_ita_ese_mng')">
				<router-link :to="{ name: academy ? 'academy_int_esercizi_new' : 'academy_ita_esercizi_new'}" class="w-100 h-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;">
					<div class="text-center">
						<p class="mb-0"><i class="fa fa-plus-circle text-muted fa-5x"></i></p>
						<strong class="text-uppercase text-muted">{{ $i18n.t('Nuovo esercizio') }}</strong>
					</div>
				</router-link>
			</div>
			<div class="col-md-4 col-lg-4 col-xl-4 mb-4" style="min-height:250px;" v-if="auth.isUserEnable('academy_import_ese')">
				<div class="w-100 h-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;cursor:pointer;" @click="openImportExe(-1)">
					<div class="text-center">
						<p class="mb-0"><i class="fa fa-share-square text-muted fa-5x"></i></p>
						<strong class="text-uppercase text-muted">{{ $i18n.t('Condividi da Football') }}</strong>
					</div>
				</div>
			</div>
			<div class="col-md-4 col-lg-4 col-xl-4 mb-4" style="min-height:250px;" v-if="academy && auth.isUserEnable('academy_import_ese')">
				<div class="w-100 h-100 d-flex align-items-center justify-content-center" style="border: 5px dashed #e5e5e5;cursor:pointer;" @click="openImportExe(0)">
					<div class="text-center">
						<p class="mb-0"><i class="fa fa-share-square text-muted fa-5x"></i></p>
						<strong class="text-uppercase text-muted">{{ $i18n.t('Condividi da Academy Italia') }}</strong>
					</div>
				</div>
			</div>
			<div class="col-md-4 col-lg-4 col-xl-4 mb-4" v-for="ex in exercises_bozze.data">
				<esercizicard :ex="ex" :skills="skills(ex)" :skills_portiere="skills_portiere(ex)"></esercizicard>
			</div>
		</v-infinite-scroll>
	</div>
</template>

<script>
var Auth = require('../../../auth.js');

import axios from 'axios';
import { mapState } from 'vuex';
import InfiniteScroll from 'v-infinite-scroll';

export default {
	methods: {
		nextPage() {
			if(this.page < this.exercises_bozze.last_page) {
				this.$store.commit('acad_esercizi/setPage', this.page + 1);
				this.$store.dispatch('acad_esercizi/filter_bozze', this.page + 1);
			}
		},
		skills(ex) {
			return JSON.parse(ex.skill.standard);
		},

		skills_portiere(ex) {
			return JSON.parse(ex.skill.special);
		},
		openImportExe(academy) {
			this.$root.$emit('condividiEserciziDaFootball', academy);
		}
	},

	computed: {
		auth: function() {
          return Auth;
        },
		...mapState('acad_esercizi', ['exercises_bozze', 'page', 'academy']),
	},

	components: {
		esercizicard: require('./esercizicard.vue').default,
		'v-infinite-scroll': InfiniteScroll
	},

	mounted() {
		this.$store.commit('acad_esercizi/setPage', 1);
		this.$store.dispatch('acad_esercizi/filter_bozze');
	},

}
</script>
