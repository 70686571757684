import Vue from 'vue';
import { isPlainObject, has, findIndex } from 'lodash';

export default {
  set(state, payload) {
    if (!payload.key) return;

    state[payload.key] = payload.value;
  },

  setBackground(state, background) {
    state.canvas.background = isPlainObject(background) ? background.imageUrl : background;
  },

  setCanvasObjects(state, objects) {
    state.canvasObjects = objects;

    if (!state.canvas.background && has(state.canvasObjects, 'backgrounds[0].imageUrl')) {
      state.canvas.background = state.canvasObjects.backgrounds[0].imageUrl;
    }
  },

  setCanvasColor(state, color) {
    state.canvas.color.hex = color;
  },

  toggleDrawingMode(state) {
    state.canvas.drawingMode = !state.canvas.drawingMode;
    Vue.set(state.canvas, 'wavy', false);
    Vue.set(state.canvas, 'dashed', false);
  },

  setDrawingMode(state, drawingMode){
    state.canvas.drawingMode = drawingMode;
  },

  setLineWidth(state, width) {
    if (width < 1) {
      state.canvas.lineWidth = 1;
    } else if (width > 150) {
      state.canvas.lineWidth = 150;
    } else {
      state.canvas.lineWidth = width;
    }
  },

  export (state, json) {
    state.canvas.export = json;
  },

  exportImage(state, image) {
    state.canvas.image = image;
  },

  setExercises(state, data) {
    state.exercises = data.data;
    state.pagination.currentPage = data.currentPage;
    state.pagination.length = data.lastPage;
  },

  deleteExercise(state, id) {
    const index = findIndex(state.exercises, ['id', id]);

    if (index !== -1) {
      state.exercises.splice(index, 1);
    }
  },

  resetExercises(state) {
    state.exercises = [];
    state.pagination.currentPage = 1;
    state.pagination.length = 1;
  },

  resetCanvasEditor(state) {
    state.canvas.background = null;
    state.canvas.color.hex = '#000000';
    state.canvas.drawingMode = false;
    state.canvas.lineWidth = 1;
    state.canvas.export = '';
    state.canvas.image = '';
  },

  toggleWavy(state) {
    state.canvas.wavy = !state.canvas.wavy;
    Vue.set(state.canvas, 'drawingMode', false);
  },

  removeWavy(state) {
    Vue.set(state.canvas, 'wavy', false);
    Vue.set(state.canvas, 'dashed', false);
  },

  setOpacity(state, value) {
    state.canvas.opacity = value;
  },

  setDashed(state, value) {
    state.canvas.dashed = value;
  },

  setActiveObject(state, obj) {
    if (typeof obj === 'string') {
      state.activeObject = state.activeObject && state.activeObject === obj ? null : obj;
    } else {
      state.activeObject = state.activeObject && state.activeObject.id === obj.id ? null : obj;
    }
    if (state.canvas.drawingMode) {
      Vue.set(state.canvas, 'drawingMode', false);
    }
    if (state.canvas.wavy) {
      Vue.set(state.canvas, 'wavy', false);
    }
  },

  removeActiveObject(state) {
    state.activeObject = null;
  },

};
