<template>
  <b-navbar toggleable="sm" variant="primary">

    <b-navbar-nav v-if="isInHome">
      <b-nav-item href="#" @click.prevent="toggleLeft">Risultati</b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="nav-center">
      <b-nav-item to="/geomatch">Home</b-nav-item>
      <b-nav-item v-if="isInHome" to="/geomatch/admin">Admin</b-nav-item>
      <template v-if="isInAdmin">
        <b-nav-item to="/geomatch/admin">Stadi</b-nav-item>
        <b-nav-item to="/geomatch/admin/matches">Partite</b-nav-item>
        <b-nav-item to="/geomatch/admin/teams">Squadre</b-nav-item>
        <b-nav-item to="/geomatch/admin/competizioni">Competizioni</b-nav-item>
      </template>
    </b-navbar-nav>

    <b-navbar-nav class="ml-auto" v-if="isInHome">
      <b-nav-item href="#" @click.prevent="toggleRight">Filtri</b-nav-item>
    </b-navbar-nav>

  </b-navbar>
</template>

<script>
export default {
  name: 'Header',

  computed: {
    isInHome() {
      return this.$route.path === '/geomatch';
    },

    isInAdmin() {
      return this.$route.path.startsWith('/geomatch/admin');
    },
  },

  methods: {
    toggleLeft() {
      this.$emit('onLeftToggle');
    },

    toggleRight() {
      this.$emit('onRightToggle');
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-light .navbar-nav, .nav-center {
  .nav-link, .nav-link:hover, .nav-link:active, .nav-link:focus {
    color: #fff !important;
  }
}

.nav-center {
  flex: 1;
  justify-content: center;
}
</style>
