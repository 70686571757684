<template>
	<div :style="contentHeight && isFullscreen ? 'max-height:' + contentHeight + 'px;overflow-y:auto;overflow-x:hidden;' : ''">
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card title="Minutaggio Partite" :no-body="isWidget ? true : false">
			<b-row class="mb-2" align-v="center" v-if="!isWidget || isFullscreen">
				<b-col cols="2">
					<b-datepicker v-model="filters.from" placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
				</b-col>
				<b-col cols="2">
					<b-datepicker v-model="filters.to" placeholder="To" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>		
				</b-col>
				<b-col cols="3">
					<b-form-checkbox-group size="sm" v-model="filters.match_types" buttons button-variant="outline-primary">
						<b-form-checkbox v-for="type in match_types" :value="type">{{ type }}</b-form-checkbox>
					</b-form-checkbox-group>
				</b-col>
				<b-col cols="1">
					<b-button size="sm" variant="primary" @click="fetchPlayersInConvitto"><i class="fa fa-search" aria-hidden="true"></i></b-button> 
				</b-col>
				<b-col>
					<b-checkbox size="sm" v-model="filters.only_convitto" :value="true"> Mostra solo giocatori in convitto </b-checkbox>
				</b-col>
			</b-row>

			<b-table-simple :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 30) + 'px' : '73vh'" hover responsive small striped>
				<b-thead>
					<b-tr>
						<b-th v-if="ux.hideDisabled" class="text-center" style="border:none;"></b-th>
						<b-th class="text-center" style="border:none;">	
							#
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('in_convitto')"><i class="fa fa-home" title="In convitto"></i></a> <span v-if="getIndex('in_convitto') > -1">{{ filters.order_dir[getIndex('in_convitto')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th style="border:none;">	
							<a href="" @click.prevent="setOrdering('cognome')">COGNOME</a> <span v-if="getIndex('cognome') > -1">{{ filters.order_dir[getIndex('cognome')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th style="border:none;">	
							<a href="" @click.prevent="setOrdering('nome')">NOME</a> <span v-if="getIndex('nome') > -1">{{ filters.order_dir[getIndex('nome')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('percentuale_giocata')">% DI MIN TOTALI</a> <span v-if="getIndex('percentuale_giocata') > -1">{{ filters.order_dir[getIndex('percentuale_giocata')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">
							<a href="" @click.prevent="setOrdering('min_totali')">MIN GIOCATI</a> <span v-if="getIndex('min_totali') > -1">{{ filters.order_dir[getIndex('min_totali')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('starting')">STARTING 11</a> <span v-if="getIndex('starting') > -1">{{ filters.order_dir[getIndex('starting')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('partite')">PARTITE</a> <span v-if="getIndex('partite') > -1">{{ filters.order_dir[getIndex('partite')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('disponibilita')">DISPONIBILITA</a> <span v-if="getIndex('disponibilita') > -1">{{ filters.order_dir[getIndex('disponibilita')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('convocazioni')">CONVOCAZIONI</a> <span v-if="getIndex('convocazioni') > -1">{{ filters.order_dir[getIndex('convocazioni')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('altra_squadra')">ALTRA SQUADRA</a> <span v-if="getIndex('altra_squadra') > -1">{{ filters.order_dir[getIndex('altra_squadra')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('infortunato')">INFORTUNATO</a> <span v-if="getIndex('infortunato') > -1">{{ filters.order_dir[getIndex('infortunato')] == -1 ? '↥' : '↧' }}</span>
						</b-th>
						<b-th class="text-center" style="border:none;">	
							<a href="" @click.prevent="setOrdering('squalificato')">SQUALIFICATO</a> <span v-if="getIndex('squalificato') > -1">{{ filters.order_dir[getIndex('squalificato')] == -1 ? '↥' : '↧' }}</span>
						</b-th>

					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="player, index in players" :key="player.persona_id" :variant="ux.hideDisabled == true ? (( hidePreset.indexOf(player.persona_id) !== -1 ) ? 'danger':'') : ''">
						<b-td v-if="ux.hideDisabled" class="text-center"><input type="checkbox" v-model="hidePreset" :value="player.persona_id" /></b-td>
						<b-td class="text-center">
							{{ index + 1}}
						</b-td>
						<b-td class="text-center">
							<i class="fa fa-home" v-if="player.in_convitto" title="In convitto"></i>	
						</b-td>
						<b-td>
							{{ player.cognome }}
						</b-td>	
						<b-td>
							{{ player.nome }}
						</b-td>	
						<b-td class="text-center" :style="getStyle(_.round(player.percentuale_giocata, 1))">
							{{ _.round(player.percentuale_giocata, 1) }}
						</b-td>	
						<b-td class="text-center">
							{{ player.min_totali }}
						</b-td>	
						<b-td class="text-center">
							{{ player.starting }}
						</b-td>	
						<b-td class="text-center">
							{{ player.partite }}
						</b-td>	
						<b-td class="text-center">
							{{ player.disponibilita }}
						</b-td>	
						<b-td class="text-center">
							{{ player.convocazioni }}
						</b-td>	
						<b-td class="text-center">
							{{ player.altra_squadra }}
						</b-td>	
						<b-td class="text-center">
							{{ player.infortunato }}
						</b-td>
						<b-td class="text-center">
							{{ player.squalificato }}
						</b-td>			
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<b-row class="mt-1" align-v="center" v-if="!isWidget || isFullscreen">
				<b-col cols="5">
					<b-badge v-for="soglia in soglie" :style="soglia.style" class="mr-1">
						{{ soglia.min }}% - {{ soglia.max }}%
					</b-badge>
				</b-col>
				<b-col class="text-right">
					<b-form-checkbox size="sm" v-model="ux.hideDisabled">
						Gestisci Giocatori Nascosti {{ ux.hideDisabled ? '(' + hidePreset.length + ')' : ''}}
					</b-form-checkbox>
				</b-col>
				<b-col>
					<b-button-group>
						<b-button v-if="ux.hideDisabled" variant="outline-success" size="sm" @click.prevent="savePersone(hidePreset)">Aggiorna</b-button>
						<b-button v-if="ux.hideDisabled" variant="outline-warning" size="sm" @click.prevent="svuotaPersone()">Svuota</b-button>
					</b-button-group>
				</b-col>
				<b-col cols="1" class="text-right">
					<b-button size="sm" variant="primary" title="Download report" class="mr-2" @click="download"><i class="fa fa-download" aria-hidden="true"></i></b-button>
				</b-col>
			</b-row>

			<div v-if="players.length > 0" class="mt-2">
				<hr />
				<b-form-group label="Seleziona Giocatori">
					<b-form-checkbox-group v-model="player_ids">
					<b-form-checkbox size="sm" v-for="player in items" :value="player.persona_id">{{ player.cognome }} {{ player.nome }}</b-form-checkbox>
					</b-form-checkbox-group>
				</b-form-group>
				<MinutaggioPartiteGiocatore v-for="player_id in player_ids" :player_id="player_id" :filters="filters" class="mb-1" :max_convocazioni="max_convocazioni" :categoria_id="categoriaId"></MinutaggioPartiteGiocatore>
			</div>
		</b-card>
	</div>
</template>

<script>

import { mapState } from 'vuex';
import pageBase from '@/mixins/pageBase.js';
var Auth = require('@/auth.js');
export default {
	mixins: [ pageBase ],
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function() {
		return {
			loading: false,
			items: [],
			columns: [],
			filters: {
				from: null,
				to: null,
				only_convitto: this.configWidget && this.configWidget.only_convitto ? this.configWidget.only_convitto : false,
				order_by: this.configWidget && this.configWidget.order_by ? this.configWidget.order_by : [ 'percentuale_giocata' ],
				order_dir: this.configWidget && this.configWidget.order_dir ? this.configWidget.order_dir : [ 1 ],
				match_types: this.configWidget && this.configWidget.match_types ? this.configWidget.match_types : [],
				stagione_id: null,
			},
			soglie: [],
			match_types: ['Campionato', 'Coppa', 'Amichevole', 'Torneo', 'Nazionale'],
			ux: {
				hideDisabled: false,
			},
			hidePreset: [],
			random: Math.random(),
			player_ids: [],
			showGraphPlayers: false,
		}
	},
	components: {
		'MinutaggioPartiteGiocatore': require('@/components/layout/reports/MinutaggioPartiteGiocatore.vue').default,
	},
	created: function() {
		if(this.isWidget) {
			if(this.configWidget && this.configWidget.hidePlayers) {
				this.hidePreset = this.configWidget.hidePlayers;
			} else {
				this.hidePreset = [];
			}
		} else if(this.auth.presets().minutaggioPartiteHide != undefined) {
			this.hidePreset = _.cloneDeep(this.auth.presets().minutaggioPartiteHide)
		} else {
			this.hidePreset = [];
		}
	},
	mounted() {
		this.filters.stagione_id = this.seasonId;
		var season = this.user.seasons.find(item => item.id == this.seasonId);
		if(season) {
			this.filters.from = season.start_at.split(" ")[0];
			var now = this.moment().format('YYYY-MM-DD');
			this.filters.to = season.end_at.split(" ")[0] > now ? now : season.end_at.split(" ")[0];
		}
		this.fetchPlayersInConvitto();
	},
	computed: {
		...mapState(['categoriaId', 'seasonId', 'rosaTeamId']),
		user: function() {
			return Auth.user;
		},
		players: function() {
			var dati = this.items;

			if(this.ux.hideDisabled == false) {
				dati = dati.filter(item => this.hidePreset.indexOf(item.persona_id) == -1);
			}
			if(this.filters.only_convitto) {
				dati = dati.filter(item => item.in_convitto);
			} 
			if(this.random) {
				for(var i in this.filters.order_by) {
					var order_by = this.filters.order_by[i];
					var order_dir = this.filters.order_dir[i];
					dati = _.orderBy(dati, function(a) {
						var value = a[order_by];
						if(value == '-') {
							value = 0;
						}
						if(order_by == 'cognome' || order_by == 'nome') {
							value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
						}
						return value;
					}.bind(this), [ order_dir == -1 ? 'asc' : 'desc' ]);
				}
			}
			return dati;
		},
		max_convocazioni: function () {
			return _.maxBy(this.items, 'convocazioni').convocazioni;		
		}
	},
	watch: {
		rosaTeamId() {
			this.fetchPlayersInConvitto();
		},
		seasonId() {
			var season = this.user.seasons.find(item => item.id == this.seasonId);
			if(season) {
				this.filters.stagione_id = season.id;
				this.filters.from = season.start_at.split(" ")[0];
				var now = this.moment().format('YYYY-MM-DD');
				this.filters.to = season.end_at.split(" ")[0] > now ? now : season.end_at.split(" ")[0];
			}
			this.fetchPlayersInConvitto();
		},
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},
		'filters.only_convitto': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'only_convitto', this.filters.only_convitto);
			}
		},
		'filters.match_types': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'match_types', this.filters.match_types);
			}
		},
		'filters.order_by': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'order_by', this.filters.order_by);
			}
		},
		'filters.order_dir': function() {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'order_dir', this.filters.order_dir);
			}
		},
	},
	methods: {
		refreshData: function() {
			this.fetchPlayersInConvitto();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		fetchPlayersInConvitto: function() {
			this.player_ids = [];
			this.items = [];
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/atls/dashboard/convitto/' + this.categoriaId, {params: this.filters}).then(function(data) {
				this.items = data.data.players;
				this.soglie = data.data.soglie;
				this.loading = false;
			});
		},
		download: function() {
			var url = [this.$store.state.apiEndPoint + '/report/download/minutaggio/partite/' + this.user.id + '/' + this.user.api_token];
			url.push('?categoria_id=' + this.categoriaId);
			url.push('&stagione_id=' + this.seasonId);
			if(this.filters.from) {
				url.push('&from=' + this.filters.from);
			}
			if(this.filters.to) {
				url.push('&to=' + this.filters.to);
			}
			url.push('&order_by=' + this.filters.order_by);
			url.push('&order_dir=' + this.filters.order_dir);
			url.push('&only_convitto=' + this.filters.only_convitto);
			if(this.filters.match_types.length > 0) {
				url.push('&match_types=' + this.filters.match_types);
			}
			if(this.hidePreset.length > 0) {
				url.push('&hide_players=' + this.hidePreset);
			}
			window.open(url.join(''));
		},
		getStyle: function(perc) {
			var style = '';
			var findSoglia = this.soglie.find(item => item.min <= perc && item.max >= perc);
			if(findSoglia) {
				style = findSoglia.style;
			}
			return style;
		},
		setOrdering: function(key) {
			var findIndex = this.filters.order_by.findIndex(item => item == key);
			if(findIndex > -1) {
				var dir = this.filters.order_dir[findIndex];
				if(dir == -1) {
					this.filters.order_dir[findIndex] = 1;
				} else {
					this.filters.order_by.splice(findIndex, 1);
					this.filters.order_dir.splice(findIndex, 1);
				}
			} else {
				this.filters.order_by.unshift(key);
				this.filters.order_dir.unshift(-1);
			}
			this.random = Math.random();
		},
		getIndex(key) {
			return this.filters.order_by.findIndex(item => item == key);
		},
		svuotaPersone: function() {
			this.hidePreset = [];
			this.savePersone(this.hidePreset);
		},
		savePersone: function(persone) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'hidePlayers', persone);
				this.ux.hideDisabled = false;
			} else {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'minutaggioPartiteHide', persone: persone }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.ux.hideDisabled = false;
				});
			}
		},
	}
};
</script>  