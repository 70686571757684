<template>
	<div>

		<!--
		<pre>{{sessione}}</pre>
		<pre>{{sceltaAttivita}}</pre>
		-->


		<form @submit.stop.prevent="handleSubmit">
			<SessioneIntestazione :noPersone="true" :playersPreset="playersPreset" :sessione="sessione" :tipoSessione="sessioneCorrente" :persone="playerOptions" @refresh="$emit('refresh')"></SessioneIntestazione>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th>Orario</b-th>
							<b-th>Attività</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="fase, k in sessione.fasi" v-if="fase.entita == 'Fase_Attivita'">
							<b-td><b-input type="time" v-model="fase.entity.orario" /></b-td>
							<b-td>
								<div class="d-flex align-items-center">

									<b-form-input class="flex-grow-1" type="text" v-model="fase.entity.attivita" :list="'list-' + k"></b-form-input>

									<b-form-datalist :id="'list-' + k">
										<option v-for="attivita in sceltaAttivita">{{ attivita }}</option>
									</b-form-datalist>

									<button class="px-2 small" style="border: none; cursor: pointer;" @click.prevent="sposta(k,0)" :disabled="k == 0">
										<i class="fa fa-arrow-up" aria-hidden="true"></i>
									</button>
									<button class="px-2 small" style="border: none; cursor: pointer;" @click.prevent="sposta(k,1)" :disabled="k == sessione.fasi.length-1">
										<i class="fa fa-arrow-down" aria-hidden="true"></i>
									</button>
									<button class="px-2 small" style="border: none; cursor: pointer; color: #ba2e3b" @click.prevent="removeFase(k)">
										<i class="fa fa-trash" aria-hidden="true"></i>
									</button>
								</div>
							</b-td>
						</b-tr>
						<b-tr class="mt-2">
							<b-td colspan="2"><b-button block variant="primary" size="sm" @click.prevent="addVoce">Nuova Attività</b-button></b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			<!-- <pre>{{ fasiAttivita }}</pre> -->

			<Fase 
				ref="faseRef" 
				class="mb-2"
				@sposta="sposta(k, $event)" 
				@remove="removeFase(k)" 
				@duplica="duplicaFase(k)" 
				:sessioneCorrente="sessioneCorrente" 
				:index="k+1" 
				:fase="fase" 
				:isManagerSessione="isManagerSessione" 
				:estensioniFase="estensioniFase"
				:noPersone="true"
				:noRearrange="true"
				:disabled="isPhaseDisabled(fase)"
				v-for="fase, k in sessione.fasi" :sessione="sessione" v-if="fase.entita != 'Fase_Attivita'"
			>
				<div :is="fase.entita" :sessioneCorrente="sessioneCorrente" :sessione="sessione" :fase="fase" :glossario="glossario" :isManagerSessione="isManagerSessione" ></div>
			
			</Fase>

			<b-row>
				<b-col>
					<b-button-group class="mt-3" v-if="isManagerSessione">
						<b-button v-if="canAddPhase" class="mr-0" variant="outline-primary" @click="showAddFasi"><i class="fa fa-plus fa-fw"></i> Aggiungi Fase</b-button>
						<b-button ref="submit" type="submit" variant="primary" v-if="auth.isLastSeason(seasonId) || auth.isAdmin()" class="text-uppercase ml-0"><strong>Salva</strong></b-button>
					</b-button-group>
				</b-col>
				<b-col class="text-right">
					<b-button-group class="mt-3" v-if="(isManagerSessione && auth.isLastSeason(seasonId)) || auth.isAdmin()">
						<b-button class="mr-0" variant="outline-primary" @click="showDuplicaSessione">Duplica</b-button>
						<b-button variant="outline-primary" @click="deleteSessione">Elimina</b-button>
					</b-button-group>
				</b-col>
			</b-row>

		</form>

	</div>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';
import sessionFasiMixin from '@/mixins/sessioneFasi.js';

import axios from 'axios';
import moment from 'moment'

import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	props: [ 'sessione', 'playersPreset', 'playerOptions', 'sessioneTipi', 'seasonId', 'fasiGeneriche', 'estensioniFase', 'team' ],
	name: 'Sessione',
	mixins: [
		sessionMixin,
		sessionFasiMixin,
	],
	data: function(){
		return{
			sceltaAttivita: null,
			ux: {
				newFase: false,
				showMatrice: false,
				showVideo: false
			}
		}
	},
	created: function () {
		this.fetchListaAttivita();
	},
	computed: {
		fasiAttivita: function() {
			return _.filter(this.sessione.fasi, { entita: 'Fase_Attivita' });
		}
	},
	methods: {
		addVoce: function() {

			var hasEmpty = _.filter(this.sessione.fasi, function(a) { return a.entita == 'Fase_Attivita' && a.entity.attivita.length == 0 });

			if(hasEmpty.length > 0) return false;

			this.sessione.fasi.push({
				nome: '',
				entita: 'Fase_Attivita',
				setup: null,
				config: null,
				entity: {
					orario: '',
					attivita: ''
				}
			});
		},
		fetchListaAttivita: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessioni/attivita/lista-fasi').then(function(data) {
				this.sceltaAttivita = data.data;
			});
			
		},
		handleSubmit: function () {
			this.$emit('submit');
		},
	},
	watch: {}
}
</script>
