<template>
  <section>
    <headapp></headapp>
    <div class="container-fluid">
      <b-card>
        <b-row align-v="center">
          <b-col cols="2"><i class="fa fa-play fa-2 mr-2" aria-hidden="true" title="Numero di videotags"></i> {{videotags_count}} </b-col>
          <b-col cols="2"><i class="fa fa-futbol-o fa-2 mr-2" aria-hidden="true" title="Numero di eventi"></i> {{partite}}</b-col>
          <b-col cols="2"><i class="fa fa-clock-o fa-2 mr-2" aria-hidden="true" title="Tempo totale videotags"></i> {{videotags_time}}</b-col>
          <!--b-col cols="3"></b-col-->
          <!--b-col cols="1"></b-col-->
          <b-col cols="3">
            <b-form-group label-cols="2" content-cols="10" label="Utenti" horizontal v-if="auth.isUserEnable('videotag_utenti')" class="mb-0">
              <b-form-select v-model="utente">
                <option value="0">-- Nessuno --</option>
                <option v-for="item in utenti" :value="item.id">{{item.nomeCompleto}}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-btn v-if="auth.isUserEnable('videotag_utenti') && auth.isUserEnable('video_down_clip') && utente > 0" variant="primary" size="sm" @click="downloadsUtente">Archivio downloads</b-btn>
          </b-col>
          <b-col cols="1">
          </b-col>
          <b-col cols="1">
            <b-btn v-if="auth.isUserEnable('video_down_clip')" variant="success" size="sm" @click="downloadsPersonali">I miei downloads</b-btn>
          </b-col>
        </b-row>
      </b-card>
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <span>Videotag</span>
          </div>
          <b-container fluid>
            <b-row>
              <b-col cols="7">
                <p v-if="(utente == 0 || utente == user.id) && rosa == 0">Filtra i videotag del tuo archivio personale</p>
                <p v-if="utente != 0">Filtra i videotag dell'archivio personale di <strong class="color-rosso">{{ labelUtente }}</strong></p>
                <p v-if="utente == 0 && rosa != 0">Filtra i videotag della squadra: <strong class="color-rosso">{{ user.rosaTeams.find((item) => item.id == rosa).categoria.name }}</strong></p>
                <b-form @submit.stop.prevent="filtraArchivio()">
                  <b-form-row>
                    <b-col
                    v-for="(filter, filterName, i) in filters"
                    :key="`filter-${i}`"
                    cols="2">
                    <b-form-group>
                      <b-form-select v-model="selectedFields[filterName]" style="font-size:0.9rem;">
                        <template slot="first">
                          <option value="">{{ filter.name }}</option>
                        </template>
                        <template v-if="valueIsArray(filter.values)">
                          <option v-for="(value, i) in filter.values" :key="`value-${i}`" :value="value.id">
                            {{ value.name }}
                          </option>
                        </template>
                        <template v-else>
                          <optgroup v-for="(values, labelName, i) in filter.values" :key="`group-${i}`" :label="labelName">
                            <option v-for="(value, j) in values" :key="`value-${j}`" :value="value.id">
                              {{ value.name }}
                            </option>
                          </optgroup>
                        </template>

                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group>
                      <b-form-input v-model="selectedFields.keyword" type="text" placeholder="Parola chiave" style="font-size:0.9rem;"/>
                    </b-form-group>
                  </b-col>

                  <b-col cols="1" class="col-submit">
                    <b-form-group>
                      <b-button type="submit" variant="success" style="font-size:0.9rem;" title="Cerca"><i class="fa fa-search" aria-hidden="true"></i>
                      </b-button>
                    </b-form-group>
                  </b-col>
                  <b-col cols="1" class="col-submit">
                   <b-form-group>
                    <b-button variant="primary" @click.prevent="reset()" style="font-size:0.9rem;" title="Cancella"><i class="fa fa-ban" aria-hidden="true"></i>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>

          </b-col>
          <b-col class="pb-3" align-self="end">
            <b-row>
              <b-col align-self="end">Elenco video</b-col>
            </b-row>
            <b-row v-if="auth.isUserEnable('videotag_rosa') && user.rosaTeams.length > 0" align-v="end" class="mt-1">
              <b-col>
                <b-form-select v-model="rosa" size="sm" class="mb-1">
                  <option value="0">-- rosa --</option>
                  <option v-for="team in user.rosaTeams" :value="team.id" :key="team.id">
                    {{ team.categoria.name }} 
                  </option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select v-model="utente" size="sm" class="mb-1">
                  <option value="0">-- utente --</option>
                  <option v-for="item in utenti_video" :value="item.id" :key="item.id">
                    {{ item.nomeCompleto }} 
                  </option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="pb-3" align-self="end">
            <b-row>
              <b-col align-self="end">Gestione cartelle</b-col>
            </b-row>
            <b-row v-if="auth.isUserEnable('videotag_rosa') && user.rosaTeams.length > 0" align-v="end" class="mt-1">
              <b-col><b-form-select v-model="rosa_cartelle" size="sm" class="mb-1">
                <option value="0">-- rosa --</option>
                <option v-for="team in user.rosaTeams" :value="team.id" :key="team.id">
                  {{ team.categoria.name }} 
                </option>
              </b-form-select></b-col>
              <b-col><b-form-select v-model="utente_cartelle" size="sm" class="mb-1">
                <option value="0">-- utente --</option>
                <option v-for="item in utenti_cartelle" :value="item.id" :key="item.id">
                  {{ item.nomeCompleto }} 
                </option>
              </b-form-select></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="7">
            <video
            id="video-wrapper"
            class="video-js vjs-default-skin"
            controls
            :data-setup="setup">
          </video>
        </b-col>
        <v-infinite-scroll :loading="loadingArchivio" @bottom="nextPage" :offset='20' style="max-height: 60vh; overflow-y: scroll;" class="col">
          <b-card no-body v-for="video in videos" class="mb-1 p-2" :key="'video_' + video.id" :style="'background:' + video.videotags[0].privato ? 'yellow' : 'red'" :bg-variant="video.videotags[0].privato ? 'info' : 'default'">
            <b-row style="cursor:pointer">
              <b-col cols="11" v-b-toggle="'video_' + video.id">
                <div v-if="video.tipo == 'wyscout' && video.id_partita && !video.id_match">
                  <div>{{ partitaLabel(video.partita.squadre) }}</div>
                  <small class="text-muted">{{ video.partita.competizione.nome }} <br/> {{ video.partita.data | formatDateHour }}</small>
                </div>
                <div v-if="video.match">
                  <div>{{ matchLabel(video.match) }}</div>
                  <small class="text-muted">{{ matchTypeLabel(video.match) }} <br/> {{ video.match.data | formatDateHour }}</small>
                </div>
                <div v-if="video.sessioni.length > 0">
                  <div> {{ video.sessioni[0].nome }} </div>
                  <small class="text-muted">{{ video.sessioni[0].sessione_tipo.label }} <br/> {{ video.sessioni[0].inizio | formatDateHour }}</small>
                </div>
                <div v-else-if="video.fasi.length > 0">
                  <div> {{ video.fasi[0].nome }} </div>
                  <small class="text-muted"> {{ video.fasi[0].sessione.sessione_tipo.label }} -> {{ video.fasi[0].entita }} <br/> {{ video.fasi[0].sessione.inizio | formatDateHour }}</small>
                </div>
              </b-col>
              <b-col v-b-toggle="'video_' + video.id">
                <i class="when-closed fa fa-caret-right"></i>
                <i class="when-opened fa fa-caret-down"></i>
              </b-col>
            </b-row>
            <b-collapse :id="'video_' + video.id">
              <draggable :list="video.videotags" :options="{group:{ name:'videotag', pull:'clone', put:false}, sort:false, disabled:(utente > 0 && utente != user.id && !auth.isUserEnable('videotag_rosa'))}">  
                <div v-for="(videotag, index) in video.videotags" :key="'tag_' + videotag.id">
                  <small><strong>{{ soggettoLabel(videotag) }}</strong></small><br/>
                  <small>{{ azioniLabel(videotag.azioni) }}</small>
                  <b-card no-body :class="['mb-1 p-1 default', videotag.peso, tags_cartelle_ids.indexOf(videotag.id) > -1 ? 'evidenziato' : '']">
                    <b-row>
                      <b-col cols="1" @click="playClip(videotag.id)">
                        <i class="fa fa-play" aria-hidden="true"></i>
                      </b-col>
                      <b-col cols="6" @click="playClip(videotag.id)">
                        <small>{{ videotag.inizio }} - {{ videotag.fine }}</small>
                      </b-col>
                      <b-col>
                        <i title="preferiti" class="fa fa-heart" aria-hidden="true" v-if="videotag.preferiti"></i>
                      </b-col>
                      <b-col>
                        <i v-b-tooltip.hover :title="videotag.nota" class="fa fa-info-circle" aria-hidden="true" v-if="videotag.nota"></i>
                      </b-col>
                      <b-col @click="downloadClip(videotag.id)" v-if="auth.isUserEnable('video_down_clip')">
                        <i class="fa fa-download" aria-hidden="true" title="download clip"></i>
                      </b-col>
                      <b-col @click="deleteTag(videotag.id, video.videotags, index)" v-if="utente == 0 || utente == user.id || (utente > 0 && auth.isAdmin())">
                        <i class="fa fa-trash" aria-hidden="true" title="elimina videotag"></i>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                       <small> {{ videotag.utente.nomeCompleto}}</small>
                     </b-col>
                   </b-row>
                 </b-card>
               </div>
             </draggable>
           </b-collapse>
         </b-card>
         <div v-if="loadingArchivio" align="center" style="width:100%">
          <img src="/assets/varie/loading.gif" style="width: 120px;" />
        </div>
      </v-infinite-scroll>
      <!--/b-col-->
      <b-col>
        <newcartella :utente_cartelle="utente_cartelle" @creaCartella="creaCartella"></newcartella>
        <b-row>
          <v-infinite-scroll :loading="loadingCartelle" @bottom="nextPageCartelle" :offset='20' style="max-height: 50vh; overflow-y: scroll;" class="col">

            <draggable :list="cartelle" @change="updateCartelle" :options="{disabled:(utente_cartelle > 0 && utente_cartelle != user.id && !auth.isAdmin() && !auth.isPerfezionamento())}">    
              <b-card no-body v-for="(cartella, index) in cartelle" :class="['mb-1 p-2', cartella.riassunto ? 'riassunto-' + cartella.riassunto : '' ]" :key="'cartella_' + cartella.id">
                <b-row>
                  <b-col cols="8" v-b-toggle="'cartella_' + cartella.id" style="cursor:move">
                    <div>{{ cartella.nome }}</div>
                    <div><small>{{ durataCartella(cartella) }}</small></div>
                    <div><small>{{ cartella.utente.nomeCompleto }}</small></div>
                  </b-col>
                  <b-col v-b-toggle="'cartella_' + cartella.id">
                    <i class="when-closed fa fa-caret-right"></i>
                    <i class="when-opened fa fa-caret-down"></i>
                  </b-col>
                  <b-col cols="1" @click="downloadCartella(cartella.id)" v-if="auth.isUserEnable('video_down_clip') && cartella.videotags.length > 0" style="cursor:pointer">
                    <i class="fa fa-download" aria-hidden="true" title="download cartella"></i>
                  </b-col>
                  <b-col cols="1" @click="selezionaCartella(cartella)" v-if="utente_cartelle == 0 || utente_cartelle == user.id || (utente_cartelle > 0 && auth.isAdmin())" style="cursor:pointer">
                    <i class="fa fa-pencil-square-o" aria-hidden="true" title="modifica cartella"></i>
                  </b-col>
                  <b-col cols="1" @click="deleteCartella(cartella.id, index)" v-if="utente_cartelle == 0 || utente_cartelle == user.id || (utente_cartelle > 0 && auth.isAdmin())" style="cursor:pointer">
                    <i class="fa fa-trash" aria-hidden="true" title="elimina cartella"></i>
                  </b-col>
                </b-row>
                <b-row v-if="editCartella.id == cartella.id">
                  <b-col>
                    <b-row>
                      <b-col> <small><span class="color-rosso">Modifica cartella</span></small></b-col>
                      <b-col class="text-right">
                        <b-form-select v-model="editCartella.riassunto" size="sm" class="mb-1">
                          <option value="0">-- tipo --</option>
                          <option value="1">riassunto</option>
                          <option value="2">highlights</option>
                          <option value="3">sintesi</option>
                          <option value="4">goals</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-input-group size="sm" class="mb-1">
                      <b-form-input id="editCartella" v-model="editCartella.nome"></b-form-input>
                      <b-input-group-append>
                        <b-btn variant="secondary" @click="deselezionaCartella" title="Annulla"><i class="fa fa-ban" aria-hidden="true"></i></b-btn>
                        <b-btn variant="primary" @click="modificaCartella" title="Modifica"><i class="fa fa-pencil" aria-hidden="true"></i></b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-collapse :id="'cartella_' + cartella.id">
                  <draggable :list="cartella.videotags" :options="{group:{ name:'videotag'}, disabled:(utente_cartelle > 0 && utente_cartelle != user.id && !auth.isAdmin() && !auth.isPerfezionamento())}" style="min-height:50px;" @change="changeTagsCartella($event, cartella)"> 
                    <div v-for="(videotag, index) in cartella.videotags" :key="'cvt_' + videotag.id">
                      <b-card no-body :class="['mb-1 p-2 default', videotag.peso ]">
                        <b-row>
                          <b-col cols="1" @click="playClip(videotag.id)">
                            <i class="fa fa-play" aria-hidden="true"></i>
                          </b-col>
                          <b-col cols="7" @click="playClip(videotag.id)">
                            <small>{{ videotag.inizio }} - {{ videotag.fine }}</small>
                          </b-col>
                          <b-col>
                            <i title="preferiti" class="fa fa-heart" aria-hidden="true" v-if="videotag.preferiti"></i>
                          </b-col>
                          <b-col>
                            <i v-b-tooltip.hover :title="videotag.nota" class="fa fa-info-circle" aria-hidden="true" v-if="videotag.nota"></i>
                          </b-col>
                          <b-col @click="deleteTagCartella(videotag.pivot.id, videotag.id, cartella.id, index)" v-if="utente_cartelle == 0 || utente_cartelle == user.id || (utente_cartelle > 0 && auth.isAdmin())">
                            <i class="fa fa-trash" aria-hidden="true" title="elimina videotag da cartella"></i>
                          </b-col>
                        </b-row>
                      </b-card>
                    </div>
                  </draggable>
                </b-collapse>
              </b-card>
            </draggable>

            <b-col v-if="loadingCartelle" class="text-center">
              <img src="/assets/varie/loading.gif" style="width: 120px;" />
            </b-col>
          </v-infinite-scroll>
        </b-row>

      </b-col>
    </b-row>
    <b-row class="mt-2 text-right">
      <b-col>
        <b-badge class="mr-1" style="background:red;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Riassunto</b-badge>
        <b-badge class="mr-1" style="background:green;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Highlights</b-badge>
        <b-badge class="mr-1" style="background:blue;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Sintesi</b-badge>
        <b-badge class="mr-1" style="background:orange;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;">Goals</b-badge>
      </b-col>
    </b-row>
  </b-container>
</div>
</div>
</div>
<b-modal size="lg" ref="downloadModal" :title="myDownload ? 'I miei downloads' : 'Archivio downloads di ' + labelUtente" ok-only ok-title="Chiudi"> 
  <b-table :items="downloads" :fields="[{key: 'creato', formatter: this.formatDate}, 'file', 'azioni']">
    <template #cell(file)="row">
      {{row.item.path ? row.item.path : 'File non ancora disponibile!'}}
    </template>
    <template #cell(azioni)="row">
      <b-button size="sm" variant="primary" title="download video" @click="downloadVideo(row.item.id)" v-if="row.item.path">
        <i class="fa fa-download" aria-hidden="true"></i>
      </b-button>
      <b-button size="sm" title="elimina video download" @click="eliminaVideo(row.item.id)" v-if="utente == 0 || utente == user.id || (utente > 0 && auth.isAdmin())">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </b-button>
    </template>
  </b-table>
</b-modal>
<footerapp></footerapp>
</section>
</template>

<script>

var Auth = require('../../../auth.js');
import videojs from 'video.js';
import 'videojs-errors';
import 'videojs-youtube';
import draggable from 'vuedraggable';
import moment from 'moment';
import _ from 'lodash';
import InfiniteScroll from 'v-infinite-scroll'

export default {
  data() {
    return {
      loadingArchivio: false,
      loadingCartelle: false,
      page: 1,
      total: 0,
      count_tag: 0,
      tot_time_tag: 0,
      videos: [],
      cartelle: [],
      page_cartelle: 1,
      total_cartelle: 0,
      selectedFields: {
        playerOrTeam: '',
        action: '',
        player: '',
        team: '',
        coach: '',
        positiveOrNegative: '',
        defensiveOrOffensive: '',
        fieldSection: '',
        keyword: '',
        anno: '',
        mese: '',
      },
      newCartella: {
        nome: '',
        riassunto: 0,
      },
      editCartella: {
        id: 0,
        nome: '',
        riassunto: 0,
      },
      tags_cartelle_ids: [],
      giocatori: [],
      squadre: [],
      allenatori: [],
      skills: [],
      values: [],
      mesi: [],
      anni: [],
      utenti: [],
      utente: 0,
      rosa: 0,
      utente_cartelle: 0,
      rosa_cartelle: 0,
      utenti_video: [],
      utenti_cartelle: [],
      mesiLabel: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      downloads: [],
      tipoPartita: ['Campionato', 'Coppa', 'Amichevole', 'Torneo'],
      errors_player: {
        4: {
          headline: 'Video non disponibile',
          type: '',
          message: 'La clip non è stata ancora generata.<br/>Riprova più tardi.',
        }
      },
      myDownload: true,
    }
  },

  components: {
    headapp:            require('../../layout/headapp.vue').default,
    menusub:            require('../../layout/menusub.vue').default,
    footerapp:          require('../../layout/footerapp.vue').default,
    newcartella:          require('./newcartella.vue').default,
    draggable,
    'v-infinite-scroll': InfiniteScroll
  },
  computed: {
    auth: function() {
      return Auth;
    },
    user: function() {
      return Auth.user;
    },
    filters() {
      return {
        playerOrTeam: {
          name: 'Tipo Tag',
          values: [
          { id: 0, name: 'Squadra' },
          { id: 1, name: 'Giocatore' },
          { id: 2, name: 'Allenatore' },
          ],
        },
        action: {
          name: 'Azione',
          values: this.actionValues,
        },
        player: {
          name: 'Giocatore',
          values: this.playerValues,
        },
        team: {
          name: 'Squadra',
          values: this.teamValues,
        },
        coach: {
          name: 'Allenatore',
          values: this.coachValues,
        },
        positiveOrNegative: {
          name: 'Positivo o Negativo',
          values: [
          { id: 1, name: 'Positivo' },
          { id: 0, name: 'Negativo' },
          ],
        },
        defensiveOrOffensive: {
          name: 'Difensivo o Offensivo',
          values: [
          { id: 0, name: 'Difensivo' },
          { id: 1, name: 'Offensivo' },
          ],
        },
        fieldSection: {
          name: 'Sezione campo',
          values: [
          { id: 1, name: 'Zona 1' },
          { id: 2, name: 'Zona 2' },
          { id: 3, name: 'Zona 3' },
          { id: 4, name: 'Zona 4' },
          { id: 5, name: 'Zona 5' },
          { id: 6, name: 'Zona 6' },
          { id: 7, name: 'Zona 7' },
          { id: 8, name: 'Zona 8' },
          { id: 9, name: 'Zona 9' },
          ],
        },
        anno: {
          name: 'Anno',
          values: this.anniValues,
        },
        mese: {
          name: 'Mese',
          values: this.mesiValues,
        }
      };
    },
    actionValues() {
      var only_root_skill = true;
      for(var i in Auth.user.rosaTeams) {
        var team = Auth.user.rosaTeams[i];
        if(!team.categoria.only_root_skill) {
          only_root_skill = false;
          break;
        }
      }
      var jsonObject = {};
      for (var k in this.skills) {
        var item = this.skills[k];          
        if(item.fields) {
          for (var j in item.fields) {
            var item2 = item.fields[j];
            if(item2.fields) {

              var obj = {id: item2.id, name: item2.it};
              var array = [];
              if(typeof(jsonObject[item.it]) !== 'undefined') {
               array = jsonObject[item.it];
             } 
             array.push(obj);
             jsonObject = this.nest(jsonObject, [item.it], array);

             if(!only_root_skill) {
               for (var y in item2.fields) {
                var item3 = item2.fields[y];
                if(item3.fields) {
                  for (var x in item3.fields) {
                    var item4 = item3.fields[x];
                    var obj = {id: item4.id, name: item2.it + " -> " + item3.it + " -> " + item4.it};
                    var array = [];
                    if(typeof(jsonObject[item.it]) !== 'undefined') {
                     array = jsonObject[item.it];
                   } 
                   array.push(obj);
                   jsonObject = this.nest(jsonObject, [item.it], array);
                 }

               } else {
                var obj = {id: item3.id, name: item2.it + " -> " + item3.it };
                var array = [];
                if(typeof(jsonObject[item.it]) !== 'undefined') {
                  array = jsonObject[item.it];
                } 
                array.push(obj);
                jsonObject = this.nest(jsonObject, [item.it], array);
              }    
            }
          }

        } else {
          var obj = {id: item2.id, name: item2.it};
          var array = [];
          if(typeof(jsonObject[item.it]) !== 'undefined') {
            array = jsonObject[item.it];
          } 
          array.push(obj); 
          jsonObject = this.nest(jsonObject, [item.it], array);      
        }    
      }
    }
  }
  return jsonObject;
},
playerValues() {
  var values = [];
  for(var i in this.giocatori) {
    var giocatore = {
      id: this.giocatori[i].id,
      name: this.giocatori[i].cognome + " " + this.giocatori[i].nome,
    };
    values.push(giocatore);
  }
  return values;
},
teamValues() {
  var values = [];
  for(var i in this.squadre) {
    var squadra = {
      id: this.squadre[i].id,
      name: this.squadre[i].name,
    };
    values.push(squadra);
  }
  return values;
},
coachValues() {
  var values = [];
  for(var i in this.allenatori) {
    var allenatore = {
      id: this.allenatori[i].id,
      name: this.allenatori[i].cognome + " " + this.allenatori[i].nome,
    };
    values.push(allenatore);
  }
  return values;
},
anniValues() {
 var values = [];
 for(var i in this.anni) {
  var anno = {
    id: this.anni[i].anno,
    name: this.anni[i].anno,
  };
  values.push(anno);
}
return values;
},
mesiValues() {
  var values = [];
  for(var i in this.mesi) {
    var mese = {
      id: this.mesi[i].mese,
      name: this.mesiLabel[this.mesi[i].mese - 1],
    };
    values.push(mese);
  }
  return values;
},
setup() {
  return JSON.stringify({
    techOrder: ['html5'],
    width: '100%',
    height: '100%',
    aspectRatio: '16:9',
    youtube: {
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  });
},
partite() {
 return this.total;
},
videotags_count() {
  return this.count_tag;
},
videotags_time() {
  return moment.utc(this.tot_time_tag * 1000).format("HH:mm:ss");
},
labelUtente() {
  for(var i in this.utenti) {
    if(this.utenti[i].id == this.utente) {
      return this.utenti[i].nomeCompleto; 
    }
  }
},
},
created: function () {
  this.loadArchivio();
  this.loadCartelle();
  this.loadFiltri();
  if(Auth.isUserEnable('videotag_utenti')) {
    this.loadUtenti();
  }
},
methods: {
  loadArchivio() {
    this.loadingArchivio = true;
    this.page = 1;
    this.videos = [];
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/archivio/' + this.utente + '/' + this.rosa).then((response) => {
      if (response.ok)
      {
        this.videos = response.data.videos.data;
        this.total = response.data.videos.total;
        this.count_tag = response.data.videotags_count;
        this.tot_time_tag = response.data.videotags_time;
        this.loadingArchivio = false;
      }
    }, (response) => {
    });
  },
  nextPage() {
    if(this.videos.length < this.total) {
      this.page++;
      this.loadingArchivio = true;
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/archivio/' + this.utente + '/' + this.rosa + '?page=' + this.page, {params: this.selectedFields}).then((response) => {
        if (response.ok)
        {
          this.videos = this.videos.concat(response.data.videos.data);
          this.loadingArchivio = false;
        }
      }, (response) => {
      });
    }
  },
  loadCartelle() {
    this.loadingCartelle = true;
    this.page_cartelle = 1;
    this.cartelle = [];
    this.tags_cartelle_ids = [];
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/cartelle/' + this.utente_cartelle + '/' + this.rosa_cartelle).then((response) => {
      if (response.ok)
      {
        this.cartelle = response.data.data;
        this.total_cartelle = response.data.total;
        for(var i in this.cartelle) {
          var cartella = this.cartelle[i];
          for(var x in cartella.videotags) {
            var videotag = cartella.videotags[x];
            this.tags_cartelle_ids.push(videotag.id);
          } 
        }
        this.loadingCartelle = false;
      }
    }, (response) => {

    });
  },
  nextPageCartelle() {
    if(this.cartelle.length < this.total_cartelle) {
      this.page_cartelle++;
      this.loadingCartelle = true;
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/cartelle/' + this.utente_cartelle + '/' + this.rosa_cartelle +'?page=' + this.page_cartelle).then((response) => {
        if (response.ok)
        {
          this.cartelle = this.cartelle.concat(response.data.data);
          this.loadingCartelle = false;
        }
      }, (response) => {
      });
    }
  },
  loadFiltri() {
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/filtri/archivio/' + this.utente + '/' + this.rosa).then((response) => {
      if (response.ok)
      {
        this.giocatori = response.data.giocatori;
        this.squadre = response.data.squadre;
        this.allenatori = response.data.allenatori;
        this.skills = response.data.skills;
        this.mesi = response.data.mesi;
        this.anni = response.data.anni;

      }
    }, (response) => {

    });
  },
  loadUtenti() {
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/utenti').then((response) => {
      if (response.ok)
      {
        this.utenti = response.data;
      }
    }, (response) => {

    });
  },

  loadUtentiVideo() {
    this.utenti_video = [];
    if(this.rosa != 0) {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/utenti/rosa/' + this.rosa).then((response) => {
        if (response.ok)
        {
          this.utenti_video = response.data;
        }
      }, (response) => {

      });
    } 
  },

  loadUtentiCartelle() {
    this.utenti_cartelle = [];
    if(this.rosa_cartelle != 0) {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/utenti/rosa/' + this.rosa_cartelle).then((response) => {
        if (response.ok)
        {
          this.utenti_cartelle = response.data;
        }
      }, (response) => {

      });
    } 
  },

  valueIsArray(value) {
    return Array.isArray(value);
  },

  nest: function(obj, keys, v) {
    if (keys.length === 1) {
      obj[keys[0]] = v;
    } else {
      var key = keys.shift();
      obj[key] = this.nest(typeof obj[key] === 'undefined' ? {} : obj[key], keys, v);
    }

    return obj;
  },

  filtraArchivio() {
    this.loadingArchivio = true;
    this.page = 1;
    this.videos = [];
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/archivio/' + this.utente + '/' + this.rosa, {params: this.selectedFields}).then((response) => {
      if (response.ok)
      {
        this.videos = response.data.videos.data;
        this.total = response.data.videos.total;
        this.count_tag = response.data.videotags_count;
        this.tot_time_tag = response.data.videotags_time;
        this.loadingArchivio = false;
      }
    }, (response) => {

    });
  },
  reset() {
    this.selectedFields = {
      playerOrTeam: '',
      action: '',
      player: '',
      team: '',
      coach: '',
      positiveOrNegative: '',
      defensiveOrOffensive: '',
      fieldSection: '',
      keyword: '',
      anno: '',
      mese: '',
    };
  },
  partitaLabel(squadre) {
    if(squadre.length > 0) {
      if(squadre[0].pivot.side == 'home') {
        return squadre[0].name + " - " + squadre[1].name;
      } else return squadre[1].name + " - " + squadre[0].name;
    } else {
      return "Home - Away"
    }
  },
  matchLabel(match) {
    return match.home.name + " - " + match.away.name;
  },
  matchTypeLabel(match) {
    var label = match.categoria.name;
    if(match.type > 0) {
      label += " - " + this.tipoPartita[match.type - 1];
    } 
    return label; 
  },
  azioniLabel(azioni) {
    if(azioni.length > 0) {
      var nome = "";
      for(var i in azioni) {
        var azione = azioni[i];
        if(nome != "") {
          nome += " - ";
        }
        nome += azione.nome_azione;
      }
      if(nome == "") {
        return "Azione generica";
      }
      return nome;
    } else {
      return "Azione generica"
    }
  },
  soggettoLabel(videotag) {
    var soggetto = "";
    if(videotag.tipotag == "giocatore") {
      soggetto = videotag.giocatore.cognome + " " + videotag.giocatore.nome;
    } else if(videotag.tipotag == "squadra") {
      soggetto = videotag.squadra.name;
    } else if(videotag.tipotag == "allenatore") {
      soggetto = videotag.allenatore.cognome + " " + videotag.allenatore.nome;
    }
    return soggetto;     
  },
  playClip(id) {
    this.$video.src({type: 'video/mp4', src: this.$store.state.apiEndPoint + '/videotag/clip/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token});
    this.$video.play();
  },
  deleteTag(id, videotags, index) {
    if(confirm('Sei sicuro di voler eliminare il tag?')) {
      this.$http.delete(this.$store.state.apiEndPoint + '/tag/' + id).then((response) => {
        if (response.ok)
        {
          videotags.splice(index, 1);
        }
      }, (response) => {
      });
    }
  },
  creaCartella(newCartella) {
    if(newCartella.nome == "") {
      alert("Devi dare un nome alla cartella!");
      return;
    } else {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/add/cartella/' + this.utente_cartelle, {params: newCartella}).then((response) => {
        if (response.ok)
        {
          this.cartelle.push(response.data);
        }
      }, (response) => {
      });
    }
  },
  deleteCartella(id, index) {
    if(confirm('Sei sicuro di voler eliminare la cartella?')) {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/delete/cartella/' + id).then((response) => {
        if (response.ok)
        {
          var videotags = this.cartelle[index].videotags;
          for(var i in videotags) {
            var videotag = videotags[i];
            var idx = this.tags_cartelle_ids.indexOf(videotag.id);
            if(idx > -1) {
              this.tags_cartelle_ids.splice(idx, 1);
            }
          }
          this.cartelle.splice(index, 1);
        }
      }, (response) => {
      });
    }
  },
  selezionaCartella(cartella) {
    this.editCartella = {
      id: cartella.id,
      nome: cartella.nome,
      riassunto: cartella.riassunto
    };
  },
  deselezionaCartella() {
    this.editCartella = {
      id: 0,
      nome: '',
      riassunto: 0
    };
  },
  modificaCartella(id, index) {
    if(this.editCartella.nome == "") {
      alert("Devi dare un nome alla cartella!");
      return;
    } else {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/edit/cartella/' + this.editCartella.id, {params: this.editCartella}).then((response) => {
        if (response.ok)
        {
          for(var i in this.cartelle) {
            if(this.cartelle[i].id == this.editCartella.id) {
              this.cartelle[i].nome = this.editCartella.nome;
              this.cartelle[i].riassunto = this.editCartella.riassunto;
              break;
            }
          }
          this.editCartella = {
            id: 0,
            nome: '',
            riassunto: 0
          }
        }
      }, (response) => {
      });
    }
  },
  updateCartelle() {
    var ids_cartelle = [];
    for(var i in this.cartelle) {
      ids_cartelle.push(this.cartelle[i].id);
    }
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/update/order/cartelle', {params: ids_cartelle}).then((response) => {
      if (response.ok)
      {
      }
    }, (response) => {
    });
  },

  changeTagsCartella(evt, cartella) {
    if(evt.added) {
      var videotags = [];
      for(var i in cartella.videotags) {
        videotags.push(cartella.videotags[i].id);
      }
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/add/tag/cartella', {params: {'cartella': cartella.id, 'videotags': videotags}}).then((response) => {
        if (response.ok)
        {
          for(var i in this.cartelle) {
            if(this.cartelle[i].id == cartella.id) {
              this.cartelle[i].videotags = response.data.videotags;
              break;
            }
          }
          this.tags_cartelle_ids.push(evt.added.element.id);
        }
      }, (response) => {
      });
    } else if(evt.removed) {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/del/tag/cartella/' + evt.removed.element.pivot.id).then((response) => {
        if (response.ok)
        {
          var idx = this.tags_cartelle_ids.indexOf(evt.removed.element.id);
          if(idx > -1) {
            this.tags_cartelle_ids.splice(idx, 1);
          }
        }
      }, (response) => {
      });
    } else if(evt.moved) {
      var tagscartella = [];
      for(var i in cartella.videotags) {
        tagscartella.push(cartella.videotags[i].pivot.id);
      }
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/update/order/tags/cartella', {params: {'videotags': tagscartella}}).then((response) => {
        if (response.ok)
        {
        }
      }, (response) => {
      });   
    }
  },
  deleteTagCartella(id, id_videotag, id_cartella, index) {
    if(confirm('Sei sicuro di voler eliminare il tag dalla cartella?')) {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/del/tag/cartella/' + id).then((response) => {
        if (response.ok)
        {
          for(var i in this.cartelle) {
            if(this.cartelle[i].id == id_cartella) {
              this.cartelle[i].videotags.splice(index, 1);
              break;
            }
          }
          var idx = this.tags_cartelle_ids.indexOf(id_videotag);
          if(idx > -1) {
            this.tags_cartelle_ids.splice(idx, 1);
          }
        }
      }, (response) => {
      });
    }
  },
  downloadClip(id) {
    window.open(this.$store.state.apiEndPoint + '/videotag/download/clip/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token);
  },
  downloadCartella(id) {
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/download/cartella/' + id).then((response) => {
      if (response.ok)
      {
        alert(response.data.message);
      }
    }, (response) => {
    });
  },

  downloadsUtente() {
    this.myDownload = false;
    this.archivioDownloads(this.utente);
  },

  downloadsPersonali() {
    this.myDownload = true;
    this.archivioDownloads(0);
  },

  archivioDownloads(utente_id) {
    this.$http.get(this.$store.state.apiEndPoint + '/videotags/downloads/' + utente_id).then((response) => {
      if (response.ok)
      {
        this.downloads = response.data; 
        this.$refs.downloadModal.show();
      }
    }, (response) => {

    });
  },

  formatDate (data) {
    var m = moment.utc(data).local();
    var format = m.format('DD/MM/YYYY HH:mm'); 
    return format;
  },
  downloadVideo(video) {
    window.open(this.$store.state.apiEndPoint + '/videotags/download/video/' + video + "/" + Auth.user.id + "/" + Auth.user.api_token);
  },
  eliminaVideo(video) {
    if(confirm("Confermi l'eliminazione del video?")) {
      this.$http.get(this.$store.state.apiEndPoint + '/videotags/elimina/videodownload/' + video).then((response) => {
        if (response.ok)
        {
          this.downloads = response.data;
        }
      }, (response) => {
      });
    }
  },
  durataCartella(cartella) {
    var time = 0;
    for(var x in cartella.videotags) {
      var videotag = cartella.videotags[x];
      time = time + (moment(videotag.fine, "HH:mm:ss") - moment(videotag.inizio, "HH:mm:ss"));
    }
    return moment.utc(time).format("HH:mm:ss");
  },
},
mounted() {
  this.$video = videojs('video-wrapper');
  this.$video.errors({
    "errors": this.errors_player,
  });
},
beforeDestroy() {
  this.$video.dispose();
  this.$video = null;
},
filters: {
  formatDateHour: function (date) {
    var m = moment.utc(date).local();
    if(m.isValid()) {
      return m.format('DD/MM/YYYY HH:mm'); 
    }
    return "";
  },
},
watch: {
  utente: function () {
    this.$video.pause();
    this.$video.reset();
    this.reset();
    this.loadArchivio();
    this.loadFiltri();
  },

  utente_cartelle: function () {
    this.loadCartelle();
  },

  rosa: function () {
    this.utente = 0;
    this.loadUtentiVideo();

    this.$video.pause();
    this.$video.reset();
    this.reset();
    this.loadArchivio();
    this.loadFiltri();
  },

  rosa_cartelle: function () {
    this.utente_cartelle = 0;
    this.loadUtentiCartelle();
    this.loadCartelle();
  }

}

}
</script>
<style lang="scss">

.video-js {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
  $big-play-width: 3em;
  $big-play-height: 1.5em;
  left: 50%;
  top: 50%;
  margin-left: -($big-play-width / 2);
  margin-top: -($big-play-height / 2);
}

.default {
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
}

.positivo {
  background-color: rgba(55, 128, 70, 0.7);
}

.negativo {
  background-color: rgba(166, 15, 33, 0.7);
}

.riassunto-1 {
  border-color: red;
}

.riassunto-2 {
  border-color: green;
}

.riassunto-3 {
  border-color: blue;
}

.riassunto-4 {
  border-color: orange;
}

.evidenziato {
  border: 3px solid yellow;
}

</style>
