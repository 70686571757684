<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		<div class="card">
			<div class="card-body">
				<div class="card-title">
					<span>Glossario</span>
				</div>
				<div class="content">
					<b-card no-body>
						<b-tabs pills card vertical>
							<b-tab title="Fisioterapie" v-if="auth.isUserEnable('glo_fisio')">
								<b-row class="mb-1">
									<b-col cols="4">
										<b-form-input
										v-model="search_terapia"
										placeholder="Quick search"
										></b-form-input>
									</b-col>
									<b-col>
										<b-button variant="outline-primary" @click="addTerapia">Nuova fisioterapia <i class="fa fa-plus-circle"></i></b-button>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card>
										<b-tab v-for="gruppo in filtered_terapie" :title="gruppo.label">
											<b-row>
												<b-col cols="2" class="mb-1" v-for="item in gruppo.items">
													<b-card align="center" style="cursor:pointer;height:100%;" @click="selectTerapia(item)">
														<b-row style="height:100%">
															<b-col>
																<div><b-img-lazy v-if="item.icona_url" :src="item.icona_url" fluid width="50"></b-img-lazy></div>
																<div>{{ item.nome }}</div>
																<div v-if="item.descrizione"><i :id="'terapia_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Descrizione"></i><b-popover title="Descrizione" :target="'terapia_' + item.id" triggers="hover" placement="top">{{ item.descrizione }}</b-popover></div>
															</b-col>
														</b-row>
													</b-card>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>
							<b-tab title="Fisioterapia Test" v-if="auth.isUserEnable('glo_fisio_test')">
								<b-row class="mb-1">
									<b-col cols="4">
										<b-form-input
										v-model="search_test"
										placeholder="Quick search"
										></b-form-input>
									</b-col>
									<b-col>
										<b-button variant="outline-primary" @click="addTest">Nuovo test <i class="fa fa-plus-circle"></i></b-button>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card>
										<b-tab v-for="gruppo in filtered_test" :title="gruppo.label">
											<b-row>
												<b-col cols="2" class="mb-1" v-for="item in gruppo.items">
													<b-card align="center" style="cursor:pointer;height:100%;" @click="selectTest(item)">
														<b-row style="height:100%">
															<b-col>
																<div><b-img-lazy v-if="item.icona_url" :src="item.icona_url" fluid width="50"></b-img-lazy></div>
																<div>{{ item.nome }}</div>
																<div v-if="item.descrizione"><i :id="'test_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Descrizione"></i><b-popover title="Descrizione" :target="'test_' + item.id" triggers="hover" placement="top">{{ item.descrizione }}</b-popover></div>
															</b-col>
														</b-row>
													</b-card>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>
							<b-tab title="Nutrition" v-if="auth.isUserEnable('glo_nutrition')">
								<b-row class="mb-1">
									<b-col cols="4">
										<b-form-input
										v-model="search_nutrition"
										placeholder="Quick search"
										></b-form-input>
									</b-col>
									<b-col>
										<b-button variant="outline-primary" @click="addNutrition">Nuova nutrition <i class="fa fa-plus-circle"></i></b-button>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card>
										<b-tab v-for="gruppo in filtered_nutrition" :title="gruppo.label">
											<b-row>
												<b-col cols="2" class="mb-1" v-for="item in gruppo.items">
													<b-card align="center" style="cursor:pointer;height:100%;" @click="selectNutrition(item)">
														<b-row style="height:100%">
															<b-col>
																<div><b-img-lazy v-if="item.icona_url" :src="item.icona_url" fluid width="50"></b-img-lazy></div>
																<div>{{ item.nome }}</div>
																<div v-if="item.descrizione"><i :id="'nutrition_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Descrizione"></i><b-popover title="Descrizione" :target="'nutrition_' + item.id" triggers="hover" placement="top">{{ item.descrizione }}</b-popover></div>
															</b-col>
														</b-row>
													</b-card>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>
							<b-tab title="Farmaci" v-if="auth.isUserEnable('glo_farmaci')">
								<b-row class="mb-1">
									<b-col cols="4">
										<b-form-input
										v-model="search_farmaco"
										placeholder="Quick search"
										></b-form-input>
									</b-col>
									<b-col>
										<b-button variant="outline-primary" @click="addFarmaco">Nuovo farmaco <i class="fa fa-plus-circle"></i></b-button>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card>
										<b-tab v-for="gruppo in filtered_farmaci" :title="gruppo.label">
											<b-row>
												<b-col cols="2" class="mb-1" v-for="item in gruppo.items">
													<b-card align="center" style="cursor:pointer;height:100%;" @click="selectFarmaco(item)">
														<b-row style="height:100%">
															<b-col>
																<div><b-img-lazy v-if="item.icona_url" :src="item.icona_url" fluid width="50"></b-img-lazy></div>
																<div>{{ item.nome }}</div>
																<div v-if="item.descrizione"><i :id="'farmaco_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Descrizione"></i><b-popover title="Descrizione" :target="'farmaco_' + item.id" triggers="hover" placement="top">{{ item.descrizione }}</b-popover></div>
															</b-col>
														</b-row>
													</b-card>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>
							<b-tab title="Psicologia Test" v-if="auth.isUserEnable('glo_psico_test')">
								<b-row class="mb-1">
									<b-col cols="4">
										<b-form-input
										v-model="search_test_psicologia"
										placeholder="Quick search"
										></b-form-input>
									</b-col>
									<b-col>
										<b-button variant="outline-primary" @click="addTestPsicologia">Nuovo test <i class="fa fa-plus-circle"></i></b-button>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card>
										<b-tab v-for="gruppo in filtered_test_psicologia" :title="gruppo.label">
											<b-row>
												<b-col cols="2" class="mb-1" v-for="item in gruppo.items">
													<b-card align="center" style="cursor:pointer;height:100%;" @click="selectTestPsicologia(item)">
														<b-row style="height:100%">
															<b-col>
																<div><b-img-lazy v-if="item.icona_url" :src="item.icona_url" fluid width="50"></b-img-lazy></div>
																<div>{{ item.nome }}</div>
																<div v-if="item.descrizione"><i :id="'test_psicologia_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Descrizione"></i><b-popover title="Descrizione" :target="'test_psicologia_' + item.id" triggers="hover" placement="top">{{ item.descrizione }}</b-popover></div>
															</b-col>
														</b-row>
													</b-card>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>
							<b-tab title="Icone" v-if="auth.isUserEnable('glo_icone')">
								<b-row class="mb-1">
									<b-col cols="4">
										<b-form-input
										v-model="search_icona"
										placeholder="Quick search"
										></b-form-input>
									</b-col>
									<b-col>
										<b-button variant="outline-primary" @click="addIcona">Nuova icona <i class="fa fa-plus-circle"></i></b-button>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card>
										<b-tab v-for="gruppo in filtered_icone" :title="gruppo.label">
											<b-row>
												<b-col cols="2" class="mb-1" v-for="item in gruppo.items">
													<b-card align="center" style="cursor:pointer;height:100%;" @click="selectIcona(item)">
														<b-row style="height:100%">
															<b-col>
																<div><b-img-lazy v-if="item.url" :src="item.url" fluid width="50"></b-img-lazy></div>
																<div>{{ item.nome }}</div>
															</b-col>
														</b-row>
													</b-card>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>

							<b-tab title="Palestra" v-if="auth.isUserEnable('palestra_esercizi')">
								<b-row class="mb-1">
									<b-col cols="6">
										<b-input-group class="mb-2">
											<b-form-input type="text" v-model="palestra_title" placeholder="Cerca per titolo/autore..."></b-form-input>
											<b-input-group-append>
												<b-button @click="palestraReset"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
												<b-button variant="primary" @click="palestraSubmit"><i class="fa fa-search" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card class="p-0" v-model="palestra_exercises_tab">
										<b-tab title="I miei esercizi" class="p-0">
											<card :title="'Esercizi (' + (palestra_exercises.total ? palestra_exercises.total : 0) + ')'" class="border-0 rounded-0" comp="palestraesercizi"></card>
										</b-tab>
										<b-tab title="Rosa" class="p-0">
											<card :title="'Rosa (' + (palestra_exercises_archivio.total ? palestra_exercises_archivio.total : 0) + ')'" class="border-0 rounded-0" comp="palestraeserciziarchivio"></card>
										</b-tab>
										<b-tab title="Archivio" class="p-0">
											<card :title="'Archivio (' + (palestra_exercises_condivisi.total ? palestra_exercises_condivisi.total : 0) + ')'" class="border-0 rounded-0" comp="palestraesercizicondivisi"></card>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>

							<b-tab title="Piscina" v-if="auth.isUserEnable('piscina_esercizi')">
								<b-row class="mb-1">
									<b-col cols="6">
										<b-input-group class="mb-2">
											<b-form-input type="text" v-model="piscina_title" placeholder="Cerca per titolo/autore..."></b-form-input>
											<b-input-group-append>
												<b-button @click="piscinaReset"><i class="fa fa-ban" aria-hidden="true"></i></b-button>
												<b-button variant="primary" @click="piscinaSubmit"><i class="fa fa-search" aria-hidden="true"></i></b-button>
											</b-input-group-append>
										</b-input-group>
									</b-col>
								</b-row>
								<b-card no-body>
									<b-tabs pills card class="p-0" v-model="piscina_exercises_tab">
										<b-tab title="I miei esercizi" class="p-0">
											<card :title="'Esercizi (' + (piscina_exercises.total ? piscina_exercises.total : 0) + ')'" class="border-0 rounded-0" comp="piscinaesercizi"></card>
										</b-tab>
										<b-tab title="Rosa" class="p-0">
											<card :title="'Rosa (' + (piscina_exercises_archivio.total ? piscina_exercises_archivio.total : 0) + ')'" class="border-0 rounded-0" comp="piscinaeserciziarchivio"></card>
										</b-tab>
										<b-tab title="Archivio" class="p-0">
											<card :title="'Archivio (' + (piscina_exercises_condivisi.total ? piscina_exercises_condivisi.total : 0) + ')'" class="border-0 rounded-0" comp="piscinaesercizicondivisi"></card>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-tab>

						</b-tabs>
					</b-card>

					<ModalGlossario ref="glossarioModal" :tipo="tipo" :entita="entita" :entita_gruppi="entita_gruppi" :option_items="option_items" @salvaEntita="salvaEntita" @eliminaEntita="eliminaEntita"></ModalGlossario>
					<ModalIcona ref="iconaModal" :icona="entita" :icona_gruppi="entita_gruppi" @salvaIcona="salvaIcona" @eliminaIcona="eliminaIcona"></ModalIcona>
				</div>
			</div>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>

/* questo file è odiato da tutti ^_^ */

var Auth = require('../../../auth.js');
import axios from 'axios';
import * as types from '../../../store/types';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';
export default {
	data: function() {
		return {
			test_psicologia_glo: [],
			fisioterapia_glo: [],
			fisioterapia_test_glo: [],
			nutrition_glo: [],
			farmaco_glo: [],
			icone: [],
			search_terapia: '',
			search_test: '',
			search_test_psicologia: '',
			search_nutrition: '',
			search_farmaco: '',
			search_icona: '',
			terapia: {
				id: 0,
				nome: null,
				gruppo: null,
				descrizione: null,
				attivo: 1,
				icona_id: null,
				icona_url: null,
			},
			test: {
				id: 0,
				nome: null,
				gruppo: null,
				descrizione: null,
				attivo: 1,
				label_value_1: null,
				option_value_1: null,
				label_value_2: null,
				option_value_2: null,
				label_value_3: null,
				option_value_3: null,
				label_value_4: null,
				option_value_4: null,
				label_value_5: null,
				option_value_5: null,
				label_value_6: null,
				option_value_6: null,
				icona_id: null,
				icona_url: null,
			},
			test_psicologia: {
				id: 0,
				nome: null,
				gruppo: null,
				descrizione: null,
				attivo: 1,
				label_value_1: null,
				option_value_1: null,
				label_value_2: null,
				option_value_2: null,
				label_value_3: null,
				option_value_3: null,
				label_value_4: null,
				option_value_4: null,
				label_value_5: null,
				option_value_5: null,
				label_value_6: null,
				option_value_6: null,
				icona_id: null,
				icona_url: null,
			},
			nutrition: {
				id: 0,
				nome: null,
				gruppo: null,
				descrizione: null,
				sigla: null,
				attivo: 1,
				icona: null,
			},
			farmaco: {
				id: 0,
				nome: null,
				gruppo: null,
				descrizione: null,
				forma_farmaceutica: null,
				via_di_somministrazione: null,
				dosaggio: null,
				unita_di_misura: null,
				categoria: null,
				principi_attivi: null,
				attivo: 1,
				icona_id: null,
				icona_url: null,
			},
			icona: {
				id: 0,
				nome: null,
				gruppo: null,
				file: null,
				url: null,
				file_icon: null,
			},
			option_items: {
				forme_farmaceutiche: [],
				vie_di_somministrazione: [],
				unita_di_misura: [],
			},
			test_gruppi: [],
			test_psicologia_gruppi: [],
			farmaco_gruppi: [],
			terapia_gruppi: [],
			nutrition_gruppi: [],
			icona_gruppi: [],
			
			entita: null,
			tipo: null,
			entita_gruppi: [],
		}
	},
	computed: {

		auth: function() {
			return Auth;
		},
		filtered_terapie: function () {
			if (this.search_terapia != "") {
				var filtered_terapie = [];
				for(var i in this.fisioterapia_glo) {
					var item = this.fisioterapia_glo[i];
					var filtered_items = item.items.filter((item) =>
						item.nome.toLowerCase().includes(this.search_terapia.toLowerCase())
						);
					if(filtered_items.length > 0) {
						var option = {
							label: item.label,
							items: filtered_items,
						}
						filtered_terapie.push(option);		
					}
				}
				return filtered_terapie;
			}
			return this.fisioterapia_glo;
		},
		filtered_test_psicologia: function () {
			if (this.search_test_psicologia != "") {
				var filtered_terapie = [];
				for(var i in this.test_psicologia_glo) {
					var item = this.test_psicologia_glo[i];
					var filtered_items = item.items.filter((item) =>
						item.nome.toLowerCase().includes(this.search_test_psicologia.toLowerCase())
						);
					if(filtered_items.length > 0) {
						var option = {
							label: item.label,
							items: filtered_items,
						}
						filtered_terapie.push(option);		
					}
				}
				return filtered_terapie;
			}
			return this.test_psicologia_glo;
		},
		filtered_test: function () {
			if (this.search_test != "") {
				var filtered_test = [];
				for(var i in this.fisioterapia_test_glo) {
					var item = this.fisioterapia_test_glo[i];
					var filtered_items = item.items.filter((test) =>
						test.nome.toLowerCase().includes(this.search_test.toLowerCase())
						);
					if(filtered_items.length > 0) {
						var option = {
							label: item.label,
							items: filtered_items,
						}
						filtered_test.push(option);		
					}
				}
				return filtered_test;
			}
			return this.fisioterapia_test_glo;
		},
		filtered_nutrition: function () {
			if (this.search_nutrition != "") {
				var filtered_nutrition = [];
				for(var i in this.nutrition_glo) {
					var item = this.nutrition_glo[i];
					var filtered_items = item.items.filter((item) =>
						item.nome.toLowerCase().includes(this.search_nutrition.toLowerCase())
						);
					if(filtered_items.length > 0) {
						var option = {
							label: item.label,
							items: filtered_items,
						}
						filtered_nutrition.push(option);		
					}
				}
				return filtered_nutrition;
			}
			return this.nutrition_glo;
		},
		filtered_farmaci: function () {
			if (this.search_farmaco != "") {
				var filtered_farmaci = [];
				for(var i in this.farmaco_glo) {
					var item = this.farmaco_glo[i];
					var filtered_items = item.items.filter((item) =>
						item.nome.toLowerCase().includes(this.search_farmaco.toLowerCase())
						);
					if(filtered_items.length > 0) {
						var option = {
							label: item.label,
							items: filtered_items,
						}
						filtered_farmaci.push(option);		
					}
				}
				return filtered_farmaci;
			}
			return this.farmaco_glo;
		},
		filtered_icone: function () {
			if (this.search_icona != "") {
				var filtered_icone = [];
				for(var i in this.icone) {
					var item = this.icone[i];
					var filtered_items = item.items.filter((item) =>
						item.nome.toLowerCase().includes(this.search_icona.toLowerCase())
						);
					if(filtered_items.length > 0) {
						var option = {
							label: item.label,
							items: filtered_items,
						}
						filtered_icone.push(option);		
					}
				}
				return filtered_icone;
			}
			return this.icone;
		},

		...mapState(['rosaTeamId']),
		...mapState('palestra', {
			palestra_exercises: 'exercises',
			palestra_exercises_archivio: 'exercises_archivio',
			palestra_exercises_condivisi: 'exercises_condivisi'
		}),
		palestra_exercises_tab: {
			get() {
				return this.$store.state.palestra.exercises_tab;
			},

			set(value) {
				this.$store.commit('palestra/setExcerciseTab', value);
			},
		},
		palestra_title: {
			get() {
				return this.$store.state.palestra.title;
			},
			set(value) {
				this.$store.commit('palestra/setTitle', value);
			},
		},
		...mapState('piscina', {
			piscina_exercises: 'exercises',
			piscina_exercises_archivio: 'exercises_archivio',
			piscina_exercises_condivisi: 'exercises_condivisi'
		}),
		piscina_exercises_tab: {
			get() {
				return this.$store.state.piscina.exercises_tab;
			},

			set(value) {
				this.$store.commit('piscina/setExcerciseTab', value);
			},
		},
		piscina_title: {
			get() {
				return this.$store.state.piscina.title;
			},
			set(value) {
				this.$store.commit('piscina/setTitle', value);
			},
		},
	},

	watch: {
		rosaTeamId() {
			if(this.auth.isUserEnable('palestra_esercizi')) {
				this.$store.commit('palestra/setPage', 1);
				if(this.palestra_exercises_tab == 0) {
					this.$store.dispatch('palestra/filter');
				} else if(this.palestra_exercises_tab == 1) {
					this.$store.dispatch('palestra/filter_archivio');
				} else if(this.palestra_exercises_tab == 2) {
					this.$store.dispatch('palestra/filter_condivisi');
				} 
			}
			if(this.auth.isUserEnable('piscina_esercizi')) {
				this.$store.commit('piscina/setPage', 1);
				if(this.piscina_exercises_tab == 0) {
					this.$store.dispatch('piscina/filter');
				} else if(this.piscina_exercises_tab == 1) {
					this.$store.dispatch('piscina/filter_archivio');
				} else if(this.piscina_exercises_tab == 2) {
					this.$store.dispatch('piscina/filter_condivisi');
				} 
			}
		},  
		palestra_exercises_tab() {
			this.$store.commit('palestra/setPage', 1);
			if(this.palestra_exercises_tab == 0) {
				this.$store.dispatch('palestra/filter');
			} else if(this.palestra_exercises_tab == 1) {
				this.$store.dispatch('palestra/filter_archivio');
			} else if(this.palestra_exercises_tab == 2) {
				this.$store.dispatch('palestra/filter_condivisi');
			} 
		},
		piscina_exercises_tab() {
			this.$store.commit('piscina/setPage', 1);
			if(this.piscina_exercises_tab == 0) {
				this.$store.dispatch('piscina/filter');
			} else if(this.piscina_exercises_tab == 1) {
				this.$store.dispatch('piscina/filter_archivio');
			} else if(this.piscina_exercises_tab == 2) {
				this.$store.dispatch('piscina/filter_condivisi');
			} 
		}
	}, 

	components: {
		Multiselect,
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		ModalGlossario:     require('../../layout/Medical/ModalGlossario.vue').default,
		ModalIcona:     require('../../layout/Medical/ModalIcona.vue').default,
		card:           require('../../layout/card.vue').default,
	},
	created: function () {
		if(this.auth.isUserEnable('glo_farmaci')) {
			this.getFormeFarmaceutiche();
			this.getVieDiSomministrazione();
			this.getUnitaDiMisura();
			this.getFarmacoGlo();
		}
		if(this.auth.isUserEnable('glo_fisio')) {
			this.getFisioterapiaGlo();
		}
		if(this.auth.isUserEnable('glo_fisio_test')) {
			this.getFisioterapiaTestGlo();
		}
		if(this.auth.isUserEnable('glo_nutrition')) {
			this.getNutritionGlo();
		}
		if(this.auth.isUserEnable('glo_psico_test')) {
			this.getPsicologiaTestGlo();
		}
		if(this.auth.isUserEnable('glo_icone')) {
			this.getIcone();
		}
		if(this.auth.isUserEnable('palestra_esercizi')) {
			this.$store.commit('palestra/setTitle', '');
			this.$store.commit('palestra/setFilters', { facility: 0, goal: 0, tt: 0, intensity: 0});
		}
		if(this.auth.isUserEnable('piscina_esercizi')) {
			this.$store.commit('piscina/setTitle', '');
		}
	},
	methods: {
		getFormeFarmaceutiche() {
			this.$http.get(this.$store.state.apiEndPoint + '/farmaco/forme/farmaceutiche').then((response) => {
				if (response.ok) {
					this.option_items.forme_farmaceutiche = response.data;
				}
			}, (response) => {
			});
		},

		getVieDiSomministrazione() {
			this.$http.get(this.$store.state.apiEndPoint + '/farmaco/vie/di/somministrazione').then((response) => {
				if (response.ok) {
					this.option_items.vie_di_somministrazione = response.data;
				}
			}, (response) => {
			});
		},

		getUnitaDiMisura() {
			this.$http.get(this.$store.state.apiEndPoint + '/farmaco/unita/di/misura').then((response) => {
				if (response.ok) {
					this.option_items.unita_di_misura = response.data;
				}
			}, (response) => {
			});
		},

		getFisioterapiaGlo() {
			this.$http.get(this.$store.state.apiEndPoint + '/fisioterapia/glo').then((response) => {
				if (response.ok) {
					this.fisioterapia_glo = [];
					this.terapia_gruppi = [];
					for(var i in response.data) {
						var item = response.data[i];
						var gruppo = item.gruppo ? item.gruppo : 'Senza gruppo';
						var index = this.fisioterapia_glo.findIndex((obj) => obj.label == gruppo);
						if(index == -1) {
							if(item.gruppo) {
								this.terapia_gruppi.push(gruppo);
							}
							var option = {
								label: gruppo,
								items: [],
							}
							option.items.push(item);
							this.fisioterapia_glo.push(option);
						} else {
							this.fisioterapia_glo[index].items.push(item);
						}
					}
				}
			}, (response) => {
			});
		},
		getPsicologiaTestGlo: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/psicologia/test/glo').then((response) => {
				if (response.ok) {
					this.test_psicologia_glo = [];
					this.test_psicologia_gruppi = [];
					for(var i in response.data) {
						var item = response.data[i];
						var gruppo = item.gruppo ? item.gruppo : 'Senza gruppo';
						var index = this.test_psicologia_glo.findIndex((obj) => obj.label == gruppo);
						if(index == -1) {
							if(item.gruppo) {
								this.test_psicologia_gruppi.push(gruppo);
							}
							var option = {
								label: gruppo,
								items: [],
							}
							option.items.push(item);
							this.test_psicologia_glo.push(option);
						} else {
							this.test_psicologia_glo[index].items.push(item);
						}
					}
				}
			}, (response) => {
			});
		},
		getFisioterapiaTestGlo() {
			this.$http.get(this.$store.state.apiEndPoint + '/fisioterapia/test/glo').then((response) => {
				if (response.ok) {
					this.fisioterapia_test_glo = [];
					this.test_gruppi = [];
					for(var i in response.data) {
						var item = response.data[i];
						var gruppo = item.gruppo ? item.gruppo : 'Senza gruppo';
						var index = this.fisioterapia_test_glo.findIndex((obj) => obj.label == gruppo);
						if(index == -1) {
							if(item.gruppo) {
								this.test_gruppi.push(gruppo);
							}
							var option = {
								label: gruppo,
								items: [],
							}
							option.items.push(item);
							this.fisioterapia_test_glo.push(option);
						} else {
							this.fisioterapia_test_glo[index].items.push(item);
						}
					}
				}
			}, (response) => {
			});
		},

		getNutritionGlo() {
			this.$http.get(this.$store.state.apiEndPoint + '/nutrition/glo').then((response) => {
				if (response.ok) {
					this.nutrition_glo = [];
					this.nutrition_gruppi = [];
					for(var i in response.data) {
						var item = response.data[i];
						var gruppo = item.gruppo ? item.gruppo : 'Senza gruppo';
						var index = this.nutrition_glo.findIndex((obj) => obj.label == gruppo);
						if(index == -1) {
							if(item.gruppo) {
								this.nutrition_gruppi.push(gruppo);
							}
							var option = {
								label: gruppo,
								items: [],
							}
							option.items.push(item);
							this.nutrition_glo.push(option);
						} else {
							this.nutrition_glo[index].items.push(item);
						}
					}
				}
			}, (response) => {
			});
		},

		getFarmacoGlo() {
			this.$http.get(this.$store.state.apiEndPoint + '/farmaco/glo').then((response) => {
				if (response.ok) {
					this.farmaco_glo = [];
					this.farmaco_gruppi = [];
					for(var i in response.data) {
						var item = response.data[i];
						var gruppo = item.gruppo ? item.gruppo : 'Senza gruppo';
						var index = this.farmaco_glo.findIndex((obj) => obj.label == gruppo);
						if(index == -1) {
							if(item.gruppo) {
								this.farmaco_gruppi.push(gruppo);
							}
							var option = {
								label: gruppo,
								items: [],
							}
							option.items.push(item);
							this.farmaco_glo.push(option);
						} else {
							this.farmaco_glo[index].items.push(item);
						}
					}
				}
			}, (response) => {
			});
		},

		getIcone() {
			this.$http.get(this.$store.state.apiEndPoint + '/icone').then((response) => {
				if (response.ok) {
					this.icone = [];
					this.icona_gruppi = [];
					for(var i in response.data) {
						var item = response.data[i];
						var gruppo = item.gruppo ? item.gruppo : 'Senza gruppo';
						var index = this.icone.findIndex((obj) => obj.label == gruppo);
						if(index == -1) {
							if(item.gruppo) {
								this.icona_gruppi.push(gruppo);
							}
							var option = {
								label: gruppo,
								items: [],
							}
							option.items.push(item);
							this.icone.push(option);
						} else {
							this.icone[index].items.push(item);
						}
					}
				}
			}, (response) => {
			});
		},

		addTerapia() {
			this.terapia.id = 0;
			this.terapia.nome = null;
			this.terapia.gruppo = null;
			this.terapia.descrizione = null;
			this.terapia.attivo = 1;
			this.terapia.icona_id = null;
			this.terapia.icona_url = null;

			this.entita = { ...this.terapia };
			this.tipo = 'Fisioterapia';
			this.entita_gruppi = this.terapia_gruppi;
			this.$refs.glossarioModal.show();
		},

		selectTerapia(terapia) {
			this.terapia.id = terapia.id;
			this.terapia.nome = terapia.nome;
			this.terapia.gruppo = terapia.gruppo;
			this.terapia.descrizione = terapia.descrizione;
			this.terapia.attivo = terapia.attivo;
			this.terapia.icona_id = terapia.icona_id;
			this.terapia.icona_url = terapia.icona_url;

			this.entita = { ...this.terapia };
			this.tipo = 'Fisioterapia';
			this.entita_gruppi = this.terapia_gruppi;
			this.$refs.glossarioModal.show();
		},

		salvaTerapia() {
			if(!this.entita.nome || this.entita.nome == '') {
				alert('Devi inserire un nome per la terapia!');
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/fisioterapia/glo/salva', this.entita).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getFisioterapiaGlo();
					this.$refs.glossarioModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		eliminaTerapia() {
			if(confirm("Confermi l'eliminazione della terapia ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/fisioterapia/glo/elimina/' + this.entita.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getFisioterapiaGlo();
						this.$refs.glossarioModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		addTest() {
			this.test.id = 0;
			this.test.nome = null;
			this.test.gruppo = null;
			this.test.descrizione = null;
			this.test.attivo = 1;
			this.test.label_value_1 = null;
			this.test.option_value_1 = null;
			this.test.label_value_2 = null;
			this.test.option_value_2 = null;
			this.test.label_value_3 = null;
			this.test.option_value_3 = null;
			this.test.label_value_4 = null;
			this.test.option_value_4 = null;
			this.test.label_value_5 = null;
			this.test.option_value_5 = null;
			this.test.label_value_6 = null;
			this.test.option_value_6 = null;
			this.test.icona_id = null;
			this.test.icona_url = null;

			this.entita = { ...this.test };
			this.tipo = 'Fisioterapia Test';
			this.entita_gruppi = this.test_gruppi;
			this.$refs.glossarioModal.show();
		},

		selectTest(test) {
			this.test.id = test.id;
			this.test.nome = test.nome;
			this.test.gruppo = test.gruppo;
			this.test.descrizione = test.descrizione;
			this.test.attivo = 1;
			this.test.label_value_1 = test.label_value_1;
			this.test.option_value_1 = test.option_value_1;
			this.test.label_value_2 = test.label_value_2;
			this.test.option_value_2 = test.option_value_2;
			this.test.label_value_3 = test.label_value_3;
			this.test.option_value_3 =test.option_value_3;
			this.test.label_value_4 = test.label_value_4;
			this.test.option_value_4 = test.option_value_4;
			this.test.label_value_5 = test.label_value_5;
			this.test.option_value_5 = test.option_value_5;
			this.test.label_value_6 = test.label_value_6;
			this.test.option_value_6 = test.option_value_6;
			this.test.icona_id = test.icona_id;
			this.test.icona_url = test.icona_url;

			this.entita = { ...this.test };
			this.tipo = 'Fisioterapia Test';
			this.entita_gruppi = this.test_gruppi;
			this.$refs.glossarioModal.show();
		},

		salvaTest() {
			if(!this.entita.nome || this.entita.nome == '') {
				alert('Devi inserire un nome per il test!');
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/fisioterapia/test/glo/salva', this.entita).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getFisioterapiaTestGlo();
					this.$refs.glossarioModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		eliminaTest() {
			if(confirm("Confermi l'eliminazione del test ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/fisioterapia/test/glo/elimina/' + this.entita.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getFisioterapiaTestGlo();
						this.$refs.glossarioModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		addTestPsicologia() {
			this.test_psicologia.id = 0;
			this.test_psicologia.nome = null;
			this.test_psicologia.gruppo = null;
			this.test_psicologia.descrizione = null;
			this.test_psicologia.attivo = 1;
			this.test_psicologia.label_value_1 = null;
			this.test_psicologia.option_value_1 = null;
			this.test_psicologia.label_value_2 = null;
			this.test_psicologia.option_value_2 = null;
			this.test_psicologia.label_value_3 = null;
			this.test_psicologia.option_value_3 = null;
			this.test_psicologia.label_value_4 = null;
			this.test_psicologia.option_value_4 = null;
			this.test_psicologia.label_value_5 = null;
			this.test_psicologia.option_value_5 = null;
			this.test_psicologia.label_value_6 = null;
			this.test_psicologia.option_value_6 = null;
			this.test_psicologia.icona_id = null;
			this.test_psicologia.icona_url = null;

			this.entita = { ...this.test_psicologia };
			this.tipo = 'Psicologia Test';
			this.entita_gruppi = this.test_psicologia_gruppi;
			this.$refs.glossarioModal.show();
		},

		selectTestPsicologia(test_psicologia) {
			this.test_psicologia.id = test_psicologia.id;
			this.test_psicologia.nome = test_psicologia.nome;
			this.test_psicologia.gruppo = test_psicologia.gruppo;
			this.test_psicologia.descrizione = test_psicologia.descrizione;
			this.test_psicologia.attivo = 1;
			this.test_psicologia.label_value_1 = test_psicologia.label_value_1;
			this.test_psicologia.option_value_1 = test_psicologia.option_value_1;
			this.test_psicologia.label_value_2 = test_psicologia.label_value_2;
			this.test_psicologia.option_value_2 = test_psicologia.option_value_2;
			this.test_psicologia.label_value_3 = test_psicologia.label_value_3;
			this.test_psicologia.option_value_3 =test_psicologia.option_value_3;
			this.test_psicologia.label_value_4 = test_psicologia.label_value_4;
			this.test_psicologia.option_value_4 = test_psicologia.option_value_4;
			this.test_psicologia.label_value_5 = test_psicologia.label_value_5;
			this.test_psicologia.option_value_5 = test_psicologia.option_value_5;
			this.test_psicologia.label_value_6 = test_psicologia.label_value_6;
			this.test_psicologia.option_value_6 = test_psicologia.option_value_6;
			this.test_psicologia.icona_id = test_psicologia.icona_id;
			this.test_psicologia.icona_url = test_psicologia.icona_url;

			this.entita = { ...this.test_psicologia };
			this.tipo = 'Psicologia Test';
			this.entita_gruppi = this.test_psicologia_gruppi;
			this.$refs.glossarioModal.show();
		},

		salvaTestPsicologia() {
			if(!this.entita.nome || this.entita.nome == '') {
				alert('Devi inserire un nome per il test!');
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/psicologia/test/glo/salva', this.entita).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getPsicologiaTestGlo();
					this.$refs.glossarioModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		eliminaTestPsicologia() {
			if(confirm("Confermi l'eliminazione del test ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/psicologia/test/glo/elimina/' + this.entita.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getPsicologiaTestGlo();
						this.$refs.glossarioModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		addNutrition() {
			this.nutrition.id = 0;
			this.nutrition.nome = null;
			this.nutrition.gruppo = null;
			this.nutrition.descrizione = null;
			this.nutrition.sigla = null;
			this.nutrition.attivo = 1;
			this.nutrition.icona_id = null;
			this.nutrition.icona_url = null;

			this.entita = { ...this.nutrition };
			this.tipo = 'Nutrition';
			this.entita_gruppi = this.nutrition_gruppi;
			this.$refs.glossarioModal.show();
		},

		selectNutrition(nutrition) {
			this.nutrition.id = nutrition.id;
			this.nutrition.nome = nutrition.nome;
			this.nutrition.gruppo = nutrition.gruppo;
			this.nutrition.descrizione = nutrition.descrizione;
			this.nutrition.sigla = nutrition.sigla;
			this.nutrition.attivo = nutrition.attivo;
			this.nutrition.icona_id = nutrition.icona_id;
			this.nutrition.icona_url = nutrition.icona_url;

			this.entita = { ...this.nutrition };
			this.tipo = 'Nutrition';
			this.entita_gruppi = this.nutrition_gruppi;
			this.$refs.glossarioModal.show();
		},

		salvaNutrition() {
			if(!this.entita.nome || this.entita.nome == '') {
				alert('Devi inserire un nome per la nutrition!');
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/nutrition/glo/salva', this.entita).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getNutritionGlo();
					this.$refs.glossarioModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		eliminaNutrition() {
			if(confirm("Confermi l'eliminazione della nutrition ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/nutrition/glo/elimina/' + this.entita.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getNutritionGlo();
						this.$refs.glossarioModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		addFarmaco() {
			this.farmaco.id = 0;
			this.farmaco.nome = null;
			this.farmaco.gruppo = null;
			this.farmaco.descrizione = null;
			this.farmaco.forma_farmaceutica = null;
			this.farmaco.via_di_somministrazione = null;
			this.farmaco.dosaggio = null;
			this.farmaco.unita_di_misura = null;
			this.farmaco.categoria = null;
			this.farmaco.principi_attivi = null;			
			this.farmaco.attivo = 1;
			this.farmaco.icona_id = null;
			this.farmaco.icona_url = null;

			this.entita = { ...this.farmaco };
			this.tipo = 'Farmaco';
			this.entita_gruppi = this.farmaco_gruppi;
			this.$refs.glossarioModal.show();
		},

		selectFarmaco(farmaco) {
			this.farmaco.id = farmaco.id;
			this.farmaco.nome = farmaco.nome;
			this.farmaco.gruppo = farmaco.gruppo;
			this.farmaco.descrizione = farmaco.descrizione;
			this.farmaco.forma_farmaceutica = farmaco.forma_farmaceutica;
			this.farmaco.via_di_somministrazione = farmaco.via_di_somministrazione;
			this.farmaco.dosaggio = farmaco.dosaggio;
			this.farmaco.unita_di_misura = farmaco.unita_di_misura;
			this.farmaco.categoria = farmaco.categoria;
			this.farmaco.principi_attivi = farmaco.principi_attivi;			
			this.farmaco.attivo = 1;
			this.farmaco.icona_id = farmaco.icona_id;
			this.farmaco.icona_url = farmaco.icona_url;

			this.entita = { ...this.farmaco };
			this.tipo = 'Farmaco';
			this.entita_gruppi = this.farmaco_gruppi;
			this.$refs.glossarioModal.show();
		},

		salvaFarmaco() {
			if(!this.entita.nome || this.entita.nome == '') {
				alert('Devi inserire un nome per il farmaco!');
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/farmaco/glo/salva', this.entita).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getFarmacoGlo();
					this.$refs.glossarioModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		eliminaFarmaco() {
			if(confirm("Confermi l'eliminazione del farmaco ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/farmaco/glo/elimina/' + this.entita.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getFarmacoGlo();
						this.$refs.glossarioModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		addIcona() {
			this.icona.id = 0;
			this.icona.nome = null;
			this.icona.gruppo = null;
			this.icona.file = null;
			this.icona.url = null;
			this.icona.file_icon = null;

			this.entita = { ...this.icona };
			this.entita_gruppi = this.icona_gruppi;	
			this.$refs.iconaModal.show();
		},

		selectIcona(icona) {
			this.icona.id = icona.id;
			this.icona.nome = icona.nome;
			this.icona.gruppo = icona.gruppo;
			this.icona.file = icona.file;
			this.icona.url = icona.url;
			this.icona.file_icon = null;

			this.entita = { ...this.icona };
			this.entita_gruppi = this.icona_gruppi;	
			this.$refs.iconaModal.show();
		},

		salvaIcona() {
			if(!this.entita.nome || this.entita.nome == '') {
				alert("Devi inserire un nome per l'icona!");
				return;
			}
			var formData = new FormData();
			formData.append("form", JSON.stringify(this.entita));
			formData.append("file_icona", this.entita.file_icon);
			this.$http.post(this.$store.state.apiEndPoint + '/icona/salva', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getIcone();
					this.$refs.iconaModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},

		eliminaIcona() {
			if(confirm("Confermi l'eliminazione dell'icona ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/icona/elimina/' + this.entita.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getIcone();
						this.$refs.iconaModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		salvaEntita(tipo) {
			if(tipo == 'Fisioterapia') {
				this.salvaTerapia();
			} else if(tipo == 'Fisioterapia Test') {
				this.salvaTest();
			} else if(tipo == 'Psicologia Test') {
				this.salvaTestPsicologia();
			} else if(tipo == 'Nutrition') {
				this.salvaNutrition();
			} else if(tipo == 'Farmaco') {
				this.salvaFarmaco();
			}
		},

		eliminaEntita(tipo) {
			if(tipo == 'Fisioterapia') {
				this.eliminaTerapia();
			} else if(tipo == 'Fisioterapia Test') {
				this.eliminaTest();
			} else if(tipo == 'Psicologia Test') {
				this.eliminaTestPsicologia();
			} else if(tipo == 'Nutrition') {
				this.eliminaNutrition();
			} else if(tipo == 'Farmaco') {
				this.eliminaFarmaco();
			}
		},
		palestraSubmit() {
			this.$store.commit('palestra/setPage', 1);
			if(this.palestra_exercises_tab == 0) {
				this.$store.dispatch('palestra/filter');
			} else if(this.palestra_exercises_tab == 1) {
				this.$store.dispatch('palestra/filter_archivio');
			} else if(this.palestra_exercises_tab == 2) {
				this.$store.dispatch('palestra/filter_condivisi');
			} 
		},
		palestraReset() {
			this.palestra_title = '';
			this.palestraSubmit();
		},
		piscinaSubmit() {
			this.$store.commit('piscina/setPage', 1);
			if(this.piscina_exercises_tab == 0) {
				this.$store.dispatch('piscina/filter');
			} else if(this.piscina_exercises_tab == 1) {
				this.$store.dispatch('piscina/filter_archivio');
			} else if(this.piscina_exercises_tab == 2) {
				this.$store.dispatch('piscina/filter_condivisi');
			} 
		},
		piscinaReset() {
			this.piscina_title = '';
			this.piscinaSubmit();
		},
	},
}
</script>