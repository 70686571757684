<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Cartelle ({{ cartelle.length }})</span>
					</div>
					<div class="content">
						<b-row class="mb-3" align-v="center">
							<b-col md="2">
								<b-button variant="primary" @click="newCartella">Nuova Cartella</b-button>	
							</b-col>
							<b-col md="1">
								Filtra per:
							</b-col>
							<b-col md="2">
								<b-form-input v-model="filters.titolo" placeholder="Titolo video" />
							</b-col>
							<b-col md="2">
								<b-form-select v-model="filters.stato" class="inline mr-3"> 
									<option value="">-- Stato video --</option>
									<option value="0">Bozza</option>
									<option value="1">Pubblicato</option>
									<option value="2">Archiviato</option>
								</b-form-select>
							</b-col>
							<b-col md="3">
								<multiselect 
								v-model="filters.categorie"
								:multiple="true"
								:options="user.romalabCats"
								placeholder="-- Categorie video --"
								label="name"
								track-by="id"/>
							</b-col>
							<b-col md="2">
								<b-form-input type="date" v-model="filters.data" class="inline mr-3"></b-form-input>
							</b-col>
						</b-row>
						<div class="row">
							<div class="col-md-4 col-lg-3 col-xl-3 mb-2" v-for="cartella in cartelle">
								<b-card :title="cartella.nome" class="h-100">
									<b-row align-v="center">
										<b-col>
											<div>Creata il <strong>{{ cartella.created_at | formatDateHour }}</strong></div>
											<div>Utente: <strong> {{ cartella.utente.nomeCompleto }}</strong></div>
											<div>Documenti: <strong>{{ cartella.video_count }}</strong></div>
											<div>Modificata il <strong>{{ cartella.updated_at | formatDateHour }}</strong></div>
										</b-col>
									</b-row>
									<b-row class="mt-2">
										<b-col>
											<b-button title="Apri cartella" variant="primary" size="sm" class="mr-1" :to="{ name: 'romalab_video_cartella', params: {id: cartella.id} }">Apri <i class="fa fa-folder-open" aria-hidden="true"></i></b-button>
											<b-button title="Modifica cartella" variant="success" size="sm" class="mr-1" @click.prevent="editCartella(cartella)">Modifica <i class="fa fa-pencil" aria-hidden="true"></i></b-button>
											<b-button title="Elimina cartella" size="sm" v-if="cartella.video_count == 0" @click.prevent="eliminaCartella(cartella)">Elimina <i class="fa fa-trash" aria-hidden="true"></i></b-button>
										</b-col>
									</b-row>
								</b-card>
							</div>
						</div>
					</div>
					<b-modal id="cartellaModal" ref="cartellaModal" :title="titleModal" ok-only ok-title="Chiudi">
						<b-row class="mb-3">
							<b-col cols="12"><p>Inserisci un nome:</p></b-col>
							<b-col cols="12">
								<b-form-input type="text" v-model="form.nome"></b-form-input>
							</b-col>
						</b-row>
						<b-button variant="primary" class="mt-3" @click.prevent="addCartella" v-if="!cartella_id">Salva</b-button>
						<b-button variant="primary" class="mt-3" @click.prevent="updateCartella" v-if="cartella_id">Salva</b-button>
					</b-modal>
				</div>
			</div>	
		</div>

		<footerapp></footerapp>

	</section>
</template>

<script>

var Auth = require('../../../auth.js');
import * as types from '../../../store/types'
import moment from 'moment'
import axios from 'axios';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
	},

	data: function(){
		return {
			titleModal: '',
			cartelle: [],
			filters: {
				stato: '',
				data: '',
				categorie: [],
				titolo: '',
			},
			form: {
				nome: '',
				tipo: 'video',
			},
			cartella_id: null,
		}
	},

	computed: {
		user: function() {
			return Auth.user;
		},
	},
	created() {
		this.getCartelle();
	},
	methods: {
		getCartelle() {
			this.cartelle = [];
			this.$http.get(this.$store.state.apiEndPoint + '/romalab/get/cartelle/video', {params: this.filters}).then((response) => {
				if (response.ok)
				{
					this.cartelle = response.data;
				}
			}, (response) => {
			});
		},
		newCartella() {
			this.cartella_id = null;
			this.titleModal = 'Nuova cartella';
			this.form.nome = '';
			this.$refs.cartellaModal.show();
		},
		addCartella() {
			this.$http.post(this.$store.state.apiEndPoint + '/romalab/cartelle/add', this.form).then((response) => {
				if (response.ok)
				{
					this.$refs.cartellaModal.hide();
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getCartelle();
				}
			}, (response) => {
			});
		},
		editCartella(cartella) {
			this.cartella_id = cartella.id;
			this.titleModal = 'Modifica cartella';
			this.form.nome = cartella.nome;
			this.$refs.cartellaModal.show();
		},
		updateCartella() {
			this.$http.post(this.$store.state.apiEndPoint + '/romalab/cartelle/edit/' + this.cartella_id, this.form).then((response) => {
				if (response.ok)
				{
					this.$refs.cartellaModal.hide();
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getCartelle();
				}
			}, (response) => {
			});
		},
		eliminaCartella(cartella) {
			if(confirm("Confermi l'eliminazione della cartella '" + cartella.nome + "' ? ")) {
			this.$http.get(this.$store.state.apiEndPoint + '/romalab/cartelle/delete/' + cartella.id).then((response) => {
				if (response.ok)
				{
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getCartelle();
				}
			}, (response) => {
			});
			}
		},
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		}
	},
	watch: {
		filters: {
			handler() {
				this.getCartelle();
			},      
			deep: true,
		},
	}
}
</script>
