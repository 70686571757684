<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		<menusub></menusub>
		
		<PrevenzioneDashboard />
		
		<footerapp></footerapp>
	</section>
</template>

<script>
import pageBase from '@/mixins/pageBase.js'
import PrevenzioneDashboard from '@/components/layout/reports/PrevenzioneDashboard.vue'

export default {
	mixins: [ pageBase ],
	components: {
		PrevenzioneDashboard
	},
}

</script>