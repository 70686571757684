export default {
    exercises: [],
    exercises_archivio: [],
    exercises_condivisi: [],
    title: '',
    page: 1,
    exercises_tab: 0,
    filters: {
    	facility: 0,
    	goal: 0,
    	tt: 0,
    	intensity: 0,
    } ,
};
