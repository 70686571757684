<template>
    <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 2399.6 1639.8" style="enable-background:new 0 0 2399.6 1639.8;" xml:space="preserve">
    <rect x="379.9" y="-379.9" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 2019.718 -379.8967)" style="fill:#6DB55C" width="1639.8" height="2399.6"/>
    <rect x="120" y="120" style="fill:none" width="2159.6" height="1399.8"/>
    <rect x="1307.8" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 2723.5571 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="1199.8" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 2507.5957 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="1091.8" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 2291.634 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="983.8" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 2075.6726 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="875.9" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1859.7111 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="767.9" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1643.7496 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="659.9" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1427.7881 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="551.9" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1211.8265 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="443.9" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 995.8651 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="336" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 779.9035 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="228" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 563.942 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="120" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 347.9805 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="2171.6" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4451.2495 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="2063.7" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4235.2876 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="1955.7" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4019.3264 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="1847.7" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3803.3647 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="1739.7" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3587.4033 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="1631.7" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3371.4417 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <rect x="1523.7" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3155.4802 1639.821)" style="fill:#5AA84C;" width="108" height="1399.8"/>
    <rect x="1415.8" y="120" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 2939.5188 1639.821)" style="fill:#6DB55C" width="108" height="1399.8"/>
    <g>
        <path style="fill:#FFFFFF" d="M2316.9,743.3h-37.3V120H120v623.8H83.2V902H120v617.8h2159.6V901.5h37.3V743.3z M127.7,639.7h98.7v367.1
        h-98.7V639.7z M127.7,1014.5h106.4V632H127.7V410.9H444v816.9H127.7V1014.5z M127.7,1235.5h324V972.6c45.3-37,71.2-91.5,71.2-150
        c0-58.5-26-113-71.2-150V403.2h-324V160.7c17.4-1.8,31.2-15.6,33-33.1H1196l0.2,504.3c-24.1,0.5-47.6,5.4-69.7,14.7
        c-22.6,9.5-42.9,23.2-60.3,40.7c-17.4,17.4-31.1,37.7-40.6,60.3c-9.9,23.4-14.9,48.2-14.9,73.9c0,25.6,5,50.5,14.9,73.9
        c9.5,22.6,23.2,42.9,40.6,60.3c17.4,17.4,37.7,31.1,60.3,40.7c22.1,9.4,45.6,14.2,69.8,14.7l0.2,501H160.7
        c-1.8-17.4-15.6-31.2-33-33V1235.5z M451.6,683c40.4,35.4,63.6,85.7,63.6,139.7c0,54-23.2,104.3-63.6,139.7V683z M1196.2,809.8
        c-4.9,1.7-8.5,6.2-8.5,11.7s3.6,10,8.5,11.7l0,170.1c-98.5-2.2-178-82.8-178-181.8s79.4-179.6,177.9-181.9L1196.2,809.8z
        M1203.9,833.4c5.2-1.6,9-6.2,9-11.9s-3.9-10.3-9-11.9l-0.1-170c98.8,1.9,178.6,82.7,178.6,181.9c0,99.2-79.7,179.9-178.4,181.9
        L1203.9,833.4z M1204,1011.1c24.3-0.4,47.9-5.3,70.2-14.7c22.6-9.6,42.9-23.3,60.3-40.7c17.4-17.4,31.1-37.7,40.7-60.3
        c9.9-23.4,14.9-48.3,14.9-73.9c0-25.6-5-50.5-14.9-73.9c-9.5-22.6-23.2-42.9-40.7-60.3c-17.4-17.4-37.7-31.1-60.3-40.7
        c-22.3-9.4-46-14.3-70.3-14.7l-0.2-504.3h1035.2c1.8,17.5,15.6,31.2,33.1,33.1v243.6h-323.5v268.9c-45.2,37-71.2,91.5-71.2,150
        c0,58.5,26,113,71.2,150v263.4h323.5v242.5c-17.5,1.8-31.3,15.6-33.1,33H1204.2L1204,1011.1z M1948.5,962.9
        c-40.3-35.4-63.6-85.7-63.6-139.7c0-54,23.2-104.3,63.6-139.7V962.9z M1956.2,1228.9V412h315.8v219.4H2166v382.4h105.9v215.1
        H1956.2z M2173.7,1006.1V639.1h98.2v367.1H2173.7z M2271.9,153.1c-13.2-1.8-23.7-12.2-25.4-25.4h25.4V153.1z M153,127.7
        c-1.8,13.2-12.2,23.6-25.4,25.4v-25.4H153z M90.9,894.3V751.5H120v142.9H90.9z M127.7,1486.7c13.2,1.8,23.6,12.2,25.4,25.4h-25.4
        V1486.7z M2246.5,1512.1c1.8-13.2,12.2-23.6,25.4-25.4v25.4H2246.5z M2309.2,893.8h-29.6V750.9h29.6V893.8z"/>
        <path style="fill:#FFFFFF" d="M2063.7,811.1c-6.4,0-11.5,5.2-11.5,11.5c0,6.3,5.1,11.5,11.5,11.5s11.5-5.2,11.5-11.5
        C2075.2,816.3,2070,811.1,2063.7,811.1z"/>
        <path style="fill:#FFFFFF" d="M336.4,811.1c-6.3,0-11.5,5.2-11.5,11.5c0,6.3,5.2,11.5,11.5,11.5c6.4,0,11.5-5.2,11.5-11.5
        C348,816.3,342.8,811.1,336.4,811.1z"/>
    </g>
</svg>
</template>

<script>
export default {
    data()
    {
        return {
        }
    },

    methods:
    {
    }
}
</script>
