<template>
	<div>
		<!-- <pre>{{bonus.payments_rules}}</pre> -->
		<!-- class="flex-column align-items-start" -->

		<h5>Pagamenti</h5>
		<b-list-group>
			<b-list-group-item v-for="payment, k in bonus.paymentsRulesJson">
				<b-row>
					<b-col cols="1" class="text-center align-items-center">
						<div class="lead">#{{ k+1 }}</div>
						<div class="text-success" v-if="mismatch || bonus.paymentsRulesJson.length">{{ percentuale(payment.amount) }}%</div>
					</b-col>
					<b-col cols="11">

						<b-form-group label="Pagamento">
							<b-form-select v-model="payment.type">
								<b-form-select-option value="month">Con una Mensilità</b-form-select-option>
								<b-form-select-option value="date">Data Specifica</b-form-select-option>
								<b-form-select-option value="none">Nessuna Scelta</b-form-select-option>
							</b-form-select>
						</b-form-group>

						<div v-if="payment.type === 'month'">
							<label><b-checkbox switch v-model="payment.onComplete">Mensilità di Maturazione</b-checkbox></label>

							<div v-if="!payment.onComplete">
								<b-form-select v-model="payment.date">
									<b-form-select-option v-for="mese in scelteMesi" :value="mese.value">{{ mese.label }}</b-form-select-option>
								</b-form-select>
							</div>

						</div>
						<div v-if="payment.type === 'date'">
							
							<b-form-group label="Data Specifica">
								<b-form-datepicker v-model="payment.date"></b-form-datepicker>
							</b-form-group>

						</div>

						<b-form-group label="Importo">
							<b-row>
								<b-col>
									<b-input type="number" v-model.number="payment.amount"></b-input>
								</b-col>
								<b-col class="text-center" cols="3">
									<div>
										<a @click.prevent="setAmount(payment, parseFloat(bonus.importo)/1)" href="">1</a> -
										<a @click.prevent="setAmount(payment, parseFloat(bonus.importo)/2)" href="">1/2</a> -
										<a @click.prevent="setAmount(payment, parseFloat(bonus.importo)/3)" href="">1/3</a>
									</div>
									<div><a href="javascript:;" @click.prevent="bonus.paymentsRulesJson.splice(k, 1)">Rimuovi</a></div>
								</b-col>
							</b-row>
						</b-form-group>

					</b-col>
				</b-row>
			</b-list-group-item>
		</b-list-group>

		<b-row class="mt-2">
			<b-col>
				<b-button-group>
					<b-button v-if="mismatch" size="xs" @click.prenvet="makePayment(mismatch)">Aggiungi Pagamento Rimanente</b-button>
				</b-button-group>
			</b-col>
			<b-col class="text-right">
				<b-button size="xs" @click.prevent="makePayment">Aggiungi Pagamento</b-button>
			</b-col>
		</b-row>

		<b-alert class="mt-2" v-if="mismatch" :variant="mismatch < 0 ? 'danger' : 'warning'" show>
			
			<span v-if="mismatch > 0">Ci sono ancora € <strong>{{mismatch|money}}</strong> da associare ad un pagamento</span>
			<span v-if="mismatch < 0">Ci sono € <strong>{{-1*mismatch|money}}</strong> in eccesso</span>

		</b-alert>

	</div>
</template>


<script>

import _ from 'lodash'
import moment from 'moment'

export default {
	props: [ 'bonus', 'periodo' ],
	filters: {
		money: function(v) {
			var formatter = new Intl.NumberFormat('it-IT', {
			  style: 'currency',
			  currency: 'EUR',
			});
			return formatter.format(v).replace(' €', '');
		}
	},
	computed: {
		mismatch: function() {
			return parseFloat(this.bonus.importo) - _.sumBy(this.bonus.paymentsRulesJson, 'amount');
		},
		scelteMesi: function() {

			var scelte = [];
			var d = moment(this.periodo.inizio);
			var go = true;

			while(go) {

				scelte.push({ label: d.format('MMMM YYYY'), value: d.format('YYYY-MM-DD') });
				d.add(1, 'month');

				if(d.isAfter(moment(this.periodo.fine)))
					go = false;
			}

			return scelte;

		}
	},
	methods: {
		makePayment: function(amount = 0) {

			this.bonus.paymentsRulesJson.push(
				{ 
					type: 'none',
					date: null,
					none: true,
					onComplete: false,
					amount: _.round(amount,2)
				}
			);

		},
		setAmount: function(where, amount) {
			where.amount = _.round(amount, 2);
		},
		percentuale: function(amount) {
			return _.round(amount/parseFloat(this.bonus.importo)*100, 2);
		}
	},
	watch: {
		'bonus.importo': {
			handler: function() {
				if(this.bonus.paymentsRulesJson.length == 1) {
					this.bonus.paymentsRulesJson[0].amount = parseFloat(this.bonus.importo)
				}
			}
		},
		bonus: {
			immediate: true,
			handler: function() {
				if(this.bonus.paymentsRulesJson == null) {
					this.bonus.paymentsRulesJson = [
						{ 
							type: 'none',
							date: null,
							none: true,
							onComplete: false,
							amount: _.round(parseFloat(this.bonus.importo),2)
						}
					];
				}
			}
		}
	}
	// components: {
	// 	RuleConfig: require('./RegolaConfiguratore.vue').default
	// },
	// data: function() {
	// 	return {
	// 		ux: {
	// 			show: false
	// 		},
	// 		ruleInfo: false
	// 	}
	// },
	// created: function() {
	// 	if(this.compact == true)
	// 		this.ux.show = true;
	// },
	// watch: {
	// 	'rule.regola': {
	// 		deep: true,
	// 		immediate: true,
	// 		handler: function() {
	// 			this.getRuleInfo();
	// 		}
	// 	}
	// },
	// methods: {
	// 	removeRule: function() {
	// 		var confirm = window.confirm("Confermi la rimozione della regola?");
	// 		if(confirm) {
	// 			this.$emit('removerule');
	// 		}
	// 	},
	// 	getRuleInfo: function() {
	// 		this.$http.get(this.$store.state.apiEndPoint + '/contratti/rule/' + this.rule.regola).then(function(data) {
	// 			console.log(data);
	// 			this.ruleInfo = data.data;
	// 		});
	// 	}
	// }
};

</script>