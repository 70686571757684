<template>
	<div>
		<b-row>
			<b-col>
				<b-card :key="random">
					<E-Chart style="width: 100%; height: 800px" :option="graphOption"></E-Chart>
					<b-row v-if="level == 'player'"><b-col class="text-center font-weight-bold">Deceleration</b-col><b-col class="text-center font-weight-bold">Acceleration</b-col></b-row>
				</b-card>
			</b-col>
			<b-col cols="2" class="pl-3">
				<b-form-group label="Visualizza">
					<b-form-radio-group size="sm" v-model="show"><b-form-radio value="media">Medie</b-form-radio><b-form-radio value="totale">Totali</b-form-radio></b-form-radio-group>
				</b-form-group>
				<b-form-group label="Livello di dettaglio">
					<b-form-radio-group size="sm" v-model="level"><b-form-radio value="team">Team</b-form-radio><b-form-radio value="player" :disabled="filters.players.length == 0">Giocatore</b-form-radio></b-form-radio-group>
				</b-form-group>
				<b-form-group label="Medie" v-if="level == 'player'">
					<b-form-checkbox-group size="sm" v-model="medie"><b-form-checkbox value="team">Media Team</b-form-checkbox><b-form-checkbox value="selected">Media Giocatori Selezionati</b-form-checkbox></b-form-checkbox-group>
				</b-form-group>
				<b-form-group label="Tipologia di dati">
					<b-form-radio-group size="sm" v-model="type"><b-form-radio value="effort_count">Occorrenze</b-form-radio><b-form-radio value="distance">Distanze (m)</b-form-radio><b-form-radio value="duration">Durate</b-form-radio>
					</b-form-radio-group>
				</b-form-group>
				<b-form-group label="Unità di misura" v-if="type == 'duration'">
					<b-form-radio-group size="sm" v-model="misura"><b-form-radio value="secondi">Secondi</b-form-radio><b-form-radio value="minuti">Minuti</b-form-radio></b-form-radio-group>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>

function isValidNumber(a) {
	if(!_.isNumber(a) || _.isNaN(a) || a == 0 || !_.isFinite(a)) return false;
	return true;
}

function buildNeurom(dati, type, show, misura) {
	var num_acc = 0;
	var acc1 = 0;
	var acc2 = 0;
	var acc3 = 0;
	var num_dec = 0;
	var dec3 = 0;
	var dec2 = 0;
	var dec1 = 0;
	if(type == 'effort_count') {
		num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count')
			]);
		acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_effort_count');
		acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_effort_count');
		acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_effort_count');
		num_dec = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count')
			]);
		dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_effort_count');
		dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_effort_count');
		dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_effort_count');
	} else if(type == 'distance') {
		num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_distance'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_distance'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_distance')
			]);
		acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_distance');
		acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_distance');
		acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_distance');
		num_dec = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_distance'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_distance'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_distance')
			]);
		dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_distance');
		dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_distance');
		dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_distance');
	} else if(type == 'duration') {
		num_acc = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band6_total_duration'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band7_total_duration'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band8_total_duration')
			]);
		acc1 = _.sumBy(dati, 'catapult_gen2_acceleration_band6_total_duration');
		acc2 = _.sumBy(dati, 'catapult_gen2_acceleration_band7_total_duration');
		acc3 = _.sumBy(dati, 'catapult_gen2_acceleration_band8_total_duration');
		num_dec = _.sum([
			_.sumBy(dati, 'catapult_gen2_acceleration_band1_total_duration'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band2_total_duration'),
			_.sumBy(dati, 'catapult_gen2_acceleration_band3_total_duration')
			]);
		dec3 = _.sumBy(dati, 'catapult_gen2_acceleration_band1_total_duration');
		dec2 = _.sumBy(dati, 'catapult_gen2_acceleration_band2_total_duration');
		dec1 = _.sumBy(dati, 'catapult_gen2_acceleration_band3_total_duration');
		if(misura == 'minuti') {
			num_acc = num_acc/60;
			acc1 = acc1/60;
			acc2 = acc2/60;
			acc3 = acc3/60;
			num_dec = num_dec/60;
			dec3 = dec3/60;
			dec2 = dec2/60;
			dec1 = dec1/60;
		}
	}
	return {
		acc: isValidNumber(num_acc) ? (show == 'totale' ? _.round(num_acc) : num_acc) : 0,
		acc1: isValidNumber(acc1) ? (show == 'totale' ? _.round(acc1) : acc1) : 0,
		acc2: isValidNumber(acc2) ? (show == 'totale' ? _.round(acc2) : acc2) : 0,
		acc3: isValidNumber(acc3) ? (show == 'totale' ? _.round(acc3) : acc3) : 0,
		dec: isValidNumber(num_dec) ? (show == 'totale' ? _.round(num_dec) : num_dec) : 0,
		dec3: isValidNumber(dec3) ? (show == 'totale' ? _.round(dec3) : dec3) : 0,
		dec2: isValidNumber(dec2) ? (show == 'totale' ? _.round(dec2) : dec2) : 0,
		dec1: isValidNumber(dec1) ? (show == 'totale' ? _.round(dec1) : dec1) : 0,
	}
}

import eChart from '@/components/layout/E-Chart/Base.vue';
export default {
	props: [ 'dataset', 'filters', 'results', 'datasetNopeople', 'days' ],
	components: {
		'E-Chart': eChart,
	},
	data: function() {
		return {
			kpi_keys: [
				{key: 'dec', label: 'Dec', stacked: 'Tot' },
				{key: 'dec1', label: 'Dec 1', stacked: '3' },
				{key: 'dec2', label: 'Dec 2', stacked: '2' },
				{key: 'dec3', label: 'Dec 3', stacked: '1' },
				{key: 'acc', label: 'Acc', stacked: 'Tot' },
				{key: 'acc1', label: 'Acc 1', stacked: '1' },
				{key: 'acc2', label: 'Acc 2', stacked: '2' },
				{key: 'acc3', label: 'Acc 3', stacked: '3' },
				],
			labels: [
				['Dec 3', 'Dec 2', 'Dec 1', 'Dec'],
				['Acc 3', 'Acc 2', 'Acc 1', 'Acc']
				],
			show: 'media',
			type: 'effort_count',
			misura: 'secondi',
			level: 'team',
			medie: [],
			random: Math.random(),
		}
	},
	watch: {
		'filters.players': function() {
			if(this.filters.players.length == 0) {
				this.level = 'team';
			}
		},
		level: function() {
			this.random = Math.random();
		}, 
		dataset: function() {
			this.random = Math.random();
		},
		type: function() {
			this.random = Math.random();
		},
		show: function() {
			this.random = Math.random();
		},
		medie: function() {
			this.random = Math.random();
		},
	},
	computed: {
		theDataset: function() {
			var dataset = this.dataset;
			if(this.show == 'media') {
				var grouped_by_date = _.groupBy(dataset, 'date');
				var data = _.map(grouped_by_date, function(dati) {

					var grouped_by_person = _.groupBy(dati, 'persona_id');

					var data_person = _.map(grouped_by_person, function(dati_persona) {
						return buildNeurom(dati_persona, this.type, this.show, this.misura);
					}.bind(this));

					var medie = {
						acc: _.size(data_person.filter(item => item['acc'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc') / _.size(data_person.filter(item => item['acc'] != 0)), 0) : 0,
						acc1: _.size(data_person.filter(item => item['acc1'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc1') / _.size(data_person.filter(item => item['acc1'] != 0)), 0) : 0,
						acc2: _.size(data_person.filter(item => item['acc2'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc2') / _.size(data_person.filter(item => item['acc2'] != 0)), 0) : 0,
						acc3: _.size(data_person.filter(item => item['acc3'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc3') / _.size(data_person.filter(item => item['acc3'] != 0)), 0) : 0,
						dec: _.size(data_person.filter(item => item['dec'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec') / _.size(data_person.filter(item => item['dec'] != 0)), 0) : 0,
						dec3: _.size(data_person.filter(item => item['dec3'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec3') / _.size(data_person.filter(item => item['dec3'] != 0)), 0) : 0,
						dec2: _.size(data_person.filter(item => item['dec2'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec2') / _.size(data_person.filter(item => item['dec2'] != 0)), 0) : 0,
						dec1: _.size(data_person.filter(item => item['dec1'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec1') / _.size(data_person.filter(item => item['dec1'] != 0)), 0) : 0,
					}
					return medie;

				}.bind(this));

				return {
					acc: _.size(data.filter(item => item['acc'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc') / _.size(data.filter(item => item['acc'] != 0)), 0) : 0,
					acc1: _.size(data.filter(item => item['acc1'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc1') / _.size(data.filter(item => item['acc1'] != 0)), 0) : 0,
					acc2: _.size(data.filter(item => item['acc2'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc2') / _.size(data.filter(item => item['acc2'] != 0)), 0) : 0,
					acc3: _.size(data.filter(item => item['acc3'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc3') / _.size(data.filter(item => item['acc3'] != 0)), 0) : 0,
					dec: _.size(data.filter(item => item['dec'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec') / _.size(data.filter(item => item['dec'] != 0)), 0) : 0,
					dec3: _.size(data.filter(item => item['dec3'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec3') / _.size(data.filter(item => item['dec3'] != 0)), 0) : 0,
					dec2: _.size(data.filter(item => item['dec2'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec2') / _.size(data.filter(item => item['dec2'] != 0)), 0) : 0,
					dec1: _.size(data.filter(item => item['dec1'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec1') / _.size(data.filter(item => item['dec1'] != 0)), 0) : 0,
				}
			} else {
				return buildNeurom(dataset, this.type, this.show, this.misura);
			}
		},
		theFullDataset: function() {
			var dataset = this.datasetNopeople;
			if(this.show == 'media') {
				var grouped_by_date = _.groupBy(dataset, 'date');
				var data = _.map(grouped_by_date, function(dati) {

					var grouped_by_person = _.groupBy(dati, 'persona_id');

					var data_person = _.map(grouped_by_person, function(dati_persona) {
						return buildNeurom(dati_persona, this.type, this.show, this.misura);
					}.bind(this));

					var medie = {
						acc: _.size(data_person.filter(item => item['acc'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc') / _.size(data_person.filter(item => item['acc'] != 0)), 0) : 0,
						acc1: _.size(data_person.filter(item => item['acc1'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc1') / _.size(data_person.filter(item => item['acc1'] != 0)), 0) : 0,
						acc2: _.size(data_person.filter(item => item['acc2'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc2') / _.size(data_person.filter(item => item['acc2'] != 0)), 0) : 0,
						acc3: _.size(data_person.filter(item => item['acc3'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'acc3') / _.size(data_person.filter(item => item['acc3'] != 0)), 0) : 0,
						dec: _.size(data_person.filter(item => item['dec'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec') / _.size(data_person.filter(item => item['dec'] != 0)), 0) : 0,
						dec3: _.size(data_person.filter(item => item['dec3'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec3') / _.size(data_person.filter(item => item['dec3'] != 0)), 0) : 0,
						dec2: _.size(data_person.filter(item => item['dec2'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec2') / _.size(data_person.filter(item => item['dec2'] != 0)), 0) : 0,
						dec1: _.size(data_person.filter(item => item['dec1'] != 0)) > 0 ? _.round(_.sumBy(data_person, 'dec1') / _.size(data_person.filter(item => item['dec1'] != 0)), 0) : 0,
					}
					return medie;

				}.bind(this));

				return {
					acc: _.size(data.filter(item => item['acc'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc') / _.size(data.filter(item => item['acc'] != 0)), 0) : 0,
					acc1: _.size(data.filter(item => item['acc1'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc1') / _.size(data.filter(item => item['acc1'] != 0)), 0) : 0,
					acc2: _.size(data.filter(item => item['acc2'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc2') / _.size(data.filter(item => item['acc2'] != 0)), 0) : 0,
					acc3: _.size(data.filter(item => item['acc3'] != 0)) > 0 ? _.round(_.sumBy(data, 'acc3') / _.size(data.filter(item => item['acc3'] != 0)), 0) : 0,
					dec: _.size(data.filter(item => item['dec'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec') / _.size(data.filter(item => item['dec'] != 0)), 0) : 0,
					dec3: _.size(data.filter(item => item['dec3'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec3') / _.size(data.filter(item => item['dec3'] != 0)), 0) : 0,
					dec2: _.size(data.filter(item => item['dec2'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec2') / _.size(data.filter(item => item['dec2'] != 0)), 0) : 0,
					dec1: _.size(data.filter(item => item['dec1'] != 0)) > 0 ? _.round(_.sumBy(data, 'dec1') / _.size(data.filter(item => item['dec1'] != 0)), 0) : 0,
				}
			} else {
				return buildNeurom(dataset, this.type, this.show, this.misura);
			}
		},
		theDatasetByPerson: function() {
			var dataset = this.dataset;
			dataset = _.groupBy(dataset, 'persona_id');

			if(this.show == 'media') {
				
				var data = _.mapValues(dataset, function(dati) {

					var grouped_by_date = _.groupBy(dati, 'date');

					var data_person = _.mapValues(grouped_by_date, function(dati_persona) {
						return buildNeurom(dati_persona, this.type, this.show, this.misura);
					}.bind(this));
					var medie = {
						acc: _.size(_.values(data_person).filter(item => item['acc'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'acc') / _.size(_.values(data_person).filter(item => item['acc'] != 0)), 0) : 0,
						acc1: _.size(_.values(data_person).filter(item => item['acc1'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'acc1') / _.size(_.values(data_person).filter(item => item['acc1'] != 0)), 0) : 0,
						acc2: _.size(_.values(data_person).filter(item => item['acc2'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'acc2') / _.size(_.values(data_person).filter(item => item['acc2'] != 0)), 0) : 0,
						acc3: _.size(_.values(data_person).filter(item => item['acc3'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'acc3') / _.size(_.values(data_person).filter(item => item['acc3'] != 0)), 0) : 0,
						dec: _.size(_.values(data_person).filter(item => item['dec'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'dec') / _.size(_.values(data_person).filter(item => item['dec'] != 0)), 0) : 0,
						dec3: _.size(_.values(data_person).filter(item => item['dec3'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'dec3') / _.size(_.values(data_person).filter(item => item['dec3'] != 0)), 0) : 0,
						dec2: _.size(_.values(data_person).filter(item => item['dec2'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'dec2') / _.size(_.values(data_person).filter(item => item['dec2'] != 0)), 0) : 0,
						dec1: _.size(_.values(data_person).filter(item => item['dec1'] != 0)) > 0 ? _.round(_.sumBy(_.values(data_person), 'dec1') / _.size(_.values(data_person).filter(item => item['dec1'] != 0)), 0) : 0,
					}
					return medie;

				}.bind(this));

				return data;
			} else {
				return _.mapValues(dataset, function(a) {
					return buildNeurom(a, this.type, this.show, this.misura);
				}.bind(this));
			}
		},

		graphOption: function() {
			var series = [];
			var legend = [];

			var colors = [
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3',
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3'
				];

			var max = 0;

			if(this.level == 'team') {
				var serie1 = {
					data: _.reverse([this.theDataset.dec * -1, this.theDataset.dec1 * -1, this.theDataset.dec2 * -1, this.theDataset.dec3 * -1]),
					type: 'bar',
					colorBy: 'data',
					stack: 'Total',
					name: 'Deceleration',
					label: {
						show: true
					},
					emphasis: {
						focus: 'series'
					},
				};
				series.push(serie1);	
				var serie2 = {
					data: _.reverse([this.theDataset.acc, this.theDataset.acc1, this.theDataset.acc2, this.theDataset.acc3]),
					type: 'bar',
					colorBy: 'data',
					name: 'Acceleration',
					stack: 'Total',
					label: {
						show: true
					},
					emphasis: {
						focus: 'series'
					},
				};	
				series.push(serie2);
				max = _.max([this.theDataset.acc, this.theDataset.dec]);
			} else {
				var index = 0;
				_.forEach(this.theDatasetByPerson, function(dsp, person_id) {
					var label = this.getPerson(person_id).cognome;
					var serie1 = {
						id: 'dec_' + person_id,
						data: _.reverse([dsp.dec * -1, dsp.dec1 * -1, dsp.dec2 * -1, dsp.dec3 * -1]),
						type: 'bar',
						name: label,
						stack: 'Total_' + person_id,
						label: {
							show: true,
						},
						emphasis: {
							focus: 'series'
						},
						itemStyle: {
							color: colors[index],
						},
						z: 10,
					};
					if(index == 0 && this.medie.length > 0) {
						serie1.markLine = {
							symbol: 'none',
							silent: true,
							data: []
						}
						if(this.medie.includes('selected')) {
							var dec = this.theDataset.dec;
							if(this.show == 'totale') {
								dec = _.round(dec / this.days.length);
							}
							serie1.markLine.data.push({ xAxis:  dec * -1, label: {position: 'insideStartTop', formatter: 'Avg Dec: {c}'}, lineStyle: {color: '#000000'}});
						//	serie1.markLine.data.push({ xAxis:  this.theDataset.dec1 * -1, label: {position: 'insideStartTop', formatter: 'Avg Dec 1: {c}'}, lineStyle: {color: '#000000'}});
						//	serie1.markLine.data.push({ xAxis:  this.theDataset.dec2 * -1, label: {position: 'insideStartTop', formatter: 'Avg Dec 2: {c}'}, lineStyle: {color: '#000000'}});
						//	serie1.markLine.data.push({ xAxis:  this.theDataset.dec3 * -1, label: {position: 'insideStartTop', formatter: 'Avg Dec 3: {c}'}, lineStyle: {color: '#000000'}});
							if(dec > max) {
								max = dec;
							}
						}
						if(this.medie.includes('team')) {
							var dec = this.theFullDataset.dec;
							if(this.show == 'totale') {
								dec = _.round(dec / this.days.length);
							}
							serie1.markLine.data.push({ xAxis: dec * -1, label: {position: 'insideMiddleTop', formatter: 'Avg Team Dec: {c}'}, lineStyle: {color: '#FF0000'}});
						//	serie1.markLine.data.push({ xAxis:  this.theFullDataset.dec1 * -1, label: {position: 'insideMiddleTop', formatter: 'Avg Team Dec 1: {c}'}, lineStyle: {color: '#FF0000'}});
						//	serie1.markLine.data.push({ xAxis:  this.theFullDataset.dec2 * -1, label: {position: 'insideMiddleTop', formatter: 'Avg Team Dec 2: {c}'}, lineStyle: {color: '#FF0000'}});
						//	serie1.markLine.data.push({ xAxis:  this.theFullDataset.dec3 * -1, label: {position: 'insideMiddleTop', formatter: 'Avg Team Dec 3: {c}'}, lineStyle: {color: '#FF0000'}});
							if(dec > max) {
								max = dec;
							}
						}
					}
					series.push(serie1);
					var serie2 = {
						id: 'acc_' + person_id,
						data: _.reverse([dsp.acc, dsp.acc1, dsp.acc2, dsp.acc3]),
						type: 'bar',
						name: label,
						stack: 'Total_' + person_id,
						label: {
							show: true,
						},
						emphasis: {
							focus: 'series'
						},
						itemStyle: {
							color: colors[index],
						},
						z: 10,
					};
					if(index == 0 && this.medie.length > 0) {
						serie2.markLine = {
							symbol: 'none',
							silent: true,
							data: []
						}
						if(this.medie.includes('selected')) {
							var acc = this.theDataset.acc;
							if(this.show == 'totale') {
								acc = _.round(acc / this.days.length);
							}
							serie2.markLine.data.push({ xAxis:  acc, label: {position: 'insideStartTop', formatter: 'Avg Acc: {c}'}, lineStyle: {color: '#000000'}});
						//	serie2.markLine.data.push({ xAxis:  this.theDataset.acc1, label: {position: 'insideStartTop', formatter: 'Avg Acc 1: {c}'}, lineStyle: {color: '#000000'}});
						//	serie2.markLine.data.push({ xAxis:  this.theDataset.acc2, label: {position: 'insideStartTop', formatter: 'Avg Acc 2: {c}'}, lineStyle: {color: '#000000'}});
						//	serie2.markLine.data.push({ xAxis:  this.theDataset.acc3, label: {position: 'insideStartTop', formatter: 'Avg Acc 3: {c}'}, lineStyle: {color: '#000000'}});
							if(acc > max) {
								max = acc;
							}
						}
						if(this.medie.includes('team')) {
							var acc = this.theFullDataset.acc;
							if(this.show == 'totale') {
								acc = _.round(acc / this.days.length);
							}
							serie2.markLine.data.push({ xAxis:  acc, label: {position: 'insideMiddleTop', formatter: 'Avg Team Acc: {c}'}, lineStyle: {color: '#FF0000'}});
						//	serie2.markLine.data.push({ xAxis:  this.theFullDataset.acc1, label: {position: 'insideMiddleTop', formatter: 'Avg Team Acc 1: {c}'}, lineStyle: {color: '#FF0000'}});
						//	serie2.markLine.data.push({ xAxis:  this.theFullDataset.acc2, label: {position: 'insideMiddleTop', formatter: 'Avg Team Acc 2: {c}'}, lineStyle: {color: '#FF0000'}});
						//	serie2.markLine.data.push({ xAxis:  this.theFullDataset.acc3, label: {position: 'insideMiddleTop', formatter: 'Avg Team Acc 3: {c}'}, lineStyle: {color: '#FF0000'}});
							if(acc > max) {
								max = acc;
							}
						}
					}
					series.push(serie2);
					legend.push(label);
					index++;

					if(dsp.acc > max) {
						max = dsp.acc;
					}
					if(dsp.dec > max) {
						max = dsp.dec;
					}
				}.bind(this));
			}

			var options = {
				yAxis: {
					type: 'category',
					data: _.reverse(['Tot', 'Band 1', 'Band 2', 'Band 3']),
					axisTick: {
						show: false
					},
				},
				xAxis: {
					type: 'value',
					max: max,
					min: max * -1
				},
				series: series,
				tooltip: {
					trigger: 'item',
					formatter: function (params) {
						return this.labels[params.seriesIndex][params.dataIndex] + ': <strong>' + params.value + '</strong>';
					}.bind(this)
				},
			}

			if(this.level == 'team') {
				options.legend = {
					data: [ 'Deceleration', 'Acceleration'],
					icon: 'none',
					itemGap: 400,
					textStyle: {
						fontWeight: 'bold',
						fontSize: 16
					}
				}
				options.tooltip = {
					trigger: 'item',
					formatter: function (params) {
						return this.labels[params.seriesIndex][params.dataIndex] + ': <strong>' + params.value + '</strong>';
					}.bind(this)
				}
			} else {
				options.legend = {
					data: legend,
				};
				options.tooltip = {
					trigger: 'item',
					formatter: function (params) {
						return `${params.seriesName}<br>${params.marker}${this.labels[(params.seriesId.includes('dec_') ? 0 : 1)][params.dataIndex]}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span>`;
					}.bind(this)
				};
			}

			return options;

		}
	},

	methods: {
		getPerson(person_id) {
			return this.results.raw_persone.find(item => item.id == person_id);
		}
	}
}

</script>