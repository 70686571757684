<template id="rosa">
	<section class="rosa">
		<headapp></headapp>
		<div class="card">
			<div class="card-body">
				<div>
					<b-row>
						<b-col v-if="ux.showType == 'table'" class="card-title">
							Raccolta Dati del {{ux.stats.from | date }}
						</b-col>
						<b-col class="card-title" v-else>
							<span>Medical ({{ players.length }})</span>
						</b-col>
						<b-col class="text-right">
							<div>
								<a :href="exportUrl" target="_blank" class="btn btn-primary btn-sm">Esporta</a>
								<b-button size="sm" variant="primary" @click.prevent="showSoglie">Configura soglie <i class="fa fa-signal" aria-hidden="true"></i></b-button>
								<button class="btn btn-link" @click.prevent="ux.showType = 'card'" v-b-tooltip.hover title="Vista Griglia" placement="bottom"><i class="fa fa-th"></i></button>
								<button class="btn btn-link" @click.prevent="ux.showType = 'table'" v-b-tooltip.hover title="Vista Elenco" placement="bottom"><i class="fa fa-bars"></i></button>
							</div>
						</b-col>
					</b-row>
					<div v-if="ux.showType == 'table'" class="mb-2">
						<b-row>
							<b-col cols="2">
								<label>Periodo:</label>
								<b-form-input v-model="ux.stats.from" @change="ux.stats.to = ux.stats.from" type="date"></b-form-input>
								<b-form-input v-show="false" v-model="ux.stats.to" type="date"></b-form-input>
							</b-col>
							<b-col>
								<label>Raccolte Dati: <strong v-if="ux.stats.fase_form.length == 0">Tutte le tipologie</strong></label>
								<multiselect v-model="ux.stats.fase_form" :options="glossarioTipiDato" :multiple="true" placeholder="Seleziona"></multiselect>
							</b-col>
							<b-col>
								<label>Tipi di Sessione: <strong v-if="ux.stats.fase_form_sessione.length == 0">Tutte le tipologie</strong></label>
								<multiselect v-model="ux.stats.fase_form_sessione" :options="['Fisioterapia', 'Nutrition', 'Farmaco', 'CheckIn', 'Psicologica', 'Pesa Giornaliera', 'Dexa', 'RTP', 'Standard', 'Palestra']" :multiple="true" placeholder="Seleziona"></multiselect>
							</b-col>
						</b-row>
					</div>
				</div>
				<div class="content">
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					

					<!-- Vista a Card -->
					<div class="row" v-if="ux.showType == 'card'">
						<div v-for="giocatore in players" :key="giocatore.id" class="col-2">
							<div class="figurinagiocatore" :id="'giocatore_'+giocatore.id">
								<div class="card mb-2">
									<div class="row">
										<div class="col-12 text-center">
											<router-link :to="{ name: 'medical_giocatore_scheda', params: { id: giocatore.id }}"><img v-bind:src="giocatore.avatar" height="200"></router-link>
											<i class="fa fa-ambulance" v-if="giocatore.injured" title="Infortunato" style="position:absolute;top:10px;right:20px;"></i>
										</div>
									</div>						
									<div class="card-body">
										<div class="font-bold text-center">{{ giocatore.name }}</div>
										<div class="mb-1 text-center font-size-xs color-rosso">{{ giocatore.ruolo != '' ? giocatore.ruolo : '&nbsp;' }}</div>
										<div class="row no-gutters text-center font-size-sm">
											<div class="col" title="Infortuni"><i class="fa fa-h-square"></i><br> {{ giocatore.injuries }}</div>
											<div class="col" title="Malattie"><i class="fa fa-medkit"></i><br>{{ giocatore.illnesses }}</div>
											<div class="col" title="Test"><i class="fa fa-heartbeat"></i><br> {{ giocatore.testrtp }}</div>
										</div>   
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- /Vista a Card -->

					<!-- vista a tabella -->
					<persone-sessioni @refresh="getPlayers" v-else-if="ux.showType == 'table' && loading == false && players.length > 0" :config="ux.stats" :persone="players"></persone-sessioni>
					<!-- /vista a tabella -->

				</div>
			</div>
		</div>
		<ModalSoglia ref="sogliaModal" :gruppi="['Medical']" @reloadData="getPlayers"></ModalSoglia>
		<footerapp></footerapp>
	</section>
</template>

<script>
var Auth = require('../../../auth.js');
import axios from 'axios';
import moment from 'moment';
import * as types from '../../../store/types';
import { mapState } from 'vuex';
export default {
	data: function(){
		return {
			ux: {
				showType: 'table',
				stats: null
			},
			loading: false,
			loadingPlayers: false,
			errors: [],
			render_player: true,
			players: [],
			date_selected: moment().format('YYYY-MM-DD'),

			glossarioTipiDato: [
				'Fase_Dato_Vas',
				'Fase_Dato_Rpe',
				'Fase_Dato_Tqr',
				'Fase_Dato_Peso',
				'Fase_Dato_Presenza',
				'Fase_Dato_Durata',
				'Fase_Dato_Pom',
				'Fase_Dato_Sleep_Quality'
			]

		}
	},
	computed: {
		exportUrl: function() {
			var pl = { 
				team_id: this.rosaTeamId, 
				season_id: this.seasonId,
				stats: this.ux.stats
			};
			return this.$store.state.apiEndPoint + '/medical/players-export?q=' + encodeURIComponent(btoa(JSON.stringify(pl)));
		},
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId', 'seasonId']),
	},
	watch: {
		'$route.query.search': {
			deep: true,
			handler: function(a,b) {

				if(a == b) return false;

				if(this.$route.query.search) {

					// var unnav = window.atob(this.$route.query.search);
					var unnav = this.$route.query.search;
					var obj = JSON.parse(unnav);

					this.ux.stats = obj;

				}

			},
			immediate: true
		},
		'ux.showType': {
			deep: true,
			immediate: true,
			handler: function() {

				if(this.ux.showType == 'table') {
					if(this.ux.stats == null) {
						this.ux.stats = {
							from: moment().format('YYYY-MM-DD'),
							to: moment().format('YYYY-MM-DD'),
							fase_form: [ 'Fase_Dato_Presenza', 'Fase_Dato_Tqr', 'Fase_Dato_Vas' ],
							fase_form_sessione: [ 'CheckIn', 'Fisioterapia' ]
						}
					}
				} else
					this.ux.stats = null;

				//this.getPlayers();
			}
		},
		rosaTeamId() {
			this.getPlayers();
		},
		seasonId() {
			this.getPlayers();
		},
		date_selected() {
			this.getPlayers();
		},
		'ux.stats': {
			deep: true,
			handler: function() {

				var nav = JSON.stringify(this.ux.stats);
				// var nav = window.btoa(JSON.stringify(this.ux.stats));
				this.$router.push({ query: { search: nav }});
				//this.$router.replace({ query: { search: nav }});
				this.getPlayers();

			}
		}
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		PersoneSessioni:   	require('@/components/layout/Sessioni/PersoneSessioni.vue').default,
		ModalSoglia:    require('@/components/layout/Medical/ModalSoglia.vue').default,
	},
	created: function () {
		this.getPlayers();
	},
	methods: {
		showSoglie() {
			this.$refs.sogliaModal.show();
		},

		getPlayers: _.debounce(function() {

			this.players = [];
			this.loading = true;
			this.$http.post(
				this.$store.state.apiEndPoint + '/medical/players', { 
					team_id: this.rosaTeamId, 
					season_id: this.seasonId,
					stats: this.ux.stats
				}).then((response) => {
					if (response.ok)
					{
						if(response.data.players) {
							this.players = response.data.players.map(item => ({								
								name: item.cognome + ' ' + item.nome,
								name_table: item.cognome + ' ' + item.nome[0] + '.',
								id: item.id,
								ruolo: ( item.last_carriera_sportiva && item.last_carriera_sportiva.ruolo_ideale.length > 0 ) ? item.last_carriera_sportiva.ruolo_ideale[0].nome : '',
								avatar: item.avatar_url,
								injuries: item.injuries_count,
								illnesses: item.illnesses_count,
								testrtp: item.test_count,
								stats: item.stats,
								injured: item.injured
							}));
						}
						this.loading = false;
					}
				}, (response) => {
				});
			}, 100)
		},
		filters: {
			date: function(i) {
				return moment(i).format('DD/MM/YYYY');
			}
		}
	}
	</script>