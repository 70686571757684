<template>
	<div>
		{{ test[field].length > 0 ? '✔︎' : '' }}
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	computed: {
	}
}
	
</script>