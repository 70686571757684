<template>
  <b-col :cols="fullScreen ? '12' : '8'" :class="[fullScreen ? 'full-screen' : '', 'video-wrapper']">
    <b-row class="video-row">
      <b-col>
        <video
          id="video-wrapper"
          class="video-js vjs-default-skin"
          controls
          :data-setup="setup">
        </video>
      </b-col>
    </b-row>

    <b-row v-if="!fullScreen" class="mt-1 playback-row">
      <b-col cols="1">
        <b-button
          variant="primary"
          class="w-100 playback-light"
          @click="rewind">-10 sec</b-button>
      </b-col>

      <b-col cols="1">
        <b-button
          :pressed="currentPlaybackRate === 0.5"
          variant="primary"
          class="w-100 playback-light"
          @click="changePlaybackRate(0.5)">0.5x</b-button>
      </b-col>

      <b-col>
        <b-button
          :pressed="currentPlaybackRate === 1"
          variant="primary"
          class="w-100 playback-light"
          @click="changePlaybackRate(1)">{{ 1 }}x</b-button>
      </b-col>

      <b-col>
        <b-button
          :variant="isRecording && 'active' ? 'success' : 'primary'"
          :class="[isRecording && 'active', 'w-100 rec-button']"
          @click="toggleRecording">
          <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
        </b-button>
      </b-col>

      <b-col v-for="playbackRate in [1.5, 2]" :key="`rate-${playbackRate}`">
        <b-button
          :pressed="currentPlaybackRate === playbackRate"
          variant="primary"
          class="w-100 playback-dark"
          @click="changePlaybackRate(playbackRate)">{{ playbackRate }}x</b-button>
      </b-col>
    </b-row>

    <slot></slot>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';
import { isPlainObject } from 'lodash';
import videojs from 'video.js';
import 'videojs-youtube';

export default {
  name: 'VideoPlayer',

  data() {
    return {
      preload: 'auto',
      recording: {
        start: null,
        finish: null,
      },
    };
  },

  props: ['video'],

  computed: {
    ...mapState(['seekTo', 'fullScreen', 'currentTime', 'isRecording', 'currentPlaybackRate']),
    setup() {
      return JSON.stringify({
        techOrder: this.video.type === 'video/youtube' ? ['youtube'] : ['html5'],
        sources: [{ type: this.video.type, src: this.video.url }],
        width: '100%',
        height: '100%',
        aspectRatio: '16:9',
        youtube: {
          iv_load_policy: 3,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
      });
    },
  },

  methods: {
    changePlaybackRate(playbackRate) {
      if (!this.$video.paused()) {
        this.$store.commit('changePlaybackRate', playbackRate);
      }
    },

    onDurationReady(duration) {
      this.$store.commit('setDuration', duration);
    },

    /**
     * Tries to retrieve the duration with different methods.
     * If it fails with the current method, it skips to the next.
     * The method order is:
     *  1. Normal call
     *  2. In `loadedmetadata` event
     *  3. In `play` event
     *
     * In the 3rd case, it starts the video and pause and reset it immediately after
     */
    retrieveDuration() {
      let duration = Math.floor(this.$video.duration());

      const self = this;

      function checkDuration() {
        if (duration && !Number.isNaN(duration) && duration > 0) {
          self.onDurationReady(duration);
          return true;
        }

        return false;
      }

      if (checkDuration()) return;

      this.$video.one('loadedmetadata', () => {
        duration = Math.floor(this.$video.duration());
        if (checkDuration()) return;

        this.$video.one('play', () => {
          duration = Math.floor(this.$video.duration());

          this.$video.currentTime(0);
          this.$video.pause();

          checkDuration();
        });
        this.$video.play();
      });
    },

    onPlay() {
      this.$store.commit('play');
      this.$interval = setInterval(() => {
        this.updateCurrentTime();
      }, 1000 / 60);
    },

    onPause() {
      this.$store.commit('pause');
      clearInterval(this.$interval);
    },

    updateCurrentTime() {
      this.$store.commit('updateCurrentTime', this.$video.currentTime());
    },

    toggleRecording() {
      this.$store.dispatch('toggleRecording');
    },

    rewind() {
      let newTime = this.$video.currentTime() - 10;
      if (newTime < 0) newTime = 0;

      this.$video.currentTime(newTime);
    },
  },

  watch: {
    fullScreen() {
      this.$video.height(100);
      this.$video.fluid(!this.fullScreen);
    },

    currentPlaybackRate() {
      this.$video.playbackRate(this.currentPlaybackRate);
    }
  },

  mounted() {
    this.$video = videojs('video-wrapper', {
      controlBar: {
        currentTimeDisplay: true,
      },
    });
    this.retrieveDuration();
    this.$video.on('play', this.onPlay);
    this.$video.on('pause', this.onPause);
    this.$video.on('seeked', this.updateCurrentTime);

    this.$root.$on('seekTo', (val) => {
      const time = isPlainObject(val) ? val.time : val;
      this.$video.currentTime(time);
      if (!isPlainObject(val) || val.play) {
        this.$video.play();
      }
    });
    this.$root.$on('updateCurrentTime', (val) => {
      let newTime = this.$video.currentTime() + Number(val);
      if (newTime < 0) newTime = 0;
      if (newTime > this.$video.duration()) newTime = this.$video.duration();

      this.$video.currentTime(newTime);
    });
  },

  beforeDestroy() {
    clearInterval(this.$interval);
    this.$video.off('play', this.onPlay);
    this.$video.off('pause', this.onPause);
    this.$video.off('seeked', this.updateCurrentTime);
    this.$root.$off('seekTo');
    this.$root.$off('updateCurrentTime');
    this.$video.dispose();
    this.$video = null;
  },

};
</script>


<style lang="scss">
.video-js {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
  $big-play-width: 3em;
  /* 1.5em = 45px default */
  $big-play-height: 1.5em;
  left: 50%;
  top: 50%;
  margin-left: -($big-play-width / 2);
  margin-top: -($big-play-height / 2);
}

.video-js .vjs-current-time,
.vjs-no-flex .vjs-current-time {
  display: block;
}

.full-screen {
  height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;

  .video-row {
    flex: 1;
  }
}

.btn-primary.playback-light {
  background-color: #F7B545;
  border-color: #F7B545;

  &:hover {
    color: #fff;
    background-color: darken(#F7B545, 20%);
    border-color: darken(#F7B545, 20%);
  }

  &:focus, &.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 152, 61, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(245, 152, 61, 0.5);
  }

  &:not([disabled]):not(.disabled).active, &:not([disabled]):not(.disabled):active, &.dropdown-toggle {
    color: #fff;
    background-color: darken(#F7B545, 20%);
    background-image: none;
    border-color: darken(#F7B545, 20%);
    box-shadow: 0 0 0 0.2rem rgba(245, 152, 61, 0.5);
  }
}

.btn-primary.playback-dark {
  background-color: #EE8739;
  border-color: #EE8739;

  &:hover {
    color: #fff;
    background-color: darken(#EE8739, 20%);
    border-color: darken(#EE8739, 20%);
  }

  &:focus, &.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 152, 61, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(245, 152, 61, 0.5);
  }

  &:not([disabled]):not(.disabled).active, &:not([disabled]):not(.disabled):active, &.dropdown-toggle {
    color: #fff;
    background-color: darken(#EE8739, 20%);
    background-image: none;
    border-color: darken(#EE8739, 20%);
    box-shadow: 0 0 0 0.2rem rgba(245, 152, 61, 0.5);
  }
}

.rec-button.active i {
  color: #F7B545;
}

// .video-wrapper {
//   display: flex;
//   flex-direction: column;
// }
</style>
