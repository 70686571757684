<template id="presenze">
	<section class="presenze">
		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title"><span>Dati GPS</span></div>
					<div class="content">
						<b-row>
							<b-col>
								<b-row>
									<b-col cols="2">
										<file-upload class="btn btn-success" v-model="files" :headers="headers" @input-file="inputFile" ref="upload" :accept="accept" :post-action="postAction">
											Seleziona file csv
										</file-upload>
									</b-col>
									<b-col cols="2">
										<b-button variant="primary" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="startUpload">Upload</b-button>
									</b-col>
								</b-row>
								<div v-for="(file, index) in files" :key="file.id">
									<p> {{file.name}}</p>
									<div class="progress" v-if="file.active || (file.progress !== '0.00' && file.progress !== '100.00')">
										<div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
									</div>
								</div>
							</b-col>
						</b-row>
						<h5 v-if="gps.length == 0">Nessun dato gps trovato.</h5>
						<b-table v-if="gps.length > 0" striped hover :items="gps">
							<template #cell(HEAD_id)="data">
      							Giocatore
    						</template>
							<template #cell(HEAD_rosa)="data">
    						</template>
    						<template #cell(id)="row">
        						{{ row.item.rosa.giocatore.cognome }} {{ row.item.rosa.giocatore.nome }}
      						</template>	
							<template #cell(rosa)="data">
      						</template>							
						</b-table>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>
	</section>
</template>

<script>
import * as types from '../../../../store/types';
import FileUpload from 'vue-upload-component';
var Auth = require('../../../../auth.js');
export default {
	data: function(){
		return{
			gps: [],
			files: [],
			headers: {
				'tokenauth': 'Bearer ' + Auth.user.api_token
			},
			postAction: this.$store.state.apiEndPoint + '/gps/import/csv', 
			accept: '.csv',
		}
	},
	created: function (){
		this.getDatiGps();
	},
	methods: {
		getDatiGps: function(){
				this.$http.get(this.$store.state.apiEndPoint + '/gps/partita/' + this.$route.params.id).then((response) => {
					// success callback
					if (response.ok)
					{
						this.gps = response.data;
					}
				}, (response) => {
					// error callback
				});
			},
			inputFile(newFile, oldFile) {
				if (newFile && oldFile) {
			    // update
			    if (newFile.active && !oldFile.active) {
			    }
			    if (newFile.progress !== oldFile.progress) {
			    // progress
			}
			if (newFile.error && !oldFile.error) {
				this.$store.commit(types.ALERTS_PUSH, { msg: newFile.response.message, status: 0 });
				    // error
				}
				if (newFile.success && !oldFile.success) {
			//		this.gps = newFile.response.gps;
					this.getDatiGps();
					this.$store.commit(types.ALERTS_PUSH, { msg: newFile.response.message, status: 1 });
				}
			}
		},
		startUpload() {
			if(this.files.length > 0) {
				this.files[0].data = {
					'id_partita' : this.$route.params.id,
				};
				this.$refs.upload.active = true;
			}
		},
},
components: {
	headapp:            require('../../../layout/headapp.vue').default,
	footerapp:          require('../../../layout/footerapp.vue').default,
	menusub:            require('../../../layout/menusub.vue').default,
	FileUpload,
},
}
</script>
