<template>
	<div>
		
		<Intestazione :readonly="true" v-if="matchWs" :label="matchWs.rawMatch.label" :date="moment(matchWs.rawMatch.date, 'LLL')" :competizione="matchWs.competizione.paese.paese + ' - ' + matchWs.competizione.nome">
			
			<slot slot="left">
				<span v-if="ux.editing">
					<b-button @click.prevent="salvaValutazioni(area, payloadSubmit)" :disabled="!ux.canSaveValutazioni" variant="primary"><em class="fa fa-floppy-o"></em></b-button>
					<b-button @click.prevent="importLastVal" v-if="hasLastVals" title="Importa ultime valutazioni" variant="success"><em class="fa fa-clone"></em></b-button>
				</span>
				<b-button @click.prevent="showEdit" variant="primary" v-else-if="!ux.formazioni" title="Inserisci valutazioni"><em class="fa fa-pencil"></em></b-button>
				<b-button v-if="((matchWs.home.length == 0 || matchWs.away.length == 0) || (formazioneManuale && formazioneManuale.id)) && !ux.editing" @click.prevent="showFormazioni" variant="secondary" title="Inserisci formazioni"><em class="fa fa-list"></em></b-button>
				<span v-if="ux.formazioni">
					<b-button :disabled="!ux.canSaveFormazioni" @click.prevent="doSalvaFormazione(formazioneManuale)" variant="primary"><em class="fa fa-floppy-o"></em></b-button>
				</span>
			</slot>
			<slot slot="right">
				<div v-if="!ux.editing && !ux.detail && !ux.formazioni">
					<b-button v-if="utente_id" @click.prevent="showDetail" variant="primary"><em class="fa fa-search"></em></b-button>
				</div>
				<div v-else>
					<b-button @click.prevent="exitMode" variant="secondary"><em class="fa fa-sign-out"></em></b-button>
				</div>
			</slot>

			<slot slot="left2">

				<b-form-select @change="createPayload(); interfaccia_fixValutazioneSquadra(formValutazione, 'voto_away', formValutazione.voto_home)" v-model="formValutazione.voto_home" v-if="ux.editing">
					<option :value="null">-- Valore --</option>
					<option :value="1">+ forte</option>
					<option :value="2">equivalente</option>
					<option :value="3">+ bassa</option>
				</b-form-select>
				<code v-else>{{ valutazioneSquadra.voto_team }}</code>

			</slot>
			<slot slot="right2">

				<b-form-select @change="createPayload(); interfaccia_fixValutazioneSquadra(formValutazione, 'voto_away', formValutazione.voto_home)" v-model="formValutazione.voto_away" v-if="ux.editing">
					<option :value="null">-- Valore --</option>
					<option :value="1">+ forte</option>
					<option :value="2">equivalente</option>
					<option :value="3">+ bassa</option>
				</b-form-select>
				<code v-else>{{ valutazioneSquadra.voto_opponent }}</code>

			</slot>

			<slot slot="picker">
				<b-form-select size="sm" v-model="utente_id" v-if="valutazioniByUtente.length">
					<b-form-select-option v-for="persona in valutazioniByUtente" :value="persona.value">{{persona.label}}</b-form-select-option>
				</b-form-select>
			</slot>

			<slot slot="bottom">
				<div class="d-flex justify-content-end" v-if="ux.detail == true || ux.editing == true">
					<div style="min-width: 40%" class="text-right">
						<languageselector :lingue="lingue" :model="lingua" :readonly="ux.detail == true"></languageselector>
					</div>
				</div>
			</slot>

		</Intestazione>
		
		<div v-if="!ux.editing && !ux.formazioni">
			<Campetto :readonly="true" @change.prevent="false" v-if="matchWs" :homeId="matchWs.homeId" :awayId="matchWs.awayId" :home="matchWs.home" :away="matchWs.away" :formazioni="matchFormationsWs">
					
				<b-form-radio-group v-model="formValutazione.modalita">
					<b-form-radio value="stadio" title="Live"><i class="fa fa-car"></i></b-form-radio>
					<b-form-radio value="video" title="Video"><i class="fa fa-video-camera"></i></b-form-radio>
					<b-form-radio value="actions_report" title="Actions report"><i class="fa fa-fast-forward"></i></b-form-radio>
				</b-form-radio-group>

			</Campetto>	

			<b-row v-if="ux.detail == false">
				<b-col cols="6">
					<ListaGiocatori :valutazioni="valutazioniSelezionate" v-if="matchWs" :formazione="matchWs.home"></ListaGiocatori>
				</b-col>
				<b-col cols="6">
					<ListaGiocatori :valutazioni="valutazioniSelezionate" v-if="matchWs" :formazione="matchWs.away"></ListaGiocatori>
				</b-col>
			</b-row>
			<div v-else-if="ux.detail == true">
				<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
					{{ homeLabel }}
				</b-card>
				<ListaValutazioni :gradimenti="gradimenti" :valutazioni="valutazioniSelezionate" v-if="matchWs" :formazione="matchWs.home" :lingua="lingua"></ListaValutazioni>
				<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
					{{ awayLabel }}
				</b-card>
				<ListaValutazioni :gradimenti="gradimenti" :valutazioni="valutazioniSelezionate" v-if="matchWs" :formazione="matchWs.away" :lingua="lingua"></ListaValutazioni>
			</div>
		</div>
		<div v-else-if="ux.formazioni == true">
			
			<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
				{{ homeLabel }}
			</b-card>

			<EditFormazione :ruoli="ruoli" @change="createPayload" ref="formhome" :home="true" :valutazioni="valutazioniSelezionate" v-if="formazioneManuale" :formazione="formazioneManuale.home"></EditFormazione>
			<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
				{{ awayLabel }}
			</b-card>
			<EditFormazione :ruoli="ruoli" @change="createPayload" ref="formaway" :away="true" :valutazioni="valutazioniSelezionate" v-if="formazioneManuale" :formazione="formazioneManuale.away"></EditFormazione>


			<!-- <pre>{{ payloadFormazioni }}</pre> -->
		</div>
		<div v-else>

			<b-card no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
				{{ homeLabel }}
			</b-card>
			<EditValutazioni :gradimenti="gradimenti" :ruoli="ruoli" @change="createPayload" ref="valhome" :home="true" :valutazioni="valutazioniSelezionate" v-if="matchWs" :formazione="matchWs.home" :lingua="lingua"></EditValutazioni>
			<b-card  no-body class="p-1 mb-1 text-center" bg-variant="secondary" style="color:#FFFFFF;font-size:1.1rem;">
				{{ awayLabel }}
			</b-card>
			<EditValutazioni :gradimenti="gradimenti" :ruoli="ruoli" @change="createPayload" ref="valaway" :away="true" :valutazioni="valutazioniSelezionate" v-if="matchWs" :formazione="matchWs.away" :lingua="lingua"></EditValutazioni>

			<!-- <pre>{{ payloadSubmit }}</pre> -->

		</div>

	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'
import scoutingBase from '@/mixins/scoutingBase.js'
import linguaBase from '@/mixins/linguaBase.js'

export default {
	mixins: [ pageBase, scoutingBase ],
	props: ['wyId','partiteId'],
	data: function() {
		return {
			utente_id: null,
			formazioneManuale: null,
			formazioneManualeManuale: null,
			matchFormationsWs: null,
			match: null,
			matchWs: null,
			valutazioni: null,
			payloadSubmit: {},
			ruoli: null,
			gradimenti: null,
			formValutazione: {
				modalita: null,
				voto_home: null,
				voto_away: null
			},
			ux: {
				formazioni: false,
				canSaveFormazioni: false,
				canSaveValutazioni: false,
				detail: false,
				editing: false
			},
			lingue: [],
			lingua: {
				selected: null,
				master: null,
			}
		}
	},
	computed: {
		label: function() {
			return this.matchWs && this.matchWs.rawMatch ? this.matchWs.rawMatch.label : '';
		},
		awayLabel: function() {
			return this.matchWs && this.matchWs.rawMatch ? this.matchWs.rawMatch.label.split(",")[0].split("-")[1] : '';
		},
		homeLabel: function() {
			return this.matchWs && this.matchWs.rawMatch ? this.matchWs.rawMatch.label.split(",")[0].split("-")[0] : '';
		},
		hasLastVals: function() {

			var homeVals = _.filter(_.map(this.matchWs.home, 'last_val_breve'));
			var awayVals = _.filter(_.map(this.matchWs.away, 'last_val_breve'));

			if(homeVals.length || homeVals.length) return true;
			return false;
		}
	},
	methods: {
		showFormazioni: function() {
			this.ux.formazioni = true;
		},
		start: function() {
			this.ux.editing = false;
			this.fetchLingue();
			this.fetchRuoli();
			this.fetchGradimenti();
			// this.fetchMatch();
			this.fetchMatchFormationsWS();
			this.fetchMatchWithPlayerWS();
			this.fetchFormazioneManuale();
			this.fetchValutazioni();
		},
		importLastVal: function() {

			if(confirm()) {
				this.$refs.valhome.importVal();
				this.$refs.valaway.importVal();
			}

		},
		showDetail: function() {
			this.ux.detail = !this.ux.detail;
		},
		showEdit: function() {
			this.ux.editing = !this.ux.editing;
		},
		createPayload: function() {

			if(!this.$refs.valhome || !this.$refs.valaway) return false;

			var out = {};

			_.extend(out, this.$refs.valhome.form);
			_.extend(out, this.$refs.valaway.form);

			out.wyscout_match_id = this.wyId;
			out.wyscout_match_label = this.matchWs.rawMatch.label;
			out.id_partita = null;
			out.voto_away = this.formValutazione.voto_away;
			out.voto_home = this.formValutazione.voto_home;
			out.modalita = this.formValutazione.modalita;

			out.lingua_master = this.lingua.master;

			this.payloadSubmit = out;


			if(_.filter(_.values(this.$refs.valhome.errors)).length > 0 || _.filter(_.values(this.$refs.valaway.errors)).length > 0)
				this.ux.canSaveValutazioni = false;
			else
				this.ux.canSaveValutazioni = true;


		}
	},
	watch: {
		'formazioneManuale': {
			deep: true,
			handler: function() {

				if(!this.$refs.valhome || !this.$refs.valaway) return false;

				var hasHomeErrors = _.filter(this.$refs.formhome.errors, function(b) { return b.length > 0 }).length > 0;
				var hasAwayErrors = _.filter(this.$refs.formaway.errors, function(b) { return b.length > 0 }).length > 0;

				this.ux.canSaveFormazioni = !hasHomeErrors && !hasAwayErrors;

			}
		},
		utente_id: {
			handler: function() {
				this.formValutazione.voto_home = this.valutazioneSquadra.voto_team;
				this.formValutazione.voto_away = this.valutazioneSquadra.voto_opponent;
				this.formValutazione.modalita = this.valutazioneSquadra.modalita;
			}
		},
		wyId: {
			immediate: true,
			handler: function() {
				this.start();
			}
		}
	}

}

</script>