<template id="videotag">
	<section class="videotag">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Videotag</span>
					</div>
					<div v-if="loading" class="text-center"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
					<videotag v-if="!loading" :goalVisible="false"></videotag>
				</div>
			</div>
		</div>
		<footerapp></footerapp>
	</section>
</template>

<script>

import moment from 'moment';
import * as types from '../../../../store/types';
import { mapState } from 'vuex';

export default {

	data: function() {
		return {
			loading: true,
			data: [],
		}
	},

	components: {
		headapp:             require('../../../layout/headapp.vue').default,
		menusub:             require('../../../layout/menusub.vue').default,
		footerapp:           require('../../../layout/footerapp.vue').default,
		videotag: 			 require('../../../layout/videotag/Home.vue').default,
		
	},

	created: function () {
		this.loadAllenamento();	 
	},
	computed: {
		...mapState(['rosaTeamId']),
	},
	watch: {
	    rosaTeamId() {
		  this.loadAllenamento();
	    },  
  	},
	methods: {
		loadAllenamento() {
			if(this.rosaTeamId) {
				this.$http.get(this.$store.state.apiEndPoint + '/video/allenamento/videotag/' + this.$route.params.id_video + '/' + this.rosaTeamId).then((response) => {
					if (response.ok) {
						this.data = response.data;
						this.$store.commit('cancelNewTag');
						this.$store.commit('setPrivateTag', false);
						this.$store.commit('updateData', this.data);
						this.loading = false;	
					}
				}, (response) => {
            		// error callback
        		});
			}
		}
	}
}
</script>