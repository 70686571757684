<template>
  <div id="app" :class="[isInHome() && 'full-height']">
    <headapp></headapp>
    <v-header
    @onLeftToggle="toggleLeftSidebar"
    @onRightToggle="toggleRightSidebar" />

    <router-view></router-view>

    <mission-modal />
    
    <b-modal ref="matchesModal" title="Ipotetiche partite nei dintorni" ok-only ok-title="Chiudi">
      <div v-if="matches_by_city && matches_by_city.matches" class="text-center">
        <h5>{{ matches_by_city.city + ' - ' + matches_by_city.paese }}</h5>
        <b-row v-for="match in matches_by_city.matches" class="border-bottom py-2">
          <b-col>
            <div>{{ match.competizione }}</div>
            <img :src="getLogo(match.id_casa)" width="25"></img> <strong>{{ match.casa }} - {{ match.trasferta }}</strong> <img :src="getLogo(match.id_trasferta)" width="25"></img><br />
            {{ matchDate(match) }}
          </b-col>
        </b-row>   
      </div> 
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from './Header.vue';
import MissionModal from './MissionModal.vue';
import { isMobile } from '../../../utils';
import axios from 'axios';
import moment from 'moment';
import { ALERTS_PUSH } from '../../../store/types';

const FORMAT = 'DD/MM/YYYY HH:mm';

export default {
  name: 'Geomatch',
  components: {
    headapp: require('../../layout/headapp.vue').default,
    MissionModal,
    'v-header': Header,
  },

  data() {
    return {
      matches_by_city: null,
    };
  },

  computed: mapState('geomatch', ['leftSidebarCollapsed', 'rightSidebarCollapsed', 'filters']),

  methods: {
    toggleLeftSidebar() {
      this.$store.commit('geomatch/toggleLeftSidebar');
    },

    toggleRightSidebar() {
      this.$store.commit('geomatch/toggleRightSidebar');
    },

    isInHome() {
      return this.$route.path === '/geomatch';
    },
    matchDate(match) {
      return moment.utc(match.data).local().format(FORMAT);
    },
    getLogo(id) {
      return this.$store.state.apiEndPoint + '/logo/team/' + id;
    },
    getMatchesByCity(item) {
      this.matches_by_city = item;
      this.$refs.matchesModal.show();
    }
  },

  watch: {
    leftSidebarCollapsed() {
      if (this.leftSidebarCollapsed && this.rightSidebarCollapsed && isMobile) {
        this.$store.commit('toggleRightSidebar', false);
      }
    },

    rightSidebarCollapsed() {
      if (this.rightSidebarCollapsed && this.leftSidebarCollapsed && isMobile) {
        this.$store.commit('toggleLeftSidebar', false);
      }
    },
  },

  mounted() {
    this.$root.$on('getMatchesByCity', this.getMatchesByCity);
  },

  beforeDestroy() {
    this.$root.$off('getMatchesByCity');
  }
};
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.97);

  &.full-height {
    height: 100vh;
  }
}

.wrapper {
  display: flex;
  flex: 1;
}
</style>
