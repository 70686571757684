import Vue from 'vue'
import Vuex from 'vuex'
import state from './store';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import language from './modules/language'
import alerts from './modules/alerts'
import geomatch from './modules/geomatch'
import editor from './modules/editor'
import exercises from './modules/exercises'
import acad_esercizi from './modules/acad_esercizi'
import palestra from './modules/palestra'
import piscina from './modules/piscina'
import search_esercizi from './modules/search_esercizi'
import calendar_events from './modules/calendar_events'

Vue.use(Vuex)

export default new Vuex.Store({
  mutations,
  actions,
  getters,  
  state: Object.assign({}, {
    showResalert: true,
 // apiEndPoint: 'http://localhost:8888'
 // apiEndPoint: 'https://api.dev.asromasystem.com'
    apiEndPoint: 'https://api.asromasystem.com',
    endpointRedazione: 'https://football.e000.yay.st/',
  }, state),
  modules: {
    language,
    alerts,
    geomatch,
    editor,
    exercises,
    calendar_events,
    acad_esercizi,
    palestra,
    piscina,
    search_esercizi
  }
})
