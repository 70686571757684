<template id="osservatori">
	<section class="osservatori">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<div class="card-title">
						<span>Osservatori ({{ osservatori.length }})</span>
					</div>
					<div class="content">
						<b-btn v-b-toggle.collapse_paesi variant="primary" class="mb-2">Paesi</b-btn>
						<b-collapse id="collapse_paesi" class="mb-2" @shown="initMap">
							<b-card>	
								<div class="row">
									<div class="col-2">
										<h4>Osservatori</h4>
										<div v-for="osservatore in osservatori">
											<div class="row">
												<div class="col"> 
													<span class="m-1" style="font-size: 1.25rem;cursor: pointer;" v-b-popover.hover = "getPaesi(osservatore)" @mouseover="showPaesi(osservatore)" @mouseout="resetPaesi(osservatore)"> {{ osservatore.nomeCompleto }} ({{ osservatore.paesi.length }})</span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-10">
										<div id="chartdiv" style="width: 100%; height: 500px;"></div>
									</div>
								</div>	
							</b-card>
						</b-collapse>
						<b-row>
							<b-col sm="1">
								<b-form-group>
									<b-form-select v-model="filters.tutti">
										<option :value="0">Solo attivi</option>
										<option :value="1">Tutti</option>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col sm="3">
								<b-form-group horizontal label="Filtra dati dal" label-cols="4">
									<b-form-input type="date" v-model="filters.dal"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col sm="3">
								<b-form-group horizontal label="al" label-cols="3" label-text-align="right">
									<b-form-input type="date" v-model="filters.al"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<div v-if="vista == 'griglia'">	
							<div class="row">
								<div v-for="osservatore in osservatori" class="col-2">
									<cardosservatore :osservatore="osservatore" :academy="route.meta.module == 'academy' ? 1 : (route.meta.module == 'usascout' ? 2 : 0)" :newscout="true"></cardosservatore>
								</div>
								<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footerapp></footerapp>

	</section>
</template>

<script>
import AmCharts from 'ammap3';
import 'ammap3/ammap/ammap.js';
import 'ammap3/ammap/maps/js/worldLow.js';
import 'ammap3/ammap/maps/js/continentsLow.js';
import 'ammap3/ammap/lang/it.js';

export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		cardosservatore: 	require('../../layout/cardosservatore.vue').default,
	},
	data: function(){
		return {
			vista: 'griglia',
			osservatori: [],
			loading: false,
			paesi_utente: [],
			filters: {
				tutti: 0,
				dal: '',
				al: '',
			},
		}
	},
	created: function () {

		this.loading = true;

		this.$http.get(this.$store.state.apiEndPoint + '/scouting/osservatori/newscout').then((response) => {
			if (response.ok)
			{
				this.osservatori = response.data; 
				for(var i in this.osservatori) {
					var paesi =  this.osservatori[i].paesi;
					for(var x in paesi) {
						var found = false;
						var paese = paesi[x];
						for(var y in this.paesi_utente) {
							var paese_utente = this.paesi_utente[y];
							if(paese_utente.id == paese.id) {
								found = true;
								paese_utente.utenti.push(this.osservatori[i].nomeCompleto);
								break;
							}
						}
						if(!found) {
							var utenti = [];
							utenti.push(this.osservatori[i].nomeCompleto);
							paese.utenti = utenti; 
							this.paesi_utente.push(paese);
						}
					}
				}
				this.loading = false;
			}
		}, (response) => {
            // error callback
        });      
	},
	methods: {

		filtraDati() {

			this.loading = true;

			this.$http.get(this.$store.state.apiEndPoint + '/scouting/osservatori/newscout', {params: this.filters}).then((response) => {
				if (response.ok)
				{
					this.osservatori = response.data; 
					for(var i in this.osservatori) {
						var paesi =  this.osservatori[i].paesi;
						for(var x in paesi) {
							var found = false;
							var paese = paesi[x];
							for(var y in this.paesi_utente) {
								var paese_utente = this.paesi_utente[y];
								if(paese_utente.id == paese.id) {
									found = true;
									paese_utente.utenti.push(this.osservatori[i].nomeCompleto);
									break;
								}
							}
							if(!found) {
								var utenti = [];
								utenti.push(this.osservatori[i].nomeCompleto);
								paese.utenti = utenti; 
								this.paesi_utente.push(paese);
							}
						}
					}
					this.loading = false;
				}
			}, (response) => {
            // error callback
        });
		},
		initMap() {
			this.$map = new window.AmCharts.makeChart( "chartdiv", {
				"type": "map",
				"theme": "light",
				"projection": "miller",
				"language": "it",
				"allowClickOnSelectedObject": false,
				"areasSettings": {
					"autoZoom": false,
					"rollOverOutlineColor": "#A80532",
					"selectedColor": "#A80532",
					"color": "#FFC64F",
					"rollOverColor": "#A80532"
				},

				"dataProvider": {
					"map": "worldLow",
					"getAreasFromMap": true
				},
			});

			this.preSelectCountries();
		},	

		handleGoHome() {
			this.$map.validateNow();
		},
		preSelectCountries() {
			for(var i = 0; i < this.paesi_utente.length; i++) {
				var area = this.$map.getObjectById(this.paesi_utente[i].sigla);
				var osservatori = '';	
				for(var x in this.paesi_utente[i].utenti) {
					osservatori = osservatori + "<div><strong>" + this.paesi_utente[i].utenti[x] + "</strong></div>";
				}
				area.titleTr = area.titleTr + osservatori;	
				area.showAsSelected = true;
				this.$map.returnInitialColor(area);
			}
		},
		getPaesi(osservatore) {
			var paesi = "";
			for(var i  in osservatore.paesi) {
				var paese = osservatore.paesi[i].paese;
				paesi = paesi + '<div><strong>' + paese + '</strong></div>';
			}
			return {
				html: true,
				title: () => { return osservatore.nomeCompleto },
				content: () => { return paesi }
			}
		},
		showPaesi(osservatore) {
			for(var i = 0; i < this.paesi_utente.length; i++) {
				var area = this.$map.getObjectById(this.paesi_utente[i].sigla);
				area.showAsSelected = false;
				this.$map.returnInitialColor(area);
			}
			for(var i = 0; i < osservatore.paesi.length; i++) {
				var area = this.$map.getObjectById(osservatore.paesi[i].sigla);
				area.showAsSelected = true;
				this.$map.returnInitialColor(area);
			}		
		},
		resetPaesi(osservatore) {
			for(var i = 0; i < osservatore.paesi.length; i++) {
				var area = this.$map.getObjectById(osservatore.paesi[i].sigla);
				area.showAsSelected = false;
				this.$map.returnInitialColor(area);
			}
			for(var i = 0; i < this.paesi_utente.length; i++) {
				var area = this.$map.getObjectById(this.paesi_utente[i].sigla);
				area.showAsSelected = true;
				this.$map.returnInitialColor(area);
			}
		},
	},
	computed: {
		route: function() {
			return this.$route;
		},
	},
	watch: {
		'filters.dal': function () {
			this.filtraDati();
		},
		'filters.al': function () {
			this.filtraDati();
		},
		'filters.tutti': function () {
			this.filtraDati();
		},
	}

}
</script>
