<template id="palestra">
	<div class="palestra">
		<table class="table table-striped">
			<thead>
				<tr>
					<th title="Data">Data</th>
					<th title="Work">Work</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(allen, index) in params.allenamenti" v-if="index < params.count_rows || (index >= params.count_rows && show_all)">	
					<td><a :href="'#/football/palestra/allenamento/' + allen.id">{{allen.start_at | formatDate}}</a></td>
					<td> {{allen.work.name}} </td>
				</tr>
			</tbody>
		</table>
		<b-button v-if="params.allenamenti.length > params.count_rows" variant="primary" @click="switchShow" size="sm" :title="(show_all ? 'Nascondi' : 'Mostra') + ' allenamenti palestra'"><i class="fa fa-arrow-down" aria-hidden="true" v-if="!show_all"></i><i class="fa fa-arrow-up" aria-hidden="true" v-if="show_all"></i></b-button>
	</div>

</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		params: {
			type: Object,
			required: false
		}
	},
	data: function() {
		return {
			show_all: false,
		}
	},
	methods: {
		switchShow: function() {
			this.show_all = !this.show_all;
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return "";
		}
	},
}
</script>
