<template id="formazione">
    <div class="formazione">
        <div class="form-group">
            <multiselect @input="updateValue" v-model="modul" :options="opt_modules" :multiple="false" :close-on-select="true" :clear-on-select="false" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="name" :preselect-first="false" :disabled="disabled"></multiselect>
        </div>

        <div :class="'modulo modulo-'+modulo+' modulo-'+dove">
            <div class="campo">
                <img src="/assets/images/campo.svg">
            </div>

            <div v-for="n in giocatori" :class="'player player-'+n">    
                <span class="numero">{{ n }}</span>
                <divisa
                :type="divisa.type"
                :primario="divisa.primario"
                :secondario="divisa.secondario"
                :terziario="divisa.terziario"
                ></divisa>
            </div>
        </div>

    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import cache from '@/mixins/cache.js';

export default {

    components: {
        Multiselect,
        divisa:           require('../layout/divise/divisa.vue').default,
    },

    mixins: [ cache ],

    data () {
        return {

            modules: [],
            modul: null,

            divisa: {
                type:       this.shirt == 'avversario' ? 'verticale' : 'verticale',
                primario:   this.shirt == 'avversario' ? '#FCD816' : '#a80532',
                secondario: this.shirt == 'avversario' ? '#0F1863' : '#FFEB3B',
                terziario:  this.shirt == 'avversario' ? '#FCD816' : '#f07f01',
            }

        }
    },

    props: {

        modulo: {
            type: String,
            required: false,
        default: null,
        },

        shirt: {
            type: String,
            required: false,
        default: 'roma'
        },

        dove: {
            type: String,
            required: true,
        default: 'casa'
        },

        giocatori: {
            type: Number,
        default: 11
        },

        disabled: {
            type: Boolean,
        default: false,
        }

    },

    created() {
        this.getFormations();
    },

    methods: {
        fixModulo: function() {
            for(var i in this.opt_modules) {
                var item = this.opt_modules[i];
                var label = this.findAndReplace(item.name, '-', '');
                label = this.findAndReplace(label, ' ', '');
                if(label == this.modulo) {
                    this.modul = item;
                    break;
                }
            }
        },
        findAndReplace: function(string, target, replacement) {
            var i = 0, length = string.length;
            for (i; i < length; i++) {
                string = string.replace(target, replacement);
            }
            return string;
        },

        getFormations: function() {
            var formations = this.getCache('formations');
            if (formations) {
                this.modules = formations;
                this.fixModulo();
            } else {
                this.$http.get(this.$store.state.apiEndPoint + '/formations').then((response) => {
                    this.modules = response.data;
                    this.setCache('formations', this.modules);
                    this.fixModulo();
                });
            }
        },

        updateValue (mod) {
            var newStr = this.findAndReplace(this.modul.name, '-', '');
            newStr = this.findAndReplace(newStr, ' ', '');
            this.$emit('updatePartita', mod);
        }
    },
    computed: {
        opt_modules: function() {
            var array_opt = [];
            for(var i in this.modules) {
                var modulo = this.modules[i];
                if(modulo.players == this.giocatori) {
                    var obj = {
                        name: modulo.name,
                        id: modulo.id,
                    }
                    array_opt.push(obj);
                }
            }
            return array_opt;
        },
    },

    watch: {
        modulo: {
            immediate: true,
            handler: function() {
                this.fixModulo();
            }
        }
    }

}
</script>
