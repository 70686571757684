<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" md="6">
          <h3>Squadra di casa</h3>
          <b-form-select
            id="homePlayers"
            :value="player ? player.id : null"
            :options="homePlayers"
            text-field="name"
            value-field="id"
            @change="setPlayer($event)">
            <template slot="first">
              <!-- this slot appears above the options from 'options' prop -->
              <option :value="null" disabled>-- Seleziona un opzione --</option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" md="6">
          <h3>Sqadra di trasferta</h3>
          <b-form-select
            id="visitingPlayers"
            :value="player ? player.id : null"
            :options="visitingPlayers"
            text-field="name"
            value-field="id"
            @change="setPlayer($event, true)">
            <template slot="first">
              <!-- this slot appears above the options from 'options' prop -->
              <option :value="null" disabled>-- Seleziona un opzione --</option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" class="mt-3">
      <b-row>
        <b-col v-for="skill in assistSkills" :key="skill.id" cols="12" md="6" class="mb-1">
          <b-button
            variant="primary w-100"
            @click.prevent="setSkill(skill)"
            :class="{ active: skillActiveClass(skill) }" :title="skill.name">
            <span>{{ skill.name }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  name: 'AssistPlayerSection',

  props: {
    negative: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      player: null,
      skill: null,
    };
  },

  computed: mapState({
    homePlayers: state => state.data && state.data.teams.home.players,
    visitingPlayers: state => state.data && state.data.teams.visiting.players,
    assistSkills: state => state.data && state.data.skills.find(s => Number(s.id) === 13).children,
  }),

  methods: {
    setPlayer(value, visiting = false) {
      const players = visiting ? this.visitingPlayers : this.homePlayers;
      const player = players.find(p => p.id === value);
      this.player = player;
      this.$emit('player-clicked', player);
    },

    skillActiveClass(skill) {
      return this.skill && skill.id === this.skill.id && 'active';
    },

    setSkill(skill) {
      this.skill = skill;
      this.$emit('skill-clicked', skill);
    },

    reset() {
      this.player = null;
      this.skill = null;
    },
  }
};
</script>
