<template id="gruppi">
  <section class="gruppi">
    <headapp></headapp>
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>News</span>
        </div>
        <div class="content">
          <admin-table
          api-url="/admin/news"
          :columns="columns"
          :createFormData="newData"
          :editFormData="editData"
          :canDelete="true"
          :sendMail="true"
          :defaultFilter="false"
          @resetCreateFormData="resetCreateFormData"
          @editError="showError"
          @createError="showError"
          @showModal="onShowModal"
          @showSendMailModal="onShowSendMailModal">
          <template slot="create-modal-body" slot-scope="row">
            <b-form-group label="Data">
              <b-form-input type="date" placeholder="Data" v-model="newData.date" />
            </b-form-group>
            <b-form-group label="Titolo">
              <b-form-input type="text" placeholder="Nome" v-model="newData.title" />
            </b-form-group>
            <b-form-group label="Descrizione">
              <b-form-textarea :rows="8" v-model="newData.description"></b-form-textarea>
            </b-form-group>
            <b-form-group label="Leggi+">
              <b-form-textarea :rows="8" v-model="newData.body"></b-form-textarea>
            </b-form-group>
            <b-form-group label="Permessi">
              <b-form-checkbox-group v-model="newData.rights" name="permessi_new" stacked style="height:400px;overflow-y:auto;">
                <b-form-checkbox v-for="permesso in permessi_options" :value="permesso.nome" :key="permesso.id">{{ permesso.info ? permesso.info.info + ' (' + permesso.nome + ')' : permesso.nome }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </template>
          <template slot="edit-modal-body" slot-scope="row">
            <b-form-group label="Attiva">
              <b-form-checkbox v-model="editData.active" value="1"/>
            </b-form-group>
            <b-form-group label="Data">
             <b-form-input type="date" placeholder="Data" v-model="editData.date" />
           </b-form-group>
           <b-form-group label="Titolo">
            <b-form-input type="text" placeholder="Nome" v-model="editData.title" />
          </b-form-group>
          <b-form-group label="Descrizione">
            <b-form-textarea :rows="8" v-model="editData.description"></b-form-textarea>
          </b-form-group>
          <b-form-group label="Leggi+">
            <b-form-textarea :rows="8" v-model="editData.body"></b-form-textarea>
          </b-form-group>
          <b-form-group label="Permessi">
            <b-form-checkbox-group v-model="editData.rights" name="permessi_upd" stacked style="height:400px;overflow-y:auto;">
              <b-form-checkbox v-for="permesso in permessi_options" :value="permesso.nome" :key="permesso.id">{{ permesso.info ? permesso.info.info + ' (' + permesso.nome + ')' : permesso.nome }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <b-row><b-col cols="2"><b-form-group label="Test">
            <b-form-radio-group v-model="test">
              <b-form-radio :value="0">No</b-form-radio>
              <b-form-radio :value="1">Si</b-form-radio>
            </b-form-radio-group>
          </b-form-group></b-col><b-col><b-form-group v-if="test" label="Invia a">
            <b-form-input type="text" v-model="email" />
          </b-form-group></b-col></b-row>
          <b-button size="sm" variant="success" @click.prevent="sendMail(editData.id)">Invia Mail <i class="fa fa-paper-plane" aria-hidden="true"></i></b-button>
        </template>
      </admin-table>
      <b-modal ref="sendMailModal" size="lg" title="Invia news selezionate">
        <b-row><b-col cols="2"><b-form-group label="Test">
          <b-form-radio-group v-model="test">
            <b-form-radio :value="0">No</b-form-radio>
            <b-form-radio :value="1">Si</b-form-radio>
          </b-form-radio-group>
        </b-form-group></b-col><b-col><b-form-group v-if="test" label="Invia a">
          <b-form-input type="text" v-model="email" />
        </b-form-group></b-col></b-row>
        <b-button size="sm" variant="success" @click.prevent="sendMail(news_ids)">Invia Mail <i class="fa fa-paper-plane" aria-hidden="true"></i></b-button>
      </b-modal>
    </div>
  </div>
</div>
<footerapp></footerapp>
</section>
</template>

<script>
import axios from 'axios';
import adminTable from './adminTable.vue';
import * as types from '../../../store/types';

export default {
  components: { 
    adminTable, 
    headapp: require('../../layout/headapp.vue').default,
    footerapp: require('../../layout/footerapp.vue').default
  },

  data() {
    return {
      columns: [
        { key: 'selected_id', label: '' },
        { key: 'id', label: 'ID' },
        { key: 'active', label: 'Attiva' },
        { key: 'date', label: 'Data' },
        { key: 'title', label: 'Titolo' },
        { key: 'description', label: 'Descrizione'},
        { key: 'body', label: 'Leggi+'},
        { key: 'rights', label: 'Permessi'},
        { key: 'actions', label: 'Azioni' },
        ],
      newData: {
        date: null,
        title: null,
        description: null,
        body: null,
        rights: [],
      },
      editData: {
        active: false,
        date: null,
        title: null,
        description: null,
        body: null,
        rights: [],
      },
      permessi_options: [],
      test: 0,
      email: null,
      news_ids: [],
    };
  },
  methods: {
    onShowModal(row) {
      this.editData.id = row.item.id;
      this.editData.active = row.item.active;
      this.editData.date = row.item.date;
      this.editData.title = row.item.title;
      this.editData.description = row.item.description;
      this.editData.body = row.item.body;
      this.editData.rights = JSON.parse(row.item.rights);
    },
    resetCreateFormData() {
      this.newData.date = null;
      this.newData.title = null;
      this.newData.description = null;
      this.newData.body = null;
      this.newData.rights = [];
    },
    showError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(types.ALERTS_PUSH, { msg: err.response.data.error, status: 0 });
      }
    },
    sendMail(ids) {
      axios.get('/admin/send/news/' + ids, { params: {
        test: this.test,
        email: this.email,       
      }}).then((res) => {
        this.$store.commit(types.ALERTS_PUSH, { msg: res.data.message, status: 1 }); 
      }).catch((err) => {
        this.$store.commit(types.ALERTS_PUSH, { msg: err, status: 0 }); 
      });
      this.$refs.sendMailModal.hide();
    },
    onShowSendMailModal(selected_ids) {
      if(selected_ids.length > 0) {
        this.news_ids = selected_ids;
        this.$refs.sendMailModal.show();
      }
    },
  },
  created: function() {
   axios.get('/admin/permessi').then((res) => {
    this.permessi_options = res.data;
    this.permessi_options = this.permessi_options.sort(function(a, b) { 
      return (a.info ? a.info.info : a.nome).localeCompare(b.info ? b.info.info : b.nome);
    });
  });
 },
};
</script>

<style lang="scss">

.pac-container {
  z-index: 1051 !important;
}
</style>
