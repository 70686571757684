<template>
	<div>
		<div v-if="risultati">
			<b-table-simple striped small responsive sticky-header="80vh">
				<b-thead style="background-color: white;">
					<b-tr>
						<b-th class="border-0" sticky-column style="min-width:200px">Chiave</b-th>
						<b-th class="border-0" v-for="k in tableInvertita.persona_id">
							{{ getPlayer(k).cognome + ' ' + getPlayer(k).nome }}
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="valoriChiave,k in tableInvertita">
						<b-td sticky-column style="min-width:200px">{{risultati.intestazioni[k] ? risultati.intestazioni[k].label : ''}}</b-td>
						<b-td v-for="kk,i in tableInvertita.persona_id">
							<div v-if="risultati.intestazioni[k]" class="d-flex align-items-center">
								<div class="flex-grow-1" :is="typeOf(risultati.intestazioni[k].type)" :test="risultati.table[kk][0]" :field="k" :options="risultati.intestazioni[k]" :dataLayer="dataLayer"></div>
								<div class="pl-2 pr-1 small" style="cursor: pointer; color: #ba2e3b" v-if="risultati.table[kk] != null" @click.prevent="askEmpty(risultati.table[kk], k)">
									<i class="fa fa-trash" aria-hidden="true"></i>
								</div>
							</div>

						</b-td>
						
					</b-tr>
				</b-tbody>
			</b-table-simple>
			
		</div>
	</div>
</template>

<script>

import _ from 'lodash'
import sessionMixin from '@/mixins/sessione.js';
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	mixins: [
		sessionMixin
	],
	components: {
		SessioneMatriceBoolean: require('@/components/layout/Sessioni/Matrix/Boolean.vue').default,
		SessioneMatriceNumber: require('@/components/layout/Sessioni/Matrix/Number.vue').default,
		SessioneMatriceSelector: require('@/components/layout/Sessioni/Matrix/Selector.vue').default,
		SessioneMatriceTextarea: require('@/components/layout/Sessioni/Matrix/Textarea.vue').default,
		SessioneMatriceText: require('@/components/layout/Sessioni/Matrix/Text.vue').default,
		SessioneMatriceEnum: require('@/components/layout/Sessioni/Matrix/Enum.vue').default,
		SessioneMatriceDate: require('@/components/layout/Sessioni/Matrix/Date.vue').default,
		SessioneMatriceJson: require('@/components/layout/Sessioni/Matrix/Json.vue').default,
	},
	watch: {
		risultati: {
			deep: true,
			handler: function() {

				var selectors = _.filter(this.risultati.intestazioni, { type: 'selector' });

				_.each(selectors, function(sel) {
					if(!_.find(this.dataLayer, { url: sel.url })) {
						this.$http.get(this.$store.state.apiEndPoint + sel.url).then(function(data) {
							this.dataLayer.push({
								url: sel.url,
								data: data.data
							});
						});
					}
				}.bind(this));

			},
			immediate: true
		}
	},
	created: function() {

		

	},
	props: [ 'risultati', 'fase' ],
	data: function() {
		return {
			dataLayer: [],
			ux: {
				show: true
			}
		}
	},
	
	computed: {
		tableInvertita: function() {

			console.log(this.risultati.table);
			var keys = _.keys(_.first(_.values(this.risultati.table)[0]));
			var out = {};
			out.persona_id = [];

			_.each(_.keys(this.risultati.table), function(player) {
				out.persona_id.push(player);
			});

			_.each(keys, function(v) {

				out[v] = [];

				_.each(this.risultati.table, function(giocatore) {
					_.each(giocatore, function(p) {
						out[v].push(p[v]);
					})
				}.bind(this));


			}.bind(this));

			return out;

		},
		tabella: function() {
			var chiavi = _.keys(this.risultati.intestazioni);
			return chiavi;
		}
	},
	methods: {
		askEmpty: function(player, k) {
			if(player[k] != null) {
				if(confirm('Confermi la rimozione del dato?'))
					player[k] = null;
			}
		},
		getPlayer: function(id) {
			return _.find(this.fase.persone, { id: parseInt(id) });
		},
		typeOf: function(tipo) {
			return 'SessioneMatrice' + _.capitalize(tipo);
		}
	}
}

</script>