<template>
	<div>
		<b-input v-if="!readonly" :min="min" :max="max" type="number" size="sm" :step="step" v-model="test[field]" style="min-width:100px"></b-input>
		<div :style="style" v-else>{{test[field]}}</div>
	</div>
</template>


<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');

export default {
	props: [ 'test', 'field', 'options', 'dataLayer', 'readonly' ],
	data: function(){
		return {
			
		}
	},
	methods: {
		
	},
	computed: {
		min: function() {
			if(this.options.number_options != undefined && this.options.number_options.min != undefined)
				return this.options.number_options.min;
			return undefined;
		},
		max: function() {
			if(this.options.number_options != undefined && this.options.number_options.max != undefined)
				return this.options.number_options.max;
			return undefined;
		},
		step: function() {

			if(this.options.number_options != undefined && this.options.number_options.step != undefined)
				return this.options.number_options.step;
			return 0.01;

		},
		style: function() {

			var style = [];
			if(this.options.soglie) {

				var valoreDaConfrontare = parseFloat(this.test[this.field]);

				if(this.options.soglie.type == 'scala') {

					var sogliaScelta = _.filter(this.options.soglie.config, function(regola) {
						if(regola.min && regola.max)
							return valoreDaConfrontare >= regola.min && valoreDaConfrontare <= regola.max;
						else if(regola.min && !regola.max)
							return valoreDaConfrontare >= regola.min;
						else if(!regola.min && regola.max)
							return valoreDaConfrontare <= regola.max;
					});

					if(sogliaScelta.length > 0) {
						style.push(sogliaScelta[0].style);
					}


				}

			}

			style.push('display: inline-block; padding: 0.1em 0.4em; border-radius: 4px');
			return style.join(';');
			
		}
	}
}
	
</script>