<template id="cardpartita">
	<div class="cardpartita">
		<div class="row">
			<div class="col-7">
			</div>
			<div class="col-5 text-center">
				<span class="text-primary mr-1 font-size-lg">Report ({{partita.reports.length}}) </span> 
				<b-btn variant="success" title="Aggiungi report" class="mr-1" @click="aggiungiReport(partita.id, partita.partita.wyscout_id, partita.live)" v-if="editable && auth.isUserEnable('scout_add_report_match')"><i class="fa fa-plus" aria-hidden="true"></i></b-btn>	
			</div>
		</div>
		<div class="versus">
			<div class="row flex-items-xs-middle flex-items-xs-center text-sm-center">
				<div class="col-2">

					<div><b-img :src="partita.squadra1.logo_url" fluid :alt="partita.squadra1.officialName" :width="80"></b-img></div>
					<div>
						<span>{{ partita.squadra1.officialName }}</span>
						<span v-if="partita.stato == 'Played'" class="color-rosso pl-1">{{ partita.score_1 }}</span>
					</div>

				</div>
				<div class="col-1">

					<div class="versus__vs">
						<span>VS.</span>
					</div>

				</div>
				<div class="col-2">

					<div><b-img :src="partita.squadra2.logo_url" fluid :alt="partita.squadra2.officialName" :width="80"></b-img></div>
					<div>
						<span>{{ partita.squadra2.officialName }}</span>
						<span v-if="partita.stato == 'Played'" class="color-rosso pl-1">{{ partita.score_2 }}</span>
					</div>

				</div>
				<div class="col-1 text-center">
				</div>
				<div class="col-2 text-center">
					<div class="list-group p-1" v-if="partita.reports.length > 0 && partita.reports[currentReport - 1].reports_giocatori && partita.reports[currentReport - 1].reports_giocatori.length > 0">		
						<div class="row" v-for="report_giocatore in partita.reports[currentReport - 1].reports_giocatori">
							<div class="col-3">
								<img src="/assets/smiles/no_grad.png" width="18" @click="setGradimento(report_giocatore)" style="cursor:pointer" title="Setta gradimento" v-if="!report_giocatore.gradimento"/>
								<img :src="'/assets/smiles/grad_' + report_giocatore.gradimento.id + '.png'" width="18" :title="newscout && report_giocatore.gradimento.new_name ? report_giocatore.gradimento.new_name : report_giocatore.gradimento.nome" v-if="report_giocatore.gradimento"/>
							</div>	
							<div class="col-9 text-left list-group-item-action list-group-item-primary">
								<a :href="'#/' + (newscout ? 'newscout' : 'scouting') + '/view/giocatore/' + report_giocatore.giocatore.id" target="_blank">
									{{report_giocatore.giocatore.cognome}} {{report_giocatore.giocatore.nome.substr(0,1)}}.
								</a>
							</div>
						</div>

					</div>
				</div>
				<div class="col-4 text-center">
					<div v-if="partita.reports.length > 0" class="my-1">
						<div v-if="partita.reports[currentReport - 1].report" class="row align-items-center"><div class="col-8 text-center"><button type="button" class="btn btn-link" @click="prevPage">PREV</button><button type="button" class="btn btn-link" @click="nextPage">NEXT</button></div><div class="col-4 text-left" style="font-size:1rem;">{{currentPage}} di {{totalPages}}</div></div>
						<canvas v-if="partita.reports[currentReport - 1].report" :id="'pdf-canvas-' + partita.reports[currentReport - 1].id" width="150" style="cursor:zoom-in" @click="zoomReport"></canvas>
						<div v-if="!partita.reports[currentReport - 1].report" class="row align-items-center justify-content-center" style="height:150px;">
							<div class="col-8 text-center" style="background:#FFFFFF;">
								<div style="width:100%;padding-top:20px;padding-bottom:20px;">Nessun report associato</div>
							</div>
						</div>
						<div><button type="button" class="btn btn-success btn-sm mr-1" @click="downloadReport" title="Download report" v-if="auth.isUserEnable('scout_down_report_match') && partita.reports[currentReport - 1].report"><i class="fa fa-download" aria-hidden="true"></i></button><button type="button" class="btn btn-primary btn-sm" @click="eliminaReport" title="Elimina report" v-if="editable &&auth.isUserEnable('scout_del_report_match')"><i class="fa fa-trash" aria-hidden="true"></i></button></div>
						<div class="text-primary mt-1">{{partita.reports[currentReport - 1].utente.nomeCompleto}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-7 text-sm-center">
				{{ partita.data | formatDate }} / <strong>{{ partita.luogo }}</strong>
				<br/>
				<div v-if="partita.utente">inserita da: <strong>{{ partita.utente.nomeCompleto }}</strong></div>
			</div>
			<div class="col-5">
				<b-pagination v-if="partita.reports.length > 0" size="sm" align="center"  :total-rows="partita.reports.length" v-model="currentReport" :per-page="1">
				</b-pagination>
			</div>
		</div>
	</div>
</template>

<script>

import moment from 'moment'

var Auth = require('../../auth.js');

export default {
	props:{
		partita: {
			type: Object,
			required: true
		},
		editable: {
			type: Boolean,
			default: true
		},
		newscout: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			currentReport: 0,
			pdfDoc: null,
			totalPages: 0,
			currentPage: 0,
			loading: false,
		}
	},
	filters: {   
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				m.locale('it');
				return m.format('LLL'); 
			}
			return "";
		},   
	},
	methods: {
		loadReport() {
			if(this.partita.reports.length > 0 && this.partita.reports[this.currentReport - 1].report && this.partita.reports[this.currentReport - 1].report != "" && !this.loading) {
				this.loading = true;
				var report = this.partita.reports[this.currentReport - 1];
				this.$http.get(this.$store.state.apiEndPoint + '/pdfshow/' + report.report).then((response) => {
					if (response.ok)
					{
						var pdf_data = atob(response.data);
						PDFJS.getDocument({ data: pdf_data }).then((pdf_doc) => {
							this.totalPages = pdf_doc.numPages;
							this.pdfDoc = pdf_doc;
							this.showPage(1);
						});
					}
				}, (response) => {
				});
			}
		},
		aggiungiReport(id, ws_id, live) {
			this.$emit('aggiungiReport', id, ws_id, live);
		},
		showPage(page_no) {
			this.currentPage = page_no;
			this.pdfDoc.getPage(page_no).then((page) => {
				var report = this.partita.reports[this.currentReport - 1];
				var desiredWidth = document.getElementById('pdf-canvas-' + report.id).width;
				var viewport = page.getViewport(1);
				var scale = desiredWidth / viewport.width;
				var scaledViewport = page.getViewport(scale);
				document.getElementById('pdf-canvas-' + report.id).height = scaledViewport.height;
				var renderContext = {
					canvasContext: document.getElementById('pdf-canvas-' + report.id).getContext('2d'),
					viewport: scaledViewport
				};
				page.render(renderContext).then(() => {
					this.loading = false;
				});
			});
		},
		prevPage() {
			if(this.currentPage != 1) {
				this.showPage(this.currentPage - 1);
			}    
		},
		nextPage() {
			if(this.currentPage != this.totalPages) {
				this.showPage(this.currentPage + 1);
			}    
		},
		downloadReport() {
			window.location = this.$store.state.apiEndPoint + '/scouting/download/pdf/report/partita/' + this.partita.reports[this.currentReport - 1].id + "/" + Auth.user.id + "/" + Auth.user.api_token;
		},
		eliminaReport() {
			this.$emit('eliminaReport', this.partita.reports[this.currentReport - 1].id);
		},
		zoomReport() {
			this.$emit('zoomReport', this.pdfDoc, this.totalPages);
		},
		setGradimento(report_giocatore) {
			this.$emit('setGradimento', report_giocatore);
		},	
	},
	created: function () {
		if(this.partita.reports.length > 0) {
			this.currentReport = 1;     
		} 
	},
	watch: {
		currentReport: function() {
			this.loadReport();  
		},
		'partita.reports': function() {
			this.currentReport = 0;
			this.pdfDoc = null;
			this.totalPages = 0;
			this.currentPage = 0;
			if(this.partita.reports.length > 0) {
				this.currentReport = 1;     
			}
			this.loadReport();	 
		},
	},
	computed: {
		auth: function() {
			return Auth;
		},
	},
}
</script>