import moment from 'moment';

const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

export default {
  methods: {
    matchDateFormat(date) {
      return moment.utc(date).local().format(DATETIME_FORMAT);
    },
  },
};
