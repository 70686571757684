<template id="esercizicard">
	<div class="esercizicard h-100">

		<div class="card h-100" :id="'exe_' + ex.id">
			<router-link :to="'/' + (academy ? 'academyint' : 'academyita') + '/esercizio/'+ex.id" v-if="ex.video == ''">
				<img :src="image" class="card-img-top img-fluid" />
			</router-link>
			<b-embed type="video" controls v-if="ex.video != ''" :poster="image" preload="none" controlsList="nodownload" oncontextmenu="return false;">
				<source :src="ex.video" type="video/mp4">
				</b-embed>
				<div class="card-body">
					<b-row>
						<b-col>

							<p class="text-truncate mb-0" :title="ex.name"><strong>{{ex.name}}</strong></p>
							<p class="mb-0 font-size-sm text-truncate">{{ $i18n.t('di') }} <span class="color-rosso">{{ex.utente ? ex.utente.nomeCompleto : 'N/D' }}</span></p>
							<div v-if="!ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Principi di gioco')">PG:</span> <span class="color-rosso" :title="label_skills_1">{{ label_skills_1 }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Componenti tecniche')">CT:</span> <span class="color-rosso" :title="label_skills_2">{{ label_skills_2 }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Capacita motorie')">CM:</span> <span class="color-rosso" :title="label_skills_3">{{ label_skills_3 }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>
							</div>
							<div v-if="ex.portiere">
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Tecnica podalica')">TP:</span> <span class="color-rosso" :title="label_skills_4">{{ label_skills_4 }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Tecnica di base')">TB:</span> <span class="color-rosso" :title="label_skills_5">{{ label_skills_5 }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Qualità tattiche')">QT:</span><span class="color-rosso" :title="label_skills_6">{{ label_skills_6 }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span :title="$i18n.t('Capacità motorie')">CM:</span> <span class="color-rosso" :title="label_skills_7">{{ label_skills_7 }}</span></p>
							</div>
							<div class="text-center">
							<span class="color-rosso" v-if="ex.rifiutato" style="font-size: 1rem;"><strong>{{ $i18n.t('RIFIUTATO') }}</strong></span>
							</div>
						</b-col>
						<b-col cols="2">
							<b-button size="sm" :title="$i18n.t('Visualizza esercizio')" :to="{ name: academy ? 'academy_int_esercizio' : 'academy_ita_esercizio', params: { id: ex.id }}" variant="primary" v-if="ex.video != ''"><i class="fa fa-search" aria-hidden="true"></i></b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
	</template>

	<script>
	import { mapState } from 'vuex';
	var Auth = require('../../../auth.js');
	export default {
		props: {
			ex: { type: Object },

			skills: { type: Array },
			skills_portiere: { type: Array }
		},

		computed: {
			auth: function() {
				return Auth;
			},
			image: function() {
				return this.ex.canvas_url;
			},

			label_skills_1: function() {
				var label = "";
				if(this.ex.principiDiGioco && this.skills && this.skills.length > 0) {
					label = this.getLabel(this.ex.principiDiGioco, this.skills[0]);
				}
				return (label != "" ? label : "N/D");		
			},
			label_skills_2: function() {
				var label = "";
				if(this.ex.componentiTecniche && this.skills && this.skills.length > 0) {
					label = this.getLabel(this.ex.componentiTecniche, this.skills[1]);
				}
				return (label != "" ? label : "N/D");		
			},
			label_skills_3: function() {
				var label = "";
				if(this.ex.capacitaMotorie && this.skills && this.skills.length > 0) {
					label = this.getLabel(this.ex.capacitaMotorie, this.skills[2]);
				}
				return (label != "" ? label : "N/D");		
			},
			label_skills_4: function() {
				var label = "";
				if(this.ex.tecnicaPodalica && this.skills_portiere && this.skills_portiere.length > 0) {
					label = this.getLabel(this.ex.tecnicaPodalica, this.skills_portiere[0]);
				}
				return (label != "" ? label : "N/D");		
			},
			label_skills_5: function() {
				var label = "";
				if(this.ex.tecnicaDiBase && this.skills_portiere && this.skills_portiere.length > 0) {
					label = this.getLabel(this.ex.tecnicaDiBase, this.skills_portiere[1]);
				}
				return (label != "" ? label : "N/D");		
			},
			label_skills_6: function() {
				var label = "";
				if(this.ex.qualitaTattiche && this.skills_portiere && this.skills_portiere.length > 0) {
					label = this.getLabel(this.ex.qualitaTattiche, this.skills_portiere[2]);
				}
				return (label != "" ? label : "N/D");		
			},
			label_skills_7: function() {
				var label = "";
				if(this.ex.capacitaMotorie && this.skills_portiere && this.skills_portiere.length > 0) {
					label = this.getLabel(this.ex.capacitaMotorie, this.skills_portiere[3]);
				}
				return (label != "" ? label : "N/D");		
			},
			...mapState('acad_esercizi', ['academy']),
		},

		methods: {
			getLabel(id_skill, skills) {
				var label = "";
				var skill_items = skills.fields;
				for(var key in skill_items) {
					var skill = skill_items[key];
					if(skill.id == id_skill) {
						label = skill[this.$i18n.t('lang')];
						return label;
					}
					if(skill.fields) {
						var items = skill.fields;
						for(var key2 in items) {
							var subskill = items[key2];
							if(subskill.fields) {
								var items2 = subskill.fields;
								for(var key3 in items2) {
									var subskill2 = items2[key3];
									if(subskill2.id == id_skill) {
										label = skill[this.$i18n.t('lang')] + " -> " + subskill[this.$i18n.t('lang')] + " -> " + subskill2[this.$i18n.t('lang')];
										return label;
									}  
								}
							} else {
								if(subskill.id == id_skill) {
									label = skill[this.$i18n.t('lang')] + " -> " + subskill[this.$i18n.t('lang')];
									return label;
								} 		
							}
						}
					} else {
						if(skill.id == id_skill) {
							label = skill[this.$i18n.t('lang')];
							return label;
						}
					}
				}
				return label;
			},
		},


		data: function() {
			return {
				dummy: false,
			}
		},
	}
	</script>
