export default {
    exercises: [],
    exercises_waiting: [],
    exercises_asroma: [],
    exercises_certificati: [],
    exercises_da_validare: [],
    n_exercises: 0,
    n_exercises_waiting: 0,
    objective_ids: [],
    title: '',
    page: 1,
    exercises_tab: 0,
    drill_id: null,
};
