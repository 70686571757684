import axios from 'axios';

export default {
  fetchExercises({ commit }, filters) {
    return axios.get('exercises', { params: { filters } })
      .then((res) => {
        commit('setExercises', res.data);
        return Promise.resolve();
      });
  },

  deleteExercise({ commit }, id) {
    return axios.delete(`exercises/${id}`)
      .then(() => {
        commit('deleteExercise', id);
        return Promise.resolve();
      });
  },
};
