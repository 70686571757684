<template>
	<div>

		<b-tabs class="mb-2">
			<b-tab :title="raggruppamento" v-for="raggruppamento, k in theGruppiGroupedLab">
				<b-row v-for="gruppo in _.reverse(_.filter(theGruppiGrouped[raggruppamento], { supergroup: 1 }))">
					<b-col :cols="3">
						<b-button style="margin-bottom: 0.1em" block size="sm" :variant="gruppo.selected ? (gruppo.supergroup == 0 ? 'primary' : 'success') : (gruppo.supergroup == 0 ? 'outline-secondary' : 'outline-success')" @click.prevent="toggle(gruppo.id)">{{ gruppo.name | clean }}</b-button>
					</b-col>
					<b-col :cols="3" v-for="gg in gruppo.items">
						<b-button style="margin-bottom: 0.1em" block size="sm" :variant="gg.selected ? (gg.supergroup == 0 ? 'primary' : 'success') : (gg.supergroup == 0 ? 'outline-secondary' : 'outline-success')" @click.prevent="toggle(gg.id)">{{ gg.name | clean }}</b-button>
					</b-col>
				</b-row>
				<b-row class="mt-2" v-if="_.filter(theGruppiGrouped[raggruppamento], { supergroup: 0 })">
					<b-col :cols="3" v-for="gruppo in _.filter(theGruppiGrouped[raggruppamento], { supergroup: 0 })">
						<b-button style="margin-bottom: 0.1em" block size="sm" :variant="gruppo.selected ? (gruppo.supergroup == 0 ? 'primary' : 'success') : (gruppo.supergroup == 0 ? 'outline-secondary' : 'outline-success')" @click.prevent="toggle(gruppo.id)">{{ gruppo.name | clean }}</b-button>
					</b-col>
				</b-row>

				<div v-if="raggruppamento == 'Bilaterale'" class="mt-1">
					<hr />
					
					<b-row v-for="gruppo in _.reverse(_.filter(theGruppiGrouped['Sinistra'], { supergroup: 1 }))">
						<b-col :cols="3">
							<b-button style="margin-bottom: 0.1em" block size="sm" :variant="gruppo.selected ? (gruppo.supergroup == 0 ? 'primary' : 'success') : (gruppo.supergroup == 0 ? 'outline-secondary' : 'outline-success')" @click.prevent="toggleBilateral(gruppo.id)">{{ gruppo.name | clean }}</b-button>
						</b-col>
						<b-col :cols="3" v-for="gg in gruppo.items">
							<b-button style="margin-bottom: 0.1em" block size="sm" :variant="gg.selected ? (gg.supergroup == 0 ? 'primary' : 'success') : (gg.supergroup == 0 ? 'outline-secondary' : 'outline-success')" @click.prevent="toggleBilateral(gg.id)">{{ gg.name | clean }}</b-button>
						</b-col>
					</b-row>

				</div>

			</b-tab>
			
		</b-tabs>



		<label>Singole Zone</label>
		<multiselect v-model="ungroupedPartsStatic" :options="parts" :multiple="true" placeholder="Seleziona" label="name" track-by="id"></multiselect>

		<!-- <pre>{{theSupergruppi}}</pre>
		<pre>{{theGruppiGrouped}}</pre>
		<pre>{{supergruppi}}</pre>
		<pre>{{selected}}</pre>
		<pre>{{theGruppi}}</pre> -->
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
import faseMixin from '@/mixins/fase.js';

export default {
	props: [
		'parts',
		'supergruppi',
		'gruppi',
		'selected',
	],
	filters: {
		clean: function(a) {
			return _.truncate(a.replace('SX', '').replace('DX', ''), { length: 20 });
		}
	},
	data: function() {
		return {
			ungroupedPartsStatic: false
		}
	},
	watch: {
		ungroupedPartsStatic: function(a, b) {


			if(b.length > a.length) {

				var toRemove = [];
				var removed = _.difference(b,a);

				if(removed.length == 0) return false;

				this.$emit('unselect', _.map(_.filter(removed, function(e) {
					return e.isGroup == undefined;
				}), 'id'));

				var gruppiToRemove = _.filter(removed, function(e) {
					return e.isGroup != undefined;
				});

				_.each(gruppiToRemove, function(e) {
					_.each(e.group_ids, function(a) {
						toRemove.push(a);
					});
				});

				this.$emit('unselect', toRemove);

			} else {

				var toAdd = [];
				var added = _.difference(a,b);

				if(added.length == 0) return false;

				_.each(added, function(i) {
					if(i.isGroup == undefined)
						toAdd.push(i.id);
					else {
						_.each(i.group_ids, function(c) {
							if(toAdd.indexOf(c) == -1)
								toAdd.push(c);
						});
					}


				}.bind(this));

				// console.log(toAdd);
				
				this.$emit('select', toAdd);

			}

			//console.log(gruppiToRemove);

		},
		ungroupedParts: {
			immediate: true,
			handler: function() {
				this.ungroupedPartsStatic = this.ungroupedParts;
			}
		}
	},
	methods: {
		toggleBilateral: function(id) {

			var gruppo = _.find(this.theGruppi, { id });
			var alternate = _.find(this.theGruppi, { name: gruppo.name.replace('SX', 'DX') });

			this.toggle(gruppo.id);
			this.toggle(alternate.id);
			//console.log(gruppo, alternate);

		},
		toggle: function(id) {

			var gruppo = _.find(this.theGruppi, { id });
			if(!gruppo) return false;

			if(gruppo.selected == true) {

				var idsValidi = _.filter(gruppo.group_ids, function(a) {

					var isSelectedHowMany = _.filter(this.theGruppi, function(e) {
						return e.group_ids.indexOf(a) !== -1 && e.selected == true;
					});

					if(isSelectedHowMany.length <= 1 || gruppo.supergroup == 1) return true;
					return false;

				}.bind(this));

				this.$emit('unselect', idsValidi);

			} else {
				this.$emit('select', gruppo.group_ids);
			}

		},
		isSelected: function(id) {

			var gruppo = _.find(this.theGruppi, { id });
			if(!gruppo) return '!!!';
			return gruppo.selected;
			//.selected;

			//return _.find(this.selected, { id });
		}
	},
	computed: {
		_: function() {
			return _;
		},
		theGruppiGroupedLab: function() {
			return ['Destra', 'Sinistra', 'Bilaterale'];
		},
		theGruppiGrouped: function() {

			var waf_groups = _.filter(_.map(this.theGruppi, function(a) {
				if(a.parent_id != null) return false;

				a.items = _.filter(this.theGruppi, { parent_id: a.id });
				return a;
			}.bind(this)));



			return _.groupBy(waf_groups, function(e) {
				return e.name.indexOf('SX') != -1 ? 'Sinistra' : (e.name.indexOf('DX') != -1 ? 'Destra' : 'Bilaterale');
			});

		},
		multiParts: function() {

			return _.concat(this.theGruppi, this.parts);

		},
		ungroupedParts: function() {

			var gruppiFuoriLista = [];
			var ossiFuoriLista = _.filter(this.selected, function(item) {

				var id = item.id;

				var isInGroups = _.filter(this.theGruppi, function(i) {
					return i.group_ids.indexOf(id) != -1;
				});

				if(isInGroups.length == 0) return true;

				var selectedGroups = _.filter(isInGroups, { selected: true });
				if(selectedGroups.length > 0) {

					return false;
				}

				return true;

			}.bind(this));

			return _.concat(gruppiFuoriLista, ossiFuoriLista)

		},
		theSupergruppi: function() {
			return _.map(this.supergruppi, function(i) {

				// i.selected = ;

				return i;
			});
		},
		theGruppi: function() {

			return _.orderBy(_.map(this.gruppi, function(a) {

				//console.log(a.name, a.id);
				var sel = _.filter(a.group_ids, function(id) {
					return _.find(this.selected, { id: id });
				}.bind(this));
				//console.log(sel.length, a.group_ids.length);

				a.isGroup = true;
				a.selected = (sel.length == a.group_ids.length);

				return a;

			}.bind(this)), ['supergroup', 'name'], ['desc', 'asc']);
		}
	}
}

</script>