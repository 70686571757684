<template>
	<div class="d-flex flex-column" :style="'position: relative;' + (isToday ? 'background: #fcf8e3;' : '')" @click="dayClick" @drop="$emit('eventDrop', $event, day)" @dragover.prevent  @dragenter.prevent> 
		<!--div class="px-1 d-flex justify-content-between align-items-center"  style="border-bottom: 1px dashed rgba(0,0,0,.2);" :style="headStyle"-->
		<div class="px-1 d-flex justify-content-between align-items-center"  :style="headStyle">	
			<div>
				<span class="font-weight-bold ml-1 color-rosso" :style="!isCurrentMonth ? 'opacity: 0.3' : ''">{{ moment(day).format('D') }}</span>
			</div>
			<span title="Partita" style="cursor:pointer;" @click.stop="$emit('eventSelected', hasMatch)"><img v-if="hasMatch" :src="hasMatch.CalendarObject.icona ? hasMatch.CalendarObject.icona : '/assets/partite/icona-Torneo.png'" height="18" /></span>
			<!--span class="small color-rosso" v-if="focus">{{ moment(day).format('M/YYYY') }}</span-->
		</div>
		<div :style="eventsStyle" class="flex-grow-1 px-1" v-if="view != 'days' || (view == 'days' && isPortrait)">
			<EventoCalendario draggable v-for="a in events" :event="a" :isCurrentMonth="isCurrentMonth" @select="$emit('eventSelected', a)" :key="a.id" @dragstart="$emit('eventDrag', $event, a)"></EventoCalendario>
		</div>
		<div id="content_day" ref="content_day" :style="eventsStyle" class="flex-grow-1 px-1" v-else @drop="eventDayDrop" @dragover="eventDayDragOver">
			<b-row class="w-100">
				<b-col cols="0_5">	
					<div v-for="n in 24" :style="'position:absolute;width:100%;height:'+ (zoom * 60) +'px;top:' + ((n-1) * (zoom * 60) + 25) + 'px'" class="text-left pr-1 border-bottom" :id="n-1">	
						<hr id="todayMinute" :style="'border-top:1px solid red;margin:0;position:relative;top:' + (zoom * moment().minute()) + 'px'" v-if="isToday && moment().hour() == n - 1"/>
						{{ moment().set('hour', n - 1).set('minute', 0).format('HH:mm') }}
					</div>
				</b-col>
				<b-col v-for="c, index in max_cols">	
					<div draggable v-for="item in _.filter(map_events, { columnIndex: index })" :style="getCssEvento(item.event.CalendarObject.id)" @click.stop="$emit('eventSelected', item.event)" @dragstart="eventDayDrag($event, item.event)" :id="item.event.id">
						{{ moment(item.event.CalendarObject.inizio).format('HH:mm') }} - {{ moment(item.event.CalendarObject.fine).format('HH:mm') }} {{ item.event.CalendarObject.nome }} <div style="float:right">{{ item.event.CalendarObject.operatore }}</div>
					</div>
				</b-col>
			</b-row>
		</div>
		<div style="position: absolute; bottom: 0" class="px-1" v-if="events && !focus && view == 'months'">
			<a href="#" @click.stop.prevent="$emit('onFocus')" class="text-muted small px-1 d-inline-block" style="background-color: white">
				{{ ux.collapsed ? (events.length || 0) + ' Eventi' : 'Collassa' }}
			</a>
		</div>
		<div v-else-if="focus && view == 'months'">
			<a href="#" @click.stop.prevent="$emit('outFocus')" class="text-muted small px-1 d-inline-block" style="background-color: white">
				Collassa
			</a>
		</div>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	props: [ 'day', 'events', 'focus', 'selected', 'view', 'zoom', 'focusDate', 'isPortrait'],
	mixins: [ pageBase ],
	components: {
		EventoCalendario: require('@/components/layout/Sessioni/Calendario/Event.vue').default,
	},
	watch: {
		view: {
			immediate: true,
			handler: function() {
				if(this.view != 'months') {
					this.ux.collapsed = false;
				} else {
					this.ux.collapsed = true;
				}
			}
		},
		events() {
			if(this.view == 'days'  && !this.isPortrait) {
				if(this.isToday) {
					var top = this.moment().hour() * (this.zoom * 60);
					_.delay(function(){
							this.$emit('scrollTo', top);
						}.bind(this), 100);
				} else if(this.events && this.events.length > 0) {
					var elem = this.map_events[this.events[0].CalendarObject.id];
					if(elem) {
						var top = elem.startMinute * this.zoom;
						_.delay(function(){
							this.$emit('scrollTo', top);
						}.bind(this), 100);
					}
				}
			}
		}
	},
	computed: {
		isToday() {
			return this.moment().format('YYYY-MM-DD') == this.day;
		},
		isCurrentMonth() {
			return this.moment(this.focusDate).format('YYYY-MM') == this.moment(this.day).format('YYYY-MM');
		},
		hasMatch() {
			if(this.events && this.events.length > 0) {
				var match = this.events.find((item) => item.CalendarObject.sessione_tipo_glo_id == 15);
				if(match != undefined) {
					return match;
				}
			}
			return false;
		},

		headStyle: function() {

			var s = [];

			s.push('position: sticky');
			s.push('top: 0');
			s.push('z-index: 3');
			if(this.moment().format('YYYY-MM-DD') == this.day) {
				s.push('background-color: #fcf8e3');
			} else {
				s.push('background-color: white');
			}

			return s.join('; ');

		},
		eventsStyle: function() {
			var s = [];

			if(this.ux.collapsed == false)
				s.push('overflow-y: auto');
			else
				s.push('overflow-y: hidden');

			if(this.view == 'days' && !this.isPortrait) {
				s.push('height:' + ((this.zoom * 60) * 24) + 'px');
			}
			
			return s.join('; ');
		},
		count_slot_cols: function() {
			var count_slots = {};
			if(this.view == 'days' && !this.isPortrait) {
				for(var i = 0; i < this.slots; i++) {
					count_slots[(i * 5) + '-' + ((i+1) * 5)] = 0;
				}
				for(var i in this.events) {
					var evento = this.events[i];
					var startMinute = (this.moment(evento.CalendarObject.inizio).hour()*60) + this.moment(evento.CalendarObject.inizio).minute();
					var endMinute = (this.moment(evento.CalendarObject.fine).hour()*60) + this.moment(evento.CalendarObject.fine).minute();
					for(var x = startMinute ; x < endMinute ; x += 5) {
						var foundKey = Object.keys(count_slots).find(key => (x >= key.split('-')[0] && x < key.split('-')[1]));
						count_slots[foundKey] += 1;
					}		
				} 
			}
			return count_slots;
		},
		max_cols: function() {
			return _(this.count_slot_cols).values().max();
		},
		map_events: function() {
			var map_events = {};
			var slots = {};
			if(this.view == 'days' && !this.isPortrait) {

				for(var i = 0; i < this.slots; i++) {
					slots[(i * 5) + '-' + ((i+1) * 5)] = [];
					for(var x = 0; x < this.max_cols; x++) {
						slots[(i * 5) + '-' + ((i+1) * 5)].push(0);
					}
				}
				for(var i in this.events) {
					var evento = this.events[i];
					var startMinute = (this.moment(evento.CalendarObject.inizio).hour()*60) + this.moment(evento.CalendarObject.inizio).minute();
					var endMinute = (this.moment(evento.CalendarObject.fine).hour()*60) + this.moment(evento.CalendarObject.fine).minute();
					var startIndex = -1;
					for(var x = startMinute ; x < endMinute ; x += 5) {
						var foundKey = Object.keys(slots).find(key => (x >= key.split('-')[0] && x < key.split('-')[1]));
						if(startIndex == -1) {
							for(var y = 0; y < this.max_cols; y++) {
								if(slots[foundKey][y] == 0) {
									startIndex = y;
									map_events[evento.CalendarObject.id] = {
										columnIndex: startIndex,
										startMinute: startMinute,
										endMinute: endMinute,
										event: evento,
										count_slot: 0
									}
									break;
								}
							}
						}
						slots[foundKey][startIndex] = evento.CalendarObject;
						map_events[evento.CalendarObject.id].count_slot += 1;
					}


				}
			}
			return map_events;
		},
	},
	methods: {
		getCssEvento: function(id) {
			var s = [];
			s.push('position:absolute');
			s.push('z-index:10');
			var elem = this.map_events[id];
			var top = elem.startMinute * this.zoom;
			s.push('top:' + top + 'px');
			var height = (elem.endMinute - elem.startMinute) * this.zoom;
			s.push('height:' + height + 'px');
			s.push('background-color:' + elem.event.CalendarObject.colore);
			s.push('width:99%');
			s.push('color:#FFFFFF');
			s.push('border-radius:3px');
			s.push('font-size: 12px');
			s.push('cursor: pointer');
			return s.join('; ');
		},
		eventDayDrag(evt, item) { 
			var img = document.createElement("img");   
			img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
			img.width = 0;
			img.height = 0;
			img.opacity = 0;
			evt.dataTransfer.setDragImage(img, 0, 0);
			evt.dataTransfer.dropEffect = 'move';      
			evt.dataTransfer.effectAllowed = 'move';   
		//	evt.dataTransfer.setData('itemID', item.id); 
			this.evt.id = item.id;
			this.evt.offsetTop = evt.target.offsetTop;
			this.evt.clientY = evt.clientY;
		}, 

		eventDayDragOver(evt) {
			var startMinute = Math.round(this.evt.offsetTop/this.zoom);
			var minuteDiff = (this.evt.clientY - evt.clientY)/this.zoom;
			var newStartMinute = startMinute - minuteDiff;

			var foundKey = Object.keys(this.count_slot_cols).find(key => (newStartMinute >= key.split('-')[0] && newStartMinute < key.split('-')[1]));
			if(foundKey == undefined) return false;
			var hour = foundKey.split('-')[0] / 60;
			var minute = 0;
			if(!Number.isInteger(hour)) {
				minute = Math.round(parseFloat( '0.' + hour.toString().split('.')[1]) * 60);
				hour = Math.trunc(hour);
			}
		//	console.log('hour: ' + hour);
		//	console.log('minute: ' + minute);
			const item = this.events.find((item) => item.id == this.evt.id);  

			var newInizio = this.moment(this.day + ' ' + hour + ":" + minute);
			var currentInizio = this.moment(this.day + ' ' + this.moment(item.CalendarObject.inizio).format("HH:mm:ss"));

			var duration = this.moment.duration(newInizio.diff(currentInizio));
			var diffHours = duration.hours();
			var diffMinutes = duration.minutes();

			item.CalendarObject.inizio = this.moment(item.CalendarObject.inizio).add(diffHours, 'hours').add(diffMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
			item.CalendarObject.fine = this.moment(item.CalendarObject.fine).add(diffHours, 'hours').add(diffMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
		}, 

		eventDayDrop(evt) { 
		//	const itemID = evt.dataTransfer.getData('itemID');      
			const item = this.events.find((obj) => obj.id == this.evt.id); 
			if(item == undefined) return false;  
			if(item && item.CalendarObject.interactive == false) return false; 

			this.$http.post(this.$store.state.apiEndPoint + '/' + item.CalendarObject.controller + '/sposta-evento/' + item.id, {
				inizio: item.CalendarObject.inizio,
				fine: item.CalendarObject.fine,
			}).then(function(data) {
				this.$emit('refresh');
			});
		},
		dayClick(evt) {
			if(this.view != 'days' || (this.view == 'days' && this.isPortrait)) {
				this.$emit('dayClick', this.day);
			} else {
				var minute = (evt.target.offsetTop  / this.zoom) + (evt.layerY / this.zoom);
				var foundKey = Object.keys(this.count_slot_cols).find(key => (minute >= key.split('-')[0] && minute < key.split('-')[1]));
				var hour = foundKey.split('-')[0] / 60;
			//	var minute = 0;
				if(!Number.isInteger(hour)) {
				//	minute = Math.round(parseFloat( '0.' + hour.toString().split('.')[1]) * 60);
					hour = Math.trunc(hour);
				} 
			//	var time = (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ':00';
				var time = (hour < 10 ? '0' + hour : hour) + ':00:00';
				this.$emit('dayClick', this.day, time);
			}
		}
	},
	data: function() {
		return {
			slots: 96*3,
			ux: {
				collapsed: true,
			},
			evt: {
				id: null,
				offsetTop: null,
				clientY: null,
			}
		}
	}, 
}

</script>