<template>
	<div>
		<b-card no-body class="p-1 px-2">
			<b-row>
				<b-col cols="1" class="align-items-center d-flex text-center justify-content-end">
					<div style="font-size: 120%" class="font-weight-bold mr-2">{{ fase.order + 1 }}</div>
				</b-col>
				<b-col cols="3" class="align-items-center d-flex" style="line-height: 1.25">
					<strong>{{ fase.nome }}</strong>
				</b-col>
				<b-col class="align-items-center d-flex small" cols="8">
					{{ fase.entity.nota }}
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

export default {
	components: {
		FisioItemsSpan: require('@/components/layout/Sessioni/Medical/FisioItemsSpan.vue').default,
	},
	mixins: [
	],
	props: [ 'fase' ],
	computed: {
		zoneDelCorpo: function() {
			if(this.fase.Fase_Ext_BodyParts && this.fase.Fase_Ext_BodyParts.body_parts) {
				return _.uniq(_.map(this.fase.Fase_Ext_BodyParts.body_parts, 'group_name'));
			}
			return false;
		},
		checked: function() {
			var p = _.uniq(_.map(this.fase.persone, 'pivot.checked'));

			if(p.length == 1) {
				if(p[0] == true) return 1;
				else if(p[0] == false) return -1;
			}

			return null;

		}
	},
	filters: {
	}
}	

</script>