<template>
	<section class="players-matches">
		<headapp></headapp>
		<menusub></menusub>
		<ToolsAnalysis />
		<footerapp></footerapp>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
export default {
	mixins: [ pageBase ],
	components: {
		'ToolsAnalysis': require('@/components/layout/Sessioni/Generica/Tools_Table.vue').default
	},
};
</script> 
