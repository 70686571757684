<template>
	<div>
		<b-card border-variant="success" class="mb-2">
			<template slot="header">Variabili del giocatore per la Stagione</template>
			<b-row>
				<b-col>
					<b-checkbox :value="1" :unchecked-value="null" v-model="realPer.migliormarcatore_seriea" switch>Miglior Marcatore Campionato <code class="small">migliormarcatore_seriea</code></b-checkbox>
					<b-checkbox :value="1" :unchecked-value="null" v-model="realPer.migliormarcatore_coppa_italia" switch>Miglior Marcatore Coppa Italia <code class="small">migliormarcatore_coppa_italia</code></b-checkbox>
					<hr />
					<b-checkbox :value="1" :unchecked-value="null" v-model="realPer.migliorgiocatore_campionato" switch>Miglior Giocatore Campionato <code class="small">migliorgiocatore_campionato</code></b-checkbox>
					<hr />
					<b-form-group>
						<template slot="label">
							Presenze Nazionale <code class="small">presenze_nazionale</code>
						</template>
						<b-input type="number" v-model.number="realPer.presenze_nazionale" step="1"></b-input>
					</b-form-group>
					<b-checkbox :value="1" :unchecked-value="null" v-model="realPer.elegibile" switch>Elegibile <code class="small">elegibile</code></b-checkbox>
				</b-col>
				<b-col>
					<b-checkbox :value="1" :unchecked-value="null" v-model="realPer.migliormarcatore_uel" switch>Miglior Marcatore UEL <code class="small">migliormarcatore_uel</code></b-checkbox>
					<b-checkbox :value="1" :unchecked-value="null" v-model="realPer.migliormarcatore_ucl" switch>Miglior Marcatore UCL <code class="small">migliormarcatore_ucl</code></b-checkbox>
					<b-checkbox :value="1" :unchecked-value="null" v-model="realPer.migliormarcatore_uecl" switch>Miglior Marcatore UECL <code class="small">migliormarcatore_uecl</code></b-checkbox>
				</b-col>
			</b-row>

		</b-card>
	</div>
</template>

<script>


export default {
	props: [ 'periodo', 'periodi' ],
	computed: {
		realPer: function() {
			return _.find(this.periodi, { id: this.periodo });
		}
	},
	methods: {
		applyRow: function() {

			this.$emit('netto', this.periodo.importo_netto);
			this.$emit('lordo', this.periodo.importo_lordo);

		}
	}
};

</script>