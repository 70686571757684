<template>
	<div>

		<b-row>
			<b-col :cols="6">
				<strong>Sessioni</strong>
				<hr />
				<ul>
					<li v-for="sessione in sessioni">
						<label><input type="checkbox" v-model="waitSessioni" :value="sessione.id" /> {{ sessione.nome }}</label>
					</li>
				</ul>
			</b-col>
			<b-col :cols="6">
				<strong>Fasi</strong>
				<hr />
				<ul>
					<li v-for="fase,k in fasi">
						<label><input type="checkbox" v-model="waitFasi" :value="k" /> {{ k }}</label>
					</li>
				</ul>
			</b-col>
		</b-row>
		<hr />
		
		<!--
		<b-row>
			<b-col :cols="4" v-for="estensione,k in query.sessioneEstensione" v-if="query.sessioneEstensione">
				<strong>{{k}}</strong>
				<QueryEstensione :manifest="manifestEstensioni[k]" :estensione="estensione"></QueryEstensione>
				<pre>{{estensione}}</pre>
			</b-col>
		</b-row>
		-->

		<b-row v-if="query.fase">
			<b-col :cols="4" v-for="fase,k in query.fase">
				<strong>{{k}}</strong>
				<QueryFase :manifest="manifest.fasi[k]" :fase="fase"></QueryFase>
			</b-col>
		</b-row>

		<pre>{{query}}</pre>

	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	props: ['manifest', 'query'],
	components: {
		'QueryFase': require('@/components/layout/reports/QueryFase.vue').default,
		'QueryEstensione': require('@/components/layout/reports/QueryEstensione.vue').default
	},
	data: function() {
		return {
			waitSessioni: [],
			waitFasi: [],
		}
	},
	watch: {
		waitFasi: function() {

			_.each(this.waitFasi, function(fase) {

				if(this.query.fase == undefined)
					this.$set(this.query, 'fase', {});
				
				if(this.query.fase[fase] == undefined) {
					var manifestFase = _.keys(this.manifest.fasi[fase]);
					this.$set(this.query.fase, fase, {});
					_.each(manifestFase, function(k) {
						if(this.manifest.fasi[fase][k].hideFilter == undefined || this.manifest.fasi[fase][k].hideFilter == false)
							this.$set(this.query.fase[fase], k, {
								condition: ( this.manifest.fasi[fase][k].multiple ? 'IN' : '=' ),
								value: null
							});
					}.bind(this));
				}

			}.bind(this));

			_.each(this.query.fase, function(a,k) {
				if(this.waitFasi.indexOf(k) == -1) delete(this.query.fase[k]);
			}.bind(this));

			if(_.size(this.waitFasi) == 0)
				delete(this.query.fase);

		},
		waitSessioni: function() {

			if(this.query.sessione == undefined)
				this.$set(this.query, 'sessione', {});

			if(this.waitSessioni.length == 1) {
				this.query.sessione = {
					sessione_tipo_glo_id: {
						condition: '=',
						value: this.waitSessioni
					}
				};
			} else if(this.waitSessioni.length > 1) {
				this.query.sessione = {
					sessione_tipo_glo_id: {
						condition: 'IN',
						value: this.waitSessioni
					}
				};
			} else {
				delete this.query.sessione;
			}

			if(this.waitSessioni.length > 0) {

				var estensioniToKeep = [];
				_.each(this.waitSessioni, function(a) {
					var s = _.find(this.manifest.sessioni, { id: a });
					estensioniToKeep.push(s.estensione);
				}.bind(this));
				var estensioniToKeep = _.uniq(_.filter(_.flatten(estensioniToKeep)));

				_.each(this.query.sessioneEstensione, function(a,k) {
					if(estensioniToKeep.indexOf(k) == -1)
						delete this.query.sessioneEstensione[k];
				}.bind(this));

				if(_.size(this.query.sessioneEstensione) == 0)
					delete this.query.sessioneEstensione;

			} else {
				delete this.query.sessioneEstensione
			}

			_.each(this.waitSessioni, function(sessione) {

				var tipo = _.find(this.manifest.sessioni, { id: sessione });
				if(tipo.intestazioneEstensione != undefined) {

					if(this.query.sessioneEstensione == undefined)
						this.$set(this.query, 'sessioneEstensione', {});

					if(this.query.sessioneEstensione[tipo.estensione] == undefined)
						this.$set(this.query.sessioneEstensione, tipo.estensione, {});

					_.each(tipo.intestazioneEstensione, function(a,k) {
						this.$set(this.query.sessioneEstensione[tipo.estensione], k, null);
					}.bind(this))


				}

			}.bind(this));

		}
	},
	computed: {
		manifestEstensioni: function() {
			var o = {};
			_.each(this.manifest.sessioni, function(s) {
				if(s.intestazioneEstensione)
					o[s.estensione] = s.intestazioneEstensione;
			});

			return o;
		},
		sessioni: function() {
			return this.manifest.sessioni;
		},
		fasi: function() {

			if(this.waitSessioni.length > 0) {

				var tipologie = this.query.sessione.sessione_tipo_glo_id.value;
				if(!_.isArray(tipologie)) {
					var fasiNomi = _.find(this.manifest.sessioni, { id: tipologie }).tipologieFasi;
				} else {

					var fasiNomi = [];
					_.each(tipologie, function(a) {
						var nome = _.find(this.manifest.sessioni, { id: a });
						fasiNomi.push(nome.tipologieFasi);
					}.bind(this));
					fasiNomi = _.uniq(_.flatten(fasiNomi));

				}

				var out = {};
				for(var a in fasiNomi) {
					out[fasiNomi[a]] = this.manifest.fasi[fasiNomi[a]];
				}

				return out;

			}
			
			return this.manifest.fasi;

		},
		start: function() {
			return _.size(this.query) == 0;
		}
	}
}

</script>