<template>
	<section>
		<headapp></headapp>
		<div class="card">
			<div class="card-body">
				<div class="content">
					<div class="card-title d-flex">
						<span class="mr-auto">Arbitri ({{ filtered_arbitri.length }})</span>
					</div>
					<div v-if="loading" align="center" style="width:100%"><img src="/assets/varie/loading.gif" style="width: 150px;" /></div>
					<b-row class="my-1">
						<b-col cols="4">
							<b-form-input
							type="search"
							v-model="search_arbitro"
							placeholder="Quick search"
							></b-form-input>
						</b-col>
						<b-col>
							<b-button variant="outline-primary" @click="addArbitro">Aggiungi arbitro <i class="fa fa-plus-circle"></i></b-button>
						</b-col>
					</b-row>
					<div class="row">
						<div v-for="item in filtered_arbitri" :key="item.id" class="col-2">
							<div class="figurinagiocatore h-100 pb-2" :id="'arbitro_'+item.id">
								<div class="card mb-2 h-100" style="cursor:pointer" @click="selectArbitro(item)">
									<div class="row">
										<div class="col-12 text-center">
											<b-img-lazy :src="item.avatar_url" height="200"></b-img-lazy>
										</div>
									</div>

									<div class="card-body p-2">	
										<div class="font-bold text-center">{{ item.cognome }} {{ item.nome }}</div>
										<div class="mb-1 text-center font-size-xs color-rosso">{{ item.arbitro.sezione }}</div>
										<div class="mb-1 text-center font-size-xs">{{ item.arbitro.stato }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="arbitroModal" ref="arbitroModal" :title="arbitro && arbitro.id ? 'Arbitro' : 'Aggiungi arbitro'" ok-only ok-title="Chiudi" @shown="updateRandom">

			<b-form-group :key="'avt_' + random">
				<picture-input ref="pictureArbitro" @change="onAvatarChange" width="150" height="150" size="10" class="my-1" :customStrings="customStrings" :prefill="avatar"></picture-input>
			</b-form-group>
			<b-form-group label="Cognome">
				<b-form-input v-model="arbitro.cognome"></b-form-input>
			</b-form-group>
			<b-form-group label="Nome">
				<b-form-input v-model="arbitro.nome"></b-form-input>
			</b-form-group>
			<b-form-group label="Sezione">
				<b-form-input v-model="arbitro.sezione"></b-form-input>
			</b-form-group>
			<b-form-group label="Stato">
				<b-form-input v-model="arbitro.stato"></b-form-input>
			</b-form-group>
			<template #modal-footer>
				<b-row class="mt-2">
					<b-col>
						<b-button variant="outline-primary" @click="salvaArbitro">Salva</b-button>
						<b-button v-if="arbitro && arbitro.id" variant="outline-secondary" @click="eliminaArbitro">Elimina</b-button>
						<b-button variant="secondary" @click="chiudiModal">Chiudi</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<footerapp></footerapp>
	</section>
</template>

<script>
var Auth = require('@/auth.js');
import axios from 'axios';
import * as types from '@/store/types';
import { mapState } from 'vuex';
export default {
	data: function(){
		return {
			arbitri: [],
			loading: false,
			errors: [],
			search_arbitro: '',
			arbitro: {
				id: 0,
				nome: null,
				cognome: null,
				sezione: null,
				stato: null,
			},
			image_arbitro: '',
			file_avatar: '',
			customStrings: {
				drag: this.$i18n.t('drag'),
				fileSize: this.$i18n.t('fileSize'),
				fileType: this.$i18n.t('fileType'),
			},
			random: Math.random(),
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		avatar: function() {
			if(this.image_arbitro != "") {
				return this.image_arbitro;
			} 
			return '';
		},
		filtered_arbitri: function () {
			if (this.search_arbitro != "") {
				return this.arbitri.filter((item) =>
					(item.cognome && item.cognome.toLowerCase().includes(this.search_arbitro.toLowerCase())) ||
					(item.nome && item.nome.toLowerCase().includes(this.search_arbitro.toLowerCase())) ||
					(item.arbitro.sezione && item.arbitro.sezione.toLowerCase().includes(this.search_arbitro.toLowerCase()))
					);
			}
			return this.arbitri;
		},
	},
	watch: {
	},
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
		pictureInput: require('../../layout/picture_input.vue').default,
	},
	created: function () {
		this.getArbitri();
	},
	methods: {
		getArbitri: function() {				
			this.arbitri = [];
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/arbitri').then((response) => {
				if (response.ok)
				{
					if(response.data) {
						this.arbitri = response.data;
					}
				}
				this.loading = false;
			}, (response) => {
			});
		},

		addArbitro() {
			this.arbitro.id = 0;
			this.arbitro.nome = null;
			this.arbitro.cognome = null;
			this.arbitro.sezione = null;
			this.arbitro.stato = null;
			this.image_arbitro = '';
			this.file_avatar = '';
			this.$refs.arbitroModal.show();
		},

		selectArbitro(item) {
			this.arbitro.id = item.id;
			this.arbitro.nome = item.nome;
			this.arbitro.cognome = item.cognome;
			this.arbitro.sezione = item.arbitro.sezione;
			this.arbitro.stato = item.arbitro.stato;
			this.image_arbitro = item.avatar ? item.avatar_url : null;
			this.file_avatar = '';
			this.$refs.arbitroModal.show();
		},

		salvaArbitro() {
			if(!this.arbitro.cognome || this.arbitro.cognome == '') {
				alert("Campo 'Cognome' obbligatorio!");
				return;
			}
			if(!this.arbitro.nome || this.arbitro.nome == '') {
				alert("Campo 'Nome' obbligatorio!");
				return;
			}
			if(!this.arbitro.sezione || this.arbitro.sezione == '') {
				alert("Campo 'Sezione' obbligatorio!");
				return;
			}
			var formData = new FormData();
			formData.append("form", JSON.stringify(this.arbitro));
			formData.append("file_avatar", this.file_avatar);
			this.$http.post(this.$store.state.apiEndPoint + '/arbitri/salva', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getArbitri();
					this.$refs.arbitroModal.hide();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});

		},

		eliminaArbitro() {
			if(confirm("Confermi l'eliminazione dell'arbitro ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/arbitri/elimina/' + this.arbitro.id).then((response) => {
					if (response.ok) {
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.getArbitri();
						this.search_arbitro = '';
						this.$refs.arbitroModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		updateRandom() {
			this.random = Math.random();
		},

		chiudiModal() {
			this.$refs.arbitroModal.hide();
		},

		onAvatarChange(e) {
			if (this.$refs.pictureArbitro.file) {
				this.file_avatar = this.$refs.pictureArbitro.file;
			} else {
				console.log('FileReader Player API not supported: use the <form> 2')
			}
		},
	}
}
</script>
