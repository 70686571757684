<template>
	<section class="players-matches">
		<b-overlay :show="loading" no-wrap></b-overlay>
		<b-card :title="title" :no-body="isWidget ? true : false">
			
			<b-row class="my-2" align-v="center" v-if="!isWidget || isFullscreen">
				<b-col cols="2" class="pl-2">
					<b-datepicker v-model="ux.from" placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" size="sm" class="flex-grow-1 mr-1"></b-datepicker>
				</b-col>
				<b-col class="text-right">
					<label><input type="checkbox" v-model="ux.hideDisabled" /> Gestisci Sessioni Nascoste</label>
					<b-button-group class="mx-2">
						<b-button v-if="ux.hideDisabled" variant="outline-success" size="xs" class="mr-0 " @click.prevent="saveSessioni(hidePreset)">Aggiorna</b-button>
						<b-button v-if="ux.hideDisabled" variant="outline-warning" size="xs" @click.prevent="svuotaSessioni()">Svuota</b-button>
					</b-button-group>
					<b-button size="sm" variant="primary" title="Download" class="mx-2" @click="download"><i class="fa fa-download" aria-hidden="true"></i></b-button>
				</b-col>
			</b-row>
			<div class="d-flex align-items-center justify-content-between"><div class="pl-3"><img src="/assets/images/logo_new.png" height="40px;" /></div><div class="font-weight-bold" style="font-size: 2rem;line-height: 1;color: rgba(153,10,44, 1);">PREVENZIONE</div><div class="pr-3"><clock/></div></div>
			<div class="d-flex" :style="'width:100%;' + (contentHeight ? 'max-height: ' + (contentHeight - 10) + 'px;overflow:auto;' : '')">
				<div v-if="sessioniToShow.length == 0" class="text-center mt-2"  style="width:100%"><h5>Nessuna sessione trovata!</h5></div>
				<div v-for="gruppoSessione, key in sessioniGroupedByOra" :style="'padding: 5px 10px 5px 10px; width:' + getWidthGruppo(key) + '%'">
					<div class="text-center" style="width:100%;background-color:orange"><h4>{{ moment(key).format('HH:mm') }}</h4></div>
					<div class="d-flex" style="width:100%">
						<div class="text-center" v-for="sessione in gruppoSessione" :style="'padding: 5px 10px 5px 10px; width:' + getWidthSessioneGruppo(key) + '%;' + (ux.hideDisabled == true ? (( hidePreset.indexOf(sessione.id) !== -1 ) ? 'border: 2px solid #dc3545;':'border: 2px solid #28a745;') : '')">
							<div v-if="ux.hideDisabled"><input type="checkbox" v-model="hidePreset" :value="sessione.id" /></div>
							<img :src="sessione.operatore ? sessione.operatore.board_avatar_url : '/assets/varie/profile.jpg'" class="img-fluid" style="object-fit: contain; width: 100px; height: 100px; border: 1px solid rgba(0,0,0,.2); border-radius: 100%; box-shadow: 0px 0px 10px rgba(0,0,0,.1)" />
							<div>{{ sessione.operatore ? sessione.operatore.cognome + " " + sessione.operatore.nome[0] : '&nbsp;' }}</div>	
							<div class="text-center my-2" style="width:100%;background-color:#CCCCCC;"><h5 style="padding: 1px 0px 3px 0px;">{{ getNomeSessione(sessione) }}</h5></div>
							<div class="d-flex flex-wrap d-flex justify-content-center">
								<div v-for="player in sessione.persone" class="m-2" @click="getFasiPlayer(player.id, sessione.id)" style="cursor:pointer">
									<img :src="player.board_avatar_url" class="img-fluid" style="object-fit: contain; width: 100px; height: 100px; border: 1px solid rgba(0,0,0,.2); border-radius: 100%; box-shadow: 0px 0px 10px rgba(0,0,0,.1)" />
									<div>{{ player.cognome + " " + player.nome[0] + '.' }}</div>		
								</div>	
							</div>					
						</div>
					</div>
				</div>
			</div>
		</b-card>
		<b-modal ref="fasiModal" size="xl" ok-only ok-title="Chiudi">
			<div class="text-center" style="width:100%" v-if="player">
				<img :src="player.board_avatar_url" class="img-fluid" style="object-fit: contain; width: 100px; height: 100px; border: 1px solid rgba(0,0,0,.2); border-radius: 100%; box-shadow: 0px 0px 10px rgba(0,0,0,.1)" />
				<div>{{ player.cognome + " " + player.nome[0] + '.' }}</div>	
			</div>
			<div class="d-flex">
				<div class="flex-grow-1">
					<div class="my-1" v-for="fase in fasi" :fase="fase" :is="componentCalendar(fase)"></div>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script>

import pageBase from '@/mixins/pageBase.js';
import calendarFasi from '@/mixins/calendarFasi.js';
var Auth = require('@/auth.js');
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	mixins: [ pageBase, calendarFasi ],
	props: ['isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	data: function() {
		return {
			loading: false,
			sessioni: [],
			columns: [],
			ux: {
				from: moment().format('YYYY-MM-DD'),
				to: null,
				hideDisabled: false,
			},
			hidePreset: null,
			fasi: [],
			player: null,
		}
	},
	components: {
		'clock': require('@/components/layout/clock.vue').default,
	},
	mounted() {
		this.fetchSessioni();
	},
	created: function () {
		if(this.isWidget) {
			if(this.configWidget && this.configWidget.hideSessions) {
				this.hidePreset = this.configWidget.hideSessions;
			} else {
				this.hidePreset = [];
			}
		} else if(this.auth.presets().sessioniPrevenzioneHide != undefined) {
			this.hidePreset = _.cloneDeep(this.auth.presets().sessioniPrevenzioneHide)
		} else {
			this.hidePreset = [];
		}
	},
	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		...mapState(['rosaTeamId']),

		sessioniToShow: function() {
			if(this.ux.hideDisabled == false) {
				var dati = _.filter(this.sessioni, function(a) {
					return this.hidePreset.indexOf(a.id) == -1;
				}.bind(this));
			} else {
				var dati = this.sessioni;
			}
			return _.sortBy(dati, ['inizio']);
		},

		sessioniGroupedByOra: function() {
			return _.groupBy(this.sessioniToShow, 'inizio');
		},

		title: function() {
			return 'Prevenzione del ' + moment(this.ux.from).format('DD/MM/YYYY');
		}
	},
	watch: {
		'refresh': {
			immediate: true,
			handler: function() {
				if(this.refresh && this.refresh > 0) {
					_.delay(this.refreshData, this.refresh * 60000);
				}
			}
		},
		'ux.from': function() {
			this.fetchSessioni();
		},
		rosaTeamId() {
			this.fetchSessioni();
		},
		'title': {
			immediate: true,
			handler: function() {
				if(this.isWidget) {
					this.$emit('updateTitleWidget', this.isWidget, this.title);
				}
			}
		},
	},
	methods: {
		getNomeSessione(s) {
			return s.nome;
		//	return [s.estensione.primo_livello, s.estensione.secondo_livello, s.estensione.terzo_livello_1, s.estensione.terzo_livello_2].join('').length ? [s.estensione.primo_livello, s.estensione.secondo_livello, s.estensione.terzo_livello_1, s.estensione.terzo_livello_2].join(' ') : s.nome;
		},
		getWidthGruppo(gruppo) {
			var widthPerc = (100/this.sessioniToShow.length) * this.sessioniGroupedByOra[gruppo].length;
			return widthPerc;
		},
		getWidthSessioneGruppo(gruppo) {
			var widthPerc = 100/this.sessioniGroupedByOra[gruppo].length;
			return widthPerc;
		},
		refreshData: function() {
			this.fetchSessioni();
			if(this.refresh && this.refresh > 0) {
				_.delay(this.refreshData, this.refresh * 60000);
			}
		},
		fetchSessioni: function() {
			this.sessioni = [];
			this.loading = true;
			this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/sessioni/prevenzione', {from: this.ux.from, team_id: this.rosaTeamId} ).then(function(data) {
				this.sessioni = data.data;
				this.loading = false;
			});
		},
		svuotaSessioni: function() {
			this.hidePreset = [];
			this.saveSessioni(this.hidePreset);
		},
		saveSessioni: function(sessioni) {
			if(this.isWidget) {
				this.$emit('updateConfigWidget', this.isWidget, 'hideSessions', sessioni);
				this.ux.hideDisabled = false;
			} else {
				this.$http.post(this.$store.state.apiEndPoint + '/atlas/dashboard/set-persone', { chiave: 'sessioniPrevenzioneHide', persone: sessioni }).then(function(data) {
					this.auth.refreshUser(this.$root.$children[0]);
					this.ux.hideDisabled = false;
				});
			}
		},
		download: function() {
			var url = [this.$store.state.apiEndPoint + '/report/download/sessioni/prevenzione/' + this.user.id + '/' + this.user.api_token];
			url.push('?from=' + this.ux.from);
			url.push('&team_id=' + this.rosaTeamId);
			if(this.hidePreset.length > 0) {
				url.push('&hide_sessions=' + this.hidePreset);
			}
			window.open(url.join(''));
		},
		watchClock: function() {
			this.clock = {
				day: moment().date(),
				wd: moment().isoWeekday(),
				month: moment().month(),
				hour: moment().format('HH'),
				min: moment().format('mm'),
				sec: moment().format('ss')
			}
			_.delay(this.watchClock, 100);
		},
		getFasiPlayer(player_id, sessione_id) {
			var sessione = this.sessioni.find(item => item.id == sessione_id);
			this.player = sessione.persone.find(item => item.id == player_id);
			this.fasi = sessione.fasi.filter(item => { return item.persone.findIndex(pl => pl.id == player_id) > -1 && item.entita != 'Fase_Form'});
			this.$refs.fasiModal.show();
		},
		componentCalendar: function(component) {
			var desiredComp = 'Calendar' + component.entita;
			if(this.$options.components[desiredComp])
				return desiredComp;
			return 'CalendarFase';
		},
	}
};
</script> 