<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">
			<menusub></menusub>
			<b-card no-body class="border-0">
				<!--div v-if="auth.isUserEnable('whiteboard_config')">
				<b-button variant="link" class="nav-link" href="/plain/monitor/#/screen/edit" target="_blank"><span style="font-size: 24px;font-family: 'bebasneue', Arial, sans-serif;">BOARD EDITOR</span></b-button>
			</div-->
			<b-row align-v="center" class="my-2">
				<b-col cols="3" class="pl-2">
					<b-form-select v-model="monitor_id">
						<b-form-select-option v-for="item in monitors" :value="item.id">{{ item.nome }}</b-form-select-option>
					</b-form-select>
				</b-col>
				<b-col cols="3" class="pl-2">
					<b-button-group v-if="auth.isAdmin()">
						<b-button title="Aggiungi monitor" variant="success" @click="newMonitor">Aggiungi <i class="fa fa-plus" aria-hidden="true"></i></b-button>
						<b-button title="Modifica monitor" @click="editMonitor">Modifica <i class="fa fa-pencil" aria-hidden="true"></i></b-button>
						<b-button title="Elimina monitor" :disabled="monitor_id == 1" variant="danger" @click="deleteMonitor">Elimina <i class="fa fa-trash" aria-hidden="true"></i></b-button>
					</b-button-group>
				</b-col>
				<b-col class="text-right">
					<!--b-button variant="link" class="nav-link" :href="'https://app.asromasystem.com/plain/monitor/#/screen/edit?monitor_id=' + monitor_id" target="_blank"><span style="font-size: 24px;font-family: 'bebasneue', Arial, sans-serif;">BOARD EDITOR</span></b-button-->
					<b-button :href="'/plain/monitor/#/screen/edit?monitor_id=' + monitor_id" target="_blank" v-if="canBoardEditor">Board Editor</b-button>		
				</b-col>
			</b-row>
			<b-embed :key="monitor_id" type="iframe"
			:src="'/plain/monitor/#/screen?monitor_id=' + monitor_id"
			allowfullscreen
			></b-embed>
		</b-card>
		<b-modal size="lg" ref="monitorModal" @ok="salvaMonitor" ok-title="Salva" cancel-title="Chiudi" :title="monitor_form.id ? 'Modifica Monitor' : 'Nuovo Monitor'">
			<b-form-group label="Nome">
				<b-form-input type="text" v-model="monitor_form.nome"></b-form-input>
			</b-form-group>
			<b-form-group label="Larghezza (px)">
				<b-form-input type="number" v-model="monitor_form.width"></b-form-input>	
			</b-form-group>
			<b-form-group label="Altezza (px)">
				<b-form-input type="number" v-model="monitor_form.height"></b-form-input>
			</b-form-group>
			<b-form-group label="Permesso">
				<b-form-input type="text" v-model="monitor_form.permesso"></b-form-input>
			</b-form-group>
			<b-form-group label="Riporta il monitor alla home">
				<b-checkbox type="text" v-model="monitor_form.self_page" :value="1" :unchecked-value="0"></b-checkbox>
			</b-form-group>
			<b-form-group label="Durata per ritorno alla home">
				<b-form-input type="number" v-model="monitor_form.self_page_time"></b-form-input>
			</b-form-group>
			<b-form-group label="Link" v-if="monitor_form.id">
				<b-link :href="'https://app.asromasystem.com/plain/monitor/#/screen?monitor_id=' + monitor_form.id" target="_blank">{{ 'https://app.asromasystem.com/plain/monitor/#/screen?monitor_id=' + monitor_form.id }}</b-link>
			</b-form-group>
		</b-modal>
	</div>

	<footerapp></footerapp>

</section>
</template>

<script>
var Auth = require('../../../auth.js');
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		card:               require('../../layout/card.vue').default,
	},

	data: function() {
		return {
			categoriaId: 1,
			monitors: [],
			monitor_id: null,
			monitor_form: {
				id: null,
				nome: null,
				width: 1920,
				height: 1080,
				categoria_id: 1,
				permesso: null
			}
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		canBoardEditor: function() {
			if(!this.monitor_id) return false; 
			if(this.auth.isAdmin()) return true; 
			var monitor = this.monitors.find(item => item.id == this.monitor_id);
			if(monitor && this.auth.isUserEnable(monitor.permesso)) {
				return true;
			}
			return false;
		}
	},

	created() {
		this.getMonitors();
	},

	methods: {
		getMonitors: function() {	
			this.monitors = [];
			this.$http.get(this.$store.state.apiEndPoint + '/whiteboard/monitors/' + this.categoriaId).then((response) => {
				this.monitors = response.data;
				if(this.monitors.length > 0 && !this.monitor_id) {
					this.monitor_id = this.monitors[0].id;

				}
			});
		},

		deleteMonitor: function() {	
			if(this.monitor_id && this.monitor_id != 1) {
				if(confirm("Confermi di voler eliminare il monitor selezionato ?")) {
					this.$http.get(this.$store.state.apiEndPoint + '/whiteboard/monitor/delete/' + this.monitor_id).then((response) => {
						this.monitor_id = null;
						this.getMonitors();
					});
				}
			}
		},

		editMonitor: function() {
			var monitor = this.monitors.find(item => item.id == this.monitor_id);
			this.monitor_form = {
				id: monitor.id,
				nome: monitor.nome,
				width: monitor.width,
				height: monitor.height,
				permesso: monitor.permesso,
				self_page: monitor.self_page,
				self_page_time: monitor.self_page_time,
			}
			this.$refs.monitorModal.show();
		},

		newMonitor: function() {
			this.monitor_form = {
				id: null,
				nome: null,
				width: 1920,
				height: 1080,
				categoria_id: 1,
				permesso: null,
				self_page: 1,
				self_page_time: 10
			}
			this.$refs.monitorModal.show();
		},

		salvaMonitor: function() {
			var path = this.$store.state.apiEndPoint + (this.monitor_form.id ? '/whiteboard/monitor/update/' +  this.monitor_form.id : '/whiteboard/monitor/create');
			this.$http.post(path, this.monitor_form).then((response) => {
				this.getMonitors();
			});		
		},
	}
}
</script>
