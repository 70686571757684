<template id="dashboard">
	<section class="allenamento">

		<b-modal hide-footer ref="stop-sess" no-close-on-esc no-close-on-backdrop hide-header-close>
			La sessione è stata modificata successivamente alla tua apertura.
			<div class="mt-3">
				<b-button variant="success" @click.prevent="refreshSession">Ricarica</b-button>
			</div>
		</b-modal>

		<headapp></headapp>
		<div v-if="sessione">
			<menusub></menusub>
			<div class="card border-0 rounded-0" v-if="sessione">

				<div class="card-body">
					<div class="container-fluid">
						<ModalMatrice v-if="ux.showMatrice" @close="ux.showMatrice = false;" @save="ux.showMatrice = false; getSessione()" :sessioneId="ux.showMatrice"></ModalMatrice>
						<b-row>
							<b-col>
								<div class="card-title">
									<span>{{ getTipoSessione }}</span>
								</div>
							</b-col>
							<b-col class="text-right">
								<b-button size="sm" variant="outline-primary" v-if="auth.isUserEnable('football_partite') && sessione.partita_rel" :to="{ name: 'football_partita', params: { id: sessione.partita_rel.partita_id }}"> <i class="fa fa-futbol-o" aria-hidden="true"></i> Partita</b-button>
								<b-button size="sm" variant="outline-primary" v-if="$route.params.tipo == 19" :to="{ name: $route.meta.module + '_sessione_presentazione', params: { id: $route.params.id, tipo: $route.params.tipo }}"> <i class="fa fa-desktop" aria-hidden="true"></i>  Presentazione</b-button>
								<b-button size="sm" variant="outline-primary" v-if="auth.isUserEnable('video_sessioni')" :to="{ name: $route.meta.module + '_sessione_video', params: { id: $route.params.id, tipo: $route.params.tipo }}"> <i class="fa fa-camera" aria-hidden="true"></i> Video</b-button>
								<b-button size="sm" variant="outline-primary" v-if="hasStampa" @click="downloadPdf"> Stampa pdf</b-button>
								<b-button size="sm" v-if="hasSetup(sessione, 'matrixHideButton', false) == false" variant="outline-secondary" @click.prevent="ux.showMatrice = sessione.id">{{ hasSetup(sessione, 'matrixLabel', 'Activity Check') }}</b-button>
								<b-button size="sm" variant="outline-secondary" @click="importDatiTool" v-if="auth.isUserEnable('import_tools_analysis') && hasToolGps" :disabled="waiting || canImportDatiTool != true">
									<span v-if="canImportDatiTool != true">({{ (wait_till/1000).toFixed(1) }} s)</span>
									{{ waiting ? 'Acquisizione dati in corso...' : (isAlreadyImported ? 'Ri-Acquisisci dati tool' : 'Acquisisci dati tool')}}
								</b-button>
								<div class="card-subtitle text-muted mt-1" v-if="auth.isUserEnable('sessione_view_autore')" style="font-size:0.9rem">
									<span>Creata da: <strong>{{ sessione.utente ? sessione.utente.nomeCompleto : 'Autore non disponibile'}}</strong> il <strong>{{ sessione.created_at | formatDateHour }}</strong></span>
								</div>
								<div class="card-subtitle text-muted mt-1" v-if="auth.isUserEnable('sessione_ultima_modifica')" style="font-size:0.9rem">
									<span>Modificata da: <strong>{{ sessione.editore ? sessione.editore.nomeCompleto : 'Editore non disponibile'}}</strong> il <strong>{{ sessione.updated_at | formatDateHour }}</strong></span>
								</div>
							</b-col>
						</b-row>
						<div class="content">

							<div :is="hasSetup(sessione, 'component', 'VistaDettaglio')"
							:saveLoading="saveLoading"
							:loadingFase="loadingFase"
							:sessionDataLayer="sessionDataLayer"
							:playerPreset="playersPreset"
							:playerOptions="player_options"
							:staffOptions="staff_options"
							:sessione="sessione"
							:sessioneTipi="sessione_tipi"
							:fasiGeneriche="fasiGeneriche"
							:estensioniFase="estensioniFase"
							:seasonId="season_id"
							:team="team"
							:importDatiToolReport="importDatiTool_report"
							@submit="handleSubmit"
							@refresh="getSessione"
							@newFase="showAddFasi"
							@removeFase="removeFase($event)"
							@duplicateFase="duplicaFase($event)"
							@moveFase="sposta($event.index, $event.dove)"
							@deleteSessione="deleteSessione"
							@showDuplicaSessione="showDuplicaSessione">
						</div>
						<!-- <pre>{{payloadUpdate}}</pre> -->

						<ModalNewFase @close="ux.newFase = false" @selected="aggiungiFasi" :sessione="sessione" :glossario="glossario" :sessioneCorrente="sessioneCorrente" v-if="ux.newFase"></ModalNewFase>
					</div>
					<b-row class="mt-3" align-v="center" v-if="ux.clone">
						<b-col cols="6">
							<b-form-group label="Dal" description="Data specifica o inizio intervallo">
								<b-form-input v-model="clone_form.from" type="date"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group label="Al" description="Data di fine intervallo (opzionale)">
								<b-form-input v-model="clone_form.to" type="date"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col cols="12">
							<b-form-group label="Giorni" description="Seleziona uno o più giorni specifici su cui vuoi duplicare la sessione nell'intervallo scelto (lascia vuoto per duplicare su tutti i giorni)">
								<b-form-checkbox-group
								v-model="clone_form.days"
								:options="days_options"
								name="days"
								></b-form-checkbox-group>
							</b-form-group>
						</b-col>
						<b-col>
							<b-button variant="outline-primary" v-if="sessione.id" class="mt-3" @click="duplicaSessione">Crea duplicato</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
	</div>
	<footerapp></footerapp>
</section>
</template>

<script>
import sessionMixin from '@/mixins/sessione.js';

import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
export default {
	name: 'Sessione',
	mixins: [
		sessionMixin
		],
	components: {
		headapp:            require('../../layout/headapp.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		treedbody:            require('../../layout/3dbody.vue').default,
		VistaDettaglio: require('@/components/layout/Sessioni/Generica/VistaDettaglio.vue').default,
		VistaProva: require('@/components/layout/Sessioni/Generica/VistaTemplate.vue').default,
		VistaAttivita: require('@/components/layout/Sessioni/Generica/VistaAttivita.vue').default,
		ModalNewFase: require('../../layout/Sessioni/ModalNewFase.vue').default,
		ModalMatrice: require('@/components/layout/Sessioni/Matrice.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
	},
	data: function(){
		return{
			saveLoading: false,
			sessionDataLayer: {},
			latestFetch: null,
			loadingFase: false,
			blockCheck: false,
			playersPreset: [],
			ux: {
				newFase: false,
				showMatrice: false,
				showVideo: false,
				clone: false,
			},
			sessione: null,
			player_options: [],
			sessione_tipi: [],
			season_id: null,
			fasiGeneriche: null,
			estensioniFase: [],
			team: null,
			clone_form: {
				from: null,
				to: null,
				days: [],
			},
			days_options: [
				{ text: 'LUN', value: 1 },
				{ text: 'MAR', value: 2 },
				{ text: 'MER', value: 3 },
				{ text: 'GIO', value: 4 },
				{ text: 'VEN', value: 5 },
				{ text: 'SAB', value: 6 },
				{ text: 'DOM', value: 0 }
				],
			waiting: false,
			wait_till: null,
			waiting_watch: null,
			importDatiTool_report: null,
			staff_options: [],
			timerId: null,
		}
	},
	created: function () {
		this.getTipiSessione();
		this.getPlayers();
		this.getFasiGeneriche();
		this.getSessione();
		this.getSessioneEstensioniFase();
		this.getTeam();
		this.getStaff();
	},
	destroyed() {
		clearTimeout(this.timerId);
	},
	methods: {
		refreshSession: function() {
			this.$refs['stop-sess'].hide();
			this.blockCheck = false;
			this.getSessione();
		},
		fetchCheck: function() {

			if(this.blockCheck == false) {
				axios.get(`/sessione/` + this.$route.params.id).then(function(res) {
					if(res.data.updated_at !== this.latestFetch) {
						this.$refs['stop-sess'].show();
						this.blockCheck = true;
					}
				}.bind(this));
			}

			this.timerId = _.delay(function() {
				this.fetchCheck();
			}.bind(this), 3*60000);

		},
		getSessioneEstensioniFase: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessioni/estensioni/glossario', { params: { area: this.$route.params.tipo ? this.$route.params.tipo : '' }}).then(function(res) {
				this.estensioniFase = res.data;
			});
		},
		aggiungiFasi: function(fasi) {
			this.sessione.fasi = _.union(this.sessione.fasi, fasi);
		},
		getTipiSessione() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessione/tipi').then((response) => {
				if (response.ok) {
					this.sessione_tipi = response.data;
				}
			}, (response) => {
			});
		},
		getFasiGeneriche() {
			this.$http.get(this.$store.state.apiEndPoint + '/sessioni/glossario', {params: { area: this.$route.params.tipo ? this.$route.params.tipo : '', team_id: this.rosaTeamId}} ).then(function(data) {
				this.fasiGeneriche = data.data;
			})
		},
		getSessione() {

			if(this.$route.params.id) {
				axios.get(`/sessione/${this.$route.params.id}?no_fasi=1`)
				.then((res) => {

					res.data.players = res.data.persone.map(item => ({
						name: item.cognome + ' ' + item.nome,
						id: item.id,
					}));
					
					if(this.latestFetch == null) var c = 1;

					this.latestFetch = res.data.updated_at;

					if(c)
						this.fetchCheck();

					res.data.start_at = moment(res.data.inizio).format("YYYY-MM-DD");
					res.data.from_hour 	= moment(res.data.inizio).format("HH:mm:ss");
					res.data.to_hour 	= moment(res.data.fine).format("HH:mm:ss");
					res.data.season_id  = res.data.stagione_id;
					this.season_id  = res.data.stagione_id;
					this.sessione = res.data;

					this.loadingFase = true
					axios.get(`/sessione/${this.$route.params.id}`)
						.then(( res_with_fasi ) => {
							
							this.sessione.fasi = _.map(res_with_fasi.data.fasi, function(fase) {
		
								if(!fase.durata_reale) {
									fase.durata_reale = fase.durata;
								}
								this.$set(fase, 'players', _.map(this.sessione.persone, function(item) {
									var realItem = _.find(fase.persone, { id: item.id });
									return {
										name: item.cognome + ' ' + item.nome,
										id: item.id,
										inPhase: _.map(fase.persone, 'id').indexOf(item.id) !== -1,
										activity_check: { reason: (realItem ? realItem.pivot.reason : null), checked: (realItem ? realItem.pivot.checked : null), nota: (realItem ? realItem.pivot.nota : '') }
									}
								}));
		
								/* Prima o poi.... 👇🏻 */
								this.$set(fase, 'injuries', _.map(fase.injuries, function(item) {
									return {
										id: item.id,							
										data: item.data,
										pathology: item.pathology,
										persona_id: item.persona_id
									}
								}));
		
								this.$set(fase, 'illnesses', _.map(fase.illnesses, function(item) {
									return {
										id: item.id,							
										data: item.data,
										illness_types: item.illness_types,
										illness_other_types: item.illness_other_types,
										persona_id: item.persona_id
									}
								}));
								
								return fase;
		
							}.bind(this));

						})
						.finally( () => this.loadingFase = false )

				}).catch((err) => {
					this.loading = false;
				});

			}
		},

		handleSubmit: function () {

			if(!this.isManagerSessione || this.saveLoading ) {
				return false;
			}

			this.saveLoading = true

			const formData = new FormData();
			formData.append('payloadUpdate', JSON.stringify(this.payloadUpdate));
			this.payloadUpdate.fasi.forEach(function(element, i) {
				if( element != undefined && element.entity && element.entity.file) {
					formData.append("fase_image_" + i, element.entity.file);
				}
			});

			this.$http.post(this.$store.state.apiEndPoint + '/sessione/update/' + this.sessione.id, formData).then(function(response) {

				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.getSessione();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			})
			.finally( () => {
				setTimeout(() => {
					this.saveLoading = false
				}, 5000);
			})
		},
		showAddFasi: function () {
			this.ux.newFase = true;
		},
		removeFase: function (k) {
			if(confirm("Confermi l'eliminazione della fase?")) {
				this.sessione.fasi.splice(k, 1);
			}
		},
		duplicaFase: function (k) {
			var newFase = _.cloneDeep(this.sessione.fasi[k]);
			newFase.id = null;
			if(newFase.entita_id) {
				delete newFase.entita_id;
			}
			if(newFase.entity) {
				if(newFase.entity.id) {
					newFase.entity.id = null;
				}
				if(newFase.entity.entita_id) {
					delete newFase.entity.entita_id;
				}
			}
			this.sessione.fasi.push(newFase);
		},
		sposta(index, dove) {
			if(dove == 1) {
				var temp = this.sessione.fasi.splice(index, 1); 
				this.sessione.fasi.splice(index + 1, 0, temp[0]);
			} else {
				var temp = this.sessione.fasi.splice(index, 1); 
				this.sessione.fasi.splice(index - 1, 0, temp[0]);
			}
		},
		getPlayers: function() {				
			this.player_options = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: this.rosaTeamId, season_id: this.season_id }}).then((response) => {
				if (response.ok)
				{
					this.player_options = [
					{
						label: 'Seleziona tutti',
						items: response.data.map(item => ({								
							name: item.cognome + ' ' + item.nome,
							id: item.id,
						})),
					}
					];
				}
			}, (response) => {
			});
		},
		getStaff: function() {				
			this.staff_options = [];
			this.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: this.rosaTeamId,season_id: this.seasonId  }}).then((response) => {
				if (response.ok)
				{
					this.staff_options = response.data;
				}
			});
		},
		downloadPdf() {
			window.open(this.$store.state.apiEndPoint + '/sessione/pdf/' + this.sessione.id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
		},
		getTeam() {
			if(this.rosaTeamId){
				this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.rosaTeamId).then((response) => {
					if (response.ok)
					{
						this.team = response.data;
					}
				}, (response) => {
				});
			}
		},
		deleteSessione() {
			if(confirm("Confermi l'eliminazione della sessione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/sessione/delete/' + this.sessione.id).then((response) => {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: this.$route.meta.module + '_calendar' });
				});
			}
		},
		showDuplicaSessione() {
			this.ux.clone = !this.ux.clone;
		},
		duplicaSessione() {
			if(!this.clone_form.from) {
				alert("Devi selezionare almeno una data su cui duplicare la sessione!");
				return;
			}
			if(confirm("Confermi la duplicazione della sessione ?")) {
				this.$http.post(this.$store.state.apiEndPoint + '/sessione/duplica/' + this.sessione.id, this.clone_form).then((response) => {
					this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
					this.$router.push({ name: this.$route.meta.module + '_calendar' });

				}, (response) => {
					this.errors = response.data;
					for (var index in this.errors)
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
				});
			}
		},
		importDatiTool: function() {
			this.waiting = true;
			this.$http.get(this.$store.state.apiEndPoint + '/catapult/sessione/' + this.sessione.id).then(function(data) {
				this.getSessione();
				this.$root.$emit('refresh-tools-notes');
				this.waiting_watch = setInterval(function() {
					if(this.wait_till > 0)
						this.wait_till-=100;
					else {
						this.wait_till = null;
						clearInterval(this.waiting_watch);
					}
				}.bind(this), 100);
				this.wait_till = 10*1000;
				this.importDatiTool_report = data.data;
				this.waiting = false;	
			});
		},

	},
	computed: {
		isAlreadyImported: function() {
			if(!this.sessione || !this.sessione.forms || !this.sessione.forms.fasi_form ) return null;
			return _.filter(this.sessione.forms.fasi_form, function(i) { return i.entity.entita == 'Fase_Dato_Tools' }).length;
		},
		canImportDatiTool: function() {
			if(this.wait_till == null) return true;
			return false;

			return this.wait_till.diff(moment());
		},
		hasToolGps: function() {
			return _.find(this.sessione.tools_rel, { tool_glo_id: 1 }) && _.find(this.sessione.tools_rel, { tool_glo_id: 1 }).used == 1;
		},
		glossario: function() {
			return this.fasiGeneriche;
		},
		payloadUpdate: function() {
			return {
				nome: this.sessione.nome,
				inizio: this.sessione.start_at + ' ' + this.sessione.from_hour,
				fine: this.sessione.start_at + ' ' + this.sessione.to_hour,
				sessione_tipo_glo_id: this.sessione.sessione_tipo_glo_id,
				persone: this.sessione.players,
				estensione: this.sessione.estensione,
				skills_rel: this.sessione.skills_rel,
				tools_rel: this.sessione.tools_rel,
				fasi: this.sessione.fasi,
				setup: this.sessione.setup,
				operatore_id: this.sessione.operatore_id
			}
		},
		sessioneCorrente: function() {
			return _.find(this.sessione_tipi, { id: this.sessione.sessione_tipo_glo_id });
		},
		personeCorrenti: function() {
			return _.map(this.sessione.persone, function(a) {
				return {
					name: a.cognome + ' ' + a.nome,
					id: a.id
				}
			});
		},
		personeIds: function() {
			return _.map(this.personeCorrenti, 'id');
		},
		auth: function() {
			return Auth;
		},
		...mapState(['rosaTeamId']),
		getTipoSessione: function() {
			if(this.sessione_tipi.length > 0) {
				return this.sessione_tipi.find((item) => item.id == this.$route.params.tipo).label;	
			} return 'Sessione';
		},
		isManagerSessione: function() {
			if(this.sessione && this.sessione.sessione_tipo) {

				if(this.team && this.sessione.categoria_id != this.team.id_categoria) {
					return false;
				}

				return this.auth.isUserEnable('sessione_' + this.sessione.sessione_tipo.nome.toLowerCase().replaceAll(' ', '') + '_mng');
			}
			return false;
		},
		hasStampa() {
			return this.hasSetup(this.sessione, 'hasStampa', false);
		}
	},
	watch: {
		'sessione.players': {
			deep: true,
			handler: function() {

				var inPhaseDefault = !(this.hasSetup(this.sessione, 'disableAutoPlayerInPhase', false));

				_.each(this.sessione.players, function(player) {

					var the_player = _.cloneDeep(player);
					the_player.inPhase = inPhaseDefault;
					the_player.activity_check = {
						checked: null,
						nota: null,
						reason: null
					};

					_.each(this.sessione.fasi, function(fase) {

						var playerFase = _.cloneDeep(the_player);
						if(fase.setup != undefined && fase.setup.ifInPhaseAlsoActivityCheck != undefined && inPhaseDefault)
							playerFase.activity_check.checked = true;
						if(!_.find(fase.players, { id: player.id })) {
							if( !fase.players )
								fase.players = []
							fase.players.push(playerFase);
						}

					}.bind(this));

				}.bind(this));

			}
		},
		rosaTeamId() {
			this.getPlayers();
		}, 
	},
	filters: {
		formatDateHour: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY HH:mm'); 
			}
			return date;
		}
	}, 
}
</script>