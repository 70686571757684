<template>
	<div>
		<label>Nome</label>
		<b-form-input :disabled="disabled" v-model="fase.nome" type="text"></b-form-input>
		<b-row>
			<b-col>
				<label>Durata</label>
				<b-form-input :disabled="disabled" v-model="fase.durata" type="time"></b-form-input>
			</b-col>
			<b-col>
				<label>Durata effettiva</label>
				<b-form-input :disabled="disabled" v-model="fase.durata_reale" type="time"></b-form-input>
			</b-col>
		</b-row>
		<label>Terapia</label>
		<b-form-select :disabled="disabled || (fase.entity.id != undefined ? true : false)" v-model="fase.entity.fisioterapia_glo_id">
			<option :value="null">seleziona</option>
			<option :value="opt.id" v-for="opt in theGlossario['Fisioterapia']" :key="opt.id">{{opt.nome}}</option>
		</b-form-select>

		<fase-glossario-dati v-if="fase.entity.items" :items="fase.entity.items"></fase-glossario-dati>
		
		<!-- Slot contenitore delle estensioni -->
		<!-- <slot></slot> -->
		<!-- Slot contenitore delle estensioni -->

		
	</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { mapState } from 'vuex';
import * as types from '@/store/types';
var Auth = require('@/auth.js');
import faseMixin from '@/mixins/fase.js';

export default {
	name: 'FaseFisioterapia',
	data: function() {
		return {
			groupsHelper: false,
			random: Math.random()
		}
	},
	mixins: [
	faseMixin
	],
	props: [ 'sessione', 'glossario', 'fase', 'medical_regions_original', 'medical_regions', 'medical_regions_map', 'medical_regions_groups', 'medical_regions_supergroups', 'disabled' ],
	methods: {
		multiUnselect: function(ids) {
			//console.log('multiUnselect', ids);
			var toUnselect = [];
			_.each(ids, function(id) {
				var k = _.findKey(this.fase.entity.medical_regions, { id });
				this.fase.entity.medical_regions.splice(k, 1);
			}.bind(this));
		},
		multiSelect: function(ids) {
			//console.log('multiSelect', ids);
			_.each(ids, function(id) {
				var obj = _.find(this.medical_regions, { id });
				if(!_.find(this.fase.entity.medical_regions, { id }))
					this.fase.entity.medical_regions.push(_.cloneDeep(obj));
			}.bind(this));
		},
		groupChanged: function() {
			
			if(!this.groupsHelper) return false;

			var selection = [];
			// All Group Ids:
			_.each(this.groupsHelper.groups_selected, function(group_name) {
				var validObjects = _.filter(this.medical_regions_original, { group_name });
				if(validObjects.length) {
					_.each(validObjects, function(a) {
						selection.push({ id: a.id, name: a.name });
					})
				}
			}.bind(this));

			_.each(this.groupsHelper.free_ids_selection, function(a) {
				selection.push({ id: a.id, name: a.name });
			})


			//console.log(selection);
			this.fase.entity.medical_regions = selection;
			//console.log(this.groupsHelper.groups_selected, this.groupsHelper.free_ids);

		},
		updateRegionsSelected: function(content, item) {
			if(!this.disabled) {
				item.entity.medical_regions = content;
			}
		},
		createItem: function() {

		},
		buildGroupHelper: function() {

			var groups = [];
			var groups_selected = [];
			var free_ids = [];

			_.each(this.fase.entity.medical_regions, function(single) {
				var originalRecord = _.find(this.medical_regions_original, { id: single.id });
				if(originalRecord.group_name) {
					groups.push(originalRecord.group_name);
				} else {
					free_ids.push(originalRecord);
				}
			}.bind(this));


			if(groups.length > 0) {
				groups = _.uniq(groups);
				var selectedIds = _.map(this.fase.entity.medical_regions, 'id');
				// Now, we need to check if all the group items are selected! :)
				_.each(groups, function(group_name) {

					var ids = _.filter(this.medical_regions_original, { group_name });
					var all = _.map(_.map(ids, 'id'), function(a) {
						return selectedIds.indexOf(a) !== -1;
					}.bind(this));

					//console.log(_.filter(all).length, ids.length);

					if(_.filter(all).length == ids.length) {
						groups_selected.push(group_name);
					} else {
						// console.log('diff length?!');
						_.each(ids, function(a) {
							free_ids.push(a);
						});
					}


				}.bind(this));
			}

			this.groupsHelper = { 
				groups_selected, 
				free_ids, 
				groups_options: _.uniq(_.filter(_.map(this.medical_regions_original, 'group_name'))),
				medical_regions: _.map(_.filter(this.medical_regions_original, function(a) {
					return a.group_name == null;
				}), function(e) {
					return { id: e.id, name: e.name };
				}),
				free_ids_selection: _.map(free_ids, function(e) {
					return { id: e.id, name: e.name };
				}), 
				group: groups_selected.length > 0 ? true : false 
			};

		}
	},
	watch: {
		'groupsHelper.groups_selected': {
			deep: true,
			handler: function(a,b) {
				//console.log(a,b);
			}
		},
		'fase.entity.medical_regions': {
			immediate: true,
			deep: true,
			handler: function(a,b) {
				//console.log('i',a);
				// console.log('here good im');
				//if(a !== undef)
				//this.buildGroupHelper();
			}
		},
		'fase.entity.fisioterapia_glo_id': {
			immediate: true,
			deep: true,
			handler: function(a,b) {

				if(this.fase.entity.id == undefined) {

					if(this.fase.entity.items == undefined) {
						this.$set(this.fase.entity, 'items', []);
						this.$set(this.fase.entity, 'fisioterapia_glo_config_id', null);
					}

					var newItem = this.theGlossario['Fisioterapia'].find(item => item.id == this.fase.entity.fisioterapia_glo_id);
					//console.log(newItem);
					
					if(newItem && newItem.fisioterapia_glo_config && newItem.fisioterapia_glo_config.items.length > 0) {
						this.fase.entity.items = _.cloneDeep(newItem.fisioterapia_glo_config.items);
						this.fase.entity.fisioterapia_glo_config_id = newItem.fisioterapia_glo_config.id;
					} else {
						if(this.fase.entity.items) {
							this.fase.entity.items = [];
						}
					}

				}
				/*

				this.random = Math.random();*/

			}
		}
	},
	computed: {
		group_selected: function() {
			return _.groupBy(this.medical_regions_original, 'group_name');
			return this.fase.entity.medical_regions;
		}
	},
	components: {
		SelettoreCorpo: require('@/components/layout/Medical/SelettoreZoneCorpo.vue').default,
		treedbody: require('@/components/layout/3dbody.vue').default,
		FaseGlossarioDati: require('@/components/layout/Sessioni/Generica/FaseGlossarioDati.vue').default,
	},
}
</script>