<template id="dashboard">
	<section class="allenamento">
		<headapp></headapp>

		<div class="card border-0 rounded-0">
			<div class="card-body">
				<div class="container">
					<div class="card-title">
						<span>Allenamento Palestra</span>
					</div>
					<div class="content">
						<form @submit.stop.prevent="handleSubmit">
							<b-row class="mb-1">
								<b-col>
									<label>Work</label>
									<b-form-select v-model="work">
										<option :value="null">seleziona</option>
										<option :value="item.id" v-for="item in works" :key="item.id">{{item.name}}</option>
									</b-form-select>
								</b-col>
								<b-col>
									<label>	Giocatore</label>
									<b-form-select v-model="rosa">
										<option :value="-1">seleziona</option>
										<option :value="0">Squadra</option>
										<option :value="-2">Portieri</option>
										<option :value="item.id" v-for="item in players" :key="item.id">{{item.name}}</option>
									</b-form-select>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col>
									<b-form-input v-model="start_at" type="date"></b-form-input>
								</b-col>
								<b-col>
									<b-form-input v-model="time_at" type="time"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="my-2" align-v="center">
								<b-col>
								</b-col>
								<b-col>
									<div class="pull-right">
										<b-button variant="outline-primary" @click="downloadPdf"><i class="fa fa-eye fa-fw"></i> stampa pdf</b-button>
									</div>
								</b-col>
							</b-row>
							<b-form-group v-for="(item, index) in esercizi"  :key="item.id">
								<b-card>
									<b-row>
										<b-col><h3 class="d-inline-block card-title" style="border:0;">Esercizio {{index + 1}}</h3></b-col>
										<b-col class="text-right" v-if="auth.isUserEnable('palestra_allen_mng')">
											<b-button variant="outline-primary" size="sm" v-if="esercizi[index - 1]" @click="sposta(index, 0)"><i class="fa fa-long-arrow-up"></i> Sposta su</b-button>
											<b-button variant="outline-primary" size="sm" v-if="esercizi[index + 1]" @click="sposta(index, 1)"><i class="fa fa-long-arrow-down"></i> Sposta giù</b-button>
											<b-button variant="danger" size="sm" @click="removeEsercizio(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi esercizio</b-button>
										</b-col>
									</b-row>

									<b-row class="mb-2" align-v="end">
										<b-col cols="2">
											<label>Facility</label>
											<b-form-select v-model="item.id_facility" :disabled="item.esercizio">
												<option :value="null">seleziona</option>
												<option :value="opt.id" v-for="opt in facility_opt" :key="opt.id">{{opt.name}}</option>
											</b-form-select>
										</b-col>
										<b-col cols="2">
											<label>Goal</label>
											<b-form-select v-model="item.id_goal" :disabled="item.esercizio">
												<option :value="null">seleziona</option>
												<option :value="opt.id" v-for="opt in goal_opt" :key="opt.id">{{opt.name}}</option>
											</b-form-select>
										</b-col>
										<b-col cols="2">
											<label>Tt</label>
											<b-form-select v-model="item.id_tt" :disabled="item.esercizio || item.goal != 17">
												<option :value="null">seleziona</option>
												<option :value="opt.id" v-for="opt in tt_opt" :key="opt.id">{{opt.name}}</option>
											</b-form-select>
										</b-col>
										<b-col>
											<label>Intensity</label>
											<b-row>
												<b-col>
													<b-form-select v-model="item.id_intensity">
														<option :value="null">seleziona</option>
														<option :value="opt.id" v-for="opt in intensity_opt" :key="opt.id">{{opt.name}}</option>
													</b-form-select>
												</b-col>
												<b-col>
													<b-form-input v-model="item.intensity_value"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input v-model="item.intensity_value_2"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input v-model="item.intensity_value_3"></b-form-input>
												</b-col>
												<b-col>
													<b-form-input v-model="item.intensity_value_4"></b-form-input>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
									<b-row class="mb-2">
										<b-col v-if="auth.isUserEnable('palestra_allen_mng')">
											<label>Rep/Tempo</label>
											<b-form-radio-group v-model="item.has_reps">
												<b-form-radio :value="1">Rep</b-form-radio>
												<b-form-radio :value="0">Tempo</b-form-radio>
											</b-form-radio-group>
										</b-col>
										<b-col v-if="auth.isUserEnable('palestra_allen_mng') || (!auth.isUserEnable('palestra_allen_mng') && item.reps > 0)">
											<label>{{ item.has_reps ? 'Reps' : 'Tempo di esecuzione (minuti)'}}</label>
											<b-form-input v-model="item.reps"></b-form-input>
										</b-col>
										<b-col v-if="item.has_reps && (auth.isUserEnable('palestra_allen_mng') || (!auth.isUserEnable('palestra_allen_mng') && item.reps_dx > 0))">
											<label>Reps dx</label>
											<b-form-input type="number" min="0" v-model="item.reps_dx"></b-form-input>
										</b-col>
										<b-col v-if="item.has_reps && (auth.isUserEnable('palestra_allen_mng') || (!auth.isUserEnable('palestra_allen_mng') && item.reps_sx > 0))">
											<label>Reps sx</label>
											<b-form-input type="number" min="0" v-model="item.reps_sx"></b-form-input>
										</b-col>
										<b-col>
											<label>Sets</label>
											<b-form-input type="number" min="0" v-model="item.sets"></b-form-input>
										</b-col>
										<b-col>
											<label>Rest (minuti)</label>
											<b-form-input v-model="item.rest"></b-form-input>
										</b-col>
									</b-row>
									<b-row v-if="!item.id_esercizio" class="mt-3">
										<b-col cols="6">
											<b-input-group>
												<b-form-input type="text" v-model="item.search" placeholder="Cerca esercizio da Rosa per titolo/autore..."></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="search_ex(item, index)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-col>
										<b-col cols="6">
											<b-input-group>
												<b-form-input type="text" v-model="item.search_condivisi" placeholder="Cerca esercizio da Archivio per titolo/autore..."></b-form-input>
												<b-input-group-append>
													<b-button variant="primary" @click="search_ex_condivisi(item, index)"><i class="fa fa-search" aria-hidden="true"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</b-col>
									</b-row>
									<b-row v-else class="mt-3">
										<b-col cols="12" style="text-align:center;">
											<h4>{{ item.esercizio.name }}</h4>
										</b-col>
										<b-col cols="12">
											<div style="text-align:justify;">
												{{ item.esercizio.description }}
											</div>		
										</b-col>
										<b-col cols="6" v-for="media in item.esercizio.media">
											<div v-if="media.id && media.tipo == 'foto' && media.image && media.image != ''" class="text-center">
												<b-img :src="media.foto_url" :height="400"/>
											</div>
											<div v-if="media.id && media.tipo == 'video' && media.video && media.video != ''" class="text-center">
												<b-embed type="video" controls aspect="16by9" :poster="media.image" preload="none" controlsList="nodownload" oncontextmenu="return false;">
													<source :src="media.video_url"/>    
												</b-embed>
											</div>
										</b-col>
									</b-row>
								</b-card>
							</b-form-group>
							<b-form-group id="phaseGroup" v-if="auth.isUserEnable('palestra_allen_mng')">
								<b-row>
									<b-col>
										<b-button variant="outline-primary" @click="addEsercizio"><i class="fa fa-plus fa-fw"></i> Aggiungi esercizio</b-button>
									</b-col>
								</b-row>
							</b-form-group>
							<b-row>
								<b-col class="text-right">
									<b-button v-if="auth.isLastSeason(season_id) && auth.isUserEnable('palestra_allen_mng')" @click="switchDuplica" variant="outline-primary" size="lg"><i class="fa fa-arrow-up" aria-hidden="true" v-if="clona"></i><i class="fa fa-arrow-down" aria-hidden="true" v-if="!clona"></i> DUPLICA</b-button>
									<button v-if="auth.isLastSeason(season_id) && auth.isUserEnable('palestra_allen_mng')" class="btn btn-default btn-primary btn-lg text-uppercase ml-2"><strong>Salva</strong></button>
								</b-col>
							</b-row>
							<b-row class="mt-3" align-v="center" v-if="clona">
								<b-col cols="12">
									<b-form-group label="Seleziona giocatori (lasciare vuoto se si vuole duplicare per la squadra)">
										<multiselect v-model="player_ids" :options="player_options" :multiple="true" :close-on-select="true" :clear-on-select="true" :hide-selected="false" :preserve-search="true" placeholder="Seleziona" label="name" track-by="id" group-values="items"
										group-label="label" :group-select="true"></multiselect>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Dal">
										<b-form-input v-model="from" type="date"></b-form-input>
									</b-form-group>		
								</b-col>
								<b-col>
									<b-form-group label="Al">
										<b-form-input v-model="to" type="date"></b-form-input>	
									</b-form-group>	
								</b-col>
								<b-col>
									<b-button variant="outline-primary" class="mt-2" @click="duplicaAllenamento">Crea duplicato</b-button>
								</b-col>
							</b-row>
						</form>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>

		<b-modal id="exModal" ref="exModal" title="Aggiungi Esercizio da Rosa" size="lg" centered>
			<b-container fluid>
				<p>Esercizi totali: {{ exercises_archivio.total }}</p>
				<v-infinite-scroll @bottom="nextPage" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
					<b-media class="py-3 border-bottom" v-for="ex in exercises_archivio.data" :key="ex.id">
						<b-img slot="aside" :src="ex.media.length > 0 ? (ex.media[0].tipo == 'foto' ? ex.media[0].foto_url : ex.media[0].image) : '/assets/varie/missing.jpg'" @click="setExercise(ex.id)" height="170" style="cursor:pointer;"/>
							<div class="d-flex flex-column" style="height: 170px;">
								<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
								<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Facility">Facility:</span> <span class="color-rosso" :title="ex.facility ? ex.facility.name : ''">{{ ex.facility ? ex.facility.name : '' }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Facility">Goal:</span> <span class="color-rosso" :title="ex.goal ? ex.goal.name : ''">{{ ex.goal ? ex.goal.name : '' }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tt">Tt:</span> <span class="color-rosso" :title="ex.tt ? ex.tt.name : ''">{{ ex.tt ? ex.tt.name : '' }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Intensity">Intensity:</span> <span class="color-rosso" :title="ex.intensity ? ex.intensity.name : ''">{{ ex.intensity ? ex.intensity.name : '' }}</span></p>
								<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>	
								<div class="flex-grow-1" style="overflow-y: auto;">
									<p class="text-muted"><small>{{ex.description}}</small></p>
								</div>
							</div>
						</b-media>
					</v-infinite-scroll>
				</b-container>
			</b-modal>
			<b-modal id="exCondivisiModal" ref="exCondivisiModal" title="Aggiungi Esercizio da Archivio" size="lg" centered>
				<b-container fluid>
					<p>Esercizi totali: {{ exercises_condivisi.total }}</p>
					<v-infinite-scroll @bottom="nextPageCondivisi" :offset='30' style="max-height: 400px; overflow-y: scroll;" class="row">
						<b-media class="py-3 border-bottom" v-for="ex in exercises_condivisi.data" :key="ex.id">
							<b-img slot="aside" :src="ex.media.length > 0 ? (ex.media[0].tipo == 'foto' ? ex.media[0].foto_url : ex.media[0].image) : '/assets/varie/missing.jpg'" @click="setExercise(ex.id)" height="170" style="cursor:pointer;"/>
								<div class="d-flex flex-column" style="height: 170px;">
									<h5 class="flex-shrink-0 pb-2 m-0">{{ ex.name }}</h5>
									<div v-if="ex.utente"><small> di <span class="color-rosso">{{ ex.utente.nomeCompleto }}</span></small></div>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Facility">Facility:</span> <span class="color-rosso" :title="ex.facility ? ex.facility.name : ''">{{ ex.facility ? ex.facility.name : '' }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Facility">Goal:</span> <span class="color-rosso" :title="ex.goal ? ex.goal.name : ''">{{ ex.goal ? ex.goal.name : '' }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Tt">Tt:</span> <span class="color-rosso" :title="ex.tt ? ex.tt.name : ''">{{ ex.tt ? ex.tt.name : '' }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem"><span title="Intensity">Intensity:</span> <span class="color-rosso" :title="ex.intensity ? ex.intensity.name : ''">{{ ex.intensity ? ex.intensity.name : '' }}</span></p>
									<p class="mb-0 text-truncate" style="font-size: 0.7rem">&nbsp;</p>	
									<div class="flex-grow-1" style="overflow-y: auto;">
										<p class="text-muted"><small>{{ex.description}}</small></p>
									</div>
								</div>
							</b-media>
						</v-infinite-scroll>
					</b-container>
				</b-modal>
			</section>
		</template>

		<script>
		import axios from 'axios';
		import moment from 'moment'
		import { mapState } from 'vuex';
		import * as types from '../../../../store/types';
		import InfiniteScroll from 'v-infinite-scroll';
		var Auth = require('../../../../auth.js');
		export default {
			data: function(){
				return{				
					start_at: null,
					time_at: null,

					esercizi: [],

					exe_index: 0,
					season_id: null,

					players: [],
					works: [],
					rosa: -1,
					work: null,

					facility_opt: [],
					goal_opt: [],
					tt_opt: [],
					intensity_opt: [],

					clona: false,
					player_ids: [],
					from: null,
					to: null,
				}
			},
			created: function () {

				this.getWork();
				this.getPlayers();
				this.getOptionsEsercizi();
				this.getAllenamento();
			},
			methods: {

				getAllenamento() {
					if(this.$route.params.id) {
						axios.get(`/palestra/allenamento/` + this.$route.params.id)
						.then((res) => {
							this.work 		= res.data.id_work;
							this.rosa 		= res.data.persona_id ? res.data.persona_id : (res.data.portieri ? -2 : 0);
							this.start_at 	= moment(res.data.start_at).format("YYYY-MM-DD");
							this.time_at 	= moment(res.data.start_at).format("HH:mm:ss");
							this.season_id  = res.data.id_stagione;
							this.from 	= moment(res.data.start_at).format("YYYY-MM-DD");
							this.to 	= moment(res.data.start_at).format("YYYY-MM-DD");

							this.esercizi = res.data.esercizi;
							for(var i in this.esercizi) {
								var item = this.esercizi[i];
								item.rest = item.rest.toFixed(2);
							}
						}).catch((err) => {
							this.loading = false;
						});
					}
				},

				handleSubmit: function () {
					if(!Auth.isUserEnable('palestra_allen_mng')) {
						return;
					}
					if(!Auth.isLastSeason(this.season_id)) {
						alert("Non puoi modificare un allenamento di una stagione archiviata!");
						return;
					}
					if(this.rosa == -1) {
						alert("Devi selezionare la squadra o un giocatore della rosa!");
						return;
					}
					const formData = new FormData();
					formData.append("start_at",  this.start_at);
					formData.append("time_at",  this.time_at);
					formData.append("work",  this.work);
					formData.append("rosa",  this.rosa);

					this.esercizi.forEach(function(element, i) {
						if( element != undefined ){
							formData.append("esercizi[]", JSON.stringify(element));
						}
					});
					this.update(formData,  this.$route.params.id);
				},

				update(data, id){
					this.$http.post(this.$store.state.apiEndPoint + '/palestra/allenamento/edit/' + id, data).then((response) => {
						if (response.ok){
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
							this.getAllenamento();
						}
					}, (response) => {
						if (response.status == 422) {
							this.errors = response.data;
							for (var index in this.errors) {
								this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
							}
						}
					});
				},

				addEsercizio: function () {
					this.esercizi.push( { id_esercizio: null, esercizio: null, sets: 0, has_reps: 1, reps: 0, reps_dx: 0, reps_sx: 0, rest: 0, id_facility: null, id_goal: null, id_tt: null, id_intensity: null, intensity_value: 0, intensity_value_2: 0, intensity_value_3: 0, intensity_value_4: 0, search: '', search_condivisi: '' });
				},
				removeEsercizio: function (item) {
					var index = this.esercizi.indexOf(item);
					this.esercizi.splice(index, 1);
				},

				search_ex: function (item, index) {
					this.$refs.exModal.hide();
					this.$refs.exCondivisiModal.hide();
					var array = [];
					this.$store.commit('palestra/setTitle', item.search);
					this.$store.commit('palestra/setPage', 1);
					this.$store.dispatch('palestra/filter_archivio');
					this.exe_index = index;
					this.$refs.exModal.show();
				},

				search_ex_condivisi: function (item, index) {
					this.$refs.exModal.hide();
					this.$refs.exCondivisiModal.hide();
					var array = [];
					this.$store.commit('palestra/setTitle', item.search_condivisi);
					this.$store.commit('palestra/setPage', 1);
					this.$store.dispatch('palestra/filter_condivisi');
					this.exe_index = index;
					this.$refs.exCondivisiModal.show();
				},

				setExercise: function(id) {
					axios.get(`/palestra/esercizio/` + id)
					.then((res) => {
						this.esercizi[this.exe_index].id_esercizio = res.data.id;
						this.esercizi[this.exe_index].esercizio = res.data;

						this.esercizi[this.exe_index].id_facility = res.data.id_facility;
						this.esercizi[this.exe_index].id_goal = res.data.id_goal;
						this.esercizi[this.exe_index].id_tt = res.data.id_tt;
						this.esercizi[this.exe_index].id_intensity = res.data.id_intensity;

						this.esercizi.sort();

						this.$refs.exModal.hide();
						this.$refs.exCondivisiModal.hide();
					}).catch((err) => {
						this.loading = false;
						this.$refs.exModal.hide();
						this.$refs.exCondivisiModal.hide();
					});
				},

				downloadPdf() {
					window.open(this.$store.state.apiEndPoint + '/palestra/allenamento/' + this.$route.params.id + "/pdf", "_blank");
				},
				sposta(index, dove) {
					if(dove == 1) {
						var temp = this.esercizi.splice(index, 1); 
						this.esercizi.splice(index + 1, 0, temp[0]);
					} else {
						var temp = this.esercizi.splice(index, 1); 
						this.esercizi.splice(index - 1, 0, temp[0]);
					}
				},
				nextPage() {
					if(this.page < this.exercises_archivio.last_page) {
						this.$store.commit('palestra/setPage', this.page + 1);
						this.$store.dispatch('palestra/filter_archivio', this.page + 1);
					}
				},
				nextPageCondivisi() {
					if(this.page < this.exercises_condivisi.last_page) {
						this.$store.commit('palestra/setPage', this.page + 1);
						this.$store.dispatch('palestra/filter_condivisi', this.page + 1);
					}
				},
				getPlayers: function() {				
					this.players = [];
					this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: this.rosaTeamId, season_id: this.season_id }}).then((response) => {
						if (response.ok)
						{
							this.players = response.data.map(item => ({								
								name: item.cognome + ' ' + item.nome,
								id: item.id,
							}));
						}
					}, (response) => {
					});
				},
				getWork: function() {				
					this.$http.get(this.$store.state.apiEndPoint + '/palestra/work').then((response) => {
						if (response.ok)
						{
							this.works = response.data;
						}
					}, (response) => {
					});
				},
				getOptionsEsercizi: function() {
					axios.get(`/palestra/opzioni/esercizi`)
					.then((res) => {
						this.facility_opt = res.data.facility;
						this.goal_opt = res.data.goal;
						this.tt_opt = res.data.tt;
						this.intensity_opt = res.data.intensity;

					}).catch((err) => {
					});
				},
				switchDuplica() {
					this.clona = !this.clona;
				},
				duplicaAllenamento() {
				//	if(this.player_ids.length > 0) {
					var player_ids = [];
					for(var i in this.player_ids) {
						player_ids.push(this.player_ids[i].id);
					}
					var formData = new FormData();
					formData.append("players", player_ids);
					formData.append("from", this.from);
					formData.append("to", this.to);
					this.clona = false;
					this.$http.post(this.$store.state.apiEndPoint + '/palestra/allenamento/multi/duplica/' + this.$route.params.id, formData).then((response) => {
						if (response.ok){
							this.player_ids = [];
							this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						}
					}, (response) => {
						if (response.status == 422) {
							this.errors = response.data;
							for (var index in this.errors) {
								this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
							}
						}
					});
				/*	} else {
						alert("Devi selezionare almeno un giocatore su cui duplicare l'allenamento!");
					}  */
				},
			},
			components: {
				headapp:            require('../../../layout/headapp.vue').default,
				footerapp:          require('../../../layout/footerapp.vue').default,
				formazione:         require('../../../layout/formazione.vue').default,
				'v-infinite-scroll': InfiniteScroll
			},
			computed: {
				auth: function() {
					return Auth;
				},
				...mapState('palestra', ['exercises_archivio', 'exercises_condivisi', 'page']),
				...mapState(['rosaTeamId']),

				player_options: function() {
					return [
					{
						label: 'Giocatori',
						items: this.players.filter(item => item.id != this.rosa),
					}
					];
				}
			},
			watch: {
				rosaTeamId() {
					this.getPlayers();
				},  
			},
		}
		</script>
