<template>
	<div>
		<b-row>
			<b-col>
				<b-input type="date" v-model="date"></b-input>
			</b-col>
			<b-col>
				<b-input type="time" v-model="clock"></b-input>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import moment from 'moment'
import * as types from '@/store/types'
import { mapState } from 'vuex';

var Auth = require('@/auth.js');

export default {
	props: ['value'],
	data: function() {
		return {
			date: null,
			clock: null
		}
	},
	watch: {
		'clock': function() {
			this.trig();
		},
		'date': function() {
			this.trig();
		},
		value: {
			immediate: true,
			handler: function() {

				if(this.value === null) return false;
				console.log(this.value);
				var m = moment(this.value);

				this.date = m.format('YYYY-MM-DD');
				this.clock = m.format('HH:mm');

			}
		}
	},
	methods: {
		trig: function() {
			var date_formatted = (this.date || '0000-00-00') + ' ' + (this.clock || '');
			console.log(date_formatted);
			this.$emit('input', date_formatted);
		}
	}
}

</script>