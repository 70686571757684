<template>
	<div>
		<b-card no-body class="px-1 py-0 mb-1" v-for="(player, index) in formazione" :bg-variant="index % 2 == 0 ? 'light' : 'default'" :border-variant="errors[index].length ? 'danger':''">
			<b-card-body>
				<b-row align-v="center" class="text-center">
					<b-col>
						<b-row class="text-left">
							<b-col>
								Cognome
								<b-input-group>
									<b-form-input type="text" v-model="player.cognome" :readonly="player.wyId != null"></b-form-input>
									<b-input-group-append v-if="player.wyId == null">
										<b-button variant="primary" @click="searchPlayer(player, index, 1)" title="Cerca giocatore"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
									<b-input-group-append v-else>
										<b-button variant="warning" @click="unlinkPlayer(player)" title="Scollega Giocatore WY Scout"><i class="fa fa-times" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>
							</b-col>
						</b-row>
						<b-row class="text-left">
							<b-col>
								Nome
								<b-form-input :readonly="player.wyId != null" type="text" v-model="player.nome"></b-form-input>
							</b-col>
						</b-row>
					</b-col>
					<b-col>
						<b-row class="text-left">
							<b-col>
								Numero
								<b-form-input type="number" min="1" v-model="player.numero"></b-form-input>
							</b-col>
						</b-row>
						<b-row class="text-left">
							<b-col>
								Data di nascita
								<b-form-input :readonly="player.wyId != null" type="date" v-model="player.nascita"></b-form-input>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="4" class="text-center pt-2">
						<div class="posizione">
							<div class="campo">
								<img src="/assets/images/campo.svg">
							</div>
							<div v-for="r in filtered_ruoli" :class="'player ' + r.sigla.toLowerCase()" :title="r.nome"  v-on:click="player.id_ruolo = r.id">
								<span class="ruolo" v-if="player.id_ruolo == r.id"><div class="tondo_ide"></div></span>
								<span v-else class="ruolo"><div class="tondo"></div></span>
							</div>
						</div>
					</b-col>
					<b-col cols="1">
						<b-button class="mb-1" variant="secondary" @click="removePlayer(index, 1)"><i class="fa fa-minus" aria-hidden="true"></i></b-button>
					</b-col>
				</b-row>
			</b-card-body>
			<template #footer v-if="errors[index].length">
				<span class="text-danger">Devi inserire: <strong>{{errors[index].join(', ')}}</strong></span>
			</template>
		</b-card>

		<div class="text-right">
			<b-button class="mb-1" variant="primary" @click="addPlayer()"><i class="fa fa-plus" aria-hidden="true"></i></b-button>
		</div>


		<b-modal ref="searchPlayerModal" title="Ricerca giocatore su wyscout">
			<div v-if="modalSearchPlayer.results == null"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
			<p v-else-if="!modalSearchPlayer.results.length">Nessun giocatore trovato!</p>
			<div v-else>
				<p>Trovati {{modalSearchPlayer.results.length}} giocatori!, su wyscout</p>
				<hr/>
				<div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
					<div v-for="(player, index) in modalSearchPlayer.results" :key="index" class="row" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaGiocatore(player)">
						<div class="col-md-3 my-1 mr-1" style="text-align: center;">
							<img v-if="player.imageDataURL" :src="player.imageDataURL"/>
						</div>
						<div class="col-md-6 my-1">
							<h5 style="display: inline;">{{ player.lastName }} {{ player.firstName }}</h5><br />
							<span class="text-muted" v-if="player.birthArea"><flag v-if="player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="24"></flag> {{ player.birthArea.name }} </span><br />
							<span class="text-muted">{{ player.birthDate | formatBirthDate }}</span><br />
							<span class="text-muted">{{ player.role.name }}</span><br />
							<span class="text-muted" v-if="player.currentTeam"><flag v-if="player.birthArea.alpha2code" :country="player.currentTeam.area.alpha2code" :size="24"></flag> {{ player.currentTeam.officialName }}</span><br />     
						</div>
						<div class="col-md-2 my-1 align-self-center" style="text-align: center;">
							<img v-if="player.currentTeam" :src="player.currentTeam.imageDataURL" width="100" />
						</div>
					</div>
				</div>
			</div>
		</b-modal>


	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			form: {},
			opt_struttura: [
				{ value: null, text: '-- Select --' },
				{ value: '0', text: 'Brevilinea' },
				{ value: '1', text: 'Normolinea' },
				{ value: '2', text: 'Longilinea' }
			],
			opt_muscolatura: [
				{ value: null, text: '-- Select --' },
				{ value: '0', text: 'Esile' },
				{ value: '1', text: 'Normale' },
				{ value: '2', text: 'Possente' }
			],
			opt_piede: [
				{ value: null, text: '-- Select --' },
				{ value: '0', text: 'Destro' },
				{ value: '1', text: 'Sinistro' },
				{ value: '2', text: 'Ambidestro' }
			],
			opt_sing: [
				{ value: null, text: '-- Select --' },
				{ value: '4', text: 'Insufficiente' },
				{ value: '0', text: 'Sufficiente' },
				{ value: '1', text: 'Discreta' },
				{ value: '2', text: 'Buona' },
				{ value: '3', text: 'Ottima' }
			],
			opt_plur: [
				{ value: null, text: '-- Select --' },
				{ value: '4', text: 'Insufficienti' },
				{ value: '0', text: 'Sufficienti' },
				{ value: '1', text: 'Discrete' },
				{ value: '2', text: 'Buone' },
				{ value: '3', text: 'Ottime' }
			],
			ruoli_map: [
				{ id: 3, ws_roles: ['gk']}, /* po - portiere */
				{ id: 4, ws_roles: ['cb']}, /* dc - difensore centrale */
				{ id: 5, ws_roles: ['rcb', 'rcb3']}, /* dcd - difensore centrale destro */
				{ id: 6, ws_roles: ['lcb', 'lcb3']}, /* dcs - difensore centrale sinistro */
				{ id: 9, ws_roles: ['rb', 'rb5']}, /* td - terzino destro */
				{ id: 10, ws_roles: ['lb', 'lb5']}, /* ts - terzino sinistro */
				{ id: 16, ws_roles: ['dmf']}, /* cc - centocampista centrale */
				{ id: 17, ws_roles: ['rcmf', 'rcmf3', 'rdmf']}, /* ccd - centocampista centrale destro */
				{ id: 18, ws_roles: ['lcmf', 'lcmf3', 'ldmf']}, /* ccs - centrocampista centrale sinistro */
				{ id: 19, ws_roles: ['rw', 'ramf', 'rwb']}, /* ed - esterno destro */
				{ id: 20, ws_roles: ['lw', 'lamf', 'lwb']}, /* es - esterno sinistro */	
				{ id: 24, ws_roles: ['rwf']}, /* ad - attaccante destro */
				{ id: 25, ws_roles: ['lwf']}, /* as - attaccante sinistro */
				{ id: 26, ws_roles: ['cf', 'amf']}, /* ac - attaccante centrale */
				{ id: 27, ws_roles: ['ss']}, /* pc - punta centrale */
			],
			ruoli_sigle: ['PO', 'DC', 'DCS', 'DCD', 'TS', 'TD', 'CC', 'CID', 'CIS', 'ES', 'ED', 'TQC', 'AC', 'AS', 'AD', 'PC'],
			label_struttura: ['brevilinea', 'normolinea', 'longilinea'],
			label_piede: ['dx', 'sx', 'ad'],
			label_muscolatura: ['esile', 'normale', 'possente'],
			label_velocita: ['sufficiente', 'discreta', 'buona', 'ottima', 'insufficiente'],
			label_resistenza: ['sufficiente', 'discreta', 'buona', 'ottima', 'insufficiente'],
			label_tecnica: ['sufficiente', 'discreto', 'buono', 'ottimo', 'insufficiente'],
			label_tattica: ['sufficienti', 'discreti', 'buoni', 'ottimi', 'insufficienti'],
			label_difensive: ['sufficienti', 'discrete', 'buone', 'ottime', 'insufficienti'],
			label_offensive: ['sufficienti', 'discrete', 'buone', 'ottime', 'insufficienti'],
			modalSearchPlayer: {
				show: false,
				results: null,
				player: null
			}
		}
	},
	props: [ 'formazione', 'valutazioni', 'home', 'away', 'ruoli', 'gradimenti' ],
	watch: {
		form: {
			deep: true,
			handler: function() {
				this.$emit('change');
			}
		}
	},
	methods: {
		unlinkPlayer: function(player) {

			player.wyId = null;
			player.foot = null;
			player.height = null;

		},
		selezionaGiocatore: function(player) {

			// this.modalSearchPlayer.player ...

			this.modalSearchPlayer.player.wyId = player.wyId;
			this.modalSearchPlayer.player.cognome = player.lastName;
			this.modalSearchPlayer.player.nome = player.firstName;
			this.modalSearchPlayer.player.shortName = player.shortName;
			this.modalSearchPlayer.player.height = player.height;
			this.modalSearchPlayer.player.foot = player.foot;
			this.modalSearchPlayer.player.nascita = player.birthDate;

			this.modalSearchPlayer = { player: null, results: null, show: false };

			this.$refs.searchPlayerModal.hide();

		},
		searchPlayer: function(player, index, home) {

			//console.log(player, index, home, 'ishome' + this.home);

			this.isHome = home;
			this.player_index = index;
			var query = player.cognome.trim().replace(/ /g, "_");

			if(query.length > 0) {

				if(player.nome && player.nome.trim() != "") {
					query = query + "_" + player.nome.trim().replace(/ /g, "_");
				}

				this.$refs.searchPlayerModal.show();

				this.modalSearchPlayer.show = true;
				this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then(function(data) {
					this.modalSearchPlayer.player = player;
					this.modalSearchPlayer.results = data.data;
				});

			}


			// 	if (response.ok)
			// 	{
			// 		this.players = response.data;
			// 		this.is_search = false;
			// 	}
			// }, (response) => {

			// });

		},
		addPlayer: function() {
			var player = {
				wyId: null,
				cognome: null,
				nome: null,
				nascita: null,
				shortName: null,
				height: null,
				foot: null,
				numero: null,
				id_ruolo: null,
				persona_id: null,
			};

			player.id = (this.home ? 'h' : 'a') + Date.now();
			player.numero = this.formazione.length + 1;
			this.formazione.push(player);
		},
		removePlayer: function(index, home) {
			this.formazione.splice(index, 1);
		},
		piede: function (foot) {
			if(foot == "right") {        
				return "0";
			}  else if(foot == "left") {
				return "1";
			}  else if(foot == "both") {
				return "2";
			} 
			return null;          
		},
		posizione: function(playerId, isHome) {
			var position_id = null;
			var formation_players = null;
				formation_players = this.formazione;
			for(var i in formation_players) {
				var item = formation_players[i];
				var wyId = Object.keys(item)[0];
				if(wyId == playerId) {
					var position = item[Object.keys(item)[0]]['position'];
					for(var x in this.ruoli_map) {
						if(this.ruoli_map[x].ws_roles.includes(position)) {
							return this.ruoli_map[x].id;
						}
					}
				}
			}
			return position_id;
		}
	},
	computed: {
		errors: function() {

			var needed = {
				cognome: 'Cognome',
				nome: 'Nome',
				nascita: 'Data di nascita',
				id_ruolo: 'Ruolo'
			};

			var errors = {};

			_.each(this.formazione, function(player, i) {

				var fields = [];
				_.each(needed, function(c, b) {
					if(player[b] == undefined || player[b] == '')
						fields.push(c);
				})

				errors[i] = fields;

			});/*

			if(!player.cognome || player.cognome == "") {
				alert("IMPOSSIBILE SALVARE LA FORMAZIONE! Sul giocatore n. " + player.numero + " della formazione di casa non hai inserito il cognome.");
				return;
			}
			if(!player.nome || player.nome == "") {
				alert("IMPOSSIBILE SALVARE LA FORMAZIONE! Sul giocatore n. " + player.numero + " della formazione di casa non hai inserito il nome.");
				return;
			}
			if(!player.nascita) {
				alert("IMPOSSIBILE SALVARE LA FORMAZIONE! Sul giocatore n. " + player.numero + " della formazione di casa non hai inserito la data di nascita.");
				return;
			}
			if(!player.id_ruolo) {
				alert("IMPOSSIBILE SALVARE LA FORMAZIONE! Sul giocatore n. " + player.numero + " della formazione di casa non hai inserito il ruolo.");
				return;
			}*/

			return errors;

		},
		filtered_ruoli() {
			return this.ruoli.filter(ruolo => this.ruoli_sigle.includes(ruolo.sigla));
		},
		theFormazione: function() {
			return this.formazione.sort(function(a, b) { 
				var roles_ws = ['GKP', 'DEF', 'MID', 'FWD', 'N/D'];
				var role_a = a.role ? a.role.code3 : 'N/D';
				var role_b = b.role ? b.role.code3 : 'N/D';
				return roles_ws.indexOf(role_a) - roles_ws.indexOf(role_b);
			});
		}
	}

}

/*


		

		searchPlayer(player, index, home) {
			if(!player.cognome || player.cognome == "") {
				return;
			}
			this.isHome = home;
			this.player_index = index;
			var query = player.cognome.trim().replace(/ /g, "_");
			if(query != "") {
				this.players = [];
				this.is_search = true;
				this.$refs.searchPlayerModal.show();
				if(player.nome && player.nome.trim() != "") {
					query = query + "_" + player.nome.trim().replace(/ /g, "_");                        
				}
				this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then((response) => {

					if (response.ok)
					{
						this.players = response.data;
						this.is_search = false;
					}
				}, (response) => {

				});
			}
		},





 */

</script>