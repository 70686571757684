<template>
  <b-modal :id="`share-tag-${tag.id}`" centered title="Condividi questo tag" @ok="onOk()">
    <b-form>
      <b-form-group>
        <b-list-group>
          <b-list-group-item>
            <b-form-checkbox
              v-model="allSelected"
              class="mb-0"
              @change="toggleAll()">
              {{ allSelected ? 'Deseleziona tutto' : 'Seleziona tutto' }}
            </b-form-checkbox>
          </b-list-group-item>

          <b-form-checkbox-group v-model="selectedPeople">
            <b-list-group-item v-for="person in share" :key="person.id">
              <b-form-checkbox :value="person.id" class="mb-0">{{ person.name }}</b-form-checkbox>
            </b-list-group-item>
          </b-form-checkbox-group>

        </b-list-group>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'ShareTag',
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedPeople: [],
      allSelected: false,
    };
  },

  computed: {
    ...mapState({
      share: state => state.data.share,
    }),
  },

  methods: {
    toggleAll() {
      this.selectedPeople = this.allSelected ? [] : _.map(this.share, 'id');
    },

    onOk() {
      if (!_.isEmpty(this.selectedPeople)) {
        this.$store.dispatch('shareTag', {
          id: this.tag.id,
          people: this.selectedPeople,
        });
      }
    },
  },

  watch: {
    selectedPeople() {
      this.allSelected = this.selectedPeople.length === this.share.length;
    },
  },
};
</script>
