<template id="stackedbarchart">

	<canvas :id="barid" :height="hchart" :width="wchart"></canvas>

</template>

<script>
export default {
	props: {
		barid: {
			type: String,
			required: true
		},

		datachart: {
			type: Object,
			required: false
		},

		wchart: {
			type: Number,
			default: 100
		},

		hchart: {
			type: Number,
			default: 250
		}
	},

	mounted: function(){

		var ctx = document.getElementById(this.barid);
		this.myChart = new Chart(ctx, {

			type: 'bar',

			data: this.datachart,

			options: {
				scales: {
	                xAxes: [{
	                	stacked: true,
	                }],
	                yAxes: [{
	                	stacked: true,
	                	ticks: {
                    		beginAtZero: true,
                		}
	                }]
	            }     
	        }
	    });
	},

	watch: {
		datachart: function() {
			this.myChart.data.datasets = this.datachart.datasets;
			this.myChart.update();			
		},
	},

}
</script>
