<template id="dashboard">
	<section class="allenamenti">

		<headapp></headapp>

		<div class="">
			<menusub></menusub>
			<div class="card border-0 rounded-0">
				<div class="card-body">
					<div>
						<b-button v-if="auth.isAdmin() || auth.isUserEnable('player_access_att_mng')" variant="primary" class="text-uppercase mb-3 mr-2" @click="newAttivita"><strong>New</strong></b-button>
						<b-button v-if="auth.isAdmin() || auth.isUserEnable('player_access_att_mng')" variant="outline-primary" class="text-uppercase mb-3 mr-2" @click="openPublish"><strong>Publish</strong></b-button>
						<b-button :variant="zoom ? 'info' : 'outline-info'" class="text-uppercase mb-3 mr-2" @click="zoom = !zoom"><strong>{{ zoom ? 'Zoom out' : 'Zoom In'}}</strong></b-button>
						<b-button variant="success" class="text-uppercase mb-3" @click="openPrintModal"><strong>Print PDF</strong></b-button>
					</div>
					
					<div class="content" ref="page_1">
						<full-calendar :class="zoom ? 'playeraccess fc fc-unthemed fc-ltr' : 'fc fc-unthemed fc-ltr'" v-if="auth.isAdmin() || auth.isUserEnable('player_access_att_mng')" ref="calendar" :event-sources="eventSources" @event-render="eventRender" @event-selected="eventSelected" @day-click="dayClick" :config="config" @event-drop="eventDrop"></full-calendar>
						<full-calendar :class="zoom ? 'playeraccess fc fc-unthemed fc-ltr' : 'fc fc-unthemed fc-ltr'" v-else ref="calendar" :event-sources="eventSources" @event-render="eventRender" @event-selected="eventSelected" :config="config"></full-calendar>
						<b-row class="my-2" v-if="auth.isAdmin() || auth.isUserEnable('player_access_att_mng')">
							<b-col cols="8">
								<b-badge style="background:red;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Communication</b-badge>
								<b-badge style="background:green;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Partnerships</b-badge>
								<b-badge style="background:blueviolet;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Brand</b-badge>
								<b-badge style="background:blue;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Roma Department</b-badge>
								<b-badge style="background:orange;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Digital</b-badge>
								<b-badge style="background:olive;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Sustainability Department</b-badge>
								<b-badge style="background:darkturquoise;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Other</b-badge>
								<b-badge style="background:fuchsia;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Team Schedule</b-badge>
								<b-badge style="background:salmon;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Press Conference</b-badge>
								<b-badge style="background:saddlebrown;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Private</b-badge>
							</b-col>
							<b-col class="text-right">
								<b-form-radio-group v-model="filter_public">
									<b-form-radio :value="0">All activities</b-form-radio>
									<b-form-radio :value="1">Public</b-form-radio>
									<b-form-radio :value="2">Private</b-form-radio>
								</b-form-radio-group>
							</b-col>
						</b-row>
						<b-row class="my-2" v-else>
							<b-col>
								<b-badge style="background:red;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Communication</b-badge>
								<b-badge style="background:green;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Partnerships</b-badge>
								<b-badge style="background:blueviolet;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Brand</b-badge>
								<b-badge style="background:blue;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Roma Department</b-badge>
								<b-badge style="background:orange;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Digital</b-badge>
								<b-badge style="background:olive;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Sustainability Department</b-badge>
								<b-badge style="background:darkturquoise;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Other</b-badge>
								<b-badge style="background:fuchsia;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Team Schedule</b-badge>
								<b-badge style="background:salmon;padding: 0.5em 0.7em;font-weight: normal;font-size: 85%;margin-right:0.3rem">Press Conference</b-badge>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</div>

		<footerapp></footerapp>

		<b-modal id="eventModal" ref="eventModal" :title="event_id ? 'Activity' : 'New Activity'" centered @ok="handleOk" @cancel="handleCancel" ok-title="Save" size="lg">
			<b-container fluid>
				<form @submit.stop.prevent="handleSubmit">
					<b-row class="mb-1">
						<b-col>
							<b-row class="mb-2" v-if="!event_id">
								<b-col>
									<b-form-radio-group v-model="servizio">
										<b-form-radio :value="0">Player Access</b-form-radio>
										<b-form-radio :value="1">Private</b-form-radio>
										<b-form-radio :value="2">Team Schedule</b-form-radio>
										<b-form-radio :value="3">Press Conference</b-form-radio>
									</b-form-radio-group>
								</b-col>
							</b-row>
							<div v-if="servizio == 0">
								<b-row class="mb-1">
									<b-col cols="4">
										<b-form-group label="Data">
											<b-form-input v-model="data" type="date"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-1" v-if="!event_id">
									<b-col>
										Request

										<div v-if="richiesta" class="border p-1 mb-1" style="font-size: 0.9rem;">
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Department: 
												</b-col>
												<b-col cols="2">
													{{ getDepartment(richiesta.department) }}	
												</b-col>
												<b-col>
													{{ richiesta.department_text }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Content: 
												</b-col>
												<b-col cols="2">	
													{{ getContent(richiesta.content) }}	
												</b-col>

												<b-col>
													{{ richiesta.content_text }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Required by: 
												</b-col>
												<b-col>
													{{ richiesta.required_by }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Period: 
												</b-col>
												<b-col>
													{{ richiesta.period }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Location: 
												</b-col>
												<b-col cols="2">
													{{ getLocation(richiesta.location) }}		
												</b-col>
												<b-col>
													{{ richiesta.location_text }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Player/s: 
												</b-col>
												<b-col cols="2">	
													<strong><em>Desiderata</em></strong>
												</b-col>
												<b-col>
													<span v-for="(item, key) in JSON.parse(richiesta.players_desiderata)">
														{{ item.name }} 
														<span v-if="key < (JSON.parse(richiesta.players_desiderata)).length - 1">, </span>
													</span>
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
												</b-col>
												<b-col cols="2">	
													<strong><em>Alternative</em></strong>
												</b-col>
												<b-col>
													<span v-for="(item, key) in JSON.parse(richiesta.players_alternative)">
														{{ item.name }} 
														<span v-if="key < (JSON.parse(richiesta.players_alternative)).length - 1">, </span>
													</span>
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Timing: 
												</b-col>
												<b-col>
													{{ richiesta.timing }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Dress code: 
												</b-col>
												<b-col>
													{{ richiesta.dress_code }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Description: 
												</b-col>
												<b-col>
													{{ richiesta.description }}
												</b-col>
											</b-row>
											<b-row align-v="center" class="mb-2">
												<b-col cols="2">
													Note: 
												</b-col>
												<b-col>
													{{ richiesta.note }}

												</b-col>
											</b-row>
										</div>
										<b-pagination v-if="requests.length > 0" size="sm" align="center" :total-rows="requests.length" v-model="page_req" :per-page="1" @change="selectPageRequest"></b-pagination>
										<div v-else class="my-2">
											<strong>No request found!</strong>	
										</div>
										<!--b-form-group label="Request">
											<b-form-select v-model="id_richiesta" :options="request_options" value-field="id" text-field="name"></b-form-select>
										</b-form-group-->
									</b-col>
								</b-row>
								<b-row class="mb-1" v-else>
									<b-col>
										<b-form-group label="Request">
											<router-link :to="'/playeraccess/richieste/view/' + request.id" target="_blank" v-if="auth.isUserEnable('player_access_req')">
												<h5 class="color-rosso">{{ request ? formatDate(request.created_at) + ': ' + request.utente.nomeCompleto + ' - ' + getDepartment(request.department) + ' - ' + getContent(request.content) : ''}}</h5>	
											</router-link>
											<h5 v-else class="color-rosso">{{ request ? formatDate(request.created_at) + ': ' + request.utente.nomeCompleto + ' - ' + getDepartment(request.department) + ' - ' + getContent(request.content) : ''}}</h5>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col cols="2">
										<b-form-group label="Time from">
											<b-form-input v-model="timing" type="time"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group label="Time to">
											<b-form-input v-model="time_to" type="time"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group label="&nbsp;">
											<b-form-input v-model="timing_text" plaintext></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col>
										<b-form-group label="Content">
											<b-form-input v-model="content"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col>
										<b-form-group label="Location">
											<b-form-input v-model="location"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col>
										<b-form-group label="Player/s">
											<multiselect v-model="players" track-by="id" label="name" :options="player_options" :multiple="true" :searchable="true" placeholder="Select player/s"></multiselect>
										</b-form-group> 
									</b-col>
								</b-row>
								<b-row class="mb-1">
									<b-col>
										<b-form-group label="Public">
											<b-form-radio-group
											v-model="public"
											>
											<b-form-radio :value="0">No</b-form-radio>
											<b-form-radio :value="1">Yes</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
						<div v-else>
							<b-row class="mb-1">
								<b-col cols="4">
									<b-form-group label="Data">
										<b-form-input v-model="data" type="date"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols="4">
									<b-form-group label="Time">
										<b-form-input v-model="timing" type="time"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col>
									<b-form-group label="Description">
										<b-form-textarea
										id="textarea"
										v-model="description"
										rows="3"
										max-rows="6"
										placeholder="Free text..."
										></b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-if="servizio >= 2" class="mb-1">
								<b-col>
									<b-form-group label="Public">
										<b-form-radio-group
										v-model="public"
										>
										<b-form-radio :value="0">No</b-form-radio>
										<b-form-radio :value="1">Yes</b-form-radio>
									</b-form-radio-group>
								</b-form-group>
							</b-col>
						</b-row>
					</div>
					<b-row class="mt-3">
						<b-col>
							<!--b-button v-if="auth.isUserEnable('player_access_att_mng') && (auth.isAdmin() || (selected && selected.utente_id == user.id))" v-show="event_id" @click="eliminaAttivita" variant="outline-primary">Delete</b-button-->
							<b-button v-if="auth.isUserEnable('player_access_att_mng') || auth.isAdmin()" v-show="event_id" @click="eliminaAttivita" variant="outline-primary">Delete</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</form>
	</b-container>
</b-modal>

<b-modal id="publishModal" ref="publishModal" title="Publish" centered @ok="publish" ok-title="OK" size="lg">
	<b-container fluid>
		<b-row class="mb-1">
			<b-col>
				<b-form-group label="From">
					<b-form-input v-model="from" type="date"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group label="To">
					<b-form-input v-model="to" type="date"></b-form-input>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col>
				<b-form-radio-group v-model="updated">
					<b-form-radio :value="0">Publish</b-form-radio>
					<b-form-radio :value="1">Update</b-form-radio>
				</b-form-radio-group>
			</b-col>
		</b-row>
	</b-container>
</b-modal>

<b-modal id="pdfModal" ref="pdfModal" title="Print pdf" centered @ok="download" ok-title="Print">
	<b-container fluid>
		<b-row class="mb-1">
			<b-col>
				<b-form-radio-group v-model="landscape">
					<b-form-radio :value="0">Portrait</b-form-radio>
					<b-form-radio :value="1">Landscape</b-form-radio>
				</b-form-radio-group>
			</b-col>
		</b-row>
	</b-container>
</b-modal>

</section>
</template>

<script>
import moment from 'moment';
import { isString } from 'lodash';
import { FullCalendar } from 'vue-full-calendar'
// import '../../../lang/agenda.js'
import * as types from '../../../store/types'
import axios from 'axios';
import '@/assets/fonts/ASRoma-Regular-normal.js';
import { jsPDF } from 'jspdf';
var Auth = require('../../../auth.js');
export default {

	data: function(){
		return{	
			config:
			{
				disableResizing: true,
				editable: true,
				defaultView: 'month',
				locale: this.$i18n.t('lang'),
				height: 'auto',
			},
			selected: null,
			event_id: null,
			servizio: 0,
			data: null,
			id_richiesta: null,
			request: null,
			timing: null,
			time_to: null,
			timing_text: null,
			content: null,
			location: null,
			players: [],
			public: 0,
			description: null,
			requests: [],
			request_options: [],
			player_options: [],
			options_department: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Communication'},
			{ value: 2, text: 'Partnerships'},
			{ value: 3, text: 'Brand'},
			{ value: 4, text: 'Roma Department'},
			{ value: 5, text: 'Digital'},
			{ value: 7, text: 'Sustainability Department'},
			{ value: 6, text: 'Other'}
			],
			options_content: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Interviews'},
			{ value: 2, text: 'Shootings (foto&video)'},
			{ value: 3, text: 'Events'},
			{ value: 4, text: 'Appereances'},
			{ value: 5, text: 'Other'}
			],
			options_location: [
			{ value: 0, text: ''}, 
			{ value: 1, text: 'Internal'},
			{ value: 2, text: 'External'},
			],
			from: null,
			to: null,
			filter_public: 0,
			zoom: false,

			header_pdf: null,
			page_count: 0,
			page_pdf: [null],

			richiesta: null,
			page_req: 1,
			landscape: 0,
			updated: 0,
		}
	},

	computed: {
		auth: function() {
			return Auth;
		},
		user: function() {
			return Auth.user;
		},
		eventSources: function() {
			console.log('filter_public: ' + this.filter_public);
			const self = this;
			var events = [];
			events.push({
				events(start, end, timezone, callback) {
					var start = moment(start).format("YYYY-MM-DD HH:mm:ss")
					var end = moment(end).format("YYYY-MM-DD HH:mm:ss")					
					const formData = new FormData();
					formData.append("dal", start);
					formData.append("al", end);
					formData.append("filter_public", self.filter_public);
					formData.set('_method', 'POST');

					const opts = {
						url: `/playeraccess/attivita`,
						method: 'POST',
						data: formData,
					};

					axios(opts)
					.then((res) => {
						var data = res.data.map(item => ({
							title: self.getTitleActivity(item),
							start: item.data + (item.timing && item.timing != '00:00:00' ? ' ' + item.timing : ''),
							end: item.data + (item.time_to && item.time_to != '00:00:00' ? ' ' + item.time_to : ''),
							data: item.data,
							timing: item.timing,
							time_to: item.time_to,
							id: item.id,
							servizio: item.servizio,
							content: item.content_text,
							location: item.location_text,
							utente_id: item.utente_id,
							public: item.public,
							description: item.description,
							id_richiesta: item.id_richiesta,
							request: item.richiesta,
							utente: item.utente,
							players: item.rosa,
							staff: item.staff,
							color: self.getColorActivity(item),
							textColor: '#FFF',
							durationEditable: false,
						}));
						callback(data);
					}).catch((err) => {
						console.log(err);
					});
				},
				color: '#F7CA18',
				textColor: '#A80532',
			}); 
			return events;
		}
	},

	created() {
		this.getPlayers();
		this.getRichiesteInAttesa();

		this.$http.get(this.$store.state.apiEndPoint + '/headerpdf').then((response) => {
			if (response.ok)
			{
				this.header_pdf = response.data;    
			}
		}, (response) => {
		});
	},

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		FullCalendar,
	},

	methods: {

		handleCancel (evt) {
			this.event_id = null;
			this.servizio = 0;
		},
		handleOk (evt) {
			evt.preventDefault();
			if(this.auth.isUserEnable('player_access_att_mng') || this.auth.isAdmin()) {
				this.handleSubmit();
			}
		},

		getColorActivity(activity) {
			if(activity.servizio == 1) {
				return 'saddlebrown';
			} else if(activity.servizio == 2) {
				return 'fuchsia';
			} else if(activity.servizio == 3) {
				return 'salmon';
			}else {
				if(activity.richiesta) {
					if(activity.richiesta.department == 1) {
						return 'red';
					} else if(activity.richiesta.department == 2) {
						return 'green';
					} else if(activity.richiesta.department == 3) {
						return 'blueviolet';
					} else if(activity.richiesta.department == 4) {
						return 'blue';
					} else if(activity.richiesta.department == 5) {
						return 'orange';
					} else if(activity.richiesta.department == 6) {
						return 'darkturquoise';
					} else if(activity.richiesta.department == 7) {
						return 'olive';
					}
				}
			}
			return 'gray';
		},

		getTitleActivity(activity) {
			var label = "";
			if(!activity.timing || activity.timing == '00:00:00') {
				label = 'TBD - ';
			}
			if(activity.servizio == 1) {
				label += activity.description ? activity.description : 'Private';
			} else if(activity.servizio == 2) {
				label += activity.description ? activity.description : 'Team Schedule';
			} else if(activity.servizio == 3) {
				label += activity.description ? activity.description : 'Press Conference';
			} else {
				var hasTeam = false;
				label += activity.content_text ? activity.content_text : 'Player Access';
				if(activity.richiesta) {
					if(activity.richiesta.timing) {
						label += '\n' + activity.richiesta.timing;
					}
					if(activity.richiesta.department != 6) {
						label += '\n' + this.getDepartment(activity.richiesta.department);
					}
					if(activity.richiesta.department_text && activity.richiesta.department_text != "") {
						label += ' - ' + activity.richiesta.department_text;
					}
					if(activity.rosa.length > 0) {
						for(var i in activity.rosa) {
							var rosa = activity.rosa[i];
							label += '\n' + rosa.cognome + ' ' + rosa.nome;
						}
						hasTeam = true;
					} else if(activity.richiesta.players_desiderata) {
						var players_desiderata = JSON.parse(activity.richiesta.players_desiderata);
						if(players_desiderata.length > 0 && players_desiderata[0].id == -1) {
							label += '\nTeam';
							hasTeam = true;
						}
					}
				}
				for(var i in activity.staff) {
					var staff = activity.staff[i];
					label += '\n' + staff.cognome + ' ' + staff.nome;
				}
				if(!hasTeam) {
					for(var i in activity.rosa) {
						var rosa = activity.rosa[i];
						label += '\n' + rosa.cognome + ' ' + rosa.nome;
					}
				}
			}
			return label;
		},

		openPrintModal() {
			this.$refs.pdfModal.show();
		},

		openPublish() {
			this.from = null;
			this.to = null;
			this.$refs.publishModal.show();
		},

		publish () {
			if(confirm('Confirm the publication of the activities in the selected period?')) {
				const formData = new FormData();
				if(!this.from || this.from == '') {
					alert('You must enter a from date!');
					return;
				}
				formData.append("from", this.from);
				if(!this.to || this.to == '') {
					alert('You must enter a to date!');
					return;
				}
				formData.append("to", this.to);
				formData.append("updated", this.updated);

				this.$http.post(this.$store.state.apiEndPoint + '/playeraccess/attivita/publish', formData).then((response) => {

					if (response.ok)
					{
						this.getRichiesteInAttesa();
						this.event_id = null;
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
				});
			}
		},

		newAttivita() {

			this.servizio = 0;
			this.selected = null;
			this.data = null;
			this.id_richiesta = null;
			this.request = null;
			this.timing = null;
			this.time_to = null;
			this.timing_text = null;
			this.content = null;
			this.location = null;
			this.players = [];
			this.public = 0;
			this.description = null;

			this.page_req = 1;
			this.richiesta = null;
			if(this.requests.length > 0) {
				this.richiesta = this.requests[0];
			} 
			this.$refs.eventModal.show();
		},

		handleSubmit () {
			if(!this.event_id) {
				this.create();
			} else {
			//	if(this.auth.isAdmin() || (this.selected && this.selected.utente_id == this.user.id)) {
				if(this.auth.isAdmin() || this.auth.isUserEnable('player_access_att_mng')) {	
					const formData = new FormData();
					if(!this.data || this.data == '') {
						alert('You must enter a date for the activity!');
						return;
					}
					formData.append("data", this.data);
					if(this.servizio > 0) {
						formData.append("timing", this.timing);
						formData.append("description", this.description);
						if(this.servizio >= 2) {
							formData.append("public", this.public);
						}
					} else {
						if(!this.id_richiesta) {
							alert("You must select a request!");
							return;
						}
						formData.append("id_richiesta", this.id_richiesta);
						formData.append("timing", this.timing);
						formData.append("time_to", this.time_to);
						formData.append("content", this.content);
						formData.append("location", this.location);

						var rosa_ids = [];
						var staff_ids = [];
						for(var i in this.players) {
							if(isString(this.players[i].id) && this.players[i].id.startsWith("s")) {
								staff_ids.push(this.players[i].id.substr(1));
							} else {
								rosa_ids.push(this.players[i].id);
							}
						}
						formData.append("rosa_ids", rosa_ids);
						formData.append("staff_ids", staff_ids);
						formData.append("public", this.public);
					}
					this.update(formData,  this.event_id);
				}
			}
			this.event_id = null;
			this.servizio = 0;
		},

		removeEvent() {
			this.$refs.calendar.$emit('remove-event', this.selected);
			this.selected = {};
		},
		eventSelected(event) {
			this.players = [];
			this.event_id = event.id;
			this.servizio = event.servizio;
			this.data = event.data;
			this.timing = event.timing;
			if(this.servizio > 0) {
				this.description = event.description;
				this.id_richiesta = null;
				this.request = null;
				this.timing_text = null;
				this.content = null;
				this.location = null;
				this.public = 0;
				if(this.servizio >= 2) {
					this.public = event.public;
				}
			} else {
				this.id_richiesta = event.id_richiesta;
				this.request = event.request;
				this.time_to = event.time_to;
				this.timing_text = this.request ? this.request.timing : null;
				this.content = event.content;
				this.location = event.location;
				this.public = event.public;
				this.description = null;

				this.players = event.players.map(item => ({								
					name: item.cognome + ' ' + item.nome,
					id: item.id,
				}));
				
				for(var i in event.staff) {
					var staff = event.staff[i]; 
					this.players.unshift({name: staff.cognome + ' ' + staff.nome, id: 's' + staff.id });
				}
			}
			this.selected = event;
			this.$refs.eventModal.show();
		},

		eventRender(event) {
		},

		dayClick(date, jsEvent, view) {
			this.event_id = null;
			this.servizio = 0;
			this.selected = null;
			this.data = moment(date['_d']).format("YYYY-MM-DD");
			this.id_richiesta = null;
			this.request = null;
			this.timing = null;
			this.time_to = null;
			this.timing_text = null;
			this.content = null;
			this.location = null;
			this.players = [];
			this.public = 0;
			this.description = null;
			this.page_req = 1;
			this.richiesta = null;
			if(this.requests.length > 0) {
				this.richiesta = this.requests[0];
			}
			this.$refs.eventModal.show();
		},

		eventDrop(event, delta, revertFunc) {
		//	if(this.auth.isAdmin() || (event && event['utente_id'] == this.user.id)) {
			if(this.auth.isAdmin() || this.auth.isUserEnable('player_access_att_mng')) {	
				const formData = new FormData();
				formData.append("drop",  true);
				formData.append("data",  moment(event['start']['_d']).format("YYYY-MM-DD"));		
				this.update(formData,  event['id']);
			}
		},

		refreshEvents() {
			this.$refs.calendar.$emit('refetch-events');
			this.$refs.calendar.fireMethod('changeView', 'basicDay');
			this.$refs.calendar.fireMethod('changeView', 'month');
		},

		update(data, id) {
			if(this.auth.isUserEnable('player_access_att_mng') || this.auth.isAdmin()) {
				this.$http.post(this.$store.state.apiEndPoint + '/playeraccess/attivita/edit/' + id, data).then((response) => {
					if (response.ok){
						this.getRichiesteInAttesa();
						this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
					if (response.status == 422) {
						this.errors = response.data;
						for (var index in this.errors) {
							this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
						}
					}
				});
			}
		},

		create() {
			var rosa_ids = [];
			var staff_ids = [];
			const formData = new FormData();
			formData.append("servizio", this.servizio);
			if(!this.data || this.data == '') {
				alert('You must enter a date for the activity!');
				return;
			}
			formData.append("data", this.data);
			if(this.servizio > 0) {
				formData.append("timing", this.timing);
				formData.append("description", this.description);
				if(this.servizio >= 2) {
					formData.append("public", this.public);
				}
			} else {
				if(!this.richiesta) {
					alert("You must select a request!");
					return;
				}
			/*	if(!this.id_richiesta) {
					alert("You must select a request!");
					return;
				}  */
				this.id_richiesta = this.richiesta.id;
				formData.append("id_richiesta", this.id_richiesta);
				formData.append("timing", this.timing);
				formData.append("time_to", this.time_to);
				formData.append("content", this.content);
				formData.append("location", this.location);

				for(var i in this.players) {
					if(isString(this.players[i].id) && this.players[i].id.startsWith("s")) {
						staff_ids.push(this.players[i].id.substr(1));
					} else {
						rosa_ids.push(this.players[i].id);
					}
				}
				formData.append("rosa_ids", rosa_ids);
				formData.append("staff_ids", staff_ids);
				formData.append("public", this.public);
			}

			this.$http.post(this.$store.state.apiEndPoint + '/playeraccess/attivita/add', formData).then((response) => {
				if (response.ok){
					this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
					this.refreshEvents();
					this.$refs.eventModal.hide();
					this.getRichiesteInAttesa();
				}
			}, (response) => {
				if (response.status == 422) {
					this.errors = response.data;
					for (var index in this.errors) {
						this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
					}
				}
			});
		},
		eliminaAttivita() {
			if(confirm('Confirm the deletion of the activity?')) {
				this.$http.get(this.$store.state.apiEndPoint + '/playeraccess/attivita/delete/' + this.event_id).then((response) => {

					if (response.ok)
					{
						this.getRichiesteInAttesa();
						this.event_id = null;
						this.$store.commit(types.ALERTS_PUSH, { msg: this.$i18n.t(response.data.message), status: 1 });
						this.refreshEvents();
						this.$refs.eventModal.hide();
					}
				}, (response) => {
				});
			}
		},

		getPlayers: function() {				
			this.players = [];
			this.$http.get(this.$store.state.apiEndPoint + '/palestra/players', {params: { team_id: 13 }}).then((response) => {
				if (response.ok)
				{
					this.player_options = response.data.map(item => ({								
						name: item.cognome + ' ' + item.nome,
						id: item.id,
					}));

					this.getStaff();
				}
			}, (response) => {
			});
		},

		getStaff: function(){				
			this.staff = [];
			this.$http.get(this.$store.state.apiEndPoint + '/staff/get/members', {params: { team_id: 13 }}).then((response) => {
				if (response.ok)
				{
					var members = response.data;
					for(var i in members) {
						var member = members[i];
						if(member.last_carriera_sportiva.ruoli && member.last_carriera_sportiva.ruoli.length > 0 && (member.last_carriera_sportiva.ruoli[0].persona_ruolo_glo_id == 46 || member.last_carriera_sportiva.ruoli[0].persona_ruolo_glo_id == 29)) {
							this.player_options.unshift({name: member.cognome + ' ' + member.nome, id: 's' + member.id });
						}
					}
				}
			}, (response) => {
			});
		},

		getRichiesteInAttesa: function() {
			this.requests = [];				
			this.request_options = [];
			this.$http.get(this.$store.state.apiEndPoint + '/playeraccess/richieste/in/attesa').then((response) => {
				if (response.ok)
				{
					this.requests = response.data;
					this.request_options = response.data.map(item => ({								
						name: this.formatDate(item.created_at) + ': ' + item.utente.nomeCompleto + ' - ' + this.getDepartment(item.department) + ' - ' + this.getContent(item.content),
						id: item.id,
					}));
					this.request_options.unshift({name: '-- Select --', id: null});
				}
			}, (response) => {
			});
		},

		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
		},

		getDepartment(department) {
			var index = this.options_department.findIndex((item) => item.value == department);
			if(index > -1) {
				return this.options_department[index].text;
			}
			return "";
		},

		getContent(content) {
			var index = this.options_content.findIndex((item) => item.value == content);
			if(index > -1) {
				return this.options_content[index].text;
			}
			return "";
		},

		getLocation(location) {
			var index = this.options_location.findIndex((item) => item.value == location);
			if(index > -1) {
				return this.options_location[index].text;
			}
			return "";
		},

		download() { 

			this.page_count = 0;
			this.page_pdf = [ null];

			this.printing = true;

			for(var page = 1; page <=1; page++) {

				const el = this.$refs['page_' + page];
				const options = {
					type: 'dataURL',
					index: page,
					scale: '1'
				}
				this.$html2canvas(el, options).then(canvas => {
					this.page_pdf[options.index - 1] = canvas;
					this.page_count++;

				});
			}
		},

		selectPageRequest(page) {
			this.richiesta = this.requests[page - 1];   
		},

	},

	watch: {
		richiesta() {
			if(!this.event_id) {
				if(this.richiesta) {
					this.timing_text = this.richiesta.timing;
					this.content = this.getContent(this.richiesta.content) + (this.richiesta.content_text && this.richiesta.content_text != "" ? ' - ' + this.richiesta.content_text : '');
					this.location = this.getLocation(this.richiesta.location) + (this.richiesta.location_text && this.richiesta.location_text != "" ? ' - ' + this.richiesta.location_text : '');
					var players_ids = [];
					this.players = [];	
					var players_desiderata = JSON.parse(this.richiesta.players_desiderata);
					for(var i in players_desiderata) {
						var player = players_desiderata[i];
						if(!players_ids.includes(player.id)) {
							this.players.push(player);
							players_ids.push(player.id);
						}
					}
					var players_alternative = JSON.parse(this.richiesta.players_alternative);
					for(var i in players_alternative) {
						var player = players_alternative[i];
						if(!players_ids.includes(player.id)) {
							this.players.push(player);
							players_ids.push(player.id);
						}
					}		
				} else {
					this.timing_text = null;
					this.content = null;
					this.location = null;
					this.players = [];	
				}
			}
		},

		zoom() {
			this.refreshEvents();
		},

		page_count() {
			if(this.page_count ==  1) {
				var doc = new jsPDF((this.landscape ? "l" : "p"), "mm", "a4");

				var widthDoc = doc.internal.pageSize.getWidth(); 
				var heightDoc = doc.internal.pageSize.getHeight();

				doc.setFillColor('#E9E9E9');
				doc.rect(0, 0, widthDoc, 10, "F");
				doc.setFont('ASRoma-Regular');
				doc.setFontSize(12);
				doc.setTextColor('#A80532');
				doc.text(3, 7, 'Player Access Schedule');

				var img = new Image();
				img.src = 'assets/images/logo_new.png';	
				doc.addImage(img, 'PNG', this.landscape ? 275 : 190, 4, 17, 4);

				var imgProps = doc.getImageProperties(this.page_pdf[0]);
				var imgWidth = imgProps.width;
  				var imgHeight = imgProps.height;

				var widthRatio = (widthDoc - 5) / imgWidth;
   				var heightRatio = (heightDoc - 14) / imgHeight;
   				var ratio = Math.min(widthRatio, heightRatio);

  				var finalWidth = imgWidth * ratio;
  				var finalHeight = imgHeight * ratio;
  				
  				var x = (widthDoc - finalWidth) / 2;
  				var y = 12;

  				doc.addImage(this.page_pdf[0], 'JPEG', x, y, finalWidth, finalHeight);
				
				doc.save('playeraccess_attivita.pdf');
				this.printing = false;
			}
		},
	}

}
</script>

<style>
.fc-resizer.fc-end-resizer {
	display: none;
}
</style>
